
/* eslint-disable @typescript-eslint/no-unused-vars */
 

import { Product, type ICartProducts } from '../../../../../app/modules/apps/cart/core/products/_models'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined } from '@ant-design/icons'
import PageCounter from './PageCounter'
import { RootStoreContext } from '../../../../../stores/rootStore'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { uploadTemp } from '../../../../../app/modules/apps/file/core/_requests'
import { useIntl } from 'react-intl'
import ShoppingTotals from '../ShoppingTotals'
import { Input, UploadFile } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';

interface IProps {
  product: ICartProducts
  getPageCounter?: (totalPages: number) => void
  addTocart: (product: Product, quantity: number) => void
  setNumberImagePages: Dispatch<SetStateAction<number>>
  numberImagePages: any
}
interface FileInfo {
  fileList: UploadFile<any>[];
}

const UploaderTemp: React.FC<IProps> = ({ product, getPageCounter, addTocart, setNumberImagePages, numberImagePages }) => {
  const [fileNames, setFileNames] = useState<string[]>([]);
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)

  const quantity = product.quantity
  const { setfiles, setfilesPaypal, files, setNumberPages, filesPaypal, numberPages } = rootStore.cartStore
  const [info, setInfo] = useState<FileInfo>({ fileList: [] });
  const fileType = filesPaypal ? filesPaypal[0].type : []
  const [fileUploaded, setFileUploaded] = useState(false);
  const handleChange = (infoHandle: any) => {
    console.log("infoEnHandle",infoHandle)
    const files: File[] = [];

    infoHandle.fileList.forEach((file: any) => {
      files.push(file.originFileObj)
    })
    setfiles(files)
    localStorage.setItem('file_paypal', JSON.stringify(files[0]))
    setfilesPaypal(files)
    console.log(files)
    setInfo(infoHandle);
  }


  const [hasFiles, setHasFiles] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const handlePageCounter = (pageCounter: number) => {
    setNumberPages(pageCounter)
    getPageCounter != null && getPageCounter(pageCounter)
  }
  const formatFileSize = (sizeInBytes: any) => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return sizeInMB.toFixed(2) + ' MB';
  };


  const handleAddToCart = (value: number) => {
    if (value) addTocart(product.product, value - quantity)
  }

  const handlePages = () => {
    console.log(fileType, 'filetype');
    console.log(filesPaypal, 'filePYPAL');
    
    if (fileType !== 'application/pdf') {
      return (
        <Input
          className='counter-input'
          type='number'
          value={quantity}
          min='1'
          style={{
            WebkitAppearance: 'none',
            MozAppearance: 'textfield',
          }}
          onChange={(e: any) => {
            handleAddToCart(parseInt(e.target.value));
            setNumberImagePages(parseInt(e.target.value));
          }}
          inputMode='numeric'
          pattern='[0-9]*'
          onWheel={(e: { preventDefault: () => any }) => e.preventDefault()}
        />
      );
    }
  
    return null;
  };


  
  return (
    <div>
        <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Dragger
      className={`dragger-price ${isMouseOver ? 'heartbeat-animation' : ''}`}
     
        style={{
          backgroundColor: '#D0F0FD66',
          border: '#0B76B7 dashed 1px',
          borderRadius: '10px',
          padding: '10px',
          marginBottom:'40px',
        }}
        action={uploadTemp()}
        data={{ type: 'temp', fileName: product.product.id }}
        maxCount={product.quantity}
        showUploadList={false}
        onChange={(info) => {
          console.log("INFO ONCA",info)
          // Check if the length of fileList is greater than 1
            // If so, only keep the first file and discard the rest
            const length=info.fileList.length
            info.fileList = [info.fileList[length-1]];
            console.log("INFO DESPUES",info)
            handleChange(info);
            setInfo(info);
            setHasFiles(info.fileList.length > 0);
        }}
        beforeUpload={() => false}
      >
        {hasFiles ? (
          <><div style={{ textAlign: 'center', display: 'flex' }}>
              <p>{/* Puedes agregar contenido adicional aquí */}</p>
              {files != null && (
                <>
                  <div className='father-container-product-upload' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {info?.fileList.map((file: any, index: number) => (
                      <div className='container-product-name' key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center' }}>
                        <img className='image-pdf'
                          src="/media/svg/menu-icons/Pdf-blue.svg"
                          alt="Logo de Immigracion"
                          style={{ width: '20px', height: '20px', marginBottom: '5px' }} />
                        <p style={{ marginTop: '10px', textAlign: 'center' }}>
                          <p className='file-name-price' style={{ color: '#0B76B7', fontSize: '17px', display: 'flex' }}>
                            <p className='file-name'>
                              {file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}
                            </p>
                            <div className='container-size-handler' style={{ display: 'flex', alignItems: 'center', backgroundColor: '#0B76B7', marginLeft: '20px' }}>
                              <p style={{ color: 'white', width: '79px', height: '26px', borderRadius: '5px', marginLeft: '10px' }}>
                                {formatFileSize(file.size)}
                              </p>
                              {files && files.length > 0 && (
                                <div onClick={(e) => e.stopPropagation()}>
                                  {handlePages()}
                                </div>
                              )}
                              <p style={{ color: 'white', fontSize: '15x', paddingLeft: '10px', paddingRight: '10px' }}> {fileType === 'application/pdf' ? <PageCounter files={toJS(files)} getPageCounter={handlePageCounter} /> : numberImagePages} Paginas</p>
                            </div>
                            <img
                className='image-trash'
                src="/media/svg/menu-icons/trash-can.png"
                alt="Logo de Immigracion"
                style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  e.stopPropagation() 
                  setfiles([]) 
                  setFileUploaded(false) 
                  setHasFiles(false) 
                  setNumberPages(0);
                  setNumberImagePages(0);
                  handleAddToCart(0);
                  window.location.reload();
                } } />
                          </p>
                        </p>
                      </div>
                    ))}
                  </div>
                
                </>
              )}
            </div>
            <div className='container-trash'><img
                className='image-trash2'
                src="/media/svg/menu-icons/trash-can.png"
                alt="Logo de Immigracion"
                style={{ width: '30px', height: '30px', marginTop: '4px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  e.stopPropagation() 
                  setfiles([]) 
                  setFileUploaded(false) 
                  setHasFiles(false) 
                  setNumberPages(0);
                  setNumberImagePages(0);
                  handleAddToCart(0);
                  window.location.reload();
                } } /></div></>
        ) : (
          <><div className='container-dragger-inside' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className='container-image-firstext' style={{ textAlign: 'center', padding: '20px', display: 'flex', justifyContent: 'center' }}>
            <div className='container-second-firstext' style={{ borderRadius: '50px', border: '2px solid #18BFFF ', width: '14rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem' }}>
              <img className='image-pdf-dragger'
                src="/media/svg/menu-icons/download.svg"
                alt="Logo de Immigracion"
                style={{ width: '20px', height: '20px', marginBottom: '5px', marginRight: '10px' }} />  <p style={{ color: '#0B76B7', fontSize: '16px', fontWeight: '700' }}>Seleccione un archivo</p>  </div>
          </div><p style={{ color: '#0B76B7', fontSize: '14px', fontWeight: '700',fontStyle:'italic' }}>Subir 1 archivo a la vez</p></div></>
        )}
      </Dragger></div>

    </div>
  )
}

export default observer(UploaderTemp)
