 
 

import Login from '../../../../../auth/components/Login'
import Registration from '../../../../../auth/components/Registration'
import {Tabs} from 'antd'

const {TabPane} = Tabs
const Account = () => {
  return (
    <div>
      <Tabs centered className='custom-tabs'>
        <TabPane tab='Usuario nuevo' key='1'>
          <Registration />
        </TabPane>
        <TabPane tab='Iniciar sesión' key='2'>
          <div className='login-responsive text-left mx-5'>
            <Login />
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Account
