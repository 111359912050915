/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {type Dispatch, type SetStateAction, useContext, useEffect, useState} from 'react'
import {Select, Space, Spin} from 'antd'
import {Form} from 'react-bootstrap'
import {type Priority} from '../../../app/modules/apps/priority/core/_models'
import {RootStoreContext} from '../../../stores/rootStore'
import {observer} from 'mobx-react'

const {Option} = Select
interface IProps {
  setPriority: Dispatch<SetStateAction<Priority | undefined>>
  defaultValue?: string
  defaultColor?: string | '#fff'
  eneable: boolean
}

const PriorityModule: React.FC<IProps> = ({setPriority, defaultValue, defaultColor, eneable}) => {
  const rootStore = useContext(RootStoreContext)
  const {priorities, isLoadingPriority, loadPriority} = rootStore.priorityStore
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    loadPriority()
  }, [])

  const handleChange = (value: any) => {
    const priority = priorities.find((priority) => {
      return priority.id === value.target.value
    })
    if (priority != null) setPriority(priority)
    setColor(priority?.color ? priority.color : '#fff')
  }
  return (
    <Space>
      {isLoadingPriority ? (
        <div>
          <Spin />
        </div>
      ) : (
        <Form.Select
          aria-label='Default select example'
          style={{
            width: 74,
            background: color,
            borderRadius: '2px',
            color: 'black',
            border: '1px solid #c7c7c7',
            paddingTop: '6px',
            paddingBottom: '6px',
          }}
          onChange={(value) => {
            handleChange(value)
          }}
        >
          <option>{defaultValue}</option>
          {eneable ? (
            <>
              {priorities.map((priority) => (
                <option
                  key={priority.id}
                  style={{background: priority.color ? priority.color : '#fff'}}
                  data-color={priority.color}
                  value={priority.id}
                >
                  {priority.name}
                </option>
              ))}
            </>
          ) : null}
        </Form.Select>
      )}
    </Space>
  )
}

export default observer(PriorityModule)
