import {type ApiCalls, type ApiCallsQueryResponse, type IQueryApiCalls} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const APICALLS_URL = `${API_URL}/apicalls`
const APICALLS_SERVICE_URL = `${process.env.REACT_APP_API_SERVICE_URL}`

const getApiCalls = async (query: IQueryApiCalls): Promise<ApiCalls[]> => {
  return await axios
    .post(`${APICALLS_URL}/all`, {...query})
    .then((d: AxiosResponse<ApiCallsQueryResponse>) => {
      return d.data as ApiCalls[]
    })
}

const verifyPayment = async (apical: ApiCalls): Promise<any> => {
  return await axios
    .post(`${APICALLS_URL}/verify`, {...apical})
    .then((d: AxiosResponse<ApiCallsQueryResponse>) => {
      return d.data
    })
}

const generatePayment = async (apical: ApiCalls): Promise<any> => {
  const data = {
    data: {
      object: apical.data,
    },
  }
  return await axios
    .post(`${APICALLS_SERVICE_URL}/stripe/paymentintent`, data)
    .then((d: AxiosResponse<ApiCallsQueryResponse>) => {
      return d.data
    })
}

const updateApicallIgnore = async (apical: ApiCalls): Promise<any> => {
  return await axios
    .patch(`${APICALLS_URL}/ignore/${apical.id}`, {ignored: apical.ignored})
    .then((d: AxiosResponse<ApiCallsQueryResponse>) => {
      return d.data
    })
}
export {getApiCalls, verifyPayment, generatePayment, updateApicallIgnore}
