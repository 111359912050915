/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import {Alert, Button, H1, H3, H4, Input, Link, Success} from '../../components'
import {useContext, useState} from 'react'
import ContainerLeft from './ContainerLeft'
import {ErrorParser} from '../../core/error/utils/ErrorParser'
import {RootStoreContext} from '../../stores/rootStore'
import clsx from 'clsx'
import {useFormik} from 'formik'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const rootStore = useContext(RootStoreContext)
  const {requestPasswordReset} = rootStore.authStore

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPasswordReset(values.email)
        .then(({data: {result}}) => {
          setHasErrors(false)
          setLoading(false)
        })
        .catch((error) => {
          setError(ErrorParser(`${error.response.data.errors}`))
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
      setTimeout(() => {
        // requestPassword(values.email)
        //   .then(({data: {result}}) => {
        //     setHasErrors(false)
        //     setLoading(false)
        //   })
        //   .catch(() => {
        //     setHasErrors(true)
        //     setLoading(false)
        //     setSubmitting(false)
        //     setStatus('The login detail is incorrect')
        //   })
      }, 1000)
    },
  })

  return (
    <>
      <div className=' flex-wrap overflow-hidden table '>
        <div className='w-full h-screen overflow-hidden lg:w-1/2 xl:w-1/2 px-4 mx-auto my-auto table-cell align-middle'>
          <div className='-mt-32'>
            <header className='h-32 text-center w-48 justify-center items-center lg:left-0 m-auto lg:w-32 xl:w-32'>
              <img
                src={'/media/newdesign/07-logo-horizontal-color.svg'}
                alt='immiland'
                className=''
              />
            </header>
          </div>
          <div className=' align-content-center lg:px-20 xl:px-32 '>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={formik.handleSubmit}
            >
              <div className=''>
                {/* begin::Title */}
                <H1 className=''>Forgot Password?</H1>
                {/* end::Title */}

                {/* begin::Link */}
                <H4>Enter your email to reset your password.</H4>
                {/* end::Link */}
              </div>

              {/* begin::Title */}

              {hasErrors === false ? (
                <>
                  <Success>
                    Se ha enviado un correo a {formik.values.email} con un enlace para restablecer
                    su contraseña.
                  </Success>
                </>
              ) : (
                <>
                  <div>
                    <H3>Email</H3>
                    <Input
                      type='email'
                      placeholder='Email'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        '',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Alert>
                        <span role='alert'>{formik.errors.email}</span>
                      </Alert>
                    )}
                    {hasErrors === true && <Alert>{error}</Alert>}
                  </div>
                  <div className='my-3 justify-center m-auto'>
                    {loading ? (
                      <>
                        <span className='my-2 '>
                          Por favor espere...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </>
                    ) : (
                      <Button type='submit' id='kt_password_reset_submit'>
                        <span className=''>Enviar</span>
                        {loading && (
                          <span className='my-2 '>
                            Porfavor espere...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </Button>
                    )}
                  </div>
                </>
              )}

              <div className='my-2'>
                <Link to='/auth/login'>
                  <p>Regresar</p>
                </Link>
              </div>
            </form>
          </div>
        </div>

        <div className='w-full h-0 lg:h-screen xl:h-screen overflow-hidden bg-gray-100 lg:w-1/2 xl:w-1/2 p-0 lg:p-10 xl:p-10 table-cell align-middle'>
          <ContainerLeft />
        </div>
      </div>
    </>
  )
}
