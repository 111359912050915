 
import IntakeProductCard from './IntakeProductCard'
interface props {
  productId:string
  }

const IntakeTranslationRoute : React.FC<props> = ({productId}) => {
  return (
    <IntakeProductCard productId={productId} />
  )
}

export default IntakeTranslationRoute
