/* eslint-disable @typescript-eslint/no-unused-vars */
 

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useState, useEffect, useContext } from 'react'
import { CountrySelect } from '../../../../../../app/modules/utils/components/CountrySelect'
import { type ProfileImmigration } from '../../../../../../app/modules/apps/profile/core/_models'
import { isEmpty } from 'lodash'
import { Progress } from 'antd'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import 'antd/dist/antd.css'
import './style.css'

import { updateProfileNew } from 'app/modules/apps/profile/core/_requests'
import Swal from 'sweetalert2'
import { RootStoreContext } from 'stores/rootStore'
import { Users } from 'app/modules/apps/users/core/_models'
const profileDetailsSchema = Yup.object().shape({
  occupacion: Yup.string().required('Occupation is required'),
  area_de_estudios: Yup.string().required('Study is required'),
  proeficiencia_en_frances: Yup.string().required('French proficiency is required'),
  proeficiencia_en_ingles: Yup.string().required('English proficiency is required'),
  historial_laboral: Yup.string().required('Labour history is required'),
})

interface Props {
  updateProfile: (ProfileImmigration: ProfileImmigration) => void
  ProfileImmigration: ProfileImmigration
  isUserLoading?: boolean
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
  /*  onComplete: (survey: any) => void */
  data: any
  userId: string | undefined
  setRefetchActivator: Dispatch<SetStateAction<boolean>>
  currentUser: Users
}

const InmiDetailsNew: React.FC<Props> = ({
  edit = true,
  setTabActive,
  data,
  setRefetchActivator,
  userId,
  currentUser,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { selectUser } = rootStore.usersStore
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [continueButtonColor, setContinueButtonColor] = useState('gray')
  const initialFormValues = {
    /* datos laborales */
    occupacion: '',
    area_de_estudios: '',
    proeficiencia_en_ingles: '',
    proeficiencia_en_frances: '',
    historial_laboral: '',
    plicaciones_previas: '',
    tipo_aplicacion: '',
    rechazos_visa: '',

  }

  const [formValues, setFormValues] = useState<ProfileImmigration | null>(data.profile || initialFormValues)

  const formikSecond = useFormik<ProfileImmigration>({
    initialValues: isEmpty(formValues) ? initialFormValues : formValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async () => {
      setLoading(true)
      setLoading(false)
    },
  })

  const formData = {
    /* Datos laborales */
    occupacion: formikSecond.values.occupacion,
    area_de_estudios: formikSecond.values.area_de_estudios,
    proeficiencia_en_ingles: formikSecond.values.proeficiencia_en_ingles,
    proeficiencia_en_frances: formikSecond.values.proeficiencia_en_frances,
    historial_laboral: formikSecond.values.historial_laboral,
    plicaciones_previas: formikSecond.values.plicaciones_previas,
    tipo_aplicacion: formikSecond.values.tipo_aplicacion,
    rechazos_visa: formikSecond.values.rechazos_visa,

  }
  const [progreso, setProgreso] = useState(0.9494949)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    /* Datos laborales */
    formData.occupacion = formikSecond.values.occupacion
    formData.area_de_estudios = formikSecond.values.area_de_estudios
    formData.proeficiencia_en_ingles = formikSecond.values.proeficiencia_en_ingles
    formData.proeficiencia_en_frances = formikSecond.values.proeficiencia_en_frances
    formData.historial_laboral = formikSecond.values.historial_laboral
    formData.plicaciones_previas = formikSecond.values.plicaciones_previas
    formData.tipo_aplicacion = formikSecond.values.tipo_aplicacion
    formData.rechazos_visa = formikSecond.values.rechazos_visa

    try {
      await formikSecond.validateForm()
      await formikSecond.handleSubmit()
      await profileDetailsSchema.validate(formikSecond.values, { abortEarly: false })
      await updateProfileNew({
        profile: formikSecond.values,
        userId: userId,
      })

      const nuevoProgreso = calcularProgreso(formikSecond.values)
      setProgreso(nuevoProgreso)
      setFormValues(formikSecond.values)
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
      setRefetchActivator(true)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        console.error('Error de validación:', error.errors)
        Swal.fire({
          title: 'Error',
          text: 'No puede continuar porque hay campos vacíos en el formulario',
          icon: 'error',
          confirmButtonText: 'Continuar',
        })
      } else {
        console.error('Error al actualizar el perfil:', error)
      }
    }
  }

  const handleSelectUser = (user: Users) => {
    selectUser(user)
  }
  // Actualizar los valores iniciales del formulario cuando data.profile cambie
  useEffect(() => {
    if (!isEmpty(data.profile)) {
      setFormValues(data.profile)
    }
  }, [data.profile])

  useEffect(() => {
    const nuevoProgreso = calcularProgreso(formikSecond.values)
    setProgreso(nuevoProgreso)
  }, [formikSecond.values])

  const calcularProgreso = (values: any) => {
    const preguntas = [
      'occupacion',
      'grado_academico',
      'area_de_estudios',
      'proeficiencia_en_ingles',
      'proeficiencia_en_frances',
      'historial_laboral',
    ]

    const preguntasRespondidas = preguntas.filter((pregunta) => values[pregunta] !== '').length

    const nuevoProgreso = (preguntasRespondidas / preguntas.length) * 100

    return nuevoProgreso
  }
  const progresoEntero = Math.floor(progreso)
  return (
    <div>
      <div id='kt_account_profile_details'>
        {/* <p
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '1rem',
            textAlign: 'center',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          Por favor llene los campos correspondientes para poder comenzar su proceso migratorio
        </p>
        <div className='progress-bar-container' style={{ padding: '0rem 5rem 0rem 5rem' }}>
          <Progress
            percent={progresoEntero} // Usar el valor entero
            status={progresoEntero === 100 ? 'success' : 'active'}
            showInfo={true}
          />
        </div> */}
        <form
          onSubmit={handleSubmit}
          noValidate
          className='form'
          style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}
        >
          <div className='card-body p-9 bg-white'>
            <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
              INFORMACIÓN LABORAL
            </H3>
            <div className='container-form-modal border-black'
            // style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: '1px solid black', borderRadius: '10px', paddingTop: '1rem', marginBottom: '1rem' }}
            >
              <div className='column mb-6 '>
                <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                  Ocupación
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    className='custom-input-form'
                    type='text'
                    placeholder='Ocupación'
                    {...formikSecond.getFieldProps('occupacion')}
                    value={formikSecond.values.occupacion}
                  />
                  {formikSecond.touched.occupacion && formikSecond.errors.occupacion && (
                    <Alert variant='danger'>{formikSecond.errors.occupacion}</Alert>
                  )}
                </div>
              </div>
              <div className='column mb-6'>
                <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                  Área de estudio
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    className='custom-input-form'
                    type='text'
                    placeholder='Área de estudio'
                    {...formikSecond.getFieldProps('area_de_estudios')}
                    value={formikSecond.values.area_de_estudios || ''}
                  />
                  {formikSecond.touched.area_de_estudios &&
                    formikSecond.errors.area_de_estudios && (
                      <Alert variant='danger'>{formikSecond.errors.area_de_estudios}</Alert>
                    )}
                </div>
              </div>
              <div className='column mb-6'>
                <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                  Historial laboral más reciente (Max. 200 Caracteres)
                </H3>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    maxLength={200}
                    className='custom-input-form'
                    style={{height:'8rem'}}
                    placeholder='Historial laboral'
                    {...formikSecond.getFieldProps('historial_laboral')}
                    defaultValue={formikSecond.values.historial_laboral}
                  ></textarea>
                  {formikSecond.touched.historial_laboral &&
                    formikSecond.errors.historial_laboral && (
                      <Alert variant='danger'>{formikSecond.errors.historial_laboral}</Alert>
                    )}
                </div>
              </div>
              <div className='column mb-6'>
                <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                  Proficiencia en inglés
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    className='custom-input-select'
                    {...formikSecond.getFieldProps('proeficiencia_en_ingles')}
                    value={formikSecond.values.proeficiencia_en_ingles}
                  >
                    <option value=''>{intl.formatMessage({ id: 'PROFILE.ENGLISHPROF' })}...</option>
                    <option value='Nulo'>Nulo</option>
                    <option value='Basico'>Básico</option>
                    <option value='Intermedio'>Intermedio</option>
                    <option value='Avanzado'>Avanzado</option>
                  </Select>
                  {formikSecond.touched.proeficiencia_en_ingles &&
                    formikSecond.errors.proeficiencia_en_ingles && (
                      <Alert>{formikSecond.errors.proeficiencia_en_ingles}</Alert>
                    )}
                </div>
              </div>
              <div className='column mb-6'>
                <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                  Proficiencia en frances
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    className='custom-input-select'
                    {...formikSecond.getFieldProps('proeficiencia_en_frances')}
                    value={formikSecond.values.proeficiencia_en_frances}
                  >
                    <option value=''>{intl.formatMessage({ id: 'PROFILE.FRENCHPROF' })}...</option>
                    <option value='Nulo'>Nulo</option>
                    <option value='Basico'>Básico</option>
                    <option value='Intermedio'>Intermedio</option>
                    <option value='Avanzado'>Avanzado</option>
                  </Select>
                  {formikSecond.touched.proeficiencia_en_frances &&
                    formikSecond.errors.proeficiencia_en_frances && (
                      <Alert>{formikSecond.errors.proeficiencia_en_frances}</Alert>
                    )}
                </div>
              </div>
              <div className='column mb-6'>
                <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                  ¿Ha realizado aplicaciones previas a Canadá en el pasado?
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    {...formikSecond.getFieldProps('plicaciones_previas')}
                    className='custom-input-select'
                  >
                    <option value=''>
                      {intl.formatMessage({ id: 'PROFILE.CANADAAPLICATION' })}...
                    </option>
                    <option value='Si'>Si</option>
                    <option value='No'>No</option>
                  </Select>
                  {formikSecond.touched.plicaciones_previas &&
                    formikSecond.errors.plicaciones_previas && (
                      <Alert>{formikSecond.errors.plicaciones_previas}</Alert>
                    )}
                </div>
              </div>
              {formikSecond.values.plicaciones_previas === 'Si' && (
                <>
                  <div className='column mb-6'>
                    <H3 className='col-lg-12 col-form-label fw-bold fs-6'>
                      ¿Qué tipo de aplicación realizó?
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Input
                        className='custom-input-form'
                        type='text'
                        placeholder='¿Qué tipo de aplicación realizó?'
                        {...formikSecond.getFieldProps('tipo_aplicacion')}
                        value={formikSecond.values.tipo_aplicacion}
                      />
                      {formikSecond.touched.tipo_aplicacion &&
                        formikSecond.errors.tipo_aplicacion && (
                          <Alert variant='danger'>{formikSecond.errors.tipo_aplicacion}</Alert>
                        )}
                    </div>
                  </div>
                  <div className='column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      ¿Ha tenido rechazos?
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input-select'
                        {...formikSecond.getFieldProps('rechazos_visa')}
                        value={formikSecond.values.rechazos_visa || ''}
                      >
                        <option value=''>{intl.formatMessage({ id: 'PROFILE.REJECTED' })}...</option>
                        <option value='Si'>Si</option>
                        <option value='No'>No</option>
                      </Select>
                      {formikSecond.touched.rechazos_visa && formikSecond.errors.rechazos_visa && (
                        <Alert>{formikSecond.errors.rechazos_visa}</Alert>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {edit && (
              <div className='container-buttons mb-8'>
                <Button
                  className='button-ui button-primary'
                  type='submit'
                  onClick={() => {
                    if (setTabActive != null) {
                      setTabActive('homenew2')
                      handleSelectUser(currentUser)
                    }
                  }}
                 
                >
                  Guardar datos
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default observer(InmiDetailsNew)
