 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {LikeOutlined, LinkOutlined, SearchOutlined, UserAddOutlined} from '@ant-design/icons'
import {Button, DatePicker, Input, InputRef, Space, Switch, Table} from 'antd'
import {
  type IQueryParams,
  type Translations,
  type TranslationsPartial,
} from '../../../../app/modules/apps/translations/core/_models'
import React, {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import {AiOutlineMail} from 'react-icons/ai'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {IReply, type IComposeData} from '../../../../app/modules/apps/appointments/core/_models'
import ModalLoader from '../../../../core/components/ModalLoader/index'
import {type Priority} from '../../../../app/modules/apps/priority/core/_models'
import PriorityModule from '../../../../app/pages/priority/PriorityModule'
import {RootStoreContext} from '../../../../stores/rootStore'
import {type StatusTag} from '../../../../app/modules/apps/statusTag/core/_models'
import StatusTagModule from '../../../../app/pages/statusTag/StatusTagModule'
import Swal from 'sweetalert2'
import {TimeZone} from '../../../../app/modules/utils/components/TimeZone'
import moment, {Moment} from 'moment'
import {numberCurrencyConverter} from '../../../../core/utils/numberCurrencyConverter'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {saveAs} from 'file-saver'
import './style.css'
import Column from 'antd/lib/table/Column'
import {ColumnFilterItem, ColumnType} from 'antd/lib/table/interface'
import {useQuery} from 'react-query'
import {getStatusTag} from 'app/modules/apps/statusTag/core/_requests'
import Highlighter from 'react-highlight-words'
import {getProductsTableFilter} from 'app/modules/apps/cart/core/products/_requests'
import {getAllStaff} from 'app/modules/apps/staff/core/_requests'
import {RangeValue} from 'rc-picker/lib/interface'
import {sendEmailBlueTemplateExtra, sendEmailWithLink} from 'app/modules/apps/users/core/_requests'
import ManualTranslationModal from './ManualTranslationModal'
import { Users } from 'app/modules/apps/users/core/_models'
import image from './image/avatar.svg'
import notes from '../admin/image/notes.svg'
import NotesModalTranslation from 'app/pages/admin/users/List/SlideTranslations'
const querysParam: IQueryParams = {
  page: 1,
  limit: 50,
}

interface IProps {
  setAssign: Dispatch<SetStateAction<boolean>>
  setTranslation: Dispatch<SetStateAction<Translations>>
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>
  setComposePost: React.Dispatch<React.SetStateAction<boolean>>
  setTabNumber: Dispatch<SetStateAction<string>>
}

const ListTranslations: React.FC<IProps> = ({
  setAssign,
  setTranslation,
  setComposePost,
  setComposePostData,
  setTabNumber,
}) => {
  const [loading, setLoading] = useState(false)
  const exportToCSV = () => {
    setLoading(true)

    // Crea el contenido del archivo CSV
    const csvContent = [
      'Perfil,Clientes,Email,ProductName,State,Translator,Priority,Expiration,Created,Delivered,Express,UploadExtras,NetsuiteStatus,Pages,Total,TranslatedPages',
      ...translations.map((record) =>
        [
          record.userName,
          record.email,
          record.productName,
          record.status,
          record.staffName,
          record.priority,
          record.endDue,
          record.created_at,
          record.deliverDate,
          record.express ? 'Si' : 'No',
          record.uploadExtra ? 'Si' : 'No',
          record.netSuiteStatus ? 'Si' : 'No',
          record.pages || '',
          record.totalpayment || '',
          record.pagesTranslated || '',
        ].join(',')
      ),
    ].join('\n')

    // Crea un objaeto Blob con el contenido CSV
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})

    // Guarda el archivo CSV usando FileSaver.js
    saveAs(blob, 'translations.csv')

    setLoading(false)
  }
  const API_URL = process.env.REACT_APP_API_URL
  const USERS_URL = `immiland.app`
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {
    translations,
    translationsTotal,
    isLoadingTranslation,
    loadTranslations,
    updateFieldTranslation,
  } = rootStore.translationStore

  const {data: dataStatus} = useQuery('gettingStatus', async () => await getStatusTag())

  const {data: staffData} = useQuery('getStf', async () => await getAllStaff())

  const {data: dataProducts} = useQuery('getProducts', async () => await getProductsTableFilter())

  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof Translations
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [priority, setPriority] = React.useState<Priority>()
  const [translatorInfo, setTranslatorInfo] = useState<ColumnFilterItem[]>()
  const [productNameInfo, setProductNameInfo] = useState<ColumnFilterItem[]>()
  const [statusInfo, setStatusInfo] = useState<ColumnFilterItem[]>()
  const [staffInfo, setStaffInfo] = useState<ColumnFilterItem[]>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentStatus, setCurrentStatus] = useState<number[]>()
  const [currentStaffName, setCurrentStaffName] = useState<string[]>()
  const [currentProductName, setCurrentProductName] = useState<string[]>()
  const [fromDateEndDue, setFromDateEndDue] = useState<moment.Moment | null>(null)
  const [toDateEndDue, setToDateEndDue] = useState<moment.Moment | null>(null)
  const [fromDateCreation, setFromDateCreation] = useState<moment.Moment | null>(null)
  const [toDateCreation, setToDateCreation] = useState<moment.Moment | null>(null)
  const [fromDateDelivered, setFromDateDelivered] = useState<moment.Moment | null>(null)
  const [toDateDelivered, setToDateDelivered] = useState<moment.Moment | null>(null)
  const [currentDateDelivered, setCurrentDateDelivered] = useState<Moment[] | null[]>()
  const [currentCreation, setCurrentCreation] = useState<Moment[] | null[]>()
  const [currentendDueDate, setCurrentEndDueDate] = useState<Moment[] | null[]>()
  const [translatedPagesChange, setTranslatedPagesChange] = useState<string>('')
  const [note, setNote] = useState<string>('')
  const [edit, setEdit] = useState<boolean>(false)
  const [idSelected, setIdSelected] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisible2, setIsModalVisible2] = useState(false)
  const [userInfo, setUserInfo] = useState<any>()
  const [translationId, setTranslationId] = useState<string>('');

  const showModal = () => {
    setIsModalVisible(true)
  }
  const showModal2 = () => {
    setIsModalVisible2(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleCancel2 = () => {
    setIsModalVisible2(false)
  }
  const handleCreate = (values: any) => {
    // Handle the values from the form here
    console.log('Received values:', values)
    setIsModalVisible(false)
  }
  const handleCreate2 = (values: any) => {
    // Handle the values from the form here
    console.log('Received values:', values)
    setIsModalVisible2(false)
  }
  useEffect(() => {
    console.log('currentStatusEffect' + currentStatus)
    loadTranslations({
      page: currentPage,
      limit: 50,
      status: currentStatus,
      email: currentEmail,
      productName: currentProductName,
      staffName: currentStaffName,
      endDate: currentendDueDate,
      creationDate: currentCreation,
      deliveredDate: currentDateDelivered,
    })
  }, [
    currentPage,
    currentStatus,
    currentEmail,
    currentProductName,
    currentStaffName,
    currentendDueDate,
    currentCreation,
    currentDateDelivered,
    loadTranslations,
  ])

  const handleSearchCustom = (type: string, value: string) => {
    setCurrentPage(1)
    if (type === 'email') {
      setCurrentEmail(value)
    }
  }

  useEffect(() => {
    setStatusInfo(
      dataStatus?.map((item: any) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
    )

    setTranslatorInfo(
      translations?.map((item: any) => {
        if (item.staffName !== undefined) {
          return {
            text: item.staffName,
            value: item.staffName,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )

    setStaffInfo(
      staffData?.map((item: any) => {
        if (
          item.jotformName !== undefined &&
          (item.type === 'translate_admin' ||
            item.type === 'translate' ||
            item.type === 'translation_admin' ||
            item.type === 'translation'
            )
        ) {
          return {
            text: item.jotformName,
            value: item.jotformName,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )

    setProductNameInfo(
      dataProducts?.data.map((item: any) => {
        if (item.name !== undefined && item.name.toLowerCase().includes('traduccion')) {
          return {
            text: item.name,
            value: item.name,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )
  }, [translations, staffData, dataStatus, dataProducts?.data])

  const handleAssign = (translation: Translations) => {
    setAssign(true)
    setTranslation(translation)
  }

  const handleEndDue = async (value: any, translationId: string) => {
    try {
      if (value) {
        const endDue = value.format('DD-MM-YYYY')

        const translation: TranslationsPartial = {
          endDue: `${endDue} 23:59:59`,
        }

        const updated = await updateFieldTranslation(translation, translationId)
        if (updated != null) {
          loadTranslations(querysParam)
        }
      }
    } catch (error) {}
  }

  const handleNote = async (value: string, translationId: string) => {
    const translation: TranslationsPartial = {
      note: value,
    }
    await updateFieldTranslation(translation, translationId)
    setEdit(false)
  }
  const handleExpress = async (value: any, translationId: string) => {
    const translation: TranslationsPartial = {
      express: value,
    }

    await updateFieldTranslation(translation, translationId)
  }

  const handleUploadExtra = async (value: any, translationId: string) => {
    const translation: TranslationsPartial = {
      uploadExtra: value,
      uploadExtraDate: new Date(),
    }

    await updateFieldTranslation(translation, translationId)
  }
11
  const handleOpenPost = (data: any) => {
    setComposePost(true)
    setComposePostData(data)
  }

  const handlePagesTranslated = async (value: string, translationId: string) => {
    if (parseInt(value) > 0) {
      const translation: TranslationsPartial = {
        pagesTranslated: parseInt(value),
      }
      await updateFieldTranslation(translation, translationId)
    }
  }

  const handleNetsuiteStatus = async (value: any, translationId: string) => {
    const translation: TranslationsPartial = {
      netSuiteStatus: value,
    }

    await updateFieldTranslation(translation, translationId)
  }

  const handleChangeStatusTag = async (statusTag: StatusTag, id: string, record: any) => {
    console.log('Cambiando tag - id:', id);
    console.log('Cambiando tag - statusTag:', statusTag);
    console.log('Cambiando tag');
    console.log('Cambiando tag - record:', record);

    // Resto de la lógica...

    const translation: TranslationsPartial = {
      statusTag: { id: statusTag.id },
      isFinished: statusTag.id === '5',
    };

    const updated = updateFieldTranslation(translation, id);
    if (!updated) {
      Swal.fire('Error', 'Error updating translation', 'error');
    }


    if (statusTag.id === 'Completed') {
      const record = translations.find((record) => record.id === id);
      if (record) {
        await handleSendFeedbackEmail(record);
      }
    }
  };

  if (isLoadingTranslation) {
    return <>Cargando..</>
  }


  const handleSendFeedbackEmail = async (record: any) => {
    console.log('Entrando en handleSendFeedbackEmail - record:', record);

    if (record && record.id) {
      try {
        const link = `${USERS_URL}/services/satisfaction/${record.id}`;
        await sendEmailWithLink(record.email, link);
        console.log('Correo enviado con éxito');
      } catch (error) {
        console.error('Error al enviar el correo:', error);
      }
    } else {
      console.error('Error: Record or record.id is undefined');
    }
  };










  const onChange = async (pagination: any, filters: any) => {
    console.log('currentstatus' + currentStatus)
    console.log('currentstatuslen' + currentStatus?.length)
    console.log('pasginartion crreut' + pagination.current + 'andFilters' + JSON.stringify(filters))
    setCurrentPage(pagination.current)
    console.log('lenght' + currentStatus?.length)
    if (currentStatus?.length === undefined || currentStatus.length === 0) {
      setCurrentStatus(filters.statusTag)
    } else if (currentStatus?.length > 0) {
      setCurrentStatus(currentStatus)
    }

    if (currentProductName?.length === undefined || currentProductName.length === 0) {
      setCurrentProductName(filters.productName)
    } else if (currentProductName?.length > 0) {
      setCurrentProductName(currentProductName)
    }

    if (currentStaffName?.length === undefined || currentStaffName.length === 0) {
      setCurrentStaffName(filters.staffName)
    } else if (currentStaffName?.length > 0) {
      setCurrentProductName(currentProductName)
    }
  }

  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<Translations> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setCurrentEmail('')
            }}
            size='small'
            style={{width: 90}}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              /*  setSearchedColumn(dataIndex) */
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record: Translations) =>
      record
        .email!.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleDateChangeEndDue = (dates: RangeValue<moment.Moment>, type: string) => {
    console.log(dates, type)
    if (dates && type === 'endDue') {
      console.log('Date1' + dates[0])
      setFromDateEndDue(dates[0])
      setToDateEndDue(dates[1])
    }
  }
  const handleDateChangeCreated = (dates: RangeValue<moment.Moment>, type: string) => {
    if (dates && type === 'created') {
      console.log('Date1created' + JSON.stringify(dates))
      setFromDateCreation(dates[0])
      setToDateCreation(dates[1])
    }
  }
  const handleSearchDatePickerEndDue = (
    fromDate: Moment | null,
    toDate: Moment | null,
    type: string
  ) => {
    if (type === 'endDue') {
      console.log('llegue aqui end due', fromDateCreation, toDateCreation)
      setCurrentPage(1)
      setCurrentEndDueDate([fromDateEndDue!, toDateEndDue!])
    }
  }

  const handleSearchDatePickerCreated = (
    fromDate: Moment | null,
    toDate: Moment | null,
    type: string
  ) => {
    if (type === 'created') {
      console.log('llegue aqui created', fromDateCreation, toDateCreation)
      setCurrentPage(1)
      setCurrentCreation([fromDateCreation!, toDateCreation!])
    }
  }
  const handleSearchDatePickerDelivered = (
    fromDate: Moment | null,
    toDate: Moment | null,
    type: string
  ) => {
    if (type === 'delivered') {
      setCurrentPage(1)
      setCurrentDateDelivered([fromDateDelivered!, toDateDelivered!])
    }
  }
  const handleDateChangeDelivered = (dates: RangeValue<moment.Moment>, type: string) => {
    if (dates && type === 'delivered') {
      setFromDateDelivered(dates[0])
      setToDateDelivered(dates[1])
    }
  }

  return (
    <>
      {isLoadingTranslation ? (
        <div>
          <ModalLoader />
        </div>
      ) : null}
      <div style={{marginRight:'1rem',  position:'absolute', right:'30px', top:'127px'}} className='CSV-Container'>
        <button className='button-ui button-primary'  onClick={exportToCSV}>
          Exportar a CSV
        </button>
        <button className='button-ui button-primary' onClick={showModal}>
          Traducción Manual
        </button>
        <ManualTranslationModal
          visible={isModalVisible}
          onCancel={handleCancel}
          onCreate={handleCreate}
          products={dataProducts}
        />
      </div>

      {(currentStatus && currentStatus.length > 0) ||
      (currentProductName && currentProductName.length > 0) ||
      (currentStaffName && currentStaffName.length > 0) ? (
        <>
          {' '}
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentStatus && currentStatus.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentStatus([])
                  }}
                >
                  <p>Status</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentStatus.map((item) => {
                    if (item === 1) {
                      return 'Pending assignation'
                    }
                    if (item === 2) {
                      return 'Assigned'
                    }
                    if (item === 3) {
                      return 'In Progress'
                    }
                    if (item === 4) {
                      return 'Stuck'
                    }
                    if (item === 5) {
                      return 'Completed'
                    }
                    if (item === 6) {
                      return 'Waiting for the client'
                    }
                    if (item === 7) {
                      return 'Pending Payment'
                    }
                    if (item === 8) {
                      return 'Pending documents'
                    }
                    if (item === 9) {
                      return 'REFUNDED'
                    }
                    return ''
                  })}
                  readOnly
                ></input>{' '}
                {/* {currentCountryOfOrigin.map((item) => item + " ")} */}
              </div>
            )}

            {currentProductName && currentProductName.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentProductName([])
                  }}
                >
                  <p>Product Name</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentProductName?.toString()}
                  readOnly
                ></input>{' '}
                {/* {currentCountryOfOrigin.map((item) => item + " ")} */}
              </div>
            )}

            {currentStaffName && currentStaffName.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentStaffName([])
                  }}
                >
                  <p>Staff Name</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentStaffName?.toString()}
                  readOnly
                ></input>{' '}
                {/* {currentCountryOfOrigin.map((item) => item + " ")} */}
              </div>
            )}
          </div>
        </>
      ) : null}

      <Table
       className='table-master my-custom-table2'
        style={{overflow: 'auto', position: 'relative'}}
        scroll={{x: 'max-content', y: 500}}
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: translationsTotal || 0,
        }}
        dataSource={translations}
        onChange={onChange}
        rowClassName={() => 'row-hover'}
      >
          <Column
          width={80}
       className='image-avatar'
          key='Avatars'
          render={(record: Users) => (
            <>

         {  record.photoId !== null  ? <img style={{borderRadius:'999px',width:'35px', height:'35px'}} src={`//immilandpublic.nyc3.digitaloceanspaces.com/${record?.photoId}-avatar-large.jpg`}/> : <img style={{borderRadius:'999px',width:'35px', height:'35px'}} src={image}/>  }


            </>
          )}
        />

  {/*       <Column
          width={300}
          title='Nombre'
          render={(record: any) => (
            <Space>
              <p> {record.userName}</p>
            </Space>
          )}
        /> */}
         <Column
          width={200}
          title='CLIENTE'
          dataIndex={'email'}
          key='email'
          {...getColumnSearchPropsEmail('email')}
          render={(email: string, record: any) =>      <div style={{display:'flex', flexDirection:'column', marginBottom:'15px'}}>
            <b style={{margin:'0', height:'1.5rem'}}>{record.userName ?.length > 32 ? <>{record.userName.substring(0, 32)}...</> : <>{record.userName}</>}</b>      <p style={{ margin: '0' }}>{email ?.length > 32 ? <>{email.substring(0, 32)}...</> : <>{email}</>}</p></div>} />


         <Column
          width={200}
          title='TRADUCCIÓN'
          key='productName'
          render={(record: any) => (
            <Space>
              {record.productName}
            </Space>
          )}
          filters={productNameInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record.productName === value.toString()
          }}
        />
 <Column
          width={250}
          title='VENCE EL'
          filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: '30px'}}>
              <DatePicker.RangePicker
                value={[fromDateEndDue, toDateEndDue]}
                onChange={(value) => {
                  handleDateChangeEndDue(value, 'endDue')
                }}
              />
              <div>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleSearchDatePickerEndDue(fromDateEndDue!, toDateEndDue!, 'endDue')
                  }}
                  size='small'
                  style={{width: 90, marginRight: 8}}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDateEndDue(null)
                    setToDateEndDue(null)
                    setCurrentEndDueDate([null, null])
                  }}
                  size='small'
                  style={{width: 90}}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
          render={(record: any) => (
            <Space
              style={{
                width: '150px',
              }}
            >
              <DatePicker
                style={{width: '10rem'}}
                onChange={async (date: any) => {
                  await handleEndDue(date, record.id ? record.id : '')
                }}
                value={record.endDue ? moment(record.endDue).utc() : null}
              />
            </Space>
          )}
        />

        <Column
          width={200}
          title='CREADO EL'
          filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
              <DatePicker.RangePicker
                value={[fromDateCreation, toDateCreation]}
                onChange={(value) => {
                  handleDateChangeCreated(value, 'created')
                }}
              />
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleSearchDatePickerCreated(fromDateCreation!, toDateCreation!, 'created')
                  }}
                  size='small'  
                  style={{width: 90, marginRight: 8}}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDateCreation(null)
                    setToDateCreation(null)
                    setCurrentCreation([null, null])
                  }}
                  size='small'
                  style={{width: 90}}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
          render={(record: any) => (
            <Space>
              <p style={{fontSize: '14px', paddingTop: '8px'}}>
                {' '}
                {record.created_at
                  ? new Date(record.created_at)
                      .toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                      .replace(/\//g, '-')
                  : null}
              </p>
            </Space>
          )}
        />
     <Column
          width={160}
          title='ENTREGADO EL'
          filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
              <DatePicker.RangePicker
                value={[fromDateDelivered, toDateDelivered]}
                onChange={(value) => {
                  handleDateChangeDelivered(value, 'delivered')
                }}
              />
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleSearchDatePickerDelivered(
                      fromDateDelivered!,
                      toDateDelivered!,
                      'delivered'
                    )
                  }}
                  size='small'
                  style={{width: 90, marginRight: 8}}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDateDelivered(null)
                    setToDateDelivered(null)
                    setCurrentDateDelivered([null, null])
                  }}
                  size='small'
                  style={{width: 90}}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
          render={(record: any) => (
            <Space>
              <p>
                {' '}
                <TimeZone date={record.deliverDate ? record.deliverDate : null} />
              </p>
            </Space>
          )}
        />
         <Column
          width={'4%'}
          title={'PAGINAS'}
          render={(record: any) => (
            <Space style={{color: '#bf3c3c', fontWeight: 'bold', paddingLeft:'23px'}}>
              {record.pages ? record.pages.toString() : '0'}
            </Space>
          )}
        />
            <Column
          width={'10%'}
          title={'PAGINAS TRADUCIDAS'}
          render={(record: any) => (
            <Space /* style={{ paddingTop: '10px' }} */>
              <div style={{color: '#bf3c3c', fontWeight: 'bold'}}>{record.pagesTranslated} </div>
              <div style={{width: '60px'}}>
                <Input
                  onChange={async (e) => {
                    setTranslatedPagesChange(e.target.value)
                  }}
                  placeholder='Type New Value...'
                  type='number'
                />
              </div>
              <Button
                onClick={async (e) => {
                  await handlePagesTranslated(translatedPagesChange, record.id ? record.id : '')
                }}
              >
                Click
              </Button>
            </Space>
          )}
        />
        <Column
          filters={statusInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          title={'ESTADO'}
          key='statusTag'
          render={(record: any) => (
            <Space
              style={{
                width: '150px',
              }}
            >
 <StatusTagModule
                setStatusTag={(statusTag: StatusTag) => {
                  handleChangeStatusTag(statusTag, record.id , record || '')
                } }
                defaultValue={record.status ? record.status : ''}
                defaultColor={record.statusColor ? record.statusColor : ''}
                eneable={true} handleSendFeedbackEmail={handleSendFeedbackEmail}    record={record}       />
        </Space>
      )}
    />

        <Column
     title={'TRADUCTOR'}
          render={(record: any) => (
            <Space>
              {record.staffName ? (
                <>
                  <Button
                    onClick={() => {
                      handleAssign(record)
                    }}
                    style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                  >
                    <AvatarProfileUsers
                      image={
                        record.photoIdStaff
                          ? `${record.photoPathStaff}/${record.photoIdStaff}-avatar-small.jpg`
                          : ''
                      }
                      size={25}
                    />
                  </Button>
                </>
              ) : (
                <div>
                  <Button
                    onClick={() => {
                      handleAssign(record)
                    }}
                    style={{
                      fontSize: '12px',
                      backgroundColor: 'white',
                      border: '1px solid #bf3c3c',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <UserAddOutlined title='Asignar' />
                    <p
                      style={{
                        color: 'black',
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginLeft: '3px',
                      }}
                    >
                      Asignar
                    </p>
                  </Button>
                </div>
              )}
            </Space>
          )}
        />
        <Column
           title='NOMBRE'
          key='staffName'
          filters={staffInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record.staffName === value.toString()
          }}
          render={(record: Translations) => (
            <Space
              style={{
                width: '150px',
              }}
            >
              {record.staffName ? (
                <>
                  <span>{record.staffName}</span>
                </>
              ) : null}
            </Space>
          )}
        />

        <Column
          render={(record: any) => (
            <Space style={{display: 'flex', padding: '30px'}}>
              <Button
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #bf3c3c',
                  width: 'fit-content',
                }}
                onClick={() => {
                  setTranslation(record)
                }}
                className='text-primary'
              >
                {record.filesUploaded ? (
                  <div style={{backgroundColor: 'white'}}>
                    <LinkOutlined style={{marginTop: '1px' , marginRight:'-30px'}} />
                    Archivos
                  </div>
                ) : null}
              </Button>
            </Space>
          )}
        />

        <Column
        width={50}
        title='PRIORIDAD'
          render={(record: any) => (
            <Space style={{padding: '10px'}}>
              <PriorityModule
                setPriority={setPriority}
                defaultValue={record.priority ? record.priority : ''}
                defaultColor={record.priorityColor ? record.priorityColor : ''}
                eneable={true}
              />
            </Space>
          )}
        />




        <Column
          width={90}
          title='EXPRESS'
          render={(record: any) => (
            <Space>
              <Switch
                checked={record.express}
                onChange={async (value: any) => {
                  await handleExpress(value, record.id ? record.id : '')
                }}
              />
              {/*  <>  {record.express.toString()}</> */}
            </Space>
          )}
        />

        <Column
          width={50}
          title='EXTRA'
          render={(record: any) => (
            <Space>
              <Switch
                checked={record.uploadExtra}
                onChange={async (value: any) => {
                  await handleUploadExtra(value, record.id ? record.id : '')
                }}
              />
              {/*  <>  {record.uploadExtra.toString()}</> */}
            </Space>
          )}
        />
        <Column
          render={(record: any) => (
            <Space style={{padding: '10px'}}>
              {record.uploadExtra ? (
                <>
                  {record.staffName ? (
                    <Button
                      style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                      onClick={() => sendEmailBlueTemplateExtra(record.staffName, record.email)}
                      className='text-primary'
                    >
                      <LinkOutlined />
                      &nbsp;record credito
                    </Button>
                  ) : (
                    <p>Debe asignar un consultor antes de enviar el correo.</p>
                  )}
                </>
              ) : null}
            </Space>
          )}
        />
        <Column
          width={100}
          title={intl.formatMessage({id: 'TRANSLATION.NETSUITESTATUS'})}
          render={(record: any) => (
            <Space>
              <Switch
                checked={record.netSuiteStatus}
                onChange={(value: any) => {
                  handleNetsuiteStatus(value, record.id ? record.id : '')
                }}
              />
              {/*  <>  {record.netSuiteStatus.toString()}</> */}
            </Space>
          )}
        />

        <Column
          width={100}
          title={'TOTAL'}
          render={(record: any) => (
            <Space>
              {record.currency}
              {numberCurrencyConverter(record.totalpayment || 0, record.currency || 'CAD')}
            </Space>
          )}
        />

  {/*
        <Column
          width={'7%'}
          title={'NOTA'}
          render={(record: any) =>
            edit === false ? (
              <>
                <Space >
                  {record.note && <textarea value={record.note} className='ant-input' readOnly />}
                  <Button
                    onClick={async (e) => {

                      setEdit(true)
                      setIdSelected(record.id)
                    }}
                  >
                    {record.note ? 'Editar' : 'Crear'}
                  </Button>
                </Space>
              </>
            ) : (
              <>
                {record.id === idSelected && (
                  <Space >
                    <div style={{width: '150px'}}>
                      <textarea
                        style={{color: 'black'}}
                        defaultValue={record.note ? record.note : ''}
                        className='ant-input'
                        onChange={async (e) => {
                          setNote(e.target.value)
                        }}
                      />
                    </div>
                    <Button
                      onClick={async (e) => {
                        await handleNote(note, record.id ? record.id : '')
                      }}
                    >
                      Guardar
                    </Button>
                  </Space>
                )}
              </>
            )
          }
        /> */}
<Column
          width={50}
          title='NOTAS'
          render={(record: any) => (
            <button
            onClick={(event) => {
              // Este es el clic del botón interno
              event.stopPropagation(); // Evita la propagación del evento al botón externo
              setIsModalVisible2(true);
              setUserInfo(JSON.parse(JSON.stringify(record.userId)));
              setTranslationId(record.id); 
            }}
          >
            <img src={notes} alt="Notas" />
          </button>
          )}
        />

        <Column
           width={70}
          render={(record: any) => (
            <Space>
              <Button
                style={{background: 'white', border: '1px solid #FEA102'}}
                onClick={() => {
                  setTabNumber('1')
                  handleOpenPost({
                    orderDetailId: record.orderDetailId ? record.orderDetailId : '',
                    user: `${record.userName} `,
                    email: record.email ? record.email : '',
                    userId: record.userId ? record.userId : '',
                    fromId: record.userTranslationId ? record.userTranslationId : '',
                    messageId: null,
                    fromUser: record.userTranslationId ? record.userTranslationId : '',
                    folder: 'inbox',
                    draft: false,
                    favorite: false,
                    files_info: [],
                    unread: false,
                  })
                }}
              >
                <AiOutlineMail />
              </Button>
            </Space>
          )}
        />
      </Table>

      {isModalVisible2 === true ? (
  <NotesModalTranslation
    userInfo={userInfo}
    visible={isModalVisible2}
    onCancel={handleCancel2}
    onCreate={handleCreate2}
    products={[]}
    translationId={translationId} // Pasando el translationId como prop
  />
) : null}
    </>
  )
}

export default observer(ListTranslations)
