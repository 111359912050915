import React from 'react'
import './consultorselector.css'
import {useNavigate} from 'react-router-dom'
import {ArrowLeftOutlined} from '@ant-design/icons'

const EduOptions: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h1 style={{textAlign: 'center', fontSize: '2rem', paddingTop: '2rem'}}>
        FORMULARIO OPCIONES EDUCATIVAS
      </h1>
      <ArrowLeftOutlined
        className=' p-2 hover:bg-gray-200 hover:rounded-lg'
        onClick={() => navigate(-1)}
      />
      <iframe
        className='iframe-student'
        id='JotFormIFrame-200425683276052'
        title='School Options Clients'
        allow='geolocation; microphone; camera'
        src='https://form.jotform.com/immiland/school-options-clients'
        scrolling='no'
      ></iframe>
    </div>
  )
}

export default EduOptions
