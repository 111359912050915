import {Col, Row, Switch} from 'antd'
import {useEffect, useState} from 'react'
import {getTemplates} from '../../../../app/modules/apps/templates/core/_requests'
import {useQuery} from 'react-query'

interface Props {
  setShared?: React.Dispatch<React.SetStateAction<string[]>> | undefined
}
const AutoShares: React.FC<Props> = (props) => {
  const [listId, setListId] = useState<string[]>([])
  const {data, status} = useQuery(
    'getautoShareTemplates',
    async () => await getTemplates('autoShare=true')
  )
  const {setShared} = props

  useEffect(() => {
    if (status === 'success' && data?.data != null) {
      const newOptions = data.data.map((item: any) => {
        return {
          key: item.id,
          title: item.name,
          description: item.name,
        }
      })
      setListId(newOptions.map((item: {key: any}) => item.key))
      if (setShared != null) setShared(newOptions.map((item: {key: any}) => item.key))
    }
    // eslint-disable-next-line
  }, [data, status])

  const handleChange = (id: string) => {
    let find = false

    listId.forEach((item) => {
      if (item === id) {
        setListId(listId.filter((item) => item !== id))
        if (setShared != null) setShared(listId.filter((item) => item !== id))
        find = true
      }
    })

    if (!find) {
      setListId([...listId, id])
      if (setShared != null) setShared(listId.filter((item) => item !== id))
    }

    // setListId(data?.data?.map((item) => item))
  }

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  if (status === 'success' && data != null) {
    return (
      <>
        <br />
        <span>
          <h3>Plantillas compartidas</h3>
        </span>
        <Row>
          {data.data?.map((item: any) => {
            return (
              <Col span={8} key={item.id}>
                <Switch
                  defaultChecked={true}
                  onChange={() => {
                    handleChange(item.id)
                  }}
                />

                {item.name}
              </Col>
            )
          })}
        </Row>
        <br />
      </>
    )
  } else {
    return <></>
  }
}

export {AutoShares}
