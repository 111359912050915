export const immigration = {
  'IMMIGRATION.SERVICES': 'Immigration services',
  'IMMIGRATION.OPTIONS': 'Immigration options',
  'IMMIGRATION.SERVICESOPTIONS': 'Immigraction options',
  'IMMIGRATION.FUNDS': 'Immigrations funds',
  'IMMIGRATION.ELEMENTS': 'Immigration elements',
  'IMMIGRATION.REQUIREMENTS': 'Immigration requirements',
  'IMMIGRATION.COSTS': 'Immigration costs',
  'IMMIGRATION.LINKS': 'Immigration links',
}
