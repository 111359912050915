import {Container} from '../../../components/Container/index'
import {type FC} from 'react'
import {FileManager} from './FileManager'
import {useIntl} from 'react-intl'

const FileManagerWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.FILEMANAGER'})}>
        <FileManager />
      </Container>
    </>
  )
}

export {FileManagerWrapper}
