/* eslint-disable react-hooks/exhaustive-deps */
import { getContractByUser } from 'app/modules/apps/contract/_requests'
import { observer } from 'mobx-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Graduate from '../NewIntakeView/Icons/GraduateHat.svg'
import UsersIcon from '../NewIntakeView/Icons/Users.svg'
import UsersIcon2 from '../NewIntakeView/Icons/User2.svg'
import Plane from '../NewIntakeView/Icons/air-mail.svg'
import Briefcase from '../NewIntakeView/Icons/briefcase-2.svg'
import House from '../NewIntakeView/Icons/House.svg'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'components'
import { Tooltip } from 'antd'
import { PdfView } from 'app/pages/services/services/formsManagerClient.tsx/pdfView'
import { TableRow2 } from 'components/Tables/TableRow2'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  setMessageId?: React.Dispatch<React.SetStateAction<string>>
  setShowMessage?: React.Dispatch<React.SetStateAction<boolean>>
  userId: string
  userEmail: string
  setActiveKey: Dispatch<SetStateAction<boolean>>
  setContractSelected: Dispatch<SetStateAction<string>>
}

const ConsultantContractsList = (props: IProps) => {
  const { userId } = props
  const { setActiveKey } = props
  const { setContractSelected } = props
  const [countDict, setCountDict] = useState<any>(null)
  const [amendmentOpen, setAmendmentOpen] = useState<any>(false)
  const [envelopeIdSelected, setEnvelopeIdSelected] = useState<string>()
  const [showContract, setShowContract] = useState<boolean>(false)
  const [showSlideOver, setShowSlideOver] = useState<boolean>(false)
  const [isHoveredArray, setIsHoveredArray] = useState<boolean[]>([])
  const [orderedData, setOrderedData] = useState<any>(null)

  const { data: dataFromDatabase, status: statusFromDatabase } = useQuery(
    'allcontrDb',
    async () => await getContractByUser(userId ? userId : '')
  )

  useEffect(() => {
    if (dataFromDatabase && dataFromDatabase.data) {
      // Ordenar dataFromDatabase.data por contracts_users_created_at desde el más reciente al más antiguo
      const sortedData = [...dataFromDatabase.data].sort((a: any, b: any) => {
        // Crear objetos Date para comparar
        const dateA = new Date(a.contracts_users_completedDate)
        const dateB = new Date(b.contracts_users_completedDate)
        // Comparar las fechas: más reciente primero
        return dateB.getTime() - dateA.getTime()
      })
      setOrderedData(sortedData)
      // Usar los datos ordenados para contar los applicants
      setCountDict(countApplicants(sortedData))
    }
  }, [dataFromDatabase, statusFromDatabase])

  useEffect(() => {
    // Inicializar el array de estado isHoveredArray con la misma longitud que dataFromDatabase
    if (statusFromDatabase === 'success' && orderedData) {
      setIsHoveredArray(Array(orderedData.length).fill(false))
    }
  }, [statusFromDatabase])

  /* otros */

  const othersProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713', 'f9fe4a37-7284-4bf7-a22e-f0f12678bb99', '662a5e18-340f-41e6-830a-10576ead3115'
  ]

  const othersProductsMap: { [key: string]: string } = {
    '8dcb5115-bdce-45e3-8b50-d2d592020713': 'Bio',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99': 'F-Bio',
    '662a5e18-340f-41e6-830a-10576ead3115': 'SL',
  }

  /* VISITA */

  const visitProducts = [
    'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const visitProductsMap: { [key: string]: string } = {
    'a10980f2-57f3-43c7-b863-a995915389de': 'VVD',
    '3f056a93-1f4b-4598-9aec-f929ecd00e97': 'eTA',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a': 'Super visa',
    'daa261eb-6263-4b6e-acbb-4343bd4ce35a': 'transit',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9': 'VR',
    'b0e0313b-6344-4733-90a0-6d6031f8be1e': 'VV',
    '41669177-9782-4052-bb8d-08b6c29c928f': 'VV(Study less 6)',
    'b9ada976-a4fe-444d-9f6a-dd58c0928120': 'VR(Dependent)',
  };

  /* ESTUDIO */

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const studyProductsMap: { [key: string]: string } = {
    '94726637-76e7-438b-9494-99b136bbc7ea': 'SPd',
    'bb4056fb-2440-4a06-aec1-8d13349e7427': 'SPd(IC)',
    '8327593a-cc82-4703-afaa-72030f67c001': 'SP',
    'f9b3dcd2-b420-4cc0-b5df-575f9a5be911': 'SDS',
    '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800': 'SP(IC)',
    '32549570-adf0-4add-ad43-5c49fed6566d': 'SP(language)',
    '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c': 'SPd(IC language)'
  };

  /* Residence */

  const residenceProducts = [
    '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', '1ec2dc58-5776-49d3-b487-f373ffd1e0a3',
  ]

  const residenceProductsMap: { [key: string]: string } = {
    '6f317d28-cab2-4316-9a25-3f62e4e047e6': 'Citizenship',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d': 'CSQ',
    '3320f244-f338-43d0-8006-503003d16501': 'CSQ(dependent)',
    'b58daf73-88be-41ee-8a0d-9e485748ced8': 'CSQ(spouse/partner)',
    '7ac7912e-9514-4281-924b-1a36a4dd3065': 'Express Entry(dependent)',
    '9c1f03d1-5100-4a50-8030-cfb8ebad4480': 'Express Entry(AM)',
    '48b466b6-d8e1-48f8-ae32-2877760f92b7': 'Express Entry(EI phase 1)',
    'd1195212-1cdc-4792-b88b-824922f63c36': 'Express Entry(IA phase 2)',
    '7d8df96c-dd71-48aa-b0f7-301e59706212': 'PNE(main applicant phase 1)',
    '03030060-aa44-452a-ad54-80fedf29a547': 'PNP',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d': 'Express Entry(EI Spouse/Partner phase 1)',
    '5848c9c4-bfc9-49b0-881d-9cab020dd1e5': 'Express Entry(IA Spouse/Partner phase 2)',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3': 'TRV',
  };


  /* SECCION DE TRABAJO */

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', '6420335e-30aa-46c5-892b-e1fa23914733',
    '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac', '229d7b91-7a9b-407a-988b-9e45096bff5b',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994', 'c6df5f52-670d-4d7f-809e-72cb59fa684a',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3', 'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]
  const workProductsMap: { [key: string]: string } = {
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61': 'LMIA Exempt',
    '41b1a433-5900-4b96-85f3-7e2c8fce0a41': 'LMIA Exempt(PE)',
    '50eb5404-9ae2-4655-91ae-89d2e8a6fa73': 'LMIA Exempt(Flagpole)',
    '19ca7bf2-6514-478b-bb78-c7861619fe39': 'LMIA Exempt(IC)',
    'e377ed33-a53a-4312-8842-1a689237b07c': 'LMIA Exempt',
    '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b': 'LMIA exempt(PE)',
    'e8e477d1-f126-4a64-93da-539b3715730d': 'LMIA positive',
    '30a323ab-236c-461c-93ea-28308c8fafa6': 'LMIA positive(PE)',
    '08e1143b-4f7f-48db-a289-9a3fc40f8037': 'LMIA positive(flagpole)',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa': 'LMIA positive(IC)',
    'a645705f-f9f7-47c6-9319-5a1026da84d2': 'LMIA positive',
    '6e420420-d96c-4cb8-a145-95a7964ed618': 'LMIA positive(PE)',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095': 'CO-OP',
    '9015fb11-d513-4f6e-9de9-aab92d5d2b42': 'IEC',
    '4f050e6f-e9eb-4ee0-b12c-465d2d715c16': 'IEC',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c': 'LMIA exemption',
    '0dac7746-02c0-47db-be28-14755d62a64d': 'LMIA Federal',
    '6420335e-30aa-46c5-892b-e1fa23914733': 'LMIA Quebec',
    '52096218-da31-4e01-a406-0e06a9ca4d07': 'OWP Vulnerable Worker',
    'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac': 'OWP(MA)',
    '229d7b91-7a9b-407a-988b-9e45096bff5b': 'OWP(Special Mesure Opt-in)',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9': 'PGWP',
    'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994': 'PGWP(PE)',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a': 'PGWP',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3': 'Sponsorship',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4': 'Sponsorship',
    '1d2b991b-7d75-4127-99d8-19f884efcfd0': 'OWP Spouse/Partner',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f': 'OWP Spouse/Partner',
    'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f': 'OWP Spouse/Partner',
    'b6f813c5-0911-4b86-be17-0e74bc332316': 'Spouse/Partner RSWP(Phase 1)'
  };

  const countApplicants = (data: any) => {
    const countDict: Record<string, Set<string>> = {}
    data.forEach((contract: any) => {
      if (contract.contracts_users_applicants) {
        const applicants = JSON.parse(contract.contracts_users_applicants)
        const contractId = contract.contracts_users_envelope_id
        if (!countDict[contractId]) {
          countDict[contractId] = new Set<string>()
        }
        applicants.forEach((applicant: any) => {
          const { memberName, memberType } = applicant
          const key = `${memberName}-${memberType}`
          countDict[contractId].add(key)
        })
      }
    })
    const countResult: Record<string, number> = {}
    for (const [contractId, applicantsSet] of Object.entries(countDict)) {
      countResult[contractId] = applicantsSet.size
    }
    return countResult
  }

  const handleOpenContract = (envelopeId: string) => {
    setEnvelopeIdSelected(envelopeId)
    setShowSlideOver(true)
  }

  return (
    <>
      {showContract ? (
        <>
          <Button
            className='btn  btn-primary'
            style={{
              width: '8rem',
              alignSelf: 'end',
              marginRight: '2rem',
              border: 'none',
              marginBottom: '1rem',
              gap: '0.5rem',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setShowContract(false)
            }}>
            Regresar
          </Button>
          {envelopeIdSelected ? (
            <>
              <PdfView envelopeId={envelopeIdSelected} />
            </>
          ) : null}
        </>
      ) : (
        <div className='main-data-continer'
          style={{
            backgroundColor: 'white',
            margin: '1rem',
            borderRadius: '10px'
          }}>
          <div className='text-container'>
            <div className='onboard-guide-container'>
              <div className='w-full '
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <div style={{ gap: '10px' }} className='side-buttons'>
                  <button className="side-button" onClick={() => { setAmendmentOpen(false) }}>CONTRATOS</button>
                  <button className="side-button" onClick={() => { setAmendmentOpen(true) }}>ENMIENDAS</button>
                </div>
                <div className='contracts-container'>
                  <>
                    {statusFromDatabase === 'success' && amendmentOpen === false ? (
                      orderedData && orderedData.map((item: any, index: number) => (
                        <>
                          {item.contracts_users_match_id &&
                            <div className='button-form2' key={index}>
                              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
                                <div className='contract-continer'>
                                  <p style={{ margin: '0', fontSize: '14px', fontWeight: 'normal', }}>Contrato nº:</p>
                                  <p className='contract-text'>{item.contracts_users_envelope_id}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                                  <p className='contract-text'>Contratado el </p>
                                  <p className='contract-text'>{item.contracts_users_completedDate.substring(0, 10)}</p>
                                </div>
                              </div>
                              <div className='flex flex-wrap gap-2 mt-2 mb-2'>
                                {/* Botón para mostrar el número de aplicantes */}
                                {statusFromDatabase === 'success' &&
                                  countDict !== null &&
                                  countDict[item.contracts_users_envelope_id] && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '3px 5px',
                                        border: '1px solid #DFE4EA',
                                        borderRadius: '3px',
                                        gap: '5px'
                                      }}>
                                      <img src={UsersIcon} alt="Users" />
                                      <p style={{
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        margin: '0'
                                      }}>{countDict[item.contracts_users_envelope_id]} Persona(s)</p>
                                    </div>)}

                                {item.contracts_users_applicants &&
                                  (() => {
                                    const displayedProductIds = new Set();
                                    return JSON.parse(item.contracts_users_applicants)
                                      .filter((applicant: any) => othersProducts.includes(applicant.productId))
                                      .map((applicant: any, index: number) => {
                                        if (displayedProductIds.has(applicant.productId)) {
                                          return null; // No renderizar si el productId ya se ha mostrado
                                        }
                                        displayedProductIds.add(applicant.productId);
                                        return (
                                          <div
                                            key={index} // Añade una key única
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              padding: '3px 5px',
                                              backgroundColor: '#DFE4EA',
                                              borderRadius: '3px',
                                              gap: '5px',
                                            }}>
                                            <img src={UsersIcon2} alt="User" />
                                            <p
                                              style={{
                                                fontSize: '10px',
                                                fontWeight: 'bold',
                                                color: 'black',
                                                margin: '0',
                                              }}>
                                              {othersProductsMap[applicant.productId]} {/* Work */}
                                            </p>
                                          </div>
                                        );
                                      });
                                  })()}

                                {item.contracts_users_applicants &&
                                  (() => {
                                    const displayedProductIds = new Set();
                                    return JSON.parse(item.contracts_users_applicants)
                                      .filter((applicant: any) => workProducts.includes(applicant.productId))
                                      .map((applicant: any, index: number) => {
                                        if (displayedProductIds.has(applicant.productId)) {
                                          return null; // No renderizar si el productId ya se ha mostrado
                                        }
                                        displayedProductIds.add(applicant.productId);
                                        return (
                                          <div
                                            key={index} // Añade una key única
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              padding: '3px 5px',
                                              backgroundColor: '#22AD5C',
                                              borderRadius: '3px',
                                              gap: '5px',
                                            }}>
                                            <img src={Briefcase} alt="Briefcase" />
                                            <p
                                              style={{
                                                fontSize: '10px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                                margin: '0',
                                              }}>
                                              {workProductsMap[applicant.productId]} {/* Work */}
                                            </p>
                                          </div>
                                        );
                                      });
                                  })()}

                                {item.contracts_users_applicants &&
                                  (() => {
                                    const displayedProductIds = new Set();
                                    return JSON.parse(item.contracts_users_applicants)
                                      .filter((applicant: any) => studyProducts.includes(applicant.productId))
                                      .map((applicant: any, index: number) => {
                                        if (displayedProductIds.has(applicant.productId)) {
                                          return null; // No renderizar si el productId ya se ha mostrado
                                        }
                                        displayedProductIds.add(applicant.productId);
                                        return (
                                          <div
                                            key={index} // Añade una key única
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              padding: '3px 5px',
                                              backgroundColor: '#EF5DA8',
                                              borderRadius: '3px',
                                              gap: '5px',
                                            }}>
                                            <img src={Graduate} alt="Briefcase" />
                                            <p
                                              style={{
                                                fontSize: '10px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                                margin: '0',
                                              }}>
                                              {studyProductsMap[applicant.productId]} {/* Work */}
                                            </p>
                                          </div>
                                        );
                                      });
                                  })()}

                                {item.contracts_users_applicants &&
                                  (() => {
                                    const displayedProductIds = new Set();
                                    return JSON.parse(item.contracts_users_applicants)
                                      .filter((applicant: any) => residenceProducts.includes(applicant.productId))
                                      .map((applicant: any, index: number) => {
                                        if (displayedProductIds.has(applicant.productId)) {
                                          return null; // No renderizar si el productId ya se ha mostrado
                                        }
                                        displayedProductIds.add(applicant.productId);
                                        return (
                                          <div
                                            key={index} // Añade una key única
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              padding: '3px 5px',
                                              backgroundColor: '#F27430',
                                              borderRadius: '3px',
                                              gap: '5px',
                                            }}>
                                            <img src={House} alt="Briefcase" />
                                            <p
                                              style={{
                                                fontSize: '10px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                                margin: '0',
                                              }}>
                                              {residenceProductsMap[applicant.productId]} {/* Work */}
                                            </p>
                                          </div>
                                        );
                                      });
                                  })()}

                                {item.contracts_users_applicants &&
                                  (() => {
                                    const displayedProductIds = new Set();
                                    return JSON.parse(item.contracts_users_applicants)
                                      .filter((applicant: any) => visitProducts.includes(applicant.productId))
                                      .map((applicant: any, index: number) => {
                                        if (displayedProductIds.has(applicant.productId)) {
                                          return null; // No renderizar si el productId ya se ha mostrado
                                        }
                                        displayedProductIds.add(applicant.productId);
                                        return (
                                          <div
                                            key={index} // Añade una key única
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              padding: '3px 5px',
                                              backgroundColor: '#01A9DB',
                                              borderRadius: '3px',
                                              gap: '5px',
                                            }}>
                                            <img src={Plane} alt="Briefcase" />
                                            <p
                                              style={{
                                                fontSize: '10px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                                margin: '0',
                                              }}>
                                              {visitProductsMap[applicant.productId]} {/* Work */}
                                            </p>
                                          </div>
                                        );
                                      });
                                  })()}
                              </div>
                              <div className='flex flex-col gap-2 mt-4 mb-4 '>
                                {/* step 1 */}
                                {(item.contracts_users_step === 1 || item.contracts_users_step === null) &&
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number2'>
                                      <p><b>1</b></p>
                                    </div>
                                    <div className='blackpath' />
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <p className='step-text2' style={{ color: 'black' }}>CONTRATO Y RESPUESTAS</p>
                                    </div>
                                  </div>}
                                {(item.contracts_users_step > 1) &&
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-done2'>
                                      <p style={{ fontSize: '1rem', margin: '0', fontWeight: 'lighter' }}><b>1</b></p>
                                    </div>
                                    <div className='greenpath2'/>
                                    <p className='step-text2' style={{ color: '#13C296' }}>CONTRATO Y RESPUESTAS</p>
                                  </div>}
                                {/* step 2 */}
                                {(item.contracts_users_step < 2 || item.contracts_users_step === null) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p ><b>2</b></p>
                                    </div>
                                    <div className='graypath' />
                                    <p className='step-text2' style={{ color: '#d1d5db' }}>FORMULARIOS</p>
                                  </div>)}
                                {(item.contracts_users_step > 2) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-done2'>
                                      <p style={{ fontSize: '1rem', margin: '0', fontWeight: 'lighter' }}><b>2</b></p>
                                      <div className='greenpath2' />
                                    </div>
                                    <p className='step-text2' style={{ color: '#13C296', }}>FORMULARIOS</p>
                                  </div>)}
                                {(item.contracts_users_step === 2) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number2'>
                                      <p><b>2</b></p>
                                    </div>
                                    <div  />
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <p className='step-text2' style={{ color: 'black', fontSize: '0.7rem' }}> FORMULARIOS</p>
                                      <div className='step-badge-active2'>
                                        <p className='step-badge-active-p-ones'>Pendiente</p>
                                      </div>
                                    </div>
                                  </div>)}
                                {/* Step 3 */}
                                {(item.contracts_users_step > 3) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div
                                      className='guide-number-done2'>
                                      <p style={{ fontSize: '1rem', margin: '0', fontWeight: 'lighter' }}><b>3</b></p>
                                    </div>
                                    <div className='greenpath2'/>
                                    <p className='step-text2' style={{ color: '#13C296' }}>DOCUMENTOS</p>
                                  </div>)}
                                {item.contracts_users_step === 3 && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number2'>
                                      <p><b>3</b></p>
                                    </div>
                                    <div/>
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <p className='step-text2' style={{ color: 'black', fontSize: '0.7rem' }}>DOCUMENTOS</p>
                                      <div className='step-badge-active2'>
                                        <p className='step-badge-active-p-ones'>Pendiente</p>
                                      </div>
                                    </div>
                                  </div>)}
                                {(item.contracts_users_step < 3 || item.contracts_users_step === null) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p><b>3</b></p>
                                    </div>
                                    <div className='graypath'/>
                                    <p className='step-text2' style={{ color: '#d1d5db' }}>DOCUMENTOS</p>
                                  </div>)}
                                {/* step 4 */}
                                {item.contracts_users_step >= 5 && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div
                                      className='guide-number-done2'>
                                      <p style={{ fontSize: '1rem', margin: '0', fontWeight: 'lighter' }}><b>4</b></p>
                                    </div>
                                    <div className='flex flex-col'>
                                      <div className='greenpath2'/>
                                      <p className='step-text2' style={{ color: '#13C296', }}>ASIGNACIÓN Y SEGUIMIENTO</p>
                                    </div>
                                  </div>
                                )}
                                {(item.contracts_users_step === 4 && item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number2'>
                                      <p><b>4</b></p>
                                    </div>
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <div className='blackpath' />
                                      <p className='step-text2' style={{ color: 'black', }}>ASIGNACIÓN Y SEGUIMIENTO</p>
                                    </div>
                                  </div>)}
                                {(item.contracts_users_step < 5 && item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p><b>4</b></p>
                                    </div>
                                    <div className='graypath'  />
                                    <p className='step-text2' style={{ color: '#d1d5db', }}>ASIGNACIÓN Y SEGUIMIENTO</p>
                                  </div>)}
                                {/* step 5 */}
                                {item.contracts_users_completed === true && (
                                  <div className='w-full flex flex-row items-center gap-2'>
                                    <div
                                      className='guide-number-done2'>
                                      <p style={{ fontSize: '1rem', margin: '0', fontWeight: 'lighter' }}>
                                        <b>5</b>
                                      </p>
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className='step-text2' style={{ color: '#13C296', }}>CONFIRMAR CARTA</p>
                                    </div>
                                  </div>
                                )}
                                {(item.contracts_users_step === 5 && item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number2'>
                                      <p>
                                        <b>5</b>
                                      </p>
                                    </div>
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <p className='step-text2' style={{ color: 'black', }}>CONFIRMAR CARTA</p>
                                    </div>
                                    <div className='step-badge-active2'>
                                        <p className='step-badge-active-p-ones'>Pendiente</p>
                                      </div>
                                  </div>)}
                                {(item.contracts_users_step < 5 && item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p>
                                        <b>5</b>
                                      </p>
                                    </div>
                                    <p className='step-text2' style={{ color: '#d1d5db', }}>CONFIRMAR CARTA</p>
                                  </div>)}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '1rem',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flex:'1'
                                }}>
                                  <button
                                    className='button-ui button-secondary'

                                    onMouseEnter={() => {
                                      const newArray = [...isHoveredArray]
                                      newArray[index] = true
                                      setIsHoveredArray(newArray)
                                    }}
                                    onMouseLeave={() => {
                                      const newArray = [...isHoveredArray]
                                      newArray[index] = false
                                      setIsHoveredArray(newArray)
                                    }}
                                    onClick={() => {
                                      setActiveKey(true)
                                      setContractSelected(item.contracts_users_envelope_id)
                                    }}>
                                    <p className='meet-text2'
                                      style={{
                                        margin: '0',
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                      }}>IR AL PROCESO</p>
                                    <img
                                      alt="Calendar"
                                      className='meet-image2'
                                      src={isHoveredArray[index] ? '/media/icons/app/menu/arrow-right-white.svg' :
                                        '/media/icons/app/menu/arrow-right-black.svg'} />
                                  </button>
                                </div>
                              </div>
                            </div>}
                        </>
                      ))) : (
                      <>
                        {amendmentOpen === false &&
                          <img src='/media/logos/ezgif.com-resize.gif' className='h-10px w-10px' alt='' />
                        }
                      </>
                    )}
                    {statusFromDatabase === 'success' && amendmentOpen === true ? (
                      <TableContainer style={{ width: '100%' }}>
                        <Table
                          style={{
                            borderCollapse: 'separate',
                            overflow: 'auto',
                            position: 'relative',
                            borderSpacing: '1rem 1rem'
                          }}>
                          <TableHead>
                            <TableRow2 className='table-header-border'>
                              <TableCell>
                                <a style={{ color: '#4B5563', fontWeight: 'bold', border: 'none' }}>ID. DE CONTRATO</a>
                              </TableCell>
                              <TableCell>
                                <a style={{ color: '#4B5563', fontWeight: 'bold', border: 'none' }}>ULTIMA ACTUALIZACION</a>
                              </TableCell>
                              <TableCell>
                                <a style={{ color: '#4B5563', fontWeight: 'bold', border: 'none' }}>ACCIONES</a>
                              </TableCell>
                            </TableRow2>
                          </TableHead >
                          <TableBody >
                            {orderedData && orderedData.map((item: any) => (
                              item.contracts_users_type === "amendment" && (
                                <TableRow key={item.contracts_users_envelope_id} >
                                  <TableCell >
                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: '1rem',
                                      alignItems: 'center'
                                    }}>
                                      <img
                                        src='/media/svg/menu-icons/pdf.svg'
                                        alt='arrow Icon'
                                        className='icon'
                                      /> {item.contracts_users_envelope_id}</div>
                                  </TableCell>
                                  <TableCell>{item.contracts_users_completedDate.substring(0, 10)}</TableCell>
                                  <TableCell>
                                    <Tooltip placement='top' title={'Ver Documento'}>
                                      <button
                                        onClick={() => {
                                          handleOpenContract(item.contracts_users_envelope_id)
                                        }}>
                                        <img style={{ cursor: 'pointer', marginBottom: '-2rem' }}
                                          src='/media/svg/menu-icons/eyeblack.svg'
                                          alt='arrow Icon'
                                          className='icon' />
                                      </button>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>)))}
                          </TableBody>
                        </Table>
                      </TableContainer>) : (
                      <>
                        {amendmentOpen === true &&
                          <img src='/media/logos/ezgif.com-resize.gif' className='h-10px w-10px' alt='' />
                        }
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showSlideOver && (
        <div className="fixed inset-0 overflow-hidden z-50">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex"
              aria-labelledby="slide-over-title"
              role="dialog"
              aria-modal="true">
              <div style={{ width: '60rem', borderRadius: '30px' }} className="relative w-screen ">
                <div style={{ borderRadius: '20px' }}
                  className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div
                      style={{ marginBottom: '40px', marginTop: '30px' }}
                      className="flex items-start justify-between">
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 24px 10px 24px',
                            height: 'auto',
                            width: 'auto',
                            marginTop: '30px',
                            marginBottom: '20px',
                          }}
                          onClick={() => setShowSlideOver(false)}
                          className="button-form-volver">
                          <img
                            alt="Arrow"
                            className="meet-image2"
                            src="/media/icons/app/menu/arrow-left-white.svg" />
                          <b style={{ color: 'white' }}>VOLVER</b>
                        </button>
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <img
                        alt="Arrow"
                        style={{ marginBottom: '30px' }}
                        src="/media/svg/menu-icons/pdf.svg" />
                      <h2 style={{
                        color: '#49323A',
                        fontSize: '24px',
                        fontWeight: '600',
                        margin: '0',
                        fontFamily: 'Lato',
                        marginBottom: '30px'
                      }}>Enmienda N°: {envelopeIdSelected}</h2>
                    </div>
                  </div>
                  {/* Add content here */}
                  {envelopeIdSelected && <PdfView envelopeId={envelopeIdSelected} />}
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ConsultantContractsList)
