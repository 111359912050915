/* eslint-disable @typescript-eslint/no-unused-vars */

import {useContext, useState} from 'react'
import {type AppointmentsAdmin} from '../../../../app/modules/apps/appointments/core/_models'
import {RootStoreContext} from '../../../../stores/rootStore'
import Swal from 'sweetalert2'
import SwitchConsultor from '../SwitchConsultor'
import {useIntl} from 'react-intl'
import {Tooltip} from 'antd'
import {Button} from 'components/Button'
import {TbHandMove, TbMailForward, TbStatusChange} from 'react-icons/tb'
import './style.css'
interface IProps {
  appointment: AppointmentsAdmin
}

const MenuOptions: React.FC<IProps> = ({appointment}) => {
  const rootStore = useContext(RootStoreContext)
  const {requestPasswordReset, sendReminder} = rootStore.authStore

  const [modalShow, setModalShow] = useState(false)

  const intl = useIntl()

  const handleResendEmail = (email: string) => {
    Swal.fire({
      title: '¿Está seguro que desea enviar este recordatorio?',
      text: `${intl.formatMessage({ id: 'AUTH.FORGOT.RESENDEMAIL' })} ${email}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '<div class="btn-with-border">CONFIRMAR <img src="/media/icons/app/menu/icons8-check-50white.png" alt="User Icon" style="width: 20px; height: 20px;" /></div>',
      cancelButtonText: intl.formatMessage({ id: 'AUTH.FORGOT.RESENDEMAILCANCEL' }),
      customClass: {
        confirmButton: 'order-2 btn-with-border-no-shadow', // Añadir nueva clase
        cancelButton: 'btn-with-icon',
      },
      buttonsStyling: false,
      showClass: {
        popup: 'swal2-noanimation',
        backdrop: 'swal2-noanimation',
      },
      hideClass: {
        popup: '',
        backdrop: '',
      },
    }).then((result) => {
      if (result.value) {
        sendReminder(email);
  
        // Mostrar el segundo popup después de enviar con éxito
        Swal.fire({
          title: '<span style="color: #13C296;">¡Muy bien!</span>', // Establecer el color verde para el título
          html: `<span style="color: black; margin-bottom: 30px;">¡Fue enviado con éxito!</span><br></b>`,
          icon: 'success',
          confirmButtonText: 'Cerrar', // Cambiar el texto del botón a "Cerrar"
          customClass: {
            confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
          },
          buttonsStyling: false,
        });
      }
    });
  };
  
  
  
  
  
  
  
  
  

  return (
    <div style={{display: 'flex', gap: '20px'}}>
      <SwitchConsultor
        modalShow={modalShow}
        appointment={appointment}
        setModalShow={setModalShow}
      />
      <Tooltip placement='top' title={'Reenviar email'}>
        <Button
          type='link'
          // tooltip={intl.formatMessage({id: 'FORMSMANAGER.TEST'})}
          onClick={() => {
            handleResendEmail(appointment.email ? appointment.email : '')
          }}
          className='change-consultor-button'  
        >
            <img src="/media/icons/app/menu/bell-alt.svg" alt="User Icon"  />
        </Button>
      </Tooltip>

{/*       <Tooltip placement='top' title={'Cambiar consultor'}>
        <Button
          type='link'
          // tooltip={intl.formatMessage({id: 'FORMSMANAGER.TEST'})}
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
           className='change-consultor-button' 
        >
           <img src="/media/icons/app/menu/pencil-alt.png" alt="User Icon"/>
        </Button>
      </Tooltip> */}

      <Tooltip placement='top' title={'Cambiar cita'}>
        <Button
          type='link'
          // tooltip={intl.formatMessage({id: 'FORMSMANAGER.TEST'})}
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
          className='change-consultor-button' 
        >
           <img src="/media/icons/app/menu/pencil-alt.png" alt="User Icon"/>
        </Button>
      </Tooltip> 
      {/* <SwitchConsultor
        modalShow={modalShow}
        appointment={appointment}
        setModalShow={setModalShow}
      />
      <DropdownButton
        style={{ backgroundColor: '#1e2b37', borderRadius: '5px' }}
        id='acciones'
        title='Acciones'
      >
        <Dropdown.Item
          href='#'
          onClick={(e) => {
            e.preventDefault()
            handleResendEmail(appointment.email ? appointment.email : '')
          }}
        >
          {intl.formatMessage({ id: 'TRANSLATION.RESEND' })}
        </Dropdown.Item>
        <Dropdown.Item
          href='#'
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          {intl.formatMessage({ id: 'TRANSLATION.CHANGE' })}
        </Dropdown.Item>
        <Dropdown.Item
          href='#'
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          {intl.formatMessage({ id: 'APPOINTMENTS.MOVEAPPOINTMENT' })}
        </Dropdown.Item>
      </DropdownButton> */}
    </div>
  )
}

export default MenuOptions
