export const profile = {
  'UPDATE.SUCCESS': 'Actualizacion exitosa',
  'UPDATE.ERROR': 'Error al actualizar',
  'APPOINTMENTS.LINKMEET': 'Enlace para la cita',
  'APPOINTMENTS.CONSULTANT': 'Consultor',
  'APPOINTMENTS.DATE': 'Fecha de la cita',
  'TRANSLATOR.SELECT': 'Seleccione idioma',
  'CORE.BTNSAVE': 'Guardar',
  'CORE.WELCOME': 'Bienvenido',
  'CORE.DATE': 'Fecha',
  'CORE.TYPE': 'Tipo',
  'CORE.REQUIRED': 'Campo requerido',
  'MENU.NEW': 'Nuevo',
  'MENU.ACTIONS': 'Acciones',
  'MENU.CREATE_POST': 'Crear nueva publicacion',
  'MENU.PAGES': 'Paginas',
  'MENU.FEATURES': 'Caracteristicas',
  'MENU.APPS': 'Aplicaciones',
  'MENU.DASHBOARD': 'Tablero',
  'MENU.MYINFO': 'Informacion',
  'MENU.MYSERVICES': 'Servicios',
  'MENU.MYAPPOINTMENTS': 'Citas',
  'MENU.MYACCESOR': 'Mi Consultor',
  'MENU.MYDOCUMENTS': 'Documenttos',
  'MENU.ACTIVITIES': 'Actividades',
  'MENU.ACTIVITIES_LIST': 'Lista de actividades',
  'MENU.FILEMANAGER': 'Administrador de archivos',
  'MENU.TEMPLATES': 'Plantillas',
  'MENU.MESSAGES': 'Mensajes',
  'MENU.CLIENTS': 'Clientes',
  'MENU.TRANSLATION': 'Traduciones',
  'MENU.TIMELINE': 'Timeline',
  'MENU.MYTRANSLATIONS': 'Traducciones',
  'MENU.MANAGE': 'Administrar',
  'AUTH.GENERAL.OR': 'o',
  'AUTH.GENERAL.SUBMIT_BUTTON': 'Enviar',
  'AUTH.GENERAL.NO_ACCOUNT': 'No tienes una cuenta?',
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Regístrate',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Se te olvidó tu contraseña',
  'AUTH.GENERAL.BACK_BUTTON': 'Espalda',
  'AUTH.GENERAL.PRIVACY': 'Intimidad',
  'AUTH.GENERAL.LEGAL': 'Legal',
  'AUTH.GENERAL.CONTACT': 'Contacto',
  'AUTH.LOGIN.TITLE': 'Crear una cuenta',
  'AUTH.LOGIN.BUTTON': 'Registrarse',
  'AUTH.FORGOT.TITLE': 'Contraseña olvidada?',
  'AUTH.FORGOT.DESC': 'Ingrese su correo electrónico para restablecer su contraseña',
  'AUTH.FORGOT.SUCCESS': 'Su cuenta ha sido reestablecida exitosamente.',
  'AUTH.REGISTER.TITLE': 'Registrarse',
  'AUTH.REGISTER.DESC': 'Ingrese su informacion para crear una cuenta',
  'AUTH.REGISTER.SUCCESS': 'Su cuenta ha sido registrada exitosamente.',
  'AUTH.INPUT.EMAIL': 'Email',
  'AUTH.INPUT.FULLNAME': 'Nombre Completo',
  'AUTH.INPUT.PASSWORD': 'Contraseña',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Confirmar Contraseña',
  'AUTH.INPUT.USERNAME': 'Usuario',
  'AUTH.VALIDATION.INVALID': '{name} no es valido',
  'AUTH.VALIDATION.REQUIRED': '{name} es requerido',
  'AUTH.VALIDATION.MIN_LENGTH': '{name}} longitud minima es {{min}',
  'AUTH.VALIDATION.AGREEMENT_REQUIRED': 'Aceptar terminos y condiciones es requeridos',
  'AUTH.VALIDATION.NOT_FOUND': 'El requerido {name} no ha sido encontrado',
  'AUTH.VALIDATION.INVALID_LOGIN': 'El detalle del registro es incorrecto',
  'AUTH.VALIDATION.REQUIRED_FIELD': 'Campo requerido',
  'AUTH.VALIDATION.MIN_LENGTH_FIELD': 'Longitud de campo minima:',
  'AUTH.VALIDATION.MAX_LENGTH_FIELD': 'Longitud de campo maxima:',
  'AUTH.VALIDATION.INVALID_FIELD': 'El campo no es valido',
  'ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT': 'Numero de registros seleccionados: ',
  'ECOMMERCE.COMMON.ALL': 'Todo',
  'ECOMMERCE.COMMON.SUSPENDED': 'Suspendido',
  'ECOMMERCE.COMMON.ACTIVE': 'Activo',
  'ECOMMERCE.COMMON.FILTER': 'Filtro',
  'ECOMMERCE.COMMON.BY_STATUS': 'Por estado',
  'ECOMMERCE.COMMON.BY_TYPE': 'Por tipo',
  'ECOMMERCE.COMMON.BUSINESS': 'Negocio',
  'ECOMMERCE.COMMON.INDIVIDUAL': 'Individual',
  'ECOMMERCE.COMMON.SEARCH': 'Buscar',
  'ECOMMERCE.COMMON.IN_ALL_FIELDS': 'En todo los campos',
  'ECOMMERCE.ECOMMERCE': 'Comercio electronico',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS': 'Clientes',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST': 'Listado de clientes',
  'ECOMMERCE.CUSTOMERS.NEW_CUSTOMER': 'Cliente Nuevo',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE': 'Cliente borrado',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION':
    'Esta seguro de borrar permanentemente este cliente?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION': 'Cliente se esta borrando...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE': 'Cliente ha sido borrado',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE': 'Cliente Borrar',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION':
    'Esta seguro de borrar permanentemente los clientes seleccionados?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION': 'Los clientes se estan borrando...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE': 'Clientes seleccionados han sido borrados',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE':
    'El estado de los clientes seleccionados ha sido actualizado ',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE':
    'El estado de los clientes seleccionados ha sido exitosamente actualizado',
  'ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE': 'El cliente ha sido actualizado',
  'ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE': 'El cliente ha sido creado',
  'PROFILE.PROFILETITTLE': 'Mi perfil ',
  'PROFILE.PROFILETITTLEBASIC': 'Información Personal',
  'PROFILE.PROFILETITTLEIMMIGRATION': 'Información Migratoria',
  'PROFILE.SIGNOUT': 'Cerrar sesión',
  'PROFILE.PLEASEWAIT': 'Por favor espere',
  'PROFILE.FIRSTNAME': 'Nombre',
  'PROFILE.LASTNAME': 'Apellido',
  'PROFILE.EMAIL': 'Correo electronico',
  'PROFILE.OCCUPATION': 'Ocupacion',
  'PROFILE.BIRTH': 'Fecha de nacimiento',
  'PROFILE.ADDRESS': 'Direccion',
  'PROFILE.PHONE': 'Telefono',
  'PROFILE.MARITALSTATUS': 'Estado civil',
  'PROFILE.COUNTRYOFORIGIN': 'Pais en el que reside',
  'PROFILE.NATIONALITIE': 'Nacionalidad',
  'PROFILE.NATIONALITIESELECT': 'Seleccionar nacionalidad...',
  'PROFILE.LANGUAGE': 'Idioma',
  'PROFILE.LANGUAGESELECT': 'Seleccionar lenguaje...',
  'PROFILE.TIMEZONE': 'Zona horaria',
  'PROFILE.TIMEZONESELECT': 'Seleccionar zona horaria...',
  'PROFILE.GENDER': 'Genero',
  'PROFILE.GENDERSELECT': 'Seleccionar genero',
  'PROFILE.GENDERM': 'Hombre',
  'PROFILE.GENDERF': 'Mujer',
  'PROFILE.GENDERO': 'Otro',
  'PROFILE.AGE': 'Edad',
  'PROFILE.AVATAR': 'Foto de Perfil',
  'PROFILE.PROFILE': 'Perfil',
  'PROFILE.CONTINUE': 'Continuar',
}
