/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Link} from '../../../../../../components'
import React, {useContext, useEffect, useState} from 'react'
import {type Orders} from '../../../../../../app/modules/apps/cart/core/preorders/_models'
import {RootStoreContext} from '../../../../../../stores/rootStore'

interface IProps {
  code: string
}

const CheckOutResume: React.FC<IProps> = ({code}) => {
  console.log("holaa22")
  const rootStore = useContext(RootStoreContext)
  const {getPreoderByCode, resetCart} = rootStore.cartStore
  const [orders, setOrders] = useState<Orders[]>([])

  useEffect(() => {
    searchOrder()
  }, [code])

  const searchOrder = async () => {
    const result = await getPreoderByCode(code)
    if (result != null) {
      setOrders(result)
    }
  }

  return (
    <div className='w-full block lg:w-1/2 xl:w-1/2 justify-center m-auto text-center'>
      <div className=''>
        {orders.length > 0 && (
          <>
            {orders.map((order) => (
              <div key={order.productId}>
                <div className='m-4'>
                  <div>
                    <img src={'/media/newdesign/ready.png'} className=' w-80 block m-auto' alt='' />
                  </div>
                  <div className='my-2'>
                    <div className='font-bold mb-2'>Transaction sucessfully purchased!</div>
                    <p className='text-xs '>
                      Congratulations ! Your purchase is complete. You will receive a
                    </p>
                    <p className='text-xs -mt-3'>confirmation email shortly.</p>
                  </div>
                  <div className='my-2'>
                    <Link to='/web/thanks'>
                      <p className='text-white rounded-md bg-tomato-500 hover:bg-tomato-400 py-2 px-3 '>
                        Mis Traducciones
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default CheckOutResume
