/* eslint-disable @typescript-eslint/no-unused-vars */

import {type FC, useContext, useState} from 'react'
import {Container} from '../../../../components/Container/index'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {exportToExcel} from 'utils/export'
import {getLogs} from 'app/modules/apps/cart/core/program/_request'

const EducationWrapper: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.STORE'})}>
        <div className='flex gap-4 justify-center flex-wrap'>
          {/*   <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/admin/store/products">
            <img className='w-32' src="/media/svg/menu-icons/education.png"/>
            <h2>Mis Servicios</h2>
          </Link>
          <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/admin/store/categories">
            <img className='w-32' src="/media/svg/menu-iconseducation.png"/>
            <h2>Categorias</h2>
          </Link>
          <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/admin/store/cupons">
            <img style={{width:100, paddingTop:28}} src="/media/svg/menu-icons/education.png"/>
            <h2>Mis Cupónes</h2>
          </Link> */}
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/Eduproducts'
          >
            <img className='w-32' src='/media/svg/menu-icons/education.png' alt='' />
            <h2>Crear Instituciones</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/Eduprogram'
          >
            <img className='w-32' src='/media/svg/menu-icons/program.png' alt='' />
            <h2>Crear Programas</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/Apliprogram'
          >
            <img className='w-32' src='/media/svg/menu-icons/aplication.png' alt='' />
            <h2>Crear Registro</h2>
          </Link>
        </div>
      </Container>
    </>
  )
}

export {EducationWrapper}
