import tw from 'tailwind-styled-components'

const Inbox = tw.div`
  w-full
  overflow-hidden
  lg:w-2/12
  border-none
  p-2
`
export {Inbox}
