/* eslint-disable @typescript-eslint/no-unused-vars */

import {Input} from '../../../../../components'
import {
  type IQueryUsers,
  type IQueryUsersFilter,
} from '../../../../../app/modules/apps/users/core/_models'
import React from 'react'
import {useIntl} from 'react-intl'

interface IProps {
  setSearch: (search: IQueryUsers) => void
  setSearchFilter?: (search: IQueryUsersFilter) => void
  searchQuery: IQueryUsers
  searchFilter?: IQueryUsersFilter
}

const Filter: React.FC<IProps> = ({setSearch, searchQuery, searchFilter, setSearchFilter}) => {
  const intl = useIntl()
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({...searchQuery, search: e.target.value})
  }

  return (
    <div>
      <div className=''></div>
    </div>
  )
}

export default Filter
