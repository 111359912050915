import {
  type Services,
  type ServicesQueryResponse,
} from '../../../../app/modules/apps/services/core/_models'
import {Table, TableBody, TableCell, TableHead, TableRow} from '../../../../components'
import DateTimeZone from '../../../../core/components/DateTimeZone'
import {KTSVG} from '../../../../core/helpers'
import React from 'react'
import {numberCurrencyConverter} from '../../../../core/utils/numberCurrencyConverter'


interface Props {
  services?: ServicesQueryResponse
}

const ServiceDetail: React.FC<Props> = ({services}) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Servicio</TableCell>
            <TableCell>Staff</TableCell>
            <TableCell>Fecha compra</TableCell>
            <TableCell>Fecha consulta</TableCell>
            <TableCell>Factura</TableCell>
            <TableCell>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services?.data?.map((values: Services, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className='symbol symbol-50px me-2'>
                  <span className='symbol-label'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen024.svg'
                      className='svg-icon-2x svg-icon-danger'
                    />
                  </span>
                </div>
              </TableCell>
              <TableCell>
                <a href='default' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                 {/*  {parse(values.name || '')} {parse(values.description || '')} */}
                 {values.name}
                </a>
              </TableCell>
              <TableCell className='text-dark'>
                <span className='badge badge-light-info fw-bold me-1'>{values.consultant}</span>
              </TableCell>
              <TableCell className='text-dark'>
                {typeof values.date !== 'undefined' && values.date !== null && (
                  <DateTimeZone date={values.date} />
                )}
              </TableCell>
              <TableCell className='text-dark'>
                {typeof values.start_time !== 'undefined' && values.start_time !== null && (
                  <DateTimeZone date={values.start_time} />
                )}
              </TableCell>
              <TableCell className='text-end'>
                <a href={values.receiptLink || '#'} target={'_blank'} rel='noreferrer'>
                  Factura
                </a>
              </TableCell>
              <TableCell className='text-end'>
                {numberCurrencyConverter(values.total || 0, values.currency || 'CAD')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export {ServiceDetail}
