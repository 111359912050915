/* eslint-disable  */
import {type Dispatch, type SetStateAction, useContext, useEffect, useState} from 'react'
import {Spin, Upload} from 'antd'
import LoadingBar from 'react-top-loading-bar'
import Resizer from 'react-image-file-resizer'
import {RootStoreContext} from '../../../../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {uploadFileAvatar} from '../../../../../../../app/modules/apps/file/core/_requests'

interface Props {
  initialImage: string
}

const AvatarEdit: React.FC<Props> = ({initialImage}) => {
  const rootStore = useContext(RootStoreContext)
  const {getProfileFromStore, isLoadingAvatar, setIsLoadingAvatar, setIsEditing, reloadProfile} =
    rootStore.profileStore
  const [imageLarge, setImageLarge] = useState<string>('')
  const [imageMedium, setImageMedium] = useState<string>(initialImage)
  const [imageSmall, setSmall] = useState<string>('')
  const [percent, setPercent] = useState(-1)

  const onChange = async (evt: any) => {
    setIsLoadingAvatar(true)
    await resizeFile(evt.file, 70, setSmall)
    await resizeFile(evt.file, 200, setImageMedium)
    await resizeFile(evt.file, 400, setImageLarge)
  }

  useEffect(() => {
    if (imageLarge !== '' && imageMedium !== '' && imageSmall !== '') {
      uploadImages()
    }
     
  }, [imageLarge, imageMedium, imageSmall])

  const uploadImages = async () => {
    const result = await uploadFileAvatar(imageLarge, imageMedium, imageSmall, getProgress)

    if (result) {
      const newProfile = getProfileFromStore
      if (newProfile.photo != null) {
        newProfile.photo.id = result.id
        newProfile.photo.path = result.path
      }
      reloadProfile()
      setIsLoadingAvatar(false)
      setIsEditing(false)
    }
  }

  useEffect(() => {
    if (percent === 100) {
      setPercent(-1)
    }
  }, [percent])

  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  const resizeFile = async (
    file: Blob,
    whidt: number,
    setImage: Dispatch<SetStateAction<string>>
  ) => {
    await Resizer.imageFileResizer(
      file,
      whidt,
      whidt,
      'JPEG',
      100,
      0,
      (uri) => {
        setImage(uri.toString())
      },
      'base64'
    )
  }

  return (
    <div>
      {isLoadingAvatar ? (
        <>
          <LoadingBar color='#BE8575' progress={percent} height={5} />
          <Spin />
        </>
      ) : (
        <>
          <Upload
            beforeUpload={() => false}
            listType='picture-card'
            maxCount={1}
            onChange={onChange}
            accept='image/*'
          >
            {'+ Cargar imagen'}
          </Upload>
        </>
      )}
    </div>
  )
}

export default observer(AvatarEdit)
