 
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Button, Space, Table} from 'antd'
import React, {useEffect, useState} from 'react'
import {type DocumentsProduct} from '../../../../../../app/modules/apps/forms/formproducts/core/_models'
import {type Product} from '../../../../../../app/modules/apps/cart/core/products/_models'
import {useQuery} from 'react-query'
import {getDocuments} from 'app/modules/apps/intake/_requests'
import {
  getImmigrationRequirementFiles,
  saveImmigrationRequirementFiles,
} from 'app/modules/apps/immigration/requirements/core/_requests'
import checkmark_white from '../../../../../../../src/app/pages/contratationApp/icons/checkmark_white.svg'

const {Column} = Table

interface IProps {
  product: Product
  documentproducts: DocumentsProduct[]
}

const AddDocuments: React.FC<IProps> = ({product, documentproducts}) => {
  const [showToast, setShowToast] = useState(false)
  const [update, setupdate] = useState(1)
  const [filterData, setFilterData] = useState<any>([])
  const {
    data: docData,
    status: docStatus,
    refetch: docRefetch,
  } = useQuery('getdocss', async () => await getDocuments())
  const {data, status, refetch} = useQuery(
    'ListDocumentsProducts',
    async () => await getImmigrationRequirementFiles(product.id || '')
  )

  const handleClick = async (id: string) => {
   const result= await saveImmigrationRequirementFiles(product.id as string, id)
   docRefetch()
    refetch()
    setupdate(update + 1)
    if (result) {
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
    }
  }

  const filterDocuments = () => {
    if (status === 'success' && docStatus === 'success') {
      const filteredDocs = docData.data.filter(
        (doc: any) => !data?.find((item: any) => (item.document && item.document.id 
        === doc.id ))
      )
      setFilterData(filteredDocs)
      console.log('Filtrado', filteredDocs)
    }
  }

  useEffect(() => {
    filterDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docData, data])

  return (
    <div>
       {showToast === true ?
        <div className='toast-style-container'>
          <div className='toast-style'>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%',top:'0px' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Guardado Exitoso
          </div>
        </div> : null}
      {docStatus === 'loading' && <div>Loading...</div>}
      {docStatus === 'success' && docData.data && (
        <div>
          {update > 0 && (
            <Table dataSource={filterData} rowKey='id' size='small'>
              <Column title='Form' dataIndex='name' />
              <Column title='Descripcion' dataIndex='description' />
              <Column
                title='asignar'
                key='estado'
                render={(text, record: any) => (
                  <Space>
                    <Button
                      type='link'
                      onClick={async () => {
                        await handleClick(record.id)
                      }}
                    >
                      Asignar
                    </Button>
                  </Space>
                )}
              />
            </Table>
          )}
        </div>
      )}
    </div>
  )
}
export default AddDocuments
