  /* eslint-disable @typescript-eslint/no-unused-vars */
 

import {Col, Form, Input, Row, Typography} from 'antd'
import {type ImmigrationOption} from '../../../../app/modules/apps/immigration/options/core/_models'
import React, {useEffect, useRef, useState} from 'react'
import {Button} from '../../../../components'
import FileSelect from '../../../../app/pages/fileManager/FileSelect'
import {type Files} from '../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../../../app/modules/apps/immigration/services/core/_models'
import ImmigrationServicesSelect from '../ImmigrationServicesSelect'
import JoditEditor from 'jodit-react'
import {useForm} from 'react-hook-form'
import validationDatatSchema from './_validation'
import {yupResolver} from '@hookform/resolvers/yup'

const {Text} = Typography

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "align",
  "|",
  "ol",
  "ul",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|"
];

const config = {
  minHeight: 300,
  size: 'large',
  readonly: false,
  spellcheck: true,
  buttons: buttons,
  indentMargin: 30,
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  tabIndex: -1,
  language: 'es',
  debugLanguage: false,
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }

  return e && e.fileList
}

export interface IProps {
  onComplete: (data: ImmigrationOption) => void
  data: ImmigrationOption
  showwFileSelect?: boolean
  files?: Files[]
  setFiles: (files: Files[]) => void
}

const AddEditData: React.FunctionComponent<IProps> = ({
  onComplete,
  data,
  showwFileSelect = false,
  files = [],
  setFiles,
}) => {
  const [newdata, setNewdata] = useState(data)
  const [immigrationService, setImmigrationService] = useState<ImmigrationService | null>(null)
  const [description, setDescription] = useState(data.description)
  const [error, setError] = useState<null | string>(null)

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: {errors},
    setFocus,
  } = useForm({
    resolver: yupResolver(validationDatatSchema),
  })

  useEffect(() => {
    setValue('files', files)
  }, [files, setValue])

  useEffect(() => {
    console.log('🚀 ~ file: index.tsx ~ line 88 ~ data', data)
    if (data && data.id) {
      setValue('name', data.name || '')
      setValue('description', data.description || '')
      setValue('order', data.order ? data.order : 100)
      setValue('immigrationService', data.immigrationService)
    }
  }, [data, setValue])

  const editor = useRef(null)

  const onSubmit = async (data: any) => {
    setError(null)
    onComplete(data)
  }

  const getImmigrationService = (ImmigrationService: ImmigrationService) => {
    setImmigrationService(ImmigrationService)
    data.immigrationService = ImmigrationService
    setNewdata(data)
  }

  const onError = (error: any) => {
    setError(error)
  }

  const handleChangeSelect = (target: any, data: any) => {
    setValue(target, data)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Form name='validate_other' layout='vertical' {...formItemLayout} onFinish={handleSubmit}>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              initialValue={immigrationService?.name}
              label='Servicio de inmigración'
              style={{width: '100%'}}
              {...register('immigrationService')}
            >
              <ImmigrationServicesSelect
                getImmigrationService={getImmigrationService}
                handleChangeSelect={handleChangeSelect}
                defaultSelect={data?.immigrationService}
              />

              <Text type='danger'> Error </Text>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              name='name'
              label='Nombre'
              style={{width: '100%'}}
            >
              <Input
                style={{width: '100%'}}
                onChange={(e: any) => {
                  handleChangeSelect('name', e.target.value)
                }}
                defaultValue={data?.name || ''}
              />
              <Text type='danger'> Error </Text>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              label='Descripcion'
              style={{width: '100%'}}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <JoditEditor
                ref={editor}
                value={data?.description || ''}
                config={config}
                onChange={(content) => {
                  handleChangeSelect('description', content)
                }}
              />
              <Text type='danger'> Error </Text>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              name='order'
              label='Orden'
              style={{width: '100%'}}
            >
              <Input
                style={{width: '100%'}}
                onChange={(e: any) => {
                  handleChangeSelect('order', e.target.value)
                }}
                defaultValue={data?.order || 100}
              />
              <Text type='danger'> Error </Text>
            </Form.Item>

            {showwFileSelect && (
              <>
                <FileSelect
                  getFiles={(files: Files[]) => {
                    console.log('🚀 ~ file: index.tsx ~ line 192 ~ files', files)
                    setFiles(files)
                  }}
                  files={files}
                />
              </>
            )}

            <Text type='danger'>{error && <>Debe diligenciar los campos requeridos</>}</Text>
            <Form.Item>
              <Button type='submit' onClick={handleSubmit(onSubmit, onError)}>
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default AddEditData
