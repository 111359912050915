/* eslint-disable react-hooks/exhaustive-deps */
 
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Spin, Upload} from 'antd'
import {useEffect, useState} from 'react'
import {Button} from '../../../../../components'
import LoadingBar from 'react-top-loading-bar'
import {type Product} from '../../../../../app/modules/apps/cart/core/products/_models'
import {dataURLtoFile} from '../../../../../core/utils/dataURLtoFile'
import {resizeFile} from '../../../../../core/utils/resizeImages'

interface Props {
  product: Product
  getFile: (file: File | null) => void
}

const ProductImage: React.FC<Props> = ({product, getFile}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [imageLarge, setImageLarge] = useState<string>(product.image?product.image:'' )
  const [imageFile, setImageFile] = useState<File>()
  const [image, setImage] = useState<string>('')

  const [percent, setPercent] = useState(-1)

  useEffect(() => {
    try {
      const file = dataURLtoFile(image, 'avatar-large.jpg')
      getFile(file)
    } catch (error) {}
  }, [image])

  useEffect(() => {
    try {
      if (imageFile != null) getFile(imageFile)
    } catch (error) {}
  }, [imageFile])

  const onChange = async (evt: any) => {
    // verify if the file is an svg file
    if (evt.file.type === 'image/svg+xml') {
      setImageFile(evt.file)
    } else {
      await resizeFile(evt.file, 400, setImage)
    }

    //
  }

  return (
    <div>
      {isLoading ? (
        <>
          <LoadingBar color='#BE8575' progress={percent} height={5} />
          <Spin />
        </>
      ) : (
        <>
          <Upload
            beforeUpload={() => false}
            listType='picture'
            maxCount={1}
            onChange={onChange}
            style={{width: '100%', height: '30px'}}
            accept='image/*'
            onDrop={(evt) => {
              getFile(null)
            }}
          >
            <button className='button-ui button-tertiary button-s button-form'><a style={{color: 'white'}}>+ Cambiar imagen</a></button>
          </Upload>
        </>
      )}
      {imageLarge.length > 0 && (
        <img
          src={imageLarge}
          style={{width: '100%', height: '100%', maxWidth: '300px'}}
          alt='avatar'
        />
      )}
    </div>
  )
}

export default ProductImage
