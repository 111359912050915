 

import axios, { type AxiosResponse } from 'axios'
import { type RootObjectCompletedContracts } from './_models'
import { axiosErrorHandler } from 'app/modules/errors/ApiError'
import { FormsDataQueryResponse } from '../formsdata/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const FORMSDATAS_URL = `${API_URL}/contracts`
const DOCUSIGN_URL = process.env.REACT_APP_DOCUSIGN_BACKEND_URL
const getBase64pdf = async (params: string): Promise<any> => {
  return await axios.get(`${DOCUSIGN_URL}/getPdf/${params}`).then((d: AxiosResponse<any>) => {
    return d.data
  })
}

const getAllCompletedContracts = async (
  fromDate: string,
  toDate: string
): Promise<RootObjectCompletedContracts> => {
  return await axios
    .get(`${DOCUSIGN_URL}/getcompletedcontracts/${fromDate}/${toDate}`)
    .then((d: AxiosResponse<RootObjectCompletedContracts>) => {
      return d.data
    })
}

const getAllCompletedContractsByEmail = async (
  email: string | undefined,
  fromDate: string,
  toDate: string
): Promise<RootObjectCompletedContracts> => {
  return await axios
    .get(`${DOCUSIGN_URL}/getcompletedcontracts/${email}/${fromDate}/${toDate}`)
    .then((d: AxiosResponse<RootObjectCompletedContracts>) => {
      return d.data
    })
}


// En _requests.js o donde sea que se encuentren tus solicitudes

export const getAllCompletedContractsByEmailAndId = async (email: string, contractId: string, fromDate: string, toDate: string) => {
  const response = await fetch(`/api/contracts?email=${email}&contractId=${contractId}&fromDate=${fromDate}&toDate=${toDate}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};


const getBase64DO = async (email: string): Promise<any> => {
  return await axios.get(`${DOCUSIGN_URL}/getfiles/${email}`).then((d: AxiosResponse<any>) => {
    return d.data
  })
}

const updateContracts = async (id: string, contract: any) => {
  return await axios
    .patch(`${FORMSDATAS_URL}/${id}`, contract)
    .then((response: AxiosResponse<FormsDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const updateCon = async (id: string, status: any) => {
  return await axios
    .patch(`${FORMSDATAS_URL}/updatecon/${id}`, { "status": status })
    .then((response: AxiosResponse<FormsDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}


const updateuofrep = async (id: string) => {
  return await axios
    .patch(`${FORMSDATAS_URL}/updateuof/${id}`)
    .then((response: AxiosResponse<FormsDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}
export {
  getBase64pdf,
  getAllCompletedContracts,
  getAllCompletedContractsByEmail,
  getBase64DO,
  updateContracts,
  updateCon,
  updateuofrep
}
