/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useEffect, useState} from 'react'
import {getProfile} from '../../../../../app/modules/apps/profile/core/_requests'
import {useQuery} from 'react-query'
import {IComposeData} from 'app/modules/apps/appointments/core/_models'
import {ProfileMain} from './cards/ProfileMain'

interface Props {
  composepostdata: IComposeData
}

const Settings: React.FC<Props> = (props) => {
  const {data, status, refetch} = useQuery('profile', getProfile)
  const [refetchActivator, setRefetchActivator] = useState(false)
  const {composepostdata} = props

  useEffect(() => {
    if (refetchActivator) {
      refetch()
    }
    setRefetchActivator(false)
  }, [refetch, refetchActivator])

  useEffect(() => {}, [])

  if (status === 'success' && data != null) {
    return (
      <ProfileMain
        setRefetchActivator={setRefetchActivator}
        profile={data}
        ProfileImmigration={data}
        composepostdata={composepostdata}
      />
    )
  } else {
    return <>loading</>
  }
}

export default Settings
