/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  createMessages,
  getMessagesIdUsers,
  replyMessages,
  sendDraft,
  updateDraft,
  updateMessages,
} from '../../../app/modules/apps/messages/core/_requests'
import { AutoShares } from './AutoShares'
import ComposeTemplate from './ComposeTemplate'
import { FileSelector } from './FileSelector'
import { type Messages as IMessages } from '../../../app/modules/apps/messages/core/_models'
import Input from 'antd/lib/input/Input'
import JoditEditor from 'jodit-react'
import { RootStoreContext } from '../../../stores/rootStore'
import { SearchTemplate } from '../../../app/pages/templateManager/SearchTemplate'
import Swal from 'sweetalert2'
import ToComponent from './ToComponent'
import { type UploadChangeParam } from 'antd/lib/upload'
import { type UploadFile } from 'antd/lib/upload/interface'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import '../../../app/pages/messages/UserMessages/styles/swalStyle.css'
import './ComposeTemplate/style.css'
import { downloadFileLibrary, postLibraryFiles } from 'app/modules/apps/library/core/_requests'
import { useQuery } from 'react-query'
import { getTemplateSendingBluee, getUserUsersById, sendEmailBlueTemplate } from 'app/modules/apps/users/core/_requests'
import { sendingBlueTemplates } from 'app/modules/apps/sendinBlueTemplates/_models'
import { IToUsers } from './interfaces/ITOUsers'
import { uploadfilesmessagenewversion } from 'app/modules/apps/file/core/_requests'
import PDFIcon from '../contratationApp/icons/PDF.svg'
import Arrowleft from '../contratationApp/icons/arrow-left.svg'
import PdfViewerAndres from 'core/components/PdfViewer/pdfViewerAndres'
import { IReply, initialReplyData } from 'app/modules/apps/appointments/core/_models'
import PhotoView from '../services/services/supportDocumentsClient/PhotoView'
import iconorechazo from '../contratationApp/icons/iconorechazo.svg'
import load from '../../../../src/app/pages/contratationApp/icons/load.svg'

interface Props {
  data?: string
  composepostdata: any
  cc: IToUsers[]
  showSelectFiles: boolean
  showShareFiles: boolean
  type: string
  showTemplateSearch: boolean
  initialContent: string
  initialSubject: string
  handleClose: () => void
  showButtonClose?: boolean
  initialfolder?: string
  showComposeTemplate?: boolean
  category: string
  tabNumber?: string
  setShowMessage: React.Dispatch<React.SetStateAction<boolean>>
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage: React.Dispatch<React.SetStateAction<boolean>>
  setComposeData: React.Dispatch<React.SetStateAction<IReply>>
}

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "align",
  "|",
  "ol",
  "ul",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|"
];

const config = {
  minHeight: 300,
  size: 'large',
  readonly: false,
  spellcheck: true,
  buttons: buttons,
  indentMargin: 30,
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  tabIndex: -1,
  language: 'es',
  debugLanguage: false,
}

const MessageContent: React.FC<Props> = ({
  composepostdata,
  showSelectFiles,
  showShareFiles,
  type,
  showTemplateSearch,
  initialContent,
  initialSubject,
  handleClose,
  cc,
  initialfolder,
  showComposeTemplate = false,
  category,
  tabNumber,
  setShowMessage,
  setShowMessageRows,
  setReplyMessage,
  setComposeData,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { currentUser } = rootStore.usersStore
  const { staff } = rootStore.authStore
  const { loadCounterMessagesAuth, loadMessages, loadMessagesNew } = rootStore.messageStore
  const { sendAllDataUser } = rootStore.composeTemplateStore
  const [messageId, setMessageId] = useState<string>('')
  const intl = useIntl()
  const editor = useRef(null)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [files, setTargetFilesParent] = useState<string[]>([])
  const [shared, setShared] = useState<string[]>([])
  const [filesArray, setFilesArray] = useState<any>([])
  const [content, setContent] = useState(initialContent)
  const [subject, setSubject] = useState(showTemplateSearch ? '' : initialSubject)
  const [folder, setFolder] = useState(initialfolder || 'inbox')
  const [newContent, setNewContent] = useState('')
  const [lookImage, setLookImage] = useState<any>()
  const [errorFileMessage, setErrorFileMesasge] = useState<boolean>(false)
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(false);
  const [to, setTO] = useState<IToUsers[]>([])
  const { user } = rootStore.authStore
  const successMessage = '¡Fue enviado con éxito!';
  const notificationMessage = `Hemos notificado a los destinatarios  de tu mensaje.`;
  const [target, setTarget] = useState<any>([]);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const { data: sendingBlueData, refetch } = useQuery(
    'getAllSendingBlueTemplatess',
    async () => await getTemplateSendingBluee()
  )

  const { refetch: userRefetch } = useQuery(
    'listnotesbyuser',
    async () => await getMessagesIdUsers(currentUser !== null ? currentUser?.id as string : '')
  )

  const [arrayTemplates, setArrayTemplates] = useState<sendingBlueTemplates[]>([])
  useEffect(() => { console.log("cambiefiles", file) }, [files])
  useEffect(() => { }, [to])
  useEffect(() => { }, [isSubmiting])
  useEffect(() => { }, [content, files])
  useEffect(() => { }, [shared])
  useEffect(() => { }, [folder])
  useEffect(() => {
    if (category === 'postconsulta') {
      if (arrayTemplates?.length === 0 && sendingBlueData !== undefined) {
        setArrayTemplates(sendingBlueData?.filter((item: any) => item.tag === 'Post Consulta'))
      }
    }
  }, [sendingBlueData])
  useEffect(() => {
    if (composepostdata && composepostdata.files_info && filesArray.length === 0) {
      setFilesArray(composepostdata && composepostdata.files_info)
    }
  }, [composepostdata])

  const deleteTarget = async (item: any) => {
    const nuevoArray = target.filter((elemento: any) => elemento !== item);
    setTarget(nuevoArray)
  }

  const deleteFileArray = async (item: any) => {
    const nuevoArray = filesArray.filter((elemento: any) => elemento !== item);
    setFilesArray(nuevoArray)
  }
  const changeTemplate = (item: any, checked: boolean) => {
    if (checked === false) {
      setArrayTemplates((prevTemplates: any) =>
        prevTemplates?.filter((item: any) => item.id !== Number(item.id))
      )
    } else {
      setArrayTemplates((prevTemplates: any) => {
        if (!Array.isArray(prevTemplates)) {
          return [item] // If prevTemplates is not an array, return a new array with only the item
        }
        return [...prevTemplates, item] // Spread the elements of prevTemplates and append the item
      })
    }
  }

  const handleSubmitFromDraft = async () => {
    if (composepostdata.messageId && category === "fromDraft") {
      console.log("entre aca", composepostdata)
      const destEmail = await getUserUsersById(to[0].id)
      const message: any = {
        id: composepostdata.messageId,
        subject: subject,
        content: newContent,
        to: to,
        email: destEmail?.email,
        userId: to[0].id   //ahora mismo solo crear borrador para 1
      }
      const arrayOfId: any[] = []
      for (const elemento of filesArray) {
        if (!elemento.originalname) {
          const file: any = await uploadfilesmessagenewversion(elemento, () => { })
          console.log("file", file)
          arrayOfId.push({ id: file.id });
        } else {
          arrayOfId.push({ id: elemento.id });
        }
      }

      if (target && target.length && target.length > 0) {
        for (const elemento of target) {
          arrayOfId.push({ id: elemento.key });
        }
      }
      if (arrayOfId && arrayOfId.length && arrayOfId.length > 0) {
        message.files = arrayOfId
      }
      const result = await updateDraft(message)
      if (result != null) {
        setLoading(false)
        loadMessages("draft")
        loadMessagesNew("draft", 1)
        setMessageId("")
        sendAllDataUser("")
        setNewContent('')
        setSubject('')
        setTargetFilesParent([])
        setShared([])
        setTO([])
        Swal.fire({
          titleText: '¡Muy bien!',
          html: `
            <div class="custom-container">
              <div class="custom-text">Su borrador ha sido guardado</div>
            </div>
          `,
          customClass: {
            icon: 'custom-icon-class',
            title: 'custom-title-class',
            confirmButton: 'custom-confirm-button-class'
          },
          confirmButtonText: 'Cerrar',
          imageUrl: '/media/svg/menu-icons/thumbs-up.svg',
          imageWidth: 64,  // ajusta según tus necesidades
          imageHeight: 64  // ajusta según tus necesidades
        }).then((result) => {
          if (result.isConfirmed) {
            userRefetch()
            setComposeData(initialReplyData)
            handleCloseMessage
            setShowMessage(false)
            setShowMessageRows(true)
            setReplyMessage(false)
          }
        });

      } else {
        setIsSubmiting(false)
      }
      return
    }
  }

  const handleSubmitDraft = () => {
    setLoading(true)
    if ((JSON.stringify(to) === JSON.stringify([{ "id": "" }])) || !to || JSON.stringify(to) === JSON.stringify([{ "id": undefined }]) || (JSON.stringify(to) === JSON.stringify([{ "id": null }]))) {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'Debe seleccionar un destinatario',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    userRefetch()
    if (composepostdata.messageId && category === "fromDraft") {
      handleSubmitFromDraft()
      return
    }
    composepostdata.userId = composepostdata.fromId
    composepostdata.user = composepostdata.fromId
    composepostdata.folder = 'draft'
    setFolder('draft')
    composepostdata.draft = true
    setTO([])
    cc = []
    handleSubmit()
  }

  const handleSubmit = () => {
    setLoading(true)
    /*  setNewContent('') */
    if (composepostdata.folder === 'draft') {
      setFolder('draft')
    }

    setIsSubmiting(true)
    if (!validateType()) {
      setIsSubmiting(false)
      return false
    }
    if ((JSON.stringify(to) === JSON.stringify([{ "id": "" }])) || !to || JSON.stringify(to) === JSON.stringify([{ "id": undefined }]) || JSON.stringify(to) === JSON.stringify([{ "id": null }])) {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'Debe seleccionar un destinatario',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    if (subject === '') {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'No ha escrito ningún Asunto',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    if (newContent === '') {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'No ha escrito ningún mensage',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    if (files.length === 0 && showSelectFiles) {
      setLoading(false)
      Swal.fire({
        title: 'Sin arvhivos adjuntos?',
        text: 'No ha adjuntado ningun archivo desea seguir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviarlo!',
      }).then((result: any) => {
        if (!result.isConfirmed) {
          setIsSubmiting(false)
        } else {
          sendMessage()
        }
      })
    } else {
      sendMessage()
    }
  }

  const sendMessage = async () => {
    const messageData: IMessages = {
      content: newContent,
      email: composepostdata.email,
      to,
      cc,
      user: composepostdata.userId,
      files,
      type,
      subject,
      shared,
      messageId: composepostdata.messageId || null,
      id: undefined,
      fromId: composepostdata.fromId,
      fromUser: composepostdata.fromUser,
      userId: composepostdata.userId,
      fromEmail: '',
      orderDetailId: composepostdata.orderDetailId,
      folder: composepostdata.folder,
      draft: composepostdata.draft,
      favorite: composepostdata.favorite,
      files_info: composepostdata.files_info,
      unread: composepostdata.unread
    }
    let result
    let resultFilesLibrary
    let resultSendingBlue
    const arrayOfId: any[] = []
    for (const elemento of filesArray) {
      if (!elemento.originalname) {
        const file: any = await uploadfilesmessagenewversion(elemento, () => { })
        console.log("file", file)
        arrayOfId.push({ id: file.id });
      } else {
        arrayOfId.push({ id: elemento.id });
      }
    }

    if (target && target.length && target.length > 0) {
      for (const elemento of target) {
        arrayOfId.push({ id: elemento.key });
      }
    }
    if (arrayOfId && arrayOfId.length && arrayOfId.length > 0) {
      messageData.files = arrayOfId
    }
    console.log("MESSAGEDATA.FILES", messageData.files)
    if (composepostdata.messageId && category === "fromDraft") {
      const message: any = {
        id: composepostdata.messageId,
        subject: subject,
        content: newContent,
        to: to,
        files: messageData.files,
        messageId: composepostdata.messageIdRelation,
      }
      const rest = await sendDraft(message)
      if (rest != null) {
        setLoading(false)
        setMessageId(rest.id as string)
        sendAllDataUser(composepostdata.userId)
        setNewContent('')
        setSubject('')
        setTargetFilesParent([])
        setShared([])
        setTO([])
        Swal.fire({
          titleText: '¡Muy bien!',
          html: `
            <div class="custom-container">
              <div class="custom-text">${successMessage}</div>
              <div class="custom-footer">${notificationMessage}</div>
            </div>
          `,
          customClass: {
            icon: 'custom-icon-class',
            title: 'custom-title-class',
            confirmButton: 'custom-confirm-button-class'
          },
          confirmButtonText: 'Cerrar',
          imageUrl: '/media/svg/menu-icons/thumbs-up.svg',
          imageWidth: 64,  // ajusta según tus necesidades
          imageHeight: 64  // ajusta según tus necesidades
        }).then((result) => {
          if (result.isConfirmed) {
            userRefetch()
            setComposeData(initialReplyData)
            handleCloseMessage
            setShowMessage(false)
            setShowMessageRows(true)
            setReplyMessage(false)
          }
        });
      } else {
        setIsSubmiting(false)
      }
      loadMessages("draft")
      loadMessagesNew("draft", 1)
      return

    }
    if (messageId !== '') result = await updateMessages(messageData, messageId)
    else {
      if (type === 'reply') result = await replyMessages(messageData)
      else {
        result = await createMessages(messageData)
        if (messageData.files.length > 0) {
          resultFilesLibrary = await postLibraryFiles(messageData.userId, messageData.files)
        }
        console.log(JSON.stringify(arrayTemplates))
        if (arrayTemplates && arrayTemplates.length > 0) {
          console.log('entre aqui')
          resultSendingBlue = await sendEmailBlueTemplate([messageData.email], '25')
        }
      }
    }

    if (result != null) {
      setLoading(false)
      setMessageId(result.id as string)
      sendAllDataUser(composepostdata.userId)
      setNewContent('')
      setSubject('')
      setTargetFilesParent([])
      setShared([])
      setTO([])
      Swal.fire({
        titleText: '¡Muy bien!',
        html: `
          <div class="custom-container">
          <div class="custom-text">${composepostdata.draft === true ?
            'Su borrador ha sido guardado' : successMessage}</div>
            <div class="custom-footer">${notificationMessage}</div>
          </div>
        `,
        customClass: {
          icon: 'custom-icon-class',
          title: 'custom-title-class',
          confirmButton: 'custom-confirm-button-class'
        },
        confirmButtonText: 'Cerrar',
        imageUrl: '/media/svg/menu-icons/thumbs-up.svg',
        imageWidth: 64,  // ajusta según tus necesidades
        imageHeight: 64  // ajusta según tus necesidades
      }).then((result) => {
        if (result.isConfirmed) {
          userRefetch()
          setComposeData(initialReplyData)
          handleCloseMessage
          setShowMessage(false)
          setShowMessageRows(true)
          setReplyMessage(false)
        }
      });
    } else {
      setIsSubmiting(false)
    }
    loadCounterMessagesAuth(user?.id)
  }

  const validateType = () => {
    if (type === 'forward') {
      if (to.length === 0) {
        Swal.fire({
          title: 'No se puede reenviar',
          text: 'No se puede reenviar sin destinatario',
          icon: 'warning',
          confirmButtonText: 'Regresar',
        })
      } else {
        composepostdata.userId = to[0].id
        composepostdata.user = to[0].id

        return true
      }
    } else {
      return true
    }
    return false
  }

  const setFiles = (files: UploadChangeParam<UploadFile<unknown>>) => {
    // setTargetFilesParent(files)
  }

  const handleCloseMessage = () => {
    handleClose()
    if (setShowMessage) setShowMessage(false)
  }
  const handleViewer = async (item: any) => {
    setLoadingFile(true)
    const file = await downloadFileLibrary(item.id, () => {
      // comment to deactivate eslint
    })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    setFile(file)
    setFileName('documento.pdf')
    setLoadingFile(false)
  }
  const convertImage = async (document: any) => {
    setLoadingFile(true)
    const file = await downloadFileLibrary(document, () => { })
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      } else {
        console.error('Error reading the file.')
      }
      setLoadingFile(false)
    }
    fileReader.onerror = () => { }
    fileReader.readAsArrayBuffer(file)
  }

  const verDoc = (file: any) => {
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL, '_blank')
  }
  return (
    <div className='w-full' id='ReplyMessage'>
      {file && file.type === 'application/pdf' &&
        <div
          className='pdf-viewer-width'>
          <button
            className='button-volver'
            style={{
              height: '2rem',
              width: '5rem',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
              top: '0',
              color:'black',
              marginTop: '1rem'
            }}
            onClick={() => {
              setFile(null)
              setHide(false)
            }}
          >
            Volver
          </button>
          <br />
          <div className='width-full-for-pdf'>
            <PdfViewerAndres
              name={fileName}
              url={URL.createObjectURL(file)}
              children={undefined} />
          </div>
        </div>}
      {lookImage &&
        <div style={{/*  marginTop: '1rem' , */position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 10000,
          overflow: 'scroll',
          backgroundColor: 'white',
          top: '0'
        }}>
          <PhotoView image={lookImage} setLookImage={setLookImage} />
        </div>}
      {!file && <div className='flex justify-arround hide-component-fullscreen '
        style={{
          width: '100%',
          height: '4rem',
          position: 'fixed',
          zIndex: '9999',
          backgroundColor: 'white',
          top: '0',
          paddingTop: '1rem'
        }}>
        <button
          className='button2 '
          style={{
            height: '2rem',
            width: '5rem',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            userRefetch()
            handleCloseMessage
            setShowMessage(false)
            setShowMessageRows(true)
            setReplyMessage(false)
            setComposeData(initialReplyData)
          }}>
          <p className='meet-text2' style={{ margin: '0', fontSize: '12px' }}>VOLVER</p>
        </button>
        {newContent !== '' ?
          <div className='flex gap-1 pb-8'>
            <button
              style={{
                height: '2rem',
                width: '6rem',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className='button2'
              onClick={handleSubmitDraft}>
              <p className='meet-text2 hide-component-fullscreen'
                style={{ margin: '0', fontSize: '12px' }}>BORRADOR</p>
            </button>
            <button
              style={{
                height: '2rem',
                width: '6rem',
                boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                border: '1px solid white',
                backgroundColor: '#2CD673',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={handleSubmit}
              className='button4'>
              <b className='meet-text3 '
                style={{ margin: '0', fontSize: '12px' }}>ENVIAR</b>
            </button>
          </div> :
          <div className='flex gap-1 pb-8'>
            <button
              style={{
                height: '2rem',
                width: '6rem',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #8899A8',
                backgroundColor: '#DFE4EA'
              }}
              className='button2'>
              <p className='meet-text2 hide-component-fullscreen'
                style={{ margin: '0', color: '#8899A8', fontSize: '12px' }}>BORRADOR</p>
            </button>
            <button
              style={{
                height: '2rem',
                width: '6rem',
                boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #8899A8',
                backgroundColor: '#DFE4EA'
              }}
              className='button4'>
              <b className='meet-text3 '
                style={{ margin: '0', color: '#8899A8', fontSize: '12px' }}>ENVIAR</b>
            </button>
          </div>
        }
      </div>}


      <div className='flex mb-8 mt-2 margin-top-cha' style={{ gap: '10px' }}>
        {composepostdata.draft === false ?
          <>
            <img
              src='/media/svg/menu-icons/message-sender.svg'
              alt='Via estudios'
              style={{
                width: '30px',
                height: '30px',
              }} />
            <h1 style={{ fontSize: '24px' }}>Responder mensaje</h1>
          </>
          : <h1 style={{ fontSize: '24px' }}>Borrador</h1>}
      </div>
      {showComposeTemplate && (
        <div className='row'>
          <div className='col-md-12 w-60'>
            <ComposeTemplate setContent={setContent} />
            <div className='form-group'>
              {type === 'forward' ? (
                <div className='form-group' />
              ) : (
                <>
                  <label>To: </label>
                  {` ${composepostdata.email}`}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <div className='row'>
          <div className='col-md-12 px-8'>
            {composepostdata.type === 'forward' ? (
              <ToComponent setCC={setTO} data={composepostdata} isNew={true} />
            ) : (
              /*    composepostdata.folder !== 'draft' ? ( */
              <ToComponent setCC={setTO} data={composepostdata} isNew={false} />
              /*     ) : null */
            )}
          </div>
        </div>
        {showTemplateSearch ? (
          <SearchTemplate setSubject={setSubject} setContent={setContent} />
        ) : null}
        {composepostdata.draft === false ?
          null
          : <div className='row mt-4'>
            <div className='col-md-12 px-8'>
              <div className='form-group flex gap-4'>
                <label>Asunto:</label>
                <Input
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value)
                  }}
                  style={{ width: '100%' }}
                  type='text' />
              </div>
            </div>
          </div>}
        <div className='row'>
          <div className='col-md-12 px-8'>
            <div className='form-group'>
              {staff && tabNumber !== '1' && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: '0.5rem' }}>
                  <div>
                    <label>Plantillas:</label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <SearchTemplate setSubject={setSubject} setContent={setContent} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className='flex gap-4 mb-4'>
          <p style={{ marginLeft: '1rem' }}>Adjuntar</p>
          <div className='attach-container'>
            {tabNumber !== '1' &&
              <FileSelector
                setTargetFilesParent={setTargetFilesParent}
                target={target} setTarget={setTarget} />}
            <div className='flex flex-col'>
              <input
                style={{
                  width: '14rem',
                  height: '2.5rem',
                  border: `${errorFileMessage === true ? '1px solid red' : '1px solid #01A9DB'}`,
                  borderRadius: '5px',
                  display: 'flex',
                  gap: '5px',
                  padding: '5px'
                }}
                className='uploader_input'
                accept='.pdf'
                type='file'
                max={1}
                onChange={(event: any) => {
                  const file = event.target.files[0]
                  if (file && file.name) {
                    if (file
                      && file.type !== 'application/pdf'
                      && file.type !== 'image/png'
                      && file.type !== 'image/jpeg'
                    ) {
                      setErrorFileMesasge(true)
                      return
                    }
                    setErrorFileMesasge(false)
                    if (filesArray && filesArray.length && filesArray.length > 0) {
                      setFilesArray((prevFilesArray: any) => [...prevFilesArray, file]);
                    } else {
                      setFilesArray([file])
                    }
                  }
                }}
              />
              {errorFileMessage == false ? <p style={{ fontSize: '13px' }}>
                Solo archivos .pdf .jpeg .jpg .png
              </p> : <div style={{ backgroundColor: '#FEF3F3', width: 'fit-content', height: 'fit-content', padding: '6px 12px', borderRadius: '10px', display: 'flex', gap: '5px' }}>
                <img style={{ width: '13px' }} src={iconorechazo} alt="PDFIcon" />
                <div> <p style={{ color: 'red', margin: 0 }}> Solo se aceptan formatos .pdf .jpeg .jpg .png</p>
                </div></div>}
            </div>
          </div>
        </div>
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          onChange={(content) => {
            setNewContent(content)
          }} />
        {loadingFile === true && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /><p style={{ margin: 0 }}>Cargando archivo adjunto</p></div>}
        <div className='gap-2 mt-4 mb-2'
          style={{ display: 'flex', flexWrap: 'wrap' }}>

          <div className='gap-2 mt-4 mb-2 file-label'
            style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filesArray &&
              filesArray.length > 0 &&
              filesArray.map((fileArray: any) => (
                <div
                  className='files-show-message-hover'
                  style={{
                    maxWidth: '12rem',
                    border: '1px solid #01A9DB',
                    borderRadius: '5px',
                    display: 'flex',
                    gap: '5px',
                    padding: '5px'

                  }}
                  onClick={(event) => {
                    event.stopPropagation()

                    if (fileArray.name) {
                      verDoc(fileArray)
                      return
                    }

                    if (fileArray.originalname.includes('.pdf')) {
                      handleViewer(fileArray)
                    } else if (fileArray.originalname.includes('.png') ||
                      fileArray.originalname.includes('.jpg') ||
                      fileArray.originalname.includes('.jpeg')) {
                      convertImage(fileArray.id)
                    }
                  }}>
                  <div
                    className='flex gap-4 mr-2 ml-2 file-label'>
                    <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                    {fileArray && (
                      <p style={{
                        color: '#01A9DB',
                        margin: '0',
                        fontSize: '1rem'
                      }}>
                        {fileArray.name ?
                          `${fileArray.name.slice(0, 10)}${fileArray.name.length > 10 ? '...' : ''}` :
                          (fileArray.originalname ?
                            `${fileArray.originalname.slice(0, 10)}${fileArray.originalname.length > 10 ? '...' : ''}` :
                            '')}
                      </p>
                    )}

                  </div>
                  <p onClick={() => deleteFileArray(fileArray)} className='mr-2' style={{ color: '#01A9DB', margin: '0', fontSize: '1rem' }}>X</p>
                </div>
              ))}
            {target &&
              target.length > 0
              && target.map((fileArray: any) => (
                <div
                  className='files-show-message-hover'
                  style={{
                    border: '1px solid #01A9DB',
                    maxWidth: '12rem',
                    borderRadius: '5px',
                    display: 'flex',
                    gap: '5px',
                    padding: '5px',
                    cursor: 'pointer',
                    height: '2rem'
                  }}>
                  <div
                    className='flex gap-4 mr-2 ml-2 file-label'
                    onClick={() => { handleViewer(fileArray)
                      setHide(true)
                     }}>
                    <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                    {fileArray.title &&
                      <p style={{ color: '#01A9DB', margin: '0', fontSize: '1rem' }}>
                        {`${fileArray.title.slice(0, 10)}${fileArray.title.length > 10 ? '...' : ''}`}
                      </p>}
                  </div>
                  <p className='mr-2' onClick={() => { deleteTarget(fileArray) }} style={{ color: '#01A9DB', margin: '0', fontSize: '1rem' }}>X</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      {showSelectFiles ? (
        <></>
      ) : null}
      {showShareFiles ? (
        <>
          <AutoShares setShared={setShared} />
          <br />
        </>
      ) : null}
      <br />
      <div className='flex justify-between hide-component'>
        <button
          className='button-volver'
          style={{
            width: '8rem',
            display: 'flex',
            gap: '5px'
          }}
          onClick={() => {
            userRefetch()
            handleCloseMessage
            setShowMessage(false)
            setShowMessageRows(true)
            setReplyMessage(false)
            setComposeData(initialReplyData)
          }}>
          <img src={Arrowleft} alt="Arrowleft" />
          <p className='meet-text2' style={{ margin: '0' }}>VOLVER</p>
        </button>
        {loading === true ? <>
          <button style={{
            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
            border: '1px solid white',
            backgroundColor: '#2CD673',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
            onClick={() => {
              /*  handleSubmit() */
            }} className='button4'>
            <b className='meet-text3'>CARGANDO </b>
            <img style={{ width: '25px', height: '25px', color: 'white' }}
              src={load} alt="ArrowTop" />
          </button></>
          : <>
            {newContent !== '' ?
              <div className='flex gap-4 pb-8'>
                <button
                  className='button-volver'
                  onClick={handleSubmitDraft}>
                  <p className='meet-text2 hide-component'
                    style={{ margin: '0' }}>GUARDAR BORRADOR</p>
                </button>
                <button
                  style={{
                    boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                    border: '1px solid white',
                    backgroundColor: '#2CD673',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClick={handleSubmit} className='button-volver'>
                  <p className='meet-text3 hide-component'
                    style={{ margin: '0' }}>ENVIAR MENSAJE</p>
                  <img
                    src='/media/svg/menu-icons/Send.svg'
                    alt='Via estudios'
                    style={{
                      width: '25px',
                      height: '25px',
                    }} />
                </button>
              </div> :
              <div className='flex gap-4 pb-8'>
                <button
                  className='button-volver'
                  style={{ border: '1px solid #8899A8', backgroundColor: '#DFE4EA' }}>
                  <p className='meet-text2 hide-component'
                    style={{ margin: '0', color: '#8899A8' }}>GUARDAR BORRADOR</p>
                </button>
                <button
                  style={{
                    boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                    border: '1px solid #8899A8',
                    backgroundColor: '#DFE4EA',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  className='button-volver'>
                  <p className='meet-text3 hide-component'
                    style={{ margin: '0', color: '#8899A8' }}>ENVIAR MENSAJE</p>
                </button>
              </div>}
          </>}
      </div>
    </div>
  )
}

export default observer(MessageContent)
