/* eslint-disable react-hooks/exhaustive-deps */
 
import React, {useContext, useEffect, useState} from 'react'
import {type Category} from '../../../../app/modules/apps/cart/core/category/_models'
import ListWrapper from '../ListWrapper'
import {RootStoreContext} from '../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {useParams} from 'react-router-dom'

const CategoryWrapper = () => {
  const rootStore = useContext(RootStoreContext)
  const {categories} = rootStore.cartStore

  const [currentCategory, setCurrentCategory] = useState<Category | null>(null)
  const {categorySlug} = useParams()

  useEffect(() => {
    if (categories.length > 0) {
      categories.find((category) => {
        if (category.link === categorySlug) {
          setCurrentCategory(category)
        }
      })
    }
  }, [categorySlug])

  return (
    <>
      <h1>{currentCategory?.name}</h1>
      {currentCategory != null && <ListWrapper category={currentCategory} />}
    </>
  )
}

export default observer(CategoryWrapper)
