
import { Dispatch, SetStateAction } from 'react'

import Grid2 from './userDashboardIcons/grid-2-plus2.svg'
import Plane from './userDashboardIcons/Plane.svg'
import Graduation from './userDashboardIcons/graduation.svg'
import Briefcase from './userDashboardIcons//briefcase-2-black.svg'


interface IProps {
  hide: Dispatch<SetStateAction<boolean>>
}

const CardManager: React.FC<IProps> = ({hide}) => {
  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    hide(false);
  };
  return (
    <div className='card-manager-container' onClick={handleClose}>
      <div className='card-manager-main' onClick={(e) => e.stopPropagation()}>
        <div className='flex flex-row gap-2'>
          <img src={Grid2} alt="Grid2" />
          <h1>Personaliza los widgets de tu dashboard</h1>
          <button style={{ fontSize:'0.7rem',position:'relative', top:'-25px', right:'-5px' ,display:'flex', alignItems:'center', justifyContent:'center',padding:'3px 5px', width:'20px', height:'20px', border:'1px solid', borderRadius:'50%' }} onClick={()=>{
            hide(false)
          }}>X</button>
        </div>
        <p style={{fontSize:'0.8rem'}}>Tienes 4 widgets activos en tu dashboard.</p>
        <div style={{paddingBottom:'0.5rem', marginBottom:'1rem', borderBottom:'1px solid' }}>
          <h1 style={{fontSize:'0.8rem' , marginBottom:'1rem'}}>Widgets propios de la app</h1>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'3px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Mensajes</h2>
              <p style={{fontSize:'0.7rem'}}>Tienes 5 mensajes</p>
            </div>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'3px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Tus Power-ups</h2>
              <p style={{fontSize:'0.7rem'}}>Existe 1 proceso activo</p>
            </div>
          </div>
        </div>
        <div style={{paddingBottom:'0.5rem', marginBottom:'1rem', borderBottom:'1px solid' }}>
          <div className='flex flex-row gap-2 mb-2'>
           <img style={{width:'14px'}} src={Plane} alt="plane" />
            <h1 style={{fontSize:'0.8rem'}}>Widgets de Inmigración</h1>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'3px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Proceso migratorio</h2>
              <p style={{fontSize:'0.7rem'}}>Existe 1 proceso activo</p>
            </div>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'3px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Solicitud de Visa</h2>
              <p style={{fontSize:'0.7rem'}}>Sin procesos activos</p>
            </div>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'3px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Settlement</h2>
              <p style={{fontSize:'0.7rem'}}>Sin procesos activos</p>
            </div>
          </div>
        </div>
        <div style={{paddingBottom:'0.5rem', marginBottom:'1rem', borderBottom:'1px solid' }}>
          <div className='flex flex-row gap-2 mb-2'>
           <img style={{width:'14px'}} src={Graduation} alt="Graduation" />
            <h1 style={{fontSize:'0.8rem'}}>Widgets de Educación</h1>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'5px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Asesoría Educativo</h2>
              <p style={{fontSize:'0.7rem'}}>Sin procesos activos</p>
            </div>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'5px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Registro académico</h2>
              <p style={{fontSize:'0.7rem'}}>Sin procesos activos</p>
            </div>
          </div>
        </div>
        <div>
          <div className='flex flex-row gap-2 mb-2'>
           <img style={{width:'14px'}} src={Briefcase} alt="Briefcase" />
            <h1 style={{fontSize:'0.8rem'}}>Widgets de Trabajo</h1>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'5px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Recruitment</h2>
              <p style={{fontSize:'0.7rem'}}>Existen 2 procesos activos</p>
            </div>
          </div>
          <div className='flex flex-row gap-2 items-start'>
            <input style={{marginTop:'5px'}} type="checkbox" />
            <div>
              <h2 style={{fontSize:'0.7rem', marginBottom:'0.1rem'}}>Traducciones</h2>
              <p style={{fontSize:'0.7rem'}}>Existen 2 procesos activos</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardManager;
