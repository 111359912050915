/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'

interface IProps {
  iframe: string
}

const IframeCalendly: React.FC<IProps> = ({iframe}) => {
  console.log('🚀 ~ file: index.tsx:10 ~ iframe', typeof iframe)
  const [height, setHeight] = useState(window.innerHeight - 70)
  const updateDimensions = () => {
    let width = window.innerWidth
    const height = window.innerHeight - 400
    if (width >= 960) {
      width = 960
    }

    setHeight(height)
  }

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', iframe.toString())
    head?.appendChild(script)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])
  return (
    <>
      <iframe title='title' src={iframe.toString()} width='100%' height={height}></iframe>
    </>
  )
}

export default IframeCalendly
