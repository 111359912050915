/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import {Space, Table} from 'antd'
import {Button} from '../../../../../components'
import {useQuery} from 'react-query'
import Column from 'antd/lib/table/Column'
import {useIntl} from 'react-intl'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {SurveyClient} from './surveyClient'
import {getForms} from '../../../../../app/modules/apps/forms/core/_requests'
import {type Forms} from '../../../../../app/modules/apps/forms/core/_models'
import {type Contracts} from '../../../../../app/modules/apps/contract/_models'

interface IProps {
  formsusersData?: string
  visaType: string
  handleClose: (key: string) => void
  contracts?: Contracts[]
}
const ListFormsClientAuto: React.FC<IProps> = ({
  formsusersData,
  visaType,
  handleClose,
  contracts,
}) => {
  const [showSurvey, setShowSurvey] = useState(false)
  const [jsonDataSelected, setJsonDataSelected] = useState({})
  const [formNameSelected, setformNameSelected] = useState('')
  const [visaTypeSelected, setvisaTypeSelected] = useState(visaType)
  const [formsDataIdSelected, setFormsDataIdSelected] = useState('')
  const [selected, setSelected] = useState<Forms[]>([])
  const [showListFormsClientTable, setShowListFormsClientTable] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const intl = useIntl()
  const {user} = rootStore.authStore

  useEffect(() => {
    if (user?.role?.name === 'User') {
      setShowListFormsClientTable(true)
    }
    console.log(visaType + ' en state')
  }, [user, selected, visaType])
  const {
    data: dataResult,
    status,
    refetch,
    isSuccess,
    isLoading,
  } = useQuery('listformsclientssAutso', async () => await getForms('limit=50&page=1&name='))
  const handleStartForm = (recordData: object, formName: string, formsDataId: string) => {
    setShowSurvey(true)
    setJsonDataSelected(recordData)
    setformNameSelected(formName)
    setFormsDataIdSelected(formsDataId)
  }
  if (showSurvey) {
    return (
      <>
        <SurveyClient
          userId={`${user?.id}`}
          formId={formNameSelected}
          surveyJson={jsonDataSelected}
          formsDataIdSelected={formsDataIdSelected}
        />
        <Button
          className='mb-3 '
          onClick={() => {
            handleClose('menu')
          }}
        >
          Regresar
        </Button>
      </>
    )
  } else {
    if (user !== undefined && contracts && contracts !== null && contracts[0] !== undefined) {
      if (showListFormsClientTable) {
        return (
          <>
            {selected ? (
              <Table
                dataSource={dataResult?.filter(
                  (form) => form.category === visaType || form.category === 'visageneral'
                )}
                rowKey='id'
              >
                <Column
                  title={intl.formatMessage({id: 'MYINFO.FORMSNAME'})}
                  key='action'
                  render={(text, record: any) => (
                    <Space>
                      <Space>{record.name}</Space>
                    </Space>
                  )}
                />
                <Column
                  title={intl.formatMessage({id: 'MENU.ACTIONS'})}
                  key='action'
                  render={(text, record: any) => (
                    <Space>
                      <Space>
                        <Button
                          type='link'
                          onClick={() => {
                            handleStartForm(record.data, record.id, record.formsdataid)
                          }}
                          style={{color: 'green', fontSize: '14px', border: '1px solid'}}
                        >
                          {intl.formatMessage({id: 'LISTFORMS.FILLTHEFORM'})}
                        </Button>
                      </Space>
                    </Space>
                  )}
                />
              </Table>
            ) : null}
            <Button
              className='mb-3 '
              onClick={() => {
                handleClose('menu')
              }}
            >
              Regresar
            </Button>
          </>
        )
      } else {
        return <h1> {intl.formatMessage({id: 'LISTFORMS.YOUARENOTCLIENT'})}</h1>
      }
    } else {
      return <h1> {intl.formatMessage({id: 'LISTFORMS.USERISUNDEFINED'})}</h1>
    }
  }
}
export {ListFormsClientAuto}
