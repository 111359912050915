

function FirstLogging() {

  return (
    <div style={{marginTop:'5rem'}} className="first-log">
      <div className="flex flex-row items-start gap-4">
        <div className="checkbox-green-continer">
          <div className="checkbox-green">
            &#x2713;
          </div>
        </div>
        <div className="flex flex-col">
          <h1 style={{ color: '#004434', fontWeight: 'bold', fontSize: '18px' }}>¡Este es su primer ingreso!</h1>
          <p style={{ color: '#637381' }}>Le recomendamos iniciar con su proceso migratorio ¡Estamos listos para empezar!</p>
        </div>
      </div>
    </div>
  )
}

export default FirstLogging;
