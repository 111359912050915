 
import {Button, Space, Table} from 'antd'
import React, { useState } from 'react'
import {
  getFormsProducts,
  softDeleteFormsProduct,
} from '../../../../../../app/modules/apps/forms/formproducts/core/_requests'
import Column from 'antd/lib/table/Column'
import {type FormsProduct} from '../../../../../../app/modules/apps/forms/formproducts/core/_models'
import {type Product} from '../../../../../../app/modules/apps/cart/core/products/_models'
import {useQuery} from 'react-query'
import checkmark_white from '../../../../contratationApp/icons/checkmark_white.svg'
interface IProps {
  product: Product
  setFormsProducts: (formsProducts: FormsProduct[]) => void
}

const ListForms: React.FC<IProps> = ({product, setFormsProducts}) => {
  const [showToast, setShowToast] = useState(false)
  const {data, status, refetch} = useQuery(
    'ListFormProducts',
    async () => await getFormsProducts(product.id || '')
  )

  if (data != null) {
    setFormsProducts(data)
  }

  const handleDelete = async (id: string) => {
    const result = await softDeleteFormsProduct(id)
    if (result) refetch()
      if (result) {
        setShowToast(true)
        setTimeout(() => {
          setShowToast(false)
        }, 3000)
      }
  }

  return (
    <div>
      {showToast === true ?
        <div className='toast-style-container'>
          <div className='toast-style'>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
           Eliminado
          </div>
        </div> : null}
      {status === 'loading' && <div>Loading...</div>}
      {status === 'success' && data != null && (
        <div>
          <Table dataSource={data} rowKey='id' size='small'>
            <Column
              title='Form'
              render={(text, record: any) => <Space>{record.form.name}</Space>}
            />
            <Column
              title='Descripcion'
              render={(text, record: any) => <Space>{record.form.description}</Space>}
            />
            <Column title='Orden' render={(text, record: any) => <Space>{record.order}</Space>} />
            <Column
              title='Eliminar'
              key='estado'
              render={(text, record: any) => (
                <Space>
                  <Button
                    type='link'
                    onClick={async () => {
                      await handleDelete(record.id)
                    }}
                  >
                    Eliminar
                  </Button>
                </Space>
              )}
            />
          </Table>
        </div>
      )}
    </div>
  )
}

export default ListForms
