import axios, {type AxiosResponse} from 'axios'

import {type Aplication} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const APLICATION_URL = `${API_URL}/application`
const STATUSEDUCATION_URL = `${API_URL}/statuseducation`

const getAplication = (): Promise<Aplication[]> | undefined => {
  return axios.get(`${APLICATION_URL}`).then((response: AxiosResponse) => {
    return response.data as Aplication[]
  })
}

const getAplicationByUserId = (id: string): Promise<Aplication[]> | undefined => {
  return axios.get(`${APLICATION_URL}/getById/${id}`).then((response: AxiosResponse) => {
    return response.data as Aplication[]
  })
}

const getStatusEducation = (): Promise<any> | undefined => {
  return axios.get(`${STATUSEDUCATION_URL}`).then((response: AxiosResponse) => {
    return response.data as any
  })
}

const createAplication = (aplication: any): Promise<Aplication[]> | undefined => {
  return axios
    .post(`${APLICATION_URL}/newApplication`, {...aplication})
    .then((response: AxiosResponse) => {
      return response.data as Aplication[]
    })
}

const updateAplication = (aplication: string, body: {}): Promise<Aplication> | undefined => {
  return axios
    .patch(`${APLICATION_URL}/updateApplication/${aplication}`, {...body})
    .then((response: AxiosResponse) => {
      return response.data as Aplication
    })
}

const getAplicationTableFilter = (aplication: Aplication): Promise<any> => {
  return axios.get(`${APLICATION_URL}`, {params: aplication}).then((response: AxiosResponse) => {
    return response.data // Usar response.data en lugar de "response as any"
  })
}

export {
  getAplication,
  createAplication,
  updateAplication,
  getAplicationTableFilter,
  getStatusEducation,
  getAplicationByUserId,
}
