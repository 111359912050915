import {useState} from 'react'
import axios from 'axios'
import {Aplication} from 'app/modules/apps/cart/core/aplication/_models'
import {getAplication} from 'app/modules/apps/cart/core/aplication/_request'
import CreateAplicationForm from './CreateAplicationForm'
import {EditOutlined, FilterOutlined, SearchOutlined} from '@ant-design/icons'
import EditAplicationForm from './EditAplicationForm'
import {useQuery} from 'react-query'
import {DeleteOutlined} from '@ant-design/icons'
import {Modal, Select} from 'antd'

const API_URL = process.env.REACT_APP_API_URL
const REMOVEAPLICATION_URL = `${API_URL}/application/removeApplication`
const AplicationTable = () => {
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [editAplication, setEditAplication] = useState<Aplication | null>(null)
  const [showEditForm, setShowEditForm] = useState(false)
  const {data: aplications, refetch} = useQuery('GetAplication', async () => await getAplication())
  const [showFilterModalClient, setShowFilterModalClient] = useState(false)
  const [filterClientEmail, setFilterClientEmail] = useState('')
  const [showFilterModalProgram, setShowFilterModalProgram] = useState(false)
  const [filterProgramName, setFilterProgramName] = useState('')
  const [showFilterModalInstitution, setShowFilterModalInstitution] = useState(false)
  const [filterInstitutionName, setFilterInstitutionName] = useState('')
  const [showFilterModalStatus, setShowFilterModalStatus] = useState(false)
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>([])
  const uniqueStatusValues = Array.from(
    new Set(aplications?.map((aplication) => aplication.statuseducation.name))
  )
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 20
  const startIndex = (currentPage - 1) * recordsPerPage
  const endIndex = startIndex + recordsPerPage
  const filteredApplications = aplications?.filter(
    (aplication) =>
      aplication.user.email.toLowerCase().includes(filterClientEmail.toLowerCase()) &&
      aplication.program.name.toLowerCase().includes(filterProgramName.toLowerCase()) &&
      aplication.program.institution.name
        .toLowerCase()
        .includes(filterInstitutionName.toLowerCase()) &&
      (selectedStatusFilters.length === 0 ||
        selectedStatusFilters.includes(aplication.statuseducation.name))
  )

  const totalPagesFiltered = Math.ceil((filteredApplications?.length || 0) / recordsPerPage)

  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm)
  }
  const handleAplicationCreated = () => {
    /*  fetchInstitutions();  */
    refetch()
    setShowTable(true)
    setShowCreateForm(false)
  }

  const handleEditClick = (aplication: Aplication) => {
    setEditAplication(aplication)
    setShowEditForm(true)
    setShowTable(false)
  }

  const handleEditSave = () => {
    setShowEditForm(false)
    setShowTable(true)
    refetch()
    /*  fetchInstitutions(); */ // Actualizar la lista de instituciones después de editar
  }

  const handleDeleteClick = async (aplication: Aplication) => {
    try {
      await axios.delete(`${REMOVEAPLICATION_URL}/${aplication.id}`)
      refetch()
    } catch (error) {
      console.error('Error deleting aplication:', error)
    }
  }

  return (
    <div>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '2rem',
        }}
        className='text-2xl font-semibold mb-4'
      >
        Tabla Registro
      </h2>
      <button
        style={{marginBottom: '2rem', marginLeft: '2rem'}}
        className='submit-button2'
        onClick={() => {
          toggleCreateForm()
          setShowTable(false)
        }}
      >
        Crear Registro
      </button>
      {showCreateForm && (
        <CreateAplicationForm
          showTable={showTable}
          setShowTable={setShowTable}
          onInstitutionCreated={handleAplicationCreated}
        />
      )}
      {showTable && (
        <div className='overflow-x-auto'>
          <table className='mt-4 min-w-full bg-white border rounded-md shadow-sm'>
            <thead className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'>
              <tr>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Client</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalClient(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Client Email'
                    visible={showFilterModalClient}
                    onCancel={() => setShowFilterModalClient(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by client email'
                      value={filterClientEmail}
                      onChange={(e) => setFilterClientEmail(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Status</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalStatus(true)}
                    >
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Status'
                    visible={showFilterModalStatus}
                    onCancel={() => setShowFilterModalStatus(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Filter by status'
                      value={selectedStatusFilters}
                      onChange={(selectedValues) => setSelectedStatusFilters(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueStatusValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Program</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalProgram(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Program'
                    visible={showFilterModalProgram}
                    onCancel={() => setShowFilterModalProgram(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by client Program'
                      value={filterProgramName}
                      onChange={(e) => setFilterProgramName(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Institution</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalInstitution(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Institution'
                    visible={showFilterModalInstitution}
                    onCancel={() => setShowFilterModalInstitution(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by client Program'
                      value={filterInstitutionName}
                      onChange={(e) => setFilterInstitutionName(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredApplications?.slice(startIndex, endIndex).map((aplication) => (
                <tr key={aplication.id}>
                  <td className='px-4 py-2'>{aplication.user.email}</td>
                  <td className='px-4 py-2'>{aplication.statuseducation.name}</td>
                  <td className='px-4 py-2'>{aplication.program.name}</td>
                  <td className='px-4 py-2'>{aplication.program.institution.name}</td>
                  {/* ... rest of the table rows */}
                  <td className='px-4 py-2'>
                    <button className='edit-button' onClick={() => handleEditClick(aplication)}>
                      <EditOutlined />
                    </button>
                  </td>
                  <td className='px-4 py-2'>
                    <button className='delete-button' onClick={() => handleDeleteClick(aplication)}>
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='pagination'>
            <button
              className='pagination-button'
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              {currentPage}/{totalPagesFiltered}
            </span>
            <button
              className='pagination-button'
              disabled={currentPage === totalPagesFiltered}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {showEditForm && editAplication !== null && (
        <EditAplicationForm editAplication={editAplication} onSave={handleEditSave} />
      )}
    </div>
  )
}

export default AplicationTable
