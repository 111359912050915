import {type FC, useContext} from 'react'
import {Container} from '../../../../components/Container/index'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getStaff} from 'app/modules/apps/staff/core/_requests'

const AdminTranslateWrapper: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {data: dataStaff} = useQuery(
    'getAllStaffssss',
    async () => await getStaff(user?.id ? user.id : '')
  )
  return (
    <>
      <Container title={intl.formatMessage({id: 'FORMSMANAGER.ADMINTOOLS'})}>
        <div className='flex gap-4 justify-center flex-wrap'>
          {dataStaff &&
            user?.role?.id === 1 &&
            (dataStaff[0].type === 'translation_admin' ||
              dataStaff[0].type === 'finance' ||
              dataStaff[0].type === 'coo') && (
              <Link
                className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
                to='/translationadmin'
              >
                <img className='w-32' src='/media/svg/menu-icons/Webinar.svg' alt='' />
                <h2>Administrar</h2>
              </Link>
            )}
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/translationstaff'
          >
            <img className='w-32' src='/media/svg/menu-icons/traducc_espanol_express.svg' alt='' />
            <h2>Mis traducciones</h2>
          </Link>

          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/translationstaffsatisfaction'
          >
            <img className='w-32' src='/media/svg/menu-icons/traducc_espanol_express.svg' alt='' />
            <h2>Formularios de satisfaccion</h2>
          </Link>
        </div>
      </Container>
    </>
  )
}

export {AdminTranslateWrapper}
