import {type calendlyModel} from './_models'

export const generateCalendlyProps = (
  join_url: string,
  start_time: string,
  name: string,
  end_time?: string,
  uri?: string,
  created_at?: string,
  updated_at?: string
) => {
  const props: calendlyModel = {
    uri: uri || '',
    name,
    status: 'active',
    join_url,
    created_at: created_at || '',
    end_time: end_time || '',
    start_time,
    updated_at: updated_at || '',
    location_type: 'google_conference',
  }

  return props
}
