 
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { type FC, useContext } from 'react'
import { Container } from '../../../components/Container/index'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { UserModel } from 'auth/models/User/UserModel'
import { getLibraryFiles } from 'app/modules/apps/library/core/_requests'
import { getAllTranslationUser } from 'app/modules/apps/translations/core/_requests'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import { getTimeLineByUserId } from 'app/modules/apps/timeline/_requests'

interface IProps {
  user: UserModel | undefined
}
const UserServicesWrapper: FC<IProps> = ({ user }) => {
  const rootStore = useContext(RootStoreContext)
  const { contracts, loadingContracts } = rootStore.authStore
  const intl = useIntl()
  //check if has translations
  const { data: translationData } = useQuery(
    'getTransList',
    async () => await getAllTranslationUser()
  )
  //check if has files in the library
  const { data: libraryData } = useQuery(
    'getLibraryList',
    async () => await getLibraryFiles(user?.id!)
  )
  const { data } = useQuery(
    'getTimeLine',
    async () => {
      const timelineData = await getTimeLineByUserId(user && user?.id ? user.id.toString() : '')
      return timelineData
    },
    { enabled: user !== undefined, cacheTime: 0 }
  )

  return (
    <>
      <Container title={intl.formatMessage({ id: 'MENU.MYSERVICES' })}>
        <div className='flex gap-4 justify-center flex-wrap'>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/appointments'
          >
            <img
              className='w-32'
              src='/media/svg/menu-icons/consulta_regular_migratorio.svg'
              alt=''
            />
            <h2>{intl.formatMessage({ id: 'MENU.MYAPPOINTMENTS' })}</h2>
          </Link>
          {/*  <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/feedbackform'
          >
            <img
              className='w-42'
              src='/media/svg/menu-icons/satisfaction-icon.svg'
              alt=''
            />
            <h2>{intl.formatMessage({id: 'Formulario de satisfaccion'})}</h2>
          </Link>
 */}
          {libraryData && libraryData?.length > 0 && (
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/library'
            >
              <img className='w-32' src='/media/svg/menu-icons/Traduccion_espanol.svg' alt='' />
              <h2>{intl.formatMessage({ id: 'MENU.LIBRARY' })}</h2>
            </Link>
          )}
          {translationData && translationData.length > 0 && (
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/translation'
            >
              <img
                className='w-32'
                src='/media/svg/menu-icons/traducc_espanol_express.svg'
                alt=''
              />
              <h2>{intl.formatMessage({ id: 'MENU.TRANSLATION' })}</h2>
            </Link>
          )}

          {data && data.length > 0 && (
            <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to='/timeline'>
              <img className='w-32' src='/media/svg/menu-icons/timeline.svg' alt='' />
              <h2>{intl.formatMessage({ id: 'MENU.TIMELINE' })}</h2>
            </Link>
          )}
          {/*  {loadingContracts === true && <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src='/media/logos/ezgif.com-resize.gif' style={{ width: '185px' }} alt='' />{' '}
            <div>Cargando...</div>
          </div>}
          {loadingContracts === false && contracts && contracts.length > 0 && (
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/myvisa/proccess'
            >
              <img className='w-32' src='/media/svg/menu-icons/Pickup_airport.svg' alt='' />
              <h2>{intl.formatMessage({ id: 'MENU.MYVISA' })}</h2>
            </Link>
          )}  */}
        </div>
      </Container>
    </>
  )
}

export default observer(UserServicesWrapper)
