export const auth = {
  'AUTH.GENERAL.OR': 'o',
  'AUTH.GENERAL.SUBMIT_BUTTON': 'Enviar',
  'AUTH.GENERAL.NO_ACCOUNT': 'No tienes una cuenta?',
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Regístrate',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Se te olvidó tu contraseña',
  'AUTH.GENERAL.BACK_BUTTON': 'Atrás',
  'AUTH.GENERAL.PRIVACY': 'Intimidad',
  'AUTH.GENERAL.LEGAL': 'Legal',
  'AUTH.GENERAL.CONTACT': 'Contacto',
  'AUTH.LOGIN.TITLE': 'Crear una cuenta',
  'AUTH.LOGIN.BUTTON': 'Registrarse',
  'AUTH.FORGOT.RESENDEMAIL': 'Se enviará a',
  'AUTH.FORGOT.SENDEMAIL': 'Hemos notificado a',
  'AUTH.FORGOT.RESENDEMAILCONFIRM': 'Enviar',
  'AUTH.FORGOT.RESENDEMAILCANCEL': 'Cancelar',
  'AUTH.FORGOT.TITLE': 'Contraseña olvidada?',
  'AUTH.FORGOT.DESC': 'Ingrese su correo electrónico para restablecer su contraseña',
  'AUTH.FORGOT.SUCCESS': 'Su cuenta ha sido reestablecida exitosamente.',
  'AUTH.REGISTER.TITLE': 'Registrarse',
  'AUTH.REGISTER.DESC': 'Ingrese su informacion para crear una cuenta',
  'AUTH.REGISTER.SUCCESS': 'Su cuenta ha sido registrada exitosamente.',
  'AUTH.INPUT.EMAIL': 'Email',
  'AUTH.INPUT.FULLNAME': 'Nombre Completo',
  'AUTH.INPUT.PASSWORD': 'Contraseña',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Confirmar Contraseña',
  'AUTH.INPUT.USERNAME': 'Usuario',
  'AUTH.VALIDATION.INVALID': '{name} no es valido',
  'AUTH.VALIDATION.REQUIRED': '{name} es requerido',
  'AUTH.VALIDATION.MIN_LENGTH': '{name}} longitud minima es {{min}',
  'AUTH.VALIDATION.AGREEMENT_REQUIRED': 'Aceptar terminos y condiciones es requeridos',
  'AUTH.VALIDATION.NOT_FOUND': 'El requerido {name} no ha sido encontrado',
  'AUTH.VALIDATION.INVALID_LOGIN': 'El detalle del registro es incorrecto',
  'AUTH.VALIDATION.REQUIRED_FIELD': 'Campo requerido',
  'AUTH.VALIDATION.MIN_LENGTH_FIELD': 'Longitud de campo minima:',
  'AUTH.VALIDATION.MAX_LENGTH_FIELD': 'Longitud de campo maxima:',
  'AUTH.VALIDATION.INVALID_FIELD': 'El campo no es valido',
  'AUTH.VALIDATION.PASSWORD': 'Contraseña es requerida',
  'AUTH.VALIDATION.MINSYMBOLS': 'Minimo tres simbolos',
  'AUTH.VALIDATION.MAXSYMBOLS': 'Maximo tres simbolos',
  'AUTH.VALIDATION.CONFIRMPASSWORD': 'Confirmar Contraseña es requerida',
  'AUTH.VALIDATION.CONFIRMPASSWORDMATCH': 'Confirmar Contraseña y Contraseña deben ser iguales',
  'AUTH.VALIDATION.SAVE': 'Guardar',
  'AUTH.VALIDATION.SAVESUCCESS': 'Guardado correctamente',
  'AUTH.VALIDATION.SAVERROR': 'Error',
  'AUTH.VALIDATION.WRITEYOURPASSWORD': 'Digite su nueva contraseña y confirmacion de contraseña',
}
