/* eslint-disable @typescript-eslint/no-unused-vars */
 

import {Alert, Col, Form, Input, Row, Switch} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {Button} from '../../../../components'
import JoditEditor from 'jodit-react'
import {type Templates} from '../../../../app/modules/apps/templates/core/_models'
import {useIntl} from 'react-intl'

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }

  return e && e.fileList
}

export interface ISurveyProps {
  onComplete: (survey: any) => void
  data?: Templates
}

const AddTemplate: React.FunctionComponent<ISurveyProps> = (props) => {
  const intl = useIntl()

  const editor = useRef(null)
  const {onComplete, data} = props
  const [content, setContent] = useState('')
  const [name, setName] = useState(data?.name || '')
  const [autoShare, setAutoShare] = useState(false)

  const [validate, setValidate] = useState(false)

  useEffect(() => {
    if (data?.text) {
      setContent(data.text)
    }
  }, [data])

  const handleSubmit = () => {
    // alert('The form was submitted')
    // setValidate(false)

    if (!content) {
      return
    }

    const formData: Templates = {
      id: data?.id || null,
      name,
      text: content,
      autoShare,
    }

    onComplete(formData)
  }

  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ol",
    "ul",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "|",
    "image",
    "link",
    "table",
    "|",
    "hr",
    "|",
    "fullsize",
    "selectall",
    "print",
    "|"
  ];

  const config = {
    minHeight: 300,
    size: 'large',
    readonly: false,
    spellcheck: true,
    buttons: buttons,
    indentMargin: 30,
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    tabIndex: -1,
    language: 'es',
    debugLanguage: false,
  }


  return (
    <>
      <Row>
        {/* {data?.id} */}
        <Col span={24}>
          <Form>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              initialValue={name}
              name='name'
              label={intl.formatMessage({id: 'TEMPLATES.TEMPLATENAME'})}
              style={{width: '100%'}}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{width: '100%'}}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({id: 'TEMPLATES.TEMPLATE'})}
              wrapperCol={{
                span: 24,
              }}
            >
              {validate ? (
                <Alert message='Template is required' type='warning' showIcon closable />
              ) : null}

              <Form.Item name='text' valuePropName='fileList' getValueFromEvent={normFile} noStyle>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={(newContent) => {
                    setContent(newContent)
                  }}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name='autoShare'
              label={intl.formatMessage({id: 'TEMPLATES.SHARE'})}
              valuePropName='checked'
            >
              <Switch
                defaultChecked={data?.autoShare ? data?.autoShare : false}
                onChange={(checked) => {
                  setAutoShare(checked)
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                onClick={() => {
                  handleSubmit()
                }}
              >
                {intl.formatMessage({id: 'TEMPLATES.SAVE'})}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export {AddTemplate}
