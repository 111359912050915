import React, {useContext, useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import {RootStoreContext} from '../../../stores/rootStore'

interface IProps {
  date: Date
}

const DateTimeZone: React.FC<IProps> = ({date}) => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const [zone, setZone] = useState<string>('Canada/Saskatchewan')

  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    } else {
      setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }, [user])
  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, {zone}).toFormat('yyyy-MM-dd HH:mm:ss a')

    return keepOffset.toString()
  }
  return <> {getDate(date)}</>
}

export default DateTimeZone
