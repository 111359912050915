/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getDocuments } from 'app/modules/apps/intake/_requests'
import {
  deleteUserDocumentsUsers,
  getDocumentsbyContractId,
  updateDocComments,
  updateDocStatus,
} from 'app/modules/apps/userDocument/_requests'
import { Users } from 'app/modules/apps/users/core/_models'
import DocumentView from './DocumentView'
import { downloadFileLibrary } from 'app/modules/apps/library/core/_requests'
import PhotoView from './PhotoView'
import {
  sendEmailForCaseWorkerReviewAproved,
  sendEmailForCaseWorkerReviewRejected,
} from 'app/modules/apps/users/core/_requests'
import { uploadNoFileIntake, uploadNoFileIntakeProduct } from 'app/modules/apps/file/core/_requests'
import { getAllCompletedContractsByEmail, updateContracts } from 'app/modules/apps/docusign/_requests'
import { PdfView } from '../formsManagerClient.tsx/pdfView'
import { RootStoreContext } from 'stores/rootStore'
import { getDocumentsByProducts } from 'app/modules/apps/immigration/requirements/core/_requests'
import { getContractByUser, getContractByUserEmailAllTable } from 'app/modules/apps/contract/_requests'
import Swal from 'sweetalert2'
import ChevronRightIcon from '../../../dashboard/userDasboardComponents/userDashboardIcons/chevron-right.svg'
import addfilesIcon from '../../../admin/users/UserMenusoptions/NewIntakeView/Icons/add-files.svg'
import addfilesIcon2 from '../../../admin/users/UserMenusoptions/NewIntakeView/Icons/bell-alt.svg'
import World from '../../../contratationApp/icons/world.svg'
import Pencil from '../../../contratationApp/icons/signature.svg'
import Clock from '../../../contratationApp/icons/clock-alt-1.svg'
import Briefcase from '../../../admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-4.svg'
import DeleteFrom from '../../../admin/users/UserMenusoptions/NewIntakeView/Icons/circle-minus.svg'
import Send from '../../../contratationApp/icons/Sendplane.svg'
import signatureblack from '../../../contratationApp/icons/signature-black.svg'

interface IProps {
  user: Users | null
  contractsFound: string
  contract: any
}

const DocumentRequirenmentList: React.FC<IProps> = ({ user, contractsFound, contract }) => {
  const rootStore = useContext(RootStoreContext)
  const { updatingContracts } = rootStore.authStore
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openFormCompleted, setOpenFormCompleted] = useState<boolean>(false)
  const [documentId, setDocumentId] = useState<string>('')
  const [productId, setProductId] = useState<string>('')
  const [active3, setActive3] = useState<boolean>(false)
  const [active4, setActive4] = useState(0)
  const [active2, setActive2] = useState(false)
  const [documentsByMember, setDocumentsByMember] = useState<any[]>([])
  const [lookDocument, setLookDocument] = useState<any>()
  const [lookImage, setLookImage] = useState<any>()
  const [porcentageBase, setPorcentageBase] = useState(0)
  const [completedLenght, setCompletedLenght] = useState(0)
  const [pendingDocuments, setPendingDocuments] = useState<boolean>(false)
  const [reviewingDocuments, setReviewingDocuments] = useState<boolean>(false)
  const [aprovedDocuments, setAprovedDocuments] = useState<boolean>(false)
  const [rejectedDocuments, setRejectedDocuments] = useState<boolean>(false)
  const [allDocuments, setAllDocuments] = useState<boolean>(true)
  const [numDraft, setNumDraft] = useState(-1)
  const [numAproved, setNumAproved] = useState(-1)
  const [numReview, setNumReview] = useState(-1)
  const [numPending, setNumPending] = useState(-1)
  const [loading, setLoading] = useState(true);
  const [usrfound, setUsrFound] = useState(false);
  const [listProductFound, setListProductFound] = useState<any>();
  const [staffEmail, setStaffEmail] = useState<any>();
  const { contracts } = rootStore.authStore
  const [nameSelected, setNameSelected] = useState<string>('')
  const [isHovered, setIsHovered] = useState(false)
  const [typeSelected, setTypeSelected] = useState<string>('')
  const [Alertvalidation, setAlertValidation] = useState(false)
  const [switch1, setSwitch1] = useState(0)
  const [active1, setActive1] = useState(false)
  const [error, setError] = useState(false)
  const [openManual, setOpenManual] = useState<boolean>(false)
  const [docStatus, setDocStatus] = useState('')
  const [comment, setComment] = useState('')
  const [currentId, setcurrentId] = useState('')
  const [showUserRepresentative, setShowUserRepresentative] = useState<any>('')
  const documentData: any[] = []
  const [buttonStates, setButtonStates] = useState<number | null>(null);

  const { data: dataUserRep, isFetching: ifuseofrep } = useQuery(
    'getAllContractCompletedByEmailxxx',
    async () =>
      await getAllCompletedContractsByEmail(
        user?.email!,
        user?.createdAt?.toString()!,
        '0000-00-00'
      ),
    { retry: false }
  )

  const {
    data: documentByContract,
    status: userDocStatus,
    refetch: docRefetch,
    isFetching
  } = useQuery('getDocumentByContract', async () => await getDocumentsbyContractId(contract.contracts_users_envelope_id), {
    enabled: Boolean(contract.contracts_users_envelope_id !== undefined),
    cacheTime: 0
  })

  const { data, status, refetch, isFetching: isFetchingDocument } = useQuery(
    'documents',
    async () => {
      if (listProductFound && listProductFound.length > 0) {
        return await getDocumentsByProducts(listProductFound);
      }
    },
    {
      enabled: Boolean(listProductFound && listProductFound.length > 0),
      cacheTime: 0
    });

  const { data: dataFromDatabase, status: contractByUserStatus } = useQuery(
    'allcontrDb',
    async () => await getContractByUser(user?.id!), {
    enabled: (user?.id !== undefined),
    cacheTime: 0
  })

  const { data: contractsByUserEmail } = useQuery(
    'allcontrDasdb',
    async () => await getContractByUserEmailAllTable(user?.id!), {
    enabled: (user?.id !== undefined),
    cacheTime: 0
  })

  const { data: docData, status: statusDocData, refetch: refetchDoc } = useQuery('getdocss', async () => await getDocuments())

  function groupProductsByMember(products: any) {
    const groupedProducts = products.reduce((groupedProducts: any, product: any) => {
      const { memberName, memberType, productId, productName } = product;
      const key = `${memberName}-${memberType}`;
      if (!groupedProducts[key]) {
        groupedProducts[key] = {
          memberName,
          memberType,
          products: [],
        };
      }
      groupedProducts[key].products.push({
        productId,
        productName,
      });
      return groupedProducts;
    }, {});
    const result = Object.values(groupedProducts);
    return result;
  }

  const setStatus = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        status: 0,
      }
    } else {
      dataContractData = {
        status: 0,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  useEffect(() => { }, [])
  useEffect(() => {
    if (contract && contract.contracts_users_list_products) {
      setListProductFound(JSON.parse(JSON.stringify(contract.contracts_users_list_products)))
    }
    else if (contract && contract.contracts_users_applicants) {
      const productIdArray = JSON.parse(contract.contracts_users_applicants).map((item: any) => { return item.productId });
      setListProductFound(productIdArray)
    }
    if (listProductFound && listProductFound.length > 0) {
      refetch()
    }

  }, [contract])

  useEffect(() => {
    if (listProductFound && listProductFound.length > 0) {
      refetch()
      docRefetch()
    }
  }, [listProductFound])

  useEffect(() => {
    if (status === 'success') {
      setLoading(false);
    }
    if (contractsByUserEmail && contractsByUserEmail.data && contractsByUserEmail.data.length > 0) {
      const tieneMatterUseofrep = contractsByUserEmail.data.some((elemento: any) => elemento.hasOwnProperty('matter') && elemento.matter === 'useofrepresentative');
      const contractFound = contractsByUserEmail.data.find((elemento: any) => elemento.hasOwnProperty('envelope_id') && elemento.envelope_id === contractsFound);
      if (tieneMatterUseofrep) {
        setUsrFound(true)
      }
      if (contractFound && contractFound.staffId) {

        setStaffEmail(contractFound.staffId.user.email)
      }
    }
  }, [status, contractByUserStatus, userDocStatus]);

  const toggleContent = (index: number) => {
    setButtonStates(prevIndex => (prevIndex === index ? null : index));
  };

  const handleToggleContent = (index: number) => {
    toggleContent(index);
  };
  useEffect(() => {
    if (data && dataFromDatabase && documentByContract && isFetching === false && isFetchingDocument === false) {
      const updatedUserData: any = [...data];
      const documentsPerMember: any = []
      dataFromDatabase && dataFromDatabase.data.forEach((cont: any) => {
        if (contractsFound === cont.contracts_users_envelope_id) {
          const memberInfo: any = groupProductsByMember(JSON.parse(cont.contracts_users_applicants));
          const arrayFoundValidation: any = [];
          for (const eachUpdatedUserData of updatedUserData) {
            for (const eachDocument of eachUpdatedUserData) {
              for (const member of memberInfo) {
                const check = arrayFoundValidation.find((eachArrayFoundValidation: any) => {
                  if (eachArrayFoundValidation.memberName === member.memberName &&
                    eachArrayFoundValidation.memberType === member.memberType &&
                    eachDocument.id === eachArrayFoundValidation.documentId &&
                    eachDocument.product.id === eachArrayFoundValidation.productId
                  ) {
                    return true; // Si quieres mantener la lógica original del find, debes retornar true aquí
                  }
                  return false; // Si quieres mantener la lógica original del find, debes retornar false aquí
                });

                if (check === undefined &&
                  (member.products.some((producto: any) => producto.productId === eachDocument.product.id)) === true) {
                  arrayFoundValidation.push({ memberName: member.memberName, memberType: member.memberType, documentId: eachDocument.id, productId: eachDocument.product.id })
                  documentsPerMember.push({ memberName: member.memberName, memberType: member.memberType, documents: eachDocument, productName: eachDocument.product.name, productId: eachDocument.product.id })
                }
              }
            }
          }
        }
      });
      /* const groupedData = new Map();
      documentsPerMember.forEach((obj: any) => {
        const key = `${obj.memberName}_${obj.memberType}_${obj.productName}`;
        if (groupedData.has(key)) {
          groupedData.get(key).documents.push(obj.documents);
        } else {
          groupedData.set(key, {
            memberName: obj.memberName,
            memberType: obj.memberType,
            productName: obj.productName,
            productId: obj.productId,
            documents: [obj.documents],
          });
        }
      });

      const result = Array.from(groupedData.values()); */

      //START   // Array para almacenar los elementos agrupados
      const result: any = [];

      // Itera sobre cada elemento
      documentsPerMember.forEach((elemento: any) => {
        // Busca si ya existe un grupo con las mismas propiedades
        const grupoExistente = result.find((grupo: any) =>
          grupo.memberName === elemento.memberName &&
          grupo.memberType === elemento.memberType &&
          grupo.productName === elemento.productName
        );

        // Si ya existe un grupo con las mismas propiedades, agrega el documento al grupo
        if (grupoExistente) {
          // Verifica si el documento ya está presente en el grupo
          const documentoActual = elemento.documents;
          if (!grupoExistente.documents.some((doc: any) => doc.id === documentoActual.id)) {
            grupoExistente.documents.push(documentoActual);
          }
        } else {
          // Si no existe un grupo con las mismas propiedades, crea un nuevo grupo
          result.push({
            memberName: elemento.memberName,
            memberType: elemento.memberType,
            productName: elemento.productName,
            documents: [elemento.documents],
            productId: elemento.productId,
          });
        }
      });
      //END
      const alreadyFound: any = []
      result.map((eachMember: any, index: any) => {

        for (const eachDocument of documentByContract) {
          let found = false
          if (
            eachDocument.memberName === eachMember.memberName &&
            eachDocument.memberType === eachMember.memberType
          ) {
            found = true
          } else {
            found = false
          }
          if (found) {
            const isAlreadyIncluded = alreadyFound.some(
              (doc: any) =>
                doc.document.id === eachDocument.document.id &&
                doc.product.id === eachDocument.product.id &&
                doc.memberName === eachDocument.memberName &&
                doc.memberType === eachDocument.memberType
            )
            if (!isAlreadyIncluded) {
              result[index].documents.push(eachDocument)
              alreadyFound.push(eachDocument)
            }
          }
        }
      })
      const filteredArray = result.map((item: any) => {
        const files = new Set();
        const toRemove = new Set();
        item.documents.forEach((doc: any) => {
          const key = doc.document.id + '|' + doc.product.id;
          if (files.has(key)) {
            toRemove.add(key);
          } else {
            files.add(key);
          }
        });
        item.documents = item.documents.filter((doc: any) => {
          const key = doc.document.id + '|' + doc.product.id;
          return !toRemove.has(key) || ('file' in doc);
        });
        return item;
      });

      let totalRejectedForms = 0;
      let totalAprovedForms = 0;
      let totalReviewForms = 0;
      let totalPendingForms = 0;

      filteredArray.map((filt: any) => {
        filt.documents.map((docs: any) => {
          if (docs.status === 'Rechazado') {
            totalRejectedForms++
          }
          if (docs.status === 'Aprovado') {
            totalAprovedForms++
          }
          if (docs.status === 'Pendiente por revisión') {
            totalReviewForms++
          }
          if (!docs.status || docs.status === 'Pendiente por consignar') {
            totalPendingForms++
          }
        })
      })
      setTimeout(() => {
        setNumDraft(totalRejectedForms)
        setNumAproved(totalAprovedForms)
        setNumReview(totalReviewForms)
        const count = filteredArray.reduce((total: any, item: any) => {
          const documentsWithDeletedAt = item.documents.filter((document: any) => !document.deletedAt && !document.file);
          return total + documentsWithDeletedAt.length;
        }, 0);
        setNumPending(count);
      }, 2000)
      setDocumentsByMember(filteredArray)
    }
  }, [userDocStatus, status, isFetching, isFetchingDocument])

  const convertDocument = async (id: string) => {
    const file = await downloadFileLibrary(id, () => { })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    setLookDocument(file)
  }

  const convertImage = async (document: any) => {
    const file = await downloadFileLibrary(document, () => { })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      } else {
        console.error('Error reading the file.')
      }
    }
    fileReader.onerror = () => {
      console.error('FileReader error:', fileReader.error)
    }
    fileReader.readAsArrayBuffer(file)
  }

  useEffect(() => {
    if (active3 === true) {
      refetch()
      docRefetch()
      setActive2(false)
      setActive3(false)
    }
  }, [active3, completedLenght, openForm, porcentageBase, refetch])

  useEffect(() => { }, [porcentageBase])

  useEffect(() => { }, [switch1])

  useEffect(() => { }, [currentId])

  useEffect(() => { }, [setStatus])

  useEffect(() => { }, [docStatus])

  const filterButton = (name: string) => {
    if (name === "all") {
      setAllDocuments(true)
      setPendingDocuments(false)
      setReviewingDocuments(false)
      setAprovedDocuments(false)
      setRejectedDocuments(false)
      return
    }
    if (name === "pending") {
      setAllDocuments(false)
      setPendingDocuments(true)
      setReviewingDocuments(false)
      setAprovedDocuments(false)
      setRejectedDocuments(false)
      return
    }
    if (name === "review") {
      setAllDocuments(false)
      setPendingDocuments(false)
      setReviewingDocuments(true)
      setAprovedDocuments(false)
      setRejectedDocuments(false)
      return
    }
    if (name === "aproved") {
      setAllDocuments(false)
      setPendingDocuments(false)
      setReviewingDocuments(false)
      setAprovedDocuments(true)
      setRejectedDocuments(false)
      return
    }
    if (name === "rejected") {
      setAllDocuments(false)
      setPendingDocuments(false)
      setReviewingDocuments(false)
      setAprovedDocuments(false)
      setRejectedDocuments(true)
      return
    }
  }

  if (statusDocData === 'success') {
    docData.data.map((item: any) => {
      documentData.push({
        id: item.id,
        key: item.index,
        name: item.name,
        active: item.item,
      })
    })
  }

  const SendMessage = () => {
    let allDocsApproved = true;
    let hasRejectedDoc = false;
    let hasPendantDoc = false;
    documentsByMember.forEach((member: any) => {
      member.documents.forEach((doc: any) => {
        if (doc.deletedAt) {
          return;
        }
        if (!doc.deletedAt && (doc.status === 'Pendiente por consignar' ||
          doc.status === 'Pendiente por revisión')) {
          setAlertValidation(true);
          hasPendantDoc = true;
          setTimeout(() => {
            setAlertValidation(false);
          }, 3000);
          allDocsApproved = false;

        } else if (doc.status === 'Rechazado') {
          hasRejectedDoc = true;
          allDocsApproved = false;
        }
      });
    });

    if (allDocsApproved && !hasRejectedDoc) {
      popupCompleted();
      SendMailAproved(user?.email === undefined ? '' : user?.email);
    } else if (hasRejectedDoc) {
      popupRejected();
      SendMailRejected(user?.email === undefined ? '' : user?.email);
    }
    else if (hasPendantDoc) {
      popupPendingRemindAlert()
    }
  };

  const SendMailRejected = async (email: string) => {
    await sendEmailForCaseWorkerReviewRejected(email)
  }

  const SendMailAproved = async (email: string) => {
    await sendEmailForCaseWorkerReviewAproved(email)
  }

  const handleStatusChange = async (id: any, status: string) => {
    await updateDocStatus(id, status)
  }

  const handleComments = async (id: any, comment: string) => {
    await updateDocComments(id, comment)
  }

  const popupRejectedAlert = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay)

    Swal.fire({
      title: '<span style="color: #13C296;">Cambios guardados correctamente</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Se enviará un mensaje de rechazo al solicitante al enviarle la notificación</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {
      document.body.removeChild(overlay);
    });


    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }

  const popupPendingRemindAlert = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay)

    Swal.fire({
      title: '<span style="color: #13C296;">¡Advertencia!</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Debe revisar todos los documentos antes de enviar una notificación al cliente</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {
      document.body.removeChild(overlay);
    });


    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }

  const popupRejected = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay)

    Swal.fire({
      title: '<span style="color: #13C296;">Notificacion enviada</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Se ha enviado un mensaje de rechazo al solicitante</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {

      document.body.removeChild(overlay);
    });


    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }

  const popupAproved = () => {
    // Agregar fondo gris transparente a toda la ventana
    const overlay = document.createElement('div');
    overlay.classList.add('overlay'); // Agregar la clase 'overlay'
    document.body.appendChild(overlay);

    Swal.fire({
      title: '<span style="color: #13C296;">Notificacion enviada</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Cambios guardados correctamente</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {

      document.body.removeChild(overlay);
    });
  };

  const popupCompleted = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay);

    Swal.fire({
      title: '<span style="color: #13C296;">Notificacion enviada</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Se ha enviado notificación de completado al solicitante</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {

      document.body.removeChild(overlay);
    })

    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }

  const popupAdd = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay);

    Swal.fire({
      title: '<span style="color: #13C296;">¡Muy Bien!</span>',
      icon: 'info',
      html:
        '<p style="color:black; font-size: 25px;">Documento asignado con éxito!</b>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {

      document.body.removeChild(overlay);
    });


    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }

  const popupRemove = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay);

    Swal.fire({
      title: '<span style="color: #13C296;">¡Muy Bien!</span>',
      icon: 'info',
      html:
        '<p style="color:black; font-size: 25px;">Documento eliminado con éxito!</b>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {

      document.body.removeChild(overlay);
    });


    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }
  const handleDelete = async (id: string) => {
    const result = await deleteUserDocumentsUsers(id)
    if (result) {
      docRefetch()
      setSwitch1(switch1 + 1);
      setTimeout(() => {
        setActive1(false);
        setProductId('');
        setDocumentId('');
      }, 400);
    }
  }

  const handleDeleteProductDocument = async (memberName: string, memberType: string, documentId: string) => {
    documentsByMember.map(async (member: any) => {
      if (member.memberName === memberName && member.memberType === memberType) {
        const result = await uploadNoFileIntakeProduct(
          documentId,
          user?.id!,
          contract.contracts_users_envelope_id,
          member.productId,
          member.memberName,
          member.memberType
        );
        if (result) {
          docRefetch()
          setSwitch1(switch1 + 1);
          setTimeout(() => {
            setActive1(false);
            setProductId('');
            setDocumentId('');
          }, 400);
        }
      }

    })
  }

  const findAuthor = (docId: any) => {
    console.log("IDA+ ", docId)
    if (documentByContract && documentByContract.length > 0) {
      const docFound = documentByContract.find((doc: any) => doc.id === docId);
      if (docFound) {
        if (docFound && docFound.author && docFound.author.firstName && docFound.author.lastName) {
          return `${docFound.author.firstName + " " + docFound.author.lastName}`;
        }
      }
    }
    return "";
  }

  const findCreatedAt = (docId: any) => {
    console.log("IDA+ ", docId)
    if (documentByContract && documentByContract.length > 0) {
      const docFound = documentByContract.find((doc: any) => doc.id === docId);
      if (docFound) {
        return docFound.createdAt.slice(0, 10);
      }
    }
    return "";
  }



  return (
    <>{documentsByMember &&
      <div className='main-data-continer'>
        {loading === false ? <> <div className='text-container'>
          <div className='onboard-guide-container'>
            {openForm === false ? (
              <div className='step-three-main-container '>
                <div className='flex justify-between'>
                  <h1 className='step-header' style={{ fontSize: '30px' }}>Documentos</h1>
                  <div style={{ display: 'flex', gap: '1rem' }}> <button
                    className='button-form-manual-asign'
                    style={{ width: '12rem' }}
                    onClick={() => { setOpenManual(true) }}>
                    <div className='button-form-manual-asign-text-container'><p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>ASIGNACIÓN MANUAL</p>
                      <img src={addfilesIcon} alt='Cart' /></div>
                  </button>
                    <button
                      className='button-ui button-CTA'
               
                      onClick={() => {
                        SendMessage()
                      }}
                    >
                      NOTIFICAR
                      <img src={addfilesIcon2} alt='Cart' />
                    </button>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='flex flex-row gap-4 mt-4 mobile-styles'>
                    <button className='btn-section' onClick={() => (filterButton("all"))}>
                      <p style={{ margin: '0', fontSize: '12px' }}>Ver todos</p>
                    </button>
                    <button className='btn-section' onClick={() => (filterButton("pending"))} >
                      <p style={{ margin: '0', fontSize: '12px' }}>Por adjuntar</p>
                      <p className='amount pending'>{numPending >= 0 ? numPending : 0}</p>
                    </button>
                    <button className='btn-section' onClick={() => (filterButton("review"))}>
                      <p style={{ margin: '0', fontSize: '12px' }}>En revisión</p>
                      <p style={{ width: '1.5rem', height: '1.5rem', borderRadius: '50%', backgroundColor: '#E5E7EB', color: 'gray', margin: '0', textAlign: 'center', fontSize: '14px' }}>{numReview >= 0 ? numReview : 0}</p>
                    </button>
                    {numAproved > 0 &&
                      <button className='btn-section' onClick={() => (filterButton("aproved"))}>
                        <p style={{ margin: '0', fontSize: '12px' }}>Aprobados</p>
                        <p style={{ width: '1.5rem', height: '1.5rem', borderRadius: '50%', backgroundColor: '#DAF8E6', color: '#1A8245', margin: '0', textAlign: 'center', fontSize: '14px' }}>{numAproved}</p>
                      </button>}
                    {numDraft > 0 &&
                      <button className='btn-section' onClick={() => (filterButton("rejected"))}>
                        <p style={{ margin: '0', fontSize: '12px' }}>Rechazados</p>
                        <p style={{ width: '1.5rem', height: '1.5rem', borderRadius: '50%', backgroundColor: '#F89090', color: 'white', margin: '0', textAlign: 'center', fontSize: '14px' }}>{numDraft}</p>
                      </button>}
                  </div>
                </div>
                {lookDocument ? (
                  <div className='user-profile-popup'>
                    <div className='user-profile-popup-continer'>
                      <DocumentView document={lookDocument} setLookDocument={setLookDocument} />
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          border: '2px solid #374151',
                          padding: '1rem',
                          borderRadius: '10px',
                          marginBottom: '2rem'
                        }}>
                        <div className='flex justify-start w-full gap-8'>
                          <div>
                            <p>Definir estado del documento</p>
                            <select
                              className='option-styles'
                              onChange={(e) => {
                                setDocStatus(e.target.value)
                              }}>
                              <option value=''>Seleccione</option>
                              <option value='Rechazado'>Rechazado</option>
                              <option value='Aprovado'>Aprobado</option>
                            </select>
                            <div className='mt-2'>
                              {docStatus === 'Aprovado' &&
                                <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                                  <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Aprobado</p>
                                </div>}
                              {docStatus === 'Rechazado' &&
                                <div className='step-badge-rejected' style={{ width: '6rem', height: '1.5rem', marginRight: '10px' }}>
                                  <p style={{ fontSize: '12px' }} className='step-badge-rejected-p-ones'>Rechazado</p>
                                </div>}
                            </div>
                          </div>
                          {docStatus === 'Rechazado' &&
                            <div >
                              Motivo del rechazo
                              <textarea
                                title='name'
                                maxLength={300}
                                defaultValue={comment}
                                className='text-styles'
                                style={{ width: '20rem' }}
                                onChange={(e) => {
                                  setComment(e.target.value);
                                }}>
                              </textarea>
                            </div>}
                        </div>
                      </div>
                      <div className='flex justify-end'>
                        <div
                          className='powerup-card-btn'
                          style={{ width: '8rem' }}
                          onClick={() => {

                            if (docStatus === 'Rechazado') {
                              popupRejectedAlert()
                            }
                            else if (docStatus === 'Aprovado') {
                              popupAproved()
                            }

                            handleComments(currentId, comment)
                            handleStatusChange(currentId, docStatus)
                            docRefetch()
                            refetch()
                            setTimeout(() => {
                              docRefetch()
                              refetch()
                              setComment('')
                              setActive1(false)
                              setSwitch1(switch1 + 1)
                              setActive2(false)
                              setActive3(false)
                              setActive4(0)
                            }, 400)
                            setLookDocument(undefined)
                          }}>
                          <p style={{ color: 'white', fontSize: '1.1rem', margin: '0', cursor: 'pointer' }}>Actualizar</p>
                          <img src={Send} alt='Send' />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : lookImage ? (
                  <div className='user-profile-popup'>
                    <div className='user-profile-popup-continer'>
                      <PhotoView image={lookImage} setLookImage={setLookImage} />
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          border: '2px solid #374151',
                          padding: '1rem',
                          borderRadius: '10px',
                          marginBottom: '2rem'
                        }}>
                        <div className='flex justify-start w-ful gap-8'>
                          <div>
                            <p>Definir estado del documento</p>
                            <select
                              className='option-styles'
                              onChange={(e) => {
                                setDocStatus(e.target.value)
                              }}>
                              <option value=''>Seleccione</option>
                              <option value='Rechazado'>Rechazado</option>
                              <option value='Aprovado'>Aprobado</option>
                            </select>
                            <div className='mt-2'>
                              {docStatus === 'Aprovado' &&
                                <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                                  <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Aprobado</p>
                                </div>}
                              {docStatus === 'Rechazado' &&
                                <div className='step-badge-rejected' style={{ width: '6rem', height: '1.5rem', marginRight: '10px' }}>
                                  <p style={{ fontSize: '12px' }} className='step-badge-rejected-p-ones'>Rechazado</p>
                                </div>}
                            </div>
                          </div>
                          {docStatus === 'Rechazado' && <div>
                            Motivo del rechazo
                            <textarea
                              title='name'
                              maxLength={300}
                              defaultValue={comment}
                              className='text-styles'
                              style={{ width: '20rem' }}
                              onChange={(e) => {
                                setComment(e.target.value);
                              }}>
                            </textarea>
                          </div>}
                        </div>
                      </div>
                      <div className='flex justify-end'>
                        <div
                          className='powerup-card-btn'
                          style={{ width: '8rem' }}
                          onClick={() => {
                            if (docStatus === 'Rechazado') {
                              popupRejectedAlert()
                            }
                            else if (docStatus === 'Aprovado') {

                              popupAproved()
                            }

                            handleComments(currentId, comment)
                            handleStatusChange(currentId, docStatus)
                            docRefetch()
                            refetch()
                            setTimeout(() => {
                              docRefetch()
                              refetch()
                              setComment('')
                              setActive1(false)
                              setSwitch1(switch1 + 1)
                              setActive2(false)
                              setActive3(false)
                              setActive4(0)
                            }, 400)
                            setLookDocument(undefined)
                          }}>
                          <p style={{ color: 'white', fontSize: '1.1rem', margin: '0' }}>Actualizar</p>
                          <img src={Send} alt='Send' />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {ifuseofrep === true && <>
                      <div>
                        <ul className='uploader-documents-continer '>
                          <div
                            style={{ paddingBottom: '0.5rem', marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}
                            className='step2-aplicant-card'>
                            <div style={{ width: '100%' }}
                              className='flex gap-2 items-center'>
                              <div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className='flex gap-4 items-center'>
                                  <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                    <img style={{ width: '30px', height: '30px' }} src={signatureblack} alt="ArrowTop" />
                                    <p style={{ fontSize: '18px', margin: '0' }}>
                                      <b> Firma de autorizacion </b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ display: 'flex', flexDirection: 'row', width: '300px', alignItems: 'center', justifyContent: 'center' }}>
                              <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" />
                              <div style={{ width: '220px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#955DF5', fontWeight: 'bold' }}>Verificando firma de autorizacion</div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </>}
                    {ifuseofrep === false && dataUserRep && dataUserRep.folderItems && dataUserRep.folderItems &&
                      <>
                        {dataUserRep.folderItems.map((elemento: any) => {
                          if (elemento.useofrep_envelopeid === contractsFound) {
                            return <>
                              <div>
                                <ul className='uploader-documents-continer '>
                                  <div
                                    style={{ paddingBottom: '0.5rem', marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}
                                    className='step2-aplicant-card'>
                                    <div style={{ width: '100%', cursor: 'pointer' }}

                                      className='flex gap-2 items-center' onClick={(e) => { setShowUserRepresentative(elemento.envelopeId) }}>
                                      <div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className='flex gap-4 items-center'>
                                          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                            <img style={{ width: '30px', height: '30px' }} src={signatureblack} alt="ArrowTop" />
                                            <p style={{ fontSize: '18px', margin: '0' }}>
                                              <b> Firma de autorizacion </b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={(e) => { e.stopPropagation(); setShowUserRepresentative(elemento.envelopeId) }}>
                                      <img
                                        className='show-btn'
                                        src={ChevronRightIcon}
                                        alt='ChevronRight'
                                      />
                                    </div>
                                  </div>
                                </ul>
                              </div>
                            </>
                          }
                          return null;
                        })}
                      </>}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                      {documentsByMember &&
                        documentsByMember?.sort((a: any, b: any) => {
                          // Ordenar por memberType, priorizando 'Solicitante principal'
                          if (a.memberType === 'Solicitante principal' && b.memberType !== 'Solicitante principal') {
                            return -1;
                          } else if (a.memberType !== 'Solicitante principal' && b.memberType === 'Solicitante principal') {
                            return 1;
                          } else if (a.memberType === 'Conyuge o Pareja de hecho' && b.memberType !== 'Conyuge o Pareja de hecho') {
                            return -1;
                          } else if (a.memberType !== 'Conyuge o Pareja de hecho' && b.memberType === 'Conyuge o Pareja de hecho') {
                            return 1;
                          } else {
                            return 0;
                          }
                        })
                          .map((member: any, index: number) => (
                            <>
                              <div className={`step2-aplicant-card ${buttonStates === index ? 'open' : ''}`}>
                                <div
                                  onClick={() => handleToggleContent(index)}
                                  style={{ paddingBottom: '0.5rem', marginBottom: '1rem', cursor: 'pointer' }}
                                  className='flex gap-4 mt-2 items-center justify-between w-full'>
                                  <div style={{ width: '100%' }}
                                    className='flex gap-2 items-center'>
                                    <div>
                                      <div style={{ width: '100%' }} className='flex gap-4 items-center'>
                                        <p style={{ fontSize: '18px', margin: '0' }}>
                                          <b>{member.memberName}</b> ({member.memberType})
                                        </p>
                                      </div>
                                      <b style={{ fontSize: '12px' }}>{member.productName} </b>
                                    </div>
                                  </div>
                                  <div
                                    className={buttonStates === index ? 'show-btn-presed' : 'show-btn-presed2'}
                                    onClick={(e) => { e.stopPropagation(); handleToggleContent(index); }}>
                                    <img
                                      className='show-btn'
                                      src={ChevronRightIcon}
                                      alt='ChevronRight'
                                    />
                                  </div>
                                </div>
                                <div style={{ width: '100%' }}>
                                  {buttonStates === index && (
                                    <div style={{ width: '100%', }}>
                                      <table style={{ width: '100%' }}>
                                        <tr style={{ borderBottom: '1px solid #DFE4EA' }}>
                                          <th style={{ width: '40%' }}>Documento</th>
                                          <th style={{ width: '15%' }}>Fecha</th>
                                          <th style={{ width: '15%' }}>Estado</th>
                                          <th style={{ width: '25%' }}>Comentarios</th>
                                          <th style={{ width: '5%' }}></th>
                                        </tr>
                                        {member.documents.map((document: any) => (
                                          <>
                                            {document.status && !document.deletedAt ?
                                              <>
                                                {document.status === 'Rechazado' && (allDocuments === true || rejectedDocuments === true) ?
                                                  <tr className='step-two-header' style={{ borderBottom: '1px solid #DFE4EA', height: 'auto', cursor: 'pointer' }} onClick={() => {
                                                    setComment(document.comments)
                                                    setcurrentId(document.id)
                                                    setDocStatus(document.status)
                                                    document.file.mimetype === 'application/pdf' ?
                                                      convertDocument(document.file.id as string) :
                                                      convertImage(document.file.id as string)
                                                  }}>
                                                    <td style={{ paddingRight: '5px' }}>
                                                      <p style={{ fontSize: '14px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                                        {document.document.name}
                                                      </p>
                                                      {findAuthor(document.id) && <p style={{ color: 'gray', margin: 0 }}>Asignado el {findCreatedAt(document.id)} -<b>  {findAuthor(document.id)}</b></p>}


                                                      <div className='flex gap-2 mt-1 mb-1'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Traducir</p>
                                                          </div>) : null}
                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Legalizar</p>
                                                          </div>) : null}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '0px', marginTop: '10px' }}>
                                                        {document.document.updatedAt.substring(0, 10)}
                                                      </p>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '10px', marginTop: '0px' }}>
                                                        Hora: {document.document.updatedAt.substring(11, 16)}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <div className='step-badge-rejected' style={{ width: '6rem', height: '1.5rem', marginRight: '10px' }}>
                                                        <p style={{ fontSize: '12px' }} className='step-badge-rejected-p-ones'>Rechazado</p>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="flex flex-col">
                                                        <p style={{ color: '#BC1C21', fontSize: '12px' }}>{document.comments}</p>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div
                                                        onClick={() => {
                                                          setComment(document.comments)
                                                          setcurrentId(document.id)
                                                          setDocStatus(document.status)
                                                          document.file.mimetype === 'application/pdf' ?
                                                            convertDocument(document.file.id as string) :
                                                            convertImage(document.file.id as string)
                                                        }}>
                                                        <img
                                                          className='show-btn'
                                                          src={ChevronRightIcon}
                                                          alt='ChevronRight' />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  : null}
                                                {document.status === 'Pendiente por consignar' && !document.deletedAt && (pendingDocuments === true || allDocuments === true) ? (
                                                  <tr className='step-two-header' style={{ borderBottom: '1px solid #DFE4EA', height: 'auto' }} onClick={() => {
                                                    setcurrentId(document.id)
                                                    setDocStatus(document.status)
                                                    document.file.mimetype === 'application/pdf' ?
                                                      convertDocument(document.file.id as string) :
                                                      convertImage(document.file.id as string)
                                                  }}>
                                                    <td style={{ paddingRight: '5px' }}>
                                                      <p style={{ fontSize: '14px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                                        {document.document.name}
                                                      </p>
                                                      {findAuthor(document.id) && <p style={{ color: 'gray', margin: 0 }}>Asignado el {findCreatedAt(document.id)} -<b>  {findAuthor(document.id)}</b></p>}

                                                      <div className='flex gap-2 mt-1 mb-1'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Traducir</p>
                                                          </div>) : null}
                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Legalizar</p>
                                                          </div>) : null}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '0px', marginTop: '10px' }}>
                                                        {document.document.updatedAt.substring(0, 10)}
                                                      </p>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '10px', marginTop: '0px' }}>
                                                        Hora: {document.document.updatedAt.substring(11, 16)}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <div className='step-badge-active2' style={{ width: '6rem', height: '1.5rem' }}>
                                                        <p className='step-badge-active-p-ones'>Por adjuntar</p>
                                                      </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                ) : null}
                                                {document.status === 'Aprovado' && (aprovedDocuments === true || allDocuments === true) ? (
                                                  <tr className='step-two-header' style={{ borderBottom: '1px solid #DFE4EA', height: 'auto', cursor: 'pointer' }} onClick={() => {
                                                    setcurrentId(document.id)
                                                    setDocStatus(document.status)
                                                    document.file.mimetype === 'application/pdf' ?
                                                      convertDocument(document.file.id as string) :
                                                      convertImage(document.file.id as string)
                                                  }}>
                                                    <td style={{ paddingRight: '5px' }}>
                                                      <p style={{ fontSize: '14px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                                        {document.document.name}
                                                      </p>
                                                      {findAuthor(document.id) && <p style={{ color: 'gray', margin: 0 }}>Asignado el {findCreatedAt(document.id)} -<b>  {findAuthor(document.id)}</b></p>}

                                                      <div className='flex gap-2 mt-1 mb-1'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Traducir</p>
                                                          </div>) : null}
                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Legalizar</p>
                                                          </div>) : null}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '0px', marginTop: '10px' }}>
                                                        {document.document.updatedAt.substring(0, 10)}
                                                      </p>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '10px', marginTop: '0px' }}>
                                                        Hora: {document.document.updatedAt.substring(11, 16)}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                                                        <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Aprobado</p>
                                                      </div>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                      <div
                                                        onClick={() => {
                                                          setcurrentId(document.id)
                                                          setDocStatus(document.status)
                                                          document.file.mimetype === 'application/pdf' ?
                                                            convertDocument(document.file.id as string) :
                                                            convertImage(document.file.id as string)
                                                        }}>
                                                        <img
                                                          className='show-btn'
                                                          src={ChevronRightIcon}
                                                          alt='ChevronRight'
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ) : null}

                                                {document.status === 'Pendiente por revisión' &&
                                                  (allDocuments === true || reviewingDocuments === true) ?
                                                  (<tr className='step-two-header'
                                                    style={{
                                                      borderBottom: '1px solid #DFE4EA',
                                                      height: 'auto',
                                                      cursor: 'pointer'
                                                    }} onClick={() => {
                                                      setcurrentId(document.id)
                                                      setDocStatus(document.status)
                                                      document.file.mimetype === 'application/pdf' ?
                                                        convertDocument(document.file.id as string) :
                                                        convertImage(document.file.id as string)
                                                    }}>
                                                    <td style={{ paddingRight: '5px' }}>
                                                      <p style={{ fontSize: '14px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                                        {document.document.name}
                                                      </p>
                                                      {findAuthor(document.id) && <p style={{ color: 'gray', margin: 0 }}>Asignado el {findCreatedAt(document.id)} -<b>  {findAuthor(document.id)}</b></p>}
                                                      <div className='flex gap-2 mt-1 mb-1'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Traducir</p>
                                                          </div>) : null}

                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Legalizar</p>
                                                          </div>) : null}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '0px', marginTop: '10px' }}>
                                                        {document.document.updatedAt.substring(0, 10)}
                                                      </p>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '10px', marginTop: '0px' }}>
                                                        Hora: {document.document.updatedAt.substring(11, 16)}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <div className='step-badge-active-draft2' style={{ width: '7.5rem', height: '1.5rem', border: '1px solid #8899A8' }}>
                                                        <img style={{ width: '16px', height: '16px' }} src={Clock} alt="Clock" />
                                                        <p className='step-badge-active-draft-p-ones hide-tex' style={{ fontSize: '12px', margin: '0' }}>En revisión</p>
                                                        <p className='step-badge-active-draft-p-ones hide-tex2' style={{ fontSize: '12px' }}>En revisión</p>
                                                      </div>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                      <div
                                                        onClick={() => {
                                                          setcurrentId(document.id)
                                                          setDocStatus(document.status)
                                                          document.file.mimetype === 'application/pdf' ?
                                                            convertDocument(document.file.id as string) :
                                                            convertImage(document.file.id as string)
                                                        }}>
                                                        <img
                                                          className='show-btn'
                                                          src={ChevronRightIcon}
                                                          alt='ChevronRight'
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  ) : null}
                                              </>
                                              :
                                              <>
                                                {document.document.statusDoc === 'Pendiente por consignar' && !document.deletedAt && (pendingDocuments === true || allDocuments === true) ? (
                                                  <tr className='step-two-header' style={{ borderBottom: '1px solid #DFE4EA', height: 'auto', cursor: 'pointer' }} onClick={() => {
                                                    setShowUserRepresentative("")
                                                  }}>
                                                    <td style={{ paddingRight: '5px' }}>
                                                      <p style={{ fontSize: '14px', color: '#4B5563', fontWeight: 'bold' }}>
                                                        {document.document.name}
                                                      </p>
                                                      <div className='flex gap-2 mt-1 mb-1'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Traducir</p>
                                                          </div>) : null}

                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Legalizar</p>
                                                          </div>) : null}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '0px', marginTop: '10px' }}>
                                                        {document.document.updatedAt.substring(0, 10)}
                                                      </p>
                                                      <p style={{ fontSize: '12px', color: '#4B5563', marginBottom: '10px', marginTop: '0px' }}>
                                                        Hora: {document.document.updatedAt.substring(11, 16)}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <div className='step-badge-active2' style={{ width: '6rem', height: '1.5rem' }}>
                                                        <p className='step-badge-active-p-ones'>Por adjuntar</p>
                                                      </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                ) : null}
                                              </>}
                                          </>))}
                                      </table>
                                    </div>)}
                                </div>
                              </div>
                            </>))}
                    </div>
                    {showUserRepresentative !== "" && <>
                      <div className='user-profile-popup'>
                        <div className='user-profile-popup-continer'>
                          <button
                            style={{
                              marginBottom: '10px',
                            }}
                            className='button-ui button-secondary'
                            onClick={() => {
                              setShowUserRepresentative("")
                            }}
                          >
                            <p className='meet-text2' style={{ fontWeight: 'bold', margin: '0' }}>Volver</p>
                          </button>
                          <>
                            <PdfView envelopeId={showUserRepresentative} /></>
                        </div>
                      </div>
                    </>}
                  </>)}
              </div>
            ) : (
              <div className='w-full mr-8 ml-8 '>{openFormCompleted === false ? <></> : <></>}</div>)}
          </div>
        </div>
        </> :
          <>Cargando...</>
        }
        {
          openManual === true &&
          <div className='user-profile-popup'>
            <div className='user-profile-popup-continer'>
              <div className='w-full mb-4 items-start pl-4 pb-4'
                style={{ borderBottom: '1px solid #DFE4EA' }}>
                <button style={{
                  marginTop: '30px',

                }}
                  onClick={() => setOpenManual(false)}
                  className='button-ui button-secondary button-form-volver'
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                  <img
                    alt="Calendar"
                    className='meet-image2'
                    src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-white.svg'}
                  />
                  <b style={{ color: 'white' }}>VOLVER</b>
                </button>
              </div>
              <div style={{ padding: '0px 30px 0px 30px' }} >
                <div>
                  <div
                    className='flex gap-2 items-center mb-4'>
                    <img src={Briefcase} alt="Briefcase" />
                    <h1 style={{ fontSize: '18px', fontWeight: 'bold', margin: '0' }}>Asignar documentos manualmente</h1>
                  </div>
                  <div
                    className='flex gap-2'
                    style={{ height: '5rem', backgroundColor: '#D0F0FD', borderRadius: '10px', padding: '1rem' }}>
                    <div style={{ width: '1px', height: '100%', border: '1px solid #8899A8' }} />
                    <div>
                      <p style={{ fontSize: '12px', margin: '0' }}>Servicio</p>
                      <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0' }}>Visitor Visa</p>
                    </div>
                  </div>
                  <p style={{ fontWeight: 'bold', marginTop: '1rem', marginBottom: '1rem' }}>Asignar Documentos</p>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '2px solid #374151',
                    padding: '1rem',
                    borderRadius: '10px'
                  }}>
                  <div className='flex gap-4 items-center mb-4'>
                    <div>
                      <p style={{ margin: '0' }}>Asignar tipo de aplicante</p>
                      <select
                        style={{ width: '12rem' }}
                        className='option-styles'
                        value={typeSelected}
                        name=''
                        id=''
                        onChange={(e: any) => {
                          setTypeSelected(e.target.value)
                        }}
                      >
                        <option value=''>Seleccione</option>
                        {documentsByMember.map((member: any, index: number) => (
                          <option value={member.memberType}>
                            {member.memberType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <p style={{ margin: '0' }}>Asignar nombre</p>
                      <select
                        style={{ width: '12rem' }}
                        className='option-styles'
                        value={nameSelected}
                        name=''
                        id=''
                        onChange={(e: any) => {

                          setNameSelected(e.target.value)
                        }}>
                        <option value=''>Seleccione</option>
                        {documentsByMember.map((member: any, index: number) => (
                          <option key={index} value={member.memberName}>
                            {member.memberName}
                          </option>))}
                      </select>
                    </div>
                    <div>
                      <p style={{ margin: '0' }}>Asignar documento</p>
                      <select
                        style={{ width: '12rem' }}
                        className='option-styles'
                        value={documentId}
                        name=''
                        id=''
                        onChange={(e: any) => {

                          setDocumentId(e.target.value)
                        }}>
                        <option value=''>Asignar documento</option>
                        {documentData?.map((doc: any) => (
                          <>
                            <option value={doc.id}>{doc.name}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className='flex flex-col items-center'>
                      <button
                        className='button-ui button-tertiary'
                        onClick={() => {
                          documentsByMember.map(async (member: any) => {
                            if (nameSelected !== '' && typeSelected !== '' && documentId) {
                              if (member.memberType === typeSelected && member.memberName === nameSelected) {
                                await uploadNoFileIntake(
                                  documentId,
                                  user?.id!,
                                  contract.contracts_users_envelope_id,
                                  member.productId,
                                  nameSelected,
                                  typeSelected,
                                  "true"
                                );
                                docRefetch()
                                /*  refetch(); */
                                setSwitch1(switch1 + 1);
                                setTimeout(() => {
                                  setActive1(false);
                                  setProductId('');
                                  popupAdd()
                                  setDocumentId('');
                                }, 400);

                              }
                            } else {
                              setError(true);
                            }

                          })
                        }}
                        // style={{

                        //   padding: '10px, 36px, 10px, 36px',
                        //   borderRadius: '50px',
                        //   border: '2px solid #01A9DB',
                        //   gap: '10px',
                        //   color: '#01A9DB'
                        // }}
                        >
                        Añadir
                      </button>
                      {error === true && <span style={{ color: 'red', fontSize: '8px' }}>Llene todos los campos</span>}
                    </div>
                  </div>
                  {
                    documentsByMember.map((docs: any) => (
                      <>
                        <>

                          <div>
                            <div>
                              <>
                                <p>Documentos asignados al usuario {docs.memberType}  {docs.memberName} </p>
                                <ul
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    border: '2px solid #6B7280',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '10px',
                                    listStyle: 'none',

                                  }}>
                                  {docs.documents && docs.documents
                                    .filter((doc: any) => !doc.deletedAt) // Filtrar documentos no eliminados
                                    .sort((a: any, b: any) => {
                                      if (!a.updatedAt && !b.updatedAt) return 0; // Si ambos no tienen updatedAt, no hay diferencia en el orden
                                      if (!a.updatedAt) return 1; // Si solo a no tiene updatedAt, lo colocamos después
                                      if (!b.updatedAt) return -1; // Si solo b no tiene updatedAt, lo colocamos antes
                                      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(); // Ordenar por updatedAt de manera descendente
                                    })
                                    .map((doc: any, index: number) => (
                                      <li key={index} className='form-container-options flex justify-between pb-2 items-center' style={{ borderBottom: '1px solid #DFE4EA', height: '3.5rem' }}>
                                        <p style={{ margin: '0' }}>
                                          {doc.document.name}
                                        </p>
                                        <div onClick={() => { { popupRemove(), doc.memberName ? handleDelete(doc.id) : handleDeleteProductDocument(docs.memberName, docs.memberType, doc.document.id) } }} className='flex gap-2 items-center'>
                                          <img style={{ margin: '0' }} src={DeleteFrom} alt="DeleteFrom" />
                                          <p className='delete-button-text delete-button-hover' style={{ fontWeight: 'bold', color: '#E10E0E', margin: '0', cursor: 'pointer' }}>Quitar Documento</p>
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </>
                            </div>
                          </div>
                        </>
                      </>)
                    )}
                </div>
              </div>
            </div>
          </div>}
      </div >}</>

  )
}

export default DocumentRequirenmentList
