/* eslint-disable @typescript-eslint/no-unused-vars */
import {type ProfileImmigration, type Profile} from 'app/modules/apps/profile/core/_models'
import {Link} from 'react-router-dom'

interface Props {
  ProfileImmigration: ProfileImmigration
  staffId: string
}

const CaseWorkerDashboard: React.FC<Props> = (props) => {
  const {ProfileImmigration, staffId} = props
  return (
    <div className='flex items-center justify-around gap-8 flex-wrap'>
      <a
        className='flex flex-col h-52 items-center justify-center'
        href='https://docs.google.com/document/d/1Z80qiKOLA1T-ISCam8uTdGkJBM3_RONY/edit'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='px-2 py-4 '>
          <img
            width='100'
            height='100'
            src='https://img.icons8.com/ios-filled/100/bf3c3c/user-manual.png'
            alt='user-manual'
          />
        </div>
        <h2 className='w-24 h-8'>Guia/Manual</h2>
      </a>
      <Link className='flex flex-col h-52 items-center justify-center' to='/myclients/list'>
        <div className='px-2 py-4 '>
          <img
            width='100'
            height='100'
            src='https://img.icons8.com/external-tal-revivo-regular-tal-revivo/100/bf3c3c/external-business-official-call-with-client-over-a-smartphone-meeting-regular-tal-revivo.png'
            alt='client'
          />
        </div>
        <h2 className='w-24 h-8'> Mis Clientes</h2>
      </Link>
      <div className='flex flex-col h-52 items-center justify-center'>
        <div className='px-2 py-4 '>
          <img
            width='100'
            height='100'
            src='https://img.icons8.com/ios/100/bf3c3c/translate-app.png'
            alt='translate-app'
          />
        </div>
        <h2 className='w-24 h-8'>Status de traducciones</h2>
      </div>
      <Link className='flex flex-col h-52 items-center justify-center' to='/user/support'>
        <div className='px-2 py-4 '>
          <img
            width='100'
            height='100'
            src='https://img.icons8.com/sf-black/100/bf3c3c/chat.png'
            alt='chat'
          />
        </div>
        <h2 className='w-24 h-8'>Chat</h2>
      </Link>
    </div>
  )
}

export {CaseWorkerDashboard}
