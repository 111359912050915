export const contratation = {
  'MENU.ANSWERS': 'Respuestas contratacion',
  'CONTRATATION.ANSWERSCONTAINER': 'Respuestas',
  'CONTRATATION.EMAIL': 'Email',
  'CONTRATATION.DATEANDHOUR': 'Fecha y Hora',
  'CONTRATATION.ANSWERS': 'Respuestas',
  'CONTRATATION.BACK': 'Atras',
  'CONTRATATION.ANSWERSOF': 'Respuesta de ',
  'CONTRATATION.QUESTION': 'Pregunta ',
}
