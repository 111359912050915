 

import {Avatar as AvatarAnt} from 'antd'
import {RootStoreContext} from '../../../../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {useContext} from 'react'

interface Props {
  initialImage: string
  size: number
}

const AvatarProfile: React.FC<Props> = ({size}) => {
  const rootStore = useContext(RootStoreContext)
  const {getProfileFromStore} = rootStore.profileStore
  const {user} = rootStore.authStore

  const getSize = (size: number) => {
    try {
      if (!getProfileFromStore.photo?.path && !user?.photo?.path) {
        return '/media/avatars/blank.png'
      }
    } catch (e) {
      return '/media/avatars/blank.png'
    }

    const path = getProfileFromStore.photo?.path || user?.photo?.path
    const photoId = getProfileFromStore.photo?.id || user?.photo?.id

    if (size <= 70) {
      return `${path}/${photoId}-avatar-small.jpg`
    }

    if (size <= 200) {
      return `${path}/${photoId}-avatar-medium.jpg`
    }
    if (size <= 400) {
      return `${path}/${photoId}-avatar-lague.jpg`
    }
  }

  return (
    <>
      <AvatarAnt src={getSize(size)} size={size} />
    </>
  )
}

export default observer(AvatarProfile)
