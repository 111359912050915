/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react'
import { Box } from '../../../../components'
import { Card } from 'react-bootstrap'
import { DatePicker } from 'antd'
import Modal from 'react-bootstrap/Modal'
import { type OrdersDetail } from '../../../../app/modules/apps/ordersdetail/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import { type Staff } from '../../../../app/modules/apps/staff/core/_models'
import StaffSelect from '../../../../app/components/common/StaffSelect'
import Swal from 'sweetalert2'
import { dateConverterTimeZone } from '../../../../core/utils/dateConverterTimeZone'
import { generateCalendlyProps } from '../../../../app/modules/apps/appointments/calendlymodel/generateCalendlyProps'
import moment from 'moment'
import { updateOrdersDetail } from '../../../../app/modules/apps/ordersdetail/core/_requests'
import { useIntl } from 'react-intl'
import '../MenuOptions/style.css'
import { AppointmentsAdmin } from 'app/modules/apps/appointments/core/_models'

interface IProps {
  appointment: AppointmentsAdmin
  modalShow: boolean
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>
}

const SwitchConsultor: React.FC<IProps> = ({ appointment, modalShow, setModalShow }) => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [staff, setStaff] = useState<Staff | null>(null)
  const intl = useIntl()
  const [zone, setZone] = React.useState('UTC')
  const [dateChoose, setDateChoose] = React.useState<any>()
  const [dateTime, setDateTime] = React.useState('')
  const [isHovered, setIsHovered] = useState(false)
  useEffect(() => {
    if (modalShow) {
      setStaff(null)
    }
  }, [modalShow])

  const handleDateChange = (date: any) => {
    setDateChoose(date)
    const dateObject = date.toDate()
    const newDateTime = dateConverterTimeZone(dateObject, zone, 'utc') ?? ''
    setDateTime(newDateTime)
  }

  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    }
  }, [user])

  useEffect(() => {
    dateChoose ? handleDateChange(dateChoose) : null
  }, [zone])

  const handleChangeConsultor = () => {
    Swal.fire({
      title: '¿Está seguro que desea modificar la cita?',
      text: `¿Modificaras el  consultor de la cita con el
             Email: "${appointment.email}" \n
              y lo cambiaras por ${staff?.name || ''}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '<div class="btn-with-border">CONFIRMAR <img src="/media/icons/app/menu/icons8-check-50white.png" alt="User Icon" style="width: 15px" /></div>',
      cancelButtonText: intl.formatMessage({ id: 'AUTH.FORGOT.RESENDEMAILCANCEL' }),
      customClass: {
        confirmButton: 'order-2 btn-with-border-no-shadow', // Añadir nueva clase
        cancelButton: 'btn-with-icon',
      },
      buttonsStyling: false,
      showClass: {
        popup: 'swal2-noanimation',
        backdrop: 'swal2-noanimation',
      },
      hideClass: {
        popup: '',
        backdrop: '',
      },
    }).then(async (result) => {
      if (result.value) {
        if (staff != null && staff.id) {
          const newAppointment: OrdersDetail = {
            id: appointment.id,
            staff,
          }
          if (dateTime) {
            const newProps = generateCalendlyProps(
              appointment.join_url || '',
              dateTime,
              appointment.description || '',
              appointment.end_time?.toString() || '',
              appointment.uri || '',
              appointment.created_at?.toString() || '',
              appointment.updated_at?.toString() || ''
            )
            newAppointment.props = JSON.stringify(newProps)
          }

          const result = await updateOrdersDetail(newAppointment, appointment.id)
          if (result != null) {
            Swal.fire('<span style="color: #13C296;">¡Muy bien!</span> ', '¡Fue modificado con éxito!', 'success')
            setModalShow(false)
          } else {
            Swal.fire('Cambio de consultor', 'Ha ocurrido un error', 'error')
          }
        }
      }
    })
  }

  const getDescriptionText = (htmlString: string) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html')
    return doc.body.textContent || ''
  }

  return (
    <div data-controller="slide-over">
      <div
        data-slide-over-target="container" style={{ zIndex: '9999' }}
        className={`fixed inset-0 overflow-hidden ${modalShow ? '' : 'hidden'}`}
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true">
        <div
          data-slide-over-target="backdrop"
          className="fixed inset-0 bg-black opacity-50 transition-opacity">
        </div>
        <div
          data-slide-over-target="panel"
          className="absolute inset-y-0 right-0 max-w-full flex overflow-hidden bg-white shadow-xl transform transition ease-in-out duration-500 sm:duration-700">
          <div style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto'
          }}
            className="relative w-screen  p-8">
            <Modal.Body className='show-grid'>
              <button style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1px 16px 1px 16px',
                height: 'auto',
                width: 'auto'
              }}
                onClick={() => {
                  setModalShow(false)
                }}
                className='button2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <img
                  alt="Calendar"
                  className='meet-image2'
                  src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' :
                    '/media/icons/app/menu/arrow-left-black.svg'} />
                <b className='meet-text2'>VOLVER</b>
              </button>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <img style={{
                  width: '30px',
                  height: '30px',
                  marginRight: '10px',
                  marginBottom: '10px'
                }}
                  src="/media/icons/app/menu/cita.svg"
                  alt="User Icon" />
                <label style={{
                  color: 'black',
                  fontSize: '24px'
                }}>
                  <b>Editar Cita</b>
                </label>
              </div>
              <label style={{ color: 'black' }}>
                <b>Datos de la cita</b>
              </label>
              <Card.Body style={{
                border: '1px solid black',
                borderRadius: '10px',
              }}>
                <div className="mb-3 flex flex-col"
                style={{padding:'1rem 2rem'}}>
                  <label className="form-label2">Cliente</label>
                  <input style={{ width: '100%', }}
                    type="text"
                    className="custom-input"
                    value={`${appointment.first_name} ${appointment.last_name}`}
                    readOnly
                  />
                </div>
                <div className="mb-3 flex flex-col">
                  <label className="form-label2">Descripción/Servicio</label>
                  <input
                    style={{ width: '100%' }}
                    type="text"
                    className="custom-input"
                    value={getDescriptionText(appointment.description)}
                    readOnly />
                </div>
                <Card.Text>
                  <Box>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '2px'
                    }}
                      className='row mb-6'>
                      <label className="form-label2">Fecha y Hora de la Cita</label>
                      <div style={{ zIndex: '9999', display: 'flex' }}>
                        <DatePicker
                          className='custom-input'
                          format='YYYY-MM-DD HH:mm:ss'
                          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                          onChange={handleDateChange}
                          style={{ zIndex: '9999' }}
                        />
                      </div>
                    </div>
                  </Box>
                  <div className='flex flex-col gap-2'>
                    <label className="form-label2">Staff asignado</label>
                    <input
                      style={{ width: '100%' }}
                      type="text"
                      className="custom-input"
                      value={getDescriptionText(appointment.consultant)}
                      readOnly />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <label className="form-label2">Asignar nuevo Staff</label>
                    {staff != null ? (
                      <>
                        <div className='flex gap-2'>
                          <div>
                            <strong>Consultor nuevo:</strong>
                          </div>
                          <div>
                            <p>{`${staff.name}`}</p>
                          </div>
                        </div>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                          alignItems: 'center',
                          marginRight: '1rem'
                        }}>
                          <div className='flex w-full justify-between'>
                            <button style={{
                              border: '1px solid black',
                              borderRadius: '30px',
                              padding: '1px 30px',
                              backgroundColor: 'black',
                              color: 'white'
                            }}
                              onClick={() => {
                                handleChangeConsultor()
                              }}>
                              Cambiar
                            </button>
                            <button style={{
                              border: '1px solid black',
                              borderRadius: '30px',
                              padding: '1px 30px'
                            }}
                              onClick={() => {
                                setStaff(null)
                              }}>
                              Cancelar
                            </button>
                          </div>
                        </div>
                        <br />
                      </>
                    ) : (
                      <>
                        <StaffSelect setStaff={setStaff} />
                      </>
                    )}
                  </div>
                </Card.Text>
              </Card.Body>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SwitchConsultor
