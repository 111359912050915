/* eslint-disable react-hooks/exhaustive-deps */
import {Autocomplete, type IOption} from '../../../../components'
import {useEffect, useState} from 'react'
import {type Staff} from '../../../../app/modules/apps/staff/core/_models'
import {getStaffs} from '../../../../app/modules/apps/staff/core/_requests'
import {useQuery} from 'react-query'

const initialOptions: IOption[] = []
interface IProps {
  selected: IOption
  setSelected: (option: IOption) => void
}

const StaffSelectOption: React.FC<IProps> = ({selected, setSelected}) => {
  const [query, setQuery] = useState<Staff>({})
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<IOption[]>(initialOptions)
  const {data, refetch} = useQuery('getAllStaffs', async () => await getStaffs(query))

  const handleSearch = (query: string) => {
    const staffsVars: Staff = {
      type: 'consultant,immigration_admin,coo,education',
      name: query,
    }
    setQuery(staffsVars)
  }

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      refetch()
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [query])

  useEffect(() => {
    if (data != null) {
      setLoading(false)
      const staffs = data.map((staff: Staff) => {
        return {id: staff.id || '', value: staff.name || ''}
      })
      setOptions(staffs)
    }
  }, [data])

  const handleSelect = (option: IOption) => {
    setSelected(option)
  }

  return (
    <>
      <Autocomplete
        options={options}
        onChange={handleSearch}
        isLoading={loading}
        onSelect={handleSelect}
        selected={selected}
      />
    </>
  )
}

export default StaffSelectOption
