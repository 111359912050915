import { Container, Link } from '../../../../components';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import { CSSTransition } from 'react-transition-group';
import SlideOverComponent from './TranlationHover';
import { ICartProducts } from 'app/modules/apps/cart/core/products/_models';
import './style.css'
const UserCart = () => {
  const intl = useIntl();
  const [showSubButtons, setShowSubButtons] = useState(false);
  const [showEducationSubButtons, setShowEducationSubButtons] = useState(false);
  const [activeButton, setActiveButton] = useState<string | null>('recommendations');


  const handleImmigrationClick = () => {
    setShowSubButtons(!showSubButtons);
    setShowEducationSubButtons(false);
    setActiveButton('immigration');
  };

  const handleRecruitmentClick = () => {
    setShowSubButtons(false);
    setShowEducationSubButtons(false);
    setActiveButton('recruitment');
    window.open('https://www.immilandcanada.com/reclutamiento/inicio', '_blank');
  };

  const handleEducationClick = () => {
    setShowEducationSubButtons(!showEducationSubButtons);
    setShowSubButtons(false);
    setActiveButton('education');
  };

  const handleTranslationClick = () => {
    setShowSubButtons(false);
    setShowEducationSubButtons(false);
    setActiveButton('translation');
    window.open('https://www.immilandcanada.com/traduccion/inicio', '_blank');
  };

  const handleSettlementClick = () => {
    setShowSubButtons(false);
    setShowEducationSubButtons(false);
    setActiveButton('settlement');
    window.open('https://www.immilandcanada.com/establecimiento/inicio', '_blank');
  };

  const handleRecommendationsClick = () => {
    setShowSubButtons(false);
    setShowEducationSubButtons(false);
    setActiveButton('recommendations');
  };
  const [showSlideOver, setShowSlideOver] = useState(false);

  /*   const handleTranslationClick = () => {
      setShowSlideOver(true);
      // Resto del código...
    }; */

  const handleCloseSlideOver = () => {
    setShowSlideOver(false);
  };
  console.log(showSlideOver);
  return (
    <>
      <Container title=''>
        <div className='step-header' >
          <h1 className='step-header-title'>
            <img className='w-15' src='/media/svg/menu-icons/ship.svg' alt='' /> Tienda de Power-ups</h1>
          <p style={{ paddingLeft: '24px' }} className='step-header-subtitle'>¡Agilice sus procesos ahora adquiriendo power-ups para su plan!</p>
        </div>

        <div className='all-container tienda'>

          <div className='container-education'>

            <button
              onClick={handleRecommendationsClick}
              className={`button-hover text-black text-sm px-5 py-2.5 mb-3 block w-full text-left focus:ring-transparent focus:outline-none ${activeButton === 'recommendations' ? 'border-b-2 border-black' : ''
                }`}
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '13rem', }}>
                <img className='w-8' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/reco.svg' alt='' /> RECOMENDADOS
              </div>
            </button>
            <button
              onClick={handleImmigrationClick}
              className={`button-hover text-black text-sm px-5 py-2.5 mb-3 block w-full text-left  focus:ring-transparent focus:outline-none ${activeButton === 'immigration' ? 'border-b-2 border-black' : ''
                }`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className='w-8' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/air-mail.svg' alt='' />    IMMIGRATION</div>
            </button>

            <button
              onClick={handleRecruitmentClick}
              className={`button-hover text-black text-sm px-5 py-2.5 mb-3 block w-full text-left  focus:ring-transparent focus:outline-none ${activeButton === 'recruitment' ? 'border-b-2 border-black' : ''
                }`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className='w-8' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/recru.svg' alt='' />    RECRUITMENT</div>
            </button>

            <button
              onClick={handleEducationClick}
              className={`button-hover text-black text-sm px-5 py-2.5 mb-3 block w-full text-left  focus:ring-transparent focus:outline-none ${activeButton === 'education' ? 'border-b-2 border-black' : ''
                }`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className='w-8' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/graduation.svg' alt='' />    EDUCATION</div>
            </button>
            <button
              onClick={handleTranslationClick}
              className={`button-hover text-black text-sm px-5 py-2.5 mb-3 block w-full text-left  focus:ring-transparent focus:outline-none ${activeButton === 'translation' ? 'border-b-2 border-black' : ''
                }`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  className='w-8'
                  style={{ padding: '5px', marginTop: '5px' }}
                  src='/static/media/world.55d4e9d90f8ec9484cf4.svg'
                  alt=''
                />
                TRANSLATION
              </div>
            </button>


            <button
              onClick={handleSettlementClick}
              className={`button-hover text-black text-sm px-5 py-2.5 block w-full text-left  focus:ring-transparent focus:outline-none ${activeButton === 'settlement' ? 'border-b-2 border-black' : ''
                }`}
            >
              <div className='setle' style={{ display: 'flex', alignItems: 'center' }}>
                <img className='w-8' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/setle.svg' alt='' />    SETTLEMENT</div>
            </button>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}  className='all-container servicios-tienda'>
            {showSubButtons && (
              <div className='powerup-card-container' style={{ display: 'flex' }}>
                
                {/* <div className='button-container'>
                  <Link to='/cart/immigrationservicescontract' className='button-select'>
                    <div className='image-div'>
                      <img className='w-10' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/air-mail.svg' alt='' />
                    </div>
                    <div className='text-style'>
                      <b style={{ marginLeft: '20px', fontSize: '18px', color: '#374151' }}>Contratación de Visa</b>
                      <p style={{ fontSize: '14px', marginLeft: '20px', color: '#1F2A37', paddingRight: '10px' }}>Materializa tu viaje a Canadá y hacer seguimiento a tu proceso, Cotiza Gratis.</p>

                      <div className='button-div'>
                        <button className='buton-primary'
                          style={{
                            borderRadius: '50px',
                            border: '2px solid var(--dark-dark, #111928)',
                            background: 'var(--dark-dark, #111928)',
                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                            marginTop: '10px', 
                            color: 'white',
                            width: '15rem',
                            height: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >


                          <div className='button-logo'>
                            <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Gratis</div>
                        </button>
                      </div>
                    </div>
                  </Link>
                </div> */}

                <Link to='/cart/immigrationservicescontract' className='powerup-card  '>

                  <div className='powerup-card-left-style inmigracion' style={{ background: 'red', }}>
                    <img className='w-10' src="/media/svg/menu-icons/air-mail.svg" alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2 >Contratación de Visa</h2>
                      <p >Materialice su viaje a Canadá y haga seguimiento a su proceso. Cotice gratis.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' />Gratis
                    </button>

                  </div>
                </Link>

{/* 
                <div className='button-container'>

                  <Link to='/cart/consultamigratoria' className='button-select'>
                    <div className='image-div'>
                      <img className='w-10' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/air-mail.svg' alt='' />
                    </div>
                    <div className='text-style'>
                      <b style={{ marginLeft: '20px', fontSize: '18px', color: '#374151' }}>Cita migratoria con consultor</b>
                      <p style={{ fontSize: '14px', marginLeft: '20px', color: '#1F2A37', paddingRight: '10px' }}>Resuelve tus dudas y coordina una cita con el consultor que prefieras.</p>

                      <div className='button-div'>
                        <button className='buton-primary'
                          style={{
                            borderRadius: '50px',
                            border: '2px solid var(--dark-dark, #111928)',
                            background: 'var(--dark-dark, #111928)',
                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                            marginTop: '10px', 
                            color: 'white',
                            width: '15rem',
                            height: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >


                          <div className='button-logo'>
                            <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Desde CAD $150</div>
                        </button>
                      </div>
                    </div>
                  </Link>
                </div> */}

                <Link to='/cart/consultamigratoria' className='powerup-card  '>

                  <div className='powerup-card-left-style inmigracion' style={{ background: 'red', }}>
                    <img className='w-10' src="/media/svg/menu-icons/air-mail.svg" alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2>Cita migratoria con consultor</h2>
                      <p >Resuelva sus dudas y coordine una cita con el consultor que prefiera.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Desde CAD $150
                    </button>

                  </div>
                </Link>


              </div>
            )}

            {showEducationSubButtons && (
              <div className='container-all-buttons'>

                {/* <div className='button-container'>
                  <Link to='/cart/eduoption' className='button-select'>
                    <div className='image-div-edu1'>
                      <img className='w-10' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/graduation.svg' alt='' />
                    </div>
                    <div className='text-style'>
                      <b style={{ marginLeft: '20px', fontSize: '18px', color: '#374151' }}>Opciones Educativas</b>
                      <p style={{ fontSize: '14px', marginLeft: '20px', color: '#1F2A37', paddingRight: '10px' }}>Apoyo a estudiantes para buscar opciones o programas en colleges o institutos.</p>

                      <div className='button-div'>
                        <button className='buton-primary'
                          style={{
                            borderRadius: '50px',
                            border: '2px solid var(--dark-dark, #111928)',
                            background: 'var(--dark-dark, #111928)',
                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                            marginTop: '10px', 
                            color: 'white',
                            width: '15rem',
                            height: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >


                          <div className='button-logo'>
                            <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Gratis</div>
                        </button>
                      </div>
                    </div>
                  </Link>
                </div> */}

                <Link to='/cart/eduoption' className='powerup-card '>

                  <div className='powerup-card-left-style educacion' style={{ background: 'red', }}>
                    <img className='w-10' src='/media/svg/menu-icons/graduation.svg' alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2>Opciones educativas</h2>
                      <p >Apoyo a estudiantes para buscar opciones o programas en colleges o institutos.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Gratis
                    </button>

                  </div>
                </Link>

                {/* <div className='button-container'>
                  <Link to='/cart/OpcionesEducativas' className='button-select'>
                    <div className='image-div-edu'>
                      <img className='w-10' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/graduation.svg' alt='' />
                    </div>
                    <div className='text-style'>
                      <b style={{ marginLeft: '20px', fontSize: '18px', color: '#374151' }}>Asesoria Educativa</b>
                      <p style={{ fontSize: '14px', marginLeft: '20px', color: '#1F2A37', paddingRight: '10px' }}>Selecciona a uno de nuestros consultores para que te ayuden con una asesoria</p>

                      <div className='button-div'>
                        <button className='buton-primary'
                          style={{
                            borderRadius: '50px',
                            border: '2px solid var(--dark-dark, #111928)',
                            background: 'var(--dark-dark, #111928)',
                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                            marginTop: '10px', 
                            color: 'white',
                            width: '15rem',
                            height: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >


                          <div className='button-logo'>
                            <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Desde CAD $35</div>
                        </button>
                      </div>
                    </div>
                  </Link>
                </div> */}

                <Link to='/cart/OpcionesEducativas' className='powerup-card '>

                  <div className='powerup-card-left-style educacion' style={{ background: 'red', }}>
                    <img className='w-10' src='/media/svg/menu-icons/graduation.svg' alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2>AsesorÍa Educativa</h2>
                      <p >Seleccione a uno de nuestros consultores para que Le ayuden con una asesorÍa.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Desde  CAD $35
                    </button>

                  </div>
                </Link>


                {/* <div className='button-container'>
                  <Link to='https://www.immilandcanada.com/educacion/registro-institucion-master' className='button-select'>
                    <div className='image-div-edu'>
                      <img className='w-10' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/graduation.svg' alt='' />
                    </div>
                    <div className='text-style'>
                      <b style={{ marginLeft: '20px', fontSize: '18px', color: '#374151' }}>Registro Academico</b>
                      <p style={{ fontSize: '14px', marginLeft: '20px', color: '#1F2A37', paddingRight: '10px' }}>Nuestro Formulario de registro completo a su institución superior elegida.</p>

                      <div className='button-div'>
                        <button className='buton-primary'
                          style={{
                            borderRadius: '50px',
                            border: '2px solid var(--dark-dark, #111928)',
                            background: 'var(--dark-dark, #111928)',
                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                            marginTop: '10px', 
                            color: 'white',
                            width: '15rem',
                            height: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >


                          <div className='button-logo'>
                            <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Desde  CAD $2,000</div>
                        </button>
                      </div>
                    </div>
                  </Link>
                </div> */}

                

                <Link to='https://www.immilandcanada.com/educacion/registro-institucion-master' className='powerup-card '>

                  <div className='powerup-card-left-style educacion' style={{ background: 'red', }}>
                    <img className='w-10' src='/media/svg/menu-icons/graduation.svg' alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2>Registro académico</h2>
                      <p >Nuestro Formulario de registro completo a su institución superior elegida.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Desde  CAD $2,000
                    </button>

                  </div>
                </Link>


                {/* <div className='button-container'>
<Link to='https://www.immilandcanada.com/educacion/registro-idiomas-online' className='button-select'>
  <div className='image-div-edu'>
    <img className='w-10' style={{padding:'5px', marginTop:'5px'}} src='/media/svg/menu-icons/graduation.svg' alt='' />
  </div>
  <div className='text-style'>
    <b style={{ marginLeft: '20px' , fontSize:'18px', color:'#374151' }}>Registro Idiomas</b>
    <p style={{ fontSize: '14px', marginLeft: '20px', color:'#1F2A37',paddingRight:'10px' }}>Nuestro Formulario de registro completo a su institución de idioma de preferencia.</p>
 
  <div className='button-div'>
    <button className='buton-primary'
      style={{
        borderRadius: '50px',
        border: '2px solid var(--dark-dark, #111928)',
        background: 'var(--dark-dark, #111928)',
        boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
        marginTop: '10px', 
        color:'white',
       width:'15rem',
       height:'3rem',
       display:'flex',
       justifyContent: 'center',
       alignItems: 'center',
      }}
    >

      
      <div className='button-logo'>
      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Desde CAD $125</div>
    </button> 
    </div>
  </div>
</Link>
</div> */}



                <Link to='https://www.immilandcanada.com/educacion/registro-idiomas-online' className='powerup-card '>

                  <div className='powerup-card-left-style traducciones' style={{ background: 'red', }}>
                    <img className='w-10' src="/static/media/world.55d4e9d90f8ec9484cf4.svg" alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2>Registro Idiomas</h2>
                      <p >Nuestro formulario de registro completo a su institución de idioma de preferencia.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Desde CAD $125
                    </button>

                  </div>
                </Link>
              </div>
            )}

            {activeButton === 'recommendations' && (
              <div className='powerup-card-container'>


                <Link to='https://www.immilandcanada.com/traduccion/inicio' className='powerup-card '>
                  <div className='powerup-card-left-style traducciones'>
                    <img className='w-10' src="/static/media/world.55d4e9d90f8ec9484cf4.svg" alt='' />
                  </div>
                  <div className='powerup-card-right-style'>
                    <div className='description'>
                      <h2>Traducciones</h2>
                      <p >Acelere sus procesos con una transcripción de calidad para sus documentos.</p>
                    </div>

                    <button className='button-ui button-primary button-s'>
                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Desde CAD $37
                    </button>

                  </div>

                </Link>


                <Link to='https://www.immilandcanada.com/establecimiento/inicio' className='powerup-card '>

                  <div className='powerup-card-left-style settlement' style={{ background: 'red', }}>
                    <img className='w-10' style={{ padding: '5px', marginTop: '5px' }} src='/media/svg/menu-icons/setle.svg' alt='' />
                  </div>
                  <div className='powerup-card-right-style text-style'>
                    <div className='description'>
                      <h2>Settlement</h2>
                      <p>Le apoyamos con asesorÍa profesional encontrando un buen lugar para vivir.</p>

                    </div>

                    <button className='button-ui button-primary button-s'
                    >

                      <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Gratis
                    </button>

                  </div>
                </Link>




                

                  <Link to='/cart/OpcionesEducativas' className='powerup-card '>
                    <div className='powerup-card-left-style educacion'>
                      <img className='w-10' src='/media/svg/menu-icons/graduation.svg' alt='' />
                    </div>
                    <div className='powerup-card-right-style'>
                      <div className='description'>
                        <h2>Asesoría educativa</h2>
                        <p >Explore el sistema educativo canadiense con las diferentes instituciones en la provincia(s) de su interés.</p>
                      </div>

                      <button className='button-ui button-primary button-s'>
                        <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' /> Gratis
                      </button>

                    </div>

                  </Link>

                  {/* <Link to='/cart/OpcionesEducativas' className='button-select'>
     <div className='image-div-edu'>
       <img className='w-10' style={{padding:'5px', marginTop:'5px'}} src='/media/svg/menu-icons/graduation.svg' alt='' />
     </div>
     <div className='text-style'>
       <b style={{ marginLeft: '20px' , fontSize:'18px', color:'#374151' }}>Asesoria Educativa</b>
       <p style={{ fontSize: '14px', marginLeft: '20px', color:'#1F2A37',paddingRight:'10px' }}>Explore el sistema educativo canadiense con las diferentes instituciones en la provincia(s) de su interés.</p>
    
     <div className='button-div'>
       <button className='buton-primary'
         style={{
           borderRadius: '50px',
           border: '2px solid var(--dark-dark, #111928)',
           background: 'var(--dark-dark, #111928)',
           boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
           marginTop: '10px', 
           color:'white',
          width:'15rem',
          height:'3rem',
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
         }}
       >
   
         
         <div className='button-logo'>
         <img className='w-4' src='/media/svg/menu-icons/chart.svg' alt='' style={{ marginRight: '5px' }} /> Gratis</div>
       </button> 
       </div>
     </div>
   </Link> */}
                </div>

            )}
          </div>
        </div>
      </Container>

      {/*  <CSSTransition
      in={showSlideOver}
      timeout={300}
      classNames="overlay"  
      unmountOnExit
      onExited={handleCloseSlideOver}
    >
      <SlideOverComponent onClose={handleCloseSlideOver}  products={products}  />
    </CSSTransition> */}
    </>

  );
};
export default UserCart;
