/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import search from '../../pages/dashboard/userDasboardComponents/userDashboardIcons/search-alt.svg'
import { RootStoreContext } from 'stores/rootStore'
import WhiteEnvelope from '../contratationApp/icons/WhiteEnvelope.svg'
import { getUserNewQuery } from 'app/modules/apps/users/core/_requests'
import { Users } from 'app/modules/apps/users/core/_models'
import { useQuery } from 'react-query'
import { getAllMessages } from 'app/modules/apps/messages/core/_requests'
import { Link } from 'react-router-dom'

interface Props {
  setCreateMessage: Dispatch<SetStateAction<boolean>>
  setIsUnread: Dispatch<SetStateAction<boolean>>
  setIsFavorite: Dispatch<SetStateAction<boolean>>
  handleSearchMessages: (value: string) => void
  hide: boolean
}

const NewMessageImbox: React.FC<Props> = ({
  hide,
  setCreateMessage,
  setIsUnread,
  handleSearchMessages,
  setIsFavorite }) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const [isFetching, setIsFetching] = useState<any>()
  const [wordSearched, setWordSearched] = useState('')
  const [loading, setLoading] = useState<any>()
  const [users, setUsers] = useState<any>()
  const [showList, setShowList] = useState(true)
  const { loadMessages,
    setMessageNew,
    setMessage,
    loadMessagesNew,
    messageSelected,
    setMessageSelected,
    setWord,
    setWordNewFetch } = rootStore.messageStore

  const onClickUser = async (user: Users) => {
    if (loading === false || loading === undefined) {
      setMessageSelected(false)
      setLoading(true)
      setWordSearched(user.email)
      setWord(user.id)
      setShowList(false)
      const data = {
        search: user.id,
        pagination: 1,
      };
      const result = await getAllMessages(data)
      console.log("result", result)
      if (result) {
        setWordNewFetch(result)
        setMessageNew(result)
        setMessage(result)
        setLoading(false)
      }
    }
  }

  const changeInputUser = async (search: string) => {
    if (loading === false || loading === undefined) {
      setShowList(true)
      setIsFetching(true)
      setWordSearched(search)
      setWord(search)
      if (search) {
        try {
          const result = await getUserNewQuery({ email: search })
          setUsers(result)
          setIsFetching(false)
        } catch (e) {
          setIsFetching(false)
        }
      }
      setIsFetching(false)
    }
  }

  const detectEnter = async (e: any) => {
    if (e.target.value !== '' && (loading === false || loading === undefined)) {
      setMessageSelected(false)
      if (e.key === 'Enter') {
        setShowList(false)
        setLoading(true)
        console.log('Enter key pressed!', e.target.value);
        // You can add your own code to handle the Enter key press here
        const data = {
          search: e.target.value,
          pagination: 1,
        };
        const result = await getAllMessages(data)
        console.log("result", result)
        if (result) {
          setWordNewFetch(result)
          setMessageNew(result)
          setMessage(result)
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    console.log("cambie")
    setWordSearched("")
    setWord("")
  }, [messageSelected])


  useEffect(() => {
    console.log("cambie el nombre", wordSearched)
  }, [wordSearched])

  return (
    <div className='w-full'>
      <div style={{
        display: 'flex',
        gap: '7px',
        fontSize: '12px',
        marginTop: '2rem',
        marginLeft: '1rem'
      }}>
        <img
          alt="Arrow"
          style={{ marginBottom: '4px' }}
          src="/media/svg/menu-icons/home.svg"
        />
 <Link to='/dashboard' style={{
  color: '#111928',
  fontWeight: 'bold',
  fontSize: '12px'
}}>
  Inicio
</Link>

        <img
          alt="Arrow"
          src="/media/svg/menu-icons/arrowright.svg" />
        <a style={{
          color: '#637381',
          fontWeight: 'bold',
          fontSize: '12px'
        }}>Mensajes
        </a>
      </div>
      <div className="inbox-continer-component">
        <h1 className='title-style-inbox'>Mensajes</h1>
        {staff && hide === false &&
          <div
            style={{ height: '65px' }}
            className="first-blue hide-component">
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              className="flex flex-row items-start gap-4">
              <div style={{
                border: 'solid 3px #0B76B7',
                color: '#0B76B7',
                paddingRight: '0.5rem',
                paddingLeft: '0.5rem',
                borderRadius: '50%',
                fontWeight: 'bold'
              }}>
                i
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="flex flex-row gap-8">
                <h1 style={{
                  color: '#0B76B7',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  margin: '0'
                }}>Para anuncios masivos sin documentos</h1>
                <button
                  style={{ backgroundColor: 'white', width: '10rem' }}
                  className="button2">
                  <div className='flex'>
                    <p className='meet-text2'>Ingresa a <b>Brevo</b></p>
                  </div>
                </button>
              </div>
            </div>
          </div>}
        <button onClick={() => {
          setCreateMessage(true)
        }}
          className='button-ui button-primary'>
          <img src={WhiteEnvelope} alt="WhiteEnvelope hide-component" />
          <p className='create-message-text'>CREAR MENSAJE</p>
        </button>

      </div>
      <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }}></div>
      <div
        style={{ display: 'flex', justifyContent: `${staff ? 'space-between' : 'flex-end'}` }}
        className='p-4 hide-component'>
        {staff && hide === false &&
          <div style={{ position: 'relative', width: '390px' }}>
            <input
              style={{ width: '100%', border: '1px solid #637381', color: '#637381' }}
              type="text"
              placeholder="Ingresa el nombre de un cliente"
              className='input-styles'
              value={wordSearched}
              onChange={(e) => {
                changeInputUser(e.target.value)
              }}
              onKeyDown={(e) => { detectEnter(e) }} />
            <img className='search-icon' src={search} alt="Search" />
          </div>}
        {loading && hide === false && <>cargando..</>}
        {isFetching === true && hide === false && wordSearched ?
          <div className='results-list2'>
            Cargando...
          </div>
          : <>
            {wordSearched && showList && (
              <div
                className='results-list3'>
                {users?.data?.map((user: Users) => (
                  <div
                    onClick={() => {
                      onClickUser(user)
                    }}
                    className='search-result'
                    /* key={user.id}  value={user.id}  */>
                    {user.email + "--" + user.firstName + " " + user.lastName}
                  </div>
                ))}
              </div>
            )}
          </>}
        <div className='hide-component flex' style={{ gap: '10px' }}>
          <button
            onClick={() => {
              setWord("")
              setIsUnread(false)
              setIsFavorite(false)
              handleSearchMessages('inbox')
              loadMessagesNew("inbox", 1)
              loadMessages("inbox")
              setWordSearched("")
            }}
            style={{ backgroundColor: 'white', width: '7rem', paddingTop: '7px' }}
            className='button-ui button-secondary button-s'>
            <div className='flex' style={{ display: 'flex', justifyContent: 'center' }}>
              <p className='meet-text2'>Ver todos</p>
            </div>
          </button>
          <button
            onClick={() => {
              setWord("")
              setIsUnread(true)
              handleSearchMessages('inbox')
              setWordSearched("")
            }}
            style={{ backgroundColor: 'white', width: '7rem', paddingTop: '7px' }}
            className='button-ui button-secondary button-s'>
            <div className='flex' style={{ display: 'flex', justifyContent: 'center' }}>
              <p className='meet-text2'>No leidos</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default (NewMessageImbox)
