import {Autocomplete, type IOption} from '../../../components'
import React, {useContext, useEffect} from 'react'
import {RootStoreContext} from '../../../stores/rootStore'
import moment from 'moment'

interface IProps {
  selected: IOption
  setSelected: (option: IOption) => void
}

const TimeZoneSelect: React.FC<IProps> = ({selected, setSelected}) => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const [option, setOption] = React.useState(selected)

  const timeZones = moment.tz.names()
  const [options, setOptions] = React.useState<IOption[]>([])

  useEffect(() => {
    if (user?.timeZone) {
      setOption({id: user.timeZone, value: user.timeZone})
    }
  }, [user])

  useEffect(() => {
    const newOptions = timeZones.map((zone) => {
      return {
        id: zone,
        value: zone,
      }
    })
    setOptions(newOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (query: string) => {
    const newOptions = timeZones
      .filter((zone) => zone.toLowerCase().includes(query.toLowerCase()))
      .map((zone) => {
        return {
          id: zone,
          value: zone,
        }
      })
    setOptions(newOptions)
  }

  return (
    <>
      <Autocomplete
        options={options}
        onChange={handleSearch}
        isLoading={false}
        onSelect={(option) => {
          setSelected(option)
          setOption(option)
        }}
        selected={option}
      />
    </>
  )
}
export default TimeZoneSelect
