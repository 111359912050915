import styled from 'styled-components'

const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const DesktopModalContainer = styled(ModalContainer)`
  border-radius: 7px;
  box-shadow: 0 0 64px rgba(0,0,0,0)
  padding: 40px;
  width: 50vw;
  height: fit-content;
  font-size: 16px;
  background-color: white;
  padding-bottom: 80px;
`

export const Header = styled.h3`
  color: #c94646;
  font-size: 35px;
  line-height: 1em;
  font-weight: bold;
  margin: 5px 0 10px;
  text-align: center;
`

export const UserImage = styled.img`
  width: 200px;
  height: 200px;
  padding: 10px;
  margin-top: 30px;
  border-radius: 100%;
`

export const DataDiv = styled.div`
  width: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
`
