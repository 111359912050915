/* eslint-disable @typescript-eslint/no-unused-vars*/

import { Alert, Menu, Tooltip } from 'antd'
import {
  AvatarSection,
  CardWindow,
  Content,
  Header,
  TittleBar,
} from '../../../../core/components/CardWindow'
import { BsReply, BsReplyAll } from 'react-icons/bs'
import {
  getMessagesUnseen,
  getMessagesUsersIdMessageGeneral,
} from '../../../../app/modules/apps/messages/core/_requests'
import { useContext, useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import FilesShowTable from '../FilesShowTable'
import { type IReply } from '../../../../app/modules/apps/appointments/core/_models'
import { type Messages } from '../../../../app/modules/apps/messages/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import { TimeZone } from '../../../../app/modules/utils/components/TimeZone'
import { getContent } from '../../../../core/components/email/utils/ContentManager'
import parse from 'html-react-parser'
import { useQuery } from 'react-query'
import { MailAvatar } from '../../../../core/components/email'
import { Button } from '../../../../components/Button'
import BaseModalWrapper from '../../../../components/profilePopup/BaseModalWarpper'
import Swal from 'sweetalert2'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  messageId: string
  setShowMessage: React.Dispatch<React.SetStateAction<boolean>>
  setlist: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  setComposepostdata: React.Dispatch<React.SetStateAction<IReply>>
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
}

const ShowMessages: React.FC<IProps> = ({
  messageId,
  setShowMessage,
  handleClose,
  setComposepostdata,
  setlist,
  setReplyMessage,
  setShowMessageRows,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { loadMessages } = rootStore.messageStore
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = () => {
    setIsModalVisible((wasModasVisible) => !wasModasVisible)
  }

  const { data, status, refetch } = useQuery(
    'ShowMessagesGeneral',
    async () => await getMessagesUsersIdMessageGeneral(messageId),
    {
      enabled: messageId !== '',
      onSuccess: () => {
        // comment to deactivate eslint
      },
    }
  )

  useEffect(() => {
    if (messageId) {
      refetch()
    }
    // eslint-disable-next-line
  }, [messageId])

  const handleCloseMessage = () => {
    setReplyMessage(false)
    handleClose()
    if (setShowMessage) setShowMessage(false)
  }

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const handleReply = (message: Messages) => {
    const data: IReply = {
      orderDetailId: message.orderDetailId,
      user: `${message.user}`,
      email: message.fromEmail,
      userId: message.fromId,
      fromId: message.userId,
      messageId: message.id || '',
      content: /* getContent(message.content) */'',
      subject: `Re: ${message.subject}`,
      to: '{}',
      cc: '{}',
      type: 'reply',
      attachment: '',
      fromUser: message.userId,
      folder: 'inbox',
      draft: false,
      favorite: false,
      files_info: [],
      unread: false,
    }
    setComposepostdata(data)
    setReplyMessage(true)
  }

  const handleForward = (message: Messages) => {
    const data: IReply = {
      orderDetailId: message.orderDetailId,
      user: `${message.user}`,
      email: message.fromEmail,
      userId: message.fromId,
      fromId: message.userId,
      messageId: message.id || '',
      content: getContent(message.content),
      subject: `Fw: ${message.subject}`,
      to: '{}',
      cc: '{}',
      type: 'forward',
      attachment: '',
      fromUser: message.userId,
      folder: 'inbox',
      draft: false,
      favorite: false,
      files_info: [],
      unread: false,
    }
    setComposepostdata(data)
    setlist(false)
    setShowMessage(false)
    setReplyMessage(true)
  }

  if (status === 'success' && data) {
    return (
      <>
        <CardWindow style={{ height: 'fit-content', width: '-webkit-fill-available' }}>
          <Header style={{ height: 'auto', marginBottom: '0' }}>
            <div
              className=' text-xs flex'
              style={{ paddingBottom: '0.5rem', marginBottom: '1rem', gap: '0.8rem' }}>
              <div>
                <AvatarSection className='lg:w-2/12'>
                  <button onClick={toggleModal}>
                    <AvatarProfileUsers
                      image={
                        data && data[0] && data[0].fromPathId
                          ? `${data[0].fromPath}/${data[0].fromPathId}-avatar-small.jpg`
                          : '/media/avatars/avatar.png'
                      }
                      size={70}/>
                  </button>
                  <BaseModalWrapper
                    setIsModalVisible={setIsModalVisible}
                    isModalVisible={isModalVisible}
                    onBackdropClick={toggleModal}
                    name={data[0].fromName}
                    email={data[0].fromEmail}
                    fromId={data[0].fromId}
                    fromPathId={data[0].fromPathId}
                    fromPath={data[0].fromPath}/>
                </AvatarSection>
              </div>
              <div>
                <h1 style={{ fontSize: '1.3rem', margin: '0' }}>{` ${data[0].fromName} `}</h1>
                <TimeZone date={data[0].created_at ? data[0].created_at : null} />
                <div className='text-xs'>{`${data[0].fromEmail} `}</div>
                {data[0].folder === 'sent' ? (
                  <span style={{ fontWeight: 'bold' }}>{`Para: ${data[0].fromName} `}</span>
                ) : (
                  <span style={{ fontWeight: 'bold' }}>{`De: ${data[0].fromName} `}</span>
                )}
              </div>
              <div className='w-full justify-end my-auto mr-2 flex items-center hover:bg-gray-100 hover:rounded-full cursor-pointer'>
                <AiOutlineCloseCircle
                  className='w-6 h-6  m-1 md:m-2'
                  onClick={() => {
                    handleCloseMessage
                    setShowMessage(false)
                    setShowMessageRows(true)
                    setReplyMessage(false)
                  }}/>
              </div>
            </div>
          </Header>
          {data[0].folder === 'draft' ? (
            <Alert message='Borrador' type='warning' />
          ) : null}
          <Content>
            <div style={{ borderBottom: '1px solid gray', padding: '0.5rem', paddingBottom: '1rem' }}>
              <div className='text-3xl'>
                <p style={{ width: '100%' }}>{parse(data[0].subject) || ''}</p>
              </div>
              <div>
                <p style={{ width: '100%' }}>{parse(data[0].content) || ''}</p>
              </div>
              <Menu className='flex justify-center items-center m-auto content-end bg-tomato-400 '>
                <Tooltip title='Responder'>
                  <div className='w-full justify-end my-auto mr-2 flex items-center hover:bg-gray-100 hover:rounded-full cursor-pointer'>
                    <a style={{ width: '100%' }} href='#ReplyMessage'>
                      <Button
                        onClick={() => {
                          handleReply(data[0])
                        }}>
                        <BsReply className='w-6 h-6 m-1 md:m-2' key='reply' />
                        Responder
                      </Button>
                    </a>
                  </div>
                </Tooltip>
                <Tooltip title='Reenviar'>
                  <div className='w-full justify-end my-auto mr-2 flex items-center hover:bg-gray-100 hover:rounded-full cursor-pointer '>
                    <Button
                      onClick={() => {
                        handleForward(data[0])
                      }}>
                      <BsReplyAll key='resend' className='w-6 h-6  m-1 md:m-2' />
                      Reenviar
                    </Button>
                  </div>
                </Tooltip>
              </Menu>
            </div>
            <TittleBar style={{ height: 'auto', marginTop: '2rem' }}>
              <div
                className=' text-xs flex'
                style={{ paddingBottom: '0.5rem', marginBottom: '1rem', gap: '0.8rem' }}>
                <div>
                  <MailAvatar className='lg:w-2/12'>
                    <button onClick={toggleModal}>
                      <AvatarProfileUsers
                        image={
                          data && data[0] && data[0].fromPathId
                            ? `${data[0].fromPath}/${data[0].fromPathId}-avatar-small.jpg`
                            : ''
                        }
                        size={70}/>
                    </button>
                    <BaseModalWrapper
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      onBackdropClick={toggleModal}
                      name={data && data[0] && data[0].fromName}
                      email={data && data[0] && data[0].fromEmail}
                      fromId={data && data[0] && data[0].fromId}
                      fromPathId={data && data[0] && data[0].fromPathId}
                      fromPath={data && data[0] && data[0].fromPath}
                    />
                  </MailAvatar>
                </div>
                <div>
                  <h1 style={{ fontSize: '1.3rem', margin: '0' }}>{` ${data[0].fromName} `}</h1>
                  <TimeZone date={data[0].created_at ? data[0].created_at : null} />
                  <div className='text-xs'>{`${data[0].fromEmail} `}</div>
                  {data[0].folder === 'sent' ? (
                    <span style={{ fontWeight: 'bold' }}>{`Para: ${data[0].fromName} `}</span>
                  ) : (
                    <span style={{ fontWeight: 'bold' }}>{`De: ${data[0].fromName} `}</span>
                  )}
                </div>
              </div>
            </TittleBar>
            <div className=' '>{parse(data[0].content) || ''}</div>
          </Content>
        </CardWindow>
        <div>
          <FilesShowTable files={data} />
        </div>
      </>
    )
  } else {
    return <> No data</>
  }
}
export default ShowMessages
