import React, {type ReactNode} from 'react'
import {Tabs} from 'antd'

const {TabPane} = Tabs

interface IProps {
  options: ReactNode
  requirements: ReactNode
  funds: ReactNode
  elements: ReactNode
  costs: ReactNode
  links: ReactNode
}

const TabsTemplate: React.FC<IProps> = ({options, requirements, funds, elements, costs, links}) => {
  return (
    <Tabs type='card' tabPosition='left'>
      <TabPane tab='Opciones migratorias' key='1'>
        {options}
      </TabPane>
      <TabPane tab='Requisitos' key='2'>
        {requirements}
      </TabPane>
      <TabPane tab='Monto de dinero' key='3'>
        {funds}
      </TabPane>
      <TabPane tab='Elementos para trabajar' key='4'>
        {elements}
      </TabPane>
      <TabPane tab='Costos' key='5'>
        {costs}
      </TabPane>
      <TabPane tab='Links' key='6'>
        {links}
      </TabPane>
    </Tabs>
  )
}

export default TabsTemplate
