import * as Yup from 'yup'

const validationDatatSchema = Yup.object().shape({
  description: Yup.string().required('Este campo es requerido'),
  name: Yup.string().required('Este campo es requerido'),
  order: Yup.number(),
  immigrationService: Yup.object(),
  files: Yup.array(),
})

export default validationDatatSchema
