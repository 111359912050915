/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react'
import {Input, Button, DatePicker, Select, Menu, Dropdown} from 'antd'
import moment from 'moment'
import './newtimeline.scss'
import {RootStoreContext} from 'stores/rootStore'

interface ITimelineSlideProps {
  isSlideOverVisible: boolean
  setIsSlideOverVisible: (visible: boolean) => void
  eventTitle: string
  eventStatus: string
  setEventTitle: (title: string) => void
  eventDescription: string
  setEventDescription: (description: string) => void
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
  handleCreateEvent: () => void
  setEventStatus: Dispatch<SetStateAction<string>>
  setTagger: Dispatch<SetStateAction<string>>
  idgger: Dispatch<SetStateAction<string>>
  selectedDropdownItem: string
  setSelectedDropdown: (value: string) => void
  additionalData: string
  setAdditionalData: Dispatch<SetStateAction<string>>
  isEditMode: boolean
  setIsEditMode: Dispatch<SetStateAction<boolean>>
}

const TimelineSlide: React.FC<ITimelineSlideProps> = ({
  isSlideOverVisible,
  setIsSlideOverVisible,
  eventTitle,
  setEventTitle,
  eventDescription,
  setEventDescription,
  selectedDate,
  setSelectedDate,


  handleCreateEvent,
  eventStatus,
  setEventStatus,
  setTagger,
  idgger,
  selectedDropdownItem,
  setSelectedDropdown,
  additionalData,
  isEditMode,
  setAdditionalData,
  setIsEditMode,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([])
  const [selectedEventTag, setSelectedEventTag] = useState<string>('')
  const rootStore = useContext(RootStoreContext)
  const {user: userrole} = rootStore.authStore
  const [errorMessage, setErrorMessage] = useState('')
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  function generateRandomId() {
    return Math.random().toString(36).substring(2, 10) // Puedes ajustar la longitud según tus necesidades
  }

  const handleDropdownSelect = (e: any) => {
    const selectedKey = e.key
    setSelectedDropdown(selectedKey)

    let title = ''
    let description = ''

    if (selectedKey === 'Mejorar el inglés') {
      title = 'Mejorar el inglés'
      description = 'Su nivel actual debe ser llevado a un nivel avanzado'
      additionalData =
        'Idealmente a un CLB 9 que equivale a 9 en todas las bandas, si toma CELPIP, o triple 7 en reading writing, speaking y 8 en listening si toma IELTS general. Estudie idiomas con Immiland. Ingrese aquí: <a href="https://www.planeta-immiland-education.com/">https://www.planeta-immiland-education.com/</a> '
    } else if (selectedKey === 'Aprender frances') {
      title = 'Aprender frances'
      description = 'Debe llevar su nivel de Frances  a un nivel intermedio'
      additionalData =
        'Llevarlo a un nivel intermedio. Si toma la prueba TEF, debe lograr como mínimo 207 en lectura, 310 en escritura, 249 en escucha  y 310 en habla. Estudie idiomas con Immiland. Ingrese aquí:<a href="https://www.planeta-immiland-education.com/">https://www.planeta-immiland-education.com/</a> '
    } else if (selectedKey === 'Comenzar el proceso de ECA, se recomienda el WES') {
      title = 'Comenzar el proceso de ECA, se recomienda el WES'
      description =
        'Este proceso permite obtener una evaluación comparativa de sus estudios respecto al nivel educativo de los mismos en Canadá.'
      additionalData =
        'Es necesario conseguir el ECA del nivel de estudios más alto; por ejemplo, si tiene licenciatura y maestría, entonces solicite el de la maestría solamente. En este link, consigue información sobre la ECA para Express Entry: <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/education-assessed.html">https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/education-assessed.html</a>'
    } else if (selectedKey === 'Tomar la prueba de inglés IELTS general o CELPIP') {
      title = 'Tomar la prueba de inglés IELTS general o CELPIP'
      description =
        'La prueba de idiomas es el único elemento que puede justificar su nivel de idiomas ante IRCC.'
      additionalData =
        'En estos links, se puede registrar para la prueba IELTS general o CELPIP:  <a href="https://takeielts.britishcouncil.org/take-ielts/book">https://takeielts.britishcouncil.org/take-ielts/book</a> <a href="https://www.celpip.ca/take-celpip/where-do-we-test/">https://www.celpip.ca/take-celpip/where-do-we-test/</a>'
    } else if (selectedKey === 'Recaudar los fondos necesarios') {
      title = 'Recaudar los fondos necesarios'
      description = 'Estos fondos son los requeridos para demostrar solvencia económica.'
      additionalData =
        'En este link, consigue información sobre las pruebas de fondos para el Express Entry: <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html">https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html</a>'
    } else if (selectedKey === 'Tomar la prueba de francés TEF') {
      title = 'Tomar la prueba de francés TEF'
      description =
        'La prueba de idiomas es el único elemento que puede justificar su nivel de idiomas ante IRCC'
      additionalData =
        'En este link, se puede registrar para la prueba TEF: <a href="https://www.lefrancaisdesaffaires.fr/en/find-a-center/">https://www.lefrancaisdesaffaires.fr/en/find-a-center/</a>'
    } else if (selectedKey === 'Solicitar comprobantes laborales') {
      title = 'Solicitar comprobantes laborales'
      description =
        'Un elemento importante para su proceso es demostrar experiencia de trabajo en el TEER calificado vinculado a sus estudios'
      additionalData =
        'El comprobante debe incluir: <p>Tareas de cargo en relación al TEER calificado.</p> <p>Título de cargo.</p> <p>Horas.</p> <p>Salario.</p> <p>Fechas.</p> <p>Recuerde que entre más años haya trabajado más puntos tendrá. El máximo puntaje se da a personas con 6 años de experiencia en adelante, comenzando a otorgar puntos desde el primer año. </p>'
    } else if (selectedKey === 'Registrar unión libre') {
      title = 'Registrar unión libre'
      description =
        'Si su pareja y usted tienen una relación de concubinato, es importante que se proceda a la formalización de la misma con un registro.'
      additionalData =
        'Además, es importante evidencia que demuestre 12 meses de vida en común. Los elementos de evidencia pueden ser: <p>Cuenta bancaria a nombre de ambos. </p> <p>Propiedades a nombre de ambos.</p> <p>Contrato de arrendamiento a nombre de ambos.</p>'
    } else if (selectedKey === 'Viajar') {
      title = 'Viajar'
      description = 'Tener historial de viajes es trascendental para su proceso.'
      additionalData =
        'Lo recomendado es viajar a países que no sean vecinos. El viaje puede ser de 3 días, por ejemplo. El objetivo es conseguir stamps en el pasaporte que demuestran que en el pasado ha visitado otros países y ha respetado sus leyes migratorias. Por ejemplo, si vive en América del Sur, puede viajar a América Central y viceversa. Si está en sus capacidades viajar a Europa, puede hacerlo siempre y cuando no descuente de su solvencia económica. Evitar los viajes a países que solicitan visado, ya que un rechazo de visa puede afectar su proceso de solicitud de visa con Canadá.'
    } else if (selectedKey === 'Iniciar con la traducción de documentos') {
      title = 'Iniciar con la traducción de documentos'
      description =
        'Tome en cuenta que todos los documentos usados para su proceso de visado deben estar traducidos.'
      additionalData =
        'Si desea los servicios de Immiland para este proceso, puede contratarlos aquí: https://www.immilandcanada.com/traduccion/inicio.'
    } else if (selectedKey === 'Affidavit') {
      title = 'Affidavit'
      description =
        'Si tiene un padrino «sponsor» que cubrirá sus gastos en Canadá, es importante que el mismo firme un affidavit en el que se compromete a solventar todos sus gastos.'
      additionalData =
        'También, se recomienda que: <p>El dinero que proporciona para sus estudios sea transferido a su cuenta de banco (la del estudiante).</p> <p>El sponsor demuestre con una carta hecha por un contador que, además del dinero que pone a su disposición, cuenta con fondos extras para cubrir sus gastos personales.</p> <p>Si usted es una persona independiente, es importante que el sponsor no coloque el 100% de la solvencia requerida para el proceso.</p>'
    } else if (selectedKey === 'Aplicar al visado') {
      title = 'Aplicar al visado'
      description =
        'Si desea los servicios de Immiland para este proceso, puede pedir una cotización y contratarlos en nuestra plataforma, de click en mostrar mas para ubicar el link: '
      additionalData =
        'Tome en cuenta que usted puede aplicar como estudiante y si aplica a su perfil puede incluir en su solicitud a su pareja (esposo/a o pareja de hecho) y sus hijos menores de 22 años, link a cotizacion: <a href="https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402">https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402</a>'
    } else if (selectedKey === 'Programa educativo corto vinculado') {
      title = 'Programa educativo corto vinculado'
      description =
        'Tomar un programa educativo corto vinculado con el programa a estudiar en Canadá.'
      additionalData =
        'Esto es recomendable para personas que deciden reinventarse o estudiar algo que no está vinculado directamente con lo que desea estudiar en Canadá. Es importante que el programa seleccionado emita un diploma o certificado de terminación.'
    } else if (selectedKey === 'Crear un plan de negocios') {
      title = 'Crear un plan de negocios'
      description =
        'Al decidir estudiar en Canadá una carrera que le ayude a crecer, desarrollar o mejorar su empresa actual, es de extrema importancia demostrar que ese estudio le hará aportes a su empresa y que la inversión que hará en el ámbito educativo traerá un retorno de inversión a su empresa.'
      additionalData =
        'Este plan debe ser desarrollado por una persona con experiencia en el área. '
    } else if (selectedKey === 'Parejas casadas') {
      title = 'Parejas casadas'
      description = 'Esperar 6 meses de Casados.'
      additionalData =
        'Si su matrimonio tiene menos de 6 meses, se recomienda esperar a los 6 meses de matrimonio para solicitar el visado.'
    } else if (selectedKey === 'Solicitar la residencia permanente o la ciudadanía') {
      title = 'Solicitar la residencia permanente o la ciudadanía'
      description =
        'Demostrar que se cuenta con un estatus migratorio permanente en el país donde se está hará que se cree un arraigo más fuerte al mismo y, por ende, podrá beneficiar su proceso.'
      additionalData =
        'Si al momento de solicitar el visado no cuenta con el documento que muestra su estatus permanente o ciudadanía, se debe anexar evidencia de que se está en proceso de aprobación.'
    } else if (selectedKey === 'Solicitar/renovar un pasaporte') {
      title = 'Solicitar/renovar un pasaporte'
      description =
        'Es importante tener un pasaporte vigente o prórroga al momento de solicitar el visado.'
      additionalData =
        'También se recomienda que la validez del pasaporte sea por el total del programa educativo. De lo contrario deberá solicitar una extensión de su permiso de estudios en Canadá.'
    } else if (selectedKey === 'Regresar a su país de origen') {
      title = 'Regresar a su país de origen'
      description =
        'Si solicita un permiso de estudios estando en un país en el cual no tiene estatus migratorio válido, su solicitud será rechazada de inmediato.'
      additionalData =
        'Por esta razón debe: <p>Regresar a su país de origen, establecerse y esperar al menos 12 meses.</p> <p>Viajar a otros países para demostrar que respeta las leyes migratorias.</p> <p>Evitar la repetición de una estadía en un país como indocumentado.</p> <p>Evitar, en la medida de lo posible, recibir una deportación o una orden de salida.</p> <p>Terminar sus estudios actuales</p>'
    } else if (selectedKey === 'Búsqueda y registro en la institución canadiense') {
      title = 'Búsqueda y registro en la institución canadiense'
      description =
        'Por favor, complete este formulario para que el departamento de educación entre en contacto con usted y así comience con la búsqueda de opciones y luego continúe con el registro, de click a Mostrar mas para encontrar el link al formulario.'
      additionalData =
        'Tome en cuenta que este proceso no tiene costo administrativo por parte de Immiland. Recuerde que es importante respetar el hilo lógico del desarrollo de sus estudios y su perfil profesional y que no es recomendable cambiar de área educativa. siga el siguiente link al formulario  <a href="https://immiland.app/cart/eduoption">https://immiland.app/cart/eduoption</a> '
    } else if (selectedKey === 'Trabajar en solvencia económica') {
      title = 'Trabajar en solvencia económica'
      description =
        'Solvencia requerida: Costo del primer año de estudios + CAD $20,635.00 por manutención del estudiante o CAD $25,690.00 por manutención en caso de venir con su pareja o CAD $31,583.00 por manutención en caso de venir con su pareja junto con un hijo dependiente. Para más detalles referirse a la tabla siguiente: <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/study-permits/assessing-application.html"> https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/study-permits/assessing-application.html</a>'
      additionalData =
        'Tome en cuenta que este monto puede cambiar debido al costo anual del programa que seleccione con el departamento educativo.'
    } else if (selectedKey === 'Estudiar idiomas') {
      title = 'Estudiar idiomas'
      description =
        'Tome en cuenta que para lograr ser admitido y estudiar en una institución canadiense el dominio del idioma es obligatorio. Si no tiene el nivel requerido por el college o universidad deberá hacer un pathway y esto implica que usted debe tener una mayor solvencia económica.'
      additionalData =
        'Para evaluar opciones de cursos de idiomas regulares o cursos de idiomas con Planeta Immiland Education, por favor, contacte a ana.valero@immilandcanada.com. '
    } else if (selectedKey === 'Comenzar el pathway (online)') {
      title = 'Comenzar el pathway (online)'
      description =
        'Es importante comenzar el pathway, en caso de hacerlo online, en  un período prudente de modo que le dé tiempo de terminarlo y así consiga la admisión completa y pueda solicitar el visado respectivo.'
      additionalData =
        'Para evaluar opciones de cursos de idiomas regulares o cursos de idiomas con Planeta Immiland Education, por favor, contacte a ana.valero@immilandcanada.com.'
    } else if (selectedKey === 'Terminar el pathway (online)') {
      title = 'Terminar el pathway (online)'
      description =
        'De preferencia debe ser completado 3 meses antes del inicio de clases para poder solicitar el visado de estudios.'
      additionalData =
        'Para evaluar opciones de cursos de idiomas regulares o cursos de idiomas con Planeta Immiland Education, por favor, contacte a ana.valero@immilandcanada.com.'
    } else if (selectedKey === 'Curriculum') {
      title = 'Curriculum'
      description =
        'Construir su CV, crear un perfil en LinkedIn o actualizarlo, utilizar recursos enviados a su biblioteca para saber por dónde aplicar a empleos y cómo prepararse para las entrevistas.'
      additionalData =
        'En Immiland, ofrecemos el servicio de redacción de CV. Ingrese aquí: <a href="https://www.immilandcanada.com/reclutamiento/curriculum-vitae-laboral">https://www.immilandcanada.com/reclutamiento/curriculum-vitae-laboral</a>'
    } else if (selectedKey === 'Continuar mejorando el idioma') {
      title = 'Continuar mejorando el idioma'
      description =
        'Tome en cuenta que el idioma es prioritario para conseguir trabajo en Canadá, por lo tanto, es recomendable que se enfoque en el inglés para motivos de búsqueda y entrevista laboral'
      additionalData =
        'Puede tomar nuestros cursos de idioma para lograr el objetivo deseado. Ingrese aquí: <a href="https://www.planeta-immiland-education.com/">https://www.planeta-immiland-education.com/</a>'
    } else if (selectedKey === 'Conseguir el nivel CLB 5 de francés') {
      title = 'Conseguir el nivel CLB 5 de francés'
      description =
        'Bajo la categoría Mobilité Francophone las personas que demuestren un nivel de francés CLB 5 podrán beneficiarse de un permiso de trabajo sin LMIA positivo, lo que facilitará su proceso de contratación.'
      additionalData =
        'Nuestra academia de idiomas ofrece un curso enfocado en alcanzar el CLB 5 de francés. Ingrese aquí:  <a href="https://www.planeta-immiland-education.com/store-mobilite-francophone">https://www.planeta-immiland-education.com/store-mobilite-francophone</a>'
    } else if (selectedKey === 'Practicar para la entrevista') {
      title = 'Practicar para la entrevista'
      description =
        'No olvide tener claro el día de la entrevista lo siguiente: Vocabulario de su NOC/TEER, Entender a fondo el plan migratorio que permitirá su entrada a Canadá, Llegar a la entrevista a tiempo, Estar en un lugar con buena iluminación y tranquilo, Demostrar el beneficio que usted puede aportar a la empresa.'
      additionalData =
        'Para más tips sobre una entrevista exitosa, puede ver este video:   <a href="https://www.youtube.com/watch?v=co92q5HY2r4">https://www.youtube.com/watch?v=co92q5HY2r4</a>'
    } else if (selectedKey === 'Asegurar la oferta de empleo') {
      title = 'Asegurar la oferta de empleo'
      description = 'Conseguir un empleador dispuesto a patrocinarlo por LMIA positivo.'
      additionalData =
        'Estos dos links le pueden ayudar a la búsqueda de empleadores en proceso LMIA o abiertos a internacionales: <a href="https://www.jobbank.gc.ca/jobsearch/jobsearch?fsrc=32">https://www.jobbank.gc.ca/jobsearch/jobsearch?fsrc=32</a>  <a href="https://www.jobbank.gc.ca/jobsearch/advancedsearch">https://www.jobbank.gc.ca/jobsearch/advancedsearch</a> Si necesita que Immiland negocie su oferta de empleo, puede solicitarlo aquí: <a href="https://www.immilandcanada.com/reclutamiento/negociacion-oferta-laboral">https://www.immilandcanada.com/reclutamiento/negociacion-oferta-laboral</a>'
    } else if (selectedKey === 'Oferta adecuada') {
      title = 'Oferta adecuada'
      description =
        'Asegúrese de que la oferta esté en el TEER elegible para su programa migratorio.'
      additionalData =
        'Para buscar el nivel TEER, haga click en el siguiente link: <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/find-national-occupation-code.html"> https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/find-national-occupation-code.html</a>'
    } else if (selectedKey === 'Solicitar la residencia permanente o la ciudadanía') {
      title = 'Solicitar la residencia permanente o la ciudadanía'
      description =
        'Demostrar que se cuenta con un estatus migratorio permanente en el país donde se está hará que se cree un arraigo más fuerte al mismo y, por ende, podrá beneficiar su proceso.'
      additionalData =
        'Si al momento de solicitar el visado no cuenta con el documento que muestra su estatus permanente o ciudadanía, se debe anexar evidencia de que se está en proceso de aprobación.</a>'
    }
    else if (selectedKey === 'Llegada legal a Canadá') {
      title = 'Llegada legal a Canadá'
      description =
        'Finalizacion del proceso'
      additionalData =
        'Felicidades, si llegaste a este punto entonces tu viaje a Canadá esta un paso mas cerca!</a>'
    }

    const randomId = generateRandomId() // Genera un nuevo ID
    idgger(randomId) // Asigna el nuevo ID
    setTagger('Pendiente')
    setEventTitle(title)
    setEventDescription(description)
    setDropdownOptions(dropdownOptions)
    setAdditionalData(additionalData)
  }
  const filterDropdownOptions = (selectedTag: string): string[] => {
    if (selectedTag === 'INMIGRACIÓN') {
      return [
        'Mejorar el inglés',
        'Aprender frances',
        'Comenzar el proceso de ECA, se recomienda el WES',
        'Tomar la prueba de inglés IELTS general o CELPIP',
        'Tomar la prueba de francés TEF',
        'Recaudar los fondos necesarios',
        'Solicitar comprobantes laborales',
        'Llegada legal a Canadá',
      ]
    } else if (selectedTag === 'ESTUDIOS') {
      return [
        'Búsqueda y registro en la institución canadiense',
        'Trabajar en solvencia económica',
        'Estudiar idiomas',
        'Comenzar el pathway (online)',
        'Terminar el pathway (online)',
        'Registrar unión libre',
        'Viajar',
        'Iniciar con la traducción de documentos',
        'Affidavit',
        'Aplicar al visado',
        'Tomar un programa educativo corto vinculado con el programa a estudiar en Canadá.',
        'Crear un plan de negocios',
        'Parejas casadas',
        'Solicitar la residencia permanente o la ciudadanía',
        'Solicitar/renovar un pasaporte',
        'Regresar a su país de origen',
        'Llegada legal a Canadá',
      ]
    } else if (selectedTag === 'TRABAJO') {
      return [
        'Curriculum',
        'Continuar mejorando el idioma',
        'Conseguir el nivel CLB 5 de francés',
        'Practicar para la entrevista',
        'Asegurar la oferta de empleo',
        'Oferta adecuada',
        'Solicitar la residencia permanente o la ciudadanía',
        'Llegada legal a Canadá',
      ]
    } else {
      return [] // Si no se selecciona ninguna etiqueta, no muestra opciones
    }
  }
  const menu = (
    <Menu onClick={handleDropdownSelect}>
      {filterDropdownOptions(selectedEventTag).map((option) => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  )

  useEffect(() => {
    if (isEditMode === false) {
      setSelectedDate(null)

      setEventTitle('')
      setEventDescription('')
      setAdditionalData('')
      setSelectedEventTag('')
      setEventStatus('')
    }
  }, [
    isEditMode,
    setAdditionalData,
    setEventDescription,
    setEventTitle,
    setSelectedDate,
    setEventStatus,
    setSelectedEventTag,
  ])

  return (
    <div
      style={{zIndex: '99999'}}
      className={`fixed inset-0 overflow-hidden transition-transform transform ${
        isSlideOverVisible ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
      <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
        <div className='relative w-screen max-w-md'>
          <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
            <div className='flex-1 py-6 overflow-y-auto px-4 sm:px-6'>
              <h2 style={{color:'black'}} className='text-2xl font-extrabold text-gray-900'>Crear Evento</h2>
              <div className='mb-4'>
                <div className='mb-4'>
                  <div className='mt-4 mb-4'>
                    <strong>Cuando</strong>
                    <DatePicker
  className='slide-time'
  style={{width: '100%'}}
  value={selectedDate ? moment(selectedDate) : null}
  onChange={(date) => setSelectedDate(date ? date.toDate() : null)}
  picker='month'
  format='YYYY-MM'
  disabledDate={(current) => current && current < moment().startOf('month')}
/>
                  </div>
                 {/*  <div className='mb-4'>
                    <strong>Hasta</strong>
                    <DatePicker
  className='slide-time'
  style={{width: '100%'}}
  value={selectedDate2 ? moment(selectedDate2) : null}
  onChange={(date) => setSelectedDate2(date ? date.toDate() : null)}
  picker='month'
  format='YYYY-MM'
  disabledDate={(current) => current && current < moment().startOf('month')}
/>
                  </div> */}
                  <strong>Etiqueta</strong>
                  <Select
                    className='slide-time'
                    style={{width: '100%', border: '1px solid black', backgroundColor: 'white'}}
                    onChange={(e) => {
                      console.log('Etiqueta seleccionada:', setSelectedEventTag)
                      console.log('Etiqueta seleccionada2:', setEventStatus)
                      setSelectedEventTag(e) // Configura la etiqueta seleccionada
                      setEventStatus(e)
                      setErrorMessage('')
                    }}
                    value={eventStatus}
                    options={[
                      {value: '', label: 'Seleccione'},
                      {
                        value: 'INMIGRACIÓN',
                        label: (
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              src='/media/svg/menu-icons/Group.svg'
                              alt='Icono de avión'
                              style={{
                                borderRadius: '3px',
                                background: 'var(--cyan-cyan, #01A9DB)',
                                padding: '2px 6px',
                                marginRight: '8px',
                                width: '24px', // Ajusta el tamaño del icono según sea necesario
                                height: '24px',
                              }}
                            />
                            Inmigración
                          </div>
                        ),
                      },
                      {
                        value: 'ESTUDIOS',
                        label: (
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              src='/media/svg/menu-icons/books.svg'
                              alt='Icono de libros'
                              style={{
                                borderRadius: '3px',
                                background: 'var(--fuschia-100, #EF5DA8)',
                                padding: '2px 6px',
                                marginRight: '8px',
                                width: '24px', // Ajusta el tamaño del icono según sea necesario
                                height: '24px',
                              }}
                            />
                            Educación
                          </div>
                        ),
                      },
                      {
                        value: 'TRABAJO',
                        label: (
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              src='/media/svg/menu-icons/briefcase-alt.svg'
                              alt='Icono de maletín de trabajo'
                              style={{
                                borderRadius: '3px',
                                background: 'var(--green-green, #22AD5C)',
                                padding: '2px 6px',
                                marginRight: '8px',
                                width: '24px', // Ajusta el tamaño del icono según sea necesario
                                height: '24px',
                              }}
                            />
                            Trabajo
                          </div>
                        ),
                      },
                    ]}
                  />
                  {errorMessage && <div className='error-message'>{errorMessage}</div>}
                  <div className='mt-4 flex flex-column'>
                    <strong>Seleccione su plantilla</strong>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button className='slide-time-drop'>
                        {selectedDropdownItem || 'Seleccione su plantilla'}
                      </Button>
                    </Dropdown>
                  </div>
                </div>

                <div className='mt-4'>
        <strong style={{ color: 'black' }}>Titulo</strong> <a style={{ color: 'red' }}>*</a>
        <Input
          className='slide-time'
          placeholder='Título del Evento'
          value={eventTitle}
          onChange={(e) => {
            const randomId = generateRandomId();
            idgger(randomId);
            setTagger('Pendiente');
            setEventTitle(e.target.value);
            setTitleError(''); // Limpiar el mensaje de error al editar el título
          }}
        />
        {titleError && <div style={{ color: 'red' }}>{titleError}</div>}
      </div>
      <div className='mt-4'>
        <strong style={{ color: 'black' }}>Descripción</strong> <a style={{ color: 'red' }}>*</a>
        <Input.TextArea
          className='slide-time'
          placeholder='Descripción del Evento'
          value={eventDescription}
          onChange={(e) => {
            setEventDescription(e.target.value);
            setDescriptionError(''); // Limpiar el mensaje de error al editar la descripción
          }}
        />
        {descriptionError && <div style={{ color: 'red' }}>{descriptionError}</div>}
      </div>

                <div className='mt-4'>
                  <strong>Aditional Data</strong>
                  <Input.TextArea
                    className='slide-time'
                    placeholder='Datos adicionales'
                    value={additionalData}
                    onChange={(e) => setAdditionalData(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='flex-shrink-0 px-4 py-4 flex justify-between gap-4 '>
              <Button
                className='slide-time-button-back'
                onClick={() => {
                  setIsEditMode(false)
                  setIsSlideOverVisible(false)
                }}
              >
                Cerrar
              </Button>

              <Button
  className='slide-time-button'
  onClick={() => {
    // Validar campos obligatorios
    if (!eventStatus && !selectedEventTag) {
      setErrorMessage('Debe seleccionar una etiqueta');
      return; // No continuar si no se ha seleccionado una etiqueta
    }

    // Validar campos de título y descripción
    if (!eventTitle.trim()) {
      setTitleError('Campo obligatorio');
      return;
    }

    if (!eventDescription.trim()) {
      setDescriptionError('Campo obligatorio');
      return;
    }

    // Si pasa todas las validaciones, proceder con la lógica del botón
    if (isEditMode) {
      // Lógica para actualizar el evento
      // Puedes llamar a handleCreateEvent con los datos actualizados
      handleCreateEvent(); // Asegúrate de pasar los datos correctos
      setIsSlideOverVisible(false);
    } else {
      // Lógica para crear un nuevo evento
      setIsEditMode(false);
      handleCreateEvent();
    }

    setIsSlideOverVisible(false);
  }}
>
  {isEditMode ? 'Actualizar Evento' : 'Guardar Evento'}
</Button>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimelineSlide
