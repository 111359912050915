/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useEffect, useState} from 'react'

interface IProps {
  link: string
}

const EmbedSupport: React.FC<IProps> = ({link}) => {
  const [width, setWidth] = useState(window.innerWidth >= 1200 ? 1200 : window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight - 20)
  const updateDimensions = () => {
    let width = window.innerWidth
    const height = window.innerHeight - 400
    if (width >= 960) {
      width = 960
    }
    setWidth(width)
    setHeight(height)
  }
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', link)
    head?.appendChild(script)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <iframe title='title' src={link} width='100%' height='1600' style={{border: '0'}} />
    </>
  )
}

export default EmbedSupport
