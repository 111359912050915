import {type ID, type Response} from '../../../../../core/helpers'
import {type UserModel} from '../../../../../auth/models/User/UserModel'

export interface Staff {
  id?: string
  email?: string | null
  avatar?: string | null
  name?: string | null
  type?: string | null
  photoId?: string | null
  photoPath?: string | null
  user?: UserModel
}
export interface Staff2 {
  id: string
  calendly?: string
  created_at?: string
  deleted_at?: string
  jotform_name?: string
  netsuit_id?: string
  offline?: string
  order?: UserModel
  status: 1
  type: string
  updated_at: string
  user_id: string
  substitute: string
  autorId: string
}

export interface StaffImmigration {
  id?: ID
  staff?: object | null
}

export type StaffQueryResponse = Response<Staff[]>
export type Staff2QueryResponse = Response<Staff2[]>
type toFormValuesType = (apiData: Staff) => Staff

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  email: apiData.email || '',
  name: apiData.name || '',
  avatar: apiData.avatar || '',
  type: apiData.type || '',
  photoId: apiData.photoId || '',
  photoPath: apiData.photoPath || '',
})

export const staffDetailsInitValues: Staff = {
  avatar: '/media/avatars/300-1.jpg',
  name: '',
  email: '',
}

export const initialStaffInfo: Staff = {
  id: '2f55f0da-83ef-4bc5-8aac-783b2b80834e',
  email: '',
  avatar: '',
  name: '',
  type: '',
  photoId: '',
  photoPath: '',
  user: {
    id: '6bd3ae75-a40b-44dd-9aed-e483766cce1e',
    email: '',
  },
}
