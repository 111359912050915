import './Style/custom.css'
import {useContext, useEffect, useState} from 'react'
import Account from './Steps/Account'
import PaymentForm from './Steps/Payment'
import {RootStoreContext} from '../../../../stores/rootStore'
import ShoppingCart from './ShoppingCart'
import {Steps} from 'antd'
import {observer} from 'mobx-react'
import Summary from './summary'

const {Step} = Steps

const IntakePayWrapper = () => {
  const rootStore = useContext(RootStoreContext)
  const {isAuthenticated} = rootStore.authStore

  const [current, setCurrent] = useState(0)
  const [method, setMethod] = useState<string>('')
  const [paymentMethodValue, setPaymentMethodValue] = useState<string>('');
  useEffect(() => {
    if (isAuthenticated && current === 1) {
      setCurrent(2)
    }
  }, [isAuthenticated, current])

  const getContent = () => {
    switch (current) {
      case 0:
        return <ShoppingCart setCurrent={setCurrent} />
      case 1:
        return <Account />
      case 2:
        return  <PaymentForm setMethod={setMethod} paymentMethodValue={paymentMethodValue} />
      default:
        return <Account />
    }
  }
  const handlePaymentMethodChange = (value: string) => {
    setPaymentMethodValue(value);
  };
  return (
    
    <>{current !== 0 && (
      <>
        <div className='circle-container'>
          <div className='circle black-bg'>1</div>
          <p className='hidden sm:block' style={{ color: 'black', marginBottom: '1px', marginRight: '6rem', textTransform: 'uppercase' }}>Subir archivo</p>

          <div className='circle '>2</div>
          <p className='hidden sm:block' style={{ color: 'black', marginBottom: '1px', marginRight: '10px', textTransform: 'uppercase' }}>Confirmar solicitud</p>
        </div>

        <div className='container-line'>
          <div>
            <div className='bubble' />
            <div className='bar' />
          </div>
          <div>
            <div className='bubble' />
            <div className='bar' />
          </div>
          <div className='bubble2' />
        </div>
      </>
    )}


      <div style={{width:'100%'}} className='text-center'>
       
     
        {/*      <Steps
           current={current}
           size='small'
           style={{
             color: 'yellow',
           }}
         >
           <Step title={'Detalles de la orden'} />
           <Step title={'Cuenta'} />
           <Step title={'Confirmar orden'} />
         </Steps> */}

        <div style={{ display: 'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}} className='steps-content'>
          {current !== 0 ? (
            <div className='sumary-container' >
              <Summary method={method} onPaymentMethodChange={handlePaymentMethodChange} />
            </div>
          ) : (
            <></>
          )}
          <div>{getContent()}</div>
        </div>

        <div className='steps-action'></div>
      </div></>
  )
}

export default observer(IntakePayWrapper)
