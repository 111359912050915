import {Container} from '../../../../components/Container/index'
import FormCreate from './FormCreate'
import {useIntl} from 'react-intl'

const CreateAppointment = () => {
  const intl = useIntl()
  return (
    <Container title={intl.formatMessage({id: 'MENU.APICALLS'})}>
      <FormCreate />
    </Container>
  )
}

export default CreateAppointment
