import tw from 'tailwind-styled-components'

interface Props {
  width?: string
  read?: boolean
}

const Item = tw.a<Props>`

`
const ItemInbox = tw.div<Props>`


  

`

export {Item, ItemInbox}
