/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState} from 'react'
import axios from 'axios'
import {Program} from 'app/modules/apps/cart/core/program/_models'
import {getProgram} from 'app/modules/apps/cart/core/program/_request'

import {EditOutlined, FilterOutlined, SearchOutlined} from '@ant-design/icons'
import {useQuery} from 'react-query'
import {DeleteOutlined} from '@ant-design/icons'
import CreateProgramForm from './CreateProgramForm'
import EditProgramForm from './EditProgramForm'
import {Modal, Select} from 'antd'

const API_URL = process.env.REACT_APP_API_URL
const PROGRAM_URL = `${API_URL}/program`

const ProgramTable = () => {
  /*   const [institutions, setInstitutions] = useState<Institution[]>([]); */
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [editProgram, setEditProgram] = useState<Program | null>(null)
  const [showEditForm, setShowEditForm] = useState(false)
  const {data: programs, refetch} = useQuery('GetProgram', async () => await getProgram())
  const [showFilterModalName, setShowFilterModalName] = useState(false)
  const [nameFilter, setNameFilter] = useState('')
  const [showFilterModalInstitution, setShowFilterModalInstitution] = useState(false)
  const [institutionFilter, setInstitutionFilter] = useState('')
  const [showFilterModalLocation, setShowFilterModalLocation] = useState(false)
  const [locationFilter, setLocationFilter] = useState('')
  const [showFilterModalDiscipline, setShowFilterModalDiscipline] = useState(false)
  const [disciplineFilter, setDisciplineFilter] = useState('')
  const [showFilterModalType, setShowFilterModalType] = useState(false)
  const [typeFilter, setTypeFilter] = useState<string[]>([])
  const [showFilterModalLevel, setShowFilterModalLevel] = useState(false)
  const [LevelFilter, setLevelFilter] = useState<string[]>([])
  const [showFilterModalIntake, setShowFilterModalIntake] = useState(false)
  const [IntakeFilter, setIntakeFilter] = useState<string[]>([])
  const [showFilterModalDuration, setShowFilterModalDuration] = useState(false)
  const [durationFilter, setDurationFilter] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 5
  const startIndex = (currentPage - 1) * recordsPerPage
  const endIndex = startIndex + recordsPerPage
  const filteredPrograms = programs?.filter(
    (program) =>
      program.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
      program.institution?.name.toLowerCase().includes(institutionFilter.toLowerCase()) &&
      program.location.toLowerCase().includes(locationFilter.toLowerCase()) &&
      program.discipline.name.toLowerCase().includes(disciplineFilter.toLowerCase()) &&
      (typeFilter.length === 0 || typeFilter.includes(program.type.name)) &&
      (LevelFilter.length === 0 || LevelFilter.includes(program.level.name)) &&
      (durationFilter.length === 0 || durationFilter.includes(program.duration)) &&
      (IntakeFilter.length === 0 ||
        program.intakes.some((intake) =>
          IntakeFilter.some((selectedIntake) =>
            intake.name.toLowerCase().includes(selectedIntake.toLowerCase())
          )
        ))
  )
  const totalPagesFiltered = Math.ceil((filteredPrograms?.length || 0) / recordsPerPage)
  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm)
  }
  const handleProgramCreated = () => {
    /*  fetchInstitutions();  */
    refetch()
    setShowTable(true)
    setShowCreateForm(false)
  }

  const handleEditClick = (program: Program) => {
    setEditProgram(program)
    setShowEditForm(true)
    setShowTable(false)
  }

  const handleEditSave = () => {
    setShowEditForm(false)
    setShowTable(true)
    refetch()
    /*  fetchInstitutions(); */ // Actualizar la lista de instituciones después de editar
  }
  const uniqueTypeValues = Array.from(new Set(programs?.map((program) => program.type.name)))
  const uniqueLevelValues = Array.from(new Set(programs?.map((program) => program.level.name)))
  const uniqueDurationValues = Array.from(new Set(programs?.map((program) => program.duration)))
  const uniqueIntakeValues = Array.from(
    new Set(programs?.flatMap((program) => program.intakes.map((intake) => intake.name)))
  )

  const handleDeleteClick = async (program: Program) => {
    try {
      await axios.delete(`${PROGRAM_URL}/removeProgram/${program.programId}`)
      refetch()
    } catch (error) {
      console.error('Error deleting Program:', error)
    }
  }
  return (
    <div>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '2rem',
        }}
        className='text-2xl font-semibold mb-4'
      >
        Programs Table
      </h2>
      <button
        style={{marginBottom: '2rem', marginLeft: '2rem'}}
        className='submit-button2'
        onClick={() => {
          toggleCreateForm()

          setShowTable(false)
        }}
      >
        Crear Programa
      </button>
      {showCreateForm && (
        <CreateProgramForm
          showTable={showTable}
          setShowTable={setShowTable}
          onProgramCreated={handleProgramCreated}
        />
      )}
      {showTable && (
        <div className='overflow-x-auto'>
          <table className='mt-4 min-w-full bg-white border rounded-md shadow-sm'>
            <thead className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'>
              <tr>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Name</span>
                    <button className='filter-button' onClick={() => setShowFilterModalName(true)}>
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Name'
                    visible={showFilterModalName}
                    onCancel={() => setShowFilterModalName(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by name'
                      value={nameFilter}
                      onChange={(e) => setNameFilter(e.target.value)}
                      className='filter-input-single'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Institution</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalInstitution(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Institution'
                    visible={showFilterModalInstitution}
                    onCancel={() => setShowFilterModalInstitution(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by institution'
                      value={institutionFilter}
                      onChange={(e) => setInstitutionFilter(e.target.value)}
                      className='filter-input-single'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Location</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalLocation(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Location'
                    visible={showFilterModalLocation}
                    onCancel={() => setShowFilterModalLocation(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by location'
                      value={locationFilter}
                      onChange={(e) => setLocationFilter(e.target.value)}
                      className='filter-input-single'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Type</span>
                    <button className='filter-button' onClick={() => setShowFilterModalType(true)}>
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Type'
                    visible={showFilterModalType}
                    onCancel={() => setShowFilterModalType(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Filter by type'
                      value={typeFilter}
                      onChange={(selectedValues) => setTypeFilter(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueTypeValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Level</span>
                    <button className='filter-button' onClick={() => setShowFilterModalLevel(true)}>
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Level'
                    visible={showFilterModalLevel}
                    onCancel={() => setShowFilterModalLevel(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Filter by level'
                      value={LevelFilter}
                      onChange={(selectedValues) => setLevelFilter(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueLevelValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Discipline</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalDiscipline(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Discipline'
                    visible={showFilterModalDiscipline}
                    onCancel={() => setShowFilterModalDiscipline(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by discipline'
                      value={disciplineFilter}
                      onChange={(e) => setDisciplineFilter(e.target.value)}
                      className='filter-input-single'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Intake</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalIntake(true)}
                    >
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Intake'
                    visible={showFilterModalIntake}
                    onCancel={() => setShowFilterModalIntake(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Filter by intake'
                      value={IntakeFilter}
                      onChange={(selectedValues) => setIntakeFilter(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueIntakeValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Duration</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalDuration(true)}
                    >
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Duration'
                    visible={showFilterModalDuration}
                    onCancel={() => setShowFilterModalDuration(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Filter by duration'
                      value={durationFilter}
                      onChange={(selectedValues) => setDurationFilter(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueDurationValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>

                <th className='px-4 py-2'>Actions</th>
                <th className='px-4 py-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {programs !== undefined && programs.length > 0 && (
                <>
                  {programs
                    .filter(
                      (program) =>
                        program.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
                        program.institution?.name
                          .toLowerCase()
                          .includes(institutionFilter.toLowerCase()) &&
                        program.location.toLowerCase().includes(locationFilter.toLowerCase()) &&
                        program.discipline.name
                          .toLowerCase()
                          .includes(disciplineFilter.toLowerCase()) &&
                        (typeFilter.length === 0 || typeFilter.includes(program.type.name)) &&
                        (LevelFilter.length === 0 || LevelFilter.includes(program.level.name)) &&
                        (durationFilter.length === 0 ||
                          durationFilter.includes(program.duration)) &&
                        (IntakeFilter.length === 0 ||
                          program.intakes.some((intake) =>
                            IntakeFilter.some((selectedIntake) =>
                              intake.name.toLowerCase().includes(selectedIntake.toLowerCase())
                            )
                          ))
                    )
                    .slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
                    .map((program: Program) => (
                      <tr key={program.programId}>
                        <td className='px-4 py-2'>{program.name}</td>
                        <td className='px-4 py-2'>{program.institution?.name}</td>
                        <td className='px-4 py-2'>{program.location}</td>
                        <td className='px-4 py-2'>{program.type.name}</td>
                        <td className='px-4 py-2'>{program.level?.name}</td>
                        <td className='px-4 py-2'>{program.discipline.name}</td>
                        <td className='px-4 py-2'>
                          {program.intakes.map((intake, index) => (
                            // Render each 'intake' element here
                            <span key={index}>{intake.name}</span>
                          ))}
                        </td>
                        <td className='px-4 py-2'>{program.duration}</td>
                        <td className='px-4 py-2'>
                          <button className='edit-button' onClick={() => handleEditClick(program)}>
                            <EditOutlined />
                          </button>
                        </td>
                        <td className='px-4 py-2'>
                          <button
                            className='delete-button'
                            onClick={() => handleDeleteClick(program)}
                          >
                            <DeleteOutlined />
                          </button>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
          <div className='pagination'>
            <button
              className='pagination-button'
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              {currentPage}/{totalPagesFiltered}
            </span>
            <button
              className='pagination-button'
              disabled={currentPage === totalPagesFiltered}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {showEditForm && editProgram !== null && (
        <EditProgramForm editProgram={editProgram} onSave={handleEditSave} />
      )}
    </div>
  )
}

export default ProgramTable
