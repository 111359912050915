/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, PageHeader } from 'antd'
import { MinusCircleOutlined, PlusOutlined, SnippetsOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import AddForms from './AddForms'
import {
  type DocumentsProduct,
  type FormsProduct,
} from '../../../../../app/modules/apps/forms/formproducts/core/_models'
import List from './List'
import { type Product } from '../../../../../app/modules/apps/cart/core/products/_models'
import { useIntl } from 'react-intl'
import DocumentsList from '../ProductDocuments/List'
import AddDocuments from '../ProductDocuments/AddDocument'

interface IProps {
  product: Product
}

const ProductsForms: React.FC<IProps> = ({ product }) => {
  const [list, setList] = useState(true)
  const [document, setDocument] = useState(true)
  const [addForm, setAddForm] = useState(false)
  const [addDoc, setAddDoc] = useState(false)
  const [formsproducts, setFormsProducts] = useState<FormsProduct[]>([])
  const [documentsproducts, setDocumentsProducts] = useState<DocumentsProduct[]>([])
  const [icon, setIcon] = useState(<PlusOutlined />)
  const [icon2, setIcon2] = useState(<PlusOutlined />)
  const intl = useIntl()

  useEffect(() => {
    if (list) {
      setIcon(<PlusOutlined style={{ color: 'white' }} />)
    } else {
      setIcon(<MinusCircleOutlined style={{ color: 'white' }} />)
    }
  }, [list])

  useEffect(() => {
    if (document) {
      setIcon2(<PlusOutlined style={{ color: 'white' }} />)
    } else {
      setIcon2(<MinusCircleOutlined style={{ color: 'white' }} />)
    }
  }, [document])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <PageHeader
        className='site-page-header-responsive'
        subTitle=' '
        extra={[
          <button
            key='adddocument'
            type='button'
            style={{
              boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
              border: '1px solid white',
              backgroundColor: 'white',
              display: 'flex',
              marginTop: '20px',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
              width: '14rem',
            }} className='button-ui button-tertiary button-s '
            onClick={() => {
              setList(!list)
              setAddForm(!addForm)
            }}
          >
            <img src="/media/svg/menu-icons/template-add.svg" alt="PDF Icon" style={{ width: '2rem', height: '2rem', marginRight: '0.5rem' }} />
            <b
              style={{ cursor: 'pointer', color: 'black' }}
              onClick={() => {
                setList(!list)
                setAddForm(!addForm)
              }}
            >
              Enlazar formularios
            </b>
          </button>
        ]}>

        {list && (
          <>

            <b style={{ paddingBottom: '1rem' }}>{intl.formatMessage({ id: 'PRODUCT.ADD.FORMS' })} </b>
            <List product={product} setFormsProducts={setFormsProducts} />
          </>
        )}
        {addForm && (
          <>
            <b style={{ marginBottom: '1rem' }}>Forularios Disponibles</b>
            <AddForms product={product} formsproducts={formsproducts} />
          </>
        )}
      </PageHeader>
      <PageHeader
        className='site-page-header-responsive'
        title={
          <button
            key='adddocument'
            type='button'
            // style={{
            //       boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
            //       border: '1px solid white',
            //       backgroundColor: 'white',
            //       display: 'flex',
            //       marginTop: '20px',
            //       gap: '5px',
            //       alignItems: 'center',
            //       justifyContent: 'center',
            //       width: '14rem',
            //     }}
            className='button-ui button-secondary button-s'
            onClick={() => {
              setDocument(!document);
              setAddDoc(!addDoc);
            }}
          >
            <img src="/media/svg/menu-icons/Pdf-1.svg" alt="PDF Icon" style={{ width: '2rem', height: '2rem', marginRight: '0.5rem' }} />
            <b
              style={{ cursor: 'pointer', color: 'black' }}
              onClick={() => {
                setDocument(!document);
                setAddDoc(!addDoc);
              }}
            >
              Enlazar Documentos
            </b>
          </button>
        }
        subTitle=' '
      >
        {document && (
          <>
            <b style={{ paddingBottom: '1rem' }}>Documentos vinculados</b>
            <DocumentsList product={product} setDocumentsProducts={setDocumentsProducts} />
          </>
        )}
        {addDoc && (
          <>
            <b style={{ marginBottom: '1rem' }}>Documentos Disponibles</b>
            <AddDocuments product={product} documentproducts={documentsproducts} />
          </>
        )}
      </PageHeader>

    </div>
  )
}

export default ProductsForms
