/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react'
import { type IReply } from '../../../../app/modules/apps/appointments/core/_models'
import MessageRows from './MessageRows'
import ModalLoader from '../../../../core/components/ModalLoader'
import { RootStoreContext } from '../../../../stores/rootStore'
import { observer } from 'mobx-react'
import { Table } from 'antd'
import Column from 'antd/lib/table/Column'
import './styles/TableStyle.css'

interface IProps {
  callRefetch: boolean
  setMessageId?: React.Dispatch<React.SetStateAction<string>>
  setComposepostdata?: React.Dispatch<React.SetStateAction<IReply>>
  setlist: React.Dispatch<React.SetStateAction<boolean>>
  setShowMessage?: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage?: React.Dispatch<React.SetStateAction<boolean>>
  handleClose?: () => void
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
  folder: string
  unread: React.Dispatch<React.SetStateAction<number>>
  isUnread: boolean
  isFavorite: boolean
}

const ListMessages: React.FC<IProps> = ({
  setMessageId,
  setShowMessage,
  setReplyMessage,
  setComposepostdata,
  setlist,
  folder,
  handleClose,
  setShowMessageRows,
  unread,
  isUnread,
  isFavorite
}) => {
  const rootStore = useContext(RootStoreContext)
  const { messagesNew, setMessagesRead, isLoadingMessages, loadMessagesNew, newFetchSearchWord, setPage } = rootStore.messageStore
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    console.log("pasr por aqui", currentPage)
    loadMessagesNew(folder, currentPage)
  }, [currentPage])

  useEffect(() => {
  }, [messagesNew])
  const HandleReadmessage = (id: string) => {
    setMessagesRead(id);
    if (setMessageId != null) setMessageId(id);
    if (setShowMessage != null) setShowMessage(true);
    

    setTimeout(() => setlist(false), 0);
  };
  
  useEffect(() => {
    if (folder === 'inbox' && messagesNew && messagesNew.data) {
      let unreadMessages = 0;
      messagesNew.data.forEach((m: any) => {
        if (!m.unread && !m.draft) {
          unreadMessages++;
        }
      });
      unread(unreadMessages); 
    }
  }, [folder, messagesNew, unread]);


  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1)
      setPage(1)
    }
  }, [folder, newFetchSearchWord])


  const onChange = (pagination: any) => {
    if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
      setPage(pagination.current);
    }
  };
  

  return (
    <>
  
      {!messagesNew.data ? <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /> : 
      
      <>
        {(messagesNew && messagesNew.data && messagesNew.data.length===0) ? <>No tiene mensajes</> :
        <Table
        pagination={{
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          total: messagesNew.total,
          current: currentPage
        }}
        dataSource={messagesNew.data}
        rowKey='id'
        style={{ width: '100%', backgroundColor: '#F9FAFB' }}
        onChange={onChange}>
       {isUnread === true &&
  <Column
    title=''
    render={(text, record: any, index: any) => (
      <>
        {record.unread === false &&
          <div style={{ width: '100%', marginBottom: '8px', backgroundColor: '#F9FAFB' }}>
            <MessageRows
              key={index}
              message={record}
              folder={folder}
              HandleReadmessage={HandleReadmessage}
              handleClose={handleClose}
              setShowMessageRows={setShowMessageRows}
              setComposePostData={setComposepostdata as any}
            />
          </div>}
      </>
    )}
  />}


        {isFavorite === true &&
          <Column
            title=''
            render={(text, record: any, index: any) => (
              <>
                {record.favorite === true &&
                  <div style={{ width: '100%', marginBottom: '8px', backgroundColor: '#F9FAFB' }}>
                    <MessageRows
                      key={index}
                      message={record}
                      folder={folder}
                      HandleReadmessage={HandleReadmessage}
                      handleClose={handleClose}
                      setShowMessageRows={setShowMessageRows}
                      setComposePostData={setComposepostdata as any}
                    />
                  </div>}
              </>
            )}
          />}
        {isFavorite === false && isUnread === false &&
          <Column
            title=''
            render={(text, record: any, index: any) => (
              <div style={{ width: '100%', marginBottom: '8px', backgroundColor: '#F9FAFB' }}>
                <MessageRows
                  key={index}
                  message={record}
                  folder={folder}
                  HandleReadmessage={HandleReadmessage}
                  handleClose={handleClose}
                  setShowMessageRows={setShowMessageRows}
                  setComposePostData={setComposepostdata as any}
                />
              </div>
            )}
          />}
      </Table>
        }
        
      </>}
     
    </>
  )
}

export default observer(ListMessages)
