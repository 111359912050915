/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Switch } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  createMessages,
  getMessagesIdUsers,
  replyMessages,
  updateMessages,
} from '../../../app/modules/apps/messages/core/_requests'
import { AutoShares } from './AutoShares'
import ComposeTemplate from './ComposeTemplate'
import { FileSelector } from './FileSelector'
import { IReply, type IComposeData } from '../../../app/modules/apps/appointments/core/_models'
import Input from 'antd/lib/input/Input'
import JoditEditor from 'jodit-react'
import { RootStoreContext } from '../../../stores/rootStore'
import { SearchTemplate } from '../../../app/pages/templateManager/SearchTemplate'
import Swal from 'sweetalert2'
import ToComponent from './ToComponent'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import '../../../app/pages/messages/UserMessages/styles/swalStyle.css'
import './ComposeTemplate/style.css'
import { downloadFileLibrary, postLibraryFiles } from 'app/modules/apps/library/core/_requests'
import { useQuery } from 'react-query'
import { getTemplateSendingBluee, sendEmailBlueTemplate } from 'app/modules/apps/users/core/_requests'
import { sendingBlueTemplates } from 'app/modules/apps/sendinBlueTemplates/_models'
import { IToUsers } from './interfaces/ITOUsers'
import { uploadfilesmessagenewversion } from 'app/modules/apps/file/core/_requests'
import PDFIcon from '../contratationApp/icons/PDF.svg'
import iconorechazo from '../contratationApp/icons/iconorechazo.svg'
import Arrowleft from '../contratationApp/icons/arrow-left.svg'
import PdfViewerAndres from 'core/components/PdfViewer/pdfViewerAndres'
import load from '../../../../src/app/pages/contratationApp/icons/load.svg'
interface Props {
  contractId: string
  emailStaff: string
  data?: string
  composepostdata: IComposeData
  cc: IToUsers[]
  showSelectFiles: boolean
  showShareFiles: boolean
  type: string
  showTemplateSearch: boolean
  initialContent: string
  initialSubject: string
  handleClose: () => void
  showButtonClose?: boolean
  initialfolder?: string
  showComposeTemplate?: boolean
  category: string
  tabNumber?: string
  setCreateMessage: React.Dispatch<React.SetStateAction<boolean>>
  setShowMessage: React.Dispatch<React.SetStateAction<boolean>>
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage: React.Dispatch<React.SetStateAction<boolean>>
  setComposeData: React.Dispatch<React.SetStateAction<IReply>>
}

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "align",
  "|",
  "ol",
  "ul",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|"
]

const config = {
  minHeight: 300,
  size: 'large',
  readonly: false,
  spellcheck: true,
  buttons: buttons,
  indentMargin: 30,
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  tabIndex: -1,
  language: 'es',
  debugLanguage: false,
}

const NewMessage: React.FC<Props> = ({
  contractId,
  emailStaff,
  composepostdata,
  showSelectFiles,
  showShareFiles,
  type,
  showTemplateSearch,
  initialContent,
  initialSubject,
  handleClose,
  cc,
  showButtonClose = false,
  initialfolder,
  showComposeTemplate = false,
  category,
  tabNumber,
  setShowMessage,
  setShowMessageRows,
  setReplyMessage,
  setCreateMessage,
  setComposeData,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const { currentUser } = rootStore.usersStore
  const { sendAllDataUser } = rootStore.composeTemplateStore
  const { loadMessagesCurrentMemberMessages, loadCounterMessagesClient } = rootStore.messageStore
  const [messageId, setMessageId] = useState<string>('')
  const intl = useIntl()
  const editor = useRef(null)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [files, setTargetFilesParent] = useState<string[]>([])
  const [shared, setShared] = useState<string[]>([])
  const [filesArray, setFilesArray] = useState<any>([])
  const [content, setContent] = useState('')
  const [subject, setSubject] = useState(showTemplateSearch ? '' : initialSubject)
  const [folder, setFolder] = useState(initialfolder || 'inbox')
  const [newContent, setNewContent] = useState('')
  const [to, setTO] = useState<IToUsers[]>([])
  const [target, setTarget] = useState<any>([])
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [postConSwitch, setPostConSwitch] = useState<boolean>(false)
  const [errorFileMessage, setErrorFileMesasge] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFile, setLoadingFile] = useState<boolean>(false)
  const successMessage = '¡Fue enviado con éxito!'
  const notificationMessage = `Hemos notificado a los destinatarios  de tu mensaje.`

  const { data: sendingBlueData } = useQuery(
    'getAllSendingBlueTemplatess',
    async () => await getTemplateSendingBluee()
  )

  const { refetch: userRefetch } = useQuery(
    'listnotesbyuser',
    async () => await getMessagesIdUsers(currentUser !== null ? currentUser?.id as string : '')
  )

  const [arrayTemplates, setArrayTemplates] = useState<sendingBlueTemplates[]>([])
  const verDoc = (file: any) => {
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL, '_blank')
  }

  const handleViewer = async (item: any) => {
    setLoadingFile(true)
    const file = await downloadFileLibrary(item.key, () => {
      // comment to deactivate eslint
    })
    setFile(file)
    setFileName('documento.pdf')
    setLoadingFile(false)
  }

  const deleteTarget = async (item: any) => {
    const nuevoArray = target.filter((elemento: any) => elemento !== item)
    setTarget(nuevoArray)
  }

  const deleteFileArray = async (item: any) => {
    const nuevoArray = filesArray.filter((elemento: any) => elemento !== item)
    setFilesArray(nuevoArray)
  }
  useEffect(() => { }, [to])
  useEffect(() => { }, [])
  useEffect(() => { }, [isSubmiting])
  useEffect(() => { }, [content, files])
  useEffect(() => { }, [shared])
  useEffect(() => { }, [folder])
  useEffect(() => {
    if (category === 'postconsulta') {
      if (arrayTemplates?.length === 0 && sendingBlueData !== undefined) {
        setArrayTemplates(sendingBlueData?.filter((item: any) => item.tag === 'Post Consulta'))
      }
    }
  }, [sendingBlueData])

  const handleSubmitDraft = () => {
    setLoading(true)
    if ((JSON.stringify(to) === JSON.stringify([{ "id": "" }])) || !to) {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'Debe seleccionar un destinatario',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    console.log("hola", to)

    /* return */
    userRefetch()
    composepostdata.folder = 'draft'
    setFolder('draft')
    composepostdata.draft = true
    /* setTO([]) */
    cc = []
    handleSubmit()
  }

  const handleSubmit = () => {
    setLoading(true)
    /* setNewContent('') */
    if (composepostdata.folder === 'draft') {
      setFolder('draft')
    }
    setIsSubmiting(true)
    if (!validateType()) {
      setIsSubmiting(false)
      return false
    }
    if ((JSON.stringify(to) === JSON.stringify([{ "id": "" }])) || !to) {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'Debe seleccionar un destinatario',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    if (subject.trim() === '') {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'No ha escrito ningún Asunto',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    if (newContent.trim() === '') {
      setLoading(false)
      Swal.fire({
        title: intl.formatMessage({
          id: 'CORE.REQUIRED',
        }),
        text: 'No ha escrito ningún mensaje',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      })
      setIsSubmiting(false)
      return
    }
    if (files.length === 0 && showSelectFiles) {
      setLoading(false)
      Swal.fire({
        title: 'Sin arvhivos adjuntos?',
        text: 'No ha adjuntado ningun archivo desea seguir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviarlo!',
      }).then((result: any) => {
        if (!result.isConfirmed) {
          setIsSubmiting(false)
        } else {
          sendMessage()
        }
      })
    } else {
      sendMessage()
    }
  }

  useEffect(() => {
  }, [newContent])

  const sendMessage = async () => {
    const messageData: any = {
      content: newContent,
      email: composepostdata.email,
      to: JSON.stringify(to) === JSON.stringify([{ "id": "" }]) ? [] : to,
      cc,
      user: composepostdata.userId || null,
      files: folder === 'draft' ? [] : files,
      type,
      subject,
      shared,
      messageId: composepostdata.messageId || null,
      id: undefined,
      fromId: composepostdata.fromId,
      fromUser: composepostdata.fromUser,
      userId: composepostdata.userId || null,
      fromEmail: '',
      orderDetailId: composepostdata.orderDetailId,
      folder: composepostdata.folder,
      draft: composepostdata.draft,
      emailStaff: emailStaff,
    }
    let result
    let resultFilesLibrary
    let resultSendingBlue
    const arrayOfId: any[] = []
    for (const elemento of filesArray) {
      if (!elemento.originalname) {
        const file: any = await uploadfilesmessagenewversion(elemento, () => { })
        arrayOfId.push({ id: file.id })
      } else {
        arrayOfId.push({ id: elemento.id })
      }
    }
    if (target && target.length && target.length > 0) {
      for (const elemento of target) {
        arrayOfId.push({ id: elemento.key })
      }
    }
    if (arrayOfId && arrayOfId.length && arrayOfId.length > 0) {
      messageData.files = arrayOfId
    }
    if (postConSwitch === true && folder !== 'draft') {
      messageData.sendPostConsultEmail = true
    } else {
      messageData.sendPostConsultEmail = false
    }
    if (messageId !== '') result = await updateMessages(messageData, messageId)
    else {
      if (type === 'reply') result = await replyMessages(messageData)
      else {
        result = await createMessages(messageData)
        if (messageData.files.length > 0) {
          resultFilesLibrary = await postLibraryFiles(messageData.userId, messageData.files)
        }
        if (arrayTemplates && arrayTemplates.length > 0 && emailStaff === '') {
          resultSendingBlue = await sendEmailBlueTemplate([messageData.email], '25')
        }
      }
      if (currentUser && currentUser.id) {
        loadMessagesCurrentMemberMessages(currentUser?.id)
        loadCounterMessagesClient(currentUser?.id)
      }
    }
    if (result != null) {
      setLoading(false)
      setMessageId(result.id as string)
      sendAllDataUser(composepostdata.userId)
      setNewContent('')
      setSubject('')
      setTargetFilesParent([])
      setShared([])
      setTO([])
      if (composepostdata.draft === true) {
        Swal.fire({
          titleText: '¡Muy bien!',
          html: `
            <div class="custom-container">
              <div class="custom-text">Su borrador fue guardado con exito</div>
            </div>
          `,
          customClass: {
            icon: 'custom-icon-class',
            title: 'custom-title-class',
            confirmButton: 'custom-confirm-button-class'
          },
          confirmButtonText: 'Cerrar',
          imageUrl: '/media/svg/menu-icons/thumbs-up.svg',
          imageWidth: 64,
          imageHeight: 64
        }).then((result) => {
          if (result.isConfirmed) {
            userRefetch()
            setComposeData({
              orderDetailId: '',
              user: '',
              email: '',
              userId: '',
              fromId: '',
              subject: '',
              messageId: '',
              content: '',
              to: '',
              cc: '',
              fromUser: '',
              type: '',
              attachment: '',
              folder: 'inbox',
              draft: false,
              favorite: false,
              files_info: [],
              unread: false,
            })
            setShowMessage(false)
            setShowMessageRows(true)
            setReplyMessage(false)
            setCreateMessage(false)
          }
        })
      } else {
        setLoading(false)
        Swal.fire({
          titleText: '¡Muy bien!',
          html: `
          <div class="custom-container">
            <div class="custom-text">${successMessage}</div>
            <div class="custom-footer">${notificationMessage}</div>
          </div>
        `,
          customClass: {
            icon: 'custom-icon-class',
            title: 'custom-title-class',
            confirmButton: 'custom-confirm-button-class'
          },
          confirmButtonText: 'Cerrar',
          imageUrl: '/media/svg/menu-icons/thumbs-up.svg',
          imageWidth: 64,
          imageHeight: 64
        }).then((result) => {
          if (result.isConfirmed) {
            userRefetch()
            setComposeData({
              orderDetailId: '',
              user: '',
              email: '',
              userId: '',
              fromId: '',
              subject: '',
              messageId: '',
              content: '',
              to: '',
              cc: '',
              fromUser: '',
              type: '',
              attachment: '',
              folder: 'inbox',
              draft: false,
              favorite: false,
              files_info: [],
              unread: false,
            })
            setShowMessage(false)
            setShowMessageRows(true)
            setReplyMessage(false)
            setCreateMessage(false)
          }
        })
      }
    } else {
      setIsSubmiting(false)
    }
  }

  const validateType = () => {
    if (type === 'forward') {
      if (to.length === 0) {
        setLoading(false)
        Swal.fire({
          title: 'No se puede reenviar',
          text: 'No se puede reenviar un mensaje sin destinatario',
          icon: 'warning',
          confirmButtonText: 'Regresar',
        })
      } else {
        composepostdata.userId = to[0].id
        composepostdata.user = to[0].id
        return true
      }
    } else {
      return true
    }
    return false
  }
  const handleCloseMessage = () => {
    handleClose()
    if (setShowMessage) setShowMessage(false)
  }

  return (
    <div className='w-full' id='ReplyMessage'>
      {file &&
      <div className='pdf-viewer-width'>
        <button
          className='button-volver'
          style={{
            height: '2rem',
            width: '5rem',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            top: '0'
          }}
          onClick={() => {
            setFile(null)
          }}>
          Volver
        </button>
        <br />
        <div className='width-full-for-pdf'>
          <PdfViewerAndres
            name={fileName}
            url={URL.createObjectURL(file)}
            children={undefined} />
        </div>
      </div>}
      {!file &&<div className='hide-component-fullscreen flex justify-between'
        style={{
          width: '100%',
          height: '4rem',
          position: 'fixed',
          zIndex: '9999',
          backgroundColor: 'white',
          top: '0',
          paddingTop: '1rem'
        }}>
        <div className='flex gap-1 pr-8'>
          <button
            className='button2'
            style={{
              height: '2rem',
              width: '6rem',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              userRefetch()
              handleCloseMessage
              setShowMessage(false)
              setShowMessageRows(true)
              setReplyMessage(false)
              setCreateMessage(false)
            }}>
            <p className='meet-text2' style={{ margin: '0', fontSize: '12px' }}>VOLVER</p>
          </button>
        </div>
        <div className='flex gap-1 pr-8'>
          <button
            style={{
              height: '2rem',
              width: '6rem',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className='button2'
            onClick={handleSubmitDraft}>
            <p className='meet-text2' style={{ margin: '0', fontSize: '12px' }}>BORRADOR</p>
          </button>
          <button
            style={{
              height: '2rem',
              width: '6rem',
              boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
              border: '1px solid white',
              backgroundColor: '#2CD673',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={() => {
              composepostdata.draft=false
              composepostdata.folder="inbox"
              handleSubmit()
            }} className='button4'>
            <p style={{ margin: '0', fontSize: '12px' }}
              className='meet-text3'>ENVIAR</p>
          </button>
        </div>
      </div>}
      <div className='flex mb-8 mt-2 margin-top-cha' style={{ gap: '10px' }}>
        <img
          src='/media/svg/menu-icons/message-sender.svg'
          alt='Via estudios'
          style={{
            width: '30px',
            height: '30px'
          }} />
        <h1 style={{ fontSize: '24px' }}>Crear mensaje</h1>
      </div>
      {showComposeTemplate && (
        <div className='row'>
          <div className='col-md-12 w-60'>
            <ComposeTemplate setContent={setContent} />
            <div className='form-group'>
              {type === 'forward' ? (
                <div className='form-group'></div>
              ) : (
                <>
                  <label>To: </label>
                  {` ${composepostdata.email}`}
                </>
              )}
            </div>
          </div>
        </div>)}
      <div>
        <div className='row'>
          {contractId === '' ?
            <div className='col-md-12 px-8'>

              <ToComponent
                setCC={setTO}
                data={composepostdata}
                isNew={currentUser?.email !== undefined ? false : true} />

            </div> :
            <div className='col-md-12 px-8'>
              {composepostdata.folder !== 'draft' ? (
                <ToComponent
                  setCC={setTO}
                  data={composepostdata}
                  isNew={emailStaff !== '' ? false : true} />
              ) : null}
            </div>
          }
        </div>
        <div className='row mt-4'>
          <div className='col-md-12 px-8'>
            <div className='form-group flex gap-4'>
              <label>Asunto:</label>
              <Input
                onChange={(e) => {
                  setSubject(e.target.value)
                }}
                style={{ width: '100%' }}
                type='text'
              />
            </div>
          </div>
        </div>
        <div className='row '>
          <div className='col-md-12 px-8'>
            <div className='form-group'>
              {staff && tabNumber !== '1' && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <div>
                    <label>Plantillas:</label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <SearchTemplate setSubject={setSubject} setContent={setContent} setNewContent={setNewContent} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='flex gap-4 mb-4 w-full justify-start'>
          <p style={{ marginLeft: '1rem' }}>Adjuntar</p>
          <div className='attach-container'>
            {staff && tabNumber !== '1' &&
              <FileSelector
                setTargetFilesParent={setTargetFilesParent}
                target={target}
                setTarget={setTarget} />}
            <div className='flex flex-col'>
              <input
                style={{
                  width: '14rem',
                  height: '2.5rem',
                  border: `${errorFileMessage === true ? '1px solid red' : '1px solid #01A9DB'}`,
                  borderRadius: '5px',
                  display: 'flex',
                  gap: '5px',
                  padding: '5px'
                }}
                className='uploader_input'
                accept='.pdf'
                type='file'
                max={1}
                onChange={(event: any) => {
                  const file = event.target.files[0]
                  if (file && file.name) {
                    if (file
                      && file.type !== 'application/pdf'
                      && file.type !== 'image/png'
                      && file.type !== 'image/jpeg'
                    ) {
                      setErrorFileMesasge(true)
                      return
                    }
                    setErrorFileMesasge(false)
                    if (filesArray && filesArray.length && filesArray.length > 0) {
                      setFilesArray((prevFilesArray: any) => [...prevFilesArray, file])
                    } else {
                      setFilesArray([file])
                    }
                  }
                }} />
              {errorFileMessage == false ?
                <p style={{ fontSize: '13px' }}>
                  Solo archivos .pdf .jpeg .jpg .png
                </p>
                : <div style={{
                  backgroundColor: '#FEF3F3',
                  width: 'fit-content',
                  height: 'fit-content',
                  padding: '6px 12px',
                  borderRadius: '10px',
                  display: 'flex',
                  gap: '5px',
                  cursor: 'pointer'
                }}>
                  <img style={{ width: '13px' }} src={iconorechazo} alt="PDFIcon" />
                  <div>
                    <p style={{ color: 'red', margin: 0 }}>
                      Solo se aceptan formatos .pdf .jpeg .jpg .png
                    </p>
                  </div>
                </div>}

            </div>
            {staff &&
              <p>
                <Switch
                  checked={postConSwitch}
                  onChange={() => { setPostConSwitch(!postConSwitch) }} />
                Mensaje de seguimiento
              </p>}
          </div>
        </div>
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          onChange={(content) => {
            setNewContent(content)
          }}
        />
        {loadingFile===true && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}><img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /><p style={{margin:0}}>Cargando archivo adjunto</p></div>}
        <div className='gap-2 mt-4 mb-2'
          style={{ display: 'flex', flexWrap: 'wrap' }}>
          {filesArray &&
            filesArray.length > 0 &&
            filesArray.map((fileArray: any) => (
              <div
                className='files-show-message-hover'
                style={{
                  border: '1px solid #01A9DB',
                  maxWidth: '12rem',
                  borderRadius: '5px',
                  display: 'flex',
                  gap: '5px',
                  padding: '5px',
                  cursor: 'pointer',
                  height: '2rem'
                }}>
                <div
                  className='flex gap-4 mr-2 ml-2 file-label'
                  onClick={() => { verDoc(fileArray) }}>
                  <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                  {fileArray.name.length > 0 &&
                    <p style={{
                      color: '#01A9DB',
                      margin: '0',
                      fontSize: '1rem'
                    }}>
                      {`${fileArray.name.slice(0, 10)}${fileArray.name.length > 10 ? '...' : ''}`}
                    </p>}
                </div>
                <p onClick={() => deleteFileArray(fileArray)}
                  className='mr-2'
                  style={{
                    color: '#01A9DB',
                    margin: '0',
                    fontSize: '1rem'
                  }}>X</p>
              </div>
            ))}
          {target &&
            target.length > 0 &&
            target.map((fileArray: any) => (
              <div
                className='files-show-message-hover'
                style={{
                  maxWidth: '12rem',
                  border: '1px solid #01A9DB',
                  borderRadius: '5px',
                  display: 'flex',
                  gap: '5px',
                  padding: '5px',
                  cursor: 'pointer'
                }}>
                <div
                  className='flex gap-4 mr-2 ml-2 file-label'
                  onClick={() => { handleViewer(fileArray) }}>
                  <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                  {fileArray.title.length > 0 &&
                    <p style={{
                      color: '#01A9DB',
                      margin: '0',
                      fontSize: '1rem'
                    }}>
                      {`${fileArray.title.slice(0, 10)}${fileArray.title.length > 10 ? '...' : ''}`}
                    </p>}
                </div>
                <p onClick={() => { deleteTarget(fileArray) }}
                  className='mr-2'
                  style={{
                    color: '#01A9DB',
                    margin: '0',
                    fontSize: '1rem'
                  }}>X</p>
              </div>
            ))}
        </div>
      </div>
      {showShareFiles ? (
        <AutoShares setShared={setShared} />
      ) : null}
      <div className='flex justify-between'>
        <div className='hide-component'>
          <button
            className='button-volver'
            style={{ width: '8rem', display: 'flex', gap: '5px' }}
            onClick={() => {
              userRefetch()
              handleCloseMessage
              setShowMessage(false)
              setShowMessageRows(true)
              setReplyMessage(false)
              setCreateMessage(false)
            }}>
            <img src={Arrowleft} alt="Arrowleft" />
            <p className='meet-text2' style={{ margin: '0' }}>VOLVER</p>
          </button>
        </div>
        <div className='flex gap-4 pb-8 hide-component'>
          {loading?
           <button style={{
            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
            border: '1px solid white',
            backgroundColor: '#2CD673',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
            onClick={() => {
          /*     handleSubmit() */
            }} className='button4'>
            <b className='meet-text3'>CARGANDO </b>
            <img style={{ width: '25px', height: '25px', color: 'white' }}
                                src={load} alt="ArrowTop" />
          </button>
           :<>

          <button
            className='button-volver'
            onClick={handleSubmitDraft}>
            <p className='meet-text2' style={{ margin: '0' }}>GUARDAR BORRADOR</p>
          </button>
          <button style={{
            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
            border: '1px solid white',
            backgroundColor: '#2CD673',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
            onClick={() => {
             composepostdata.draft=false
             composepostdata.folder="inbox"
              handleSubmit()
            }} className='button-enviar'>
            <b className='meet-text3'>ENVIAR MENSAJE </b>
            <img
              src='/media/svg/menu-icons/Send.svg'
              alt='Via estudios'
              style={{
                width: '25px',
                height: '25px',
              }}
            />
          </button></>}
        </div>
      </div>
    </div>
  )
}

export default observer(NewMessage)
