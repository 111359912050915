/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  type IQueryUsers,
  initialIQueryUsers,
  type IQueryUsersFilter,
  initialIQueryUsersFilter,
} from '../../../../app/modules/apps/users/core/_models'
import React, {useContext} from 'react'
import {Button, Container, Link} from '../../../../components'
import {RootStoreContext} from '../../../../stores/rootStore'
import UserList from './List'
import UserMenusoptions from './UserMenusoptions'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {type ProfileImmigration} from 'app/modules/apps/profile/core/_models'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const UserWrapper: React.FC<Props> = (props) => {
  const {ProfileImmigration} = props
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {currentUser,selectUser} = rootStore.usersStore
  const [search, setSearch] = React.useState<IQueryUsers>(initialIQueryUsers)
  const [searchFilter, setSearchFilter] =
    React.useState<IQueryUsersFilter>(initialIQueryUsersFilter)
  return (
    <Container title={intl.formatMessage({id: 'CLIENTS.CLIENTSLIST'})}>
      <Link onClick={()=>{selectUser(null)}} to='/userslist'>
        <button
          className='button-ui button-secondary button-s'
        >
          Volver
        </button>
      </Link>
      {currentUser != null ? (
        <UserMenusoptions ProfileImmigration={ProfileImmigration} />
      ) : (
        <>
          <UserList
            search={search}
            searchFilter={searchFilter}
            setSearch={setSearch}
            searchQuery={search}
          />{' '}
        </>
      )}
    </Container>
  )
}

export default observer(UserWrapper)
