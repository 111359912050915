/* eslint-disable react-hooks/exhaustive-deps */
import {Alert, Button} from '../../../../components'
import React, {useContext, useEffect} from 'react'
import {RootStoreContext} from '../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import '../../../pages/sass-arquitecture/main.scss'
interface IProps {
  setCurrent: React.Dispatch<React.SetStateAction<number>>
  next: boolean
}

const ValidationQuantity: React.FC<IProps> = ({next, setCurrent}) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {error, cart, numberPages,filesPaypal, setError} = rootStore.cartStore

  useEffect(() => {
    validateQuantity()
  }, [cart])

  useEffect(() => {
    validateQuantity()
  }, [numberPages])

  const validateQuantity = () => {
    const fileType = filesPaypal ?  filesPaypal[0].type : []
    if (cart && cart[0] && cart[0].quantity ) {
    if ( fileType != 'application/pdf'){if (numberPages > cart[0].quantity) {
      /* setError(true) */
    } else {
     /*  setError(false) */
    }}
  }
  }
  const validateData = () => {
    setCurrent(1)
  }

  return (
    <>
      {error ? (
        <Alert>{intl.formatMessage({id: 'TRANSLATION.ERRORUPLOADCOUNT'})}</Alert>
      ) : (
        <div className='container-button-payment'><Button className=' m-auto next-button-form-payment' style={{ textTransform: 'uppercase' }} onClick={validateData} disabled={!next}>
        {intl.formatMessage({ id: 'TRANSLATION.NEXTT' })}
        <img
          src='/media/svg/menu-icons/arrow-right.svg'
          alt='arrow Icon'
          className='icon'
        />
      </Button></div> 
      )}
    </>
  )
}
export default observer(ValidationQuantity)
