import { useContext, useEffect, useState } from 'react'
import { AsideMenuItem, Link } from '../../../../components'
import { type Menus } from '../../../../app/modules/apps/menus/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import getIcon from './MenuUser/getIcons'
import { initialStaffInfo } from '../../../../app/modules/apps/staff/core/_models'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import AvatarProfile from 'app/modules/accounts/components/settings/cards/Avatar/AvatarProfile'

interface Props {
  initial?: string
}

const AsideMenuAdmin: React.FC<Props> = () => {
  const rootStore = useContext(RootStoreContext)
  const { user, logout } = rootStore.authStore
  const [isClient, setIsClient] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const { countMessagesNotReadGeneral, loadMessagesUnread, loadMessagesUnreadGeneral } =
    rootStore.messageStore

  const { menus, loadMenus } = rootStore.menustore
  const intl = useIntl()

  useEffect(() => {
    loadMessagesUnread()
    loadMessagesUnreadGeneral(initialStaffInfo)
    loadMenus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.role?.name === 'User') {
      setIsClient(true)
    }
  }, [user?.role?.name])

  const handleItemClick = (option: string) => {
    setSelectedOption(option)
  }


  return (
    <>
      <div className='flex justify-center items-start ml-4 mt-8 mb-8 gap-2'>
        <div>
          <AvatarProfile initialImage='' size={40} />
        </div>
        <div className='flex flex-col items-start'>
          <h3 className='font-bold' style={{ fontSize: '14px', margin: '0' }}>
            {user?.firstName}
          </h3>
          <h3 className='font-bold' style={{ fontSize: '14px', margin: '0' }}>
            {user?.lastName}
          </h3>
          <p className='text-gray-500' style={{ fontSize: '14px', margin: '0' }}>
            {user?.email.substring(0, 20)}...
          </p>
          {isClient ? (
            <div className='step-badge-active-success2 mt-2'>
              <p
                className='step-badge-active-success-p-ones'
                style={{ fontSize: '12px', margin: '0', color: '#1A8245' }}
              >
                Cliente
              </p>
            </div>
          ) : (
            <div className='step-badge-active-success2'>
              <p
                className='step-badge-active-success-p-ones'
                style={{ fontSize: '12px', margin: '0', color: '#1A8245' }}
              >
                Admin
              </p>
            </div>
          )}
        </div>
      </div>
      {menus?.map((menu: Menus, index) => {
        if (menu.id === '10b6be49-d1ec-4857-8a33-a3512cdfca5b') {
          loadMessagesUnreadGeneral(initialStaffInfo);
          return (
            <div key={menu.id} onClick={() => handleItemClick('')}>
              <AsideMenuItem
                to={`${menu.to}`}
                icon='/media/icons/app/menu/calendar.svg'
                title={intl.formatMessage({ id: menu.title })}
                fontIcon='bi-app-indicator'
                IconName={getIcon(menu.icon_name)}
                newMessages={countMessagesNotReadGeneral}
                children={undefined}
              />
            </div>
          );
        } else if (menu.parent === '') {
          return (
            <div key={menu.id} onClick={() => handleItemClick('')}>
              <AsideMenuItem
                key={index}
                icon='-'
                to={`${menu.to}`}
                title={intl.formatMessage({ id: menu.title })}
                IconName={getIcon(menu.icon_name)}
                fontIcon='bi-app-indicator'
                newMessagesAbsentConsultant={countMessagesNotReadGeneral}
                children={undefined}
              />
            </div>
          );
        }
      })}

      <div
        className='flex flex-col items-start justify-start pt-2 mt-2 ml-2 mb-4'
        style={{ borderTop: '1px solid #DFE4EA' }}
      >
        <div
          className={
            selectedOption === 'perfil'
              ? 'svg-fill-color w-full h-9 flex items-center pl-4'
              : 'svg-fill-color2 w-full h-9 flex items-center pl-4'
          }
          onClick={() => handleItemClick('perfil')}
        >
          <Link className='w-full' to={'/crafted/account/settings'}>
            <p
              style={{ fontSize: '14px', fontWeight: 'normal', width: '100%', margin: '0' }}
              className={
                selectedOption === 'perfil'
                  ? 'menu-color-text2 menu-color-text3 '
                  : 'menu-color-text2 '
              }
            >
              Mi perfil
            </p>
          </Link>
        </div>
        <div
          className={
            selectedOption === 'tecnico'
              ? 'svg-fill-color w-full h-9 flex items-center pl-4'
              : 'svg-fill-color2 h-9 w-full flex items-center pl-4'
          }
          onClick={() => handleItemClick('tecnico')}
        >
          <Link className='w-full' to={'/support'}>
            <p
              style={{ fontSize: '14px', fontWeight: 'normal', width: '100%', margin: '0' }}
              className={
                selectedOption === 'tecnico'
                  ? 'menu-color-text2 menu-color-text3'
                  : 'menu-color-text2'
              }
            >
              Soporte Técnico
            </p>
          </Link>
        </div>
        <div
          className={
            selectedOption === 'password'
              ? 'svg-fill-color w-full h-9 flex items-center pl-4'
              : 'svg-fill-color2 h-9 w-full flex items-center pl-4'
          }
          onClick={() => handleItemClick('password')}
        >
          <Link className='w-full' to={'/changePassword'}>
            <p
              style={{ fontSize: '14px', fontWeight: 'normal', width: '100%', margin: '0' }}
              className={
                selectedOption === 'password'
                  ? 'menu-color-text2 menu-color-text3'
                  : 'menu-color-text2'
              }
            >
              Cambiar contraseña
            </p>
          </Link>
        </div>
        <p
          className='menu-color-text2 svg-fill-color2 pt-2 h-9 flex items-center flex items-center pl-4'
          style={{ cursor: 'pointer', fontSize: '14px', width: '100%', borderTop: '1px solid #DFE4EA', fontWeight: 'normal', margin: '0' }}
          onClick={logout}
        >
          Cerrar sesión
        </p>
      </div>
    </>)
}

export default observer(AsideMenuAdmin)
