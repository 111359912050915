 
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
 
import React, {useContext, useState} from 'react'
import axios from 'axios'
import {Aplication} from 'app/modules/apps/cart/core/aplication/_models'
import {getAplicationByUserId} from 'app/modules/apps/cart/core/aplication/_request'

import {EditOutlined} from '@ant-design/icons'
import EditAplicationForm from './EditAplicationForm'
import {useQuery} from 'react-query'
import {DeleteOutlined} from '@ant-design/icons'
import {Users} from 'app/modules/apps/users/core/_models'
import {RootStoreContext} from 'stores/rootStore'
import CreateAplicationFormByUser from './CreateApplicationFormByUsers'

const API_URL = process.env.REACT_APP_API_URL
const REMOVEAPLICATION_URL = `${API_URL}/application/removeApplication`

export interface IProps {
  user: Users | undefined
}

const AplicationTableByUser: React.FunctionComponent<IProps> = ({user: user}) => {
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [editAplication, setEditAplication] = useState<Aplication | null>(null)
  const [showEditForm, setShowEditForm] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const {data: aplications, refetch} = useQuery(
    'GetAplication',
    async () => await getAplicationByUserId(currentUser?.id!)
  )

  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm)
  }
  const handleAplicationCreated = () => {
    /*  fetchInstitutions();  */
    refetch()
    setShowTable(true)
    setShowCreateForm(false)
  }

  const handleEditClick = (aplication: Aplication) => {
    setEditAplication(aplication)
    setShowEditForm(true)
    setShowTable(false)
  }

  const handleEditSave = () => {
    setShowEditForm(false)
    setShowTable(true)
    refetch()
  }

  const handleDeleteClick = async (aplication: Aplication) => {
    try {
      await axios.delete(`${REMOVEAPLICATION_URL}/${aplication.id}`)
      refetch()
    } catch (error) {
      console.error('Error deleting aplication:', error)
    }
  }

  return (
    <div>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '2rem',
        }}
        className='text-2xl font-semibold mb-4'
      >
        Tabla registro
      </h2>
      <button
        style={{marginBottom: '2rem', marginLeft: '2rem'}}
        className='submit-button2'
        onClick={() => {
          toggleCreateForm()
          setShowTable(false)
        }}
      >
        Crear Registro
      </button>
      {showCreateForm && (
        <CreateAplicationFormByUser
          showTable={showTable}
          setShowTable={setShowTable}
          onInstitutionCreated={handleAplicationCreated}
        />
      )}
      {showTable && (
        <div className='overflow-x-auto'>
          <table className='mt-4 min-w-full bg-white border rounded-md shadow-sm'>
            <thead className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'>
              <tr>
                <th className='px-4 py-2'>Client</th>
                <th className='px-4 py-2'>Status</th>
                <th className='px-4 py-2'>Progam</th>
                <th className='px-4 py-2'>Institution</th>
              </tr>
            </thead>
            <tbody>
              {aplications?.map((aplication) => (
                <tr key={aplication.id}>
                  <td className='px-4 py-2'>
                    {aplication.user.email +
                      '  ' +
                      aplication.user.firstName +
                      aplication.user.lastName}
                  </td>
                  <td className='px-4 py-2'>{aplication.statuseducation.name}</td>
                  <td className='px-4 py-2'>{aplication.program.name}</td>
                  <td className='px-4 py-2'>{aplication.program.institution.name}</td>

                  {/*               <td className="px-4 py-2">{institution.owner}</td> */}
                  <td className='px-4 py-2'>
                    <button className='edit-button' onClick={() => handleEditClick(aplication)}>
                      <EditOutlined />
                    </button>
                  </td>
                  <td className='px-4 py-2'>
                    <button className='delete-button' onClick={() => handleDeleteClick(aplication)}>
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showEditForm && editAplication !== null && (
        <EditAplicationForm editAplication={editAplication} onSave={handleEditSave} />
      )}
    </div>
  )
}

export default AplicationTableByUser
