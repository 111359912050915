// import './styles/dashboard.css'

import { useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import AsideMenuMainUser from '../../../../core/layout/components/aside/AsideMenuMainUser'
import { BiBell } from 'react-icons/bi'
import { BsChevronDown } from 'react-icons/bs'
import { CgLogIn } from 'react-icons/cg'
import { DashboardWrapper } from '../../../../app/pages/dashboard/DashboardWrapper'
import { GiShoppingCart } from 'react-icons/gi'
import { TbNotes } from 'react-icons/tb'
import { type ProfileImmigration } from 'app/modules/apps/profile/core/_models'

interface Props {
  ProfileImmigration: ProfileImmigration
}

const Dashboard: React.FC<Props> = (props) => {
  const { ProfileImmigration } = props
  const [subMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <>
      <div className='flex flex-wrap overflow-hidden'>
        <div className='w-1/6 overflow-hidden bg-tomato-400'>
          <div className='w-1/6 overflow-hidden'>
            <div className='bg-white fixed top-0 left-0 w-56 h-screen block mb-10'>
              <div className='ml-auto block float-left mb-4'>
                <img
                  src={'/media/newdesign/07-logo-horizontal-color.svg'}
                  className='block w-3/4 mt-0 m-auto float-left'
                  alt=''
                />
              </div>
              <div>
                <ul className='m-3'>
                  <p className=' text-xs text-gray-600'>Main Navigation</p>
                  <AsideMenuMainUser />
                  <li
                    className=' '
                    onClick={() => {
                      setSubMenuOpen(!subMenuOpen)
                    }}
                  >
                    <button
                      type='button'
                      className='flex p-2  text-gray-500 hover:text-tomato-500 hover:bg-rosa-500  hover:rounded-lg font-semibold relativa focus:border-none focus:outline-none'
                    >
                      <span className='text-black w-6 inline-block  justify-center items-center '>
                        <i className='text-centar my-auto'>
                          <TbNotes />
                        </i>
                      </span>
                      <span className='text-centar my-auto text-xs'>Services</span>
                      <i className='ml-24 items-end my-auto'>
                        <BsChevronDown className={`${subMenuOpen ? 'rotate-180' : ''}`} />
                      </i>
                    </button>
                    {subMenuOpen && subMenuOpen && (
                      <ul className='py-2 space-y-2 ml-4'>
                        <li className=' p-2 hover:bg-rosa-500  hover:rounded-lg font-semibold'>
                          <a href='default' className='flex text-gray-500  hover:text-tomato-500'>
                            <span className='text-black w-6 inline-block  justify-center items-center '>
                              <i className='text-centar my-auto '>
                                <TbNotes />
                              </i>
                            </span>
                            <span className='text-centar my-auto text-xs'>Translation</span>
                          </a>
                        </li>
                        <li className=' p-2 hover:bg-rosa-500  hover:rounded-lg font-semibold'>
                          <a href='default' className='flex text-gray-500  hover:text-tomato-500'>
                            <span className='text-black w-6 inline-block  justify-center items-center '>
                              <i className='text-centar my-auto '>
                                <TbNotes />
                              </i>
                            </span>
                            <span className='text-centar my-auto text-xs'>Visa</span>
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>

              <div>
                <ul className='m-3 mt-4'>
                  <p className=' text-xs text-gray-600'>Insight</p>
                  <li className=' p-2 hover:bg-rosa-500  hover:rounded-lg font-semibold'>
                    <a href='default' className='flex text-gray-500  hover:text-tomato-500'>
                      <span className='text-black w-6 inline-block  justify-center items-center '>
                        <i className='text-centar my-auto '>
                          <AiOutlineMail />
                        </i>
                      </span>
                      <span className='text-centar my-auto text-xs'>Messages</span>
                    </a>
                  </li>
                  <li className=' p-2 hover:bg-rosa-500  hover:rounded-lg font-semibold'>
                    <a href='default' className='flex text-gray-500  hover:text-tomato-500'>
                      <span className='text-black w-6 inline-block  justify-center items-center '>
                        <i className='text-centar my-auto'>
                          <BiBell />
                        </i>
                      </span>
                      <span className='text-centar my-auto text-xs'>Notificacions</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className='my-10 '>
                <span className=' text-tomato-500  h-auto flex text-xs justify-center mx-3 items-center'>
                  <i className='flex justify-between items-center '>
                    <GiShoppingCart className='h-10 w-10 ' />
                  </i>
                </span>
                <h3 className='text-tomato-500 text-center'>Purchase service</h3>
              </div>

              <div>
                <ul className='m-3 mt-4'>
                  <li className=' p-2 hover:bg-rosa-500  hover:rounded-lg font-semibold'>
                    <a href='default' className='flex text-gray-500  hover:text-tomato-500'>
                      <span className='text-black w-6 inline-block  justify-center items-center '>
                        <i className='text-centar my-auto '>
                          <CgLogIn />
                        </i>
                      </span>
                      <span className='text-centar my-auto text-xs'>Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='w-5/6 bg-white '>
          {/* <Navbar>
            <div className=' w-1/2 flex items-center px-2 py-2 rounded-md bg-gray-0 text-xs'>

            </div>
            <div className='w-1/2 h-auto flex text-xs justify-end mx-5'>
              <div className='flex justify-between items-center '>
                <HeaderUserMenu />

              </div>
              <div className=' items-center mx-2 justify-center'></div>
            </div>
          </Navbar> */}

          <div className='w-full   bg-gray-100 p-3 pl-4 '>
            <p className='text-xl font-semibold'>My Translations</p>
            <div className='m-3 bg-white rounded-md p-3 h-full'>
              <DashboardWrapper ProfileImmigration={ProfileImmigration} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
