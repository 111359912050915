/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import {Alert, Button, H1, H3, Input} from '../../components'
import {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {RootStoreContext} from '../../stores/rootStore'
import {type UserModel} from '../../auth/models/User/UserModel'
import clsx from 'clsx'
import {notification} from 'antd'
import {observer} from 'mobx-react'
import {useFormik} from 'formik'
import { registrationNew } from 'app/modules/apps/users/core/_requests'
import { UserBasic } from 'app/modules/apps/users/core/_models'
import Password from 'antd/lib/input/Password'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('Nombres es requerido'),
  email: Yup.string()
    .email('Formato de correo invalido')
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('Email es requerido'),
  lastname: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('Apellidos es requerido'),
   password: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('Contraseña es requerido'),
  /*  changepassword: Yup.string()
    .required('Confirmar Contraseña es requerido')
    .when('password', {
      is: (val: string) => (!!(val && val.length > 0)),
      then: Yup.string().oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
    }), */
  acceptTerms: Yup.bool().required('Debes aceptar los términos y condiciones'),
})

const openNotificationWithIcon = () => {
  notification.error({
    message: 'Error de registro',
    description: 'El usuario ya existe, por favor intente iniciar sesión o recupere su contraseña',
  })
}

const Registration = () => {
  const rootStore = useContext(RootStoreContext)
  const {registerUser, onErrorRegister, isAuthenticated} = rootStore.authStore

  const [loading, setLoading] = useState(false)
  const [submiting, setSubmitting] = useState(false)

  useEffect(() => {
    if (onErrorRegister) {
      if (onErrorRegister.data.status === 422) {
        openNotificationWithIcon()
        setLoading(false)
      }
    }
  }, [onErrorRegister])

  // const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      const user: UserModel = {
        id: '',
        username: '',
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        ocupation: '',
        companyName: '',
        phone: '',
        pic: '',
        countryOfOrigin: '',
        gender: '',
        maritalStatus: '',
        nationalitie: '',
        socialId: '',
        whatsapp: '',
        language: 'en',
      }

      const   userBasic = {
     
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        password: values.password,

      }
      console.log(userBasic , 'userinside')
      const result = await registerUser(user, values.password)
      if (result) {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    // PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form className='text-left' noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
      <H1>Crear cuenta</H1>
      {formik.status && <div>{formik.status}</div>}

      <div className='grid grid-cols-2 gap-4'>
        <div>
          <div>
            <H3 className='text-left'>Nombres</H3>
            <Input
              placeholder='Nombres'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                {
                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                },
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            <Alert className='text-left'>
              {formik.touched.firstname && formik.errors.firstname && (
                <span role='alert'>{formik.errors.firstname}</span>
              )}
            </Alert>
          </div>
        </div>
        <div>
          <div>
            <H3 className='text-left'>Apellidos</H3>
            <Input
              placeholder='Apellidos'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            <Alert className='text-left'>
              {formik.touched.lastname && formik.errors.lastname && (
                <span role='alert'>{formik.errors.lastname}</span>
              )}
            </Alert>
          </div>
        </div>
      </div>

      <div>
        <H3 className='text-left'>Email</H3>
        <Input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        <Alert className='text-left'>
          {formik.touched.email && formik.errors.email && (
            <span role='alert'>{formik.errors.email}</span>
          )}
        </Alert>
      </div>

      <div className='grid grid-cols-2 gap-4'>
        <div>
          <div data-kt-password-meter='true'>
            <H3 className='text-left'>Contraseña</H3>
            <Input
              type='password'
              placeholder='Contraseña'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <Alert className='text-left'>
              {formik.touched.password && formik.errors.password && (
                <span role='alert'>{formik.errors.password}</span>
              )}
            </Alert>
          </div>
        </div>
        <div>
          <div>
            <H3 className='text-left'>Confirmar Contraseña</H3>
            <Input
              type='password'
              placeholder='Confirmar Contraseña'
              autoComplete='off'
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            <Alert className='text-left'>
              {formik.touched.changepassword && formik.errors.changepassword && (
                <span role='alert'>{formik.errors.changepassword}</span>
              )}
            </Alert>
          </div>
        </div>
      </div> 
      <div>
        <p className='text-xs'>
          Use mas de 8 caracteres, incluye mayusculas, minusculas, numeros y simbolos.
        </p>
      </div>

      <div className='fv-row mb-5 float-left text-sm ml-4'>
        <input
          className='form-check-input'
          type='checkbox'
          id='kt_login_toc_agree'
          {...formik.getFieldProps('acceptTerms')}
        />
        <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='kt_login_toc_agree'>
          Estoy de acuerdo con los{' '}
          <Link
            to='https://www.immilandcanada.com/terminos-y-condiciones'
            target={'_blank'}
            className='ms-1 link-primary'
          >
            términos y condiciones
          </Link>
          .
        </label>
        <Alert className='text-left'>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <span role='alert'>{formik.errors.acceptTerms}</span>
          )}
        </Alert>
      </div>

      <div className='text-center'>
        <Button
          type='submit'
          id='kt_sign_up_submit'
          disabled={!formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Registrarse</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Porfavor espere...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </div>
    </form>
  )
}

export default observer(Registration)
