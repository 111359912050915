 

import AvatarProfileUsers from '../../../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {type Calendly} from '../../../../../../app/modules/apps/calendly/core/_models'
import React from 'react'
import {TimeZone2} from '../../../../../../app/modules/utils/components/TimeZone/TimeZone2'

interface IProps {
  calendly: Calendly
  timezone: string
  setUrl: (url: string) => void
}

const CalendlyCards: React.FC<IProps> = ({calendly, timezone, setUrl}) => {
  const composeTo = (url_name: string, date: string) => {
    const yearAndMonth = date.split('-')
    const year = yearAndMonth[0]
    const month = yearAndMonth[1]
    const day = yearAndMonth[2].split('T')[0]

    const base = `https://calendly.com/immiland-canada/${url_name}/${date}?month=${year}-${month}&date=${year}-${month}-${day}`

    console.log('🚀 ~ file: CalendlyCards.tsx:22 ~ composeTo ~ base', base)
    setUrl(base)

    return '#'
  }

  return (
    <>
      <div className='w-full card-immigration '>
        <div>
          <div>
            <div className='relative px-2 mt-2  '>
              <h4 className='mt-1 font-semibold uppercase leading-tight truncate'>
                <AvatarProfileUsers
                  image={
                    calendly.data.path
                      ? `${calendly.data.path}/${calendly.data.photo}-avatar-small.jpg`
                      : ''
                  }
                  size={25}
                />
                <span className='ml-2 card-immigration-staff '>{calendly.data.staff}</span>
              </h4>
              {calendly.spots?.map((item, index) => (
                <div
                  key={index}
                  className='bg-white  card-immigration-spot '
                  onClick={() => {
                    composeTo(calendly.data.url_name, item.start_time)
                  }}
                >
                  <div
                    onClick={() => {
                      // composeTo(calendly.data.url_name, item.start_time)
                    }}
                    className='cursor-pointer card-immigration-link    '
                  >
                    <div className='card-immigration-spot-content'>
                      <div className='card-immigration-dot'></div>

                      <TimeZone2 date={item.start_time} timeZone={timezone} onlyTime={true} />
                    </div>
                  </div>
                </div>
              ))}
              <div className='mt-4'>
                <span className='text-teal-600 text-md font-semibold card-immigration-price '>
                  $ {calendly.data.price} CAD
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CalendlyCards
