import {Route, Routes} from 'react-router-dom'
import ImmigrationCosts from '../../../app/pages/immigration/costs'
import ImmigrationElements from '../../../app/pages/immigration/element'
import ImmigrationFunds from '../../../app/pages/immigration/funds'
import ImmigrationLinks from '../../../app/pages/immigration/links'
import ImmigrationOptions from '../../../app/pages/immigration/options'
import ImmigrationRequirements from '../../../app/pages/immigration/requirements'

const ImmigrationRoutes = () => {
  return (
    <Routes>
      <Route
        path='/immigration/options'
        element={<ImmigrationOptions title='IMMIGRATION.OPTIONS' />}
      />
      <Route
        path='/immigration/requirements'
        element={<ImmigrationRequirements title='IMMIGRATION.REQUIREMENTS' />}
      />
      <Route
        path='/immigration/elements'
        element={<ImmigrationElements title='IMMIGRATION.ELEMENTS' />}
      />
      <Route path='/immigration/funds' element={<ImmigrationFunds title='IMMIGRATION.FUNDS' />} />
      <Route path='/immigration/costs' element={<ImmigrationCosts title='IMMIGRATION.COSTS' />} />
      <Route path='/immigration/links' element={<ImmigrationLinks title='IMMIGRATION.LINKS' />} />
    </Routes>
  )
}

export default ImmigrationRoutes
