/* eslint-disable @typescript-eslint/no-empty-function */
 
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import { Alert, Button, H3, Input } from '../../../../components'
import React, { useContext, useEffect } from 'react'
import { Md5 } from 'md5-typescript'
import { type PaymentsPayu } from '../../../../app/modules/apps/cart/core/paymentpayu/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import { getPreOrderByCode } from '../../../../app/modules/apps/cart/core/preorders/_requests'
import { observer } from 'mobx-react'
import { savePaymentsPayu } from '../../../../app/modules/apps/cart/core/paymentpayu/_requests'
import { useForm } from 'react-hook-form'
import { useFormik } from 'formik'
import { yupResolver } from '@hookform/resolvers/yup'
import { createOrderDetail, createOrderPaypal, createPaymentPaypal, createTask, createTranslation, createTranslationDetail, createTranslationResult, createUserPaypal, findOrderByPaymentId, findPaymentByIntent, findProductByDesc, findTask, findTaskByUser, findTranslation, findUserByEmail, uploadFilesPaypal } from 'app/modules/apps/paypal/_requests'

const loginSchema = Yup.object().shape({
  identificationType: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Tipo de identificacion es requerido'),
  identification: Yup.string().min(3, 'Minimum 3 symbols').required('Identificacion es requerido'),
  name: Yup.string().min(3, 'Minimum 3 symbols').required('Nombre es requerido'),
  lastname: Yup.string().min(3, 'Minimum 3 symbols').required('Apellido es requerido'),
  phone: Yup.string().min(3, 'Minimum 3 symbols').required('Telefono es requerido'),
})

const initialValues = {
  identificationType: '',
  identification: '',
  name: '',
  lastname: '',
  phone: '',
  merchantId: '',
  accountId: '',
  description: '',
  referenceCode: '',
  amount: '',
  tax: '',
  taxReturnBase: '',
  currency: '',
  signature: '',
  test: '',
  buyerEmail: '',
  responseUrl: '',
  confirmationUrl: '',
}

const formControlStyle = {
  boxShadow: 'none',
  borderRadius: '4px',
  border: '1px solid #c7cfd8',
  background: '#fff',
  padding: '6px 12px',
  fontSize: '14px',
  lineHeight: '1.428571429',
  color: '#555',
  display: 'block',
  width: '30rem',
  height: '100px',
  margin: '0.5em 0 2em',
  position: 'relative' as const,
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch' as const,
}

// -------------------------------------------------------------------------------- //
// Test Data
// const ApiKey = '4Vj8eK4rloUd272L48hsrarnUA'
// const merchantId = '508029'
// const accountId = '512321'
// -------------------------------------------------------------------------------- //

// -------------------------------------------------------------------------------- //
// Prod Data
const ApiKey = 'l23iu8nqlZ5rfI494Vl2t423oX'
const merchantId = '971792'
const accountId = '979659'
// -------------------------------------------------------------------------------- //

const currency = 'COP'

interface IProps {
  saving: boolean
  setSaving: (saving: boolean) => void
}

const Payu: React.FC<IProps> = ({ saving, setSaving }) => {


  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [newUser, setNewUser] = React.useState<any>()
  const { savePreOrder, preOrder, cart, changeProductPrice, getSource } = rootStore.cartStore
  const [amount, setAmount] = React.useState(0)
  const [description, setDescription] = React.useState('')
  const [referenceCode, setReferenceCode] = React.useState('')
  const [buyerEmail, setBuyerEmail] = React.useState(user?.email || '')
  const [isChecked, setIsChecked] = React.useState(false)
  const [emailMatch, setEmailMatch] = React.useState(true);

  // -------------------------------------------------------------------------------- //
  // Test Data
  //Test https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/
  const [urlCheckoutPayu, setUrlCheckoutPayu] = React.useState('https://checkout.payulatam.com/ppp-web-gateway-payu/');
  const [testPayU, setTestPayU] = React.useState('false');
  // const [responseUrl, setResponseUrl] = React.useState('http://localhost:3011/web/thanks');
  // const [confirmationUrl, setConfirmationUrl] = React.useState('http://localhost:3011/web/thanks');
  // -------------------------------------------------------------------------------- //

  // -------------------------------------------------------------------------------- //
  // Prod Data
  // Prod https://checkout.payulatam.com/ppp-web-gateway-payu/
  // const [urlCheckoutPayu, setUrlCheckoutPayu] = React.useState(
  //   'https://checkout.payulatam.com/ppp-web-gateway-payu/'
  // )
  // const [testPayU, setTestPayU] = React.useState('false')
  const [responseUrl, setResponseUrl] = React.useState('https://immiland.app/web/thanks')
  const [confirmationUrl, setConfirmationUrl] = React.useState('https://immiland.app/web/thanks')
  // -------------------------------------------------------------------------------- //

  const [pay, setPay] = React.useState(false)

  const [identificationType, setIdentificationType] = React.useState('')
  const [identification, setIdentification] = React.useState('')
  const [name, setName] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [event, setEvent] = React.useState<any>()
  const [hash, setHash] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [confirmEmail, setConfirmEmail] = React.useState('')

  const [couponCode, setCouponCode] = React.useState('')
  const { filesPaypal, numberPages } = rootStore.cartStore

  const { setValue } = useForm({
    resolver: yupResolver(loginSchema),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const preorder = await generatePreorder1()
      const data: PaymentsPayu = {
        identificationType: values.identificationType,
        identification: values.identification,
        name: values.name,
        lastname: values.lastname,
        phone: values.phone,
        sourceId: getSource(),
        code: preorder?.code || '',
        description: preorder?.description || '',
        amount: `${preorder?.amount || 0}`,
        referenceCode: preorder?.code || '',
        statusName: 'GENERATED',
        user: {
          id: newUser?.id || '',
        },
      }

      const result = await savePaymentsPayu(data)

      if (data) {
        setSubmitting(false)
        window.location.href = 'https://example.com' // Replace with your desired external URL
          ; <form
            method='post'
            action='https://checkout.payulatam.com/ppp-web-gateway-payu/'
          ></form>
      }
    },
  })

  const generateHash = async (referenceCode: string, amount: number) => {
    const hashString = `${ApiKey}~${merchantId}~${referenceCode}~${amount}~${currency}`
    const hash = Md5.init(hashString)
    setHash(hash.toString())
  }

  const generatePreorder = async (
    isCupon: boolean,
    couponType: boolean,
    couponValue: number,
    couponPercentage: number
  ) => {
    const preorder = await savePreOrder('payu')

    if (preorder != null) {
      const preoderData = await getPreOrderByCode(preOrder.code)

      if (preoderData != null) {
        let discountCupon = preoderData[0].total

        if (isCupon) {
          if (couponType) {
            discountCupon = preoderData[0].total - preoderData[0].total * (couponPercentage / 100)
          } else {
            discountCupon = preoderData[0].total - couponValue
          }
        }
        setAmount(discountCupon)
        setDescription(preOrder.description)
        setReferenceCode(preOrder.code)
        setBuyerEmail(newUser?.email || '')

        generateHash(preOrder.code, discountCupon)

        return {
          code: preOrder.code,
          amount: `${discountCupon}`,
          referenceCode: preOrder.code,
          description: preOrder.description,
        }
      } else {
        // setSaving(false)
      }
    }
  }

  const generatePreorder1 = async () => {
    const preorder = await savePreOrder('payu')

    if (preorder != null) {
      const preoderData = await getPreOrderByCode(preOrder.code)

      if (preoderData != null) {
        setAmount(preoderData[0].total)
        setDescription(preOrder.description)
        setReferenceCode(preOrder.code)
        setBuyerEmail(newUser?.email || '')

        generateHash(preOrder.code, preoderData[0].total)

        return {
          code: preOrder.code,
          amount: `${preoderData[0].total}`,
          referenceCode: preOrder.code,
          description: preOrder.description,
        }
      } else {
        // setSaving(false)
      }
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const generateOrderPayu = (event: any) => {
    const form = event.target
    const data = {}
  }

  const handleChangeIdentificationType = (event: any) => {
    const value = event.target.value
    setIdentificationType(value)
  }

  const handleChangeIdentification = (event: any) => {
    const value = event.target.value
    setIdentification(value)
  }

  const handleChangeName = (event: any) => {
    const value = event.target.value
    setName(value)
  }

  const handleChangeLastname = (event: any) => {
    const value = event.target.value
    setLastname(value)
  }

  const handleChangePhone = (event: any) => {
    const value = event.target.value
    setPhone(value)
  }

  const handleChangeEmail = (event: any) => {
    const value = event.target.value
    setEmail(value)
    setEmailMatch(event.target.value === confirmEmail);
  }

  const handleChangeConfirmEmail = async (event: any) => {
    const value = event.target.value
    setConfirmEmail(value)
    setEmailMatch(event.target.value === email);
  }


  const generatePayment = async () => {
    let isCupon = false
    let couponType = false
    let couponValue = 0
    let couponPercentage = 0

    if (couponCode.length > 0 && couponCode === '10CUPON') {
      isCupon = true
      couponType = true
      couponValue = 10
      couponPercentage = 10
    }
    const userExist = await findUserByEmail(confirmEmail)
    setNewUser(userExist)
      if (!userExist) {
        const newUser = await createUserPaypal(confirmEmail, name, lastname)
        setNewUser(newUser)
      }
     
    const preorder = await generatePreorder(isCupon, couponType, couponValue, couponPercentage)
    const data: PaymentsPayu = {
      identificationType: identificationType,
      identification: identification,
      name: name,
      lastname: lastname,
      phone: phone,
      sourceId: getSource(),
      code: preorder?.code || '',
      description: preorder?.description || '',
      amount: `${preorder?.amount || 0}`,
      referenceCode: preorder?.code || '',
      statusName: 'GENERATED',
      user: {
        id: userExist? userExist.id : newUser.id
      },
    }
    const description = preorder?.description.substring(0, preorder?.description.indexOf(','));
    const task = await findTask(description)
    const totalPurchase = preorder?.description.split(':')[2].trim()
    
    const payment = await savePaymentsPayu(data)
    // const paymentId = payment?.id
    // console.log(payment.id)
    // const orderData = {
    //   receipt_link: 'payu',
    //   total: totalPurchase,
    //   user_id: user?.id,
    //   payment_id: paymentId
    // }
    // await createOrderPaypal(orderData)
    // const order = await findOrderByPaymentId(paymentId)
    // const orderId = order.id
    // const product = await findProductByDesc(description)
    // const productId = product.id
    // const orderDetailData = {
    //   total: totalPurchase,
    //   order_id: orderId,
    //   product_id: productId
    // }
    // createOrderDetail(orderDetailData)
    const taskData = {
      user_id: userExist? userExist.id : newUser.id,
      task_id: task
      // order_detail_id: orderDetail.id
    }
    /* await createTask(taskData) */
    const taskUser = await findTaskByUser(userExist? userExist.id : newUser.id)
    const translationData = {
      userId: userExist? userExist.id : newUser.id,
      statustag: 1,
      taskUser: taskUser.id,
      task: task,
      pages: numberPages
    }
    const fileResponse: any = await uploadFilesPaypal(filesPaypal, () => { })
    await createTranslation(translationData)
    const translation = await findTranslation(taskUser.id)
    const translationDetailData = {
      taskId: task,
      fileId: fileResponse[0].id,
      translationId: translation.id
    }
    createTranslationDetail(translationDetailData)
    const translationResultData = {
      userId: userExist? userExist.id : newUser.id,
      taskId: task,
      translationId: translation.id
    }
    createTranslationResult(translationResultData)

    if (payment) {
      setPay(true)
    } 
  }

  const handleInputChange = (e: any) => {
    setCouponCode(e.target.value)
  }

  const handleSubmit1 = (event: any) => {
    event.preventDefault()
    const form = event.target
    const url = form.getAttribute('action')
    window.open(url, '_blank')
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', urlCheckoutPayu)
    form.setAttribute('target', '_blank')
    form.setAttribute('accept-charset', 'UTF-8')
    form.setAttribute('enctype', 'application/x-www-form-urlencoded')

    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'merchantId')
    input.setAttribute('value', merchantId)
    form.appendChild(input)

    const input2 = document.createElement('input')
    input2.setAttribute('type', 'hidden')
    input2.setAttribute('name', 'accountId')
    input2.setAttribute('value', accountId)
    form.appendChild(input2)

    const input3 = document.createElement('input')
    input3.setAttribute('type', 'hidden')
    input3.setAttribute('name', 'description')
    input3.setAttribute('value', description)

    form.appendChild(input3)

    const input4 = document.createElement('input')
    input4.setAttribute('type', 'hidden')
    input4.setAttribute('name', 'referenceCode')
    input4.setAttribute('value', referenceCode)
    form.appendChild(input4)

    const input5 = document.createElement('input')
    input5.setAttribute('type', 'hidden')
    input5.setAttribute('name', 'amount')
    input5.setAttribute('value', amount.toString())
    form.appendChild(input5)

    const input6 = document.createElement('input')
    input6.setAttribute('type', 'hidden')
    input6.setAttribute('name', 'tax')
    input6.setAttribute('value', '0')
    form.appendChild(input6)

    const input7 = document.createElement('input')
    input7.setAttribute('type', 'hidden')
    input7.setAttribute('name', 'taxReturnBase')
    input7.setAttribute('value', '0')
    form.appendChild(input7)

    const input8 = document.createElement('input')
    input8.setAttribute('type', 'hidden')
    input8.setAttribute('name', 'currency')
    input8.setAttribute('value', currency)
    form.appendChild(input8)

    const input9 = document.createElement('input')
    input9.setAttribute('type', 'hidden')
    input9.setAttribute('name', 'signature')
    input9.setAttribute('value', hash)
    form.appendChild(input9)

    const input10 = document.createElement('input')
    input10.setAttribute('type', 'hidden')
    input10.setAttribute('name', 'test')
    input10.setAttribute('value', testPayU)
    form.appendChild(input10)

    const input11 = document.createElement('input')
    input11.setAttribute('type', 'hidden')
    input11.setAttribute('name', 'buyerEmail')
    input11.setAttribute('value', buyerEmail)
    form.appendChild(input11)

    const input12 = document.createElement('input')
    input12.setAttribute('type', 'hidden')
    input12.setAttribute('name', 'responseUrl')
    input12.setAttribute('value', responseUrl)
    form.appendChild(input12)

    const input13 = document.createElement('input')
    input13.setAttribute('type', 'hidden')
    input13.setAttribute('name', 'confirmationUrl')
    input13.setAttribute('value', confirmationUrl)
    form.appendChild(input13)

    document.body.appendChild(form)
    form.submit()

    setSaving(true)
    document.body.removeChild(form)
    window.close()
  }

  return (
    <div>
      {/* Test https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/ */}
      {/* Prod https://checkout.payulatam.com/ppp-web-gateway-payu/ */}
      {/* <form method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu/" onSubmit={handleSubmit}></form> */}
      <form className='form-style-table' onSubmit={handleSubmit}>
        <section className=''>
        <div className='container-select-payment' style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}> <H3>Tipo de identificación</H3>

          <select
            className='custom-input-select-payment'
            onChange={handleChangeIdentificationType}
          >
            <option value=''>Seleccione</option>
            <option value='Registro civil'>Registro civil</option>
            <option value='Tarjeta de identidad'>Tarjeta de identidad</option>
            <option value='Tarjeta de extranjería'>Tarjeta de extranjería</option>
            <option value='Cédula de extranjería'>Cédula de extranjería</option>
            <option value='NIT'>NIT</option>
            <option value='Pasaporte'>Pasaporte</option>
            <option value='Documento de identificación extranjero'>
              Documento de identificación extranjero
            </option>
            <option value='NIT de otro país'>NIT de otro país</option>
            <option value='NUIP '>NUIP *</option>
            <option value='Cédula de ciudadanía'>Cédula de ciudadanía</option>
          </select>
</div>
          {/* <IdentificationTypes props={formik.getFieldProps('identificationType')} handleChange={handleChangeIdentificationType}/>

          {formik.touched.identificationType && formik.errors.identificationType && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.identificationType}</Alert>
              </span>
            </div>
          )} */}
        </section>
        <div className='container-form-payment'>
        <section className=''>
         <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}> <H3>Número de identificación</H3>
          <input
            className='custom-input-payment'
            onChange={handleChangeIdentification}
            placeholder='Número de identificación'
          /></div>

          {/* <Input
            type='text'
            placeholder='Número de identificación'
            {...formik.getFieldProps('identification')}
            className={clsx('', {
              'is-invalid': formik.touched.identification && formik.errors.identification
            })}
            onChange={()=>{console.log("hola")}}
          /> */}

          {formik.touched.identification && formik.errors.identification && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.identification}</Alert>
              </span>
            </div>
          )}
        </section>
        <section className=''>
        <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}>  <H3>Nombres</H3>
          <input
            className='custom-input-payment'
            onChange={handleChangeName}
            placeholder='Nombres'
          /> </div>
          {/* <Input
            type='text'
            placeholder='Nombres'
            {...formik.getFieldProps('name')}
            className={clsx('', {
              'is-invalid': formik.touched.name && formik.errors.name
            })}
            onChange={handleChangeName}
          />

          {formik.touched.name && formik.errors.name && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.name}</Alert>
              </span>
            </div>
          )} */}
        </section>
        <section className=''>
        <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}>  <H3>Apellidos</H3>
          <input
            className='custom-input-payment'
            onChange={handleChangeLastname}
            placeholder='Apellidos'
          /> </div>
          {/* <Input
            type='text'
            placeholder='Apellidos'
            {...formik.getFieldProps('lastname')}
            className={clsx('', {
              'is-invalid': formik.touched.lastname && formik.errors.lastname
            })}
            onChange={handleChangeLastname}
          />

          {formik.touched.lastname && formik.errors.lastname && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.lastname}</Alert>
              </span>
            </div>
          )} */}
        </section>
        <section className=''>
        <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}><H3>Teléfono</H3>

          <input
            className='custom-input-payment'
            onChange={handleChangePhone}
            placeholder='Teléfono'
          /></div>

          {/* <Input
            type='text'
            placeholder='Teléfono'
            {...formik.getFieldProps('phone')}
            className={clsx('', {
              'is-invalid': formik.touched.phone && formik.errors.phone
            })}
            onChange={handleChangePhone}
          />

          {formik.touched.phone && formik.errors.phone && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.phone}</Alert>
              </span>
            </div>
          )} */}
        </section>

        <section className=''>
        <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}> <H3>Email</H3>

          <input
            value={email}
            className='custom-input-payment'
            onChange={handleChangeEmail}
            placeholder='Email'
          /> </div>

          {/* <Input
            type='text'
            placeholder='Teléfono'
            {...formik.getFieldProps('phone')}
            className={clsx('', {
              'is-invalid': formik.touched.phone && formik.errors.phone
            })}
            onChange={handleChangePhone}
          />

          {formik.touched.phone && formik.errors.phone && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.phone}</Alert>
              </span>
            </div>
          )} */}
        </section>

        <section className=''>
        <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}> <H3>Confirmar email</H3>

          <input
            value={confirmEmail}
            className='custom-input-payment'
            onChange={handleChangeConfirmEmail}
            placeholder='Confirmar email'
          /> </div>

          {/* <Input
            type='text'
            placeholder='Teléfono'
            {...formik.getFieldProps('phone')}
            className={clsx('', {
              'is-invalid': formik.touched.phone && formik.errors.phone
            })}
            onChange={handleChangePhone}
          />

          {formik.touched.phone && formik.errors.phone && (
            <div className=''>
              <span role='alert' className=''>
                <Alert>{formik.errors.phone}</Alert>
              </span>
            </div>
          )} */}
        </section>

        <section className=''>
        <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}>  <H3>Cupón</H3>
          <input
            type='text'
            value={couponCode}
            onChange={handleInputChange}
            placeholder='Ingresa Cupón'
            className='custom-input-payment'
            style={{ borderRadius: '4px', width: '200px' }}
          /> </div>
        </section>
</div>
        <section className='section'>
          <Alert>
            {formik.status && (
              <div className=''>
                <div className=''>
                  <span role='alert' className=''>
                    <Alert>{formik.status}</Alert>
                  </span>
                </div>
              </div>
            )}
          </Alert>

          <Input name='source' type='hidden' value={getSource()} />
          <Input name='merchantId' type='hidden' value={merchantId} />
          <Input name='accountId' type='hidden' value={accountId} />
          <Input name='description' type='hidden' value={description} />
          <Input name='referenceCode' type='hidden' value={referenceCode} />
          <Input name='amount' type='hidden' value={amount} />
          <Input name='tax' type='hidden' value='0' />
          <Input name='taxReturnBase' type='hidden' value='0' />
          <Input name='currency' type='hidden' value={currency} />
          <Input name='signature' type='hidden' value={hash} />
          <Input name='test' type='hidden' value='false' />

          <Input name='buyerEmail' type='hidden' value={buyerEmail} />
          <Input name='responseUrl' type='hidden' value={responseUrl} />
          <Input name='confirmationUrl' type='hidden' value={confirmationUrl} />

          <div style={{ textAlign: 'left' }}>
            <label >
              <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
              <span className='ms-2' >
                He leído y acepto los términos y condiciones de esta página de la siguiente manera:
              </span>
            </label>
          </div>
          <div className='formControlStyle'>
            <div>
              <p style={{ textAlign: 'center' }}>
                <strong>INMILAND CANADA INC, Terms and Conditions Translations</strong>
              </p>
              <ol>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Modalidad de servicio:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que nuestros servicios de traducción son realizados por traductores
                humanos, cada uno con su estilo y enfoque personal en la traducción. Nos esforzamos
                por proporcionar traducciones precisas y fieles al original, pero ten en cuenta que
                el estilo de traducción puede variar ligeramente entre diferentes traductores.
              </p>
              <ol start={2}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Alcance de servicio </strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que este servicio es de traducción regular. Si requieres servicio especial
                para traducir mi licencia de conducir, NO debo comprar este servicio y debe
                devolverse al menú principal de productos y seleccionar el adecuado.&nbsp;
              </p>
              <ol start={3}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Política de cancelación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que al momento de la compra, si existe algún documento que compré pero que
                ya no necesito traducir, Immiland no realiza reembolsos, pero si podrá dar uso de
                esa página para reemplazarla por otra que sí requiera traducción. Immiland respeta
                su crédito para intercambiarlo por otra página por un lapso de tiempo de 2 meses
                posteriores a la compra.&nbsp;
              </p>
              <ol start={4}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Revisión y retroalimentación:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que una vez entregados los documentos traducidos, debo revisarlos
                cuidadosamente para asegurarse de que cumplan con mis expectativas. Si encuentro
                algún error o necesito realizar cambios, debo informar al traductor. Estaremos
                encantados de corregir cualquier error y adaptar la traducción a tus requerimientos
                específicos.
              </p>
              <ol start={5}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Tiempo de entrega:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nuestros tiempos de entrega estimados son de 1 a 5 días hábiles para servicios
                express y de 5 a 7 días hábiles para servicios normales. Sin embargo, ten en cuenta
                que el tiempo de entrega puede variar según la disponibilidad de los traductores y
                la complejidad de los documentos. Luego de la compra, podrás acceder a la plataforma
                de Immiland para chequear los tiempos. Te recomendamos contactarnos para obtener una
                estimación precisa en base a tus necesidades.
              </p>

              <ol start={6}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Entrega de documentación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Los documentos se entregan en copias digitales por la plataforma de Immiland al
                cliente. El servicio no incluye envíos de traducciones físicas.&nbsp;
              </p>
              <ol start={7}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Calidad del documento:</strong>
                  </p>
                </li>
              </ol>
              <p>
                La calidad del documento original enviado puede afectar la precisión y el estilo de
                nuestras traducciones. Te recomendamos proporcionar documentos claros, legibles y
                con una buena calidad de resolución para obtener mejores resultados. Además, si
                tienes preferencias específicas de estilo o terminología, por favor indícanos al
                realizar tu solicitud.
              </p>
              <ol start={8}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Confidencialidad:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Valoramos la confidencialidad y nos comprometemos a proteger la información que nos
                proporcionas durante el proceso de traducción. A continuación, se detallan nuestras
                políticas y prácticas relacionadas con la confidencialidad y la protección de la
                información:&nbsp;
              </p>
              <p>a. Privacidad y Seguridad:</p>
              <p>
                Tomamos medidas para garantizar la privacidad y seguridad de tus documentos y datos
                personales. Implementamos procedimientos físicos, electrónicos y administrativos
                para proteger la información contra accesos no autorizados, divulgación o
                alteración.
              </p>
              <p>b. Uso Limitado de la Información:</p>
              <p>
                Utilizamos la información proporcionada exclusivamente con el propósito de llevar a
                cabo los servicios de traducción solicitados. No compartiremos, venderemos ni
                alquilaremos tu información personal a terceros sin tu consentimiento explícito, a
                menos que estemos legalmente obligados a hacerlo.
              </p>
              <p>c. Acuerdos de Confidencialidad:</p>
              <p>
                Nuestros traductores y miembros del equipo están sujetos a acuerdos de
                confidencialidad que salvaguardan la información que manejan durante el proceso de
                traducción. Estos acuerdos garantizan que los traductores mantengan la
                confidencialidad de los documentos y la información proporcionada por los clientes.
              </p>
              <p>d. Eliminación Segura de Datos:</p>
              <p>
                Una vez que se ha completado el proceso de traducción y entregado el trabajo final
                al cliente, eliminaremos de manera segura todos los documentos y datos relacionados
                con la traducción, a menos que se haya acordado lo contrario con el cliente.
              </p>
              <p>e. Comunicación Segura:</p>
              <p>
                Utilizamos métodos seguros de comunicación para el intercambio de documentos y
                cualquier otra información relacionada con la traducción. Estos métodos pueden
                incluir el cifrado de archivos y el uso de sistemas seguros de gestión de
                documentos.
              </p>
              <p>f. Responsabilidad Compartida:</p>
              <p>
                Si bien nos esforzamos por proteger la información confidencial, es importante
                destacar que la seguridad en línea y la confidencialidad de los datos son
                responsabilidades compartidas. Te recomendamos tomar precauciones adicionales al
                compartir información confidencial a través de Internet y mantener actualizados tus
                sistemas de seguridad informática.
              </p>
              <p>g. Cumplimiento Legal:</p>
              <p>
                Cumplimos con todas las leyes y regulaciones aplicables en relación con la
                confidencialidad y la protección de datos. En el caso de cualquier cambio en las
                leyes o regulaciones pertinentes, ajustaremos nuestras prácticas para garantizar el
                cumplimiento continuo.
              </p>
              <p>
                Ten en cuenta que este descargo de responsabilidad proporciona una visión general de
                nuestras políticas y prácticas de confidencialidad. Para obtener información más
                detallada sobre cómo manejamos y protegemos la información confidencial, te
                recomendamos revisar nuestra política de privacidad y solicitar cualquier aclaración
                adicional que puedas necesitar.
              </p>
              <ol start={9}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Derechos de autor:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Al utilizar nuestros servicios de traducción, confirmas que tienes los derechos
                necesarios sobre los documentos a traducir y que no infringes los derechos de autor
                de terceros. No nos responsabilizamos por reclamaciones legales relacionadas con los
                derechos de autor de los documentos proporcionados.
              </p>
              <ol start={10}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Modificaciones en los términos:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nos reservamos el derecho de realizar modificaciones en estos términos y condiciones
                en cualquier momento sin previo aviso. Los cambios entrarán en vigor a partir de su
                publicación en nuestro sitio web.
              </p>
              <p>
                <strong>
                  Descargo de responsabilidad para servicios de traducción licencia conducir
                  (Disclaimer)
                </strong>
              </p>
              <ol start={11}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Modalidad de servicio:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que nuestros servicios de traducción son realizados por traductores
                humanos, cada uno con su estilo y enfoque personal en la traducción. Nos esforzamos
                por proporcionar traducciones precisas y fieles al original, pero ten en cuenta que
                el estilo de traducción puede variar ligeramente entre diferentes traductores.
              </p>
              <ol start={12}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Alcance de servicio </strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que este servicio NO es de traducción regular y solo aplica para licencias
                de conducir.&nbsp;
              </p>

              <ol start={13}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Política de cancelación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que al momento de la compra, si existe algún documento que compré pero que
                ya no necesito traducir, Immiland no realiza reembolsos, pero si podrá dar uso de
                esa página para reemplazarla por otra que si requiera traducción. Immiland respeta
                su crédito para intercambiarlo por otra página por un lapso de tiempo de 2 meses
                posteriores a la compra.
              </p>
              <br />
              <ol start={14}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Revisión y retroalimentación:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que una vez entregados los documentos traducidos, debo revisarlos
                cuidadosamente para asegurarse de que cumplan con mis expectativas. Si encuentro
                algún error o necesito realizar cambios, debo informar al traductor. Estaremos
                encantados de corregir cualquier error y adaptar la traducción a tus requerimientos
                específicos.
              </p>
              <br />
              <ol start={15}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Tiempo de entrega:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nuestros tiempos de entrega estimados son de 1 a 5 días hábiles para servicios
                express y de 5 a 7 días hábiles para servicios normales. Sin embargo, ten en cuenta
                que el tiempo de entrega puede variar según la disponibilidad de los traductores y
                la complejidad de los documentos. Luego de la compra, podrás acceder a la plataforma
                de Immiland para chequear los tiempos. Te recomendamos contactarnos para obtener una
                estimación precisa en base a tus necesidades.
              </p>
              <br />
              <ol start={16}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Entrega de documentación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Los documentos se entregan en copias digitales por la plataforma de Immiland al
                cliente. El servicio no incluye envíos de traducciones físicas.
              </p>
              <br />
              <ol start={17}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Calidad del documento:</strong>
                  </p>
                </li>
              </ol>
              <p>
                La calidad del documento original enviado puede afectar la precisión y el estilo de
                nuestras traducciones. Te recomendamos proporcionar documentos claros, legibles y
                con una buena calidad de resolución para obtener mejores resultados. Además, si
                tienes preferencias específicas de estilo o terminología, por favor indícanos al
                realizar tu solicitud.
              </p>
              <br />
              <ol start={18}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Confidencialidad:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Valoramos la confidencialidad y nos comprometemos a proteger la información que nos
                proporcionas durante el proceso de traducción. A continuación, se detallan nuestras
                políticas y prácticas relacionadas con la confidencialidad y la protección de la
                información.
              </p>
              <br />
              <p>a. Privacidad y Seguridad:</p>
              <p>
                Tomamos medidas para garantizar la privacidad y seguridad de tus documentos y datos
                personales. Implementamos procedimientos físicos, electrónicos y administrativos
                para proteger la información contra accesos no autorizados, divulgación o
                alteración.
              </p>
              <br />
              <p>b. Uso Limitado de la Información:</p>
              <p>
                Utilizamos la información proporcionada exclusivamente con el propósito de llevar a
                cabo los servicios de traducción solicitados. No compartiremos, venderemos ni
                alquilaremos tu información personal a terceros sin tu consentimiento explícito, a
                menos que estemos legalmente obligados a hacerlo.
              </p>
              <br />
              <p>c. Acuerdos de Confidencialidad:</p>
              <p>
                Nuestros traductores y miembros del equipo están sujetos a acuerdos de
                confidencialidad que salvaguardan la información que manejan durante el proceso de
                traducción. Estos acuerdos garantizan que los traductores mantengan la
                confidencialidad de los documentos y la información proporcionada por los clientes.
              </p>
              <br />
              <p>d. Eliminación Segura de Datos:</p>
              <p>
                Una vez que se ha completado el proceso de traducción y entregado el trabajo final
                al cliente, eliminaremos de manera segura todos los documentos y datos relacionados
                con la traducción, a menos que se haya acordado lo contrario con el cliente.
              </p>
              <br />
              <p>e. Comunicación Segura:</p>
              <p>
                Utilizamos métodos seguros de comunicación para el intercambio de documentos y
                cualquier otra información relacionada con la traducción. Estos métodos pueden
                incluir el cifrado de archivos y el uso de sistemas seguros de gestión de
                documentos.
              </p>
              <br />
              <p>f. Responsabilidad Compartida:</p>
              <p>
                Si bien nos esforzamos por proteger la información confidencial, es importante
                destacar que la seguridad en línea y la confidencialidad de los datos son
                responsabilidades compartidas. Te recomendamos tomar precauciones adicionales al
                compartir información confidencial a través de Internet y mantener actualizados tus
                sistemas de seguridad informática.
              </p>
              <br />
              <p>g. Cumplimiento Legal:</p>
              <p>
                Cumplimos con todas las leyes y regulaciones aplicables en relación con la
                confidencialidad y la protección de datos. En el caso de cualquier cambio en las
                leyes o regulaciones pertinentes, ajustaremos nuestras prácticas para garantizar el
                cumplimiento continuo.
              </p>
              <br />
              <p>
                Ten en cuenta que este descargo de responsabilidad proporciona una visión general de
                nuestras políticas y prácticas de confidencialidad. Para obtener información más
                detallada sobre cómo manejamos y protegemos la información confidencial, te
                recomendamos revisar nuestra política de privacidad y solicitar cualquier aclaración
                adicional que puedas necesitar.
              </p>
              <br />
              <ol start={19}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Derechos de autor:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Al utilizar nuestros servicios de traducción, confirmas que tienes los derechos
                necesarios sobre los documentos a traducir y que no infringes los derechos de autor
                de terceros. No nos responsabilizamos por reclamaciones legales relacionadas con los
                derechos de autor de los documentos proporcionados.
              </p>
              <br />
              <ol start={20}>
                <li style={{ fontWeight: 'bold' }}>
                  <p>
                    <strong>Modificaciones en los términos:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nos reservamos el derecho de realizar modificaciones en estos términos y condiciones
                en cualquier momento sin previo aviso. Los cambios entrarán en vigor a partir de su
                publicación en nuestro sitio web.
              </p>
              <br />
              <br />
            </div>
          </div>

          {isChecked ? (
            <div>
              {pay ?  emailMatch ? (
                <Button type='submit'>Generar pago</Button>
              ) :  <Button type='submit' disabled={true}>Generar pago</Button>
              :(
                <div
                  className='flex justify-center items-center rounded-lg content-center text-sm pl-2
                pr-2 text-white text-center hover:bg-tomato-400 focus:outline-none w-full h-8  bg-tomato-500'
                  onClick={generatePayment}
                  style={{
                    transition: 'background-color 0.1s ease',
                    cursor: 'pointer', 
                  }}
                >
                  {' '}
                  Generar Factura{' '}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </section>
      </form>
      {!emailMatch && <p style={{ color: 'red' }}>Los correos electrónicos no coinciden.</p>}
    </div>
  )
}

export default observer(Payu)
