export const common = {
  'COMMON.NAME': 'Name',
  'COMMON.ACTION': 'Action',
  'COMMON.ADD': 'Add',
  'COMMON.BACK': 'Back',
  'COMMON.SEARCH': 'Search',
  'COMMON.REMOVE': 'Remove',
  'COMMON.SAVE': 'Save',
  'COMMON.CANCEL': 'Cancel',
  'COMMON.EDIT': 'Edit',
  'COMMON.DELETE': 'Delete',
  'COMMON.CLOSE': 'Close',
  'COMMON.SELECT': 'Select',
  'COMMON.DATETIME': 'Date Time',
  'COMMON.URLMEETING': 'Url Meeting',
  'COMMON.BTNSAVEUSER': 'Save User',
}
