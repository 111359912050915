/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
 
import React, { useContext, useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import {
  getProfileImmigration,
  updateProfileBasic,
  updateProfileNew,
} from '../../../../../../app/modules/apps/profile/core/_requests'

import { Container } from '../../../../../../components/Container/index'
import {
  ProfileImmigration,
  type Profile,
} from '../../../../../../app/modules/apps/profile/core/_models'
import ProfileDetails from './ProfileDetails'
import { RootStoreContext } from '../../../../../../stores/rootStore'
import Swal from 'sweetalert2'
import { type UserModel } from '../../../../../../auth/models/User/UserModel'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import '../../../../../../index.css'
import { IComposeData } from 'app/modules/apps/appointments/core/_models'
import './style.css'

import InmiDetailsChop1 from './surveychop1'
import InmiDetailsChop2 from './surveychop2'
import ProfileDetailsModal from './profileDetailsModal'
import InmiDetailsChop1Modal from './surveychop1Modal'
import InmiDetailsChop2Modal from './surveychop2Modal'
import { Navigate, Route } from 'react-router-dom'
interface Props {
  profile: Profile
  isUserLoading?: boolean
  ProfileImmigration: ProfileImmigration
  composepostdata: IComposeData
  setRefetchActivator: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfileMain: React.FC<Props> = ({
  profile,
  isUserLoading,
  ProfileImmigration,
  setRefetchActivator,
}) => {
  const intl = useIntl()
   
  const [profileImmigration, setProfileImmigration] = useState(false)
  const [tabActive, setTabActive] = useState<string>('profile')
   
  const [showProfileImmigration, setShowProfileImmigration] = useState(false)
  const { data, status, refetch } = useQuery('profileimmigration', getProfileImmigration)
  const [refetchImmiDetails, setRefetchImmiDetails] = useState(false)
  const [firstInfoObject, setFirstInfoObject] = useState<any>()
  const rootStore = useContext(RootStoreContext)
  const { user, fulfillUser } = rootStore.authStore
  const [completionStatus, setCompletionStatus] = useState<boolean>(false);
  useEffect(() => {
    if (status === 'success') {
      setProfileImmigration(true)
    }
  }, [status])

  useEffect(() => {
    if (refetchImmiDetails) {
      refetch()
    }
    setRefetchImmiDetails(false)
  }, [refetchImmiDetails])

  useEffect(() => {
    if (user?.role?.name === 'User') {
      setShowProfileImmigration(true)
    } else if (user?.role?.name === 'Admin') {
      setShowProfileImmigration(false)
    }
  }, [user])
  useEffect(() => {
  }, [data]);

  useEffect(() => {
  }, [profile]);

  const hasAnsweredQuestions = (profileData: any) => {
    // Verificar si alguno de los campos dentro de profileData está lleno
    for (const key in profileData) {
      if (profileData.hasOwnProperty(key)) {
        if (profileData[key] !== null && profileData[key] !== '') {
          return true; // Si encuentra algún campo lleno, retornar true
        }
      }
    }
    return false; // Si no se encuentra ningún campo lleno, retornar false
  };
  const hasAnsweredAllQuestions = (profileData: any) => {
    // Verificar si todas las preguntas relevantes están respondidas
    return profileData && profileData.emigrar_con_familia;
  };


  const showModal =
    user?.role?.name === 'User' &&
    !hasAnsweredAllQuestions(data?.profile);


  const tabChangedHandler = (key: any) => {
    if (key === 'profile') setTabActive('profile')
    if (key === 'homenew') setTabActive('homenew')
    if (key === 'homenew1') setTabActive('homenew1')
    if (key === 'homenew2') setTabActive('homenew2')
  }

  const handlePpdateProfileBasic = async (profileBasic: any) => {
    const result = await updateProfileBasic(profileBasic)
    if (result != null) {
      fulfillUser(result as UserModel)
      /*  Swal.fire({
         title: `Indicó que su zona horaria es ${profileBasic.timeZone}, si ${profileBasic.timeZone} no corresponde a su zona horaria por favor modifíquela en la información básica. `,
         icon: 'warning',
         showConfirmButton: true,
       }).then(() => { */
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })

      setRefetchActivator(true)
    } else {
      Swal.fire({
        title: 'error',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  if (!showModal) {
    return (
      <Container title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLE' })}>

        <Tabs
          activeKey={tabActive}
          id="controlled-tab-example"
          className="mb-3"
          onSelect={tabChangedHandler}
          style={{ fontSize: '0.75rem' }}
        >
          <Tab eventKey="profile" title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLEBASIC' })}>
            <ProfileDetails
              setTabActive={setTabActive}
              profile={profile}
              isUserLoading={isUserLoading}
              updateProfileBasic={handlePpdateProfileBasic}
              showAvatar={true}
            />
          </Tab>
          <Tab eventKey="homenew1" title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLEIMMIGRATIONNEW' })}>
            {status === 'success' && (
              <InmiDetailsChop1
                setRefetchActivator={setRefetchImmiDetails}
                data={data ?? {}}
                setTabActive={setTabActive}
                ProfileImmigration={ProfileImmigration}
                isUserLoading={isUserLoading}
                updateProfile={updateProfileNew}
                showAvatar={true}
                userId={user?.id}
                setFirstInfoObject={setFirstInfoObject}
                firstInfoObject={firstInfoObject}
                setCompletionStatus={setCompletionStatus}
              />
            )}
          </Tab>
          <Tab eventKey="homenew2" title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLEIMMIGRATIONNEW2' })} disabled={!completionStatus}>
            {status === 'success' && (
              <InmiDetailsChop2
                setRefetchActivator={setRefetchImmiDetails}
                data={data ?? {}}
                setTabActive={setTabActive}
                ProfileImmigration={ProfileImmigration}
                isUserLoading={isUserLoading}
                updateProfile={updateProfileNew}
                showAvatar={true}
                userId={user?.id}
                setFirstInfoObject={setFirstInfoObject}
                firstInfoObject={firstInfoObject}
              />
            )}
          </Tab>
        </Tabs>
      </Container>
    );
  }

  return (
    <Modal show={true} style={{ display: 'flex',  maxWidth:'100%' }}>
      <Modal.Body style={{  height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth:'100%' }}>
        <Tabs
          activeKey={tabActive}
          id="controlled-tab-example"
          className="mb-3"
          onSelect={tabChangedHandler}
          style={{ display: 'none' }}
        >
          <Tab eventKey="profile"
          style={{width:'100%', maxWidth:'100%'}}
          title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLEBASIC' })}>
            <ProfileDetailsModal
              setTabActive={setTabActive}
              profile={profile}
              isUserLoading={isUserLoading}
              updateProfileBasic={handlePpdateProfileBasic}
              showAvatar={true}
            />
          </Tab>
          <Tab eventKey="homenew1"
          style={{width:'100%', maxWidth:'100%'}}
          title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLEIMMIGRATIONNEW' })}>
            {status === 'success' && (
              <InmiDetailsChop1Modal
                setRefetchActivator={setRefetchImmiDetails}
                data={data ?? {}}
                setTabActive={setTabActive}
                ProfileImmigration={ProfileImmigration}
                isUserLoading={isUserLoading}
                updateProfile={updateProfileNew}
                showAvatar={true}
                userId={user?.id}
                setFirstInfoObject={setFirstInfoObject}
                firstInfoObject={firstInfoObject}
                setCompletionStatus={setCompletionStatus}
              />
            )}
          </Tab>
          <Tab eventKey="homenew2"
          style={{width:'100%', maxWidth:'100%'}}
          title={intl.formatMessage({ id: 'PROFILE.PROFILETITTLEIMMIGRATIONNEW2' })}
          disabled={!completionStatus}>
            {status === 'success' && (
              <InmiDetailsChop2Modal
                setRefetchActivator={setRefetchImmiDetails}
                data={data ?? {}}
                setTabActive={setTabActive}
                ProfileImmigration={ProfileImmigration}
                isUserLoading={isUserLoading}
                updateProfile={updateProfileNew}
                showAvatar={true}
                userId={user?.id}
                setFirstInfoObject={setFirstInfoObject}
                firstInfoObject={firstInfoObject}
              />
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export { ProfileMain };
