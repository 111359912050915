import React, {type ReactNode} from 'react'
import TopBar from '../TopBar'
interface ContentProps {
  children: ReactNode
}
const CartContent: React.FC<ContentProps> = ({children}) => {
  return (
    <main className='s-layout__content'>
      <TopBar />
      <div className='main-windows'>{children}</div>
    </main>
  )
}

export default CartContent
