import { RootStoreContext } from 'stores/rootStore'
import {Container} from '../../../components/Container/index'
import ListDocuments from './ListDocuments'
import React, { useContext } from 'react'
import {useIntl} from 'react-intl'

const LibraryWrapper = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  return <Container title={intl.formatMessage({id: 'MENU.LIBRARY'})}>{<ListDocuments user={user} />}</Container>
}

export default LibraryWrapper
