/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-empty-function */

import { useContext, useEffect, useState } from "react"
import Clock from '../../../contratationApp/icons/clock-alt-1.svg'
import ChevronRightIcon from '../../../contratationApp/icons/chevron-right.svg'
import Swal from 'sweetalert2'
import { uploadFileIntakeLetters } from "app/modules/apps/file/core/_requests"
import { Users } from "app/modules/apps/users/core/_models"
import { createGoogleDocument, getContractByUserId, getGoogleAuthUrl, publishGoogleDocument } from "app/modules/apps/contract/_requests"
import { useQuery } from "react-query"
import { getIntakeDocumentsbyContractId } from "app/modules/apps/userDocument/_requests"
import { RootStoreContext } from "stores/rootStore"
import { downloadFileLibrary } from "app/modules/apps/library/core/_requests"
import DocumentView from "./DocumentView"
import PhotoView from "./PhotoView"
import { sendEmailApprovalOfLetters } from "app/modules/apps/users/core/_requests"

interface IProps {
  currentUser: Users | null
  email: string
  id: string
  contractsFound: any
}

const LetterManager: React.FC<IProps> = ({ contractsFound, currentUser }) => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [isHovered, setIsHovered] = useState(false)
  const [letterId, setLetterId] = useState('')
  const [active, setActive] = useState(false)
  const [files, setFiles] = useState<any>(null)
  const [aplicants, setAplicants] = useState<any[]>([])
  const [memberName, setMemberName] = useState("")
  const [memberType, setMemberType] = useState("")
  const [lookDocument, setLookDocument] = useState<any>()
  const [lookImage, setLookImage] = useState<any>()
  const [embedLink, setEmbedLink] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const authUrl = await getGoogleAuthUrl();
        setIsAuthenticated(Boolean(authUrl)); // Verifica si hay una URL de autenticación válida
      } catch (error) {
        console.error("Error verificando autenticación:", error);
      }
    };

    checkAuthentication();
  }, []);

  const redirectToGoogleAuth = async () => {
    try {
      const authUrl = await getGoogleAuthUrl();
      window.location.href = authUrl; // Redirige a la página de autenticación de Google
    } catch (error) {
      console.error("Error obteniendo la URL de autenticación de Google:", error);
    }
  };

  const handleCreateDocument = async () => {
    if (!isAuthenticated) {
      // Redirige si no está autenticado
      redirectToGoogleAuth();
      return;
    }

    if (!memberName || !memberType) {
      Swal.fire({
        title: "Debe seleccionar un aplicante primero",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    }

    // Selector de plantillas
    const { value: templateType } = await Swal.fire({
      title: "Seleccione la plantilla",
      input: "radio",
      inputOptions: {
        acceptance: "Carta de Aceptación",
        normal: "Carta Normal",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Debe seleccionar una plantilla!";
        }
      },
    });

    if (templateType) {
      const templateId =
        templateType === "acceptance"
          ? "1ztoUxLlYCtcksasgwHQ6UB0vmAFB8rAO" // ID de la plantilla de aceptación
          : "1twfqWIMVwlz8TlPpa7EnYJPi0Pg-O4b2"; // ID de la plantilla normal

      try {
        // Crear el documento con los datos del cliente
        const documentId = await createGoogleDocument(templateId, {
          name: memberName,
          type: memberType,
        });

        Swal.fire({
          title: "Documento creado exitosamente!",
          icon: "success",
          showConfirmButton: true,
        });

        // Publicar el documento y obtener el enlace embed
        const embedLink = await publishGoogleDocument(documentId);
        setEmbedLink(embedLink);

      } catch (error) {
        Swal.fire({
          title: "Error creando el documento",
          icon: "error",
          showConfirmButton: true,
        });
      }
    }
  };

  /* const handlePublishDocument = async () => {
    if (embedLink) {
      try {
        Swal.fire({
          title: "Guardando documento...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        // Aquí guardamos el documento publicado
        Swal.fire({
          title: "Documento publicado exitosamente!",
          icon: "success",
          showConfirmButton: true,
        });

      } catch (error) {
        Swal.fire({
          title: "Error publicando el documento",
          icon: "error",
          showConfirmButton: true,
        });
      }
    }
  }; */
  // Función para mostrar el Google Docs embed
  const renderGoogleDocsEmbed = () => {
    if (embedLink) {
      return (
        <div className="google-docs-embed-container">
          <iframe
            src={embedLink}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      );
    }
    return null;
  };


  const {
    data: dataFromContractTable,
    status: statusDatabase,
  } = useQuery('contractFromUserIdActiveqq', async () => await getContractByUserId(currentUser?.id!), {
    retry: false,
    cacheTime: 0,
  })

  const {
    data: dataDocumentsbyContractId,
    status: statusDocumentsbyContractI,
    refetch: refetchDocumentsbyContractI,
  } = useQuery('DocumentsbyContractI', async () => await getIntakeDocumentsbyContractId(contractsFound), {
    retry: false,
    cacheTime: 0,
  })

  const handleUpload = async (
    documentId: string,
    productId: string,
    memberName: string,
    memberType: string
  ) => {
    const result = await uploadFileIntakeLetters(
      files,
      () => { },
      "intakeletters",
      documentId,
      currentUser?.id!,
      contractsFound,
      productId,
      memberName,
      memberType,
      user?.id!
    )

    if (result) {
      if (currentUser?.email) {
        await sendEmailApprovalOfLetters(currentUser.email)
      } else {
        Swal.fire({
          title: 'Error: El correo del usuario no está disponible.',
          icon: 'error',
          showConfirmButton: true,
        })
      }
      setTimeout(() => {
        setFiles(null)
        refetchDocumentsbyContractI()
      }, 500)
    }
  }

  const convertDocument = async (id: string) => {
    const file = await downloadFileLibrary(id, () => { })
    setLookDocument(file)
  }

  const convertImage = async (document: any) => {
    const file = await downloadFileLibrary(document, () => { })
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      }
      else {
        console.error('Error reading the file.')
      }
    }
    fileReader.onerror = () => { }
    fileReader.readAsArrayBuffer(file)
  }

  useEffect(() => {
    if (statusDocumentsbyContractI === 'success') {
      console.log('done')
    }
  }, [dataDocumentsbyContractId, statusDocumentsbyContractI])

  useEffect(() => {
    if (statusDatabase === 'success') {
      dataFromContractTable.data.map((contract: any) => {
        if (contract.envelope_id === contractsFound) {
          const aplicants = JSON.parse(contract.applicants)
          setAplicants(aplicants)
        }
      })
    }
  }, [contractsFound, dataFromContractTable, statusDatabase])

  return (
    <div style={{
      padding: '0.2rem',
      display: 'flex',
      width: '100%',
      flexDirection: 'column'
    }}
      className="manager-carta">
      <div className='loader-carta' >
        <strong className='caseWorker-title'>Cargue la carta redactada para revisión del cliente</strong>

        <button
        className='button-ui button-primary button-s'
        onClick={handleCreateDocument}>
        Crear Documento
      </button>

      {renderGoogleDocsEmbed()}

     {/*  {embedLink && (
        <button
          className='button-ui button-primary button-s'
          onClick={handlePublishDocument}>
          Guardar Documento
        </button>
      )} */}
        <div className='inputs-container-loader'>
          <div>
            <p style={{ display: 'flex', margin: '0' }}>
              <b>Tipo de carta</b>
            </p>
            <select
              name='department'
              id='department'
              className='option-styles-intake'
              onChange={(e: any) => {
                if (e.target.value === 'Carta de motivos') {
                  setLetterId('ee6487ab-2f20-41a0-8dc5-7bbf119f6faa')
                } else if (e.target.value === 'Submision Letter') {
                  setLetterId('e7ec501a-a9ea-44dd-93ac-0203c8aa88f7')
                }
              }}>
              <option value=''>Seleccione</option>
              <option value='Carta de motivos'>Carta de motivos</option>
              <option value='Submision Letter'>Submision Letter</option>
            </select>
            {active === true && <label style={{ color: 'red', fontSize:'10px' }}>Debe Seleccionar una carta</label>}
          </div>
          <div>
            <p style={{ margin: '0' }}>Nombre solicitante</p>
            <select
              style={{ width: '320px' }}
              className='option-styles'
              onChange={(e) => {
                const selectedValue = e.target.value
                if (selectedValue) {
                  const { memberName, memberType } = JSON.parse(selectedValue)
                  setMemberName(memberName)
                  setMemberType(memberType)
                }}}>
              <option
                style={{ width: 'auto' }}
                value="">Seleccione</option>
              {(() => {
                const seenNames = new Set()
                return aplicants.map((applicant: any) => {
                  if (seenNames.has(applicant.memberName)) {
                    return null
                  }
                  seenNames.add(applicant.memberName)
                  return (
                    <option
                      key={applicant.memberName}
                      style={{ width: 'auto' }}
                      value={JSON.stringify({ memberName: applicant.memberName, memberType: applicant.memberType })}
                    >
                      {applicant.memberName} ({applicant.memberType})
                    </option>
                  )
                })})()}
            </select>
            {active === true && <label style={{ color: 'red', fontSize:'10px' }}>Debe Seleccionar una aplicante</label>}
          </div>
          <div className='flex flex-col items-start'>
            <div>
              <p style={{ display: 'flex', margin: '0' }}>
                Subir documento
              </p>
              <input
                className='option-styles-intake'
                type='file'
                accept='.pdf,.jpeg,.jpg,.png'
                max={1}
                onChange={(event: any) => {
                  const file = event.target.files[0]
                  if (file) {
                    const allowedExtensions = [
                      'pdf',
                      'jpeg',
                      'jpg',
                      'png',
                    ]
                    const fileExtension = file.name
                      .split('.')
                      .pop()
                      ?.toLowerCase()
                    if (
                      fileExtension &&
                      !allowedExtensions.includes(
                        fileExtension
                      )
                    ) {
                      if (file) {
                        const allowedExtensions = [
                          'pdf',
                          'jpeg',
                          'jpg',
                          'png',
                        ]
                        const fileExtension = file.name
                          .split('.')
                          .pop()
                          ?.toLowerCase()
                        if (
                          fileExtension &&
                          !allowedExtensions.includes(
                            fileExtension
                          )
                        ) {
                          Swal.fire({
                            title:
                              'error solo archivos .pdf, .jpeg, .jpg, .png son aceptados',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                          })
                          event.target.value = null
                          return
                        }
                      }
                    }
                  }
                  setFiles(file)
                  setActive(false)
                }}/>
            </div>
            <p >Archivos JPG, PNG, PDF.</p>
          </div>
          <div style={{ marginTop: '20px' }}>
            <button
              className='button-ui button-primary button-s'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => {
                if (letterId !== '' && memberName !== '') {
                  handleUpload(letterId, "b0e0313b-6344-4733-90a0-6d6031f8be1e", memberName, memberType,)
                  setActive(false)
                } else {
                  setActive(true)
                }
              }}>
              <b style={{ color: 'white' }} className='meet-text2'>Enviar</b>
              <img
                alt="Calendar"
                className='meet-image2'
                src={isHovered ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-white.svg'}
              />
            </button>
          </div>
        </div>
      </div>
      <div
        style={{ paddingBottom: '0.5rem', marginBottom: '1rem', cursor: 'pointer' }}
        className='flex gap-4 mt-2 items-center justify-between w-full'>
        <div style={{ width: '100%' }}
          className='flex gap-2 items-center'>
          <div className="flex flex-col items-start">
            <div style={{ width: '100%' }} className='flex gap-4 items-center'>
              <p style={{ fontSize: '18px', margin: '0' }}>
                <b>Nombre del miembro</b> (Tipo de miembro)
              </p>
            </div>
            <b style={{ fontSize: '12px' }}>product name </b>
          </div>
        </div>
        <div>
          <img
            className='show-btn'
            src={ChevronRightIcon}
            alt='ChevronRight'/>
        </div>
      </div>
      {dataDocumentsbyContractId && dataDocumentsbyContractId.map((letter: any) =>
        <>
          {letter.status === 'Rechazado' &&
            <div className='uploader-documents-item mt-8' style={{ border: '1px solid #F23030', listStyle: 'none' }}>
              <div className='uploader-documents-item-maindiv '>
                <div className='flex flex-col w-full'>
                  <div className='flex gap-4'>
                    <div className='flex items-center gap-4 justify-between w-full'>
                      <div className='flex flex-col'>
                        <p className='uploader-documents-title'>
                          {letter.document.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-4 w-full'>
                  <div className="rejected-doc" style={{ width: '100%' }}>
                    <div className="flex flex-row items-center gap-4">
                      <div className="flex flex-col">
                        <p style={{
                          color: '#BC1C21',
                          fontSize: '16px',
                          margin: '0',
                          alignSelf: 'flex-start'
                        }}>Comentario:</p>
                        <p style={{ color: '#BC1C21', fontSize: '14px' }}>{letter.comments}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div style={{ flex: '1' }} className='flex flex-col items-start'>
                    <div >
                      <p style={{ display: 'flex', margin: '0' }}>
                        Subir documento
                      </p>
                      <input
                        className='option-styles-intake'
                        type='file'
                        accept='.pdf,.jpeg,.jpg,.png'
                        max={1}
                        onChange={(event: any) => {
                          const file = event.target.files[0]
                          if (file) {
                            const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png']
                            const fileExtension = file.name
                              .split('.')
                              .pop()
                              ?.toLowerCase()
                            if (
                              fileExtension &&
                              !allowedExtensions.includes(
                                fileExtension
                              )
                            ) {
                              if (file) {
                                const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png']
                                const fileExtension = file.name
                                  .split('.')
                                  .pop()
                                  ?.toLowerCase()
                                if (
                                  fileExtension &&
                                  !allowedExtensions.includes(
                                    fileExtension
                                  )
                                ) {
                                  Swal.fire({
                                    title:
                                      'error solo archivos .pdf, .jpeg, .jpg, .png son aceptados',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1500,
                                  })
                                  event.target.value = null
                                  return
                                }
                              }
                            }
                          }
                          setFiles(file)
                        }}/>
                    </div>
                    <p >Archivos JPG, PNG, PDF.</p>
                  </div>
                  <button
                    className='button-ui button-primary button-s'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      marginTop: '20px',
                    }}
                    onClick={() => {
                      handleUpload(letter.document.id, letter.product.id, letter.memberName, letter.memberType)
                    }}>
                    <b style={{ color: 'white' }} className='meet-text2'>Enviar</b>
                    <img
                      alt="Calendar"
                      className='meet-image2'
                      src={isHovered ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-white.svg'}
                    />
                  </button>
                </div>
              </div>
            </div>}

          {letter.status === 'Aprovado' &&
            <div className='step2-aplicant-card mt-8'>
              <div className='flex justify-between w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className='flex items-center justify-between gap-4 w-full'>
                  <div className='flex flex-col'>
                    <p className='uploader-documents-title'>
                      {letter.document.name}
                    </p>
                  </div>
                  <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                    <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Aprobado</p>
                  </div>
                </div>
                {letter.file.mimetype === 'application/pdf' ? (
                  <div
                    onClick={() => {
                      convertDocument(letter.file.id as string)
                    }}
                    className='view-documents-uploaded'>
                    <img src="" alt="" />
                    <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{letter.file.name}</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      convertImage(letter.file.id as string)
                    }}
                    className='view-documents-uploaded'>
                    <img src="" alt="" />
                    <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{letter.file.name}</p>
                  </div>
                )}
              </div>
            </div>}
          {letter.status === 'Pendiente por revisión' &&
            <div className='step2-aplicant-card mt-8'>
              <div className='flex justify-between w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className='flex items-center justify-between gap-4 w-full'>
                  <div>
                    <p className='uploader-documents-title'>
                      {letter.document.name}
                    </p>
                  </div>
                  <div className='step-badge-active-draft2' style={{ width: 'auto', height: '1.5rem', border: '1px solid #8899A8' }}>
                    <img style={{ width: '16px', height: '16px' }} src={Clock} alt="Clock" />
                    <p className='step-badge-active-draft-p-ones hide-tex' style={{ fontSize: '12px', margin: '0' }}>Pendiente por revisión</p>
                    <p className='step-badge-active-draft-p-ones hide-tex2' style={{ fontSize: '12px' }}>Pendiente por revisión</p>
                  </div>
                </div>
                {letter.file.mimetype === 'application/pdf' ? (
                  <div
                    onClick={() => {
                      convertDocument(letter.file.id as string)
                    }}
                    className='view-documents-uploaded'>
                    <img src="" alt="" />
                    <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{letter.file.name}</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      convertImage(letter.file.id as string)
                    }}
                    className='view-documents-uploaded'>
                    <img src="" alt="" />
                    <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{letter.file.name}</p>
                  </div>
                )}
              </div>
            </div>}
        </>)}
      {lookDocument && (
        <div className='user-profile-popup'>
          <div className='user-profile-popup-continer'>
            <div style={{ marginTop: '1rem' }}>
              <DocumentView document={lookDocument} setLookDocument={setLookDocument} />
            </div>
          </div>
        </div>
      )}
      {lookImage && (
        <div className='user-profile-popup'>
          <div className='user-profile-popup-continer'>
            <div style={{ marginTop: '1rem' }}>
              <PhotoView image={lookImage} setLookImage={setLookImage} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default LetterManager
