 
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react'
import { Form,  Select} from 'antd'
import {getUserNewQuery, getUserUsersByEmail} from 'app/modules/apps/users/core/_requests'
import {Users} from 'app/modules/apps/users/core/_models'
import {useQuery} from 'react-query'

import {
  createTask,
  createTaskManual,
  createTranslation,
  createTranslationDetail,
  createTranslationResult,
  createUserBasic,

  findTask,
  findTaskByUser,
  findTranslation,
} from 'app/modules/apps/paypal/_requests'
import './style.css'
import { uploadFileManualTranslation } from 'app/modules/apps/file/core/_requests';
/* import '../../../sass-arquitecture/main.scss' */
import Swal from 'sweetalert2';
import PageCounter from '../common/PageCounter';
// import crypto from 'crypto';
import bcrypt from 'bcryptjs';

const {Option} = Select

interface ManualTranslationModalProps {
  visible: boolean
  onCancel: () => void
  onCreate: (values: any) => void
  products: any
  // getPageCounter?: (totalPages: number) => void
}

const ManualTranslationModal: React.FC<ManualTranslationModalProps> = ({
  visible,
  onCancel,
  onCreate,
  products,
}: ManualTranslationModalProps) => {
  const [wordSearched, setWordSearched] = useState('')
  const [showList, setShowList] = useState(true)
  const [productDesc, setProductsId] = useState('')
  const [userId, setUserId] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [pages, setPages] = useState(0)
  const [fileId, setFileId] = useState('')

  const [files, setFiles] = useState<any>(null)

  const [form] = Form.useForm()
  const {data: users, refetch: refecthUser} = useQuery(
    'GetUseeer',
    async () => await getUserNewQuery({email: wordSearched})
  )

  const salt = bcrypt.genSaltSync(10);
  const password =
    "ImmiLand?" + Math.round(100 + Math.random() * (999 - 100)) + "_";
  const encryptPassword = bcrypt.hashSync(password, salt);
  // const hashCrypto = crypto
  //   .createHash("sha256")
  //   .update(crypto.randomBytes(64).toString("hex"))
  //   .digest("hex");


  const onClickUser = (user: Users) => {
    setWordSearched(user.email)
    setUserEmail(user.email)
    setUserId(user.id as string)
    setShowList(false)
  }

  const changeInputUser = async (search: string) => {
    setWordSearched(search)
    if (search) {
      const result = await getUserUsersByEmail(search)
      setShowList(true)
    }
  }

  const onSave = async (data: any) => {
    let newUser: any
    if (!data.userId) {
      newUser = await createUserBasic(data.userEmail, encryptPassword)
    }
    const file = await handleUpload()
    const task = await findTask(data.productDesc)


    const taskData = {
      task_id: task,
      user_id: data.userId ? data.userId : newUser.id
    }
    const taskUser= await createTaskManual(taskData)
   /*  const taskUser = await findTaskUsersByTaskIdManual(task) */
    const translationData = {
      statustag: 1,
      userId: data.userId ? data.userId : newUser.id,
      task: task,
      taskUser: taskUser.id,
      pages: pages ? pages : 1 
    }
    await createTranslation(translationData)
    const translation = await findTranslation(taskUser.id)
    const translationDetailData = {
      taskId: task,
      fileId: file.id,
      translationId: translation.id,
    }
    createTranslationDetail(translationDetailData)
    const translationResultData = {
      userId: data.userId ? data.userId : newUser.id,
      taskId: task,
      translationId: translation.id,
    }
    createTranslationResult(translationResultData)

    onCreate(data)

    onCancel()

    Swal.fire('Traducción creada correctamente', '', 'success')

    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  const handleUpload = async () => {
    const file: any = await uploadFileManualTranslation(files, () => {})
    setFiles(null)
    return file
  }

  // const handlePageCounter = (pageCounter: number) => {
  //   setPages(pageCounter)
  //   getPageCounter != null && getPageCounter(pageCounter)
  // }




  useEffect(() => {
    refecthUser()
  }, [refecthUser, wordSearched])

  useEffect(() => {}, [products])
  return (
    <div
      className={` fixed inset-0 overflow-hidden transform transition-transform ${
        visible ? 'translate-x-0' : 'translate-x-full'
      }`}
      style={{zIndex: '9999'}}
    >
      <div className='absolute inset-0 bg-gray-500 opacity-75' onClick={onCancel}></div>
      <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
        <div className='relative w-screen max-w-md'>
          <div style={{height: '55rem', borderTopLeftRadius:'20px', borderBottomLeftRadius:'20px'}} className='flex flex-col bg-white shadow-xl overflow-y-scroll'>
            <div className='flex-1 py-6 overflow-y-auto px-4 sm:px-6'>
              <h2 className='text-lg font-medium text-black'>Crear Traducción Manual</h2>
              <div className='sm:px-6 relative flex-1 px-4 mt-6'>
                {/* Add your form fields here */}
                {/* Example input field */}
                <div className="mt-4">

                  <label className='label'>Seleccione el  usuario:</label>
                  <input
                    className='custom-input'
                    type='text'
                    name='user'
                    value={wordSearched}
                    onChange={(e) => {
                      changeInputUser(e.target.value)
                    }}
                  />
                  {wordSearched && showList && (
                    <div className='results-list'>
                      {users?.data?.map((user: Users) => (
                        <div
                          onClick={() => {
                            onClickUser(user)
                          }}
                          className='search-result' /* key={user.id}  value={user.id}  */
                        >
                          {user.email + '  -  ' + user.firstName + '  ' + user.lastName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* Example text area */}
                <div className='mt-4'>
                  <label htmlFor='textArea' className='block text-sm font-medium text-gray-700'>
                    Archivo
                  </label>
                  <>
                    <img
                      style={{marginBottom: '1rem'}}
                      width='40'
                      height='40'
                      src='https://img.icons8.com/external-line-adri-ansyah/64/ababab/external-social-social-media-interface-line-adri-ansyah-12.png'
                      alt='external-social-social-media-interface-line-adri-ansyah-12'
                    />
                    <input
                      className='uploader_input'
                      accept='.pdf'
                      type='file'
                      max={1}
                      onChange={(event: any) => {
                        const file = event.target.files[0]
                        setFiles(file)
                        setUserEmail(wordSearched)
                      }}
                    />
                  </>
                  <div className='mt-4'>
  <label htmlFor='pageCountField' className='block text-sm font-medium text-gray-700'>
    Total de páginas
  </label>
  <input
    id='pageCountField'
    type='number'
    min='1'
    className='form-input mt-1 block w-full'
    style={{width:'5rem', border: '1px solid #DFE4EA', borderRadius: '0.25rem'}}
    value={pages}
    onChange={(e) => setPages(parseInt(e.target.value))}
  />
</div>

                </div>
                {/* Example dropdown field */}
                <div className='mt-4'>
                  <label
                    htmlFor='dropdownField'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Producto
                  </label>

                  <select
                    id='dropdownField'
                    name='dropdownField'
                    className='form-select mt-1 block w-full mb-6 mt-6'
                    onChange={(e) => {
                      setProductsId(e.target.value)
                    }}
                  >
                    <option value=''>Seleccione un producto</option>

                    {products?.data
                      .filter((product: any) => product.name.toLowerCase().includes('traduccion'))
                      .map((product: any) => (
                        <option key={product.id} value={product.name}>
                          {product.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div  className='container-buttons-input-manual-translation'>
              <button style={{border: '1px solid black' , borderRadius:'30px' , padding:'8px 20px'}}  onClick={onCancel}>
                Cerrar
              </button>
              <button  style={{border: '1px solid black' , borderRadius:'30px' , padding:'8px 20px', backgroundColor:'black', color:'white'}}
                className='next-button-input-manual-translation'
                onClick={async () => {
                  // console.log("word", wordSearched)
                  const datapayload = {
                    productDesc: productDesc,
                    userId: userId,
                    userEmail: userEmail,
                  }
                  onSave(datapayload)
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManualTranslationModal
