export const menu = {
  'MENU.NEW': 'Nuevo',
  'MENU.ACTIONS': 'Acciones',
  'MENU.CREATE_POST': 'Crear nueva publicacion',
  'MENU.PAGES': 'Paginas',
  'MENU.FEATURES': 'Caracteristicas',
  'MENU.APPS': 'Aplicaciones',
  'MENU.DASHBOARD': 'Tablero',
  'MENU.MYVISA': 'Mi Proceso de Visa',
  'MENU.MYINFO': 'Mi Perfil',
  'MENU.MYSERVICES': 'Mis Servicios',
  'MENU.MYAPPOINTMENTS': 'Mis Citas',
  'MENU.MYDOCUMENTS': 'Documentos',
  'MENU.ACTIVITIES': 'Actividades',
  'MENU.ACTIVITIES_LIST': 'Lista de actividades',
  'MENU.FILEMANAGER': 'Administrador de archivos',
  'MENU.TEMPLATES': 'Plantillas',
  'MENU.MESSAGES': 'Mensajes',
  'MENU.CLIENTS': 'Clientes',
  'MENU.APPOINMENTS': 'Citas por consultor',
  'MENU.TRANSLATION': 'Mis Traducciones',
  'MENU.TIMELINE': 'Timeline',
  'MENU.MYTRANSLATIONS': 'Mis Traducciones',
  'MENU.MANAGE': 'Administrar',
  'MENU.ADMIN': 'Clientes',
  'MENU.STORE': 'Tienda',
  'MENU.AVAILABLESERVICES': 'Servicios Disponibles',
  'MENU.STORE.CATEGORY': 'Categorias',
  'MENU.STORE.CUPON': 'Cupónes',
  'MENU.STORE.PRODUCT': 'Mis Servicios',
  'MAIN.NAVIGATION': 'Menú principal',
  'MENU.APPOINTMENTBYCONSULTANT': 'Citas por consultor',
  'MENU.CUSTOMERS': 'Clientes',
  'MENU.GENERALMESSAGES': 'Mensajes consultor ausente',
  'MENU.INTAKEADMIN': 'Administrador de intake',
  'MENU.PURCHASESERVICE': 'Adquirir Nuevo Servicio',
  'MENU.MYSHOP': 'Mi Tienda',
  'MENU.CONTRACT': 'Contratación Immiland',
  'MENU.APICALLS': 'Integraciones Api',
  'MENU.CHANGEPASSWORD': 'Cambiar contraseña',
  'MENU.MYAPPOINTMENTSADMIN': 'Administración de Citas',
  'MENU.CREATEAPPOINMENT': 'Crear Cita',
  'MENU.SUPPORT': 'Soporte Técnico',
  'MENU.LIBRARY': 'Biblioteca',
  'MENU.CONTRACTATION': 'Menu de contratación',
  'MENU.EDUCATION': 'Educación',
  'IMMIGRATION.INSIDE_CANADA': 'Estoy Dentro de Canadá',
  'IMMIGRATION.OUTSIDE_CANADA': 'Estoy Fuera de Canadá',
}
