/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import {Alert, Button, H1, H3, Input, Link} from '../../components'
import {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import ContainerLeft from './ContainerLeft'
import {RootStoreContext} from '../../stores/rootStore'
import clsx from 'clsx'
import {useFormik} from 'formik'

const initialValues = {
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  /*   changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (!!(val && val.length > 0)),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
    }), */
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export const Restore = () => {
  const rootStore = useContext(RootStoreContext)
  const {requestReset} = rootStore.authStore
  const navigate = useNavigate()
  const [reseted, setReseted] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    if (reseted) navigate('/auth/login')
    // eslint-disable-next-line
  }, [reseted])

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      requestReset(id || '', values.password, values.changepassword)
        .then(({data: {api_token}}) => {
          setLoading(false)
          setReseted(true)
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus(
            'El link de restablecimiento de contraseña es inválido o ya expiró, intente nuevamente con otro link'
          )
        })
    },
  })

  useEffect(() => {
    // PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <div className=' flex-wrap overflow-hidden table '>
        <div className='w-full h-screen overflow-hidden lg:w-1/2 xl:w-1/2 px-4 mx-auto my-auto table-cell align-middle'>
          <div>
            <header className='h-28 text-center w-48 justify-center items-center lg:left-0 m-auto lg:w-32 xl:w-32'>
              <img
                src={'/media/newdesign/07-logo-horizontal-color.svg'}
                alt='immiland'
                className=''
              />
            </header>
          </div>
          <div className=' align-content-center lg:px-20 xl:px-32 '>
            <form className='' noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
              <H1>Restaurar contraseña</H1>
              <div className='my-3'>{formik.status && <Alert>{formik.status}</Alert>}</div>
              {/* begin::Form group Password */}
              <div className='' data-kt-password-meter='true'>
                <div className=''>
                  <H3>Contraseña</H3>
                  <div>
                    <Input
                      type='password'
                      placeholder='Password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        '',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />

                    {formik.touched.password && formik.errors.password && (
                      <Alert>
                        <span role='alert'>{formik.errors.password}</span>
                      </Alert>
                    )}
                  </div>
                </div>
                <p className='text-xs'>
                  Use 8 or more characters with a mix of letters, numbers & symbols.
                </p>
              </div>

              {/* begin::Form group Confirm password */}
              <div className='fv-row mb-2'>
                <H3>Confirmar Contraseña</H3>
                <Input
                  type='password'
                  placeholder='Password confirmation'
                  autoComplete='off'
                  {...formik.getFieldProps('changepassword')}
                  className={clsx(
                    '',
                    {
                      'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                      'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                  )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <Alert>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </Alert>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='fv-row mb-2'>
                <div className='form-check form-check-custom form-check-solid text-sm'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='kt_login_toc_agree'
                    {...formik.getFieldProps('acceptTerms')}
                  />
                  <label
                    className='form-check-label fw-bold text-gray-700 fs-6'
                    htmlFor='kt_login_toc_agree'>
                    I Agree the <a href="https://www.immilandcanada.com/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">terms and conditions.</a>
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <Alert>
                          <span role='alert'>{formik.errors.acceptTerms}</span>
                        </Alert>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='text-center mb-2'>
                <Button
                  type='submit'
                  id='kt_sign_up_submit'
                  className=' mb-2'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                >
                  {!loading && <span className='indicator-label'>Guardar</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
                <Link to='/auth/login'>Login</Link>
              </div>
            </form>
          </div>
        </div>

        <div className='w-full h-0 lg:h-screen xl:h-screen overflow-hidden bg-gray-100 lg:w-1/2 xl:w-1/2 p-0 lg:p-10 xl:p-10 table-cell align-middle'>
          <ContainerLeft />
        </div>
      </div>
    </>
  )
}
