/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {getInstitution} from 'app/modules/apps/cart/core/education/_request'

import {useQuery} from 'react-query'
import {createAplication, getStatusEducation} from 'app/modules/apps/cart/core/aplication/_request'
import {getPormgramsById} from 'app/modules/apps/cart/core/program/_request'
import {Program} from 'app/modules/apps/cart/core/program/_models'
import {Institution} from 'app/modules/apps/cart/core/education/_models'
import {Users} from 'app/modules/apps/users/core/_models'
import './search.css'
import {getUserNewQuery, getUserUsersByEmail} from 'app/modules/apps/users/core/_requests'

interface CreateInstitutionFormProps {
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
  onInstitutionCreated: () => void
}
/* const API_URL = process.env.REACT_APP_API_URL;
const INSTITUTION_URL = `${API_URL}`; */
const CreateAplicationForm: React.FC<CreateInstitutionFormProps> = ({
  setShowTable,
  onInstitutionCreated,
}) => {
  const [formData, setFormData] = useState({
    id: '',
    institution: {
      institutionId: '',
      name: '',
      province: '',
      country: '',
      address: '',
      type: '',
      partnership: '',
    },
    user: {
      avatar: '/media/avatars/300-1.jpg',
      firstName: '',
      lastName: '',
      id: '',
      birth: new Date('1900-01-01'),
      address: '',
      countryOfOrigin: '',
      gender: '',
      phone: '',
      whatsapp: '',
      email: '',
      nationalitie: '',

      language: '',
      timeZone: '',
      last_service: '',
      users: {},
      age: '',
      role: {},
      first_service: '',
      photoId: '',
      photoPath: '',
      createdAt: '',
      last_service_date_jotform_name: '',
    },
    statuseducation: {id: '', name: ''},
    program: {
      programId: '',
      name: '',
      institution: {
        institutionId: '',
        name: '',
        province: '',
        country: '',
        address: '',
        type: '',
        partnership: '',
      },
      location: '',
      level: {
        name: '',
        id: '',
      },
      type: {
        name: '',
        id: '',
      },
      discipline: {
        name: '',
        id: '',
      },
      intake: {
        name: '',
        id: '',
      },
      duration: '',
    },
  })

  const {data: statused} = useQuery('getStatusEd', async () => await getStatusEducation())
  const {data: institutions} = useQuery('GetI', async () => await getInstitution())
  const [insitutionSelected, setInstitutionSelected] = useState('')
  const [programselected, setProgramsSelected] = useState<Program[]>()
  const [wordSearched, setWordSearched] = useState('')
  const [showList, setShowList] = useState(true)

  const {data: users, refetch: refecthUser} = useQuery(
    'Getstt',
    async () => await getUserNewQuery({email: wordSearched})
  )

  if (insitutionSelected) {
    const fetchPrograms = async () => {
      const response = await getPormgramsById(insitutionSelected)
      if (response) {
        setProgramsSelected(response as any)
      }
    }

    fetchPrograms().then(() => {
      setInstitutionSelected('')
    })
  }

  const [formVisible, setFormVisible] = useState(true)
  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()

    try {
      await createAplication(formData)
      setFormData({
        id: '',
        user: {
          avatar: '/media/avatars/300-1.jpg',
          firstName: '',
          lastName: '',
          id: '',
          birth: new Date('1900-01-01'),
          address: '',
          countryOfOrigin: '',
          gender: '',
          phone: '',
          whatsapp: '',
          email: '',
          nationalitie: '',

          language: '',
          timeZone: '',
          last_service: '',
          users: {},
          age: '',
          role: {},
          first_service: '',
          photoId: '',
          photoPath: '',
          createdAt: '',
          last_service_date_jotform_name: '',
        },
        statuseducation: {id: '', name: ''},
        program: {
          programId: '',
          name: '',
          institution: {
            institutionId: '',
            name: '',
            province: '',
            country: '',
            address: '',
            type: '',
            partnership: '',
          },
          location: '',
          level: {
            name: '',
            id: '',
          },
          type: {
            name: '',
            id: '',
          },
          discipline: {
            name: '',
            id: '',
          },
          intake: {
            name: '',
            id: '',
          },
          duration: '',
        },
        institution: {
          institutionId: '',
          name: '',
          province: '',
          country: '',
          address: '',
          type: '',
          partnership: '',
        },
      })

      setShowTable(true)
      setFormVisible(false)
      onInstitutionCreated()
    } catch (error) {
      console.error('Error creating institution:', error)
    }
  }

  const changeInstitutionSelected = (id: string) => {
    setInstitutionSelected(id)
  }

  const changeInputUser = async (search: string) => {
    /*  if (statuseducations &&  statuseducations[0]) {
       console.log(JSON.stringify(statuseducations))
       setFormData((prevData: any) => ({
         ...prevData,
         "statuseducation": statuseducations[0].id,
       }));



     } */
    setShowList(true)
    setWordSearched(search)
    if (search) {
      const result = await getUserUsersByEmail(search)
      if (result) {
        setFormData((prevData: any) => ({
          ...prevData,
          user: result.id,
        }))
      }
    }
  }

  const onClickUser = (user: Users) => {
    setWordSearched(user.email)
    setFormData((prevData: any) => ({
      ...prevData,
      user: user.id,
    }))
    setShowList(false)
  }

  useEffect(() => {
    refecthUser()
  }, [wordSearched])

  return (
    <div className='form-container1'>
      {formVisible && (
        <form onSubmit={handleSubmit} className='custom-form1'>
          <div className='form-group1'>
            <label className='label'>Buscador de usuarios:</label>
            <input
              className='input'
              type='text'
              name='user'
              value={wordSearched}
              onChange={(e) => {
                changeInputUser(e.target.value)
              }}
            />
          </div>
          {wordSearched && showList && (
            <div className='results-list'>
              {users?.data?.map((user: Users) => (
                <div
                  onClick={() => {
                    onClickUser(user)
                  }}
                  className='search-result' /* key={user.id}  value={user.id}  */
                >
                  {user.email + '  -  ' + user.firstName + '  ' + user.lastName}
                </div>
              ))}
            </div>
          )}
          <div className='form-group1'>
            <label className='label'>Status:</label>
            {statused ? (
              <select
                className='input'
                name='statuseducation'
                value={formData.statuseducation.id}
                onChange={(event) => {
                  handleChange(event)
                }}
              >
                <option value=''>Select Status educations</option>
                {statused.map((status: any) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>Cargando status...</p>
            )}
          </div>
          <div className='form-group1'>
            <label className='label'>Institution:</label>
            {institutions ? (
              <select
                className='input'
                name='institution'
                value={formData.institution.institutionId}
                onChange={(event) => {
                  handleChange(event)
                  changeInstitutionSelected(event.target.value)
                }}
              >
                <option value=''>Select Institution</option>
                {institutions.map((institution: Institution) => (
                  <option key={institution.institutionId} value={institution.institutionId}>
                    {institution.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>Cargando instituciones...</p>
            )}
          </div>
          <div className='form-group1'>
            <label className='label'>Program:</label>
            {programselected && programselected.length > 0 ? (
              <select
                className='input'
                name='program'
                value={formData.program.programId}
                onChange={handleChange}
              >
                <option value=''>Select program</option>
                {programselected.map((program: Program) => (
                  <option key={program.programId} value={program.programId}>
                    {program.name}
                  </option>
                ))}
              </select>
            ) : (
              <>
                {programselected && programselected.length === 0 ? (
                  <p>La institucion seleccionada no tiene programas</p>
                ) : (
                  <p>Cargando programas...</p>
                )}
              </>
            )}
          </div>
          <button type='submit' className='submit-button1'>
            Crear Registro
          </button>
        </form>
      )}
    </div>
  )
}

export default CreateAplicationForm
