/* eslint-disable @typescript-eslint/no-unused-vars */

import {Button, PageHeader, Space} from 'antd'
import {
  type Category,
  categoryDetailsInitValues,
} from '../../../../../app/modules/apps/cart/core/category/_models'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {useEffect, useState} from 'react'
import {
  createCategory,
  updateCategory,
} from '../../../../../app/modules/apps/cart/core/category/_requests'
import {AddCategory} from './AddCategory'
import {ListCategories} from './ListCategories'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

// get all icons from @fortawesome/fontawesome-free

const CategoriManager = () => {
  const [addcategory, setAddCategory] = useState(false)
  const [icon, setIcon] = useState(<PlusOutlined />)
  const [callRefetch, setCallRefetch] = useState(false)
  const [category, setCategory] = useState<Category | null>(null)

  const intl = useIntl()

  useEffect(() => {
    if (addcategory) {
      setCallRefetch(false)
      setIcon(<MinusCircleOutlined style={{color: 'white'}} />)
    } else {
      setCallRefetch(true)
      setIcon(<PlusOutlined style={{color: 'white'}} />)
    }
  }, [addcategory])

  const handleSaveTemplate = async (data: Category) => {
    setCallRefetch(true)
    if (data.id) {
      const result = await createCategory(data)

      if (result != null) {
        setCategory(null)
        Swal.fire({
          title: 'Success!',
          text: 'Guardado correctamente ',
          icon: 'success',
          confirmButtonText: 'Regresar',
        })
      }
    } else {
      const result = await updateCategory(data)

      if (result != null) {
        setCategory(null)
        Swal.fire({
          title: 'Success!',
          text: 'Guardado correctamente ',
          icon: 'success',
          confirmButtonText: 'Regresar',
        })
      }
    }
    setAddCategory(false)
    setCallRefetch(false)
  }

  const handleEdit = (data: any) => {
    setCategory(data)
    setAddCategory(true)
  }
  return (
    <div>
      {' '}
      <PageHeader
        className='site-page-header-responsive'
        subTitle=''
        extra={[
          <Button
            key='addcategory'
            type='primary'
            style={{background: 'white', border: '1px solid #bf3c3c'}}
            onClick={() => {
              setAddCategory(!addcategory)
              setCategory(categoryDetailsInitValues)
            }}
            shape='circle'
            icon={icon}
          />,
        ]}
      >
        {addcategory ? (
          <>
            {category != null && (
              <AddCategory category={category} onComplete={handleSaveTemplate} />
            )}
          </>
        ) : (
          <Space direction='vertical' style={{display: 'flex'}}>
            <ListCategories
              handleEdit={handleEdit}
              callRefetch={callRefetch}
              onComplete={handleSaveTemplate}
            />
          </Space>
        )}
      </PageHeader>
    </div>
  )
}

export default CategoriManager
