import {useState} from 'react'
import axios from 'axios'
import {Institution} from 'app/modules/apps/cart/core/education/_models'
import {getInstitution} from 'app/modules/apps/cart/core/education/_request'
import CreateInstitutionForm from './CreateInstitutionForm'
import EditInstitutionForm from './EditInstitutionForm'
import {useQuery} from 'react-query'
import {Modal, Select} from 'antd'
import {EditOutlined, DeleteOutlined, SearchOutlined, FilterOutlined} from '@ant-design/icons'

const API_URL = process.env.REACT_APP_API_URL
const INSTITUTION_URL = `${API_URL}/institution`

const InstitutionTable = () => {
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [editInstitution, setEditInstitution] = useState<Institution | null>(null)
  const [showEditForm, setShowEditForm] = useState(false)
  const [filterNameText, setFilterNameText] = useState('')
  const [filterProvinceText, setFilterProvinceText] = useState('')
  const [filterCountryText, setFilterCountryText] = useState('')
  const [filterAddressText, setFilterAddressText] = useState('')
  const [filterTypeText, setFilterTypeText] = useState('')
  const [filterPartnershipText, setFilterPartnershipText] = useState('')
  const [showFilterModalName, setShowFilterModalName] = useState(false)
  const [showFilterModalProvince, setShowFilterModalProvince] = useState(false)
  const [showFilterModalCountry, setShowFilterModalCountry] = useState(false)
  const [showFilterModalAddress, setShowFilterModalAddress] = useState(false)
  const [showFilterModalType, setShowFilterModalType] = useState(false)
  const [showFilterModalPartnership, setShowFilterModalPartnership] = useState(false)
  const {data: institutions, refetch} = useQuery(
    'GetInstitution',
    async () => await getInstitution()
  )
  const uniqueProvinceValues = Array.from(
    new Set(institutions?.map((institution) => institution.province))
  )
  const uniqueTypeValues = Array.from(new Set(institutions?.map((institution) => institution.type)))
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 20

  const startIndex = (currentPage - 1) * recordsPerPage
  const endIndex = startIndex + recordsPerPage
  const filteredInstitutions = institutions?.filter(
    (institution) =>
      institution.name.toLowerCase().includes(filterNameText.toLowerCase()) &&
      (filterProvinceText.length === 0 || filterProvinceText.includes(institution.province)) &&
      institution.country.toLowerCase().includes(filterCountryText.toLowerCase()) &&
      institution.address.toLowerCase().includes(filterAddressText.toLowerCase()) &&
      (!filterTypeText.length || filterTypeText.includes(institution.type)) &&
      institution.partnership.toLowerCase().includes(filterPartnershipText.toLowerCase())
  )

  // Calcular el total de páginas basado en las instituciones filtradas
  const totalPagesFiltered = Math.ceil((filteredInstitutions?.length ?? 0) / recordsPerPage)

  const visibleInstitutions = filteredInstitutions?.slice(startIndex, endIndex) ?? []

  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm)
  }

  const handleInstitutionCreated = () => {
    refetch()
    setShowTable(true)
    setShowCreateForm(false)
  }

  const handleEditClick = (institution: Institution) => {
    setEditInstitution(institution)
    setShowEditForm(true)
    setShowTable(false)
  }

  const handleEditSave = () => {
    setShowEditForm(false)
    setShowTable(true)
    refetch()
  }

  const handleDeleteClick = async (institution: Institution) => {
    try {
      await axios.delete(`${INSTITUTION_URL}/${institution.institutionId}`)
      refetch()
    } catch (error) {
      console.error('Error deleting institution:', error)
    }
  }

  return (
    <div>
      <h2
        className='text-2xl font-semibold mb-4'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '2rem',
        }}
      >
        Institution Table
      </h2>
      <button
        className='submit-button2'
        style={{marginBottom: '2rem', marginLeft: '2rem'}}
        onClick={() => {
          toggleCreateForm()
          setShowTable(false)
        }}
      >
        Create Institution
      </button>
      {showCreateForm && (
        <CreateInstitutionForm
          showTable={showTable}
          setShowTable={setShowTable}
          onInstitutionCreated={handleInstitutionCreated}
        />
      )}
      {showTable && (
        <div className='overflow-x-auto'>
          <table className='mt-4 min-w-full bg-white border rounded-md shadow-sm'>
            <thead className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'>
              <tr>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Name</span>
                    <button className='filter-button' onClick={() => setShowFilterModalName(true)}>
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Name'
                    visible={showFilterModalName}
                    onCancel={() => setShowFilterModalName(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by name'
                      value={filterNameText}
                      onChange={(e) => setFilterNameText(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Province</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalProvince(true)}
                    >
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Province'
                    visible={showFilterModalProvince}
                    onCancel={() => setShowFilterModalProvince(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Filter by province'
                      value={filterProvinceText}
                      onChange={(selectedValues) => setFilterProvinceText(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueProvinceValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Country</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalCountry(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Country'
                    visible={showFilterModalCountry}
                    onCancel={() => setShowFilterModalCountry(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by country'
                      value={filterCountryText}
                      onChange={(e) => setFilterCountryText(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Address</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalAddress(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Address'
                    visible={showFilterModalAddress}
                    onCancel={() => setShowFilterModalAddress(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by address'
                      value={filterAddressText}
                      onChange={(e) => setFilterAddressText(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Type</span>
                    <button className='filter-button' onClick={() => setShowFilterModalType(true)}>
                      <FilterOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Type'
                    visible={showFilterModalType}
                    onCancel={() => setShowFilterModalType(false)}
                    footer={null}
                  >
                    <Select
                      mode='multiple' // This specifies that multiple values can be selected
                      placeholder='Filter by type'
                      value={filterTypeText}
                      onChange={(selectedValues) => setFilterTypeText(selectedValues)}
                      className='filter-input'
                    >
                      {uniqueTypeValues.map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Modal>
                </th>

                <th className='px-4 py-2'>
                  <div className='flex items-center space-x-2'>
                    <span>Partnership</span>
                    <button
                      className='filter-button'
                      onClick={() => setShowFilterModalPartnership(true)}
                    >
                      <SearchOutlined />
                    </button>
                  </div>
                  <Modal
                    title='Filter by Partnership'
                    visible={showFilterModalPartnership}
                    onCancel={() => setShowFilterModalPartnership(false)}
                    footer={null}
                  >
                    <input
                      type='text'
                      placeholder='Filter by partnership'
                      value={filterPartnershipText}
                      onChange={(e) => setFilterPartnershipText(e.target.value)}
                      className='filter-input'
                    />
                  </Modal>
                </th>
                <th className='px-4 py-2'>Actions</th>
                <th className='px-4 py-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {visibleInstitutions
                ?.filter(
                  (institution) =>
                    institution.name.toLowerCase().includes(filterNameText.toLowerCase()) &&
                    (filterProvinceText.length === 0 ||
                      filterProvinceText.includes(institution.province)) &&
                    institution.country.toLowerCase().includes(filterCountryText.toLowerCase()) &&
                    institution.address.toLowerCase().includes(filterAddressText.toLowerCase()) &&
                    (!filterTypeText.length || filterTypeText.includes(institution.type)) &&
                    institution.partnership
                      .toLowerCase()
                      .includes(filterPartnershipText.toLowerCase())
                )
                .map((institution) => (
                  <tr key={institution.institutionId}>
                    <td className='px-4 py-2'>{institution.name}</td>
                    <td className='px-4 py-2'>{institution.province}</td>
                    <td className='px-4 py-2'>{institution.country}</td>
                    <td className='px-4 py-2'>{institution.address}</td>
                    <td className='px-4 py-2'>{institution.type}</td>
                    <td className='px-4 py-2'>{institution.partnership}</td>
                    <td className='px-4 py-2'>
                      <button className='edit-button' onClick={() => handleEditClick(institution)}>
                        <EditOutlined />
                      </button>
                    </td>
                    <td className='px-4 py-2'>
                      <button
                        className='delete-button'
                        onClick={() => handleDeleteClick(institution)}
                      >
                        <DeleteOutlined />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className='pagination'>
            <button
              className='pagination-button'
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              {currentPage}/{totalPagesFiltered}
            </span>
            <button
              className='pagination-button'
              disabled={currentPage === totalPagesFiltered}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {showEditForm && editInstitution !== null && (
        <EditInstitutionForm editInstitution={editInstitution} onSave={handleEditSave} />
      )}
    </div>
  )
}

export default InstitutionTable
