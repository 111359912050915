export const formsManager = {
  'FORMSMANAGER.ADMINTOOLS': 'Admin Tools',
  'FORMSMANAGER.MANAGER': 'Forms Manager',
  'FORMSMANAGER.FORMSNAME': 'Forms Name',
  'FORMSMANAGER.FORMSCATEGORY': 'Forms Category',
  'FORMSMANAGER.SUBMIT': 'Submit',
  'FORMSMANAGER.FORMSLIST': 'Form List',
  'FORMSMANAGER.FORMSMANAGER': 'Forms Manager',
  'FORMSMANAGER.FORMSDESCRIPTION': 'Forms Description',
  'FORMSMANAGER.JSON': 'JSON:',
  'FORMSMANAGER.SAVE': 'SAVE',
  'FORMSMANAGER.TEST': 'TEST ',
  'FORMSMANAGER.DELETE': 'DELETE',
  'FORMSMANAGER.EDIT': 'EDIT',
  'LISTFORMS.FILLTHEFORM': 'Fill the form',
  'LISTFORMS.YOUARENOTCLIENT': 'You are not client',
  'FORMSMANAGER.MESSAGES': 'FormsManager',
  'SERVICES.INFORMATIONREQUIRED': 'Informacion required',
  'SERVICES.FORMMANUAL': 'Addional Forms',
  'SERVICES.ONBOARD': 'Welcome OnBoard',
  'SERVICES.FORMAUTOMATIC': 'Assigned Forms',
  'SERVICES.CONTRACT': 'Contract',
  'SERVICES.IMPORTANTINFO': 'Important Information',
  'SERVICES.SUPPORTDOCUMENTS': 'Support Documents',
  'SERVICES.CLIENTDOCUMENTS': 'Client Documents',
  'SERVICES.EXTRADOCUMENTS': 'Extra Documents',
  'SUPPORTDOCUMENTS.TRANSLATE': 'Translate',
  'SUPPORTDOCUMENTS.LEGISLATE': 'Legislate',
  'SUPPORTDOCUMENTS.DELETE': 'Delete from the list',
  'MENU.CONTRACTATION': 'Contractation menu',
  'MENU.EDUCATION': 'Education',
  'IMMIGRATION.INSIDE_CANADA': 'I am inside Canadá',
  'IMMIGRATION.OUTSIDE_CANADA': 'I am Outside Canadá',
}
