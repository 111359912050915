import type {ReactNode} from 'react'
import {type ID, type Response} from '../../../../../core/helpers'

import {type Staff} from '../../staff/core/_models'

export interface Appointments {
  id?: ID
  created_at?: Date | null
  end_time?: Date | null
  join_url?: string | null
  name?: string | null
  start_time?: Date | null
  status?: string | null
  updated_at?: Date | null
  email?: string | null
  uri?: string | null
  location_type?: string | null
  staffUserId?: string | null
  consultant: string
}

export type AppointmentsQueryResponse = Response<Appointments[]>

type toFormValuesType = (apiData: Appointments) => Appointments

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id || '',
  created_at: apiData.created_at != null ? new Date(apiData.created_at) : null,
  end_time: apiData.end_time != null ? new Date(apiData.end_time) : null,
  join_url: apiData.join_url || '',
  name: apiData.name || '',
  start_time: apiData.start_time != null ? new Date(apiData.start_time) : null,
  status: apiData.status || '',
  updated_at: apiData.updated_at != null ? new Date(apiData.updated_at) : null,
  uri: apiData.uri || '',
  location_type: apiData.location_type || '',
  staffUserId: apiData.staffUserId || '',
  consultant: apiData.consultant,
})

export const AppointmentsDetailsInitValues: Appointments = {
  created_at: null,
  end_time: null,
  join_url: '',
  name: '',
  start_time: null,
  status: '',
  updated_at: null,
  uri: '',
  location_type: '',
  consultant: '',
}

export interface IApointments {
  page: number
  limit: number
  startDate: string
  endDate: string
  search: string
  consultantId?: string
  consultantName?: any[]
  userId?: string
  email: string
  description?: any[]
  token?: string; 
}

export interface ResponseApointment {
  data: AppointmentsAdmin[]
  total: string
}

export interface AppointmentsAdmin extends Appointments {
  title: ReactNode
  from: string
  fromId: string
  date: Date | null
  description: string
  first_name: string
  id: string
  last_name: string
  nationalitie: string
  orderId: string
  avatarID: string
  avatarPath: string
  receiptLink: string
  total: number
  user_id: string
}
export type AppointmentsResponse = Response<ResponseApointment>
export type AppointmentsAdminQueryResponse = Response<AppointmentsAdmin[]>
type toFormValuesAdminType = (apiData: AppointmentsAdmin) => AppointmentsAdmin
export const toFormValuesAdmin: toFormValuesAdminType = (apiData) => ({
  title: apiData.title,
  from: apiData.from || '',
  fromId: apiData.fromId || '',
  date: apiData.date != null ? new Date(apiData.date) : null,
  description: apiData.description || '',
  first_name: apiData.first_name || '',
  id: apiData.id || '',
  last_name: apiData.last_name || '',
  nationalitie: apiData.nationalitie || '',
  orderId: apiData.orderId || '',
  receiptLink: apiData.receiptLink || '',
  total: apiData.total || 0,
  user_id: apiData.user_id || '',
  created_at: apiData.created_at != null ? new Date(apiData.created_at) : null,
  end_time: apiData.end_time != null ? new Date(apiData.end_time) : null,
  join_url: apiData.join_url || '',
  name: apiData.name || '',
  start_time: apiData.start_time != null ? new Date(apiData.start_time) : null,
  status: apiData.status || '',
  updated_at: apiData.updated_at != null ? new Date(apiData.updated_at) : null,
  uri: apiData.uri || '',
  location_type: apiData.location_type || '',
  avatarID: apiData.avatarID || '',
  avatarPath: apiData.avatarPath || '',
  staffUserId: apiData.staffUserId || '',
  consultant: apiData.consultant,
})

export interface IComposeData {
  fromUser: string
  orderDetailId: string
  user: string
  email: string
  userId: string
  fromId: string
  messageId: string | null
  folder: string
  draft: boolean
  favorite: boolean
  photoPath?: string
  photoID?: string
  files_info: any[]
  unread: boolean
}

export const initialComposeData: IComposeData = {
  orderDetailId: '',
  user: '',
  email: '',
  userId: '',
  fromId: '',
  messageId: null,
  fromUser: '',
  folder: 'inbox',
  draft: false,
  favorite: false,
  files_info: [],
  unread: false,
}

export interface IReply extends IComposeData {
  subject: string
  messageId: string
  content: string
  to: string
  cc: string
  type: string
  attachment: string
}

export const initialReplyData: IReply = {
  orderDetailId: '',
  user: '',
  email: '',
  userId: '',
  fromId: '',
  subject: '',
  messageId: '',
  content: '',
  to: '',
  cc: '',
  fromUser: '',
  type: '',
  attachment: '',
  folder: 'inbox',
  draft: false,
  favorite: false,
  files_info: [],
  unread: false,
}

export interface ICreacteAppointment {
  staff: Staff
  product: {
    id: string
  }
  user: {
    id: string
  }
  props: string
}
