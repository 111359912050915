/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {type Dispatch, type SetStateAction, useContext, useEffect, useState} from 'react'
import {UploadOutlined} from '@ant-design/icons'
import LoadingBar from 'react-top-loading-bar'
import ModalLoader from '../../../../core/components/ModalLoader'
import {RootStoreContext} from '../../../../stores/rootStore'
import {Button, Upload} from 'antd'
import {uploadFilesResult} from 'app/modules/apps/translations/core/_requests'
import Swal from 'sweetalert2'

interface IProps {
  setUploadFiles: Dispatch<SetStateAction<boolean>>
  setTranslate: Dispatch<SetStateAction<string>>
  setFilesUploaded: Dispatch<SetStateAction<boolean>>
  setList: Dispatch<SetStateAction<boolean>>
  translate: string
}

const UploadHandler: React.FC<IProps> = ({
  setUploadFiles,
  setTranslate,
  setFilesUploaded,
  setList,
  translate,
}) => {
  const rootStore = useContext(RootStoreContext)
  const {uploadFiles, isLoadingTranslation} = rootStore.translationStore
  const [fileList, setFileList] = useState([])
  const [files, setFiles] = useState([])
  const [submifiles, setSubmitFiles] = useState(false)

  const [percent, setPercent] = useState(0)

  const handleUpload = async () => {
    const result = await uploadFilesResult(fileList, getProgress, 'translateExtra', translate)
    if (result != null) {
      /*  setUploadFiles(false); */
      setFilesUploaded(false)
      setTranslate('')
      fileList.length = 0
      /* setList(true); */
      /* setCounterToRefreshTranslatedDocument(counterToRefreshTranslatedDocument+1) */
      Swal.fire({
        title: 'Archivo adjuntado',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
      })
    } else {
      fileList.length = 0
    }
  }

  useEffect(() => {
    if (submifiles) {
      uploadFilesTranslate()
      setSubmitFiles(false)
    }
  }, [submifiles])

  const getProgress = (progress: any) => {
    setPercent(progress)
  }
  const handleFileChange = (info: any) => {
    setFileList(info.fileList)
  }

  const uploadFilesTranslate = async () => {
    const result = await uploadFiles(files, getProgress, 'translate', translate)
    if (result != null) {
      setUploadFiles(false)
      setFilesUploaded(false)
      setTranslate('')
      setList(true)
    }
  }

  const handleBack = () => {
    setUploadFiles(false)
    setFilesUploaded(false)
    setTranslate('')
    setList(true)
  }

  return (
    <div>
      {isLoadingTranslation ? (
        <div>
          <ModalLoader />
        </div>
      ) : null}
      <Button
      className='button-ui button-secondary button-s'
        onClick={() => {
          handleBack()
        }}
      >
        Volver
      </Button>
      <br />
      <br />
      <br />
      <LoadingBar color='#BE8575' progress={percent} height={5} />
      <Upload
        multiple
        beforeUpload={() => false} // Prevent file list from being cleared on each selection
        fileList={fileList}
        onChange={handleFileChange}
      >
        {/* <UploadComponent setFiles={setFiles} setSubmitFiles={setSubmitFiles} /> */}
        <Button icon={<UploadOutlined />} type='primary'>
          Seleccionar archivos
        </Button>
      </Upload>
      <Button
        type='primary'
        onClick={handleUpload}
        disabled={fileList.length === 0}
        style={{marginTop: '16px'}}
      >
        Subir archivos
      </Button>
      {submifiles ? (
        <div>
          <h1>Archivos seleccionados</h1>
          {files.map((file: any) => (
            <div key={file.uid}>
              <h2>{file.name}</h2>
              <p>{file.status}</p>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {files.length > 0 && <>{files.length}</>}
    </div>
  )
}

export default UploadHandler
