export const checkList = {
  'CHECKLIST.MESSAGES': 'Check List Form',
  'PAGE.NEXTPAGE': 'Next Page and Save',
  'PAGE.NEXT': 'Next',
  'PAGE.PREVIOUS': 'Previous',
  'PAGE.COMPLETEPAGE': 'Complete and Save',
  'PAGE.COMPLETEPAGEMESSAGE':
    '<h3>Thank you, please check if you need to fill more information </h3>',
  'PAGE.FINISHIMPORTANTPAGE': '<h3>Thanks you, please click to the tab  Asigned Forms</h3>',
}
