/* eslint-disable react-hooks/exhaustive-deps */
import {DatePicker, Switch} from 'antd'
import React, {useEffect} from 'react'
import {Box} from '../../../../../components/Box'
import {type IQueryApiCalls} from '../../../../../app/modules/apps/apicalls/core/_models'
import {Input} from '../../../../../components'
import moment from 'moment'

const {RangePicker} = DatePicker
const dateFormat = 'YYYY-MM-DD'
const today = new Date()
const new_date = moment(today, dateFormat)

interface Props {
  query: IQueryApiCalls
  setQuery: (query: IQueryApiCalls) => void
  refetch: () => void
}

const FiltersApiCalls: React.FC<Props> = ({query, setQuery}) => {
  const [search, setSearch] = React.useState<string>('')

  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: IQueryApiCalls = {
          startDate: values[0].format('YYYY-MM-DD'),
          endDate: values[1].format('YYYY-MM-DD'),
          page: 1,
          limit: 50,
        }
        setQuery(params)
      }
    } catch (error) {}
  }

  const handleSwitch = (checked: boolean, key: string) => {
    const params: IQueryApiCalls = {
      ...query,
      [key]: checked,
      page: 1,
      limit: 50,
    }

    setQuery(params)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params: IQueryApiCalls = {
        ...query,
        data: search,
        page: 1,
        limit: 50,
      }
      setQuery(params)
    }, 100)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [search])

  const handleSearch = (e: any) => {
    // debounce search
    setSearch(e.target.value)
  }

  return (
    <>
      <Box>
        <div className='flex flex-wrap overflow-hidden lg:-mx-2'>
          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3'>
            <RangePicker
              defaultValue={[moment(new Date(), dateFormat), moment(new_date, dateFormat)]}
              onChange={handleDate}
              style={{
                border: '1px solid #be8575',
                width: '100%',
              }}
              onCalendarChange={handleDate}
            />
          </div>

          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/6'>
            <Input
              placeholder='Search'
              value={search}
              onChange={(e: any) => {
                handleSearch(e)
              }}
            />
          </div>
          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/6'>
            COMPLETED
            <Switch
              onChange={(e) => {
                handleSwitch(e, 'completed')
              }}
            />
          </div>

          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/6'>
            VERIFIED
            <Switch
              onChange={(e) => {
                handleSwitch(e, 'verified')
              }}
            />
          </div>
          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/6'>
            IGNORED
            <Switch
              onChange={(e) => {
                handleSwitch(e, 'ignored')
              }}
            />
          </div>
        </div>
      </Box>
    </>
  )
}

export default FiltersApiCalls
