/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react'
import Modal from './Modal'
import {DesktopModalContainer, Header, UserImage, DataDiv} from './ModalPopup.style'
import {useQuery} from 'react-query'
import {RootStoreContext} from '../../stores/rootStore'
import {Button} from '../../components'
import {getUserUsersById} from '../../app/modules/apps/users/core/_requests'
import {Link} from 'react-router-dom'
import {RoleEnum} from '../../auth/models/User/UserModel'
import {type Users} from '../../app/modules/apps/users/core/_models'

interface BaseModalWrapperProps {
  isModalVisible: boolean
  name: string | undefined
  email?: string
  fromId: string
  fromPath: string | undefined
  fromPathId: string | undefined
  onBackdropClick: () => void
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const BaseModalWrapper: React.FC<BaseModalWrapperProps> = ({
  onBackdropClick,
  isModalVisible,
  name,
  email,
  fromId,
  fromPath,
  fromPathId,
  setIsModalVisible,
}) => {
  const [state, setState] = useState(true)
  const {data, refetch} = useQuery('getUserInfo', async () => await getUserUsersById(fromId))
  const rootStore = useContext(RootStoreContext)
  const {getUsers, isLoadingUsers, users, selectUser} = rootStore.usersStore
  const {currentUser} = rootStore.usersStore
  const [goToProfile, setGoToProfile] = useState(false)
  const {user, logout} = rootStore.authStore
  if (!isModalVisible) {
    if (!state) {
      setState(true)
    }
    return null
  } else {
    if (state) {
      refetch()
      setState(false)
    }
  }

  const handleSelectUser = (user?: Users) => {
    if (user !== undefined) {
      selectUser(user)
    }
  }

  const findUser = async (fromId: string) => {
    const result: Users | undefined | void = await getUserUsersById(fromId)
    if (result !== undefined) {
      selectUser(result)
    }
  }

  return (
    <Modal onBackdropClick={onBackdropClick}>
      <DesktopModalContainer>
<Button
  style={{ width: '2rem', alignSelf: 'end', marginRight: '0.5rem', marginTop: '0.5rem' }}
  onClick={() => {
    console.log("Close Button Clicked");
   
    setIsModalVisible(false);
  }}
>
  X
</Button>

        <UserImage
          src={
            fromPathId ? `${fromPath}/${fromPathId}-avatar-large.jpg` : '/media/avatars/avatar.png'
          }
        />
        <Header>{name}</Header>

        <p>Email: {email}</p>

        {user != null && user.role?.id === RoleEnum.admin ? (
          <>
            <DataDiv>
              <p>Fecha de nacimiento: {data?.birth?.toString().substring(0, 10)} </p>
              <p>Género: {data?.gender}</p>
              <p>Número de teléfono: {data?.phone} </p>
              <p>Dirección: {data?.address}</p>
              <p>Nacionalidad: {data?.nationalitie}</p>
            </DataDiv>
            <Link to='/usersList'>
              <Button
                onClick={() => {
                  findUser(fromId)
                }}
              >
                Ver perfil
              </Button>
            </Link>
          </>
        ) : (
          <>
            <a
              href='https://www.immilandcanada.com/equipo-immiland'
              target='_blank'
              rel='noreferrer'
            >
              <Button
                onClick={() => {
                  handleSelectUser(data)
                }}
              >
                Ver más
              </Button>
            </a>
          </>
        )}
      </DesktopModalContainer>
    </Modal>
  )
}

export default observer(BaseModalWrapper)
