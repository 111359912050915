/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from 'react'
import {RoleEnum} from '../../../auth/models/User/UserModel'
import {Container} from '../../../components/Container/index'
import {DashboardConsultant} from './DashboardConsultant'
import DashboardUser from './DashboardUser'
import {RootStoreContext} from '../../../stores/rootStore'
import {type ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import {getStaff} from 'app/modules/apps/staff/core/_requests'
import {useQuery} from 'react-query'
import {TranslationsWrapperStaff} from '../translations/staff/TranslationsWrapperStaff'
import {CaseWorkerDashboard} from './caseworker/CaseWorkerDashboard'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const DashboardWrapper: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const {ProfileImmigration} = props
  const {user} = rootStore.authStore
  const [consultan, setConsultant] = useState(false)
  const {data: StaffData, status: StaffStatus} = useQuery(
    'getStff',
    async () => await getStaff(user ? user.id : ''),
    {enabled: user !== undefined, cacheTime: 0}
  )
  const [staffId, setStaffId] = useState('')

  useEffect(() => {
    if (staffId === '' && StaffData !== undefined && StaffData[0] && StaffData[0].id) {
      setStaffId(StaffData[0].id)
    }
  }, [StaffData])

  useEffect(() => {
    if (user != null) {
      if (user.role?.id === RoleEnum.admin) {
        setConsultant(true)
      } else if (user.role?.id === RoleEnum.user) {
      }
    }
  }, [user])

  if ((StaffStatus === 'loading' || staffId === '') && user?.role?.id === RoleEnum.admin) {
    return <>Cargando..</>
  }
  return (
    <>
      <div 
      // style={{minHeight: '60rem'}}
      >
        <Container title=''>
          {StaffData &&
            user?.role?.id === 1 &&
            (StaffData[0].type === 'consultant' ||
              StaffData[0].type === 'consultant_admin' ||
              StaffData[0].type === 'education' ||
              StaffData[0].type === 'intake' ||
              StaffData[0].type === 'coo') && (
              <DashboardConsultant ProfileImmigration={ProfileImmigration} staffId={staffId} />
            )}
          {StaffData &&
            user?.role?.id === 1 &&
            (StaffData[0].type === 'translation' || StaffData[0].type === 'translation_admin') && (
              <TranslationsWrapperStaff ProfileImmigration={ProfileImmigration} />
            )}

          {StaffData && user?.role?.id === 1 && StaffData[0].type === 'caseworker' && (
            <CaseWorkerDashboard ProfileImmigration={ProfileImmigration} staffId={staffId} />
          )}

          {user?.role?.id === RoleEnum.user && <DashboardUser userDetected={user} />}
        </Container>
      </div>
    </>
  )
}

export {DashboardWrapper}
