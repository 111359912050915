import React, {useState} from 'react'
import {createInstitution} from 'app/modules/apps/cart/core/education/_request'
import {CountrySelect} from 'app/modules/utils/components/CountrySelect'
import Swal from 'sweetalert2'

interface CreateInstitutionFormProps {
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
  onInstitutionCreated: () => void
}
/* const API_URL = process.env.REACT_APP_API_URL;
const INSTITUTION_URL = `${API_URL}`; */

interface FormData {
  name: string
  province: string
  country: string
  address: string
  type: string
  partnership: string
}
const CreateInstitutionForm: React.FC<CreateInstitutionFormProps> = ({
  setShowTable,
  onInstitutionCreated,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    province: '',
    country: '',
    address: '',
    type: '',
    partnership: '',
    /*    owner: '', */
  })
  const [formVisible, setFormVisible] = useState(true)
  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    const errors: {[key: string]: boolean} = {}

    for (const key in formData) {
      if (!formData[key as keyof FormData]) {
        errors[key] = true
      }
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }))

      Swal.fire({
        icon: 'error',
        title: 'Form Submission Error',
        text: 'Cannot submit the form with empty fields. Please fill out all fields.',
      })
      return
    }
    try {
      /*
      const response = await axios.post(`${INSTITUTION_URL}/institution/newInstitution`, formData); */
      const response = await createInstitution(formData)

      console.log('Institution created:', response)

      setFormData({
        name: '',
        province: '',
        country: '',
        address: '',
        type: '',
        partnership: '',
        /*         owner: '', */
      })

      setShowTable(true)
      setFormVisible(false)
      onInstitutionCreated()
      setFieldErrors({
        name: false,
        province: false,
        country: false,
        address: false,
        type: false,
        partnership: false,
      })
    } catch (error) {
      console.error('Error creating institution:', error)
    }
  }

  const provinces = [
    {
      name: 'Alberta',
    },

    {
      name: 'British Columbia',
    },

    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
  ]

  const [fieldErrors, setFieldErrors] = useState({
    name: false,
    province: false,
    country: false,
    address: false,
    type: false,
    partnership: false,
  })
  return (
    <div className='form-container1'>
      {formVisible && (
        <form onSubmit={handleSubmit} className='custom-form1'>
          <div className='form-group1'>
            <label className='label'>Name:</label>
            <input
              className='input'
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
            />
            {fieldErrors.name && <p className='error-message'>Name is required.</p>}
          </div>
          <div className='form-group1'>
            <label className='label'>Province</label>
            <select
              className='input'
              name='province'
              value={formData.province}
              onChange={handleChange}
            >
              <option value=''>Select a province</option>
              {provinces.map((province) => (
                <option key={province.name} value={province.name}>
                  {province.name}
                </option>
              ))}
            </select>
            {fieldErrors.province && <p className='error-message'>Province is required.</p>}
          </div>
          <div className='form-group1'>
            <label className='label'>Country:</label>
            <select
              className='input'
              name='country'
              value={formData.country}
              onChange={handleChange}
            >
              <CountrySelect />
            </select>
            {fieldErrors.country && <p className='error-message'>Country is required.</p>}
          </div>
          <div className='form-group1'>
            <label className='label'>Address:</label>
            <input
              className='input'
              type='text'
              name='address'
              value={formData.address}
              onChange={handleChange}
            />
            {fieldErrors.address && <p className='error-message'>Address is required.</p>}
          </div>
          <div className='form-group1'>
            <label className='label'>Type:</label>
            <input
              className='input'
              type='text'
              name='type'
              value={formData.type}
              onChange={handleChange}
            />
            {fieldErrors.type && <p className='error-message'>Type is required.</p>}
          </div>
          <div className='form-group1'>
            <label className='label'>Partnership:</label>
            <input
              className='input'
              type='text'
              name='partnership'
              value={formData.partnership}
              onChange={handleChange}
            />
            {fieldErrors.partnership && <p className='error-message'>Partnership is required.</p>}
          </div>
          {/*  <div className="form-group">
          <label className="label">Owner:</label>
          <input className="input" type="text" name="owner" value={formData.owner} onChange={handleChange} />
        </div> */}
          <button type='submit' className='submit-button1'>
            Create Institution
          </button>
        </form>
      )}
    </div>
  )
}

export default CreateInstitutionForm
