/* eslint-disable */
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useContext, useState, useEffect } from 'react'
import { CountrySelect } from '../../../../utils/components/CountrySelect'
import { type Profile } from '../../../../apps/profile/core/_models'
import { RootStoreContext } from '../../../../../../stores/rootStore'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import type { MenuProps } from 'antd'
import { DatePicker } from 'antd'
import './style.css'

import { Users } from 'app/modules/apps/users/core/_models'
import { updateEmailBasic } from 'app/modules/apps/profile/core/_requests'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  birth: Yup.date().required('birth name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'El teléfono solo puede contener números')
    .required('El teléfono es obligatorio')
    .min(10, 'El teléfono debe tener al menos 10 dígitos'),
  countryOfOrigin: Yup.string().required('Country of origin is required'),
  gender: Yup.string().required('gender is required'),
  nationalitie: Yup.string().required('Nationalities is required'),
  address: Yup.string().required('Address is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
})

interface Props {
  profile: Profile
  isUserLoading?: boolean
  updateProfileBasic: (profile: Profile) => void
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
  currentUser: Users
  setRefetchActivator: Dispatch<SetStateAction<boolean>>
  refetchActivator: boolean
}


const ProfileDetails2: React.FC<Props> = ({
  profile,
  isUserLoading,
  updateProfileBasic,
  showAvatar,
  edit = true,
  setTabActive,
  currentUser,
  setRefetchActivator,
  refetchActivator,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const { selectUser } = rootStore.usersStore
  const { getUser, getpProfile } = rootStore.userStore
  const intl = useIntl()
  const timeZones = moment.tz.names()
  const [loading, setLoading] = useState(false)
  const [activeView, setActiveView] = useState(1)
  const [isViewFieldsFilled, setIsViewFieldsFilled] = useState(false)
  const [newEmail, setNewEmail] = useState('')

  const formik = useFormik<Profile>({
    initialValues: profile,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      await updateProfileBasic(values)
      setLoading(true)
      setLoading(false)
    },
  })

  async function handleUpdateEmail(email:string) {
    if (email !== '') {
     const result= await updateEmailBasic(currentUser?.id,  email);
    }
  }

  const handleSelectUser = (user: Users) => {
    getpProfile(currentUser?.id ?? '')
    getUser(currentUser?.id ?? '')
    selectUser(user)
  }

  useEffect(() => {
    // Calcula la edad cuando cambie la fecha de nacimiento
    const birthDate = formik.values.birth
    if (birthDate instanceof Date) {
      const age = ageCalculator(birthDate)
      formik.setFieldValue('age', age)
    }
  }, [formik.values.birth])

  const ageCalculator = (formikBirthDate: Date) => {
    const currentDate = moment()
    const birthDate = moment(formikBirthDate)
    if (!birthDate.isValid()) {
      return 'No se puede calcular'
    }
    const age = currentDate.diff(birthDate, 'years')
    return age.toString() + ' años de edad'
  }

  // Función para verificar si todos los campos en el activeView actual están llenos
  const checkIfActiveViewFieldsFilled = () => {
    const fieldsToCheck =
      activeView === 1
        ? ['firstName', 'lastName', 'birth', 'gender']
        : activeView === 2
          ? ['address', 'phone']
          : activeView === 3
            ? ['countryOfOrigin', 'nationalitie', 'language', 'timeZone']
            : []
    const isFilled = fieldsToCheck.every(
      (fieldName) => formik.values[fieldName as keyof Profile] !== '')
    setIsViewFieldsFilled(isFilled)
  }

  useEffect(() => {
    // Llama a la función al cambiar de activeView
    checkIfActiveViewFieldsFilled()
  }, [activeView])

  useEffect(() => {
    // Llama a la función cuando se actualiza cualquier campo
    checkIfActiveViewFieldsFilled()
  }, [formik.values])

  useEffect(() => {
    if (refetchActivator) {
      getUser(currentUser?.id ?? '')
    }
    setRefetchActivator(false)
  }, [refetchActivator])
  const handleInputChange = (event: any) => {
    const { value } = event.target;
    const newValue = value.replace(/\D/g, ''); // Remover todos los caracteres no numéricos

    // Actualizar el valor del input con solo números
    event.target.value = newValue;
  };

  return (
    <>
      <div id='kt_account_profile_details'>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className='form'
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className=' bg-white'>
            <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
              DATOS PERSONALES
            </H3>
            <div className='container-form-modal'
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                borderRadius: '10px',
                paddingTop: '1rem',
                marginBottom: '1rem'
              }}>
              <div className='container-question'>
                <H3 className='ml-3 w-full col-form-label title-forms'>
                  Nombre
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    type='text'
                    placeholder='Nombre'
                    {...formik.getFieldProps('firstName')}
                    className='custom-input' />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Alert variant='danger'>{formik.errors.firstName}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Apellido
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    type='text'
                    placeholder='Apellidos'
                    {...formik.getFieldProps('lastName')}
                    className='custom-input' />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Alert variant='danger'>{formik.errors.lastName}</Alert>
                  )}
                </div>
              </div>

              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Fecha de nacimiento
                </H3>
                <div className='col-lg-8 fv-row'>
                  <DatePicker
                    className='custom-datepicker'
                    style={{ border: '1px solid gray' }}
                    value={
                      (formik.getFieldProps('birth').value !== '' && formik.getFieldProps('birth').value !== null)
                        ? moment.utc(formik.getFieldProps('birth').value)
                        : null}
                    onChange={(date) => {
                      const formattedDate = moment.utc(date).format('YYYY-MM-DD')
                      formik.setFieldValue('birth', formattedDate)
                    }}
                    data-date-format='yyyy-MM-dd' />
                </div>
                {formik.touched.birth && formik.errors.birth && (
                  <Alert variant='danger'>{formik.errors.birth}</Alert>
                )}
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Edad
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    className='custom-input '
                    type='text'
                    placeholder='Edad'
                    value={ageCalculator(formik.getFieldProps('birth').value)}
                    readOnly
                  />
                  {formik.touched.birth && formik.errors.birth && (
                    <Alert variant='danger'>{formik.errors.birth}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Dirección
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    className='custom-input'
                    type='text'
                    placeholder='Dirección'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <Alert variant='danger'>{formik.errors.address}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='col-lg-4 col-form-label title-forms'>
                  Teléfono
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    type='tel'
                    placeholder='Teléfono'
                    {...formik.getFieldProps('phone')}
                    className='custom-input' />
                  {formik.touched.phone && formik.errors.phone && (
                    <Alert variant='danger'>{formik.errors.phone}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  <span className='required'>
                    País en el que reside
                  </span>
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select {...formik.getFieldProps('countryOfOrigin')}
                    className='custom-input'>
                    <CountrySelect />
                  </Select>
                  {formik.touched.countryOfOrigin && formik.errors.countryOfOrigin && (
                    <Alert>{formik.errors.countryOfOrigin}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Nacionalidad
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select {...formik.getFieldProps('nationalitie')}
                    className='custom-input'>
                    <CountrySelect />
                  </Select>
                  {formik.touched.nationalitie && formik.errors.nationalitie && (
                    <Alert>{formik.errors.nationalitie}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Idioma
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select {...formik.getFieldProps('language')}
                    className='custom-input'>
                    <option value=''>Seleccione</option>
                    <option value='en'>English</option>
                    <option value='es'>Español - Spanish</option>
                    <option value='fr'>Français - French</option>
                  </Select>
                  {formik.touched.language && formik.errors.language && (
                    <Alert>{formik.errors.language}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Zona Horaria (Ciudad)
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select {...formik.getFieldProps('timeZone')} className='custom-input'>
                    {timeZones.map((timeZone: string) => {
                      return (
                        <option key={timeZone} value={timeZone}>
                          {timeZone}
                        </option>
                      )
                    })}
                  </Select>

                  {formik.touched.timeZone && formik.errors.timeZone && (
                    <div className='fv-plugins-message-container'>
                      <Alert variant='danger'>
                        <div className='fv-help-block'>{formik.errors.timeZone}</div>
                      </Alert>
                    </div>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Género
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select {...formik.getFieldProps('gender')} className='custom-input'>
                    <option value=''>Seleccione</option>
                    <option value='M'>Masculino</option>
                    <option value='F'>Femenino</option>
                    <option value='O'>Otros</option>
                  </Select>
                  {formik.touched.gender && formik.errors.gender && (
                    <Alert>{formik.errors.gender}</Alert>
                  )}
                </div>
              </div>
              {staff.type === 'client_support' || staff.type === 'consultant_admin' ?
              <div className='container-question'>
                <H3 className='ml-3 col-form-label title-forms'>
                  Email
                </H3>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    defaultValue={currentUser.email}
                    placeholder='Email'
                    onChange={(e)=>{setNewEmail(e.target.value)}}
                    className='custom-input' />
                  {formik.touched.lastName && formik.errors.email && (
                    <Alert variant='danger'>{formik.errors.email}</Alert>
                  )}
                </div>
              </div>: null}
            </div>
          </div>
          <div className='container-button mb-8'>
            <Button
              className='button-ui button-primary '
              type='submit'
              disabled={loading}
              onClick={() => {
                handleUpdateEmail(newEmail)
                if (isEmpty(formik.errors) && setTabActive != null) {
                  setTabActive('homenew1')
                  handleSelectUser(currentUser)
                  setRefetchActivator(true)
                }
              }}
            >
              Guardar datos
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default observer(ProfileDetails2)
