import tw from 'tailwind-styled-components'

interface IVariants {
  variant: 'badge_success' | 'badge_warning' | 'badge_light' | 'badge_danger' | 'badge_info'
}

interface DivProps {
  width?: string
  height?: string
  disabled?: boolean
  variant?: IVariants
  outline?: boolean
  active?: boolean
  badge_success?: boolean
  badge_light?: boolean
  badge_warning?: boolean
}

const LeftSideBar = tw.div`
  w-full 
  overflow-hidden 
  lg:w-2/12  
`

const CategoryItemList = tw.ul`
  flex 
  flex-wrap 
  overflow-hidden
`

const CategoryItem = tw.li<DivProps>`
  text-gray-500
  font-semibold
  w-full 
  overflow-hidden 
  flex 
  text-xs 
  hover:bg-gray-300  
  rounded-xl
  items-center  
  pl-2
  py-2
  cursor-pointer
  mr-px-2
  border

 ${(p: any) => (p.active ? 'bg-rosa-500 border-tomato-500 border-r-4 ' : '')}

`

const CategoryItemTitle = tw.span`
  m-0
  p-0;
  border-0;
  inherit;
  align-baseline;
  text-sm;

`

const CategoryItemNumber = tw.span<DivProps>`
  font-semibold
  rounded-md
  px-2
  py-0.5

${(p: any) => (p.badge_success ? 'bg-verde-600 text-verde-900 ' : '')}
${(p: any) => (p.badge_warning ? 'bg-yellow-500 text-brown-500 ' : '')}
${(p: any) => (p.badge_light ? 'bg-gray-100 text-black ' : '')}
${(p: any) => (p.badge_danger ? 'bg-danger-100 text-danger-500 ' : '')}
${(p: any) => (p.badge_info ? 'bg-info-500 text-info-500 ' : '')}
`
export {LeftSideBar, CategoryItemList, CategoryItem, CategoryItemTitle, CategoryItemNumber}
