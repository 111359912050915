/* eslint-disable @typescript-eslint/no-unused-vars */
import {type FC, useContext} from 'react'
import ClientServicesMenusoptions from './services/servicesMenuOption/index'
import {Container} from '../../../components/Container/index'
import {RootStoreContext} from '../../../stores/rootStore'
import {useIntl} from 'react-intl'

const ServicesWrapper: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.MYSERVICES'})}>
        <ClientServicesMenusoptions />
      </Container>
    </>
  )
}

export {ServicesWrapper}
