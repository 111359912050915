/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  ResponseApointment,
  type AppointmentsAdmin,
  type IComposeData,
  IApointments,
  IReply,
} from '../../../../app/modules/apps/appointments/core/_models'
import { Link } from '../../../../components'
import { Button, DatePicker, Input, InputRef, Space, Table, Tooltip } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SiGooglemeet } from 'react-icons/si'
import { AiOutlineMail } from 'react-icons/ai'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import { DateTime } from 'luxon'
import DialogComponent from '../../../../components/Dialog'
import MenuOptions from '../MenuOptions'
import { RootStoreContext } from '../../../../stores/rootStore'
import UserProfile from '../../../../app/pages/user/UserProfile'
import { useIntl } from 'react-intl'
import { ProfileImmigration } from 'app/modules/apps/profile/core/_models'
import Column from 'antd/lib/table/Column'
import Arrow from '../../dashboard/userDasboardComponents/userDashboardIcons/arrow-right-black.svg'
import {
  ColumnFilterItem,
  ColumnType,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { RangeValue } from 'rc-picker/lib/interface'
import { getUserUsersById } from 'app/modules/apps/users/core/_requests'
import { Users } from 'app/modules/apps/users/core/_models'
import '../../admin/users/List/style.css'
import { RoleEnum } from 'auth/models/User/UserModel'

interface Props {
  appointments?: ResponseApointment
  setComposePost: React.Dispatch<React.SetStateAction<boolean>>
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>
  setComposeNote: React.Dispatch<React.SetStateAction<boolean>>
  zone: string
  ProfileImmigration: ProfileImmigration
  setQuery: (query: IApointments) => void
  query: IApointments | null
}
interface ButtonHoverStates {
  [buttonId: string]: boolean;
}
const AppointmentDetails: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const { selectUser } = rootStore.usersStore
  const { user } = rootStore.authStore
  const { ProfileImmigration } = props
  const [open, setOpen] = React.useState(false)
  const [opendataUser, setDataUser] = React.useState<IComposeData | null>(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [nameInfo, setNameInfo] = useState<ColumnFilterItem[]>()
  const intl = useIntl()
  const { appointments, setComposePost, setComposePostData, zone, setQuery, query } = props
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const searchInput = useRef<InputRef>(null)
  const [currentProductName, setCurrentProductName] = useState<string[]>()
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [buttonHoverStates, setButtonHoverStates] = useState<ButtonHoverStates>({});
  const getDateAdjustedForConsultant = (date: any) => {
    const adjustedDate = DateTime.fromISO(date).plus({ hours: 1 }).toFormat('yyyy-MM-dd HH:mm');
    return adjustedDate.toString();
  };
  const handleHoverChange = (buttonId: string, isHovered: boolean) => {
    setButtonHoverStates((prevStates) => ({
      ...prevStates,
      [buttonId]: isHovered,
    }));
  };
  type DataIndex = keyof AppointmentsAdmin
  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, { zone }).toFormat('yyyy-MM-dd HH:mm a')

    return keepOffset.toString()
  }

  const handleOpenMeet = (value: any) => {
    window.open(value, '_blank')
  }

  
  const handleOpenPost = (data: any) => {
    setComposePost(true)
    setComposePostData(data)
  }

  useEffect(() => {
    if (nameInfo === undefined && appointments && appointments.data.length > 0) {
      setNameInfo(
        appointments?.data.map((item: any) => {
          if (item.name !== undefined && item.name !== null) {
            return {
              text: item.name,
              value: item.name,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }
  }, [appointments])

  const handleSearchCustom = (type: string, value: string) => {
    if (type === 'email') {
      setCurrentEmail(value)
      try {
        const params: any = {
          ...query,
          email: value,
          startDate: '',
          endDate: '',
        }
        setFromDate(null)
        setToDate(null)
        setQuery(params)
      } catch (error) { }
    }
  }
  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<AppointmentsAdmin> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              selectedKeys[0] = ''
              setCurrentEmail('')
              handleSearchCustom('email', '')
            }}
            size='small'
            style={{ width: 90 }}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record: any) =>
      record.email
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const onChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<AppointmentsAdmin> | SorterResult<AppointmentsAdmin>[],
    extra: TableCurrentDataSource<AppointmentsAdmin>
  ) => {
    setCurrentPage(pagination.current)
    if (filters.productName !== null) {
      setCurrentProductName(filters.productName)
    }

    if (filters.productName === null) {
      setCurrentProductName([])
    }
  }

  useEffect(() => {
    try {
      if (query !== null) {
        const params: IApointments = {
          ...query,
          page: currentPage,
          description: currentProductName,
        }

        setQuery(params)
      }
    } catch (error) { }
  }, [currentPage, currentProductName])

  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: any = {
          ...query,
          startDate: values[0].format('DD-MM-YYYY'),
          endDate: values[1].format('DD-MM-YYYY'),
          email: '',
        }

        setQuery(params)
      } else {
        const params: any = {
          ...query,
          startDate: '',
          endDate: '',
        }
        setQuery(params)
      }
    } catch (error) { }
  }

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }
  const findUser = async (fromId: string) => {
    const result: Users | undefined | void = await getUserUsersById(fromId)
    if (result !== undefined) {
      selectUser(result)
    }
  }

  const currentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();

    // Agregar un cero delante si el mes o el día son menores que 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  };

  const formattingTime = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });
    return formattedTime
  };

  const selectedUser=async (userId:string)=>{
   const user= await getUserUsersById(userId)
   if(user) selectUser(user)
  }


  return (
    <>
      <div className='flex flex-col w-full'>
        <div className='card-title'>
          <img alt="Calendar" src='/media/icons/app/menu/Group.svg' />
          <h1 >Próximas citas</h1>
        </div>
        <p>Hoy es {currentDate()}</p>
        {appointments && appointments.data.length > 0 &&
          <> <p className='today-date'>Tienes <b>{appointments.data.filter((eachAppointment: any) => {
            return eachAppointment.start_time.substring(0, 10) === currentDate();
          }).length} cita(s)</b> para hoy</p>
            <div>
              {appointments.data.map((eachAppointment: any) => {
                // Aquí puedes realizar operaciones con cada cita si lo necesitas
                if (eachAppointment.start_time.substring(0, 10) === currentDate()) {
                  return (
                    <div className='consultant-date' key={eachAppointment.id}>
                      {/* Renderizar información de cada cita */}
                      <div className='div-name-date'>
                        <p className='date-consult'>{getDate(eachAppointment.start_time).substring(11, 16)} </p>
                        <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '180px' }} className='name-cosult'>{eachAppointment.first_name} {eachAppointment.last_name} </p>


  {user?.role?.id === RoleEnum.admin && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
    <Link className='link' onClick={() => { selectedUser(eachAppointment.user_id) }} to='/userslist'>
      <b style={{color:'#01A9DB'}} >Ver perfil</b>
      {/* Add the image with the specified src attribute */}
      <img src="/media/icons/app/menu/user-icon.svg" alt="User Icon" style={{ marginLeft: '5px' }} />
    </Link>
    </div>
  )}



                      </div>

                      {/*   <p>{eachAppointment.email} </p> */}

                      <div className='div-description' style={{ marginTop: '0.3rem' }}>
                        <p className='description-consult'>{eachAppointment.description.replace(/<\/?[^>]+(>|$)/g, '')} </p>
                      </div>
                      <div className='container-meet' >
                          <Tooltip placement='top' title={'Google meet'}>
                            <button
                              className='button'
                              onClick={() => {
                                handleOpenMeet(`${eachAppointment.join_url}`);
                              }}
                              onMouseEnter={() => handleHoverChange(eachAppointment.id, true)}
                              onMouseLeave={() => handleHoverChange(eachAppointment.id, false)}
                            >
                              <div className='div-meet'>
                                <img
                                  alt="Calendar"
                                  className='meet-image'
                                  src={buttonHoverStates[eachAppointment.id] ? '/media/icons/app/menu/google-meet.svg' : '/media/icons/app/menu/google-meet-disable.svg'}

                                />
                                <b className='meet-text'>Unirme</b>
                              </div>
                            </button>
                          </Tooltip>

                        </div>
                      <div className='line-container-consult'> </div>
                    </div>
                  );
                } else {
                  return null; // Otra acción si no se cumple la condición
                }
              })}
            </div>

          </>
        }
      </div>
      <div className="button-container">
        <button
          className='button-ui button-secondary button2'

          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <Link to='/appointmentsbyconsultant/list' className='div-meet2'>
            <p className='meet-text2'>VER MIS CITAS</p>
            <img
              alt="Calendar"
              className='meet-image2'
              src={isHovered2 ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'}
            />

          </Link>
        </button>
      </div>
    </>
  );
};


export { AppointmentDetails }







/* return (
  <>
    <DialogComponent
      children={
        <>
          {opendataUser != null && (
            <UserProfile
              composepostdata={opendataUser}
              edit={true}
              ProfileImmigration={ProfileImmigration}
            />
          )}
        </>
      }
      title='Perfil del usuario'
      isOpen={open}
      closeModal={() => {
        setOpen(false)
      }}
      width='w-full'
      height='h-full'
    />
    {currentProductName && currentProductName.length > 0 ? (
      <>
        {' '}
        <h1>Filtros: </h1>
        <div
          style={{
            border: '2px solid #bf3c3c',
            display: 'block',
            padding: '0.5rem',
            paddingTop: '1rem',
            borderRadius: '10px',
            marginBottom: '2rem',
          }}
        >
          {currentProductName.length > 0 && (
            <div style={{display: 'flex', marginBottom: '0.002rem'}}>
              <Button
                style={{
                  width: '13rem',
                  marginRight: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                onClick={() => {
                  setCurrentProductName([])
                }}
              >
                <p>Descripcion</p> <p>X</p>
              </Button>
              <input
                title='recidence'
                className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                value={currentProductName.toString()}
                readOnly
              ></input>{' '}
            </div>
          )}
        </div>
      </>
    ) : null}

    <Table
      style={{overflow: 'auto', position: 'relative'}}
      className={appointments?.data && appointments.data.length === 0 ? 'tableantdauto' : ''}
      scroll={{x: 'max-content', y: 500}}
      pagination={{
        pageSize: 50,
        showLessItems: true,
        size: 'small',
        responsive: true,
        simple: true,
        showQuickJumper: false,
        showSizeChanger: false,
        current: currentPage,
        total: Number(appointments?.total) || 0,
      }}
      dataSource={appointments?.data}
      onChange={onChange}
    >
      <Column
        render={(record: AppointmentsAdmin) => (
          <Space size='middle' style={{width: '4rem'}}>
            <Tooltip placement='top' title={'Google meet'}>
              <Button
                style={{background: 'white', border: '1px solid #301DAE'}}
                onClick={() => {
                  handleOpenMeet(`${record.join_url}`)
                }}
              >
                {<SiGooglemeet />}
              </Button>
            </Tooltip>
          </Space>
        )}
      />

      <Column
        render={(record: AppointmentsAdmin) => (
          <Space size='middle' style={{width: '4rem'}}>
            <Tooltip placement='top' title={'Mensaje post consulta'}>
              <Button
                style={{background: 'white', border: '1px solid #FEA102'}}
                onClick={() => {
                  handleOpenPost({
                    orderDetailId: record.id,
                    user: `${record.first_name} ${record.last_name}`,
                    email: record.email || '',
                    userId: record.user_id,
                    fromId: record.fromId,
                    messageId: null,
                    fromUser: record.from || '',
                    folder: 'inbox',
                    draft: false,
                  })
                }}
              >
                {<AiOutlineMail />}
              </Button>
            </Tooltip>
          </Space>
        )}
      />

      <Column
        width={50}
        render={(record: AppointmentsAdmin) => (
          <Space
            className='cursor-pointer'
            onClick={() => {
              setDataUser({
                orderDetailId: record.id,
                user: `${record.first_name} ${record.last_name}`,
                email: record.email || '',
                userId: record.user_id,
                fromId: record.staffUserId || '',
                messageId: null,
                fromUser: '',
                folder: 'inbox',
                draft: false,
              })
              setOpen(true)
            }}
          >
            <AvatarProfileUsers
              style={{width: '10rem'}}
              image={
                record.avatarID ? `${record.avatarPath}/${record.avatarID}-avatar-small.jpg` : ''
              }
              size={25}
            />
          </Space>
        )}
      />

      <Column
        width={250}
        title={intl.formatMessage({id: 'PROFILE.FIRSTNAME'})}
        render={(record: AppointmentsAdmin) => (
          <Space>
            <Link to='/usersList'>
              <div
                style={{color: 'black'}}
                onClick={() => {
                  findUser(record.user_id)
                }}
              >
                {record.first_name + ' ' + record.last_name}
              </div>
            </Link>
          </Space>
        )}
      />

      <Column
        width={300}
        key='email'
        dataIndex={'email'}
        {...getColumnSearchPropsEmail('email')}
        title={intl.formatMessage({id: 'PROFILE.EMAIL'})}
      />

      <Column
        width={180}
        title={intl.formatMessage({id: 'CORE.DATE'}) + ` (${zone})`}
        render={(record: AppointmentsAdmin) => (
          <Space size='middle'>
            <p>{getDate(record.start_time)}</p>
          </Space>
        )}
        filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
          <div style={{padding: 8}}>
            <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
              <Button
                type='primary'
                onClick={() => {
                  confirm()
                  handleDate([fromDate, toDate])
                  setCurrentEmail('')
                }}
                size='small'
                style={{width: 90, marginRight: 8}}
              >
                Filtrar
              </Button>
              <Button
                onClick={() => {
                  setFromDate(null)
                  setToDate(null)
                  handleDate(['', ''])
                }}
                size='small'
                style={{width: 90}}
              >
                Limpiar
              </Button>
            </div>
          </div>
        )}
      />

      <Column
        width={450}
        key={'productName'}
        title={intl.formatMessage({id: 'CORE.TYPE'})}
        render={(record: AppointmentsAdmin) => (
          <Space size='middle' style={{}}>
            {record.name}
          </Space>
        )}
        filters={nameInfo?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
        )}
        onFilter={(value, record: any) => {
          return record.name === value.toString()
        }}
        filteredValue={currentProductName}
      />

      <Column
        render={(record: AppointmentsAdmin) => (
          <Space
            size='middle'
            style={{
              zIndex: 999,
              height: 'fit-content',
            }}
          >
            <MenuOptions appointment={record} />
          </Space>
        )}
      />
    </Table>
  </>
) */
