/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import {
  Item,
  ItemInbox,
  MailAvatar,
} from '../../../../../core/components/email'
import React from 'react'
import AvatarProfileUsers from '../../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import { type Messages } from '../../../../../app/modules/apps/messages/core/_models'
import TextMailSplit from '../../../../../core/components/email/TextMailSplit'
import '../../../../pages/admin/users/List/style.css'
import './theme.less'
import { updateMessagesFavorite, updateMessagesState, updateMessagesUnread } from 'app/modules/apps/messages/core/_requests'
import yellowStar from '../../../contratationApp/icons/star-fill.svg'
import EnvelopClose from '../../../contratationApp/icons/envelopeClose.svg'
import EnvelopOpen from '../../../contratationApp/icons/envelope open text.svg'
import StarUnfill from '../../../contratationApp/icons/black-star.svg'
import { RootStoreContext } from 'stores/rootStore'
import PDFIcon from '../../../contratationApp/icons/PDF.svg'

interface IProps {
  message: Messages
  folder: string
  HandleReadmessage: (id: string) => void
  handleClose?: () => void
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
  setComposePostData: React.Dispatch<React.SetStateAction<any>>
}
const MessageRows: React.FC<IProps> = ({
  message,
  folder,
  HandleReadmessage,
  handleClose,
  setShowMessageRows,
  setComposePostData
}) => {
  const rootStore = useContext(RootStoreContext)
  const { page, loadMessages, loadMessagesUnread, messagesUnread, setMessageSelected, loadMessagesNew, loadCounterMessagesAuth } = rootStore.messageStore
  const { user } = rootStore.authStore
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (active === true) {
      setTimeout(() => {
        loadMessages(folder)
        loadMessagesUnread()
        loadMessagesNew(folder, page)
        messagesUnread
      }, 200)
      setActive(false)
    }
  }, [active])

  const MessagesUpdateState = async () => {
    if (window.location.pathname.includes('/messages/imbox')) {
      const unread = true
      if (message.id && message.unread === false) {
        await updateMessagesState(unread, message.id?.toString())
        loadCounterMessagesAuth(user?.id)
      }

    }
  }
  const isSmallScreen = () => {
    return window.innerWidth < 950
  }

  const MessagesUpdateFavorite = async () => {
    let star = false
    message.favorite === false ? star = true : star = false
    if (message.id) {
      await updateMessagesFavorite(star, message.id?.toString())
    }
  }

  const MessagesUnread = async () => {
    let read = false
    message.unread === false ? read = true : read = false
    if (message.id) {
      await updateMessagesUnread(read, message.id?.toString())
      loadCounterMessagesAuth(user?.id)
    }
  }

  if (message.folder === folder || folder === 'search' || folder === 'draft') {
    return (
      <>
        {/* Vista para pantallas grandes */}
        {!isSmallScreen() && (
          <Item
            style={{ background: 'none', boxShadow: 'none', position: 'relative' }}
            key={message.id}
            onClick={() => {
              setMessageSelected(true)
              console.log("message", JSON.stringify(message))
              MessagesUpdateState()
              setActive(true)
              HandleReadmessage(message.id ? message.id?.toString() : '')
              if (handleClose != null) {
                handleClose()
              }
              if (screen.width < 1024) {
                setShowMessageRows(false)
              }
              if (folder === 'draft') {
                const data = {
                  messageId: message.id?.toString(),
                  content: message.content,
                  subject: message.subject,
                  messageIdRelation: message.messageId?.toString(),
                  to: message.to,
                  cc: "",
                  type: "new_message",
                  attachment: "",
                  files_info: JSON.parse(JSON.stringify(message.files_info)),
                  userId: message.userId,
                  email: message.email,
                  draft: true
                }
                setComposePostData(data)
              }
            }}
          >
            <ItemInbox
              className={`table-master my-custom-table2 row-hover3`}
              style={{ display: 'flex', alignItems: 'center' }}
            >

              {message.unread === false ?
                <div className='line-green' /> :
                <div className='line-hide' />
              }

              {folder !== 'sent' &&
                folder !== 'draft' &&
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    top: '10px',
                    left: '10px',
                    zIndex: 1,
                  }}
                >
                  {message.favorite === true ?
                    <img
                      style={{ width: '20px', marginLeft: '1rem' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        MessagesUpdateFavorite()
                        setActive(true)
                      }
                      }
                      src={yellowStar}
                      alt="yellowStar" />
                    :
                    <img
                      style={{ width: '20px', marginLeft: '1rem' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        MessagesUpdateFavorite()
                        setActive(true)
                      }
                      }
                      src={StarUnfill}
                      alt="Star" />
                  }
                  {message.unread === false ?
                    <img
                      style={{ width: '20px', marginLeft: '1rem' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        MessagesUnread()
                        setActive(true)
                      }
                      }
                      src={EnvelopClose}
                      alt="EnvelopClose" /> :
                    <img
                      style={{ width: '20px', marginLeft: '1rem' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        MessagesUnread()
                        setActive(true)
                      }
                      }
                      src={EnvelopOpen}
                      alt="EnvelopOpen" />
                  }
                </div>}
              <MailAvatar
                className=' sm:w-2/12 md:w-2/12 lg:w-2/12'
                style={{ marginTop: '7px', marginLeft: '40px', width: '5rem' }}>
                <AvatarProfileUsers style={{ width: '1rem', height: '1rem' }}
                  image={
                    message.fromPathId
                      ? `${message.fromPath}/${message.fromPathId}-avatar-small.jpg`
                      : '/media/avatars/avatar.svg'
                  }
                  size={40}
                />
              </MailAvatar>
              <div style={{ width: '15rem' }}>
                {message.fromName !== undefined && message.fromName.length > 0 &&
                  <p style={{ color: `${message.unread === false ? 'black' : '#4B5563'}`, marginTop: '14px', fontWeight: `${message.unread === false ? 'bold' : 'normal'}`, margin: '0' }}>
                    {`${message.fromName.slice(0, 50)}${message.fromName.length > 50 ? '...' : ''}`}
                  </p>}
                {message.fromEmail && message.fromEmail.length > 0 && <p style={{ color: `${message.unread === false ? 'black' : '#4B5563'}`, marginTop: '6px', fontWeight: `${message.unread === false ? 'bold' : 'normal'}`, margin: '0' }}>
                  {`${message.fromEmail.slice(0, 50)}${message.fromEmail.length > 50 ? '...' : ''}`}
                </p>}
              </div>
              <div style={screen.width < 1024 ? { width: '100%' } : {}} className='block lg:w-10/12'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='w-full'>
                    <div className='flex' style={{ width: '100%', marginLeft: '1rem' }}>
                      {message.subject.length > 0 &&
                        <p style={{
                          marginLeft: '2rem',
                          color: `${message.unread === false ? 'black' : '#4B5563'}`,
                          fontWeight: `${message.unread === false ? 'bold' : 'normal'}`,
                          margin: '0'
                        }}>
                          {message.subject.slice(0, 25)} -
                        </p>} &nbsp;
                      <p style={{
                        color: `${message.unread === false ? 'black' : '#4B5563'}`,
                        fontWeight: `${message.unread === false ? 'bold' : 'normal'}`,
                        margin: '0'
                      }}>
                        <TextMailSplit text={message.content} length={70} />
                      </p>
                    </div>
                    {
                      message.files_info &&
                      <div className='flex gap-2 ml-2 file-label'
                        style={{ width: 'max-content' }}>
                        {message.files_info[0] && (message.files_info[0].originalname !== null) &&
                          <div
                            style={{ border: '1px solid #01A9DB', borderRadius: '5px', display: 'flex', gap: '5px', padding: '2px 5px', width: 'max-content', cursor: 'pointer' }}
                          >
                            <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                            {message.files_info[0].originalname.length > 0 && <p style={{ color: '#01A9DB', margin: '0', fontSize: '12px' }}>{`${message.files_info[0].originalname.slice(0, 15)}${message.files_info[0].originalname.length > 15 ? '...' : ''}`}</p>}
                          </div>}
                        {message.files_info[1] && (message.files_info[0].originalname !== null) && <div
                          style={{ border: '1px solid #01A9DB', borderRadius: '5px', display: 'flex', gap: '5px', padding: '2px 5px', width: 'max-content', cursor: 'pointer' }}
                        >
                          <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                          {message.files_info[1].originalname.length > 0 && <p style={{ color: '#01A9DB', margin: '0', fontSize: '12px' }}> {`${message.files_info[1].originalname.slice(0, 15)}${message.files_info[1].originalname.length > 15 ? '...' : ''}`}</p>}
                        </div>
                        }
                        {message.files_info.length > 2 && <p style={{ color: '#01A9DB', margin: '0', fontSize: '1rem', fontWeight: `${message.unread === false ? 'bold' : 'normal'}` }}>+ {message.files_info.length - 2}</p>}
                      </div>}
                  </div>
                  <div
                    style={{ color: 'black', fontSize: '15px', width: '12rem', height: '5rem', display: 'flex', alignItems: 'center', marginLeft: '1rem' }}
                    className='w-1/3 text-right text-[8px] mr-1'>
                    <p style={{ color: `${message.unread === false ? 'black' : '#4B5563'}`, marginTop: '12px', fontWeight: `${message.unread === false ? 'bold' : 'normal'}`, margin: '0' }}>{message.created_at?.slice(0, 11)}</p>
                  </div>
                  <img
                    src="/media/avatars/Group.svg"
                    alt="Icon"
                    style={{ width: '24px', height: '24px', marginTop: '2px', marginRight: '10px' }} />
                </div>
                <div
                  style={screen.width < 1024 ? { display: 'flex' } : {}}
                  className='md:flex items-center'
                >
                </div>
              </div>
            </ItemInbox>
          </Item>
        )}

        {/* Vista para pantallas pequeñas */}
        {isSmallScreen() && (
          <Item
            style={{ width: '100%', background: 'none', boxShadow: 'none', position: 'relative' }}
            key={message.id}
            onClick={() => {
              setMessageSelected(true)
              MessagesUpdateState()
              setActive(true)
              HandleReadmessage(message.id ? message.id?.toString() : '')
              if (handleClose != null) {
                handleClose()
              }
              if (screen.width < 1024) {
                setShowMessageRows(false)
              }
              if (folder === 'draft') {
                const data = {
                  messageId: message.id?.toString(),
                  content: message.content,
                  subject: message.subject,
                  messageIdRelation: message.messageId?.toString(),
                  to: message.to,
                  cc: "",
                  type: "new_message",
                  attachment: "",
                  files_info: JSON.parse(JSON.stringify(message.files_info)),
                  userId: message.userId,
                  email: message.email,
                  draft: true
                }
                setComposePostData(data)
              }
            }}
          >
            <ItemInbox
              className={`table-master my-custom-table2 row-hover3`}
              style={{ display: 'flex', alignItems: 'center', height: 'max-content', width: '100%' }}
            >
              {message.unread === false ?
                <div className='line-green' /> :
                <div className='line-hide' />
              }
              <div
                style={{
                  top: '10px',
                  left: '10px',
                  zIndex: 1,
                  marginBottom: '40px'
                }}
              >
                <MailAvatar
                  className=' sm:w-2/12 md:w-2/12 lg:w-2/12'
                  style={{ marginLeft: '10px', width: '100%' }}>
                  <AvatarProfileUsers
                    style={{ width: '100%', height: '1rem' }}
                    image={
                      message.fromPathId
                        ? `${message.fromPath}/${message.fromPathId}-avatar-small.jpg`
                        : '/media/avatars/avatar.svg'
                    }
                    size={24} />
                </MailAvatar>
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: '10px', marginTop: '5px' }}>
                  <div className='w-full mb-2'
                    style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {message.fromName !== undefined && message.fromName.length > 0 &&
                      <p style={{
                        fontSize: '16px',
                        color: `${message.unread === false ? 'black' : '#4B5563'}`,
                        marginTop: '14px',
                        fontWeight: `${message.unread === false ? 'bold' : 'normal'}`,
                        margin: '0',
                        marginLeft: '1rem'
                      }}>
                        {`${message.fromName.slice(0, 10)}${message.fromName.length > 15 ? '...' : ''}`}
                      </p>}

                    {folder !== 'sent' &&
                    folder !== 'draft' &&
                      <>
                        {message.favorite === true ?
                          <img
                            style={{ width: '20px', marginLeft: '1rem' }}
                            onClick={(event) => {
                              event.stopPropagation()
                              MessagesUpdateFavorite()
                              setActive(true)
                            }}
                            src={yellowStar}
                            alt="yellowStar" />
                          :
                          <img
                            style={{ width: '20px', marginLeft: '1rem' }}
                            onClick={(event) => {
                              event.stopPropagation()
                              MessagesUpdateFavorite()
                              setActive(true)
                            }}
                            src={StarUnfill}
                            alt="Star" />
                        }
                        {message.unread === false ?
                          <img
                            style={{ width: '20px', marginLeft: '1rem' }}
                            onClick={(event) => {
                              event.stopPropagation()
                              MessagesUnread()
                              setActive(true)
                            }
                            }
                            src={EnvelopClose}
                            alt="EnvelopClose" /> :
                          <img
                            style={{ width: '20px', marginLeft: '1.5rem' }}
                            onClick={(event) => {
                              event.stopPropagation()
                              MessagesUnread()
                              setActive(true)
                            }
                            }
                            src={EnvelopOpen}
                            alt="EnvelopOpen" />
                        }
                      </>}
                    <p style={{
                      color: `${message.unread === false ? 'black' : '#4B5563'}`,
                      marginTop: '12px',
                      fontWeight: `${message.unread === false ? 'bold' : 'normal'}`,
                      margin: '0',
                      marginLeft: '1rem',
                      marginRight: '1rem'
                    }}>
                      {message.created_at?.slice(5, 11)}
                    </p>

                  </div>
                  <div className='flex flex-col'
                    style={{ width: '100%', marginLeft: '1rem' }}>
                    {message.subject.length > 0 &&
                      <p style={{
                        color: `${message.unread === false ? 'black' : '#4B5563'}`,
                        fontWeight: 'bold',
                        margin: '0',
                        fontSize: '12px'
                      }}>
                        {message.subject.slice(0, 30)} -</p>}
                    <p style={{
                      color: `${message.unread === false ? 'black' : '#4B5563'}`,
                      fontWeight: `${message.unread === false ? 'bold' : 'normal'}`,
                      margin: '0',
                      fontSize: '12px'
                    }}>
                      <TextMailSplit text={message.content} length={45} />
                    </p>
                  </div>
                </div>
              </div>
              <div style={
                screen.width < 1024 ? { width: '10%' } : {}}
                className='block lg:w-10/12'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src="/media/avatars/Group.svg"
                    alt="Icon"
                    style={{ width: '24px', height: '24px', marginTop: '2px', marginRight: '10px' }} />
                </div>
                <div
                  style={screen.width < 1024 ? { display: 'flex' } : {}}
                  className='md:flex items-center'
                >
                </div>
              </div>
            </ItemInbox>
          </Item>
        )}
      </>
    )
  } else {
    return null
  }
}

export default MessageRows
