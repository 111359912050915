/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {SmileOutlined} from '@ant-design/icons'
import React, {useContext, useEffect, useState} from 'react'
import {Result, Row} from 'antd'
import {Box} from '../../../../../core/layout/components/Box/Box'
import {Container} from 'react-bootstrap'
import ListFiles from '../../../../../app/pages/translations/common/ListFiles'
import LoadingBar from 'react-top-loading-bar'
import ModalLoader from '../../../../../core/components/ModalLoader'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {type Translations} from '../../../../../app/modules/apps/translations/core/_models'
import {observer} from 'mobx-react'

interface IProps {
  translation: Translations
  setTranslation: (translations: Translations | null) => void
}

const ShowTranslationService: React.FC<IProps> = ({translation, setTranslation}) => {
  const rootStore = useContext(RootStoreContext)
  const {
    isLoadingTranslation,
    translationsFiles,
    translationsResult,
    getTranslation,
    getTranslationResult,
    downloadFile,
  } = rootStore.translationStore

  const {user} = rootStore.authStore
  const [percent, setPercent] = useState(-1)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (translation) {
      getTranslation(translation.id || '')
      getTranslationResult(translation.id || '')
    }
  }, [translation])

  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  const handleDownload = (id: string) => {
    downloadFile(id, getProgress)
  }

  const handlePreview = (id: string) => {
    // comentario para desactivar el error de eslint
  }

  return (
    <div>
      <Container>
        {isLoadingTranslation ? (
          <div>
            <ModalLoader />
          </div>
        ) : null}

        <br />

        {user?.role?.name === 'User' && translationsResult.length > 0 ? <></> : null}
        <LoadingBar
          color='#BE8575'
          progress={percent}
          height={5}
          shadow={true}
          shadowStyle={{boxShadow: '0 0 20px #BE8575, 0 0 20px #BE8575'}}
        />
        <Row style={{width: '100%'}}>
          <Box className='ant-col'>
            <h2>Tus archivos</h2>
            <ListFiles
              downloadFile={handleDownload}
              data={translationsFiles}
              previewFile={handlePreview}
            />
          </Box>

          <Box className='ant-col' style={{width: '100%'}}>
            {translationsResult?.length > 0 ? (
              <>
                <h2>Traducción</h2>
                <ListFiles
                  downloadFile={handleDownload}
                  data={translationsResult}
                  previewFile={handlePreview}
                />
              </>
            ) : (
              <div>
                <Result
                  icon={<SmileOutlined className='' />}
                  title='Traduccion Pendiente!'
                  subTitle='Estamos trabajando en tu traducción, pero no te preocupes, te avisaremos cuando esté lista.'
                />
              </div>
            )}
          </Box>
        </Row>
      </Container>
    </div>
  )
}

export default observer(ShowTranslationService)
