 
 
/* eslint-disable  */
import './style.css'
import {
  type IQueryUsers,
  type Users,
  type IQueryUsersFilter,
} from '../../../../modules/apps/users/core/_models'
import React, { type Key, useContext, useEffect, useRef, useState } from 'react'
import AvatarProfileUsers from '../../../../modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { Button, Checkbox, Input, type InputRef, Space, Table } from 'antd'
import {
  type ColumnFilterItem,
  type ColumnType,
  type SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import type { TableRowSelection } from 'antd/es/table/interface'
import {
  getPartnetship,
  getTemplateSendingBluee,
} from '../../../../modules/apps/users/core/_requests'
import { useQuery } from 'react-query'
import moment, { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { DatePicker } from 'antd'
import { RootStoreContext } from '../../../../../stores/rootStore'
import { getUserProfile } from '../../../../modules/apps/user/core/_requests'
import { getAllStaff } from 'app/modules/apps/staff/core/_requests'
import { getProductsTableFilter } from 'app/modules/apps/cart/core/products/_requests'
import ManualTranslationModal from 'app/pages/translations/admin/ManualTranslationModal'
import NotesModal from './Slide'
import image from './image/avatar.svg'
import notes from './image/notes.svg'
import arrow from './image/arrow.svg'
interface IProps {
  search: IQueryUsers
  searchFilter: IQueryUsersFilter
  setSearch: (search: IQueryUsers) => void
  searchQuery: IQueryUsers
}

const UserList: React.FC<IProps> = ({ search, setSearch, searchQuery }) => {
  const { Column } = Table
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const { getUsersNewQuery, users, selectUser, isLoadingUsers } = rootStore.usersStore
  const { user: userAUTH, staff: staffAuth } = rootStore.authStore
  const { loadCounterMessagesClient } = rootStore.messageStore
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRowEmail, setSelectedRowEmail] = useState<string[]>([])
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Users>>({})
  const [lastServiceInfo, setLastServiceInfo] = useState<ColumnFilterItem[]>()
  const [partnershipInfo, setPartnershipInfo] = useState<ColumnFilterItem[]>()
  const [countryOfOriginInfo, setCountryOfOriginInfo] = useState<ColumnFilterItem[]>()
  const [nationalityInfo, setNationalityInfo] = useState<ColumnFilterItem[]>()
  const [staffInfo, setStaffInfo] = useState<ColumnFilterItem[]>()
  const [sbData, setSbData] = useState<Array<{ value: string; label: string }>>([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [ifDataFiltered, setIfDataFiltered] = useState<Users[]>([])
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentCountryOfOrigin, setCurrentCountryOfOrigin] = useState([])
  const [currentCountryOfNationality, setCurrentCountryOfNationality] = useState([])
  const [currentStaffName, setCurrentStaffName] = useState([])
  const [currentUTM, setCurrentUTM] = useState([])
  const [currentLastService, setCurrentLastService] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({
    nationalitie: [],
    countryOfOrigin: [],
    last_service: [],
    last_service_date_jotform_name: [],
    utm: [],
  })
  const [edit, setEdit] = useState<boolean>(false)
  const [idSelected, setIdSelected] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState<any>()
  const { data: sendingBlueData, refetch } = useQuery(
    'getAllSendingBlueTemplates',
    async () => await getTemplateSendingBluee()
  )
  const { data: StaffData } = useQuery('getStf', async () => await getAllStaff())
  const { data: productData } = useQuery('getPro', async () => await getProductsTableFilter())
  const { data: parnershipData } = useQuery('getPar', async () => await getPartnetship())

  const handleSearchDatePicker = (fromDate: Moment, toDate: Moment) => {
    setCurrentPage(1)
    setSearch({
      ...searchQuery,
      startDate: moment.utc(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      endDate: moment.utc(toDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      page: currentPage,
    })
  }

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }

  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof Users

  const handleSearchCustom = (type: string, value: string) => {
    setCurrentPage(1)
    if (type === 'email') {
      setCurrentEmail(value)
    }
  }

  useEffect(() => {
    if (sendingBlueData != null) {
      setSbData(
        sendingBlueData.map((template) => {
          return {
            value: template.id.toString(),
            label: '#' + `${template.id.toString()} ` + template.name,
          }
        })
      )
    }
  }, [sendingBlueData])

  useEffect(() => {
    if (users != null && users?.data != null) {
      setLastServiceInfo(
        users.data?.map((item) => {
          if (item.last_service !== undefined) {
            return {
              text: item.last_service,
              value: item.last_service,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )

      const fetchData = async () => {
        try {
          const response = await fetch('https://surveyjs.io/api/CountriesExample')
          const data = await response.json()
          setCountryOfOriginInfo(
            data?.map((item: any) => {
              if (item.name !== undefined) {
                return {
                  text: item.name,
                  value: item.name,
                }
              } else {
                return {
                  text: '',
                  value: '',
                }
              }
            })
          )

          setNationalityInfo(
            data?.map((item: any) => {
              if (item.name !== undefined) {
                return {
                  text: item.name,
                  value: item.name,
                }
              } else {
                return {
                  text: '',
                  value: '',
                }
              }
            })
          )
        } catch (error) {
          console.error('Error fetching country of origin info:', error)
        }
      }
      fetchData()

      setStaffInfo(
        StaffData?.map((item: any) => {
          if (item.jotformName !== undefined) {
            return {
              text: item.jotformName,
              value: item.jotformName,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )

      setPartnershipInfo(
        parnershipData?.map((utm: any) => {
          if (utm !== undefined) {
            return {
              text: utm,
              value: utm,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )

      setLastServiceInfo(
        productData?.data?.map((item: any) => {
          if (item.name !== undefined) {
            return {
              text: item.name,
              value: item.name,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }

    if (users !== undefined && users?.data !== undefined) {
      setIfDataFiltered(users.data)
    }
  }, [users])

  useEffect(() => {
    if (staffAuth && userAUTH) {
      getUsersNewQuery({
        limit: 50,
        page: currentPage,
        email: currentEmail,
        startDate: moment.utc(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
        endDate: moment.utc(toDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
        countryOfOrigin: currentCountryOfOrigin,
        nationalitie: currentCountryOfNationality,
        staffName: currentStaffName, // Asegúrate de que estás utilizando currentStaffName
        lastService: currentLastService,
        utm:
          staffAuth.type === 'partner' && userAUTH?.email !== 'partner@immilandcanada.com'
            ? [userAUTH?.utm]
            : currentUTM,
      })
    }
  }, [
    search,
    currentPage,
    currentEmail,
    currentCountryOfOrigin,
    currentCountryOfNationality,
    currentStaffName,
    currentLastService,
    currentUTM,
    userAUTH,
    staffAuth,
  ])
  

  const handleSelectUser = (user: Users) => {
    console.log("Selected User:", user); 
    selectUser(user);
    loadCounterMessagesClient(user.id);
  }
  

  const onChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<Users> | SorterResult<Users>[],
    extra: TableCurrentDataSource<Users>
  ) => {
    setCurrentPage(pagination.current)
    if (filters.countryOfOrigin && filters.countryOfOrigin.length > 0) {
      setCurrentCountryOfOrigin(filters.countryOfOrigin as [])
    }

    if (filters.nationalitie && filters.nationalitie.length > 0) {
      setCurrentCountryOfNationality(filters.nationalitie as [])
    }

    if (
      filters.last_service_date_jotform_name &&
      filters.last_service_date_jotform_name.length > 0
    ) {
      setCurrentStaffName(filters.last_service_date_jotform_name as [])
    }

    if (filters.utm && filters.utm.length > 0) {
      setCurrentUTM(filters.utm as [])
    }

    if (filters.last_service && filters.last_service.length > 0) {
      setCurrentLastService(filters.last_service as [])
    }

    setIfDataFiltered(extra.currentDataSource)
    setFilteredInfo(filters)
    setSortedInfo(sorter as SorterResult<Users>)
  }

  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<Users> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setCurrentEmail('')
            }}
            size='small'
            style={{ width: 90 }}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record: Users) =>
      record.email
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const onSelectChange = async (newSelectedRowKeys: React.Key[]) => {
    selectedRowEmail.length = 0
    setSelectedRowKeys(newSelectedRowKeys)
    newSelectedRowKeys.map(async (id) => {
      const userEmailSelected = await getUserProfile(id as unknown as string)
      selectedRowEmail.push(userEmailSelected.email)
    })
  }

  const handleSelect = (record: any, selected: any) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key])
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key)
        return [...keys.slice(0, index), ...keys.slice(index + 1)]
      })
    }
  }

  const toggleSelectAll = () => {
    if (users !== null && users.data !== null && users.data !== undefined) {
      setSelectedRowKeys((keys) =>
        keys.length === ifDataFiltered.length
          ? []
          : users !== null && users.data !== null && users.data !== undefined
            ? ifDataFiltered.map((r) => r.id as Key)
            : []
      )
    }
  }

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length > 0}
      indeterminate={
        users !== null &&
        users.data !== null &&
        users.data !== undefined &&
        selectedRowKeys.length > 0 &&
        selectedRowKeys.length < users.data.length
      }
      onChange={toggleSelectAll}
    />
  )

  const rowSelection: TableRowSelection<Users> = {
    selectedRowKeys,
    type: 'checkbox',
    onChange: onSelectChange,
    fixed: false,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  }

  if (isLoadingUsers) {
    return (
      <>
        {' '}
        <div>
          <img src='/media/logos/logovid.gif' className='h-10px w-10px' alt='' />
        </div>
      </>
    )
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCreate = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {currentCountryOfOrigin.length > 0 ||
        currentCountryOfNationality.length > 0 ||
        currentStaffName.length > 0 ||
        currentLastService.length > 0 ||
        currentUTM.length > 0 ? (
        <>
          {' '}
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentCountryOfOrigin.length > 0 && (
              <div style={{ display: 'flex', marginBottom: '0.002rem' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentCountryOfOrigin([])
                    filteredInfo.countryOfOrigin = []
                  }}
                >
                  <p>Pais de Residencia</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentCountryOfOrigin.toString()}
                  readOnly
                ></input>{' '}
              </div>
            )}

            {currentCountryOfNationality.length > 0 && (
              <div style={{ display: 'flex', marginBottom: '0.002rem' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentCountryOfNationality([])
                    filteredInfo.nationalitie = []
                  }}
                >
                  <p>Pais de Nacionalidad</p> <p>X</p>
                </Button>
                <input
                  title='nationality'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentCountryOfNationality.toString()}
                  readOnly
                ></input>
              </div>
            )}

            {currentLastService.length > 0 && (
              <div style={{ display: 'flex', marginBottom: '0.002rem' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentLastService([])
                    filteredInfo.last_service = []
                  }}
                >
                  <p>Último servicio</p> <p>X</p>
                </Button>
                <input
                  title='last services'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentLastService.toString()}
                  readOnly
                ></input>
              </div>
            )}
            {currentStaffName.length > 0 && (
              <div style={{ display: 'flex' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentStaffName([])
                    filteredInfo.last_service_date_jotform_name = []
                  }}
                >
                  <p>Nombre del staff</p> <p>X</p>
                </Button>
                <input
                  title='staff'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentStaffName.toString()}
                  readOnly
                ></input>
              </div>
            )}

            {currentUTM.length > 0 && (
              <div style={{ display: 'flex' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentUTM([])
                    filteredInfo.utm = []
                  }}
                >
                  <p>UTM</p> <p>X</p>
                </Button>
                <input
                  title='UTM'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentUTM.toString()}
                  readOnly
                ></input>
              </div>
            )}
          </div>
        </>
      ) : null}

      <Table
        className='table-master my-custom-table'
        style={{ overflow: 'auto', position: 'relative' }}
        scroll={{ x: 'max-content', y: 500 }}
        /*     rowSelection={rowSelection} */
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: users?.count || 0,
          style: { display: 'flex', justifyContent: 'center' },
        }}
        dataSource={users?.data}
        rowKey='id'
        onChange={onChange}
        onHeaderRow={() => ({
          style: {
            background: 'none',
          },
        })}
        onRow={(record: Users, rowIndex?: number | undefined) => {
          return {
            onClick: () => handleSelectUser(record),
          };
        }}
        rowClassName={() => 'row-hover'}
      >

        <Column
          width={50}
          className='image-avatar'
          key='Avatars'
          render={(record: Users) => (
            <>
              {record.photoId !== null ? <img style={{ borderRadius: '999px', width: '35px', height: '35px' }} src={`//immilandpublic.nyc3.digitaloceanspaces.com/${record?.photoId}-avatar-large.jpg`} /> : <img style={{ borderRadius: '999px', width: '35px', height: '35px' }} src={image} />}
            </>
          )}
        />
        {/*        <Column
          width={250}
          title='Name'
          key='name'

          render={(record: Users) => <Space>{record.firstName + ' ' + record.lastName}</Space>}

        /> */}

        <Column
          width={200}
          title='NOMBRE'
          dataIndex={'email'}
          key='email'
          {...getColumnSearchPropsEmail('email')}
          render={(email: string, record: any) =>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
              <b style={{ margin: '0', height: '1.5rem' }}>{record.firstName.length > 14 || record.lastName.length > 14 ? <><>{record.firstName.substring(0, 14)}...</><>{record.lastName.substring(0, 14)}...</></> : <>{record.firstName + ' ' + record.lastName}</>}</b>      <p style={{ margin: '0' }}>{email?.length > 28 ? <>{email.substring(0, 28)}...</> : <>{email}</>}</p></div>} />


        <Column
          width={130}
          title='CREADO EL'
          dataIndex='createdAt'
          key='creationDate'
          render={(date) => <span>{moment(date).format('YYYY-MM-DD')}</span>}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          )}
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleSearchDatePicker(fromDate!, toDate!)
                  }}
                  size='small'
                  style={{ width: 90, marginRight: 8 }}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDate(null)
                    setToDate(null)
                    handleSearchDatePicker(fromDate!, toDate!)
                  }}
                  size='small'
                  style={{ width: 90 }}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
        />


        {/*  <Column
          width={100}
          title='Edad'
          dataIndex={'age'}
          key='age'
          render={(age: string) => <>{age}</>}
        /> */}



        <Column
          width={150}
          title='NACIONALIDAD'
          dataIndex={'nationalitie'}
          key='nationalitie'
          filters={nationalityInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            ; <Button>Filtrar</Button>
            return record === value.toString()
          }}
        />

        <Column
          width={140}
          title='RESIDENCIA'
          dataIndex={'countryOfOrigin'}
          key='countryOfOrigin'
          filters={countryOfOriginInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) => <>{record}</>}
        />

        <Column
          width={180}

          title='ULTIMO SERVICIO'
          dataIndex={'last_service'}
          key='last_service'
          filters={lastServiceInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) =>
            <>
              {record !== null && <p style={{
                fontSize: '13px',
                fontWeight: 'bold',
                backgroundColor: '#F9FAFB',
                width: '160px',
                padding: '7px 0px 0px 24px',
                marginTop: '10px'
              }}>
                {record.length > 18 ? `${record.slice(0, 18)}...` : record}
              </p>}
            </>}
        />

        <Column
          width={150}
          title='STAFF'
          dataIndex={'last_service_date_jotform_name'}
          key='last_service_date_jotform_name'
          filters={staffInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) => <>{record}</>}
        />

        {/* <Column
          width={200}
          title='Partnership'
          dataIndex={'utm'}
          key='utm'
          filters={partnershipInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) => <>{record}</>}
        /> */}
        <Column
          width={50}
          title='NOTAS'
          render={(record: any) => (
            <button
              onClick={(event) => {
                // Este es el clic del botón interno
                event.stopPropagation(); // Evita la propagación del evento al botón externo
                setIsModalVisible(true);
                setUserInfo(JSON.parse(JSON.stringify(record)));
                console.log('este es user info' + JSON.stringify(record));
                console.log(record);
              }}
            >
              <img src={notes} alt="Notas" />
            </button>
          )}
        />
        <Column
          width={50}
          key='data'
          render={(index: Users) => (
            <>
              <button
                style={{ width: '4rem', height: '2rem', borderRadius: '10px', boxShadow: 'none' }}
              >
                <img src={arrow} alt="Notas" />
              </button>
            </>
          )}
        />

      </Table>

      {isModalVisible === true ? <NotesModal
        userInfo={userInfo}
        visible={isModalVisible}
        onCancel={handleCancel}
        onCreate={handleCreate}
        products={[]}
      /> : null}



    </>
  )
}

export default observer(UserList)
