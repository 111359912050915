/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../components'
import { LinkOutlined } from '@ant-design/icons'
import React, { type Dispatch, type SetStateAction, useContext, useEffect, useState } from 'react'
import { Select, Space, Tag, Typography } from 'antd'
import { AiOutlineMail } from 'react-icons/ai'
import { IReply } from '../../../../app/modules/apps/appointments/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import { TimeZone } from '../../../../app/modules/utils/components/TimeZone'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import './style.css'
import { sendEmailErrorReport } from 'app/modules/apps/users/core/_requests'
import { Modal, Form, Input } from 'antd'
import { ExclamationCircleOutlined, LikeOutlined } from '@ant-design/icons'
import { getTranslations } from 'app/modules/apps/translations/core/_requests'


interface IProps {
  setUploadFiles: Dispatch<SetStateAction<boolean>>
  setTranslate: Dispatch<SetStateAction<string>>
  setFilesUploaded: Dispatch<SetStateAction<boolean>>
  setTranslation: Dispatch<SetStateAction<boolean>>
  setList: Dispatch<SetStateAction<boolean>>
  setComposeMessage: Dispatch<SetStateAction<boolean>>
  setComposepostdata: React.Dispatch<React.SetStateAction<IReply>>
  setSubject: Dispatch<SetStateAction<string>>
  user: any;
}

const ListTranslations: React.FC<IProps> = ({
  setUploadFiles,
  setTranslate,
  setList,
  setTranslation,
  setComposepostdata,
  setComposeMessage,
  setSubject,
  user
}) => {
  const rootStore = useContext(RootStoreContext)
  const { translationsUser, loadTranslationsUser } = rootStore.translationStore
  const [progressMap, setProgressMap] = useState<{ [key: string]: number }>({})
  const [daysRemaining, setDaysRemaining] = useState(60)
  const [modalVisible, setModalVisible] = useState(false);
  const [translationId, setTranslationId] = useState<any>('')


  useEffect(() => {
    loadTranslationsUser()
  }, [])

  useEffect(() => {
    if (translationsUser && translationsUser.length > 0) {
      const currentDate = new Date()
      const newProgressMap: { [key: string]: number } = {}
      translationsUser.forEach((translation) => {
        const startDate = new Date(translation.created_at ?? '')
        const deliverDate = new Date(translation.endDue ?? '')
        const totalDiff = Math.abs(deliverDate.getTime() - startDate.getTime())
        const currentDiff = Math.abs(currentDate.getTime() - startDate.getTime())
        const totalDays = Math.ceil(totalDiff / (1000 * 3600 * 24))
        const currentDays = Math.ceil(currentDiff / (1000 * 3600 * 24))
        let progress = (currentDays / totalDays) * 100
        if (translation.status === 'Completed') {
          progress = 100
        }

        if (translation.id !== undefined) {
          newProgressMap[translation.id] = progress
        }
        // Resto 60 días david
        const extraDate = new Date(translation.uploadExtraDate ?? '')
        extraDate.setDate(extraDate.getDate() + 60)

        // Calculo los días restantes david
        const diffTime = extraDate.getTime() - currentDate.getTime()
        const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24))

        // Actualizo el estado david
        setDaysRemaining(diffDays)
      })

      setProgressMap(newProgressMap)
    }
  }, [translationsUser])

  const intl = useIntl()
  const handleLookFiles = (translationId: string, filesUploaded: string, uploadExtra: boolean) => {
    /* if (uploadExtra) {
      setUploadFiles(true)
      setTranslate(translationId)
      setList(false)
      return
    } */

    if (filesUploaded) {
      setTranslation(true)
    } else {
      setUploadFiles(true)
    }
    setTranslate(translationId)
    setList(false)
  }

  const handleUploadFiles = (
    translationId: string,
    filesUploaded: string,
    uploadExtra: boolean
  ) => {
    if (uploadExtra) {
      setUploadFiles(true)
      setTranslate(translationId)
      setList(false)
      return
    }

    /*  if (filesUploaded) {
      setTranslation(true)
    } else {
      setUploadFiles(true)
    }
    setTranslate(translationId)
    setList(false) */
  }

  const handleReplyMessage = (values: any) => {
    setList(false)
    setComposeMessage(true)
    setComposepostdata(values)
  }
  const [selectedRecord, setSelectedRecord] = useState<any>(null)

  const [isErrorReportVisible, setIsErrorReportVisible] = useState(false)
  const [errorReportData, setErrorReportData] = useState({
    affectedTranslation: '',
    errorDescription: '',
    errorFile: '',
  })

  const showReportErrorPopup = (record: any) => {
    // ...
    setSelectedRecord(record)
    setSelectedErrorFile('') // Reiniciar el valor seleccionado en el dropdown
    setIsErrorReportVisible(true)

    // Obtener los valores de originalname
    getTranslations(record.id ? record.id : '')
      .then((translations) => {
        if (translations && translations.length > 0) {
          const fileId = translations.map((translation) => translation.fileId || '')
          console.log('fileId:', fileId, translations) // Verifica si fileId se muestra correctamente aquí
          setSelectedRecord({
            ...record,
            fileId: fileId, // Asegúrate de que fileId se asigne correctamente aquí
          })
          const originalnames = translations.map((translation) => translation.originalname || '')
          setOriginalnameOptions(originalnames)

        }
      })
      .catch((error) => {
        console.error('Error al obtener las traducciones:', error)
      })
    console.log(getTranslations)
  }


  const handleErrorReportSubmit = async () => {
    const selectedFileName = selectedErrorFile
    const fileId = selectedRecord && selectedRecord.fileId ? selectedRecord.fileId : ''

    try {
      console.log('fileId en el frontend:', fileId)
      await sendEmailErrorReport(
        errorReportData.affectedTranslation,
        errorReportData.errorDescription,
        selectedFileName,
        selectedRecord.staffName,
        selectedRecord.userName,
        fileId[0]
      )
      setIsErrorReportVisible(false) // Cerrar el popup después de enviar el correo
    } catch (error) {
      // Manejar errores de envío aquí, si es necesario
      console.error('Error al enviar el informe de error:', error)
    }
    console.log(handleErrorReportSubmit)
  }

  const [selectedErrorFile, setSelectedErrorFile] = useState<string>('') // Valor seleccionado en el dropdown
  const [originalnameOptions, setOriginalnameOptions] = useState<string[]>([]) // Valores para el dropdown

  return (
    <div className="table-container-translation-user">
      <TableContainer>
        <Table
        // dataSource={translationsUser}
        // rowKey='id'
        >
          <TableHead   >
            <TableRow className='row-hover2'    >
              <TableCell style={{ color: 'black' }}>Mi traductor</TableCell>
              <TableCell style={{ color: 'black' }}>{intl.formatMessage({ id: 'TRANSLATION.TRANSLATOR' })}</TableCell>
              <TableCell style={{ color: 'black' }}>Traduccion</TableCell>
              <TableCell style={{ color: 'black' }}>{intl.formatMessage({ id: 'TRANSLATION.DATECREATION' })}</TableCell>
              <TableCell style={{ color: 'black' }}>{intl.formatMessage({ id: 'TRANSLATION.DELIBER' })}</TableCell>
              <TableCell style={{ color: 'black' }}>{intl.formatMessage({ id: 'TRANSLATION.STATE' })}</TableCell>
              <TableCell style={{ color: 'black' }}>{intl.formatMessage({ id: 'TRANSLATION.FILES' })}</TableCell>
              <TableCell style={{ color: 'black' }}>{intl.formatMessage({ id: 'TRANSLATION.PAGES' })}</TableCell>
              <TableCell style={{ color: 'black', width: '2rem' }}>{intl.formatMessage({ id: 'TRANSLATION.PENDINGPAGES' })}</TableCell>
              <TableCell></TableCell>
              <TableCell style={{ color: 'black' }}>Mensajes</TableCell>
              <TableCell style={{ color: 'black' }}>Reporte</TableCell>
            </TableRow >
          </TableHead>

          <TableBody className='table-master my-custom-table2'>
            {translationsUser &&
              translationsUser.map((record, index) => (
                <TableRow key={index} className='row-hover2'>
                  <TableCell>
                    <Space>
                      <AvatarProfileUsers
                        image={
                          record.photoIdStaff
                            ? `${record.photoPathStaff}/${record.photoIdStaff}-avatar-small.jpg`
                            : ''
                        }
                        size={25}
                      />
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>{record.staffName}</Space>
                  </TableCell>
                  <TableCell>
                    <Space>{record.productName}</Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      <TimeZone date={record.created_at ? record.created_at : null} />
                    </Space>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Space>
                      <TimeZone date={record.endDue ? record.endDue : null} />
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      {record.status === 'Completed' ? (
                        <Tag color={record.statusColor ? record.statusColor : ''.toLowerCase()}>
                          {intl.formatMessage({ id: 'CORE.DONE' })}
                        </Tag>
                      ) : (
                        <Tag color={record.statusColor ? record.statusColor : ''.toLowerCase()}>
                          {intl.formatMessage({ id: 'CORE.PENDING' })}
                        </Tag>
                      )}
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      {record.filesUploaded && (
                        <Button
                          style={{
                            backgroundColor: 'white',
                            color: '#BF3C3C',
                            borderRadius: '5px',
                            border: '1px solid #BF3C3C',
                            padding: '10px',
                            height: '2rem',
                            width: '8rem',
                          }}
                          onClick={() => {
                            handleLookFiles(
                              record.id ? record.id : '',
                              record.filesUploaded ? record.filesUploaded : '',
                              !!record.uploadExtra
                            )
                          }}>
                          { }
                          {record.filesUploaded && (
                            <>
                              <LinkOutlined className='extraButton' style={{ color: '#BF3C3C' }} />
                              <span style={{ color: '#BF3C3C', fontSize: '12px' }}>Mostrar archivos</span>
                            </>
                          )}
                        </Button>
                      )}
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space className='space-table2'>{record.pages}</Space>
                  </TableCell>
                  <TableCell>
                    <Space className='space-table'>
                      {record.pages !== null &&
                        record.pages !== undefined &&
                        record.pagesTranslated !== null &&
                        record.pagesTranslated !== undefined
                        ? record.pages - record.pagesTranslated
                        : 0}
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space className='loadFiles'>
                      {record.uploadExtra &&
                        record.pages != null &&
                        record.pagesTranslated != null &&
                        record.pages - record.pagesTranslated > 0 &&
                        daysRemaining > 0 && (
                          <>
                            <div style={{ marginBottom: '10px', width: '12rem' }}>
                              <Typography.Text
                                className='latido'
                                type='success'
                                style={{ display: 'flex', flexDirection: 'column', color: '#bf3c3c' }}
                              >
                                Disponibles:{' '}
                                {record.pages != null
                                  ? record.pagesTranslated != null
                                    ? record.pages - record.pagesTranslated
                                    : record.pages
                                  : 0}{' '}
                                de {record.pages} Pagina(as) tienes
                                <p>
                                  {' '}
                                  <b style={{ color: 'green' }}>{daysRemaining} dias para usarlas</b>
                                </p>
                              </Typography.Text>
                            </div>
                            <Button
                              style={{ width: 'max-content' }}
                              outline
                              onClick={() => {
                                handleUploadFiles(
                                  record.id ? record.id : '',
                                  record.filesUploaded ? record.filesUploaded : '',
                                  !!record.uploadExtra
                                )
                              }}>
                              <>
                                <LinkOutlined className='extraButton' />
                                Cargar Archivos Extra
                              </>
                            </Button>
                          </>
                        )}
                    </Space>
                  </TableCell>

                  <TableCell>
                    <Space>
                      <Button
                        style={{ background: 'white', border: '1px solid #FEA102', display: 'flex' }}
                        onClick={() => {
                          setSubject(record.description ? record.description : '')
                          handleReplyMessage({
                            detailOrderId: record.orderDetailId,
                            user: `${record.userName}`,
                            email: record.staffEmail,
                            userId: record.userTranslationId,
                            fromId: record.userId,
                            messageId: null,
                          })
                        }}>
                        <AiOutlineMail />
                      </Button>
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      <Button
                        className='button-exclama'
                        type='link'
                        onClick={() => showReportErrorPopup(record)}>
                        <ExclamationCircleOutlined className='exclabutton' />
                      </Button>
                    </Space>
                  </TableCell>
                  <Modal
                    title='Reportar Error'
                    open={isErrorReportVisible}
                    onCancel={() => setIsErrorReportVisible(false)}
                    footer={[
                      <div className='buttonContainer'>
                        <Button
                          className='buttonreport'
                          key='cancel'
                          onClick={() => setIsErrorReportVisible(false)}>
                          Cancelar
                        </Button>
                        ,
                        <Button
                          className='buttonreport'
                          key='submit'
                          type='primary'
                          onClick={handleErrorReportSubmit}
                        >
                          Enviar
                        </Button>
                        ,
                      </div>,
                    ]}>
                    <Form name='errorReportForm' onFinish={handleErrorReportSubmit}>
                      <Form.Item
                        name='affectedTranslation'
                        label='Traducción Afectada'
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, ingrese la traducción afectada',
                          },
                        ]}>
                        <Input
                          value={errorReportData.affectedTranslation}
                          onChange={(e) =>
                            setErrorReportData({
                              ...errorReportData,
                              affectedTranslation: e.target.value,
                            })} />
                      </Form.Item>
                      <Form.Item
                        name='errorDescription'
                        label='Descripción del Error'
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, ingrese la descripción del error',
                          },
                        ]}>
                        <Input.TextArea
                          value={errorReportData.errorDescription}
                          onChange={(e) =>
                            setErrorReportData({
                              ...errorReportData,
                              errorDescription: e.target.value,
                            })} />
                      </Form.Item>
                      <Form.Item
                        name='errorFile'
                        label='Nombre del archivo con el error'
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, seleccione un nombre de archivo con el error',
                          },
                        ]}>
                        <Select
                          value={selectedErrorFile}
                          onChange={(value) => setSelectedErrorFile(value)}>
                          {originalnameOptions.map((originalname, index) => (
                            <Select.Option key={index} value={originalname}>
                              {originalname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <TableCell>
                    <TableCell>
                      <div className="translation-item">
                        <Link to={`/services/satisfaction/${record.id}`}>
                          <LikeOutlined className='exclabutton' />
                        </Link>
                      </div>
                    </TableCell>
                    {/*   <Button
                      className='button-exclama'
                      type='link'
                      onClick={() => getTranslationId(record)}
                    >
                      <LikeOutlined  className='exclabutton' />
                    </Button>
      <Modal
        title="Formulario de Feedback"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <CustomerFeedbackForm user={user} translationId={translationId} />
      </Modal> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default observer(ListTranslations)
