 
/* eslint-disable */
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useContext, useState, useEffect } from 'react'
import AvatarEdit from './Avatar/AvatarEdit'
import AvatarProfile from './Avatar/AvatarProfile'
import AvatarProfileAction from './Avatar/AvatarProfileAction'
import { CountrySelect } from '../../../../../../app/modules/utils/components/CountrySelect'
import { type Profile } from '../../../../../../app/modules/apps/profile/core/_models'
import { RootStoreContext } from '../../../../../../stores/rootStore'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import type { MenuProps } from 'antd'
import { DatePicker } from 'antd'

import './style.css'
import classnames from 'classnames'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  birth: Yup.date().required('birth name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'El teléfono solo puede contener números')
    .required('El teléfono es obligatorio')
    .min(10, 'El teléfono debe tener al menos 10 dígitos'),
  countryOfOrigin: Yup.string().required('Country of origin is required'),
  gender: Yup.string().required('gender is required'),
  nationalitie: Yup.string().required('Nationalities is required'),
  address: Yup.string().required('Address is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
})

interface Props {
  profile: Profile
  isUserLoading?: boolean
  updateProfileBasic: (profile: Profile) => void
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
}

type MenuItem = Required<MenuProps>['items'][number]

const ProfileDetailsModal: React.FC<Props> = ({
  profile,
  isUserLoading,
  updateProfileBasic,
  showAvatar,
  edit = true,
  setTabActive,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { staff, user } = rootStore.authStore
  const { isEditing } = rootStore.profileStore
  const intl = useIntl()
  const timeZones = moment.tz.names()
  const [loading, setLoading] = useState(false)
  const [activeView, setActiveView] = useState(1)

  const formik = useFormik<Profile>({
    initialValues: profile,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await updateProfileBasic(values)
      setLoading(false)
    },
  })

  useEffect(() => {
    // Calcula la edad cuando cambie la fecha de nacimiento
    const birthDate = formik.values.birth
    if (birthDate instanceof Date) {
      const age = ageCalculator(birthDate)
      formik.setFieldValue('age', age)
    }
  }, [formik.values.birth])

  const ageCalculator = (formikBirthDate: Date) => {
    const currentDate = moment()
    const birthDate = moment(formikBirthDate)

    if (!birthDate.isValid()) {
      return 'No se puede calcular'
    }

    const age = currentDate.diff(birthDate, 'years')
    return age.toString() + ' años de edad'
  }

  const handleNext = () => {
    if (activeView < 3) {
      setActiveView(activeView + 1)
    }
  }

  const handlePrev = () => {
    if (activeView > 1) {
      setActiveView(activeView - 1)
    }
  }
  const [isViewFieldsFilled, setIsViewFieldsFilled] = useState(false)

  // ...

  // Función para verificar si todos los campos en el activeView actual están llenos
  const checkIfActiveViewFieldsFilled = () => {
    let isFilled = false;

    const fieldsToCheck =
      activeView === 1
        ? ['firstName', 'lastName', 'birth', 'gender']
        : activeView === 2
          ? ['address', 'phone']
          : activeView === 3
            ? ['countryOfOrigin', 'nationalitie', 'language', 'timeZone']
            : [];

    if (activeView === 2) {
      // Si es la vista de datos de contacto, verificar también la longitud del campo de teléfono
      isFilled = fieldsToCheck.every(fieldName => {
        if (fieldName === 'phone') {
          const phoneValue = formik.values[fieldName];
          return typeof phoneValue === 'string' && phoneValue.length >= 10;
        }
        return !!formik.values[fieldName as keyof Profile];
      });
    } else {
      // Para otras vistas, verificar simplemente si los campos requeridos están llenos
      isFilled = fieldsToCheck.every(fieldName => !!formik.values[fieldName as keyof Profile]);
    }

    setIsViewFieldsFilled(isFilled);
  };

  useEffect(() => {
    // Llama a la función al cambiar de activeView
    checkIfActiveViewFieldsFilled()
  }, [activeView])

  useEffect(() => {
    // Llama a la función cuando se actualiza cualquier campo
    checkIfActiveViewFieldsFilled()
  }, [formik.values])

  // ...
  const handleInputChange = (event: any) => {
    const { value } = event.target;
    const newValue = value.replace(/\D/g, ''); // Remover todos los caracteres no numéricos

    // Actualizar el valor del input con solo números
    event.target.value = newValue;
  };


  const [inputValue, setInputValue] = useState<string>('');
  const [showList, setShowList] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(timeZones);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // Actualizar el valor del campo inputValue con el valor de formik en el montaje inicial
    setInputValue(formik.values.timeZone || '');
  }, [formik.values.timeZone]);

  const handleInputChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setShowList(true);

    const filtered = timeZones.filter(option =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setErrorMessage('');
    formik.setFieldValue('timeZone', value); // Actualizar el valor en el objeto formik
  };

  const handleOptionSelect = (value: string) => {
    setInputValue(value);
    setShowList(false);
    setErrorMessage(''); // Limpiar el mensaje de error al seleccionar una opción válida
    formik.setFieldValue('timeZone', value); // Actualizar el valor en el objeto formik
  };

  const handleBlur = () => {
    // Validar solo si el valor ingresado no está en las opciones filtradas
    if (!filteredOptions.includes(inputValue) && inputValue !== '') {

      setErrorMessage('Ha seleccionado una zona horaria incorrecta');
    }
  };
  return (
    <>
      {profile.firstName === '' ? (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          {activeView === 1 && (
            <p className='text-init'>
              Bienvenido a Immiland. Para un mejor servicio, por favor ingrese sus{' '}
              <strong className='strong-element'>Datos personales</strong> y su{' '}
              <strong className='strong-element'>Foto de perfil</strong>.
            </p>
          )}
          {activeView === 2 && (
            <p className='text-init'>
              Ingrese por favor sus <strong className='strong-element'>datos de contacto.</strong>
            </p>
          )}
          {activeView === 3 && (
            <p className='text-init'>
              Por ultimo Ingrese los relacionados a su{' '}
              <strong className='strong-element'>ubicación y nacionalidad.</strong>
            </p>
          )}
          <div className='numbers-wrapper'
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px'
            }}>
            <div className='number-row'>
              <div className={classnames('number-circle', { 'active-number': activeView === 1 })}>
                1
              </div>
              <div className='hidden sm:block' >Datos Personales</div>
            </div>
            <div className='number-row'>
              <div className={classnames('number-circle', { 'active-number': activeView === 2 })}>
                2
              </div>
              <div className='hidden sm:block' >Datos Contacto</div>
            </div>
            <div className='number-row'>
              <div className={classnames('number-circle', { 'active-number': activeView === 3 })}>
                3
              </div>
              <div className='hidden sm:block' >Datos Geograficos</div>
            </div>
          </div>
          <div id='kt_account_profile_details'
            style={{ width: '100%' }}>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className='form'
              style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: '2rem' }}
            >
              <div className='card-body p-9 bg-white'>
                {activeView === 1 &&
                  <div className='w-full'>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'center'
                    }}>
                      <div className='container-question '>
                        <H3 className='col-lg-4  title-forms'>
                          Nombre
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <Input
                            placeholder='Nombre'
                            type='text'
                            {...formik.getFieldProps('firstName')}
                            className='custom-input' />
                        </div>
                        <div style={{ marginLeft: '20px' }}> {formik.touched.firstName && formik.errors.firstName && (
                          <Alert variant='danger'>{formik.errors.firstName}</Alert>
                        )}</div>
                      </div>
                      <div className='container-question '>
                        <H3 className='col-lg-4  title-forms'>
                          Apellido
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <Input
                            type='text'
                            placeholder='Apellido'
                            {...formik.getFieldProps('lastName')}
                            className='custom-input'
                          />
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                          {formik.touched.lastName && formik.errors.lastName &&
                            (
                              <Alert variant='danger'>{formik.errors.lastName}</Alert>
                            )}
                        </div>
                      </div>
                      <div style={{display:'flex', flexDirection:'column'}}>
                      <div className='container-question '>
                        <H3 className='col-lg-8 col-form-label required fw-bold fs-6'
                          style={{ padding: '4px 16px' }}>
                          Fecha de nacimiento
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <DatePicker
                            className='custom-datepicker'
                            style={{ border: '1px solid gray', zIndex: 1500 }}
                            onChange={(date) => {
                              if (date?.isValid()) {
                                // Comprueba si la fecha es válida antes de actualizar el valor
                                formik.setFieldValue('birth', date?.format('YYYY-MM-DD'))
                              }
                            }}
                            data-date-format='yyyy-MM-dd'
                            placeholder={
                              formik.getFieldProps('birth').value === ''
                                ? intl.formatMessage({ id: 'PROFILE.BIRTH' })
                                : formik.getFieldProps('birth').value
                            }
                          />
                        </div>
                        {formik.touched.birth && formik.errors.birth && (
                          <Alert variant='danger'>{formik.errors.birth}</Alert>
                        )}
                      </div>
                      <div className='container-question'>
                        <H3 className='col-lg-4 col-form-label title-forms'>
                          Género
                        </H3>
                        <div style={{ paddingLeft: '10px' }}>
                          <select {...formik.getFieldProps('gender')}
                            className='custom-input'>
                            <option value=''>
                              Seleccionar género
                            </option>
                            <option value='M'>Masculino</option>
                            <option value='F'>Femenino</option>
                            <option value='O'>Otro</option>
                          </select>
                        </div>
                        <div style={{ marginLeft: '15px' }}>{formik.touched.gender && formik.errors.gender && (
                          <Alert>{formik.errors.gender}</Alert>
                        )}</div>
                      </div> </div>
                    </div>
                    {showAvatar ? (
                      <div className='avatar-container'>
                        <div>
                          <div>
                            {isEditing ? (
                              <>
                                <AvatarEdit initialImage={''} />
                              </>
                            ) : (
                              <>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                  <AvatarProfile initialImage='' size={100} />
                                  <AvatarProfileAction editable={true} />
                                </div>
                              </>
                            )}
                            <H3 className='col-lg-4 col-form-label title-forms hide-component'>
                              Foto de perfil
                            </H3>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className='flex w-full mt-4 mb-2 items-center justify-center'>
                      <Button
                        type='button'
                        onClick={() => {
                          handleNext();
                          window.scrollTo({ top: 0, behavior: 'smooth' }); // Esto hace scroll hacia arriba
                        }}
                        className={`next-button-form ${!isViewFieldsFilled ? 'disabled-button' : ''}`}
                        disabled={!isViewFieldsFilled}
                      >
                        Siguiente{' '}
                        <img
                          src='/media/svg/menu-icons/arrow-right.svg'
                          alt='arrow Icon'
                          className='icon'
                        />
                      </Button>
                    </div>
                  </div>
                }
                {activeView === 2 && (
                  <div className='w-full'>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <div className='container-question'>
                        <H3 className='col-lg-4 col-form-label title-forms'>
                          Dirección
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <Input
                            type='text'
                            placeholder='Dirección'
                            {...formik.getFieldProps('address')}
                            className='custom-input'
                          />
                          {formik.touched.address && formik.errors.address && (
                            <Alert variant='danger'>{formik.errors.address}</Alert>
                          )}
                        </div>
                      </div>
                      <div className='container-question'>
                        <H3 className='col-lg-4 col-form-label title-forms'>
                          <span className='required'>
                            Teléfono
                          </span>
                        </H3>
                        {formik.values.phone && formik.values.phone.length < 10 && (
                          <span style={{
                            fontSize: '10px',
                            color: 'rgb(191 60 60 )',
                            marginLeft: '15px'
                          }}>Minimo 10 dígitos</span>
                        )}
                        <div className='col-lg-8 fv-row'>
                          <Input
                            type='tel'
                            placeholder='Teléfono'
                            {...formik.getFieldProps('phone')}
                            className='custom-input'
                            onInput={handleInputChange} />
                        </div>
                      </div>
                    </div>
                    <div className='flex w-full mt-4 mb-2 items-center justify-center'>
                      <Button
                        type='button'
                        onClick={() => {
                          handleNext();
                          window.scrollTo({ top: 0, behavior: 'smooth' }); // Esto hace scroll hacia arriba
                        }}
                        className={`next-button-form ${!isViewFieldsFilled ? 'disabled-button' : ''}`}
                        disabled={!isViewFieldsFilled}
                      >
                        Siguiente{' '}
                        <img
                          src='/media/svg/menu-icons/arrow-right.svg'
                          alt='arrow Icon'
                          className='icon'
                        />
                      </Button>
                    </div>
                  </div>
                )}
                {activeView === 3 && (
                  <div className='w-full'>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <div className='container-question'>
                        <H3 className='col-lg-8 col-form-label title-forms'>
                          <span className='required'>
                            País en el que reside
                          </span>
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <Select
                            {...formik.getFieldProps('countryOfOrigin')}
                            className='custom-input'>
                            <CountrySelect />
                          </Select>
                          {formik.touched.countryOfOrigin && formik.errors.countryOfOrigin && (
                            <Alert>{formik.errors.countryOfOrigin}</Alert>
                          )}
                        </div>
                      </div>
                      <div className='container-question'>
                        <H3 className='col-lg-4 col-form-label title-forms'>
                          <span className='required'>
                            Nacionalidad
                          </span>
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <Select
                            {...formik.getFieldProps('nationalitie')}
                            className='custom-input'
                          >
                            <CountrySelect />
                          </Select>
                          {formik.touched.nationalitie && formik.errors.nationalitie && (
                            <Alert>{formik.errors.nationalitie}</Alert>
                          )}
                        </div>
                      </div>
                      <div className='container-question'>
                        <H3 className='col-lg-8 col-form-label title-forms'>
                          Seleccionar lenguaje
                        </H3>
                        <div className='col-lg-8 fv-row'>
                          <Select {...formik.getFieldProps('language')}
                            className='custom-input'>
                            <option value=''>
                              Seleccionar lenguaje...
                            </option>
                            <option value='en'>English</option>
                            <option value='es'>Español - Spanish</option>
                            <option value='fr'>Français - French</option>
                          </Select>
                          {formik.touched.language && formik.errors.language && (
                            <Alert>{formik.errors.language}</Alert>
                          )}
                        </div>
                      </div>
                      <div className='container-question'>
                    <div style={{ display: 'flex', alignItems: 'center' }}> <H3 className='lg-12 pl-3 col-form-label title-forms'>Zona Horaria (Ciudad)</H3>
                      {errorMessage && (
                        <div className='fv-plugins-message-container'>
                          <div style={{ marginLeft: '18px', fontSize: '12px', marginBottom: '4px' }} className='custom-error-message'>{errorMessage}</div>
                        </div>
                      )}</div>

                    <div className='col-lg-8'>
                      <input
                        type="text"
                        placeholder='Zona Horaria'
                        value={inputValue}
                        onChange={handleInputChange2}
                        onBlur={handleBlur}
                        className='custom-input'
                      />
                      {showList && (
                        <div className='results-list-form'>
                          {filteredOptions.map((timeZone: string) => (
                            <div
                              key={timeZone}
                              className='search-result'
                              onClick={() => handleOptionSelect(timeZone)}
                            >
                              {timeZone}
                            </div>
                          ))}
                        </div>
                      )}

                    </div>
                  </div>
                    </div>
                    <div className='flex w-full mt-4 mb-2 items-center justify-center'>
                      <Button
                        className={`button-ui button-primary ${!isViewFieldsFilled ? 'disabled-button' : ''}`}
                        type='submit'
                        disabled={!isViewFieldsFilled}
                        onClick={() => {
                          if (isEmpty(formik.errors) && setTabActive != null) {
                            setTabActive('homenew1');
                            window.scrollTo({ top: 0, behavior: 'smooth' }); // Esto hace scroll hacia arriba
                          }
                        }}
                        style={{
                          display: activeView === 3 ? 'block' : 'none',
                        }}
                      >
                        <span
                          className='button-content'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px',
                          }}
                        >
                          {!loading && 'Guardar'}
                          <img
                            src='/media/svg/menu-icons/arrow-right.svg'
                            alt='arrow Icon'
                            className='icon'
                          />
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Por favor espere...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <>
          <div
            className='profile-details-form1'
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            id='kt_account_profile_details'>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className='form'
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <div className='w-full card-body p-9 bg-white'
                style={{ borderRadius: '10px' }}>
                {showAvatar ? (
                  <div className='col'>
                    <>
                      {isEditing ? (
                        <AvatarEdit initialImage={''} />
                      ) : (
                        <div className='flex items-center flex-wrap gap-8'>
                          <AvatarProfile initialImage='' size={100} />
                          <div className='hide-component'>
                            <AvatarProfileAction editable={true} />
                          </div>
                        </div>
                      )}
                      <H3 className='col-lg-4 col-form-label fw-bold fs-6'>
                        Foto de perfil
                      </H3>
                      <div className='hide-component-fullscreen'>
                        <AvatarProfileAction editable={true} />
                      </div>
                    </>
                  </div>
                ) : null}
                <div>
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center'
                  }}>
                    <div className='container-question'>
                      <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Nombre
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Input
                          type='text'
                          placeholder='Nombre'
                          {...formik.getFieldProps('firstName')}
                          className='custom-input'
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                          <Alert variant='danger'>{formik.errors.firstName}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Apellido
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Input
                          type='text'
                          placeholder='Apellido'
                          {...formik.getFieldProps('lastName')}
                          className='custom-input'
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <Alert variant='danger'>{formik.errors.lastName}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question' >
                      <H3 className='col-lg-8 col-form-label required fw-bold fs-6'
                        style={{ padding: '4px 16px' }}>
                        Fecha de nacimiento
                      </H3>
                      <div className='col-lg-8 fv-row '>
                        <DatePicker
                          className='custom-datepicker'
                          style={{ border: '1px solid gray' }}
                          value={
                            formik.getFieldProps('birth').value !== ''
                              ? moment.utc(formik.getFieldProps('birth').value)
                              : null
                          }
                          onChange={(date) => {
                            const formattedDate = moment.utc(date).format('YYYY-MM-DD')
                            formik.setFieldValue('birth', formattedDate)
                          }}
                          data-date-format='yyyy-MM-dd'
                        />
                      </div>
                      {formik.touched.birth && formik.errors.birth && (
                        <Alert variant='danger'>{formik.errors.birth}</Alert>
                      )}
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Edad
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Input
                          className='custom-input'
                          type='text'
                          placeholder={ageCalculator(formik.getFieldProps('birth').value)}
                          value={ageCalculator(formik.getFieldProps('birth').value)}
                          readOnly
                        />
                        {formik.touched.birth && formik.errors.birth && (
                          <Alert variant='danger'>{formik.errors.birth}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                        Dirección
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Input
                          className='custom-input'
                          type='text'
                          placeholder='Dirección'
                          {...formik.getFieldProps('address')}
                        />
                        {formik.touched.address && formik.errors.address && (
                          <Alert variant='danger'>{formik.errors.address}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>
                          Teléfono
                        </span>
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Input
                          type='tel'
                          placeholder='Teléfono'
                          {...formik.getFieldProps('phone')}
                          className='custom-input'
                          onInput={handleInputChange}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <Alert variant='danger'>{formik.errors.phone}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-8 col-form-label fw-bold fs-6'>
                        <span className='required'>
                          País en el que reside
                        </span>
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Select {...formik.getFieldProps('countryOfOrigin')}
                          className='custom-input'>
                          <CountrySelect />
                        </Select>
                        {formik.touched.countryOfOrigin &&
                          formik.errors.countryOfOrigin && (
                            <Alert>{formik.errors.countryOfOrigin}</Alert>
                          )}
                      </div>
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>
                          Nacionalidad
                        </span>
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Select {...formik.getFieldProps('nationalitie')} className='custom-input'>
                          <CountrySelect />
                        </Select>
                        {formik.touched.nationalitie && formik.errors.nationalitie && (
                          <Alert>{formik.errors.nationalitie}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question'>
                      <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Idioma
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Select {...formik.getFieldProps('language')} className='custom-input'>
                          <option value=''>
                            Idioma...
                          </option>
                          <option value='en'>English</option>
                          <option value='es'>Español - Spanish</option>
                          <option value='fr'>Français - French</option>
                        </Select>
                        {formik.touched.language && formik.errors.language && (
                          <Alert>{formik.errors.language}</Alert>
                        )}
                      </div>
                    </div>
                    <div className='container-question'>
                    <div style={{ display: 'flex', alignItems: 'center' }}> <H3 className='lg-12 pl-3 col-form-label title-forms'>Zona Horaria (Ciudad)</H3>
                      {errorMessage && (
                        <div className='fv-plugins-message-container'>
                          <div style={{ marginLeft: '18px', fontSize: '12px', marginBottom: '4px' }} className='custom-error-message'>{errorMessage}</div>
                        </div>
                      )}</div>

                    <div className='col-lg-8'>
                      <input
                        type="text"
                        placeholder='Zona Horaria'
                        value={inputValue}
                        onChange={handleInputChange2}
                        onBlur={handleBlur}
                        className='custom-input'
                      />
                      {showList && (
                        <div className='results-list-form'>
                          {filteredOptions.map((timeZone: string) => (
                            <div
                              key={timeZone}
                              className='search-result'
                              onClick={() => handleOptionSelect(timeZone)}
                            >
                              {timeZone}
                            </div>
                          ))}
                        </div>
                      )}

                    </div>
                  </div>
                    <div className='container-question '>
                      <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Género
                      </H3>
                      <div className='col-lg-8 fv-row'>
                        <Select {...formik.getFieldProps('gender')}
                          className='custom-input'>
                          <option value=''>Seleccione género</option>
                          <option value='M'>Masculino</option>
                          <option value='F'>Femenino</option>
                          <option value='O'>Otro</option>
                        </Select>
                        {formik.touched.gender && formik.errors.gender && (
                          <Alert>{formik.errors.gender}</Alert>
                        )}
                      </div>
                    </div>
                    {staff && staff.type === 'consultant' && (
                      <div className='container-question'>
                        <H3 className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span className='required'>
                            {intl.formatMessage({ id: 'Descripción' })}
                          </span>
                        </H3>
                        <textarea
                          className='custom-input'
                          placeholder={'Datos del consultor'}
                          maxLength={200}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <Alert variant='danger'>{formik.errors.phone}</Alert>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex w-full mt-4 mb-8 items-center justify-center'>
                  <Button
                    className='button-ui button-primary'
                    type='submit'
                    disabled={!isViewFieldsFilled || !formik.values.timeZone || !!errorMessage}
                    onClick={() => {
                      isEmpty(formik.errors) && setTabActive != null ? setTabActive('homenew1') : null
                    }}>
                    {!loading && 'Continuar'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Por favor espere...{' '}
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  )
}

export default observer(ProfileDetailsModal)
