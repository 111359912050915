/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  ResponseApointment,
  type AppointmentsAdmin,
  type IComposeData,
  IApointments,
  IReply,
} from '../../../../app/modules/apps/appointments/core/_models';
import { Button, DatePicker, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { SiGooglemeet } from 'react-icons/si';
import { DateTime } from 'luxon';
import DialogComponent from '../../../../components/Dialog';
import UserProfile from '../../../../app/pages/user/UserProfile';
import { useIntl } from 'react-intl';
import { ProfileImmigration } from 'app/modules/apps/profile/core/_models';
import Column from 'antd/lib/table/Column';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { RangeValue } from 'rc-picker/lib/interface';
import MenuOptions from '../MenuOptions';
import MenuOptionsUser from '../MenuOptions/menuOptionsUser';

interface Props {
  appointments?: ResponseApointment;
  setComposePost: React.Dispatch<React.SetStateAction<boolean>>;
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>;
  setComposeNote: React.Dispatch<React.SetStateAction<boolean>>;
  zone: string;
  ProfileImmigration: ProfileImmigration;
  setQuery: (query: IApointments) => void;
  query: IApointments | null;
  userId: string;
}

const UsersAppointments: React.FC<Props> = (props) => {
  const { ProfileImmigration, userId } = props;
  const [open, setOpen] = React.useState(false);
  const [opendataUser, setDataUser] = React.useState<IComposeData | null>(null);
  const [nameInfo, setNameInfo] = useState<ColumnFilterItem[]>();
  const intl = useIntl();
  const { appointments, zone, setQuery, query } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProductName, setCurrentProductName] = useState<string[]>();
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
  const [toDate, setToDate] = useState<moment.Moment | null>(null);
  const [userIdDetect, setUserIdDetect] = useState(false);

  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, { zone }).toFormat('yyyy-MM-dd HH:mm a');
    return keepOffset.toString();
  };

  const handleOpenMeet = (value: any) => {
    window.open(value, '_blank');
  };

  useEffect(() => {
    if (nameInfo === undefined && appointments && appointments.data.length > 0) {
      setNameInfo(
        appointments?.data.map((item: any, index: number) => {
          if (item.name !== undefined && item.name !== null) {
            return {
              text: item.name,
              value: item.name,
              key: index.toString(),
            };
          } else {
            return {
              text: '',
              value: '',
              key: index.toString(),
            };
          }
        })
      );
    }
  }, [appointments]);

  const onChange = (pagination: any, filters: any) => {
    setCurrentPage(pagination.current);
    console.log('proudctName' + filters.productName);
    if (filters.productName !== null) {
      setCurrentProductName(filters.productName);
    }

    if (filters.productName === null) {
      setCurrentProductName([]);
    }
  };

  useEffect(() => {
    try {
      if (query !== null) {
        const params: IApointments = {
          ...query,
          page: currentPage,
          description: currentProductName,
          userId: userId,
        };

        setQuery(params);
        setUserIdDetect(true);
      }
    } catch (error) {}
  }, [currentPage, currentProductName]);

  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: any = {
          ...query,
          startDate: values[0].format('DD-MM-YYYY'),
          endDate: values[1].format('DD-MM-YYYY'),
        };

        setQuery(params);
      } else {
        const params: any = {
          ...query,
          startDate: '',
          endDate: '',
        };
        setQuery(params);
      }
    } catch (error) {}
  };

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    }
  };

  if (userIdDetect === false) {
    return <>Cargando..</>;
  }
  
  return (
    <>
      <DialogComponent
        children={
          <>
            {opendataUser != null && (
              <UserProfile
                composepostdata={opendataUser}
                edit={true}
                ProfileImmigration={ProfileImmigration}
              />
            )}
          </>
        }
        title='Perfil del usuario'
        isOpen={open}
        closeModal={() => {
          setOpen(false);
        }}
        width='w-4/5'
        height='h-4/5'
      />
      {currentProductName && currentProductName.length > 0 ? (
        <>
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentProductName.length > 0 && (
              <div style={{ display: 'flex', marginBottom: '0.002rem' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentProductName([]);
                  }}
                >
                  <p>Descripcion</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentProductName.toString()}
                  readOnly
                />
              </div>
            )}
          </div>
        </>
      ) : null}
      
      <Table
        style={{ overflow: 'auto', position: 'relative' }}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: Number(appointments?.total) || 0,
        }}
        dataSource={appointments?.data.map(record => ({ ...record, key: record.id }))} // Añadir 'key' único
        onChange={onChange}
      >
        <Column
          title={intl.formatMessage({ id: 'PROFILE.FIRSTNAME' })}
          render={(record: AppointmentsAdmin) => (
            <Space size='middle'>
              <p>
                {record.first_name} {record.last_name}
              </p>
            </Space>
          )}
        />

        <Column key='email' dataIndex={'email'} title={intl.formatMessage({ id: 'PROFILE.EMAIL' })} />

        <Column
          title={intl.formatMessage({ id: 'CORE.DATE' }) + ` (${zone})`}
          render={(record: AppointmentsAdmin) => (
            <Space size='middle'>
              <p>
                {getDate(record.start_time)} ( {zone} )
              </p>
            </Space>
          )}
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm();
                    handleDate([fromDate, toDate]);
                  }}
                  size='small'
                  style={{ width: 90, marginRight: 8 }}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                    handleDate(['', '']);
                  }}
                  size='small'
                  style={{ width: 90 }}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
        />

        <Column
          key={'productName'}
          title={intl.formatMessage({ id: 'CORE.TYPE' })}
          render={(record: AppointmentsAdmin) => (
            <Space size='middle'>
              {record.name}
            </Space>
          )}
        />

        <Column
          render={(record: AppointmentsAdmin) => (
            <Space size='middle'>
              <Button
                style={{ background: 'white', border: '1px solid #301DAE' }}
                onClick={() => {
                  handleOpenMeet(`${record.join_url}`);
                }}
              >
                {<SiGooglemeet />}
              </Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export { UsersAppointments };
