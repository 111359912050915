/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect} from 'react'
import CartContent from './CartContent'
import {Outlet} from 'react-router-dom'
import {RootStoreContext} from '../../../stores/rootStore'
import {observer} from 'mobx-react'

const Layout: React.FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {isLoadingCategories, categories, getCategories} = rootStore.cartStore

  useEffect(() => {
    getCategories('')
  }, [])

  return (
    <>
      <div className='s-layout'>
        {isLoadingCategories ? (
          <>Loading</>
        ) : (
          <>
            {categories ? (
              <>
                <CartContent>
                  <Outlet />
                </CartContent>
              </>
            ) : (
              <>No data</>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default observer(Layout)
