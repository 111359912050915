export const auth = {
  'AUTH.GENERAL.OR': 'Or',
  'AUTH.GENERAL.SUBMIT_BUTTON': 'Submit',
  'AUTH.GENERAL.NO_ACCOUNT': "Don't have an account?",
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Sign Up',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Forgot Password',
  'AUTH.GENERAL.BACK_BUTTON': 'Back',
  'AUTH.GENERAL.PRIVACY': 'Privacy',
  'AUTH.GENERAL.LEGAL': 'Legal',
  'AUTH.GENERAL.CONTACT': 'Contact',
  'AUTH.LOGIN.TITLE': 'Login Account',
  'AUTH.LOGIN.BUTTON': 'Sign In',
  'AUTH.FORGOT.TITLE': 'Forgotten Password?',
  'AUTH.FORGOT.RESENDEMAIL': 'Resend Email',
  'AUTH.FORGOT.RESENDEMAILCONFIRM': 'Send',
  'AUTH.FORGOT.RESENDEMAILCANCEL': 'Cancel',
  'AUTH.FORGOT.DESC': 'Enter your email to reset your password',
  'AUTH.FORGOT.SUCCESS': 'Your account has been successfully reset.',
  'AUTH.REGISTER.TITLE': 'Sign Up',
  'AUTH.REGISTER.DESC': 'Enter your details to create your account',
  'AUTH.REGISTER.SUCCESS': 'Your account has been successfuly registered.',
  'AUTH.INPUT.EMAIL': 'Email',
  'AUTH.INPUT.FULLNAME': 'Fullname',
  'AUTH.INPUT.PASSWORD': 'Password',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Confirm Password',
  'AUTH.INPUT.USERNAME': 'Username',
  'AUTH.VALIDATION.INVALID': '{name} is not valid',
  'AUTH.VALIDATION.REQUIRED': '{name} is required',
  'AUTH.VALIDATION.MIN_LENGTH': '{name}} minimum length is {{min}',
  'AUTH.VALIDATION.AGREEMENT_REQUIRED': 'Accepting terms & conditions are required',
  'AUTH.VALIDATION.NOT_FOUND': 'The requested {name} is not found',
  'AUTH.VALIDATION.INVALID_LOGIN': 'The login detail is incorrect',
  'AUTH.VALIDATION.REQUIRED_FIELD': 'Required field',
  'AUTH.VALIDATION.MIN_LENGTH_FIELD': 'Minimum field length:',
  'AUTH.VALIDATION.MAX_LENGTH_FIELD': 'Maximum field length:',
  'AUTH.VALIDATION.INVALID_FIELD': 'Field is not valid',
  'AUTH.VALIDATION.PASSWORD': 'Password is required',
  'AUTH.VALIDATION.MINSYMBOLS': 'Minimum three simbols',
  'AUTH.VALIDATION.MAXSYMBOLS': 'Maximum three simbols',
  'AUTH.VALIDATION.CONFIRMPASSWORD': 'Confirm password is required',
  'AUTH.VALIDATION.CONFIRMPASSWORDMATCH': 'Confirm password and password must match',
  'AUTH.VALIDATION.SAVE': 'Save',
  'AUTH.VALIDATION.SAVESUCCESS': 'Saved',
  'AUTH.VALIDATION.SAVERROR': 'Error',
  'AUTH.VALIDATION.WRITEYOURPASSWORD': 'Write your new password and password confirmation',
}
