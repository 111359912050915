/* eslint-disable react-hooks/exhaustive-deps */
 
 
 
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useContext, useEffect, useState} from 'react'
import {Row} from 'antd'
import {Box} from '../../../../core/layout/components/Box/Box'
import {Button} from '../../../../components'
import {Container} from 'react-bootstrap'
import DialogComponent from '../../../../components/Dialog'
import ListFiles from '../common/ListFiles'
import LoadingBar from 'react-top-loading-bar'
import ModalLoader from '../../../../core/components/ModalLoader'
import PageCounter from './PageCounter'
import PdfViewer from '../../../../core/components/PdfViewer/PdfViewer'
import {RootStoreContext} from '../../../../stores/rootStore'
import Swal from 'sweetalert2'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import './style.css'
import ListFilesExtra from './ListFilesExtra'

interface IProps {
  translate: string
  setTranslation: (value: boolean) => void
  setList: (value: boolean) => void
  uploaded?: boolean
  setCounterToRefreshTranslatedDocument?: React.Dispatch<React.SetStateAction<number>>
  counterToRefreshTranslatedDocument?: number
}

const ShowTranslation: React.FC<IProps> = ({
  translate,
  setList,
  setTranslation,
  uploaded,
  setCounterToRefreshTranslatedDocument,
  counterToRefreshTranslatedDocument,
}) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(true)
  const [files, setFiles] = useState<any>()
  const [allowDelete, setAllowDelete] = useState(false)

  const rootStore = useContext(RootStoreContext)
  const {
    isLoadingTranslation,
    translationsFiles,
    getTranslation,
    getTranslationResult,
    downloadFile,
    downloadFilePreview,
    deleteFile,
  } = rootStore.translationStore

  const {user} = rootStore.authStore
  const {translationsResult} = rootStore.translationStore
  const [percent, setPercent] = useState(-1)
  const [translationId, setTranslationId] = useState('')

  useEffect(() => {
    getTranslation(translate)
    getTranslationResult(translate)
  }, [translate])

  useEffect(() => {
    if (translationId === '' && translate) {
      setTranslationId(translate)
    }
  }, [translate])
  console.log('counter' + counterToRefreshTranslatedDocument)

  useEffect(() => {
    getTranslationResult(translationId)
  }, [counterToRefreshTranslatedDocument])

  useEffect(() => {
    if (user?.role?.name !== 'User') {
      setAllowDelete(true)
    }
  }, [user])

  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  const handleDownload = (id: string) => {
    downloadFile(id, getProgress)
  }

  const handleDeleteFile = async (id: string) => {
    // ask for confirmation before delete swal
    Swal.fire({
      title: intl.formatMessage({id: 'TRANSLATION.ASKDELETEFILE'}),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({id: 'TRANSLATION.ASKDELETEFILEBTNCONFIRM'}),
      cancelButtonText: intl.formatMessage({id: 'TRANSLATION.ASKDELETEFILEBTNCANCEL'}),
    }).then(async (result) => {
      if (result.value) {
        await deleteFile(id)
        await getTranslationResult(translate)
        if (setCounterToRefreshTranslatedDocument && counterToRefreshTranslatedDocument) {
          setCounterToRefreshTranslatedDocument(counterToRefreshTranslatedDocument + 1)
        }
      }
    })
  }
  const handleBack = () => {
    setTranslation(false)
    setList(true)
  }

  const handlePreview = async (id: string) => {
    const file = await downloadFilePreview(id, getProgress)
    setFiles(file)
    setIsOpen(true)
  }

  return (
    <div>
      {files && (
        <DialogComponent
          isOpen={isOpen}
          closeModal={() => {
            setIsOpen(false)
          }}
          title={''}
        >
          <div>
            Paginas: <PageCounter file={files} />
            <PdfViewer url={files} name={'documento.pdf'} />
          </div>
        </DialogComponent>
      )}

      <Container>
        {isLoadingTranslation ? (
          <div>
            <ModalLoader />
          </div>
        ) : null}
        <Button
       className='back-button-translation-user'
          onClick={() => {
            handleBack()
          }}
        >
          Regresar
        </Button>
        <br />

        {user?.role?.name === 'User' && translationsResult.length > 0 ? <></> : null}
        <LoadingBar
          color='#BE8575'
          progress={percent}
          height={5}
          shadow={true}
          shadowStyle={{boxShadow: '0 0 20px #BE8575, 0 0 20px #BE8575'}}
        />
        <Row className='translation-user-container' style={{width: '100%'}}>
          <Box className='ant-col' style={{width: '100%'}}>
            <h2>Tus archivos subidos</h2>
            <ListFiles
              downloadFile={handleDownload}
              data={translationsFiles}
              previewFile={handlePreview}
            />
          </Box>
          {translationsResult.find((translation: any) =>
            translation.originalname.includes('ImmilandExtraDocument')
          ) ? (
            <>
              <Box className='ant-collumn' style={{width: '100%'}}>
                {translationsResult?.length > 0 ? (
                  <>
                    <h2>Extra Documentos</h2>
                    <ListFilesExtra
                      downloadFile={handleDownload}
                      data={translationsResult}
                      previewFile={handlePreview}
                      allowDelete={allowDelete}
                      deleteFile={handleDeleteFile}
                    />
                  </>
                ) : null}
              </Box>
            </>
          ) : null}

          <div className='translation-user-container-complete' style={{width: '100%'}}>
            {translationsResult?.length > 0 ? (
              <>
                <h2>Traducciones completadas</h2>

                <ListFiles
                  downloadFile={handleDownload}
                  data={translationsResult}
                  previewFile={handlePreview}
                  allowDelete={allowDelete}
                  deleteFile={handleDeleteFile}
                />
              </>
            ) : (
              <div></div>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default observer(ShowTranslation)
