export const clients = {
  'CLIENTS.CLIENTSLIST': 'Lista de clientes',
  'CLIENTS.SEARCH': 'Buscar',
  'CLIENTS.NAME': 'Nombre',
  'CLIENTS.EMAIL': 'Correo electronico',
  'CLIENTS.PROFILEINFORMATION': 'Informacion del perfil',
  'CLIENTS.PURCHASEDSERVICES': 'Servicios comprados',
  'CLIENTS.COMMUNICATION': 'Comunicacion',
  'CLIENTS.TRANSLATIONS': 'Traduciones',
  'CLIENTS.PROFILE': 'Perfil',
  'CLIENTS.BACK': 'Atras',
  'CLIENTS.FORMS': 'Formularios',
  'CLIENTS.DOCUMENTS': 'Documentos',
  'CLIENTS.MYPROCESSVISA': 'Mi proceso de visa',
  'CLIENTS.PROCESSVISA': 'Proceso de visa',
}
