/* eslint-disable @typescript-eslint/no-unused-vars */

import {type Calendly, type CalendlyQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const Calendly_URL = `${API_URL}/calendlypublic`

const formatDate = (date: Date) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`
}

const getCalendly = async (date: string, timezone: string): Promise<Calendly[]> => {
  return await axios
    .post(`${Calendly_URL}/all`, {
      date,
      timezone,
    })
    .then((response: AxiosResponse<CalendlyQueryResponse>) => {
      return response.data as Calendly[]
    })
}

const getAllMontCalendly = async (date: string, timezone: string): Promise<any[]> => {
  return await axios
    .post(`${Calendly_URL}/allonmonth`, {
      date,
      timezone,
    })
    .then((response: AxiosResponse<CalendlyQueryResponse>) => {
      return response.data as any[]
    })
}

export {getCalendly, getAllMontCalendly}
