/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  TranslationsQueryNew,
  type IQueryParams,
  type Translations,
  type TranslationsPartial,
  type TranslationsQueryResponse,
  TranslationsQueryNewResponse,
} from './_models'
import axios, {type AxiosRequestConfig, type AxiosResponse} from 'axios'

import Buffer from 'buffer'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'
import fileDownload from 'js-file-download'
const API_URL = process.env.REACT_APP_API_URL
const TRANSLATIONS_URL = `${API_URL}/translations`
const uploadFiles = async (
  fileListObject: any,
  getProgress: Function,
  type: string,
  translationId: string
) => {
  const formData = new FormData()
  fileListObject.forEach((fileObject: any) => {
    formData.append('files[]', fileObject.originFileObj)
  })
  formData.append('type', type)
  formData.append('translationId', translationId)
  formData.append('uploadExtra', 'false')
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${TRANSLATIONS_URL}/uploadtranslate`, formData, config as {})
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const uploadFilesResult = async (
  fileListObject: any,
  getProgress: Function,
  type: string,
  translationId: string
) => {
  const formData = new FormData()
  fileListObject.forEach((fileObject: any) => {
    formData.append('files[]', fileObject.originFileObj)
  })
  formData.append('type', type)
  formData.append('translationId', translationId)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${TRANSLATIONS_URL}/uploadresult`, formData, config as {})
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const getTranslations = async (translationId: string): Promise<Translations[]> => {
  return await axios
    .get(`${TRANSLATIONS_URL}/files/${translationId}`)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}
const getTranslationsResult = async (taskId: string): Promise<Translations[]> => {
  return await axios
    .get(`${TRANSLATIONS_URL}/result/${taskId}`)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}
const getAllTranslation = async (queryParams: IQueryParams): Promise<Translations[]> => {
  return await axios
    .post(`${TRANSLATIONS_URL}`, queryParams)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}

const getAllTranslationNew = async (queryParams: IQueryParams): Promise<TranslationsQueryNew> => {
  return await axios
    .post(`${TRANSLATIONS_URL}/allNew`, queryParams)
    .then((d: AxiosResponse<TranslationsQueryNewResponse>) => {
      return d.data as unknown as TranslationsQueryNew
    })
}
const setStaffToTranslation = async (
  staffId: string,
  translationId: string
): Promise<Translations[]> => {
  return await axios
    .post(`${TRANSLATIONS_URL}/setstaff`, {staffId, translationId})
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}

const saveSurvey = async (body: any): Promise<Translations[]> => {
  return await axios
    .post(`${TRANSLATIONS_URL}/survey`, {body})
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}

const getSurvey = async (translationId: any): Promise<Translations[]> => {
  return await axios
    .get(`${TRANSLATIONS_URL}/getSurvey/${translationId}`)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}

const getSurveyStar = async (): Promise<Translations[]> => {
  return await axios
    .get(`${TRANSLATIONS_URL}/getSurveyStar`)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[];
    });
};

const updateTranslation = async (
  field: TranslationsPartial,
  translationId: string
): Promise<Translations[]> => {
  return await axios
    .patch(`${TRANSLATIONS_URL}/update/${translationId}`, field)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}
const getAllTranslationUser = async (): Promise<Translations[]> => {
  return await axios
    .post(`${TRANSLATIONS_URL}/all/user`)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data as Translations[]
    })
}
const downloadFileTranslation = async (id: string, getProgress: Function) => {
  const config: AxiosRequestConfig = {
    responseType: 'stream',
    /*  onDownloadProgress: (progressEvent: { loaded: number, total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    } */
  }
  return await axios.get(`${TRANSLATIONS_URL}/download/${id}`, config).then((response) => {
    const buff = Buffer.Buffer.from(response.data.file)
    fileDownload(buff, response.data.fileName)
    return null
  })
}
const getAllTranslationUserService = async (userId: string): Promise<Translations[]> => {
  return await axios
    .post(`${TRANSLATIONS_URL}/all/userservice/${userId}`)
    .then((d: AxiosResponse<Translations[]>) => {
      return d.data
    })
}
const checkAssignments = async (): Promise<any> => {
  return await axios
    .post(`${TRANSLATIONS_URL}/checktransaltions`)
    .then((d: AxiosResponse<Translations[]>) => {
      return d.data
    })
}
const downloadFileTranslationPreview = async (id: string, getProgress: Function) => {
  const config: AxiosRequestConfig = {
    responseType: 'stream',
    /*  onDownloadProgress: (progressEvent: { loaded: number, total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    } */
  }
  return await axios.get(`${TRANSLATIONS_URL}/download/${id}`, config).then((response) => {
    const buff = Buffer.Buffer.from(response.data.file)
    // conver file to blob
    const blob = new Blob([buff], {type: 'application/pdf'})
    // create url from blob
    const url = window.URL.createObjectURL(blob)
    // fileDownload(buff, response.data.fileName)
    return blob
  })
}
const deleteFile = async (id: string) => {
  return await axios
    .delete(`${TRANSLATIONS_URL}/deletefile/${id}`)
    .then((d: AxiosResponse<Translations[]>) => {
      return d.data
    })
}
export {
  getTranslations,
  saveSurvey,
  getTranslationsResult,
  getAllTranslation,
  setStaffToTranslation,
  updateTranslation,
  getAllTranslationUser,
  uploadFiles,
  downloadFileTranslation,
  uploadFilesResult,
  getAllTranslationUserService,
  checkAssignments,
  downloadFileTranslationPreview,
  deleteFile,
  getAllTranslationNew,
  getSurvey,
  getSurveyStar,
}
