import axios, {type AxiosResponse} from 'axios'
import {PaypalResponse} from './_models'
import {axiosErrorHandler} from 'app/modules/errors/ApiError'
const API_URL = process.env.REACT_APP_API_URL
const FILE_URL = `${API_URL}/translations`
const createUserPaypal = async (
  email: string,
  firstName: string,
  lastName: string
): Promise<any> => {
  return await axios
    .post(`${API_URL}/users/paypal`, {
      email,
      firstName,
      lastName
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createUserBasic = async (email: string, password: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/users`, {
      email,
      password
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findUserByEmail = async (email: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/users/email`, {
      email,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findUserById = async (id: any): Promise<any> => {
  return await axios.get(`${API_URL}/users/${id}`).then((d: AxiosResponse<PaypalResponse>) => {
    return d.data
  })
}

const findPaymentByIntent = async (data: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/paymentpaypal/intent`, {
      data,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findOrderByPaymentId = async (data: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/orders/paypal/payment`, {
      data,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findTask = async (description: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/tasks/product`, {
      description,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findProductByDesc = async (data: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/products/paypal/description`, {
      data,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findTaskByUser = async (data: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/tasksuserss/userid`, {
      data,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findOrderDetail = async (order: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/orderdetails/orderId`, {
      order,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const findTranslation = async (data: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/translations/paypal/task`, {
      data,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const uploadFilesPaypal = async (files: any, getProgress: Function) => {
  const formData = new FormData()
  files?.forEach((file: any) => {
    formData.append('files[]', file)
  })
  formData.append('length', files?.length.toString() || '0')

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadtranslatepaypal`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}

const createPaymentPaypal = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/paymentPaypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createTranslationDetail = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/translationdetails/paypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createTranslationResult = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/translationresults/paypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createOrderPaypal = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/orders/paypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createOrderDetail = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/orderdetails/createpaypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createTask = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/tasksuserss/paypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createTaskManual = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/tasksuserss/manual`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const createTranslation = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/translations/paypal`, {
      body,
    })
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

const emailTranslation = async (email: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/products/transemail/${email}`)
    .then((d: AxiosResponse<PaypalResponse>) => {
      return d.data
    })
}

export {
  createUserPaypal,
  findUserById,
  createUserBasic,
  findOrderDetail,
  uploadFilesPaypal,
  findTask,
  createTranslationDetail,
  createTranslationResult,
  findTranslation,
  createPaymentPaypal,
  findUserByEmail,
  createOrderPaypal,
  createOrderDetail,
  createTask,
  createTranslation,
  findPaymentByIntent,
  findOrderByPaymentId,
  findProductByDesc,
  findTaskByUser,
  emailTranslation,
  createTaskManual
}
