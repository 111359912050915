/* eslint-disable @typescript-eslint/no-unused-vars */

import {useEffect, useState} from 'react'
import {
  type IComposeData,
  initialComposeData,
  IReply,
  initialReplyData,
} from '../../../../app/modules/apps/appointments/core/_models'
import AssignTranslation from './AssignTranslation'
import {Container} from '../../../../components/Container/index'
import {type IToUsers} from '../../../../app/pages/messages/interfaces/ITOUsers'
import {PostConsult} from '../../../../app/pages/messages'
import RigthPanel from './RigthPanel'
import ShowTranslation from '../common/ShowTranslation'
import {type Translations} from '../../../../app/modules/apps/translations/core/_models'
import TranslationsComponent from './TranslationsComponent'
import {useIntl} from 'react-intl'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import UploadHandlerStaff from '../staff/UploadHandlerStaff'
import {Box} from 'core/layout/components/Box/Box'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const TranslationsWrapperAdmin: React.FC<Props> = (props) => {
  const {ProfileImmigration} = props
  const intl = useIntl()
  const [assign, setAssign] = useState(false)
  const [translation, setTranslation] = useState<Translations>({})
  const [composePost, setComposePost] = useState<boolean>(false)
  const [tabNumber, setTabNumber] = useState('')
  const [composepostdata, setComposePostData] = useState<IReply>(initialReplyData)
  const [translate, setTranslate] = useState('')
  const [filesUploaded, setFilesUploaded] = useState(false)
  const [uploadFiles, setUploadFiles] = useState(false)
  const [to, setTO] = useState<IToUsers[]>([])
  const [counterToRefreshTranslatedDocument, setCounterToRefreshTranslatedDocument] =
    useState<number>(0)

  useEffect(() => {
    // comment to deactivate eslint
  }, [translation])

  if (assign) {
    return (
      <AssignTranslation
        translation={translation}
        setAssign={setAssign}
        setTranslation={setTranslation}
      />
    )
  }

  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.TRANSLATION'})}>
        {translation.id && (
          <>
            <RigthPanel showPanel={true}>
              <ShowTranslation
                setTranslation={setComposePost}
                setList={() => {
                  setTranslation({})
                }}
                translate={translation.id ? translation.id : ''}
                setCounterToRefreshTranslatedDocument={setCounterToRefreshTranslatedDocument}
                counterToRefreshTranslatedDocument={counterToRefreshTranslatedDocument}
              />
              <Box style={{marginLeft: '1.5rem', width: '91%'}}>
                <h2>Adjuntador</h2>
                <UploadHandlerStaff
                  setUploadFiles={setUploadFiles}
                  setTranslate={setTranslate}
                  setFilesUploaded={setFilesUploaded}
                  setList={() => {
                    setTranslation({})
                  }}
                  translate={translation.id ? translation.id : ''}
                  setCounterToRefreshTranslatedDocument={setCounterToRefreshTranslatedDocument}
                  counterToRefreshTranslatedDocument={counterToRefreshTranslatedDocument}
                />
              </Box>
            </RigthPanel>
          </>
        )}
        {composePost && (
          <>
            <PostConsult
              setComposePost={setComposePost}
              setCC={setTO}
              cc={[]}
              composepostdata={composepostdata}
              showShareFiles={false}
              type='translation'
              showSelectFiles={false}
              initialContent=''
              showTemplateSearch={false}
              title='Traducción'
              initialSubject={'Traducción'}
              ProfileImmigration={ProfileImmigration}
              tabNumber={tabNumber}
              setTabNumber={setTabNumber}
              setComposePostData={setComposePostData}
            />
          </>
        )}

        {!composePost && (
          <TranslationsComponent
            setAssign={setAssign}
            setTranslation={setTranslation}
            setComposePost={setComposePost}
            setComposePostData={setComposePostData}
            setTabNumber={setTabNumber}
          />
        )}
      </Container>
    </>
  )
}

export {TranslationsWrapperAdmin}
