/* eslint-disable @typescript-eslint/no-unused-vars */
import {Switch} from 'antd'
import {observer} from 'mobx-react'
import {useQuery} from 'react-query'
import {
  getAnswerByEmail,
  saveContractTemps,
} from '../../../../../app/modules/apps/contract/_requests'
import {useIntl} from 'react-intl'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeaders,
  TableRow,
  Table,
  P,
} from '../../../../../components'
import './style.css'
import {RootObjectCompletedContracts} from 'app/modules/apps/docusign/_models'
interface IProps {
  userEmail: string
  contractsFound: RootObjectCompletedContracts
}

const ShowAnswersConsultant = (props: IProps) => {
  const intl = useIntl()
  const {userEmail} = props
  const {
    data: dataResult,
    status,
    refetch,
    isSuccess,
    isLoading,
  } = useQuery('contractsByEmail', async () => await getAnswerByEmail(userEmail))
  if (isSuccess) {
    // deactive, linter
  }
  const handleCheckBox = async (checked: boolean, id: string) => {
    const payload = {
      id,
      visible: checked,
    }

    await saveContractTemps(payload)
  }
  return (
    <>
      {dataResult != null ? (
        <div className='main-data-continer'>
          {dataResult.map((answers) => (
            <div style={{marginTop: '50px'}}>
              <h2> {intl.formatMessage({id: 'CONTRACTS.STATUS'})}</h2>{' '}
              <Switch
                defaultChecked={answers.visible}
                onChange={(e) => {
                  handleCheckBox(e, answers.id)
                }}
              />
              <div className='main-data-continer'>
                {answers?.props.map((data: any) => (
                  <div className='aplicant-continer'>
                    <div
                      className='aplicant-header'
                      style={{display: 'flex', flexDirection: 'column'}}
                    >
                      <div>
                        <h3>
                          {Object.keys(JSON.parse(data)).map((key) =>
                            key === 'Tipo de familiar'
                              ? JSON.parse(data)[key as keyof typeof data]
                              : ''
                          )}
                          {': '}
                          {Object.keys(JSON.parse(data)).map((key) =>
                            key === 'Nombres' ? JSON.parse(data)[key as keyof typeof data] : ''
                          )}{' '}
                          {Object.keys(JSON.parse(data)).map((key) =>
                            key === 'Apellidos' ? JSON.parse(data)[key as keyof typeof data] : ''
                          )}
                        </h3>
                        {answers.match_id ? answers.match_id : ''}
                      </div>
                      <div></div>
                    </div>
                    <div className='aplicant-data-container'>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeaders>Respuestas:</TableHeaders>
                            <TableHeaders></TableHeaders>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {answers.applicants && (
                            <TableRow>
                              <TableCell>Productos comprados</TableCell>
                              <TableCell>
                                {JSON.parse(answers.applicants)
                                  .filter(
                                    (applicant: any) =>
                                      JSON.parse(data).Nombre + ' ' + JSON.parse(data).Apellidos ===
                                      applicant.memberName
                                  )
                                  .map((matchingApplicant: any) => matchingApplicant.productName)
                                  .join(', ')}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.keys(JSON.parse(data)).map((key) => (
                            <TableRow>
                              {' '}
                              <TableCell>{key}</TableCell>{' '}
                              <TableCell>
                                {typeof JSON.parse(data)[key as keyof typeof data] === 'boolean' ? (
                                  JSON.parse(data)[key as keyof typeof data] === true ? 'si' : 'no'
                                ) : (
                                  JSON.parse(data)[key as keyof typeof data]
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>no data</>
      )}
    </>
  )
}
export default observer(ShowAnswersConsultant)
