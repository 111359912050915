import {DateTime} from 'luxon'
import React from 'react'

interface Props {
  date: string | null
  timeZone: string
  onlyTime?: boolean
}

const TimeZone2: React.FC<Props> = ({date, timeZone, onlyTime = false}) => {
  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, {zone: timeZone}).toFormat('yyyy-MM-dd HH:mm:ss a')
    const split = keepOffset.split(' ')
    if (onlyTime) {
      return `${split[1]} ${split[2]}`
    } else {
      return keepOffset
    }
  }

  return <div> {date ? getDate(date) : null}</div>
}

export {TimeZone2}
