 
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {Dispatch, SetStateAction, useContext, useEffect} from 'react'
import {Box} from '../../../../../components/Box'
import {Button, Link} from '../../../../../components'
import {ClientsMenuOptions} from './styles'
import {RootStoreContext} from '../../../../../stores/rootStore'
import ShowClientDocuments from './ShowClientDocuments'
import ShowEmailUser from './ShowEmailUser'
import ShowProfileUser from './ShowProfileUser'
import ShowServices from './ShowServices'
import ShowTranslations from './ShowTranslations'
import {Tooltip} from 'antd'
import {UserBox} from '../UserBox'
import UserHeader from './UserHeader'
import {UserItems} from '../UserItems'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {type ProfileImmigration} from '../../../../../app/modules/apps/profile/core/_models'
import Swal from 'sweetalert2'
import './styles.scss'
import {getTimeLineByUserId} from 'app/modules/apps/timeline/_requests'
import UserServicesWrapper from 'app/pages/services/UserServicesWrapper'
import TimelineComponent from 'app/pages/user/newtimeline'
const initialState = {
  profile: false,
  forms: false,
  translations: false,
  services: false,
  email: false,
  menu: false,
  documents: false,
  timeline: false,
}
interface Props {
  ProfileImmigration: ProfileImmigration
  setUs?: Dispatch<SetStateAction<string>>
}
const UserMenusoptions: React.FC<Props> = (props, user) => {
  const {ProfileImmigration} = props
  const {setUs} = props
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {selectUser, currentUser} = rootStore.usersStore

  const [option, setOption] = React.useState(initialState)

  useEffect(() => {
    handleOption('menu')
  }, [])

  const handleOption = (key: string) => {
    setOption({
      ...initialState,
      [key]: true,
    })
  }

  return (
    <div style={{width:'100%'}}>
      <UserHeader ProfileImmigration={ProfileImmigration} user={user} />
      <br />

      {/*  {option.menu && (
        <>
          <Tooltip title={intl.formatMessage({id: 'CLIENTS.BACK'})}>
            <Button
              className=' justify-center items-center m-auto px-2 pb-2 pt-0.5 hover:rounded-full hover:bg-gray-200'
              onClick={async () => {
                await selectUser(null)
                if (setUs) await setUs('')
              }}
              style={{width: '12rem', alignSelf: 'start', gap: '0.5rem'}}
            >
              Volver a lista clientes
            </Button>
          </Tooltip>
          <UserBox>
            <UserItems
              onClick={() => {
                handleOption('profile')
              }}
            >
              <ClientsMenuOptions
                className='block mx-auto my-auto'
                src='/media/admin/profileinfo.png'
              />
              <br />
              <span> {intl.formatMessage({id: 'CLIENTS.PROFILEINFORMATION'})} </span>
            </UserItems>
            <UserItems
              onClick={() => {
                handleOption('services')
              }}
            >
              <ClientsMenuOptions
                className='block mx-auto my-auto'
                src='/media/admin/services.png'
              />
              <br />
              <span> {intl.formatMessage({id: 'CLIENTS.PURCHASEDSERVICES'})} </span>
            </UserItems>
            <UserItems
              onClick={() => {
                handleOption('email')
              }}
            >
              <ClientsMenuOptions
                className='block mx-auto my-auto'
                src='/media/admin/communications.png'
              />
              <br />
              <span> {intl.formatMessage({id: 'CLIENTS.COMMUNICATION'})} </span>
            </UserItems>
            <UserItems
              onClick={() => {
                handleOption('translations')
              }}
            >
              <ClientsMenuOptions
                className='block mx-auto my-auto'
                src='/media/admin/translations.png'
              />
              <br />
              <span> {intl.formatMessage({id: 'CLIENTS.TRANSLATIONS'})} </span>
            </UserItems>
            <UserItems
              onClick={() => {
                handleOption('documents')
              }}
            >
              <ClientsMenuOptions
                className='block mx-auto my-auto  '
                src='/media/admin/documents.png'
              />
              <br />
              <span> {intl.formatMessage({id: 'CLIENTS.PROCESSVISA'})} </span>
            </UserItems>
            <UserItems
              onClick={() => {
                handleOption('documents')
              }}
            >
              <Link
                className='textOnLink flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
                to='/admin/store/Apliprogrambysers'
              >
                <img className='linkImage w-32' src='/media/svg/menu-icons/registry2.png' alt='' />
              </Link>
              <span> Mis registros </span>
            </UserItems>
             <UserItems
              onClick={() => {
                handleOption('timeline')
              }}
            >
               <Link
                className='textOnLink flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
                to='/timelineadminsnew'
              >
                <img className='w-32' src='/media/svg/menu-icons/timeline.svg' alt='' />
                <span className='textTime'>{intl.formatMessage({id: 'MENU.TIMELINE'})}</span>
              </Link> *
            </UserItems>
          </UserBox>
        </>
      )}

      <Box>
        {option.profile && (
          <>
            <ShowProfileUser handleClose={handleOption} ProfileImmigration={ProfileImmigration} />
          </>
        )}
        {option.email && (
          <>
            <ShowEmailUser handleClose={handleOption} />
          </>
        )}
        {option.services && (
          <>
            <ShowServices handleClose={handleOption} />
          </>
        )}
        {option.translations && (
          <>
            <ShowTranslations handleClose={handleOption} />
          </>
        )}{' '}
        {option.forms && (
          <>
            <ShowForms handleClose={handleOption} />
          </>
        )}
        {option.documents && (
          <>
            <ShowClientDocuments
              handleClose={handleOption}
              currentUserEmail={currentUser?.email ? currentUser.email : ''}
            />
          </>
        )}
        {option.timeline && (
          <>
            <TimelineComponent user={user} email={''} />
          </>
        )}
      </Box> */}
    </div>
  )
}

export default UserMenusoptions
