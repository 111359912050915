/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {Container} from '../../../../components/Container/index'
import {useIntl} from 'react-intl'

interface IProps {
  link: string
}

const EmbedImmigration: React.FC<IProps> = ({link}) => {
  const [width, setWidth] = useState(window.innerWidth >= 1200 ? 1200 : window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight - 20)
  const updateDimensions = () => {
    let width = window.innerWidth
    const height = window.innerHeight - 400
    if (width >= 960) {
      width = 960
    }
    setWidth(width)
    setHeight(height)
  }
  const intl = useIntl()
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', link)
    head?.appendChild(script)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.CONTRACT'})}>
        <ArrowLeftOutlined
          className=' p-2 hover:bg-gray-200 hover:rounded-lg'
          onClick={() => {
            history.back()
          }}
        />
        <iframe
          src={link}
          width={'100%'}
          height={height}
          frameBorder='0'
          style={{border: 'none', overflow: 'hidden'}}
        />
      </Container>
    </>
  )
}

export default EmbedImmigration
