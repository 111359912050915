 

import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {type Product} from '../../../../app/modules/apps/cart/core/products/_models'
import {RootStoreContext} from '../../../../stores/rootStore'
import parse from 'html-react-parser'

const {REACT_APP_PUBLIC_URL} = process.env
interface IProps {
  product: Product
}

const ProductListDetail: React.FC<IProps> = ({product}) => {
  const rootStore = useContext(RootStoreContext)
  const {addTocart} = rootStore.cartStore

  return (
    <>
      <div className='row p-2  mt-4 product-detail border rounded'>
        <div className='col-md-3 mt-1'>
          <img
            className='img-fluid img-responsive rounded product-image'
            src={product.image}
            alt=''
          />
        </div>
        <div className='col-md-6 mt-1'>
          <h5>{product.name}</h5>

          <p className='text-justify text-truncate para mb-0'>
            {parse(product.description)}
            <br />
            <br />
          </p>
        </div>
        <div className='align-items-center align-content-center col-md-3 border-left mt-1'>
          <div className='d-flex flex-row align-items-center'>
            <h4 className='mr-1'>$ {product.price}</h4>
            <br />
          </div>
          <div className='d-flex flex-column mt-4'>
            <Link
              className='btn btn-primary btn-sm'
              to={`${REACT_APP_PUBLIC_URL}/store/product/${product.id}`}
            >
              Ver mas
            </Link>
            <br />
            <button
              className='btn btn-primary btn-sm'
              type='button'
              onClick={async () => {
                await addTocart(product, 1)
              }}
            >
              Añadir
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductListDetail
