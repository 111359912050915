 
/* eslint-disable  */
import {type Files, type QueryfilesData} from '../../../../app/modules/apps/file/core/_models'
import React, {useEffect, useState} from 'react'
import {Box} from '../../../../components'
import {ListFiles} from './ListFiles'
import {getFilesIds} from '../../../../app/modules/apps/file/core/_requests'
import {useQuery} from 'react-query'

interface IProps {
  getFiles?: (files: Files[]) => void
  files: Files[]
}

const initialQueryData: QueryfilesData = {
  type: 'file-manager',
  page: 1,
  search: '',
  limit: 100,
  ids: [],
  in: false,
}

const FileSelect: React.FC<IProps> = ({getFiles, files}) => {
  const [query, setQuery] = useState(initialQueryData)
  const [filter, setFilter] = useState('')
  const {data, status, refetch} = useQuery(
    'filemanagerids',
    async () =>
      await getFilesIds({
        ...query,
        ids: files.map((file) => file.id),
        in: false,
        search: filter,
      })
  )

  useEffect(() => {
    // debounce
    console.log('🚀 ~ file: index.tsx ~ line 43 ~ filter', filter)
    const timer = setTimeout(() => {
      refetch()
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [filter])

  useEffect(() => {
    // comment to deactivate eslint
  }, [files])

  const handleAddFile = (file: Files) => {
    console.log('🚀 ~ file: index.tsx ~ line 47 ~ handleAddFile ~ file', file)
    if (files.findIndex((f) => f.id === file.id) === -1) {
      getFiles != null ? getFiles([...files, file]) : null
    }
  }

  const handleRemove = (file: Files) => {
    getFiles != null ? getFiles(files.filter((f) => f.id !== file.id)) : null
  }

  return (
    <>
      <div className='flex flex-wrap -mx-px overflow-hidden sm:-mx-px lg:-mx-1 xl:-mx-px'>
        <div className='my-px px-px w-full overflow-hidden sm:my-px sm:px-px sm:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-px xl:px-px xl:w-1/2'>
          <Box>
            <ListFiles
              files={files}
              inIds={true}
              showSearch={false}
              showRemove={true}
              handleRemove={handleRemove}
            />
          </Box>
        </div>

        <div className='my-px px-px w-full overflow-hidden sm:my-px sm:px-px sm:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-px xl:px-px xl:w-1/2'>
          <Box>
            <ListFiles
              files={data?.data ? data.data : []}
              inIds={false}
              showSearch={true}
              showAdd={true}
              handleAddFile={handleAddFile}
              setFilter={setFilter}
              filter={filter}
            />
          </Box>
        </div>
      </div>
    </>
  )
}

export default FileSelect
