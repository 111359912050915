 
/* eslint-disable  */
import {Descriptions, PageHeader, Tabs, Timeline} from 'antd'
import './UserMessages/styles/TableStyle.css'
import {type IComposeData} from '../../../app/modules/apps/appointments/core/_models'
import {useQuery} from 'react-query'
import {getUserUsersById} from '../../../app/modules/apps/users/core/_requests'
import AvatarProfileUsers from '../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {ProfileImmigration, type Profile} from '../../../app/modules/apps/profile/core/_models'
import TimelineComponent from '../user/newtimeline'
import { IToUsers } from './interfaces/ITOUsers'

interface Props {
  areaDeEstudios?: string | null | undefined
  data?: string
  composepostdata: IComposeData
  setComposePostTimeline?: React.Dispatch<React.SetStateAction<boolean>>
  setComposeNote?: React.Dispatch<React.SetStateAction<boolean>>
  setCC: React.Dispatch<React.SetStateAction<IToUsers[]>>
  cc: IToUsers[]
  title: string
  showSelectFiles: boolean
  showShareFiles: boolean
  initialContent: string
  showTemplateSearch: boolean
  type: string
  initialSubject: string
  showComposeTemplate?: boolean
  ProfileImmigration: ProfileImmigration
  timelineUserId: string
}

const {TabPane} = Tabs

const MessagesTimeline: React.FC<Props> = ({
  composepostdata,
  setComposePostTimeline,
  setComposeNote,
  timelineUserId,
  title,
}) => {
  const handleClose = () => {
    if (setComposePostTimeline != null) setComposePostTimeline(false)
    if (setComposeNote != null) setComposeNote(false)
  }

  const {data} = useQuery('getUserInfo', async () => await getUserUsersById(composepostdata.userId))

  return (
    <>
      <div className=''>
        <div style={{position: 'absolute', left: '70vw', top: '15vh'}}>
          <AvatarProfileUsers
            image={
              composepostdata.photoID
                ? `${composepostdata.photoPath}/${composepostdata.photoID}-avatar-large.jpg`
                : ''
            }
            size={100}
          />
        </div>
        <PageHeader
          className='site-page-header-responsive'
          onBack={() => {
            handleClose()
          }}
          title={title}
          subTitle=''
          footer={
            <Tabs defaultActiveKey='3' animated={true}>
              <TabPane tab=' Linea de Tiempo' key='5'>
                <TimelineComponent user={data} email={''} timelineUserId={timelineUserId} />
              </TabPane>
            </Tabs>
          }
        >
          <Descriptions size='small' column={2}>
            <Descriptions.Item label='Nombre'>{composepostdata.user}</Descriptions.Item>
            <Descriptions.Item label='Email'>{data?.email}</Descriptions.Item>
            <Descriptions.Item label='Nacionalidad'>{data?.nationalitie}</Descriptions.Item>
            <Descriptions.Item label='Teléfono'>{data?.phone}</Descriptions.Item>
            <Descriptions.Item label='Dirección'>{data?.address}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>
    </>
  )
}

export {MessagesTimeline}
