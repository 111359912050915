import React, {useContext, useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import {RootStoreContext} from '../../../../../stores/rootStore'

interface Props {
  date: string | null
}

const TimeZone: React.FC<Props> = ({date}) => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore

  const [zone, setZone] = useState<string>('Canada/Saskatchewan')
  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    } else {
      setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }, [user])

  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, {zone}).toFormat('yyyy-MM-dd HH:mm')

    return keepOffset.toString()
  }

  return <p style={{ fontSize: '0.8rem', margin: '0', fontWeight: 'bold' }}> {date ? getDate(date) : null}</p>
}

export {TimeZone}
