/* eslint-disable @typescript-eslint/no-unused-vars */

import {Input, Space, Table} from 'antd'
import React, {useEffect, useState} from 'react'
import AvatarProfileUsers from '../../../../../src/app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {type Staff} from '../../../modules/apps/staff/core/_models'
import {getStaffs} from '../../../modules/apps/staff/core/_requests'
import {useQuery} from 'react-query'

const {Column} = Table
interface Props {
  setStaff: (staff: Staff) => void
}

const StaffSelect: React.FC<Props> = ({setStaff}) => {
  const [options, setOptions] = useState<Staff[]>([])
  const [query, setQuery] = useState<Staff>({})
  const {data, refetch} = useQuery('getAllStaffs', async () => await getStaffs(query))

  useEffect(() => {
    if (query) {
      const delayDebounceFn = setTimeout(() => {
        refetch()
      }, 500)
      return () => {
        clearTimeout(delayDebounceFn)
      }
    }
  }, [query, refetch])

  useEffect(() => {
    if (data != null) {
      setOptions(data)
    }
  }, [data])

  const handleSearch = (value: string) => {
    const staffsVars: Staff = {
      type: 'consultant,immigration_admin,consultant_admin,coo',
      name: value,
    }
    setQuery(staffsVars)
  }

  const handleSelect = (staff: Staff) => {
    setStaff(staff)
  }

  return (
    <>
      <input
      className="custom-input"
        style={{
          width: '100%',
        }}
        onChange={(e) => {
          handleSearch(e.target.value)
        }}
      />

      <Table dataSource={options} rowKey='id'>
        <Column

          render={(record: any) => (
            <Space
              style={{cursor: 'pointer'}}
              onClick={() => {
                handleSelect(record)
              }}
            >
              <AvatarProfileUsers size={20} image={record.avatar} />
            </Space>
          )}
        />
        <Column
          title=''
          render={(record: any) => (
            <Space
              style={{cursor: 'pointer'}}
              onClick={() => {
                handleSelect(record)
              }}
            >
              {record.name}
            </Space>
          )}
        />
      </Table>
    </>
  )
}

export default StaffSelect
