/* eslint-disable @typescript-eslint/no-unused-vars */
import {Alert, Button, Container, H3, Input} from '../../../../components'
import {useIntl} from 'react-intl'
import {useContext} from 'react'
import {changeUserPassword} from '../../../../auth/models/AuthRequest'
import {RootStoreContext} from '../../../../stores/rootStore'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'

const ChangeUserPassword = () => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const intl = useIntl()

  const passwordRequired = intl.formatMessage({id: 'AUTH.VALIDATION.PASSWORD'})
  const minSymbols = intl.formatMessage({id: 'AUTH.VALIDATION.MINSYMBOLS'})
  const maxSymbols = intl.formatMessage({id: 'AUTH.VALIDATION.MAXSYMBOLS'})
  const confirmPassword = intl.formatMessage({id: 'AUTH.VALIDATION.CONFIRMPASSWORD'})
  const confirmPasswordMatch = intl.formatMessage({id: 'AUTH.VALIDATION.CONFIRMPASSWORDMATCH'})
  const save = intl.formatMessage({id: 'AUTH.VALIDATION.SAVE'})
  const saveSuccess = intl.formatMessage({id: 'AUTH.VALIDATION.SAVESUCCESS'})
  const saveError = intl.formatMessage({id: 'AUTH.VALIDATION.SAVERROR'})
  const back = intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})
  const passwordTitle = intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})
  const confirmPasswordTitle = intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})
  const writePassword = intl.formatMessage({id: 'AUTH.VALIDATION.WRITEYOURPASSWORD'})

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, `${minSymbols}`)
      .max(50, `${maxSymbols}`)
      .required(`${passwordRequired}`),
    confirmPassword: Yup.string()
      .required(`${confirmPassword}`)
      .oneOf([Yup.ref('password')], `${confirmPasswordMatch}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setStatus('')

      if (user != null) {
        changeUserPassword(user?.id, values.confirmPassword).then(async (response) => {
          Swal.fire({
            title: `${saveSuccess} !`,
            text: `${saveSuccess}`,
            icon: 'success',
            confirmButtonText: `${back}`,
          })
        })
      }
    },
  })

  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.CHANGEPASSWORD'})} >
        <div id='kt_account_profile_details'>
          <form className='' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
            <div className='card-body p-9 bg-white'>
              <H3>{writePassword}</H3>
              <section>
                <div className='row mb-6 mt-8'>
                  <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>{`${passwordTitle}`}</H3>
                  <div className='col-lg-8 fv-row'>
                    <Input
                      type='password'
                      autoComplete='off'
                      placeholder={`${passwordTitle}`}
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        '',
                        {
                          'is-invalid': (
                            <Alert>formik.touched.password && formik.errors.password,</Alert>
                          ),
                        },
                        {
                          'is-valid': (
                            <Alert>formik.touched.password && !formik.errors.password,</Alert>
                          ),
                        }
                      )}
                    />
                    <Alert>
                      {formik.touched.password && formik.errors.password && (
                        <div className=''>
                          <div className=''>
                            <span role='alert' className=''>
                              <Alert>{formik.errors.password}</Alert>
                            </span>
                          </div>
                        </div>
                      )}
                    </Alert>
                  </div>
                </div>
              </section>
              <section>
                <div className='row mb-6 mt-8'>
                  <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>{`${confirmPasswordTitle}`}</H3>
                  <div className='col-lg-8 fv-row'>
                    <Input
                      type='password'
                      autoComplete='off'
                      placeholder={`${confirmPasswordTitle}`}
                      {...formik.getFieldProps('confirmPassword')}
                      className={clsx(
                        '',
                        {
                          'is-invalid': (
                            <Alert>
                              formik.touched.confirmPassword && formik.errors.confirmPassword,
                            </Alert>
                          ),
                        },
                        {
                          'is-valid': (
                            <Alert>
                              formik.touched.confirmPassword && !formik.errors.confirmPassword,
                            </Alert>
                          ),
                        }
                      )}
                    />
                    <Alert>
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className=''>
                          <div className=''>
                            <span role='alert' className=''>
                              <Alert>{formik.errors.confirmPassword}</Alert>
                            </span>
                          </div>
                        </div>
                      )}
                    </Alert>
                  </div>
                </div>
              </section>
              <section>
                <Alert>
                  {formik.status && (
                    <div className=''>
                      <div className=''>
                        <span role='alert' className=''>
                          <Alert>{formik.status}</Alert>
                        </span>
                      </div>
                    </div>
                  )}
                </Alert>
                <Button type='submit'>{save}</Button>
              </section>
            </div>
          </form>
        </div>
      </Container>
    </>
  )
}

export default ChangeUserPassword
