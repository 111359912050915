import React from 'react'

const MainLayout = () => {
  return (
    <div>
      <h1>Desde MainLayout Yessss</h1>
    </div>
  )
}

export default MainLayout
