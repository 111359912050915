import React, {type ReactNode} from 'react'
interface ContentProps {
  children: ReactNode
}
const EmbedContent: React.FC<ContentProps> = ({children}) => {
  return (
    <main className=''>
      <div className='main-windows'>{children}</div>
    </main>
  )
}

export default EmbedContent
