/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useContext, useEffect} from 'react'
import {RootStoreContext} from '../../../../../stores/rootStore'
import ShowForms from './ShowForms'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'

const initialState = {
  profile: false,
  forms: false,
  translations: false,
  services: false,
  email: false,
  menu: false,
}

const ClientServicesMenusoptions = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {selectUser, currentUser} = rootStore.usersStore

  const [option, setOption] = React.useState(initialState)

  useEffect(() => {
    handleOption('menu')
  }, [])

  const handleOption = (key: string) => {
    setOption({
      ...initialState,
      [key]: true,
    })
  }

  return (
    <div>
      {' '}
      <ShowForms handleClose={handleOption} />
    </div>
  )
}

export default observer(ClientServicesMenusoptions)
