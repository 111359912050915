import {Container} from '../../../components/Container/index'
import {type FC} from 'react'
import {TemplateManager} from './TemplateManager'
import {useIntl} from 'react-intl'

const TemplateManagerWrapper: FC = () => {
  const intl = useIntl()
  return (
    <Container title={intl.formatMessage({id: 'MENU.TEMPLATES'})}>
      <TemplateManager />
    </Container>
  )
}

export {TemplateManagerWrapper}
