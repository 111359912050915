/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Autocomplete, type IOption} from '../../../../../components'
import React, {useEffect, useState} from 'react'
import {type Product} from '../../../../../app/modules/apps/cart/core/products/_models'
import {getProducts} from '../../../../../app/modules/apps/cart/core/products/_requests'
import {useQuery} from 'react-query'

interface IProps {
  selected: IOption
  setSelected: (option: IOption) => void
}
const ProductSelect: React.FC<IProps> = ({selected, setSelected}) => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<IOption[]>([])
  const {data, status, refetch} = useQuery('getAllProducts', async () => await getProducts(query))

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      refetch()
    }, 500)
    console.log('🚀 ~ file: index.tsx ~ line 27 ~ timer ~ timer', timer)
    return () => {
      clearTimeout(timer)
    }
  }, [query])

  useEffect(() => {
    if (status === 'success') {
      console.log('🚀 ~ file: index.tsx ~ line 32 ~ useEffect ~ status', status)
    }
  }, [status])

  useEffect(() => {
    if (data != null) {
      const products = data.map((product: Product) => {
        return {id: product.id || '', value: product.name || ''}
      })
      setOptions(products)
    }
  }, [data])

  const handleSelect = (option: IOption) => {
    setSelected(option)
  }

  return (
    <>
      <Autocomplete
        options={options}
        onChange={handleSearch}
        isLoading={loading}
        onSelect={handleSelect}
        selected={selected}
      />
    </>
  )
}

export default ProductSelect
