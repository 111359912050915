/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { type Dispatch, type SetStateAction, useContext, useState, useEffect } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { getFormsNew } from '../../../../modules/apps/forms/core/_requests'
import { useQuery } from 'react-query'
import { Checkbox, DatePicker, Space } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CountrySelect } from 'app/modules/utils/components/CountrySelect'
import moment from 'moment'
import Lifering from '../../icons/question-circle.svg'
import PostalCodeSearch from './postaCodeFilter'
import { CitySelect } from 'app/modules/utils/components/CanadaCitiesSelect/Index'

interface IProps {
  formIndex: string
  setOpenForm: Dispatch<SetStateAction<boolean>>
}

const DisplayFormsTests: React.FC<IProps> = ({ formIndex, setOpenForm }) => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [shouldShowConditional, setShouldShowConditional] = useState(false)
  const [currentAnswers, setCurrentAnswers] = useState<any>(null)
  const [currentUser, setCurrentUser] = useState('')
  const [readpopup, setReadpopup] = useState(0)
  const [readValidator, setReadValidator] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [spawnQuestion, setSpawnQuestion] = useState(0)
  const [alert, setAlert] = useState(false)
  const [alertIndex, setAlertIndex] = useState(0)
  const [startDate, setStartDate] = useState<Record<number, string>>({})
  const [endDate, setEndDate] = useState<Record<number, string>>({})
  const [errors, setErrors] = useState<Record<number, string>>({})
  const [errors2, setErrors2] = useState<Record<number, string>>({})
  const [noErrors, setNoErrors] = useState(true)
  const [noErrors2, setNoErrors2] = useState(true)

  const { data, status, isLoading } = useQuery(
    'form',
    async () => await getFormsNew('limit=50&page=1&name='),
    { retry: false, cacheTime: 0 }
  )

  const handleStartDateChange = (index: number, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : ''
    setStartDate(prev => ({ ...prev, [index]: dateString }))

    if (dateString && endDate[index] && dateString > endDate[index]) {
      setErrors(prev => ({ ...prev, [index]: 'La fecha "Desde" no puede ser mayor que la fecha "Hasta".' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }

  const handleEndDateChange = (index: number, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : ''
    setEndDate(prev => ({ ...prev, [index]: dateString }))

    if (startDate[index] && dateString && startDate[index] > dateString) {
      setErrors(prev => ({ ...prev, [index]: 'La fecha "Hasta" no puede ser menor que la fecha "Desde".' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }

  const handleDateMustBeCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors(prev => {
          const newErrors = { ...prev, [index]: 'La fecha debe ser mayor a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors(prev => {
          const newErrors = { ...prev }
          delete newErrors[index]// Debugging log
          return newErrors
        })
      }
    }
  }

  const handleDateMustBeNotCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha debe ser previa a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  const handleDateFromMustBeCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha desde debe ser mayor a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  useEffect(() => {
    setNoErrors2(Object.keys(errors2).length === 0)
  }, [errors2])

  useEffect(() => {
    if (noErrors2 !== null) {
      if (currentAnswers) {
        validateDataForSave(currentAnswers)
      }
    }
  }, [noErrors2]);

  useEffect(() => {
    setNoErrors(Object.keys(errors).length === 0)
  }, [errors])

  useEffect(() => {
    if (noErrors !== null) {
      if (currentAnswers) {
        validateDataForSave(currentAnswers)
      }
    }
  }, [noErrors]);

  useEffect(() => {
    user?.id !== undefined ? setCurrentUser(user?.id) : setCurrentUser('')
  }, [user])

  useEffect(() => {
    if (isValid === true) {
      saveForms(currentAnswers)
      setReadValidator(readValidator + 1)
      setShowError(false)
    }
  }, [isValid])

  useEffect(() => { }, [currentAnswers])

  if (status === 'success' && isLoading === false && currentAnswers === null) {
    data.data.data.map((form: any) => {
      if (form.id === formIndex) {
        setCurrentAnswers(form)
      }
    })
  }

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2' />
      </>
    )
  }

  const conditionalQuestions = (cond: any, index: any) => {
    return (
      <>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '0.3rem',
                        marginLeft: '3rem',
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div
                  className='popup-from-style'
                  dangerouslySetInnerHTML={{ __html: cond.info }}
                />
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' && cond.dependent === true ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    maxLength={40}
                    placeholder='Escriba aquí'
                    className='input-styles-quetion'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s/]/g, '')
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)
                      if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }}
                  />
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {readValidator > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Numeric Input */}
        {cond.type === 'numericInput' ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      *Solo se admiten caracteres numericos
                    </p>
                  )}
                  <input
                    type="text"
                    title="name"
                    maxLength={28}
                    placeholder="Escriba aquí"
                    className="input-styles-quetion"
                    value={cond.answer} // Usar value en lugar de manipular directamente el valor
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue.replace(/[^0-9]/g, '')
                      cond.answer = cleanInput
                      if (/[^0-9]/.test(cleanInput)) {
                        // Mostrar la alerta
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Ocultar la alerta
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      setReadValidator(readValidator + 1)
                    }}
                  />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='text-format'>
                <div className='flex'>
                  <textarea
                    title='name'
                    maxLength={200}
                    className='text-styles'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s]/g, '')
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)
                      if (/[^A-Z0-9\s]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }}
                  />
                </div>
                <div
                  className='popup-from-style'
                  dangerouslySetInnerHTML={{ __html: cond.info }}
                />
                <span>Max 100 carácteres</span>
                {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                  <span
                    style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginBottom: '3rem',
                      marginLeft: '0.3rem',
                    }}
                  >
                    {cond.validateAnswer}
                  </span>
                ) : null}
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked === true) {
                          cond.multipleAnswer.push(e.target.value)
                          setReadValidator(readValidator + 1)
                        } else {
                          const indexToRemove = cond.multipleAnswer.indexOf(e.target.value)
                          if (indexToRemove !== -1) {
                            cond.multipleAnswer.splice(indexToRemove, 1)
                          }
                        }
                      }}
                    >
                      {choice}
                    </Checkbox>
                  ))}
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      format='YYYY-MM-DD'
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.answer = date.format('YYYY-MM-DD').toString()
                          setReadValidator(readValidator + 1)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className="flex flex-col gap-12">
            <div className="flex flex-col justify-between">
              <p className="question-styles">{cond.question}</p>
              <div className="flex">
                <div className="flex flex-col gap-4 ml-16">
                  <Space direction="vertical">
                    <p className="m-0">Desde:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                          handleStartDateChange(index, date)
                          handleDateFromMustBeCurrent(date, cond.fromMustBeCurrent, index)
                        } else {
                          handleStartDateChange(index, null)
                          handleDateFromMustBeCurrent(null, cond.fromMustBeCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <Space direction="vertical">
                    <p className="m-0">Hasta:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          handleEndDateChange(index, date)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleEndDateChange(index, null)
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <div
                    className="popup-from-style"
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {errors && errors[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors[index]}</p>}
                  {cond.validate === true && !cond.multipleAnswer && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex flex-col justify-between'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex flex-col justify-between'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex mt-8'>
            <p className='paragraph-styles' style={{ color: '#ababab' }}>
              {cond.question}
            </p>
            <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex flex-col justify-between'>
            <p className='description-styles' style={{ color: '#3a3a3a' }}>
              {cond.question}
            </p>
            <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <a className='paragraphStyles' href={cond.link}>
              {cond.question}
            </a>
            <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <CountrySelect />
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(cond, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type city */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <CitySelect province={cond.conditional} />
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(cond, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' && cond.dependent === false ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                  />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }} />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    )
  }

  const conditionalQuestions2 = (cond: any, index: number): React.JSX.Element => {
    return (
      <>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    maxLength={40}
                    placeholder='Escriba aquí'
                    className='input-styles-quetion'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s/]/g, '')
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)
                      if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }} />
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {readValidator > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='text-format'>
                <div className='flex'>
                  <textarea
                    title='name'
                    maxLength={200}
                    className='text-styles'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s]/g, '')
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)
                      if (/[^A-Z0-9\s]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }} />
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                </div>
                <span>Max 100 carácteres</span>
                {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                  <span
                    style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginBottom: '3rem',
                      marginLeft: '0.3rem',
                    }}>
                    {cond.validateAnswer}
                  </span>
                ) : null}
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Numeric Input */}
        {cond.type === 'numericInput' ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      *Solo se admiten caracteres numericos
                    </p>
                  )}
                  <input
                    type="text"
                    title="name"
                    maxLength={28}
                    placeholder="Escriba aquí"
                    className="input-styles-quetion"
                    value={cond.answer} // Usar value en lugar de manipular directamente el valor
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue.replace(/[^0-9]/g, '')
                      cond.answer = cleanInput
                      if (/[^0-9]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      setReadValidator(readValidator + 1)
                    }} />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div
                  className='popup-form-style'
                  dangerouslySetInnerHTML={{ __html: cond.info }}
                />
              </div>
            </div>
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked === true) {
                          cond.multipleAnswer.push(e.target.value)
                          setReadValidator(readValidator + 1)
                        } else {
                          const indexToRemove = cond.multipleAnswer.indexOf(e.target.value)
                          if (indexToRemove !== -1) {
                            cond.multipleAnswer.splice(indexToRemove, 1)
                          }
                        }
                      }}>
                      {choice}
                    </Checkbox>
                  ))}
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      format='YYYY-MM-DD'
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.answer = date.format('YYYY-MM-DD').toString()
                          setReadValidator(readValidator + 1)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          console.log('')
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className="flex flex-col gap-12">
            <div className="flex flex-col justify-between">
              <p className="question-styles">{cond.question}</p>
              <div className="flex">
                <div className="flex flex-col gap-4 ml-16">
                  <Space direction="vertical">
                    <p className="m-0">Desde:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                          handleStartDateChange(index, date)
                          handleDateFromMustBeCurrent(date, cond.fromMustBeCurrent, index)
                        } else {
                          handleStartDateChange(index, null)
                          handleDateFromMustBeCurrent(null, cond.fromMustBeCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <Space direction="vertical">
                    <p className="m-0">Hasta:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          handleEndDateChange(index, date)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleEndDateChange(index, null)
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <div
                    className="popup-from-style"
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {errors && errors[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors[index]}</p>}
                  {cond.validate === true && !cond.multipleAnswer && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex flex-col justify-between'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex flex-col justify-between'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex mt-8'>
            <p className='paragraph-styles' style={{ color: '#ababab' }}>
              {cond.question}
            </p>
            <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex flex-col justify-between'>
            <p className='description-styles' style={{ color: '#3a3a3a' }}>
              {cond.question}
            </p>
            <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <a className='paragraphStyles' href={cond.link}>
              {cond.question}
            </a>
            <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <CountrySelect />
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(cond, index)
                  : null
              )}
          </div>
        ) : null}

        {/* Type city */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <CitySelect province={cond.conditional} />
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, index)
                  : null
              )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex flex-col justify-between'>
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers.data.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(cond, index)
                  : null
              )}
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' && cond.dependent === false ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                  />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }} />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    )
  }

  const saveForms = async (answers: any) => {
    let dataFormData: object
    if (answers.id != null) {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.data,
      }
    } else {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.data,
      }
    }
    console.log('saved')
    setOpenForm(false)
  }

  const validateDataForSave = (answers: any) => {
    let allQuestionsValid = true
    answers.data.questions.forEach((answer: any) => {
      if (noErrors === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (noErrors2 === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'input' ||
          answer.type === 'date' ||
          answer.type === 'text' ||
          answer.type === 'option' ||
          answer.type === 'country') &&
        answer.answer === ''
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'choice' || answer.type === 'interval') &&
        answer.multipleAnswer.length === 0
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
    })
    if (allQuestionsValid) {
      setIsValid(true)
    }
  }

  return (
    <>
      {currentAnswers && currentAnswers !== undefined ? (
        <form className='form-style'
          style={{ marginTop: '3rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {currentAnswers.data &&
            currentAnswers.data.questions.map((question: any, index: number) => (
              <div style={{ position: 'absolute', top: '0px', right: '24px' }} className='w-full flex justify-end help-button'>
                {question.type === 'help' ? (
                  <a
                    className='product-name btn-section mt-4'
                    style={{ width: 'max-content' }}
                    href={question.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={Lifering} alt="Lifering" />
                    <p style={{
                      margin: '0',
                      fontSize: '14px',
                      textDecoration: 'none',
                      color: 'black'
                    }}>{question.question}</p>
                  </a>
                ) : null}
              </div>
            ))}
          <h1 className='form-title' style={{ marginTop: '4rem' }}>
            {currentAnswers.data?.formName}
          </h1>
          {currentAnswers.data.questions.map((question: any, index: number) => (
            <div >
              {/* Type Input */}
              {question.type === 'input' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex'>
                      <div>
                        <input
                          type='text'
                          title='name'
                          maxLength={40}
                          placeholder='Escriba aquí'
                          className='input-styles-quetion'
                          value={question.answer} // Usar value en lugar de manipular directamente el valor
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value
                            const cleanInput = inputValue
                              .normalize('NFD') // Normalize the string
                              .replace(/[\u0300-\u036f]/g, '') // Remove accents
                              .toUpperCase() // Convert to uppercase
                              .replace(/[^A-Z0-9\s/]/g, '')
                            question.answer = cleanInput
                            if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                              setAlert(true)
                              setAlertIndex(index)
                            } else {
                              setAlert(false)
                              setAlertIndex(0)
                            }
                            setReadValidator(readValidator + 1)
                          }} />
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}>
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.data.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, index)
                          : null
                      )}
                  </div>
                </div>
              ) : null}

              {/* Type Numeric Input */}
              {question.type === 'numericInput' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        {alert === true && alertIndex === index && (
                          <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                            *Solo se admiten caracteres numericos
                          </p>
                        )}
                        <input
                          type="text"
                          title="name"
                          maxLength={28}
                          placeholder="Escriba aquí"
                          className="input-styles-quetion"
                          value={question.answer} // Usar value en lugar de manipular directamente el valor
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value
                            const cleanInput = inputValue.replace(/[^0-9]/g, '')
                            question.answer = cleanInput
                            if (/[^0-9]/.test(cleanInput)) {
                              setAlert(true)
                              setAlertIndex(index)
                            } else {
                              setAlert(false)
                              setAlertIndex(0)
                            }
                            setReadValidator(readValidator + 1)
                          }}
                        />
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                              fontSize: '10px'
                            }}>
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Type Text */}
              {question.type === 'text' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex'>
                      <div className='text-format'>
                        <textarea
                          title='name'
                          maxLength={200}
                          className='text-styles'
                          onChange={(e) => {
                            const inputValue = e.target.value
                            const cleanInput = inputValue
                              .normalize('NFD') // Normalize the string
                              .replace(/[\u0300-\u036f]/g, '') // Remove accents
                              .toUpperCase() // Convert to uppercase
                              .replace(/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/g, '')
                            e.target.value = cleanInput
                            question.answer = cleanInput
                            setReadValidator(readValidator + 1)
                            if (/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/.test(cleanInput)) {
                              setAlert(true)
                              setAlertIndex(index)
                            } else {
                              setAlert(false)
                              setAlertIndex(0)
                            }
                          }} />
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        <span>Max 100 carácteres</span>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.data.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, index)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type Option */}
              {question.type === 'option' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex '>
                      <div>
                        <select
                          title='option'
                          className='option-styles '
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                          }}>
                          <option value=''>Seleccione</option>
                          {question.option.map((option: string) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.data.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, index)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type choice */}
              {question.type === 'choice' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex'>
                      <div className='choice-option'>
                        {question.choices.map((choice: string) => (
                          <Checkbox
                            value={choice}
                            onChange={(e: CheckboxChangeEvent) => {
                              if (e.target.checked === true) {
                                currentAnswers.data.questions[index].multipleAnswer.push(
                                  e.target.value
                                )
                              } else {
                                const indexToRemove = currentAnswers.data.questions[
                                  index
                                ].multipleAnswer.indexOf(e.target.value)
                                if (indexToRemove !== -1) {
                                  currentAnswers.data.questions[index].multipleAnswer.splice(
                                    indexToRemove,
                                    1
                                  )
                                }
                              }
                            }}
                          >
                            {choice}
                          </Checkbox>
                        ))}
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.data.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, index)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type Date */}
              {question.type === 'date' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex'>
                      <div>
                        <Space direction='vertical'>
                          <DatePicker
                            format='YYYY-MM-DD'
                            onChange={(date) => {
                              if (date && date !== null) {
                                currentAnswers.data.questions[index].answer = date
                                  .format('YYYY-MM-DD')
                                  .toString()
                                handleDateMustBeCurrent(date, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                              } else {
                                handleDateMustBeCurrent(null, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(null, question.mustBeNotCurrent, index)
                              }
                            }}
                          />
                        </Space>
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.data.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, index)
                          : null
                      )}
                  </div>
                </div>
              ) : null}

              {/* Type Interval */}
              {question.type === 'interval' && !question.dependent ? (
                <div>
                  <div className="flex flex-col">
                    <div className="flex gap-1">
                      {question.validate ? (
                        <p className="info-styles" style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                          *
                        </p>
                      ) : (
                        <div className="info-styles" />
                      )}
                      <p className="question-styles">{question.question}</p>
                    </div>
                    <div className="flex gap-2">
                      <div className="flex flex-col gap-2">
                        <Space direction="vertical">
                          <p className="m-0">Desde:</p>
                          <DatePicker
                            format="YYYY-MM-DD"
                            onChange={(date) => {
                              if (date && date !== null) {
                                question.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                                handleStartDateChange(index, date)
                                handleDateFromMustBeCurrent(date, question.fromMustBeCurrent, index)
                              } else {
                                handleStartDateChange(index, null)
                                handleDateFromMustBeCurrent(null, question.fromMustBeCurrent, index)
                              }
                            }}
                          />
                        </Space>
                        <Space direction="vertical">
                          <p className="m-0">Hasta:</p>
                          <DatePicker
                            format="YYYY-MM-DD"
                            onChange={(date) => {
                              if (date && date !== null) {
                                question.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                                handleEndDateChange(index, date)
                                handleDateMustBeCurrent(date, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                              } else {
                                handleEndDateChange(index, null)
                                handleDateMustBeCurrent(null, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(null, question.mustBeNotCurrent, index)
                              }
                            }}
                          />
                        </Space>
                        <div
                          className="popup-form-style"
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {errors2 && errors2[index] &&
                          <p style={{
                            color: '#bf3c3c',
                            fontWeight: 'bolder',
                            marginLeft: '0.3rem',
                            marginBottom: '0'
                          }}>{errors2[index]}</p>}
                        {errors && errors[index] &&
                          <p style={{
                            color: '#bf3c3c',
                            fontWeight: 'bolder',
                            marginLeft: '0.3rem',
                            marginBottom: '0'
                          }}>{errors[index]}</p>}
                        {question.validate && !question.multipleAnswer && readValidator > 0 && (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                              fontSize: '10px'
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Type Video */}
              {question.type === 'video' && question.dependent === false ? (
                <div className='flex  flex-col justify-between'>
                  <p className='question-styles'>{question.question}</p>
                  <iframe className='iframe-style' src={question.video} title={question.question} />
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: question.info }}
                  />
                </div>
              ) : null}

              {/* Type Image */}
              {question.type === 'image' && question.dependent === false ? (
                <div className='flex flex-col justify-between'>
                  <p className='question-styles'>{question.question}</p>
                  <img className='iframe-style' src={question.image} alt={question.question} />
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: question.info }}
                  />
                </div>
              ) : null}

              {/* Type Paragraph */}
              {question.type === 'paragraph' && question.dependent === false ? (
                <div className='flex flex-col justify-between'>
                  <p className='paragraph-styles' style={{ color: '#ababab' }}>
                    {question.question}
                  </p>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: question.info }}
                  />
                </div>
              ) : null}

              {/* Type description */}
              {question.type === 'description' && question.dependent === false ? (
                <div className='flex flex-col justify-between'>
                  <p className='description-styles' style={{ color: '#3a3a3a' }}>
                    {question.question}
                  </p>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: question.info }}
                  />
                </div>
              ) : null}

              {/* Type Link */}
              {question.type === 'link' && question.dependent === false ? (
                <div className='flex flex-col justify-between'>
                  <a className='paragraphStyles' href={question.link}>
                    {question.question}
                  </a>
                  <div
                    className='popup-from-style'
                    dangerouslySetInnerHTML={{ __html: question.info }}
                  />
                </div>
              ) : null}

              {/* Type country */}
              {question.type === 'country' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex '>
                      <div>
                        <select
                          title='option'
                          className='option-styles '
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}
                        >
                          <CountrySelect />
                        </select>
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.data.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, index)
                        : null
                    )}
                </div>
              ) : null}

              {/*Type province */}
              {question.type === 'province' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div>
                      <select
                        title='option'
                        className='option-styles '
                        onChange={(e: any) => {
                          question.answer = e.target.value
                          shouldShowConditional === true
                            ? setShouldShowConditional(false)
                            : setShouldShowConditional(true)
                          setSpawnQuestion(spawnQuestion + 1)
                        }}>
                        <option value=''>Seleccione</option>
                        <option value='AB'>Alberta</option>
                        <option value='BC'>British Columbia</option>
                        <option value='MB'>Manitoba</option>
                        <option value='NB'>New Brunswick</option>
                        <option value='NL'>Newfoundland and Labrador</option>
                        <option value='NS'>Nova Scotia</option>
                        <option value='NU'>Nunavut</option>
                        <option value='ON'>Ontario</option>
                        <option value='QC'>Quebec</option>
                        <option value='SK'>Saskatchewan</option>
                        <option value='YT'>Yukon</option>
                        <option value='PE'>Prince Edward Island</option>
                        <option value='NT'>Northwest Territories</option>
                      </select>
                      {question.validate === true && question.answer === '' && readValidator > 0 ? (
                        <span
                          style={{
                            color: '#bf3c3c',
                            fontWeight: 'bolder',
                            marginBottom: '3rem',
                            marginLeft: '0.3rem',
                          }}
                        >
                          {question.validateAnswer}
                        </span>
                      ) : null}
                    </div>
                    <div
                      className='popup-from-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.data.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, index)
                        : null
                    )}
                </div>
              ) : null}

               {/* Type city */}
               {question.type === 'city' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex '>
                      <div>
                        <select
                          title='option'
                          className='option-styles '
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}
                        >
                          <CitySelect province={question.conditional} />
                        </select>
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.data.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, index)
                        : null
                    )}
                </div>
              ) : null}

              {/*Type language */}
              {question.type === 'language' && question.dependent === false ? (
                <div className='flex flex-col gap-12'>
                  <div className='flex flex-col justify-between'>
                    <p className='question-styles'>{question.question}</p>
                    <div className='flex'>
                      <div>
                        <select
                          title='option'
                          className='option-styles '
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}>
                          <option value=''>Seleccione</option>
                          <option value='English'>English</option>
                          <option value='Mandarin'>Mandarin Chinese</option>
                          <option value='Hindi'>Hindi</option>
                          <option value='Spanish'>Spanish</option>
                          <option value='French'>French</option>
                          <option value='Arabic'>Standard Arabic</option>
                          <option value='Bengali'>Bengali</option>
                          <option value='Russian'>Russian</option>
                          <option value='Portuguese'>Portuguese</option>
                          <option value='Urdu'>Urdu</option>
                          <option value='Indonesian'>Indonesian</option>
                          <option value='German'>German</option>
                          <option value='Japanese'>Japanese</option>
                          <option value='Nigerian'>Nigerian Pidgin</option>
                          <option value='Marathi'>Marathi</option>
                          <option value='Telugu'>Telugu</option>
                          <option value='Turkish'>Turkish</option>
                          <option value='Tamil'>Tamil</option>
                          <option value='Yue'>Yue Chinese</option>
                          <option value='Vietnamese'>Vietnamese</option>
                          <option value='Tagalog'>Tagalog</option>
                          <option value='Wu'>Wu Chinese</option>
                          <option value='Korean'>Korean</option>
                          <option value='Iranian'>Iranian Persian (Farsi)</option>
                          <option value='Hausa'>Hausa</option>
                          <option value='Egyptian'>Egyptian Spoken Arabic</option>
                          <option value='Swahili'>Swahili</option>
                          <option value='Javanese'>Javanese</option>
                          <option value='Italian'>Italian</option>
                          <option value='Punjabi'>Western Punjabi</option>
                          <option value='Kannada'>Kannada</option>
                          <option value='Gujarati'>Gujarati</option>
                          <option value='Thai'>Thai</option>
                        </select>
                      </div>
                      <div
                        className='popup-from-style'
                        dangerouslySetInnerHTML={{ __html: question.info }}
                      />
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.data.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, index)
                          : null
                      )}
                  </div>
                </div>
              ) : null}

              {/* Type postalcode */}
              {question.type === 'postalcode' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <PostalCodeSearch
                          onPostalCodeSelect={(selectedPostalCode: string) => {
                            question.answer = selectedPostalCode;
                          }}
                        />
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: question.info }} />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                              fontSize: '10px'
                            }}>
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
          <button
            style={{ marginTop: '4rem' }}
            type='button'
            className='init-path-btn save-btn'
            onClick={() => {
              validateDataForSave(currentAnswers)
            }}
          >
            Guardar
          </button>
          {readValidator > 0 && showError === true ? <p>hola</p> : null}
        </form>
      ) : (
        <p>no data</p>
      )}
    </>
  )
}

export { DisplayFormsTests }
