/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button} from '../../components/Button'

interface IProps {
  isOpen: boolean
  closeModal: () => void
  children: React.ReactNode
  title: string
  description?: string
  width?: string
  height?: string
  closeButton?: boolean | true
  closeButtonLabel?: string
}

const DialogComponent: React.FC<IProps> = ({
  isOpen,
  children,
  closeModal,
  title,
  description,
  width,
  height,
  closeButtonLabel = 'Cerrar',
  closeButton = true,
}) => {
  return (
    <>
      {isOpen && (
        <>
          <div style={{zIndex:'99999'}} className='w-100 h-100 flex   justify-center items-center fixed inset-0  outline-none focus:outline-none bg-gray-600 bg-opacity-90 '>
            <div  className={`relative  my-6 mx-auto   ${width} ${height} `}>
              <div className='border-0 rounded-lg shadow-xl relative   bg-white outline-none focus:outline-none pdf-viewer-responsive'>
                <div className='flex  items-start justify-between pl-6 pt-2 pr-8 border-b border-solid border-gray-300 rounded-t   '>
                  <h3 className='text-2xl font=semibold'>{title}</h3>
                  {closeButton && (
                    <button
                      className='bg-transparent border-0 text-black float-right'
                      onClick={() => {
                        closeModal()
                      }}
                    >
                     
                    </button>
                  )}
                </div>
                <div style={{height:'36rem'}} className='relative p-6 flex-auto overflow-y-auto '>{children}</div>
                {closeButton && (
                  <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                    <Button
                      type='button'
                      onClick={() => {
                        closeModal()
                      }}
                    >
                      {closeButtonLabel}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DialogComponent
