/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {RootStoreContext} from '../../../stores/rootStore'
import clsx from 'clsx'
import {useFormik} from 'formik'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const user_dev_test = process.env.REACT_APP_TEMP_DEV_USER_MAIL || ''
const user_dev_test_pass = process.env.REACT_APP_TEMP_DEV_MAIL_PASS || ''

const initialValues = {
  email: user_dev_test,
  password: user_dev_test_pass,
}

const MainLogin = () => {
  const [loading, setLoading] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {loginUser, onErrorLogin, isAuthenticated} = rootStore.authStore

  useEffect(() => {
    if (onErrorLogin != null) {
      setLoading(false)
      formik.setStatus('Error iniciando session')
      formik.setSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onErrorLogin])

  // const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      setStatus('')

      loginUser(values.email, values.password)
      window.location.href = '/'
    },
  })

  return (
    <>
      <div className='container-login'>
        <div className='bg-white container-l'>
          <header>
            <img
              src={'/media/newdesign/07-logo-horizontal-color.svg'}
              alt='immiland'
              className='immilandhorizontallogo'
            />
          </header>
          <div className='welcome'>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              <section className='set'>
                <h1>Welcome back</h1>
                <h4>Welcome back! Please enter your details</h4>
              </section>
              <section>
                <h3>Email address</h3>
                <input
                  className='input-style'
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className='alert'>
                      {formik.errors.email}
                    </span>
                  </div>
                )}
              </section>
              <section>
                <h3>Password</h3>
                <input
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'input-style',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='alert'>
                        {formik.errors.password}
                      </span>
                    </div>
                  </div>
                )}
              </section>
              <section>
                <button type='submit' className='sign-in'>
                  <span>
                    <p> Sign in</p>
                  </span>
                </button>
                <div className='dont-have-an-account'>
                  <Link
                    to='/auth/forgot-password'
                    className='forgot-password'
                    style={{marginLeft: '5px'}}
                  >
                    Forgot password?
                  </Link>
                </div>
              </section>
            </form>
          </div>
        </div>

        <div className='bg-gray container-r '>
          <div className='right-objects'>
            <section className='main-icon'>
              <img src={'/media/newdesign/logo_simbolo.svg'} className='' alt='' />
            </section>
            <section className='slogan'>
              <img src={'/media/newdesign/slogan.svg'} className='' alt='' />
            </section>
            <section className='all-logos'>
              <img src={'/media/newdesign/all-logos.svg'} className='' alt='' />
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainLogin
