/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type Messages as IMessages,
  type Messages,
} from '../../../../app/modules/apps/messages/core/_models'
import React, {useContext} from 'react'
import {Button} from '../../../../components'
import {type IReply} from '../../../../app/modules/apps/appointments/core/_models'
import MessageRows from './MessageRows'
import {RootStoreContext} from '../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {Space, Table} from 'antd'
import Column from 'antd/lib/table/Column'

interface IProps {
  setMessagesSeacrh: (messges: null) => void
  setMessageId?: React.Dispatch<React.SetStateAction<string>>
  setComposepostdata?: React.Dispatch<React.SetStateAction<IReply>>
  setlist: React.Dispatch<React.SetStateAction<boolean>>
  setShowMessage?: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage?: React.Dispatch<React.SetStateAction<boolean>>
  folder: string
  messages: Messages[]
  setClearSearch: (b: boolean) => void
  handleClose?: () => void
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
}

const ListMessagesSearch: React.FC<IProps> = ({
  setMessageId,
  setShowMessage,
  setReplyMessage,
  setComposepostdata,
  setlist,
  messages,
  setClearSearch,
  handleClose,
  setShowMessageRows,
}) => {
  const rootStore = useContext(RootStoreContext)
  const {setMessagesRead} = rootStore.messageStore
  const {user} = rootStore.authStore

  const HandleReadmessage = (id: string) => {
    setMessagesRead(id)
    if (setMessageId != null) setMessageId(id)
    if (setShowMessage != null) setShowMessage(true)
    setlist(false)
  }

  return (
    <>
      <div className='p-2'>
        <Button
          info
          className='mb-2'
          onClick={() => {
            setClearSearch(true)
          }}
        >
          Limpiar
        </Button>
      </div>
      <>
        <Table dataSource={messages} rowKey='id' style={{width: '100%'}}>
          <Column
            title=''
            render={(text, record: any, index: any) => (
              <Space style={{width: '100%'}}>
                <MessageRows
                  key={index}
                  message={record}
                  folder={'search'}
                  HandleReadmessage={HandleReadmessage}
                  handleClose={handleClose}
                  setShowMessageRows={setShowMessageRows}
                />
              </Space>
            )}
          />
        </Table>
      </>
    </>
  )
}
export default observer(ListMessagesSearch)
