import {Route, Routes} from 'react-router-dom'
import Calendly from '../Views/Calendly'
import EmbedTest from '../Views/Embed'
import Thanks from '../Views/Thanks'
import WebLayout from '../Layout/WebLayout'

const WebRoutes = () => {
  return (
    <Routes>
      <Route element={<WebLayout />}>
        <Route path='/thanks' element={<Thanks />} />
        <Route path='/embed' element={<EmbedTest />} />
        <Route path='/calendly' element={<Calendly />} />
      </Route>
    </Routes>
  )
}

export default WebRoutes
