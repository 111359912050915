 
 
import React, {useEffect} from 'react'
import {useQuery} from 'react-query'
import {AutoComplete} from 'antd'
import {type ImmigrationService} from '../../../../app/modules/apps/immigration/services/core/_models'
import {getImmigrationServices} from '../../../../app/modules/apps/immigration/services/core/_requests'

interface IProps {
  getImmigrationService: (immigrationservice: ImmigrationService) => void
  handleChangeSelect: (target: string, data: any) => void
  defaultSelect: ImmigrationService | null | undefined
}

interface IOptions {
  id: string
  value: string
  label: string
}

const ImmigrationServicesSelect: React.FC<IProps> = ({
  getImmigrationService,
  handleChangeSelect,
  defaultSelect,
}) => {
  const [options, setOptions] = React.useState<IOptions[]>([])
  const {data, status} = useQuery(
    'ListAllImmigrationServicesSelect',
    async () => await getImmigrationServices({})
  )

  useEffect(() => {
    if (status === 'success' && data) {
      setOptions(
        data.map((immigrationservice) => ({
          id: immigrationservice.id || '',
          value: immigrationservice.name || '',
          label: immigrationservice.name || '',
        }))
      )
    }
  }, [data, status])

  if (status === 'loading') {
    return <div>Loading...</div>
  }

  if (status === 'error') {
    return <div>Error</div>
  }

  const setImmigrationService = (immigrationserviceSelect: IOptions) => {
    data?.map((immigrationservice) => {
      if (immigrationservice.id === immigrationserviceSelect.id) {
        getImmigrationService(immigrationservice)
        handleChangeSelect('immigrationService', immigrationservice)
      }
    })
  }

  return (
    <div>
      {data != null && (
        <AutoComplete
          style={{
            width: '100%',
          }}
          options={options}
          placeholder='Seleccione un servicio de inmigración'
          filterOption={(inputValue, option: any) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={(value: string, option: IOptions) => {
            setImmigrationService(option)
          }}
          defaultValue={defaultSelect?.name}
        />
      )}
    </div>
  )
}

export default ImmigrationServicesSelect
