
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Input } from '../../../../components'
import {
  type ICartProducts,
  type Product,
} from '../../../../app/modules/apps/cart/core/products/_models'
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import FormRenders from './FormRenders'
import { RootStoreContext } from '../../../../stores/rootStore'
import UploaderTemp from './UploaderTemp'
import { observer } from 'mobx-react'
import parse from 'html-react-parser'
import '../../../pages/sass-arquitecture/main.scss';
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { number } from 'yup'
import { SetState } from 'immer/dist/internal'
import PageCounter from './UploaderTemp/PageCounter'
import { toJS } from 'mobx'


interface IProps {
  product: ICartProducts
  addTocart: (product: Product, quantity: number) => void
  removeFromCart: (product: Product) => void

}

const ShoppingProductsDetail: React.FC<IProps> = ({ product, addTocart, removeFromCart }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  const rootStore = useContext(RootStoreContext)
  const { setError, numberPages, cart, filesPaypal, setNumberPages ,files} = rootStore.cartStore
  const { name, description, price, image, comment, upload } = product.product
  const intl = useIntl()
  const fileType = filesPaypal ? filesPaypal[0].type : []
  const [isLoaded, setIsLoaded] = useState(false)
  const [numberImagePages,    setNumberImagePages] = useState(1);
  const quantity = product.quantity


  const getTotalPrice = (pages: number) => {
    const finalPrice = price * pages
    return finalPrice
  }


  useEffect(() => {
    handleAddToCart(numberPages)
    console.log(numberPages)

    // comentario para desactivar el linter
  }, [isLoaded, numberPages])

  const handleAddToCart = (value: number) => {
    if (value) addTocart(product.product, value - quantity)
  }
  const formatDescription = (text: string) => {
    // Eliminar etiquetas HTML
    const textWithoutHTML = text.replace(/<[^>]+>/g, '');
    // Dividir el texto en párrafos
    const paragraphs = textWithoutHTML.split('\n').filter(para => para.trim() !== '');
    // Retornar un array de párrafos
    return paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };


  const validateQuantityUpload = (totalPages: number) => {
    // comentario para desactivar el linter
  }
  const handlePageCounter = (pageCounter: number) => {
    setNumberPages(pageCounter)
    validateQuantityUpload != null && validateQuantityUpload(pageCounter)
  }
  const handlePages = () => {
    if (fileType != 'application/pdf') {
      return <><button
        className='-mt-3 text-xl flex border border-gray-100 rounded-sm hover:border-yellow-400 focus:outline-none px-2 py-1'
        onClick={() => {
          handleAddToCart(quantity - 1)
        }}
      >
        -
      </button><Input
          type='number'
          value={quantity}
          min='1'
          style={{
            textAlign: 'center',
            fontSize: '1rem',
            alignItems: 'center',
            width: '4rem',
            height: '2.5rem',
            margin: '0 0.8rem 0 0.8rem',
          }}
          onChange={(e: any) => {
            handleAddToCart(parseInt(e.target.value))
          }} /><button
            className='-mt-3 text-xl flex border border-gray-100 rounded-sm hover:border-yellow-400 focus:outline-none px-2 py-1'
            onClick={() => {
              handleAddToCart(quantity + 1)
            }}
          >
          +
        </button></>
    }
    return
  }

  return (
    <>
      <div className='container-translation-main' style={{width:'80%', marginLeft:'10rem'}}>
      <div className='circle-container'>
          <div className='circle'>1</div>
          <p className='hidden sm:block' style={{ color: 'black', marginBottom: '1px', marginRight: '6rem', textTransform: 'uppercase' ,fontFamily:'Lato' }}>Subir archivo</p>


          <div className='circle black-bg'>2</div>
          <p className='hidden sm:block' style={{ color: 'black', marginBottom: '1px', marginRight: '10px', textTransform: 'uppercase' ,fontFamily:'Lato'  }}>Confirmar solicitud</p>



        </div>

        <div className='container-line'>

          <div><div className='bubble' /><div className='bar' /></div>
          <div><div className='bubble2' /><div className='bar2' /></div><div className='bubble2' />

        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontFamily: 'Lato', fontSize: '24px', marginBottom: '40px' }}>
        <img
            className='w-20
            hover:border-yellow-400 p-3'
            src={image}
            alt=''
          />
           <div className='font-bold -mt-3'>{name}</div>
           <div className='font-bold -mt-3'>
  {/*     {formatDescription(description)} */}
    </div>
        </div>
        <div className='container-of-container-text'><div className='container-text'> <b style={{ fontSize: '24px' }}>Subir archivo</b>
          <p style={{ fontSize: '18px' }}>Suba los archivos que desea traducir para calcular el valor total. </p></div></div>    
          {comment &&
          <div className="rejected-doc-yellow mt-2 mb-2">
                        <div className="flex flex-row items-center gap-4 items-center">
                         
                              <img src="/media/svg/menu-icons/warning.svg" alt="WarningIcon" />
                            
                          <div className="flex flex-col">
                           
                            <p style={{ color: '#9D5425', fontSize: '14px',margin:'0px' }}>
                            {comment}

                            </p>
                          </div>
                        </div>
                        </div>
                        }
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}><b style={{ fontSize: '18px', marginBottom: '20px' }}>Consideraciones para optimizar tu traducción.</b></div>      
          <div className='container-image'>
            <div className='containmage'> <img
              src="/media/svg/menu-icons/eye-alt.svg"
              alt="Logo de Immigracion"
              style={{ paddingRight: '10px' }}
            />  <p style={{ fontSize: '14px', textAlign: 'left' }}>Asegúrese de que esté <b>legible y sin daños.</b></p></div> 
            <div className='line'>
              </div>
              <div className='containmage'> <img
              src="/media/svg/menu-icons/Pdf-1.svg"
              alt="Logo de Immigracion"
              style={{ paddingRight: '10px' }}
            />  <p style={{ fontSize: '14px', textAlign: 'left' }}>Su documento debe digitalizado y unido(.pdf, .png, o .jpg)  <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open('https://www.ilovepdf.com/merge_pdf', '_blank')}>Puede unir el pdf aquí.</span></p></div> 
             <div className='line'>     
              </div>
              <div className='containmage'> <img
              src="/media/svg/menu-icons/imagepngjpg.svg"
              alt="Logo de Immigracion"
              style={{ paddingRight: '10px' }}
            />  <p style={{ fontSize: '14px', textAlign: 'left' }}>
              Si tu archivo es una imagen selecciona manualmente la cantidad de paginas</p></div> 
              <div className='line'>
            
              </div>
              <div className='containmage'> <img
              src="/media/svg/menu-icons/clock-alt-1.svg"
              alt="Logo de Immigracion"
              style={{ paddingRight: '10px' }}
            />  <p style={{ fontSize: '14px', textAlign: 'left' }}>
              {name.includes('express') ?<>Sus traducciones disponibles en 3 a 5 días hábiles. </>: <>Sus traducciones disponibles en 3 a 7 días hábiles.</> }</p></div> 
              <div className='line'>
            
            </div>
            <div className='containmage'>
  <img
    src="/media/svg/menu-icons/lock-alt-3.svg"
    alt="Logo de Immigracion"
    style={{ paddingRight: '10px' }}
  />
  <p style={{ fontSize: '14px', textAlign: 'left' }}>
    Su PDF no debe estar encriptado <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open('https://www.ilovepdf.com/es/desbloquear_pdf', '_blank')}>Puede desbloquearlo aquí.</span>
  </p>
</div>

              </div>
      
    
          {upload && <UploaderTemp product={product} getPageCounter={validateQuantityUpload} addTocart={addTocart} setNumberImagePages={setNumberImagePages} numberImagePages={numberImagePages}  />}
        <div className='div-price' style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '22px', marginBottom: '40px' }}>
        <p style={{ color: '#13C296', fontSize: '18px', fontWeight: '700' }}>Total de traducción</p>
        <div className='container-price' style={{ backgroundColor: '#DAF8E6', width: '30rem', height: '6rem', display: 'flex', borderRadius: '10px', padding: '5px 5px 5px 5px' }}>
          <div style={{ display: 'flex' }}>
            <img
              src="/media/svg/menu-icons/files.svg"
              alt="Logo de Immigracion"
              style={{ paddingRight: '10px', paddingTop: '12px', width: '40px', height: '40px' }}
            />  <p style={{ color: '#13C296', fontWeight: '800', fontSize: '18px', display: 'flex', marginTop: '10px', gap: '10px' }}>
            <div className='divtest'>
              <p className='page-number' style={{color:'#13C296', fontSize:'15x', paddingLeft:'10px',  paddingRight:'10px'}}> { fileType === 'application/pdf' ? <PageCounter files={toJS(files)} getPageCounter={handlePageCounter}  /> : numberImagePages} Paginas</p>
            </div> 
          </p> <img
          className='arrow-price'
              src="/media/svg/menu-icons/arrow-right-green.svg"
              alt="Logo de Immigracion"
              style={{ paddingRight: '10px', paddingTop: '12px', marginLeft: '10px', width: '40px', height: '40px' }}
            /> </div>
          <div>  <p style={{color:'#13C296' , fontSize:'24px',fontWeight:'700', marginBottom:'0px', marginTop:'8px'}}>{fileType === 'application/pdf' ? 
            formatter.format(getTotalPrice(numberPages)) : 
            formatter.format(getTotalPrice(quantity))}</p>
            <p style={{ color: '#1A8245' }}>Valor por página: CAD {formatter.format(price)}</p></div></div></div>


      </div>
    </>
  )
}

export default observer(ShoppingProductsDetail)