
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import {
  getAnswerByEmail,
  getContractByUser,
} from '../../../../../app/modules/apps/contract/_requests';
import './style.css';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import CompletedUserFormslist from 'app/pages/admin/users/UserMenusoptions/ShowCompletedForms';
import { RootStoreContext } from 'stores/rootStore';
import DocumentRequirenmentList from './DocumentRequirenmentList';
import UsersIcon from '../../../../../app/pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Users.svg';
import UsersIcon2 from '../../../../../app/pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg';
import Plane from '../../../../../app/pages/admin/users/UserMenusoptions/NewIntakeView/Icons/air-mail.svg';
import Briefcase from '../../../../../app/pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-2.svg';
import House from '../../../../../app/pages/admin/users/UserMenusoptions/NewIntakeView/Icons/House.svg';
import Graduate from '../../../../../app/pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg';
import MyContractConsultor from 'app/pages/contratationApp/userContratationmenu/MyContractConsultor';
import { PdfView } from '../formsManagerClient.tsx/pdfView';
import IntakeList from 'app/pages/admin/users/UserMenusoptions/IntakeList';
import SetVisaDepartament from './VisaDepartmentSelection';
import { getForms } from 'app/modules/apps/forms/core/_requests';
import { getFormsCompletedData } from 'app/modules/apps/formsdata/core/_requests';
import LetterManager from './letterManager';
import UserBtn from '../../../../pages/dashboard/userDasboardComponents/userDashboardIcons/user-alt-4.svg'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface IProps {
  userEmail: any;
  contractsFound: string; /* RootObjectCompletedContracts */
  setActiveKey: Dispatch<SetStateAction<boolean>>;
  goToResumen: () => void;
}

interface ApplicantsVisibility {
  [key: number]: boolean;
}

const ShowAnswersConsultant = (props: IProps) => {
  const { setActiveKey, userEmail, contractsFound, goToResumen } = props; 
  const [pendantForms, setpendantForms] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('CONTRATO Y RESPUESTAS');
  const rootStore = useContext(RootStoreContext);
  const [selectedMessage, setSelectedMessage] = useState('Productos asociados');
  const [showMyContract, setShowMyContract] = useState(true);
  const [department, setDepartment] = useState('');
  const [principalProd, setPrincipalProd] = useState<string[]>([]);
  const [countDict, setCountDict] = useState<any>(null);
  const [applicantsVisibility, setApplicantsVisibility] = useState<ApplicantsVisibility>({});
  const { selectUser, currentUser } = rootStore.usersStore;
  const location = useLocation();
  const isNotifyRoute = location.pathname.includes('/user/notify');
  const { data: dataResult } = useQuery('contractsByEmail', async () => await getAnswerByEmail(userEmail));
  const navigate = useNavigate();

  const { data: dataFromDatabase, status: statusFromDatabase } = useQuery('allcontrDb', async () =>
    getContractByUser(currentUser?.id ? currentUser.id : '')
  );

  const { data, status, refetch: refetchForms } = useQuery('listforms', async () => await getForms('limit=50&page=1&name='));

  const {
    data: dataFormsCompleted,
    status: statusFromCompleted,
    refetch,
    isRefetching,
    isFetching: ifCompleted,
    isLoading: ilCompleted,
  } = useQuery('listformsclients', async () => await getFormsCompletedData(currentUser?.id ? currentUser.id : ''));

  const handleButtonClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  /* otros */

  const othersProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713', 'f9fe4a37-7284-4bf7-a22e-f0f12678bb99', '662a5e18-340f-41e6-830a-10576ead3115'
  ]

  const othersProductsMap: { [key: string]: string } = {
    '8dcb5115-bdce-45e3-8b50-d2d592020713': 'Bio',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99': 'F-Bio',
    '662a5e18-340f-41e6-830a-10576ead3115': 'SL',
  }

  /* VISITA */

  const visitProducts = [
    'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const visitProductsMap: { [key: string]: string } = {
    'a10980f2-57f3-43c7-b863-a995915389de': 'VVD',
    '3f056a93-1f4b-4598-9aec-f929ecd00e97': 'eTA',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a': 'Super visa',
    'daa261eb-6263-4b6e-acbb-4343bd4ce35a': 'transit',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9': 'VR',
    'b0e0313b-6344-4733-90a0-6d6031f8be1e': 'VV',
    '41669177-9782-4052-bb8d-08b6c29c928f': 'VV(Study less 6)',
    'b9ada976-a4fe-444d-9f6a-dd58c0928120': 'VR(Dependent)',
  }

  /* ESTUDIO */

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const studyProductsMap: { [key: string]: string } = {
    '94726637-76e7-438b-9494-99b136bbc7ea': 'SPd',
    'bb4056fb-2440-4a06-aec1-8d13349e7427': 'SPd(IC)',
    '8327593a-cc82-4703-afaa-72030f67c001': 'SP',
    'f9b3dcd2-b420-4cc0-b5df-575f9a5be911': 'SDS',
    '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800': 'SP(IC)',
    '32549570-adf0-4add-ad43-5c49fed6566d': 'SP(language)',
    '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c': 'SPd(IC language)'
  }

  /* Residence */

  const residenceProducts = [
    '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', '1ec2dc58-5776-49d3-b487-f373ffd1e0a3',
  ]

  const residenceProductsMap: { [key: string]: string } = {
    '6f317d28-cab2-4316-9a25-3f62e4e047e6': 'Citizenship',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d': 'CSQ',
    '3320f244-f338-43d0-8006-503003d16501': 'CSQ(dependent)',
    'b58daf73-88be-41ee-8a0d-9e485748ced8': 'CSQ(spouse/partner)',
    '7ac7912e-9514-4281-924b-1a36a4dd3065': 'Express Entry(dependent)',
    '9c1f03d1-5100-4a50-8030-cfb8ebad4480': 'Express Entry(AM)',
    '48b466b6-d8e1-48f8-ae32-2877760f92b7': 'Express Entry(EI phase 1)',
    'd1195212-1cdc-4792-b88b-824922f63c36': 'Express Entry(IA phase 2)',
    '7d8df96c-dd71-48aa-b0f7-301e59706212': 'PNE(main applicant phase 1)',
    '03030060-aa44-452a-ad54-80fedf29a547': 'PNP',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d': 'Express Entry(EI Spouse/Partner phase 1)',
    '5848c9c4-bfc9-49b0-881d-9cab020dd1e5': 'Express Entry(IA Spouse/Partner phase 2)',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3': 'TRV',
  }

  /* SECCION DE TRABAJO */

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', '6420335e-30aa-46c5-892b-e1fa23914733',
    '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac', '229d7b91-7a9b-407a-988b-9e45096bff5b',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994', 'c6df5f52-670d-4d7f-809e-72cb59fa684a',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3', 'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]

  const workProductsMap: { [key: string]: string } = {
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61': 'LMIA Exempt',
    '41b1a433-5900-4b96-85f3-7e2c8fce0a41': 'LMIA Exempt(PE)',
    '50eb5404-9ae2-4655-91ae-89d2e8a6fa73': 'LMIA Exempt(Flagpole)',
    '19ca7bf2-6514-478b-bb78-c7861619fe39': 'LMIA Exempt(IC)',
    'e377ed33-a53a-4312-8842-1a689237b07c': 'LMIA Exempt',
    '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b': 'LMIA exempt(PE)',
    'e8e477d1-f126-4a64-93da-539b3715730d': 'LMIA positive',
    '30a323ab-236c-461c-93ea-28308c8fafa6': 'LMIA positive(PE)',
    '08e1143b-4f7f-48db-a289-9a3fc40f8037': 'LMIA positive(flagpole)',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa': 'LMIA positive(IC)',
    'a645705f-f9f7-47c6-9319-5a1026da84d2': 'LMIA positive',
    '6e420420-d96c-4cb8-a145-95a7964ed618': 'LMIA positive(PE)',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095': 'CO-OP',
    '9015fb11-d513-4f6e-9de9-aab92d5d2b42': 'IEC',
    '4f050e6f-e9eb-4ee0-b12c-465d2d715c16': 'IEC',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c': 'LMIA exemption',
    '0dac7746-02c0-47db-be28-14755d62a64d': 'LMIA Federal',
    '6420335e-30aa-46c5-892b-e1fa23914733': 'LMIA Quebec',
    '52096218-da31-4e01-a406-0e06a9ca4d07': 'OWP Vulnerable Worker',
    'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac': 'OWP(MA)',
    '229d7b91-7a9b-407a-988b-9e45096bff5b': 'OWP(Special Mesure Opt-in)',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9': 'PGWP',
    'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994': 'PGWP(PE)',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a': 'PGWP',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3': 'Sponsorship',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4': 'Sponsorship',
    '1d2b991b-7d75-4127-99d8-19f884efcfd0': 'OWP Spouse/Partner',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f': 'OWP Spouse/Partner',
    'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f': 'OWP Spouse/Partner',
    'b6f813c5-0911-4b86-be17-0e74bc332316': 'Spouse/Partner RSWP(Phase 1)'
  }

  const countApplicants = () => {
    const countDict: Record<string, Set<string>> = {};
    dataFromDatabase?.data?.forEach((contract: any) => {
      if (contract.contracts_users_applicants) {
        const applicants = JSON.parse(contract.contracts_users_applicants);
        const contractId = contract.contracts_users_envelope_id;
        if (!countDict[contractId]) {
          countDict[contractId] = new Set<string>();
        }
        applicants.forEach((applicant: any) => {
          const { memberName, memberType } = applicant;
          const key = `${memberName}-${memberType}`;
          countDict[contractId].add(key);
        });
      }
    });
    console.log(currentContract, 'esto es current contract')
    const countResult: Record<string, number> = {};
    for (const [contractId, applicantsSet] of Object.entries(countDict)) {
      countResult[contractId] = applicantsSet.size;
    }
    return countResult;
  };

  const currentContract = dataFromDatabase?.data?.find(
    (contract: { contracts_users_envelope_id: string }) => contract.contracts_users_envelope_id === contractsFound
  );
  const contracts_users_step = currentContract?.contracts_users_step || 0;

  const [activeStage, setActiveStage] = useState(contracts_users_step);

  const handleButtonClick1 = (tabName: string, stageNumber: number) => {
    setActiveTab(tabName);
    setActiveStage(stageNumber);
  };

  const getStageClass = (stageNumber: number) => {
    if (stageNumber === activeStage) {
      return 'pending-stage';
    } else if (stageNumber <= contracts_users_step) {
      return 'complete-stage';
    } else {
      return 'white-stage';
    }
  };

  useEffect(() => {
    if (statusFromCompleted && dataFormsCompleted) {
      const formsForSpecificContract = dataFormsCompleted.filter((form: any) => form.contract === contractsFound);
      console.log('formsForSpecificContract', formsForSpecificContract);
    }

    if (statusFromDatabase === 'success') {
      setCountDict(countApplicants());
    }
    if (currentContract) {
      const contractsUsersApplicants = JSON.parse(currentContract.contracts_users_applicants);
      contractsUsersApplicants.forEach((applicant: any) => {
        if (applicant.memberType === 'Solicitante principal' && !principalProd.includes(applicant.productId)) {
          setPrincipalProd((prevState) => [...prevState, applicant.productId]);
        }
      });
    }
  }, [statusFromCompleted, dataFormsCompleted, statusFromDatabase, currentContract, principalProd]);

  if (!currentContract) {
    return <p>Contrato no encontrado</p>;
  }

  const toggleVisibility = (index: number) => {
    setApplicantsVisibility((prevVisibility) => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }));
  };

  return (
    <>
     <button
  style={{
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 24px 10px 24px',
    height: 'auto',
    width: 'auto',
    marginTop: '30px',
    marginBottom: '10px',
    marginLeft: '20px',
  }}
  onClick={() => {
    if (isNotifyRoute) {
      navigate(-1); // Redirige a la página anterior si estás en /user/notify
    } else {
      setActiveKey(false); // Mantiene el comportamiento actual si estás en /userslist
    }
  }}
  className="button-volver"
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
>
  <img
    alt="Calendar"
    className="meet-image2"
    src={isHovered ? '/media/icons/app/menu/arrow-left-black.svg' : '/media/icons/app/menu/arrow-left-black.svg'}
  />
  <b className="meet-text2">VOLVER A VISAS DEL CLIENTE</b>
</button>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            backgroundColor: '#DFE4EA',
            height: '1px',
            width: '97%',
            marginTop: '15px',
          }}
        />
      </div>
      <div
        style={{
          color: '#374151',
          display: 'flex',
          marginTop: '30px',
          marginLeft: '25px',
        }}
      >
        <div>
          {statusFromDatabase === 'success' && currentContract && (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                  width: '100%',
                  fontSize: '18px',
                  fontWeight: '700',
                }}
              >
                <strong>Contrato n° {contractsFound}</strong>
                <div
                  style={{
                    maxWidth: '40rem',
                    marginLeft: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="flex row flex-wrap gap-2 mt-2 mb-2"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                      marginRight: '15px',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    <p className="contract-text">Contratado el </p>
                    <p className="contract-text">
                      {currentContract.contracts_users_completedDate.substring(0, 10)}
                      <span style={{ margin: '0 12px' }}>| </span><strong><SetVisaDepartament productId={principalProd} setDepartment={setDepartment} /></strong>
                    </p>
                  </div>

                  {/* Botón para mostrar el número de aplicantes */}
                  {statusFromDatabase === 'success' &&
                    countDict !== null &&
                    countDict[currentContract.contracts_users_envelope_id] && (
                      <button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '3px 5px',
                          border: '1px solid #DFE4EA',
                          borderRadius: '3px',
                          gap: '5px',
                        }}
                      >
                        <img src={UsersIcon} alt="Users" />
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: 'bold',
                            margin: '0',
                          }}
                        >
                          {countDict[currentContract.contracts_users_envelope_id]} Persona(s)
                        </p>
                      </button>
                    )}

                  {currentContract.contracts_users_applicants &&
                    (() => {
                      const displayedProductIds = new Set();
                      return JSON.parse(currentContract.contracts_users_applicants)
                        .filter((applicant: any) => othersProducts.includes(applicant.productId))
                        .map((applicant: any, index: number) => {
                          if (displayedProductIds.has(applicant.productId)) {
                            return null; // No renderizar si el productId ya se ha mostrado
                          }
                          displayedProductIds.add(applicant.productId);
                          return (
                            <div
                              key={index} // Añade una key única
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#DFE4EA',
                                borderRadius: '3px',
                                gap: '5px',
                              }}>
                              <img src={UsersIcon2} alt="User" />
                              <p
                                style={{
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  color: 'black',
                                  margin: '0',
                                }}>
                                {othersProductsMap[applicant.productId]} {/* otros */}
                              </p>
                            </div>
                          );
                        });
                    })()}

                  {currentContract.contracts_users_applicants &&
                    (() => {
                      const displayedProductIds = new Set();
                      return JSON.parse(currentContract.contracts_users_applicants)
                        .filter((applicant: any) => workProducts.includes(applicant.productId))
                        .map((applicant: any, index: number) => {
                          if (displayedProductIds.has(applicant.productId)) {
                            return null; // No renderizar si el productId ya se ha mostrado
                          }
                          displayedProductIds.add(applicant.productId);
                          return (
                            <div
                              key={index} // Añade una key única
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#22AD5C',
                                borderRadius: '3px',
                                gap: '5px',
                              }}>
                              <img src={Briefcase} alt="Briefcase" />
                              <p
                                style={{
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  color: 'white',
                                  margin: '0',
                                }}>
                                {workProductsMap[applicant.productId]} {/* Work */}
                              </p>
                            </div>
                          );
                        });
                    })()}

                  {currentContract.contracts_users_applicants &&
                    (() => {
                      const displayedProductIds = new Set();
                      return JSON.parse(currentContract.contracts_users_applicants)
                        .filter((applicant: any) => studyProducts.includes(applicant.productId))
                        .map((applicant: any, index: number) => {
                          if (displayedProductIds.has(applicant.productId)) {
                            return null; // No renderizar si el productId ya se ha mostrado
                          }
                          displayedProductIds.add(applicant.productId);
                          return (
                            <div
                              key={index} // Añade una key única
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#EF5DA8',
                                borderRadius: '3px',
                                gap: '5px',
                              }}>
                              <img src={Graduate} alt="Briefcase" />
                              <p
                                style={{
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  color: 'white',
                                  margin: '0',
                                }}>
                                {studyProductsMap[applicant.productId]} {/* Work */}
                              </p>
                            </div>
                          );
                        });
                    })()}

                  {currentContract.contracts_users_applicants &&
                    (() => {
                      const displayedProductIds = new Set();
                      return JSON.parse(currentContract.contracts_users_applicants)
                        .filter((applicant: any) => residenceProducts.includes(applicant.productId))
                        .map((applicant: any, index: number) => {
                          if (displayedProductIds.has(applicant.productId)) {
                            return null; // No renderizar si el productId ya se ha mostrado
                          }
                          displayedProductIds.add(applicant.productId);
                          return (
                            <div
                              key={index} // Añade una key única
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#F27430',
                                borderRadius: '3px',
                                gap: '5px',
                              }}>
                              <img src={House} alt="Briefcase" />
                              <p
                                style={{
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  color: 'white',
                                  margin: '0',
                                }}>
                                {residenceProductsMap[applicant.productId]} {/* Work */}
                              </p>
                            </div>
                          );
                        });
                    })()}

                  {currentContract.contracts_users_applicants &&
                    (() => {
                      const displayedProductIds = new Set();
                      return JSON.parse(currentContract.contracts_users_applicants)
                        .filter((applicant: any) => visitProducts.includes(applicant.productId))
                        .map((applicant: any, index: number) => {
                          if (displayedProductIds.has(applicant.productId)) {
                            return null; // No renderizar si el productId ya se ha mostrado
                          }
                          displayedProductIds.add(applicant.productId);
                          return (
                            <div
                              key={index} // Añade una key única
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#01A9DB',
                                borderRadius: '3px',
                                gap: '5px',
                              }}>
                              <img src={Plane} alt="Briefcase" />
                              <p
                                style={{
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  color: 'white',
                                  margin: '0',
                                }}>
                                {visitProductsMap[applicant.productId]} {/* Work */}
                              </p>
                            </div>
                          );
                        });
                    })()}
    {!isNotifyRoute && (
  <button
    style={{ width: '4rem', padding: '5px', height: '2rem' }}
    className='button-ui button-secondary button-xs'
    onClick={goToResumen}
  >
    <div>
      <img src={UserBtn} alt="UserBtn" className="button-image" />
      {/* <span className="button-text">Ir</span> */}
    </div>
  </button>
)}

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            backgroundColor: '#DFE4EA',
            height: '1px',
            width: '97%',
            marginTop: '20px',
          }}
        />
      </div>

      {/* Renderiza los botones */}
      <div className="flex-container">
        <div className="vertical-nav-bar">
          <button className="nav-button" onClick={() => handleButtonClick1('CONTRATO Y RESPUESTAS', 1)}>
            <a style={{ width: '6rem', letterSpacing: '1px' }} className={getStageClass(1)}>Etapa 1</a>
            <strong>CONTRATO Y RESPUESTAS</strong>
          </button>
          <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }} />

          <button className="nav-button" onClick={() => handleButtonClick1('FORMULARIOS', 2)}>
            <a style={{ letterSpacing: '1px' }} className={getStageClass(2)}>Etapa 2</a>
            <div style={{ display: 'flex' }}>
              <strong style={{ marginLeft: '18px' }}>FORMULARIOS</strong>
            </div>
          </button>
          <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }} />

          <button className="nav-button" onClick={() => handleButtonClick1('DOCUMENTOS', 3)}>
            <a style={{ letterSpacing: '1px' }} className={getStageClass(3)}>Etapa 3</a>
            <div style={{ display: 'flex' }}>
              <strong style={{ marginLeft: '18px' }}>DOCUMENTOS</strong>
            </div>
          </button>
          <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }} />

          <button className="nav-button" onClick={() => handleButtonClick1('ASIGNACIÓN Y SEGUIMIENTO', 4)}>
            <a style={{ letterSpacing: '1px' }} className={getStageClass(5)}>Etapa 4</a>
            <div style={{ display: 'flex' }}>
              <strong style={{ marginLeft: '14px' }}>ASIGNACIÓN</strong>
            </div>
          </button>
          <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }} />

          <button className="nav-button" onClick={() => handleButtonClick1('CARTA', 5)}>
            <a style={{ letterSpacing: '1px' }} className={getStageClass(4)}>Etapa 5</a>
            <div style={{ display: 'flex' }}>
              <strong style={{ marginLeft: '10px' }}>CARTA</strong>
            </div>
          </button>
          <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }} />
        </div>
        {activeTab === 'CARTA' && (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
            <LetterManager email='' id='' contractsFound={contractsFound} currentUser={currentUser} />
          </div>
        )}
        {activeTab === 'FORMULARIOS' && (
          <div style={{ width: '100%' }}>
            <CompletedUserFormslist user={currentUser} contractsFound={contractsFound || ''} />
          </div>
        )}
        {activeTab === 'ASIGNACIÓN Y SEGUIMIENTO' && (
          <div style={{ width: '100%' }}>
            <IntakeList email={currentUser?.email || ''} id={currentUser?.id || ''} contractsFound={contractsFound || ''} />
          </div>
        )}
        {activeTab === 'CONTRATO Y RESPUESTAS' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
            }}
          >
            <a
              style={{
                fontSize: '30px',
                fontWeight: '700',
                color: '#4B5563',
              }}
            >
              Contrato y respuestas
            </a>
            <div className="side-buttons">
              <button
                className={`side-button ${selectedMessage === 'Productos asociados' ? 'active' : ''}`}
                onClick={() => {
                  setSelectedMessage('Productos asociados');
                  setShowMyContract(true);
                }}
              >
                Servicios asociados
              </button>
              <button className="side-button" onClick={() => setSelectedMessage('Contrato')}>
                Contrato
              </button>
              <button className="side-button" onClick={() => setSelectedMessage('Respuestas de contratación')}>
                Respuestas de contratación
              </button>
            </div>
            {/* Contenedor de mensaje */}
            {selectedMessage && (
              <div
                className="message-container"
                style={{
                  width: '100%',
                }}
              >
                <div
                  className="color-box"
                  style={{
                    backgroundColor: '#D0F0FD66',
                    width: '100%',
                  }}
                >
                  {selectedMessage === 'Productos asociados' && showMyContract && (
                    <>
                      {dataFromDatabase?.data?.map((prod: any) =>
                        prod.contracts_users_envelope_id === contractsFound ? <MyContractConsultor key={prod.contracts_users_envelope_id} prod={prod} /> : null
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {selectedMessage && (
              <div
               /*  className="message-container"
                style={{
                  width: '100%',
                }} */
                >
                <div
                 /*  className="color-box"
                  style={{
                    width: '100%',
                  }} */>
                  {selectedMessage === 'Contrato' && showMyContract && (
                    <>
                      <PdfView envelopeId={contractsFound || ''} />
                    </>
                  )}
                </div>
              </div>
            )}
            {selectedMessage && (
              <div
                className="message-container"
                style={{
                  width: '100%',
                }}>
                <div
                  className="color-box"
                  style={{
                    backgroundColor: '#D0F0FD66',
                    width: '100%',
                  }}>
                  {selectedMessage === 'Respuestas de contratación' && showMyContract && (
                    <>
                      {dataResult != null ? (
                        <div className="main-data-continer">
                          {dataResult
                            .filter((contract: any) => {
                              // Verificar si ambos contract y currentContract tienen match_id
                              if (contract.match_id !== undefined && currentContract.contracts_users_match_id !== undefined) {
                                return contract.match_id === currentContract.contracts_users_match_id;
                              } else {
                                // Si alguno de los contratos no tiene match_id, no aplicar filtro
                                return true;
                              }
                            })
                            .map((answers: any) => (
                              <div style={{ width: '100%' }} key={answers.id}>
                                <div className="main-data-continer">
                                  {answers?.props.map((data: any, index: number) => (
                                    <div
                                      className="aplicant-continer-phase2"
                                      style={{ marginBottom: '10px', width: '100%' }}
                                      key={index}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '10px',
                                          width: '100%',
                                          height: '3rem',
                                          justifyContent: 'space-between',
                                        }}
                                        onClick={() => toggleVisibility(index)}>
                                        <div
                                          style={{
                                            color: '#0B76B7',
                                            fontWeight: '700',
                                            fontSize: '14px',
                                          }}>
                                          {JSON.parse(data)['Tipo de familiar']}
                                        </div>
                                        <img
                                          style={{ width: '20px', height: '20px' }}
                                          src={
                                            applicantsVisibility[index]
                                              ? '/media/svg/menu-icons/chevron-up-circle.svg'
                                              : '/media/svg/menu-icons/chevron-down-circle.svg'
                                          }
                                          alt="icon" />
                                      </div>
                                      {applicantsVisibility[index] && (
                                        <div className="aplicant-data-container">
                                          <div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between',
                                              }}>
                                              {Object.keys(JSON.parse(data)).map((key) => (
                                                <div
                                                  style={{
                                                    width: '47%',
                                                    display: 'flex',
                                                    gap: '10px',
                                                  }}
                                                  key={key}>
                                                  <p style={{ color: '#0B76B7' }}>{key}:</p>
                                                  <p
                                                    style={{
                                                      fontWeight: 'bold',
                                                      color: 'black',
                                                    }}>
                                                    {typeof JSON.parse(data)[key as keyof typeof data] === 'boolean'
                                                      ? JSON.parse(data)[key as keyof typeof data] === true
                                                        ? 'si'
                                                        : 'no'
                                                      : JSON.parse(data)[key as keyof typeof data]}
                                                  </p>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <>no data</>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === 'DOCUMENTOS' && (
          <div style={{ width: '100%' }}>
            <DocumentRequirenmentList user={currentUser} contractsFound={contractsFound || ''} contract={currentContract} />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(ShowAnswersConsultant);
