import React from 'react'
import './consultorselector.css'

const EmbedCal: React.FC = () => {
  return (
    <div>
      <h1 style={{textAlign: 'center', fontSize: '2rem'}}>Asesoría Vocacional Con Descuento</h1>
      <iframe
        title='title'
        style={{minHeight: '50rem'}}
        src='https://calendly.com/immiland-canada/asesoria-vocacional-descuento?month=2023-07'
        width='100%'
        height='100%'
      ></iframe>
    </div>
  )
}

export default EmbedCal
