import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const TIMELINE = `${API_URL}/timeline`

const postTimeLineCenter = async (userid: string, centerdata: any): Promise<any> => {
  const response = await axios.post(`${TIMELINE}`, {user: userid, centerdata: centerdata})
  return response.data as any
}

const postTimeLineLeft = async (userid: string, leftdata: any): Promise<any> => {
  const response = await axios.post(`${TIMELINE}`, {user: userid, leftdata: leftdata})
  return response.data as any
}

const postTimeLineRight = async (userid: string, rightdata: any): Promise<any> => {
  const response = await axios.post(`${TIMELINE}`, {user: userid, rightdata: rightdata})
  return response.data as any
}

const getTimeLineByUserId = async (userid: string): Promise<any> => {
  const response = await axios.get(`${TIMELINE}/user/${userid}`)
  return response.data as any
}

const postTimeLineNew = async ({
  userid,
  timelineData,
}: {
  userid: string
  timelineData: any
}): Promise<any> => {
  const response = await axios.post(`${TIMELINE}`, {user: userid, timelineData: timelineData})
  return response.data as any
}

export {
  postTimeLineCenter,
  postTimeLineLeft,
  postTimeLineRight,
  getTimeLineByUserId,
  postTimeLineNew,
}
