import {type Templates, type TemplatesQueryResponse, toFormValues} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const TEMPLATES_URL = `${API_URL}/templates`

const getOneTemplates = async (query: string): Promise<TemplatesQueryResponse> => {
  return await axios
    .get(`${TEMPLATES_URL}?${query}`)
    .then((d: AxiosResponse<TemplatesQueryResponse>) => {
      return d.data
    })
}

const getTemplateById = async (id: string): Promise<Templates> => {
  return await axios.get(`${TEMPLATES_URL}/id/${id}`).then((d: AxiosResponse<Templates>) => {
    return d.data
  })
}

const getTemplates = (params: string | null): Promise<TemplatesQueryResponse> | null => {
  return axios
    .get(`${TEMPLATES_URL}?${params}`)
    .then((d: AxiosResponse<TemplatesQueryResponse>) => {
      return d.data
    })
}

const getTemplatesManager = (params: string | null): Promise<TemplatesQueryResponse> | null => {
  return axios
    .get(`${TEMPLATES_URL}/manager?${params}`)
    .then((d: AxiosResponse<TemplatesQueryResponse>) => {
      return d.data
    })
}

const updateTemplates = async (templates: Templates) => {
  return await axios.patch(`${TEMPLATES_URL}/${templates.id}`, templates).then((response) => {
    return toFormValues(response.data as Templates)
  })
}

const saveTemplates = async (templates: Templates): Promise<Templates | undefined> => {
  return await axios.post(`${TEMPLATES_URL}`, templates).then((response) => {
    return toFormValues(response.data as Templates)
  })
}

export {
  getOneTemplates,
  updateTemplates,
  getTemplates,
  saveTemplates,
  getTemplateById,
  getTemplatesManager,
}
