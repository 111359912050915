/* eslint-disable @typescript-eslint/no-unused-vars */


import {Alert} from '../../../../../../components'
import {RootStoreContext} from '../../../../../../stores/rootStore'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'

const {REACT_APP_PUBLIC_URL} = process.env

interface IProps {
  status: string
  errorMessage: string
  paymentCode: string
}

const PaymentStatus: React.FC<IProps> = ({status, errorMessage, paymentCode}) => {
  console.log("STATUS Andres",status)
  const rootStore = useContext(RootStoreContext)
  const {resetCart, isEmbed} = rootStore.cartStore
  const navigate = useNavigate()
  const { saveSecondCall } = rootStore.cartStore;
 

const handleSubmitSecondCall=async ()=>{
  const result = await saveSecondCall();
}


  switch (status) {
    case 'processing':
      return <h2>Processing...</h2>
    case 'requires_payment_method':
      return <h2>Requires payment method...</h2>
    case 'requires_confirmation':
      return <h2>Processing...</h2>
    case 'requires_action':
      return <h2>Authenticating...</h2>
    case 'succeeded':
     handleSubmitSecondCall()
    /*  const embed = isEmbed ? '/embed' : '' */
    setTimeout(() => {
      resetCart();
      navigate(`${REACT_APP_PUBLIC_URL}/embed/store/payment/sucesswo`);
    }, 3000);
  
      return <h2>Payment successful {paymentCode}</h2>
    case 'error':
      return (
        <>
          <Alert className='float-left'>{errorMessage}</Alert>
        </>
      )

    default:
      return null
  } 
/* 
  const handleSuccess = async () => {
    try {
      const result = await saveSecondCall();
      // If saveSecondCall succeeds, reset the cart and navigate
      
      const embed = isEmbed ? '/embed' : '';
      navigate(`${REACT_APP_PUBLIC_URL}${embed}/store/payment/success/${paymentCode}`);
      console.log("URL",`${REACT_APP_PUBLIC_URL}${embed}/store/payment/success/${paymentCode}`)
      resetCart();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  // Call handleSuccess directly when status is 'succeeded'
  if (status === 'succeeded') {
    handleSuccess();
    // No need to render anything here, as the navigation will handle the rendering
    return null;
  }

  // Render based on status
  switch (status) {
    case 'processing':
      return <h2>Processing...</h2>;
    case 'requires_payment_method':
      return <h2>Requires payment method...</h2>;
    case 'requires_confirmation':
      return <h2>Processing...</h2>;
    case 'requires_action':
      return <h2>Authenticating...</h2>;
    case 'error':
      return <Alert className='float-left'>{errorMessage}</Alert>;
    default:
      return null;
  } */
}

export default PaymentStatus
