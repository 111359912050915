import {type ID, type Response} from '../../../../../core/helpers'

export interface Templates {
  id?: ID
  name: string
  text?: string | null
  autoShare?: boolean | false
  createdAt?: Date | null
}

export interface DataTemplates {
  key: string
  templateName: string
}

export type TemplatesQueryResponse = Response<Templates[]>

type toFormValuesType = (apiData: Templates) => Templates

export const toDataTemplates = (apiData: Templates[]) =>
  apiData.map((template: Templates) => ({
    key: template.id,
    name: template.name,
    autoShare: template.autoShare,
    text: template.text,
  }))

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id || '',
  name: apiData.name || '',
  text: apiData.text || '',
  autoShare: apiData.autoShare || false,
  createdAt: apiData.createdAt != null ? new Date(apiData.createdAt) : null,
})
