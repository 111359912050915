/* eslint-disable react-hooks/exhaustive-deps */
 
import {Button, Input, Table, TableContainer} from '../../../../components'
import React, {useEffect} from 'react'
import {TableBody, TableCell, TableHead, TableRow} from '../../../../components/Tables'
import {EditOutlined} from '@ant-design/icons'
import {type ImmigrationElement} from '../../../../app/modules/apps/immigration/elements/core/_models'
import {Space} from 'antd'
import {getImmigrationElement} from '../../../../app/modules/apps/immigration/elements/core/_requests'
import parse from 'html-react-parser'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'

interface IProps {
  handleEdit: (data: ImmigrationElement) => void
}

const ListElements: React.FC<IProps> = ({handleEdit}) => {
  const intl = useIntl()
  const [search, setSearch] = React.useState('')
  const {data, status, refetch} = useQuery(
    'listimmigrationservices',
    async () =>
      await getImmigrationElement({
        page: 1,
        limit: 100,
      })
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch()
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [search])

  if (status === 'success' && data) {
    return (
      <>
        <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3'>
          <Input
            className='flex'
            placeholder='Buscar'
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
          />
        </div>
        <TableContainer style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({id: 'COMMON.NAME'})} </TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Servicion de immigración</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((element: ImmigrationElement, index: number) => (
                <TableRow key={index}>
                  <TableCell>{element.name}</TableCell>
                  <TableCell>{parse(element.description || '')}</TableCell>
                  <TableCell>{element.immigrationService?.name}</TableCell>
                  <TableCell>
                    <Space align='center'>
                      <Button
                        type='link'
                        onClick={() => {
                          handleEdit(element)
                        }}
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid green',
                          color: '#bf3c3c',
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    </Space>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  } else {
    return <>No data</>
  }
}

export default ListElements
