/* eslint-disable react-hooks/exhaustive-deps */
 
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../../components'
import {
  type IQueryApiCalls,
  apiCallsQueryInitValues,
} from '../../../../../app/modules/apps/apicalls/core/_models'
import {useEffect, useState} from 'react'
import DetailApiCall from './DetailApiCall'
import FiltersApiCalls from '../Filters/FiltersApiCalls'
import {getApiCalls} from '../../../../../app/modules/apps/apicalls/core/_requests'
import {useQuery} from 'react-query'

const ListApiCalls = () => {
  const [query, setQuery] = useState<IQueryApiCalls>(apiCallsQueryInitValues)
  const {data, status, refetch} = useQuery('getAllApiCalls', async () => await getApiCalls(query))

  useEffect(() => {
    refetch()
  }, [query])

  return (
    <>
      <FiltersApiCalls query={query} setQuery={setQuery} refetch={refetch} />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Estatus</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>COMPLETED</TableCell>
              <TableCell>VERIFIED</TableCell>
              <TableCell>IGNORED</TableCell>
              <TableCell>CREATED_AT</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {status === 'success' && data ? (
              <>
                <DetailApiCall data={data} refetch={refetch} />{' '}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={10}>No hay datos</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ListApiCalls
