/* eslint-disable @typescript-eslint/no-unused-vars */
import {type FC, useContext, useEffect, useState} from 'react'
import {RoleEnum} from '../../../../auth/models/User/UserModel'
import {Container} from '../../../../components/Container/index'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useIntl} from 'react-intl'
import {Answers} from './Answers'
import {Button, Link} from 'components'

const ContratationAppAnswers: FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const intl = useIntl()
  const [consultan, setConsultant] = useState(false)
  useEffect(() => {
    if (user != null) {
      if (user.role?.id === RoleEnum.admin) {
        // Opps casi piso un admin ')
        setConsultant(true)
      } else if (user.role?.id === RoleEnum.user) {
        // Un user ')
      }
    }
     
  }, [user])

  return (
    <>
      <Container title={intl.formatMessage({id: 'CONTRATATION.ANSWERSCONTAINER'})}>
        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
          <Link to='/admin/tools'>
            
            <button
              className='button-ui button-secondary button-s'
           >
              Volver
            </button>
        
          </Link>
        </div>
        {consultan ? (
          <>
            <Answers />
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export {ContratationAppAnswers}
