 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {LikeOutlined, LinkOutlined, SearchOutlined, StarOutlined, UserAddOutlined} from '@ant-design/icons'
import {Button, DatePicker, Input, InputRef, Space, Switch, Table} from 'antd'
import {
  type IQueryParams,
  type Translations,
  type TranslationsPartial,
} from '../../../../app/modules/apps/translations/core/_models'
import React, {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import {AiOutlineMail} from 'react-icons/ai'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {IReply, type IComposeData} from '../../../../app/modules/apps/appointments/core/_models'
import ModalLoader from '../../../../core/components/ModalLoader/index'
import {type Priority} from '../../../../app/modules/apps/priority/core/_models'
import PriorityModule from '../../../../app/pages/priority/PriorityModule'
import {RootStoreContext} from '../../../../stores/rootStore'
import {type StatusTag} from '../../../../app/modules/apps/statusTag/core/_models'
import StatusTagModule from '../../../../app/pages/statusTag/StatusTagModule'
import Swal from 'sweetalert2'
import {TimeZone} from '../../../../app/modules/utils/components/TimeZone'
import moment, {Moment} from 'moment'
import {numberCurrencyConverter} from '../../../../core/utils/numberCurrencyConverter'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {saveAs} from 'file-saver'
import './style.css'
import Column from 'antd/lib/table/Column'
import {ColumnFilterItem, ColumnType} from 'antd/lib/table/interface'
import {useQuery} from 'react-query'
import {getStatusTag} from 'app/modules/apps/statusTag/core/_requests'
import Highlighter from 'react-highlight-words'
import {getProductsTableFilter} from 'app/modules/apps/cart/core/products/_requests'
import {getAllStaff} from 'app/modules/apps/staff/core/_requests'
import {RangeValue} from 'rc-picker/lib/interface'
import {sendEmailBlueTemplateExtra, sendEmailWithLink} from 'app/modules/apps/users/core/_requests'
import ManualTranslationModal from './ManualTranslationModal'
import { Users } from 'app/modules/apps/users/core/_models'
import image from './image/avatar.svg'
import notes from '../admin/image/notes.svg'
import NotesModalTranslation from 'app/pages/admin/users/List/SlideTranslations'
import { getSurvey, getSurveyStar } from 'app/modules/apps/translations/core/_requests'
import { Rate } from 'antd';
import { StarFilled } from '@ant-design/icons';
const querysParam: IQueryParams = {
  page: 1,
  limit: 50,
}

interface IProps {
  setAssign: Dispatch<SetStateAction<boolean>>
  setTranslation: Dispatch<SetStateAction<Translations>>
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>
  setComposePost: React.Dispatch<React.SetStateAction<boolean>>
  setTabNumber: Dispatch<SetStateAction<string>>
}

const ListSatisfaction: React.FC<IProps> = ({

  setComposePost,
  setComposePostData,
  setTabNumber,
}) => {
  const [loading, setLoading] = useState(false)


  const rootStore = useContext(RootStoreContext)
  const {
    translations,
    translationsTotal,
    loadTranslations,
    

  } = rootStore.translationStore

  const {data: dataStatus} = useQuery('gettingStatus', async () => await getStatusTag())

  const {data: staffData} = useQuery('getStf', async () => await getAllStaff())

  const {data: dataProducts} = useQuery('getProducts', async () => await getProductsTableFilter())

  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof Translations
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [priority, setPriority] = React.useState<Priority>()
  const [translatorInfo, setTranslatorInfo] = useState<ColumnFilterItem[]>()
  const [productNameInfo, setProductNameInfo] = useState<ColumnFilterItem[]>()
  const [statusInfo, setStatusInfo] = useState<ColumnFilterItem[]>()
  const [staffInfo, setStaffInfo] = useState<ColumnFilterItem[]>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentStatus, setCurrentStatus] = useState<number[]>()
  const [currentStaffName, setCurrentStaffName] = useState<string[]>()
  const [currentProductName, setCurrentProductName] = useState<string[]>()
  const [fromDateEndDue, setFromDateEndDue] = useState<moment.Moment | null>(null)
  const [toDateEndDue, setToDateEndDue] = useState<moment.Moment | null>(null)
  const [fromDateCreation, setFromDateCreation] = useState<moment.Moment | null>(null)
  const [toDateCreation, setToDateCreation] = useState<moment.Moment | null>(null)
  const [fromDateDelivered, setFromDateDelivered] = useState<moment.Moment | null>(null)
  const [toDateDelivered, setToDateDelivered] = useState<moment.Moment | null>(null)
  const [currentDateDelivered, setCurrentDateDelivered] = useState<Moment[] | null[]>()
  const [currentCreation, setCurrentCreation] = useState<Moment[] | null[]>()
  const [currentendDueDate, setCurrentEndDueDate] = useState<Moment[] | null[]>()
  const [surveyData, setSurveyData] = useState<any[]>([]);

  useEffect(() => {
    const fetchSurveyStarData = async () => {
      try {
        const surveyStarData = await getSurveyStar();
        setSurveyData(surveyStarData);
        console.log('Datos de la encuesta Star:', surveyStarData);
      } catch (error) {
        console.error('Error al obtener los datos de la encuesta Star:', error);
      }
    };
  
    fetchSurveyStarData();
  }, []);






  
  useEffect(() => {
    console.log('currentStatusEffect' + currentStatus)
    loadTranslations({
      page: currentPage,
      limit: 50,
      status: currentStatus,
      email: currentEmail,
      productName: currentProductName,
      staffName: currentStaffName,
      endDate: currentendDueDate,
      creationDate: currentCreation,
      deliveredDate: currentDateDelivered,
    })
  }, [
    currentPage,
    currentStatus,
    currentEmail,
    currentProductName,
    currentStaffName,
    currentendDueDate,
    currentCreation,
    currentDateDelivered,
    loadTranslations,
  ])

  const handleSearchCustom = (type: string, value: string) => {
    setCurrentPage(1)
    if (type === 'email') {
      setCurrentEmail(value)
    }
  }

  useEffect(() => {
    setStatusInfo(
      dataStatus?.map((item: any) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
    )

    setTranslatorInfo(
      translations?.map((item: any) => {
        if (item.staffName !== undefined) {
          return {
            text: item.staffName,
            value: item.staffName,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )

    setStaffInfo(
      staffData?.map((item: any) => {
        if (
          item.jotformName !== undefined &&
          (item.type === 'translate_admin' ||
            item.type === 'translate' ||
            item.type === 'translation_admin' ||
            item.type === 'translation'
            )
        ) {
          return {
            text: item.jotformName,
            value: item.jotformName,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )

    setProductNameInfo(
      dataProducts?.data.map((item: any) => {
        if (item.name !== undefined && item.name.toLowerCase().includes('traduccion')) {
          return {
            text: item.name,
            value: item.name,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )
  }, [translations, staffData, dataStatus, dataProducts?.data])


  const onChange = async (pagination: any, filters: any) => {
    console.log('currentstatus' + currentStatus)
    console.log('currentstatuslen' + currentStatus?.length)
    console.log('pasginartion crreut' + pagination.current + 'andFilters' + JSON.stringify(filters))
    setCurrentPage(pagination.current)
    console.log('lenght' + currentStatus?.length)
    if (currentStatus?.length === undefined || currentStatus.length === 0) {
      setCurrentStatus(filters.statusTag)
    } else if (currentStatus?.length > 0) {
      setCurrentStatus(currentStatus)
    }

    if (currentProductName?.length === undefined || currentProductName.length === 0) {
      setCurrentProductName(filters.productName)
    } else if (currentProductName?.length > 0) {
      setCurrentProductName(currentProductName)
    }

    if (currentStaffName?.length === undefined || currentStaffName.length === 0) {
      setCurrentStaffName(filters.staffName)
    } else if (currentStaffName?.length > 0) {
      setCurrentProductName(currentProductName)
    }
  }
  const handleOpenPost = (data: any) => {
    setComposePost(true)
    setComposePostData(data)
  }
  const getColumnSearchPropsEmail = (dataIndex: string): ColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] !== undefined ? String(selectedKeys[0]) : ''}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchCustom('email', selectedKeys[0] as string)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchCustom('email', selectedKeys[0] as string)}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              setSearchText('');
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0] as string);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
  });


  const handleSearchDatePickerDelivered = (
    fromDate: Moment | null,
    toDate: Moment | null,
    type: string
  ) => {
    if (type === 'delivered') {
      setCurrentPage(1)
      setCurrentDateDelivered([fromDateDelivered!, toDateDelivered!])
    }
  }
  const handleDateChangeDelivered = (dates: RangeValue<moment.Moment>, type: string) => {
    if (dates && type === 'delivered') {
      setFromDateDelivered(dates[0])
      setToDateDelivered(dates[1])
    }
  }
  const renderStars = (stars: any) => {
    const maxStars = 5; 
    const filledStars = stars; 
    const emptyStars = maxStars - filledStars; 
  

    const starIcons = [];
  
    // Agregar estrellas llenas al array
    for (let i = 0; i < filledStars; i++) {
      starIcons.push(<StarFilled key={`star-filled-${i}`} style={{ color: 'yellow' }} />);
    }
  
    // Agregar estrellas vacías al array
    for (let i = 0; i < emptyStars; i++) {
      starIcons.push(<StarOutlined key={`star-outlined-${i}`} style={{ color: 'yellow' }} />);
    }
  
    return starIcons;
  };
  
  return (
    <>
    

    <Table
  className='table-master my-custom-table3'
  style={{ overflow: 'auto', position: 'relative' }}
  scroll={{ x: 80, y: 500 }}
  pagination={{
    pageSize: 50,
    showLessItems: true,
    size: 'small',
    responsive: true,
    simple: true,
    showQuickJumper: false,
    showSizeChanger: false,
    current: currentPage,
    total: surveyData.length || 0,
  }}
  dataSource={surveyData}
  onChange={onChange}
  rowClassName={() => 'row-hover'}
>
  <Column
    width={190}
    title='CLIENTE'
    dataIndex={'user_email'}
    key='user_email'
    {...getColumnSearchPropsEmail('user_email')}
    render={(email: string, record: any) => (
      <div style={{display: 'flex', flexDirection: 'column', marginBottom: '15px'}}>
        <b style={{margin: '0', height: '1.5rem'}}>
          {record.user_first_name?.length > 32 ? `${record.user_first_name.substring(0, 32)}...` : record.user_first_name}
        </b>
        <p style={{margin: '0'}}>
          {email?.length > 32 ? `${email.substring(0, 32)}...` : email}
        </p>
      </div>
    )}
  />
  <Column
    width={190}
    title="TRADUCCIÓN"
    key="product_name"
    dataIndex="product_name"
    render={(productName: string) => (
      <Space>
         {productName}
      </Space>
    )}
    filters={productNameInfo?.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
    )}
    onFilter={(value, record: any) => record.translation === value.toString()}
  />
  <Column
        width={190}
        title="ENTREGADO EL"
        dataIndex="deliver_date"
        filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[fromDateDelivered, toDateDelivered]}
              onChange={(value) => handleDateChangeDelivered(value, 'delivered')}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                  handleSearchDatePickerDelivered(fromDateDelivered, toDateDelivered, 'delivered');
                }}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Filtrar
              </Button>
              <Button
                onClick={() => {
                  setFromDateDelivered(null);
                  setToDateDelivered(null);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </div>
          </div>
        )}
        render={(deliverDate: string) => (
          <Space>
            <TimeZone date={deliverDate} />
          </Space>
        )}
      />

<Column
  width={250}
  title="Comentario"
  dataIndex={['survey', 0, 'comment']}
  key="comment"
  render={(comment: string) => (
    <Space style={{ marginLeft: '-15rem', width:'30rem' }}> {/* Ajusta el margen derecho aquí */}
      {comment}
    </Space>
  )}
/>

<Column
  width={150}
  title="Comunicación clara"
  dataIndex={['survey', 0, 'stars']}
  key="stars"
  render={(stars: number) => (
    <span className="star-container" style={{ fontSize: 5, width:'5px', height:'5px', display: 'flex', alignItems: 'center', marginLeft:'3rem' }} >
      {renderStars(stars)}
    </span>
  )}
/>
<Column
  width={150}
  title="Traducción precisa"
  dataIndex={['survey', 0, 'stars2']}
  key="stars2"
  render={(stars2: number) => (
    <span className="star-container" style={{ fontSize: 5, width:'5px', height:'5px', display: 'flex', alignItems: 'center', marginLeft:'3rem' }} >
       {renderStars(stars2)}
    </span>
  )}
/>
<Column
  width={150}
  title="Formatos"
  dataIndex={['survey', 0, 'stars3']}
  key="stars3"
  render={(stars3: number) => (
    <span className="star-container" style={{ fontSize: 5, width:'5px', height:'5px', display: 'flex', alignItems: 'center', marginLeft:'2rem' }} >
       {renderStars(stars3)}
    </span>
  )}
/>
<Column
  width={200}
  title="Tiempo de proceso"
  dataIndex={['survey', 0, 'stars4']}
  key="stars4"
  render={(stars4: number) => (
    <span className="star-container" style={{ fontSize: 5, width:'5px', height:'5px', display: 'flex', alignItems: 'center', marginLeft:'3rem' }} >
      {renderStars(stars4)}
    </span>
  )}
/>
<Column
  width={150}
  title="Preguntas resueltas"
  dataIndex={['survey', 0, 'stars5']}
  key="stars5"
  render={(stars5: number) => (
    <span className="star-container" style={{ fontSize: 5, width:'5px', height:'5px', display: 'flex', alignItems: 'center', marginLeft:'3rem' }} >
      {renderStars(stars5)}
    </span>
  )}
/>

<Column
           width={70}
          render={(record: any) => (
            <Space>
              <Button
                style={{background: 'white', border: '1px solid #FEA102'}}
                onClick={() => {
                  setTabNumber('1')
                  handleOpenPost({
                    orderDetailId: record.orderDetailId ? record.orderDetailId : '',
                    user: `${record.userName} `,
                    email: record.email ? record.email : '',
                    userId: record.userId ? record.userId : '',
                    fromId: record.userTranslationId ? record.userTranslationId : '',
                    messageId: null,
                    fromUser: record.userTranslationId ? record.userTranslationId : '',
                    folder: 'inbox',
                    draft: false,
                    favorite: false,
                    files_info: [],
                    unread: false,
                  })
                }}
              >
                <AiOutlineMail />
              </Button>
            </Space>
          )}
        />

</Table>



      
    </>
  )
}

export default observer(ListSatisfaction)
