export const core = {
  'UPDATE.SUCCESS': 'Actualizacion exitosa',
  'UPDATE.ERROR': 'Error al actualizar',
  'TRANSLATOR.SELECT': 'Seleccione el idioma ',
  'CORE.BTNSAVE': 'Guardar',
  'CORE.WELCOME': 'Bienvenido',
  'CORE.DATE': 'Fecha',
  'CORE.TYPE': 'Tipo',
  'CORE.STATE': 'Estado',
  'CORE.PENDING': 'Pendiente',
  'CORE.DONE': 'Finalizado con exito',
  'CORE.REQUIRED': 'Campo requerido',
  'ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT': 'Numero de registros seleccionados: ',
  'ECOMMERCE.COMMON.ALL': 'Todo',
  'ECOMMERCE.COMMON.SUSPENDED': 'Suspendido',
  'ECOMMERCE.COMMON.ACTIVE': 'Activo',
  'ECOMMERCE.COMMON.FILTER': 'Filtro',
  'ECOMMERCE.COMMON.BY_STATUS': 'Por estado',
  'ECOMMERCE.COMMON.BY_TYPE': 'Por tipo',
  'ECOMMERCE.COMMON.BUSINESS': 'Negocio',
  'ECOMMERCE.COMMON.INDIVIDUAL': 'Individual',
  'ECOMMERCE.COMMON.SEARCH': 'Buscar',
  'ECOMMERCE.COMMON.IN_ALL_FIELDS': 'En todo los campos',
  'ECOMMERCE.ECOMMERCE': 'Comercio electronico',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS': 'Clientes',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST': 'Listado de clientes',
  'ECOMMERCE.CUSTOMERS.NEW_CUSTOMER': 'Cliente Nuevo',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE': 'Cliente borrado',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION':
    'Esta seguro de borrar permanentemente este cliente?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION': 'Cliente se esta borrando...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE': 'Cliente ha sido borrado',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE': 'Cliente Borrar',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION':
    'Esta seguro de borrar permanentemente los clientes seleccionados?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION': 'Los clientes se estan borrando...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE': 'Clientes seleccionados han sido borrados',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE':
    'El estado de los clientes seleccionados ha sido actualizado ',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE':
    'El estado de los clientes seleccionados ha sido exitosamente actualizado',
  'ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE': 'El cliente ha sido actualizado',
  'ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE': 'El cliente ha sido creado',
  'PROFILE.PROFILETITTLE': 'Mi perfil ',
  'PROFILE.PROFILETITTLEBASIC': 'Información Basica',
  'PROFILE.PROFILETITTLEIMMIGRATION': 'Información Migratoria',
  'PROFILE.PROFILETITTLEIMMIGRATIONNEW': 'Perfil Laboral',
  'PROFILE.PROFILETITTLEIMMIGRATIONNEW2': 'Informacion Familiar',
  'PROFILE.SIGNOUT': 'Cerrar sesión',
  'PROFILE.PLEASEWAIT': 'Por favor espere',
  'PROFILE.FIRSTNAME': 'Nombre',
  'PROFILE.LASTNAME': 'Apellido',
  'PROFILE.EMAIL': 'Correo electronico',
  'PROFILE.OCCUPATION': 'Ocupacion',
  'PROFILE.CHILDREN': 'N° de hijos (digite 0 sin no tiene)',
  'PROFILE.ACADEMIC': 'Grado académico mas alto finalizado',
  'PROFILE.ACADEMICDEGREE': 'Grado academico mas alto completado',
  'PROFILE.STUDY': 'Área de estudio',
  'PROFILE.ENGLISHPROF': 'Proficiencia en inglés',
  'PROFILE.FRENCHPROF': 'Proficiencia en frances',
  'PROFILE.LABOURHISTORY': 'Historial laboral más reciente',
  'PROFILE.CANADAAPLICATION': 'Ha realizado aplicaciones previas a Canadá en el pasado?',
  'PROFILE.TYPEOFAPLICATION': 'qué tipo de aplicación realizó?',
  'PROFILE.BIRTH': 'Fecha de nacimiento',
  'PROFILE.ADDRESS': 'Direccion',
  'PROFILE.REJECTED': 'ha tenido rechazos?',
  'PROFILE.CIVILSTATUS': 'Estado civil',
  'PROFILE.CONYUGE': 'Nombre del conyuge',
  'PROFILE.CONYUGEAGE': 'Edad del conyuge',
  'PROFILE.CONYUGECOUNTRY': 'Pais de residencia actual',
  'PROFILE.CONYUGESTUDY': 'Area de estudio',

  'PROFILE.PHONE': 'Telefono',
  'PROFILE.MARITALSTATUS': 'Estado civil',
  'PROFILE.COUNTRYOFORIGIN': 'Pais en el que reside',
  'PROFILE.NATIONALITIE': 'Nacionalidad',
  'PROFILE.NATIONALITIESELECT': 'Seleccionar nacionalidad...',
  'PROFILE.LANGUAGE': 'Idioma',
  'PROFILE.LANGUAGESELECT': 'Seleccionar lenguaje...',
  'PROFILE.TIMEZONE': 'Zona horaria',
  'PROFILE.TIMEZONESELECT': 'Seleccionar zona horaria...',
  'PROFILE.GENDER': 'Genero',
  'PROFILE.GENDERSELECT': 'Seleccionar genero',
  'PROFILE.GENDERM': 'Hombre',
  'PROFILE.GENDERF': 'Mujer',
  'PROFILE.GENDERO': 'Otro',
  'PROFILE.CONTINUE': 'Continuar',
}
