/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import { DisplayForms } from '../newUserViewContratationApp/Forms/form'
import { getForms } from '../../../../app/modules/apps/forms/core/_requests'
import { useQuery } from 'react-query'
import { getContractByUser } from 'app/modules/apps/contract/_requests'
import { RootStoreContext } from 'stores/rootStore'
import { getFormsProducts } from 'app/modules/apps/forms/formproducts/core/_requests'
import { ID } from 'core/helpers'
import { getFormsCompletedData } from 'app/modules/apps/formsdata/core/_requests'
import { DisplayFormCompleted } from '../newUserViewContratationApp/Forms/formCompleted'
import { DisplayFormDarft } from '../newUserViewContratationApp/Forms/formDraft'
import { updateContracts } from 'app/modules/apps/docusign/_requests'
import File from '../icons/file.svg'
import ChevronRight from '../icons/chevron-right.svg'
import Chevron2 from '../icons/chevron-right2.svg'
import TumbsUp from '../../contratationApp/icons/thumbs-up.svg'
import ClockY from '../icons/clock-alt-yellow.svg'
import Arrow from '../icons/arrow-left.svg'
import Box from '../icons/box-4.svg'
import MyContractStep from './MyContractStep'
import Graduate from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg'
import UsersIcon from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Users.svg'
import Plane from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/air-mail.svg'
import Briefcase from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-2.svg'
import Houseonly from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Houseonly.svg'
import UsersIcon2 from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg'
import CompletedContractsModal from '../contratacionAnswers/CompletedContractsModal'
import { ContractsTemps } from 'app/modules/apps/contract/_models'
import { findTempsByContractId } from 'app/modules/apps/appointments/core/_requests'

interface IProps {
  name: string
  contractData: RootObjectCompletedContracts | undefined
  contractStatus: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  contractId: string
  setContractId: Dispatch<SetStateAction<string>>
  start: Dispatch<SetStateAction<boolean>>
  maxStep: number
  setMaxStep: Dispatch<SetStateAction<number>>
  stepBar: number
  setStepBar: Dispatch<SetStateAction<number>>
  listProducts: string[]
  setListProducts: Dispatch<SetStateAction<string[]>>
  contract: any

}
const AdditionalUserForms: React.FC<IProps> = ({
  name,
  step,
  setStep,
  contractId,
  start,
  maxStep,
  setMaxStep,
  setStepBar,
  listProducts,
  setListProducts,
  contract,

}) => {
  const rootStore = useContext(RootStoreContext)
  const { user, updatingContracts } = rootStore.authStore
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openFormCompleted, setOpenFormCompleted] = useState<boolean>(false)
  const [openDraft, setOpenDraft] = useState<boolean>(false)
  const [progressbar, setProgressbar] = useState(0)
  const [porcentageBase, setPorcentageBase] = useState(0)
  const [getIndex, setGetIndex] = useState(0)
  const [completedLenght, setCompletedLenght] = useState(0)
  const [barUpdate, setbarUpdate] = useState(0)
  const [active, setActive] = useState<boolean>(false)
  const [active2, setActive2] = useState<boolean>(false)
  const [active3, setActive3] = useState<boolean>(false)
  const [active4, setActive4] = useState<boolean>(false)
  const [active5, setActive5] = useState<boolean>(false)
  const [active6, setActive6] = useState<boolean>(false)
  const [active7, setActive7] = useState<boolean>(false)
  const [active8, setActive8] = useState<boolean>(false)
  const [dataSelected, setDataSelected] = useState<ContractsTemps>()
  const [active888, setActive888] = useState<boolean>(false)
  const [active9, setActive9] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [update, setupdate] = useState(0)
  const [productId, setProductId] = useState('')
  const [userForms, setUserForms] = useState<any[]>([])
  const [userFormsFinal, setUserFormsFinal] = useState<any[]>([])
  const [userFormsCompleted, setUserFormsCompleted] = useState<any[]>([])
  const [userFormsDrafts, setUserFormsDrafts] = useState<any[]>([])
  const [userFormsTotal, setUserFormsTotal] = useState<any[]>([])
  const [userAllForms, setUserAllForms] = useState<any>({ formsStatusCompleted: [], formsStatusDraft: [], formsStatusPending: [], })
  const [completedFormId, setCompletedFormId] = useState<string>('')
  const finalProducts: string[] = []
  const finalProductsIdMember: any = []
  const [memberTypeSelected, setMemberTypeSelected] = useState('')
  const [memberNameSelected, setMemberNameSelected] = useState('')
  const [showPendingForms, setShowPendingForms] = useState<boolean>(false)
  const [showCompletedForm, setShowCompletedForm] = useState<boolean>(false)
  const [showDraftForms, setShowDraftForms] = useState<boolean>(false)
  const [showAllForms, setShowAllForms] = useState<boolean>(true)
  const [uploadThing, setUploadThing] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState("all")
  const [pendantForms, setpendantForms] = useState(-1)
  const [draftForms, setDraftforms] = useState(-1)
  const [completedForms, setCompletedForms] = useState(-1)
  const [formSelected, setFormSelected] = useState<any>()
  const [pendingFormFromIndex, setPendingFormFromIndex] = useState<any>()
  const [positionIndex, setPositionIndex] = useState<any>()
  const [firstGuide, setFirstGuide] = useState<any>()
  const [lastGuide, setLastGuide] = useState<any>()
  const [guide, setGuide] = useState<any>()
  const [showDiv, setShowDiv] = useState(null)
  const [showContract, setShowContract] = useState(false)
  const [showContractInfo, setShowContractInfo] = useState<any>()
  const [showMainContainer, setShowMainContainer] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)


  useEffect(() => {
    const fetchTemps = async () => {
      if (contractId) {
        try {
          const tempsData = await findTempsByContractId(contractId);
          console.log('Temps data for contractId:', tempsData);
          if (tempsData && tempsData.length > 0) {
            setDataSelected(tempsData[0]);
          }
        } catch (error) {
          console.error('Error fetching temps:', error);
        }
      }
    };

    fetchTemps();
  }, [contractId]);



  const [contractsFound, setContractsFound] = useState<RootObjectCompletedContracts>({
    resultSetSize: '',
    startPosition: '',
    endPosition: '',
    totalSetSize: '',
    nextUri: '',
    previousUri: '',
    envelopes: [],
    folderItems: [],
  })
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const toggleDiv = (index: any) => {
    if (showDiv === index) {
      setShowDiv(null)
    } else {
      setShowDiv(index)
    }
  }
  let members: any = []

  const { data, status, refetch: refetchForms } = useQuery(
    'listforms',
    async () => {
      console.log("Fetching forms with query: limit=50&page=1&name=");
      const formsData = await getForms('limit=50&page=1&name=');
      console.log("Forms data received:", formsData);
      return formsData;
    }
  ); /* formularios */

  const {
    data: dataFormsCompleted,
    status: statusFromCompleted,
    isFetched,
    refetch,
  } = useQuery(
    'listformsclientsss',
    async () => {

      const completedFormsData = await getFormsCompletedData(user?.id!);

      return completedFormsData;
    },
    {
      enabled: user?.id !== undefined,
      cacheTime: 0
    }
  );

  const {
    data: dataFromDatabase,
    status: statusFromDatabase,
    refetch: refetchFromDataBase,
  } = useQuery(
    'allcontrDb',
    async () => {
      const contractData = await getContractByUser(user?.id!);
      return contractData;
    },
    {
      enabled: user?.id !== undefined,
      cacheTime: 0
    }
  );


  const GetMainId = (contractId: string) => {
    dataFromDatabase.data.map((cont: any) => {
      if (contractId === cont.contracts_users_envelope_id) {
        setSteps(cont.contracts_users_id)
      } else {
        setStatus(cont.contracts_users_id)
      }
    })
  }

  const handleToggleContent = (index: number) => {
    toggleContent(index)
  }

  const handleToggleContent3 = (index: number) => {
    toggleContent3(index)
  }

  const handleToggleContent2 = (index: number) => {
    toggleContent2(index)
  }

  function groupProductsByMember(products: any) {
    const groupedProducts = products.reduce((groupedProducts: any, product: any) => {
      const { memberName, memberType, productId, productName } = product
      const key = `${memberName}-${memberType}`
      if (!groupedProducts[key]) {
        groupedProducts[key] = {
          memberName,
          memberType,
          products: [],
        }
      }
      groupedProducts[key].products.push({
        productId,
        productName,
      })
      return groupedProducts
    }, {})
    const result = Object.values(groupedProducts)
    return result
  }

  const setSteps = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        step: step > 3 ? step : 3,
        status: 1,
        list_products: listProducts,
      }
    } else {
      dataContractData = {
        step: step > 3 ? step : 3,
        status: 1,
        list_products: listProducts,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const setStatus = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        status: 0,
      }
    } else {
      dataContractData = {
        status: 0,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const updateForms = (userFormsCompletedParam: any, userFormsDraftsParam: any) => {
    if (userForms.length > 0 &&
      userFormsTotal.length >= 0 &&
      userForms[0].memberName &&
      userFormsDrafts &&
      userFormsCompleted) {
      if (active6 === false) {
        const missingForms: any = []
        const missingFormsByMember: any = {}
        setUserForms(userForms.filter(form => {
          return form.hasOwnProperty('memberName') && form.hasOwnProperty('memberType')
        }))
        for (const producto of userForms) {
          const { memberName, memberType } = producto
          const key = `${memberName}_${memberType}`
          if (!missingFormsByMember[key]) {
            missingFormsByMember[key] = []
          }
          const productoCompletado = userFormsTotal.find(
            (completado) => ((completado.productName === producto.name) &&
              (completado.memberType === producto.memberType) &&
              (completado.memberName === producto.memberName))
          )
          if (!productoCompletado) {
            missingFormsByMember[key].push({
              name: producto.name,
              prodIds: producto.prodIds,
              forms: producto.forms,
              memberName,
              memberType
            })
            continue
          }
          const missingFormsProducto: any[] = []
          for (const formulario of producto.forms) {
            const formularioCompletado = productoCompletado.forms.find(
              (completadoForm: any) => completadoForm.formData.formName === formulario.data.formName
            )
            if (!formularioCompletado) {
              missingFormsByMember[key].push({
                name: producto.name,
                id: producto.id,
                prodIds: producto.prodIds,
                forms: missingFormsProducto,
                memberName,
                memberType
              })
            }
          }
          missingForms[producto.name] = {
            name: producto.name,
            id: producto.id,
            prodIds: producto.prodIds,
            forms: missingFormsProducto,
            memberName: producto.memberName,
            memberType: producto.memberType
          }
        }
        const valores: any = Object.values(missingFormsByMember).flat()
        const missingFormsArray = Object.values(missingFormsByMember)
        missingFormsArray.forEach((producto: any) => {
          producto && producto.forms &&
            producto.forms.sort((a: any, b: any) => {
              const indexA = specificOrder.indexOf(a.data.guide)
              const indexB = specificOrder.indexOf(b.data.guide)
              if (indexA === -1 && indexB === -1) {
                return 0
              }
              if (indexA === -1) {
                return 1
              }
              if (indexB === -1) {
                return -1
              }
              return indexA - indexB
            })
        })
        if (userFormsTotal && userFormsTotal[0] && userFormsTotal[0].forms) {
          valores.forEach((pending: any) => {
            pending.forms = pending.forms.filter((formPending: any) => {
              let foundMatch = false
              userFormsTotal[0].forms.forEach((notPending: any) => {
                if (
                  notPending.formDataFormId === formPending.id &&
                  pending.memberName === notPending.memberName &&
                  pending.memberType === notPending.memberType
                ) {
                  foundMatch = true
                }
              })
              return !foundMatch
            })
          })
          /* valores = valores.filter((pending: any) => pending.forms.length > 0) */
        }
        for (const valor of valores) {
          if (userFormsTotal && userFormsTotal[0] && userFormsTotal[0].forms) {
            for (const notPending of userFormsTotal[0].forms) {
              if (valor.memberName === notPending.memberName &&
                valor.memberType === notPending.memberType &&
                valor.name === notPending.productName
              ) {
                valor.forms.push(notPending)
              }
            }
          }
        }
        let totalPendantForms = -1
        valores.forEach((pendant: any) => {
          pendant.forms.forEach((status: any) => {
            if (status.status === null) {
              totalPendantForms++
            }
          })
        })
        setTimeout(() => {
          setActive7(true)
        }, 500)
        setUserAllForms({
          formsStatusCompleted: userFormsCompletedParam,
          formsStatusDraft: userFormsDraftsParam,
          formsStatusPending: valores,
        })
        setUserFormsFinal(valores)
        setupdate(update + 1)
        setActive6(true)
        refetch()
      }
    }
  }

  if (statusFromCompleted === 'success' && statusFromDatabase === 'success' && status === 'success' && active3 === false) {
    if (active5 === false) {
      const tempFormsByProductName: { [productName: string]: any[] } = {}
      dataFormsCompleted?.forEach((formCompleted: any) => {
        const productName = formCompleted.product.name
        const idProduct = formCompleted.product.id
        const formData = formCompleted.datafill
        const formDataId = formCompleted.id
        const formDataFormId = formCompleted.form.id
        const userContractId = formCompleted.contract
        const formStatus = formCompleted.status
        const memberName = formCompleted.memberName
        const memberType = formCompleted.memberType
        const deletedAt = formCompleted.deletedAt
        if (userContractId === contractId) {
          if (tempFormsByProductName.hasOwnProperty(productName)) {
            tempFormsByProductName[productName].push({
              formData,
              formDataId,
              formDataFormId,
              formStatus,
              idProduct,
              memberName,
              memberType,
              productName,
              deletedAt
            })
          } else {
            tempFormsByProductName[productName] = [{
              formData,
              formDataId,
              formDataFormId,
              formStatus,
              idProduct,
              memberName,
              memberType,
              productName,
              deletedAt
            }]
          }
        }
      })
      const formsStatus0: any = []
      const formsStatus1: any = []
      const formsStatusCombined: any = []
      const flattemp = Object.values(tempFormsByProductName).flat()
      const formsWithStatus0: any = flattemp.filter(
        (form) => form.formStatus === 0
      )
      const formsWithStatus1: any = flattemp.filter(
        (form) => form.formStatus === 1
      )
      if (formsWithStatus0.length > 0) {
        formsStatus0.push({
          name: formsWithStatus0.productName,
          memberName: formsWithStatus0.memberName,
          memberType: formsWithStatus0.memberType,
          forms: formsWithStatus0,
          deletedAt: formsWithStatus0.deletedAt
        })
      }
      if (formsWithStatus1.length > 0) {
        formsStatus1.push({
          name: formsWithStatus0.productName,
          memberName: formsWithStatus1.memberName,
          memberType: formsWithStatus1.memberType,
          forms: formsWithStatus1,
          deletedAt: formsWithStatus1.deletedAt
        })
      }
      const combinedForms = [...formsWithStatus0, ...formsWithStatus1]
      if (combinedForms.length > 0) {
        formsStatusCombined.push({ forms: combinedForms })
      }
      setUserFormsTotal(formsStatusCombined)
      setupdate(update + 1)
      let uploadthing2 = true
      if (uploadThing === true || uploadthing2) {
        const tempFormsByProductName: {
          [key: string]: {
            name: string
            memberName: string
            memberType: string
            forms: any[]
          }
        } = {}
        dataFormsCompleted?.forEach((formCompleted: any) => {
          const productName = formCompleted.product.name
          const idProduct = formCompleted.product.id
          const formData = formCompleted.datafill
          const formDataId = formCompleted.id
          const formDataFormId = formCompleted.form.id
          const userContractId = formCompleted.contract
          const formStatus = formCompleted.status
          const memberName = formCompleted.memberName
          const memberType = formCompleted.memberType
          const deletedAt = formCompleted.deletedAt
          if (userContractId === contractId) {
            const key = `${productName}_${memberName}_${memberType}`
            if (tempFormsByProductName.hasOwnProperty(key)) {
              tempFormsByProductName[key].forms.push({
                formData,
                formDataId,
                formDataFormId,
                formStatus,
                idProduct,
                memberName,
                memberType,
                productName,
                deletedAt
              })
            } else {
              tempFormsByProductName[key] = {
                name: productName,
                memberName,
                memberType,
                forms: [
                  {
                    formData,
                    formDataId,
                    formDataFormId,
                    formStatus,
                    idProduct,
                    memberName,
                    memberType,
                    productName,
                    deletedAt
                  },
                ],
              }
            }
          }
        })
        const formsStatus0 = Object.values(tempFormsByProductName)
          .filter((item) => item.forms.some((form) => form.formStatus === 0))
          .map((item) => ({ ...item, forms: item.forms.filter((form) => form.formStatus === 0) }))
        const formsStatus1 = Object.values(tempFormsByProductName)
          .filter((item) => item.forms.some((form) => form.formStatus === 1))
          .map((item) => ({ ...item, forms: item.forms.filter((form) => form.formStatus === 1) }))
        let totalCompletedForms = -1
        let totalDraftForms = -1
        formsStatus1.forEach(complete => {
          complete.forms.forEach(form => {
            if (!form.deletedAt) {
              totalCompletedForms++
            }
          })
        })
        formsStatus0.forEach(complete => {
          complete.forms.forEach(form => {
            if (!form.deletedAt) {
              totalDraftForms++
            }
          })
        })
        if (uploadThing === true) {
        }
        setDraftforms(totalDraftForms + 1)
        setUserFormsCompleted(formsStatus1)
        setUserFormsDrafts(formsStatus0)
        setupdate(update + 1)
        uploadthing2 = false
        setUploadThing(false)
        setCompletedForms(totalCompletedForms + 1)
      }
      setActive5(true)
    }
  }
  const getPorcentage = () => {
    if (active2 === false && porcentageBase > 0) {
      setProgressbar(porcentageBase * completedLenght)
      setbarUpdate(barUpdate + 1)
      setActive2(true)
    }
  }
  if (statusFromDatabase === 'success' && active === false && active3 === false) {
    dataFromDatabase.data.map((data: any) => {
      if (data.contracts_users_envelope_id === contractId) {
        if (data.contracts_users_applicants) {
          members = groupProductsByMember(JSON.parse(data.contracts_users_applicants))
          members.forEach((member: any) => {
            member.products.forEach((product: any) => {
              finalProducts.push(product.productName)
              finalProductsIdMember.push({
                productId: product.productId,
                memberType: member.memberType,
                memberName: member.memberName,
              })
            })
          })
        }
      }
    })
    if (active4 === false && finalProducts.length > 0) {
      const finalIds = finalProductsIdMember.map((ids: any) => ids.productId)
      setListProducts(finalIds)
      setActive4(true)
      processFinalProductsId(members, userFormsCompleted, userFormsDrafts)
    }
    if (userForms !== null && userForms?.length > 0) {
      let totalForms = 0
      userForms.forEach((product) => {
        const productForms = product.forms || []
        totalForms += productForms.length
      })
      const percentageBase = totalForms > 0 ? 100 / totalForms : 0
      setPorcentageBase(percentageBase)
      setActive(true)
    }
  }
  async function processFinalProductsId(finalProductsIdMember: any[], userFormsCompletedParam: any, userFormsDraftsParam: any) {
    const finalResult = []
    if (finalProductsIdMember && finalProductsIdMember.length > 0 && active9 === false) {
      for (const member of finalProductsIdMember) {
        const finalObject: any = {}
        for (const product of member.products) {
          const res = await getFormsProducts(product.productId) as any
          if (res !== null && res.length > 0) {
            const productName = res[0].product.name
            const productId = res[0].product.id
            if (!finalObject[productName]) {
              finalObject[productName] = {
                forms: res.map((form: any) => form.form),
                prodIds: productId,
                memberType: member.memberType,
                memberName: member.memberName,
              }
            } else {
              finalObject[productName].forms.push(...res.map((form: any) => form.form))
              finalObject[productName].prodIds.push(productId)
            }
            res.forEach((form: any) => {
              if (!userForms.some((f) => f.id === form.form.id)) {
                userForms.push(form.form)
              }
            })
          }
        }
        const memberProducts = Object.keys(finalObject).map((productName) => ({
          name: productName,
          forms: finalObject[productName].forms,
          prodIds: finalObject[productName].prodIds,
          memberType: finalObject[productName].memberType,
          memberName: finalObject[productName].memberName,
        }))
        finalResult.push(...memberProducts)
      }
      setActive9(true)
    }
    setUserForms(finalResult)
    setTimeout(() => {
      setActive7(true)
    }, 500)
    updateForms(userFormsCompletedParam, userFormsDraftsParam)
  }
  const specificOrder: string[] = [
    '001', '002', '003', '004', '005', '006', '007', '008', '009', '010',
    '011', '012', '013', '014', '015', '016', '017', '018', '019', '020',
    '021', '022', '023', '024', '025', '026', '027', '028', '029', '030',
  ]
  const GetIndex = (id: ID) => {
    setPendingFormFromIndex(null)
    status === 'success' && data?.map((form: any, index) => {
      if (id === form.id) {
        setPendingFormFromIndex(form)
        setGetIndex(form.data.guide)
      }
    })
  }
  useEffect(() => {
    let forms: any = []
    userAllForms.formsStatusPending.map((eachApplicant: any) => {
      if (eachApplicant.memberName === memberNameSelected && eachApplicant.memberType === memberTypeSelected) {
        forms = eachApplicant.forms.filter((eform: any) => {
          return !eform.deleted_at && !eform.deletedAt
        })
      }
      // Convertir los valores de guide o formGuide a números y formatos
      const guidesInfo = forms.map((formulario: any) => {
        if (formulario && formulario.data && formulario.data.guide) {
          return {
            guide: formulario.data.guide,
            guideFormat: parseInt(formulario.data.guide, 10)
          }
        } else {
          return {
            guide: formulario.formData.guide,
            guideFormat: parseInt(formulario.formData.guide, 10)
          }
        }
      })
      // Extraer las listas de guides y guidesFormat
      const guidesFormat = guidesInfo.map((info: any) => info.guideFormat)
      const guidesNOFormat = guidesInfo.map((info: any) => info.guide)
      guidesNOFormat.sort((a: any, b: any) => {
        return parseInt(a, 10) - parseInt(b, 10)
      })
      setGuide(guidesNOFormat)
      // Encontrar el valor mínimo y máximo
      const minGuide = Math.min(...guidesFormat)
      const maxGuide = Math.max(...guidesFormat)
      // Convertir de nuevo a formato con ceros a la izquierda
      const minGuideStr = minGuide.toString().padStart(3, '0')
      const maxGuideStr = maxGuide.toString().padStart(3, '0')
      setFirstGuide(minGuideStr)
      setLastGuide(maxGuideStr)
      /* setCalculatingForms(forms.length) */
      if (pendingFormFromIndex) {
        forms.map((eachForm: any, index: number) => {
          if (pendingFormFromIndex.id === eachForm.id) {
            setPositionIndex(eachForm.data.guide)
            setOpenFormCompleted(false)
            setOpenDraft(false)
          }
        })
        return
      }
      //borradores
      if (formSelected.formStatus === 0) {
        setCompletedFormId(formSelected.formDataId
        )
        setOpenFormCompleted(false)
        setOpenDraft(true)
        forms.map((eachForm: any, index: number) => {
          if (formSelected.formDataId === eachForm.formDataId) {

            setPositionIndex(eachForm.formData.guide)
          }
        })
        return
      }
      //completados
      if (formSelected.formStatus === 1) {
        setCompletedFormId(formSelected.formDataId)
        setOpenFormCompleted(true)
        forms.map((eachForm: any, index: number) => {
          if (formSelected.formDataId === eachForm.formDataId) {
            setPositionIndex(eachForm.formData.guide)
          }
        })
        return
      }
    })
  }, [formSelected])

  useEffect(() => {
    let newForm: any
    const pending = userAllForms.formsStatusPending
    let forms: any = []

    userAllForms.formsStatusPending.map((eachApplicant: any) => {
      if (eachApplicant.memberName === memberNameSelected && eachApplicant.memberType === memberTypeSelected) {
        /*   forms = eachApplicant.forms */
        forms = eachApplicant.forms.filter((eform: any) => {
          return !eform.deleted_at && !eform.deletedAt
        })
        eachApplicant.forms.map((eachAppEachFrom: any) => {
          if (eachAppEachFrom.data && eachAppEachFrom.data.guide === positionIndex) {
            newForm = eachAppEachFrom
          } else if (eachAppEachFrom.formData && eachAppEachFrom.formData.guide === positionIndex) {
            newForm = eachAppEachFrom
          }/* else{
            setOpenForm(false)
            setOpenFormCompleted(false)
          } */

        })
        /*  newForm = eachApplicant.forms[positionIndex] */
      }
      if (newForm) {
        //borradores
        if (newForm && newForm.formStatus === 0) {
          setCompletedFormId(newForm.formDataId)
          setOpenFormCompleted(false)
          setOpenDraft(true)
          return
        }
        //borradores
        if (newForm && newForm.formStatus === 1) {
          setCompletedFormId(newForm.formDataId)
          setOpenFormCompleted(true)
          return
        }
        data?.map((eachForm: any, index: number) => {
          if (newForm.id === eachForm.id) {
            setGetIndex(eachForm.data.guide)
            setOpenFormCompleted(false)
            setOpenDraft(false)
          }
        })
      }
    })
  }, [positionIndex])

  useEffect(() => {
    if (active3 === true) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 500)
      setTimeout(() => {
        setActive7(false)
        setActive(false)
        setActive2(false)
        setActive5(false)
        setActive4(false)
        setActive6(false)
        getPorcentage()
        setActive3(false)
        setupdate(update + 1)
      }, 1500)
    }
  }, [
    active3,
    completedLenght,
    getPorcentage,
    openForm,
    porcentageBase,
    update,
    finalProductsIdMember.productId,
  ])

  useEffect(() => {
    getPorcentage()
  }, [getPorcentage, porcentageBase])

  useEffect(() => {
    setActive888(true)
    updateForms2(userFormsCompleted, userFormsDrafts)
    setTimeout(() => {
      setActive8(true)
    }, 10000)

  }, [userFormsDrafts, userFormsCompleted, userForms])

  const updateForms2 = (userFormsCompletedParam: any, userFormsDraftsParam: any) => {
    if (active888 === true) {
      const missingForms: any = []
      const missingFormsByMember: any = {}
      setUserForms(userForms.filter(form => {
        return form.hasOwnProperty('memberName') && form.hasOwnProperty('memberType')
      }))
      for (const producto of userForms) {
        const { memberName, memberType } = producto
        const key = `${memberName}_${memberType}`
        if (!missingFormsByMember[key]) {
          missingFormsByMember[key] = []
        }
        const productoCompletado = userFormsTotal.find(
          (completado) => ((completado.productName === producto.name) && (completado.memberType === producto.memberType) && (completado.memberName === producto.memberName))
        )
        if (!productoCompletado) {
          missingFormsByMember[key].push({
            name: producto.name,
            prodIds: producto.prodIds,
            forms: producto.forms,
            memberName,
            memberType
          })
          continue
        }
        const missingFormsProducto: any[] = []
        for (const formulario of producto.forms) {
          const formularioCompletado = productoCompletado.forms.find(
            (completadoForm: any) => completadoForm.formData.formName === formulario.data.formName
          )
          if (!formularioCompletado) {
            missingFormsByMember[key].push({
              name: producto.name,
              id: producto.id,
              prodIds: producto.prodIds,
              forms: missingFormsProducto,
              memberName,
              memberType
            })
          }
        }
        missingForms[producto.name] = {
          name: producto.name,
          id: producto.id,
          prodIds: producto.prodIds,
          forms: missingFormsProducto,
          memberName: producto.memberName,
          memberType: producto.memberType
        }
      }
      const valores: any = Object.values(missingFormsByMember).flat()
      const missingFormsArray = Object.values(missingFormsByMember)
      missingFormsArray.forEach((producto: any) => {
        producto && producto.forms &&
          producto.forms.sort((a: any, b: any) => {
            const indexA = specificOrder.indexOf(a.data.formName)
            const indexB = specificOrder.indexOf(b.data.formName)
            if (indexA === -1 && indexB === -1) {
              return 0
            }
            if (indexA === -1) {
              return 1
            }
            if (indexB === -1) {
              return -1
            }
            return indexA - indexB
          })
      })

      if (userFormsTotal && userFormsTotal[0] && userFormsTotal[0].forms) {
        valores.forEach((pending: any) => {
          pending.forms = pending && pending.forms && pending.forms.filter((formPending: any) => { //nimrod fue el ultimo que cambio esto ,esta jodiendo en dev, att andres
            let foundMatch = false
            userFormsTotal[0].forms.forEach((notPending: any) => {
              if (
                notPending.formDataFormId === formPending.id &&
                pending.memberName === notPending.memberName &&
                pending.memberType === notPending.memberType
              ) {
                foundMatch = true
              }
            })
            return !foundMatch
          })
        })
        /* valores = valores.filter((pending: any) => pending.forms.length > 0) */
      }

      for (const valor of valores) {
        if (userFormsTotal && userFormsTotal[0] && userFormsTotal[0].forms) {
          for (const notPending of userFormsTotal[0].forms) {
            if (valor.memberName === notPending.memberName &&
              valor.memberType === notPending.memberType &&
              valor.name === notPending.productName
            ) {
              valor.forms.push(notPending)
            }
          }
        }
      }

      let totalPendantForms = -1
      valores.forEach((pendant: any) => {
        pendant && pendant.forms && pendant.forms.forEach((status: any) => {
          if (status.status === null) {
            totalPendantForms++
          }
        })
      })
      setpendantForms(totalPendantForms + 1)
      setUserAllForms({
        formsStatusCompleted: userFormsCompletedParam,
        formsStatusDraft: userFormsDraftsParam,
        formsStatusPending: valores,
      })
      setUserFormsFinal(valores)
      setActive888(false)
    }
  }

  useEffect(() => {
    setActive3(true)
    if (active3 === true) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }, [])

  useEffect(() => {
    if (uploadThing === true) {
      refetch()
    }
  }, [uploadThing])

  useEffect(() => {
  }, [isFetched, dataFormsCompleted])

  useEffect(() => {
    setCompletedLenght(
      dataFormsCompleted !== null && dataFormsCompleted !== undefined
        ? dataFormsCompleted.length
        : 0
    )
  }, [dataFormsCompleted])

  const [buttonStates, setButtonStates] = useState<boolean[]>(
    Array(userFormsFinal.length).fill(false)
  )

  const toggleContent = (index: number) => {
    const updatedButtonStates = [...buttonStates]
    updatedButtonStates[index] = !updatedButtonStates[index]
    setButtonStates(updatedButtonStates)
  }

  const [buttonStates2, setButtonStates2] = useState<boolean[]>(
    Array(userFormsCompleted.length).fill(false)
  )

  const toggleContent2 = (index: number) => {
    const updatedButtonStates = [...buttonStates2]
    updatedButtonStates[index] = !updatedButtonStates[index]
    setButtonStates2(updatedButtonStates)
  }

  const [buttonStates3, setButtonStates3] = useState<boolean[]>(
    Array(userFormsCompleted.length).fill(false)
  )

  const toggleContent3 = (index: number) => {
    const updatedButtonStates = [...buttonStates3]
    updatedButtonStates[index] = !updatedButtonStates[index]
    setButtonStates3(updatedButtonStates)
  }

  const filterButton = (name: string) => {
    if (name === "pending") {
      setShowCompletedForm(false)
      setShowDraftForms(false)
      setShowPendingForms(true)
      setShowAllForms(false)
      return
    }
    if (name === "completed") {
      setShowCompletedForm(true)
      setShowDraftForms(false)
      setShowPendingForms(false)
      setShowAllForms(false)
      return
    }
    if (name === "draft") {
      setShowCompletedForm(false)
      setShowDraftForms(true)
      setShowPendingForms(false)
      setShowAllForms(false)
      return
    }
    if (name === "all") {
      setShowCompletedForm(false)
      setShowDraftForms(false)
      setShowPendingForms(false)
      setShowAllForms(true)
      return
    }
  }
  const visitProducts = [
    'bb4056fb-2440-4a06-aec1-8d13349e7427', 'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97',
    'fd5b2b7d-8772-4ee6-91cc-53f710351021', '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', 'c263052c-80cf-4fc3-a628-88e51621f4e3',
    '6420335e-30aa-46c5-892b-e1fa23914733', '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac',
    '229d7b91-7a9b-407a-988b-9e45096bff5b', 'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a', '8a29f12d-a898-4d52-8fd1-f184094cd8c3', '65245c09-8c4d-485a-abaa-8f752d4dfe39',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '588bed15-e8fb-4216-8580-6de93f1512ae', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]

  const residenceProducts = [
    'c7571404-31f4-45b3-898a-160d4e72afc5', '9665d0d1-c145-4dc6-be9f-d4c69006604c', '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '2a7387ab-c1dc-45f1-a6b3-3f22c0fc30e5', 'f455901e-a97e-4c2d-abd4-8a359778e88a',
    '068de1e8-3074-4259-8a2a-1418d8f39ca3', '87bcc196-0f68-421b-9591-4e3565d69783', '728d916e-47fe-48ae-9628-468712651938',
    '0c9f691d-2b23-4c7d-8419-7f55785418f6', 'fb1a576c-f5a1-4d72-9aa8-d2f8eb5fa34d', '1de57333-82cf-4aaa-8a9b-23fadab35a3f',
    '7b84fba5-c4a9-48bc-ac44-f84a97db6ea2', 'bdda48a1-ea4d-480d-9e2c-e14e7904dd27', '054e28db-5fa8-487b-a452-38b94392f1ce',
    'cd90d231-265a-4be0-9982-54cf3aee6537', '346454f5-f81f-4a37-abc6-8e6d17f26512', '29c268c2-b5a9-4888-82c9-a209b5e20962',
    'a9b2deee-cb6f-4aa0-a521-8ecc02d32394', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', 'ee8c9b93-151c-40ce-86ca-8f8f2aa3a644',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3', '4762b784-650d-429b-b48d-02c351f34085'
  ]

  const specialProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99'
  ]

  const renderIcons = (applicants: any[]) => {
    let showPlane = false
    let showGraduate = false
    let showBriefcase = false
    let showHouse = false
    let showUsersIcon2 = false

    applicants.forEach(applicant => {
      if (visitProducts.includes(applicant.productId)) showPlane = true
      if (studyProducts.includes(applicant.productId)) showGraduate = true
      if (workProducts.includes(applicant.productId)) showBriefcase = true
      if (residenceProducts.includes(applicant.productId)) showHouse = true
      if (specialProducts.includes(applicant.productId)) showUsersIcon2 = true
    })
  }

  useEffect(() => {
    if (dataFormsCompleted && dataFromDatabase) {

      setContractsFound(dataFormsCompleted);
    }
  }, [dataFormsCompleted, dataFromDatabase]);
  useEffect(() => {
  }, [contractsFound]);

  /* const handleOpenModal = async () => {
    if (!contractsFound || !contractsFound.envelopes || contractsFound.envelopes.length === 0) {
        console.warn("contractsFound is undefined or empty");

        try {
            console.log("Refetching contracts...");
            const result = await refetch();
            console.log('Refetch result:', result); // Ya confirmamos que tienes datos en 'result'

            if (result?.data && Array.isArray(result.data) && result.data.length > 0) {
                const envelopes = result.data.filter(item => item.contract && item.contract === '76e38ce9-a6fa-48e2-8771-d0871db7efe5');
                console.log("Filtered envelopes:", envelopes);
                if (envelopes.length > 0) {
                    setContractsFound({ envelopes });
                    setIsModalVisible(true);
                } else {
                    console.warn("No se encontraron contratos válidos.");
                }
            } else {
                console.warn("No se encontraron datos después de refetch");
            }
        } catch (error) {
            console.error("Error fetching contracts data:", error);
        }
    } else {
        setIsModalVisible(true);
    }
  };

     */





  // Call this function or make sure your data is loaded before rendering the modal
  useEffect(() => {
    if (dataFormsCompleted && dataFromDatabase) {
      setContractsFound(dataFormsCompleted);
    }
  }, [dataFormsCompleted, dataFromDatabase]);
  return (
    <div>
      {showMainContainer ? (
        <div className='main-data-continer'>
          <div className='text-container'>
            {loading === true && (
              <div className='loading-style'>
                <div style={{ width: '80vw', height: '100vh', position: 'fixed' }}>
                  <img src='/media/logos/logovid.gif' style={{ width: '100%', height: '100%' }} alt='logo' />
                </div>
              </div>
            )}
            <button
              className='button-ui button-secondary button-s'
              onClick={() => { setStep(1) }}>
              <img src={Arrow} alt="Arrow" />
              <p>VOLVER A SOLICITUDES</p>
            </button>
            <div style={{ width: '100%', height: '1px', backgroundColor: '#DFE4EA', marginTop: '1rem' }}></div>
            <div className='step-three-header-container'>
              <div>
                <h1 style={{ marginBottom: '0rem' }} className='step-header-title'>
                  Contrato n° {contractId}
                  <button
                // style={{
                //   color: "#01A9DB",
                //   display: "flex",
                //   flexDirection: "row",
                //   gap: "0.3rem",
                //   alignItems: "center",
                //   justifyContent: "center",
                // }}
                className='button-ui button-tertiary no-border'
                onClick={() => setIsModalVisible(true)}
              >
                <img src={Box} alt="Box" />
                <p style={{ margin: "0", fontSize: "1rem" }}>Ver detalles del contrato</p>
              </button>
                </h1>
                {contract.map((item: any) =>
                  <>
                    {item.envelope_id === contractId ? (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '20px', alignItems: 'center' }}>
                        <p style={{ fontSize: '12px' }}>Contratado el {item.completedDate.substring(0, 10)}</p>
                        {(() => {
                          // Verificar si 'contracts_users_applicants' está definido
                          const applicants = item.applicants ? JSON.parse(item.applicants) : []
                          let showPlane = false
                          let showGraduate = false
                          let showBriefcase = false
                          let showHouse = false
                          let showUsersIcon2 = false
                          applicants.forEach((applicant: { productId: string }) => {
                            if (visitProducts.includes(applicant.productId)) showPlane = true
                            if (studyProducts.includes(applicant.productId)) showGraduate = true
                            if (workProducts.includes(applicant.productId)) showBriefcase = true
                            if (residenceProducts.includes(applicant.productId)) showHouse = true
                            if (specialProducts.includes(applicant.productId)) showUsersIcon2 = true
                          })
                          // Calcular los tipos de miembro únicos
                          const uniqueMemberTypesCount = applicants.reduce((acc: Record<string, boolean>, applicant: { memberType: string | number }) => {
                            acc[applicant.memberType] = true
                            return acc
                          }, {})
                          const uniqueMemberTypes = Object.keys(uniqueMemberTypesCount).length
                          return (
                            <div style={{ display: 'flex', gap: '10px', marginTop: '-14px' }}>
                              {showPlane && (
                                <button
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '3px 5px',
                                    backgroundColor: '#01A9DB',
                                    borderRadius: '3px',
                                    gap: '5px'
                                  }}>
                                  <img src={Plane} alt="Plane Icon" />
                                </button>)}

                              {showGraduate && (
                                <button
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '3px 5px',
                                    backgroundColor: '#EF5DA8',
                                    borderRadius: '3px',
                                    gap: '5px'
                                  }}
                                >
                                  <img src={Graduate} alt="Graduate Icon" />
                                </button>
                              )}

                              {showBriefcase && (
                                <button
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '3px 5px',
                                    backgroundColor: '#22AD5C',
                                    borderRadius: '3px',
                                    gap: '5px'
                                  }}>
                                  <img src={Briefcase} alt="Briefcase Icon" />
                                </button>)}

                              {showHouse && (
                                <button
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '3px 5px',
                                    borderRadius: '3px',
                                    gap: '5px',
                                    width: '35px'
                                  }}
                                >
                                  <img src={Houseonly} alt="House Icon" />
                                </button>
                              )}

                              {showUsersIcon2 && (
                                <button
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '3px 5px',
                                    backgroundColor: '#DFE4EA',
                                    borderRadius: '3px',
                                    gap: '5px'
                                  }}
                                >
                                  <img src={UsersIcon2} alt="Users Icon 2" />
                                </button>
                              )}

                              {/* Icono con el número de tipos de miembros únicos */}
                              <button
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '3px 5px',
                                  border: '1px solid #DFE4EA',
                                  borderRadius: '3px',
                                  gap: '5px'
                                }}
                              >
                                <img src={UsersIcon} alt="User Icon" />
                                <p style={{ fontSize: '10px', fontWeight: 'bold', margin: '0' }}>{uniqueMemberTypes}</p>
                              </button>
                            </div>
                          )
                        })()}
                      </div>
                    ) : null}
                  </>
                )}
              </div>



              {/*    <a
                         className='product-name btn-section help-step-btn'
                         rel='noreferrer'
                         target='_blank'
                         href="https://comunidad.immilandcanada.com/tags/c/inmigracion/5/guia-formularios">
                         <img src={Lifering} alt="Lifering" />
                         <p style={{ margin: '0', fontSize: '14px', textDecoration: 'none', color: 'black' }}>Ayuda</p>
                       </a> */}

            </div>
            <div className='onboard-guide-container'>
              {/* Time line */}
              <div className='step-line-cont'>
                {step > 1 && (
                  <div
                    className='guide-number-done one-btn'
                    onClick={() => {
                      setStep(1)
                    }}>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>1</b></p>
                    <p className='hide-tex step-text' style={{ color: '#13C296' }}>Servicio contratado</p>
                    <div className='greenpath2' />
                    <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                  </div>
                )}

                {(maxStep > 2 && step > 2) || (maxStep > 2 && step < 2) ? (
                  <div
                    className='guide-number-done two-btn'
                    onClick={() => {
                      setStep(2)
                    }}>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                    <p className='hide-tex step-text' style={{ color: '#13C296' }}> Completar formularios</p>
                    <div className='greenpath2' />
                    <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                  </div>
                ) : step === 2 && (
                  <>
                    <div className='guide-number two-btn'>
                      <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                      <p className='hide-tex step-text' style={{ color: 'black' }}> Completar formularios</p>
                      <div className='blackpath' />
                      <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                    </div>
                    <div className='responsive-badges'>
                      <div className='step-badge-active2 step-badge-rejected-dinamic pending-s2-badge' >
                        <img src={ClockY} alt="ClockY" />
                        <div className='flex gap-1'>

                          <p className='step-badge-active-p-ones' ><b>{pendantForms < 0 ? 0 : pendantForms}</b></p>
                          <p className='step-badge-active-p-ones hide-tex' >Pendiente(s)</p>
                        </div>
                      </div>
                    </div>
                  </>)}

                {(maxStep > 3 && step > 3) || (maxStep >= 3 && step < 3) ? (
                  <div
                    className='guide-number-done three-btn'
                    onClick={() => {
                      setStep(3)
                    }}>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                    <p className='hide-tex step-text' style={{ color: '#13C296' }}> Adjuntar documentos</p>
                    <div className='greenpath2' />
                    <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                  </div>
                ) : step === 3 ? (
                  <div className='guide-number three-btn'>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                    <p className='hide-tex step-text' style={{ color: 'black' }}> Adjuntar documentos</p>
                    <div className='blackpath' />
                    <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                  </div>
                ) : (
                  <div className='guide-number-deactivate three-btn'>
                    <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                    <p className='hide-tex step-text' style={{ color: '#d1d5db' }}> Adjuntar documentos</p>
                    <div className='graypath' />
                    <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                  </div>
                )}

                {(maxStep >= 5 && step > 4) || (maxStep >= 5 && step < 4) && (
                  <div
                    className='guide-number-done four-btn'
                    onClick={() => {
                      setStep(4)
                    }}>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                    <p className='hide-tex step-text' style={{ color: '#13C296' }}>Asignación y segimiento</p>
                    <div className='greenpath2' />
                    <img className='data-final-step-chevron'
                      style={{ width: '20px', height: '20px' }}
                      src={Chevron2} alt="Chevron2" />
                  </div>)}
                {step === 4 ? (
                  <div className='guide-number four-btn'>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                    <p className='hide-tex step-text' style={{ color: 'black' }}>Asignación y segimiento</p>
                    <div className='blackpath' style={{ top: '66px', left: '-8px', height: '5.5rem' }} />
                    <img className='data-final-step-chevron'
                      style={{ width: '20px', height: '20px' }}
                      src={Chevron2} alt="Chevron2" />
                  </div>
                ) : null}
                {(maxStep < 5) && (
                  <div className='guide-number-deactivate four-btn'>
                    <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                    <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Asignación y segimiento</p>
                    <div className='graypath' />
                    <img className='data-final-step-chevron'
                      style={{ width: '20px', height: '20px' }}
                      src={Chevron2} alt="Chevron2" />
                  </div>)}

                {maxStep >= 5 && step < 5 && (
                  <div
                    className='guide-number-done five-btn'
                    onClick={() => {
                      setStep(5)
                    }}
                  >
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                    <p className='hide-tex step-text' style={{ color: '#13C296' }}>Confirmar cartas</p>
                  </div>)}
                {step === 5 && (
                  <div className='guide-number five-btn'>
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                    <p className='hide-tex step-text' style={{ color: 'black' }}>Confirmar cartas</p>
                  </div>)}
                {maxStep < 5 &&
                  <div className='guide-number-deactivate five-btn'>
                    <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                    <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Confirmar cartas</p>
                  </div>}
              </div>

              {openForm === false ? (
                <div className='step-two-container-main'>
                  <h1 className='step-header-title'>Completar formularios</h1>
                  <p className='step-header-subtitle'>Revise los formularios que tiene pendientes por completar.</p>
                  <div className='flex flex-row gap-4 p-4 mb-4 mobile-styles'>
                    <button
                      className={`btn-section ${activeTab === "all" && "active"}`}
                      onClick={() => {
                        filterButton("all")
                        setActiveTab('all')
                      }}>
                      <p style={{ margin: '0', fontSize: '12px' }}>Ver todos</p>
                    </button>
                    <button onClick={() => {
                      filterButton("pending")
                      setActiveTab('pending')
                    }} className={`btn-section ${activeTab === "pending" && "active"}`}>
                      <p style={{ margin: '0', fontSize: '12px' }}>Pendientes</p>
                      <p className='amount pending'>{pendantForms < 0 ? 0 : pendantForms}  </p>
                    </button>
                    <button onClick={() => {
                      filterButton("completed")
                      setActiveTab('completed')
                    }} className={`btn-section ${activeTab === "completed" && "active"}`}>
                      <p style={{ margin: '0', fontSize: '12px' }}>Completados</p>
                      <p className='amount approved'>{completedForms < 0 ? 0 : completedForms}</p>
                    </button>
                    <button onClick={() => {
                      filterButton("draft")
                      setActiveTab('draft')
                    }} className={`btn-section ${activeTab === "draft" && "active"}`}>
                      <p style={{ margin: '0', fontSize: '12px' }}>Borradores</p>
                      <p className='amount review'>{draftForms < 0 ? 0 : draftForms}</p>
                    </button>
                  </div>
                  <>
                    {update > 0 && showPendingForms === true &&
                      userFormsFinal && userFormsFinal.map((userForm: any, index: number) => (
                        <>
                          {/* esta es la lista de pendientes */}
                          {userForm.forms && userForm.forms.length > 0 && userForm.forms.filter((elemento: any) => elemento.formStatus !== 0 && elemento.formStatus !== 1).length > 0 && (
                            <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates[index] ? 'open' : ''}`}>
                              <div
                                className='flex justify-between items-center w-full'
                                onClick={() => handleToggleContent(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                                <div>
                                  <div className='step-two-header-container'>
                                    <p style={{ fontSize: '18px', margin: '0' }}>
                                      <b>{userForm.memberName}</b> ({userForm.memberType})
                                    </p>
                                    <div className='step-badge-active2' style={{ paddingLeft: '3px', paddingRight: '3px', width: '6rem', height: '1.5rem' }}>

                                      <p className='step-badge-active-p-ones' style={{ fontSize: '12px' }}>{(userForm?.forms.filter((form: any) => form.id && !form.deleted_at).length)} Pendiente(s)</p>
                                    </div>
                                  </div>
                                  <p className='hide-tex' style={{ fontSize: '12px' }}><b>{userForm.name} </b></p>
                                </div>
                                <button
                                  className={
                                    buttonStates[index] ? 'show-btn-presed' : 'show-btn-presed2'
                                  }
                                  onClick={() => {
                                    toggleContent(index)
                                  }}>
                                  <img
                                    className='show-btn'
                                    src={ChevronRight}
                                    alt='ChevronRight' />
                                </button>
                              </div>
                              {buttonStates[index] && (
                                <ul className='mt-4 w-full'>
                                  {userForm.forms.length > 0 && userForm?.forms.map((form: any, index: any) => (
                                    (form.formStatus !== 0 && form.formStatus !== 1 && !form.deletedAt) &&
                                    <li
                                      className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                      key={'a' + index}>
                                      <div className='step-two-header-form' onClick={() => {
                                        GetIndex(form.id)
                                        setFormSelected(form)
                                        setOpenForm(true)
                                        setProductId(userForm.prodIds)
                                        setMemberNameSelected(userForm.memberName)
                                        setMemberTypeSelected(userForm.memberType)
                                      }} style={{ cursor: 'pointer' }}>
                                        <div className='flex items-center gap-2' >
                                          <img
                                            width='24'
                                            height='24'
                                            src={File}
                                            alt='File' />
                                          <p className='formtitle' style={{ fontSize: '1.2rem', margin: '0' }}>
                                            {form.name}
                                          </p>
                                        </div>
                                        <div className='step-badge-active2'>
                                          <p className='step-badge-active-p-ones'>Pendiente</p>
                                        </div>
                                      </div>
                                      <div className='flex gap-4 items-center '>
                                        <button
                                          className='icon-form-list'
                                          onClick={() => {
                                            GetIndex(form.id)
                                            setFormSelected(form)
                                            setOpenForm(true)
                                            setProductId(userForm.prodIds)
                                            setMemberNameSelected(userForm.memberName)
                                            setMemberTypeSelected(userForm.memberType)
                                          }}>
                                          <img
                                            width='25'
                                            height='25'
                                            src={ChevronRight}
                                            alt='ChevronRight' />
                                        </button>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                  </>
                  {update > 0 && showDraftForms === true &&
                    userFormsDrafts.length > 0 &&
                    userFormsDrafts && userFormsDrafts?.map((draft: any, index: number) => (
                      <>
                        {userFormsDrafts?.some(draft => {
                          if (draft.forms) {
                            // Si hay forms en el draft, verifica si alguno tiene deletedAt sin definir
                            return draft.forms.some((form: any) => form && !form.deletedAt)
                          }
                          // Si no hay forms en el draft, devuelve false
                          return false
                        }) &&
                          <>
                            {/* Lista de borradores */}
                            <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates2[index] ? 'open' : ''}`}>
                              <div
                                className='flex justify-between items-center w-full'
                                onClick={() => handleToggleContent2(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                                <div>
                                  <div style={{ width: '100%' }} className='flex gap-4 items-center'>
                                    <p style={{ fontSize: '18px', margin: '0' }}>
                                      <b>{draft.memberName}</b>  ({draft.memberType})
                                    </p>
                                  </div>
                                  <p className='hide-tex' style={{ fontSize: '12px' }}><b>{draft.name}</b></p>
                                </div>
                                <button
                                  className={buttonStates2[index] ? 'show-btn-presed' : 'show-btn-presed2'}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleToggleContent2(index)
                                  }}>
                                  <img
                                    className='show-btn'
                                    src={ChevronRight}
                                    alt='ChevronRight' />
                                </button>
                              </div>
                              {buttonStates2[index] && (
                                <ul className='mt-4 w-full'>
                                  {draft &&
                                    draft?.forms.map((form: any, index: number) => (
                                      <>
                                        {form.formStatus === 0 && !form.deletedAt && (
                                          <li
                                            className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                            key={'a' + index}>
                                            <div className='step-two-header-form' onClick={() => {
                                              setOpenDraft(true)
                                              setFormSelected(form)
                                              GetIndex(form.formDataId)
                                              setOpenForm(true)
                                              setCompletedFormId(form.formDataId)
                                              setProductId(form.idProduct)
                                              setMemberNameSelected(draft.memberName)
                                              setMemberTypeSelected(draft.memberType)
                                            }} style={{ cursor: 'pointer' }}>
                                              <div className='flex items-center gap-2' >
                                                <img
                                                  width='24'
                                                  height='24'
                                                  src={File}
                                                  alt='File' />
                                                <p className='formtitle' style={{ fontSize: '1.2rem', margin: '0' }}>
                                                  {form.formData.formName}
                                                </p>
                                              </div>
                                              <div className='step-badge-active-draft2' style={{ width: '6rem', height: '1.5rem' }}>
                                                <p className='step-badge-active-draft-p-ones' style={{ fontSize: '12px', margin: '0' }}>Borrador</p>
                                              </div>
                                            </div>
                                            <div className='flex gap-4 items-center'>
                                              <button
                                                className='icon-form-list'
                                                type='button'
                                                onClick={() => {
                                                  setOpenDraft(true)
                                                  setFormSelected(form)
                                                  GetIndex(form.formDataId)
                                                  setOpenForm(true)
                                                  setCompletedFormId(form.formDataId)
                                                  setProductId(form.idProduct)
                                                  setMemberNameSelected(draft.memberName)
                                                  setMemberTypeSelected(draft.memberType)
                                                }}>
                                                <img
                                                  width='25'
                                                  height='25'
                                                  src={ChevronRight}
                                                  alt='ChevronRight' />
                                              </button>
                                            </div>
                                          </li>
                                        )}
                                      </>
                                    ))}
                                </ul>
                              )}
                            </div>
                          </>
                        }
                      </>
                    ))}
                  {update > 0 && showCompletedForm === true &&
                    userFormsCompleted.length > 0 &&
                    userFormsCompleted && userFormsCompleted?.map((formCompleted: any, index: number) => (
                      <>
                        {/* Lista de completados */}
                        <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates3[index] ? 'open' : ''}`}>
                          <div
                            className='flex justify-between items-center w-full'
                            onClick={() => handleToggleContent3(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                            <div>
                              <div style={{ width: '100%' }} className='flex gap-4 items-center'>
                                <p style={{ fontSize: '18px', margin: '0' }}>
                                  <b>{formCompleted.memberName}</b>  ({formCompleted.memberType})
                                </p>
                              </div>
                              <p className='hide-tex' style={{ fontSize: '12px' }}><b>{formCompleted.name}</b></p>
                            </div>
                            <button
                              className={buttonStates3[index] ? 'show-btn-presed' : 'show-btn-presed2'}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleToggleContent3(index)
                              }}>
                              <img
                                className='show-btn'
                                src={ChevronRight}
                                alt='ChevronRight' />
                            </button>
                          </div>
                          {buttonStates3[index] && (
                            <ul className='mt-4 w-full'>
                              {formCompleted &&
                                formCompleted?.forms.map((form: any, index: number) => (
                                  <>
                                    {form.formStatus === 1 && !form.deletedAt && (
                                      <li
                                        className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                        key={'a' + index}>
                                        <div className='step-two-header-form' onClick={() => {
                                          GetIndex(form.formDataId)
                                          setOpenForm(true)
                                          setFormSelected(form)
                                          setOpenFormCompleted(true)
                                          setCompletedFormId(form.formDataId)
                                          setMemberNameSelected(form.memberName)
                                          setMemberTypeSelected(form.memberType)
                                        }} style={{ cursor: 'pointer' }}>
                                          <div className='flex items-center gap-2'>
                                            <img
                                              width='24'
                                              height='24'
                                              src={File}
                                              alt='File' />
                                            <p className='formtitle' style={{ fontSize: '1.2rem', margin: '0' }}>
                                              {form.formData.formName}
                                            </p>
                                          </div>
                                          <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                                            <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Completado</p>
                                          </div>
                                        </div>
                                        <div className='flex gap-4 items-center'>
                                          <button
                                            className='icon-form-list'
                                            type='button'
                                            onClick={() => {
                                              setFormSelected(form)
                                              GetIndex(form.formDataId)
                                              setOpenForm(true)
                                              setOpenFormCompleted(true)
                                              setCompletedFormId(form.formDataId)
                                              setMemberNameSelected(form.memberName)
                                              setMemberTypeSelected(form.memberType)
                                            }}>
                                            <img
                                              width='25'
                                              height='25'
                                              src={ChevronRight}
                                              alt='ChevronRight' />
                                          </button>
                                        </div>
                                      </li>
                                    )}
                                  </>
                                ))}
                            </ul>
                          )}
                        </div>
                      </>
                    ))}
                  {/* Lista ver todo */}
                  {update > 0 && showAllForms === true && userAllForms &&
                    userAllForms.formsStatusPending &&
                    userAllForms.formsStatusPending.length > 0 &&
                    userAllForms?.formsStatusPending.sort((a: any, b: any) => {
                      // Ordenar por memberType, priorizando 'Solicitante principal'
                      if (a.memberType === 'Solicitante principal' && b.memberType !== 'Solicitante principal') {
                        return -1
                      } else if (a.memberType !== 'Solicitante principal' && b.memberType === 'Solicitante principal') {
                        return 1
                      } else if (a.memberType === 'Conyuge o Pareja de hecho' && b.memberType !== 'Conyuge o Pareja de hecho') {
                        return -1
                      } else if (a.memberType !== 'Conyuge o Pareja de hecho' && b.memberType === 'Conyuge o Pareja de hecho') {
                        return 1
                      } else {
                        return 0
                      }
                    }).map((pendingForm: any, index: number) => (
                      <>
                        <>
                          {/* esta es la lista de todos */}
                          {pendingForm.forms && pendingForm.forms.length > 0 && (
                            <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates[index] ? 'open' : ''}`}>
                              <div className='flex justify-between items-center w-full' onClick={() => handleToggleContent(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                                <div>
                                  <div className='step-two-header-container'>
                                    <p style={{ fontSize: '18px', margin: '0' }}>
                                      <b>{pendingForm.memberName}</b> ({pendingForm.memberType})
                                    </p>
                                    <div className='step-badge-active2' style={{ paddingLeft: '3px', paddingRight: '3px', width: '6rem', height: '1.5rem' }}>
                                      <p className='step-badge-active-p-ones' style={{ fontSize: '12px' }}>
                                        {(pendingForm?.forms.filter((form: any) => (form.id && !form.deleted_at)).length)} Pendiente(s)
                                      </p>
                                    </div>
                                  </div>
                                  <p className='hide-tex' style={{ fontSize: '12px' }}><b>{pendingForm.name}</b></p>
                                </div>
                                <button className={buttonStates[index] ? 'show-btn-presed' : 'show-btn-presed2'}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleToggleContent(index)
                                  }}>
                                  <img className='show-btn' src={ChevronRight} alt='ChevronRight' />
                                </button>
                              </div>

                              {buttonStates[index] && (
                                <ul className='mt-4 w-full'>
                                  {pendingForm?.forms.map((form: any, index: any) => {
                                    if (form.formStatus !== 1 && form.formStatus !== 0 && !form.deletedAt) {
                                      return (
                                        <li
                                          className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                          key={'a' + index}>
                                          <div className='step-two-header-form' onClick={() => {
                                            GetIndex(form.id)
                                            setFormSelected(form)
                                            setOpenForm(true)
                                            setProductId(pendingForm.prodIds)
                                            setMemberNameSelected(pendingForm.memberName)
                                            setMemberTypeSelected(pendingForm.memberType)
                                          }} style={{ cursor: 'pointer' }}>
                                            <div className='flex items-center gap-2' >
                                              <img
                                                width='24'
                                                height='24'
                                                src={File}
                                                alt='File' />
                                              <p className='formtitle' style={{ fontSize: '1.2rem', margin: '0' }}>
                                                {form.name}
                                              </p>
                                            </div>
                                            <div className='step-badge-active2'>
                                              <p className='step-badge-active-p-ones'>Pendiente</p>
                                            </div>
                                          </div>
                                          <div className='flex gap-4  items-center'>
                                            <button
                                              className='icon-form-list'
                                              type='button'
                                              onClick={() => {
                                                setFormSelected(form)
                                                GetIndex(form.id)
                                                setOpenForm(true)
                                                setProductId(pendingForm.prodIds)
                                                setMemberNameSelected(pendingForm.memberName)
                                                setMemberTypeSelected(pendingForm.memberType)
                                              }}>
                                              <img
                                                width='25'
                                                height='25'
                                                src={ChevronRight}
                                                alt='ChevronRight' />
                                            </button>
                                          </div>
                                        </li>
                                      )
                                    }
                                    if (form.formStatus === 1 && !form.deletedAt) {
                                      return (
                                        <>
                                          <li
                                            className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                            key={'a' + index}>
                                            <div className='step-two-header-form' onClick={() => {
                                              GetIndex(form.formDataId)
                                              setFormSelected(form)
                                              setOpenForm(true)
                                              setOpenFormCompleted(true)
                                              setCompletedFormId(form.formDataId)
                                              setMemberNameSelected(form.memberName)
                                              setMemberTypeSelected(form.memberType)
                                            }} style={{ cursor: 'pointer' }}>
                                              <div className='flex items-center gap-2'>
                                                <img style={{ marginBottom: '20px' }}
                                                  width='24'
                                                  height='24'
                                                  src={File}
                                                  alt='File' />
                                                <p className='product-name' style={{ fontSize: '1.2rem' }}>
                                                  {form.formData.formName}
                                                </p>
                                              </div>
                                              <div className='step-badge-active-success2'>
                                                <p className='step-badge-active-success-p-ones'>Completado</p>
                                              </div>
                                            </div>
                                            <div className='flex gap-4 items-center'>
                                              <button
                                                className='icon-form-list'
                                                type='button'
                                                onClick={() => {
                                                  setFormSelected(form)
                                                  GetIndex(form.formDataId)
                                                  setOpenForm(true)
                                                  setOpenFormCompleted(true)
                                                  setCompletedFormId(form.formDataId)
                                                  setMemberNameSelected(form.memberName)
                                                  setMemberTypeSelected(form.memberType)
                                                }}>
                                                <img
                                                  width='25'
                                                  height='25'
                                                  src={ChevronRight}
                                                  alt='ChevronRight' />
                                              </button>
                                            </div>
                                          </li>
                                        </>
                                      )
                                    }
                                    if (form.formStatus === 0 && !form.deletedAt) {
                                      return (
                                        <>
                                          <li
                                            className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                            key={'a' + index}>
                                            <div className='step-two-header-form' onClick={() => {
                                              setOpenDraft(true)
                                              setFormSelected(form)
                                              GetIndex(form.formDataId)
                                              setOpenForm(true)
                                              setCompletedFormId(form.formDataId)
                                              setProductId(form.idProduct)
                                              setMemberNameSelected(pendingForm.memberName)
                                              setMemberTypeSelected(pendingForm.memberType)
                                            }} style={{ cursor: 'pointer' }}  >
                                              <div className='flex items-center gap-2'>
                                                <img
                                                  width='24'
                                                  height='24'
                                                  src={File}
                                                  alt='File'
                                                />
                                                <p className='product-name' style={{ fontSize: '1.2rem' }}>
                                                  {form.formData.formName}
                                                </p>
                                              </div>
                                              <div className='step-badge-active-draft2' style={{ width: '3rem' }}>
                                                <p className='step-badge-active-draft-p-ones'>Borrador</p>
                                              </div>
                                            </div>
                                            <div className='flex gap-4 items-center'>
                                              <button
                                                className='icon-form-list'
                                                type='button'
                                                onClick={() => {
                                                  setOpenDraft(true)
                                                  setFormSelected(form)
                                                  GetIndex(form.formDataId)
                                                  setOpenForm(true)
                                                  setCompletedFormId(form.formDataId)
                                                  setProductId(form.idProduct)
                                                  setMemberNameSelected(pendingForm.memberName)
                                                  setMemberTypeSelected(pendingForm.memberType)
                                                }}>
                                                <img
                                                  width='25'
                                                  height='25'
                                                  src={ChevronRight}
                                                  alt='ChevronRight' />
                                              </button>
                                            </div>
                                          </li>
                                        </>
                                      )
                                    }
                                    return null
                                  })}
                                </ul>
                              )}
                            </div>
                          )}
                        </>
                      </>
                    ))}

                  {(active8 === true && maxStep <= 2 && pendantForms === 0 && draftForms === 0 && completedForms > 0) ? (
                    <div className='continue-btn-continer'>
                      <div className='continue-btn-main'>
                        <img src={TumbsUp} alt="TumbsUp" />
                        <h1 style={{ color: '#13C296', fontSize: '24px', marginTop: '1.8rem' }}>¡Excelente!</h1>
                        <h1 style={{ color: '#4F4F4F', fontSize: '24px' }}>¡Completaste todos los formularios!</h1>
                        <p style={{ color: '#637381', fontSize: '18px', width: '80%', textAlign: 'center', marginTop: '1.8rem' }}>Ahora, proceda a <b>adjuntar documentos</b>  de soporte para continuar con su proceso.</p>
                        <button
                          style={{ marginTop: '2rem', width: '8rem', height: '3rem' }}
                          type='button'
                          className='powerup-card-btn'
                          onClick={() => {
                            setStep(3)
                            GetMainId(contractId)
                            if (maxStep <= 2) {
                              setStepBar(70)
                              setMaxStep(3)
                            }
                          }}>
                          <p style={{ color: 'white', fontSize: '18px', margin: '0' }}>Continuar</p>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className='display-form-container'>


                  <button
                    className='button-volver-a-solicitudes'
                    style={{ width: '15rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}
                    onClick={() => {
                      setOpenForm(false)
                      setStep(2)
                    }}>
                    <img src={Arrow} alt="Arrow" />
                    <p style={{ margin: '0' }}>VOLVER A FORMULARIOS</p>
                  </button>

                  <div className='form-buttons-container' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '50px' }}>
                    <button
                      style={{
                        marginTop: '4rem',
                        height: '40px',
                        display: 'flex',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                        visibility: positionIndex > firstGuide ? 'visible' : 'hidden'
                      }}
                      type='button'
                      className='new-messages-button'
                      onClick={() => {
                        const index = guide.indexOf(positionIndex)
                        const foundValue = guide[index - 1]
                        setPositionIndex(foundValue)
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '0.8rem', margin: '0', display: 'flex', gap: '10px' }}>
                        <img
                          alt="Calendar"
                          className='meet-image2'
                          src='/media/icons/app/menu/arrow-left-white.svg'
                        />
                      </p>
                    </button>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <div
                        className='badge-mth'
                        style={{
                          width: 'auto',
                          height: '26px',
                          borderRadius: '10px',
                          padding: '3px 5px',
                          gap: '6px',
                          backgroundColor: '#E1E8FF',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          alt="Calendar"
                          className='meet-image2'
                          src='/media/icons/app/menu/user-alt.svg'
                          style={{ marginRight: '6px' }}
                        />
                        <p style={{
                          margin: '0',
                          fontSize: '12px',
                          fontWeight: '700',
                          color: '#5475E5',
                        }}>{JSON.stringify(memberTypeSelected).replace(/"/g, '').toUpperCase()}</p>
                      </div>
                      <>
                        {openFormCompleted === false ? (
                          <>
                            {openDraft === true ? (
                              <div className='step-badge-active-draft2' style={{ width: '3rem' }}>
                                <p className='step-badge-active-draft-p-ones'>Borrador</p>
                              </div>
                            ) : (
                              <div className='step-badge-active2'>
                                <p className='step-badge-active-p-ones'>Pendiente</p>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                            <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Completado</p>
                          </div>
                        )}
                      </>
                    </div>

                    <button
                      style={{
                        marginTop: '4rem',
                        height: '40px',
                        display: 'flex',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                        visibility: positionIndex < lastGuide ? 'visible' : 'hidden'
                      }}
                      type='button'
                      className='new-messages-button'
                      onClick={() => {
                        const index = guide.indexOf(positionIndex)
                        const foundValue = guide[index + 1]
                        setPositionIndex(foundValue)
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '0.8rem', margin: '0', display: 'flex', gap: '10px' }}>
                        <img
                          alt="Calendar"
                          className='meet-image2'
                          src='/media/icons/app/menu/arrow-right-white.svg'
                        />
                      </p>
                    </button>
                  </div>

                  {/*  <div style={{ width: '100%', height: '1px', backgroundColor: 'rgb(223, 228, 234)', marginTop: '1rem' }}></div> */}
                  {/* <p className='badge-mth'>{JSON.stringify(memberNameSelected)}</p> */}
                  {/* {calculatingForms} {positionIndex} */}
                  {openFormCompleted === false ? (
                    <>
                      {openDraft === true ? (
                        <DisplayFormDarft
                          name={name}
                          formIndex={getIndex}
                          openForm={openForm}
                          setOpenForm={setOpenForm}
                          setBarUpdate={setbarUpdate}
                          barUpdate={barUpdate}
                          setActivePorcentageBar={setActive2}
                          setActiveRefech={setActive3}
                          productId={productId}
                          contractId={contractId}
                          formCompletedId={completedFormId}
                          setDraft={setOpenDraft}
                          setIndex={setCompletedFormId}
                          setMemberNameSelected={setMemberNameSelected}
                          memberNameSelected={memberNameSelected}
                          setMemberTypeSelected={setMemberTypeSelected}
                          memberTypeSelected={memberTypeSelected}
                          refetchForms={refetchForms}
                          refetch={refetch}
                          refetchFromDataBase={refetchFromDataBase}
                          setUploadThing={setUploadThing}
                          setPositionIndex={setPositionIndex}
                          positionIndex={positionIndex}
                          guides={guide}
                          userAllForms={userAllForms}
                        />
                      ) : (
                        <DisplayForms
                          name={name}
                          formIndex={getIndex}
                          openForm={openForm}
                          setOpenForm={setOpenForm}
                          setBarUpdate={setbarUpdate}
                          barUpdate={barUpdate}
                          setActivePorcentageBar={setActive2}
                          setActiveRefech={setActive3}
                          productId={productId}
                          contractId={contractId}
                          setIndex={setGetIndex}
                          setMemberNameSelected={setMemberNameSelected}
                          memberNameSelected={memberNameSelected}
                          setMemberTypeSelected={setMemberTypeSelected}
                          memberTypeSelected={memberTypeSelected}
                          refetchForms={refetchForms}
                          refetch={refetch}
                          refetchFromDataBase={refetchFromDataBase}
                          setUploadThing={setUploadThing}
                          userAllForms={userAllForms}
                          setPositionIndex={setPositionIndex}
                          setMaxStep={setMaxStep}
                          positionIndex={positionIndex}
                          guides={guide}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayFormCompleted
                      name={name}
                      formIndex={getIndex}
                      setFormIndex={setGetIndex}
                      openForm={openForm}
                      setOpenForm={setOpenForm}
                      openFormCompleted={openFormCompleted}
                      setOpenFormCompleted={setOpenFormCompleted}
                      setBarUpdate={setbarUpdate}
                      barUpdate={barUpdate}
                      formCompletedId={completedFormId}
                      setFormCompletedID={setCompletedFormId}
                      setActiveRefech={setActive3}
                      setActivePorcentageBar={setActive2}
                      setIndex={setGetIndex}
                      userAllForms={userAllForms}
                      setMemberTypeSelected={setMemberTypeSelected}
                      setMemberNameSelected={setMemberNameSelected}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <MyContractStep
          setShowContract={setShowContract}
          setShowContractInfo={setShowContractInfo}
          setStep={setStep}
          setContractId={contractId}
          GetMainId={GetMainId}
          maxStep={maxStep}
          setStepBar={setStepBar}
          setMaxStep={setMaxStep}
          showContractInfo={showContractInfo}
          setShowMainContainer={setShowMainContainer}
        />
      )}
      <CompletedContractsModal
        visible={isModalVisible}
        onClose={handleCancel}
        setContractsFound={setContractsFound}
        contractsFound={contractsFound}
        data={dataSelected}
        userEmail={dataSelected?.email || ''}
      />
    </div>

  )
}

export default AdditionalUserForms
