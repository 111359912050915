/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Table, Spin } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import Column from 'antd/lib/table/Column'
import { Button } from '../../../../components/Button'
import { PdfView } from '../../services/services/formsManagerClient.tsx/pdfView'
import { getAllCompletedContractsByEmail } from '../../../modules/apps/docusign/_requests'
import { type FolderItem } from '../../../modules/apps/docusign/_models'
import './style.css'
interface IProps {
  email: string
  setContractsFound: Dispatch<SetStateAction<any>>
}

const CompletedContracts = (props: IProps) => {
  const intl = useIntl()
  const { email } = props
  const { setContractsFound } = props
  const [showContract, setShowContract] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<string>('')
  const [toDate, setToDate] = useState<string>('')
  const [envelopeIdSelected, setEnvelopeIdSelected] = useState<string>()

  const { data, refetch, isLoading, isRefetching, status } = useQuery(
    'getAllContractCompletedByEmail',
    async () =>
      await getAllCompletedContractsByEmail(
        email,
        fromDate === '' ? '2023-01-01' : fromDate,
        toDate === '' ? '0000-00-00' : toDate
      ),
    {
      retry: false,
      cacheTime: 0
    }
  )

  if (status === 'success') {
    setContractsFound(data)
  }

  if (status === 'error') {
    setContractsFound(null)
  }

  useEffect(() => {
    refetch()
    console.log(email, 'se hizo el refetch')
  }, [email])

  if (isLoading) {
    return (
      <>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '1.5rem',
            marginTop: '1rem',
          }}
        >
          Contratos completados
        </div>{' '}
        <Spin tip={intl.formatMessage({ id: 'LOADING.BUTTON' })} size='large'>
          <div className='content' />
        </Spin>
      </>
    )
  }

  if (isRefetching) {
    return (
      <>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '1.5rem',
            marginTop: '1rem',
          }}
        >
          Contratos completados
        </div>{' '}
        <Spin tip={intl.formatMessage({ id: 'LOADING.BUTTON' })} size='large'>
          <div className='content' />
        </Spin>
      </>
    )
  }

  const handleOpenContract = (envelopeId: string) => {
    setShowContract(true)
    setEnvelopeIdSelected(envelopeId)
  }

  return (
    <>
      {showContract ? (
        <>
          <Button
            className='btn  btn-primary'
            style={{
              width: '8rem',
              alignSelf: 'end',
              marginRight: '2rem',
              border: 'none',
              marginBottom: '1rem',
              gap: '0.5rem',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setShowContract(false)
            }}>
            Regresar
          </Button>
          {envelopeIdSelected ? (
            <>
              {' '}
              <PdfView envelopeId={envelopeIdSelected} />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {' '}
          <h1
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              fontSize: '1.5rem',
              marginTop: '1rem',
            }}
          >
            Contratos completados
          </h1>
          <div className='datepickerStyle'>
          </div>
          <Table
            className='tableContracts'
            pagination={{
              pageSize: 100,
              showLessItems: true,
              size: 'small',
              responsive: true,
              simple: true,
              showQuickJumper: false,
              showSizeChanger: false,
            }}
            dataSource={data?.folderItems}
            rowKey='id'
          >
            <Column
              title={intl.formatMessage({ id: 'CONTRACTS.SUBJECTNAME' })}
              dataIndex='emailSubject'
              key='subject'
            />
            <Column
              title={intl.formatMessage({ id: 'CONTRACTS.CONTRACTDESCRIPTION' })}
              dataIndex='matter'
              key='matter'
            />
            <Column title='matchIdasd' dataIndex='matchId' key='matchId' />
            <Column
              title={intl.formatMessage({ id: 'CONTRACTS.COMPLETED' })}
              key='Date'
              render={(text, record: FolderItem) => (
                <Space>
                  <p> {record.completedDateTime.toString().substring(0, 10)}</p>
                </Space>
              )}
            />
            <Column
              key='id'
              render={(text, record: any) => (
                <Space>
                  <Button
                    onClick={() => {
                      handleOpenContract(record.envelopeId)
                    }}
                  >
                    &nbsp; {intl.formatMessage({ id: 'CONTRACTS.SEECONTRACT' })}
                  </Button>
                </Space>
              )}
            />
          </Table>
        </>
      )}
    </>
  )
}

export default observer(CompletedContracts)
