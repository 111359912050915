 
import React, { useState } from 'react'

interface IProps {
  image: any
  setLookImage: React.Dispatch<any>
}

const PhotoView: React.FC<IProps> = ({ image, setLookImage }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div style={{ paddingBottom: '1rem', borderBottom: '1px solid #DFE4EA' }}>
        <button
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 24px 10px 24px',
            height: 'auto',
            width: 'auto',
            marginTop: '30px',
            marginBottom: '10px',
            marginLeft: '20px'
          }}
          className='button-ui button-secondary button2'
          onClick={() => {
            setLookImage(undefined)
          }}
        >
          <img
            alt="Calendar"
            className='meet-image2'
            src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-black.svg'}
          />
          <p className='meet-text2' style={{ fontWeight: 'bold', margin: '0' }}>Volver</p>
        </button>
      </div>
      {image ? (
        <div style={{ marginBottom: '1rem', marginTop: '1rem', width:'90%' }}>
          {image &&
            <img style={{ maxWidth:'700px' }} src={image} alt='Screenshot' />}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default PhotoView
