/* eslint-disable @typescript-eslint/no-unused-vars */
import {type Staff, initialStaffInfo} from '../../../../app/modules/apps/staff/core/_models'
import {Container} from '../../../../components/Container/index'
import UserMessagesGeneral from '../UserMessagesGeneral'
import {useIntl} from 'react-intl'
import {useState} from 'react'

const GeneralMessagesWrapper = () => {
  const intl = useIntl()
  const [staff, setStaff] = useState<Staff>(initialStaffInfo)

  return (
    <Container title={intl.formatMessage({id: 'MENU.MESSAGES'})}>
      {/* <StaffSelect setStaff={() => {}} /> */}
      <UserMessagesGeneral staff={staff} />
    </Container>
  )
}

export default GeneralMessagesWrapper
