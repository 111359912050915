/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useContext} from 'react'
import {Button} from '../../../../../components'
import {RootStoreContext} from '../../../../../stores/rootStore'
import TranslationService from '../Translations'
import {getAllTranslationUserService} from '../../../../../app/modules/apps/translations/core/_requests'
import {observer} from 'mobx-react'
import {useQuery} from 'react-query'

interface IProps {
  handleClose: (key: string) => void
}

const ShowTranslations: React.FC<IProps> = ({handleClose}) => {
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const {data, status, refetch} = useQuery(
    'getalltranslationuserservice',
    async () => await getAllTranslationUserService(currentUser?.id || '')
  )

  if (status === 'loading') return <div>Loading...</div>
  if (status === 'error') return <div>Error...</div>

  return <div>{data != null && <TranslationService translationsUser={data} />}</div>
}

export default observer(ShowTranslations)
