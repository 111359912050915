/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { createOrderDetail, createOrderPaypal, createPaymentPaypal, createTask, createTranslation, createTranslationDetail, createTranslationResult, createUserPaypal, emailTranslation, findOrderByPaymentId, findOrderDetail, findPaymentByIntent, findProductByDesc, findTask, findTaskByUser, findTranslation, findUserByEmail, uploadFilesPaypal } from 'app/modules/apps/paypal/_requests'
import { observer } from 'mobx-react'
import { savePaymentsPaypal } from 'app/modules/apps/cart/core/paymentpayu/_requests'
import { useNavigate } from 'react-router-dom'
// import crypto from 'crypto';
// const bcrypt = require('bcryptjs')
//

const { PayPalScriptProvider, PayPalButtons ,FUNDING} = require('@paypal/react-paypal-js')
const {REACT_APP_PUBLIC_URL} = process.env
const CheckoutPaypal = observer(() => {
    const navigate = useNavigate()
    const [couponCode, setCouponCode] = useState('')
    const [show, setShow] = useState(true)
    const [success, setSuccess] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState('')
    const [orderID, setOrderID] = useState(false)
    const [description, setDescription] = useState('')
    const [pages, setPages] = useState(0)
    const [total, setTotal] = useState(0)
    const rootStore = useContext(RootStoreContext)
    const { user: userAUTH} = rootStore.authStore
    const { filesPaypal, setCuponPrice, cuponPrice, numberPages } = rootStore.cartStore
    const [isChecked, setIsChecked] = useState(false)


    // var salt = bcrypt.genSaltSync(10);
    // var password =
    //     "ImmiLand?" + Math.round(100 + Math.random() * (999 - 100)) + "_";
    // var encryptPassword = bcrypt.hashSync(password, salt);
    // const hashCrypto = crypto
    //     .createHash("sha256")
    //     .update(crypto.randomBytes(64).toString("hex"))
    //     .digest("hex");

    const formControlStyle = {
        boxShadow: 'none',
        borderRadius: '4px',
        border: '1px solid #c7cfd8',
        background: '#fff',
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: '1.428571429',
        color: '#555',
        display: 'block',
        width: '100%',
        height: '100px',
        margin: '0.5em 0 2em',
        position: 'relative' as const,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch' as const,
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }


    const options = {
        currency: 'CAD',
        clientId: 'AUzcIrhPaRp07g6ymfTOaReS5I9v842-zPxtELKpySCwxYdpfINyiD_BLNBLHjDmv01NuyWjrFKq_deK'
       // clientId: 'AfM-bEzghndsO10MdmCGAxmVmiBBNM4UUTjNylErOBWd_LYEf2P4-fucahkzVMrRLn-wgF5U_O2VDB_G'
    }


    const { cart } = rootStore.cartStore

    const handleInputChangeCupon = (e: any) => {
        setCouponCode(e.target.value)
        if (e.target.value === 'PREPARACIONVISAEDDY') {
            setCuponPrice(getSubtotal() * 0.3)
        } else if (e.target.value === 'EDUCATION') {
            setCuponPrice(getSubtotal() * 0.3)
        } else if (e.target.value === 'IMMILAND-VISA4CLIENT') {
            setCuponPrice(getSubtotal() * 0.1)
        } else if (e.target.value === 'EBSC') {
            setCuponPrice(getSubtotal() * 0.1)
        }
        else {
            setCuponPrice(getSubtotal() * 0)
        }
    }
    const getroduct = () => {
        return cart.reduce((acc: number, product: { product: any }) => {
            return product.product.name
        }, 0)
    }

    const getSubtotal = () => {
        return cart.reduce((acc: number, product: { product: { price: number }; quantity: number }) => {
            return acc + product.product.price * product.quantity
        }, 0)
    }
    const getTax = () => {
        return getSubtotal() * 0.0
    }
    const getTotalFunction = () => {
        return getSubtotal() + getTax()
    }


    useEffect(() => {
        setDescription(getroduct().toString())
        setPages(Number(localStorage.getItem("pages")))
        setTotal(getTotalFunction())
    }, [])

    useEffect(() => {
        localStorage.removeItem('dispay');
        localStorage.setItem("dispay", JSON.stringify(cuponPrice))
    }, [cuponPrice])

    // creates a paypal order
    const createOrder = (data: any, actions: any) => {
        return actions.order
            .create({
                intent: "CAPTURE",
                application_context: {
                    return_url: "https://www.immilandcanada.com/traduccion"
                },
                purchase_units: [
                    {
                        description: description,
                        amount: {
                            currency_code: 'CAD',
                            value: total - Number(localStorage.getItem("dispay")),
                        },
                    },
                ],
            })
            .then((orderID: any) => {
                setOrderID(orderID)
                return orderID
            })
    }


    // check Approval
    const onApprove = (data: any, actions: any) => {
        return actions.order.capture().then(async function (details: any) {
            localStorage.removeItem('dispay');
            const { payer } = details
            const paymentIntentId = details.id
            const description = details.purchase_units[0].description
            const totalPurchase = details.purchase_units[0].amount.value
            /* payer.email = payer.email_address */
            let user: any
           /*  user = await findUserByEmail(payer.email)
            if (!user) {
                user = await createUserPaypal(payer.email, payer.name.given_name, payer.name.surname)
            } */

            /* const userId = user.id */
            const userId=userAUTH?.id
            const paymentData = {
                user: userId,
                paymentIntentId: paymentIntentId,
                description: description,
                code:paymentIntentId,
                clientSecret:paymentIntentId,
                paymentMethod:'paypal',
                application:'paypal',
                invoice:paymentIntentId
            }
            /* await createPaymentPaypal(paymentData); */

            const payment =  await savePaymentsPaypal(paymentData)
           /* const payment = await findPaymentByIntent(paymentIntentId) */
            const paymentId = payment.id
            const task = await findTask(description)
            const orderData = {
                receipt_link: 'paypal',
                total: totalPurchase,
                user_id: userId,
                payment_id: paymentId,
                payment_paypal_id:paymentIntentId
            }
            await createOrderPaypal(orderData)
            const order = await findOrderByPaymentId(paymentId)
            const orderId = order.id
            //busca por nommbre del producto
            const product = await findProductByDesc(description)
            const productId = product.id
            const orderDetailData = {
                total: totalPurchase,
                order_id: orderId,
                product_id: productId
            }
             await createOrderDetail(orderDetailData)
            const orderDetail = await findOrderDetail(orderId)
            const taskData = {
                user_id: userId,
                task_id: task,
                order_detail_id: orderDetail.id,
                order_id:orderId
            }
            await createTask(taskData)
            //sebusca por order detail
            const taskUser = await findTaskByUser(orderDetail.id)
            if (taskUser) {
                const translationData = {
                    userId: userId,
                    statustag: 1,
                    taskUser: taskUser.id,
                    task: task,
                    pages: numberPages
                }
                const fileResponse: any = await uploadFilesPaypal(filesPaypal, () => { })
              const tra=  await createTranslation(translationData)
                const translation = await findTranslation(taskUser.id)
                if(tra){
                const translationDetailData = {
                    taskId: task,
                    fileId: fileResponse[0].id,
                    translationId: tra.id
                }
                 await  createTranslationDetail(translationDetailData)
                const translationResultData = {
                    userId: userId,
                    taskId: task,
                    translationId: tra.id
                }
                await createTranslationResult(translationResultData)
                const email=userAUTH?.email
                await  emailTranslation(email)
            }
                setSuccess(true)
            }

        })
    }

    //capture likely error
    const onError = (data: any, actions: any) => {
        localStorage.removeItem('dispay');
        setErrorMessage('An Error occured with your payment ')
    }

    useEffect(() => {
        if (success) {
            navigate(`${REACT_APP_PUBLIC_URL}/embed/store/payment/sucesswo`)
        }
    }, [success])

    return (
        <div style={{width:'290px'}}>
            {/* <section className=''>
                <legend className='text-md'>Cupón</legend>
                <input
                    type='text'
                    value={couponCode}
                    onChange={handleInputChangeCupon}
                    placeholder='Ingresa Cupón'
                    className='custom-input-payment-coupon-paypal'
                    style={{ borderRadius: '4px', width: '200px' }}
                />
            </section> */}
             <div style={{backgroundColor:'white',padding:'16px',marginTop:'10px', borderRadius:'10px', gap:'16px'}}>   <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}> <img
      src= '/media/svg/menu-icons/paypal.svg'
      alt='Logo de Visa'
      style={{ width: '31px', height: '25px', marginBottom: '5px' }}
    /> <strong style={{ display: 'flex', fontWeight: '700', fontSize: '14px', textAlign:'justify', marginBottom:'10px' }}>Haga click en el botón para ingresar a su cuenta Paypal</strong></div>
            <PayPalScriptProvider options={options}>

                <div>
                    {show ? (
                        <PayPalButtons
                            style={{ layout: 'vertical',label:'paypal' }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                            fundingSource={FUNDING.PAYPAL}
                        />
                    ) : null}
                </div>
                {/* <div className='checkoutform2' style={{ textAlign: 'left' }}>
                    <label>
                        <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
                        <span className='ms-2'>
                            He leído y acepto los términos y condiciones de esta página de la siguiente manera:
                        </span>
                    </label>
                </div>
                <div className='formControlStyle3'>
                    <div>
                        <p style={{ textAlign: 'center' }}>
                            <strong>INMILAND CANADA INC, Terms and Conditions Translations</strong>
                        </p>
                        <ol>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Modalidad de servicio:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que nuestros servicios de traducción son realizados por traductores
                            humanos, cada uno con su estilo y enfoque personal en la traducción. Nos esforzamos
                            por proporcionar traducciones precisas y fieles al original, pero ten en cuenta que
                            el estilo de traducción puede variar ligeramente entre diferentes traductores.
                        </p>
                        <ol start={2}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Alcance de servicio </strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que este servicio es de traducción regular. Si requieres servicio especial
                            para traducir mi licencia de conducir, NO debo comprar este servicio y debe
                            devolverse al menú principal de productos y seleccionar el adecuado.&nbsp;
                        </p>
                        <ol start={3}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Política de cancelación</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que al momento de la compra, si existe algún documento que compré pero que
                            ya no necesito traducir, Immiland no realiza reembolsos, pero si podrá dar uso de
                            esa página para reemplazarla por otra que sí requiera traducción. Immiland respeta
                            su crédito para intercambiarlo por otra página por un lapso de tiempo de 2 meses
                            posteriores a la compra.&nbsp;
                        </p>
                        <ol start={4}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Revisión y retroalimentación:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que una vez entregados los documentos traducidos, debo revisarlos
                            cuidadosamente para asegurarse de que cumplan con mis expectativas. Si encuentro
                            algún error o necesito realizar cambios, debo informar al traductor. Estaremos
                            encantados de corregir cualquier error y adaptar la traducción a tus requerimientos
                            específicos.
                        </p>
                        <ol start={5}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Tiempo de entrega:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Nuestros tiempos de entrega estimados son de 1 a 5 días hábiles para servicios
                            express y de 5 a 7 días hábiles para servicios normales. Sin embargo, ten en cuenta
                            que el tiempo de entrega puede variar según la disponibilidad de los traductores y
                            la complejidad de los documentos. Luego de la compra, podrás acceder a la plataforma
                            de Immiland para chequear los tiempos. Te recomendamos contactarnos para obtener una
                            estimación precisa en base a tus necesidades.
                        </p>

                        <ol start={6}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Entrega de documentación</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Los documentos se entregan en copias digitales por la plataforma de Immiland al
                            cliente. El servicio no incluye envíos de traducciones físicas.&nbsp;
                        </p>
                        <ol start={7}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Calidad del documento:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            La calidad del documento original enviado puede afectar la precisión y el estilo de
                            nuestras traducciones. Te recomendamos proporcionar documentos claros, legibles y
                            con una buena calidad de resolución para obtener mejores resultados. Además, si
                            tienes preferencias específicas de estilo o terminología, por favor indícanos al
                            realizar tu solicitud.
                        </p>
                        <ol start={8}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Confidencialidad:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Valoramos la confidencialidad y nos comprometemos a proteger la información que nos
                            proporcionas durante el proceso de traducción. A continuación, se detallan nuestras
                            políticas y prácticas relacionadas con la confidencialidad y la protección de la
                            información:&nbsp;
                        </p>
                        <p>a. Privacidad y Seguridad:</p>
                        <p>
                            Tomamos medidas para garantizar la privacidad y seguridad de tus documentos y datos
                            personales. Implementamos procedimientos físicos, electrónicos y administrativos
                            para proteger la información contra accesos no autorizados, divulgación o
                            alteración.
                        </p>
                        <p>b. Uso Limitado de la Información:</p>
                        <p>
                            Utilizamos la información proporcionada exclusivamente con el propósito de llevar a
                            cabo los servicios de traducción solicitados. No compartiremos, venderemos ni
                            alquilaremos tu información personal a terceros sin tu consentimiento explícito, a
                            menos que estemos legalmente obligados a hacerlo.
                        </p>
                        <p>c. Acuerdos de Confidencialidad:</p>
                        <p>
                            Nuestros traductores y miembros del equipo están sujetos a acuerdos de
                            confidencialidad que salvaguardan la información que manejan durante el proceso de
                            traducción. Estos acuerdos garantizan que los traductores mantengan la
                            confidencialidad de los documentos y la información proporcionada por los clientes.
                        </p>
                        <p>d. Eliminación Segura de Datos:</p>
                        <p>
                            Una vez que se ha completado el proceso de traducción y entregado el trabajo final
                            al cliente, eliminaremos de manera segura todos los documentos y datos relacionados
                            con la traducción, a menos que se haya acordado lo contrario con el cliente.
                        </p>
                        <p>e. Comunicación Segura:</p>
                        <p>
                            Utilizamos métodos seguros de comunicación para el intercambio de documentos y
                            cualquier otra información relacionada con la traducción. Estos métodos pueden
                            incluir el cifrado de archivos y el uso de sistemas seguros de gestión de
                            documentos.
                        </p>
                        <p>f. Responsabilidad Compartida:</p>
                        <p>
                            Si bien nos esforzamos por proteger la información confidencial, es importante
                            destacar que la seguridad en línea y la confidencialidad de los datos son
                            responsabilidades compartidas. Te recomendamos tomar precauciones adicionales al
                            compartir información confidencial a través de Internet y mantener actualizados tus
                            sistemas de seguridad informática.
                        </p>
                        <p>g. Cumplimiento Legal:</p>
                        <p>
                            Cumplimos con todas las leyes y regulaciones aplicables en relación con la
                            confidencialidad y la protección de datos. En el caso de cualquier cambio en las
                            leyes o regulaciones pertinentes, ajustaremos nuestras prácticas para garantizar el
                            cumplimiento continuo.
                        </p>
                        <p>
                            Ten en cuenta que este descargo de responsabilidad proporciona una visión general de
                            nuestras políticas y prácticas de confidencialidad. Para obtener información más
                            detallada sobre cómo manejamos y protegemos la información confidencial, te
                            recomendamos revisar nuestra política de privacidad y solicitar cualquier aclaración
                            adicional que puedas necesitar.
                        </p>
                        <ol start={9}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Derechos de autor:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Al utilizar nuestros servicios de traducción, confirmas que tienes los derechos
                            necesarios sobre los documentos a traducir y que no infringes los derechos de autor
                            de terceros. No nos responsabilizamos por reclamaciones legales relacionadas con los
                            derechos de autor de los documentos proporcionados.
                        </p>
                        <ol start={10}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Modificaciones en los términos:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Nos reservamos el derecho de realizar modificaciones en estos términos y condiciones
                            en cualquier momento sin previo aviso. Los cambios entrarán en vigor a partir de su
                            publicación en nuestro sitio web.
                        </p>
                        <p>
                            <strong>
                                Descargo de responsabilidad para servicios de traducción licencia conducir
                                (Disclaimer)
                            </strong>
                        </p>
                        <ol start={11}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Modalidad de servicio:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que nuestros servicios de traducción son realizados por traductores
                            humanos, cada uno con su estilo y enfoque personal en la traducción. Nos esforzamos
                            por proporcionar traducciones precisas y fieles al original, pero ten en cuenta que
                            el estilo de traducción puede variar ligeramente entre diferentes traductores.
                        </p>
                        <ol start={12}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Alcance de servicio </strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que este servicio NO es de traducción regular y solo aplica para licencias
                            de conducir.&nbsp;
                        </p>

                        <ol start={13}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Política de cancelación</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que al momento de la compra, si existe algún documento que compré pero que
                            ya no necesito traducir, Immiland no realiza reembolsos, pero si podrá dar uso de
                            esa página para reemplazarla por otra que si requiera traducción. Immiland respeta
                            su crédito para intercambiarlo por otra página por un lapso de tiempo de 2 meses
                            posteriores a la compra.
                        </p>
                        <br />
                        <ol start={14}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Revisión y retroalimentación:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Entiendo que una vez entregados los documentos traducidos, debo revisarlos
                            cuidadosamente para asegurarse de que cumplan con mis expectativas. Si encuentro
                            algún error o necesito realizar cambios, debo informar al traductor. Estaremos
                            encantados de corregir cualquier error y adaptar la traducción a tus requerimientos
                            específicos.
                        </p>
                        <br />
                        <ol start={15}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Tiempo de entrega:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Nuestros tiempos de entrega estimados son de 1 a 5 días hábiles para servicios
                            express y de 5 a 7 días hábiles para servicios normales. Sin embargo, ten en cuenta
                            que el tiempo de entrega puede variar según la disponibilidad de los traductores y
                            la complejidad de los documentos. Luego de la compra, podrás acceder a la plataforma
                            de Immiland para chequear los tiempos. Te recomendamos contactarnos para obtener una
                            estimación precisa en base a tus necesidades.
                        </p>
                        <br />
                        <ol start={16}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Entrega de documentación</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Los documentos se entregan en copias digitales por la plataforma de Immiland al
                            cliente. El servicio no incluye envíos de traducciones físicas.
                        </p>
                        <br />
                        <ol start={17}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Calidad del documento:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            La calidad del documento original enviado puede afectar la precisión y el estilo de
                            nuestras traducciones. Te recomendamos proporcionar documentos claros, legibles y
                            con una buena calidad de resolución para obtener mejores resultados. Además, si
                            tienes preferencias específicas de estilo o terminología, por favor indícanos al
                            realizar tu solicitud.
                        </p>
                        <br />
                        <ol start={18}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Confidencialidad:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Valoramos la confidencialidad y nos comprometemos a proteger la información que nos
                            proporcionas durante el proceso de traducción. A continuación, se detallan nuestras
                            políticas y prácticas relacionadas con la confidencialidad y la protección de la
                            información.
                        </p>
                        <br />
                        <p>a. Privacidad y Seguridad:</p>
                        <p>
                            Tomamos medidas para garantizar la privacidad y seguridad de tus documentos y datos
                            personales. Implementamos procedimientos físicos, electrónicos y administrativos
                            para proteger la información contra accesos no autorizados, divulgación o
                            alteración.
                        </p>
                        <br />
                        <p>b. Uso Limitado de la Información:</p>
                        <p>
                            Utilizamos la información proporcionada exclusivamente con el propósito de llevar a
                            cabo los servicios de traducción solicitados. No compartiremos, venderemos ni
                            alquilaremos tu información personal a terceros sin tu consentimiento explícito, a
                            menos que estemos legalmente obligados a hacerlo.
                        </p>
                        <br />
                        <p>c. Acuerdos de Confidencialidad:</p>
                        <p>
                            Nuestros traductores y miembros del equipo están sujetos a acuerdos de
                            confidencialidad que salvaguardan la información que manejan durante el proceso de
                            traducción. Estos acuerdos garantizan que los traductores mantengan la
                            confidencialidad de los documentos y la información proporcionada por los clientes.
                        </p>
                        <br />
                        <p>d. Eliminación Segura de Datos:</p>
                        <p>
                            Una vez que se ha completado el proceso de traducción y entregado el trabajo final
                            al cliente, eliminaremos de manera segura todos los documentos y datos relacionados
                            con la traducción, a menos que se haya acordado lo contrario con el cliente.
                        </p>
                        <br />
                        <p>e. Comunicación Segura:</p>
                        <p>
                            Utilizamos métodos seguros de comunicación para el intercambio de documentos y
                            cualquier otra información relacionada con la traducción. Estos métodos pueden
                            incluir el cifrado de archivos y el uso de sistemas seguros de gestión de
                            documentos.
                        </p>
                        <br />
                        <p>f. Responsabilidad Compartida:</p>
                        <p>
                            Si bien nos esforzamos por proteger la información confidencial, es importante
                            destacar que la seguridad en línea y la confidencialidad de los datos son
                            responsabilidades compartidas. Te recomendamos tomar precauciones adicionales al
                            compartir información confidencial a través de Internet y mantener actualizados tus
                            sistemas de seguridad informática.
                        </p>
                        <br />
                        <p>g. Cumplimiento Legal:</p>
                        <p>
                            Cumplimos con todas las leyes y regulaciones aplicables en relación con la
                            confidencialidad y la protección de datos. En el caso de cualquier cambio en las
                            leyes o regulaciones pertinentes, ajustaremos nuestras prácticas para garantizar el
                            cumplimiento continuo.
                        </p>
                        <br />
                        <p>
                            Ten en cuenta que este descargo de responsabilidad proporciona una visión general de
                            nuestras políticas y prácticas de confidencialidad. Para obtener información más
                            detallada sobre cómo manejamos y protegemos la información confidencial, te
                            recomendamos revisar nuestra política de privacidad y solicitar cualquier aclaración
                            adicional que puedas necesitar.
                        </p>
                        <br />
                        <ol start={19}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Derechos de autor:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Al utilizar nuestros servicios de traducción, confirmas que tienes los derechos
                            necesarios sobre los documentos a traducir y que no infringes los derechos de autor
                            de terceros. No nos responsabilizamos por reclamaciones legales relacionadas con los
                            derechos de autor de los documentos proporcionados.
                        </p>
                        <br />
                        <ol start={20}>
                            <li style={{ fontWeight: 'bold' }}>
                                <p>
                                    <strong>Modificaciones en los términos:</strong>
                                </p>
                            </li>
                        </ol>
                        <p>
                            Nos reservamos el derecho de realizar modificaciones en estos términos y condiciones
                            en cualquier momento sin previo aviso. Los cambios entrarán en vigor a partir de su
                            publicación en nuestro sitio web.
                        </p>
                        <br />
                        <br />
                    </div>
                </div> */}
            </PayPalScriptProvider> <div style={{width:'100%', height:'1px', backgroundColor:'#BDBDBD', marginTop:'15px'}}></div></div>
        </div>
    )
}
)

export default CheckoutPaypal
