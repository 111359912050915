/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react"

interface IProps {
  productId: string[]
  setDepartment: Dispatch<SetStateAction<string>>
}

const SetVisaDepartament : React.FC<IProps> = ({productId, setDepartment}) => {
  const [department, setCurrentDepartment] = useState ('')
  const visitProducts = [
    'bb4056fb-2440-4a06-aec1-8d13349e7427', 'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97',
    'fd5b2b7d-8772-4ee6-91cc-53f710351021', '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const residenceProducts = [
    'c7571404-31f4-45b3-898a-160d4e72afc5', '9665d0d1-c145-4dc6-be9f-d4c69006604c', '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '2a7387ab-c1dc-45f1-a6b3-3f22c0fc30e5', 'f455901e-a97e-4c2d-abd4-8a359778e88a',
    '068de1e8-3074-4259-8a2a-1418d8f39ca3', '87bcc196-0f68-421b-9591-4e3565d69783', '728d916e-47fe-48ae-9628-468712651938',
    '0c9f691d-2b23-4c7d-8419-7f55785418f6', 'fb1a576c-f5a1-4d72-9aa8-d2f8eb5fa34d', '1de57333-82cf-4aaa-8a9b-23fadab35a3f',
    '7b84fba5-c4a9-48bc-ac44-f84a97db6ea2', 'bdda48a1-ea4d-480d-9e2c-e14e7904dd27', '054e28db-5fa8-487b-a452-38b94392f1ce',
    'cd90d231-265a-4be0-9982-54cf3aee6537', '346454f5-f81f-4a37-abc6-8e6d17f26512', '29c268c2-b5a9-4888-82c9-a209b5e20962',
    'a9b2deee-cb6f-4aa0-a521-8ecc02d32394', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', 'ee8c9b93-151c-40ce-86ca-8f8f2aa3a644',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3', '4762b784-650d-429b-b48d-02c351f34085'
  ]

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', 'c263052c-80cf-4fc3-a628-88e51621f4e3',
    '6420335e-30aa-46c5-892b-e1fa23914733', '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac',
    '229d7b91-7a9b-407a-988b-9e45096bff5b', 'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a', '8a29f12d-a898-4d52-8fd1-f184094cd8c3', '65245c09-8c4d-485a-abaa-8f752d4dfe39',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '588bed15-e8fb-4216-8580-6de93f1512ae', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]

  const setVisitor = () => {
    visitProducts.map((prod) => {
      productId.map((product) => {
      if(prod === product){
        setCurrentDepartment('Division de visitantes')
        setDepartment('Division de visitantes')
      }})
    })
  }

  const setStudy = () => {
    studyProducts.map((prod) => {
      productId.map((product) => {
        if(prod === product){
        setCurrentDepartment('Division de estudios')
        setDepartment('Division de estudios')
      }})
    })
  }

  const setWork = () => {
    workProducts.map((prod) => {
      productId.map((product) => {
        if(prod === product){
        setCurrentDepartment('Division de trabajo')
        setDepartment('Division de trabajo')
      }})
    })
  }

  const setInmigration = () => {
    residenceProducts.map((prod) => {
      productId.map((product) => {
        if(prod === product){
        setCurrentDepartment('Division de inmigración')
        setDepartment('Division de inmigración')
      }})
    })
  }

  useEffect(() => {
    setVisitor()
    setStudy()
    setWork()
    setInmigration()
  },[])

  useEffect(() => {
    setVisitor()
    setStudy()
    setWork()
    setInmigration()
  },[department, setInmigration, setStudy, setVisitor, setWork])

  return(<>{department}</>)
}

export default SetVisaDepartament
