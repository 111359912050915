/* eslint-disable */
import {
  CategoryItem,
  CategoryItemList,
  CategoryItemTitle,
  Inbox,
  LeftSideBar,
  Wrapper,
} from '../../../../core/components/email'
import { type IReply, initialReplyData } from '../../../../app/modules/apps/appointments/core/_models'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { BsInboxFill } from 'react-icons/bs'
import { IoIosDocument } from 'react-icons/io'
import ListMessages from './ListMessages'
import ListMessagesSearch from './ListMessagesSearch'
import MessageContent from '../MessageContent'
import { type Messages } from '../../../../app/modules/apps/messages/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import SearchMessages from './SearchMessages'
import ShowMessages from './ShowMessages'
import { type Staff } from '../../../../app/modules/apps/staff/core/_models'
import { Tabs } from 'antd'
import { observer } from 'mobx-react'

interface Props {
  data?: string
  staff: Staff
}

const { TabPane } = Tabs

const UserMessagesGeneral: React.FC<Props> = ({ staff }) => {
  const rootStore = useContext(RootStoreContext)
  const { loadMessagesByConsultor, loadMessagesUnreadGeneral, messagesUnreadGeneral } =
    rootStore.messageStore

  const [showmessage, setShowMessage] = useState(false)
  const [replyMessage, setReplyMessage] = useState(false)
  const [list, setlist] = useState(true)
  const [folder, setFolder] = useState('inbox')
  const [messagesSeacrh, setMessagesSeacrh] = useState<Messages[] | null>(null)
  const [clearSearch, setClearSearch] = useState(false)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [composepostdata, setComposepostdata] = useState<IReply>(initialReplyData)

  const [messageId, setMessageId] = useState('')


  useEffect(() => {
    handleSearchMessages('inbox')
    if (list) loadMessagesUnreadGeneral(staff)
  }, [list])

  const handleClose = () => {
    setReplyMessage(false)
    setlist(true)
  }

  const handleCloseShowMessage = () => {
    setReplyMessage(false)
    setShowMessage(false)
    setlist(true)
  }

  const handleSearchMessages = (value: string) => {
    setFolder(value)
    loadMessagesByConsultor(value, staff)
    loadMessagesUnreadGeneral(staff)
  }

  const countUnread = (folder: string) => {
    let count = 0
    messagesUnreadGeneral.forEach((message) => {
      if (message.folder === folder) {
        count = message.count
      }
    })
    return count
  }

  return (
    <div className='flex'>
      <>
        <Wrapper
          style={screen.width < 1024 && showmessage ? { width: '0%' } : {}}
          className=' content-start sm:w-full lg:w-1/3 p-0 '
        >
          {showMessageRows ? (
            <>
              <LeftSideBar className='col-lg-12 p-0'>
                <SearchMessages setMessagesSeacrh={setMessagesSeacrh} clearSearch={clearSearch} />
                <CategoryItemList className='flex ml-2' style={{ gap: '3px' }}>
                  <CategoryItem
                    className={folder === 'inbox' ? 'bg-rosa-500  hover:bg-rosa-300' : ''}
                    onClick={() => {
                      handleSearchMessages('inbox')
                    }}
                    style={{ width: '30%' }}
                  >
                    <div className='flex justify-center items-center my-auto'>
                      <BsInboxFill style={{ width: '12px', marginRight: '3px' }} />
                      <CategoryItemTitle style={{ width: '12px' }}>Recibidos</CategoryItemTitle>
                    </div>
                    {/*  {countUnread('inbox') > 0 ? (
                      <span className='text-right flex bg-tomato-500 rounded-full  text-white px-1 justify-center items-center m-auto'>
                        {countUnread('inbox')}{' '}
                      </span>
                    ) : null} */}
                  </CategoryItem>
                  <CategoryItem
                    className={folder === 'sent' ? 'bg-rosa-500 hover:bg-rosa-300' : ''}
                    onClick={() => {
                      handleSearchMessages('sent')
                    }}
                    style={{ width: '30%' }}
                  >
                    <div className='flex justify-center items-center my-auto'>
                      <AiOutlineSend style={{ width: '12px', marginRight: '3px' }} />
                      <CategoryItemTitle style={{ width: '12px' }}>Enviados</CategoryItemTitle>
                    </div>
                  </CategoryItem>
                  <CategoryItem
                    className={folder === 'draft' ? 'bg-rosa-500 hover:bg-rosa-300  ' : ''}
                    onClick={() => {
                      handleSearchMessages('draft')
                    }}
                    style={{ width: '30%' }}
                  >
                    <div className='flex justify-center items-center my-auto'>
                      <IoIosDocument style={{ width: '12px', marginRight: '3px' }} />
                      <CategoryItemTitle style={{ width: '12px' }}>Borradores</CategoryItemTitle>
                    </div>
                  </CategoryItem>
                </CategoryItemList>
              </LeftSideBar>

              <Inbox className='col-lg-12'>
                {messagesSeacrh != null ? (
                  <ListMessagesSearch
                    setMessageId={setMessageId}
                    setShowMessage={setShowMessage}
                    setReplyMessage={setReplyMessage}
                    setComposepostdata={setComposepostdata}
                    setlist={setlist}
                    folder={folder}
                    messages={messagesSeacrh}
                    setMessagesSeacrh={setMessagesSeacrh}
                    setClearSearch={setClearSearch}
                    handleClose={handleClose}
                    setShowMessageRows={setShowMessageRows}
                  />
                ) : (
                  <ListMessages
                    setMessageId={setMessageId}
                    callRefetch={false}
                    setShowMessage={setShowMessage}
                    setReplyMessage={setReplyMessage}
                    setComposepostdata={setComposepostdata}
                    setlist={setlist}
                    folder={folder}
                    handleClose={handleClose}
                    setShowMessageRows={setShowMessageRows}
                  />
                )}
              </Inbox>
            </>
          ) : null}
        </Wrapper>

        <div
          style={screen.width < 1024 && showmessage ? { width: '100%' } : {}}
          className='lg:flex  lg:flex-wrap lg:items-start lg:overflow-hidden lg:divide-x lg:border lg:rounded-md lg:shadow-sm  lg:w-3/4 lg:p-0 lg:ml-3'
        >
          {showmessage ? (
            <>
              <ShowMessages
                callRefetch={false}
                messageId={messageId}
                setShowMessage={setShowMessage}
                handleClose={handleCloseShowMessage}
                setComposepostdata={setComposepostdata}
                setlist={setlist}
                setReplyMessage={setReplyMessage}
                setShowMessageRows={setShowMessageRows}
              />
            </>
          ) : null}

          {replyMessage ? (
            <>
              <MessageContent
                setShowMessage={setShowMessage}
                setShowMessageRows={setShowMessageRows}
                setReplyMessage={setReplyMessage}
                composepostdata={composepostdata}
                showSelectFiles={false}
                showShareFiles={false}
                initialContent={composepostdata.content}
                showTemplateSearch={false}
                initialSubject={composepostdata.subject}
                type={composepostdata.type}
                cc={[]}
                handleClose={handleClose}
                showButtonClose={true}
                category=''
                setComposeData={setComposepostdata}
              />
            </>
          ) : null}
        </div>
      </>
    </div>
  )
}

export default observer(UserMessagesGeneral)
