/* eslint-disable @typescript-eslint/no-unused-vars */
 

import React, {type Dispatch, type SetStateAction, useEffect} from 'react'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import {useQuery} from 'react-query'
import {useIntl} from 'react-intl'
import {getForms} from '../../../../../app/modules/apps/forms/core/_requests'

interface IProps {
  setactivateRestOfTheTabs: Dispatch<SetStateAction<boolean>>
}
const SurveyImportantInfo: React.FC<IProps> = ({setactivateRestOfTheTabs}) => {
  const intl = useIntl()
  const {
    data: dataResult,
    status,
    refetch,
    isSuccess,
    isLoading,
  } = useQuery('listformsclientssAutso', async () => await getForms('limit=50&page=1&name='))
  const IsFinished = async (questions: any) => {
    setactivateRestOfTheTabs(true)
  }
  useEffect(() => {
    Survey.StylesManager.applyTheme('defaultV2')
  }, [])
  if (dataResult != null && dataResult[0] !== undefined) {
    const json = dataResult.filter((form) => form.category === 'Informacion importante')
    return (
      <>
        <Survey.Survey
          //  currentPageNo={}
          sendResultOnPageNext={true}
          // onPartialSend={sendData}
          // data={answersSoFar}
          json={json[0].data}
          onComplete={IsFinished}
          // showProgressBar={'top'}
          //  locale={currentLang}
          pageNextText={intl.formatMessage({id: 'PAGE.NEXT'})}
          pagePrevText={intl.formatMessage({id: 'PAGE.PREVIOUS'})}
          completeText={intl.formatMessage({id: 'PAGE.COMPLETEPAGE'})}
          // onCurrentPageChanging={onCurrentPageChanging}
          completedHtml={intl.formatMessage({id: 'PAGE.FINISHIMPORTANTPAGE'})}
        />
      </>
    )
  } else {
    return <></>
  }
}

export {SurveyImportantInfo}
