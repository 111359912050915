/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import * as Survey from 'survey-react'
import { Button, PageHeader, Space, Form, Checkbox, DatePicker, Select } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { CheckListWrapper } from '../../../../app/pages/checkList/checkListWrapper'
import { type Forms } from '../../../../app/modules/apps/forms/core/_models'
import { ListForms } from './ListForms'
import Swal from 'sweetalert2'
import { getFormsNew, saveForms } from '../../../../app/modules/apps/forms/core/_requests'
import { useIntl } from 'react-intl'
import {
  IFamilys,
  IQuestions,
} from 'app/pages/contratationApp/newUserViewContratationApp/Forms/answer'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CountrySelect } from 'app/modules/utils/components/CountrySelect'
import { useQuery } from 'react-query'
import { Link } from 'components'
import { getAllDocuments } from 'app/modules/apps/userDocument/_requests'
import EditAplicationByUsers from 'app/pages/admin/products/ProductManager/EditAplicationByUsers'
import PostalCodeSearch from 'app/pages/contratationApp/newUserViewContratationApp/Forms/postaCodeFilter'
import { CitySelect } from 'app/modules/utils/components/CanadaCitiesSelect/Index'

export interface Props {
  data?: IFamilys
}

const initialData: Forms = {
  id: '',
  name: '',
  description: '',
  category: '',
  data: '',
}

const yeisonBuilder: IFamilys = {
  questions: [],
  formName: '',
  guide: '',
}

const clean: IFamilys = {
  questions: [],
  formName: '',
  guide: '',
}

const questionBuilder: IQuestions = {
  index: '',
  questionId: 0,
  conditionId: '',
  date: '',
  info: '',
  type: '',
  image: '',
  video: '',
  option: [],
  choices: [],
  question: '',
  validate: true,
  validateAnswer: 'Campo Requerido',
  completed: false,
  answer: '',
  englishAnswer: '',
  frenchAnswer: '',
  multipleAnswer: [],
  conditionalQuestion: '',
  conditional: '',
  dependent: false,
  link: '',
  activeInfo: false,
  docsAttached: [],
  hide: 0,
  translate: false,
  mustBeCurrent: false,
  mustBeNotCurrent: false,
  fromMustBeCurrent: false,
}

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}
const FormsManager: React.FC<Props> = (props) => {
  const intl = useIntl()
  const [icon, setIcon] = useState(<PlusOutlined />)
  const [formName, setFormName] = useState('')
  const [formCategory, setFormCategory] = useState('')
  const [formDescription, setFormDescription] = useState('')
  const [formData, setFormData] = useState('')
  const [formSelected, setFormSelected] = useState<boolean | null>(false)
  const [isEditing, setIsEditing] = useState<boolean | null>(false)
  const [addingNewForm, setAddingNewForm] = useState<boolean | null>(false)
  const [formIdSelected, setFormIdSelected] = useState<string | null>(null)
  const [formDataSelected, setFormDataSelected] = useState<Forms | null>(null)
  const [newquestion, setNewquestion] = useState<IQuestions>(questionBuilder)
  const [finalForm, setFinalForm] = useState<IFamilys>(yeisonBuilder)
  const [optionList, setOptionList] = useState<boolean>(false)
  const [choiceList, setChoiceList] = useState<boolean>(false)
  const [videoList, setVideoList] = useState<boolean>(false)
  const [imageList, setImageList] = useState<boolean>(false)
  const [linkList, setLinkList] = useState<boolean>(false)
  const [readForm, setReadForm] = useState<number>(0)
  const [inputOptionValue, setInputOptionValue] = useState('')
  const [inputChoiceValue, setInputChoiceValue] = useState('')
  const [readpopup, setReadpopup] = useState<number>(0)
  const [inputQuestionValue, setInputQuestionValue] = useState('')
  const [inputInfoValue, setInputInfoValue] = useState('')
  const [hideQuestionDependent, setHideQuestionDependent] = useState<number>(0)
  const [inputDependentValue, setInputDependentValue] = useState('')
  const [dependent, setDependent] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [spawnQuestion, setSpawnQuestion] = useState(0)
  const [updateoptions, setUpdateoptions] = useState(0)
  const [numberQuestion, setNumberQuestion] = useState(0)
  const [editMode, setEditMode] = useState(false)
  const [updateQuestion, setUpdateQuestion] = useState(0)
  const [editMode2, setEditMode2] = useState(false)
  const provincesL = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NU', 'ON', 'QC', 'SK', 'YT', 'PE', 'NT']
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: {
      docs: string[]
      trigger: string
    }
  }>({})
  const [newOption, setNewOption] = useState('')
  const [questionIndex, setQuestionIndex] = useState(0)
  const [dependentEditor, setDependentEditor] = useState(false)
  const [conditionalQuestion, setConditionalQuestion] = useState('')
  const [conditionalAnswer, setConditionalAnswer] = useState('')
  const [conditionalId, setConditionalId] = useState('')
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)
  const [message, setMessage] = useState(false)
  const [mustBeCurrent, setMustBeCurrent] = useState(false)
  const [mustBeNotCurrent, setMustBeNotCurrent] = useState(false)
  const [fromMustBeCurrent, setFromMustBeCurrent] = useState(false)

  const { data, status, isLoading } = useQuery(
    'form',
    async () => await getFormsNew('limit=50&page=1&name='),
    { retry: false, cacheTime: 0 }
  )

  const { data: docData } = useQuery('allDocuments', async () => await getAllDocuments())

  if (status === 'success' && active2 === false) {
    const guides = data.data.data.map((item: any) => item.data.guide)
    const highestNumber = guides.reduce((acc: any, guide: any) => {
      const currentNumber = parseInt(guide, 10)
      return currentNumber > acc ? currentNumber : acc
    }, 0)
    finalForm.guide = (highestNumber + 1).toString().padStart(3, '0')
    setActive2(true)
  }

  function findHighestIndex(questions: any) {
    let highestIndex = 0
    questions.forEach((question: any) => {
      const currentIndex = parseInt(question.index, 10)
      if (!isNaN(currentIndex) && currentIndex > highestIndex) {
        highestIndex = currentIndex
      }
    })
    return highestIndex
  }

  function IndexGenerator() {
    const highestIndex = findHighestIndex(finalForm.questions)
    const nextIndex = (highestIndex + 1).toString().padStart(3, '0')
    newquestion.index = nextIndex
  }

  const editQuestionBlock = (question: any) => {
    if (active === false) {
      setDependentEditor(question.dependent)
      setConditionalQuestion(question.conditionalQuestion)
      setConditionalAnswer(question.conditional)
      setConditionalId(question.conditionId)
      setActive(true)
    }

    const questionType = (type: string) => {
      if (type === 'input') {
        return 'Input'
      } else if (type === 'numericInput') {
        return 'Input numérico'
      } else if (type === 'option') {
        return 'Selección de una opción'
      } else if (type === 'choice') {
        return 'Selección de multiples opciones'
      } else if (type === 'video') {
        return 'Video'
      } else if (type === 'image') {
        return 'Imagen'
      } else if (type === 'text') {
        return 'Caja de texto'
      } else if (type === 'date') {
        return 'Fecha'
      } else if (type === 'link') {
        return 'Link'
      } else if (type === 'interval') {
        return 'Intervalo de fechas'
      } else if (type === 'paragraph') {
        return 'Párrafo'
      } else if (type === 'country') {
        return 'Selección de país'
      } else if (type === 'province') {
        return 'Selección de provincia'
      } else if (type === 'language') {
        return 'Selección de idiomas'
      } else if (type === 'help') {
        return 'Link para ayuda'
      } else if (type === 'description') {
        return 'Subtitulo o texto'
      } else {
        return 'Indefinido'
      }
    }

    return (
      <div className='question-editor-container'>
        <div className='question-editor-main-div '>
          <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Editar Pregunta</h1>
          <div className='flex gap-4 items-center mb-4'>
            <p style={{ fontSize: '1rem', width: 'auto', margin: '0', fontWeight: 'bold' }}>Tipo de pregunta:</p>
            <p style={{ fontSize: '1.3rem', width: 'auto', margin: '0', fontWeight: 'bold' }}>
              {questionType(question.type)}
            </p>
          </div>
          <div className='flex flex-row gap-4 mb-4'>
            <div className='flex flex-col'>
              <b>Indice</b>
              <p className='input-styles' style={{ width: '4rem', marginLeft: '0' }}>
                {question.index}
              </p>
            </div>
            <div className='flex flex-col'>
              <b>Pregunta</b>
              <input
                style={{ width: '35rem', marginLeft: '0' }}
                type='text'
                title='name'
                placeholder='Reescribe tu pregunta'
                defaultValue={question.question}
                className='input-styles'
                onChange={(e) => {
                  const updatedQuestion = e.target.value
                  const originalQuestion = question.question
                  question.question = updatedQuestion
                  finalForm.questions.forEach((conditional) => {
                    if (
                      conditional.conditionalQuestion === originalQuestion &&
                      conditional.conditionId === question.index
                    ) {
                      conditional.conditionalQuestion = updatedQuestion
                    }
                  })
                  setUpdateQuestion(updateQuestion + 1)
                }}
              />
            </div>
          </div>
          <div className='flex flex-col mb-4'>
            <Checkbox
              style={{ width: 'fit-content' }}
              defaultChecked={question.validate}
              onChange={(e: CheckboxChangeEvent) => {
                question.validate = e.target.checked
                setUpdateQuestion(updateQuestion + 1)
              }}>
              ¿Es una pregunta obligatoria?
            </Checkbox>

            <Checkbox
              style={{ width: 'fit-content' }}
              defaultChecked={question.translate}
              onChange={(e: CheckboxChangeEvent) => {
                question.translate = e.target.checked
              }}
            >
              ¿Se necesita traducción?
            </Checkbox>

            {(question.type === 'interval' || question.type === 'date') ?
              <Checkbox
                style={{ width: 'fit-content' }}
                defaultChecked={question.mustBeCurrent}
                onChange={(e: CheckboxChangeEvent) => {
                  question.mustBeCurrent = e.target.checked
                }}>
                ¿Debe estar vigente?
              </Checkbox> : null}

            {(question.type === 'interval' || question.type === 'date') ?
              <Checkbox
                style={{ width: 'fit-content' }}
                defaultChecked={question.mustBeNotCurrent}
                onChange={(e: CheckboxChangeEvent) => {
                  question.mustBeNotCurrent = e.target.checked
                }}>
                ¿Debe ser una fecha previa a la actual?
              </Checkbox> : null}

            {(newquestion.type === 'interval') ?
              <Checkbox
                style={{ width: 'fit-content' }}
                defaultChecked={newquestion.fromMustBeCurrent}
                onChange={(e: CheckboxChangeEvent) => {
                  newquestion.fromMustBeCurrent = e.target.checked
                }}>
                ¿La fecha <b>Desde</b> debe estar vigente?
              </Checkbox> : null}

          </div>
          <div className='flex flex-col' style={{ backgroundColor: '#f0edee', padding: '1rem' }}>
            <b>Condiciones</b>
            <Checkbox
              style={{ width: 'fit-content' }}
              defaultChecked={question.dependent}
              onChange={(e: CheckboxChangeEvent) => {
                setDependentEditor(e.target.checked)
                question.hide = e.target.checked ? 1 : 0
                setHideQuestionDependent(hideQuestionDependent + 1)
                setUpdateQuestion(updateQuestion + 1)
              }}
            >
              ¿Es una pregunta que depende de otra?
            </Checkbox>
            <div className='flex flex-row gap-8 mb-4'>
              <div className='flex flex-col gap-2'>
                <b>Pregunta condicional</b>
                <select
                  style={{ width: '12rem', marginLeft: '0' }}
                  defaultValue={conditionalQuestion}
                  title='option'
                  className='option-styles '
                  onChange={(e: any) => {
                    const selectedOption = e.target.value
                    const selectedQuestion = finalForm.questions.find(
                      (option) => option.question === selectedOption
                    )
                    if (selectedQuestion) {
                      setConditionalId(selectedQuestion.index)
                      setUpdateoptions(updateoptions + 1)
                    }
                    setConditionalQuestion(e.target.value)
                    setUpdateQuestion(updateQuestion + 1)
                  }}
                >
                  <option value=''>Seleccione</option>
                  {finalForm.questions.map(
                    (option: IQuestions) =>
                      option.question !== '' &&
                      (option.type === 'option' ||
                        option.type === 'choice' ||
                        option.type === 'country' ||
                        option.type === 'province') && (
                        <option key={option.question} value={option.question}>
                          <p>
                            <b>{option.question}</b>&nbsp; &nbsp;
                            <b style={{ color: 'red' }}>Id: </b>
                            <b>{option.index}</b>
                          </p>
                        </option>
                      )
                  )}
                </select>
              </div>
              <div className='flex flex-col gap-2'>
                <b>Respuesta clave para generar activación</b>
                <select
                  defaultValue={conditionalAnswer}
                  style={{ width: '12rem', marginLeft: '0' }}
                  title='option'
                  className='option-styles '
                  onChange={(e: any) => {
                    setConditionalAnswer(e.target.value)
                  }}
                >
                  {finalForm.questions.map((q) =>
                    q.question === newquestion.conditionalQuestion &&
                      q.index === newquestion.conditionId ? (
                      q.type === 'country' ? (
                        // Renderiza CountrySelect como una opción dentro del select
                        <CountrySelect key={q.index} />
                      ) : q.type === 'province' ? (
                        // Renderiza opciones de provincias
                        <>
                          <option value=''>Seleccione</option>
                          <option value='AB'>Alberta</option>
                          <option value='BC'>British Columbia</option>
                          <option value='MB'>Manitoba</option>
                          <option value='NB'>New Brunswick</option>
                          <option value='NL'>Newfoundland and Labrador</option>
                          <option value='NS'>Nova Scotia</option>
                          <option value='NU'>Nunavut</option>
                          <option value='ON'>Ontario</option>
                          <option value='QC'>Quebec</option>
                          <option value='SK'>Saskatchewan</option>
                          <option value='YT'>Yukon</option>
                          <option value='PE'>Prince Edward Island</option>
                          <option value='NT'>Northwest Territories</option>
                        </>
                      ) : (
                        // Renderiza opciones normales
                        q.option.map((opt) => (
                          <option key={opt} value={opt}>
                            {opt}
                          </option>
                        ))
                      )
                    ) : null
                  )}
                </select>
              </div>
              <div className='flex flex-col gap-2'>
                <b>Id de la condición</b>
                <input
                  style={{ width: '6rem', marginLeft: '0' }}
                  type='number'
                  title='name'
                  placeholder='Id'
                  value={conditionalId}
                  className='input-styles'
                  onChange={(e) => {
                    setConditionalId(e.target.value)
                    setUpdateQuestion(updateQuestion + 1)
                  }}
                />
              </div>
            </div>
            <button
              style={{
                width: '6rem',
                fontSize: '1rem',
                height: '2.5rem',
                marginTop: '0',
                marginLeft: '1rem',
              }}
              className='init-path-btn'
              type='button'
              onClick={() => {
                if (
                  dependentEditor === true &&
                  conditionalQuestion !== '' &&
                  conditionalAnswer !== ''
                ) {
                  question.dependent = dependentEditor
                  question.conditionalQuestion = conditionalQuestion
                  question.conditional = conditionalAnswer
                  question.conditionId = conditionalId
                  setMessage(true)
                  setTimeout(() => {
                    setMessage(false)
                  }, 2000)
                } else {
                  alert('Todos los campos son obligatorios')
                }
              }}
            >
              Condicionar
            </button>
            {message && (
              <div className='flex justify-center mt-0' style={{ color: '#bf3c3c', padding: '0' }}>
                <b>¡Datos Cambiados!</b>
              </div>
            )}
          </div>
          {question.type === 'province' &&
            <div className='flex flex-col option-container '>
              <b>Opciones</b>
              {provincesL.map((opt: any, index: number) => (
                <div
                 key={index}
                  className='flex flex-row items-start gap-4 justify-between mt-2'
                  style={{ borderBottom: '1px solid black' }}
                >
                  <div className='flex flex-col gap-2 mb-2'>
                    <input
                      style={{ width: '25rem', marginLeft: '0' }}
                      type='text'
                      title='name'
                      placeholder='opciones'
                      defaultValue={opt}
                      className='input-styles'
                      onChange={(e) => {
                        const actualQuestion = opt
                        question.docsAttached.map((documents: any, index: number) => {
                          if (documents.trigger === actualQuestion) {
                            question.docsAttached[index].trigger = e.target.value
                            question.option[index] = e.target.value
                            setUpdateQuestion(updateQuestion + 1)
                          } else {
                            question.option[index] = e.target.value
                            setUpdateQuestion(updateQuestion + 1)
                          }
                        })
                      }}
                    />
                    <select
                      className='option-styles'
                      style={{ width: '25rem', height: '2rem' }}
                      name=''
                      id=''
                      onChange={(e) => {
                        const selectedDoc = e.target.value
                        const updatedDocsAttached = question.docsAttached.map((item: any) => {
                          if (item.trigger === opt) {
                            item.docs.push(selectedDoc)
                          }
                          return item
                        })

                        if (!updatedDocsAttached.some((item: any) => item.trigger === opt)) {
                          updatedDocsAttached.push({ trigger: opt, docs: [selectedDoc] })
                        }

                        question.docsAttached = updatedDocsAttached
                        setUpdateQuestion(updateQuestion + 1)
                      }}
                    >
                      <option value=''>Añadir documento</option>
                      {docData.data.map((document: any) => (
                        <option key={document.id} value={document.id}>
                          {document.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className='flex flex-col documents-list'
                    style={{ width: '25rem', marginLeft: '0' }}
                  >
                    <b>Documentos asignados:</b>
                    {question.docsAttached.map((documents: any) => (
                      <>
                        {documents.trigger === opt ? (
                          <>
                            {documents.docs.map((doc: string) => (
                              <div
                                className='flex flex-row gap-4 items-start justify-between'
                                style={{ height: '2rem' }}
                              >
                                {docData.data.map((docName: any) => (
                                  <>
                                    {docName.id === doc ? (
                                      <>
                                        <p>{docName.name}</p>
                                        <button
                                          style={{
                                            color: '#bf3c3c',
                                          }}
                                          onClick={() => {
                                            const updatedDocsAttached = question.docsAttached.map(
                                              (item: any) => {
                                                if (item.trigger === documents.trigger) {
                                                  item.docs = item.docs.filter(
                                                    (docId: string) => docId !== doc
                                                  )
                                                }
                                                return item
                                              }
                                            )
                                            const filteredDocsAttached = updatedDocsAttached.filter(
                                              (item: any) => item.docs.length > 0
                                            )
                                            question.docsAttached = filteredDocsAttached
                                            setUpdateQuestion(updateQuestion + 1)
                                          }}
                                        >
                                          <b>x</b>
                                        </button>
                                      </>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                            ))}
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      const updatedOptions = question.option.filter(
                        (option: string) => option !== opt
                      )
                      const updatedDocsAttached = question.docsAttached.filter(
                        (doc: any) => doc.trigger !== opt
                      )
                      question.option = updatedOptions
                      question.docsAttached = updatedDocsAttached
                      setUpdateQuestion(updateQuestion + 1)
                    }}
                  >
                    <b>X</b>
                  </button>
                </div>))}
            </div>}

          {question.type === 'option' && (
            <div className='flex flex-col option-container '>
              <b>Opciones</b>
              {question.option.map((opt: any, index: number) => (
                <div
                key={index}
                  className='flex flex-row items-start gap-4 justify-between mt-2'
                  style={{ borderBottom: '1px solid black' }}
                >
                  <div className='flex flex-col gap-2 mb-2'>
                    <input
                      style={{ width: '25rem', marginLeft: '0' }}
                      type='text'
                      title='name'
                      placeholder='opciones'
                      defaultValue={opt}
                      className='input-styles'
                      onChange={(e) => {
                        const actualQuestion = opt
                        question.docsAttached.map((documents: any, index: number) => {
                          if (documents.trigger === actualQuestion) {
                            question.docsAttached[index].trigger = e.target.value
                            question.option[index] = e.target.value
                            setUpdateQuestion(updateQuestion + 1)
                          } else {
                            question.option[index] = e.target.value
                            setUpdateQuestion(updateQuestion + 1)
                          }
                        })
                      }}
                    />
                    <select
                      className='option-styles'
                      style={{ width: '25rem', height: '2rem' }}
                      name=''
                      id=''
                      onChange={(e) => {
                        const selectedDoc = e.target.value
                        const updatedDocsAttached = question.docsAttached.map((item: any) => {
                          if (item.trigger === opt) {
                            item.docs.push(selectedDoc)
                          }
                          return item
                        })

                        if (!updatedDocsAttached.some((item: any) => item.trigger === opt)) {
                          updatedDocsAttached.push({ trigger: opt, docs: [selectedDoc] })
                        }

                        question.docsAttached = updatedDocsAttached
                        setUpdateQuestion(updateQuestion + 1)
                      }}
                    >
                      <option value=''>Añadir documento</option>
                      {docData.data.map((document: any) => (
                        <option key={document.id} value={document.id}>
                          {document.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className='flex flex-col documents-list'
                    style={{ width: '25rem', marginLeft: '0' }}
                  >
                    <b>Documentos asignados:</b>
                    {question.docsAttached.map((documents: any) => (
                      <>
                        {documents.trigger === opt ? (
                          <>
                            {documents.docs.map((doc: string) => (
                              <div
                                className='flex flex-row gap-4 items-start justify-between'
                                style={{ height: '2rem' }}
                              >
                                {docData.data.map((docName: any) => (
                                  <>
                                    {docName.id === doc ? (
                                      <>
                                        <p>{docName.name}</p>
                                        <button
                                          style={{
                                            color: '#bf3c3c',
                                          }}
                                          onClick={() => {
                                            const updatedDocsAttached = question.docsAttached.map(
                                              (item: any) => {
                                                if (item.trigger === documents.trigger) {
                                                  item.docs = item.docs.filter(
                                                    (docId: string) => docId !== doc
                                                  )
                                                }
                                                return item
                                              }
                                            )
                                            const filteredDocsAttached = updatedDocsAttached.filter(
                                              (item: any) => item.docs.length > 0
                                            )
                                            question.docsAttached = filteredDocsAttached
                                            setUpdateQuestion(updateQuestion + 1)
                                          }}
                                        >
                                          <b>x</b>
                                        </button>
                                      </>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                            ))}
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      const updatedOptions = question.option.filter(
                        (option: string) => option !== opt
                      )
                      const updatedDocsAttached = question.docsAttached.filter(
                        (doc: any) => doc.trigger !== opt
                      )
                      question.option = updatedOptions
                      question.docsAttached = updatedDocsAttached
                      setUpdateQuestion(updateQuestion + 1)
                    }}
                  >
                    <b>X</b>
                  </button>
                </div>
              ))}
              <div>
                <p style={{ marginLeft: '3rem', fontSize: '1rem' }}>Agregar opciones</p>
                <div className='flex'>
                  <input
                    style={{ marginBottom: '1rem', marginRight: '1rem' }}
                    type='text'
                    title='name'
                    placeholder='Agrega una opción'
                    className='input-styles'
                    onChange={(e) => {
                      setNewOption(e.target.value)
                      setUpdateQuestion(updateQuestion + 1)
                    }}
                  />
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.8rem',
                      height: '2rem',
                      marginTop: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      if (newOption) {
                        question.option.push(newOption)
                        setNewOption('')
                      }
                      setUpdateoptions(updateoptions + 1)
                    }}>
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className='flex flex-row gap-4 mb-8 justify-between'>
            <div>
              <b>Mensaje de validación</b>
              <input
                style={{ width: '12rem', marginLeft: '1rem' }}
                type='text'
                title='name'
                placeholder='Reescribe tu pregunta'
                defaultValue={question.validateAnswer}
                className='input-styles'
                onChange={(e: any) => {
                  question.validateAnswer = e.target.value
                  setUpdateoptions(updateoptions + 1)
                }} />
            </div>
            <div className='flex'>
              <b>Seccion de info</b>
              <textarea
                style={{ width: '20rem', marginLeft: '1rem', height: '10rem' }}
                placeholder='Texto de información'
                defaultValue={String(question.info)}
                className='text-styles'
                onChange={(e: any) => {
                  handleInputInfoChange(e)
                  question.activeInfo = true
                  question.info = e.target.value
                  setUpdateoptions(updateoptions + 1)
                }}
              />
            </div>
          </div>
          <button
            style={{
              width: '6rem',
              fontSize: '1rem',
              height: '2.5rem',
              marginTop: '0',
              marginLeft: '1rem',
            }}
            className='init-path-btn'
            type='button'
            onClick={() => {
              setActive(false)
              handleSaveClick()
            }}
          >
            Guardar
          </button>
        </div>
      </div>
    )
  }

  const handleOptionChange = (e: any, index: number, option: string) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions: any = { ...prevSelectedOptions }
      if (updatedOptions[index]) {
        updatedOptions[index].docs.push(e.target.value)
      } else {
        updatedOptions[index] = { docs: [e.target.value], trigger: option }
      }
      const docsAttachedArray = Object.values(updatedOptions)
      newquestion.docsAttached = docsAttachedArray
      return updatedOptions
    })
  }

  const handleButtonClick = (optionIndex: number, docId: string) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions: any = { ...prevSelectedOptions }
      if (updatedOptions[optionIndex]) {
        updatedOptions[optionIndex].docs = updatedOptions[optionIndex].docs.filter(
          (id: string) => id !== docId
        )
      }
      const docsAttachedArray = Object.values(updatedOptions)
      newquestion.docsAttached = docsAttachedArray
      return updatedOptions
    })
  }

  useEffect(() => {
    if (isEditing === true) {
      if (status === 'success' && isLoading === false) {
        data.data.data.map((form: any) => {
          if (form.id === formIdSelected && editMode2 === false) {
            setFinalForm(form.data)
            setReadForm(readForm + 1)
            setEditMode2(true)
          }
        })
      }
    }
  }, [isEditing])

  const handleEditClick = () => {
    setEditMode(true)
    setUpdateQuestion(updateQuestion + 1)
  }

  const handleSaveClick = () => {
    setEditMode(false)
    setUpdateQuestion(updateQuestion + 1)
  }

  const deleteQuestion = (index: number) => {
    const copyQuestions = [...finalForm.questions]
    copyQuestions.splice(index, 1)
    setFinalForm({ ...finalForm, questions: copyQuestions })
    setFormKey(formKey + 1)
    setReadForm(readForm + 1)
  }

  const handleInputDependentButton = () => {
    newquestion.conditional = ''
    setInputDependentValue('')
  }

  const handleInputQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputQuestionValue(e.target.value)
  }

  const handleInputQuestionChangeButton = () => {
    newquestion.question = ''
    setInputQuestionValue('')
  }

  const handleInputInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputInfoValue(e.target.value)
  }

  const handleInputInfoChangeButton = () => {
    newquestion.info = ''
    setInputInfoValue('')
  }

  const handleResetCheckbox = () => {
    setHideQuestionDependent(hideQuestionDependent + 1)
    setDependent(false)
    newquestion.dependent = false
  }

  const handleResetSelect = () => {
    const selectElement = document.getElementById('selectElement') as HTMLSelectElement
    selectElement.value = ''
    newquestion.choices = []
    newquestion.option = []
    newquestion.docsAttached = []
    setSelectedOptions({})
    setOptionList(false)
    setChoiceList(false)
    setVideoList(false)
    setImageList(false)
    setLinkList(false)
  }

  const handleResetConditionals = () => {
    newquestion.answer = ''
    newquestion.conditionalQuestion = ''
    newquestion.conditional = ''
  }

  const handleInputOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputOptionValue(e.target.value)
  }

  const handleOptionButtonClick = () => {
    newquestion.option = [...newquestion.option, inputOptionValue]
    setInputOptionValue('')
  }

  const handleInputChoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputChoiceValue(e.target.value)
  }

  const handleChoiceButtonClick = () => {
    newquestion.choices = [...newquestion.choices, inputChoiceValue]
    setInputChoiceValue('')
  }

  function resetValues() {
    setFormIdSelected(null)
    initialData.name = ''
    initialData.category = ''
    initialData.description = ''
    initialData.data = ''
  }
  const handleFormNameChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setFormName(event.target.value)
    finalForm.formName = event.target.value
  }

  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setFormDescription(event.target.value)
  }

  const handleCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setFormCategory(event.target.value)
  }

  const handleEdit = (data: Forms) => {
    initialData.name = data.name
    initialData.description = data.description
    initialData.category = data.category
    initialData.data = JSON.stringify(data.data)
  }

  const cleanPreview = () => {
    yeisonBuilder.questions.map((answer) => (answer.answer = ''))
  }

  const sendFromJson = async () => {
    cleanPreview()
    if (formIdSelected) {
      const payload: any = {
        id: formIdSelected,
        name: formName,
        data: finalForm,
        category: formCategory,
        description: formDescription,
      }

      try {
        const request: any = await saveForms(payload)
        setFormName('')
        setFormData('')
        setFormDescription('')
        setFormCategory('')
        setFinalForm(clean)
        setSpawnQuestion(spawnQuestion + 1)

        if (request) {
          Swal.fire({
            title: 'Success!',
            text: 'Guardado correctamente ',
            icon: 'success',
            confirmButtonText: 'Regresar',
          }).then(() => {
            // Realizar el refresh de la pantalla
            window.location.reload()
          })
        }
      } catch (error: any) {
        Swal.fire({
          title: 'ERROR!',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Regresar',
        })
      }


    } else {
      const payload: any = {
        name: formName,
        data: finalForm,
        description: formDescription,
        category: formCategory,
      }
      try {
        const request = await saveForms(payload)

        setFormName('')
        setFormData('')
        setFormDescription('')
        setFormCategory('')
        setActive2(false)
        setFinalForm(clean)
        setSpawnQuestion(spawnQuestion + 1)

        if (request) {

          Swal.fire({
            title: 'Success!',
            text: 'Guardado correctamente ',
            icon: 'success',
            confirmButtonText: 'Regresar',
          }).then(() => {
            // Realizar el refresh de la pantalla
            window.location.reload()
          })
        }
      } catch (error: any) {
        Swal.fire({
          title: 'ERROR!',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Regresar',
        })
      }
    }
  }

  const conditionalQuestions = (cond: any, index: number) => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          backgroundColor: '#f0edee',
          paddingTop: '1rem',
          borderRadius: '10px',
          paddingLeft: '1rem',
          paddingRight: '0.5rem',
        }}
      >
        <div className='flex flex-row gap-2'>
          <p>Pregunta: {cond.conditionalQuestion}</p>
          <p>Respuesta: {cond.conditional}</p>
          <p>Id: {cond.conditionId}</p>
        </div>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    placeholder='Text'
                    className='input-styles'

                  />
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Input Nemerico*/}
        {cond.type === 'numericInput' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    maxLength={28}
                    placeholder='Escriba aquí'
                    className='input-styles'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .replace(/[^0-9]/g, '')
                      cond.answer = cleanInput
                    }}
                  />
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'

                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='text-format'>
                <div className='flex'>
                  <textarea
                    title='name'
                    maxLength={300}
                    className='text-styles'

                  />
                  <div className='extra-info'>
                    {cond.info !== '' ? (
                      <>
                        <button
                          type='button'
                          className='info-styles'
                          onClick={() => {
                            cond.activeInfo === true
                              ? (cond.activeInfo = false)
                              : (cond.activeInfo = true)
                            setReadpopup(readpopup + 1)
                          }}
                        >
                          ?
                        </button>
                        {readpopup > 0 && cond.activeInfo ? (
                          <div
                            className='popup-from-style'
                            dangerouslySetInnerHTML={{ __html: cond.info }}
                          />
                        ) : null}
                      </>
                    ) : (
                      <div className='info-styles' />
                    )}
                    {cond.validate === true ? (
                      <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                    ) : (
                      <div className='info-styles' />
                    )}
                  </div>
                  <div className='flex'>
                    <div className='flex flex-col gap-2'>
                      <button
                        style={{
                          width: '4rem',
                          fontSize: '0.6rem',
                          height: '1.2rem',
                          marginTop: '0',
                          marginBottom: '0',
                        }}
                        className='init-path-btn'
                        type='button'
                        onClick={() => {
                          deleteQuestion(index)
                        }}
                      >
                        Borrar
                      </button>
                      <button
                        style={{
                          width: '4rem',
                          fontSize: '0.6rem',
                          height: '1.2rem',
                          marginTop: '0',
                          marginBottom: '0',
                        }}
                        className='init-path-btn'
                        type='button'
                        onClick={() => {
                          setQuestionIndex(index)
                          handleEditClick()
                        }}
                      >
                        editar
                      </button>
                    </div>
                    {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                  </div>
                </div>
                <span>Max 300 carácteres</span>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}

                    >
                      {choice}
                    </Checkbox>
                  ))}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}>
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      format='YYYY-MM-DD'
                    />
                  </Space>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div className='flex flex-col gap-4 ml-16'>
                  <Space direction='vertical'>
                    <p className='m-0'>Desde:</p>
                    <DatePicker
                      format='YYYY-MM-DD'

                    />
                  </Space>
                  <Space direction='vertical'>
                    <p className='m-0'>Hasta:</p>
                    <DatePicker
                      format='YYYY-MM-DD'
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          setSpawnQuestion(spawnQuestion + 1)
                        } else {
                          console.log('')
                        }
                      }}
                    />
                  </Space>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex '>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex justify-between'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              {cond.info !== '' && (
                <button
                  type='button'
                  className='info-styles'
                  onClick={() => {
                    cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                    setReadpopup(readpopup + 1)
                  }}
                >
                  ?
                </button>
              )}
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
              <div className='flex'>
                <div className='flex flex-col gap-2'>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      deleteQuestion(index)
                    }}
                  >
                    Borrar
                  </button>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      setQuestionIndex(index)
                      handleEditClick()
                    }}
                  >
                    editar
                  </button>
                </div>
                {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex justify-between'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              {cond.info !== '' && (
                <button
                  type='button'
                  className='info-styles'
                  onClick={() => {
                    cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                    setReadpopup(readpopup + 1)
                  }}
                >
                  ?
                </button>
              )}
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
              <div className='flex'>
                <div>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      deleteQuestion(index)
                    }}
                  >
                    Borrar
                  </button>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      setQuestionIndex(index)
                      handleEditClick()
                    }}
                  >
                    editar
                  </button>
                </div>
                {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex mt-8'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='paragraph-styles' style={{ color: '#ababab' }}>
              {cond.question}
            </p>
            {cond.info !== '' && (
              <button
                type='button'
                className='info-styles'
                onClick={() => {
                  cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                  setReadpopup(readpopup + 1)
                }}
              >
                ?
              </button>
            )}
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
            <div className='flex'>
              <div className='flex flex-col gap-2'>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    deleteQuestion(index)
                  }}
                >
                  Borrar
                </button>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    setQuestionIndex(index)
                    handleEditClick()
                  }}
                >
                  editar
                </button>
              </div>
              {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
            </div>
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex justify-between'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='description-styles' style={{ color: '#3a3a3a' }}>
              {cond.question}
            </p>
            {cond.info !== '' && (
              <button
                type='button'
                className='info-styles'
                onClick={() => {
                  cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                  setReadpopup(readpopup + 1)
                }}
              >
                ?
              </button>
            )}
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
            <div className='flex'>
              <div className='flex flex-col gap-2'>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    deleteQuestion(index)
                  }}
                >
                  Borrar
                </button>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    setQuestionIndex(index)
                    handleEditClick()
                  }}
                >
                  editar
                </button>
              </div>
              {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
            </div>
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <a className='paragraphStyles' href={cond.link}>
              {cond.question}
            </a>
            {cond.info !== '' && (
              <button
                type='button'
                className='info-styles'
                onClick={() => {
                  cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                  setReadpopup(readpopup + 1)
                }}
              >
                ?
              </button>
            )}
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
            <div className='flex'>
              <div>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    deleteQuestion(index)
                  }}
                >
                  Borrar
                </button>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    setQuestionIndex(index)
                    handleEditClick()
                  }}
                >
                  editar
                </button>
              </div>
              {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
            </div>
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <CountrySelect />
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles'>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? EditAplicationByUsers(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(cond, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Cities */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles'>
                    <CitySelect province={cond.conditional} />
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                          setSpawnQuestion(spawnQuestion + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(cond, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                  />
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}
      </div>
    )
  }

  const conditionalQuestions2 = (cond: any, index: number): React.JSX.Element => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          backgroundColor: '#d1d5db',
          paddingTop: '1rem',
          borderRadius: '10px',
          paddingLeft: '1rem',
          paddingRight: '0.5rem',
        }}
      >
        <div className='flex flex-row gap-2'>
          <p>Pregunta: {cond.conditionalQuestion}</p>
          <p>Respuesta: {cond.conditional}</p>
          <p>Id: {cond.conditionId}</p>
        </div>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    placeholder='Text'
                    className='input-styles'
                  />
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Input Nemerico*/}
        {cond.type === 'numericInput' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    maxLength={28}
                    placeholder='Escriba aquí'
                    className='input-styles'
                  />
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='text-format'>
                <div className='flex'>
                  <textarea
                    title='name'
                    maxLength={300}
                    className='text-styles'
                  />
                  <div className='extra-info'>
                    {cond.info !== '' ? (
                      <>
                        <button
                          type='button'
                          className='info-styles'
                          onClick={() => {
                            cond.activeInfo === true
                              ? (cond.activeInfo = false)
                              : (cond.activeInfo = true)
                            setReadpopup(readpopup + 1)
                          }}
                        >
                          ?
                        </button>
                        {readpopup > 0 && cond.activeInfo ? (
                          <div
                            className='popup-from-style'
                            dangerouslySetInnerHTML={{ __html: cond.info }}
                          />
                        ) : null}
                      </>
                    ) : (
                      <div className='info-styles' />
                    )}
                    {cond.validate === true ? (
                      <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                    ) : (
                      <div className='info-styles' />
                    )}
                  </div>
                  <div className='flex'>
                    <div className='flex flex-col gap-2'>
                      <button
                        style={{
                          width: '4rem',
                          fontSize: '0.6rem',
                          height: '1.2rem',
                          marginTop: '0',
                          marginBottom: '0',
                        }}
                        className='init-path-btn'
                        type='button'
                        onClick={() => {
                          deleteQuestion(index)
                        }}
                      >
                        Borrar
                      </button>
                      <button
                        style={{
                          width: '4rem',
                          fontSize: '0.6rem',
                          height: '1.2rem',
                          marginTop: '0',
                          marginBottom: '0',
                        }}
                        className='init-path-btn'
                        type='button'
                        onClick={() => {
                          setQuestionIndex(index)
                          handleEditClick()
                        }}
                      >
                        editar
                      </button>
                    </div>
                    {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                  </div>
                </div>
                <span>Max 300 carácteres</span>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                    >
                      {choice}
                    </Checkbox>
                  ))}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      format='YYYY-MM-DD'
                    />
                  </Space>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div className='flex flex-col gap-4 ml-16'>
                  <Space direction='vertical'>
                    <p className='m-0'>Desde:</p>
                    <DatePicker
                      format='YYYY-MM-DD'
                    />
                  </Space>
                  <Space direction='vertical'>
                    <p className='m-0'>Hasta:</p>
                    <DatePicker
                      format='YYYY-MM-DD'
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1].push(date.format('YYYY-MM-DD').toString())
                          setSpawnQuestion(spawnQuestion + 1)
                        } else {
                          console.log('')
                        }
                      }}
                    />
                  </Space>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex '>
                  <div>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex justify-between'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              {cond.info !== '' && (
                <button
                  type='button'
                  className='info-styles'
                  onClick={() => {
                    cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                    setReadpopup(readpopup + 1)
                  }}
                >
                  ?
                </button>
              )}
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
              <div className='flex'>
                <div>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      deleteQuestion(index)
                    }}
                  >
                    Borrar
                  </button>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      setQuestionIndex(index)
                      handleEditClick()
                    }}
                  >
                    editar
                  </button>
                </div>
                {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex justify-between'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              {cond.info !== '' && (
                <button
                  type='button'
                  className='info-styles'
                  onClick={() => {
                    cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                    setReadpopup(readpopup + 1)
                  }}
                >
                  ?
                </button>
              )}
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
              <div className='flex'>
                <div className='flex flex-col gap-2'>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      deleteQuestion(index)
                    }}
                  >
                    Borrar
                  </button>
                  <button
                    style={{
                      width: '4rem',
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                    className='init-path-btn'
                    type='button'
                    onClick={() => {
                      setQuestionIndex(index)
                      handleEditClick()
                    }}
                  >
                    editar
                  </button>
                </div>
                {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex mt-8'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='paragraphStyles' style={{ color: '#ababab' }}>
              {cond.question}
            </p>
            {cond.info !== '' && (
              <button
                type='button'
                className='info-styles'
                onClick={() => {
                  cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                  setReadpopup(readpopup + 1)
                }}
              >
                ?
              </button>
            )}
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
            <div className='flex'>
              <div>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    deleteQuestion(index)
                  }}
                >
                  Borrar
                </button>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    setQuestionIndex(index)
                    handleEditClick()
                  }}
                >
                  editar
                </button>
              </div>
              {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
            </div>
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex justify-between'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <p className='description-styles' style={{ color: '#3a3a3a' }}>
              {cond.question}
            </p>
            {cond.info !== '' && (
              <button
                type='button'
                className='info-styles'
                onClick={() => {
                  cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                  setReadpopup(readpopup + 1)
                }}
              >
                ?
              </button>
            )}
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
            <div className='flex'>
              <div className='flex flex-col gap-2'>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    deleteQuestion(index)
                  }}
                >
                  Borrar
                </button>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    setQuestionIndex(index)
                    handleEditClick()
                  }}
                >
                  editar
                </button>
              </div>
              {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
            </div>
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <b>{cond.index}</b>&nbsp; &nbsp;
            <a className='paragraphStyles' href={cond.link}>
              {cond.question}
            </a>
            {cond.info !== '' && (
              <button
                type='button'
                className='info-styles'
                onClick={() => {
                  cond.activeInfo === true ? (cond.activeInfo = false) : (cond.activeInfo = true)
                  setReadpopup(readpopup + 1)
                }}
              >
                ?
              </button>
            )}
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-from-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
            <div className='flex'>
              <div>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    deleteQuestion(index)
                  }}
                >
                  Borrar
                </button>
                <button
                  style={{
                    width: '4rem',
                    fontSize: '0.6rem',
                    height: '1.2rem',
                    marginTop: '0',
                    marginBottom: '0',
                  }}
                  className='init-path-btn'
                  type='button'
                  onClick={() => {
                    setQuestionIndex(index)
                    handleEditClick()
                  }}
                >
                  editar
                </button>
              </div>
              {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
            </div>
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <CountrySelect />
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion &&
                cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(cond, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type Cities */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <CitySelect province={cond.conditional} />
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions2(extra, i)
                : null
            )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                  >
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(cond, i)
                : null
            )}
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' ? (
          <div className='flex flex-col gap-12'>
            <div className='flex justify-between'>
              <b>{cond.index}</b>&nbsp; &nbsp;
              <p className='question-styles'>{cond.question}</p>
              <div className='flex'>
                <div>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                  />
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-from-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                  {cond.validate === true ? (
                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
                <div className='flex'>
                  <div className='flex flex-col gap-2'>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        deleteQuestion(index)
                      }}
                    >
                      Borrar
                    </button>
                    <button
                      style={{
                        width: '4rem',
                        fontSize: '0.6rem',
                        height: '1.2rem',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                      className='init-path-btn'
                      type='button'
                      onClick={() => {
                        setQuestionIndex(index)
                        handleEditClick()
                      }}
                    >
                      editar
                    </button>
                  </div>
                  {editMode && questionIndex === index ? editQuestionBlock(cond) : null}
                </div>
              </div>
            </div>
            {finalForm.questions.map((extra: any, i: number) =>
              cond.question === extra.conditionalQuestion && cond.index === extra.conditionId
                ? conditionalQuestions(extra, i)
                : null
            )}
          </div>
        ) : null}
      </div>
    )
  }

  useEffect(() => { }, [updateQuestion])
  useEffect(() => { }, [newquestion])
  useEffect(() => { }, [finalForm])
  useEffect(() => { }, [editMode])


  useEffect(() => {
    if (isEditing) {
      setAddingNewForm(true)
    }

    setIsEditing(false)
  })

  useEffect(() => {
    Survey.StylesManager.applyTheme('defaultV2')
  }, [])

  useEffect(() => {
    if (addingNewForm) {
      setIcon(<MinusCircleOutlined style={{ color: 'white' }} />)
      if (formIdSelected) {
        setFormName(initialData.name)
        setFormCategory(initialData.category)
        setFormDescription(initialData.description)
        setFormData(initialData.data)
      }
    } else {
      resetValues()
      setIcon(<PlusOutlined style={{ color: 'white' }} />)
    }
  }, [addingNewForm])

  if (formSelected === true) {
    return (
      <div>
        <CheckListWrapper formDataSelected={formDataSelected} formIdSelected={formIdSelected} />
      </div>
    )
  } else {
    return (
      <div>
        <PageHeader
          className='site-page-header-responsive'
          title=''
          subTitle=' '
          extra={[
            <Button
              key='addfile'
              type='primary'
              style={{
                background: 'white',
                border: '1px solid #80706b',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              shape='circle'
              icon={icon}
              onClick={() => {
                setAddingNewForm(!addingNewForm)
                setSpawnQuestion(spawnQuestion + 1)
                setEditMode2(false)
                setFinalForm(clean)
                setActive2(false)
              }}
            />,
          ]}
        >
          {addingNewForm ? (
            <>
              {' '}
              <h1 style={{ fontSize: '2rem', textAlign: 'center', marginBottom: '2rem' }}>
                Datos Generales
              </h1>
              <Form
                name='validate_othera'
                layout='vertical'
                {...formItemLayout}
                onFinish={sendFromJson}
              >
                <div className='flex flex-row'>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValue={{ formIdSelected } ? initialData.name : ''}
                    name='name'
                    label='Nombre'
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <input
                      className='input-styles'
                      title='form'
                      onChange={handleFormNameChanges}
                      value={formName}
                    />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValue={{ formIdSelected } ? initialData.category : ''}
                    name='category'
                    label={intl.formatMessage({ id: 'FORMSMANAGER.FORMSCATEGORY' })}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <input
                      className='input-styles'
                      title='Category'
                      onChange={handleCategory}
                      value={formCategory}
                    />
                  </Form.Item>
                </div>
                <div className='flex flex-row'>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValue={{ formIdSelected } ? initialData.description : ''}
                    name='Description'
                    label='Descripción'
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <input
                      className='input-styles'
                      title='Description'
                      onChange={handleDescription}
                      value={formDescription}
                    />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValue={{ formIdSelected } ? finalForm.guide : ''}
                    name='Número de guia'
                    label='Número de guia'
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <p className='input-styles'>{finalForm.guide}</p>
                  </Form.Item>
                </div>
                <div
                  className='w-full'
                  style={{
                    marginBottom: '4rem',
                    borderBottom: '2px solid #bf3c3c',
                    borderTop: '2px solid #bf3c3c',
                  }}>
                  <div>
                    <div className='flex flex-col' style={{ marginTop: '1rem' }}>
                      <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Crea una pregunta:</p>
                      <div className='flex flex-row gap-4'>
                        <div className='flex flex-col'>
                          <p style={{ fontSize: '1.2rem' }}>Escribe tu pregunta</p>
                          <input
                            style={{ marginBottom: '1rem', marginLeft: '0' }}
                            value={inputQuestionValue}
                            type='text'
                            title='name'
                            placeholder='Escribe tu pregunta'
                            className='input-styles'
                            onChange={(e) => {
                              handleInputQuestionChange(e)
                              newquestion.question = e.target.value
                            }}
                          />
                        </div>
                      </div>
                      <div className='flex flex-col' style={{ marginBottom: '2rem', gap: '0.5rem' }}>
                        <Checkbox
                          style={{ width: 'fit-content' }}
                          defaultChecked={true}
                          onChange={(e: CheckboxChangeEvent) => {
                            newquestion.validate = e.target.checked
                          }}
                        >
                          ¿Es una pregunta obligatoria?
                        </Checkbox>
                        <Checkbox
                          style={{ width: 'fit-content' }}
                          defaultChecked={false}
                          checked={dependent}
                          onChange={(e: CheckboxChangeEvent) => {
                            setDependent(e.target.checked)
                            newquestion.dependent = e.target.checked
                            newquestion.hide = e.target.checked ? 1 : 0
                            setHideQuestionDependent(hideQuestionDependent + 1)
                          }}
                        >
                          ¿Es una pregunta que depende de otra?
                        </Checkbox>
                        <Checkbox
                          style={{ width: 'fit-content' }}
                          defaultChecked={newquestion.translate}
                          onChange={(e: CheckboxChangeEvent) => {
                            newquestion.translate = e.target.checked
                          }}>
                          ¿Se necesita traducción?
                        </Checkbox>

                      </div>
                      {hideQuestionDependent > 0 && newquestion.dependent === true ? (
                        <div>
                          <div className='flex flex-row gap-4'>
                            <div>
                              <p style={{ fontSize: '1.2rem' }}>Seleccione la pregunta</p>
                              <div className='flex'>
                                <select
                                  style={{ marginBottom: '2rem' }}
                                  title='option'
                                  className='option-styles'
                                  onChange={(e: any) => {
                                    const selectedOption = e.target.value
                                    const selectedQuestionIndex = finalForm.questions.find(
                                      (option) => option.question === selectedOption
                                    )?.index
                                    if (selectedQuestionIndex !== undefined) {
                                      newquestion.conditionId = selectedQuestionIndex
                                      newquestion.conditionalQuestion = selectedOption
                                      setUpdateoptions(updateoptions + 1)
                                    }
                                  }}>
                                  <option value=''>Seleccione</option>
                                  {finalForm.questions.map(
                                    (option: IQuestions) =>
                                      option.question !== '' &&
                                      (option.type === 'option' ||
                                        option.type === 'choice' ||
                                        option.type === 'country' ||
                                        option.type === 'province') && (
                                        <option key={option.index} value={option.question}>
                                          <p>
                                            <b>{option.question}</b>&nbsp; &nbsp;
                                            <b style={{ color: 'red' }}>Id: </b>
                                            <b>{option.index}</b>
                                          </p>
                                        </option>
                                      )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div>
                              <p style={{ fontSize: '1.2rem' }}>Respuesta clave para generar activación</p>
                              <select
                                style={{ marginBottom: '2rem' }}
                                title='option'
                                className='option-styles'
                                onChange={(e: any) => {
                                  newquestion.conditional = e.target.value
                                }}>
                                {finalForm.questions.map((q) =>
                                  q.question === newquestion.conditionalQuestion &&
                                    q.index === newquestion.conditionId ? (
                                    q.type === 'country' ? (
                                      // Renderiza CountrySelect como una opción dentro del select
                                      <CountrySelect key={q.index} />
                                    ) : q.type === 'province' ? (
                                      // Renderiza opciones de provincias
                                      <>
                                        <option value=''>Seleccione</option>
                                        <option value='AB'>Alberta</option>
                                        <option value='BC'>British Columbia</option>
                                        <option value='MB'>Manitoba</option>
                                        <option value='NB'>New Brunswick</option>
                                        <option value='NL'>Newfoundland and Labrador</option>
                                        <option value='NS'>Nova Scotia</option>
                                        <option value='NU'>Nunavut</option>
                                        <option value='ON'>Ontario</option>
                                        <option value='QC'>Quebec</option>
                                        <option value='SK'>Saskatchewan</option>
                                        <option value='YT'>Yukon</option>
                                        <option value='PE'>Prince Edward Island</option>
                                        <option value='NT'>Northwest Territories</option>
                                      </>
                                    ) : (
                                      // Renderiza opciones normales
                                      q.option.map((opt) => (
                                        <option key={opt} value={opt}>
                                          {opt}
                                        </option>
                                      ))
                                    )
                                  ) : null
                                )}
                              </select>
                            </div>
                            <div>
                              <p style={{ fontSize: '1.2rem' }}>Id de la pregunta</p>
                              <input
                                style={{ width: '6rem', marginLeft: '0' }}
                                type='number'
                                title='name'
                                placeholder='Id de la pregunta'
                                className='input-styles'
                                value={newquestion.conditionId}
                                onChange={(e) => {
                                  newquestion.conditionId = e.target.value
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='flex '>
                      <div>
                        <p style={{ fontSize: '1.2rem' }}>Tipo de pregunta</p>
                        <select
                          style={{ marginBottom: '2rem' }}
                          title='option'
                          className='option-styles '
                          id='selectElement'
                          onChange={(e: any) => {
                            newquestion.type = e.target.value
                            newquestion.type === 'option'
                              ? setOptionList(true)
                              : setOptionList(false)
                            newquestion.type === 'choice'
                              ? setChoiceList(true)
                              : setChoiceList(false)

                            newquestion.type === 'date' || newquestion.type === 'interval'
                              ? setMustBeCurrent(true)
                              : setMustBeCurrent(false)

                            newquestion.type === 'date' || newquestion.type === 'interval'
                              ? setMustBeNotCurrent(true)
                              : setMustBeNotCurrent(false)

                            newquestion.type === 'interval'
                              ? setFromMustBeCurrent(true)
                              : setFromMustBeCurrent(false)

                            newquestion.type === 'video' ? setVideoList(true) : setVideoList(false)
                            newquestion.type === 'image' ? setImageList(true) : setImageList(false)
                            newquestion.type === 'link' ? setLinkList(true) : setLinkList(false)
                            newquestion.type === 'help' ? setLinkList(true) : setLinkList(false)
                          }}
                        >
                          <option value=''>Seleccione</option>
                          <option value='input'>Input</option>
                          <option value='numericInput'>Input numérico</option>
                          <option value='option'>Selección de una opción</option>
                          <option value='choice'>Selección de multiples opciones</option>
                          <option value='video'>Video</option>
                          <option value='image'>Imagen</option>
                          <option value='text'>Caja de texto</option>
                          <option value='date'>Fecha</option>
                          <option value='link'>Link</option>
                          <option value='interval'>Intervalo de fechas</option>
                          <option value='paragraph'>Párrafo</option>
                          <option value='country'>Selección de país</option>
                          <option value='province'>Selección de provincia</option>
                          <option value='language'>Selección de idiomas</option>
                          <option value='help'>Link para ayuda</option>
                          <option value='description'>Subtitulo o texto</option>
                          <option value='postalcode'>USA/Canadá Código postal</option>
                          <option value='city'>Ciudades de canada</option>
                        </select>

                        {mustBeCurrent === true && (newquestion.type === 'interval' || newquestion.type === 'date') ?
                          <Checkbox
                            style={{ width: 'fit-content' }}
                            defaultChecked={newquestion.mustBeCurrent}
                            onChange={(e: CheckboxChangeEvent) => {
                              newquestion.mustBeCurrent = e.target.checked
                            }}>
                            ¿Debe estar vigente?
                          </Checkbox> : null}

                        {mustBeNotCurrent === true && (newquestion.type === 'interval' || newquestion.type === 'date') ?
                          <Checkbox
                            style={{ width: 'fit-content' }}
                            defaultChecked={newquestion.mustBeNotCurrent}
                            onChange={(e: CheckboxChangeEvent) => {
                              newquestion.mustBeNotCurrent = e.target.checked
                            }}>
                            ¿Debe ser una fecha previa a la actual?
                          </Checkbox> : null}

                        {fromMustBeCurrent === true && (newquestion.type === 'interval') ?
                          <Checkbox
                            style={{ width: 'fit-content' }}
                            defaultChecked={newquestion.fromMustBeCurrent}
                            onChange={(e: CheckboxChangeEvent) => {
                              newquestion.fromMustBeCurrent = e.target.checked
                            }}>
                            ¿La fecha <b>Desde</b> debe estar vigente?
                          </Checkbox> : null}

                        <div>
                          {optionList === true && newquestion.type === 'option' ? (
                            <>
                              <div>
                                <p style={{ marginLeft: '3rem', fontSize: '1rem' }}>
                                  Agregar opciones
                                </p>
                                <div className='flex'>
                                  <input
                                    style={{ marginBottom: '1rem', marginRight: '1rem' }}
                                    type='text'
                                    title='name'
                                    placeholder='Agrega una opción'
                                    className='input-styles'
                                    value={inputOptionValue}
                                    onChange={handleInputOptionChange}
                                  />
                                  <button
                                    style={{
                                      width: '4rem',
                                      fontSize: '0.8rem',
                                      height: '2rem',
                                      marginTop: '0',
                                    }}
                                    className='init-path-btn'
                                    type='button'
                                    onClick={() => {
                                      handleOptionButtonClick()
                                      setUpdateoptions(updateoptions + 1)
                                    }}
                                  >
                                    Agregar
                                  </button>
                                </div>
                              </div>

                              <div className='flex flex-col option-container'>
                                <div>
                                  <b style={{ fontSize: '1.1rem' }}>Opciones:&nbsp; &nbsp;</b>
                                </div>
                                <div className='flex flex-col gap-8'>
                                  {newquestion.option.map((option, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: 'flex',
                                        gap: '1rem',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <b
                                        style={{
                                          marginLeft: '7rem',
                                          color: '#bf3c3c',
                                          fontSize: '1.1rem',
                                        }}
                                      >
                                        {option}
                                      </b>

                                      <select
                                        placeholder='Agregar Documentos'
                                        className='option-styles'
                                        style={{ height: '2rem' }}
                                        name=''
                                        id=''
                                        onChange={(e) => handleOptionChange(e, index, option)}
                                        value={
                                          selectedOptions[index]
                                            ? selectedOptions[index].trigger
                                            : ''
                                        }>
                                        <option value=''>Seleccione</option>
                                        {docData.data.map((document: any, docIndex: number) => (
                                          <option key={docIndex} value={document.id}>
                                            {document.name}
                                          </option>
                                        ))}
                                      </select>

                                      <div
                                        className='documents-list'
                                        style={{
                                          width: '20rem',
                                          borderRadius: '10px',
                                          padding: '0.2rem 1rem',
                                          boxShadow:
                                            '0 2px 4px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 10%)',
                                        }}
                                      >
                                        {' '}
                                        <p>Documentos Asignados:</p>
                                        <ul>
                                          {selectedOptions[index] &&
                                            selectedOptions[index].docs.map((docId, docIndex) => {
                                              const document = docData.data.find(
                                                (doc: any) => doc.id === docId
                                              )
                                              return (
                                                <li
                                                  key={docIndex}
                                                  className='option flex flex-row justify-between'
                                                >
                                                  {document.name}&nbsp; &nbsp;
                                                  <button
                                                    style={{ color: '#bf3c3c', height: '1.5rem' }}
                                                    className='remove-button'
                                                    onClick={() =>
                                                      handleButtonClick(index, document.id)
                                                    }
                                                  >
                                                    <b>x</b>
                                                  </button>
                                                </li>
                                              )
                                            })}
                                        </ul>
                                      </div>
                                      <button
                                        type='button'
                                        style={{
                                          color: 'white',
                                          backgroundColor: '#bf3c3c',
                                          fontSize: '0.8rem',
                                          fontWeight: 'bold',
                                          height: '1.5rem',
                                          width: '2rem',
                                          borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                          const updatedOptions = [...newquestion.option]
                                          updatedOptions.splice(index, 1)
                                          newquestion.option = updatedOptions
                                          setUpdateoptions(updateoptions + 1)
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : null}

                          {choiceList === true && newquestion.type === 'choice' ? (
                            <>
                              <div>
                                <p style={{ fontSize: '1rem' }}>Agregar items</p>
                                <div className='flex'>
                                  <input
                                    style={{ marginBottom: '1rem', marginRight: '1rem' }}
                                    type='text'
                                    title='name'
                                    placeholder='Agrega una opción'
                                    className='input-styles'
                                    value={inputChoiceValue}
                                    onChange={handleInputChoiceChange}
                                  />
                                  <button
                                    style={{
                                      width: '4rem',
                                      fontSize: '0.8rem',
                                      height: '2rem',
                                      marginTop: '0',
                                    }}
                                    className='init-path-btn'
                                    type='button'
                                    onClick={() => {
                                      handleChoiceButtonClick()
                                      setUpdateoptions(updateoptions + 1)
                                    }}
                                  >
                                    Agregar
                                  </button>
                                </div>
                              </div>
                              <div className='flex flex-col option-container'>
                                <b style={{ fontSize: '1.1rem' }}>Items:&nbsp; &nbsp;</b>
                                {newquestion.choices.map((choice, index) => (
                                  <div style={{ display: 'flex' }}>
                                    <b
                                      style={{
                                        marginLeft: '7rem',
                                        color: '#bf3c3c',
                                        fontSize: '1.1rem',
                                      }}
                                      key={index}
                                    >
                                      {choice}
                                    </b>
                                    <button
                                      type='button'
                                      style={{
                                        color: '#bf3c3c',
                                        fontSize: '0.8rem',
                                        marginLeft: '1rem',
                                        fontWeight: 'bold',
                                      }}
                                      onClick={() => {
                                        const updatedOptions = [...newquestion.choices]
                                        updatedOptions.splice(index, 1)
                                        newquestion.choices = updatedOptions
                                        setUpdateoptions(updateoptions + 1)
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : null}

                          {videoList === true && newquestion.type === 'video' ? (
                            <>
                              <p style={{ fontSize: '1.2rem' }}>Link del video</p>
                              <input
                                style={{ marginBottom: '1rem' }}
                                type='text'
                                title='name'
                                placeholder='Escribe el ink del video'
                                className='input-styles'
                                onChange={(e: any) => {
                                  newquestion.video = e.target.value
                                }}
                              />
                            </>
                          ) : null}

                          {imageList === true && newquestion.type === 'image' ? (
                            <>
                              <p style={{ fontSize: '1.2rem' }}>Link del image</p>
                              <input
                                style={{ marginBottom: '1rem' }}
                                type='text'
                                title='name'
                                placeholder='Escribe el ink de la imagen'
                                className='input-styles'
                                onChange={(e: any) => {
                                  newquestion.image = e.target.value
                                }}
                              />
                            </>
                          ) : null}

                          {linkList === true && newquestion.type === 'link' ? (
                            <>
                              <p style={{ fontSize: '1.2rem' }}>Inserte el link</p>
                              <input
                                style={{ marginBottom: '1rem' }}
                                type='text'
                                title='name'
                                placeholder='Escribe el link'
                                className='input-styles'
                                onChange={(e: any) => {
                                  newquestion.link = e.target.value
                                }}
                              />
                            </>
                          ) : null}

                          {linkList === true && newquestion.type === 'help' ? (
                            <>
                              <p style={{ fontSize: '1.2rem' }}>Inserte el link</p>
                              <input
                                style={{ marginBottom: '1rem' }}
                                type='text'
                                title='name'
                                placeholder='Escribe el link'
                                className='input-styles'
                                onChange={(e: any) => {
                                  newquestion.link = e.target.value
                                }}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      <p style={{ fontSize: '1.2rem', marginTop: '2rem' }}>
                        Texto para boton de información
                      </p>
                      <input
                        style={{ marginBottom: '4rem', marginLeft: '0' }}
                        type='text'
                        title='name'
                        placeholder='Botón de información'
                        className='input-styles'
                        value={inputInfoValue}
                        onChange={(e: any) => {
                          handleInputInfoChange(e)
                          newquestion.info = e.target.value
                        }}
                      />
                    </div>
                  </div>
                  <button
                    type='button'
                    className='init-path-btn'
                    onClick={() => {
                      if (newquestion.type !== '') {
                        IndexGenerator()
                        setTimeout(() => {
                          const newQuestion = { ...newquestion }
                          setNumberQuestion(numberQuestion + 1)
                          newquestion.questionId = finalForm.questions.length
                          setReadForm(readForm + 1)
                          finalForm.questions = [...finalForm.questions, newQuestion]
                          handleInputQuestionChangeButton()
                          handleResetSelect()
                          handleInputInfoChangeButton()
                          handleInputDependentButton()
                          handleResetCheckbox()
                          handleResetConditionals()
                          newquestion.video = ''
                          newquestion.image = ''
                          newquestion.link = ''
                        }, 200)
                      }
                    }}
                  >
                    Añadir pregunta
                  </button>
                </div>

                {/* Preview from */}
                <div
                  style={{
                    marginBottom: '2rem',
                    borderBottom: '2px solid #bf3c3c',
                    paddingBottom: '6rem',
                  }}
                >
                  <div>
                    <h1 style={{ fontSize: '2rem', textAlign: 'center', marginBottom: '2rem' }}>
                      Preview
                    </h1>
                    <h1 style={{ fontSize: '1.5rem' }}>Nombre del formulario: &nbsp; &nbsp;</h1>
                    <div className='flex flex-row gap-4'>
                      <h1 style={{ fontSize: '2rem', textAlign: 'center' }}>{finalForm.formName}</h1>
                      <h1 style={{ fontSize: '2rem', textAlign: 'center', color: 'red' }}>
                        Nro guia : {finalForm.guide}
                      </h1>
                    </div>
                  </div>
                  {readForm > 0
                    ? finalForm.questions.map((question, index: number) => (
                      <div>
                        {/* Type Input */}
                        {question.type === 'input' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div>
                                  <input
                                    type='text'
                                    title='name'
                                    placeholder='Text'
                                    className='input-styles'

                                  />
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                              {finalForm.questions.map((cond: any, i: number) =>
                                question.question === cond.conditionalQuestion &&
                                  question.index === cond.conditionId
                                  ? conditionalQuestions(cond, i)
                                  : null
                              )}
                            </div>
                          </div>
                        ) : null}

                        {/* Type Input Numerico */}
                        {question.type === 'numericInput' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div>
                                  <input
                                    type='text'
                                    title='name'
                                    maxLength={28}
                                    placeholder='Escriba aquí'
                                    className='input-styles'

                                  />
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                              {finalForm.questions.map((cond: any, i: number) =>
                                question.question === cond.conditionalQuestion &&
                                  question.index === cond.conditionId
                                  ? conditionalQuestions(cond, i)
                                  : null
                              )}
                            </div>
                          </div>
                        ) : null}

                        {/* Type Text */}
                        {question.type === 'text' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div className='text-format'>
                                  <textarea
                                    title='name'
                                    maxLength={300}
                                    className='text-styles'

                                  />
                                  <span>Max 300 carácteres</span>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                          setSpawnQuestion(spawnQuestion + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      style={{
                                        color: '#bf3c3c',
                                        fontSize: '1.5rem',
                                        left: '-2rem',
                                      }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(cond, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/* Type Option */}
                        {question.type === 'option' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex '>
                                <div>
                                  <select
                                    title='option'
                                    className='option-styles '

                                  >
                                    <option value=''>Seleccione</option>
                                    {question.option.map((option: string) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                          setSpawnQuestion(spawnQuestion + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex items-center'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(cond, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/* Type choice */}
                        {question.type === 'choice' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div className='choice-option'>
                                  {question.choices.map((choice: string) => (
                                    <Checkbox
                                      value={choice}

                                    >
                                      {choice}
                                    </Checkbox>
                                  ))}
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      style={{
                                        color: '#bf3c3c',
                                        fontSize: '1.5rem',
                                        left: '-2rem',
                                      }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(cond, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/* Type Date */}
                        {question.type === 'date' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div>
                                  <Space direction='vertical'>
                                    <DatePicker
                                      format='YYYY-MM-DD'
                                    />
                                  </Space>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}>
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}>
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                              {finalForm.questions.map((cond: any, i: number) =>
                                question.question === cond.conditionalQuestion &&
                                  question.index === cond.conditionId
                                  ? conditionalQuestions(cond, i)
                                  : null
                              )}
                            </div>
                          </div>
                        ) : null}

                        {/* Type Interval */}
                        {question.type === 'interval' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div className='flex flex-col gap-4 ml-16'>
                                  <Space direction='vertical'>
                                    <p className='m-0'>Desde:</p>
                                    <DatePicker
                                      format='YYYY-MM-DD'/>
                                  </Space>
                                  <Space direction='vertical'>
                                    <p className='m-0'>Hasta:</p>
                                    <DatePicker
                                      format='YYYY-MM-DD'
                                      onChange={(date) => {
                                        if (date && date !== null) {
                                          finalForm.questions[index].multipleAnswer.push(
                                            date.format('YYYY-MM-DD').toString()
                                          )
                                          setSpawnQuestion(spawnQuestion + 1)
                                        } else {
                                          console.log('')
                                        }}}/>
                                  </Space>
                                </div>
                              </div>
                              <div className='extra-info'>
                                {question.info !== '' ? (
                                  <>
                                    <button
                                      type='button'
                                      className='info-styles'
                                      onClick={() => {
                                        question.activeInfo === true
                                          ? (question.activeInfo = false)
                                          : (question.activeInfo = true)
                                        setReadpopup(readpopup + 1)
                                      }}>
                                      ?
                                    </button>
                                    {readpopup > 0 && question.activeInfo ? (
                                      <div
                                        className='popup-from-style'
                                        dangerouslySetInnerHTML={{ __html: question.info }}
                                      />
                                    ) : null}
                                  </>
                                ) : (
                                  <div className='info-styles' />
                                )}
                                {question.validate === true ? (
                                  <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                                ) : (
                                  <div className='info-styles' />
                                )}
                              </div>
                              <div className='flex'>
                                <div className='flex flex-col gap-2'>
                                  <button
                                    style={{
                                      width: '4rem',
                                      fontSize: '0.6rem',
                                      height: '1.2rem',
                                      marginTop: '0',
                                      marginBottom: '0',
                                    }}
                                    className='init-path-btn'
                                    type='button'
                                    onClick={() => {
                                      deleteQuestion(index)
                                    }}>
                                    Borrar
                                  </button>
                                  <button
                                    style={{
                                      width: '4rem',
                                      fontSize: '0.6rem',
                                      height: '1.2rem',
                                      marginTop: '0',
                                      marginBottom: '0',
                                    }}
                                    className='init-path-btn'
                                    type='button'
                                    onClick={() => {
                                      setQuestionIndex(index)
                                      handleEditClick()
                                    }}>
                                    editar
                                  </button>
                                </div>
                                {editMode && questionIndex === index
                                  ? editQuestionBlock(question)
                                  : null}
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(cond, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/* Type Video */}
                        {question.type === 'video' && question.dependent === false ? (
                          <div className='flex justify-between'>
                            <b>{question.index}</b>&nbsp; &nbsp;
                            <p className='question-styles'>{question.question}</p>
                            <iframe
                              className='iframe-style'
                              src={question.video}
                              title={question.question}/>
                            {question.info !== '' && (
                              <button
                                type='button'
                                className='info-styles'
                                onClick={() => {
                                  question.activeInfo === true
                                    ? (question.activeInfo = false)
                                    : (question.activeInfo = true)
                                  setReadpopup(readpopup + 1)
                                }}>
                                ?
                              </button>
                            )}
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-from-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                            <div className='flex'>
                              <div className='flex flex-col gap-2'>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    deleteQuestion(index)
                                  }}>
                                  Borrar
                                </button>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    setQuestionIndex(index)
                                    handleEditClick()
                                  }}>
                                  editar
                                </button>
                              </div>
                              {editMode && questionIndex === index
                                ? editQuestionBlock(question)
                                : null}
                            </div>
                          </div>
                        ) : null}

                        {/* Type Image */}
                        {question.type === 'image' && question.dependent === false ? (
                          <div className='flex justify-between'>
                            <b>{question.index}</b>&nbsp; &nbsp;
                            <p className='question-styles'>{question.question}</p>
                            <img
                              className='iframe-style'
                              src={question.image}
                              alt={question.question}/>
                            {question.info !== '' && (
                              <button
                                type='button'
                                className='info-styles'>
                                ?
                              </button>
                            )}
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-from-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                            <div className='flex'>
                              <div className='flex flex-col gap-2'>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    deleteQuestion(index)
                                  }}>
                                  Borrar
                                </button>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    setQuestionIndex(index)
                                    handleEditClick()
                                  }}>
                                  editar
                                </button>
                              </div>
                              {editMode && questionIndex === index
                                ? editQuestionBlock(question)
                                : null}
                            </div>
                          </div>
                        ) : null}

                        {/* Type Paragraph */}
                        {question.type === 'paragraph' && question.dependent === false ? (
                          <div className='flex justify-between'>
                            <b>{question.index}</b>&nbsp; &nbsp;
                            <p className='paragraph-styles' style={{ color: '#ababab' }}>
                              {question.question}
                            </p>
                            {question.info !== '' && (
                              <button
                                type='button'
                                className='info-styles'>
                                ?
                              </button>
                            )}
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-from-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                            <div className='flex'>
                              <div className='flex flex-col gap-2'>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    deleteQuestion(index)
                                  }}>
                                  Borrar
                                </button>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    setQuestionIndex(index)
                                    handleEditClick()
                                  }}>
                                  editar
                                </button>
                              </div>
                              {editMode && questionIndex === index
                                ? editQuestionBlock(question)
                                : null}
                            </div>
                          </div>
                        ) : null}

                        {/* Type description */}
                        {question.type === 'description' && question.dependent === false ? (
                          <div className='flex justify-between'>
                            <b>{question.index}</b>&nbsp; &nbsp;
                            <p className='description-styles' style={{ color: '#3a3a3a' }}>
                              {question.question}
                            </p>
                            {question.info !== '' && (
                              <button
                                type='button'
                                className='info-styles'>
                                ?
                              </button>
                            )}
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-from-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                            <div className='flex'>
                              <div className='flex flex-col gap-2'>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    deleteQuestion(index)
                                  }}>
                                  Borrar
                                </button>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    setQuestionIndex(index)
                                    handleEditClick()
                                  }}>
                                  editar
                                </button>
                              </div>
                              {editMode && questionIndex === index
                                ? editQuestionBlock(question)
                                : null}
                            </div>
                          </div>
                        ) : null}

                        {/* Type Link */}
                        {question.type === 'link' && question.dependent === false ? (
                          <div className='flex justify-between'>
                            <b>{question.index}</b>&nbsp; &nbsp;
                            <a
                              className='paragraphStyles'
                              href={question.link}
                              target='_blank'
                              rel='noopener noreferrer'>
                              {question.question}
                            </a>
                            {question.info !== '' && (
                              <button
                                type='button'
                                className='info-styles'
                                onClick={() => {
                                  question.activeInfo === true
                                    ? (question.activeInfo = false)
                                    : (question.activeInfo = true)
                                  setReadpopup(readpopup + 1)
                                }}>
                                ?
                              </button>
                            )}
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-from-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                            <div className='flex'>
                              <div className='flex flex-col gap-2'>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    deleteQuestion(index)
                                  }}>
                                  Borrar
                                </button>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    setQuestionIndex(index)
                                    handleEditClick()
                                  }}>
                                  editar
                                </button>
                              </div>
                              {editMode && questionIndex === index
                                ? editQuestionBlock(question)
                                : null}
                            </div>
                          </div>
                        ) : null}

                        {/* Type country */}
                        {question.type === 'country' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex '>
                                <div>
                                  <select
                                    title='option'
                                    className='option-styles'>
                                    <CountrySelect />
                                  </select>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                          setSpawnQuestion(spawnQuestion + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}>
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}>
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(question, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/*Type province */}
                        {question.type === 'province' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div>
                                  <select
                                    title='option'
                                    className='option-styles'>
                                    <option value=''>Seleccione</option>
                                    <option value='AB'>Alberta</option>
                                    <option value='BC'>British Columbia</option>
                                    <option value='MB'>Manitoba</option>
                                    <option value='NB'>New Brunswick</option>
                                    <option value='NL'>Newfoundland and Labrador</option>
                                    <option value='NS'>Nova Scotia</option>
                                    <option value='NU'>Nunavut</option>
                                    <option value='ON'>Ontario</option>
                                    <option value='QC'>Quebec</option>
                                    <option value='SK'>Saskatchewan</option>
                                    <option value='YT'>Yukon</option>
                                    <option value='PE'>Prince Edward Island</option>
                                    <option value='NT'>Northwest Territories</option>
                                  </select>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(cond, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/* Type cities */}
                        {question.type === 'city' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex '>
                                <div>
                                  <select
                                    title='option'
                                    className='option-styles '
                                  >
                                    <CitySelect province={question.conditional} />
                                  </select>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                          setSpawnQuestion(spawnQuestion + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                            </div>
                            {finalForm.questions.map((cond: any, i: number) =>
                              question.question === cond.conditionalQuestion &&
                                question.index === cond.conditionId
                                ? conditionalQuestions(question, i)
                                : null
                            )}
                          </div>
                        ) : null}

                        {/*Type language */}
                        {question.type === 'language' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div>
                                  <select
                                    title='option'
                                    className='option-styles '
                                  >
                                    <option value=''>Seleccione</option>
                                    <option value='English'>English</option>
                                    <option value='Mandarin'>Mandarin Chinese</option>
                                    <option value='Hindi'>Hindi</option>
                                    <option value='Spanish'>Spanish</option>
                                    <option value='French'>French</option>
                                    <option value='Arabic'>Standard Arabic</option>
                                    <option value='Bengali'>Bengali</option>
                                    <option value='Russian'>Russian</option>
                                    <option value='Portuguese'>Portuguese</option>
                                    <option value='Urdu'>Urdu</option>
                                    <option value='Indonesian'>Indonesian</option>
                                    <option value='German'>German</option>
                                    <option value='Japanese'>Japanese</option>
                                    <option value='Nigerian'>Nigerian Pidgin</option>
                                    <option value='Marathi'>Marathi</option>
                                    <option value='Telugu'>Telugu</option>
                                    <option value='Turkish'>Turkish</option>
                                    <option value='Tamil'>Tamil</option>
                                    <option value='Yue'>Yue Chinese</option>
                                    <option value='Vietnamese'>Vietnamese</option>
                                    <option value='Tagalog'>Tagalog</option>
                                    <option value='Wu'>Wu Chinese</option>
                                    <option value='Korean'>Korean</option>
                                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                                    <option value='Hausa'>Hausa</option>
                                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                                    <option value='Swahili'>Swahili</option>
                                    <option value='Javanese'>Javanese</option>
                                    <option value='Italian'>Italian</option>
                                    <option value='Punjabi'>Western Punjabi</option>
                                    <option value='Kannada'>Kannada</option>
                                    <option value='Gujarati'>Gujarati</option>
                                    <option value='Thai'>Thai</option>
                                  </select>
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p style={{ color: '#bf3c3c', fontSize: '1.5rem' }}>*</p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex '>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                              {finalForm.questions.map((cond: any, i: number) =>
                                question.question === cond.conditionalQuestion &&
                                  question.index === cond.conditionId
                                  ? conditionalQuestions(cond, i)
                                  : null
                              )}
                            </div>
                          </div>
                        ) : null}

                        {/* Type help */}
                        {question.type === 'help' ? (
                          <div className='flex justify-between'>
                            <b>{question.index}</b>&nbsp; &nbsp;
                            <a
                              className='paragraphStyles'
                              href={question.link}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {question.question}
                            </a>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-from-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                            <div className='flex'>
                              <div>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    deleteQuestion(index)
                                  }}
                                >
                                  Borrar
                                </button>
                                <button
                                  style={{
                                    width: '4rem',
                                    fontSize: '0.6rem',
                                    height: '1.2rem',
                                    marginTop: '0',
                                    marginBottom: '0',
                                  }}
                                  className='init-path-btn'
                                  type='button'
                                  onClick={() => {
                                    setQuestionIndex(index)
                                    handleEditClick()
                                  }}
                                >
                                  editar
                                </button>
                              </div>
                              {editMode && questionIndex === index
                                ? editQuestionBlock(question)
                                : null}
                            </div>
                          </div>
                        ) : null}

                        {/* Type postalcode */}
                        {question.type === 'postalcode' && question.dependent === false ? (
                          <div className='flex flex-col gap-12'>
                            <div className='flex justify-between'>
                              <b>{question.index}</b>&nbsp; &nbsp;
                              <p className='question-styles'>{question.question}</p>
                              <div className='flex'>
                                <div>
                                  <PostalCodeSearch
                                    onPostalCodeSelect={(selectedPostalCode: string) => {
                                      question.answer = selectedPostalCode;
                                    }}
                                  />
                                </div>
                                <div className='extra-info'>
                                  {question.info !== '' ? (
                                    <>
                                      <button
                                        type='button'
                                        className='info-styles'
                                        onClick={() => {
                                          question.activeInfo === true
                                            ? (question.activeInfo = false)
                                            : (question.activeInfo = true)
                                          setReadpopup(readpopup + 1)
                                        }}
                                      >
                                        ?
                                      </button>
                                      {readpopup > 0 && question.activeInfo ? (
                                        <div
                                          className='popup-from-style'
                                          dangerouslySetInnerHTML={{ __html: question.info }}
                                        />
                                      ) : null}
                                    </>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                  {question.validate === true ? (
                                    <p
                                      className='info-styles'
                                      style={{ color: '#bf3c3c', fontSize: '1.5rem' }}
                                    >
                                      *
                                    </p>
                                  ) : (
                                    <div className='info-styles' />
                                  )}
                                </div>
                                <div className='flex'>
                                  <div className='flex flex-col gap-2'>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        deleteQuestion(index)
                                      }}
                                    >
                                      Borrar
                                    </button>
                                    <button
                                      style={{
                                        width: '4rem',
                                        fontSize: '0.6rem',
                                        height: '1.2rem',
                                        marginTop: '0',
                                        marginBottom: '0',
                                      }}
                                      className='init-path-btn'
                                      type='button'
                                      onClick={() => {
                                        setQuestionIndex(index)
                                        handleEditClick()
                                      }}
                                    >
                                      editar
                                    </button>
                                  </div>
                                  {editMode && questionIndex === index
                                    ? editQuestionBlock(question)
                                    : null}
                                </div>
                              </div>
                              {finalForm.questions.map((cond: any, i: number) =>
                                question.question === cond.conditionalQuestion &&
                                  question.index === cond.conditionId
                                  ? conditionalQuestions(cond, i)
                                  : null
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                    : null}
                </div>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='init-path-btn'
                  onClick={() => {
                    setEditMode2(false)
                  }}
                >
                  Guardar Formulario
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Link to='/admin/intake'>
                <Button
                  className='btn  btn-primary'
                  style={{
                    width: '8rem',
                    alignSelf: 'end',
                    marginRight: '2rem',
                    border: 'none',
                    marginBottom: '1rem',
                    gap: '0.5rem',
                  }}
                >
                  Regresar
                </Button>
              </Link>
              <Space direction='vertical' style={{ display: 'flex' }}>
                <h1> Seleccione el formulario que desee completar </h1>
                <div>
                  {' '}
                  <ListForms
                    setFormSelected={setFormSelected}
                    setFormIdSelected={setFormIdSelected}
                    setFormDataSelected={setFormDataSelected}
                    setIsEditing={setIsEditing}
                    handleEdit={handleEdit}
                  />
                </div>
              </Space>
            </>
          )}
        </PageHeader>
      </div>
    )
  }
}

export { FormsManager }
