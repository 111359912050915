/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Upload} from 'antd'
import React, {useState, useContext} from 'react'
import {UploadOutlined} from '@ant-design/icons'
import LoadingBar from 'react-top-loading-bar'
import ModalLoader from '../../../../core/components/ModalLoader'
import {RootStoreContext} from '../../../../stores/rootStore'

interface IProps {
  setUploadFiles: React.Dispatch<React.SetStateAction<boolean>>
  setTranslate: React.Dispatch<React.SetStateAction<string>>
  setFilesUploaded: React.Dispatch<React.SetStateAction<boolean>>
  setList: React.Dispatch<React.SetStateAction<boolean>>
  setCounterToRefreshTranslatedDocument: React.Dispatch<React.SetStateAction<number>>
  counterToRefreshTranslatedDocument: number
  translate: string
}
const UploadHandlerStaff: React.FC<IProps> = ({
  setUploadFiles,
  setTranslate,
  setFilesUploaded,
  setList,
  translate,
  setCounterToRefreshTranslatedDocument,
  counterToRefreshTranslatedDocument,
}) => {
  const rootStore = useContext(RootStoreContext)
  const {uploadFilesResult, isLoadingTranslation} = rootStore.translationStore
  const [fileList, setFileList] = useState([])
  const [percent, setPercent] = useState(0)
  const [translationID, setTranslationID] = useState(translate)
  const handleUpload = async () => {
    const result = await uploadFilesResult(
      fileList,
      getProgress,
      'translate',
      translate ? translate : translationID
    )
    if (result != null) {
      setUploadFiles(false)
      setFilesUploaded(false)
      setTranslate('')
      fileList.length = 0
      /* setList(true); */
      setCounterToRefreshTranslatedDocument(counterToRefreshTranslatedDocument + 1)
    } else {
      fileList.length = 0
    }
  }
  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  const handleFileChange = (info: any) => {
    setFileList(info.fileList)
  }
  return (
    <div>
      <Upload
        multiple
        beforeUpload={() => false} // Prevent file list from being cleared on each selection
        fileList={fileList}
        onChange={handleFileChange}
      >
        <Button icon={<UploadOutlined />} type='primary'>
          Seleccionar archivos
        </Button>
      </Upload>
      <Button
        type='primary'
        onClick={handleUpload}
        disabled={fileList.length === 0}
        style={{marginTop: '16px'}}
      >
        Subir archivos
      </Button>
      {fileList.length > 0 && (
        <div>
          <h1>Archivos seleccionados</h1>
          {fileList.map((file: any) => (
            <div key={file.uid}>
              <h2>{file.name}</h2>
              <p>{file.status}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default UploadHandlerStaff
