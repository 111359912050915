import React, {useEffect, useState} from 'react'
import '../style.css'
import {Button, Checkbox} from 'antd'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {useQuery} from 'react-query'
import {
  createNewDocuments,
  deleteDocuments,
  getDocuments,
  updateDocuments,
} from 'app/modules/apps/intake/_requests'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'

const DocumentEditor: React.FC = () => {
  const [icon, setIcon] = useState(<PlusOutlined />)
  const [list, setList] = useState(true)
  const [edit, setEdit] = useState(false)
  const [newDoc, setNewDoc] = useState(false)
  const [newDocument, setNewDocument] = useState<any>(null)

  const DocumentEmpty = {
    id: '',
    name: '',
    description: '',
    comments: '',
    index: 0,
    statusDoc: 'Pendiente por consignar',
    legalize: false,
    translate: false,
    active: false,
  }

  const {data: docData, refetch} = useQuery('getdocss', async () => await getDocuments())

  useEffect(() => {
    if (list) {
      setIcon(<PlusOutlined style={{color: 'white'}} />)
      setNewDocument(DocumentEmpty)
    } else {
      newDoc === true && setIcon(<MinusCircleOutlined style={{color: 'white'}} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const deleteDocs = (id: any) => {
    deleteDocuments(id)
  }

  const saveNewDoc = (newDocument: any) => {
    createNewDocuments(newDocument)
  }

  const updateDoc = (id: string, doc: any) => {
    updateDocuments(id, doc)
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        margin: '2rem 1rem 0',
        padding: '2rem',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Button
        key='addproduct'
        type='primary'
        style={{
          display: 'flex',
          background: 'white',
          border: '1px solid #bf3c3c',
          alignSelf: 'end',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setList(!list)
          setEdit(false) //para que pueda cerrar cuando lo edito
          newDoc === false && edit === false ? setNewDoc(true) : setNewDoc(false)
        }}
        shape='circle'
        icon={icon}
      />
      <h1 style={{fontWeight:'bold'}}>Seleccione el documento que desee editar</h1>
      {edit === false && newDoc === false ? (
        <table style={{width: '100%', borderCollapse: 'separate', borderSpacing: '0 1em'}}>
          <tr style={{backgroundColor: '#fdf2f8', height: '3rem'}}>
            <th style={{paddingLeft: '2rem', borderBottom: '1px solid #ddd'}}>Nombre</th>
            <th style={{borderBottom: '1px solid #ddd'}}>Descripción</th>
            <th style={{borderBottom: '1px solid #ddd'}} />
            <th style={{borderBottom: '1px solid #ddd'}} />
          </tr>
          {docData &&
            docData?.data.map((doc: any) => (
              <tr style={{height: '3rem'}}>
                <td style={{paddingLeft: '2rem', borderBottom: '1px solid #ddd'}}>{doc.name}</td>
                <td style={{borderBottom: '1px solid #ddd'}}>{doc.description}</td>
                <td style={{borderBottom: '1px solid #ddd'}}>
                  <button
                    className='uploader-documents-buttons-available'
                    style={{top: '0'}}
                    onClick={() => {
                      setEdit(true)
                      setNewDocument(doc)
                    }}
                  >
                    Editar
                  </button>
                </td>
                <td style={{borderBottom: '1px solid #ddd'}}>
                  <button
                    className='uploader-documents-buttons-available'
                    style={{top: '0'}}
                    onClick={() => {
                      deleteDocs(doc.id)
                      refetch()
                    }}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
        </table>
      ) : null}
      {/* Edition */}
      {edit === true ? (
        <>
          <div className='mb-8 mt-8'>
            <div className='flex flex-col justify-between mb-8'>
              <h1>Nombre del doumento</h1>
              <input
                defaultValue={newDocument.name}
                type='text'
                className='input-styles'
                style={{width: '30rem'}}
                onChange={(e) => {
                  newDocument.name = e.target.value
                }}
              />
            </div>
            <div className='flex flex-col justify-between mb-8'>
              <h1>Descripción</h1>
              <input
                defaultValue={newDocument.description}
                type='text'
                className='input-styles'
                style={{width: '30rem'}}
                onChange={(e) => (newDocument.description = e.target.value)}
              />
            </div>
            <div className='flex flex-col justify-between'>
              <h1>Disclaimer</h1>
              <textarea
                defaultValue={newDocument.comments}
                maxLength={300}
                className='text-styles'
                style={{width: '30rem'}}
                onChange={(e) => (newDocument.comments = e.target.value)}
              />
            </div>
            <div className='flex mt-8 gap-8'>
              <Checkbox
                defaultChecked={newDocument.translate}
                value='Traducir'
                onChange={(e: CheckboxChangeEvent) => {
                  newDocument.translate = e.target.checked
                }}
              >
                Traducir
              </Checkbox>
              <Checkbox
                defaultChecked={newDocument.legalize}
                value='Legalizar'
                onChange={(e: CheckboxChangeEvent) => {
                  newDocument.legalize = e.target.checked
                }}
              >
                Legalizar
              </Checkbox>
            </div>
          </div>
          <button
            className='uploader-documents-buttons-available'
            style={{top: '0'}}
            onClick={() => {
              updateDoc(newDocument.id, newDocument)
              setEdit(false)
            }}
          >
            Guardar
          </button>
        </>
      ) : null}
      {/* New */}
      {newDoc === true ? (
        <>
          <div className='mb-8 mt-8'>
            <div className='flex flex-col justify-between mb-8'>
              <h1>Nombre del doumento</h1>
              <input
                type='text'
                className='input-styles'
                style={{width: '30rem'}}
                onChange={(e) => {
                  newDocument.name = e.target.value
                }}
              />
            </div>
            <div className='flex flex-col justify-between mb-8'>
              <h1>Descripción</h1>
              <input
                type='text'
                className='input-styles'
                style={{width: '30rem'}}
                onChange={(e) => (newDocument.description = e.target.value)}
              />
            </div>
            <div className='flex flex-col justify-between'>
              <h1>Disclaimer</h1>
              <textarea
                defaultValue={newDocument.comments}
                maxLength={300}
                className='text-styles'
                style={{width: '30rem'}}
                onChange={(e) => (newDocument.comments = e.target.value)}
              />
            </div>
            <div className='flex mt-8 gap-8'>
              <Checkbox
                value='Traducir'
                onChange={(e: CheckboxChangeEvent) => {
                  newDocument.translate = e.target.checked
                }}
              >
                Traducir
              </Checkbox>
              <Checkbox
                value='Legalizar'
                onChange={(e: CheckboxChangeEvent) => {
                  newDocument.legalice = e.target.checked
                }}
              >
                Legalizar
              </Checkbox>
            </div>
          </div>
          <button
            className='uploader-documents-buttons-available'
            style={{top: '0'}}
            onClick={() => {
              saveNewDoc(newDocument)
              setNewDoc(false)
              setList(!list)
            }}
          >
            Guardar
          </button>
        </>
      ) : null}
    </div>
  )
}

export default DocumentEditor
