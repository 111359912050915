 
import {Container, Link} from '../../../../components'
import {type FC} from 'react'

const SuperAdminAppointments: FC = () => {
  return (
    <>
      <Container title='Administrador de citas'>
        <div className='flex gap-4 justify-center flex-wrap'>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/appointmentsbyconsultant/list'
          >
            <img className='w-32' src='/media/svg/menu-icons/Pickup_airport.svg' alt='clientes' />
            <h2>Adminitrador de citas</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/createappointment'
          >
            <img className='w-32' src='/media/svg/menu-icons/calendar.svg' alt='clientes' />
            <h2>Crear Cita</h2>
          </Link>
        </div>
      </Container>
    </>
  )
}

export {SuperAdminAppointments}
