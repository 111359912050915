 
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import xmlbuilder from 'xmlbuilder'
import DownloadIcon from './Icons/downloadBlue2.svg'
import axios from 'axios'
import thunderblue from './Icons/thunderbolt-blue.svg'
import { RootStoreContext } from 'stores/rootStore'
import { toast } from 'react-toastify'
interface IProps {
  product: any
  principalProduct: any
  contractsData: any;
  contractsFound: any;
  lang: string
}

const IMM5708ECONYUGE: React.FC<IProps> = ({product, principalProduct, contractsData, contractsFound }) => {
  //Page 1
  const [age, setAge] = useState('')
  const [isAdult, setIsAdult] = useState(false)
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [visaType, setVisaType] = useState('')
  const [UCI, setUCI] = useState('')
  const [aliasNameIndicator, setAliasNameIndicator] = useState('')
  const [aliasFamilyName, setAliasFamilyName] = useState('')
  const [aliasGivenName, setAliasGivenName] = useState('')
  const [sex, setSex] = useState('')
  const [DOBYear, setDOBYear] = useState('')
  const [DOBMonth, setDOBMonth] = useState('')
  const [DOBDay, setDOBDay] = useState('')
  const [placeBirthCity, setPlaceBirthCity] = useState('')
  const [placeBirthCountry, setPlaceBirthCountry] = useState('')
  const [citizenship, setCitizenship] = useState('')
  const [serviceIn, setServiceIn] = useState('')
  const [CORCountry, setCORCountry] = useState('')
  const [CORStatus, setCORStatus] = useState('')
  const [COROther, setCOROther] = useState('')
  const [CORDates, setCORDates] = useState([])
  const [CORYearFrom, setCORYearFrom] = useState('')
  const [CORMonthFrom, setCORMonthFrom] = useState('')
  const [CORDayFrom, setCORDayFrom] = useState('')
  const [CORYearTo, setCORYearTo] = useState('')
  const [CORMonthTo, setCORMonthTo] = useState('')
  const [CORDayTo, setCORDayTo] = useState('')
  const [SameAsPCRIndicator, setSameAsPCRIndicator] = useState('')
  const [SameAsCORCountry, setSameAsCORCountry] = useState('')
  const [SameAsCORCountryStatus, setSameAsCORCountryStatus] = useState('')
  const [SameAsCORCountryOther, setSameAsCORCountryOther] = useState('')
  const [SameAsCORDates, setSameAsCORDates] = useState([])
  const [SameAsCORYearFrom, setSameAsCORYearFrom] = useState('')
  const [SameAsCORMonthFrom, setSameAsCORMonthFrom] = useState('')
  const [SameAsCORDayFrom, setSameAsCORDayFrom] = useState('')
  const [SameAsCORYearTo, setSameAsCORYearTo] = useState('')
  const [SameAsCORMonthTo, setSameAsCORMonthTo] = useState('')
  const [SameAsCORDayTo, setSameAsCORDayTo] = useState('')
  const [PCRIndicator, setPCRIndicator] = useState('')
  const [PCRCountry, setPCRCountry] = useState('')
  const [PCRStatus, setPCRStatus] = useState('')
  const [PCROther, setPCROther] = useState('')
  const [PCRDates, setPCRDates] = useState([])
  const [PCRYearFrom, setPCRYearFrom] = useState('')
  const [PCRMonthFrom, setPCRMonthFrom] = useState('')
  const [PCRDayFrom, setPCRDayFrom] = useState('')
  const [PCRYearTo, setPCRYearTo] = useState('')
  const [PCRMonthTo, setPCRMonthTo] = useState('')
  const [PCRDayTo, setPCRDayTo] = useState('')
  const [PCRCountry2, setPCRCountry2] = useState('')
  const [PCRStatus2, setPCRStatus2] = useState('')
  const [PCROther2, setPCROther2] = useState('')
  const [PCRDates2, setPCRDates2] = useState([])
  const [PCRYearFrom2, setPCRYearFrom2] = useState('')
  const [PCRMonthFrom2, setPCRMonthFrom2] = useState('')
  const [PCRDayFrom2, setPCRDayFrom2] = useState('')
  const [PCRYearTo2, setPCRYearTo2] = useState('')
  const [PCRMonthTo2, setPCRMonthTo2] = useState('')
  const [PCRDayTo2, setPCRDayTo2] = useState('')
  const [maritalStatus, setmaritalStatus] = useState('')
  const [maritalStatusDate, setmaritalStatusDate] = useState('')
  const [maritalStatusYear, setMaritalStatusYear] = useState('')
  const [maritalStatusMonth, setMaritalStatusMonth] = useState('')
  const [maritalStatusDay, setMaritalStatusDay] = useState('')
  const [maritalStatusGivenName, setMaritalStatusGivenName] = useState('')
  const [maritalStatusFamilyName, setMaritalStatusFamilyName] = useState('')

  //Page 2

  const [prevMarriedIndicator, setPrevMarriedIndicator] = useState('')
  const [PMFamilyNameGivenName, setPMFamilyNameGivenName] = useState('')
  const [PMFamilyName, setPMFamilyName] = useState('')
  const [PMDateOfBirthY, setPMDateOfBirthY] = useState('')
  const [PMDateOfBirthM, setPMDateOfBirthM] = useState('')
  const [PMDateOfBirthD, setPMDateOfBirthD] = useState('')
  const [PMTypeOfRelationship, setPMTypeOfRelationship] = useState('')
  const [PMDateFromDate, setPMDateFromDate] = useState('')
  const [PMDateToDate, setPMDateToDate] = useState('')
  const [PreviouslyMarriedDatesFromY, setPreviouslyMarriedDatesFromY] = useState('')
  const [PreviouslyMarriedDatesFromM, setPreviouslyMarriedDatesFromM] = useState('')
  const [PreviouslyMarriedDatesFromD, setPreviouslyMarriedDatesFromD] = useState('')
  const [PreviouslyMarriedDatesToY, setPreviouslyMarriedDatesToY] = useState('')
  const [PreviouslyMarriedDatesToM, setPreviouslyMarriedDatesToM] = useState('')
  const [PreviouslyMarriedDatesToD, setPreviouslyMarriedDatesToD] = useState('')
  const [passportNum, setPassportNum] = useState('')
  const [passportCountryOfIssue, setPassportCountryOfIssue] = useState('')
  const [passportDateOfIssue, setPassportDateOfIssue] = useState('')
  const [passportExpireDate, setPassportExpireDate] = useState('')
  const [IssueY, setIssueY] = useState('')
  const [IssueM, setIssueM] = useState('')
  const [IssueD, setIssueD] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const [expiryM, setExpiryM] = useState('')
  const [expiryD, setExpiryD] = useState('')
  const [nativeLang, setNativeLang] = useState('')
  const [ableToCommunicate, setAbleToCommunicate] = useState('')
  const [LOV, setLOV] = useState('')
  const [LanguageTest, setLanguageTest] = useState('')
  const [natIDIndicator, setNatIDIndicator] = useState('')
  const [DocNum, setDocNum] = useState('')
  const [IDCountryofIssue, setIDCountryofIssue] = useState('')
  const [IDIssueDate, setIDIssueDate] = useState('')
  const [IDExpiryDate, setIDExpiryDate] = useState('')
  const [usCardIndicator, setusCardIndicator] = useState('')
  const [usCardDocNum, setusCardDocNum] = useState('')
  const [usCardExpiryDate, setusCardExpiryDate] = useState('')
  const [POBox, setPOBox] = useState('')
  const [AptUnit, setAptUnit] = useState('')
  const [StreetNum, setStreetNum] = useState('')
  const [Streetname, setStreetname] = useState('')
  const [CityTown, setCityTown] = useState('')
  const [Country, setCountry] = useState('')
  const [ProvinceState, setProvinceState] = useState('')
  const [PostalCode, setPostalCode] = useState('')
  const [District, setDistrict] = useState('')
  const [SameAsMailingIndicator, setSameAsMailingIndicator] = useState('')
  const [SameAsMailingIndicatorAptUnit, setSameAsMailingIndicatorAptUnit] = useState('')
  const [SameAsMailingIndicatorStreetNum, setSameAsMailingIndicatorStreetNum] = useState('')
  const [SameAsMailingIndicatorStreetname, setSameAsMailingIndicatorStreetname] = useState('')
  const [SameAsMailingIndicatorCityTown, setSameAsMailingIndicatorCityTown] = useState('')
  const [SameAsMailingIndicatorCountry, setSameAsMailingIndicatorCountry] = useState('')
  const [SameAsMailingIndicatorProvince, setSameAsMailingIndicatorProvince] = useState('')
  const [SameAsMailingIndicatorPostalCode, setSameAsMailingIndicatorPostalCode] = useState('')
  const [SameAsMailingIndicatorDistrict, setSameAsMailingIndicatorDistrict] = useState('')

  //Page 3

  const [PurposeOfVisit, setPurposeOfVisit] = useState('')
  const [PurposeOfVisitOther, setPurposeOfVisitOther] = useState('')
  const [HowLongStay, setHowLongStay] = useState([])
  const [HowLongStayFromY, setHowLongStayFromY] = useState('')
  const [HowLongStayFromM, setHowLongStayFromM] = useState('')
  const [HowLongStayFromD, setHowLongStayFromD] = useState('')
  const [HowLongStayToY, setHowLongStayToY] = useState('')
  const [HowLongStayToM, setHowLongStayToM] = useState('')
  const [HowLongStayToD, setHowLongStayToD] = useState('')
  const [HowFounds, setHowFounds] = useState('')
  const [Contacts1Name, setContacts1Name] = useState('')
  const [Contacts1RelationshipToMe, setContacts1RelationshipToMe] = useState('')
  const [Contacts1AddressInCanada, setContacts1AddressInCanada] = useState('')
  const [Contacts2Name, setContacts2Name] = useState('')
  const [Contacts2RelationshipToMe, setContacts2RelationshipToMe] = useState('')
  const [Contacts2AddressInCanada, setContacts2AddressInCanada] = useState('')
  const [EducationIndicator, setEducationIndicator] = useState('')
  const [EducationDatesFromY, setEducationDatesFromY] = useState('')
  const [EducationDatesFromM, setEducationDatesFromM] = useState('')
  const [EducationDatesToY, setEducationDatesToY] = useState('')
  const [EducationDatesToM, setEducationDatesToM] = useState('')
  const [FieldOfStudy, setFieldOfStudy] = useState('')
  const [School, setSchool] = useState('')
  const [SchoolProvince, setSchoolProvince] = useState('')
  const [SchoolCityTown, setSchoolCityTown] = useState('')
  const [SchoolCountry, setSchoolCountry] = useState('')
  const [Ocopation1DatesFromY, setOcopation1DatesFromY] = useState('')
  const [Ocopation1DatesFromM, setOcopation1DatesFromM] = useState('')
  const [Ocopation1DatesToY, setOcopation1DatesToY] = useState('')
  const [Ocopation1DatesToM, setOcopation1DatesToM] = useState('')
  const [Employer1, setEmployer1] = useState('')
  const [Ocupation1, setOcupation1] = useState('')
  const [Ocupation1Province, setOcupation1Province] = useState('')
  const [Ocupation1CityTown, setOcupation1CityTown] = useState('')
  const [Ocupation1Country, setOcupation1Country] = useState('')
  const [Ocupation2Country, setOcupation2Country] = useState('')
  const [Ocopation2DatesFromY, setOcopation2DatesFromY] = useState('')
  const [Ocopation2DatesFromM, setOcopation2DatesFromM] = useState('')
  const [Ocopation2DatesToY, setOcopation2DatesToY] = useState('')
  const [Ocopation2DatesToM, setOcopation2DatesToM] = useState('')
  const [Employer2, setEmployer2] = useState('')
  const [Ocupation2, setOcupation2] = useState('')
  const [Ocupation2Province, setOcupation2Province] = useState('')
  const [Ocupation2CityTown, setOcupation2CityTown] = useState('')
  const [Ocopation3DatesFromY, setOcopation3DatesFromY] = useState('')
  const [Ocopation3DatesFromM, setOcopation3DatesFromM] = useState('')
  const [Ocopation3DatesToY, setOcopation3DatesToY] = useState('')
  const [Ocopation3DatesToM, setOcopation3DatesToM] = useState('')
  const [Employer3, setEmployer3] = useState('')
  const [Ocupation3, setOcupation3] = useState('')
  const [Ocupation3Province, setOcupation3Province] = useState('')
  const [Ocupation3CityTown, setOcupation3CityTown] = useState('')
  const [Ocupation3Country, setOcupation3Country] = useState('')
  const [BackgroundInfoMedicalChoice1, setBackgroundInfoMedicalChoice1] = useState('')
  const [BackgroundInfoMedicalChoice2, setBackgroundInfoMedicalChoice2] = useState('')
  const [BackgroundInfoMedicalDetails, setBackgroundInfoMedicalDetails] = useState('')
  const [BackgroundInfoMedicalDetails2, setBackgroundInfoMedicalDetails2] = useState('')
  const [BackgroundInfoVisaChioce1, setBackgroundInfoVisaChioce1] = useState('')
  const [BackgroundInfoVisaChioce2, setBackgroundInfoVisaChioce2] = useState('')
  const [BackgroundInfoVisaChioce3, setBackgroundInfoVisaChioce3] = useState('')
  const [BackgroundInfoVisaChioceDetails, setBackgroundInfoVisaChioceDetails] = useState('')
  const [BackgroundInfoVisaChioceDetails2, setBackgroundInfoVisaChioceDetails2] = useState('')
  const [BackgroundInfoVisaChioceDetails3, setBackgroundInfoVisaChioceDetails3] = useState('')
  const [BackgroundInfo3Choice, setBackgroundInfo3Choice] = useState('')
  const [BackgroundInfo3Details, setBackgroundInfo3Details] = useState('')
  const [MilitarChoice, setMilitarChoice] = useState('')
  const [MilitarDetails, setMilitarDetails] = useState('')
  const [PoliticalAssociation, setPoliticalAssociation] = useState('')
  const [PoliticalLoot, setPoliticalLoot] = useState('')
  const fileName = `IMM5708E ${product.memberName} ${product.name || 'formulario'}.xml`;
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const { connectSocket } = rootStore.notificationStore
  const { user: userAUTH} = rootStore.authStore
  const countries = (country: any) => {
    let Tcountry = ''
    switch (country) {
      case 'Afganistan':
        Tcountry = 'AFG (Afganistan)'
        break
      case 'Angola':
        Tcountry = 'AGO (Angola)'
        break
      case 'Albania':
        Tcountry = 'ALB (Albania)'
        break
      case 'Andorra':
        Tcountry = 'AND (Andorra)'
        break
      case 'Anguilla':
        Tcountry = 'ANG (Anguilla)'
        break
      case 'United Arab Emirates':
        Tcountry = 'ARE (United Arab Emirates)'
        break
      case 'Argentina':
        Tcountry = 'ARG (Argentina)'
        break
      case 'Armenia':
        Tcountry = 'ARM (Armenia)'
        break
      case 'Antigua and Barbuda':
        Tcountry = 'ATG (Antigua and Barbuda)'
        break
      case 'Australia':
        Tcountry = 'AUS (Australia)'
        break
      case 'Austria':
        Tcountry = 'AUT (Austria)'
        break
      case 'Azerbaijan':
        Tcountry = 'AZE (Azerbaijan)'
        break
      case 'Burundi':
        Tcountry = 'BDI (Burundi)'
        break
      case 'Belgium':
        Tcountry = 'BEL (Belgium)'
        break
      case 'Benin':
        Tcountry = 'BEN (Benin)'
        break
      case 'Burkina Faso':
        Tcountry = 'BFA (Burkina Faso)'
        break
      case 'Bangladesh':
        Tcountry = 'BGD (Bangladesh)'
        break
      case 'Bulgaria':
        Tcountry = 'BGR (Bulgaria)'
        break
      case 'Bahrain':
        Tcountry = 'BHR (Bahrain)'
        break
      case 'Bahamas':
        Tcountry = 'BHS (Bahamas)'
        break
      case 'Bosnia and Herzegovina':
        Tcountry = 'BIH (Bosnia and Herzegovina)'
        break
      case 'Belarus':
        Tcountry = 'BLR (Belarus)'
        break
      case 'Belize':
        Tcountry = 'BLZ (Belize)'
        break
      case 'Bermuda':
        Tcountry = 'BMU (Bermuda)'
        break
      case 'Bolivia':
        Tcountry = 'BOL (Bolivia)'
        break
      case 'Brazil':
        Tcountry = 'BRA (Brazil)'
        break
      case 'Barbados':
        Tcountry = 'BRB (Barbados)'
        break
      case 'Brunei Darussalam':
        Tcountry = 'BRN (Brunei Darussalam)'
        break
      case 'Bhutan':
        Tcountry = 'BTN (Bhutan)'
        break
      case 'Botswana':
        Tcountry = 'BWA (Botswana)'
        break
      case 'Central African Republic':
        Tcountry = 'CAF (Central African Republic)'
        break
      case 'Canada':
        Tcountry = 'CAN (Canada)'
        break
      case 'Switzerland':
        Tcountry = 'CHE (Switzerland)'
        break
      case 'Chile':
        Tcountry = 'CHL (Chile)'
        break
      case 'China (Hong Kong SAR)':
        Tcountry = 'CHN (China (Hong Kong SAR))'
        break
      case 'China (Macao SAR)':
        Tcountry = 'CHN (China (Macao SAR))'
        break
      case 'China':
        Tcountry = 'CHN (China)'
        break
      case 'Ivory Coast':
        Tcountry = 'CIV (Ivory Coast)'
        break
      case 'Cameroon':
        Tcountry = 'CMR (Cameroon)'
        break
      case 'Democratic Rep. of Congo':
        Tcountry = 'COD (Democratic Rep. of Congo)'
        break
      case 'Republic of Congo':
        Tcountry = 'COG (Republic of Congo)'
        break
      case 'Colombia':
        Tcountry = 'COL (Colombia)'
        break
      case 'Comoros':
        Tcountry = 'COM (Comoros)'
        break
      case 'Cabo Verde':
        Tcountry = 'CPV (Cabo Verde)'
        break
      case 'Costa Rica':
        Tcountry = 'CRI (Costa Rica)'
        break
      case 'Cuba':
        Tcountry = 'CUB (Cuba)'
        break
      case 'Cayman Islands':
        Tcountry = 'CYM (Cayman Islands)'
        break
      case 'Cyprus':
        Tcountry = 'CYP (Cyprus)'
        break
      case 'Czech Republic':
        Tcountry = 'CZE (Czech Republic)'
        break
      case 'Germany, Federal Republic Of':
        Tcountry = 'D (Germany, Federal Republic Of)'
        break
      case 'Djibouti':
        Tcountry = 'DJI (Djibouti)'
        break
      case 'Dominica':
        Tcountry = 'DMA (Dominica)'
        break
      case 'Denmark':
        Tcountry = 'DNK (Denmark)'
        break
      case 'Dominican Republic':
        Tcountry = 'DOM (Dominican Republic)'
        break
      case 'Algeria':
        Tcountry = 'DZA (Algeria)'
        break
      case 'Ecuador':
        Tcountry = 'ECU (Ecuador)'
        break
      case 'Egypt':
        Tcountry = 'EGY (Egypt)'
        break
      case 'Eritrea':
        Tcountry = 'ERI (Eritrea)'
        break
      case 'Spain':
        Tcountry = 'ESP (Spain)'
        break
      case 'Estonia':
        Tcountry = 'EST (Estonia)'
        break
      case 'Ethiopia':
        Tcountry = 'ETH (Ethiopia)'
        break
      case 'Finland':
        Tcountry = 'FIN (Finland)'
        break
      case 'Fiji':
        Tcountry = 'FJI (Fiji)'
        break
      case 'France':
        Tcountry = 'FRA (France)'
        break
      case 'Faroe Islands':
        Tcountry = 'FRO (Faroe Islands)'
        break
      case 'Federated States of Micronesia':
        Tcountry = 'FSM (Federated States of Micronesia)'
        break
      case 'Gabon':
        Tcountry = 'GAB (Gabon)'
        break
      case 'UK - Brit. overseas terr.':
        Tcountry = 'GBD (UK - Brit. overseas terr.)'
        break
      case 'UK - Brit. Ntl. Overseas':
        Tcountry = 'GBN (UK - Brit. Ntl. Overseas)'
        break
      case 'UK - Brit. overseas citizen':
        Tcountry = 'GBO (UK - Brit. overseas citizen)'
        break
      case 'UK - Brit. protected person':
        Tcountry = 'GBP (UK - Brit. protected person)'
        break
      case 'UK - British citizen':
        Tcountry = 'GBR (UK - British citizen)'
        break
      case 'UK - British subject':
        Tcountry = 'GBS (UK - British subject)'
        break
      case 'Georgia':
        Tcountry = 'GEO (Georgia)'
        break
      case 'Ghana':
        Tcountry = 'GHA (Ghana)'
        break
      case 'Guinea':
        Tcountry = 'GIN (Guinea)'
        break
      case 'Gambia':
        Tcountry = 'GMB (Gambia)'
        break
      case 'Guinea-Bissau':
        Tcountry = 'GNB (Guinea-Bissau)'
        break
      case 'Equatorial Guinea':
        Tcountry = 'GNQ (Equatorial Guinea)'
        break
      case 'Greece':
        Tcountry = 'GRC (Greece)'
        break
      case 'Grenada':
        Tcountry = 'GRD (Grenada)'
        break
      case 'Guatemala':
        Tcountry = 'GTM (Guatemala)'
        break
      case 'Guyana':
        Tcountry = 'GUY (Guyana)'
        break
      case 'Honduras':
        Tcountry = 'HND (Honduras)'
        break
      case 'Croatia':
        Tcountry = 'HRV (Croatia)'
        break
      case 'Haiti':
        Tcountry = 'HTI (Haiti)'
        break
      case 'Hungary':
        Tcountry = 'HUN (Hungary)'
        break
      case 'Indonesia':
        Tcountry = 'IDN (Indonesia)'
        break
      case 'India':
        Tcountry = 'IND (India)'
        break
      case 'Ireland':
        Tcountry = 'IRL (Ireland)'
        break
      case 'Iran':
        Tcountry = 'IRN (Iran)'
        break
      case 'Iraq':
        Tcountry = 'IRQ (Iraq)'
        break
      case 'Iceland':
        Tcountry = 'ISL (Iceland)'
        break
      case 'Israel':
        Tcountry = 'ISR (Israel)'
        break
      case 'Italy':
        Tcountry = 'ITA (Italy)'
        break
      case 'Jamaica':
        Tcountry = 'JAM (Jamaica)'
        break
      case 'Jordan':
        Tcountry = 'JOR (Jordan)'
        break
      case 'Japan':
        Tcountry = 'JPN (Japan)'
        break
      case 'Kazakhstan':
        Tcountry = 'KAZ (Kazakhstan)'
        break
      case 'Kenya':
        Tcountry = 'KEN (Kenya)'
        break
      case 'Kyrgyzstan':
        Tcountry = 'KGZ (Kyrgyzstan)'
        break
      case 'Cambodia':
        Tcountry = 'KHM (Cambodia)'
        break
      case 'Kiribati':
        Tcountry = 'KIR (Kiribati)'
        break
      case 'Saint Kitts and Nevis':
        Tcountry = 'KNA (Saint Kitts and Nevis)'
        break
      case 'Korea, South':
        Tcountry = 'KOR (Korea, South)'
        break
      case 'Kuwait':
        Tcountry = 'KWT (Kuwait)'
        break
      case 'Laos':
        Tcountry = 'LAO (Laos)'
        break
      case 'Lebanon':
        Tcountry = 'LBN (Lebanon)'
        break
      case 'Liberia':
        Tcountry = 'LBR (Liberia)'
        break
      case 'Libya':
        Tcountry = 'LBY (Libya)'
        break
      case 'Saint Lucia':
        Tcountry = 'LCA (Saint Lucia)'
        break
      case 'Liechtenstein':
        Tcountry = 'LIE (Liechtenstein)'
        break
      case 'Sri Lanka':
        Tcountry = 'LKA (Sri Lanka)'
        break
      case 'Lesotho':
        Tcountry = 'LSO (Lesotho)'
        break
      case 'Lithuania':
        Tcountry = 'LTU (Lithuania)'
        break
      case 'Luxembourg':
        Tcountry = 'LUX (Luxembourg)'
        break
      case 'Latvia':
        Tcountry = 'LVA (Latvia)'
        break
      case 'Morocco':
        Tcountry = 'MAR (Morocco)'
        break
      case 'Monaco':
        Tcountry = 'MCO (Monaco)'
        break
      case 'Moldova':
        Tcountry = 'MDA (Moldova)'
        break
      case 'Madagascar':
        Tcountry = 'MDG (Madagascar)'
        break
      case 'Maldives':
        Tcountry = 'MDV (Maldives)'
        break
      case 'Mexico':
        Tcountry = 'MEX (Mexico)'
        break
      case 'Marshall Islands':
        Tcountry = 'MHL (Marshall Islands)'
        break
      case 'Macedonia':
        Tcountry = 'MKD (Macedonia)'
        break
      case 'Mali':
        Tcountry = 'MLI (Mali)'
        break
      case 'Malta':
        Tcountry = 'MLT (Malta)'
        break
      case 'Burma (Myanmar)':
        Tcountry = 'MMR (Burma (Myanmar))'
        break
      case 'Montenegro':
        Tcountry = 'MNE (Montenegro)'
        break
      case 'Mongolia':
        Tcountry = 'MNG (Mongolia)'
        break
      case 'Mozambique':
        Tcountry = 'MOZ (Mozambique)'
        break
      case 'Mauritania':
        Tcountry = 'MRT (Mauritania)'
        break
      case 'Montserrat':
        Tcountry = 'MSR (Montserrat)'
        break
      case 'Mauritius':
        Tcountry = 'MUS (Mauritius)'
        break
      case 'Malawi':
        Tcountry = 'MWI (Malawi)'
        break
      case 'Malaysia':
        Tcountry = 'MYS (Malaysia)'
        break
      case 'Namibia':
        Tcountry = 'NAM (Namibia)'
        break
      case 'New Caledonia':
        Tcountry = 'NCL (New Caledonia)'
        break
      case 'Niger':
        Tcountry = 'NER (Niger)'
        break
      case 'Nigeria':
        Tcountry = 'NGA (Nigeria)'
        break
      case 'Nicaragua':
        Tcountry = 'NIC (Nicaragua)'
        break
      case 'Niue':
        Tcountry = 'NIU (Niue)'
        break
      case 'Netherlands':
        Tcountry = 'NLD (Netherlands)'
        break
      case 'Norway':
        Tcountry = 'NOR (Norway)'
        break
      case 'Nepal':
        Tcountry = 'NPL (Nepal)'
        break
      case 'Nauru':
        Tcountry = 'NRU (Nauru)'
        break
      case 'New Zealand':
        Tcountry = 'NZL (New Zealand)'
        break
      case 'Oman':
        Tcountry = 'OMN (Oman)'
        break
      case 'Pakistan':
        Tcountry = 'PAK (Pakistan)'
        break
      case 'Panama':
        Tcountry = 'PAN (Panama)'
        break
      case 'Pitcairn Islands':
        Tcountry = 'PCN (Pitcairn Islands)'
        break
      case 'Peru':
        Tcountry = 'PER (Peru)'
        break
      case 'Philippines':
        Tcountry = 'PHL (Philippines)'
        break
      case 'Republic of Palau':
        Tcountry = 'PLW (Republic of Palau)'
        break
      case 'Papua New Guinea':
        Tcountry = 'PNG (Papua New Guinea)'
        break
      case 'Poland':
        Tcountry = 'POL (Poland)'
        break
      case 'Puerto Rico':
        Tcountry = 'PRI (Puerto Rico)'
        break
      case 'Korea, North':
        Tcountry = 'PRK (Korea, North (DPRK))'
        break
      case 'Portugal':
        Tcountry = 'PRT (Portugal)'
        break
      case 'Paraguay':
        Tcountry = 'PRY (Paraguay)'
        break
      case 'Palestinian Authority':
        Tcountry = 'PSE (Palestinian Authority)'
        break
      case 'French Polynesia':
        Tcountry = 'PYF (French Polynesia)'
        break
      case 'Qatar':
        Tcountry = 'QAT (Qatar)'
        break
      case 'Reunion':
        Tcountry = 'REU (Reunion)'
        break
      case 'Romania':
        Tcountry = 'ROU (Romania)'
        break
      case 'Russia':
        Tcountry = 'RUS (Russia)'
        break
      case 'Rwanda':
        Tcountry = 'RWA (Rwanda)'
        break
      case 'Saudi Arabia':
        Tcountry = 'SAU (Saudi Arabia)'
        break
      case 'Sudan':
        Tcountry = 'SDN (Sudan)'
        break
      case 'Senegal':
        Tcountry = 'SEN (Senegal)'
        break
      case 'Singapore':
        Tcountry = 'SGP (Singapore)'
        break
      case 'South Georgia and South Sandwich Islands':
        Tcountry = 'SGS (South Georgia and South Sandwich Islands)'
        break
      case 'Saint Helena':
        Tcountry = 'SHN (Saint Helena)'
        break
      case 'Svalbard and Jan Mayen Islands':
        Tcountry = 'SJM (Svalbard and Jan Mayen Islands)'
        break
      case 'Solomon Islands':
        Tcountry = 'SLB (Solomon Islands)'
        break
      case 'Sierra Leone':
        Tcountry = 'SLE (Sierra Leone)'
        break
      case 'El Salvador':
        Tcountry = 'SLV (El Salvador)'
        break
      case 'San Marino':
        Tcountry = 'SMR (San Marino)'
        break
      case 'Somalia':
        Tcountry = 'SOM (Somalia)'
        break
      case 'Saint Pierre and Miquelon':
        Tcountry = 'SPM (Saint Pierre and Miquelon)'
        break
      case 'Serbia, Republic of':
        Tcountry = 'SRB (Serbia, Republic of)'
        break
      case 'South Sudan':
        Tcountry = 'SSD (South Sudan)'
        break
      case 'Sao Tome and Principe':
        Tcountry = 'STP (Sao Tome and Principe)'
        break
      case 'Suriname':
        Tcountry = 'SUR (Suriname)'
        break
      case 'Slovakia':
        Tcountry = 'SVK (Slovakia)'
        break
      case 'Slovenia':
        Tcountry = 'SVN (Slovenia)'
        break
      case 'Sweden':
        Tcountry = 'SWE (Sweden)'
        break
      case 'Swaziland':
        Tcountry = 'SWZ (Swaziland)'
        break
      case 'Seychelles':
        Tcountry = 'SYC (Seychelles)'
        break
      case 'Syria':
        Tcountry = 'SYR (Syria)'
        break
      case 'Turks and Caicos Islands':
        Tcountry = 'TCA (Turks and Caicos Islands)'
        break
      case 'Chad':
        Tcountry = 'TCD (Chad)'
        break
      case 'Togo':
        Tcountry = 'TGO (Togo)'
        break
      case 'Thailand':
        Tcountry = 'THA (Thailand)'
        break
      case 'Tajikistan':
        Tcountry = 'TJK (Tajikistan)'
        break
      case 'Tokelau':
        Tcountry = 'TKL (Tokelau)'
        break
      case 'Turkmenistan':
        Tcountry = 'TKM (Turkmenistan)'
        break
      case 'East Timor':
        Tcountry = 'TLS (East Timor)'
        break
      case 'Tonga':
        Tcountry = 'TON (Tonga)'
        break
      case 'Trinidad and Tobago':
        Tcountry = 'TTO (Trinidad and Tobago)'
        break
      case 'Tunisia':
        Tcountry = 'TUN (Tunisia)'
        break
      case 'Turkey':
        Tcountry = 'TUR (Turkey)'
        break
      case 'Tuvalu':
        Tcountry = 'TUV (Tuvalu)'
        break
      case 'Taiwan':
        Tcountry = 'TWN (Taiwan)'
        break
      case 'Tanzania':
        Tcountry = 'TZA (Tanzania)'
        break
      case 'Uganda':
        Tcountry = 'UGA (Uganda)'
        break
      case 'Ukraine':
        Tcountry = 'UKR (Ukraine)'
        break
      case 'UN specialized agency':
        Tcountry = 'UNA (UN specialized agency)'
        break
      case 'UN or official':
        Tcountry = 'UNO (UN or official)'
        break
      case 'Uruguay':
        Tcountry = 'URY (Uruguay)'
        break
      case 'United States of America':
        Tcountry = 'USA (United States of America)'
        break
      case 'Uzbekistan':
        Tcountry = 'UZB (Uzbekistan)'
        break
      case 'Vatican City':
        Tcountry = 'VAT (Vatican City)'
        break
      case 'St. Vincent and the Grenadines':
        Tcountry = 'VCT (St. Vincent and the Grenadines)'
        break
      case 'Venezuela':
        Tcountry = 'VEN (Venezuela)'
        break
      case 'British Virgin Islands':
        Tcountry = 'VGB (British Virgin Islands)'
        break
      case 'Vietnam':
        Tcountry = 'VNM (Vietnam)'
        break
      case 'Vanuatu':
        Tcountry = 'VUT (Vanuatu)'
        break
      case 'Samoa':
        Tcountry = 'WSM (Samoa)'
        break
      case 'Yemen':
        Tcountry = 'YEM (Yemen)'
        break
      case 'South Africa, Republic of':
        Tcountry = 'ZAF (South Africa, Republic of)'
        break
      case 'Zambia':
        Tcountry = 'ZMB (Zambia)'
        break
      case 'Zimbabwe':
        Tcountry = 'ZWE (Zimbabwe)'
        break
      default:
        Tcountry = 'Código de país no válido'
        break
    }

    return Tcountry
  }

  const setAnswers = () => {
    if (product.name === 'Visitor visa') {
      setVisaType('Visitor Visa')
    } else if (product.name === 'Transit visa') {
      setVisaType('Transit')
    } else {
      setVisaType('')
    }

    product.forms.map((form: any) => {
      // Formulario de Detalles personales
      if (form.formData.formName === '001') {
        form.formData.questions.map((q: any, index: number) => {
          if (q.index === '003') {
            setGivenName(q.answer)
          }
          if (q.index === '002') {
            setFamilyName(q.answer)
          }
          if (q.index === '008') {
            setUCI(q.answer)
          }
          if (q.index === '004') {
            q.answer === 'Si'
              ? setAliasNameIndicator('Y')
              : q.answer === 'No'
              ? setAliasNameIndicator('N')
              : setAliasNameIndicator('')
          }
          if (q.index === '006') {
            setAliasFamilyName(q.answer)
          }
          if (q.index === '007') {
            setAliasGivenName(q.answer)
          }
          if (q.index === '009') {
            q.answer === 'Femenino'
              ? setSex('F Female')
              : q.answer === 'Masculino'
              ? setSex('M Male')
              : q.answer === 'Desconocido'
              ? setSex('U Unknown')
              : q.answer === 'Otro genero'
              ? setSex('X Another gender')
              : setSex('')
          }
          if (q.index === '011' && q.answer.trim() !== '') {
            setDOBYear(q.answer.substring(0, 4))
            setDOBMonth(q.answer.substring(5, 7))
            setDOBDay(q.answer.substring(8))
          }
          if (q.index === '011') {
            const birthDate = new Date(q.answer)
            const today = new Date()
            let ageDifference = today.getFullYear() - birthDate.getFullYear()
            const monthsDifference = today.getMonth() - birthDate.getMonth()
            if (
              monthsDifference < 0 ||
              (monthsDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
              ageDifference--
            }
            const isAdult = ageDifference >= 18
            const currentAge = ageDifference
            setAge(currentAge.toString())
            setIsAdult(isAdult)
          }
          if (q.index === '012') {
            setPlaceBirthCity(q.answer)
          }
          if (q.index === '013') {
            setPlaceBirthCountry(q.answer)
          }
          if (q.index === '015') {
            setCitizenship(q.answer)
          }
          if (q.index === '016') {
            if (q.answer === 'Inglés') {
              setServiceIn('English')
            }
            if (q.answer === 'Francés') {
              setServiceIn('French')
            }
          }
        })
      }

      // Formulario de País de residencia actual
      if (form.formData.formName === '028') {
        form.formData.questions.map((q: any) => {
          if (q.index === '002') {
            setCORCountry(q.answer)
          }
          if (q.index === '003') {
            if (q.answer === 'Ciudadano') {
              setCORStatus('01')
            }
            if (q.answer === 'Residente permanente') {
              setPCRStatus('02')
            }
            if (q.answer === 'Visitante') {
              setCORStatus('03')
            }
            if (q.answer === 'Trabajador') {
              setCORStatus('04')
            }
            if (q.answer === 'Estudiante') {
              setCORStatus('05')
            }
            if (q.answer === 'Persona protegida') {
              setCORStatus('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setCORStatus('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setCORStatus('09')
            }
            if (q.answer === 'Otro') {
              setCORStatus('06')
            }
          }
          if (q.index === '004') {
            setCOROther(q.answer)
          }
          if (
            (q.index === '005' && q.multipleAnswer.length > 0) ||
            (q.index === '006' && q.multipleAnswer.length > 0) ||
            (q.index === '007' && q.multipleAnswer.length > 0) ||
            (q.index === '008' && q.multipleAnswer.length > 0)
          ) {
            setCORDates(q.multipleAnswer)
          }
          if (
            (q.index === '005' && q.multipleAnswer.length > 0) ||
            (q.index === '006' && q.multipleAnswer.length > 0) ||
            (q.index === '007' && q.multipleAnswer.length > 0) ||
            (q.index === '008' && q.multipleAnswer.length > 0)
          ) {
            setCORYearFrom(q.multipleAnswer[0].substring(0, 4))
            setCORMonthFrom(q.multipleAnswer[0].substring(5, 7))
            setCORDayFrom(q.multipleAnswer[0].substring(8))
          }
          if (
            (q.index === '005' && q.multipleAnswer.length > 0) ||
            (q.index === '006' && q.multipleAnswer.length > 0) ||
            (q.index === '007' && q.multipleAnswer.length > 0) ||
            (q.index === '008' && q.multipleAnswer.length > 0)
          ) {
            setCORYearTo(q.multipleAnswer[1].substring(0, 4))
            setCORMonthTo(q.multipleAnswer[1].substring(5, 7))
            setCORDayTo(q.multipleAnswer[1].substring(8))
          }
          if (q.index === '009') {
            q.answer === 'Si'
              ? setSameAsPCRIndicator('Y')
              : q.answer === 'No'
              ? setSameAsPCRIndicator('N')
              : setSameAsPCRIndicator('')
          }
          if (q.index === '011') {
            setSameAsCORCountry(q.answer)
          }
          if (q.index === '012') {
            if (q.answer === 'Ciudadano') {
              setSameAsCORCountryStatus('01')
            }
            if (q.answer === 'Residente permanente') {
              setSameAsCORCountryStatus('02')
            }
            if (q.answer === 'Visitante') {
              setSameAsCORCountryStatus('03')
            }
            if (q.answer === 'Trabajador') {
              setSameAsCORCountryStatus('04')
            }
            if (q.answer === 'Estudiante') {
              setSameAsCORCountryStatus('05')
            }
            if (q.answer === 'Persona protegida') {
              setSameAsCORCountryStatus('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setSameAsCORCountryStatus('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setSameAsCORCountryStatus('09')
            }
            if (q.answer === 'Otro') {
              setSameAsCORCountryStatus('06')
            }
          }

          if (q.index === '013') {
            setSameAsCORCountryOther(q.answer)
          }

          if (
            (q.index === '014' && q.multipleAnswer.length > 0) ||
            (q.index === '015' && q.multipleAnswer.length > 0) ||
            (q.index === '016' && q.multipleAnswer.length > 0) ||
            (q.index === '017' && q.multipleAnswer.length > 0)
          ) {
            setSameAsCORDates(q.multipleAnswer)
          }
          if (
            (q.index === '014' && q.multipleAnswer.length > 0) ||
            (q.index === '015' && q.multipleAnswer.length > 0) ||
            (q.index === '016' && q.multipleAnswer.length > 0) ||
            (q.index === '017' && q.multipleAnswer.length > 0)
          ) {
            setSameAsCORYearFrom(q.multipleAnswer[0].substring(0, 4))
            setSameAsCORMonthFrom(q.multipleAnswer[0].substring(5, 7))
            setSameAsCORDayFrom(q.multipleAnswer[0].substring(8))
          }
          if (
            (q.index === '014' && q.multipleAnswer.length > 0) ||
            (q.index === '015' && q.multipleAnswer.length > 0) ||
            (q.index === '016' && q.multipleAnswer.length > 0) ||
            (q.index === '017' && q.multipleAnswer.length > 0)
          ) {
            setSameAsCORYearTo(q.multipleAnswer[1].substring(0, 4))
            setSameAsCORMonthTo(q.multipleAnswer[1].substring(5, 7))
            setSameAsCORDayTo(q.multipleAnswer[1].substring(8))
          }
        })
      }

      // Formulario País de residencia anterior
      if (form.formData.formName === '029') {
        form.formData.questions.map((q: any, index: number) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setPCRIndicator('Y')
              : q.answer === 'No'
              ? setPCRIndicator('N')
              : setPCRIndicator('')
          }
          if (q.index === '003') {
            setPCRCountry(q.answer)
          }
          if (q.index === '004') {
            if (q.answer === 'Ciudadano') {
              setPCRStatus('01')
            }
            if (q.answer === 'Residente permanente') {
              setPCRStatus('02')
            }
            if (q.answer === 'Visitante') {
              setPCRStatus('03')
            }
            if (q.answer === 'Trabajador') {
              setPCRStatus('04')
            }
            if (q.answer === 'Estudiante') {
              setPCRStatus('05')
            }
            if (q.answer === 'Persona protegida') {
              setPCRStatus('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setPCRStatus('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setPCRStatus('09')
            }
            if (q.answer === 'Otro') {
              setPCRStatus('06')
            }
          }
          if (q.index === '006') {
            setPCROther(q.answer)
          }
          if (q.index === '005') {
            setPCRDates(q.multipleAnswer)
          }
          if (q.index === '005') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer[0] !== '' && q.multipleAnswer[1] !== '') {
                setPCRYearFrom(q.multipleAnswer[0].substring(0, 4))
                setPCRMonthFrom(q.multipleAnswer[0].substring(5, 7))
                setPCRDayFrom(q.multipleAnswer[0].substring(8))

                setPCRYearTo(q.multipleAnswer[1].substring(0, 4))
                setPCRMonthTo(q.multipleAnswer[1].substring(5, 7))
                setPCRDayTo(q.multipleAnswer[1].substring(8))
              }
            }
          }
          if (q.index === '008') {
            setPCRCountry2(q.answer)
            console.log('PCRCountry', q.answer, setPCRCountry2)
          }
          if (q.index === '009') {
            if (q.answer === 'Ciudadano') {
              setPCRStatus2('01')
            }
            if (q.answer === 'Residente permanente') {
              setPCRStatus2('02')
            }
            if (q.answer === 'Visitante') {
              setPCRStatus2('03')
            }
            if (q.answer === 'Trabajador') {
              setPCRStatus2('04')
            }
            if (q.answer === 'Estudiante') {
              setPCRStatus2('05')
            }
            if (q.answer === 'Persona protegida') {
              setPCRStatus2('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setPCRStatus2('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setPCRStatus2('09')
            }
            if (q.answer === 'Otro') {
              setPCRStatus2('06')
            }
          }
          if (q.index === '011') {
            setPCROther2(q.answer)
          }
          if (q.index === '010') {
            setPCRDates2(q.multipleAnswer)
          }
          if (q.index === '010') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer[0] !== '' && q.multipleAnswer[1] !== '') {
                setPCRYearFrom2(q.multipleAnswer[0].substring(0, 4))
                setPCRMonthFrom2(q.multipleAnswer[0].substring(5, 7))
                setPCRDayFrom2(q.multipleAnswer[0].substring(8))

                setPCRYearTo2(q.multipleAnswer[1].substring(0, 4))
                setPCRMonthTo2(q.multipleAnswer[1].substring(5, 7))
                setPCRDayTo2(q.multipleAnswer[1].substring(8))
              }
            }
          }
        })
      }

      // Formulario Estado civil actual
      if (form.formData.formName === '009') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            if (q.answer === 'Soltero') {
              setmaritalStatus('02')
            }
            if (q.answer === 'Casado') {
              setmaritalStatus('01')
            }
            if (q.answer === 'Legalmente separado') {
              setmaritalStatus('05')
            }
            if (q.answer === 'Unión de hecho (12 meses de convivencia)') {
              setmaritalStatus('03')
            }
            if (q.answer === 'Matrimonio anulado') {
              setmaritalStatus('09')
            }
            if (q.answer === 'Divorciado') {
              setmaritalStatus('04')
            }
            if (q.answer === 'Viudo') {
              setmaritalStatus('06')
            }
            if (q.answer === 'Desconocido') {
              setmaritalStatus('00')
            }
          }
          if ((q.index === '002' && q.answer !== '') || (q.index === '006' && q.answer !== '')) {
            setmaritalStatusDate(q.answer)
          }
          if ((q.index === '002' && q.answer !== '') || (q.index === '006' && q.answer !== '')) {
            setMaritalStatusYear(q.answer.substring(0, 4))
            setMaritalStatusMonth(q.answer.substring(5, 7))
            setMaritalStatusDay(q.answer.substring(8))
          }
          if ((q.index === '005' && q.answer !== '') || (q.index === '009' && q.answer !== '')) {
            setMaritalStatusGivenName(q.answer)
          }
          if ((q.index === '004' && q.answer !== '') || (q.index === '008' && q.answer !== '')) {
            setMaritalStatusFamilyName(q.answer)
          }
          if (q.index === '012') {
            q.answer === 'Si'
              ? setPrevMarriedIndicator('Y')
              : q.answer === 'No'
              ? setPrevMarriedIndicator('N')
              : setPrevMarriedIndicator('')
          }
          if (q.index === '016') {
            setPMDateOfBirthY(q.answer.substring(0, 4))
            setPMDateOfBirthM(q.answer.substring(5, 7))
            setPMDateOfBirthD(q.answer.substring(8))
          }
          if (q.index === '014') {
            setPMFamilyName(q.answer)
          }
          if (q.index === '015') {
            setPMFamilyNameGivenName(q.answer)
          }
          if (q.index === '017') {
            setPMTypeOfRelationship(q.answer)
          }
          if (q.index === '018' && q.multipleAnswer && q.multipleAnswer.length > 0) {
            setPMDateFromDate(q.multipleAnswer[0])
            setPMDateToDate(q.multipleAnswer[1])
            setPreviouslyMarriedDatesFromY(q.multipleAnswer[0].substring(0, 4))
            setPreviouslyMarriedDatesFromM(q.multipleAnswer[0].substring(5, 7))
            setPreviouslyMarriedDatesFromD(q.multipleAnswer[0].substring(8))
            setPreviouslyMarriedDatesToY(q.multipleAnswer[1].substring(0, 4))
            setPreviouslyMarriedDatesToM(q.multipleAnswer[1].substring(5, 7))
            setPreviouslyMarriedDatesToD(q.multipleAnswer[1].substring(8))
          }
        })
      }

      // Formulario de Pasaporte
      if (form.formData.formName === '003') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            setPassportNum(q.answer)
          }
          if (q.index === '003') {
            const countryOfIssue = countries(q.answer)
            setPassportCountryOfIssue(countryOfIssue)
          }
          if (q.index === '004') {
            if (q.answer !== '') {
              setPassportDateOfIssue(q.answer)
              setIssueY(q.answer.substring(0, 4))
              setIssueM(q.answer.substring(5, 7))
              setIssueD(q.answer.substring(8))
            }
          }
          if (q.index === '005') {
            if (q.answer !== '') {
              setPassportExpireDate(q.answer)
              setExpiryY(q.answer.substring(0, 4))
              setExpiryM(q.answer.substring(5, 7))
              setExpiryD(q.answer.substring(8))
            }
          }
        })
      }

      // Formulario de Idiomas
      if (form.formData.formName === '004') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            setNativeLang(q.answer)
          }
          if (q.index === '002') {
            if (q.answer === 'Inglés') {
              setAbleToCommunicate('English')
            }
            if (q.answer === 'Francés') {
              setAbleToCommunicate('French')
            }
            if (q.answer === 'Ambos') {
              setAbleToCommunicate('Both')
            }
            if (q.answer === 'Ninguno') {
              setAbleToCommunicate('Neither')
            }
          }
          if (q.index === '003') {
            setLOV(q.answer)
          }
          if (q.index === '004') {
            q.answer === 'Si'
              ? setLanguageTest('Y')
              : q.answer === 'No'
              ? setLanguageTest('N')
              : setLanguageTest('')
          }
        })
      }

      // Formulario de Documento nacional de identidad
      if (form.formData.formName === '011') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setNatIDIndicator('Y')
              : q.answer === 'No'
              ? setNatIDIndicator('N')
              : setNatIDIndicator('')
          }
          if (q.index === '002') {
            setDocNum(q.answer)
          }
          if (q.index === '003') {
            setIDCountryofIssue(q.answer)
          }
          if (q.index === '004') {
            setIDIssueDate(q.answer)
          }
          if (q.index === '005') {
            setIDExpiryDate(q.answer)
          }
        })
      }

      // Formulario de Tarjeta de residente permanente (PR) de EE. UU.
      if (form.formData.formName === '016') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setusCardIndicator('Y')
              : q.answer === 'No'
              ? setusCardIndicator('N')
              : setusCardIndicator('')
          }
          if (q.index === '002') {
            setusCardDocNum(q.answer)
          }
          if (q.index === '003') {
            setusCardExpiryDate(q.answer)
          }
        })
      }

      // Formulario de Dirección residencial principal
      if (form.formData.formName === '017') {
        form.formData.questions.map((q: any) => {
          if (q.index === '002') {
            setPOBox(q.answer)
          }
          if (q.index === '003') {
            setAptUnit(q.answer)
          }
          if (q.index === '004') {
            setStreetNum(q.answer)
          }
          if (q.index === '005') {
            setStreetname(q.answer)
          }
          if (q.index === '006') {
            setCityTown(q.answer)
          }
          if (q.index === '007') {
            setCountry(q.answer)
          }
          if (q.index === '008') {
            setProvinceState(q.answer)
          }
          if (q.index === '009') {
            setPostalCode(q.answer)
          }
          if (q.index === '010') {
            setDistrict(q.answer)
          }
          if (q.index === '011') {
            q.answer === 'Si'
              ? setSameAsMailingIndicator('Y')
              : q.answer === 'No'
              ? setSameAsMailingIndicator('N')
              : setSameAsMailingIndicator('')
          }
          if (q.index === '012') {
            setSameAsMailingIndicatorAptUnit(q.answer)
          }
          if (q.index === '013') {
            setSameAsMailingIndicatorStreetNum(q.answer)
          }
          if (q.index === '014') {
            setSameAsMailingIndicatorStreetname(q.answer)
          }
          if (q.index === '015') {
            setSameAsMailingIndicatorCityTown(q.answer)
          }
          if (q.index === '016') {
            setSameAsMailingIndicatorCountry(q.answer)
          }
          if (q.index === '017') {
            setSameAsMailingIndicatorProvince(q.answer)
          }
          if (q.index === '018') {
            setSameAsMailingIndicatorPostalCode(q.answer)
          }
          if (q.index === '019') {
            setSameAsMailingIndicatorDistrict(q.answer)
          }
        })
      }

      // Formulario de Detalles de la visita prevista en Canadá
      if (form.formData.formName === '021') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            if (q.answer === 'Negocios') {
              setPurposeOfVisit('Business')
            }
            if (q.answer === 'Turismo') {
              setPurposeOfVisit('Tourism')
            }
            if (
              q.answer === 'Estudios de corta duración (curso o programa de seis meses o menos)'
            ) {
              setPurposeOfVisit('Short-Term Studies')
            }
            if (q.answer === 'Estudiante que regresa') {
              setPurposeOfVisit('Returning Student')
            }
            if (q.answer === 'Trabajador que regresa') {
              setPurposeOfVisit('Returning Worker')
            }
            if (q.answer === 'Súper visa - Padres y abuelos') {
              setPurposeOfVisit('Super Visa: For Parents or Grandparents')
            }
            if (q.answer === 'Visita familiar') {
              setPurposeOfVisit('Family Visit')
            }
            if (q.answer === 'Visita') {
              setPurposeOfVisit('Visit')
            }
            if (q.answer === 'Otro') {
              setPurposeOfVisit('Other')
            }
          }
          if (q.index === '002') {
            setPurposeOfVisitOther(q.answer)
          }
          if (
            q.index === '003' &&
            Array.isArray(q.multipleAnswer) &&
            q.multipleAnswer.length === 2
          ) {
            if (q.multipleAnswer[0] !== '' && q.multipleAnswer[1] !== '') {
              setHowLongStay(q.multipleAnswer)
              setHowLongStayFromY(q.multipleAnswer[0].substring(0, 4))
              setHowLongStayFromM(q.multipleAnswer[0].substring(5, 7))
              setHowLongStayFromD(q.multipleAnswer[0].substring(8))
              setHowLongStayToY(q.multipleAnswer[1].substring(0, 4))
              setHowLongStayToM(q.multipleAnswer[1].substring(5, 7))
              setHowLongStayToD(q.multipleAnswer[1].substring(8))
            }
          }

          if (q.index === '004') {
            setHowFounds(q.answer)
          }
          if (q.index === '007') {
            setContacts1Name(q.answer)
          }
          if (q.index === '008') {
            setContacts1RelationshipToMe(q.answer)
          }
          if (q.index === '009') {
            setContacts1AddressInCanada(q.answer)
          }
          if (q.index === '011') {
            setContacts2Name(q.answer)
          }
          if (q.index === '012') {
            setContacts2RelationshipToMe(q.answer)
          }
          if (q.index === '013') {
            setContacts2AddressInCanada(q.answer)
          }
        })
      }

      // Formulario de Educación
      if (form.formData.formName === '027') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setEducationIndicator('Y')
              : q.answer === 'No'
              ? setEducationIndicator('N')
              : setEducationIndicator('')
          }

          if (q.index === '003') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer[0] !== '' && q.multipleAnswer[1] !== '') {
                setEducationDatesFromY(q.multipleAnswer[0].substring(0, 4))
                setEducationDatesFromM(q.multipleAnswer[0].substring(5, 7))
                setEducationDatesToY(q.multipleAnswer[1].substring(0, 4))
                setEducationDatesToM(q.multipleAnswer[1].substring(5, 7))
              }
            }
          }
          if (q.index === '004') {
            setFieldOfStudy(q.answer)
          }
          if (q.index === '005') {
            setSchool(q.answer)
          }
          if (q.index === '006') {
            setSchoolCityTown(q.answer)
          }
          if (q.index === '007') {
            setSchoolProvince(q.answer)
          }
          if (q.index === '008') {
            setSchoolCountry(q.answer)
          }
        })
      }

      // Formulario de Ocupación o empleo (actual y anteriores)
      if (form.formData.formName === '013') {
        form.formData.questions.map((q: any) => {
          if (q.index === '003' && q.answer !== '') {
            setOcopation1DatesFromY(q.answer.substring(0, 4))
            setOcopation1DatesFromM(q.answer.substring(5, 7))
          }
          if (q.index === '004' && q.answer !== '') {
            setOcopation1DatesToY(q.answer.substring(0, 4))
            setOcopation1DatesToM(q.answer.substring(5, 7))
          }
          if (q.index === '005') {
            setOcupation1(q.answer)
          }
          if (q.index === '006') {
            setEmployer1(q.answer)
          }
          if (q.index === '007') {
            setOcupation1CityTown(q.answer)
          }
          if (q.index === '008') {
            setOcupation1Country(q.answer)
          }
          if (q.index === '009') {
            setOcupation1Province(q.answer)
          }
          if (q.index === '012') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer.length > 0) {
                setOcopation2DatesFromY(q.multipleAnswer[0].substring(0, 4))
                setOcopation2DatesFromM(q.multipleAnswer[0].substring(5, 7))
                setOcopation2DatesToY(q.multipleAnswer[1].substring(0, 4))
                setOcopation2DatesToM(q.multipleAnswer[1].substring(5, 7))
              }
            }
          }
          if (q.index === '013') {
            setOcupation2(q.answer)
          }
          if (q.index === '014') {
            setEmployer2(q.answer)
          }
          if (q.index === '015') {
            setOcupation2CityTown(q.answer)
          }
          if (q.index === '016') {
            setOcupation2Country(q.answer)
          }
          if (q.index === '017') {
            setOcupation2Province(q.answer)
          }
          if (q.index === '020') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer.length > 0) {
                setOcopation3DatesFromY(q.multipleAnswer[0].substring(0, 4))
                setOcopation3DatesFromM(q.multipleAnswer[0].substring(5, 7))
                setOcopation3DatesToY(q.multipleAnswer[1].substring(0, 4))
                setOcopation3DatesToM(q.multipleAnswer[1].substring(5, 7))
              }
            }
          }
          if (q.index === '021') {
            setOcupation3(q.answer)
          }
          if (q.index === '022') {
            setEmployer3(q.answer)
          }
          if (q.index === '023') {
            setOcupation3CityTown(q.answer)
          }
          if (q.index === '024') {
            setOcupation3Country(q.answer)
          }
          if (q.index === '025') {
            setOcupation3Province(q.answer)
          }
        })
      }

      // Formulario de Declaración de antecedentes
      if (form.formData.formName === '024') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setBackgroundInfoMedicalChoice1('Y')
              : q.answer === 'No'
              ? setBackgroundInfoMedicalChoice1('N')
              : setBackgroundInfoMedicalChoice1('')
          }
          if (q.index === '002') {
            setBackgroundInfoMedicalDetails(q.answer)
          }
          if (q.index === '003') {
            q.answer === 'Si'
              ? setBackgroundInfoMedicalChoice2('Y')
              : q.answer === 'No'
              ? setBackgroundInfoMedicalChoice2('N')
              : setBackgroundInfoMedicalChoice2('')
          }
          if (q.index === '004') {
            setBackgroundInfoMedicalDetails2(q.answer)
          }
          if (q.index === '005') {
            q.answer === 'Si'
              ? setBackgroundInfoVisaChioce1('Y')
              : q.answer === 'No'
              ? setBackgroundInfoVisaChioce1('N')
              : setBackgroundInfoVisaChioce1('')
          }
          if (q.index === '006') {
            setBackgroundInfoVisaChioceDetails(q.answer)
          }
          if (q.index === '007') {
            q.answer === 'Si'
              ? setBackgroundInfoVisaChioce2('Y')
              : q.answer === 'No'
              ? setBackgroundInfoVisaChioce2('N')
              : setBackgroundInfoVisaChioce2('')
          }
          if (q.index === '008') {
            setBackgroundInfoVisaChioceDetails2(q.answer)
          }
          if (q.index === '009') {
            q.answer === 'Si'
              ? setBackgroundInfoVisaChioce3('Y')
              : q.answer === 'No'
              ? setBackgroundInfoVisaChioce3('N')
              : setBackgroundInfoVisaChioce3('')
          }
          if (q.index === '010') {
            setBackgroundInfoVisaChioceDetails3(q.answer)
          }
          if (q.index === '011') {
            q.answer === 'Si'
              ? setBackgroundInfo3Choice('Y')
              : q.answer === 'No'
              ? setBackgroundInfo3Choice('N')
              : setBackgroundInfo3Choice('')
          }
          if (q.index === '012') {
            setBackgroundInfo3Details(q.answer)
          }
          if (q.index === '013') {
            q.answer === 'Si'
              ? setMilitarChoice('Y')
              : q.answer === 'No'
              ? setMilitarChoice('N')
              : setMilitarChoice('')
          }
          if (q.index === '014') {
            setMilitarDetails(q.answer)
          }
          if (q.index === '015') {
            q.answer === 'Si'
              ? setPoliticalLoot('Y')
              : q.answer === 'No'
              ? setPoliticalLoot('N')
              : setPoliticalLoot('')
          }
        })
      }

      // Formulario de Participación y asociación con organizaciones
      if (form.formData.formName === '015') {
         
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setPoliticalAssociation('Y')
              : q.answer === 'No'
              ? setPoliticalAssociation('N')
              : setPoliticalAssociation('')
          }
        })
      }
    })
  }

  const handleUploadToServer = async () => {
    toast.info('Estamos procesando tu solicitud. Te notificaremos cuando esté listo. Tiempo de espera aproximado: 3 minutos.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: '#C3CEF6',
        height: '5rem',
        width: '27rem',
        marginLeft:'-7rem',
      },
    });
    connectSocket()
    setAnswers(); // Asumo que esta función establece algunas respuestas

    // Aquí accedes a foundContract si está disponible
    if (contractsData) {
      // Extraemos el arreglo 'data' del objeto contractsData
      const dataArray = contractsData.data;

      // Encontramos el objeto dentro de dataArray que tenga el mismo envelope_id que contractsFound
      const foundContract = dataArray.find(
        (contract: { contracts_users_envelope_id: any }) => contract.contracts_users_envelope_id === contractsFound
      );

      if (foundContract) {
        console.log('contractsData:', foundContract);

        // Creación del FormData y XML
        const updatedFormData = {
          form1: {
            Page1: {
              PersonalDetails: {
                UCIClientID: UCI,
                ServiceIn: {
                  ServiceIn: serviceIn,
                },

                Name: {
                  FamilyName: familyName,
                  GivenName: givenName,
                },
                AliasName: {
                  AliasFamilyName: aliasFamilyName,
                  AliasGivenName: aliasGivenName,
                  AliasNameIndicator: {
                    AliasNameIndicator: aliasNameIndicator,
                  },
                },
                Sex: {
                  Sex: sex,
                },
                DOBYear: DOBYear,
                DOBMonth: DOBMonth,
                DOBDay: DOBDay,
                PlaceBirthCity: placeBirthCity,
                PlaceBirthCountry: placeBirthCountry,
                Citizenship: {
                  Citizenship: citizenship,
                },
                CurrentCOR: {
                  Row2: {
                    Country: CORCountry,
                    Status: CORStatus,
                    Other: COROther,
                    FromDate: CORDates[0],
                    ToDate: CORDates[1],
                  },
                },
                CORDates: {
                  FromYr: CORYearFrom,
                  FromMM: CORMonthFrom,
                  FromDD: CORDayFrom,
                  ToYr: CORYearTo,
                  ToMM: CORMonthTo,
                  ToDD: CORDayTo,
                },
                PCRIndicator: PCRIndicator,
                PreviousCOR: {
                  Row2: {
                    Country: PCRCountry,
                    Status: PCRStatus,
                    Other: PCROther,
                    FromDate: PCRDates[0],
                    ToDate: PCRDates[1],
                  },
                  Row3: {
                    Country: PCRCountry2,
                    Status: PCRStatus2,
                    Other: PCROther2,
                    FromDate: PCRDates2[0],
                    ToDate: PCRDates2[1],
                  },
                },
                PCRDatesR1: {
                  FromYr: PCRYearFrom,
                  FromMM: PCRMonthFrom,
                  FromDD: PCRDayFrom,
                  ToYr: PCRYearTo,
                  ToMM: PCRMonthTo,
                  ToDD: PCRDayTo,
                },
                PCRDatesR2: {
                  FromYr: PCRYearFrom2,
                  FromMM: PCRMonthFrom2,
                  FromDD: PCRDayFrom2,
                  ToYr: PCRYearTo2,
                  ToMM: PCRMonthTo2,
                  ToDD: PCRDayTo2,
                },
                SameAsCORIndicator: SameAsPCRIndicator,
                CountryWhereApplying: {
                  Row2: {
                    Country: SameAsCORCountry,
                    Status: SameAsCORCountryStatus,
                    Other: SameAsCORCountryOther,
                    FromDate: SameAsCORDates[0],
                    ToDate: SameAsCORDates[1],
                  },
                },
                CWADates: {
                  FromYr: SameAsCORYearFrom,
                  FromMM: SameAsCORMonthFrom,
                  FromDD: SameAsCORDayFrom,
                  ToYr: SameAsCORYearTo,
                  ToMM: SameAsCORMonthTo,
                  ToDD: SameAsCORDayTo,
                },
              },
              MaritalStatus: {
                SectionA: {
                  MaritalStatus: maritalStatus,
                  DateOfMarriage: maritalStatusDate,
                  MarriageDate: {
                    FromYr: maritalStatusYear,
                    FromMM: maritalStatusMonth,
                    FromDD: maritalStatusDay,
                  },
                  FamilyName: maritalStatusFamilyName,
                  GivenName: maritalStatusGivenName,
                },
                Current: {
                  MaritalStatus: maritalStatus,
                  b: {
                    DateOfMarriage: maritalStatusDate,
                    MarriageDate: {
                      DOBYear: PMDateOfBirthY,
                      DOBMonth: PMDateOfBirthM,
                      DOBDay: PMDateOfBirthD,
                    },
                  },
                  c: {
                    FamilyName: maritalStatusFamilyName,
                    GivenName: maritalStatusGivenName,
                  },
                },
                d: {
                  SpouseStatus: 'N',
                },
              },
            },
            Page2: {
              MaritalStatus: {
                SectionA: {
                  PrevMarriedIndicator: prevMarriedIndicator,
                  DateLastValidated: {
                    DateCalc: '',
                    Year: 2023,
                    Month: 9,
                    Day: 8,
                  },
                  PMFamilyName: PMFamilyName,
                  GivenName: {
                    PMGivenName: PMFamilyNameGivenName,
                  },
                  PrevSpouseDOB: {
                    DOBYear: PMDateOfBirthY,
                    DOBMonth: PMDateOfBirthM,
                    DOBDay: PMDateOfBirthD,
                  },
                  TypeOfRelationship: PMTypeOfRelationship,
                  FromDate: PMDateFromDate,
                  ToDate: {
                    ToDate: PMDateToDate,
                  },
                  PreviouslyMarriedDates: {
                    FromYr: PreviouslyMarriedDatesFromY,
                    FromMM: PreviouslyMarriedDatesFromM,
                    FromDD: PreviouslyMarriedDatesFromD,
                    ToYr: PreviouslyMarriedDatesToY,
                    ToMM: PreviouslyMarriedDatesToM,
                    ToDD: PreviouslyMarriedDatesToD,
                  },
                  Languages: {
                    languagesHeader: '',
                    languages: {
                      nativeLang: {
                        nativeLang: nativeLang,
                      },
                      ableToCommunicate: {
                        ableToCommunicate: ableToCommunicate,
                      },
                      lov: LOV,
                    },
                    LanguageTest: LanguageTest,
                  },
                  Passport: {
                    PassportNum: {
                      PassportNum: passportNum,
                    },
                    CountryofIssue: {
                      CountryofIssue: passportCountryOfIssue,
                    },
                    IssueDate: {
                      IssueDate: passportDateOfIssue,
                    },
                    ExpiryDate: passportExpireDate,
                    IssueYYYY: IssueY,
                    IssueMM: IssueM,
                    IssueDD: IssueD,
                    expiryYYYY: expiryY,
                    expiryMM: expiryM,
                    expiryDD: expiryD,
                    TaiwanPIN: '',
                    IsraelPassportIndicator: '',
                  },
                },
                PrevMarriage: {
                  PrevMarriedIndicator: prevMarriedIndicator,
                  DateLastValidated: {
                    Year: 2023,
                    Month: 9,
                    Day: 14,
                  },
                  PMFamilyName: PMFamilyName,
                  PMGivenName: PMFamilyNameGivenName,
                  TypeOfRelationship: PMTypeOfRelationship,
                  From: {
                    FromDate: PMDateFromDate,
                  },
                  To: {
                    ToDate: PMDateToDate,
                  },
                  PreviouslyMarriedDates: {
                    FromYr: PreviouslyMarriedDatesFromY,
                    FromMM: PreviouslyMarriedDatesFromM,
                    FromDD: PreviouslyMarriedDatesFromD,
                    ToYr: PreviouslyMarriedDatesToY,
                    ToMM: PreviouslyMarriedDatesToM,
                    ToDD: PreviouslyMarriedDatesToD,
                  },
                  dob: {
                    DOBYear: PMDateOfBirthY,
                    DOBMonth: PMDateOfBirthM,
                    DOBDay: PMDateOfBirthD,
                  },
                },
              },
              natID: {
                q1: {
                  natIDIndicator: natIDIndicator,
                },
                natIDdocs: {
                  DocNum: {
                    DocNum: DocNum,
                  },
                  CountryofIssue: {
                    CountryofIssue: IDCountryofIssue,
                  },
                  IssueDate: {
                    IssueDate: IDIssueDate,
                  },
                  ExpiryDate: IDExpiryDate,
                },
              },
              USCard: {
                q1: {
                  usCardIndicator: usCardIndicator,
                },
                usCarddocs: {
                  DocNum: {
                    DocNum: usCardDocNum,
                  },
                  ExpiryDate: usCardExpiryDate,
                },
              },
              ContactInformation: {
                contact: {
                  AddressRow1: {
                    POBox: {
                      POBox: POBox,
                    },
                    Apt: {
                      AptUnit: AptUnit,
                    },
                    StreetNum: {
                      StreetNum: StreetNum,
                    },
                    Streetname: {
                      Streetname: Streetname,
                    },
                  },
                  AddressRow2: {
                    CityTow: {
                      CityTown: CityTown,
                      Country: {
                        Country: Country,
                      },
                      ProvinceState: {
                        ProvinceState: ProvinceState,
                      },
                      PostalCode: {
                        PostalCode: PostalCode,
                      },
                      District: District,
                    },
                    SameAsMailingIndicator: SameAsMailingIndicator,
                    ResidentialAddressRow1: {
                      AptUnit: {
                        AptUnit: SameAsMailingIndicatorAptUnit,
                      },
                      StreetNum: {
                        StreetNum: SameAsMailingIndicatorStreetNum,
                      },
                      StreetName: {
                        Streetname: SameAsMailingIndicatorStreetname,
                      },
                      CityTown: {
                        CityTown: SameAsMailingIndicatorCityTown,
                      },
                    },
                    ResidentialAddressRow2: {
                      Country: {
                        Country: SameAsMailingIndicatorCountry,
                      },
                      ProvinceState: {
                        ProvinceState: SameAsMailingIndicatorProvince,
                      },
                      PostalCode: {
                        PostalCode: SameAsMailingIndicatorPostalCode,
                      },
                      District: SameAsMailingIndicatorDistrict,
                    },
                    PhoneNumbers: {
                      Phone: {
                        Type: 'Cellular',
                        CanadaUS: 1,
                        Other: 1,
                        NumberExt: '',
                        NumberCountry: 1,
                        ActualNumber: 5146238146,
                        NANumber: {
                          AreaCode: 514,
                          FirstThree: 623,
                          LastFive: 8146,
                        },
                        IntlNumber: {
                          IntlNumber: 5146238146,
                        },
                      },
                      AltPhone: {
                        Type: '',
                        CanadaUS: '',
                        Other: '',
                        NumberExt: '',
                        NumberCountry: '',
                        ActualNumber: '',
                        NANumber: {
                          AreaCode: '',
                          FirstThree: '',
                          LastFive: '',
                        },
                        IntlNumber: {
                          IntlNumber: '',
                        },
                      },
                    },
                  },

                  Page3: {
                    FaxEmail: {
                      Phone: {
                        CanadaUS: '',
                        Other: '',
                        NumberExt: '',
                        NumberCountry: 1025,
                        ActualNumber: '',
                        NANumber: {
                          AreaCode: '',
                          FirstThree: '',
                          LastFive: '',
                        },
                        IntlNumber: {
                          IntlNumber: '',
                        },
                      },
                      Email: '',
                    },
                    DetailsOfIntendedWork: {
                      DetailsOfWork: {
                        TypeofWork: {
                          WorkPermitType: '',
                        },
                        PurposeRow1: {
                          EmployerName: {
                            EmployerName: '',
                          },
                          Address: {
                            Address: '',
                          },
                        },
                      },
                    },
                    IntendedLocationInCanada: {
                      intendedLocation: {
                        ProvinceState: {
                          ProvinceState: 7,
                        },
                        CityTown: {
                          CityTown: 5013,
                        },
                        Address: '',
                      },
                    },
                  },
                },
              },
            },
          },
        }
        console.log(updatedFormData, 'datos del formulario');
        const xml = xmlbuilder.create(updatedFormData, { encoding: 'utf-8' }).end({ pretty: true });
        const file = new Blob([xml], { type: 'application/xml' });
        const formData = new FormData();
        formData.append('file', file, fileName);

        // Añadir datos adicionales al FormData como un objeto JSON
        const additionalData = {
          memberName: product.memberName,
          memberType: product.memberType,
          contracts_users_envelope_id: foundContract.contracts_users_envelope_id,
          contracts_users_user_id: foundContract.contracts_users_user_id,
          userId: userAUTH?.id
        };
        formData.append('additionalData', JSON.stringify(additionalData));

        try {
          const response = await axios.post('https://botimm.immiland.app/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('File uploaded successfully:', response.data);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      } else {
        console.log('No contract found with envelope id:', contractsFound);
      }
    } else {
      console.log('contractsData is undefined');
    }
  };

  const handleButtonClick = () => {
    setAnswers()
    const updatedFormData = {
      form1: {
        Page1: {
          PersonalDetails: {
            UCIClientID: UCI,
            ServiceIn: {
              ServiceIn: serviceIn,
            },

            Name: {
              FamilyName: familyName,
              GivenName: givenName,
            },
            AliasName: {
              AliasFamilyName: aliasFamilyName,
              AliasGivenName: aliasGivenName,
              AliasNameIndicator: {
                AliasNameIndicator: aliasNameIndicator,
              },
            },
            Sex: {
              Sex: sex,
            },
            DOBYear: DOBYear,
            DOBMonth: DOBMonth,
            DOBDay: DOBDay,
            PlaceBirthCity: placeBirthCity,
            PlaceBirthCountry: placeBirthCountry,
            Citizenship: {
              Citizenship: citizenship,
            },
            CurrentCOR: {
              Row2: {
                Country: CORCountry,
                Status: CORStatus,
                Other: COROther,
                FromDate: CORDates[0],
                ToDate: CORDates[1],
              },
            },
            CORDates: {
              FromYr: CORYearFrom,
              FromMM: CORMonthFrom,
              FromDD: CORDayFrom,
              ToYr: CORYearTo,
              ToMM: CORMonthTo,
              ToDD: CORDayTo,
            },
            PCRIndicator: PCRIndicator,
            PreviousCOR: {
              Row2: {
                Country: PCRCountry,
                Status: PCRStatus,
                Other: PCROther,
                FromDate: PCRDates[0],
                ToDate: PCRDates[1],
              },
              Row3: {
                Country: PCRCountry2,
                Status: PCRStatus2,
                Other: PCROther2,
                FromDate: PCRDates2[0],
                ToDate: PCRDates2[1],
              },
            },
            PCRDatesR1: {
              FromYr: PCRYearFrom,
              FromMM: PCRMonthFrom,
              FromDD: PCRDayFrom,
              ToYr: PCRYearTo,
              ToMM: PCRMonthTo,
              ToDD: PCRDayTo,
            },
            PCRDatesR2: {
              FromYr: PCRYearFrom2,
              FromMM: PCRMonthFrom2,
              FromDD: PCRDayFrom2,
              ToYr: PCRYearTo2,
              ToMM: PCRMonthTo2,
              ToDD: PCRDayTo2,
            },
            SameAsCORIndicator: SameAsPCRIndicator,
            CountryWhereApplying: {
              Row2: {
                Country: SameAsCORCountry,
                Status: SameAsCORCountryStatus,
                Other: SameAsCORCountryOther,
                FromDate: SameAsCORDates[0],
                ToDate: SameAsCORDates[1],
              },
            },
            CWADates: {
              FromYr: SameAsCORYearFrom,
              FromMM: SameAsCORMonthFrom,
              FromDD: SameAsCORDayFrom,
              ToYr: SameAsCORYearTo,
              ToMM: SameAsCORMonthTo,
              ToDD: SameAsCORDayTo,
            },
          },
          MaritalStatus: {
            SectionA: {
              MaritalStatus: maritalStatus,
              DateOfMarriage: maritalStatusDate,
              MarriageDate: {
                FromYr: maritalStatusYear,
                FromMM: maritalStatusMonth,
                FromDD: maritalStatusDay,
              },
              FamilyName: maritalStatusFamilyName,
              GivenName: maritalStatusGivenName,
            },
            Current: {
              MaritalStatus: maritalStatus,
              b: {
                DateOfMarriage: maritalStatusDate,
                MarriageDate: {
                  DOBYear: PMDateOfBirthY,
                  DOBMonth: PMDateOfBirthM,
                  DOBDay: PMDateOfBirthD,
                },
              },
              c: {
                FamilyName: maritalStatusFamilyName,
                GivenName: maritalStatusGivenName,
              },
            },
            d: {
              SpouseStatus: 'N',
            },
          },
        },
        Page2: {
          MaritalStatus: {
            SectionA: {
              PrevMarriedIndicator: prevMarriedIndicator,
              DateLastValidated: {
                DateCalc: '',
                Year: 2023,
                Month: 9,
                Day: 8,
              },
              PMFamilyName: PMFamilyName,
              GivenName: {
                PMGivenName: PMFamilyNameGivenName,
              },
              PrevSpouseDOB: {
                DOBYear: PMDateOfBirthY,
                DOBMonth: PMDateOfBirthM,
                DOBDay: PMDateOfBirthD,
              },
              TypeOfRelationship: PMTypeOfRelationship,
              FromDate: PMDateFromDate,
              ToDate: {
                ToDate: PMDateToDate,
              },
              PreviouslyMarriedDates: {
                FromYr: PreviouslyMarriedDatesFromY,
                FromMM: PreviouslyMarriedDatesFromM,
                FromDD: PreviouslyMarriedDatesFromD,
                ToYr: PreviouslyMarriedDatesToY,
                ToMM: PreviouslyMarriedDatesToM,
                ToDD: PreviouslyMarriedDatesToD,
              },
              Languages: {
                languagesHeader: '',
                languages: {
                  nativeLang: {
                    nativeLang: nativeLang,
                  },
                  ableToCommunicate: {
                    ableToCommunicate: ableToCommunicate,
                  },
                  lov: LOV,
                },
                LanguageTest: LanguageTest,
              },
              Passport: {
                PassportNum: {
                  PassportNum: passportNum,
                },
                CountryofIssue: {
                  CountryofIssue: passportCountryOfIssue,
                },
                IssueDate: {
                  IssueDate: passportDateOfIssue,
                },
                ExpiryDate: passportExpireDate,
                IssueYYYY: IssueY,
                IssueMM: IssueM,
                IssueDD: IssueD,
                expiryYYYY: expiryY,
                expiryMM: expiryM,
                expiryDD: expiryD,
                TaiwanPIN: '',
                IsraelPassportIndicator: '',
              },
            },
            PrevMarriage: {
              PrevMarriedIndicator: prevMarriedIndicator,
              DateLastValidated: {
                Year: 2023,
                Month: 9,
                Day: 14,
              },
              PMFamilyName: PMFamilyName,
              PMGivenName: PMFamilyNameGivenName,
              TypeOfRelationship: PMTypeOfRelationship,
              From: {
                FromDate: PMDateFromDate,
              },
              To: {
                ToDate: PMDateToDate,
              },
              PreviouslyMarriedDates: {
                FromYr: PreviouslyMarriedDatesFromY,
                FromMM: PreviouslyMarriedDatesFromM,
                FromDD: PreviouslyMarriedDatesFromD,
                ToYr: PreviouslyMarriedDatesToY,
                ToMM: PreviouslyMarriedDatesToM,
                ToDD: PreviouslyMarriedDatesToD,
              },
              dob: {
                DOBYear: PMDateOfBirthY,
                DOBMonth: PMDateOfBirthM,
                DOBDay: PMDateOfBirthD,
              },
            },
          },
          natID: {
            q1: {
              natIDIndicator: natIDIndicator,
            },
            natIDdocs: {
              DocNum: {
                DocNum: DocNum,
              },
              CountryofIssue: {
                CountryofIssue: IDCountryofIssue,
              },
              IssueDate: {
                IssueDate: IDIssueDate,
              },
              ExpiryDate: IDExpiryDate,
            },
          },
          USCard: {
            q1: {
              usCardIndicator: usCardIndicator,
            },
            usCarddocs: {
              DocNum: {
                DocNum: usCardDocNum,
              },
              ExpiryDate: usCardExpiryDate,
            },
          },
          ContactInformation: {
            contact: {
              AddressRow1: {
                POBox: {
                  POBox: POBox,
                },
                Apt: {
                  AptUnit: AptUnit,
                },
                StreetNum: {
                  StreetNum: StreetNum,
                },
                Streetname: {
                  Streetname: Streetname,
                },
              },
              AddressRow2: {
                CityTow: {
                  CityTown: CityTown,
                  Country: {
                    Country: Country,
                  },
                  ProvinceState: {
                    ProvinceState: ProvinceState,
                  },
                  PostalCode: {
                    PostalCode: PostalCode,
                  },
                  District: District,
                },
                SameAsMailingIndicator: SameAsMailingIndicator,
                ResidentialAddressRow1: {
                  AptUnit: {
                    AptUnit: SameAsMailingIndicatorAptUnit,
                  },
                  StreetNum: {
                    StreetNum: SameAsMailingIndicatorStreetNum,
                  },
                  StreetName: {
                    Streetname: SameAsMailingIndicatorStreetname,
                  },
                  CityTown: {
                    CityTown: SameAsMailingIndicatorCityTown,
                  },
                },
                ResidentialAddressRow2: {
                  Country: {
                    Country: SameAsMailingIndicatorCountry,
                  },
                  ProvinceState: {
                    ProvinceState: SameAsMailingIndicatorProvince,
                  },
                  PostalCode: {
                    PostalCode: SameAsMailingIndicatorPostalCode,
                  },
                  District: SameAsMailingIndicatorDistrict,
                },
                PhoneNumbers: {
                  Phone: {
                    Type: 'Cellular',
                    CanadaUS: 1,
                    Other: 1,
                    NumberExt: '',
                    NumberCountry: 1,
                    ActualNumber: 5146238146,
                    NANumber: {
                      AreaCode: 514,
                      FirstThree: 623,
                      LastFive: 8146,
                    },
                    IntlNumber: {
                      IntlNumber: 5146238146,
                    },
                  },
                  AltPhone: {
                    Type: '',
                    CanadaUS: '',
                    Other: '',
                    NumberExt: '',
                    NumberCountry: '',
                    ActualNumber: '',
                    NANumber: {
                      AreaCode: '',
                      FirstThree: '',
                      LastFive: '',
                    },
                    IntlNumber: {
                      IntlNumber: '',
                    },
                  },
                },
              },

              Page3: {
                FaxEmail: {
                  Phone: {
                    CanadaUS: '',
                    Other: '',
                    NumberExt: '',
                    NumberCountry: 1025,
                    ActualNumber: '',
                    NANumber: {
                      AreaCode: '',
                      FirstThree: '',
                      LastFive: '',
                    },
                    IntlNumber: {
                      IntlNumber: '',
                    },
                  },
                  Email: '',
                },
                DetailsOfIntendedWork: {
                  DetailsOfWork: {
                    TypeofWork: {
                      WorkPermitType: '',
                    },
                    PurposeRow1: {
                      EmployerName: {
                        EmployerName: '',
                      },
                      Address: {
                        Address: '',
                      },
                    },
                  },
                },
                IntendedLocationInCanada: {
                  intendedLocation: {
                    ProvinceState: {
                      ProvinceState: 7,
                    },
                    CityTown: {
                      CityTown: 5013,
                    },
                    Address: '',
                  },
                },
              },
            },
          },
        },
      },
    }

    /*   function replaceSpecialChars(str) {

      return str.replace(/[\r\n]/g, ' ');
    }

    function addNewLine(obj: Record<string, any>) {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          addNewLine(obj[key]);
        } else if (typeof obj[key] === 'string') {
          const newKey = replaceSpecialChars(key);
          obj[newKey] = obj[key];
          delete obj[key];
        } else if (
          typeof obj[key] === 'object' &&
          obj[key].hasOwnProperty('Question')
        ) {
          const newKey = replaceSpecialChars(key);
          obj[newKey] = obj[key];
          obj[newKey].Question = replaceSpecialChars(obj[key].Question);
          delete obj[key];
        }
      }
    }

    addNewLine(updatedFormData);
    console.log(updatedFormData, 'funcion de enter y salto de pagina'); */

    const xml = xmlbuilder.create(updatedFormData, {encoding: 'utf-8'}).end({pretty: true})
    const fileName = `${product.memberName} ${product.name || 'formulario'}.xml`
    const element = document.createElement('a')
    element.href = `data:text/xml;charset=utf-8,${encodeURIComponent(xml)}`
    element.download = fileName
    element.click()
  }

  useEffect(() => {
    setAnswers()
  }, [])

  return (
    <div>

      <button
        className='button-ui button-tertiary'
        // style={{
        //   border: '2px solid #18BFFF',
        //   padding: '0.3rem 0.5rem',
        //   borderRadius: '30px',
        //   fontWeight: 'bold',
        //   marginLeft: '1rem',
        // }}
        onClick={handleUploadToServer}
      >
      <img style={{width: '24px'}} src={thunderblue} alt="thunderblue" />
      </button>
    </div>
  )
}

export default IMM5708ECONYUGE
