/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Link } from "react-router-dom"
import Search from '../userDasboardComponents/userDashboardIcons/search-alt.svg'
import { useContext, useEffect, useState } from "react"
import UserSearch from '../userDasboardComponents/userDashboardIcons/user-search.svg'
import UserBtn from '../userDasboardComponents/userDashboardIcons/user-alt-4.svg'
import Talk from '../userDasboardComponents/userDashboardIcons/speech-bubble-19.svg'
import Copy from '../userDasboardComponents/userDashboardIcons/copy-alt.svg'
import Briefcase from '../userDasboardComponents/userDashboardIcons/briefcase-2.svg'
import Graduation from '../userDasboardComponents/userDashboardIcons/graduation2.svg'
import Plane from '../userDasboardComponents/userDashboardIcons/planewhite.svg'
import User from '../userDasboardComponents/userDashboardIcons/users-2.svg'
import UserBiometrics from '../userDasboardComponents/userDashboardIcons/UserBiometrics.svg'
import { getUserNewQueryDashboard } from "app/modules/apps/users/core/_requests"
import { Users } from "app/modules/apps/users/core/_models"
import { getContractOnlyWithMatchId } from "app/modules/apps/contract/_requests"
import { RootStoreContext } from "stores/rootStore"
import { UserImage } from "components/profilePopup/ModalPopup.style"
import React from "react"
import ShowEmailUser from "app/pages/admin/users/UserMenusoptions/ShowEmailUser"
import PostalCodeSearch from "../../contratationApp/newUserViewContratationApp/Forms/postaCodeFilter"
import NewDocumentComponent from "app/pages/services/services/supportDocumentsClient/ApiDocsWord"

const DashboardConsultantSearchBar: React.FC = () => {
  const [found, setFound] = useState(false)
  const [wordSearched, setWordSearched] = useState('')
  const [showList, setShowList] = useState(true)
  const [userInfo, setUserInfo] = useState<any>()
  const [listProduct, setListProduct] = useState<any>()
  const [users, setUsers] = useState<any>()
  const [isFetching, setIsFetching] = useState<any>()
  const [lastVisaContract, setLastVisaContract] = useState<any>()
  const [visaContracts, setVisaContracts] = useState<any>()
  const rootStore = useContext(RootStoreContext)
  const [isHovered, setIsHovered] = useState(false)
  const { selectUser } = rootStore.usersStore
  const { loadCounterMessagesClient } = rootStore.messageStore
  const onClickUser = async (user: Users) => {
    setLastVisaContract(undefined)
    setVisaContracts(undefined)
    setWordSearched(user.email)
    setUserInfo(user)
    setFound(true)
    setShowList(false)
    const visaContractsResult = await getContractOnlyWithMatchId(user.id!.toString())
    if (visaContractsResult) {
      setVisaContracts(visaContractsResult)
    }
  }

  const changeInputUser = async (search: string) => {
    setShowList(true)
    setIsFetching(true)
    setWordSearched(search)
    if (search) {
      try {
        const result = await getUserNewQueryDashboard({ email: search })
        setUsers(result)
        setIsFetching(false)
      } catch (e) {
        setIsFetching(false)
      }
    }
    setIsFetching(false)
  }

  const [switchMenus, setSwitchMenus] = useState(false)
  const initialState = {
    empty: false,
    profile: false,
    forms: false,
    translations: false,
    services: false,
    email: false,
    menu: false,
    documents: false,
    timeline: false,
    register: false,
    library: false,
    edit: false,
    edit2: false,
    edit3: false
  }

  const [option, setOption] = React.useState(initialState)
  const handleOption = (key: string) => {
    setOption({
      ...initialState,
      [key]: true,
    })
  }

  useEffect(() => {
    if (lastVisaContract === undefined && visaContracts && visaContracts.data.length > 0) {
      const mostRecentDateContract = visaContracts.data.reduce((anterior: any, actual: any) => {
        const previousDate = new Date(anterior.completedDate)
        const currentDate = new Date(actual.completedDate)
        return previousDate > currentDate ? anterior : actual
      })
      setLastVisaContract(mostRecentDateContract)
      // Parsear el campo applicants
      const applicants = JSON.parse(mostRecentDateContract.applicants)

      // Extraer los productId
      const listProd = applicants.map((applicant: any) => applicant.productId)
      setListProduct(listProd)
    }
  }, [visaContracts])

  useEffect(() => { }, [wordSearched])

  function groupProductsByMember(products: any) {
    const groupedProducts = products.reduce((groupedProducts: any, product: any) => {
      const { memberName, memberType, productId, productName } = product
      const key = `${memberName}-${memberType}`
      if (!groupedProducts[key]) {
        groupedProducts[key] = {
          memberName,
          memberType,
          products: [],
        }
      }
      groupedProducts[key].products.push({
        productId,
        productName,
      })
      return groupedProducts
    }, {})
    const result = Object.values(groupedProducts)
    return result
  }

  /* otros */

  const othersProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713', 'f9fe4a37-7284-4bf7-a22e-f0f12678bb99', '662a5e18-340f-41e6-830a-10576ead3115'
  ]

  const othersProductsMap: { [key: string]: string } = {
    '8dcb5115-bdce-45e3-8b50-d2d592020713': 'Bio',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99': 'F-Bio',
    '662a5e18-340f-41e6-830a-10576ead3115': 'SL',
  }
  /* VISITA */

  const visitProducts = [
    'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const visitProductsMap: { [key: string]: string } = {
    'a10980f2-57f3-43c7-b863-a995915389de': 'VVD',
    '3f056a93-1f4b-4598-9aec-f929ecd00e97': 'eTA',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a': 'Super visa',
    'daa261eb-6263-4b6e-acbb-4343bd4ce35a': 'transit',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9': 'VR',
    'b0e0313b-6344-4733-90a0-6d6031f8be1e': 'VV',
    '41669177-9782-4052-bb8d-08b6c29c928f': 'VV(Study less 6)',
    'b9ada976-a4fe-444d-9f6a-dd58c0928120': 'VR(Dependent)',
  };

  /* ESTUDIO */

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const studyProductsMap: { [key: string]: string } = {
    '94726637-76e7-438b-9494-99b136bbc7ea': 'SPd',
    'bb4056fb-2440-4a06-aec1-8d13349e7427': 'SPd(IC)',
    '8327593a-cc82-4703-afaa-72030f67c001': 'SP',
    'f9b3dcd2-b420-4cc0-b5df-575f9a5be911': 'SDS',
    '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800': 'SP(IC)',
    '32549570-adf0-4add-ad43-5c49fed6566d': 'SP(language)',
    '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c': 'SPd(IC language)'
  };

  /* Residence */

  const residenceProducts = [
    '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', '1ec2dc58-5776-49d3-b487-f373ffd1e0a3',
  ]

  const residenceProductsMap: { [key: string]: string } = {
    '6f317d28-cab2-4316-9a25-3f62e4e047e6': 'Citizenship',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d': 'CSQ',
    '3320f244-f338-43d0-8006-503003d16501': 'CSQ(dependent)',
    'b58daf73-88be-41ee-8a0d-9e485748ced8': 'CSQ(spouse/partner)',
    '7ac7912e-9514-4281-924b-1a36a4dd3065': 'Express Entry(dependent)',
    '9c1f03d1-5100-4a50-8030-cfb8ebad4480': 'Express Entry(AM)',
    '48b466b6-d8e1-48f8-ae32-2877760f92b7': 'Express Entry(EI phase 1)',
    'd1195212-1cdc-4792-b88b-824922f63c36': 'Express Entry(IA phase 2)',
    '7d8df96c-dd71-48aa-b0f7-301e59706212': 'PNE(main applicant phase 1)',
    '03030060-aa44-452a-ad54-80fedf29a547': 'PNP',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d': 'Express Entry(EI Spouse/Partner phase 1)',
    '5848c9c4-bfc9-49b0-881d-9cab020dd1e5': 'Express Entry(IA Spouse/Partner phase 2)',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3': 'TRV',
  };


  /* SECCION DE TRABAJO */

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', '6420335e-30aa-46c5-892b-e1fa23914733',
    '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac', '229d7b91-7a9b-407a-988b-9e45096bff5b',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994', 'c6df5f52-670d-4d7f-809e-72cb59fa684a',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3', 'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]
  const workProductsMap: { [key: string]: string } = {
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61': 'LMIA Exempt',
    '41b1a433-5900-4b96-85f3-7e2c8fce0a41': 'LMIA Exempt(PE)',
    '50eb5404-9ae2-4655-91ae-89d2e8a6fa73': 'LMIA Exempt(Flagpole)',
    '19ca7bf2-6514-478b-bb78-c7861619fe39': 'LMIA Exempt(IC)',
    'e377ed33-a53a-4312-8842-1a689237b07c': 'LMIA Exempt',
    '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b': 'LMIA exempt(PE)',
    'e8e477d1-f126-4a64-93da-539b3715730d': 'LMIA positive',
    '30a323ab-236c-461c-93ea-28308c8fafa6': 'LMIA positive(PE)',
    '08e1143b-4f7f-48db-a289-9a3fc40f8037': 'LMIA positive(flagpole)',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa': 'LMIA positive(IC)',
    'a645705f-f9f7-47c6-9319-5a1026da84d2': 'LMIA positive',
    '6e420420-d96c-4cb8-a145-95a7964ed618': 'LMIA positive(PE)',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095': 'CO-OP',
    '9015fb11-d513-4f6e-9de9-aab92d5d2b42': 'IEC',
    '4f050e6f-e9eb-4ee0-b12c-465d2d715c16': 'IEC',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c': 'LMIA exemption',
    '0dac7746-02c0-47db-be28-14755d62a64d': 'LMIA Federal',
    '6420335e-30aa-46c5-892b-e1fa23914733': 'LMIA Quebec',
    '52096218-da31-4e01-a406-0e06a9ca4d07': 'OWP Vulnerable Worker',
    'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac': 'OWP(MA)',
    '229d7b91-7a9b-407a-988b-9e45096bff5b': 'OWP(Special Mesure Opt-in)',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9': 'PGWP',
    'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994': 'PGWP(PE)',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a': 'PGWP',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3': 'Sponsorship',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4': 'Sponsorship',
    '1d2b991b-7d75-4127-99d8-19f884efcfd0': 'OWP Spouse/Partner',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f': 'OWP Spouse/Partner',
    'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f': 'OWP Spouse/Partner',
    'b6f813c5-0911-4b86-be17-0e74bc332316': 'Spouse/Partner RSWP(Phase 1)'
  };

  return (
    <div className='first-logging-timeline-main'>
      <div className="gap-2 w-full items-center serach-bar-mails">
        <div className="flex gap-2">
          <img style={{ width: '24px', height: '24px' }} src={UserSearch} alt="UserSearch" />
          <p style={{ width: '10rem', margin: '0', fontSize: '20px' }}>Buscar cliente</p>
        </div>
       {/*   {       <PostalCodeSearch /> } */}
        <div className="serach-bar-container">
          <input
            style={{ width: '100%', border: '1px solid #637381', color: '#637381' }}
            type="text"
            placeholder="Ingresa el nombre de un cliente"
            className='input-styles'
            value={wordSearched}
            onChange={(e) => {
              changeInputUser(e.target.value)
            }} />
          <img className='search-icon' src={Search} alt="Search" />
        </div>
        {isFetching === true && wordSearched ?
          <div className='results-list2'>
            Cargando..
          </div>
          : <>
            {wordSearched && showList && (
              <div className='results-list2'>
                {users?.data?.map((user: Users) => (
                  <div
                    onClick={() => {
                      onClickUser(user)
                    }}
                    className='search-result'>
                    {user.email + "--" + user.firstName + " " + user.lastName}
                  </div>
                ))}
              </div>
            )}
          </>}
      </div>
      <div className='aplicant-continer'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: '4rem',
        }}>
    
        {found === false ?
          <p style={{ color: '#0B76B7' }}>Ingrese el nombre de un cliente <b>para ver su resumen aquí</b></p>
          :
          <div style={{ paddingTop: '1rem', paddingBottom: '1rem', width: '100%' }}>

            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center justify-start" style={{ paddingRight: '1rem' }}>
                {/* <img className='consultant-card-img' src={Logo} alt='staff' /> */}
                <UserImage
                  style={{ width: '100px', height: '100px' }}
                  src={
                    userInfo.photoId ? `https://immilandpublic.nyc3.digitaloceanspaces.com/${userInfo.photoId}-avatar-large.jpg` : '/media/avatars/avatar.png'
                  }
                />
                <div>
                  <p style={{ margin: '0' }}><b>{userInfo.firstName} {userInfo.lastName}</b></p>
                  <p>Activo {userInfo.createdAt.substring(0, 10)}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingRight: '1rem', paddingLeft: '1rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Email</p>
                  <p>{userInfo.email}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingRight: '1rem', paddingLeft: '1rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Asignado a</p>
                  <p>{lastVisaContract && lastVisaContract.staffId && lastVisaContract.staffId} </p>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingRight: '1rem', paddingLeft: '1rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Nacionalidad</p>
                  <p>{userInfo.nationalitie}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingLeft: '1rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Reside</p>
                  <p>{userInfo.countryOfOrigin}</p>
                </div>
              </div>
              <div style={{ paddingLeft: '1rem' }} className="flex gap-2">
                <Link onClick={() => {
                  selectUser(userInfo)
                  loadCounterMessagesClient(userInfo.id)
                }} to='/userslist'>
                  <button style={{ width: '4rem', padding: '5px' }} className='timeline-btn-one-small'>
                    <div>
                      <img src={UserBtn} alt="UserBtn" className="button-image" />
                      <span className="button-text">Ir</span>
                    </div>
                  </button>
                </Link>
                <button onClick={() => {
                  selectUser(userInfo)
                  loadCounterMessagesClient(userInfo.id)
                  setSwitchMenus(true)
                  handleOption('email')
                }} style={{ width: '4rem', padding: '5px' }} className='timeline-btn-one-small'>
                  <div>
                    <img src={Talk} alt="Talk" className="button-image" />
                    <span className="button-text">Ir</span>
                  </div>
                </button>
              </div>
            </div>
          </div>}
        {lastVisaContract && userInfo &&
          <div className="quickview-proceso" >
            <p>Último servicio contratado  {userInfo && userInfo.last_service && userInfo.last_service}</p>
            <div style={{ backgroundColor: 'white', width: '100%', borderRadius: '10px', padding: '1rem', display: 'flex', gap: '1rem' }}>
              <div className="flex gap-2 items-center">
                <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#13C296' }} />
                <p style={{ margin: '0' }}><b>Solicitud de Visa</b></p>
              </div>
              <div className="quick proceso-quick">
                <div>
                  <div className="flex gap-2">
                    <p style={{ margin: '0' }}>Ctto n° {lastVisaContract && lastVisaContract.envelope_id && lastVisaContract.envelope_id}</p>
                    <img src={Copy} alt="Copy" />
                  </div>
                  <p>Fecha de creación: &nbsp; {lastVisaContract.created_at.substring(0, 10)}</p>
                </div>
                <div className="flex gap-2">
                  <div className="flex gap-2 product-badge personas" style={{ padding: '5px', border: '1px solid #DFE4EA', borderRadius: '5px' }}>
                    <img src={User} alt="User" />
                    {lastVisaContract &&
                      lastVisaContract.applicants &&
                      groupProductsByMember(JSON.parse(lastVisaContract.applicants)).length &&
                      groupProductsByMember(JSON.parse(lastVisaContract.applicants)).length} Persona(s)
                  </div>

                  {othersProducts.some(id => listProduct && listProduct.includes(id)) &&
                    <div className="product-badge biometrics" >
                      <img src={UserBiometrics} alt="UserBiometrics" />
                      {
                        listProduct &&
                        listProduct
                          .filter((id: string) => othersProducts.includes(id))
                          .map((id: string) => (
                            <span key={id}>
                              {othersProductsMap[id]} {/* Mostrar el nombre correspondiente */}
                            </span>
                          ))
                      }
                    </div>}

                  {visitProducts.some(id => listProduct && listProduct.includes(id)) &&
                    <div className="product-badge tourism" >
                      <img src={Plane} alt="Plane" />
                      {
                        listProduct &&
                        listProduct
                          .filter((id: string) => visitProducts.includes(id))
                          .map((id: string) => (
                            <span key={id}>
                              {visitProductsMap[id]} {/* Mostrar el nombre correspondiente */}
                            </span>
                          ))
                      }
                    </div>}
                  {studyProducts.some(id => listProduct && listProduct.includes(id)) &&
                    <div className="product-badge study" >
                      <img src={Graduation} alt="Graduation" />
                      {
                        listProduct &&
                        listProduct
                          .filter((id: string) => studyProducts.includes(id))
                          .map((id: string) => (
                            <span key={id}>
                              {studyProductsMap[id]} {/* Mostrar el nombre correspondiente */}
                            </span>
                          ))
                      }
                    </div>}
                  {workProducts.some(id => listProduct && listProduct.includes(id)) && (
                    <div className="product-badge work">
                      <img src={Briefcase} alt="Briefcase" />
                      {
                        listProduct &&
                        listProduct
                          .filter((id: string) => workProducts.includes(id))
                          .map((id: string) => (
                            <span key={id}>
                              {workProductsMap[id]} {/* Mostrar el nombre correspondiente */}
                            </span>
                          ))
                      }
                    </div>
                  )}
                  {residenceProducts.some(id => listProduct && listProduct.includes(id)) && (
                    <div className="product-badge residence">
                      <img src={Briefcase} alt="Briefcase" />
                      {
                        listProduct &&
                        listProduct
                          .filter((id: string) => residenceProducts.includes(id))
                          .map((id: string) => (
                            <span key={id}>
                              {residenceProductsMap[id]} {/* Mostrar el nombre correspondiente */}
                            </span>
                          ))
                      }
                    </div>
                  )}
                </div>
              </div>
              <div className="quick etapa-quick flex gap-2 items-center justify-center" style={{ backgroundColor: '#F9FAFB', borderRadius: '10px', padding: '1rem' }}>
                <div style={{ height: '2rem', display: 'flex', flexDirection: 'row', gap: '5px', backgroundColor: 'black', padding: '5px', borderRadius: '5px', color: 'white' }}>
                  Etapa {lastVisaContract.step}
                </div>
                <div>
                  <p style={{ margin: '0' }}>Estado del contrato:</p>
                  <p style={{ height: '2rem', display: 'flex', flexDirection: 'row', gap: '5px', backgroundColor: '#DFE4EA', padding: '5px', borderRadius: '30px', color: '#374151', margin: '0', alignItems: 'center', justifyContent: 'center' }}>En proceso</p>
                </div>
              </div>
            </div>
          </div>}
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
        <button
          className='button2'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Link to='/userslist' className='div-meet2'>
            <b className='meet-text2'>VER TODOS LOS CLIENTES</b>
            <img alt="Calendar" className='meet-image2' src="/media/icons/app/menu/arrow-right-black.svg" />
          </Link>
        </button>
      </div>
      <div style={{ width: '100%' }}>
        {option.email && (
          <>
            <ShowEmailUser data={userInfo} handleClose={handleOption} />
          </>
        )}</div>
    </div>)
}
export { DashboardConsultantSearchBar }