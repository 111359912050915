import tw from 'tailwind-styled-components'

const Wrapper = tw.div`
  flex 
  flex-wrap 
  overflow-hidden 
  divide-x border 
  rounded-md 
  shadow-sm
`
export {Wrapper}
