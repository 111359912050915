/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {type Product} from '../../../../app/modules/apps/cart/core/products/_models'
import {RootStoreContext} from '../../../../stores/rootStore'
import parse from 'html-react-parser'

const {REACT_APP_PUBLIC_URL} = process.env

const ProductCard = () => {
  const rootStore = useContext(RootStoreContext)
  const {isEmbed, getProductById, addTocart, cartOptions, setProductEmbed, resetCart, cart} =
    rootStore.cartStore

  const [product, setProduct] = useState<Product | null>(null)

  const navigate = useNavigate()

  const {id} = useParams()
  useEffect(() => {
    resetCart()
  }, [])

  useEffect(() => {
    if (product != null && cart.length === 0) {
      // resetCart()
      handleAddTocart(product)
    }
  }, [product, cart])

  useEffect(() => {
    getProduct()
    cartOptions()
  }, [id])

  const getProduct = async () => {
    if (id) {
      const result = await getProductById(id)
      if (result != null) {
        setProduct(result)
        setProductEmbed(result)
      }
    }
  }

  const handleAddTocart = (product: Product) => {
    addTocart(product, 1)
    navigate(`${REACT_APP_PUBLIC_URL}/embed/store/checkout`)
  }

  const isSVG = (image: string) => {
    // verify if extension of image is SVG
    return image.includes('.svg')
  }

  return (
    <>
      {product != null && (
        <>
          <div className='container-product'>
            <div className='imgBx'>
              <img
                src={product.image}
                style={{
                  width: '100%',
                  height: '100%',
                  background: '#fff',
                }}
                alt=''
              />
              {isSVG(product.image) ? <></> : <img src={product.image} alt={product.name} />}
            </div>
            <div className='details'>
              <div className='content'>
                <h2>
                  {product.name} <br />
                </h2>
                {parse(product.description)}
                {parse(product.comment)}
                <h3>$ {product.price}</h3>
                <button
                  className='button-ui button-tertiary add'
                  onClick={() => {
                    handleAddTocart(product)
                  }}
                >
                  AÑADIR
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ProductCard
