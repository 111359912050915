import {type OrdersDetail, type OrdersDetailQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Response} from '../../../../../core/helpers'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'

const API_URL = process.env.REACT_APP_API_URL
const ORDERS_DETAIL_URL = `${API_URL}/orderdetails`

const getOrdersDetail = async (query: string): Promise<OrdersDetail[]> => {
  return await axios
    .get(`${ORDERS_DETAIL_URL}?${query}`)
    .then((d: AxiosResponse<OrdersDetailQueryResponse>) => {
      return d.data as OrdersDetail[]
    })
}

const updateOrdersDetail = async (OrdersDetail: OrdersDetail, id: string) => {
  return await axios
    .patch(`${ORDERS_DETAIL_URL}/update/${id}`, OrdersDetail)
    .then((response: AxiosResponse<Response<OrdersDetail>>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar perfil')
    })
}

export {getOrdersDetail, updateOrdersDetail}
