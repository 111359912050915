export const checkList = {
  'CHECKLIST.MESSAGES': 'Formulario Check List',
  'PAGE.NEXTPAGE': 'Siguiente Pagina y Guardar',
  'PAGE.NEXT': 'Siguiente',
  'PAGE.PREVIOUS': 'Atras',
  'PAGE.COMPLETEPAGE': 'Completar Formulario y Guardar',
  'PAGE.COMPLETEPAGEMESSAGE':
    '<h3>Gracias Por completar las preguntas, por favor revisar si tiene otro documento por llenar</h3>',
  'PAGE.FINISHIMPORTANTPAGE':
    '<h3>Gracias Por completar las preguntas, por favor Click a la pestana formularios asignados</h3>',
}
