/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getFormsCompletedData, updateFormWithData } from 'app/modules/apps/formsdata/core/_requests'
import { getForms, getImms } from 'app/modules/apps/forms/core/_requests'
import { Users } from 'app/modules/apps/users/core/_models'
import { ID } from 'core/helpers'
import { DisplayConsultantFormDarft } from 'app/pages/contratationApp/newUserViewContratationApp/Forms/formConsultantDraft'
import { RootStoreContext } from 'stores/rootStore'
import { getFormsProducts } from 'app/modules/apps/forms/formproducts/core/_requests'
import { getContractByUser } from 'app/modules/apps/contract/_requests'
import { getAllStaff } from 'app/modules/apps/staff/core/_requests'
import addfilesIcon from './NewIntakeView/Icons/add-files.svg'
import ChevronRightIcon from './NewIntakeView/Icons/chevron-right.svg'
import cloudDownload from './NewIntakeView/Icons/cloud-download.svg'
import downloadwhite from './NewIntakeView/Icons/downloadwhite.svg'
import { DisplayFormsForConsultant } from 'app/pages/contratationApp/newUserViewContratationApp/Forms/formForConsultant'
import FormManagerClient2 from 'app/pages/formsManager/formManagerClient/FormManagerClient2'
import IMM5708E from '../../IMMs/IMM5708E'
import IMM5645E from '../../IMMs/IMM5645E'
import IMM5257B from '../../IMMs/IMM5257B'
import IMM5257E from '../../IMMs/IMM5257E'
import IMM1294E from '../../IMMs/IMM1294E'
import IMM5257B_CONYUGUE from '../../IMMs/IMM5257B_Conyuge'
import IMM5257B_DEPENDANT from '../../IMMs/IMM5257B_Dependant'
import IMM5708ECONYUGE from '../../IMMs/IMM5708E_CONYUGE'
import IMM5708EDEPENDANT from '../../IMMs/IMM5708E_DEPENDANT'
import IMM5257ECONYUGE from '../../IMMs/IMM5257E_CONYUGE'
import IMM5645ECONYUGE from '../../IMMs/IMM5645E_CONYUGE'
import IMM5257EDEPENDANT from '../../IMMs/IMM5257E_DEPENDANT'
import IMM5645EDEPENDANT from '../../IMMs/IMM5645E_DEPENDANT'
import File from '../../../../pages/contratationApp/icons/file.svg'
import { downloadFile, downloadFileNew } from 'app/modules/apps/file/core/_requests'
import { translateText } from 'app/modules/apps/appointments/core/_requests'
import checkmark_white from '../../../../../../src/app/pages/contratationApp/icons/checkmark_white.svg'
import CustomToastContainer from 'app/routing/customToastContainer'

interface IProps {
  user: Users | null
  contractsFound: string
}

interface SlideOverProps {
  visible: boolean
  onClose: () => void
  children: ReactNode
}

const CompletedUserFormslist: React.FC<IProps> = ({ user, contractsFound }) => {
  const [lang, setLang] = useState('')
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openFormCompleted, setOpenFormCompleted] = useState<boolean>(false)
  const [getIndex, setGetIndex] = useState(0)
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)
  const [completedFormId, setCompletedFormId] = useState<string>('')
  const [userFormsCompleted, setUserFormsCompleted] = useState<any[]>([])
  const [userFormsCompleted2, setUserFormsCompleted2] = useState<any[]>([])
  const [staffId, setStaffId] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState("")
  const rootStore = useContext(RootStoreContext)
  const [porcentageBase, setPorcentageBase] = useState(0)
  const [completedLenght, setCompletedLenght] = useState(0)
  const [active3, setActive3] = useState<boolean>(false)
  const [active4, setActive4] = useState<boolean>(false)
  const [active5, setActive5] = useState<boolean>(false)
  const [active6, setActive6] = useState<boolean>(false)
  const [active7, setActive7] = useState<boolean>(false)
  const [active8, setActive8] = useState<boolean>(false)
  const [active888, setActive888] = useState<boolean>(false)
  const [showToast, setShowToast] = useState<boolean>(false)
  const [active9, setActive9] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [update, setupdate] = useState(0)
  const [productId, setProductId] = useState('')
  const [userForms, setUserForms] = useState<any[]>([])
  const [userFormsFinal, setUserFormsFinal] = useState<any[]>([])
  const [userFormsDrafts, setUserFormsDrafts] = useState<any[]>([])
  const [userFormsTotal, setUserFormsTotal] = useState<any[]>([])
  const [selectedName, setSelectedName] = useState("")
  const [prodPrincipal, setProdPrincipal] = useState<any>(null)
  const finalProducts: string[] = []
  const finalProductsIdMember: any = []
  const [memberTypeSelected, setMemberTypeSelected] = useState('')
  const [memberNameSelected, setMemberNameSelected] = useState('')
  const [showPendingForms, setShowPendingForms] = useState<boolean>(false)
  const [showCompletedForm, setShowCompletedForm] = useState<boolean>(false)
  const [showDraftForms, setShowDraftForms] = useState<boolean>(false)
  const [showAllForms, setShowAllForms] = useState<boolean>(true)
  const [openManual, setOpenManual] = useState<boolean>(false)
  const [uploadThing, setUploadThing] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState("all")
  const [pendantForms, setpendantForms] = useState(-1)
  const [draftForms, setDraftforms] = useState(-1)
  const [completedForms, setCompletedForms] = useState(-1)
  const [barUpdate, setbarUpdate] = useState(0)
  const [principalService, setPrincipalService] = useState('')
  const [allFroms, setAllForms] = useState<any>(null)
  const [aplicants, setAplicants] = useState<string[]>([])
  const [draftFormsPrincipal, setDraftFormsPrincipal] = useState(-1)
  const [draftFormsConyuge, setDraftFormsConyuge] = useState(-1)
  const [draftFormsChildren, setDraftFormsChildren] = useState<any>([])
  const [selectedItems, setSelectedItems] = useState<any>([])
  const [loadingDownloadAll, setLoadingDownloadAll] = useState<boolean>(false)
  const [isSlideOverVisible, setSlideOverVisible] = useState(false)
  const [filterIMM, setFilterIMM] = useState('ver todos')
  const [filterName, setFilterName] = useState('ver todos')
  const [filterDate, setFilterDate] = useState('ver todos')
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [contractsData, setContractsData] = useState(null)
  const [isTranslating, setIsTranslating] = useState(false); // Controla si las traducciones están en proceso
const [showCompletionToast, setShowCompletionToast] = useState(false); // Controla si mostrar el toast de traducción completada

  const [userAllForms, setUserAllForms] = useState<any>({
    formsStatusCompleted: [],
    formsStatusDraft: [],
    formsStatusPending: [],
  })

  const specificOrder: string[] = [
    '001', '002', '003', '004', '005', '006', '007', '008', '009', '010',
    '011', '012', '013', '014', '015', '016', '017', '018', '019', '020',
    '021', '022', '023', '024', '025', '026', '027', '028', '029', '030',
  ]

  let members: any = []

  const { data, status, refetch: refetchForms } = useQuery(
    'listforms',
    async () => await getForms('limit=50&page=1&name=')
  ) /*formularios*/

  const { data: dataImm, refetch: docRefetch } = useQuery(
    'dataimm',
    async () => await getImms(contractsFound),
    {
      initialData: { data: [] }
    }
  )

  const { data: dataFromDatabase,
    status: statusFromDatabase,
    refetch: refetchFromDataBase,
    isRefetching: irDatabase,
    isFetching, isLoading } = useQuery(
      'allcontracsDb',
      async () => await getContractByUser(user?.id!)
    ) /* contratos */

  const { data: StaffData, status: Staffstatus } = useQuery(
    'getStaff',
    async () => await getAllStaff()
  )

  const {
    data: dataFormsCompleted,
    status: statusFromCompleted,
    refetch,
    isRefetching,
    isFetching: ifCompleted,
    isLoading: ilCompleted
  } = useQuery(
    'listformsclients',
    async () => await getFormsCompletedData(user?.id!)
  ) /*formularios COmpletados*/

  useEffect(() => {
    const fetchData = async () => {
      if (user?.id) {
        try {
          const data = await getContractByUser(user.id)
          if (data) {
            setContractsData(data) // Guardamos los datos en un estado
          } else {
            console.error('No data retrieved')
          }
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      } else {
        console.error('User is null')
      }
    }
    fetchData()
  }, [user])

  useEffect(() => {
    if (dataImm && dataImm.data) {
      let newFilteredData = dataImm.data
      if (filterIMM !== 'ver todos') {
        newFilteredData = newFilteredData.filter((record: { imm: string }) => record.imm === filterIMM)
      }
      if (filterName !== 'ver todos') {
        newFilteredData = newFilteredData.filter((record: { memberName: string }) => record.memberName === filterName)
      }
      if (filterDate !== 'ver todos') {
        newFilteredData = newFilteredData.filter((record: { createdAt: string }) => record.createdAt.split('T')[0] === filterDate)
      }
      setFilteredData(newFilteredData)
    }
  }, [filterIMM, filterName, filterDate, dataImm])

  // Function to get unique values
  const getUniqueValues = (array: any[] = [], key: string) => {
    if (!array.length) return []
    if (key === 'createdAt') {
      return Array.from(new Set(array.map(item => item[key].split('T')[0])))
    }
    return Array.from(new Set(array.map(item => item[key])))
  }

  const SlideOver: React.FC<SlideOverProps> = ({ visible, onClose, children }) => {
    return (
      <div
        className={`fixed inset-0 overflow-hidden transform transition-transform ${visible ? 'translate-x-0' : 'translate-x-full'}`}
        style={{ zIndex: 9999 }}>
        <div className='absolute inset-0 bg-gray-500 opacity-75' onClick={onClose}></div>
        <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
          <div className='relative w-screen main-holder' style={{ maxWidth: '879px' }}>
            <div style={{
              border: '1px solid white',
              borderRadius: '6px'
            }}
              className='flex flex-col bg-white shadow-xl overflow-y-scroll h-100'>
              <div className='flex-1 py-6 overflow-y-auto px-4 sm:px-6'>
                <button
                  style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px 24px',
                    height: 'auto',
                    width: 'auto',
                    backgroundColor: 'white',
                    border: '2px solid black'
                  }}
                  onClick={onClose}
                  className='button-form-volver'>
                  <img
                    alt="Calendar"
                    className='meet-image2'
                    src='/media/icons/app/menu/arrow-left-black.svg' />
                  <b className='meet-text2'>VOLVER</b>
                </button>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>)
  }

  const handleDownloadAll = async () => {
    setLoadingDownloadAll(true)
    for (const fileId of selectedItems) {
      try {
        const response = await downloadFileNew(fileId, () => { })
        if (response) {
          // Si hay una respuesta, puedes manejarla aquí
        } else {
          // Manejar el caso donde no hay respuesta, si es necesario
        }
      } catch (error) {
        // Manejar errores si la promesa es rechazada
      }
    }
    setLoadingDownloadAll(false)
  }

  function groupProductsByMember(products: any) {
    const groupedProducts = products.reduce((groupedProducts: any, product: any) => {
      const { memberName, memberType, productId, productName } = product
      const key = `${memberName}-${memberType}`
      if (!groupedProducts[key]) {
        groupedProducts[key] = {
          memberName,
          memberType,
          products: [],
        }
      }
      groupedProducts[key].products.push({
        productId,
        productName,
      })
      return groupedProducts
    }, {})
    const result = Object.values(groupedProducts)
    return result
  }

  const handleToggleContent = (index: number) => {
    toggleContent(index)
  }

  const handleToggleContent3 = (index: number) => {
    toggleContent3(index)
  }

  const handleToggleContent2 = (index: number) => {
    toggleContent2(index)
  }

  const findAuthor = (formId: any) => {
    if (dataFormsCompleted && dataFormsCompleted.length > 0) {
      const foundForm = dataFormsCompleted.find((form: any) => form.id === formId)
      if (foundForm && foundForm.author && foundForm.author.firstName && foundForm.author.lastName) {
        return `${foundForm.author.firstName + " " + foundForm.author.lastName}`
      }
    }
    return ""
  }

  const findCreatedAt = (formId: any) => {
    if (dataFormsCompleted && dataFormsCompleted.length > 0) {
      const foundForm = dataFormsCompleted.find((form: any) => form.id === formId)
      if (foundForm) {
        return foundForm.createdAt.slice(0, 10)
      }
    }
    return ""
  }

  const updateForms = (userFormsCompletedParam: any, userFormsDraftsParam: any) => {
    if (userForms.length > 0 &&
      userFormsTotal.length >= 0 &&
      userForms[0].memberName &&
      userFormsDrafts &&
      userFormsCompleted) {
      if (active6 === false) {
        const missingForms: any = []
        const missingFormsByMember: any = {}
        setUserForms(userForms.filter(form => {
          return form.hasOwnProperty('memberName') && form.hasOwnProperty('memberType')
        }))
        for (const producto of userForms) {
          const { memberName, memberType } = producto
          const key = `${memberName}_${memberType}`
          if (!missingFormsByMember[key]) {
            missingFormsByMember[key] = []
          }
          const productoCompletado = userFormsTotal.find(
            (completado) => ((completado.productName === producto.name) &&
              (completado.memberType === producto.memberType) &&
              (completado.memberName === producto.memberName))
          )
          if (!productoCompletado) {
            missingFormsByMember[key].push({
              name: producto.name,
              prodIds: producto.prodIds,
              forms: producto.forms,
              memberName,
              memberType
            })
            continue
          }
          const missingFormsProducto: any[] = []
          for (const formulario of producto.forms) {
            const formularioCompletado = productoCompletado.forms.find(
              (completadoForm: any) => completadoForm.formData.formName === formulario.data.formName
            )
            if (!formularioCompletado) {
              missingFormsByMember[key].push({
                name: producto.name,
                id: producto.id,
                prodIds: producto.prodIds,
                forms: missingFormsProducto,
                memberName,
                memberType
              })
            }
          }
          missingForms[producto.name] = {
            name: producto.name,
            id: producto.id,
            prodIds: producto.prodIds,
            forms: missingFormsProducto,
            memberName: producto.memberName,
            memberType: producto.memberType
          }
        }
        const valores: any = Object.values(missingFormsByMember).flat()
        const missingFormsArray = Object.values(missingFormsByMember)
        missingFormsArray.forEach((producto: any) => {
          producto && producto.forms &&
            producto.forms.sort((a: any, b: any) => {
              const indexA = specificOrder.indexOf(a.data.guide)
              const indexB = specificOrder.indexOf(b.data.guide)
              if (indexA === -1 && indexB === -1) {
                return 0
              }
              if (indexA === -1) {
                return 1
              }
              if (indexB === -1) {
                return -1
              }
              return indexA - indexB
            })
        })
        if (userFormsTotal && userFormsTotal[0] && userFormsTotal[0].forms) {
          valores.forEach((pending: any) => {
            pending.forms = pending.forms.filter((formPending: any) => {
              let foundMatch = false
              userFormsTotal[0].forms.forEach((notPending: any) => {
                if (
                  notPending.formDataFormId  === formPending.id &&
                  pending.memberName === notPending.memberName &&
                  pending.memberType === notPending.memberType
                ) {
                  foundMatch = true
                }
              })
              return !foundMatch
            })
          })
        }
        for (const valor of valores) {
          if (userFormsTotal && userFormsTotal[0] && userFormsTotal[0].forms) {
            for (const notPending of userFormsTotal[0].forms) {
              if (valor.memberName === notPending.memberName &&
                valor.memberType === notPending.memberType &&
                valor.name === notPending.productName
              ) {
                valor.forms.push(notPending)
              }
            }
          }
        }
        let totalPendantForms = 0
        valores.forEach((pendant: any) => {
          pendant.forms.forEach((status: any) => {
            if (status.status === null) {
              totalPendantForms++
            }
          })
        })
        let totalDraftFormsPrincipal = 0
        let totalDraftFormsConyuge = 0
        const totalDraftFormsChildren: any = []
        userFormsDraftsParam.forEach((draftMember: any) => {
          if (draftMember.memberType === 'Solicitante principal') {
            const validForms = draftMember.forms.filter((form: any) => !form.deletedAt)
            totalDraftFormsPrincipal += validForms.length
          }
          if (draftMember.memberType === 'Conyuge o Pareja de hecho') {
            const validForms = draftMember.forms.filter((form: any) => !form.deletedAt)
            totalDraftFormsConyuge += validForms.length
          }
          if (draftMember.memberType === 'Hija(o) dependiente') {
            const validForms = draftMember.forms.filter((form: any) => !form.deletedAt)
            totalDraftFormsChildren.push({
              memberName: draftMember.memberName,
              draft: validForms.length
            })
          }
        })
        setDraftFormsPrincipal(totalDraftFormsPrincipal)
        setDraftFormsConyuge(totalDraftFormsConyuge)
        setDraftFormsChildren(totalDraftFormsChildren)
        setpendantForms(totalPendantForms)
        setUserAllForms({
          formsStatusCompleted: userFormsCompletedParam,
          formsStatusDraft: userFormsDraftsParam,
          formsStatusPending: valores,
        })
        setUserFormsFinal(valores)
        setupdate(update + 1)
        setActive6(true)
        refetch()
      }
    }
  }

  if (statusFromCompleted === 'success' &&
    statusFromDatabase === 'success' &&
    status === 'success' &&
    active3 === false) {
    if (active5 === false) {
      const tempFormsByProductName: { [productName: string]: any[] } = {}
      dataFormsCompleted?.forEach((formCompleted: any) => {
        const productName = formCompleted.product.name
        const idProduct = formCompleted.product.id
        const formData = formCompleted.datafill
        const formDataId = formCompleted.id
        const formDataFormId = formCompleted.form.id
        const userContractId = formCompleted.contract
        const formStatus = formCompleted.status
        const memberName = formCompleted.memberName
        const memberType = formCompleted.memberType
        const deletedAt = formCompleted.deletedAt
        const updatedAt = formCompleted.updatedAt
        if (userContractId === contractsFound) {
          if (tempFormsByProductName.hasOwnProperty(productName)) {
            tempFormsByProductName[productName].push({
              formData,
              formDataId,
              formDataFormId,
              formStatus,
              idProduct,
              memberName,
              memberType,
              productName,
              deletedAt,
              updatedAt
            })
          } else {
            tempFormsByProductName[productName] = [{
              formData,
              formDataId,
              formDataFormId,
              formStatus,
              idProduct,
              memberName,
              memberType,
              productName,
              deletedAt,
              updatedAt
            }]
          }
        }
      })
      const formsStatus0: any = []
      const formsStatus1: any = []
      const formsStatusCombined: any = []
      const flattemp = Object.values(tempFormsByProductName).flat()
      const formsWithStatus0: any = flattemp.filter(
        (form) => form.formStatus === 0
      )
      const formsWithStatus1: any = flattemp.filter(
        (form) => form.formStatus === 1
      )
      if (formsWithStatus0.length > 0) {
        formsStatus0.push({
          name: formsWithStatus0.productName,
          memberName: formsWithStatus0.memberName,
          memberType: formsWithStatus0.memberType,
          forms: formsWithStatus0,
          deletedAt: formsStatus0.deletedAt,
          updatedAt: formsStatus0.updatedAt
        })
      }
      if (formsWithStatus1.length > 0) {
        formsStatus1.push({
          name: formsWithStatus0.productName,
          memberName: formsWithStatus1.memberName,
          memberType: formsWithStatus1.memberType,
          forms: formsWithStatus1,
          deletedAt: formsStatus1.deletedAt,
          updatedAt: formsStatus0.updatedAt
        })
      }
      const combinedForms = [...formsWithStatus0, ...formsWithStatus1]
      if (combinedForms.length > 0) {
        formsStatusCombined.push({ forms: combinedForms })
      }
      setUserFormsTotal(formsStatusCombined)
      setupdate(update + 1)
      let uploadthing2 = true
      if (uploadThing === true || uploadthing2) {
        const tempFormsByProductName: {
          [key: string]: {
            name: string
            memberName: string
            memberType: string
            forms: any[]
          }
        } = {}
        dataFormsCompleted?.forEach((formCompleted: any) => {
          const productName = formCompleted.product.name
          const idProduct = formCompleted.product.id
          const formData = formCompleted.datafill
          const formDataId = formCompleted.id
          const formDataFormId = formCompleted.form.id
          const userContractId = formCompleted.contract
          const formStatus = formCompleted.status
          const memberName = formCompleted.memberName
          const memberType = formCompleted.memberType
          const deletedAt = formCompleted.deletedAt
          const updatedAt = formCompleted.updatedAt
          if (userContractId === contractsFound) {
            const key = `${productName}_${memberName}_${memberType}`
            if (tempFormsByProductName.hasOwnProperty(key)) {
              tempFormsByProductName[key].forms.push({
                formData,
                formDataId,
                formDataFormId,
                formStatus,
                idProduct,
                memberName,
                memberType,
                productName,
                deletedAt,
                updatedAt
              })
            } else {
              tempFormsByProductName[key] = {
                name: productName,
                memberName,
                memberType,
                forms: [
                  {
                    formData,
                    formDataId,
                    formDataFormId,
                    formStatus,
                    idProduct,
                    memberName,
                    memberType,
                    productName,
                    deletedAt,
                    updatedAt
                  },
                ],
              }
            }
          }
        })
        // Crear un mapa para los índices de orden
        const orderMap = specificOrder.reduce((map, id, index) => {
          map[id] = index
          return map
        }, {} as Record<string, number>)

        // Función para ordenar los formularios según specificOrder
        const sortFormsBySpecificOrder = (forms: any) => {
          return forms.sort((a: any, b: any) => orderMap[a.formData.guide] - orderMap[b.formData.guide])
        }

        // Filtrar y ordenar formsStatus0
        const formsStatus0 = Object.values(tempFormsByProductName)
          .filter((item) => item.forms.some((form) => form.formStatus === 0))
          .map((item) => ({
            ...item,
            forms: sortFormsBySpecificOrder(item.forms.filter((form) => form.formStatus === 0))
          }))

        // Filtrar y ordenar formsStatus1
        const formsStatus1 = Object.values(tempFormsByProductName)
          .filter((item) => item.forms.some((form) => form.formStatus === 1))
          .map((item) => ({
            ...item,
            forms: sortFormsBySpecificOrder(item.forms.filter((form) => form.formStatus === 1))
          }))
        let totalCompletedForms = 0
        let totalDraftForms = 0
        formsStatus1.forEach(complete => {
          complete.forms.forEach((form: any) => {
            if (!form.deletedAt) {
              totalCompletedForms++
            }
          })
        })
        formsStatus0.forEach(complete => {
          complete.forms.forEach((form: any) => {
            if (!form.deletedAt) {
              totalDraftForms++
            }
          })
        })
        if (uploadThing === true) {
        }
        setTimeout(() => {
          setCompletedForms(totalCompletedForms)
        }, 3000)
        let totalDraftFormsPrincipal = 0
        let totalDraftFormsConyuge = 0
        const totalDraftFormsChildren: any = []
        formsStatus0.forEach((draftMember: any) => {
          if (draftMember.memberType === 'Solicitante principal') {
            const validForms = draftMember.forms.filter((form: any) => !form.deletedAt)
            totalDraftFormsPrincipal += validForms.length
          }
          if (draftMember.memberType === 'Conyuge o Pareja de hecho') {
            const validForms = draftMember.forms.filter((form: any) => !form.deletedAt)
            totalDraftFormsConyuge += validForms.length
          }
          if (draftMember.memberType === 'Hija(o) dependiente') {
            const validForms = draftMember.forms.filter((form: any) => !form.deletedAt)
            totalDraftFormsChildren.push({
              memberName: draftMember.memberName,
              draft: validForms.length
            })
          }
        })
        setDraftFormsPrincipal(totalDraftFormsPrincipal)
        setDraftFormsConyuge(totalDraftFormsConyuge)
        setDraftFormsChildren(totalDraftFormsChildren)
        setDraftforms(totalDraftForms)
        setUserFormsCompleted(formsStatus1)
        setUserFormsDrafts(formsStatus0)
        setupdate(update + 1)
        setActive8(true)
        uploadthing2 = false
        setUploadThing(false)
      }
      setActive5(true)
    }
  }

  const twotwo = () => {
    const tempFormsByProductName: { [productName: string]: any[] } = {}
    dataFormsCompleted?.forEach((formCompleted: any) => {
      const productName = formCompleted.product.name
      const idProduct = formCompleted.product.id
      const formData = formCompleted.datafill
      const formDataId = formCompleted.id
      const formDataFormId = formCompleted.form.id
      const userContractId = formCompleted.contract
      const formStatus = formCompleted.status
      const memberName = formCompleted.memberName
      const memberType = formCompleted.memberType
      const deletedAt = formCompleted.deletedAt
      const updatedAt = formCompleted.updatedAt
      if (userContractId === contractsFound) {
        if (tempFormsByProductName.hasOwnProperty(productName)) {
          tempFormsByProductName[productName].push({
            formData,
            formDataId,
            formDataFormId,
            formStatus,
            idProduct,
            memberName,
            memberType,
            productName,
            deletedAt,
            updatedAt
          })
        } else {
          tempFormsByProductName[productName] = [{
            formData,
            formDataId,
            formDataFormId,
            formStatus,
            idProduct,
            memberName,
            memberType,
            productName,
            deletedAt,
            updatedAt
          }]
        }
      }
    })
    const formsStatus0: any = []
    const formsStatus1: any = []
    const formsStatusCombined: any = []
    const flattemp = Object.values(tempFormsByProductName).flat()
    const formsWithStatus0: any = flattemp.filter(
      (form) => form.formStatus === 0
    )
    const formsWithStatus1: any = flattemp.filter(
      (form) => form.formStatus === 1
    )
    if (formsWithStatus0.length > 0) {
      formsStatus0.push({
        name: formsWithStatus0.productName,
        memberName: formsWithStatus0.memberName,
        memberType: formsWithStatus0.memberType,
        forms: formsWithStatus0,
        deletedAt: formsStatus0.deletedAt,
        updatedAt: formsStatus0.updatedAt
      })
    }
    if (formsWithStatus1.length > 0) {
      formsStatus1.push({
        name: formsWithStatus0.productName,
        memberName: formsWithStatus1.memberName,
        memberType: formsWithStatus1.memberType,
        forms: formsWithStatus1,
        deletedAt: formsStatus1.deletedAt,
        updatedAt: formsStatus0.updatedAt
      })
    }
    const combinedForms = [...formsWithStatus0, ...formsWithStatus1]
    if (combinedForms.length > 0) {
      formsStatusCombined.push({ forms: combinedForms })
    }
    setUserFormsTotal(formsStatusCombined)
    setupdate(update + 1)
    let uploadthing2 = true
    if (uploadThing === true || uploadthing2) {
      const tempFormsByProductName: {
        [key: string]: {
          name: string
          memberName: string
          memberType: string
          forms: any[]
        }
      } = {}
      dataFormsCompleted?.forEach((formCompleted: any) => {
        const productName = formCompleted.product.name
        const idProduct = formCompleted.product.id
        const formData = formCompleted.datafill
        const formDataId = formCompleted.id
        const formDataFormId = formCompleted.form.id
        const userContractId = formCompleted.contract
        const formStatus = formCompleted.status
        const memberName = formCompleted.memberName
        const memberType = formCompleted.memberType
        const deletedAt = formCompleted.deletedAt
        const updatedAt = formCompleted.updatedAt
        if (userContractId === contractsFound) {
          const key = `${productName}_${memberName}_${memberType}`
          if (tempFormsByProductName.hasOwnProperty(key)) {
            tempFormsByProductName[key].forms.push({
              formData,
              formDataId,
              formDataFormId,
              formStatus,
              idProduct,
              memberName,
              memberType,
              productName,
              deletedAt,
              updatedAt
            })
          } else {
            tempFormsByProductName[key] = {
              name: productName,
              memberName,
              memberType,
              forms: [
                {
                  formData,
                  formDataId,
                  formDataFormId,
                  formStatus,
                  idProduct,
                  memberName,
                  memberType,
                  productName,
                  deletedAt,
                  updatedAt
                },
              ],
            }
          }
        }
      })

      // Crear un mapa para los índices de orden
      const orderMap = specificOrder.reduce((map, id, index) => {
        map[id] = index
        return map
      }, {} as Record<string, number>)

      // Función para ordenar los formularios según specificOrder
      const sortFormsBySpecificOrder = (forms: any) => {
        return forms.sort((a: any, b: any) => orderMap[a.formData.guide] - orderMap[b.formData.guide])
      }

      // Filtrar y ordenar formsStatus0
      const formsStatus0 = Object.values(tempFormsByProductName)
        .filter((item) => item.forms.some((form) => form.formStatus === 0))
        .map((item) => ({
          ...item,
          forms: sortFormsBySpecificOrder(item.forms.filter((form) => form.formStatus === 0))
        }))

      // Filtrar y ordenar formsStatus1
      const formsStatus1 = Object.values(tempFormsByProductName)
        .filter((item) => item.forms.some((form) => form.formStatus === 1))
        .map((item) => ({
          ...item,
          forms: sortFormsBySpecificOrder(item.forms.filter((form) => form.formStatus === 1))
        }))
      let totalCompletedForms = 0
      let totalDraftForms = 0
      formsStatus1.forEach(complete => {
        complete.forms.forEach((form: any) => {
          if (!form.deletedAt) {
            totalCompletedForms++
          }
        })
      })
      formsStatus0.forEach(complete => {
        complete.forms.forEach((form: any) => {
          if (!form.deletedAt) {
            totalDraftForms++
          }
        })
      })
      if (uploadThing === true) {
      }
      setTimeout(() => {
        setCompletedForms(totalCompletedForms)
      }, 3000)
      setDraftforms(totalDraftForms)
      setUserFormsCompleted(formsStatus1)
      setUserFormsDrafts(formsStatus0)
      setupdate(update + 1)
      setActive8(true)
      uploadthing2 = false
      setUploadThing(false)
    }
    setActive5(true)
  }

  if (statusFromDatabase === 'success' && active === false && active3 === false) {
    dataFromDatabase.data.map((data: any) => {
      if (data.contracts_users_envelope_id === contractsFound) {
        if (data.contracts_users_applicants) {
          members = groupProductsByMember(JSON.parse(data.contracts_users_applicants))
          members.forEach((member: any) => {
            member.products.forEach((product: any) => {
              finalProducts.push(product.productName)
              finalProductsIdMember.push({
                productId: product.productId,
                memberType: member.memberType,
                memberName: member.memberName,
              })
            })
          })
        }
      }
    })

    if (active4 === false && finalProducts.length > 0) {
      setActive4(true)
      processFinalProductsId(members, userFormsCompleted, userFormsDrafts)
    }
    if (userForms !== null && userForms?.length > 0) {
      let totalForms = 0
      userForms.forEach((product) => {
        const productForms = product.forms || []
        totalForms += productForms.length
      })
      const percentageBase = totalForms > 0 ? 100 / totalForms : 0
      setPorcentageBase(percentageBase)
      setActive(true)
    }
  }

  async function processFinalProductsId(
    finalProductsIdMember: any[],
    userFormsCompletedParam: any,
    userFormsDraftsParam: any) {
    const finalResult = []
    if (finalProductsIdMember && finalProductsIdMember.length > 0 && active9 === false) {
      for (const member of finalProductsIdMember) {
        const finalObject: any = {}
        for (const product of member.products) {
          const res = await getFormsProducts(product.productId) as any
          if (res !== null && res.length > 0) {
            const productName = res[0].product.name
            const productId = res[0].product.id
            if (!finalObject[productName]) {
              finalObject[productName] = {
                forms: res.map((form: any) => form.form),
                prodIds: productId,
                memberType: member.memberType,
                memberName: member.memberName,
              }
            } else {
              finalObject[productName].forms.push(...res.map((form: any) => form.form))
              finalObject[productName].prodIds.push(productId)
            }
            res.forEach((form: any) => {
              if (!userForms.some((f) => f.id === form.form.id)) {
                userForms.push(form.form)
              }
            })
          }
        }
        const memberProducts = Object.keys(finalObject).map((productName) => ({
          name: productName,
          forms: finalObject[productName].forms,
          prodIds: finalObject[productName].prodIds,
          memberType: finalObject[productName].memberType,
          memberName: finalObject[productName].memberName,
        }))
        finalResult.push(...memberProducts)
      }
      setActive9(true)
    }
    setUserForms(finalResult)
    setTimeout(() => {
      setActive7(true)
    }, 500)
    updateForms(userFormsCompletedParam, userFormsDraftsParam)
  }

  const updateForms2 = (userFormsCompletedParam: any, userFormsDraftsParam: any) => {
    if (active888 === true) {
      const missingForms: any = []
      const missingFormsByMember: any = {}
      setUserForms(userForms.filter(form => {
        return form.hasOwnProperty('memberName') &&
          form.hasOwnProperty('memberType')
      }))
      for (const producto of userForms) {
        const { memberName, memberType } = producto
        const key = `${memberName}_${memberType}`
        if (!missingFormsByMember[key]) {
          missingFormsByMember[key] = []
        }
        const productoCompletado = userFormsTotal.find(
          (completado) => ((completado.productName === producto.name) &&
            (completado.memberType === producto.memberType) &&
            (completado.memberName === producto.memberName))
        )
        if (!productoCompletado) {
          missingFormsByMember[key].push({
            name: producto.name,
            prodIds: producto.prodIds,
            forms: producto.forms,
            memberName,
            memberType
          })
          continue
        }
        const missingFormsProducto: any[] = []
        for (const formulario of producto.forms) {
          const formularioCompletado = productoCompletado.forms.find(
            (completadoForm: any) => completadoForm.formData.formName === formulario.data.formName
          )
          if (!formularioCompletado) {
            missingFormsByMember[key].push({
              name: producto.name,
              id: producto.id,
              prodIds: producto.prodIds,
              forms: missingFormsProducto,
              memberName,
              memberType
            })
          }
        }
        missingForms[producto.name] = {
          name: producto.name,
          id: producto.id,
          prodIds: producto.prodIds,
          forms: missingFormsProducto,
          memberName: producto.memberName,
          memberType: producto.memberType
        }
      }
      const valores: any = Object.values(missingFormsByMember).flat()
      const missingFormsArray = Object.values(missingFormsByMember)
      missingFormsArray.forEach((producto: any) => {
        producto && producto.forms &&
          producto.forms.sort((a: any, b: any) => {
            const indexA = specificOrder.indexOf(a.data.guide)
            const indexB = specificOrder.indexOf(b.data.guide)
            if (indexA === -1 && indexB === -1) {
              return 0
            }
            if (indexA === -1) {
              return 1
            }
            if (indexB === -1) {
              return -1
            }
            return indexA - indexB
          })
      })

      if (
        userFormsTotal &&
        userFormsTotal[0] &&
        userFormsTotal[0].forms &&
        valores
      ) {
        valores.forEach((pending: any) => {
          if (pending.forms) {
            pending.forms = pending.forms.filter((formPending: any) => {
              let foundMatch = false
              userFormsTotal[0].forms.forEach((notPending: any) => {
                if (
                  notPending.formDataFormId === formPending.id &&
                  pending.memberName === notPending.memberName &&
                  pending.memberType === notPending.memberType
                ) {
                  foundMatch = true
                }
              })
              return !foundMatch
            })
          }
        })
      }

      for (const valor of valores) {
        if (userFormsTotal &&
          userFormsTotal[0] &&
          userFormsTotal[0].forms) {
          for (const notPending of userFormsTotal[0].forms) {
            if (valor.memberName === notPending.memberName &&
              valor.memberType === notPending.memberType &&
              valor.name === notPending.productName
            ) {
              valor.forms.push(notPending)
            }
          }
        }
      }

      let totalPendantForms = 0
      if (valores) {
        valores.forEach((pendant: any) => {
          if (pendant.forms) {
            pendant.forms.forEach((status: any) => {
              if (status.status === null) {
                totalPendantForms++
              }
            })
          }
        })
      }
      setpendantForms(totalPendantForms)
      setUserAllForms({
        formsStatusCompleted: userFormsCompletedParam,
        formsStatusDraft: userFormsDraftsParam,
        formsStatusPending: valores,
      })
      setUserFormsFinal(valores)
      setActive888(false)
    }
  }

  const [buttonStates, setButtonStates] = useState<boolean[]>(
    Array(userFormsFinal.length).fill(false)
  )
  const toggleContent = (index: number) => {
    const updatedButtonStates = [...buttonStates]
    updatedButtonStates[index] = !updatedButtonStates[index]
    setButtonStates(updatedButtonStates)
  }

  const [buttonStates2, setButtonStates2] = useState<boolean[]>(
    Array(userFormsCompleted.length).fill(false)
  )
  const toggleContent2 = (index: number) => {
    const updatedButtonStates = [...buttonStates2]
    updatedButtonStates[index] = !updatedButtonStates[index]
    setButtonStates2(updatedButtonStates)
  }

  const [buttonStates3, setButtonStates3] = useState<boolean[]>(
    Array(userFormsCompleted.length).fill(false)
  )
  const toggleContent3 = (index: number) => {
    const updatedButtonStates = [...buttonStates3]
    updatedButtonStates[index] = !updatedButtonStates[index]
    setButtonStates3(updatedButtonStates)
  }

  const filterButton = (name: string) => {
    if (name === "pending") {
      setShowCompletedForm(false)
      setShowDraftForms(false)
      setShowPendingForms(true)
      setShowAllForms(false)
      return
    }
    if (name === "completed") {
      setShowCompletedForm(true)
      setShowDraftForms(false)
      setShowPendingForms(false)
      setShowAllForms(false)
      return
    }
    if (name === "draft") {
      setShowCompletedForm(false)
      setShowDraftForms(true)
      setShowPendingForms(false)
      setShowAllForms(false)
      return
    }
    if (name === "all") {
      setShowCompletedForm(false)
      setShowDraftForms(false)
      setShowPendingForms(false)
      setShowAllForms(true)
      return
    }
  }
  const handleSelectChange = (e: any) => {
    setSelectedOption(e.target.value) // Actualiza el estado con la opción seleccionada
  }

  if (
    statusFromDatabase === 'success' &&
    Staffstatus === 'success' &&
    userFormsCompleted.length > 0 &&
    active2 === false
  ) {
    userFormsCompleted2.map((forms: any, index) => {
      dataFromDatabase.data.map((usercontract: any) => {
        if (usercontract.contracts_users_envelope_id === forms.contract) {
          StaffData.map((staff: any) => {
            if (staff.id === usercontract.contracts_users_staffId) {
              setStaffId(staff.user.firstName + ' ' + staff.user.lastName)
            }
          })
        }
      })
    })
    setActive2(true)
  }

  if (status === 'success') {
    if (active === false) {
      const tempFormsByContract: { [contractId: string]: { [productName: string]: any[] } } = {} // Cambio en la estructura
      const userContracts: { [contractId: string]: string } = {} // Para rastrear los contratos de usuario
      dataFormsCompleted?.forEach((formCompleted: any) => {
        const productName = formCompleted.product.name
        const formData = formCompleted.datafill
        const formDataId = formCompleted.id
        const formDataProductId = formCompleted.product.id
        const formStatus = formCompleted.status
        const memberName = formCompleted.memberName
        const memberType = formCompleted.memberType
        const userContractId = formCompleted.contract
        if (userContractId) {
          if (!userContracts.hasOwnProperty(userContractId)) {
            userContracts[userContractId] = productName // Almacena el nombre del contrato para el ID del contrato
          }
          if (!tempFormsByContract.hasOwnProperty(userContractId)) {
            tempFormsByContract[userContractId] = {}
          }
          if (tempFormsByContract[userContractId].hasOwnProperty(productName)) {
            tempFormsByContract[userContractId][productName].push({
              formData,
              formDataId,
              formStatus,
              formDataProductId,
              memberName,
              memberType
            })
          } else {
            tempFormsByContract[userContractId][productName] = [
              { formData, formDataId, formStatus, formDataProductId, memberName, memberType },
            ]
          }
        }
      })

      // Crear un mapa para los índices de orden
      const orderMap = specificOrder.reduce((map, id, index) => {
        map[id] = index
        return map
      }, {} as Record<string, number>)

      const finalResult = Object.keys(tempFormsByContract).map((contractId) => ({
        name: userContracts[contractId], // Obtén el nombre del contrato a través del ID del contrato
        products: Object.keys(tempFormsByContract[contractId]).map((productName) => ({
          name: productName,
          forms: tempFormsByContract[contractId][productName]
            .map((formDataWithId) => ({
              formData: formDataWithId.formData,
              formDataId: formDataWithId.formDataId,
              formStatus: formDataWithId.formStatus,
              formProductId: formDataWithId.formDataProductId,
              memberName: formDataWithId.memberName,
              memberType: formDataWithId.memberType,
            }))
            .sort((a, b) => orderMap[a.formData.guide] - orderMap[b.formData.guide]), // Ordenar según specificOrder
        })),
      }))
      setUserFormsCompleted2(finalResult)
      setActive(true)
    }
  }

  const GetIndex = (id: ID) => {
    data?.map((form, index) => {
      if (id === form.id) {
        setGetIndex(index)
      }
    })
  }

  const GetCompletedIndex = (id: ID) => {
    dataFormsCompleted?.map((form: any, index: number) => {
      if (id === form.id) {
        setGetIndex(index)
      }
    })
  }

  const handleCheckboxChange = (record: any) => {
    if (selectedItems.includes(record.file.id)) {
      setSelectedItems(selectedItems.filter((itemFileId: any) => itemFileId !== record.file.id))
    } else {
      setSelectedItems([...selectedItems, record.file.id])
    }
  }

  const handleSelectAll = () => {
    if (selectedItems.length === dataImm.data.length) {
      setSelectedItems([])
    } else {
      setSelectedItems(dataImm.data.map((item: any) => item.file.id))
    }
  }

  // Crear un mapa para los índices de orden
  const orderMap = specificOrder.reduce((map, id, index) => {
    map[id] = index
    return map
  }, {} as Record<string, number>)


  const handleTranslate = async (language: string) => {
    setIsTranslating(true); // Mostrar el toast de traducción en curso

    try {
      for (const allForms of userFormsFinal) {
        for (const form of allForms.forms) {
          if (form.formStatus) {
            const datafill = { ...form.formData };

            const translationPromises = datafill.questions.map(async (question: any) => {
              if (question.translate === true && question.answer !== '') {
                const translatedText = await translateText(question.answer, language);

                if (language === 'English') {
                  question.englishAnswer = translatedText;
                } else if (language === 'French') {
                  question.frenchAnswer = translatedText;
                }
              }
            });

            await Promise.all(translationPromises);

            const dataFormData = {
              user: user?.id,
              form: form.formDataId,
              datafill: datafill,
              status: form.formStatus,
              product: allForms.prodIds,
              memberType: form.memberType,
              memberName: form.memberName,
            };

            await updateFormWithData(form.formDataId, dataFormData);
            console.log('Formulario guardado');
          }
        }
      }


      setTimeout(() => {
        setIsTranslating(false);
      }, 55000);

      setShowCompletionToast(true);

    } catch (error) {
      console.error('Error durante la traducción:', error);
      setIsTranslating(false);
    }
  };







  useEffect(() => {
    if (userAllForms && userAllForms.formsStatusPending && userAllForms.formsStatusPending.length > 0) {
      const filteredForms = userAllForms.formsStatusPending.map((member: any) => ({
        ...member,
        forms: member.forms ? member.forms.filter((form: any) => !form.deletedAt) : [] // Agregar verificación para member.forms
      }))
      setAllForms(filteredForms)
      userAllForms.formsStatusPending.forEach((pendingForm: any) => { // Cambiar map por forEach para evitar crear un array de resultados no utilizado
        if (pendingForm.memberType === 'Solicitante principal') {
          setPrincipalService(pendingForm.name)
        }
      })
      const nombres = userAllForms.formsStatusPending.map((getName: any) => getName.memberName)
      setAplicants(nombres)
    }
  }, [userAllForms])

  useEffect(() => {
  }, [getIndex])

  useEffect(() => {
  }, [completedFormId])

  useEffect(() => {
    if (irDatabase === false &&
      isRefetching === false &&
      !isFetching &&
      !isLoading &&
      !ifCompleted &&
      !ilCompleted) {
      if (userAllForms.formsStatusPending &&
        userAllForms.formsStatusPending.length > 0) {
        const filteredForms = userAllForms.formsStatusPending.map((member: any) => ({
          ...member,
          forms: member.forms.filter((form: any) => !form.deletedAt)
        }))

        setAllForms(filteredForms)
        userAllForms?.formsStatusPending.map((pendingForm: any) => {
          if (pendingForm.memberType === 'Solicitante principal') {
            setPrincipalService(pendingForm.name)
          }
        })
        if (userAllForms.formsStatusPending &&
          userAllForms.formsStatusPending.length > 0) {
          const nombres = userAllForms.formsStatusPending.map((getName: any) =>
            getName.memberName)
          setAplicants(nombres)
        }
      }
      twotwo()
    }
  }, [isRefetching, irDatabase])

  useEffect(() => {
    if (active3 === true) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 500)
      setTimeout(() => {
        setActive7(false)
        setActive(false)
        setActive2(false)
        setActive5(false)
        setActive4(false)
        setActive6(false)
        setActive3(false)
        setupdate(update + 1)
      }, 1500)
    }
  }, [
    active3,
    completedLenght,
    openForm,
    porcentageBase,
    update,
    finalProductsIdMember.productId,
  ])

  useEffect(() => {
    setActive888(true)
    updateForms2(userFormsCompleted, userFormsDrafts)
  }, [userFormsDrafts, userFormsCompleted, userForms])

  useEffect(() => {
    if (openForm === false) {
      refetch()
    }
  }, [openForm, refetch])

  useEffect(() => {
    setActive3(true)
    if (active3 === true) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }, [])

  useEffect(() => {
    if (uploadThing === true) {
      refetch()
    }
  }, [uploadThing])

  useEffect(() => {
    setCompletedLenght(
      dataFormsCompleted !== null &&
        dataFormsCompleted !== undefined
        ? dataFormsCompleted.length
        : 0
    )
  }, [dataFormsCompleted])

  return (
    <div className='onboard-guide-container'>
{isTranslating && (
  <CustomToastContainer
    toasts={[{ message: 'Se están procesando las traducciones, por favor espere y no cambie de pantalla.' }]}
    removeToast={() => setIsTranslating(false)}
    toastStyle="purple-toast"
  />
)}

{showCompletionToast && (
  <CustomToastContainer
    toasts={[{ message: 'Se han procesado todas las traducciones.' }]}
    removeToast={() => setShowCompletionToast(false)}
    toastStyle="green-toast"
    closable={true}
  />
)}

      {openForm === false ? (
        <div className='w-full mr-8'>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}> <p style={{ fontSize: '2rem', fontWeight: 'bold' }}>Formularios</p>
            <button
              className='button-ui button-primary '

              onClick={() => { setOpenManual(true) }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <p > Añadir Formulario</p>
                <img src={addfilesIcon} alt='Cart' /></div>
            </button></div>
          <div>
            <div>
              <div className='flex flex-column justify-start gap-4 flex-wrap mb-2 aplicant-continer-phase2'
                style={{ width: '100%' }}>
                <div style={{ display: 'flex', gap: '30px' }}>
                  <strong style={{ margin: '0', width: '140px' }}>Generar nuevo IMM</strong>
                  <div style={{
                    backgroundColor: '#CBF1FF',
                    flex: '2',
                    marginTop: '-10px',
                    marginRight: '-20px',
                    borderTopRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    display: 'flex',
                    height: '3rem'
                  }}>
                    <button style={{ marginLeft: '20px' }} onClick={() => {
                      setSlideOverVisible(true)
                      docRefetch()
                    }}>
                      Ver IMMs existentes en el contrato
                    </button> </div></div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                  <div>
                    <p style={{ margin: '0' }}>Nombre solicitante</p>
                    <select
                      style={{ width: '18rem' }}
                      className='option-styles'
                      onChange={(e) => {
                        setSelectedName(e.target.value)
                        userFormsFinal.map((prod: any) => {
                          if (prod.memberType === 'Solicitante principal') {
                            setProdPrincipal(prod)
                          }
                        })
                      }}>
                      <option
                        style={{ width: 'auto' }}
                        value="">Seleccione</option>
                      {userFormsFinal.map((prod: any) => (
                        <option
                          style={{ width: 'auto' }}
                          value={prod.memberName}>{prod.memberName} ({prod.memberType})</option>))}
                    </select>
                  </div>
                  <div className='flex items-center gap-2'>
                    <div className='flex flex-row gap-8'>
                      {userFormsFinal.map((prod: any, index: number) =>
                        <React.Fragment key={index}>
                          {(prod.memberType === 'Solicitante principal') &&
                            prod.memberName === selectedName &&
                            <div className='flex flex-row gap-8'>
                              <div>
                                <p style={{ margin: '0' }}>Idioma</p>
                                <select
                                  style={{ width: '9rem' }}
                                  className='option-styles'
                                  onChange={(e) => { setLang(e.target.value) }}>
                                  <option value="">Seleccione</option>
                                  <option value="original">Original</option>
                                  <option value="ingles">Inglés</option>
                                  <option value="frances">Francés</option>
                                </select>
                              </div>
                            </div>}
                          {(prod.memberType === 'Conyuge o Pareja de hecho') &&
                            prod.memberName === selectedName &&
                            <div className='flex flex-row gap-8'>
                              <div>
                                <p style={{ margin: '0' }}>Idioma</p>
                                <select
                                  style={{ width: '9rem' }}
                                  className='option-styles'
                                  onChange={(e) => { setLang(e.target.value) }}>
                                  <option value="">Seleccione</option>
                                  <option value="original">Original</option>
                                  <option value="ingles">Inglés</option>
                                  <option value="frances">Francés</option>
                                </select>
                              </div>
                            </div>}
                          {(prod.memberType === 'Hija(o) dependiente') &&
                            prod.memberName === selectedName &&
                            <div className='flex flex-row gap-8'>
                              <div>
                                <p style={{ margin: '0' }}>Idioma</p>
                                <select
                                  style={{ width: '9rem' }}
                                  className='option-styles'
                                  onChange={(e) => { setLang(e.target.value) }}>
                                  <option value="">Seleccione</option>
                                  <option value="original">Original</option>
                                  <option value="ingles">Inglés</option>
                                  <option value="frances">Francés</option>
                                </select>
                              </div>
                            </div>}
                          {lang !== '' && <>
                            {prod.memberType === 'Solicitante principal' &&
                              prod.memberName === selectedName &&
                              <div>
                                <p style={{ margin: '0' }}>Asignar IMM</p>
                                <select
                                  style={{ width: '18rem' }}
                                  className='option-styles'
                                  onChange={handleSelectChange}>
                                  <option value="">Seleccione</option>
                                  <option value="IMM5257E">IMM5257E-PRINCIPAL</option>
                                  <option value="IMM1294E">IMM1294E-PRINCIPAL</option>
                                  <option value="IMM5708E">IMM5708E-PRINCIPAL</option>
                                  <option value="IMM5645E">IMM5645E-PRINCIPAL</option>
                                  <option value="IMM5257B">IMM5257B-PRINCIPAL</option>
                                </select>
                              </div>}

                            {prod.memberType === 'Conyuge o Pareja de hecho' &&
                              prod.memberName === selectedName &&
                              <div>
                                <p style={{ margin: '0' }}>Asignar IMM</p>
                                <select
                                  style={{ width: '18rem' }}
                                  className='option-styles'
                                  onChange={handleSelectChange}>
                                  <option value="">Seleccione</option>
                                  <option value="IMM5257E-CONYUGE">IMM5257E-CONYUGE</option>
                                  <option value="IMM1294E-CONYUGE">IMM1294E-CONYUGE</option>
                                  <option value="IMM5708E-CONYUGE">IMM5708E-CONYUGE</option>
                                  <option value="IMM5645E-CONYUGE">IMM5645E-CONYUGE</option>
                                  <option value="IMM5257B-CONYUGE">IMM5257B-CONYUGE</option>
                                </select>
                              </div>}

                            {prod.memberType === 'Hija(o) dependiente' &&
                              prod.memberName === selectedName &&
                              <div>
                                <p style={{ margin: '0' }}>Asignar IMM</p>
                                <select
                                  style={{ width: '18rem' }}
                                  className='option-styles'
                                  onChange={handleSelectChange}>
                                  <option value="">Seleccione</option>
                                  <option value="IMM5257E-HIJO">IMM5257E-HIJO</option>
                                  <option value="IMM1294E-HIJO">IMM1294E-HIJO</option>
                                  <option value="IMM5708E-HIJO">IMM5708E-HIJO</option>
                                  <option value="IMM5645E-HIJO">IMM5645E-HIJO</option>
                                  <option value="IMM5257B-HIJO">IMM5257B-HIJO</option>
                                </select>
                              </div>}
                          </>}
                        </React.Fragment>)}
                    </div>
                    {userFormsFinal.map((prod: any) => (
                      <>
                        {/* debe filtrar por el contrato para que aparezca uno */}
                        {prod.memberType === 'Solicitante principal' &&
                          prod.memberName === selectedName &&
                          <div>
                            {selectedOption === 'IMM5257E' && <IMM5257E lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                            {selectedOption === 'IMM1294E' && <IMM1294E lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                            {selectedOption === 'IMM5708E' && <IMM5708E lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                            {selectedOption === 'IMM5645E' && <IMM5645E lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                            {selectedOption === 'IMM5257B' && <IMM5257B lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                          </div>}
                        {prod.memberType === 'Conyuge o Pareja de hecho' &&
                          prod.memberName === selectedName &&
                          <div>
                            {selectedOption === 'IMM5257E-CONYUGE' && <IMM5257ECONYUGE lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                            {selectedOption === 'IMM1294E-CONYUGE' && <IMM1294E lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                            {selectedOption === 'IMM5708E-CONYUGE' && <IMM5708ECONYUGE lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                            {selectedOption === 'IMM5645E-CONYUGE' && <IMM5645ECONYUGE lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                            {selectedOption === 'IMM5257B-CONYUGE' && <IMM5257B_CONYUGUE lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                          </div>}
                        {prod.memberType === 'Hija(o) dependiente' &&
                          prod.memberName === selectedName &&
                          <div>
                            {selectedOption === 'IMM5257E-HIJO' && <IMM5257EDEPENDANT lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                            {selectedOption === 'IMM1294E-HIJO' && <IMM1294E lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} />}
                            {selectedOption === 'IMM5708E-HIJO' && <IMM5708EDEPENDANT lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                            {selectedOption === 'IMM5645E-HIJO' && <IMM5645EDEPENDANT lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                            {selectedOption === 'IMM5257B-HIJO' && <IMM5257B_DEPENDANT lang={lang} product={prod} contractsData={contractsData} contractsFound={contractsFound} principalProduct={prodPrincipal} />}
                          </div>}
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <SlideOver visible={isSlideOverVisible} onClose={() => setSlideOverVisible(false)}>
                <div>
                  <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', marginTop: '20px' }}>
                    <img src={cloudDownload} alt='cloudDownload' />
                    <a style={{ fontSize: '24px', fontWeight: '600' }}>Buscar IMM generados</a>
                  </div>
                  <a style={{ fontSize: '16px', fontWeight: '700' }}>IMM generados del contrato</a>
                  <div style={{ border: '1px solid black', padding: '10px', borderRadius: '20px', marginTop: '20px' }}>
                    <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', alignItems: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label>Filtrar por IMM</label>
                        <select
                          value={filterIMM}
                          onChange={(e) => setFilterIMM(e.target.value)}
                          style={{
                            width: '168px',
                            height: '46px',
                            borderRadius: '6px',
                            border: '1px solid #DFE4EA',
                            padding: '12px 16px',
                            backgroundColor: '#FFFFFF'
                          }}
                        >
                          <option value="ver todos">Ver todos</option>
                          {dataImm && dataImm.data && getUniqueValues(dataImm.data, 'imm').map((imm, index) => (
                            <option key={index} value={imm}>{imm}</option>
                          ))}
                        </select>

                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label>Filtrar por nombre</label>
                        <select
                          value={filterName}
                          onChange={(e) => setFilterName(e.target.value)}
                          style={{
                            width: '168px',
                            height: '46px',
                            borderRadius: '6px',
                            border: '1px solid #DFE4EA',
                            padding: '12px 16px',
                            backgroundColor: '#FFFFFF'
                          }}
                        >
                          <option value="ver todos">Ver todos</option>
                          {dataImm?.data && getUniqueValues(dataImm.data, 'memberName').map((memberName, index) => (
                            <option key={index} value={memberName}>{memberName}</option>
                          ))}
                        </select>

                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label>Filtrar por fecha</label>
                        <select
                          value={filterDate}
                          onChange={(e) => setFilterDate(e.target.value)}
                          style={{
                            width: '168px',
                            height: '46px',
                            borderRadius: '6px',
                            border: '1px solid #DFE4EA',
                            padding: '12px 16px',
                            backgroundColor: '#FFFFFF'
                          }}
                        >
                          <option value="ver todos">Ver todos</option>
                          {getUniqueValues(dataImm.data, 'createdAt').map((createdAt, index) => (
                            <option key={index} value={createdAt.split('T')[0]}>{createdAt.split('T')[0]}</option>
                          ))}
                        </select>
                      </div>
                      <button
                        onClick={() => setFilteredData(dataImm.data)}
                        className='button-ui button-tertiary'
                      // style={{
                      //   borderRadius: '20px',
                      //   border: '2px solid #01A9DB',
                      //   cursor: 'pointer',

                      // }}
                      >
                        Aplicar filtro
                      </button>
                    </div>

                    <button
                      style={{
                        gap: '20px',
                        backgroundColor: selectedItems.length === 0 ? 'gray' : 'black',
                        cursor: selectedItems.length === 0 ? 'not-allowed' : 'pointer'
                      }}
                      className='button-form-volver'
                      onClick={selectedItems.length === 0 ? undefined : handleDownloadAll}
                      disabled={selectedItems.length === 0}
                    >
                      <img src={downloadwhite} alt='downloadwhite' style={{ width: '20px', height: '20px' }} />
                      <a style={{ color: 'white' }}>Descargar</a>
                    </button>

                    <div style={{ border: '1px solid black', padding: '10px', borderRadius: '20px', marginTop: '20px' }}>
                      <div>
                        {loadingDownloadAll && <>CARGANDO.....</>}
                        {filteredData && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                              type="checkbox"
                              checked={selectedItems.length === filteredData.length}
                              onChange={handleSelectAll}
                              style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '4px',
                                border: '1px solid #DFE4EA',
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            />
                            <label style={{ marginLeft: '8px' }}>Todos</label>
                          </div>
                        )}
                      </div>
                      <div style={{ backgroundColor: '#DFE4EA', width: '100%', height: '1px', marginBottom: '5px' }}></div>
                      <div>
                        {Array.isArray(filteredData) && filteredData.map((record: {
                          file: { id: any }
                          imm: string
                          memberName: string
                          memberType: string
                          createdAt: string
                        },
                          index: React.Key) => (
                          <React.Fragment key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(record.file.id)}
                                onChange={() => handleCheckboxChange(record)}
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '4px',
                                  border: '1px solid #DFE4EA',
                                  backgroundColor: '#FFFFFF',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '10px'
                                }}
                              />
                              <div style={{ gap: '70px', display: 'flex', flexDirection: 'row' }}>
                                <p>{record.imm}</p>
                                <p style={{ fontWeight: '400', fontSize: '14px' }}>{record.memberName}</p>
                                <p style={{ fontWeight: '400', fontSize: '14px' }}>{record.memberType}</p>
                                <p style={{ fontWeight: '400', fontSize: '14px' }}>
                                  {typeof record.createdAt === 'string'
                                    ? `${record.createdAt.split('T')[0]} ${record.createdAt.split('T')[1].slice(0, 5)}`
                                    : record.createdAt}
                                </p>
                              </div>
                            </div>
                            <div style={{
                              backgroundColor: '#DFE4EA',
                              width: '100%',
                              height: '1px',
                              marginBottom: '5px'
                            }}></div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </SlideOver>
            </div>
          </div>

          {/* Nueva vista */}
          < div className='main-data-continer'
            style={{ backgroundColor: 'white', borderRadius: '10px' }}>
            <div className='text-container'>
              {loading === true && (
                <div className='loading-style'>
                  <div style={{ width: '80vw', height: '100vh', position: 'fixed' }}>
                    <img src='/media/logos/logovid.gif'
                      style={{ width: '100%', height: '100%' }}
                      alt='logo' />
                  </div>
                </div>)}
              <div className='onboard-guide-container'>
                {openForm === false ? (
                  <div className='step-two-container-main'>
                    <div className='flex flex-row gap-4 p-4 mb-4 mobile-styles'>
                      <button
                        className={`btn-section ${activeTab === "all" && "active"}`}
                        onClick={() => {
                          filterButton("all")
                          setActiveTab('all')
                        }}>
                        <p style={{ margin: '0', fontSize: '12px' }}>Ver todos</p>
                      </button>
                      <button onClick={() => {
                        filterButton("pending")
                        setActiveTab('pending')
                      }} className={`btn-section ${activeTab === "pending" && "active"}`}>
                        <p style={{ margin: '0', fontSize: '12px' }}>Pendientes</p>
                        <p style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          borderRadius: '50%',
                          backgroundColor: '#F59E0B',
                          color: 'white',
                          margin: '0',
                          textAlign: 'center',
                          fontSize: '14px'
                        }}>{pendantForms < 0 ? 0 : pendantForms}  </p>
                      </button>
                      <button onClick={() => {
                        filterButton("completed")
                        setActiveTab('completed')
                      }}
                        className={`btn-section ${activeTab === "completed" && "active"}`}>
                        <p style={{ margin: '0', fontSize: '12px' }}>Completados</p>
                        <p style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          borderRadius: '50%',
                          backgroundColor: '#DAF8E6',
                          color: '#1A8245',
                          margin: '0',
                          textAlign: 'center',
                          fontSize: '14px'
                        }}>{completedForms < 0 ? 0 : completedForms}</p>
                      </button>
                      <button onClick={() => {
                        filterButton("draft")
                        setActiveTab('draft')
                      }} className={`btn-section ${activeTab === "draft" && "active"}`}>
                        <p style={{ margin: '0', fontSize: '12px' }}>Borradores</p>
                        <p style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          borderRadius: '50%',
                          backgroundColor: '#637381',
                          color: 'white',
                          margin: '0',
                          textAlign: 'center',
                          fontSize: '14px'
                        }}>{draftForms < 0 ? 0 : draftForms}</p>
                      </button>
                      <button onClick={() => handleTranslate('English')} className="p-2 bg-blue-500 text-white rounded">Translate to English</button>
                      <button onClick={() => handleTranslate('French')} className="p-2 bg-blue-500 text-white rounded">Translate to French</button>
                    </div>
                    <>
                      {update > 0 && showPendingForms === true &&
                        userFormsFinal && userFormsFinal.map((userForm: any, index: number) => (
                          <>
                            {userForm.forms &&
                              userForm.forms.length > 0 &&
                              userForm.forms.filter((elemento: any) => elemento.formStatus !== 0 &&
                                elemento.formStatus !== 1).length > 0 && (
                                <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates[index] ? 'open' : ''}`}>
                                  <div
                                    className='flex justify-between items-center w-full'
                                    onClick={() => handleToggleContent(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                                    <div>
                                      <div className='step-two-header-container'>
                                        <p style={{ fontSize: '14px', margin: '0' }}>
                                          <b>{userForm.memberName}</b> ({userForm.memberType})
                                        </p>
                                        <div className='step-badge-active2'
                                          style={{
                                            paddingLeft: '3px',
                                            paddingRight: '3px',
                                            width: '6rem',
                                            height: '1.5rem'
                                          }}>
                                          <p className='step-badge-active-p-ones'
                                            style={{ fontSize: '10px' }}>
                                            {(userForm?.forms.filter((form: any) =>
                                              form.id &&
                                              !form.deleted_at).length)}
                                            Pendiente(s)</p>
                                        </div>
                                      </div>
                                      <p className='hide-tex'
                                        style={{ fontSize: '12px' }}><b>{userForm.name} </b></p>
                                    </div>
                                    <button
                                      className={
                                        buttonStates[index] ? 'show-btn-presed' : 'show-btn-presed2'
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleToggleContent(index)
                                      }}>
                                      <img
                                        className='show-btn'
                                        src={ChevronRightIcon}
                                        alt='ChevronRight' />
                                    </button>
                                  </div>
                                  {buttonStates[index] && (
                                    <ul className='mt-4 w-full'>
                                      {userForm.forms.length > 0 &&
                                        userForm?.forms.map((form: any, index: any) => (
                                          (form.formStatus !== 0 &&
                                            form.formStatus !== 1 &&
                                            !form.deletedAt) &&
                                          <li
                                            className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                            key={'a' + index}>
                                            <div className='step-two-header-form' onClick={() => {
                                              GetIndex(form.id)
                                              setOpenForm(true)
                                              setOpenFormCompleted(false)
                                              setProductId(userForm.prodIds)
                                              setMemberNameSelected(userForm.memberName)
                                              setMemberTypeSelected(userForm.memberType)
                                            }} style={{ cursor: 'pointer' }}>
                                              <div className='flex items-center gap-2' >
                                                <img
                                                  width='24'
                                                  height='24'
                                                  src={File}
                                                  alt='File' />
                                                <p className='formtitle'
                                                  style={{
                                                    fontSize: '1.2rem',
                                                    margin: '0'
                                                  }}>
                                                  {form.name}
                                                </p>
                                              </div>
                                              <div className='step-badge-active2'>
                                                <p className='step-badge-active-p-ones'>Pendiente</p>
                                              </div>
                                            </div>
                                            <div className='flex gap-4 '>
                                              <button
                                                className='icon-form-list'
                                                onClick={() => {
                                                  GetIndex(form.id)
                                                  setOpenForm(true)
                                                  setOpenFormCompleted(false)
                                                  setProductId(userForm.prodIds)
                                                  setMemberNameSelected(userForm.memberName)
                                                  setMemberTypeSelected(userForm.memberType)
                                                }}>
                                                <img
                                                  width='25'
                                                  height='25'
                                                  src={ChevronRightIcon}
                                                  alt='ChevronRight' />
                                              </button>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                </div>
                              )}
                          </>
                        ))}
                    </>
                    {update > 0 &&
                      showCompletedForm === true &&
                      userFormsCompleted.length > 0 &&
                      userFormsCompleted &&
                      userFormsCompleted?.map((formCompleted: any, index: number) => (
                        <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates3[index] ? 'open' : ''}`}>
                          <div
                            className='flex justify-between items-center w-full'
                            onClick={() => handleToggleContent3(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                            <div>
                              <div style={{ width: '100%' }} className='flex gap-4 items-center'>
                                <p style={{ fontSize: '14px', margin: '0' }}>
                                  <b>{formCompleted.memberName}</b>  ({formCompleted.memberType})
                                </p>
                              </div>
                              <p className='hide-tex' style={{ fontSize: '12px' }}><b>{formCompleted.name}</b></p>
                            </div>
                            <button
                              className={buttonStates3[index] ? 'show-btn-presed' : 'show-btn-presed2'}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleToggleContent3(index)
                              }}>
                              <img
                                className='show-btn'
                                src={ChevronRightIcon}
                                alt='ChevronRight' />
                            </button>
                          </div>
                          {buttonStates3[index] && (
                            <ul className='mt-4 w-full'>
                              {formCompleted &&
                                formCompleted?.forms.map((form: any, index: number) => (
                                  <>
                                    {form.formStatus === 1 &&
                                      !form.deletedAt && (
                                        <li
                                          className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                          key={'a' + index}>
                                          <div className='step-two-header-form' onClick={() => {
                                            GetCompletedIndex(form.formDataId)
                                            setOpenForm(true)
                                            setOpenFormCompleted(true)
                                            setCompletedFormId(form.formDataId)
                                            setProductId(form.idProduct)
                                            setMemberNameSelected(form.memberName)
                                            setMemberTypeSelected(form.memberType)
                                          }} style={{ cursor: 'pointer' }}>
                                            <div className='flex items-center gap-2'>
                                              <img
                                                width='24'
                                                height='24'
                                                src={File}
                                                alt='File' />
                                              <p className='formtitle' style={{ fontSize: '1.2rem', margin: '0' }}>
                                                {form.formData.formName}
                                              </p>
                                            </div>
                                            <div className='step-badge-active-success2'
                                              style={{ width: '6rem', height: '1.5rem' }}>
                                              <p className='step-badge-active-success-p-ones'
                                                style={{ fontSize: '12px', margin: '0' }}>Completado</p>
                                            </div>
                                          </div>
                                          <div className='flex gap-4'>
                                            <button
                                              className='icon-form-list'
                                              type='button'
                                              onClick={() => {
                                                GetCompletedIndex(form.formDataId)
                                                setOpenForm(true)
                                                setOpenFormCompleted(true)
                                                setCompletedFormId(form.formDataId)
                                                setProductId(form.idProduct)
                                                setMemberNameSelected(form.memberName)
                                                setMemberTypeSelected(form.memberType)
                                              }}>
                                              <img
                                                width='25'
                                                height='25'
                                                src={ChevronRightIcon}
                                                alt='ChevronRight' />
                                            </button>
                                          </div>
                                        </li>
                                      )}
                                  </>
                                ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    {update > 0 &&
                      showAllForms === true &&
                      userAllForms &&
                      userAllForms.formsStatusPending &&
                      userAllForms.formsStatusPending.length > 0 &&
                      userAllForms?.formsStatusPending.sort((a: any, b: any) => {
                        // Ordenar por memberType, priorizando 'Solicitante principal'
                        if (a.memberType === 'Solicitante principal' && b.memberType !== 'Solicitante principal') {
                          return -1
                        } else if (a.memberType !== 'Solicitante principal' && b.memberType === 'Solicitante principal') {
                          return 1
                        } else if (a.memberType === 'Conyuge o Pareja de hecho' && b.memberType !== 'Conyuge o Pareja de hecho') {
                          return -1
                        } else if (a.memberType !== 'Conyuge o Pareja de hecho' && b.memberType === 'Conyuge o Pareja de hecho') {
                          return 1
                        } else {
                          return 0
                        }
                      }).map((pendingForm: any, index: number) => (
                        <>
                          {pendingForm.forms &&
                            pendingForm.forms.length > 0 && (
                              <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates[index] ? 'open' : ''}`}>
                                <div
                                  className='flex justify-between items-center w-full'
                                  onClick={() => handleToggleContent(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}>
                                  <div>
                                    <div className='step-two-header-container'>
                                      <p style={{
                                        fontSize: '14px',
                                        margin: '0'
                                      }}>
                                        <b>{pendingForm.memberName}</b>({pendingForm.memberType})</p>

                                      {pendingForm?.forms.filter((form: any) => (form.id && !form.deleted_at)).length > 0 &&
                                        <div className='step-badge-active2'
                                          style={{
                                            paddingLeft: '3px',
                                            paddingRight: '3px',
                                            width: '6rem',
                                            height: '1.5rem'
                                          }}>
                                          <p className='step-badge-active-p-ones'
                                            style={{ fontSize: '12px' }}>
                                            {(pendingForm?.forms.filter((form: any) => (form.id && !form.deleted_at)).length)} Pendiente(s)</p>
                                        </div>}

                                      {/* principal */}
                                      {draftFormsPrincipal > 0 && pendingForm.memberType === 'Solicitante principal' && <div
                                        className='step-badge-active-draft2'
                                        style={{
                                          paddingLeft: '3px',
                                          paddingRight: '3px',
                                          width: '6rem',
                                          height: '1.5rem'
                                        }}>
                                        <p style={{ margin: '0', fontSize: '12px' }}>Borradores</p>
                                        <p style={{
                                          margin: '0',
                                          textAlign: 'center',
                                          fontSize: '12px'
                                        }}>{draftFormsPrincipal}</p>
                                      </div>}
                                      {/* conyuge */}
                                      {draftFormsConyuge > 0 && pendingForm.memberType === 'Conyuge o Pareja de hecho' && <div
                                        className='step-badge-active-draft2'
                                        style={{
                                          paddingLeft: '3px',
                                          paddingRight: '3px',
                                          width: '6rem',
                                          height: '1.5rem'
                                        }}>
                                        <p style={{ margin: '0', fontSize: '12px' }}>Borradores</p>
                                        <p style={{
                                          margin: '0',
                                          textAlign: 'center',
                                          fontSize: '12px'
                                        }}>{draftFormsConyuge}</p>
                                      </div>}
                                      {/* draftFormsChildren */}
                                      {pendingForm.memberType === 'Hija(o) dependiente' &&
                                        <>
                                          {draftFormsChildren.map((child: any) =>
                                            <>
                                              {child.memberName === pendingForm.memberName ?
                                                <div
                                                  className='step-badge-active-draft2'
                                                  style={{
                                                    paddingLeft: '3px',
                                                    paddingRight: '3px',
                                                    width: '6rem',
                                                    height: '1.5rem'
                                                  }}>
                                                  <p style={{ margin: '0', fontSize: '12px' }}>Borradores</p>
                                                  <p style={{
                                                    margin: '0',
                                                    textAlign: 'center',
                                                    fontSize: '12px'
                                                  }}>{child.draft}</p>
                                                </div>
                                                : null}
                                            </>
                                          )}
                                        </>}
                                    </div>
                                    <p className='hide-tex'
                                      style={{ fontSize: '12px' }}><b>{pendingForm.name}</b></p>
                                  </div>
                                  <button
                                    className={
                                      buttonStates[index] ? 'show-btn-presed' : 'show-btn-presed2'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleToggleContent(index)
                                    }}>
                                    <img
                                      className='show-btn'
                                      src={ChevronRightIcon}
                                      alt='ChevronRight' />
                                  </button>
                                </div>
                                {buttonStates[index] && (
                                  <ul className='mt-4 w-full'>
                                    {pendingForm?.forms
                                      .slice() // Crear una copia de los formularios para no mutar el estado original
                                      .sort((a: any, b: any) => {
                                        const guideA = a?.formData?.guide
                                        const guideB = b?.formData?.guide
                                        if (guideA == null || guideB == null) {
                                          // Si alguna guía es nula o indefinida, los mantenemos en el orden original
                                          return 0
                                        }
                                        return (orderMap[guideA] || Infinity) - (orderMap[guideB] || Infinity)
                                      }) // Ordenar según specificOrder
                                      .map((form: any, index: any) => {
                                        if (form.formStatus !== 1 &&
                                          form.formStatus !== 0 &&
                                          !form.deletedAt) {
                                          return (
                                            <li
                                              className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                              key={'a' + index}>
                                              <div className='step-two-header-form' onClick={() => {
                                                GetIndex(form.id)
                                                setOpenForm(true)
                                                setOpenFormCompleted(false)
                                                setProductId(pendingForm.prodIds)
                                                setMemberNameSelected(pendingForm.memberName)
                                                setMemberTypeSelected(pendingForm.memberType)
                                              }} style={{ cursor: 'pointer' }}>
                                                <div className='flex items-center gap-2' >
                                                  <img
                                                    width='24'
                                                    height='24'
                                                    src={File}
                                                    alt='File' />
                                                  <p className='formtitle'
                                                    style={{
                                                      fontSize: '1.2rem',
                                                      margin: '0'
                                                    }}>
                                                    {form.name}
                                                  </p>
                                                </div>
                                                <div className='step-badge-active2'>
                                                  <p className='step-badge-active-p-ones'>Pendiente</p>
                                                </div>
                                              </div>
                                              <div className='flex gap-4 '>
                                                <button
                                                  className='icon-form-list'
                                                  type='button'
                                                  onClick={() => {
                                                    GetIndex(form.id)
                                                    setOpenForm(true)
                                                    setOpenFormCompleted(false)
                                                    setProductId(pendingForm.prodIds)
                                                    setMemberNameSelected(pendingForm.memberName)
                                                    setMemberTypeSelected(pendingForm.memberType)
                                                  }}>
                                                  <img
                                                    width='25'
                                                    height='25'
                                                    src={ChevronRightIcon}
                                                    alt='ChevronRight' />
                                                </button>
                                              </div>
                                            </li>
                                          )
                                        }
                                        if (form.formStatus === 1 &&
                                          !form.deletedAt) {
                                          return (
                                            <>
                                              <li
                                                className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                                key={'a' + index}>
                                                <div className='step-two-header-form' onClick={() => {
                                                  GetCompletedIndex(form.formDataId)
                                                  setOpenForm(true)
                                                  setOpenFormCompleted(true)
                                                  setCompletedFormId(form.formDataId)
                                                  setOpenFormCompleted(true)
                                                  setMemberNameSelected(form.memberName)
                                                  setMemberTypeSelected(form.memberType)
                                                  setProductId(form.idProduct)
                                                }} style={{ cursor: 'pointer' }}>
                                                  <div className='flex items-center gap-2'>
                                                    <img style={{ marginBottom: '16px' }}
                                                      width='24'
                                                      height='24'

                                                      src={File}
                                                      alt='File' />
                                                    <div>
                                                      <p className='product-name'
                                                        style={{ fontSize: '1.2rem', margin: 0 }}>
                                                        {form.formData.formName}
                                                      </p>
                                                      {findAuthor(form.formDataId) && <p style={{ margin: 0, color: 'gray' }}>
                                                        Asignado el {findCreatedAt(form.formDataId)}  - <b>{findAuthor(form.formDataId)}</b>
                                                      </p>}
                                                    </div>
                                                  </div>
                                                  <div className='step-badge-active-success2'>
                                                    <p className='step-badge-active-success-p-ones'>Completado</p>
                                                  </div>
                                                </div>
                                                <div className='flex gap-4'>
                                                  <button
                                                    className='icon-form-list'
                                                    type='button'
                                                    onClick={() => {
                                                      GetCompletedIndex(form.formDataId)
                                                      setOpenForm(true)
                                                      setOpenFormCompleted(true)
                                                      setCompletedFormId(form.formDataId)
                                                      setOpenFormCompleted(true)
                                                      setMemberNameSelected(form.memberName)
                                                      setMemberTypeSelected(form.memberType)
                                                      setProductId(form.idProduct)
                                                    }}>
                                                    <img
                                                      width='25'
                                                      height='25'
                                                      src={ChevronRightIcon}
                                                      alt='ChevronRight' />
                                                  </button>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        }
                                        if (form.formStatus === 0 &&
                                          !form.deletedAt) {
                                          return (
                                            <>
                                              <li
                                                className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                                key={'a' + index}>
                                                <div className='step-two-header-form' onClick={() => {
                                                  GetCompletedIndex(form.formDataId)
                                                  setOpenForm(true)
                                                  setOpenFormCompleted(true)
                                                  setCompletedFormId(form.formDataId)
                                                  setProductId(form.idProduct)
                                                  setMemberNameSelected(pendingForm.memberName)
                                                  setMemberTypeSelected(pendingForm.memberType)
                                                }} style={{ cursor: 'pointer' }}>
                                                  <div className='flex items-center gap-2'>
                                                    <img
                                                      width='24'
                                                      height='24'
                                                      src={File}
                                                      alt='File'
                                                    />
                                                    <div>
                                                      <p className='product-name'
                                                        style={{ fontSize: '1.2rem', margin: 0 }}>
                                                        {form.formData.formName}
                                                      </p>
                                                      {findAuthor(form.formDataId) && <p style={{ color: "gray", margin: 0 }}>
                                                        Asignado el {findCreatedAt(form.formDataId)} - <b>{findAuthor(form.formDataId)}</b>
                                                      </p>}

                                                    </div>
                                                  </div>
                                                  <div className='step-badge-active-draft2'
                                                    style={{ width: '3rem' }}>
                                                    <p className='step-badge-active-draft-p-ones'>Borrador</p>
                                                  </div>
                                                </div>
                                                <div className='flex gap-4'>
                                                  <button
                                                    className='icon-form-list'
                                                    type='button'
                                                    onClick={() => {
                                                      GetCompletedIndex(form.formDataId)
                                                      setOpenForm(true)
                                                      setOpenFormCompleted(true)
                                                      setCompletedFormId(form.formDataId)
                                                      setProductId(form.idProduct)
                                                      setMemberNameSelected(pendingForm.memberName)
                                                      setMemberTypeSelected(pendingForm.memberType)
                                                    }}>
                                                    <img
                                                      width='25'
                                                      height='25'
                                                      src={ChevronRightIcon}
                                                      alt='ChevronRight' />
                                                  </button>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        }
                                        return null
                                      })}
                                  </ul>
                                )}
                              </div>
                            )}
                        </>
                      ))}
                    {update > 0 && showDraftForms === true &&
                      userFormsDrafts.length > 0 &&
                      userFormsDrafts && userFormsDrafts?.map((draft: any, index: number) => (
                        <>
                          {userFormsDrafts?.some(draft => {
                            if (draft.forms) {
                              // Si hay forms en el draft, verifica si alguno tiene deletedAt sin definir
                              return draft.forms.some((form: any) => form && !form.deletedAt)
                            }
                            // Si no hay forms en el draft, devuelve false
                            return false
                          }) &&
                            <>
                              {/* Lista de borradores */}
                              <div className={`items-center justify-between mb-4 step2-aplicant-card ${buttonStates2[index] ? 'open' : ''}`}>
                                <div
                                  className='flex justify-between items-center w-full' onClick={() => handleToggleContent2(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }}
                                >
                                  <div>
                                    <div style={{ width: '100%' }} className='flex gap-4 items-center'>
                                      <p style={{ fontSize: '18px', margin: '0' }}>
                                        <b>{draft.memberName}</b>  ({draft.memberType})
                                      </p>
                                    </div>
                                    <p className='hide-tex' style={{ fontSize: '12px' }}><b>{draft.name}</b></p>
                                  </div>
                                  <button
                                    className={buttonStates2[index] ? 'show-btn-presed' : 'show-btn-presed2'}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleToggleContent2(index)
                                    }}>
                                    <img
                                      className='show-btn'
                                      src={ChevronRightIcon}
                                      alt='ChevronRight' />
                                  </button>
                                </div>
                                {buttonStates2[index] && (
                                  <ul className='mt-4 w-full'>
                                    {draft &&
                                      draft?.forms.map((form: any, index: number) => (
                                        <>
                                          {form.formStatus === 0 &&
                                            !form.deletedAt && (
                                              <li
                                                className='flex justify-between border-b mt-2 pb-2 step-two-header'
                                                key={'a' + index}>
                                                <div className='step-two-header-form' onClick={() => {
                                                  GetCompletedIndex(form.formDataId)
                                                  setOpenForm(true)
                                                  setOpenFormCompleted(true)
                                                  setCompletedFormId(form.formDataId)
                                                  setProductId(form.idProduct)
                                                  setMemberNameSelected(draft.memberName)
                                                  setMemberTypeSelected(draft.memberType)
                                                }} style={{ cursor: 'pointer' }}>
                                                  <div className='flex items-center gap-2' >
                                                    <img
                                                      width='24'
                                                      height='24'
                                                      src={File}
                                                      alt='File' />
                                                    <p className='formtitle' style={{ fontSize: '1.2rem', margin: '0' }}>
                                                      {form.formData.formName}
                                                    </p>
                                                  </div>
                                                  <div className='step-badge-active-draft2' style={{ width: '6rem', height: '1.5rem' }}>
                                                    <p className='step-badge-active-draft-p-ones' style={{ fontSize: '12px', margin: '0' }}>Borrador</p>
                                                  </div>
                                                </div>
                                                <div className='flex gap-4'>
                                                  <button
                                                    className='icon-form-list'
                                                    type='button'
                                                    onClick={() => {
                                                      GetCompletedIndex(form.formDataId)
                                                      setOpenForm(true)
                                                      setOpenFormCompleted(true)
                                                      setCompletedFormId(form.formDataId)
                                                      setProductId(form.idProduct)
                                                      setMemberNameSelected(draft.memberName)
                                                      setMemberTypeSelected(draft.memberType)
                                                    }}>
                                                    <img
                                                      width='25'
                                                      height='25'
                                                      src={ChevronRightIcon}
                                                      alt='ChevronRight' />
                                                  </button>
                                                </div>
                                              </li>)}
                                        </>))}
                                  </ul>)}
                              </div>
                            </>}
                        </>))}
                  </div>
                ) : (
                  <div className='display-form-container'>
                  </div>)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full mr-8 ml-8 '>
          {openFormCompleted === false ? (
            <DisplayFormsForConsultant
              name={''}
              user={user}
              formIndex={getIndex}
              openForm={openForm}
              setOpenForm={setOpenForm}
              setBarUpdate={setbarUpdate}
              barUpdate={barUpdate}
              setActivePorcentageBar={setActive2}
              setActiveRefech={setActive3}
              productId={productId}
              contractId={contractsFound}
              setIndex={setGetIndex}
              setMemberNameSelected={setMemberNameSelected}
              memberNameSelected={memberNameSelected}
              setMemberTypeSelected={setMemberTypeSelected}
              memberTypeSelected={memberTypeSelected}
              refetchForms={refetchForms}
              refetch={refetch}
              refetchFromDataBase={refetchFromDataBase}
              setUploadThing={setUploadThing} />
          ) : (
            <DisplayConsultantFormDarft
              name={''}
              formIndex={getIndex}
              setIndex={setGetIndex}
              setFormIndex={setCompletedFormId}
              openForm={openForm}
              setOpenForm={setOpenForm}
              openFormCompleted={openFormCompleted}
              setOpenFormCompleted={setOpenFormCompleted}
              formCompletedId={completedFormId}
              productId={productId}
              setMemberNameSelected={setMemberNameSelected}
              memberNameSelected={memberNameSelected}
              setMemberTypeSelected={setMemberTypeSelected}
              memberTypeSelected={memberTypeSelected}
              refetchForms={refetchForms}
              refetch={refetch}
              refetchFromDataBase={refetchFromDataBase} />
          )}
        </div>)}
      {openManual === true && <div className='user-profile-popup'>
        <div className='user-profile-popup-continer'>
          <FormManagerClient2
            user={user}
            refetchFromDataBaseTwo={refetchFromDataBase}
            refetchTwo={refetch}
            setOpenManual={setOpenManual}
            contractsFound={contractsFound}
            principalService={principalService}
            allFroms={allFroms}
            aplicants={aplicants} />
        </div>
      </div>}
      {showToast === true ?
        <div style={{ position: 'absolute', zIndex: '99999' }}>
          <div className='toast-style'
            style={{ right: '0' }}>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%', top: '0px' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Tradución complatada
          </div>
        </div> : null}
    </div>)
}
export default CompletedUserFormslist
