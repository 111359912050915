import styled from 'styled-components'

const CardWindow = styled.div`
  width: 100%;
  background-color: #fff;
  min-height: 200px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;

  margin-bottom: 20px;
  border-radius: 4px;
`
export {CardWindow}
