/* eslint-disable react-hooks/exhaustive-deps */
 
import {useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {RootStoreContext} from '../../../../stores/rootStore'
import WebContecnt from './WebContecnt'
import {observer} from 'mobx-react'
import '../styles/style.scss'
const WebLayout: React.FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {getCategories} = rootStore.cartStore

  useEffect(() => {
    getCategories('')
  }, [])

  return (
    <>
      <div>
        <WebContecnt>
          <Outlet />
        </WebContecnt>
      </div>
    </>
  )
}

export default observer(WebLayout)
