/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useContext, useState, Dispatch, SetStateAction, useEffect } from 'react'
import '../contratacionAnswers/style.css'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import { useQuery } from 'react-query'
import { getContractByUserForStepOne } from 'app/modules/apps/contract/_requests'
import { RootStoreContext } from 'stores/rootStore'
import { updateContracts } from 'app/modules/apps/docusign/_requests'
import MyContract from './MyContract'
import Box from '../icons/box-4.svg'
import { Link } from 'react-router-dom'

interface IProps {
  name: string
  data: RootObjectCompletedContracts | undefined
  status: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  contractId: string
  setContractId: Dispatch<SetStateAction<string>>
  start: Dispatch<SetStateAction<boolean>>
  maxStep: number
  setMaxStep: Dispatch<SetStateAction<number>>
  stepBar: number
  setStepBar: Dispatch<SetStateAction<number>>
}
const OnboardingUser: React.FC<IProps> = ({
  name,
  data,
  status,
  step,
  setStep,
  setContractId,
  maxStep,
  setMaxStep,
  setStepBar,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { user, updatingContracts } = rootStore.authStore
  const [active, setActive] = useState(false)
  const [finalProducts, setFinalProducts] = useState<any[]>([])
  const [isHoveredArray, setIsHoveredArray] = useState<boolean[]>([])
  const [orderedData, setOrderedData] = useState<any>(null)
  const [countDict, setCountDict] = useState<any>(null)
  const [showDiv, setShowDiv] = useState(null)
  const [showContract, setShowContract] = useState(false)
  const [showContractInfo, setShowContractInfo] = useState<any>()

  const { data: dataFromDatabase, status: statusFromDatabase } = useQuery(
    'allcontrDb',
    async () => await getContractByUserForStepOne(user?.id!)
  ) /* contratos */

  const GetMainId = (contractId: string) => {
    dataFromDatabase.data.map((cont: any) => {
      if (contractId === cont.contracts_users_envelope_id) {
        setSteps(cont.contracts_users_id, cont)
      } else {
        setStatus(cont.contracts_users_id)
      }
    })
  }

  const countApplicants = (data: any) => {
    const countDict: Record<string, Set<string>> = {}
    data.forEach((contract: any) => {
      if (contract.contracts_users_applicants) {
        const applicants = JSON.parse(contract.contracts_users_applicants)
        const contractId = contract.contracts_users_envelope_id
        if (!countDict[contractId]) {
          countDict[contractId] = new Set<string>()
        }
        applicants.forEach((applicant: any) => {
          const { memberName, memberType } = applicant
          const key = `${memberName}-${memberType}`
          countDict[contractId].add(key)
        })
      }
    })
    const countResult: Record<string, number> = {}
    for (const [contractId, applicantsSet] of Object.entries(countDict)) {
      countResult[contractId] = applicantsSet.size
    }
    return countResult
  }

  const setSteps = async (mainId: string, contract: any) => {
    let dataContractData: object
    setStep(contract.contracts_users_step <= 2 ||
      step === null ? 2 :
      contract.contracts_users_step)
    setMaxStep(contract.contracts_users_step)
    if (mainId != null) {
      dataContractData = {
        step: (contract.contracts_users_step <= 2 ||
          step === null) ? 2 :
          contract.contracts_users_step,
        status: 1,
      }
    } else {
      dataContractData = {
        step: (contract.contracts_users_step <= 2 ||
          step === null) ? 2 :
          contract.contracts_users_step,
        status: 1,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const setStatus = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        status: 0,
      }
    } else {
      dataContractData = {
        status: 0,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  if (statusFromDatabase === 'success') {
    if (active === false) {
      const newFinalProducts: any[] = []
      orderedData && orderedData.map((data: any) => {
        const matter = data.contracts_users_data.matter
        const matterProducts = matter.split('|||')
        const filteredMatterProducts = matterProducts.filter((item: string) => item.trim() !== '')
        const finalProductObj = { products: filteredMatterProducts } // Paso 2
        newFinalProducts.push(finalProductObj) // Almacena el objeto en el array
      })
      setFinalProducts(newFinalProducts)
      setActive(true)
    }
  }

  const toggleDiv = (index: any) => {
    if (showDiv === index) {
      setShowDiv(null)
    } else {
      setShowDiv(index)
    }
  }

  useEffect(() => {
    if (dataFromDatabase && dataFromDatabase.data) {
      // Ordenar dataFromDatabase.data por contracts_users_created_at desde el más reciente al más antiguo
      const sortedData = [...dataFromDatabase.data].sort((a: any, b: any) => {
        // Crear objetos Date para comparar
        const dateA = new Date(a.contracts_users_completedDate)
        const dateB = new Date(b.contracts_users_completedDate)
        // Comparar las fechas: más reciente primero
        return dateB.getTime() - dateA.getTime()
      })
      setOrderedData(sortedData)
      // Usar los datos ordenados para contar los applicants
      setCountDict(countApplicants(sortedData))
    }
  }, [dataFromDatabase, statusFromDatabase])

  useEffect(() => {
    // Inicializar el array de estado isHoveredArray con la misma longitud que dataFromDatabase
    if (statusFromDatabase === 'success' && orderedData) {
      setIsHoveredArray(Array(orderedData.length).fill(false))
    }
  }, [statusFromDatabase])

  useEffect(() => { }, [finalProducts])
  useEffect(() => { }, [])


  return (
    <> {showContract ?
      <MyContract
        setShowContract={setShowContract}
        setShowContractInfo={setShowContractInfo}
        setStep={setStep}
        setContractId={setContractId}
        GetMainId={GetMainId}
        maxStep={maxStep}
        setStepBar={setStepBar}
        setMaxStep={setMaxStep}
        showContractInfo={showContractInfo} />
      :
      <div className='main-data-continer'>
        <div className='text-container'>
          <div className='onboard-guide-container'>
            <div
              className='w-full '
              style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>

              {/* first session */}
              {status === '1' &&
                <div className="first-log">
                  <div className="flex flex-row items-start gap-4">
                    <div className="checkbox-green-continer">
                      <div className="checkbox-green">
                        &#x2713;
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <h1 style={{
                        color: '#004434',
                        fontWeight: 'bold',
                        fontSize: '18px'
                      }}>
                        ¡Felicidades! {name}
                      </h1>
                      <p style={{
                        color: '#637381',
                        fontSize: '12px'
                      }}>
                        Agradecemos su confianza en nuestros servicios migratorios.
                        Para continuar complete los formularios.
                      </p>
                    </div>
                  </div>
                </div>}
              <div style={{ display: 'flex', gap: '7px', fontSize: '12px' }}>
                <img
                  alt="Arrow"
                  style={{ marginBottom: '4px' }}
                  src="/media/svg/menu-icons/home.svg"
                />
                <Link to='/dashboard'>
                  <a style={{
                    color: '#111928',
                    fontWeight: 'bold',
                    fontSize: '12px'
                  }}>
                    Inicio
                  </a>
                </Link>
                <img
                  alt="Arrow"
                  src="/media/svg/menu-icons/arrowright.svg" />
                <a style={{
                  color: '#637381',
                  fontWeight: 'bold',
                  fontSize: '12px'
                }}>Proceso de Visa
                </a>
              </div>
              <div className='top-view-header'>


                <div className='flex   max-sm:flex-col items-start'>
                  <h1 className='step-header'
                    style={{
                      marginBottom: '9px',
                      marginRight: '16px',
                    }}>
                    Seguimiento de visa
                  </h1>
                  <p className='step-header-subtitle' style={{ marginBottom: '0' }}>
                    Tienes
                    <b>{orderedData &&
                      orderedData.filter((item: any) =>
                        item.contracts_users_match_id !== '').length}
                      proceso(s) de visa
                    </b>
                  </p>
                </div>


                <a
                  className='button-ui button-primary'
                  href='/cart/immigrationservicescontract'>
                  COTIZAR VISA
                </a>


              </div>
              <div className='contracts-container' >
                {statusFromDatabase === 'success' ? (
                  orderedData && orderedData.map(
                    (item: any, index: number) => (
                      <>
                        {item.contracts_users_match_id &&
                          <div className='button-form2'
                            style={{
                              width: '18rem',
                              borderRadius: '10px',
                              boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                              padding: '30px 30px 40px 30px',
                              backgroundColor: 'white'
                            }}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.5rem',
                              width: '100%'
                            }}>
                              <div className='contract-continer'>
                                <p style={{ margin: '0', fontSize: '14px' }}>Contrato nº:</p>
                                <p className='contract-text'>{item.contracts_users_envelope_id}</p>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                                <p className='contract-text'>Contratado el </p>
                                <p className='contract-text'>
                                  {item.contracts_users_completedDate.substring(0, 10)}
                                </p>
                              </div>
                            </div>
                            <button className='link-button'

                              onClick={() => {
                                toggleDiv(index)
                                setShowContract(true)
                                setShowContractInfo(item)
                              }}>
                              <img src={Box} alt="Box" />
                              <p>
                                Ver detalles del servicio
                              </p>
                            </button>
                            <div className='flex flex-col gap-2 mt-4 mb-4 '>
                              {/* step 1 */}
                              {(item.contracts_users_step === 1 || item.contracts_users_step === null) &&
                                <div className='step-guide w-full flex flex-row items-center gap-2'>
                                  <div className='guide-number2'>
                                    <p>
                                      <b>1</b>
                                    </p>
                                  </div>
                                  <div className='blackpath' />
                                  <div className='flex flex-row gap-1 justify-between'>
                                    <p className='step-text2'
                                      style={{ color: 'black' }}>
                                      Servicio contratado
                                    </p>
                                  </div>
                                </div>}
                              {(item.contracts_users_step > 1) &&
                                <div className='step-guide w-full flex flex-row items-center gap-2'>
                                  <div className='guide-number-done2'>
                                    <p>1</p>
                                  </div>
                                  <div className='greenpath2'/>
                                  <p className='step-text2 tag-text'
                                    style={{ color: '#13C296' }}>
                                    Servicio contratado
                                  </p>
                                </div>}
                              {/* step 2 */}
                              {(item.contracts_users_step < 2 ||
                                item.contracts_users_step === null) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p>
                                        <b>2</b>
                                      </p>
                                    </div>
                                    <div className='graypath'/>
                                    <p className='step-text2'
                                      style={{ color: '#8899A8' }}>
                                      Completar formularios
                                    </p>
                                  </div>)}
                              {(item.contracts_users_step > 2) && (
                                <div className='step-guide w-full flex flex-row items-center gap-2'>
                                  <div
                                    className='guide-number-done2'
                                    onClick={() => {
                                      setStep(2)
                                    }}>
                                    <p>2</p>
                                    <div className='greenpath2'/>
                                  </div>
                                  <p className='step-text2'
                                    style={{
                                      color: '#13C296',
                                    }}>
                                    Completar formularios
                                  </p>
                                </div>)}
                              {(item.contracts_users_step === 2) && (
                                <div className='step-guide w-full flex flex-row items-center gap-2'
                                  onClick={() => {
                                    setStep(2)
                                  }}>
                                  <div className='guide-number2'>
                                    <p>2</p>
                                  </div>
                                  <div className='blackpath'/>
                                  <div className='flex flex-row gap-1 justify-between'>
                                    <p className='step-text2'
                                      style={{
                                        color: 'black',
                                      }}>
                                      Completar formularios
                                    </p>
                                    <div className='step-badge-active2'>
                                      <p className='step-badge-active-p-ones'>Pendiente</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Step 3 */}
                              {(item.contracts_users_step > 3) && (
                                <div className='step-guide w-full flex flex-row items-center gap-2'>
                                  <div
                                    className='guide-number-done2'
                                    onClick={() => {
                                      setStep(3)
                                    }}>
                                    <p>3</p>
                                  </div>
                                  <div className='greenpath2'/>
                                  <p className='step-text2'
                                    style={{ color: '#13C296' }}>
                                    Adjuntar documentos
                                  </p>
                                </div>)}
                              {item.contracts_users_step === 3 && (
                                <div className='step-guide w-full flex flex-row items-center gap-2'
                                  onClick={() => {
                                    setStep(3)
                                  }}>
                                  <div className='guide-number2'>
                                    <p>3</p>
                                  </div>
                                  <div className='blackpath'/>
                                  <div className='flex flex-row gap-1 justify-between'>
                                    <p className='step-text2'
                                      style={{
                                        color: 'black',
                                      }}>
                                      Adjuntar documentos
                                    </p>
                                    <div className='step-badge-active2'>
                                      <p className='step-badge-active-p-ones'>Pendiente</p>
                                    </div>
                                  </div>
                                </div>)}

                              {(item.contracts_users_step < 3 ||
                                item.contracts_users_step === null) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p>3</p>
                                    </div>
                                    <div className='graypath'/>
                                    <p className='step-text2'
                                      style={{ color: '#8899A8' }}>
                                      Adjuntar documentos
                                    </p>
                                  </div>)}

                              {/* step 4 */}

                              {item.contracts_users_step >= 5 && (
                                <div className='step-guide w-full flex flex-row items-center gap-2'>
                                  <div
                                    className='guide-number-done2'
                                    onClick={() => {
                                      setStep(4)
                                    }}>
                                    <p>4</p>
                                  </div>
                                  <div className='greenpath2'/>
                                  <div className='flex flex-col'>
                                    <p className='step-text2'
                                      style={{
                                        color: '#13C296',
                                      }}>
                                      Asignación y seguimiento
                                    </p>
                                  </div>
                                </div>
                              )}
                              {(item.contracts_users_step === 4 &&
                                item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'
                                    onClick={() => {
                                      setStep(4)
                                    }}>
                                    <div className='guide-number2'>
                                      <p>4</p>
                                    </div>
                                    <div className='blackpath'/>
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <p className='step-text2'
                                        style={{
                                          color: 'black',
                                        }}>
                                        Asignación y seguimiento
                                      </p>
                                      <div className='step-badge-active2'>
                                        <p className='step-badge-active-p-ones'>Pendiente</p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {(item.contracts_users_step < 5 &&
                                item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p>4</p>
                                    </div>
                                    <div className='graypath'/>
                                    <p className='step-text2'
                                      style={{
                                        color: '#8899A8',
                                      }}>
                                      Asignación y seguimiento
                                    </p>
                                  </div>
                                )}

                              {/* step 5 */}
                              {item.contracts_users_completed === true && (
                                <div className='step-guide w-full flex flex-row items-center gap-2'>
                                  <div
                                    className='guide-number-done2'
                                    onClick={() => {
                                      setStep(5)
                                    }}>
                                    <p>5</p>
                                  </div>
                                  <div className='flex flex-col'>
                                    <p className='step-text2'
                                      style={{
                                        color: '#13C296'
                                      }}>
                                      Confirmar cartas
                                    </p>
                                  </div>
                                </div>
                              )}
                              {(item.contracts_users_step === 5 &&
                                item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'
                                    onClick={() => {
                                      setStep(5)
                                    }}>
                                    <div className='guide-number2'>
                                      <p>
                                        <b>5</b>
                                      </p>
                                    </div>
                                    <div className='flex flex-row gap-1 justify-between'>
                                      <p className='step-text2'
                                        style={{
                                          color: 'black',
                                        }}>
                                        Confirmar cartas
                                      </p>
                                      <div className='step-badge-active2'>
                                        <p className='step-badge-active-p-ones'>Pendiente</p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {(item.contracts_users_step < 5 &&
                                item.contracts_users_completed === false) && (
                                  <div className='step-guide w-full flex flex-row items-center gap-2'>
                                    <div className='guide-number-deactivate2'>
                                      <p>
                                        <b>5</b>
                                      </p>
                                    </div>
                                    <p className='step-text2'
                                      style={{
                                        color: '#8899A8',
                                      }}>
                                      Confirmar cartas
                                    </p>
                                  </div>)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center', }}>
                              <button
                                className='button-ui button-secondary'
                                onMouseEnter={() => {
                                  const newArray = [...isHoveredArray]
                                  newArray[index] = true
                                  setIsHoveredArray(newArray)
                                }}
                                onMouseLeave={() => {
                                  const newArray = [...isHoveredArray]
                                  newArray[index] = false
                                  setIsHoveredArray(newArray)
                                }}
                                onClick={() => {
                                  setContractId(item.contracts_users_envelope_id)
                                  GetMainId(item.contracts_users_envelope_id)
                                }}>
                                Ir al proceso
                                <img alt="Calendar"
                                  className='meet-image2'
                                  src={isHoveredArray[index] ?
                                    '/media/icons/app/menu/arrow-right-white.svg' :
                                    '/media/icons/app/menu/arrow-right-black.svg'} />
                              </button>
                            </div>
                          </div>}
                      </>
                    )
                  )
                ) : (
                  <img
                    src='/media/logos/ezgif.com-resize.gif'
                    className='h-10px w-10px'
                    alt='img' />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
export default OnboardingUser
