/* eslint-disable @typescript-eslint/no-unused-vars */
import { Descriptions, PageHeader, Tabs, Timeline } from 'antd'
import React, { SetStateAction, useContext, useState } from 'react'
import './UserMessages/styles/TableStyle.css'
import { Alert } from 'react-bootstrap'
import { IReply, type IComposeData } from '../../../app/modules/apps/appointments/core/_models'
import ListMessagesUser from './UserMessages/ListMessagesUser'
import MessageContent from './MessageContent'
import { RootStoreContext } from '../../../stores/rootStore'
import UserProfile from '../../../app/pages/user/UserProfile'
import { useQuery } from 'react-query'
import { getUserUsersById } from '../../../app/modules/apps/users/core/_requests'
import AvatarProfileUsers from '../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import { ProfileImmigration, type Profile } from '../../../app/modules/apps/profile/core/_models'
import TimelineComponent from '../user/newtimeline'
import { IToUsers } from './interfaces/ITOUsers'
interface Props {
  areaDeEstudios?: string | null | undefined
  data?: string
  composepostdata: IComposeData
  setComposePost?: React.Dispatch<React.SetStateAction<boolean>>
  setComposeNote?: React.Dispatch<React.SetStateAction<boolean>>
  setCC: React.Dispatch<React.SetStateAction<IToUsers[]>>
  cc: IToUsers[]
  title: string
  showSelectFiles: boolean
  showShareFiles: boolean
  initialContent: string
  showTemplateSearch: boolean
  type: string
  initialSubject: string
  showComposeTemplate?: boolean
  ProfileImmigration: ProfileImmigration
  tabNumber?: string
  setTabNumber?: React.Dispatch<SetStateAction<string>>
  timelineUserId?: string
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>
}

const { TabPane } = Tabs

const PostConsult: React.FC<Props> = ({
  areaDeEstudios,
  composepostdata,
  setComposePost,
  setComposeNote,
  setCC,
  cc,
  title,
  showSelectFiles,
  showShareFiles,
  initialContent,
  showTemplateSearch,
  type,
  initialSubject,
  showComposeTemplate,
  ProfileImmigration,
  tabNumber,
  setTabNumber,
  timelineUserId,
  setComposePostData,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { currentUser } = rootStore.usersStore
  const [showmessage, setShowMessage] = useState(false)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [replyMessage, setReplyMessage] = useState(false)

  const handleClose = () => {
    if (setComposePost != null) setComposePost(false)
    if (setComposeNote != null) setComposeNote(false)
  }

  const { data, refetch } = useQuery(
    'getUserInfo',
    async () => await getUserUsersById(composepostdata.userId)
  )
  console.log(composepostdata.email, 'data del usuario en el index de postconsult')

  return (
    <>
      <div className=''>
        <div style={{ position: 'absolute', left: '70vw', top: '20vh' }}>
          <AvatarProfileUsers
            image={
              composepostdata.photoID
                ? `${composepostdata.photoPath}/${composepostdata.photoID}-avatar-large.jpg`
                : ''
            }
            size={100}
          />
        </div>
        <PageHeader
          className='site-page-header-responsive'
          onBack={() => {
            if (setTabNumber) {
              setTabNumber('')
            }
            handleClose()
          }}
          title={title}
          subTitle=''
          footer={
            <Tabs defaultActiveKey={tabNumber ? tabNumber : '3'} animated={true}>
              <TabPane tab=' Datos del cliente' key='3'>
                <UserProfile
                  composepostdata={composepostdata}
                  edit={true}
                  ProfileImmigration={ProfileImmigration}
                />
              </TabPane>

              <TabPane tab=' Linea de Tiempo' key='5'>
                <TimelineComponent
                  email={composepostdata.email}
                  user={data}
                  timelineUserId={composepostdata.userId}
                />
              </TabPane>
              <TabPane tab=' Mensaje' key='1'>
                <br />
                {composepostdata.folder === 'draft' ? (
                  <>
                    <Alert variant='danger'>
                      <Alert.Heading>Borrador</Alert.Heading>
                      <p>
                        Con esta opcion podrás guardarlo y enviarlo mas tarde o quedarse en tu
                        bandeja.
                      </p>
                      <hr />
                    </Alert>
                  </>
                ) : null}

                <MessageContent
                  setShowMessage={setShowMessage}
                  setShowMessageRows={setShowMessageRows}
                  setReplyMessage={setReplyMessage}
                  composepostdata={composepostdata}
                  showSelectFiles={showSelectFiles}
                  showShareFiles={showShareFiles}
                  initialContent={initialContent}
                  showTemplateSearch={showTemplateSearch}
                  initialSubject={initialSubject}
                  type={type}
                  cc={cc}
                  handleClose={handleClose}
                  showComposeTemplate={showComposeTemplate}
                  category='postconsulta'
                  tabNumber={tabNumber}
                  setComposeData ={setComposePostData}
                />
              </TabPane>
              <TabPane tab=' Mensajes enviados ' key='2'>
                <ListMessagesUser callRefetch={false} userId={composepostdata.userId} />
              </TabPane>
            </Tabs>
          }
        >
          <Descriptions size='small' column={2}>
            <Descriptions.Item label='Nombre'>{composepostdata.user}</Descriptions.Item>
            <Descriptions.Item label='Email'>{data?.email}</Descriptions.Item>
            <Descriptions.Item label='Nacionalidad'>{data?.nationalitie}</Descriptions.Item>
            <Descriptions.Item label='Teléfono'>{data?.phone}</Descriptions.Item>
            <Descriptions.Item label='Dirección'>{data?.address}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>
    </>
  )
}

export { PostConsult }
