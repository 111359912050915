import {type ID, type Response} from '../../../../../core/helpers'

export interface Files {
  id?: ID
  path?: string | null
  originalname?: string | null
  name: string
  encoding?: string | null
  mimetype?: string | null
  size?: number | null
  createdAt?: Date | null
  province?: string | ''
  via?: string | ''
}

export interface DataFiles {
  key: string
  fileName: string
}

export type FilesQueryResponse = Response<Files[]>

type toFormValuesType = (apiData: Files) => Files

export const toDataFiles = (apiData: Files[]) =>
  apiData.map((file: Files) => ({key: file.id, fileName: file.name}))

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id || '',
  path: apiData.path || '',
  originalname: apiData.originalname || '',
  name: apiData.name || '',
  encoding: apiData.encoding || '',
  mimetype: apiData.mimetype || '',
  size: apiData.size || 0,
  createdAt: apiData.createdAt != null ? new Date(apiData.createdAt) : null,
})

export interface QueryfilesData {
  type: string
  page: number
  search: string
  limit: number
  ids?: ID[]
  in: boolean
}
