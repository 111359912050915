export interface IEmbedVars {
  background: string
  embed: boolean
  color: string
  primary: string
  source: string
}

export const getEmbedVars = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)

  const primary = params.get('primary') || '#262833'
  const background = params.get('background') || '#fff'
  const embed = params.get('embed') === 'true' || false

  const color = params.get('color') || '#020'
  const source = params.get('source') || ''

  const embedVars: IEmbedVars = {
    background,
    embed,
    color,
    primary,
    source,
  }
  localStorage.setItem('cartoptions', JSON.stringify(embedVars))

  const root = document.documentElement
  root?.style.setProperty('--background-color-primary', `#${primary}`)
  root?.style.setProperty('--text-color', `#${color}`)
  root?.style.setProperty('--background-color', `#${background}`)
}
