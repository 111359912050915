/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */

import { useContext, useEffect, useState } from 'react'

import Bubles from './userDashboardIcons/speech-bubble-19.svg'
import Arrow from './userDashboardIcons/arrow-right-white.svg'
import ArrowTop from '../../contratationApp/icons/arrow-top-right.svg'
import Postcard from './userDashboardIcons/postcard.svg'
import Check from './userDashboardIcons/checkmark-circle.svg'
import Chevron from './userDashboardIcons/chevron-right.svg'
import Clock from './userDashboardIcons/clock-alt-1.svg'
import { RootStoreContext } from 'stores/rootStore'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { updateCon } from 'app/modules/apps/docusign/_requests'
import Graduate from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg';
import UsersIcon from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Users.svg';
import Plane from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/air-mail.svg';
import Briefcase from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-2.svg';
import Houseonly from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Houseonly.svg';
import UsersIcon2 from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg';
function UserDashboardVisaProcces() {
  const rootStore = useContext(RootStoreContext)
  const { contracts, loadingContracts, updatingContracts, loadingDocusignFetch } = rootStore.authStore
  const [update, setUpdate] = useState(0)
  const [contractsNum, setContractsNum] = useState('')

  const [contractsCompletedNum, setContractsCompletedNum] = useState(0)
  const [selected, setSelected] = useState(contracts?.[0]?.envelope_id || "");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isFinishSetUp, setisFinishSetUp] = useState(true);


  useEffect(() => {
    if (contracts && contracts.length > 0) {
      const mostRecentDateContract = contracts.reduce((anterior: any, actual: any) => {
        const previousDate = new Date(anterior.completedDate);
        const currentDate = new Date(actual.completedDate);
        return previousDate > currentDate ? anterior : actual;
      });

      // Solo establecer el contrato más reciente si no hay ninguno seleccionado manualmente
      if (!selected) {
        setSelected(mostRecentDateContract.envelope_id);
      }
    }
  }, [contracts, selected]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 420);
    };

    handleResize(); // Check initially
    window.addEventListener('resize', handleResize); // Add listener

    return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, []);

  useEffect(() => {
    if (loadingContracts === false) {
      setisFinishSetUp(true)
    }
  }, [loadingContracts]);
  useEffect(() => {
    if (contracts && contracts.length > 0) {
      contracts.forEach((item: any) => {
        if (item.completed === false) {
          setContractsCompletedNum((prevNum) => prevNum + 1);
        }
      });

      const mostRecentDateContract = contracts.reduce((anterior: any, actual: any) => {
        const previousDate = new Date(anterior.completedDate);
        const currentDate = new Date(actual.completedDate);
        return previousDate > currentDate ? anterior : actual;
      });

      setContractsNum(mostRecentDateContract.envelope_id);
      setUpdate((prevUpdate) => prevUpdate + 1);

      // Ajustar la lógica para establecer selected
      if (!selected && mostRecentDateContract.envelope_id) {
        setSelected(mostRecentDateContract.envelope_id);
      }
    }
  }, [contracts, selected]);



  const handleStatus = (id: string) => {
    if (contracts && contracts.length > 0) {
      contracts.map(async (con: any) => {
        if (con.envelope_id === id) {
          const result = await updateCon(con.envelope_id, 1)
          if (result) {
            updatingContracts()
          }
        } else {
          const result = await updateCon(con.envelope_id, 0)
          if (result) {
            updatingContracts()
          }
        }
      })
    }
  }

  const visitProducts = [
    'bb4056fb-2440-4a06-aec1-8d13349e7427', 'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97',
    'fd5b2b7d-8772-4ee6-91cc-53f710351021', '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', 'c263052c-80cf-4fc3-a628-88e51621f4e3',
    '6420335e-30aa-46c5-892b-e1fa23914733', '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac',
    '229d7b91-7a9b-407a-988b-9e45096bff5b', 'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a', '8a29f12d-a898-4d52-8fd1-f184094cd8c3', '65245c09-8c4d-485a-abaa-8f752d4dfe39',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '588bed15-e8fb-4216-8580-6de93f1512ae', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]

  const residenceProducts = [
    'c7571404-31f4-45b3-898a-160d4e72afc5', '9665d0d1-c145-4dc6-be9f-d4c69006604c', '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '2a7387ab-c1dc-45f1-a6b3-3f22c0fc30e5', 'f455901e-a97e-4c2d-abd4-8a359778e88a',
    '068de1e8-3074-4259-8a2a-1418d8f39ca3', '87bcc196-0f68-421b-9591-4e3565d69783', '728d916e-47fe-48ae-9628-468712651938',
    '0c9f691d-2b23-4c7d-8419-7f55785418f6', 'fb1a576c-f5a1-4d72-9aa8-d2f8eb5fa34d', '1de57333-82cf-4aaa-8a9b-23fadab35a3f',
    '7b84fba5-c4a9-48bc-ac44-f84a97db6ea2', 'bdda48a1-ea4d-480d-9e2c-e14e7904dd27', '054e28db-5fa8-487b-a452-38b94392f1ce',
    'cd90d231-265a-4be0-9982-54cf3aee6537', '346454f5-f81f-4a37-abc6-8e6d17f26512', '29c268c2-b5a9-4888-82c9-a209b5e20962',
    'a9b2deee-cb6f-4aa0-a521-8ecc02d32394', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', 'ee8c9b93-151c-40ce-86ca-8f8f2aa3a644',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3', '4762b784-650d-429b-b48d-02c351f34085'
  ]

  const specialProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99'
  ];


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderIcons = (contract: any) => {
    const icons = [];
    const applicants = JSON.parse(contract.applicants);
    const renderedIcons = new Set();

    const uniqueMemberTypes = Array.from(
      new Set(applicants.map((applicant: { memberType: any }) => applicant.memberType))
    ).length;


    applicants.forEach((applicant: { productId: string }) => {
      if (visitProducts.includes(applicant.productId) && !renderedIcons.has('visit')) {
        icons.push(
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '3px 5px',
              backgroundColor: '#01A9DB',
              borderRadius: '3px',
              gap: '5px',
              fontSize: '12px'
            }}
            key={`visit-${applicant.productId}`}
          >
            <img src={Plane} alt="Visit" />
          </button>
        );
        renderedIcons.add('visit');
      }
      if (studyProducts.includes(applicant.productId) && !renderedIcons.has('study')) {
        icons.push(
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '3px 5px',
              backgroundColor: '#EF5DA8',
              borderRadius: '3px',
              gap: '5px'
            }}
            key={`study-${applicant.productId}`}
          >
            <img src={Graduate} alt="Study" />
          </button>
        );
        renderedIcons.add('study');
      }
      if (workProducts.includes(applicant.productId) && !renderedIcons.has('work')) {
        icons.push(
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '3px 5px',
              backgroundColor: '#22AD5C',
              borderRadius: '3px',
              gap: '5px'
            }}
            key={`work-${applicant.productId}`}
          >
            <img src={Briefcase} alt="Work" />
          </button>
        );
        renderedIcons.add('work');
      }
      if (residenceProducts.includes(applicant.productId) && !renderedIcons.has('residence')) {
        icons.push(
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '3px 5px',
              borderRadius: '3px',
              gap: '5px',
              width: '35px'
            }}
            key={`residence-${applicant.productId}`}
          >
            <img src={Houseonly} alt="Residence" />
          </button>
        );
        renderedIcons.add('residence');
      }
      if (specialProducts.includes(applicant.productId) && !renderedIcons.has('special')) {
        icons.push(
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '3px 5px',
              backgroundColor: '#DFE4EA',
              borderRadius: '3px',
              gap: '5px'
            }}
            key={`special-${applicant.productId}`}
          >
            <img src={UsersIcon2} alt="Special" />
          </button>
        );
        renderedIcons.add('special');
      }
    });

    icons.push(
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }} key="members">
        <img src={UsersIcon} alt="Users" />
        <span>{uniqueMemberTypes}</span>
      </div>
    );

    return icons;
  };



  const renderTag = (contract: any, isSmallScreen: boolean) => {
    let tagText = '';
    const tagStyle = { backgroundColor: '#000', color: '#fff', borderRadius: '7px', padding: '3px 10px', fontSize: '11px' };

    switch (contract.step) {
      case 0:
        tagText = 'Sin etapa';
        break;
      case 1:
        tagText = isSmallScreen ? 'Etapa 1' : 'Etapa 1 CONTRATACIÓN';
        break;
      case 2:
        tagText = isSmallScreen ? 'Etapa 2' : 'Etapa 2 FORMULARIOS';
        break;
      case 3:
        tagText = isSmallScreen ? 'Etapa 3' : 'Etapa 3 DOCUMENTOS';
        break;
      case 5:
        tagText = isSmallScreen ? 'Etapa 4' : 'Cartas y asignación';
        break;
      default:
        tagText = '';
    }

    return <div style={tagStyle}>{tagText}</div>;
  };
  const handleContractSelection = (id: string) => {
    setSelected(id);
    handleStatus(id);
    toggleDropdown();
  }

  const renderContractItems = () => {
    return contracts?.map((item: any) => (
      <div
        onClick={() => {
          handleContractSelection(item.envelope_id)
          setisFinishSetUp(false)
        }}
        style={{
          // border: selected === item.envelope_id ? '2px solid blue' : '1px solid #fff',
          borderBottom: '1px solid #ccc',
          padding: '0.5rem',
          margin: '0.2rem 0',
          cursor: 'pointer',
          backgroundColor: selected === item.envelope_id ? '#e0f7fa' : '#fff',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '12px'
        }}
        key={item.envelope_id}
      >
        <div style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '11px', }}>
          Ctto n° {item.envelope_id}
        </div>
        <div style={{ fontWeight: 'normal', }}>
          Contratado el: {new Date(item.completedDate).toLocaleDateString()}
        </div>
        <div style={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem', }}>
          {renderIcons(item)} {renderTag(item, isSmallScreen)}
        </div>

      </div>
    ));
  };
  return (
    <>
      {contracts.length > 0
        && <div className='first-logging-header-card'>
          <div className='myvisa-proccess-header'>
            <div className='myvisa-proccess-header-title-container'>
              <div className='myvisa-proccess-header-title-wraper'>
                <img src={Postcard} alt="Postcard" />
                <h1 className='myvisa-proccess-header-title' style={{ fontSize: '1.5rem' }}>Solicitud de visa</h1>
              </div>
              {update > 0 &&
                <p>Tiene {contracts.length} proceso(s) de visa activo(s)</p>}
              {!(contracts.length) &&
                <div className='mt-4'>
                  <p style={{ fontSize: '14px' }}><b>Aún no tienes ninguna solicitud.</b> ¡No pierda tiempo y contrate ahora!</p>
                  <p style={{ fontSize: '14px' }}>Obtenga sus cotizaciones al instante. Nuestro sistema identificará sus opciones elegibles.</p>
                  <p style={{ fontSize: '14px' }}>¡Simplificamos sus procesos!</p>
                </div>}
            </div>
            {loadingContracts === true ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '300px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" />
                  <div style={{
                    width: '220px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#955DF5',
                    fontWeight: 'bold'
                  }}>Actualizando contratos </div>
                </div>
              </div>
            ) : (
              loadingContracts === false && (!contracts || contracts.length === 0) ? (<></>) : (<>
                {contractsNum &&
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='flex'
                      style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{
                        position: 'relative',
                        width: '100%',
                        // maxWidth: '32rem'
                      }} className='myvisa-proccess-selector-container'>
                        <p style={{ width: '100px' }}>Ver contratos:</p>
                        <div
                          className='custom-input-adapted'
                          onClick={toggleDropdown}>
                          {selected ? (
                            <div className='item-selector-contratos'>
                              <span>
                                {`Contrato n° ${selected}`}
                              </span>
                              <div className='detalles-item-contrato'>
                                <span>
                                  {`Contratado el: ${contracts.find((contract: { envelope_id: any }) => contract.envelope_id === selected)?.completedDate.substring(0, 10)}`}
                                </span>
                                <div style={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem' }}>
                                  {renderIcons(contracts.find((contract: { envelope_id: any }) => contract.envelope_id === selected))}
                                  {renderTag(contracts.find((contract: { envelope_id: any }) => contract.envelope_id === selected), isSmallScreen)}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <span>
                              'Seleccione un contrato'
                            </span>)}
                          <span className='dropdown-arrow'>
                            <img
                              src={isDropdownOpen ? '/media/svg/menu-icons/chevron-up.svg' : '/media/svg/menu-icons/chevron-down.svg'}
                              alt='Toggle Dropdown'
                            />
                          </span>
                        </div>
                        {isDropdownOpen && (
                          <div className='myvisa-proccess-selector-dropdown'
                          >
                            {renderContractItems()}
                          </div>)}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        {loadingDocusignFetch === true && <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '300px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '5px',
                            }}>
                            <img
                              style={{ width: '20px', height: '20px' }}
                              src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" />
                            <p style={{
                              width: '220px',
                              display: 'flex',
                              alignItems: 'center',
                              color: '#955DF5',
                              fontWeight: 'bold',
                              margin: '0'
                            }}>Verificando nuevos contratos.  </p>
                          </div>
                        </>}
                      </div>
                    </div>
                  </div>}
              </>))}
          </div>
          {contracts && contracts.length > 0 &&
            <>
              {contracts.map((item: any, index: any) =>
                item.envelope_id == selected &&
                <div key={index} style={{ backgroundColor: 'white', marginTop: '12px', }}>
                  <div className='myvisa-proccess-data-continer'>
                    <div className='user-badge-container'>
                      <p>Contratado el {item.completedDate.substring(0, 10)}</p>
                    </div>
                  </div>

                  {/* Timeline */}
                  <div className='myvisa-proccess-timeline-container'>

                    {/* step 1 */}
                    {(item.step === 1 || item.step === null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-active'>
                              <p style={{ fontSize: '16px', margin: '0', color: 'white' }}>1</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ color: '#1F2A37', fontSize: '12px' }}>SERVICIO CONTRATADO</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-activate' />
                      </div>}
                    {(item.step > 1) &&
                      <div className='step-guide'>
                        <div className='flex flex-row gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-badge-success'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#13C296' }}>1</p>
                            </div>
                            <div className='step-number-checked'>
                              <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                            </div>
                          </div>
                          <h1 className='step-name'
                            style={{
                              fontSize: '12px',
                              color: '#1A8245',
                              position: 'relative',
                              top: '8px',
                              zIndex: '100'
                            }}>SERVICIO CONTRATADO</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-success' />
                      </div>}

                    {/* step 2 */}
                    {(item.step < 2 || item.step === null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-deactivate'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#8899A8' }}>2</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#8899A8' }}>COMPLETAR FORMULARIOS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-deactivate' />
                      </div>}
                    {(item.step === 2) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-active'>
                              <p style={{ fontSize: '16px', margin: '0', color: 'white' }}>2</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#1F2A37' }}>COMPLETAR FORMULARIOS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='step-badge-active'>
                          <img className='clock-pending' src={Clock} alt="Clock" />
                          <p className='step-badge-active-p-one'>Procesos pendientes</p>
                          <p className='step-badge-active-p-two'>Pendiente</p>
                        </div>
                        <div className='raya-activate' />
                      </div>}
                    {(item.step >= 3) &&
                      <div className='step-guide'>
                        <div className='flex flex-row gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-badge-success'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#13C296' }}>2</p>
                            </div>
                            <div className='step-number-checked'>
                              <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                            </div>
                          </div>
                          <h1 className='step-name'
                            style={{
                              fontSize: '12px',
                              color: '#1A8245',
                              position: 'relative',
                              top: '8px',
                              zIndex: '100'
                            }}>COMPLETAR FORMULARIOS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-success' />
                      </div>}

                    {/* step 3 */}

                    {(item.step <= 2 || item.step === null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-deactivate'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#8899A8' }}>3</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#8899A8' }}>ADJUNTAR DOCUMENTOS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-deactivate' />
                      </div>}
                    {(item.step === 3 && item.step !== null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-active'>
                              <p style={{ fontSize: '16px', margin: '0', color: 'white' }}>3</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#1F2A37' }}>ADJUNTAR DOCUMENTOS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='step-badge-active'>
                          <img className='clock-pending' src={Clock} alt="Clock" />
                          <p className='step-badge-active-p-one'>Procesos pendientes</p>
                          <p className='step-badge-active-p-two'>Pendiente</p>
                        </div>
                        <div className='raya-activate' />
                      </div>}
                    {(item.step >= 4 && item.step !== null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-badge-success'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#13C296' }}>3</p>
                            </div>
                            <div className='step-number-checked'>
                              <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                            </div>
                          </div>
                          <h1 className='step-name'
                            style={{
                              fontSize: '12px',
                              color: '#1A8245',
                              position: 'relative',
                              top: '8px',
                              zIndex: '100'
                            }}>ADJUNTAR DOCUMENTOS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-success' />
                      </div>}

                    {/* step 4 */}
                    {(item.step <= 3 || item.step === null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-deactivate'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#8899A8' }}>4</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#8899A8' }}>ASIGNACIÓN Y SEGUIMIENTO</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='raya-deactivate' />
                      </div>}
                    {(item.step === 4 && item.completed === false) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-active'>
                              <p style={{ fontSize: '16px', margin: '0', color: 'white' }}>4</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#1F2A37' }}>ASIGNACIÓN Y SEGUIMIENTO</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='step-badge-active'>
                          <img className='clock-pending' src={Clock} alt="Clock" />
                          <p className='step-badge-active-p-one'>Procesos pendientes</p>
                          <p className='step-badge-active-p-two'>Pendiente</p>
                        </div>
                      </div>}
                    {(item.step >= 5 && item.step !== null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-badge-success'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#13C296' }}>4</p>
                            </div>
                            <div className='step-number-checked'>
                              <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                            </div>
                          </div>
                          <h1 className='step-name'
                            style={{
                              fontSize: '12px',
                              color: '#1A8245',
                              position: 'relative',
                              top: '8px',
                              zIndex: '100'
                            }}>ASIGNACIÓN Y SEGUIMIENTO</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                      </div>}

                    {/* step 5 */}
                    {(item.step <= 4 || item.step === null) &&
                      <div className='step-guide'>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-deactivate'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#8899A8' }}>5</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#8899A8' }}>CONFIRMAR CARTAS</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                        <div className='step-badge-active'>
                          <img className='clock-pending' src={Clock} alt="Clock" />
                          <p className='step-badge-active-p-one'>Procesos pendientes</p>
                          <p className='step-badge-active-p-two'>Pendiente</p>
                        </div>
                      </div>}
                    {(item.step === 5 && item.completed === false) &&
                      <div style={{ height: '3rem' }}>
                        <div className='flex flex-row items-center gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-number-active'>
                              <p style={{ fontSize: '16px', margin: '0', color: 'white' }}>5</p>
                            </div>
                          </div>
                          <h1 className='step-name' style={{ fontSize: '12px', color: '#1F2A37' }}>CONFIRMAR CARTAS</h1>
                          {/* <img className='step-number-arrow' src={Chevron} alt="Chevron" /> */}
                        </div>
                      </div>}
                    {(item.completed === true) &&
                      <div className='step-guide'>
                        <div className='flex flex-row gap-1'>
                          <div className=' flex flex-col items-center'>
                            <div className='step-badge-success'>
                              <p style={{ fontSize: '16px', margin: '0', color: '#13C296' }}>5</p>
                            </div>
                            <div className='step-number-checked'>
                              <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                            </div>
                          </div>
                          <h1 className='step-name'
                            style={{
                              fontSize: '12px',
                              color: '#1A8245',
                              position: 'relative',
                              top: '8px',
                              zIndex: '100'
                            }}>ASIGNACIÓN Y SEGUIMIENTO</h1>
                          <img className='step-number-arrow' src={Chevron} alt="Chevron" />
                        </div>
                      </div>}
                  </div>
                </div>
              )}
            </>}

          <div className='timeline-btn-continer' style={{ marginTop: '2rem' }}>
            <Link
              className='button-ui button-secondary'
              to='/cart/consultamigratoria'>
              <img style={{ width: '18px', height: '18px' }} src={Bubles} alt="Bubles" />
              <p className='btn-timeline-one'>SOLICITAR ASESORÍA</p>
            </Link>
            {contracts && selected && contracts.length > 0 ?
              <>
                {loadingContracts === false && isFinishSetUp ?
                  <Link
                    className='button-form-manual-asign'
                    to='/myvisa/proccess'>
                    <p className='btn-timeline-one' style={{ marginBottom: '0', color: 'white' }}>Ir a proceso de visa</p>
                    <img style={{ height: '16px', width: '16px', color: 'white' }} src={Arrow} alt="Arrow" />
                  </Link>
                  : <>
                    <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" />
                  </>
                }
              </>
              : (contracts && contracts.length > 0 && !selected) ? (
                null
              ) : <a
                rel="noreferrer"
                target="_blank"
                className='powerup-card-btn'
                href='https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402'>
                <p className='btn-timeline-one' style={{ marginBottom: '0', color: 'white' }}>COTIZAR VISA</p>
                <img style={{ height: '16px', width: '16px' }} src={ArrowTop} alt="Arrow" />
              </a>
            }
          </div>
        </div>}
    </>
  )
}

export default observer(UserDashboardVisaProcces);
