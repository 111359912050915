/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../../components'
import {Space} from 'antd'
import {type Category} from '../../../../../app/modules/apps/cart/core/category/_models'
import {GrEdit} from 'react-icons/gr'
import {getCategories} from '../../../../../app/modules/apps/cart/core/category/_requests'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  onComplete: (data: Category) => Promise<void>
}

const ListCategories: React.FC<IProps> = ({callRefetch, handleEdit, onComplete}) => {
  const intl = useIntl()
  const {data, status, refetch} = useQuery(
    'ListCategories',
    async () => await getCategories('limit=50&page=1&name=')
  )

  useEffect(() => {
    if (callRefetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [callRefetch])

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const handleEditTemplate = (templateId: string) => {
    if (data != null) {
      const templateEdit = data?.filter((temp) => temp.id === templateId)[0]
      handleEdit(templateEdit)
    }
  }

  if (status === 'success' && data != null) {
    return (
      <>
        <TableContainer style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
          <Table
          // dataSource={data} rowKey='id'
          >
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({id: 'CATEGORY.NAME'})}</TableCell>
                <TableCell>{intl.formatMessage({id: 'CATEGORY.STATUS'})}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((template) => (
                <TableRow key={template.id}>
                  <TableCell>{template.name}</TableCell>
                  <TableCell>
                    <Space>
                      {/*   <Switch
                        defaultChecked={template.status.id === 1}
                        onChange={(e) => {
                          template.status = e ? { id: 1 } : { id: 0 }
                          onComplete(template)
                        }}
                      /> */}
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      <Button
                        style={{background: 'white', border: '1px solid #bf3c3c'}}
                        onClick={() => {
                          handleEditTemplate(`${template.id}`)
                        }}
                      >
                        <GrEdit />
                      </Button>
                    </Space>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  } else {
    return <>No data</>
  }
}

export {ListCategories}
