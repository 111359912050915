/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useContext, useEffect} from 'react'
import {type CheckBoxOptions} from '../types'
import ImmigrationDetails from '../ImmigrationDetails'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {observer} from 'mobx-react'

const ChooseImmigrationElement = () => {
  const rootStore = useContext(RootStoreContext)
  const {
    immigrationServices,
    immigrationElement,
    getImmigrationElements,
    setImmigrationElementsCheked,
  } = rootStore.composeTemplateStore

  useEffect(() => {
    getImmigrationElements()
  }, [immigrationServices])

  const handleChange = (checkBoxOptions: CheckBoxOptions, cheked: boolean, type: string) => {
    setImmigrationElementsCheked(checkBoxOptions, cheked)
  }

  useEffect(() => {
    // comment to deactivate eslint
  }, [immigrationElement])

  return (
    <>
      {immigrationElement &&
        immigrationServices.map((item) => {
          return (
            <div key={item.id}>
              <ImmigrationDetails
                immigrationService={item}
                options={immigrationElement}
                type='immigrationElement'
                handleChange={handleChange}
              />
            </div>
          )
        })}
    </>
  )
}

export default observer(ChooseImmigrationElement)
