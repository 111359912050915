  /* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Card, PageHeader, Space, Spin} from 'antd'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import React, {useEffect, useState} from 'react'
import {ListFiles} from './ListFiles'
import LoadingBar from 'react-top-loading-bar'
import ProgressWraper from '../../../../app/modules/progress'
import {UploadFile} from './UploadFile'
import {uploadFile} from '../../../../app/modules/apps/file/core/_requests'
import {useIntl} from 'react-intl'

interface Props {
  data?: string
}

const FileManager: React.FC<Props> = ({data: Props}) => {
  const [addfile, setAddFile] = useState(false)
  const [percent, setPercent] = useState(-1)
  const [uploading, setUploading] = useState(false)
  const [icon, setIcon] = useState(<PlusOutlined />)
  const [callRefetch, setCallRefetch] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    if (percent === 100) {
      setPercent(-1)
      setUploading(false)
      setCallRefetch(true)
    }
  }, [percent])

  useEffect(() => {
    if (addfile) {
      setCallRefetch(false)
      setIcon(<MinusCircleOutlined style={{color: 'white'}} />)
    } else {
      setUploading(false)
      setCallRefetch(true)
      setIcon(<PlusOutlined style={{color: 'white'}} />)
    }
  }, [addfile])

  const uploadFileHandler = async (file: any) => {
    setAddFile(false)
    setUploading(true)
    setPercent(0)
    console.log('filesubmi' + JSON.stringify(file))
    const result = await uploadFile(file, getProgress, 'file-manager')

    setCallRefetch(false)
    if (result) {
      setCallRefetch(true)
    }
  }

  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  return (
    <>
      <PageHeader
        className='site-page-header-responsive'
        title=''
        subTitle=' '
        extra={[
          <Button
            key='addfile'
            type='primary'
            style={{
              background: 'white',
              border: '1px solid #bf3c3c',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setAddFile(!addfile)
            }}
            shape='circle'
            icon={icon}
          />,
        ]}
      >
        <Space direction='vertical' size='middle' style={{display: 'flex'}}>
          {addfile ? (
            <Card title='Card' size='small'>
              <UploadFile onComplete={uploadFileHandler} />
            </Card>
          ) : (
            <>
              {uploading ? (
                <>
                  <Spin size='large' />
                  <ProgressWraper percent={percent} />
                  <LoadingBar color='#BE8575' progress={percent} height={5} />
                </>
              ) : null}
            </>
          )}

          <ListFiles callRefetch={callRefetch} />
        </Space>
      </PageHeader>
    </>
  )
}

export {FileManager}
