/* eslint-disable @typescript-eslint/no-unused-vars */

import {type FC, useContext} from 'react'
import {Container} from '../../../../components/Container/index'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

const AdminStoreWrapper: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.STORE'})}>
        <div className='flex gap-4 justify-center flex-wrap'>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/products'
          >
            <img className='w-32' src='/media/svg/menu-icons/cv_services.svg' alt='' />
            <h2>Mis Servicios</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/categories'
          >
            <img className='w-32' src='/media/svg/menu-icons/rrecruitment_services.svg' alt='' />
            <h2>Categorias</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/cupons'
          >
            <img
              style={{width: 100, paddingTop: 28}}
              src='/media/svg/menu-icons/promo-code.png'
              alt=''
            />
            <h2>Mis Cupónes</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/Eduproducts'
          >
            <img className='w-32' src='/media/svg/menu-icons/cv_services.svg' alt='' />
            <h2>Crear Instituciones</h2>
          </Link>
        </div>
      </Container>
    </>
  )
}

export {AdminStoreWrapper}
