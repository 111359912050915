 
 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../components'
import React, { useContext, useEffect, useState } from 'react'
import ListDocumentsDetailsServices from './ListDocumentsDetailsServices'
import { type QueryState } from '../../../../core/helpers'
import { getLibraryFiles, getLibraryList } from '../../../../app/modules/apps/library/core/_requests'
import { useQuery } from 'react-query'
import { RootStoreContext } from 'stores/rootStore'
import PdfViewerAndres from 'core/components/PdfViewer/pdfViewerAndres'
interface Props {
  user: any
}


const ListDocuments: React.FC<Props> = (props) => {
  const { user } = props
  const rootStore = useContext(RootStoreContext)
  const [file, setFile] = React.useState<any | null>(null)
  const [fileName, setFileName] = React.useState<string>('')
  const [showModal, setShowModal] = React.useState(false)

  useEffect(() => {
    if (file) {
      setShowModal(true)
    }
  }, [file])

  const { data } = useQuery('getLibraryList', async () => await getLibraryFiles(user.id))

  return (
    <>
      {file ? (
        <div
          style={{
            position: 'relative',
          }}
        >
          <button
            className='button2 '
            style={{
              height: '2rem',
              width: '5rem',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setFile(null)
            }}
          >
            Cerrar
          </button>
          <br />
          <PdfViewerAndres
            name={fileName}
            url={URL.createObjectURL(file)}
            children={undefined}
          />
          {/*  <PdfViewer url={file} name={fileName} /> */}
        </div>
      ) : (
        <TableContainer
        style={{width:'100%'}}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell
                style={{
                  display: 'none',
                }}
              >
                id
              </TableCell>
              <TableCell>Servicioo</TableCell>
              <TableCell>Opcion</TableCell> */}
                <TableCell>Nombre de archivo</TableCell>
                <TableCell>Ultima Actualización</TableCell>
                <TableCell>Acciones</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data != null && (
                <ListDocumentsDetailsServices
                  libraryList={data}
                  setFile={setFile}
                  setFileName={setFileName}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default ListDocuments
