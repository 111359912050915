import {Container} from '../../../components/Container/index'
import {type FC} from 'react'
import {SuperAdminAppointments} from './SuperAdminAppointments'
import {useIntl} from 'react-intl'

const AdminAppointmentsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.MYAPPOINTMENTS'})}>
        <SuperAdminAppointments />
      </Container>
    </>
  )
}

export {AdminAppointmentsWrapper}
