export const formsManager = {
  'FORMSMANAGER.ADMINTOOLS': 'Herramientas de administración',
  'FORMSMANAGER.MANAGER': 'Administrador de formularios',
  'FORMSMANAGER.FORMSNAME': 'Nombre del formulario',
  'FORMSMANAGER.FORMSCATEGORY': 'Categoria',
  'FORMSMANAGER.SUBMIT': 'Enviar',
  'FORMSMANAGER.FORMSLIST': 'Lista de formularios',
  'FORMSMANAGER.FORMSMANAGER': 'Administrador de formularios',
  'FORMSMANAGER.FORMSDESCRIPTION': 'Descriptión del formulario',
  'FORMSMANAGER.JSON': 'JSON:',
  'FORMSMANAGER.SAVE': 'Guardar',
  'FORMSMANAGER.PLEASECLICKTHEFORM': 'Por favor seleccione el formulario que desee completar',
  'FORMSMANAGER.TEST': 'Testear ',
  'FORMSMANAGER.DELETE': 'Borrar',
  'FORMSMANAGER.MESSAGES': 'Administrador de formularios',
  'FORMSMANAGER.EDIT': 'Editar',
  'LISTFORMS.FILLTHEFORM': 'Llenar el formulario',
  'LISTFORMS.YOUARENOTCLIENT': 'No eres cliente',
  'SERVICES.INFORMATIONREQUIRED': 'Informacion Requerida',
  'SERVICES.FORMMANUAL': 'Formularios Adicionales',
  'SERVICES.ONBOARD': 'Bienvenido a Bordo',
  'SERVICES.FORMAUTOMATIC': 'Formularios Asignados',
  'SERVICES.IMPORTANTINFO': 'Información Importante',
  'SERVICES.SUPPORTDOCUMENTS': 'Documentos requeridos',
  'SERVICES.CLIENTDOCUMENTS': 'Documentos del cliente',
  'SERVICES.EXTRADOCUMENTS': 'Documentos Adicionales',
  'SUPPORTDOCUMENTS.TRANSLATE': 'Traducir',
  'SUPPORTDOCUMENTS.LEGISLATE': 'Legislar',
  'SUPPORTDOCUMENTS.DELETE': 'Eliminar de la lista',
  'SUPPORTDOCUMENTS.DOWNLOAD': 'Descargar',
}
