/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  ResponseApointment,
  type AppointmentsAdmin,
  type IComposeData,
  IApointments,
} from '../../../modules/apps/appointments/core/_models'
import {Button, DatePicker, Space, Table, Tooltip} from 'antd'
import React, {useContext, useEffect, useState} from 'react'
import {SiGooglemeet} from 'react-icons/si'
import {DateTime} from 'luxon'
import DialogComponent from '../../../../components/Dialog'
import UserProfile from '../../user/UserProfile'
import {useIntl} from 'react-intl'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import Column from 'antd/lib/table/Column'
import {ColumnFilterItem} from 'antd/lib/table/interface'
import {RangeValue} from 'rc-picker/lib/interface'
import { Link } from 'components'
import { RootStoreContext } from 'stores/rootStore'
interface Props {
  appointments?: ResponseApointment
  setComposePost: React.Dispatch<React.SetStateAction<boolean>>
  setComposePostData: React.Dispatch<React.SetStateAction<IComposeData>>
  setComposeNote: React.Dispatch<React.SetStateAction<boolean>>
  zone: string
  ProfileImmigration: ProfileImmigration
  setQuery: (query: IApointments) => void
  query: IApointments | null
  userId: string
}

interface ButtonHoverStates {
  [buttonId: string]: boolean;
}

const UsersAppointments2: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const {ProfileImmigration, userId} = props
  const [open, setOpen] = React.useState(false)
  const [opendataUser, setDataUser] = React.useState<IComposeData | null>(null)
  const [nameInfo, setNameInfo] = useState<ColumnFilterItem[]>()
  const intl = useIntl()
  const {appointments, zone, setQuery, query} = props
  const [currentPage, setCurrentPage] = useState(1)
  const [currentProductName, setCurrentProductName] = useState<string[]>()
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  const [userIdDetect, setUserIdDetect] = useState(false)
  const [buttonHoverStates, setButtonHoverStates] = useState<ButtonHoverStates>({});
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, {zone}).toFormat('yyyy-MM-dd HH:mm a')

    return keepOffset.toString()
  }

  const handleOpenMeet = (value: any) => {
    window.open(value, '_blank')
  }

  const currentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();

    // Agregar un cero delante si el mes o el día son menores que 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  };

  const handleHoverChange = (buttonId: string, isHovered: boolean) => {
    setButtonHoverStates((prevStates) => ({
      ...prevStates,
      [buttonId]: isHovered,
    }));
  };

  useEffect(() => {
    if (nameInfo === undefined && appointments && appointments.data && appointments.data.length > 0) {
      setNameInfo(
        appointments?.data.map((item: any) => {
          if (item.name !== undefined && item.name !== null) {
            return {
              text: item.name,
              value: item.name,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }
  }, [appointments])

  const onChange = (pagination: any, filters: any) => {
    setCurrentPage(pagination.current)
    console.log('proudctName' + filters.productName)
    if (filters.productName !== null) {
      setCurrentProductName(filters.productName)
    }

    if (filters.productName === null) {
      setCurrentProductName([])
    }
  }

  useEffect(() => {
    try {
      if (query !== null) {
        const params: IApointments = {
          ...query,
          page: currentPage,
          description: currentProductName,
          userId: userId,
        }

        setQuery(params)
        setUserIdDetect(true)
      }
    } catch (error) {}
  }, [currentPage, currentProductName])

  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: any = {
          ...query,
          startDate: values[0].format('DD-MM-YYYY'),
          endDate: values[1].format('DD-MM-YYYY'),
        }

        setQuery(params)
      } else {
        const params: any = {
          ...query,
          startDate: '',
          endDate: '',
        }
        setQuery(params)
      }
    } catch (error) {}
  }

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }

  if (userIdDetect === false) {
    return <>Cargando..</>
  }
  return (
     <>
      <div className= " flex flex-col  w-full sm:w-60 mt-2">
        <div className='flex gap-2'>
          <img alt="Calendar"
            src='/media/icons/app/menu/Group.svg' />
          <h1 style={{ margin: '0', fontSize: '18px', fontWeight: 'bold' }}>Citas agendadas </h1>
        </div>
        <p>{currentDate()}</p>
        {appointments && appointments.data  && appointments.data.length > 0 &&
          <> <p className='today-date'>Tienes <b>{appointments.data.filter((eachAppointment: any) => {
            return eachAppointment.start_time.substring(0, 10) === currentDate();
          }).length} cita(s)</b> para hoy</p>
            <div>
              {appointments.data.map((eachAppointment: any) => {
                // Aquí puedes realizar operaciones con cada cita si lo necesitas
                if (eachAppointment.start_time.substring(0, 10) === currentDate()) {
                  return (
                    <div className='consultant-date' key={eachAppointment.id}>
                      {/* Renderizar información de cada cita */}
                      <div className='div-name-date'>
                        <p className='date-consult'>{getDate(eachAppointment.start_time).substring(11, 16)} </p>
                        <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '180px' }} className='name-cosult'>{eachAppointment.first_name} {eachAppointment.last_name} </p>
                        <div className='container-meet' >
                          <Tooltip placement='top' title={'Google meet'}>
                            <button
                              className='button'
                              onClick={() => {
                                handleOpenMeet(`${eachAppointment.join_url}`);
                              }}
                              onMouseEnter={() => handleHoverChange(eachAppointment.id, true)}
                              onMouseLeave={() => handleHoverChange(eachAppointment.id, false)}
                            >
                              <div className='div-meet'>
                                <img
                                  alt="Calendar"
                                  className='meet-image'
                                  src={buttonHoverStates[eachAppointment.id] ? '/media/icons/app/menu/google-meet.svg' : '/media/icons/app/menu/google-meet-disable.svg'}
                                />
                                <b className='meet-text'>Unirme</b>
                              </div>
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      {/*   <p>{eachAppointment.email} </p> */}

                      <div className='div-description' style={{ marginTop: '0.3rem' }}>
                        <p className='description-consult'>{eachAppointment.description.replace(/<\/?[^>]+(>|$)/g, '')} </p>
                      </div>
                      <div className='line-container-consult'> </div>
                    </div>
                  );
                } else {
                  return null; // Otra acción si no se cumple la condición
                }
              })}
            </div>

          </>
        }
      </div>

      <div className='button-container'>
      <button
        className='button-ui button-secondary button2'

        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        <Link to={staff ? '/appointmentsbyconsultant/list'  : '/appointments'} className='div-meet2'>
          <p className='meet-text2'>VER MIS CITAS</p>
          <img
            alt="Calendar"
            className='meet-image2'
            src={isHovered2 ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'}
          />

        </Link>
      </button>
      </div>
    </>

  )
}

export {UsersAppointments2}
