/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import { type IReply } from '../../../../app/modules/apps/appointments/core/_models'
import { type Messages } from '../../../../app/modules/apps/messages/core/_models'
import { TimeZone } from '../../../../app/modules/utils/components/TimeZone'
import { getContent } from '../../../../core/components/email/utils/ContentManager'
import {
  getMessagesUsersIdMessage,
  getThread,
  updateMessagesFavorite,
  updateMessagesUnread,
} from '../../../../app/modules/apps/messages/core/_requests'
import parse from 'html-react-parser'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { MailAvatar } from '../../../../core/components/email'
import BaseModalWrapper from '../../../../components/profilePopup/BaseModalWarpper'
import { RootStoreContext } from 'stores/rootStore'
import Start from '../../contratationApp/icons/Start.svg'
import StartYellow from '../../contratationApp/icons/star-fill.svg'
import Plane from '../../contratationApp/icons/telegram-original.svg'
import Resent from '../../contratationApp/icons/Resent.svg'
import PDFIcon from '../../contratationApp/icons/PDF.svg'
import Arrowleft from '../../contratationApp/icons/arrow-left.svg'
import { downloadFileLibrary } from 'app/modules/apps/library/core/_requests'
import PdfViewerAndres from 'core/components/PdfViewer/pdfViewerAndres'
import PhotoView from 'app/pages/services/services/supportDocumentsClient/PhotoView'
import { RoleEnum } from 'auth/models/User/UserModel'
import EnvelopClose from '../../../pages/contratationApp/icons/envelopeClose.svg'
import EnvelopOpen from '../../../pages/contratationApp/icons/envelope open text.svg'
import Trash from '../../../pages/contratationApp/icons/trash-can-alt-1.svg'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  messageId: string
  folder: string
  setShowMessage: React.Dispatch<React.SetStateAction<boolean>>
  setlist: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  setComposepostdata: React.Dispatch<React.SetStateAction<IReply>>
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
}

const ShowMessages: React.FC<IProps> = ({
  folder,
  messageId,
  setShowMessage,
  handleClose,
  setComposepostdata,
  setlist,
  setReplyMessage,
  setShowMessageRows,
}) => {
  /* Popup Modal Section */
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(true)
  const [openContentId, setOpenContentId] = useState<any>(null)
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [lookImage, setLookImage] = useState<any>()
  const [loadingFile, setLoadingFile] = useState<boolean>(false)
  const [loadingFilet, setLoadingFilet] = useState<boolean>(false)

  const { loadMessages, loadMessagesUnread, loadCounterMessagesAuth, loadMessagesNew, page } =
    rootStore.messageStore
  const toggleModal = () => {
    setIsModalVisible((wasModasVisible) => !wasModasVisible)
  }
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const { data, status, refetch } = useQuery(
    'ShowMessages',
    async () => await getMessagesUsersIdMessage(messageId),
    {
      enabled: messageId !== '',
      onSuccess: () => { },
    }
  )

  const { data: threat, status: thstatus } = useQuery('thr', async () => await getThread(messageId),
    {
      enabled: messageId !== '',
    }
  )


  const handleViewer = async (item: any) => {
    setLoadingFile(true)
    const file = await downloadFileLibrary(item.id, () => {
      // comment to deactivate eslint
    })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    setFile(file)
    setFileName('documento.pdf')
    setLoadingFile(false)
  }

  const handleViewert = async (item: any) => {
    setLoadingFilet(true)
    const file = await downloadFileLibrary(item.id, () => {
      // comment to deactivate eslint
    })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    setFile(file)
    setFileName('documento.pdf')
    setLoadingFilet(false)
  }

  useEffect(() => {
    if (messageId) {
      refetch()
    }
  }, [messageId])

  const handleCloseMessage = () => {
    handleClose()
    if (setShowMessage) setShowMessage(false)
  }

  if (status === 'loading') {
    return <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
  }

  const convertImage = async (document: any) => {
    setLoadingFile(true)
    const file = await downloadFileLibrary(document, () => { })
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      } else {
        console.error('Error reading the file.')
      }
    }
    fileReader.onerror = () => { }
    fileReader.readAsArrayBuffer(file)
    setLoadingFile(false)
  }

  const convertImaget = async (document: any) => {
    setLoadingFilet(true)
    const file = await downloadFileLibrary(document, () => { })
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      } else {
        console.error('Error reading the file.')
      }
    }
    fileReader.onerror = () => { }
    fileReader.readAsArrayBuffer(file)
    setLoadingFilet(false)
  }
  const handleReply = (message: Messages) => {
    const data: IReply = {
      orderDetailId: message.orderDetailId,
      user: `${message.user}`,
      email: message.fromEmail,
      userId: message.fromId,
      fromId: message.userId,
      messageId: message.id || '',
      content: /* getContent(message.content) */ '',
      subject: `Re: ${message.subject}`,
      to: '{}',
      cc: '{}',
      type: 'reply',
      attachment: '',
      fromUser: message.userId,
      folder: 'inbox',
      draft: false,
      favorite: false,
      files_info: [],
      unread: false,
    }
    setComposepostdata(data)
    setReplyMessage(true)
  }

  const handleForward = (message: Messages) => {
    const data: any = {
      orderDetailId: message.orderDetailId,
      user:/*  `${message.user}` */null,
      email:/*  message.fromEmail */"",
      userId: /* message.fromId */null,
      fromId: /* message.userId */"",
      messageId: message.id || '',
      content: getContent(message.content),
      subject: `Fw: ${message.subject}`,
      to: '{}',
      cc: '{}',
      type: 'forward',
      attachment: '',
      fromUser: message.userId,
      folder: 'inbox',
      draft: false,
      favorite: false,
      files_info: message.files_info,
      unread: false,
    }
    setComposepostdata(data)
    setlist(false)
    setReplyMessage(true)
  }

  const MessagesUnread = async (data: any) => {
    let read = false
    data.unread === false ? read = true : read = false
    if (data.id) {
      await updateMessagesUnread(read, data.id?.toString())
      loadCounterMessagesAuth(user?.id)
      loadMessages("inbox")
      loadMessagesUnread()
      loadMessagesNew("inbox", page)
      refetch()
    }
  }

  const MessagesUpdateFavorite = async (data: any) => {
    let star = false
    data.favorite === false ? star = true : star = false
    if (data.id) {
      await updateMessagesFavorite(star, data.id?.toString())
      loadCounterMessagesAuth(user?.id)
      loadMessages("inbox")
      loadMessagesNew("inbox", page)
      refetch()
    }
  }

  const convertToPlainText = (htmlString: string, maxLength: number): string => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString
    const plainText = tempElement.textContent || tempElement.innerText || ''
    return plainText
  }

  const toggleContent = (thrId: any) => {
    setOpenContentId((prevId: any) => (prevId === thrId ? null : thrId))
  }

  if (status === 'success' && data) {
    return (
      <>
        {file && file.type === 'application/pdf' &&
          <div className='pdf-viewer-width'>
            <button
              className='button-volver'
              style={{
                height: '2rem',
                width: '5rem',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'black',
                marginTop: '1rem',
              }}
              onClick={() => {
                setFile(null)
              }}>
              Volver
            </button>
            <br />
            <div>
              <PdfViewerAndres
                name={fileName}
                url={URL.createObjectURL(file)}
                children={undefined} />
            </div>
          </div>}
        {lookImage &&
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 10000,
            overflow: 'scroll',
            backgroundColor: 'white',
            top: '0'
          }}>
            <PhotoView image={lookImage} setLookImage={setLookImage} />
          </div>}
        <div style={{
          height: 'fit-content',
          width: '-webkit-fill-available',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }}>
          <div style={{ height: 'auto', marginBottom: '0' }}>
            <div className='w-full flex justify-start'>
              <div
                className='button-volver'
                style={{ width: '8rem', display: 'flex', gap: '5px' }}
                onClick={() => {
                  handleCloseMessage
                  setShowMessage(false)
                  setShowMessageRows(true)
                  setReplyMessage(false)
                  loadMessages('inbox')
                }}>
                <img src={Arrowleft} alt="Arrowleft" />
                <p className='meet-text2' style={{ margin: '0' }}>VOLVER</p>
              </div>
            </div>
            <div className='flex gap-2 pt-2 mt-2'
              style={{ borderTop: '1px solid #DFE4EA' }}>
              <p style={{
                fontWeight: 'bold',
                fontSize: '18px'
              }}>Asunto: </p>
              {data[0].subject !== undefined &&
                <p style={{
                  fontWeight: 'bold',
                  fontSize: '18px'
                }}>
                  {parse(data[0].subject) || ''}
                </p>}
              <div className='hide-component-fullscreen'
                style={{
                  alignItems: 'flex-start',
                  paddingTop: '0.2rem',
                  gap: '1rem',
                  marginLeft: '10px'
                }}>
                {folder !== 'sent' &&
                  <>
                    {data[0].favorite === true ?
                      <img
                        style={{ width: '20px', height: '20px' }}
                        onClick={() => {
                          MessagesUpdateFavorite(data[0])
                        }}
                        src={StartYellow}
                        alt="yellowStar" />
                      :
                      <img
                        style={{ width: '20px', height: '20px' }}
                        onClick={() => {
                          MessagesUpdateFavorite(data[0])
                        }}
                        src={Start}
                        alt="Star" />
                    }
                    {data[0].unread === false ?
                      <img
                        style={{ width: '20px', height: '20px' }}
                        onClick={() => {
                          MessagesUnread(data[0])
                        }}
                        src={EnvelopClose}
                        alt="EnvelopClose" /> :
                      <img
                        style={{ width: '20px', height: '20px' }}
                        onClick={() => {
                          MessagesUnread(data[0])
                          /* setActive(true) */
                        }}
                        src={EnvelopOpen}
                        alt="EnvelopOpen" />
                    }
                  </>}
              </div>
            </div>
            {loadingFilet === true && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /><p style={{ margin: 0 }}>Cargando archivo adjunto</p></div>}
            {threat && threat.map((thr: any) =>
              <>
                {thr.id !== messageId &&
                  <div
                    key={thr.id}
                    className='flex gap-2 items-start threat-fullscream-container'
                    onClick={() => {
                      toggleContent(thr.id)
                    }}>
                    <AvatarProfileUsers
                      image={
                        thr.fromPathId
                          ? `${thr.fromPath}/${thr.fromPathId}-avatar-small.jpg`
                          : '/media/avatars/avatar.png'
                      }
                      size={20} />
                    <div className='flex flex-col w-full'>
                      <div className='flex justify-between w-full'>
                        <div className='flex flex-col'>
                          <div className='flex'>
                            <p style={{
                              margin: '0',
                              fontWeight: 'bold'
                            }}>
                              {thr.from_user_first_name}
                            </p>&nbsp;
                            <p style={{
                              margin: '0',
                              fontWeight: 'bold'
                            }}>
                              {thr.from_user_last_name}
                            </p>
                          </div>
                          <p style={{
                            fontSize: '0.7rem',
                            margin: '0',
                            color: '#8899A8'
                          }}>
                            {`<${thr.from_user_email}>`}
                          </p>
                        </div>
                        <p style={{
                          margin: '0',
                          fontSize: '12px'
                        }}>
                          {thr.created_at.slice(0, 10)}
                        </p>
                      </div>
                      <div className='flex gap-2 flex-col mt-2'>
                        <p style={{ margin: '0', fontSize: '12px' }}>
                          Ver mensaje
                        </p>
                        {openContentId === thr.id && (
                          <div
                            onClick={(event) =>
                              event.stopPropagation()
                            }
                            style={{
                              margin: '0',
                              fontSize: '12px',
                              overflow: 'auto',
                              wordWrap: 'break-word', // Permite que el texto se divida en palabras si es demasiado largo
                              whiteSpace: 'pre-line', // Establece la altura máxima del párrafo (ajustar según sea necesario)
                              lineHeight: '1.5em',
                              width: '100%'
                            }}
                            dangerouslySetInnerHTML={{ __html: thr.content }}>
                            {/* {thr.content} */}
                          </div>
                        )}
                      </div>
                      <div className='gap-2 mt-1 mb-2'
                        style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {thr.files_info.length &&
                          thr.files_info.map((file: any) =>
                            <>
                              {file.id !== null &&
                                <div
                                  className='files-show-message-hover'
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    if (file.originalname.includes('.pdf')) {
                                      handleViewert(file)
                                    } else if (file.originalname.includes('.png') ||
                                      file.originalname.includes('.jpg') ||
                                      file.originalname.includes('.jpeg')) {
                                      convertImaget(file.id)
                                    }
                                  }}
                                  style={{
                                    borderRadius: '5px',
                                    display: 'flex',
                                    gap: '5px',
                                  }}>
                                  <img style={{ width: '10px' }} src={PDFIcon} alt="PDFIcon" />
                                  {file.originalname &&
                                    <p style={{
                                      color: '#01A9DB',
                                      margin: '0',
                                      fontSize: '10px'
                                    }}>
                                      {file.originalname ?
                                        `${file.originalname.slice(0, 10)}
                                  ${file.originalname.length > 10 ? '...' : ''}`
                                        : `${file.name.slice(0, 10)}
                                  ${file.name.length > 10 ? '...' : ''}`}
                                    </p>}
                                </div>}
                            </>
                          )}
                      </div>
                    </div>
                  </div>}
              </>
            )}
            <div
              className=' text-xs flex flex-col mt-4'
              onClick={() => {
                toggleContent(null)
              }}
              style={{
                paddingBottom: '0.5rem',
                marginBottom: '1rem',
                gap: '0.8rem'
              }}>
              <div className='header-show-message'>
                <div className='flex'>
                  <MailAvatar>
                    <button onClick={toggleModal}>
                      <AvatarProfileUsers
                        image={
                          data && data[0] && data[0].fromPathId
                            ? `${data[0].fromPath}/${data[0].fromPathId}-avatar-small.jpg`
                            : '/media/avatars/avatar.png'
                        }
                        size={40} />
                    </button>
                    <BaseModalWrapper
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      onBackdropClick={toggleModal}
                      name={data && data[0] && data[0].fromName}
                      email={data && data[0] && data[0].fromEmail}
                      fromId={data && data[0] && data[0].fromId}
                      fromPathId={data && data[0] && data[0].fromPathId}
                      fromPath={data && data[0] && data[0].fromPath}
                    />
                  </MailAvatar>
                  <div className='flex flex-col'>
                    <h1 style={{
                      fontSize: '1rem',
                      margin: '0',
                      fontWeight: 'bold'
                    }}>
                      {` ${data && data[0] && data[0].fromName} `}
                    </h1>
                    <p style={{
                      fontSize: '0.8rem',
                      margin: '0',
                      color: '#8899A8'
                    }}>
                      {`<${data && data[0] && data[0].fromEmail}>`}
                    </p>
                  </div>
                </div>
                <div className='header-date-message '>
                  <TimeZone date={data && data[0] && data[0].created_at ? data[0].created_at : null} />
                  <div className='hide-component'
                    style={{ alignItems: 'flex-start', gap: '1rem', marginLeft: '10px' }}>
                    {folder !== 'sent' &&
                      <>
                        {data[0].favorite === true ?
                          <img
                            onClick={() => {
                              MessagesUpdateFavorite(data[0])
                            }
                            }
                            src={StartYellow}
                            alt="yellowStar" />
                          :
                          <img
                            onClick={() => {
                              MessagesUpdateFavorite(data[0])
                            }
                            }
                            src={Start}
                            alt="Star" />
                        }
                        {data[0].unread === false ?
                          <img
                            onClick={() => {
                              MessagesUnread(data[0])
                            }
                            }
                            src={EnvelopClose}
                            alt="EnvelopClose" /> :
                          <img
                            onClick={() => {
                              MessagesUnread(data[0])
                              /* setActive(true) */
                            }
                            }
                            src={EnvelopOpen}
                            alt="EnvelopOpen" />
                        }
                      </>}
                  </div>
                </div>
              </div>
              <div style={{ padding: '0.5rem', paddingBottom: '1rem' }}>
                <div className='flex gap-1'>
                  <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Asunto: </p>
                  <p style={{ fontWeight: 'bold', fontSize: '0.95rem' }}>
                    {parse(data && data[0] && data[0].subject) || ''}
                  </p>
                </div>
                <div>
                  <p style={{ width: '100%', wordWrap: 'break-word' }}>
                    {parse(data && data[0] && data[0].content) || ''}
                  </p>
                </div>
                {loadingFile === true &&
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" />
                    <p style={{ margin: 0 }}>Cargando archivo adjunto</p>
                  </div>}
                <div className='gap-2 mt-4 mb-2'
                  style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {data[0].files_info &&
                    data[0].files_info.map((file: any) =>
                      <div
                        className='files-show-message-hover'
                        onClick={() => {
                          if (file.originalname.includes('.pdf')) {
                            handleViewer(file)
                          } else if (file.originalname.includes('.png') ||
                            file.originalname.includes('.jpg') ||
                            file.originalname.includes('.jpeg')) {
                            convertImage(file.id)
                          }
                        }}
                        style={{
                          maxWidth: '12rem',
                          border: '1px solid #01A9DB',
                          borderRadius: '5px',
                          display: 'flex',
                          gap: '5px',
                          padding: '5px'
                        }}>
                        <img style={{ width: '13px' }} src={PDFIcon} alt="PDFIcon" />
                        {file.originalname &&
                          <p style={{ color: '#01A9DB', margin: '0', fontSize: '1rem' }}>
                            {file.originalname ?
                              `${file.originalname.slice(0, 10)}${file.originalname.length > 10 ? '...' : ''}`
                              : `${file.name.slice(0, 10)}${file.name.length > 15 ? '...' : ''}`}</p>}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{ borderBottom: '1px solid #DFE4EA', paddingBottom: '1rem' }}
              className='flex justify-end '>
              {isButtonVisible === true && user != null && user.role?.id === RoleEnum.admin ?
                <button
                  style={{ display: 'flex', gap: '5px', width: '8rem', height: '3rem' }}
                  className="button-volver"
                  onClick={() => {
                    handleForward(data[0])
                    setIsButtonVisible(false)
                  }}>
                  <p className='meet-text2' >Reenviar</p>
                  <img src={Resent} alt="Resent" />
                </button>
                : null}
              {isButtonVisible === true ?
                <a
                  href='#ReplyMessage'
                  style={{ display: 'flex', width: '10rem !important', height: '3rem', padding: '10px 30px' }}
                  className='new-messages-button'
                  onClick={() => {
                    handleReply(data[0])
                    setIsButtonVisible(false)
                  }}>
                  <p>Responder</p>
                  <img style={{ width: '20px', height: '20px' }} src={Plane} alt="Plane" />
                </a> :
                <a
                  href='#ReplyMessage'
                  style={{ border: '2px solid #E10E0E', display: 'flex', width: '10rem !important', height: '3rem', padding: '10px 30px' }}
                  className='button-volver'
                  onClick={() => {
                    setIsButtonVisible(true)
                    setReplyMessage(false)
                  }}>
                  <img style={{ width: '20px', height: '20px', marginRight: '3px' }} src={Trash} alt="Trash" />
                  <p style={{ color: '#E10E0E' }}>Descartar Respuesta</p>
                </a>}
            </div>
            <div className=' text-xs flex' style={{ paddingBottom: '0.5rem', gap: '0.8rem' }}>
              <div>
                <MailAvatar className='lg:w-2/12'>
                  <BaseModalWrapper
                    setIsModalVisible={setIsModalVisible}
                    isModalVisible={isModalVisible}
                    onBackdropClick={toggleModal}
                    name={data && data[0] && data[0].fromName}
                    email={data && data[0] && data[0].fromEmail}
                    fromId={data && data[0] && data[0].fromId}
                    fromPathId={data && data[0] && data[0].fromPathId}
                    fromPath={data && data[0] && data[0].fromPath}
                  />
                </MailAvatar>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <> </>
  }
}

export default ShowMessages
