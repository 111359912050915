import {appointments} from './appointments'
import {auth} from './auth'
import {categories} from './categories'
import {checkList} from './checkList'
import {clients} from './clients'
import {common} from './common'
import {components} from './components'
import {contracts} from './contract'
import {core} from './core'
import {fileManager} from './fileManager'
import {formsManager} from './formsManager'
import {immigration} from './immigration'
import {menu} from './menu'
import {myinformation} from './myinformation'
import {products} from './products'
import {profile} from './profile'
import {templates} from './templates'
import {translation} from './translation'
import {contratation} from './contratation'

export default {
  ...core,
  ...profile,
  ...appointments,
  ...menu,
  ...auth,
  ...checkList,
  ...formsManager,
  ...translation,
  ...templates,
  ...myinformation,
  ...clients,
  ...fileManager,
  ...products,
  ...immigration,
  ...common,
  ...categories,
  ...components,
  ...contracts,
  ...contratation,
}
