/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePicker } from "antd"
import { getAllStaff, updateStaff } from "app/modules/apps/staff/core/_requests"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { RootStoreContext } from "stores/rootStore"
import checkmark_white from '../../../pages/contratationApp/icons/checkmark_white.svg'

const StaffManager: React.FC = () => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [staff, setStaff] = useState<any>(null)
  const [staffID, setStaffID] = useState<any>(null)
  const [roll, setRoll] = useState<any>(null)
  const [newRoll, setNewRoll] = useState('')
  const [newActive, setNewActive] = useState(1)
  const [startAbsense, setStartAbsense] = useState<any>(null)
  const [endAbsense, setEndAbsense] = useState<any>(null)
  const [substitute, setSubstitute] = useState<any>(null)
  const [showToast, setShowToast] = useState(false)
  const [editDate, setEditDate] = useState(true)
  const [editSubs, setEditSubs] = useState(true)
  const [validate, setValidate] = useState(0)
  const { data: StaffData, status: StaffStatus, refetch: StaffRefetch } = useQuery('getStf', async () => await getAllStaff())

  const substitutes = StaffData ?
  StaffData
    .filter((option: any) => option.substitute && option.substitute.id)
    .map((option: any) => option.substitute.id) : [];

  useEffect(() => {
    if (StaffStatus === 'success' && StaffData) {
      console.log(StaffData, 'la lista de staff')
      const types = StaffData
        .map((option: any) => option.type.trim()) // Elimina los espacios en blanco de los types
        .filter((type: string) => type !== '') // Filtra los types vacíos
        .filter((type: string, index: number, self: string[]) => self.indexOf(type) === index) // Elimina duplicados
      setRoll(types)
    }
  }, [StaffStatus, StaffData])

  useEffect(() => {
    if (staff) {
      setEditDate(staff.start && staff.end ? false : true)
      setEditSubs(staff.substitute ? false : true)
      setStartAbsense(staff.start || null)
      setEndAbsense(staff.end || null)
      setSubstitute(staff.substitute || null)
    }
  }, [staff])

  const handleSelectChange = (e: any) => {
    const selectedId = e.target.value
    const selectedStaff = StaffData?.find((option: any) => option.id === selectedId)
    setStaffID(selectedId !== '' ? selectedId : null)
    setStaff(selectedId !== '' ? selectedStaff : null)
  }

  const handleSelectSubstitute = (e: any) => {
    const selectedId = e.target.value
    const selectedSubstitute = StaffData?.find((option: any) => option.id === selectedId)
    setSubstitute(selectedId !== '' ? selectedSubstitute : null)
  }

  const saveStaff = async () => {
    const staffData = {
      type: newRoll === '' ? staff.type : newRoll,
      status: newActive,
      author: user,
      substitute: substitute ? substitute.user : staff.substitute ? staff.substitute.user : null,
      start: startAbsense,
      end: endAbsense,
    }
    const result = await updateStaff(staffID, staffData)
    console.log('saved')

    if (result) {
      setShowToast(true)
      StaffRefetch()
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
    }
  }

  return (
    <div className="flex gap-16">
      <div>
        <h1 style={{
          margin: '0',
          fontSize: '18px',
          fontWeight: 'bold',
          marginBottom: '2rem'
        }}>Staff Manager</h1>
        <div style={{
          height: '5rem',
          marginLeft: '1rem'
        }}>
          <p>
            <b>Seleccionar un case worker</b>
          </p>
          <select
            name='intake'
            id='intake'
            className='option-styles-intake'
            onChange={handleSelectChange}
          >
            <option value=''>Seleccione</option>
            {StaffData &&
              StaffData
                .filter((option: any) => option.status.name === 'Active') // Filtrar los que están activos
                .map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.jotformName}
                  </option>
                ))}
          </select>
          {validate > 0 && staff === null && (
            <p style={{ color: '#bf3c3c' }}>Debe seleccionar un case worker</p>
          )}
        </div>
      </div>

      {staff !== null && (
        <div className='first-logging-timeline-card'
          style={{ minWidth: '750px' }}>
          <h1 style={{ margin: '0', fontSize: '18px', fontWeight: 'bold', marginBottom: '1rem' }}>Editor de staff</h1>
          <p><b>Nombre:</b> {staff.user.firstName} {staff.user.lastName}</p>
          <div>
            <p><b>Editar rol</b></p>
            <select
              name='intake'
              id='intake'
              className='option-styles-intake'
              onChange={e => {
                setNewRoll(e.target.value)
                console.log('el roll', e.target.value)
              }}>
              <option value={staff.type}>Rol actual: {staff.type}</option>
              {roll &&
                roll.map((option: any) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <p><b>Editar status</b></p>
            <select
              name='intake'
              id='intake'
              className='option-styles-intake'
              onChange={e => {
                const value = Number(e.target.value);
                setNewActive(value);
                console.log('el status', value);
              }}>
              <option value={staff.status.id}>Status actual: {staff.status.name}</option>
              <option key={1} value={1}>Active</option>
              <option key={0} value={0}>Inactive</option>
            </select>
          </div>
          <p className='mt-4'><b>Establecer período de ausencia</b></p>
          <div className='flex gap-8 items-center'>
            <div>
              <p style={{ margin: '0' }}><b>Inicio:</b> &nbsp;{staff.start ? staff.start.substring(0, 10) : ''}</p>
              {editDate === true &&
                <DatePicker
                  className="mt-4"
                  format='YYYY-MM-DD'
                  onChange={(date) => {
                    if (date && date !== null) {
                      setStartAbsense(date)
                    } else {
                      setStartAbsense(null) // o el valor que consideres adecuado para fechas nulas
                    }
                  }}
                />}
            </div>
            <div>
              <p style={{ margin: '0' }}><b>Reincorporación: &nbsp;</b>{staff.end ? staff.end.substring(0, 10) : ''}</p>
              {editDate === true &&
                <DatePicker
                  className="mt-4"
                  format='YYYY-MM-DD'
                  onChange={(date) => {
                    if (date && date !== null) {
                      setEndAbsense(date)
                    } else {
                      setEndAbsense(null) // o el valor que consideres adecuado para fechas nulas
                    }
                  }}
                />}
            </div>
            {editDate === false &&
              <button
                style={{ width: '4rem' }}
                className='powerup-card-btn'
                onClick={() => {
                  setEditDate(true)
                }}>
                <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>Editar</p>
              </button>}
          </div>
          <div className='mt-4'>
            <div className="flex gap-8">
              <p><b>Suplente:</b> {substitute !== null ? substitute.firstName : ''} {substitute !== null ? substitute.lastName : ''}</p>
              {editSubs === false &&
                <button
                  style={{ width: '4rem' }}
                  className='powerup-card-btn'
                  onClick={() => {
                    setEditSubs(true)
                  }}>
                  <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>Editar</p>
                </button>}
            </div>

            {editSubs === true &&
              <select
                name='intake'
                id='intake'
                className='option-styles-intake'
                onChange={handleSelectSubstitute}
                value={substitute ? substitute.id : ''}
              >
                <option value=''>Seleccione</option>
                {StaffData &&
                  StaffData
                    .filter((option: any) =>
                      option.status.name === 'Active' &&
                      option.offline === false &&
                      !substitutes.includes(option.user.id) // Verifica si user.id no está en la lista de substitutes
                    )
                    .map((option: any) => (
                      <option key={option.id} value={option.id}>
                        {option.jotformName}
                      </option>
                    ))
                }
              </select>}

            <p style={{ marginTop: '1rem', fontSize: '16px' }}>
              <b>Asignado por:</b>
              &nbsp; {staff && staff.author ? `${staff.author.firstName} ${staff.author.lastName}` : ''}
            </p>
            <button
              style={{ marginTop: '2rem', width: '8rem' }}
              type='button'
              className='powerup-card-btn'
              onClick={saveStaff}
            >
              <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>Guardar</p>
            </button>
          </div>
        </div>
      )}
      {showToast && (
        <div className='toast-style-container' style={{ top: '2rem' }}>
          <div className='toast-style'>
            <div style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Guardado Exitoso
          </div>
        </div>
      )}
    </div>
  )
}

export { StaffManager }
