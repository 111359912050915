import { action, computed, makeObservable, observable } from 'mobx'
import { type RootStore } from '../../../../../stores/rootStore'
import io, { Socket } from "socket.io-client"


export default class NotificationStore {
    rootStore: RootStore
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable socket: Socket | null = null;

    @action connectSocket = () => {
        if (!this.socket) {
            const url = process.env.REACT_APP_DOCUSIGN_BACKEND_URL
            if (url) {
                const so = io(url)
                this.socket = so
                console.log("socket conected", so)
            }
        }
    }

    @action disconnectSocket = () => {
        if (this.socket) {
            this.socket = this.socket.disconnect()
            console.log("socket disconnected")
            this.socket = null;
        }
    }
}
