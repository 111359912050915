import {type Product} from '../../../../app/modules/apps/cart/core/products/_models'
import ProductListDetail from './ProductListDetail'
import React from 'react'

interface IProps {
  products: Product[]
}

const ProductsList: React.FC<IProps> = ({products}) => {
  return (
    <>
      <div className='container mt-5 mb-5'>
        <div className='d-flex justify-content-center row'>
          <div className='col-md-10'>
            {products.map((product) => (
              <ProductListDetail key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsList
