/* eslint-disable @typescript-eslint/no-unused-vars */

import * as Yup from 'yup'
import { Alert, Box, Button, H3, Input, Link } from '../../../../components'
import React, { useEffect, useState } from 'react'
import { type UserModel, initialUser } from '../../../../auth/models/User/UserModel'
import { getUserUsersByEmail, saveUserBasic } from '../../../../app/modules/apps/users/core/_requests'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { validateEmail } from '../../../../core/utils/validateEmail'

const userSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
})

interface IProps {
  setSelectedUser: (user: UserModel) => void
}

const CreateUser: React.FC<IProps> = ({ setSelectedUser }) => {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const [showAll, setShowAll] = useState(false)

  const formik = useFormik<UserModel>({
    initialValues: initialUser,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      setLoading(true)
      const user = await saveUserBasic(values)

      if (user != null) {
        formik.setFieldValue('firstName', user.firstName)
        formik.setFieldValue('lastName', user.lastName)
        setSelectedUser(user as unknown as UserModel)
        setShowAll(false)
      }

      setLoading(false)
    },
  })

  // useEffect with debounce
  useEffect(() => {
    const timer = setTimeout(() => {
      if (validateEmail(formik.values.email)) {
        validateUser()
      }
    }, 500)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.email])
  const validateUser = async () => {
    const result = await getUserUsersByEmail(formik.values.email)

    if (result != null) {
      formik.setFieldValue('firstName', result.firstName)
      formik.setFieldValue('lastName', result.lastName)

      setSelectedUser(result as unknown as UserModel)
    } else {
      formik.setFieldValue('firstName', '')
      formik.setFieldValue('lastName', '')
    }

    setShowAll(true)
  }

  return (
    <>
      <Link to='/appointmentsbyconsultant'>
        <Button
          className='btn  btn-primary'
          style={{
            width: '8rem',
            alignSelf: 'end',
            marginRight: '2rem',
            border: 'none',
            marginBottom: '1rem',
            gap: '0.5rem',
          }}
        >
          Regresar
        </Button>
      </Link>
      <h1 style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Create User</h1>

      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='row mb-6'>
          <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
            {intl.formatMessage({ id: 'PROFILE.EMAIL' })}
          </H3>

          <div className='col-lg-8 fv-row'>
            <Input
              className='custom-input'
              type='email'
              placeholder={intl.formatMessage({ id: 'PROFILE.EMAIL' })}
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <Alert variant='danger'>{formik.errors.email}</Alert>
            )}
          </div>
        </div>

        {showAll && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            border: '1px solid black',
            borderRadius: '10px',
            padding: '1rem',
            marginBottom: '1rem'
          }}>
            <div className='row mb-6'>
              <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({ id: 'PROFILE.FIRSTNAME' })}
              </H3>

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  placeholder={intl.formatMessage({ id: 'PROFILE.FIRSTNAME' })}
                  {...formik.getFieldProps('firstName')}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <Alert variant='danger'>{formik.errors.firstName}</Alert>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({ id: 'PROFILE.LASTNAME' })}
              </H3>

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  placeholder={intl.formatMessage({ id: 'PROFILE.LASTNAME' })}
                  {...formik.getFieldProps('lastName')}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <Alert variant='danger'>{formik.errors.lastName}</Alert>
                )}
              </div>
            </div>

            <Button type='submit' disabled={loading}>
              {!loading && intl.formatMessage({ id: 'COMMON.BTNSAVEUSER' })}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'PROFILE.PLEASEWAIT' })}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </Button>
          </div>
        )}
      </form>
    </>
  )
}

export default CreateUser
