/* eslint-disable react/no-unescaped-entities */
interface IProps {
  province: any
}

export const CitySelect: React.FC<IProps> = ({ province }) => {
  return (
    <>
      {province === 'AB' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Acme'}>Acme</option>
          <option value={'Aden'}>Aden</option>
          <option value={'Airdrie'}>Airdrie</option>
          <option value={'Alberta Beach'}>Alberta Beach</option>
          <option value={'Alder Flats'}>Alder Flats</option>
          <option value={'Alexander, AB'}>Alexander, AB</option>
          <option value={'Alhambra, AB'}>Alhambra, AB</option>
          <option value={'Alix'}>Alix</option>
          <option value={'Andrew'}>Andrew</option>
          <option value={'Acadia Valley'}>Acadia Valley</option>
          <option value={'Airdrie'}>Airdrie</option>
          <option value={'Anzac'}>Anzac</option>
          <option value={'Ardmore'}>Ardmore</option>
          <option value={'Ardrossan'}>Ardrossan</option>
          <option value={'Argentia Beach'}>Argentia Beach</option>
          <option value={'Arrowwood'}>Arrowwood</option>
          <option value={'Ashmont'}>Ashmont</option>
          <option value={'Athabasca'}>Athabasca</option>
          <option value={'Balac'}>Balac</option>
          <option value={'Balzac'}>Balzac</option>
          <option value={'Banff'}>Banff</option>
          <option value={'Barnwell'}>Barnwell</option>
          <option value={'Barons'}>Barons</option>
          <option value={'Barrhead'}>Barrhead</option>
          <option value={'Barrons'}>Barrons</option>
          <option value={'Bashaw'}>Bashaw</option>
          <option value={'Bassano'}>Bassano</option>
          <option value={'Bawlf'}>Bawlf</option>
          <option value={'Beaumont'}>Beaumont</option>
          <option value={'Beaver Lodge'}>Beaver Lodge</option>
          <option value={'Beiseker'}>Beiseker</option>
          <option value={'Bellevue'}>Bellevue</option>
          <option value={'Bentley'}>Bentley</option>
          <option value={'Bertula Beach'}>Bertula Beach</option>
          <option value={'Berwyn'}>Berwyn</option>
          <option value={'Big Valley'}>Big Valley</option>
          <option value={'Bitternlake'}>Bitternlake</option>
          <option value={'Blacktoct'}>Blacktoct</option>
          <option value={'Blairmore'}>Blairmore</option>
          <option value={'Blufftir'}>Blufftir</option>
          <option value={'Bluffton'}>Bluffton</option>
          <option value={'Bon Accord'}>Bon Accord</option>
          <option value={'Bonnyville'}>Bonnyville</option>
          <option value={'Bonnyville Beach'}>Bonnyville Beach</option>
          <option value={'Botha'}>Botha</option>
          <option value={'Bow Island'}>Bow Island</option>
          <option value={'Bowden'}>Bowden</option>
          <option value={'Boyle'}>Boyle</option>
          <option value={'Bragg Creek'}>Bragg Creek</option>
          <option value={'Breton'}>Breton</option>
          <option value={'Brooks'}>Brooks</option>
          <option value={'Bruderheim'}>Bruderheim</option>
          <option value={'Buck Lake'}>Buck Lake</option>
          <option value={'Burdett'}>Burdett</option>
          <option value={'Busby'}>Busby</option>
          <option value={'Calgary'}>Calgary</option>
          <option value={'Camrose'}>Camrose</option>
          <option value={'Canmore'}>Canmore</option>
          <option value={'Carbon'}>Carbon</option>
          <option value={'Carbondale'}>Carbondale</option>
          <option value={'Cardiff'}>Cardiff</option>
          <option value={'Cardston'}>Cardston</option>
          <option value={'Carmangay'}>Carmangay</option>
          <option value={'Caroline'}>Caroline</option>
          <option value={'Carstairs'}>Carstairs</option>
          <option value={'Carvel'}>Carvel</option>
          <option value={'Castle Island'}>Castle Island</option>
          <option value={'Castor'}>Castor</option>
          <option value={'Cayley'}>Cayley</option>
          <option value={'Cereal'}>Cereal</option>
          <option value={'Cessford'}>Cessford</option>
          <option value={'Champion'}>Champion</option>
          <option value={'Chateh'}>Chateh</option>
          <option value={'Chauvin'}>Chauvin</option>
          <option value={'Cherhill'}>Cherhill</option>
          <option value={'Chestermere'}>Chestermere</option>
          <option value={'Chief Mountain'}>Chief Mountain</option>
          <option value={'Chinook'}>Chinook</option>
          <option value={'Chipman, AB'}>Chipman, AB</option>
          <option value={'Clairmont, AB'}>Clairmont, AB</option>
          <option value={'Claresholm'}>Claresholm</option>
          <option value={'Clive'}>Clive</option>
          <option value={'Cluny'}>Cluny</option>
          <option value={'Clyde'}>Clyde</option>
          <option value={'Coaldale'}>Coaldale</option>
          <option value={'Coalhurst'}>Coalhurst</option>
          <option value={'Cochrane, AB'}>Cochrane, AB</option>
          <option value={'Cold Lake'}>Cold Lake</option>
          <option value={'Coleman'}>Coleman</option>
          <option value={'College Heights'}>College Heights</option>
          <option value={'Consort'}>Consort</option>
          <option value={'Coronation'}>Coronation</option>
          <option value={'Coutts'}>Coutts</option>
          <option value={'Cowley'}>Cowley</option>
          <option value={'Craigmyle'}>Craigmyle</option>
          <option value={'Cremona'}>Cremona</option>
          <option value={'Crossfield'}>Crossfield</option>
          <option value={'Crystal Srpings'}>Crystal Srpings</option>
          <option value={'Czar'}>Czar</option>
          <option value={'Daysland'}>Daysland</option>
          <option value={'De Winton'}>De Winton</option>
          <option value={'Debolt'}>Debolt</option>
          <option value={'Del Bonita Falls'}>Del Bonita Falls</option>
          <option value={'Delacour'}>Delacour</option>
          <option value={'Delburne'}>Delburne</option>
          <option value={'Delia'}>Delia</option>
          <option value={'Derwent'}>Derwent</option>
          <option value={'Devon'}>Devon</option>
          <option value={'Dewberry'}>Dewberry</option>
          <option value={'Diamond City'}>Diamond City</option>
          <option value={'Didsbury'}>Didsbury</option>
          <option value={'Donalda'}>Donalda</option>
          <option value={'Donnelly'}>Donnelly</option>
          <option value={'Drayton Valley'}>Drayton Valley</option>
          <option value={'Drumheller'}>Drumheller</option>
          <option value={'Duchess'}>Duchess</option>
          <option value={'Dunmore'}>Dunmore</option>
          <option value={'Eaglesham'}>Eaglesham</option>
          <option value={'Eckville'}>Eckville</option>
          <option value={'Edberg'}>Edberg</option>
          <option value={'Edgerton'}>Edgerton</option>
          <option value={'Edmonton'}>Edmonton</option>
          <option value={'Edmonton Beach'}>Edmonton Beach</option>
          <option value={'Edson'}>Edson</option>
          <option value={'Elk Point'}>Elk Point</option>
          <option value={'Elnora'}>Elnora</option>
          <option value={'Empress'}>Empress</option>
          <option value={'Enchant'}>Enchant</option>
          <option value={'Entwistle'}>Entwistle</option>
          <option value={'Erskine'}>Erskine</option>
          <option value={'Evansburg'}>Evansburg</option>
          <option value={'Fairview'}>Fairview</option>
          <option value={'Falher'}>Falher</option>
          <option value={'Faust'}>Faust</option>
          <option value={'Ferintosh'}>Ferintosh</option>
          <option value={'Flatbush'}>Flatbush</option>
          <option value={'Foremost'}>Foremost</option>
          <option value={'Forest Lawn'}>Forest Lawn</option>
          <option value={'Forestburg'}>Forestburg</option>
          <option value={'Fort Assiniboine'}>Fort Assiniboine</option>
          <option value={'Fort Chipewyan'}>Fort Chipewyan</option>
          <option value={'Fort Macleod'}>Fort Macleod</option>
          <option value={'Fort Mcmurray'}>Fort Mcmurray</option>
          <option value={'Fort Saskatchewan'}>Fort Saskatchewan</option>
          <option value={'Fort Vermilion'}>Fort Vermilion</option>
          <option value={'Fox Creek'}>Fox Creek</option>
          <option value={'Frank'}>Frank</option>
          <option value={'Gadsby'}>Gadsby</option>
          <option value={'Galahad'}>Galahad</option>
          <option value={'Ghost Lake'}>Ghost Lake</option>
          <option value={'Gibbons, AB'}>Gibbons, AB</option>
          <option value={'Giroux'}>Giroux</option>
          <option value={'Girouxville, AB'}>Girouxville, AB</option>
          <option value={'Gleichen'}>Gleichen</option>
          <option value={'Glendon'}>Glendon</option>
          <option value={'Glenevis'}>Glenevis</option>
          <option value={'Glenwood, AB'}>Glenwood, AB</option>
          <option value={'Golden Days'}>Golden Days</option>
          <option value={'Good Fish Lake'}>Good Fish Lake</option>
          <option value={'Goodfare'}>Goodfare</option>
          <option value={'Grand Centre'}>Grand Centre</option>
          <option value={'Grande Cache'}>Grande Cache</option>
          <option value={'Grande Prairie'}>Grande Prairie</option>
          <option value={'Grandview, AB'}>Grandview, AB</option>
          <option value={'Granum'}>Granum</option>
          <option value={'Grassland'}>Grassland</option>
          <option value={'Grassy Lake'}>Grassy Lake</option>
          <option value={'Grimshaw'}>Grimshaw</option>
          <option value={'Gull Lake, AB'}>Gull Lake, AB</option>
          <option value={'Hairy Hill'}>Hairy Hill</option>
          <option value={'Halkirk'}>Halkirk</option>
          <option value={'Hanna'}>Hanna</option>
          <option value={'Hardisty'}>Hardisty</option>
          <option value={'Hay Lakes'}>Hay Lakes</option>
          <option value={'Hays'}>Hays</option>
          <option value={'Hayter'}>Hayter</option>
          <option value={'Heisler'}>Heisler</option>
          <option value={'High Level'}>High Level</option>
          <option value={'High Prairie'}>High Prairie</option>
          <option value={'High River'}>High River</option>
          <option value={'Hilda'}>Hilda</option>
          <option value={'Hill Spring'}>Hill Spring</option>
          <option value={'Hilliard'}>Hilliard</option>
          <option value={'Hines Creek'}>Hines Creek</option>
          <option value={'Hinton'}>Hinton</option>
          <option value={'Hobbema'}>Hobbema</option>
          <option value={'Holden'}>Holden</option>
          <option value={'Hughenden'}>Hughenden</option>
          <option value={'Huies Creek'}>Huies Creek</option>
          <option value={'Hussar'}>Hussar</option>
          <option value={'Hythe'}>Hythe</option>
          <option value={'Innisfail'}>Innisfail</option>
          <option value={'Innisfield'}>Innisfield</option>
          <option value={'Innisfree'}>Innisfree</option>
          <option value={'Irma'}>Irma</option>
          <option value={'Iron River'}>Iron River</option>
          <option value={'Iron Springs'}>Iron Springs</option>
          <option value={'Irricana'}>Irricana</option>
          <option value={'Irvine'}>Irvine</option>
          <option value={'Island Lake, AB'}>Island Lake, AB</option>
          <option value={'Itaska Beach'}>Itaska Beach</option>
          <option value={'Janvier'}>Janvier</option>
          <option value={'Jasper'}>Jasper</option>
          <option value={'Kapasiwin'}>Kapasiwin</option>
          <option value={'Keoma'}>Keoma</option>
          <option value={'Killam'}>Killam</option>
          <option value={'Kinuso'}>Kinuso</option>
          <option value={'Kipp'}>Kipp</option>
          <option value={'Kitscoty'}>Kitscoty</option>
          <option value={'La Crete'}>La Crete</option>
          <option value={'La Glace'}>La Glace</option>
          <option value={'Lac La Biche'}>Lac La Biche</option>
          <option value={'Lacombe'}>Lacombe</option>
          <option value={'Lake Louise'}>Lake Louise</option>
          <option value={'Lakeview'}>Lakeview</option>
          <option value={'Lamont'}>Lamont</option>
          <option value={'Langdon'}>Langdon</option>
          <option value={'Lavoy'}>Lavoy</option>
          <option value={'Leduc'}>Leduc</option>
          <option value={'Legal'}>Legal</option>
          <option value={'Leslieville'}>Leslieville</option>
          <option value={'Lethbridge'}>Lethbridge</option>
          <option value={'Linden'}>Linden</option>
          <option value={'Little Smoky'}>Little Smoky</option>
          <option value={'Lloydminster, AB'}>Lloydminster, AB</option>
          <option value={'Lodgepole'}>Lodgepole</option>
          <option value={'Lomond'}>Lomond</option>
          <option value={'Longview'}>Longview</option>
          <option value={'Lougheed'}>Lougheed</option>
          <option value={'Ma Me O Beach'}>Ma Me O Beach</option>
          <option value={'Magrath'}>Magrath</option>
          <option value={'Mallaig'}>Mallaig</option>
          <option value={'Mannville'}>Mannville</option>
          <option value={'Markerville'}>Markerville</option>
          <option value={'Marsden'}>Marsden</option>
          <option value={'Marwayne'}>Marwayne</option>
          <option value={'Mayerthorpe'}>Mayerthorpe</option>
          <option value={'McLennan'}>McLennan</option>
          <option value={'Medicine Hat'}>Medicine Hat</option>
          <option value={'Medley'}>Medley</option>
          <option value={'Meeting Creek'}>Meeting Creek</option>
          <option value={'Midnapore'}>Midnapore</option>
          <option value={'Milk River'}>Milk River</option>
          <option value={'Millet'}>Millet</option>
          <option value={'Milo'}>Milo</option>
          <option value={'Minburn'}>Minburn</option>
          <option value={'Mirror'}>Mirror</option>
          <option value={'Monarch'}>Monarch</option>
          <option value={'Morinville'}>Morinville</option>
          <option value={'Morrin'}>Morrin</option>
          <option value={'Mundare'}>Mundare</option>
          <option value={'Munson'}>Munson</option>
          <option value={'Musidora'}>Musidora</option>
          <option value={'Myrnam'}>Myrnam</option>
          <option value={'Nakamun Park'}>Nakamun Park</option>
          <option value={'Nampa'}>Nampa</option>
          <option value={'Nanton'}>Nanton</option>
          <option value={'Neerlandia'}>Neerlandia</option>
          <option value={'New Dayton'}>New Dayton</option>
          <option value={'New Norway'}>New Norway</option>
          <option value={'New Sarepta'}>New Sarepta</option>
          <option value={'Nisku'}>Nisku</option>
          <option value={'Niton Junction'}>Niton Junction</option>
          <option value={'Nobleford'}>Nobleford</option>
          <option value={'Nordegg'}>Nordegg</option>
          <option value={'Norglenwood'}>Norglenwood</option>
          <option value={'North Star'}>North Star</option>
          <option value={'Nythe'}>Nythe</option>
          <option value={'Okotoks'}>Okotoks</option>
          <option value={'Olds'}>Olds</option>
          <option value={'Onoway'}>Onoway</option>
          <option value={'Other'}>Other</option>
          <option value={'Oyen'}>Oyen</option>
          <option value={'Paradise Valley'}>Paradise Valley</option>
          <option value={'Patricia'}>Patricia</option>
          <option value={'Peace River'}>Peace River</option>
          <option value={'Peers'}>Peers</option>
          <option value={'Penhold'}>Penhold</option>
          <option value={'Pickard Ville'}>Pickard Ville</option>
          <option value={'Picture Butte'}>Picture Butte</option>
          <option value={'Pincher Creek'}>Pincher Creek</option>
          <option value={'Plamondon'}>Plamondon</option>
          <option value={'Point Alison'}>Point Alison</option>
          <option value={'Ponoka'}>Ponoka</option>
          <option value={'Poplar Bay'}>Poplar Bay</option>
          <option value={'Priddis'}>Priddis</option>
          <option value={'Prosperity'}>Prosperity</option>
          <option value={'Provost'}>Provost</option>
          <option value={'Purple Springs'}>Purple Springs</option>
          <option value={'Radway'}>Radway</option>
          <option value={'Rainbow Lake'}>Rainbow Lake</option>
          <option value={'Rainier'}>Rainier</option>
          <option value={'Ralston'}>Ralston</option>
          <option value={'Raymond'}>Raymond</option>
          <option value={'Red Deer'}>Red Deer</option>
          <option value={'Red Deer County'}>Red Deer County</option>
          <option value={'Red Earth Creek'}>Red Earth Creek</option>
          <option value={'Redcliff'}>Redcliff</option>
          <option value={'Redwater'}>Redwater</option>
          <option value={'Redwood Meadowns'}>Redwood Meadowns</option>
          <option value={'Rimbey'}>Rimbey</option>
          <option value={'Rochester'}>Rochester</option>
          <option value={'Rochfort Bridge'}>Rochfort Bridge</option>
          <option value={'Rochon Sands'}>Rochon Sands</option>
          <option value={'Rocky Mountain Ho'}>Rocky Mountain Ho</option>
          <option value={'Rocky View'}>Rocky View</option>
          <option value={'Rockyford'}>Rockyford</option>
          <option value={'Rolling Hills'}>Rolling Hills</option>
          <option value={'Rosalind'}>Rosalind</option>
          <option value={'Rosedale Station'}>Rosedale Station</option>
          <option value={'Rosemary'}>Rosemary</option>
          <option value={'Ross Haven'}>Ross Haven</option>
          <option value={'Rumsey'}>Rumsey</option>
          <option value={'Rycroft'}>Rycroft</option>
          <option value={'Ryley'}>Ryley</option>
          <option value={'Saint-Isidore'}>Saint-Isidore</option>
          <option value={'Saint-Lina'}>Saint-Lina</option>
          <option value={'Saint-Michael'}>Saint-Michael</option>
          <option value={'Sandy Beach, AB'}>Sandy Beach, AB</option>
          <option value={'Sanguda Silver Sa'}>Sanguda Silver Sa</option>
          <option value={'Scandia'}>Scandia</option>
          <option value={'Seba Beach'}>Seba Beach</option>
          <option value={'Sedgewick'}>Sedgewick</option>
          <option value={'Sexsmith'}>Sexsmith</option>
          <option value={'Shaughnessy'}>Shaughnessy</option>
          <option value={'Sherwood Park, AB'}>Sherwood Park, AB</option>
          <option value={'Siksika'}>Siksika</option>
          <option value={'Silver Beach'}>Silver Beach</option>
          <option value={'Slave Lake'}>Slave Lake</option>
          <option value={'Smoky Lake'}>Smoky Lake</option>
          <option value={'South View'}>South View</option>
          <option value={'Spirit River'}>Spirit River</option>
          <option value={'Spring Lake'}>Spring Lake</option>
          <option value={'Spruce Grove'}>Spruce Grove</option>
          <option value={'Spruceview'}>Spruceview</option>
          <option value={'St Albert'}>St Albert</option>
          <option value={'St. Paul'}>St. Paul</option>
          <option value={'St-Albert Falls'}>St-Albert Falls</option>
          <option value={'Standard'}>Standard</option>
          <option value={'Stavely'}>Stavely</option>
          <option value={'Stettler'}>Stettler</option>
          <option value={'Stirling, AB'}>Stirling, AB</option>
          <option value={'Stony Plain'}>Stony Plain</option>
          <option value={'Strathcona'}>Strathcona</option>
          <option value={'Strathmore'}>Strathmore</option>
          <option value={'Strome'}>Strome</option>
          <option value={'Sturgeon'}>Sturgeon</option>
          <option value={'Suffield'}>Suffield</option>
          <option value={'Sundance Beach'}>Sundance Beach</option>
          <option value={'Sundre'}>Sundre</option>
          <option value={'Sunset House'}>Sunset House</option>
          <option value={'Sunset Point'}>Sunset Point</option>
          <option value={'Swan Hills'}>Swan Hills</option>
          <option value={'Sylvan Lake'}>Sylvan Lake</option>
          <option value={'Taber'}>Taber</option>
          <option value={'Tangent'}>Tangent</option>
          <option value={'Thorhild'}>Thorhild</option>
          <option value={'Thorsby'}>Thorsby</option>
          <option value={'Three Hills'}>Three Hills</option>
          <option value={'Tilley'}>Tilley</option>
          <option value={'Tofield'}>Tofield</option>
          <option value={'Tomahawk'}>Tomahawk</option>
          <option value={'Torrington'}>Torrington</option>
          <option value={'Trochu'}>Trochu</option>
          <option value={'Turin'}>Turin</option>
          <option value={'Turner Valley'}>Turner Valley</option>
          <option value={'Two Hills'}>Two Hills</option>
          <option value={'Val Quentin'}>Val Quentin</option>
          <option value={'Valleyview'}>Valleyview</option>
          <option value={'Vauxhall'}>Vauxhall</option>
          <option value={'Vegreville'}>Vegreville</option>
          <option value={'Vermilion'}>Vermilion</option>
          <option value={'Veteran'}>Veteran</option>
          <option value={'Viking'}>Viking</option>
          <option value={'Vilna'}>Vilna</option>
          <option value={'Vimy'}>Vimy</option>
          <option value={'Vulcan'}>Vulcan</option>
          <option value={'Wabamun'}>Wabamun</option>
          <option value={'Wabas-Desmar'}>Wabas-Desmar</option>
          <option value={'Wainwright'}>Wainwright</option>
          <option value={'Wanham'}>Wanham</option>
          <option value={'Warburg'}>Warburg</option>
          <option value={'Warner'}>Warner</option>
          <option value={'Warspite'}>Warspite</option>
          <option value={'Wasca'}>Wasca</option>
          <option value={'Waskatenau'}>Waskatenau</option>
          <option value={'Water Valley'}>Water Valley</option>
          <option value={'Waterton'}>Waterton</option>
          <option value={'Waterton Lakes'}>Waterton Lakes</option>
          <option value={'Watino'}>Watino</option>
          <option value={'Welling'}>Welling</option>
          <option value={'Wembly'}>Wembly</option>
          <option value={'West Cove'}>West Cove</option>
          <option value={'Westerose'}>Westerose</option>
          <option value={'Westlock'}>Westlock</option>
          <option value={'Wetaskiwin'}>Wetaskiwin</option>
          <option value={'Whitecourt'}>Whitecourt</option>
          <option value={'Whitelaw'}>Whitelaw</option>
          <option value={'Wild Horse'}>Wild Horse</option>
          <option value={'Wildwood'}>Wildwood</option>
          <option value={'Willingdon'}>Willingdon</option>
          <option value={'Winfield, AB'}>Winfield, AB</option>
          <option value={'Winterburn'}>Winterburn</option>
          <option value={'Woking'}>Woking</option>
          <option value={'Yellowstone'}>Yellowstone</option>
          <option value={'Youngstown'}>Youngstown</option>
          <option value={'Zama'}>Zama</option>
        </>
      }
      {province === 'BC' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'100 Mile House'}>100 Mile House</option>
          <option value={'108 Mile Ranch'}>108 Mile Ranch</option>
          <option value={'150 Mile House'}>150 Mile House</option>
          <option value={'70 Mile House'}>70 Mile House</option>
          <option value={'Abbotsford'}>Abbotsford</option>
          <option value={'Agassiz'}>Agassiz</option>
          <option value={'Alberni'}>Alberni</option>
          <option value={'Albion'}>Albion</option>
          <option value={'Aldergrove'}>Aldergrove</option>
          <option value={'Alert Bay'}>Alert Bay</option>
          <option value={'Alexis Creek'}>Alexis Creek</option>
          <option value={'Alvin'}>Alvin</option>
          <option value={'Anahim Lk.'}>Anahim Lk.</option>
          <option value={'Anglemont'}>Anglemont</option>
          <option value={'Anmore, BC'}>Anmore, BC</option>
          <option value={'Argenta'}>Argenta</option>
          <option value={'Armstrong, BC'}>Armstrong, BC</option>
          <option value={'Arras'}>Arras</option>
          <option value={'Ashcroft'}>Ashcroft</option>
          <option value={'Atlin'}>Atlin</option>
          <option value={'Baldonnel'}>Baldonnel</option>
          <option value={'Balfour'}>Balfour</option>
          <option value={'Bamfield'}>Bamfield</option>
          <option value={'Barnston Isl'}>Barnston Isl</option>
          <option value={'Barriere'}>Barriere</option>
          <option value={'Beach Grove'}>Beach Grove</option>
          <option value={'Beaver Creek'}>Beaver Creek</option>
          <option value={'Beaverdell'}>Beaverdell</option>
          <option value={'Belcarra'}>Belcarra</option>
          <option value={'Bella Bella'}>Bella Bella</option>
          <option value={'Bella Coola'}>Bella Coola</option>
          <option value={'Big Creek'}>Big Creek</option>
          <option value={'Big Lake'}>Big Lake</option>
          <option value={'Black Creek'}>Black Creek</option>
          <option value={'Blind Bay'}>Blind Bay</option>
          <option value={'Blue River'}>Blue River</option>
          <option value={'Boston Bar'}>Boston Bar</option>
          <option value={'Boswell, BC'}>Boswell, BC</option>
          <option value={'Boundry Bay'}>Boundry Bay</option>
          <option value={'Bowen Island'}>Bowen Island</option>
          <option value={'Bowser'}>Bowser</option>
          <option value={'Brackendale'}>Brackendale</option>
          <option value={'Brentwood Bay'}>Brentwood Bay</option>
          <option value={'Bridesville'}>Bridesville</option>
          <option value={'Bridge Lake'}>Bridge Lake</option>
          <option value={'Brisco'}>Brisco</option>
          <option value={'Britannia Beach'}>Britannia Beach</option>
          <option value={'Bubble Hill'}>Bubble Hill</option>
          <option value={'Buffalo Creek'}>Buffalo Creek</option>
          <option value={'Buick'}>Buick</option>
          <option value={'Bull River'}>Bull River</option>
          <option value={'Burnaby'}>Burnaby</option>
          <option value={'Burns Lake'}>Burns Lake</option>
          <option value={'Burrard Inlet'}>Burrard Inlet</option>
          <option value={'Burton'}>Burton</option>
          <option value={'Cache Creek'}>Cache Creek</option>
          <option value={'Campbell River'}>Campbell River</option>
          <option value={'Canal Flats'}>Canal Flats</option>
          <option value={'Canim Lake'}>Canim Lake</option>
          <option value={'Canoe'}>Canoe</option>
          <option value={'Canyon'}>Canyon</option>
          <option value={'Capiland'}>Capiland</option>
          <option value={'Caribou'}>Caribou</option>
          <option value={'Carson'}>Carson</option>
          <option value={'Cascade'}>Cascade</option>
          <option value={'Cassiar'}>Cassiar</option>
          <option value={'Cassidy'}>Cassidy</option>
          <option value={'Castlegar'}>Castlegar</option>
          <option value={'Cawston'}>Cawston</option>
          <option value={'Cecil Lake'}>Cecil Lake</option>
          <option value={'Cedar'}>Cedar</option>
          <option value={'Celista'}>Celista</option>
          <option value={'Central Saanich'}>Central Saanich</option>
          <option value={'Charlie Lake'}>Charlie Lake</option>
          <option value={'Chase'}>Chase</option>
          <option value={'Chemainus'}>Chemainus</option>
          <option value={'Chetwynd'}>Chetwynd</option>
          <option value={'Chilanko Forks'}>Chilanko Forks</option>
          <option value={'Chilcotin'}>Chilcotin</option>
          <option value={'Chilko Lake'}>Chilko Lake</option>
          <option value={'Chilliwack'}>Chilliwack</option>
          <option value={'Chopaka'}>Chopaka</option>
          <option value={'Christina Lake'}>Christina Lake</option>
          <option value={'Clearbrook'}>Clearbrook</option>
          <option value={'Clearwater, BC'}>Clearwater, BC</option>
          <option value={'Clements'}>Clements</option>
          <option value={'Clinton, BC'}>Clinton, BC</option>
          <option value={'Cloverdale'}>Cloverdale</option>
          <option value={'Coal Harbour'}>Coal Harbour</option>
          <option value={'Cobble Hill'}>Cobble Hill</option>
          <option value={'Coldstream'}>Coldstream</option>
          <option value={'Columbia Gardens'}>Columbia Gardens</option>
          <option value={'Colwood'}>Colwood</option>
          <option value={'Comox'}>Comox</option>
          <option value={'Coombs'}>Coombs</option>
          <option value={'Coquitlam'}>Coquitlam</option>
          <option value={'Cortes Island'}>Cortes Island</option>
          <option value={'Courtenay'}>Courtenay</option>
          <option value={'Cowichan Bay'}>Cowichan Bay</option>
          <option value={'Cranbrook'}>Cranbrook</option>
          <option value={'Crescent Beach'}>Crescent Beach</option>
          <option value={'Creston, BC'}>Creston, BC</option>
          <option value={'Crofton, BC'}>Crofton, BC</option>
          <option value={'Cultus Lake'}>Cultus Lake</option>
          <option value={'Cumberland, BC'}>Cumberland, BC</option>
          <option value="D'Arcy">D'Arcy</option>
          <option value="Dawson Creek, BC">Dawson Creek, BC</option>
          <option value="Dease Lake">Dease Lake</option>
          <option value="Deep Bay">Deep Bay</option>
          <option value="Delta">Delta</option>
          <option value="Denmen Island">Denmen Island</option>
          <option value="Deroche">Deroche</option>
          <option value="Dewdeny">Dewdeny</option>
          <option value="Douglas">Douglas</option>
          <option value="Duncan">Duncan</option>
          <option value="Dunster">Dunster</option>
          <option value="Eagle Bay, BC">Eagle Bay, BC</option>
          <option value="Eagle Creek">Eagle Creek</option>
          <option value="East Pine">East Pine</option>
          <option value="Ebrune">Ebrune</option>
          <option value="Edgewater">Edgewater</option>
          <option value="Edgewood">Edgewood</option>
          <option value="Elkford">Elkford</option>
          <option value="Elko">Elko</option>
          <option value="Endako">Endako</option>
          <option value="Enderby">Enderby</option>
          <option value="Erickson, BC">Erickson, BC</option>
          <option value="Erikson">Erikson</option>
          <option value="Errington">Errington</option>
          <option value="Esquimalt">Esquimalt</option>
          <option value="Fair. Hot Springs">Fair. Hot Springs</option>
          <option value="Falkland">Falkland</option>
          <option value="Fanny Bay">Fanny Bay</option>
          <option value="Fauquier">Fauquier</option>
          <option value="Femberton">Femberton</option>
          <option value="Fernie">Fernie</option>
          <option value="Forest Grove, BC">Forest Grove, BC</option>
          <option value="Fort Fraser">Fort Fraser</option>
          <option value="Fort Langley">Fort Langley</option>
          <option value="Fort Nelson">Fort Nelson</option>
          <option value="Fort St. James">Fort St. James</option>
          <option value="Fort St. John">Fort St. John</option>
          <option value="Fort Steele">Fort Steele</option>
          <option value="Francois Lake">Francois Lake</option>
          <option value="Fraser Lake">Fraser Lake</option>
          <option value="Fraser Mills">Fraser Mills</option>
          <option value="Fraser Valley">Fraser Valley</option>
          <option value="Fruitvale">Fruitvale</option>
          <option value="Fulford Harbor">Fulford Harbor</option>
          <option value="Furry Creek">Furry Creek</option>
          <option value="Gabriola">Gabriola</option>
          <option value="Galiano Island">Galiano Island</option>
          <option value="Ganges">Ganges</option>
          <option value="Gar. Highlands">Gar. Highlands</option>
          <option value="Garden Bay">Garden Bay</option>
          <option value="Garibaldi">Garibaldi</option>
          <option value="Genelle">Genelle</option>
          <option value="Gibsons, BC">Gibsons, BC</option>
          <option value="Gibsons">Gibsons</option>
          <option value="Gillies Bay">Gillies Bay</option>
          <option value="Gold River">Gold River</option>
          <option value="Golden">Golden</option>
          <option value="Goodlow">Goodlow</option>
          <option value="Gr Birch">Gr Birch</option>
          <option value="Grand Forks">Grand Forks</option>
          <option value="Granisle">Granisle</option>
          <option value="Grasmere">Grasmere</option>
          <option value="Gray Creek">Gray Creek</option>
          <option value="Greenwood, BC">Greenwood, BC</option>
          <option value="Grindrod">Grindrod</option>
          <option value="Hagensborg">Hagensborg</option>
          <option value="Halfmoon Bay">Halfmoon Bay</option>
          <option value="Hammond">Hammond</option>
          <option value="Hanceville">Hanceville</option>
          <option value="Haney">Haney</option>
          <option value="Harrison Hot Spri">Harrison Hot Spri</option>
          <option value="Harrison Mills">Harrison Mills</option>
          <option value="Hazelton">Hazelton</option>
          <option value="Hedley">Hedley</option>
          <option value="Heffley Creek">Heffley Creek</option>
          <option value="Heriot Bay, BC">Heriot Bay, BC</option>
          <option value="Highlands">Highlands</option>
          <option value="Hixon">Hixon</option>
          <option value="Holberg">Holberg</option>
          <option value="Hope">Hope</option>
          <option value="Hornby Island">Hornby Island</option>
          <option value="Horsefly">Horsefly</option>
          <option value="Horseshoe Bay">Horseshoe Bay</option>
          <option value="Houston">Houston</option>
          <option value="Huds Hop">Huds Hop</option>
          <option value="Huntingdon, BC">Huntingdon, BC</option>
          <option value="Invermere">Invermere</option>
          <option value="Inverness, BC">Inverness, BC</option>
          <option value="Island Highway">Island Highway</option>
          <option value="Jaffray">Jaffray</option>
          <option value="Kalendan">Kalendan</option>
          <option value="Kamloops">Kamloops</option>
          <option value="Kaslo">Kaslo</option>
          <option value="Katzie">Katzie</option>
          <option value="Kelowna">Kelowna</option>
          <option value="Keremeos">Keremeos</option>
          <option value="Kimberley">Kimberley</option>
          <option value="Kingsgate">Kingsgate</option>
          <option value="Kinnaird">Kinnaird</option>
          <option value="Kitimat">Kitimat</option>
          <option value="Kitwanga">Kitwanga</option>
          <option value="Kleene Kleene">Kleene Kleene</option>
          <option value="Kootenay Bay">Kootenay Bay</option>
          <option value="La Hache">La Hache</option>
          <option value="Ladner">Ladner</option>
          <option value="Ladysmith">Ladysmith</option>
          <option value="Lake Country">Lake Country</option>
          <option value="Lake Cowichan">Lake Cowichan</option>
          <option value="Langley">Langley</option>
          <option value="Lantzville">Lantzville</option>
          <option value="Lasqueti Island">Lasqueti Island</option>
          <option value="Latham">Latham</option>
          <option value="Laurier">Laurier</option>
          <option value="Lazo">Lazo</option>
          <option value="Lehman">Lehman</option>
          <option value="Lillooet">Lillooet</option>
          <option value="Lindell Beach">Lindell Beach</option>
          <option value="Lion's Bay">Lion's Bay</option>
          <option value="Lister">Lister</option>
          <option value="Little Fort">Little Fort</option>
          <option value="Logan Lake">Logan Lake</option>
          <option value="Lone Butte">Lone Butte</option>
          <option value="Lower Nicola">Lower Nicola</option>
          <option value="Lumby">Lumby</option>
          <option value="Lund">Lund</option>
          <option value="Lytton">Lytton</option>
          <option value="Mackenzie">Mackenzie</option>
          <option value="Madeira Park">Madeira Park</option>
          <option value="Malahat">Malahat</option>
          <option value="Malakwa">Malakwa</option>
          <option value="Mansos Landing">Mansos Landing</option>
          <option value="Maple Ridge">Maple Ridge</option>
          <option value="Mara">Mara</option>
          <option value="Marysville, BC">Marysville, BC</option>
          <option value="Masset">Masset</option>
          <option value="Matsqui">Matsqui</option>
          <option value="Mayne Lake">Mayne Lake</option>
          <option value="Mc Millian Isl">Mc Millian Isl</option>
          <option value="McBride">McBride</option>
          <option value="McLeese Lake">McLeese Lake</option>
          <option value="Meadow Creek">Meadow Creek</option>
          <option value="Merritt">Merritt</option>
          <option value="Merville">Merville</option>
          <option value="Midway">Midway</option>
          <option value="Milhouse">Milhouse</option>
          <option value="Mill Bay">Mill Bay</option>
          <option value="Mission">Mission</option>
          <option value="Moberly Lake">Moberly Lake</option>
          <option value="Montney">Montney</option>
          <option value="Montrose">Montrose</option>
          <option value="Moyle Lake">Moyle Lake</option>
          <option value="Nakusp">Nakusp</option>
          <option value="Nanaimo">Nanaimo</option>
          <option value="Nanoose Bay">Nanoose Bay</option>
          <option value="Naramata">Naramata</option>
          <option value="Nelson, BC">Nelson, BC</option>
          <option value="Nelway">Nelway</option>
          <option value="New Aiyansh">New Aiyansh</option>
          <option value="New Denver">New Denver</option>
          <option value="New Hazelton">New Hazelton</option>
          <option value="New Westminster">New Westminster</option>
          <option value="Nig Creek">Nig Creek</option>
          <option value="Nimpo">Nimpo</option>
          <option value="North Delta">North Delta</option>
          <option value="North Saanich">North Saanich</option>
          <option value="North Vancouver">North Vancouver</option>
          <option value="Oak Bay, BC">Oak Bay, BC</option>
          <option value="Ocean Falls">Ocean Falls</option>
          <option value="Okanagan">Okanagan</option>
          <option value="Okanagan Falls">Okanagan Falls</option>
          <option value="Oliver">Oliver</option>
          <option value="Osoyoos">Osoyoos</option>
          <option value="Other">Other</option>
          <option value="Oyama">Oyama</option>
          <option value="Panorama">Panorama</option>
          <option value="Parksville">Parksville</option>
          <option value="Paterson">Paterson</option>
          <option value="Peachland">Peachland</option>
          <option value="Pemberton">Pemberton</option>
          <option value="Pender Island">Pender Island</option>
          <option value="Penticton">Penticton</option>
          <option value="Pink Mountain">Pink Mountain</option>
          <option value="Pitt Meadows">Pitt Meadows</option>
          <option value="Pleasant Camp">Pleasant Camp</option>
          <option value="Point Roberts">Point Roberts</option>
          <option value={'Port Alberni'}>Port Alberni</option>
          <option value={'Port Alice'}>Port Alice</option>
          <option value={'Port Clements'}>Port Clements</option>
          <option value={'Port Coquitlam'}>Port Coquitlam</option>
          <option value={'Port Edward'}>Port Edward</option>
          <option value={'Port Hardy'}>Port Hardy</option>
          <option value={'Port Hawksbury'}>Port Hawksbury</option>
          <option value={'Port McNeill'}>Port McNeill</option>
          <option value={'Port Moody'}>Port Moody</option>
          <option value={'Pouce Coupe'}>Pouce Coupe</option>
          <option value={'Powell River'}>Powell River</option>
          <option value={'Prince George'}>Prince George</option>
          <option value={'Prince Rupert'}>Prince Rupert</option>
          <option value={'Princeton, BC'}>Princeton, BC</option>
          <option value={'Pritchard'}>Pritchard</option>
          <option value={'Proctor'}>Proctor</option>
          <option value={'Quadra Island'}>Quadra Island</option>
          <option value={'Qualicum Beach'}>Qualicum Beach</option>
          <option value={'Quathiaski Cove'}>Quathiaski Cove</option>
          <option value={'Quatsino'}>Quatsino</option>
          <option value={'Queen Charlotte'}>Queen Charlotte</option>
          <option value={'Quesnel'}>Quesnel</option>
          <option value={'Quilchena'}>Quilchena</option>
          <option value={'Radium Hot Springs'}>Radium Hot Springs</option>
          <option value={'Revelstoke'}>Revelstoke</option>
          <option value={'Richmond, BC'}>Richmond, BC</option>
          <option value={'Robert C'}>Robert C</option>
          <option value={'Robson'}>Robson</option>
          <option value={'Rock Creek'}>Rock Creek</option>
          <option value={'Roosville'}>Roosville</option>
          <option value={'Rossland'}>Rossland</option>
          <option value={'Royston'}>Royston</option>
          <option value={'Ruskin'}>Ruskin</option>
          <option value={'Rykerts'}>Rykerts</option>
          <option value={'Saanich'}>Saanich</option>
          <option value={'Saanichton'}>Saanichton</option>
          <option value={'Salmo'}>Salmo</option>
          <option value={'Salmon Arm'}>Salmon Arm</option>
          <option value={'Salt Spring Isl.'}>Salt Spring Isl.</option>
          <option value={'Sandspit'}>Sandspit</option>
          <option value={'Sardis'}>Sardis</option>
          <option value={'Saturna Island'}>Saturna Island</option>
          <option value={'Savona'}>Savona</option>
          <option value={'Sayward'}>Sayward</option>
          <option value={'Seal Cove, BC'}>Seal Cove, BC</option>
          <option value={'Sechelt'}>Sechelt</option>
          <option value={'Semiahmoo'}>Semiahmoo</option>
          <option value={'Sewell Inlet'}>Sewell Inlet</option>
          <option value={'Shawnigan Lake'}>Shawnigan Lake</option>
          <option value={'Shuswap'}>Shuswap</option>
          <option value={'Sicamous'}>Sicamous</option>
          <option value={'Sidney'}>Sidney</option>
          <option value={'Silverton'}>Silverton</option>
          <option value={'Skidegate'}>Skidegate</option>
          <option value={'Skookumchuck'}>Skookumchuck</option>
          <option value={'Slocan'}>Slocan</option>
          <option value={'Smithers'}>Smithers</option>
          <option value={'Sointula'}>Sointula</option>
          <option value={'Sooke'}>Sooke</option>
          <option value={'Sorrento'}>Sorrento</option>
          <option value={'South Fort George'}>South Fort George</option>
          <option value={'Sparwood'}>Sparwood</option>
          <option value={'Spences Bridge'}>Spences Bridge</option>
          <option value={'Squamish'}>Squamish</option>
          <option value={'Stave Falls'}>Stave Falls</option>
          <option value={'Stewart'}>Stewart</option>
          <option value={'Stikine'}>Stikine</option>
          <option value={'Sullivan Bay'}>Sullivan Bay</option>
          <option value={'Summerland'}>Summerland</option>
          <option value={'Sun Peaks'}>Sun Peaks</option>
          <option value={'Surge Narrows'}>Surge Narrows</option>
          <option value={'Surrento'}>Surrento</option>
          <option value={'Surrey, BC'}>Surrey, BC</option>
          <option value={'Ta Ta Creek'}>Ta Ta Creek</option>
          <option value={'Tahsis'}>Tahsis</option>
          <option value={'Tappen'}>Tappen</option>
          <option value={'Tasu'}>Tasu</option>
          <option value={'Tatla Lake'}>Tatla Lake</option>
          <option value={'Taylor'}>Taylor</option>
          <option value={'Telegraph Creek'}>Telegraph Creek</option>
          <option value={'Telkwa'}>Telkwa</option>
          <option value={'Terrace'}>Terrace</option>
          <option value={'Tête Jaune Cache'}>Tête Jaune Cache</option>
          <option value={'Thrums'}>Thrums</option>
          <option value={'Thetis Island'}>Thetis Island</option>
          <option value={'Thompson, BC'}>Thompson, BC</option>
          <option value={"T'Kumlups"}>T'Kumlups</option>
          <option value={'Tlell'}>Tlell</option>
          <option value={'Tofino'}>Tofino</option>
          <option value={'Trail'}>Trail</option>
          <option value={'Tsawwassen'}>Tsawwassen</option>
          <option value={'Tsay Keh'}>Tsay Keh</option>
          <option value={'Tumbler'}>Tumbler</option>
          <option value={'Tumbler Ridge'}>Tumbler Ridge</option>
          <option value={'Ucluelet'}>Ucluelet</option>
          <option value={'Union Bay'}>Union Bay</option>
          <option value={'Valemount'}>Valemount</option>
          <option value={'Vananda'}>Vananda</option>
          <option value={'Vancouver'}>Vancouver</option>
          <option value={'Vanderhoof'}>Vanderhoof</option>
          <option value={'Vavenby'}>Vavenby</option>
          <option value={'Vernon'}>Vernon</option>
          <option value={'Victoria, BC'}>Victoria, BC</option>
          <option value={'Waglisla, BC'}>Waglisla, BC</option>
          <option value={'Waneta'}>Waneta</option>
          <option value={'Warfield'}>Warfield</option>
          <option value={'Wasa Lake'}>Wasa Lake</option>
          <option value={"Webster's Corner"}>Webster's Corner</option>
          <option value={'Wells'}>Wells</option>
          <option value={'West Vancouver'}>West Vancouver</option>
          <option value={'Westbank'}>Westbank</option>
          <option value={'Westbridge'}>Westbridge</option>
          <option value={'Westminister'}>Westminister</option>
          <option value={'Westwold'}>Westwold</option>
          <option value={'Whaletown'}>Whaletown</option>
          <option value={'Whistler'}>Whistler</option>
          <option value={'White Rock'}>White Rock</option>
          <option value={'Whonnock'}>Whonnock</option>
          <option value={'Williams Lake'}>Williams Lake</option>
          <option value={'Willow River'}>Willow River</option>
          <option value={'Windermere'}>Windermere</option>
          <option value={'Winfield, BC'}>Winfield, BC</option>
          <option value={'Winlaw'}>Winlaw</option>
          <option value={'Wonowon'}>Wonowon</option>
          <option value={'Woss'}>Woss</option>
          <option value={'Wynndel'}>Wynndel</option>
          <option value={'Yahk'}>Yahk</option>
          <option value={'Yale'}>Yale</option>
          <option value={'Yarrow'}>Yarrow</option>
          <option value={'Ymir'}>Ymir</option>
          <option value={'Youbou'}>Youbou</option>
          <option value={'Zeballos'}>Zeballos</option>
        </>
      }
      {province === 'MB' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Alexander, MB'}>Alexander, MB</option>
          <option value={'Altona'}>Altona</option>
          <option value={'Anola, MB'}>Anola, MB</option>
          <option value={'Arborg'}>Arborg</option>
          <option value={'Arden'}>Arden</option>
          <option value={'Arnaud'}>Arnaud</option>
          <option value={'Arnes'}>Arnes</option>
          <option value={'Ashern'}>Ashern</option>
          <option value={'Ashville'}>Ashville</option>
          <option value={'Austin'}>Austin</option>
          <option value={'Bagot'}>Bagot</option>
          <option value={'Baldur'}>Baldur</option>
          <option value={'Balmoral'}>Balmoral</option>
          <option value={'Barrows'}>Barrows</option>
          <option value={'Beausejour'}>Beausejour</option>
          <option value={'Belmont, MB'}>Belmont, MB</option>
          <option value={'Benito'}>Benito</option>
          <option value={'Binscarth'}>Binscarth</option>
          <option value={'Birtle'}>Birtle</option>
          <option value={'Bloodvein River'}>Bloodvein River</option>
          <option value={'Blumenort'}>Blumenort</option>
          <option value={'Boissevain'}>Boissevain</option>
          <option value={'Bowsman'}>Bowsman</option>
          <option value={'Brandon'}>Brandon</option>
          <option value={'Brunkild'}>Brunkild</option>
          <option value={'Bruxelles'}>Bruxelles</option>
          <option value={'Carberry'}>Carberry</option>
          <option value={'Carman'}>Carman</option>
          <option value={'Cartier'}>Cartier</option>
          <option value={'Cartwright'}>Cartwright</option>
          <option value={'Churchill'}>Churchill</option>
          <option value={'Clandeboye'}>Clandeboye</option>
          <option value={'Coulter'}>Coulter</option>
          <option value={'Cross Lake'}>Cross Lake</option>
          <option value={'Crystal City'}>Crystal City</option>
          <option value={'Dauphin'}>Dauphin</option>
          <option value={'Decker'}>Decker</option>
          <option value={'Deloraine'}>Deloraine</option>
          <option value={'Dominion City'}>Dominion City</option>
          <option value={'Dufresne'}>Dufresne</option>
          <option value={'Dugald, MB'}>Dugald, MB</option>
          <option value={'Dunnottar'}>Dunnottar</option>
          <option value={'Durban'}>Durban</option>
          <option value={'East Kildonan'}>East Kildonan</option>
          <option value={'East Landmark'}>East Landmark</option>
          <option value={'East St Paul'}>East St Paul</option>
          <option value={'Elgin, MB'}>Elgin, MB</option>
          <option value={'Elie'}>Elie</option>
          <option value={'Elkhorn'}>Elkhorn</option>
          <option value={'Elma'}>Elma</option>
          <option value={'Elmer'}>Elmer</option>
          <option value={'Emerson'}>Emerson</option>
          <option value={'Erickson, MB'}>Erickson, MB</option>
          <option value={'Eriksdale'}>Eriksdale</option>
          <option value={'Ethelbert'}>Ethelbert</option>
          <option value={'Fisher Branch'}>Fisher Branch</option>
          <option value={'Flin Flon'}>Flin Flon</option>
          <option value={'Gardenhill'}>Gardenhill</option>
          <option value={'Garson, MB'}>Garson, MB</option>
          <option value={'Gilbert Plains'}>Gilbert Plains</option>
          <option value={'Gillam'}>Gillam</option>
          <option value={'Gimli'}>Gimli</option>
          <option value={'Gladstone'}>Gladstone</option>
          <option value={'Glenboro'}>Glenboro</option>
          <option value={'Goodlands'}>Goodlands</option>
          <option value={'Grand Rapids'}>Grand Rapids</option>
          <option value={'Grande Pointe'}>Grande Pointe</option>
          <option value={'Grandview, MB'}>Grandview, MB</option>
          <option value={'Graysville'}>Graysville</option>
          <option value={'Great Falls'}>Great Falls</option>
          <option value={'Gretna'}>Gretna</option>
          <option value={'Grosse Isle'}>Grosse Isle</option>
          <option value={'Grunthal'}>Grunthal</option>
          <option value={'Gypsumville'}>Gypsumville</option>
          <option value={'Hadashville'}>Hadashville</option>
          <option value={'Hamilton, MB'}>Hamilton, MB</option>
          <option value={'Hamiota'}>Hamiota</option>
          <option value={'Hartney'}>Hartney</option>
          <option value={'Haywood'}>Haywood</option>
          <option value={'Headingley'}>Headingley</option>
          <option value={'Hilbre'}>Hilbre</option>
          <option value={'Holland'}>Holland</option>
          <option value={'Horndean'}>Horndean</option>
          <option value={'Hudgson'}>Hudgson</option>
          <option value={'Île-des-Chênes'}>Île-des-Chênes</option>
          <option value={'Island Lake, MB'}>Island Lake, MB</option>
          <option value={'Kelwood'}>Kelwood</option>
          <option value={'Kenton'}>Kenton</option>
          <option value={'Kenville, MB'}>Kenville, MB</option>
          <option value={'Killarney'}>Killarney</option>
          <option value={'Kirkella'}>Kirkella</option>
          <option value={'Kleefeld'}>Kleefeld</option>
          <option value={'Kola'}>Kola</option>
          <option value={'Komarno'}>Komarno</option>
          <option value={'La Broquerie, MB'}>La Broquerie, MB</option>
          <option value={'La Salle, MB'}>La Salle, MB</option>
          <option value={'Lac du Bonnet'}>Lac du Bonnet</option>
          <option value={'Landmark'}>Landmark</option>
          <option value={'La-Riviere'}>La-Riviere</option>
          <option value={'Leaf Rapids'}>Leaf Rapids</option>
          <option value={'Lena'}>Lena</option>
          <option value={'Libau'}>Libau</option>
          <option value={'Lindenwoods'}>Lindenwoods</option>
          <option value={'Lockport'}>Lockport</option>
          <option value={'Lorette, MB'}>Lorette, MB</option>
          <option value={'Lowe Farm'}>Lowe Farm</option>
          <option value={'Lundar'}>Lundar</option>
          <option value={'Lyleton'}>Lyleton</option>
          <option value={'Lynn Lake'}>Lynn Lake</option>
          <option value={'MacGregor'}>MacGregor</option>
          <option value={'Manitou'}>Manitou</option>
          <option value={'Manson, MB'}>Manson, MB</option>
          <option value={'Marchand'}>Marchand</option>
          <option value={'Marquette'}>Marquette</option>
          <option value={'Matlock'}>Matlock</option>
          <option value={'McAuley'}>McAuley</option>
          <option value={'McCreary'}>McCreary</option>
          <option value={'Melita'}>Melita</option>
          <option value={'Miami'}>Miami</option>
          <option value={'Middlebro'}>Middlebro</option>
          <option value={'Miniolta'}>Miniolta</option>
          <option value={'Minitonas'}>Minitonas</option>
          <option value={'Minnedosa'}>Minnedosa</option>
          <option value={'Minto, MB'}>Minto, MB</option>
          <option value={'Mitchell, MB'}>Mitchell, MB</option>
          <option value={'Moar Lake'}>Moar Lake</option>
          <option value={'Morden'}>Morden</option>
          <option value={'Morris'}>Morris</option>
          <option value={'Napinka'}>Napinka</option>
          <option value={'Neepawa'}>Neepawa</option>
          <option value={'New Bothwell'}>New Bothwell</option>
          <option value={'Newdale'}>Newdale</option>
          <option value={'Ninette'}>Ninette</option>
          <option value={'Niverville'}>Niverville</option>
          <option value={'North Kildonan'}>North Kildonan</option>
          <option value={'Norway House'}>Norway House</option>
          <option value={'Notre Dame de Lou'}>Notre Dame de Lou</option>
          <option value={'Oak Lake'}>Oak Lake</option>
          <option value={'Oakbank, MB'}>Oakbank, MB</option>
          <option value={'Onanole'}>Onanole</option>
          <option value={'Other'}>Other</option>
          <option value={'Otterburne, MB'}>Otterburne, MB</option>
          <option value={'Pierson'}>Pierson</option>
          <option value={'Pilot Mound'}>Pilot Mound</option>
          <option value={'Pinawa'}>Pinawa</option>
          <option value={'Pine Falls'}>Pine Falls</option>
          <option value={'Pine River'}>Pine River</option>
          <option value={'Piney'}>Piney</option>
          <option value={'Pipestone'}>Pipestone</option>
          <option value={'Plum Coulee'}>Plum Coulee</option>
          <option value={'Plumas'}>Plumas</option>
          <option value={'Poplar Point'}>Poplar Point</option>
          <option value={'Poplarfield'}>Poplarfield</option>
          <option value={'Portage la Prairie'}>Portage la Prairie</option>
          <option value={'Powerview'}>Powerview</option>
          <option value={'Prawda'}>Prawda</option>
          <option value={'Randolft'}>Randolft</option>
          <option value={'Rapid City'}>Rapid City</option>
          <option value={'Red Sucker Lake'}>Red Sucker Lake</option>
          <option value={'Reinfeld'}>Reinfeld</option>
          <option value={'Reston'}>Reston</option>
          <option value={'Richer'}>Richer</option>
          <option value={'Ritchot'}>Ritchot</option>
          <option value={'Rivers'}>Rivers</option>
          <option value={'Riverton'}>Riverton</option>
          <option value={'Roblin'}>Roblin</option>
          <option value={'Roland'}>Roland</option>
          <option value={'Rorketon'}>Rorketon</option>
          <option value={'Roseisle'}>Roseisle</option>
          <option value={'Rosenfeld'}>Rosenfeld</option>
          <option value={'Rosenort'}>Rosenort</option>
          <option value={'Rossburn'}>Rossburn</option>
          <option value={'Rossendale'}>Rossendale</option>
          <option value={'Russell, MB'}>Russell, MB</option>
          <option value={'Saint Adolphe'}>Saint Adolphe</option>
          <option value={'Saint Claude'}>Saint Claude</option>
          <option value={'Saint François Xavier'}>Saint François Xavier</option>
          <option value={'Saint Martin'}>Saint Martin</option>
          <option value={'Sandy Hook'}>Sandy Hook</option>
          <option value={'Sarto'}>Sarto</option>
          <option value={'Schanzenfeld'}>Schanzenfeld</option>
          <option value={'Selkirk'}>Selkirk</option>
          <option value={'Shamattawa'}>Shamattawa</option>
          <option value={'Shilo'}>Shilo</option>
          <option value={'Shoal Lake'}>Shoal Lake</option>
          <option value={'Shortdale'}>Shortdale</option>
          <option value={'Snow Lake'}>Snow Lake</option>
          <option value={'Snowflake'}>Snowflake</option>
          <option value={'Somerset'}>Somerset</option>
          <option value={'Souris, MB'}>Souris, MB</option>
          <option value={'South Junction'}>South Junction</option>
          <option value={'Sprague'}>Sprague</option>
          <option value={'Springfield, MB'}>Springfield, MB</option>
          <option value={'St Andrews, MB'}>St Andrews, MB</option>
          <option value={'St Boniface'}>St Boniface</option>
          <option value={'St Germain'}>St Germain</option>
          <option value={'St Laurent'}>St Laurent</option>
          <option value={'St Vital'}>St Vital</option>
          <option value={'St. James-Assiniboia'}>St. James-Assiniboia</option>
          <option value={'St. Jean'}>St. Jean</option>
          <option value={'St. Malo'}>St. Malo</option>
          <option value={'St.-Pierre-Jolys'}>St.-Pierre-Jolys</option>
          <option value={'Starbuck'}>Starbuck</option>
          <option value={'Ste Agathe, MB'}>Ste Agathe, MB</option>
          <option value={'Ste Anne'}>Ste Anne</option>
          <option value={'Ste. Rose du Lac'}>Ste. Rose du Lac</option>
          <option value={'Steinbach'}>Steinbach</option>
          <option value={'Stonewall'}>Stonewall</option>
          <option value={'Stoney Mountain'}>Stoney Mountain</option>
          <option value={'Sundance'}>Sundance</option>
          <option value={'Sundown'}>Sundown</option>
          <option value={'Swan Lake'}>Swan Lake</option>
          <option value={'Swan River'}>Swan River</option>
          <option value={'Teulon'}>Teulon</option>
          <option value={'The Pas'}>The Pas</option>
          <option value={'Thicket Portage'}>Thicket Portage</option>
          <option value={'Thompson, MB'}>Thompson, MB</option>
          <option value={'Tolstol'}>Tolstol</option>
          <option value={'Tourand'}>Tourand</option>
          <option value={'Transcona'}>Transcona</option>
          <option value={'Treherne'}>Treherne</option>
          <option value={'Tuxedo'}>Tuxedo</option>
          <option value={'Tyndall'}>Tyndall</option>
          <option value={'Vermette'}>Vermette</option>
          <option value={'Virden'}>Virden</option>
          <option value={'Vita'}>Vita</option>
          <option value={'Warren, MB'}>Warren, MB</option>
          <option value={'Waskada'}>Waskada</option>
          <option value={'Waterhen'}>Waterhen</option>
          <option value={'Wawanesa'}>Wawanesa</option>
          <option value={'Waywayseecappo'}>Waywayseecappo</option>
          <option value={'Wellwood'}>Wellwood</option>
          <option value={'West Kildonan'}>West Kildonan</option>
          <option value={'West St Paul'}>West St Paul</option>
          <option value={'Whitemouth'}>Whitemouth</option>
          <option value={'Windygate'}>Windygate</option>
          <option value={'Winkler'}>Winkler</option>
          <option value={'Winnipeg'}>Winnipeg</option>
          <option value={'Winnipeg Beach'}>Winnipeg Beach</option>
          <option value={'Winnipegosis'}>Winnipegosis</option>
          <option value={'Woodlands'}>Woodlands</option>
          <option value={'Woodmore'}>Woodmore</option>
          <option value={'Zhoda, MB'}>Zhoda, MB</option>
        </>
      }
      {province === 'NB' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Acadieville'}>Acadieville</option>
          <option value={'Alma, NB'}>Alma, NB</option>
          <option value={'Andover'}>Andover</option>
          <option value={'Aroostook'}>Aroostook</option>
          <option value={'Arthurette'}>Arthurette</option>
          <option value={'Atholville'}>Atholville</option>
          <option value={'Back Bay'}>Back Bay</option>
          <option value={'Baie-Sainte-Anne'}>Baie-Sainte-Anne</option>
          <option value={'Bains Corner'}>Bains Corner</option>
          <option value={'Baker Brook'}>Baker Brook</option>
          <option value={'Barkers Point'}>Barkers Point</option>
          <option value={'Bath, NB'}>Bath, NB</option>
          <option value={'Bathurst'}>Bathurst</option>
          <option value={'Beaconsfield, NB'}>Beaconsfield, NB</option>
          <option value={'Bedell'}>Bedell</option>
          <option value={'Belledune'}>Belledune</option>
          <option value={'Beresford'}>Beresford</option>
          <option value={'Bertrand'}>Bertrand</option>
          <option value={'Berts Cove'}>Berts Cove</option>
          <option value={'Blacks Harbour'}>Blacks Harbour</option>
          <option value={'Blackville'}>Blackville</option>
          <option value={'Bloomfield, NB'}>Bloomfield, NB</option>
          <option value={'Bouctouche'}>Bouctouche</option>
          <option value={'Bridgedale'}>Bridgedale</option>
          <option value={'Bristol'}>Bristol</option>
          <option value={'Browns Flat'}>Browns Flat</option>
          <option value={'Bulls Creek'}>Bulls Creek</option>
          <option value={'Cambridge Narrows'}>Cambridge Narrows</option>
          <option value={'Campbellton'}>Campbellton</option>
          <option value={'Campobello'}>Campobello</option>
          <option value={'Canterbury'}>Canterbury</option>
          <option value={'Cap Pele'}>Cap Pele</option>
          <option value={'Cape Tourmentine'}>Cape Tourmentine</option>
          <option value={'Caraquet'}>Caraquet</option>
          <option value={'Carlingford'}>Carlingford</option>
          <option value={'Centreville, NB'}>Centreville, NB</option>
          <option value={'Charlo'}>Charlo</option>
          <option value={'Chartersville'}>Chartersville</option>
          <option value={'Chatham, NB'}>Chatham, NB</option>
          <option value={'Chipman, NB'}>Chipman, NB</option>
          <option value={'Clair'}>Clair</option>
          <option value={'Clairville'}>Clairville</option>
          <option value={'Cocagne'}>Cocagne</option>
          <option value={'Codys'}>Codys</option>
          <option value={'Coles Island'}>Coles Island</option>
          <option value={'College Bridge'}>College Bridge</option>
          <option value={'Colpitts Settl'}>Colpitts Settl</option>
          <option value={'Connors'}>Connors</option>
          <option value={'Cummings Cove'}>Cummings Cove</option>
          <option value={'Dalhousie, NB'}>Dalhousie, NB</option>
          <option value={'Deer Island Point'}>Deer Island Point</option>
          <option value={'Dieppe'}>Dieppe</option>
          <option value={'Dipper Harbour'}>Dipper Harbour</option>
          <option value={'Doaktown'}>Doaktown</option>
          <option value={'Dorchester, NB'}>Dorchester, NB</option>
          <option value={'Douglastown'}>Douglastown</option>
          <option value={'Drummond'}>Drummond</option>
          <option value={'East Riverside-Kingshurst'}>East Riverside-Kingshurst</option>
          <option value={'East Shediac'}>East Shediac</option>
          <option value={'Edmundston'}>Edmundston</option>
          <option value={'Eel River Crossing'}>Eel River Crossing</option>
          <option value={'Eng Settlement'}>Eng Settlement</option>
          <option value={'Fairhaven'}>Fairhaven</option>
          <option value={'Fairvale'}>Fairvale</option>
          <option value={'Florenceville'}>Florenceville</option>
          <option value={'Four Falls'}>Four Falls</option>
          <option value={'Fredericton'}>Fredericton</option>
          <option value={'Fredericton Junction'}>Fredericton Junction</option>
          <option value={'Gagetown'}>Gagetown</option>
          <option value={'Gaspereau Forks'}>Gaspereau Forks</option>
          <option value={'Gillespie'}>Gillespie</option>
          <option value={'Gondola Point'}>Gondola Point</option>
          <option value={'Grand Falls, NB'}>Grand Falls, NB</option>
          <option value={'Grand Harbour'}>Grand Harbour</option>
          <option value={'Grand Manan'}>Grand Manan</option>
          <option value={'Grande-Anse'}>Grande-Anse</option>
          <option value={'Green Point'}>Green Point</option>
          <option value={'Gunningsville'}>Gunningsville</option>
          <option value={'Hampton, NB'}>Hampton, NB</option>
          <option value={'Hanwell'}>Hanwell</option>
          <option value={'Harcourt'}>Harcourt</option>
          <option value={'Hartland'}>Hartland</option>
          <option value={'Harvey'}>Harvey</option>
          <option value={'Hillsborough'}>Hillsborough</option>
          <option value={'Hopewell Cape'}>Hopewell Cape</option>
          <option value={'Jacksontown'}>Jacksontown</option>
          <option value={'Jacquet River'}>Jacquet River</option>
          <option value={'Juniper'}>Juniper</option>
          <option value={'Kedgwick'}>Kedgwick</option>
          <option value={'Keswick Ridge'}>Keswick Ridge</option>
          <option value={'Kouchibouac'}>Kouchibouac</option>
          <option value={'Lac Baker'}>Lac Baker</option>
          <option value={'Lakeville'}>Lakeville</option>
          <option value={'Lameque'}>Lameque</option>
          <option value={'Lancaster, NB'}>Lancaster, NB</option>
          <option value={'Lansdowne, NB'}>Lansdowne, NB</option>
          <option value={'Lewisville'}>Lewisville</option>
          <option value={'Lincoln Heights'}>Lincoln Heights</option>
          <option value={'Little River'}>Little River</option>
          <option value={'Loch Lomond'}>Loch Lomond</option>
          <option value={'Loggieville'}>Loggieville</option>
          <option value={'Lower Brighton'}>Lower Brighton</option>
          <option value={'Lower Caraquet'}>Lower Caraquet</option>
          <option value={'Ludlow'}>Ludlow</option>
          <option value={'Maisonnette'}>Maisonnette</option>
          <option value={'Mars Hill Road'}>Mars Hill Road</option>
          <option value={'Marysville, MB'}>Marysville, MB</option>
          <option value={'Maugerville'}>Maugerville</option>
          <option value={'McAdam'}>McAdam</option>
          <option value={'Mckenna'}>Mckenna</option>
          <option value={'Meductic'}>Meductic</option>
          <option value={'Memramcook'}>Memramcook</option>
          <option value={'Millerton'}>Millerton</option>
          <option value={'Milltown, NB'}>Milltown, NB</option>
          <option value={'Millville'}>Millville</option>
          <option value={'Minto, NB'}>Minto, NB</option>
          <option value={'Miramichi'}>Miramichi</option>
          <option value={'Miscou Centre'}>Miscou Centre</option>
          <option value={'Mohannes'}>Mohannes</option>
          <option value={'Moncton'}>Moncton</option>
          <option value={'Nackawic'}>Nackawic</option>
          <option value={'Napan'}>Napan</option>
          <option value={'Nashwaaksis'}>Nashwaaksis</option>
          <option value={'Neguac'}>Neguac</option>
          <option value={'Nelson, NB'}>Nelson, NB</option>
          <option value={'New Denmark'}>New Denmark</option>
          <option value={'New Maryland'}>New Maryland</option>
          <option value={'Newcastle, NB'}>Newcastle, NB</option>
          <option value={'Nigadoo'}>Nigadoo</option>
          <option value={'North Head'}>North Head</option>
          <option value={'Northampton'}>Northampton</option>
          <option value={'Norton'}>Norton</option>
          <option value={'Notre Dame, NB'}>Notre Dame, NB</option>
          <option value={'Oak Bay, NB'}>Oak Bay, NB</option>
          <option value={'Oakland, NB'}>Oakland, NB</option>
          <option value={'Oromocto'}>Oromocto</option>
          <option value={'Other'}>Other</option>
          <option value={'Pamdenec'}>Pamdenec</option>
          <option value={'Paquetville'}>Paquetville</option>
          <option value={'Peel'}>Peel</option>
          <option value={'Pennfield'}>Pennfield</option>
          <option value={'Perth, NB'}>Perth, NB</option>
          <option value={'Perth-Andover'}>Perth-Andover</option>
          <option value={'Petit Rocher'}>Petit Rocher</option>
          <option value={'Petitcodiac'}>Petitcodiac</option>
          <option value={'Plaster Rock'}>Plaster Rock</option>
          <option value={'Pleasant Villa'}>Pleasant Villa</option>
          <option value={'Pointe-du-Chene'}>Pointe-du-Chene</option>
          <option value={'Pointe-Verte'}>Pointe-Verte</option>
          <option value={'Port Elgin, NB'}>Port Elgin, NB</option>
          <option value={'Prince William'}>Prince William</option>
          <option value={'Quispamsis'}>Quispamsis</option>
          <option value={'Red Bank'}>Red Bank</option>
          <option value={'Renforth'}>Renforth</option>
          <option value={'Renous'}>Renous</option>
          <option value={'Rexton'}>Rexton</option>
          <option value={'Richibucto'}>Richibucto</option>
          <option value={'River de Chute'}>River de Chute</option>
          <option value={'Riverbank'}>Riverbank</option>
          <option value={'Riverside-Albert'}>Riverside-Albert</option>
          <option value={'Riverview'}>Riverview</option>
          <option value={'Riverview Heights'}>Riverview Heights</option>
          <option value={'Riviere-Verte'}>Riviere-Verte</option>
          <option value={'Robichaud'}>Robichaud</option>
          <option value={'Rogersville'}>Rogersville</option>
          <option value={'Rothesay'}>Rothesay</option>
          <option value={'Sackville, NB'}>Sackville, NB</option>
          <option value={'Saint Andrews'}>Saint Andrews</option>
          <option value={'Saint Hilaire'}>Saint Hilaire</option>
          <option value={'Saint John, NB'}>Saint John, NB</option>
          <option value={'Saint- Andre'}>Saint- Andre</option>
          <option value={'Saint-Anselme'}>Saint-Anselme</option>
          <option value={'Saint-Antoine'}>Saint-Antoine</option>
          <option value={'Saint-Basile'}>Saint-Basile</option>
          <option value={'Saint-Charles'}>Saint-Charles</option>
          <option value={'Sainte-Anne-de-Kent'}>Sainte-Anne-de-Kent</option>
          <option value={'Sainte-Anne-de-Madawaska'}>Sainte-Anne-de-Madawaska</option>
          <option value={'Saint-Edouard-de-Kent'}>Saint-Edouard-de-Kent</option>
          <option value={'Sainte-Marie-de-Kent'}>Sainte-Marie-de-Kent</option>
          <option value={'Saint-Francois-de-Madawaska'}>Saint-Francois-de-Madawaska</option>
          <option value={'Saint-Jacques'}>Saint-Jacques</option>
          <option value={'Saint-Joseph, NB'}>Saint-Joseph, NB</option>
          <option value={'Saint-Leonard, NB'}>Saint-Leonard, NB</option>
          <option value={'Saint-Louis-de-Kent'}>Saint-Louis-de-Kent</option>
          <option value={'Saint-Quentin'}>Saint-Quentin</option>
          <option value={'Salisbury'}>Salisbury</option>
          <option value={'Scoudouc'}>Scoudouc</option>
          <option value={'Sea Cove, NB'}>Sea Cove, NB</option>
          <option value={'Shediac'}>Shediac</option>
          <option value={'Shippegan'}>Shippegan</option>
          <option value={'SilverWood'}>SilverWood</option>
          <option value={'St George, NB'}>St George, NB</option>
          <option value={'St Martins'}>St Martins</option>
          <option value={'St Stephen'}>St Stephen</option>
          <option value={'Stanley'}>Stanley</option>
          <option value={'Stickney'}>Stickney</option>
          <option value={'Summerfield'}>Summerfield</option>
          <option value={'Surrey, NB'}>Surrey, NB</option>
          <option value={'Sussex'}>Sussex</option>
          <option value={'Sussex Corner'}>Sussex Corner</option>
          <option value={'Taymouth'}>Taymouth</option>
          <option value={'Tide Head'}>Tide Head</option>
          <option value={'Tracadie'}>Tracadie</option>
          <option value={'Tracadie-Sheila'}>Tracadie-Sheila</option>
          <option value={'Tracy, NB'}>Tracy, NB</option>
          <option value={'Upper Coverdale'}>Upper Coverdale</option>
          <option value={'Upper Kent'}>Upper Kent</option>
          <option value={'Upper Woodstook'}>Upper Woodstook</option>
          <option value={'Wakefield, NB'}>Wakefield, NB</option>
          <option value={'Waterville'}>Waterville</option>
          <option value={'Welshpool'}>Welshpool</option>
          <option value={'Westfield'}>Westfield</option>
          <option value={'Whitehead'}>Whitehead</option>
          <option value={'Wicklow'}>Wicklow</option>
          <option value={'Wilson\'s Beach'}>Wilson's Beach</option>
          <option value={'Woodside'}>Woodside</option>
          <option value={'Woodstock, NB'}>Woodstock, NB</option>
          <option value={'Young\'s Cove'}>Young's Cove</option>
          <option value={'Zealand'}>Zealand</option>
        </>
      }
      {province === 'NL' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Aguathuna'}>Aguathuna</option>
          <option value={'Badger'}>Badger</option>
          <option value={'Baie de Verde'}>Baie de Verde</option>
          <option value={'Baie Verte'}>Baie Verte</option>
          <option value={'Bauline'}>Bauline</option>
          <option value={"Bay L'Argent"}>Bay L'Argent</option>
          <option value={'Bay Roberts'}>Bay Roberts</option>
          <option value={'Bell Island'}>Bell Island</option>
          <option value={'Belleoram'}>Belleoram</option>
          <option value={"Benoit's Cove"}>Benoit's Cove</option>
          <option value={"Bishop's Falls"}>Bishop's Falls</option>
          <option value={'Bonavista'}>Bonavista</option>
          <option value={'Botwood'}>Botwood</option>
          <option value={'Brigus'}>Brigus</option>
          <option value={'Buchans'}>Buchans</option>
          <option value={'Burgeo'}>Burgeo</option>
          <option value={'Burin'}>Burin</option>
          <option value={'Burl-Green Bay'}>Burl-Green Bay</option>
          <option value={'Cape Broyle'}>Cape Broyle</option>
          <option value={'Carbonear'}>Carbonear</option>
          <option value={'Carmanville'}>Carmanville</option>
          <option value={'Catalina'}>Catalina</option>
          <option value={'Cavendish'}>Cavendish</option>
          <option value={'Change islands'}>Change islands</option>
          <option value={'Channel-Port Aux Basques'}>Channel-Port Aux Basques</option>
          <option value={'Chapel Cove'}>Chapel Cove</option>
          <option value={'Clarenville'}>Clarenville</option>
          <option value={"Clark's Beach"}>Clark's Beach</option>
          <option value={'Come By Chance'}>Come By Chance</option>
          <option value={'Conception Bay'}>Conception Bay</option>
          <option value={'Cormack'}>Cormack</option>
          <option value={'Corner Brook'}>Corner Brook</option>
          <option value={'Cow Head'}>Cow Head</option>
          <option value={'Creston, NL'}>Creston, NL</option>
          <option value={'Cupids'}>Cupids</option>
          <option value={'Deer Lake'}>Deer Lake</option>
          <option value={'Doyles'}>Doyles</option>
          <option value={'Dunnville, NL'}>Dunnville, NL</option>
          <option value={'Durrel'}>Durrel</option>
          <option value={'Eastport'}>Eastport</option>
          <option value={'Elliston'}>Elliston</option>
          <option value={'Englee'}>Englee</option>
          <option value={"Flower's Cove"}>Flower's Cove</option>
          <option value={'Fogo'}>Fogo</option>
          <option value={'Forteau'}>Forteau</option>
          <option value={'Fortune'}>Fortune</option>
          <option value={'Freshwater'}>Freshwater</option>
          <option value={'Gambo'}>Gambo</option>
          <option value={'Gander'}>Gander</option>
          <option value={'Gaultois'}>Gaultois</option>
          <option value={'Gillams'}>Gillams</option>
          <option value={'Glenwood, NL'}>Glenwood, NL</option>
          <option value={'Glovertown'}>Glovertown</option>
          <option value={'Goose Bay'}>Goose Bay</option>
          <option value={'Gould'}>Gould</option>
          <option value={'Grand Bank'}>Grand Bank</option>
          <option value={'Grand Falls, NL'}>Grand Falls, NL</option>
          <option value={'Greenspond'}>Greenspond</option>
          <option value={'Hants Harbour'}>Hants Harbour</option>
          <option value={'Happy Valley'}>Happy Valley</option>
          <option value={'Harbour Breton'}>Harbour Breton</option>
          <option value={'Harbour Grace'}>Harbour Grace</option>
          <option value={'Hare Bay'}>Hare Bay</option>
          <option value={"Hawke's Bay"}>Hawke's Bay</option>
          <option value={'Holyrood'}>Holyrood</option>
          <option value={'Hopedale'}>Hopedale</option>
          <option value={"Jackson's Arm"}>Jackson's Arm</option>
          <option value={'Jerseyside'}>Jerseyside</option>
          <option value={'Kelligrews'}>Kelligrews</option>
          <option value={'Kilbride, NL'}>Kilbride, NL</option>
          <option value={'Labrador City'}>Labrador City</option>
          <option value={'Lamaline'}>Lamaline</option>
          <option value={'Lawn'}>Lawn</option>
          <option value={'Lewisporte'}>Lewisporte</option>
          <option value={'Little Catalina'}>Little Catalina</option>
          <option value={'Lourdes, NL'}>Lourdes, NL</option>
          <option value={'Lumsden'}>Lumsden</option>
          <option value={'Main Brook'}>Main Brook</option>
          <option value={'Makkovik'}>Makkovik</option>
          <option value={'Manuels'}>Manuels</option>
          <option value={"Mary's Harbour"}>Mary's Harbour</option>
          <option value={'Marystown'}>Marystown</option>
          <option value={'Milltown, NL'}>Milltown, NL</option>
          <option value={'Mount Pearl'}>Mount Pearl</option>
          <option value={'Nain'}>Nain</option>
          <option value={'Natuashish'}>Natuashish</option>
          <option value={'Newtown'}>Newtown</option>
          <option value={'Norris Arm'}>Norris Arm</option>
          <option value={'Old Perlican'}>Old Perlican</option>
          <option value={"O'Regans"}>O'Regans</option>
          <option value={'Other'}>Other</option>
          <option value={'Outer Cove'}>Outer Cove</option>
          <option value={'Paradise, NL'}>Paradise, NL</option>
          <option value={'Pasadena'}>Pasadena</option>
          <option value={'Placentia'}>Placentia</option>
          <option value={'Point Leamington'}>Point Leamington</option>
          <option value={'Port au Choix'}>Port au Choix</option>
          <option value={'Port Rexton'}>Port Rexton</option>
          <option value={'Port Saunders'}>Port Saunders</option>
          <option value={'Port Union'}>Port Union</option>
          <option value={'Port-au-port'}>Port-au-port</option>
          <option value={'Portugal Cove'}>Portugal Cove</option>
          <option value={'Pouch Cove'}>Pouch Cove</option>
          <option value={'Pound Cove'}>Pound Cove</option>
          <option value={'Ramea'}>Ramea</option>
          <option value={'Renews'}>Renews</option>
          <option value={'Rigolet'}>Rigolet</option>
          <option value={"Robert's Arm"}>Robert's Arm</option>
          <option value={'Rocky Harbour'}>Rocky Harbour</option>
          <option value={'Roddickton'}>Roddickton</option>
          <option value={'South Brook'}>South Brook</option>
          <option value={'South River, NL'}>South River, NL</option>
          <option value={'Southern Harbour'}>Southern Harbour</option>
          <option value={"Spaniard's Bay"}>Spaniard's Bay</option>
          <option value={'Springdale'}>Springdale</option>
          <option value={"St. Alban's, NL"}>St. Alban's, NL</option>
          <option value={'St. Anthony'}>St. Anthony</option>
          <option value={"St. George's, NL"}>St. George's, NL</option>
          <option value={"St. John's, NL"}>St. John's, NL</option>
          <option value={'St. Lawrence'}>St. Lawrence</option>
          <option value={'Stephenville'}>Stephenville</option>
          <option value={'Stephenville Cros'}>Stephenville Cros</option>
          <option value={'Topsail'}>Topsail</option>
          <option value={'Torbay'}>Torbay</option>
          <option value={'Trepassey'}>Trepassey</option>
          <option value={'Trinity'}>Trinity</option>
          <option value={'Triton'}>Triton</option>
          <option value={'Twillingate'}>Twillingate</option>
          <option value={'Upper Island Cove'}>Upper Island Cove</option>
          <option value={'Wabana'}>Wabana</option>
          <option value={'Wabush'}>Wabush</option>
          <option value={'Wesleyville'}>Wesleyville</option>
          <option value={'Western Bay'}>Western Bay</option>
          <option value={'Whitbourne'}>Whitbourne</option>
          <option value={'Windsor, NL'}>Windsor, NL</option>
          <option value={'Winterton'}>Winterton</option>
          <option value={'Witless Bay'}>Witless Bay</option>
        </>
      }
      {province === 'NS' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Amherst'}>Amherst</option>
          <option value={'Annapolis Royal'}>Annapolis Royal</option>
          <option value={'Antigonish'}>Antigonish</option>
          <option value={'Arcadia'}>Arcadia</option>
          <option value={'Armdale'}>Armdale</option>
          <option value={'Aylesford'}>Aylesford</option>
          <option value={'Baddeck'}>Baddeck</option>
          <option value={'Bakers Settlement'}>Bakers Settlement</option>
          <option value={'Barrington Pas'}>Barrington Pas</option>
          <option value={'Beach Meadows'}>Beach Meadows</option>
          <option value={'Bear River'}>Bear River</option>
          <option value={'Beaver Bank'}>Beaver Bank</option>
          <option value={'Bedford, NS'}>Bedford, NS</option>
          <option value={'Beechville'}>Beechville</option>
          <option value={'Belmont, NS'}>Belmont, NS</option>
          <option value={'Berwick'}>Berwick</option>
          <option value={'Blible Hill'}>Blible Hill</option>
          <option value={'Black Point'}>Black Point</option>
          <option value={'Blandford'}>Blandford</option>
          <option value={'Blockhouse'}>Blockhouse</option>
          <option value={'Bonico'}>Bonico</option>
          <option value={'Bridgetown'}>Bridgetown</option>
          <option value={'Bridgewater'}>Bridgewater</option>
          <option value={'Brier Island'}>Brier Island</option>
          <option value={'Brockfield'}>Brockfield</option>
          <option value={'Brookfield'}>Brookfield</option>
          <option value={'Brookside'}>Brookside</option>
          <option value={'Caledonia, NS'}>Caledonia, NS</option>
          <option value={'Caperdowm'}>Caperdowm</option>
          <option value={'Canning'}>Canning</option>
          <option value={'Canso'}>Canso</option>
          <option value={'Cape Breton'}>Cape Breton</option>
          <option value={'Certerville, NS'}>Certerville, NS</option>
          <option value={'Certreville'}>Certreville</option>
          <option value={'Chester'}>Chester</option>
          <option value={'Chester Basin'}>Chester Basin</option>
          <option value={'Cheticamp'}>Cheticamp</option>
          <option value={'Church Point'}>Church Point</option>
          <option value={'Clark`s Harbour'}>Clark`s Harbour</option>
          <option value={'Clementsvale'}>Clementsvale</option>
          <option value={'Cleveland'}>Cleveland</option>
          <option value={'Clochester'}>Clochester</option>
          <option value={'Coldbrook'}>Coldbrook</option>
          <option value={'Cole Harbour'}>Cole Harbour</option>
          <option value={'Cow Bay'}>Cow Bay</option>
          <option value={'Cumberland'}>Cumberland</option>
          <option value={'Dalhousie, NS'}>Dalhousie, NS</option>
          <option value={'Dartmouth'}>Dartmouth</option>
          <option value={'Debert, NS'}>Debert, NS</option>
          <option value={'Deep Brook'}>Deep Brook</option>
          <option value={'Digby'}>Digby</option>
          <option value={'Dingwall'}>Dingwall</option>
          <option value={'Dominion'}>Dominion</option>
          <option value={'East Bay'}>East Bay</option>
          <option value={'East Lake Ainslie'}>East Lake Ainslie</option>
          <option value={'East Pennant'}>East Pennant</option>
          <option value={'Eastern Passage'}>Eastern Passage</option>
          <option value={'Economy'}>Economy</option>
          <option value={'Ellerhouse'}>Ellerhouse</option>
          <option value={'Elmsdale'}>Elmsdale</option>
          <option value={'Enfield'}>Enfield</option>
          <option value={'Englishtown'}>Englishtown</option>
          <option value={'Eskasoni'}>Eskasoni</option>
          <option value={'Fall River'}>Fall River</option>
          <option value={'Falmounth, NS'}>Falmounth, NS</option>
          <option value={'Fletchers'}>Fletchers</option>
          <option value={'Freeport'}>Freeport</option>
          <option value={'Gaetz Brook'}>Gaetz Brook</option>
          <option value={'Gillis Point'}>Gillis Point</option>
          <option value={'Glace Bay'}>Glace Bay</option>
          <option value={'Glenwood, NS'}>Glenwood, NS</option>
          <option value={'Grand Pre'}>Grand Pre</option>
          <option value={'Grand River'}>Grand River</option>
          <option value={'Granville'}>Granville</option>
          <option value={'Granville Ferry'}>Granville Ferry</option>
          <option value={'Greenwood, NS'}>Greenwood, NS</option>
          <option value={'Guysborough'}>Guysborough</option>
          <option value={'Halifax'}>Halifax</option>
          <option value={'Hammands Plains'}>Hammands Plains</option>
          <option value={'Hants County'}>Hants County</option>
          <option value={'Hantsport'}>Hantsport</option>
          <option value={'Hatchet Lake'}>Hatchet Lake</option>
          <option value={'Head Of Jeddore'}>Head Of Jeddore</option>
          <option value={'Herring Cove'}>Herring Cove</option>
          <option value={'Hillsburn'}>Hillsburn</option>
          <option value={'Hubbards, NS'}>Hubbards, NS</option>
          <option value={'Hubley'}>Hubley</option>
          <option value={'Indian Brook'}>Indian Brook</option>
          <option value={'Ingonish Beach'}>Ingonish Beach</option>
          <option value={'Inverness, NS'}>Inverness, NS</option>
          <option value={'Irish Cove'}>Irish Cove</option>
          <option value={'Jeddore Oyster Ponds'}>Jeddore Oyster Ponds</option>
          <option value={'Judique'}>Judique</option>
          <option value={'Karsdale'}>Karsdale</option>
          <option value={'Kempt'}>Kempt</option>
          <option value={'Kentville, NS'}>Kentville, NS</option>
          <option value={'Kings City'}>Kings City</option>
          <option value={'Kingston, NS'}>Kingston, NS</option>
          <option value={'Kingsville, NS'}>Kingsville, NS</option>
          <option value={'La Have'}>La Have</option>
          <option value={'Lakeside'}>Lakeside</option>
          <option value={'Lantz'}>Lantz</option>
          <option value={'Larry\'s River'}>Larry's River</option>
          <option value={'Lawrencetown'}>Lawrencetown</option>
          <option value={'Litchfield'}>Litchfield</option>
          <option value={'Liverpool'}>Liverpool</option>
          <option value={'Lockeport'}>Lockeport</option>
          <option value={'Louisbourg'}>Louisbourg</option>
          <option value={'Lower L\'Ardoise'}>Lower L'Ardoise</option>
          <option value={'Lower Sackville'}>Lower Sackville</option>
          <option value={'Lucasville'}>Lucasville</option>
          <option value={'Lunenburg'}>Lunenburg</option>
          <option value={'Lunenburg County'}>Lunenburg County</option>
          <option value={'Mabou'}>Mabou</option>
          <option value={'Mahone Bay'}>Mahone Bay</option>
          <option value={'Malagash'}>Malagash</option>
          <option value={'Margaree Forks'}>Margaree Forks</option>
          <option value={'Margaretsville'}>Margaretsville</option>
          <option value={'Marion Bridge'}>Marion Bridge</option>
          <option value={'Martins River'}>Martins River</option>
          <option value={'Mavillette'}>Mavillette</option>
          <option value={'Merigomish, NS'}>Merigomish, NS</option>
          <option value={'Meteghan River'}>Meteghan River</option>
          <option value={'Middle Sackville'}>Middle Sackville</option>
          <option value={'Middleton'}>Middleton</option>
          <option value={'Mill Village'}>Mill Village</option>
          <option value={'Millbrook, NS'}>Millbrook, NS</option>
          <option value={'Minesville'}>Minesville</option>
          <option value={'Moose River'}>Moose River</option>
          <option value={'Mulgrave'}>Mulgrave</option>
          <option value={'Musquodoboit'}>Musquodoboit</option>
          <option value={'New Germany'}>New Germany</option>
          <option value={'New Glasgow, NS'}>New Glasgow, NS</option>
          <option value={'New Minas'}>New Minas</option>
          <option value={'New Waterford'}>New Waterford</option>
          <option value={'Newport'}>Newport</option>
          <option value={'Noel'}>Noel</option>
          <option value={'North East Point'}>North East Point</option>
          <option value={'North Sydney'}>North Sydney</option>
          <option value={'Northwest Cove'}>Northwest Cove</option>
          <option value={'Oakfield'}>Oakfield</option>
          <option value={'Other'}>Other</option>
          <option value={'Oxford'}>Oxford</option>
          <option value={'Parrsboro'}>Parrsboro</option>
          <option value={'Petit-de-Grat'}>Petit-de-Grat</option>
          <option value={'Petite Riviere'}>Petite Riviere</option>
          <option value={'Pictou'}>Pictou</option>
          <option value={'Pleasant Bay'}>Pleasant Bay</option>
          <option value={'Pleasant Valley'}>Pleasant Valley</option>
          <option value={'Pleasantville'}>Pleasantville</option>
          <option value={'Pomquet'}>Pomquet</option>
          <option value={'Port George'}>Port George</option>
          <option value={'Port Hawkesbury'}>Port Hawkesbury</option>
          <option value={'Port Hood'}>Port Hood</option>
          <option value={'Port Maitland'}>Port Maitland</option>
          <option value={'Port Saxon'}>Port Saxon</option>
          <option value={'Port Willians'}>Port Willians</option>
          <option value={'Porters Lake'}>Porters Lake</option>
          <option value={'Potter\'s Lake'}>Potter's Lake</option>
          <option value={'Poulamon'}>Poulamon</option>
          <option value={'Prospect'}>Prospect</option>
          <option value={'Pugwash'}>Pugwash</option>
          <option value={'River Denys'}>River Denys</option>
          <option value={'River John'}>River John</option>
          <option value={'Roberta'}>Roberta</option>
          <option value={'Rose Bay'}>Rose Bay</option>
          <option value={'Sackville, NS'}>Sackville, NS</option>
          <option value={'Salmon River'}>Salmon River</option>
          <option value={'Sandford'}>Sandford</option>
          <option value={'Saulnierville'}>Saulnierville</option>
          <option value={'Scotch Village'}>Scotch Village</option>
          <option value={'Scotsburn'}>Scotsburn</option>
          <option value={'Seabright'}>Seabright</option>
          <option value={'Shad Bay'}>Shad Bay</option>
          <option value={'Shag Harbour'}>Shag Harbour</option>
          <option value={'Shear Water'}>Shear Water</option>
          <option value={'Sheet Harbour'}>Sheet Harbour</option>
          <option value={'Shelburne, NS'}>Shelburne, NS</option>
          <option value={'Sherbrooke, NS'}>Sherbrooke, NS</option>
          <option value={'Shubenacadie'}>Shubenacadie</option>
          <option value={'Shubewacad'}>Shubewacad</option>
          <option value={'South Ohio'}>South Ohio</option>
          <option value={'Springfield'}>Springfield</option>
          <option value={'Springhill'}>Springhill</option>
          <option value={'St Margaret\'s Bay'}>St Margaret's Bay</option>
          <option value={'St George\'s Channel'}>St George's Channel</option>
          <option value={'St peter\'s'}>St peter's</option>
          <option value={'Ste Anne du Ruis'}>Ste Anne du Ruis</option>
          <option value={'Stellarton'}>Stellarton</option>
          <option value={'Stewiacke'}>Stewiacke</option>
          <option value={'Stillwater Lake'}>Stillwater Lake</option>
          <option value={'Sydney Mines'}>Sydney Mines</option>
          <option value={'Sydney, NS'}>Sydney, NS</option>
          <option value={'Tantallon, NS'}>Tantallon, NS</option>
          <option value={'Tatamagouche'}>Tatamagouche</option>
          <option value={'Terence Bay'}>Terence Bay</option>
          <option value={'Timberlea'}>Timberlea</option>
          <option value={'Trenton, NS'}>Trenton, NS</option>
          <option value={'Truro'}>Truro</option>
          <option value={'Tusket'}>Tusket</option>
          <option value={'Upper Kennetcook'}>Upper Kennetcook</option>
          <option value={'Upper Stewiacke'}>Upper Stewiacke</option>
          <option value={'Upper Tantallon'}>Upper Tantallon</option>
          <option value={'Walton, NS'}>Walton, NS</option>
          <option value={'Waterville, NS'}>Waterville, NS</option>
          <option value={'Waverley'}>Waverley</option>
          <option value={'Wedge Port'}>Wedge Port</option>
          <option value={'Wellington, NS'}>Wellington, NS</option>
          <option value={'West Arichat'}>West Arichat</option>
          <option value={'West Bay'}>West Bay</option>
          <option value={'West Dover'}>West Dover</option>
          <option value={'Westville'}>Westville</option>
          <option value={'Weymouth'}>Weymouth</option>
          <option value={'Whites Lake'}>Whites Lake</option>
          <option value={'Whycocomagh'}>Whycocomagh</option>
          <option value={'Wilmot, NS'}>Wilmot, NS</option>
          <option value={'Windsor; NS'}>Windsor; NS</option>
          <option value={'Wolfville'}>Wolfville</option>
          <option value={'Woods Harbour'}>Woods Harbour</option>
          <option value={'Yarmouth'}>Yarmouth</option>
        </>
      }
      {province === 'NT' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Coleville LK'}>Coleville LK</option>
          <option value={'Deline'}>Deline</option>
          <option value={'Detah'}>Detah</option>
          <option value={'Enterprise'}>Enterprise</option>
          <option value={'Fort Liard'}>Fort Liard</option>
          <option value={'Fort Rae'}>Fort Rae</option>
          <option value={'Fort Simpson'}>Fort Simpson</option>
          <option value={'Fort Smith, NT'}>Fort Smith, NT</option>
          <option value={'Ft Good Hope'}>Ft Good Hope</option>
          <option value={'Ft Providenc'}>Ft Providenc</option>
          <option value={'Ft Resolutio'}>Ft Resolutio</option>
          <option value={'Ft.Mcpherson'}>Ft.Mcpherson</option>
          <option value={'Hay River, NT'}>Hay River, NT</option>
          <option value={'Homan'}>Homan</option>
          <option value={'Iklavik'}>Iklavik</option>
          <option value={'Inuvik, NT'}>Inuvik, NT</option>
          <option value={'Jeanmarie Ri'}>Jeanmarie Ri</option>
          <option value={'Kakisa'}>Kakisa</option>
          <option value={'Lutselk\'e'}>Lutselk'e</option>
          <option value={'Nahanni Butt'}>Nahanni Butt</option>
          <option value={'Norman Wells'}>Norman Wells</option>
          <option value={'Other'}>Other</option>
          <option value={'Paulatuk'}>Paulatuk</option>
          <option value={'Rae Lakes'}>Rae Lakes</option>
          <option value={'Sachs Harbou'}>Sachs Harbou</option>
          <option value={'Snare Lakes'}>Snare Lakes</option>
          <option value={'Trout Lake'}>Trout Lake</option>
          <option value={'Tsiigehtchic'}>Tsiigehtchic</option>
          <option value={'Tuktoyaktuk'}>Tuktoyaktuk</option>
          <option value={'Tulita'}>Tulita</option>
          <option value={'Wrigley'}>Wrigley</option>
          <option value={'Yellowknife'}>Yellowknife</option>
        </>
      }
      {province === 'NU' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Artic Bay'}>Artic Bay</option>
          <option value={'Arviat'}>Arviat</option>
          <option value={'Baker Lake'}>Baker Lake</option>
          <option value={'Bathurst Inl'}>Bathurst Inl</option>
          <option value={'Bay Chimo'}>Bay Chimo</option>
          <option value={'Broughton I.'}>Broughton I.</option>
          <option value={'Cambridge Bay'}>Cambridge Bay</option>
          <option value={'Cape Dorset'}>Cape Dorset</option>
          <option value={'Chesterfield'}>Chesterfield</option>
          <option value={'Clyde River'}>Clyde River</option>
          <option value={'Coppermine'}>Coppermine</option>
          <option value={'Coral Harbour'}>Coral Harbour</option>
          <option value={'Frobisher Bay'}>Frobisher Bay</option>
          <option value={'Gjoa Haven'}>Gjoa Haven</option>
          <option value={'Grise Fjord'}>Grise Fjord</option>
          <option value={'Hall Beach'}>Hall Beach</option>
          <option value={'Igloolik'}>Igloolik</option>
          <option value={'Iqaluit'}>Iqaluit</option>
          <option value={'Kimminut'}>Kimminut</option>
          <option value={'Kugluktuk'}>Kugluktuk</option>
          <option value={'Nanisivik'}>Nanisivik</option>
          <option value={'Other'}>Other</option>
          <option value={'Pangnittung'}>Pangnittung</option>
          <option value={'Pelly Bay'}>Pelly Bay</option>
          <option value={'Pond Inlet'}>Pond Inlet</option>
          <option value={'Rankin Inlet'}>Rankin Inlet</option>
          <option value={'Repulse Bay'}>Repulse Bay</option>
          <option value={'Resolute'}>Resolute</option>
          <option value={'Sanikiluaq'}>Sanikiluaq</option>
          <option value={'Taloyoak'}>Taloyoak</option>
          <option value={'Whale Cove'}>Whale Cove</option>
        </>
      }
      {province === 'ON' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Aberfoyl'}>Aberfoyl</option>
          <option value={'Acton'}>Acton</option>
          <option value={'Agincourt'}>Agincourt</option>
          <option value={'Ailsa Craig'}>Ailsa Craig</option>
          <option value={'Ajax'}>Ajax</option>
          <option value={'Aldershot'}>Aldershot</option>
          <option value={'Alderwood'}>Alderwood</option>
          <option value={'Alexandria'}>Alexandria</option>
          <option value={'Alfred'}>Alfred</option>
          <option value={'Algona'}>Algona</option>
          <option value={'Algoma Mills'}>Algoma Mills</option>
          <option value={'Allenford'}>Allenford</option>
          <option value={'Alliston'}>Alliston</option>
          <option value={'Alma, ON'}>Alma, ON</option>
          <option value={'Almonte'}>Almonte</option>
          <option value={'Alton'}>Alton</option>
          <option value={'Alvinston'}>Alvinston</option>
          <option value={'Amherstburg'}>Amherstburg</option>
          <option value={'Amherstview'}>Amherstview</option>
          <option value={'Ancaster'}>Ancaster</option>
          <option value={'Anderson'}>Anderson</option>
          <option value={'Angus'}>Angus</option>
          <option value={'Annan'}>Annan</option>
          <option value={'Apple Hill'}>Apple Hill</option>
          <option value={'Apsley'}>Apsley</option>
          <option value={'Ariss'}>Ariss</option>
          <option value={'Arkona'}>Arkona</option>
          <option value={'Armitage'}>Armitage</option>
          <option value={'Armstrong, ON'}>Armstrong, ON</option>
          <option value={'Arnprior'}>Arnprior</option>
          <option value={'Arnstein'}>Arnstein</option>
          <option value={'Arthur'}>Arthur</option>
          <option value={'Arva'}>Arva</option>
          <option value={'Ashburn'}>Ashburn</option>
          <option value={'Ashton'}>Ashton</option>
          <option value={'Athens'}>Athens</option>
          <option value={'Atikokan'}>Atikokan</option>
          <option value={'Attawapiskat'}>Attawapiskat</option>
          <option value={'Atwood'}>Atwood</option>
          <option value={'Auburn'}>Auburn</option>
          <option value={'Aurora'}>Aurora</option>
          <option value={'Avon'}>Avon</option>
          <option value={'Avonmore'}>Avonmore</option>
          <option value={'Aylmer, ON'}>Aylmer, ON</option>
          <option value={'Ayr'}>Ayr</option>
          <option value={'Azilda, ON'}>Azilda, ON</option>
          <option value={'Baden, ON'}>Baden, ON</option>
          <option value={'Bailieboro'}>Bailieboro</option>
          <option value={'Bainsville'}>Bainsville</option>
          <option value={'Bala'}>Bala</option>
          <option value={'Baldwin'}>Baldwin</option>
          <option value={'Ballantree'}>Ballantree</option>
          <option value={'Balmertown'}>Balmertown</option>
          <option value={'Baltimore'}>Baltimore</option>
          <option value={'Bankanen'}>Bankanen</option>
          <option value={'Bancroft'}>Bancroft</option>
          <option value={'Bar Harbour'}>Bar Harbour</option>
          <option value={'Barrie'}>Barrie</option>
          <option value={'Barry`s Bay'}>Barry`s Bay</option>
          <option value={'Basswood Lake'}>Basswood Lake</option>
          <option value={'Batawa'}>Batawa</option>
          <option value={'Bath, On'}>Bath, On</option>
          <option value={'Battersea'}>Battersea</option>
          <option value={'Bayfield'}>Bayfield</option>
          <option value={'Baymouth'}>Baymouth</option>
          <option value={'Baysville'}>Baysville</option>
          <option value={'Beachburg'}>Beachburg</option>
          <option value={'Beachville'}>Beachville</option>
          <option value={'Beamsville'}>Beamsville</option>
          <option value={'Beardmore'}>Beardmore</option>
          <option value={'Beaverton'}>Beaverton</option>
          <option value={'Beeton'}>Beeton</option>
          <option value={'Belfountain'}>Belfountain</option>
          <option value={'Bell Ewart'}>Bell Ewart</option>
          <option value={'Belle River'}>Belle River</option>
          <option value={'Belleville'}>Belleville</option>
          <option value={'Belmont, ON'}>Belmont, ON</option>
          <option value={'Belwood'}>Belwood</option>
          <option value={'Berkeley'}>Berkeley</option>
          <option value={'Bethany'}>Bethany</option>
          <option value={'Binbrook'}>Binbrook</option>
          <option value={'Blackburn Hamlet'}>Blackburn Hamlet</option>
          <option value={'Blackstock'}>Blackstock</option>
          <option value={'Blenheim'}>Blenheim</option>
          <option value={'Blind River'}>Blind River</option>
          <option value={'Bloomfield, ON'}>Bloomfield, ON</option>
          <option value={'Bloomingdale'}>Bloomingdale</option>
          <option value={'Bluevale'}>Bluevale</option>
          <option value={'Blyth'}>Blyth</option>
          <option value={'Bobcaygeon'}>Bobcaygeon</option>
          <option value={'Bob-Lo Island'}>Bob-Lo Island</option>
          <option value={'Bolton'}>Bolton</option>
          <option value={'Bond Head'}>Bond Head</option>
          <option value={'Bonfield'}>Bonfield</option>
          <option value={'Borden, ON'}>Borden, ON</option>
          <option value={'Bothwell'}>Bothwell</option>
          <option value={'Bourget, ON'}>Bourget, ON</option>
          <option value={'Bowmanville'}>Bowmanville</option>
          <option value={'Bracebridge'}>Bracebridge</option>
          <option value={'Bradford'}>Bradford</option>
          <option value={'Braeside'}>Braeside</option>
          <option value={'Bramalea'}>Bramalea</option>
          <option value={'Brampton'}>Brampton</option>
          <option value={'Branchton'}>Branchton</option>
          <option value={'Brantford'}>Brantford</option>
          <option value={'Brechin'}>Brechin</option>
          <option value={'Breslau'}>Breslau</option>
          <option value={'Bridgenorth'}>Bridgenorth</option>
          <option value={'Bridgeport'}>Bridgeport</option>
          <option value={'Brigden'}>Brigden</option>
          <option value={'Bright'}>Bright</option>
          <option value={'Brighton'}>Brighton</option>
          <option value={'Brights Grove'}>Brights Grove</option>
          <option value={'Britt'}>Britt</option>
          <option value={'Brockville'}>Brockville</option>
          <option value={'Bronte'}>Bronte</option>
          <option value={'Brooklin, ON'}>Brooklin, ON</option>
          <option value={'Brownsville'}>Brownsville</option>
          <option value={'Bruce Mines'}>Bruce Mines</option>
          <option value={'Brunner'}>Brunner</option>
          <option value={'Brussels'}>Brussels</option>
          <option value={'Buckhorn'}>Buckhorn</option>
          <option value={'Burford'}>Burford</option>
          <option value={'Burgessville'}>Burgessville</option>
          <option value={'Burk`s Falls'}>Burk`s Falls</option>
          <option value={'Burlington'}>Burlington</option>
          <option value={'Burnt River'}>Burnt River</option>
          <option value={'Burritts Rapids'}>Burritts Rapids</option>
          <option value={'Buttonville'}>Buttonville</option>
          <option value={'Cabden'}>Cabden</option>
          <option value={'Cache Bay'}>Cache Bay</option>
          <option value={'Caesarea'}>Caesarea</option>
          <option value={'Caistor Centre'}>Caistor Centre</option>
          <option value={'Caledon'}>Caledon</option>
          <option value={'Caledon East'}>Caledon East</option>
          <option value={'Caledon Village'}>Caledon Village</option>
          <option value={'Caledonia, ON'}>Caledonia, ON</option>
          <option value={'Callander'}>Callander</option>
          <option value={'Cambridge'}>Cambridge</option>
          <option value={'Cambridge-Galt'}>Cambridge-Galt</option>
          <option value={'Cameron'}>Cameron</option>
          <option value={'Camlachie'}>Camlachie</option>
          <option value={'Campbellcroft'}>Campbellcroft</option>
          <option value={'Campbellford'}>Campbellford</option>
          <option value={'Campbellville'}>Campbellville</option>
          <option value={'Canfield'}>Canfield</option>
          <option value={'Cannington'}>Cannington</option>
          <option value={'Capreol'}>Capreol</option>
          <option value={'Caramat'}>Caramat</option>
          <option value={'Cardinal'}>Cardinal</option>
          <option value={'Cardinal Point'}>Cardinal Point</option>
          <option value={'Carleton Place'}>Carleton Place</option>
          <option value={'Carlton'}>Carlton</option>
          <option value={'Carp'}>Carp</option>
          <option value={'Carruthers'}>Carruthers</option>
          <option value={'Carrying Place'}>Carrying Place</option>
          <option value={'Castleton'}>Castleton</option>
          <option value={'Cataraqui'}>Cataraqui</option>
          <option value={'Cavan'}>Cavan</option>
          <option value={'Cedar Point'}>Cedar Point</option>
          <option value={'Centerville'}>Centerville</option>
          <option value={'Chalk River'}>Chalk River</option>
          <option value={'Chatham'}>Chatham</option>
          <option value={'Chelmsford'}>Chelmsford</option>
          <option value={'Chesley'}>Chesley</option>
          <option value={'Chesterville'}>Chesterville</option>
          <option value={'Cilantro'}>Cilantro</option>
          <option value={'Clarksburg'}>Clarksburg</option>
          <option value={'Clarington'}>Clarington</option>
          <option value={'Clifford'}>Clifford</option>
          <option value={'Cobourg'}>Cobourg</option>
          <option value={'Cochrane'}>Cochrane</option>
          <option value={'Coldwater'}>Coldwater</option>
          <option value={'Collingwood'}>Collingwood</option>
          <option value={'Concord'}>Concord</option>
          <option value={'Constance Bay'}>Constance Bay</option>
          <option value={'Cookstown'}>Cookstown</option>
          <option value={'Cornwall'}>Cornwall</option>
          <option value={'Cottam'}>Cottam</option>
          <option value={'Courtice'}>Courtice</option>
          <option value={'Coventry'}>Coventry</option>
          <option value={'Craigleith'}>Craigleith</option>
          <option value={'Cranbrook'}>Cranbrook</option>
          <option value={'Creemore'}>Creemore</option>
          <option value={'Crystal Beach'}>Crystal Beach</option>
          <option value={'Cumberland'}>Cumberland</option>
          <option value={'Cumbrae'}>Cumbrae</option>
          <option value={'Curative'}>Curative</option>
          <option value={'Dairyville'}>Dairyville</option>
          <option value={'Danforth'}>Danforth</option>
          <option value={'Darlington'}>Darlington</option>
          <option value={'Dartmouth'}>Dartmouth</option>
          <option value={'Davisville'}>Davisville</option>
          <option value={'Dayton'}>Dayton</option>
          <option value={'Deep River'}>Deep River</option>
          <option value={'Delhi'}>Delhi</option>
          <option value={'Derry'}>Derry</option>
          <option value={'Deseronto'}>Deseronto</option>
          <option value={'Detroit'}>Detroit</option>
          <option value={'Dewar'}>Dewar</option>
          <option value={'Dewitt'}>Dewitt</option>
          <option value={'Dublin'}>Dublin</option>
          <option value={'Dugald'}>Dugald</option>
          <option value={'Dundas'}>Dundas</option>
          <option value={'Dunsford'}>Dunsford</option>
          <option value={'Dutton'}>Dutton</option>
          <option value={'Earlton'}>Earlton</option>
          <option value={'Eaton'}>Eaton</option>
          <option value={'East End'}>East End</option>
          <option value={'East York'}>East York</option>
          <option value={'East Wawa'}>East Wawa</option>
          <option value={'Eastgate'}>Eastgate</option>
          <option value={'Eganville'}>Eganville</option>
          <option value={'Elliot Lake'}>Elliot Lake</option>
          <option value={'Elmira'}>Elmira</option>
          <option value={'Elora'}>Elora</option>
          <option value={'Elphin'}>Elphin</option>
          <option value={'Emo'}>Emo</option>
          <option value={'Erin'}>Erin</option>
          <option value={'Essex'}>Essex</option>
          <option value={'Etobicoke'}>Etobicoke</option>
          <option value={'Euphrasia'}>Euphrasia</option>
          <option value={'Fergus'}>Fergus</option>
          <option value={'Flesherton'}>Flesherton</option>
          <option value={'Forest'}>Forest</option>
          <option value={'Fort Erie'}>Fort Erie</option>
          <option value={'Fort Frances'}>Fort Frances</option>
          <option value={'Fort William'}>Fort William</option>
          <option value={'Fowlers Corners'}>Fowlers Corners</option>
          <option value={'Foxboro'}>Foxboro</option>
          <option value={'Frankford'}>Frankford</option>
          <option value={'Fresno'}>Fresno</option>
          <option value={'Galt'}>Galt</option>
          <option value={'Gananoque'}>Gananoque</option>
          <option value={'Garratt'}>Garratt</option>
          <option value={'Garry'}>Garry</option>
          <option value={'Georgetown'}>Georgetown</option>
          <option value={'Goderich'}>Goderich</option>
          <option value={'Golden'}>Golden</option>
          <option value={'Goodwood'}>Goodwood</option>
          <option value={'Gore Bay'}>Gore Bay</option>
          <option value={'Grand Bend'}>Grand Bend</option>
          <option value={'Grand Valley'}>Grand Valley</option>
          <option value={'Gravenhurst'}>Gravenhurst</option>
          <option value={'Guelph'}>Guelph</option>
          <option value={'Gull River'}>Gull River</option>
          <option value={'Hagersville'}>Hagersville</option>
          <option value={'Haliburton'}>Haliburton</option>
          <option value={'Hamilton'}>Hamilton</option>
          <option value={'Hampton'}>Hampton</option>
          <option value={'Hanover'}>Hanover</option>
          <option value={'Havelock'}>Havelock</option>
          <option value={'Hawkesbury'}>Hawkesbury</option>
          <option value={'Hickson'}>Hickson</option>
          <option value={'Hilliard'}>Hilliard</option>
          <option value={'Holland Landing'}>Holland Landing</option>
          <option value={'Holland Marsh'}>Holland Marsh</option>
          <option value={'Holstein'}>Holstein</option>
          <option value={'Horton'}>Horton</option>
          <option value={'Hudson'}>Hudson</option>
          <option value={'Huntsville'}>Huntsville</option>
          <option value={'Ingersoll'}>Ingersoll</option>
          <option value={'Inverhuron'}>Inverhuron</option>
          <option value={'Iroquois'}>Iroquois</option>
          <option value={'Jacksons Point'}>Jacksons Point</option>
          <option value={'Jaffray'}>Jaffray</option>
          <option value={'Jarvis'}>Jarvis</option>
          <option value={'Johnstown'}>Johnstown</option>
          <option value={'Kaministiquia'}>Kaministiquia</option>
          <option value={'Kanata'}>Kanata</option>
          <option value={'Kars'}>Kars</option>
          <option value={'Kawartha Lakes'}>Kawartha Lakes</option>
          <option value={'Kenora'}>Kenora</option>
          <option value={'Kettleby'}>Kettleby</option>
          <option value={'Kingston'}>Kingston</option>
          <option value={'Kingsville'}>Kingsville</option>
          <option value={'Kincardine'}>Kincardine</option>
          <option value={'Kirkland Lake'}>Kirkland Lake</option>
          <option value={'Kitchener'}>Kitchener</option>
          <option value={'Kleinburg'}>Kleinburg</option>
          <option value={'Lachine'}>Lachine</option>
          <option value={'Lakeshore'}>Lakeshore</option>
          <option value={'Lambton Shores'}>Lambton Shores</option>
          <option value={'Lakefield'}>Lakefield</option>
          <option value={'Lambton'}>Lambton</option>
          <option value={'Langton'}>Langton</option>
          <option value={'Lansdowne'}>Lansdowne</option>
          <option value={'Leamington'}>Leamington</option>
          <option value={'Lindsay'}>Lindsay</option>
          <option value={'Little Current'}>Little Current</option>
          <option value={'Lively'}>Lively</option>
          <option value={'London'}>London</option>
          <option value={'Long Sault'}>Long Sault</option>
          <option value={'Loretto'}>Loretto</option>
          <option value={'Lorne Park'}>Lorne Park</option>
          <option value={'Madoc'}>Madoc</option>
          <option value={'Malton'}>Malton</option>
          <option value={'Mansfield'}>Mansfield</option>
          <option value={'Markdale'}>Markdale</option>
          <option value={'Markham'}>Markham</option>
          <option value={'Marmora'}>Marmora</option>
          <option value={'Matachewan'}>Matachewan</option>
          <option value={'Mattawa'}>Mattawa</option>
          <option value={'Meaford'}>Meaford</option>
          <option value={'Milton'}>Milton</option>
          <option value={'Millbrook'}>Millbrook</option>
          <option value={'Minden'}>Minden</option>
          <option value={'Mississauga'}>Mississauga</option>
          <option value={'Mitchell'}>Mitchell</option>
          <option value={'Moore Park'}>Moore Park</option>
          <option value={'Morrison'}>Morrison</option>
          <option value={'Muskoka'}>Muskoka</option>
          <option value={'Napanee'}>Napanee</option>
          <option value={'Nobleton'}>Nobleton</option>
          <option value={'North Bay'}>North Bay</option>
          <option value={'North Gower'}>North Gower</option>
          <option value={'North York'}>North York</option>
          <option value={'Norwood'}>Norwood</option>
          <option value={'Oakville'}>Oakville</option>
          <option value={'Ogdensburg'}>Ogdensburg</option>
          <option value={'Orillia'}>Orillia</option>
          <option value={'Ottawa'}>Ottawa</option>
          <option value={'Owen Sound'}>Owen Sound</option>
          <option value={'Palmerston'}>Palmerston</option>
          <option value={'Parry Sound'}>Parry Sound</option>
          <option value={'Pembroke'}>Pembroke</option>
          <option value={'Penetanguishene'}>Penetanguishene</option>
          <option value={'Perth'}>Perth</option>
          <option value={'Petawawa'}>Petawawa</option>
          <option value={'Picton'}>Picton</option>
          <option value={'Port Colborne'}>Port Colborne</option>
          <option value={'Port Hope'}>Port Hope</option>
          <option value={'Port Perry'}>Port Perry</option>
          <option value={'Preston'}>Preston</option>
          <option value={'Prince Edward'}>Prince Edward</option>
          <option value={'Queensville'}>Queensville</option>
          <option value={'Quinte West'}>Quinte West</option>
          <option value={'Richmond Hill'}>Richmond Hill</option>
          <option value={'Richmond'}>Richmond</option>
          <option value={'Rockwood'}>Rockwood</option>
          <option value={'Rothesay'}>Rothesay</option>
          <option value={'Rundle'}>Rundle</option>
          <option value={'Russell'}>Russell</option>
          <option value={'Sarnia'}>Sarnia</option>
          <option value={'Sault Ste. Marie'}>Sault Ste. Marie</option>
          <option value={'Scarborough'}>Scarborough</option>
          <option value={'Seaforth'}>Seaforth</option>
          <option value={'Selkirk'}>Selkirk</option>
          <option value={'Smiths Falls'}>Smiths Falls</option>
          <option value={'Southampton'}>Southampton</option>
          <option value={'St. Catharines'}>St. Catharines</option>
          <option value={'St. Thomas'}>St. Thomas</option>
          <option value={'Stirling'}>Stirling</option>
          <option value={'Stonewall'}>Stonewall</option>
          <option value={'Stratford'}>Stratford</option>
          <option value={'Sudbury'}>Sudbury</option>
          <option value={'Tavistock'}>Tavistock</option>
          <option value={'Thamesford'}>Thamesford</option>
          <option value={'Thornhill'}>Thornhill</option>
          <option value={'Thunder Bay'}>Thunder Bay</option>
          <option value={'Tillsonburg'}>Tillsonburg</option>
          <option value={'Tobermory'}>Tobermory</option>
          <option value={'Trenton'}>Trenton</option>
          <option value={'Tweed'}>Tweed</option>
          <option value={'Uxbridge'}>Uxbridge</option>
          <option value={'Vankleek Hill'}>Vankleek Hill</option>
          <option value={'Vaughan'}>Vaughan</option>
          <option value={'Wallaceburg'}>Wallaceburg</option>
          <option value={'Wellington'}>Wellington</option>
          <option value={'Wellsley'}>Wellsley</option>
          <option value={'West End'}>West End</option>
          <option value={'West Hill'}>West Hill</option>
          <option value={'West Lincoln'}>West Lincoln</option>
          <option value={'West Toronto'}>West Toronto</option>
          <option value={'Whitby'}>Whitby</option>
          <option value={'Willowdale'}>Willowdale</option>
          <option value={'Windsor'}>Windsor</option>
          <option value={'Woodstock'}>Woodstock</option>
          <option value={'York'}>York</option>
          <option value={'York Mills'}>York Mills</option>
          <option value={'Zephyr'}>Zephyr</option>
          <option value={'Zurich'}>Zurich</option>
        </>
      }
      {province === 'PE' &&
        <>
          <option value={''}>Seleccione</option>
          <option value="Albany, PE">Albany, PE</option>
          <option value="Alberton">Alberton</option>
          <option value="Belfast">Belfast</option>
          <option value="Birch Hill">Birch Hill</option>
          <option value="Bloomfield, PE">Bloomfield, PE</option>
          <option value="Bonshaw">Bonshaw</option>
          <option value="Borden, PE">Borden, PE</option>
          <option value="Breadalbane">Breadalbane</option>
          <option value="Bunbury">Bunbury</option>
          <option value="Cardigan">Cardigan</option>
          <option value="Central Bedeque">Central Bedeque</option>
          <option value="Charlottetown">Charlottetown</option>
          <option value="Cornwall, PE">Cornwall, PE</option>
          <option value="Crapaud">Crapaud</option>
          <option value="French River">French River</option>
          <option value="Garfield">Garfield</option>
          <option value="Georgetown, PE">Georgetown, PE</option>
          <option value="Hampton">Hampton</option>
          <option value="Hunter River, PE">Hunter River, PE</option>
          <option value="Kensington">Kensington</option>
          <option value="Kinkora">Kinkora</option>
          <option value="Miminegash">Miminegash</option>
          <option value="Miscouche">Miscouche</option>
          <option value="Montague">Montague</option>
          <option value="Morell">Morell</option>
          <option value="Mount Stewart">Mount Stewart</option>
          <option value="Murray Harbour">Murray Harbour</option>
          <option value="Murray River">Murray River</option>
          <option value="New Glasgow, PE">New Glasgow, PE</option>
          <option value="North Rustico">North Rustico</option>
          <option value="O'Leary">O'Leary</option>
          <option value="Other">Other</option>
          <option value="Parkdale, PE">Parkdale, PE</option>
          <option value="Richmond, PE">Richmond, PE</option>
          <option value="Rosebank">Rosebank</option>
          <option value="Sherwood, PE">Sherwood, PE</option>
          <option value="Slemon Park">Slemon Park</option>
          <option value="Souris, PE">Souris, PE</option>
          <option value="South Port">South Port</option>
          <option value="St. Eleanor's">St. Eleanor's</option>
          <option value="St. Louis, PE">St. Louis, PE</option>
          <option value="St. Peters Bay">St. Peters Bay</option>
          <option value="Stratford">Stratford</option>
          <option value="Summerside">Summerside</option>
          <option value="Tignish">Tignish</option>
          <option value="Tyne Valley">Tyne Valley</option>
          <option value="Victoria, PE">Victoria, PE</option>
          <option value="Wellington, PE">Wellington, PE</option>
          <option value="Wilmot, PE">Wilmot, PE</option>
          <option value="Winsloe">Winsloe</option>
        </>
      }
      {province === 'QC' &&
        <>
          <option value={''}>Seleccione</option>
          <option value={'Abercorn'}>Abercorn</option>
          <option value={'Abitibi'}>Abitibi</option>
          <option value="ActonVale">ActonVale</option>
          <option value="Adamsville">Adamsville</option>
          <option value="Adstock">Adstock</option>
          <option value="Ahuntsic">Ahuntsic</option>
          <option value="Albanel">Albanel</option>
          <option value="Alma, QC">Alma, QC</option>
          <option value="A-Ma-Baie">A-Ma-Baie</option>
          <option value="Amos">Amos</option>
          <option value="Amqui">Amqui</option>
          <option value="Andreville">Andreville</option>
          <option value="Angers">Angers</option>
          <option value="Anjou">Anjou</option>
          <option value="Annaville">Annaville</option>
          <option value="Armagh">Armagh</option>
          <option value="Arthabaska">Arthabaska</option>
          <option value="Arvida">Arvida</option>
          <option value="Asbestos">Asbestos</option>
          <option value="Ascot Corner">Ascot Corner</option>
          <option value="Aston Jonction">Aston Jonction</option>
          <option value="Athelston">Athelston</option>
          <option value="Ayer`s Cliff">Ayer`s Cliff</option>
          <option value="Aylmer, QC">Aylmer, QC</option>
          <option value="Bagotville">Bagotville</option>
          <option value="Baie de Shawinigan">Baie de Shawinigan</option>
          <option value="Baie-Comeau">Baie-Comeau</option>
          <option value="Baie-des-Sables">Baie-des-Sables</option>
          <option value="Baie-du-Febvre">Baie-du-Febvre</option>
          <option value="Baie-du-Poste">Baie-du-Poste</option>
          <option value="Baie-d`Urfe">Baie-d`Urfe</option>
          <option value="Baie-Saint-Paul">Baie-Saint-Paul</option>
          <option value="Baie-Trinite">Baie-Trinite</option>
          <option value="Baieville">Baieville</option>
          <option value="Barkmere">Barkmere</option>
          <option value="Barraute">Barraute</option>
          <option value="Barville">Barville</option>
          <option value="Beaconsfield, QC">Beaconsfield, QC</option>
          <option value="Beattyville">Beattyville</option>
          <option value="Beauce">Beauce</option>
          <option value="Beauceville">Beauceville</option>
          <option value="Beauharnois">Beauharnois</option>
          <option value="Beaulac">Beaulac</option>
          <option value="Beaulieu">Beaulieu</option>
          <option value="Beauport">Beauport</option>
          <option value="Beaupre">Beaupre</option>
          <option value="Beaurepaire">Beaurepaire</option>
          <option value="Becancour">Becancour</option>
          <option value="Bedford, QC">Bedford, QC</option>
          <option value="Beebe Plain">Beebe Plain</option>
          <option value="Belair">Belair</option>
          <option value="Bellefeuille">Bellefeuille</option>
          <option value="Belleterre">Belleterre</option>
          <option value="Beloeil">Beloeil</option>
          <option value="Bernieres">Bernieres</option>
          <option value="Bernierville">Bernierville</option>
          <option value="Berthierville">Berthierville</option>
          <option value="Bishopton">Bishopton</option>
          <option value="Black Lake">Black Lake</option>
          <option value="Blainville">Blainville</option>
          <option value="Boileau">Boileau</option>
          <option value="Boisbriand">Boisbriand</option>
          <option value="Boisbuard">Boisbuard</option>
          <option value="Boischatel">Boischatel</option>
          <option value="Bois-des-Filion">Bois-des-Filion</option>
          <option value="Bolton-Ouest">Bolton-Ouest</option>
          <option value="Bonaventure">Bonaventure</option>
          <option value="Boucherville">Boucherville</option>
          <option value="Brebeuf">Brebeuf</option>
          <option value="Brigham">Brigham</option>
          <option value="Brome">Brome</option>
          <option value="Bromont">Bromont</option>
          <option value="Bromptonville">Bromptonville</option>
          <option value="Brossard">Brossard</option>
          <option value="Brownsburg">Brownsburg</option>
          <option value="Bryson">Bryson</option>
          <option value="Buckingham">Buckingham</option>
          <option value="Cabano">Cabano</option>
          <option value="Cadillac, QC">Cadillac, QC</option>
          <option value="Calixa-Lavallee">Calixa-Lavallee</option>
          <option value="Calumet">Calumet</option>
          <option value="Campbell`s Bay">Campbell`s Bay</option>
          <option value="Candiac">Candiac</option>
          <option value="Cantley">Cantley</option>
          <option value="Cap-a-l`Aigle">Cap-a-l`Aigle</option>
          <option value="Cap-aux-Meules">Cap-aux-Meules</option>
          <option value="Cap-Chat">Cap-Chat</option>
          <option value="Cap-de-la-Madeleine">Cap-de-la-Madeleine</option>
          <option value="Caplan">Caplan</option>
          <option value="Cap-Rouge">Cap-Rouge</option>
          <option value="Cap-Santé">Cap-Santé</option>
          <option value="Carignan">Carignan</option>
          <option value="Carillon">Carillon</option>
          <option value="Carleton">Carleton</option>
          <option value="Cartierville">Cartierville</option>
          <option value="Causapscal">Causapscal</option>
          <option value="Chambly">Chambly</option>
          <option value="Chambord">Chambord</option>
          <option value="Champlain">Champlain</option>
          <option value="Chandler">Chandler</option>
          <option value="Chapais">Chapais</option>
          <option value="Chapeau">Chapeau</option>
          <option value="Charlemagne">Charlemagne</option>
          <option value="Charlesbourg, QC">Charlesbourg, QC</option>
          <option value="Charny">Charny</option>
          <option value="Chateauguay">Chateauguay</option>
          <option value="Chateauguay Centre">Chateauguay Centre</option>
          <option value="Chateau-Richer">Chateau-Richer</option>
          <option value="Chelsea">Chelsea</option>
          <option value="Cheneville">Cheneville</option>
          <option value="Chertsey, QC">Chertsey, QC</option>
          <option value="Chesterville, QC">Chesterville, QC</option>
          <option value="Chibougamau">Chibougamau</option>
          <option value="Chicoutimi">Chicoutimi</option>
          <option value="Chicoutimi Nord">Chicoutimi Nord</option>
          <option value="Chisasibi">Chisasibi</option>
          <option value="Chomedy">Chomedy</option>
          <option value="Chute-aux-Outardes">Chute-aux-Outardes</option>
          <option value="Clarenceville">Clarenceville</option>
          <option value="Clermont">Clermont</option>
          <option value="Clova">Clova</option>
          <option value="Coaticook">Coaticook</option>
          <option value="Compton">Compton</option>
          <option value="Contrecoeur">Contrecoeur</option>
          <option value="Cookshire">Cookshire</option>
          <option value="Coteau-du-Lac">Coteau-du-Lac</option>
          <option value="Coteau-Landing">Coteau-Landing</option>
          <option value="Coteau-des-Neiges">Coteau-des-Neiges</option>
          <option value="Cote-Sainte-Catherine">Cote-Sainte-Catherine</option>
          <option value="Cote-Saint-Luc">Cote-Saint-Luc</option>
          <option value="Courcelles">Courcelles</option>
          <option value="Courville">Courville</option>
          <option value="Cowansville">Cowansville</option>
          <option value="Crabtree">Crabtree</option>
          <option value="Dalesville">Dalesville</option>
          <option value="Danville">Danville</option>
          <option value="Daveluyville">Daveluyville</option>
          <option value="De Grasse">De Grasse</option>
          <option value="Deauville">Deauville</option>
          <option value="Degelis">Degelis</option>
          <option value="Delson">Delson</option>
          <option value="Desbiens">Desbiens</option>
          <option value="Deschaillons">Deschaillons</option>
          <option value="Deschaillons-sur-Saint-Laurent">Deschaillons-sur-Saint-Laurent</option>
          <option value="Deschambault">Deschambault</option>
          <option value="Deschenes">Deschenes</option>
          <option value="Deux-Montagnes">Deux-Montagnes</option>
          <option value="Dixville">Dixville</option>
          <option value="Dolbeau">Dolbeau</option>
          <option value="Dollard-des-Ormeaux">Dollard-des-Ormeaux</option>
          <option value="Donnacona">Donnacona</option>
          <option value="Dorion">Dorion</option>
          <option value="Dorval">Dorval</option>
          <option value="Dosquet">Dosquet</option>
          <option value="Douville">Douville</option>
          <option value="Drummondville">Drummondville</option>
          <option value="Duberger">Duberger</option>
          <option value="Duclos">Duclos</option>
          <option value="Dunham">Dunham</option>
          <option value="Duparquet">Duparquet</option>
          <option value="Durham South, QC">Durham South, QC</option>
          <option value="Duvernay">Duvernay</option>
          <option value="East Angus">East Angus</option>
          <option value="East Broughton St">East Broughton St</option>
          <option value="East Farnham">East Farnham</option>
          <option value="East Hereford">East Hereford</option>
          <option value="Eastman">Eastman</option>
          <option value="Esterel">Esterel</option>
          <option value="Evain">Evain</option>
          <option value="Fabre">Fabre</option>
          <option value="Fabreville">Fabreville</option>
          <option value="Farnham">Farnham</option>
          <option value="Ferme-Neuve">Ferme-Neuve</option>
          <option value="Fermont">Fermont</option>
          <option value="Filford">Filford</option>
          <option value="Fleurmont">Fleurmont</option>
          <option value="Forestville">Forestville</option>
          <option value="Fort-Coulonge">Fort-Coulonge</option>
          <option value="Fortierville">Fortierville</option>
          <option value="Fossambault-sur-le-Lac">Fossambault-sur-le-Lac</option>
          <option value="Foster">Foster</option>
          <option value="Francoeur">Francoeur</option>
          <option value="Franklin">Franklin</option>
          <option value="Frelighsburg">Frelighsburg</option>
          <option value="Gagnon">Gagnon</option>
          <option value="Gaspe">Gaspe</option>
          <option value="Gatineau">Gatineau</option>
          <option value="Gentilly">Gentilly</option>
          <option value="Giffard">Giffard</option>
          <option value="Girardville">Girardville</option>
          <option value="Glenn Sutton">Glenn Sutton</option>
          <option value="Godbout">Godbout</option>
          <option value="Godmanchester">Godmanchester</option>
          <option value="Gracefield">Gracefield</option>
          <option value="Granby">Granby</option>
          <option value="Grande-Ile">Grande-Ile</option>
          <option value="Grandes-Bergeronnes">Grandes-Bergeronnes</option>
          <option value="Grande-Vallee">Grande-Vallee</option>
          <option value="Grand-Vallee">Grand-Vallee</option>
          <option value="Grand-Mere">Grand-Mere</option>
          <option value="Greece`s Point">Greece`s Point</option>
          <option value="Greenfield Park">Greenfield Park</option>
          <option value="Grenville">Grenville</option>
          <option value="Ham-Nord">Ham-Nord</option>
          <option value="Hampstead">Hampstead</option>
          <option value="Hatley">Hatley</option>
          <option value="Hauterive">Hauterive</option>
          <option value="Hebertville-Station">Hebertville-Station</option>
          <option value="Hemmingford">Hemmingford</option>
          <option value="Henryville">Henryville</option>
          <option value="Howick">Howick</option>
          <option value="Hudson">Hudson</option>
          <option value="Hudson Heights">Hudson Heights</option>
          <option value="Hull">Hull</option>
          <option value="Huntingdon, QC">Huntingdon, QC</option>
          <option value="Iberville">Iberville</option>
          <option value="Ile Charron">Ile Charron</option>
          <option value="Ile Claude">Ile Claude</option>
          <option value="Ile Dorval">Ile Dorval</option>
          <option value="Ile Laval">Ile Laval</option>
          <option value="Ile-aux-Courdres">Ile-aux-Courdres</option>
          <option value="Ile-Bizard">Ile-Bizard</option>
          <option value="Ile-Cadieux">Ile-Cadieux</option>
          <option value="Ile-de-la-Visitation">Ile-de-la-Visitation</option>
          <option value="Ile-des-Soeurs">Ile-des-Soeurs</option>
          <option value="Ile-d`Orlea">Ile-d`Orlea</option>
          <option value="Ile-Michon">Ile-Michon</option>
          <option value="Ile-Perrot">Ile-Perrot</option>
          <option value="Iles-de-la-Madeleine">Iles-de-la-Madeleine</option>
          <option value="Iles-d'Entree">Iles-d'Entree</option>
          <option value="Inverness, QC">Inverness, QC</option>
          <option value="Joliette">Joliette</option>
          <option value="Jonquiere">Jonquiere</option>
          <option value="Joutel">Joutel</option>
          <option value="Kahnawake">Kahnawake</option>
          <option value="Kamouraska">Kamouraska</option>
          <option value="Kenogami">Kenogami</option>
          <option value="Kingsbury">Kingsbury</option>
          <option value="Kingsey Falls">Kingsey Falls</option>
          <option value="Kinnear">Kinnear</option>
          <option value="Kirkland">Kirkland</option>
          <option value="Knowlton">Knowlton</option>
          <option value="La Baie">La Baie</option>
          <option value="La Guadeloupe">La Guadeloupe</option>
          <option value="La Macaza">La Macaza</option>
          <option value="La Malbaie">La Malbaie</option>
          <option value="La Patrie">La Patrie</option>
          <option value="La Peche">La Peche</option>
          <option value="La Perade">La Perade</option>
          <option value="La Plaine">La Plaine</option>
          <option value="La Pocatiere">La Pocatiere</option>
          <option value="La Prairie">La Prairie</option>
          <option value="La Presentation">La Presentation</option>
          <option value="La Providence">La Providence</option>
          <option value="La Reine">La Reine</option>
          <option value="La Sarre">La Sarre</option>
          <option value="La station du Cot">La station du Cot</option>
          <option value="La Tuque">La Tuque</option>
          <option value="La Visitation">La Visitation</option>
          <option value="Labelle">Labelle</option>
          <option value="L'Acadie">L'Acadie</option>
          <option value="Lac-a-la-Croix">Lac-a-la-Croix</option>
          <option value="Lac-au-Saumon">Lac-au-Saumon</option>
          <option value="Lac-Beauport">Lac-Beauport</option>
          <option value="Lac-Bouchette">Lac-Bouchette</option>
          <option value="Lac-Brome">Lac-Brome</option>
          <option value="Lac-Carre">Lac-Carre</option>
          <option value="Lac-Delage">Lac-Delage</option>
          <option value="Lac-des-Ecorces">Lac-des-Ecorces</option>
          <option value="Lac-Etchemin">Lac-Etchemin</option>
          <option value="Lachenaie">Lachenaie</option>
          <option value="Lachine">Lachine</option>
          <option value="Lachute">Lachute</option>
          <option value="Lac-Masson">Lac-Masson</option>
          <option value="Lac-Megantic">Lac-Megantic</option>
          <option value="Lacolle">Lacolle</option>
          <option value="Lac-Poulin">Lac-Poulin</option>
          <option value="Lac-Saint-Charles">Lac-Saint-Charles</option>
          <option value="Lac-Sainte-Marie">Lac-Sainte-Marie</option>
          <option value="Lac-Saint-Jean">Lac-Saint-Jean</option>
          <option value="Lac-Saint-Joseph">Lac-Saint-Joseph</option>
          <option value="Lac-Saint-Paul">Lac-Saint-Paul</option>
          <option value="Lac-Sergent">Lac-Sergent</option>
          <option value="Lac-Superieur">Lac-Superieur</option>
          <option value="Lafleche, QC">Lafleche, QC</option>
          <option value="Lafontaine">Lafontaine</option>
          <option value="Lambton">Lambton</option>
          <option value="L'Ancienne-Lorette">L'Ancienne-Lorette</option>
          <option value="L'Ange-Gardien">L'Ange-Gardien</option>
          <option value="Langeliers">Langeliers</option>
          <option value="L'Annonciation">L'Annonciation</option>
          <option value="L'Anse-Saint-Jean">L'Anse-Saint-Jean</option>
          <option value="LaSalle">LaSalle</option>
          <option value="Lasavanne">Lasavanne</option>
          <option value="L'Assomption">L'Assomption</option>
          <option value="Laterriere">Laterriere</option>
          <option value="Laurentides">Laurentides</option>
          <option value="Laurier-Station">Laurier-Station</option>
          <option value="Laurierville">Laurierville</option>
          <option value="Lauzon">Lauzon</option>
          <option value="Laval">Laval</option>
          <option value="Laval des Rapides">Laval des Rapides</option>
          <option value="Lavaltrie">Lavaltrie</option>
          <option value="L'Avenir">L'Avenir</option>
          <option value="Lawrenceville">Lawrenceville</option>
          <option value="Le Bic">Le Bic</option>
          <option value="Le Gardeur">Le Gardeur</option>
          <option value="Lebel-sur-Quevillon">Lebel-sur-Quevillon</option>
          <option value="Leclercville">Leclercville</option>
          <option value="Lemieux">Lemieux</option>
          <option value="LeMoyne">LeMoyne</option>
          <option value="Lennoxville">Lennoxville</option>
          <option value="L'Epiphanie">L'Epiphanie</option>
          <option value="Lery">Lery</option>
          <option value="Les Becquets">Les Becquets</option>
          <option value="Les Cedres">Les Cedres</option>
          <option value="Levis">Levis</option>
          <option value="Limoilou">Limoilou</option>
          <option value="Liniere">Liniere</option>
          <option value="L'Isle Verte">L'Isle Verte</option>
          <option value="L'Islet">L'Islet</option>
          <option value="Longue-Pointe">Longue-Pointe</option>
          <option value="Longueuil">Longueuil</option>
          <option value="Loretteville">Loretteville</option>
          <option value="Lorraine">Lorraine</option>
          <option value="Lorrainville">Lorrainville</option>
          <option value="Lotbiniere">Lotbiniere</option>
          <option value="Louiseville">Louiseville</option>
          <option value="Luceville">Luceville</option>
          <option value="Luskville">Luskville</option>
          <option value="Lyster">Lyster</option>
          <option value="Macamic">Macamic</option>
          <option value="Magog">Magog</option>
          <option value="Malartic">Malartic</option>
          <option value="Malbaie">Malbaie</option>
          <option value="Maniwaki">Maniwaki</option>
          <option value="Manseau">Manseau</option>
          <option value="Mansonville">Mansonville</option>
          <option value="Maple Grove">Maple Grove</option>
          <option value="Marbleton">Marbleton</option>
          <option value="Maria">Maria</option>
          <option value="Marieville">Marieville</option>
          <option value="Marsoui">Marsoui</option>
          <option value="Mascouche">Mascouche</option>
          <option value="Maskinonge">Maskinonge</option>
          <option value="Masson">Masson</option>
          <option value="Massueville">Massueville</option>
          <option value="Matagami">Matagami</option>
          <option value="Matane">Matane</option>
          <option value="Mcmasterville">Mcmasterville</option>
          <option value="Melbourne">Melbourne</option>
          <option value="Melocheville">Melocheville</option>
          <option value="Mercier">Mercier</option>
          <option value="Metabetchouan">Metabetchouan</option>
          <option value="Metis-sur-Mer">Metis-sur-Mer</option>
          <option value="Mirabel">Mirabel</option>
          <option value="Mistassini">Mistassini</option>
          <option value="Moffet">Moffet</option>
          <option value="Mont-Laurier">Mont-Laurier</option>
          <option value="Mont-Saint-Pierre">Mont-Saint-Pierre</option>
          <option value="Mont-Apica">Mont-Apica</option>
          <option value="Montauban">Montauban</option>
          <option value="Mont-Carmel">Mont-Carmel</option>
          <option value="Montcerf">Montcerf</option>
          <option value="Montebello">Montebello</option>
          <option value="Mont-Gabriel">Mont-Gabriel</option>
          <option value="Mont-Joli">Mont-Joli</option>
          <option value="Montmagny">Montmagny</option>
          <option value="Montmorency">Montmorency</option>
          <option value="Montreal">Montreal</option>
          <option value="Montreal Centre-Nord">Montreal Centre-Nord</option>
          <option value="Montreal - Est">Montreal - Est</option>
          <option value="Montréal - Nord">Montréal - Nord</option>
          <option value="Montreal - Nord-Est">Montreal - Nord-Est</option>
          <option value="Montreal - Nord-Ouest">Montreal - Nord-Ouest</option>
          <option value="Montréal - Ouest">Montréal - Ouest</option>
          <option value="Montreal - Sud-Est">Montreal - Sud-Est</option>
          <option value="Mont-Rolland">Mont-Rolland</option>
          <option value="Mont-Royal">Mont-Royal</option>
          <option value="Mont-Saint-Gregoire">Mont-Saint-Gregoire</option>
          <option value="Mont-Saint-Hilaire">Mont-Saint-Hilaire</option>
          <option value="Mont-Tremblant">Mont-Tremblant</option>
          <option value="Morin-Heights">Morin-Heights</option>
          <option value="Murdochville">Murdochville</option>
          <option value="Napierville">Napierville</option>
          <option value="Neufchatel">Neufchatel</option>
          <option value="Neuville">Neuville</option>
          <option value="New Carlisle">New Carlisle</option>
          <option value="New Glasgow, QC">New Glasgow, QC</option>
          <option value="New Richmond">New Richmond</option>
          <option value="Nicolet">Nicolet</option>
          <option value="Nitro">Nitro</option>
          <option value="Nominingue">Nominingue</option>
          <option value="Noranda">Noranda</option>
          <option value="Norbertville">Norbertville</option>
          <option value="Normandin">Normandin</option>
          <option value="North Hatley">North Hatley</option>
          <option value="Notre Dame de Bonsecours">Notre Dame de Bonsecours</option>
          <option value="Notre Dame de Grace">Notre Dame de Grace</option>
          <option value="Notre Dame De L`Ile Perrot">Notre Dame De L`Ile Perrot</option>
          <option value="Notre Dame de Pierrevile">Notre Dame de Pierrevile</option>
          <option value="Notre-Dame-des-Anges">Notre-Dame-des-Anges</option>
          <option value="Notre Dame des Laurentides">Notre Dame des Laurentides</option>
          <option value="Notre Dame des Pins">Notre Dame des Pins</option>
          <option value="Notre Dame Des Prairies">Notre Dame Des Prairies</option>
          <option value="Notre Dame-d'Hebertville">Notre Dame-d'Hebertville</option>
          <option value="Notre-Dame-du-Bon-Conseil">Notre-Dame-du-Bon-Conseil</option>
          <option value="Notre-Dame-du-Lac">Notre-Dame-du-Lac</option>
          <option value="Notre-Dame-du-Sacre-Coeur">Notre-Dame-du-Sacre-Coeur</option>
          <option value="Noyan">Noyan</option>
          <option value="Oka">Oka</option>
          <option value="Omerville">Omerville</option>
          <option value="Orford">Orford</option>
          <option value="Ormstown">Ormstown</option>
          <option value="Orsainville">Orsainville</option>
          <option value="Other">Other</option>
          <option value="Otterburn Park">Otterburn Park</option>
          <option value="Outremont">Outremont</option>
          <option value="Packington">Packington</option>
          <option value="Pagenteuil">Pagenteuil</option>
          <option value="Papineauville">Papineauville</option>
          <option value="Parent">Parent</option>
          <option value="Pascalis">Pascalis</option>
          <option value="Paspebiac">Paspebiac</option>
          <option value="Pentecote">Pentecote</option>
          <option value="Perce">Perce</option>
          <option value="Perkins">Perkins</option>
          <option value="Petite-Riviere-St-Francois">Petite-Riviere-St-Francois</option>
          <option value="Petite-Saguenay">Petite-Saguenay</option>
          <option value="Philipsburg">Philipsburg</option>
          <option value="Piedmont">Piedmont</option>
          <option value="Pierrefonds Est">Pierrefonds Est</option>
          <option value="Pierrfonds Ouest">Pierrfonds Ouest</option>
          <option value="Pierreville">Pierreville</option>
          <option value="Pincourt">Pincourt</option>
          <option value="Pintendre">Pintendre</option>
          <option value="Plessisville">Plessisville</option>
          <option value="Pohenegamook">Pohenegamook</option>
          <option value="Point-au-Pere">Point-au-Pere</option>
          <option value="Point-au-Pic">Point-au-Pic</option>
          <option value="Point-Aux-Outardes">Point-Aux-Outardes</option>
          <option value="Pointe Aux Trembles">Pointe Aux Trembles</option>
          <option value="Pointe-Calumet">Pointe-Calumet</option>
          <option value="Pointe-Claire">Pointe-Claire</option>
          <option value="Pointe-des-Cascades">Pointe-des-Cascades</option>
          <option value="Pointe-du-Moulin">Pointe-du-Moulin</option>
          <option value="Pointe-Fortune">Pointe-Fortune</option>
          <option value="Pointe-Gatineau">Pointe-Gatineau</option>
          <option value="Pointe-Lebel">Pointe-Lebel</option>
          <option value="Pont-Rouge">Pont-Rouge</option>
          <option value="Pont-Viau">Pont-Viau</option>
          <option value="Portage-du-Fort">Portage-du-Fort</option>
          <option value="Port-Alfred">Port-Alfred</option>
          <option value="Port-Cartier">Port-Cartier</option>
          <option value="Portneuf">Portneuf</option>
          <option value="Povungnituk">Povungnituk</option>
          <option value="Preissac">Preissac</option>
          <option value="Preville">Preville</option>
          <option value="Prevost">Prevost</option>
          <option value="Price">Price</option>
          <option value="Princeville">Princeville</option>
          <option value="Proulxville">Proulxville</option>
          <option value="Quebec City">Quebec City</option>
          <option value="Quebec">Quebec</option>
          <option value="Quyon">Quyon</option>
          <option value="Radisson, QC">Radisson, QC</option>
          <option value="Rawdon">Rawdon</option>
          <option value="Repentigny">Repentigny</option>
          <option value="Richelieu">Richelieu</option>
          <option value="Richmond, QC">Richmond, QC</option>
          <option value="Rigaud">Rigaud</option>
          <option value="Rimouski">Rimouski</option>
          <option value="Rimouski-Est">Rimouski-Est</option>
          <option value="Ripon">Ripon</option>
          <option value="Riviere-Beaudette">Riviere-Beaudette</option>
          <option value="Riviere-des-Prairies">Riviere-des-Prairies</option>
          <option value="Riviere-du-Loup">Riviere-du-Loup</option>
          <option value="Riviere-du-Moulin">Riviere-du-Moulin</option>
          <option value="Riviere-Malbaie">Riviere-Malbaie</option>
          <option value="Robertsonville">Robertsonville</option>
          <option value="Roberval">Roberval</option>
          <option value="Rock Island">Rock Island</option>
          <option value="Rock Forest">Rock Forest</option>
          <option value="Rosemere">Rosemere</option>
          <option value="Rosemont">Rosemont</option>
          <option value="Rougemont">Rougemont</option>
          <option value="Rouyn-Noranda">Rouyn-Noranda</option>
          <option value="Roxboro">Roxboro</option>
          <option value="Roxton Falls">Roxton Falls</option>
          <option value="Roxton Pond">Roxton Pond</option>
          <option value="Saint Bernard-Lacolle">Saint Bernard-Lacolle</option>
          <option value="Sabrevois">Sabrevois</option>
          <option value="Sacre Coeur de Jesus">Sacre Coeur de Jesus</option>
          <option value="Saguenay">Saguenay</option>
          <option value="Saint-Adolphe d'Howard">Saint-Adolphe d'Howard</option>
          <option value="Saint-Anaclet de Lessard">Saint-Anaclet de Lessard</option>
          <option value="Saint-Andre Avellin">Saint-Andre Avellin</option>
          <option value="Saint-Andre d'Acton">Saint-Andre d'Acton</option>
          <option value="Saint-Andre du Lac-Saint-Jean">Saint-Andre du Lac-Saint-Jean</option>
          <option value="Saint-Antoine de Tilly">Saint-Antoine de Tilly</option>
          <option value="Saint-Basile le-Grand">Saint-Basile le-Grand</option>
          <option value="Saint-Boniface de Shawinigan">Saint-Boniface de Shawinigan</option>
          <option value="Saint-Bruno de Montarville">Saint-Bruno de Montarville</option>
          <option value="Saint-Charles Borromee">Saint-Charles Borromee</option>
          <option value="Saint-Charles des Grondines">Saint-Charles des Grondines</option>
          <option value="Saint Charles Sur Richelieu">Saint Charles Sur Richelieu</option>
          <option value="Saint Coeur de Marie">Saint Coeur de Marie</option>
          <option value="Saint Come">Saint Come</option>
          <option value="Saint David de L'Auberiviere">Saint David de L'Auberiviere</option>
          <option value="Saint Anne-de-Breaupre">Saint Anne-de-Breaupre</option>
          <option value="Saint Anne-de-Bellevue">Saint Anne-de-Bellevue</option>
          <option value="Sainte Cecile de Levrard">Sainte Cecile de Levrard</option>
          <option value="Sainte Cecile de Masham">Sainte Cecile de Masham</option>
          <option value="Sainte Cecile de Milton">Sainte Cecile de Milton</option>
          <option value="Sainte Claire">Sainte Claire</option>
          <option value="Sainte Dorothee">Sainte Dorothee</option>
          <option value="Sainte Emelie de L'Energie">Sainte Emelie de L'Energie</option>
          <option value="Sainte Felicite">Sainte Felicite</option>
          <option value="Sainte Foy">Sainte Foy</option>
          <option value="Sainte Genevieve">Sainte Genevieve</option>
          <option value="Sainte Genevieve de Batiscan">Sainte Genevieve de Batiscan</option>
          <option value="Sainte Helene de Bagot">Sainte Helene de Bagot</option>
          <option value="Sainte Jeanne d'Arc">Sainte Jeanne d'Arc</option>
          <option value="Sainte Julie">Sainte Julie</option>
          <option value="Saint-Elie d'Orford">Saint-Elie d'Orford</option>
          <option value="Sainte Marcelline de Kildare">Sainte Marcelline de Kildare</option>
          <option value="Sainte Marquerite Esterel">Sainte Marquerite Esterel</option>
          <option value="Sainte Marie Beauce">Sainte Marie Beauce</option>
          <option value="Sainte Marthe sur de Lac">Sainte Marthe sur de Lac</option>
          <option value="Sainte Melanie">Sainte Melanie</option>
          <option value="Sainte Ephrem De Tring">Sainte Ephrem De Tring</option>
          <option value="Sainte Scholastique">Sainte Scholastique</option>
          <option value="Sainte Sophie">Sainte Sophie</option>
          <option value="Sainte Sophie de Levrard">Sainte Sophie de Levrard</option>
          <option value="Sainte Esprit">Sainte Esprit</option>
          <option value="Sainte Therese Ouest">Sainte Therese Ouest</option>
          <option value="Saint Etienne de Lauzon">Saint Etienne de Lauzon</option>
          <option value="Sainte Felix de Valois">Sainte Felix de Valois</option>
          <option value="Saint Ferreol les Neiges">Saint Ferreol les Neiges</option>
          <option value="Saint Gedeon">Saint Gedeon</option>
          <option value="Saint Georges de Beauce">Saint Georges de Beauce</option>
          <option value="Saint Georges de Cacouna">Saint Georges de Cacouna</option>
          <option value="Saint Georges de Windsor">Saint Georges de Windsor</option>
          <option value="Saint Georges Ouest">Saint Georges Ouest</option>
          <option value="Saint Germain de Grantham">Saint Germain de Grantham</option>
          <option value="Saint Gregoire">Saint Gregoire</option>
          <option value="Saint Gregoire de Greenlay">Saint Gregoire de Greenlay</option>
          <option value="Saint Hermenegilde">Saint Hermenegilde</option>
          <option value="Saint Hilarion">Saint Hilarion</option>
          <option value="Saint Honore">Saint Honore</option>
          <option value="Saint Jean">Saint Jean</option>
          <option value="Saint Jean Baptiste de Nicolet">Saint Jean Baptiste de Nicolet</option>
          <option value="Saint Jean Chrysostome">Saint Jean Chrysostome</option>
          <option value="Saint Jean de Boischatel">Saint Jean de Boischatel</option>
          <option value="Saint Jean de Dieu">Saint Jean de Dieu</option>
          <option value="Saint Jean de Matha">Saint Jean de Matha</option>
          <option value="Saint Jean Port Joli">Saint Jean Port Joli</option>
          <option value="Saint Jean sur le Richelieu">Saint Jean sur le Richelieu</option>
          <option value="Saint Jean Vianney">Saint Jean Vianney</option>
          <option value="Saint Jerome">Saint Jerome</option>
          <option value="Saint Joseph, QC">Saint Joseph, QC</option>
          <option value="Saint Joseph de Beauce">Saint Joseph de Beauce</option>
          <option value="Saint Joseph de la Rive">Saint Joseph de la Rive</option>
          <option value="Saint Joseph de Levis">Saint Joseph de Levis</option>
          <option value="Saint Joseph de Sorel">Saint Joseph de Sorel</option>
          <option value="Saint Joseph du Lac">Saint Joseph du Lac</option>
          <option value="Saint Lambert">Saint Lambert</option>
          <option value="Saint Lazare">Saint Lazare</option>
          <option value="Saint Leon">Saint Leon</option>
          <option value="Saint Leonard, QC">Saint Leonard, QC</option>
          <option value="Saint Leonard d'Aston">Saint Leonard d'Aston</option>
          <option value="Saint Lin">Saint Lin</option>
          <option value="Saint Louis de Ha!-Ha">Saint Louis de Ha!-Ha</option>
          <option value="Saint Luc de Vincennes">Saint Luc de Vincennes</option>
          <option value="Saint Ludger">Saint Ludger</option>
          <option value="Saint Marc sur Richelieu">Saint Marc sur Richelieu</option>
          <option value="Saint Marc des Carrieres">Saint Marc des Carrieres</option>
          <option value="Saint Mathias">Saint Mathias</option>
          <option value="Saint Mathieu">Saint Mathieu</option>
          <option value="Saint Mathieu de Beloeil">Saint Mathieu de Beloeil</option>
          <option value="Saint Mathieu du Parc">Saint Mathieu du Parc</option>
          <option value="Saint Michel des Saints">Saint Michel des Saints</option>
          <option value="Saint Patrice de Beaurivage">Saint Patrice de Beaurivage</option>
          <option value="Saint Paul">Saint Paul</option>
          <option value="Saint Phillipe">Saint Phillipe</option>
          <option value="Saint Pie de Bagot">Saint Pie de Bagot</option>
          <option value="Saint Pierre">Saint Pierre</option>
          <option value="Saint Pierre les Becquets">Saint Pierre les Becquets</option>
          <option value="Saint Placide">Saint Placide</option>
          <option value="Saint Prosper">Saint Prosper</option>
          <option value="Saint-Romain">Saint-Romain</option>
          <option value="Saint-Simon">Saint-Simon</option>
          <option value="Saint-Tite">Saint-Tite</option>
          <option value="Saint-Ubalde">Saint-Ubalde</option>
          <option value="Saint-Zacharie">Saint-Zacharie</option>
          <option value="Senneterre">Senneterre</option>
          <option value="Sorel-Tracy">Sorel-Tracy</option>
          <option value="Stanbridge East">Stanbridge East</option>
          <option value="Stanstead">Stanstead</option>
          <option value="Stanstead County">Stanstead County</option>
          <option value="St-Lin-Laurentides">St-Lin-Laurentides</option>
          <option value="St-Philippe">St-Philippe</option>
          <option value="St. Aubert">St. Aubert</option>
          <option value="St. Charles-Borromee">St. Charles-Borromee</option>
          <option value="St. Denis sur Richelieu">St. Denis sur Richelieu</option>
          <option value="St. Elie">St. Elie</option>
          <option value="St. George de Beauce">St. George de Beauce</option>
          <option value="St. Hilarion">St. Hilarion</option>
          <option value="St. Jean Baptiste">St. Jean Baptiste</option>
          <option value="St. Jean Baptiste de Nicolet">St. Jean Baptiste de Nicolet</option>
          <option value="St. Joseph de Beauce">St. Joseph de Beauce</option>
          <option value="St. Joseph de la Rive">St. Joseph de la Rive</option>
          <option value="St. Joseph de Levis">St. Joseph de Levis</option>
          <option value="St. Joseph du Lac">St. Joseph du Lac</option>
          <option value="St. Lambert">St. Lambert</option>
          <option value="St. Lawrence">St. Lawrence</option>
          <option value="St. Paul">St. Paul</option>
          <option value="St. Thomas">St. Thomas</option>
          <option value="St. Zacharie">St. Zacharie</option>
          <option value="St-Zephirin">St-Zephirin</option>
          <option value="St-Zotique">St-Zotique</option>
          <option value="Sutton, QC">Sutton, QC</option>
          <option value="Tadoussac">Tadoussac</option>
          <option value="Temiscaming">Temiscaming</option>
          <option value="Templeton">Templeton</option>
          <option value="Terrasse Vaudreuil">Terrasse Vaudreuil</option>
          <option value="Terrebonne">Terrebonne</option>
          <option value="Thetford Mines, QC">Thetford Mines, QC</option>
          <option value="Thurso">Thurso</option>
          <option value="Tingwick">Tingwick</option>
          <option value="Tracy, QC">Tracy, QC</option>
          <option value="Tring-Jonction">Tring-Jonction</option>
          <option value="Trois-Pistoles">Trois-Pistoles</option>
          <option value="Trois-Rivieres">Trois-Rivieres</option>
          <option value="Upton">Upton</option>
          <option value="Val-Barrette">Val-Barrette</option>
          <option value="Val-Belair">Val-Belair</option>
          <option value="Val-Brillant">Val-Brillant</option>
          <option value="Valcartier">Valcartier</option>
          <option value="ValCourt">ValCourt</option>
          <option value="Val-David">Val-David</option>
          <option value="Val-des-Bois">Val-des-Bois</option>
          <option value="Val-des-Monts, QC">Val-des-Monts, QC</option>
          <option value="Val-d`Or">Val-d`Or</option>
          <option value="Vallee-Jonction">Vallee-Jonction</option>
          <option value="Val-Morin">Val-Morin</option>
          <option value="Valois">Valois</option>
          <option value="Val-Racine">Val-Racine</option>
          <option value="Val-Saint-Michel">Val-Saint-Michel</option>
          <option value="Vanier, QC">Vanier, QC</option>
          <option value="Varennes">Varennes</option>
          <option value="Vaudreuil">Vaudreuil</option>
          <option value="Vaudreuil Dorion">Vaudreuil Dorion</option>
          <option value="Vaudreuil-sur-la-Lac">Vaudreuil-sur-la-Lac</option>
          <option value="Vendee">Vendee</option>
          <option value="Venise-en-Quebec">Venise-en-Quebec</option>
          <option value="Vercheres">Vercheres</option>
          <option value="Verdun">Verdun</option>
          <option value="Victoriaville, QC">Victoriaville, QC</option>
          <option value="Ville de L'Ile Perrot">Ville de L'Ile Perrot</option>
          <option value="Ville Emard">Ville Emard</option>
          <option value="Ville LeMoyne">Ville LeMoyne</option>
          <option value="Ville Saint-Laurent">Ville Saint-Laurent</option>
          <option value="Ville Saint-Pierre">Ville Saint-Pierre</option>
          <option value="Ville-Marie">Ville-Marie</option>
          <option value="Villeneuve">Villeneuve</option>
          <option value="Vimont">Vimont</option>
          <option value="Wakefield, QC">Wakefield, QC</option>
          <option value="Warden">Warden</option>
          <option value="Warwick">Warwick</option>
          <option value="Waterloo, QC">Waterloo, QC</option>
          <option value="Waterville, QC">Waterville, QC</option>
          <option value="Weedon Centre">Weedon Centre</option>
          <option value="Wentworth-Nord">Wentworth-Nord</option>
          <option value="Westmount">Westmount</option>
          <option value="Wickham">Wickham</option>
          <option value="Windmill Point">Windmill Point</option>
          <option value="Windsor, QC">Windsor, QC</option>
          <option value="Wootenville">Wootenville</option>
          <option value="Wotton">Wotton</option>
          <option value="Yamachiche">Yamachiche</option>
          <option value="Yamaska">Yamaska</option>
          <option value="Yamaska-Est">Yamaska-Est</option>

        </>
      }
      {province === 'SK' &&
        <>
          <option value={''}>Seleccione</option>
          <option value="Abbey">Abbey</option>
          <option value="Aberdeen">Aberdeen</option>
          <option value="Abernethy">Abernethy</option>
          <option value="Adanac">Adanac</option>
          <option value="Admiral">Admiral</option>
          <option value="Alameda">Alameda</option>
          <option value="Alida">Alida</option>
          <option value="Allan">Allan</option>
          <option value="Alsask">Alsask</option>
          <option value="Alvena">Alvena</option>
          <option value="Aneroid">Aneroid</option>
          <option value="Annaheim">Annaheim</option>
          <option value="Antler">Antler</option>
          <option value="Arborfield">Arborfield</option>
          <option value="Archerwill">Archerwill</option>
          <option value="Arcola">Arcola</option>
          <option value="Ardath">Ardath</option>
          <option value="Ardili">Ardili</option>
          <option value="Arelee">Arelee</option>
          <option value="Arran">Arran</option>
          <option value="Asquith">Asquith</option>
          <option value="Assiniboia">Assiniboia</option>
          <option value="Atwater">Atwater</option>
          <option value="Avonlea">Avonlea</option>
          <option value="Aylesbury">Aylesbury</option>
          <option value="Aylsham">Aylsham</option>
          <option value="Balcarres">Balcarres</option>
          <option value="Balgonie">Balgonie</option>
          <option value="Bangor">Bangor</option>
          <option value="Battleford">Battleford</option>
          <option value="Beatty">Beatty</option>
          <option value="Beauval">Beauval</option>
          <option value="Beechy">Beechy</option>
          <option value="Belle Plaine">Belle Plaine</option>
          <option value="Bellegarde">Bellegarde</option>
          <option value="Bengough">Bengough</option>
          <option value="Benson">Benson</option>
          <option value="Bethune">Bethune</option>
          <option value="Bienfait">Bienfait</option>
          <option value="Big Beaver">Big Beaver</option>
          <option value="Big River">Big River</option>
          <option value="Biggar">Biggar</option>
          <option value="Bigger">Bigger</option>
          <option value="Birch Hills">Birch Hills</option>
          <option value="Birsay">Birsay</option>
          <option value="Bjorkdale">Bjorkdale</option>
          <option value="Bladworth">Bladworth</option>
          <option value="Blaine Lake">Blaine Lake</option>
          <option value="Borden, SK">Borden, SK</option>
          <option value="Bounty">Bounty</option>
          <option value="Bracken">Bracken</option>
          <option value="Bradwell">Bradwell</option>
          <option value="Bredenbury">Bredenbury</option>
          <option value="Briercrest">Briercrest</option>
          <option value="Broadview">Broadview</option>
          <option value="Brock">Brock</option>
          <option value="Broderick">Broderick</option>
          <option value="Brownlee">Brownlee</option>
          <option value="Bruno">Bruno</option>
          <option value="B-Say-tah">B-Say-tah</option>
          <option value="Buchanan">Buchanan</option>
          <option value="Buena Vista">Buena Vista</option>
          <option value="Buffalo Narrows">Buffalo Narrows</option>
          <option value="Bulyea">Bulyea</option>
          <option value="Burstall">Burstall</option>
          <option value="Bushell Park">Bushell Park</option>
          <option value="Cabri">Cabri</option>
          <option value="Cadillac, SK">Cadillac, SK</option>
          <option value="Calder">Calder</option>
          <option value="Cando">Cando</option>
          <option value="Canora">Canora</option>
          <option value="Canwood">Canwood</option>
          <option value="Carievale">Carievale</option>
          <option value="Carlyle">Carlyle</option>
          <option value="Carlyle Lake Reso">Carlyle Lake Reso</option>
          <option value="Carmichael">Carmichael</option>
          <option value="Carnduff">Carnduff</option>
          <option value="Caronport">Caronport</option>
          <option value="Carragana">Carragana</option>
          <option value="Carrot River">Carrot River</option>
          <option value="Central Butte">Central Butte</option>
          <option value="Ceylon">Ceylon</option>
          <option value="Chamberlain">Chamberlain</option>
          <option value="Chaplin">Chaplin</option>
          <option value="Choiceland">Choiceland</option>
          <option value="Christopher Lake">Christopher Lake</option>
          <option value="Churchbridge">Churchbridge</option>
          <option value="Clavet">Clavet</option>
          <option value="Climax">Climax</option>
          <option value="Coderre">Coderre</option>
          <option value="Codette">Codette</option>
          <option value="Coleville">Coleville</option>
          <option value="Colgate">Colgate</option>
          <option value="Colonsay">Colonsay</option>
          <option value="Conquest">Conquest</option>
          <option value="Consul">Consul</option>
          <option value="Corning">Corning</option>
          <option value="Coronach">Coronach</option>
          <option value="Craik">Craik</option>
          <option value="Craven">Craven</option>
          <option value="Creelman">Creelman</option>
          <option value="Creighton">Creighton</option>
          <option value="Cudworth">Cudworth</option>
          <option value="Cumberland House">Cumberland House</option>
          <option value="Cupar">Cupar</option>
          <option value="Cut Knife">Cut Knife</option>
          <option value="Dafoe">Dafoe</option>
          <option value="Dalmeny">Dalmeny</option>
          <option value="Davidson">Davidson</option>
          <option value="Debden">Debden</option>
          <option value="Delisle">Delisle</option>
          <option value="Denare Beach">Denare Beach</option>
          <option value="Denholm">Denholm</option>
          <option value="Denzil">Denzil</option>
          <option value="Dilke">Dilke</option>
          <option value="Dinsmore">Dinsmore</option>
          <option value="Disley">Disley</option>
          <option value="Dodsland">Dodsland</option>
          <option value="Dollard">Dollard</option>
          <option value="Domremy">Domremy</option>
          <option value="Drake">Drake</option>
          <option value="Drinkwater">Drinkwater</option>
          <option value="Dubuc">Dubuc</option>
          <option value="Duck Lake">Duck Lake</option>
          <option value="Duff">Duff</option>
          <option value="Dunblane">Dunblane</option>
          <option value="Dundurn">Dundurn</option>
          <option value="Duval">Duval</option>
          <option value="Dysart">Dysart</option>
          <option value="Earl Grey">Earl Grey</option>
          <option value="Eastend">Eastend</option>
          <option value="Eatonia">Eatonia</option>
          <option value="Ebebezer">Ebebezer</option>
          <option value="Edam">Edam</option>
          <option value="Edenwold">Edenwold</option>
          <option value="Elbow">Elbow</option>
          <option value="Elfros">Elfros</option>
          <option value="Elrose">Elrose</option>
          <option value="Elstow">Elstow</option>
          <option value="Emerald Park">Emerald Park</option>
          <option value="Endeavour">Endeavour</option>
          <option value="Englefeld">Englefeld</option>
          <option value="Ernfold">Ernfold</option>
          <option value="Esterhazy">Esterhazy</option>
          <option value="Estevan">Estevan</option>
          <option value="Eston">Eston</option>
          <option value="Etters Beach">Etters Beach</option>
          <option value="Evesham">Evesham</option>
          <option value="Eyebrow">Eyebrow</option>
          <option value="Fairlight">Fairlight</option>
          <option value="Fenwood">Fenwood</option>
          <option value="Ferland">Ferland</option>
          <option value="Fielding">Fielding</option>
          <option value="Fife Lake">Fife Lake</option>
          <option value="Fillmore">Fillmore</option>
          <option value="Findlater">Findlater</option>
          <option value="Fiske">Fiske</option>
          <option value="Flaxcombe">Flaxcombe</option>
          <option value="Fleming">Fleming</option>
          <option value="Foam Lake">Foam Lake</option>
          <option value="Forget">Forget</option>
          <option value="Fort Qu'apelle">Fort Qu'apelle</option>
          <option value="Fosston">Fosston</option>
          <option value="Fox Valley">Fox Valley</option>
          <option value="Francis">Francis</option>
          <option value="Frobisher">Frobisher</option>
          <option value="Frontier">Frontier</option>
          <option value="Gainsborough">Gainsborough</option>
          <option value="Gerald">Gerald</option>
          <option value="Girvin">Girvin</option>
          <option value="Gladmar">Gladmar</option>
          <option value="Glaslyn">Glaslyn</option>
          <option value="Glen Ewen">Glen Ewen</option>
          <option value="Glenavon">Glenavon</option>
          <option value="Glenside">Glenside</option>
          <option value="Glentworth">Glentworth</option>
          <option value="Glidden">Glidden</option>
          <option value="Golden Prairie">Golden Prairie</option>
          <option value="Goodeve">Goodeve</option>
          <option value="Goodsoil">Goodsoil</option>
          <option value="Goodwater">Goodwater</option>
          <option value="Govan">Govan</option>
          <option value="Grandora">Grandora</option>
          <option value="Grandview Beach S">Grandview Beach S</option>
          <option value="Gravelbourg">Gravelbourg</option>
          <option value="Grayson">Grayson</option>
          <option value="Grenfell">Grenfell</option>
          <option value="Guernsey">Guernsey</option>
          <option value="Gull Lake, SK">Gull Lake, SK</option>
          <option value="Hafford">Hafford</option>
          <option value="Hague">Hague</option>
          <option value="Halbrite">Halbrite</option>
          <option value="Handel">Handel</option>
          <option value="Hanley">Hanley</option>
          <option value="Hardy">Hardy</option>
          <option value="Harris">Harris</option>
          <option value="Hawarden">Hawarden</option>
          <option value="Hazelwood No.94">Hazelwood No.94</option>
          <option value="Hazenmore">Hazenmore</option>
          <option value="Hazlet">Hazlet</option>
          <option value="Hepburn">Hepburn</option>
          <option value="Herbert">Herbert</option>
          <option value="Herschel">Herschel</option>
          <option value="Heward">Heward</option>
          <option value="Hodgeville">Hodgeville</option>
          <option value="Hold Fast">Hold Fast</option>
          <option value="Horizon">Horizon</option>
          <option value="Hubbard">Hubbard</option>
          <option value="Hudson Bay">Hudson Bay</option>
          <option value="Humboldt">Humboldt</option>
          <option value="Hyas">Hyas</option>
          <option value="île-à-la-Crosse">île-à-la-Crosse</option>
          <option value="Imperial">Imperial</option>
          <option value="Indian Head">Indian Head</option>
          <option value="Insinger">Insinger</option>
          <option value="Invermay">Invermay</option>
          <option value="Ituna">Ituna</option>
          <option value="Jansen">Jansen</option>
          <option value="Jasmin">Jasmin</option>
          <option value="Jedburgh">Jedburgh</option>
          <option value="Kamsack">Kamsack</option>
          <option value="Kannata Valley">Kannata Valley</option>
          <option value="Katepwa Beach">Katepwa Beach</option>
          <option value="Keeler">Keeler</option>
          <option value="Kelfield">Kelfield</option>
          <option value="Kelliher">Kelliher</option>
          <option value="Kelvington">Kelvington</option>
          <option value="Kenaston">Kenaston</option>
          <option value="Kendal">Kendal</option>
          <option value="Kennedy">Kennedy</option>
          <option value="Kerrobert">Kerrobert</option>
          <option value="Khedive">Khedive</option>
          <option value="Killaly">Killaly</option>
          <option value="Kincaid">Kincaid</option>
          <option value="Kindersley">Kindersley</option>
          <option value="Kinistino">Kinistino</option>
          <option value="Kinley">Kinley</option>
          <option value="Kipabiskau">Kipabiskau</option>
          <option value="Kipling">Kipling</option>
          <option value="Kisbey">Kisbey</option>
          <option value="Krydor">Krydor</option>
          <option value="Kyle">Kyle</option>
          <option value="La Loche">La Loche</option>
          <option value="La Ronge">La Ronge</option>
          <option value="Lafleche, SK">Lafleche, SK</option>
          <option value="Laird">Laird</option>
          <option value="Lake Alma">Lake Alma</option>
          <option value="Lake Lenore">Lake Lenore</option>
          <option value="Lampman">Lampman</option>
          <option value="Lancer">Lancer</option>
          <option value="Landis">Landis</option>
          <option value="Lang">Lang</option>
          <option value="Langenburg">Langenburg</option>
          <option value="Langham">Langham</option>
          <option value="Lanigan">Lanigan</option>
          <option value="Lashburn">Lashburn</option>
          <option value="Lawson">Lawson</option>
          <option value="Leader">Leader</option>
          <option value="Leask">Leask</option>
          <option value="Lebret">Lebret</option>
          <option value="Leipzig">Leipzig</option>
          <option value="Lemberg">Lemberg</option>
          <option value="Leney">Leney</option>
          <option value="Leoville">Leoville</option>
          <option value="Leross">Leross</option>
          <option value="Leroy">Leroy</option>
          <option value="Leslie">Leslie</option>
          <option value="Lestock">Lestock</option>
          <option value="Liberty">Liberty</option>
          <option value="Liebanthal">Liebanthal</option>
          <option value="Limerick">Limerick</option>
          <option value="Lintlaw">Lintlaw</option>
          <option value="Lipton">Lipton</option>
          <option value="Lloydminster, SK">Lloydminster, SK</option>
          <option value="Lockwood">Lockwood</option>
          <option value="Loon Lake">Loon Lake</option>
          <option value="Loreburn">Loreburn</option>
          <option value="Love">Love</option>
          <option value="Loverna">Loverna</option>
          <option value="Lucky Lake">Lucky Lake</option>
          <option value="Lumsden, SK">Lumsden, SK</option>
          <option value="Luseland">Luseland</option>
          <option value="MacDowall">MacDowall</option>
          <option value="Macklin">Macklin</option>
          <option value="MacNutt">MacNutt</option>
          <option value="Macoun">Macoun</option>
          <option value="Macrorie">Macrorie</option>
          <option value="Madison">Madison</option>
          <option value="Maidstone">Maidstone</option>
          <option value="Main Centre">Main Centre</option>
          <option value="Major">Major</option>
          <option value="Makwa">Makwa</option>
          <option value="Manitou Beach">Manitou Beach</option>
          <option value="Mankota">Mankota</option>
          <option value="Manor">Manor</option>
          <option value="Mantario">Mantario</option>
          <option value="Maple Creek">Maple Creek</option>
          <option value="Marcelin">Marcelin</option>
          <option value="Marengo">Marengo</option>
          <option value="Margo">Margo</option>
          <option value="Markinch">Markinch</option>
          <option value="Marquis">Marquis</option>
          <option value="Marshall">Marshall</option>
          <option value="Martensville">Martensville</option>
          <option value="Maryfield">Maryfield</option>
          <option value="Maymont">Maymont</option>
          <option value="Mazenod">Mazenod</option>
          <option value="McLean">McLean</option>
          <option value="McMahon">McMahon</option>
          <option value="McTaggart">McTaggart</option>
          <option value="Meacham">Meacham</option>
          <option value="Meadow Lake">Meadow Lake</option>
          <option value="Meath Park">Meath Park</option>
          <option value="Medstead">Medstead</option>
          <option value="Melfort">Melfort</option>
          <option value="Melville">Melville</option>
          <option value="Mendham">Mendham</option>
          <option value="Meota">Meota</option>
          <option value="Mervin">Mervin</option>
          <option value="Metinota">Metinota</option>
          <option value="Meyronne">Meyronne</option>
          <option value="Midale">Midale</option>
          <option value="Middle Lake">Middle Lake</option>
          <option value="Milden">Milden</option>
          <option value="Milestone">Milestone</option>
          <option value="Minton">Minton</option>
          <option value="Mistatim">Mistatim</option>
          <option value="Monchy">Monchy</option>
          <option value="Montmartre">Montmartre</option>
          <option value="Moose Jaw">Moose Jaw</option>
          <option value="Moosomin">Moosomin</option>
          <option value="Morse">Morse</option>
          <option value="Mortlach">Mortlach</option>
          <option value="Mossbank">Mossbank</option>
          <option value="Muenster">Muenster</option>
          <option value="Naicam">Naicam</option>
          <option value="Neilburg, SK">Neilburg, SK</option>
          <option value="Netherhill">Netherhill</option>
          <option value="Neudorf">Neudorf</option>
          <option value="Neville">Neville</option>
          <option value="Nipawin">Nipawin</option>
          <option value="Nokomis">Nokomis</option>
          <option value="Norquay">Norquay</option>
          <option value="North Battleford">North Battleford</option>
          <option value="North Portal">North Portal</option>
          <option value="Northgate">Northgate</option>
          <option value="Odessa">Odessa</option>
          <option value="Ogema">Ogema</option>
          <option value="Osage">Osage</option>
          <option value="Osler">Osler</option>
          <option value="Other">Other</option>
          <option value="Oungre">Oungre</option>
          <option value="Outlook">Outlook</option>
          <option value="Oxbow">Oxbow</option>
          <option value="Paddockwood">Paddockwood</option>
          <option value="Palmer">Palmer</option>
          <option value="Pangman">Pangman</option>
          <option value="Paradise Hill">Paradise Hill</option>
          <option value="Parkside">Parkside</option>
          <option value="Paynton">Paynton</option>
          <option value="Peebles">Peebles</option>
          <option value="Pelly">Pelly</option>
          <option value="Pennant">Pennant</option>
          <option value="Pense">Pense</option>
          <option value="Penzance">Penzance</option>
          <option value="Perdue">Perdue</option>
          <option value="Piapot">Piapot</option>
          <option value="Pilger">Pilger</option>
          <option value="Pilot Butte">Pilot Butte</option>
          <option value="Plato">Plato</option>
          <option value="Plenty">Plenty</option>
          <option value="Plunkett">Plunkett</option>
          <option value="Ponteix">Ponteix</option>
          <option value="Porcupine Plain">Porcupine Plain</option>
          <option value="Portreeve">Portreeve</option>
          <option value="Preeceville">Preeceville</option>
          <option value="Prelate">Prelate</option>
          <option value="Primate">Primate</option>
          <option value="Prince Albert">Prince Albert</option>
          <option value="Prud`homme">Prud`homme</option>
          <option value="Punnichy">Punnichy</option>
          <option value="Quill Lake">Quill Lake</option>
          <option value="Quinton">Quinton</option>
          <option value="Rabbit Lake">Rabbit Lake</option>
          <option value="Radisson, SK">Radisson, SK</option>
          <option value="Radville">Radville</option>
          <option value="Rama">Rama</option>
          <option value="Raymore">Raymore</option>
          <option value="Redvers">Redvers</option>
          <option value="Regina">Regina</option>
          <option value="Regina Beach">Regina Beach</option>
          <option value="Regway">Regway</option>
          <option value="Rhein">Rhein</option>
          <option value="Richard">Richard</option>
          <option value="Richardson">Richardson</option>
          <option value="Richmond, SK">Richmond, SK</option>
          <option value="Ridgedale">Ridgedale</option>
          <option value="Riverhurst">Riverhurst</option>
          <option value="Robsart">Robsart</option>
          <option value="Rocanville">Rocanville</option>
          <option value="Roche Percee">Roche Percee</option>
          <option value="Rock Haven">Rock Haven</option>
          <option value="Rockglen">Rockglen</option>
          <option value="Rose Valley">Rose Valley</option>
          <option value="Rosetown">Rosetown</option>
          <option value="Rosthern">Rosthern</option>
          <option value="Rouleau">Rouleau</option>
          <option value="Ruddell">Ruddell</option>
          <option value="Rush Lake">Rush Lake</option>
          <option value="Ruthilda">Ruthilda</option>
          <option value="Saint-Benedict">Saint-Benedict</option>
          <option value="Saint-Brieux">Saint-Brieux</option>
          <option value="Saint-Gregor">Saint-Gregor</option>
          <option value="Saint-Louis">Saint-Louis</option>
          <option value="Saint-Victor">Saint-Victor</option>
          <option value="Saint-Walburg">Saint-Walburg</option>
          <option value="Saltcoats">Saltcoats</option>
          <option value="Salvador">Salvador</option>
          <option value="Sandy Beach, SK">Sandy Beach, SK</option>
          <option value="Saskatchewan Beach">Saskatchewan Beach</option>
          <option value="Saskatoon">Saskatoon</option>
          <option value="Sceptre">Sceptre</option>
          <option value="Scobey">Scobey</option>
          <option value="Scott">Scott</option>
          <option value="Sedley">Sedley</option>
          <option value="Semans">Semans</option>
          <option value="Senlac">Senlac</option>
          <option value="Shackleton">Shackleton</option>
          <option value="Shamrock">Shamrock</option>
          <option value="Shaunavon">Shaunavon</option>
          <option value="Shedo">Shedo</option>
          <option value="Shell Lake">Shell Lake</option>
          <option value="Shellbrook">Shellbrook</option>
          <option value="Silton">Silton</option>
          <option value="Simmie">Simmie</option>
          <option value="Simpson">Simpson</option>
          <option value="Sintaluta">Sintaluta</option>
          <option value="Smeaton">Smeaton</option>
          <option value="Smiley">Smiley</option>
          <option value="Southey">Southey</option>
          <option value="Sovereign">Sovereign</option>
          <option value="Spalding">Spalding</option>
          <option value="Speers">Speers</option>
          <option value="Spirit Wood">Spirit Wood</option>
          <option value="Spring Valley">Spring Valley</option>
          <option value="Spring Water">Spring Water</option>
          <option value="Springside">Springside</option>
          <option value="Spruce Lake">Spruce Lake</option>
          <option value="Spy Hill">Spy Hill</option>
          <option value="Star City">Star City</option>
          <option value="Steelman">Steelman</option>
          <option value="Stenen">Stenen</option>
          <option value="Stewart Valley">Stewart Valley</option>
          <option value="Stockholm">Stockholm</option>
          <option value="Stornoway">Stornoway</option>
          <option value="Storthoaks">Storthoaks</option>
          <option value="Stoughton">Stoughton</option>
          <option value="Stranraer">Stranraer</option>
          <option value="Strasbourg">Strasbourg</option>
          <option value="Strongfield">Strongfield</option>
          <option value="Sturgis">Sturgis</option>
          <option value="Success">Success</option>
          <option value="Summerberry">Summerberry</option>
          <option value="Swift Current">Swift Current</option>
          <option value="Tantallon, SK">Tantallon, SK</option>
          <option value="Tessier">Tessier</option>
          <option value="Theodore">Theodore</option>
          <option value="Tisdale">Tisdale</option>
          <option value="Togo">Togo</option>
          <option value="Tompkins">Tompkins</option>
          <option value="Torquay">Torquay</option>
          <option value="Tramping Lake">Tramping Lake</option>
          <option value="Tribune">Tribune</option>
          <option value="Tugaske">Tugaske</option>
          <option value="Turtleford">Turtleford</option>
          <option value="Tuxford">Tuxford</option>
          <option value="Unity">Unity</option>
          <option value="Unwin">Unwin</option>
          <option value="Uranium City">Uranium City</option>
          <option value="Usherville">Usherville</option>
          <option value="Val Marie">Val Marie</option>
          <option value="Valparaiso">Valparaiso</option>
          <option value="Vanguard">Vanguard</option>
          <option value="Vanscoy">Vanscoy</option>
          <option value="Vawn">Vawn</option>
          <option value="Veregin">Veregin</option>
          <option value="Vibank">Vibank</option>
          <option value="Viceroy">Viceroy</option>
          <option value="Viscount">Viscount</option>
          <option value="Vonda">Vonda</option>
          <option value="Wadena">Wadena</option>
          <option value="Wakaw">Wakaw</option>
          <option value="Wakaw Lake">Wakaw Lake</option>
          <option value="Waldeck">Waldeck</option>
          <option value="Waldheim">Waldheim</option>
          <option value="Waldron">Waldron</option>
          <option value="Wapella">Wapella</option>
          <option value="Warman">Warman</option>
          <option value="Watrous">Watrous</option>
          <option value="Watson">Watson</option>
          <option value="Wawota">Wawota</option>
          <option value="Webb">Webb</option>
          <option value="Weekes">Weekes</option>
          <option value="Weirdale">Weirdale</option>
          <option value="Weldon">Weldon</option>
          <option value="Welwyn">Welwyn</option>
          <option value="West Bend">West Bend</option>
          <option value="West Poplar River">West Poplar River</option>
          <option value="Weyburn">Weyburn</option>
          <option value="White City">White City</option>
          <option value="White Fox">White Fox</option>
          <option value="Whitewood">Whitewood</option>
          <option value="Wilcox">Wilcox</option>
          <option value="Wilkie">Wilkie</option>
          <option value="Willow Bunch">Willow Bunch</option>
          <option value="Willow Creek">Willow Creek</option>
          <option value="Willowbrook">Willowbrook</option>
          <option value="Windthorst">Windthorst</option>
          <option value="Wiseton">Wiseton</option>
          <option value="Wishart">Wishart</option>
          <option value="Wolseley">Wolseley</option>
          <option value="Wood Mountain">Wood Mountain</option>
          <option value="Woodrow">Woodrow</option>
          <option value="Wroxton">Wroxton</option>
          <option value="Wynyard">Wynyard</option>
          <option value="Yarbo">Yarbo</option>
          <option value="Yellow Creek">Yellow Creek</option>
          <option value="Yellow Grass">Yellow Grass</option>
          <option value="Yorkton">Yorkton</option>
          <option value="Young">Young</option>
          <option value="Zealandia">Zealandia</option>
          <option value="Zehner">Zehner</option>
          <option value="Zelma">Zelma</option>
          <option value="Zeneta">Zeneta</option>
          <option value="Zenon Park">Zenon Park</option>
          <option value="Zumbro">Zumbro</option>
        </>
      }
      {province === 'YT' &&
        <>
          <option value={''}>Seleccione</option>
          <option value="Carcross">Carcross</option>
          <option value="Carmacks">Carmacks</option>
          <option value="Dawson City">Dawson City</option>
          <option value="Faro">Faro</option>
          <option value="Haines Junction">Haines Junction</option>
          <option value="Little Gold">Little Gold</option>
          <option value="Mayo-Elsa">Mayo-Elsa</option>
          <option value="Old Crow">Old Crow</option>
          <option value="Other">Other</option>
          <option value="Ross River">Ross River</option>
          <option value="Swift River">Swift River</option>
          <option value="Watson Lake">Watson Lake</option>
          <option value="Whitehorse">Whitehorse</option>
        </>
      }
    </>
  )
}
