import {type Dispatch, type SetStateAction} from 'react'
import Resizer from 'react-image-file-resizer'

export const resizeFile = async (
  file: Blob,
  whidt: number,
  setImage: Dispatch<SetStateAction<string>>
) => {
  await Resizer.imageFileResizer(
    file,
    whidt,
    whidt,
    'JPEG',
    100,
    0,
    (uri) => {
      setImage(uri.toString())
    },
    'base64'
  )
}
