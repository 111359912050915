/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, SetStateAction, Dispatch } from 'react'
import { Box } from '../../../../../components/Box'
import { RootStoreContext } from '../../../../../stores/rootStore'
import { observer } from 'mobx-react'
import ShowEmailUser from './ShowEmailUser'
import ShowServices from './ShowServices'
import ShowTranslations from './ShowTranslations'
import ShowClientDocuments from './ShowClientDocuments'
import ShowForms from 'app/pages/services/services/servicesMenuOption/ShowForms'
import { ProfileImmigration } from 'app/modules/apps/profile/core/_models'
import TimelineComponent from 'app/pages/user/newtimeline'
import AplicationTableByUser from '../../products/ProductManager/educationAplicationByUser'
import ListDocuments from 'app/pages/library/ListDocuments'
import UserIcon from '../../../dashboard/userDasboardComponents/userDashboardIcons/user-alt-4.svg'
import Icard from '../../../dashboard/userDasboardComponents/userDashboardIcons/id-card-alt.svg'
import Migration from '../../../dashboard/userDasboardComponents/userDashboardIcons/flag.svg'
import World from '../../../dashboard/userDasboardComponents/userDashboardIcons/world.svg'
import File from '../../../dashboard/userDasboardComponents/userDashboardIcons/file-name.svg'
import Graduation from '../../../dashboard/userDasboardComponents/userDashboardIcons/graduation.svg'
import Puzzle from '../../../dashboard/userDasboardComponents/userDashboardIcons/puzzle.svg'
import Talk from '../../../dashboard/userDasboardComponents/userDashboardIcons/speech-bubble-19.svg'
import UserBlue from '../../../dashboard/userDasboardComponents/userDashboardIcons/User-blue.svg'
import BriefCase from '../../../dashboard/userDasboardComponents/userDashboardIcons/briefcase-blue.svg'
import UsersBlue from '../../../dashboard/userDasboardComponents/userDashboardIcons/users-blue.svg'
import EditBlue from '../../../dashboard/userDasboardComponents/userDashboardIcons/pencil-altblue.svg'
import ArrowLeft from '../../../contratationApp/icons/arrow-left.svg'
import InmiDetailsNew from '../../../../modules/accounts/components/settings/cards/surveyAnt'
import InmiDetailsNew2 from '../../../../modules/accounts/components/settings/cards/surveyAnt2'
import ProfileDetails2 from 'app/modules/accounts/components/settings/cards/ProfileDetails2'
import Swal from 'sweetalert2'
import { updateProfileBasicByUser } from 'app/modules/apps/profile/core/_requests'
import { useIntl } from 'react-intl'
import { updateProfileNew } from '../../../../modules/apps/profile/core/_requests'
import NotesModal from '../List/Slide'
import { useQuery } from 'react-query'
import { addAssociatedAccount, getAssociatedAccounts, getUserNewQueryDashboard, getUserUsersById, removeAssociatedAccount, sendEmailSwitchAccount } from 'app/modules/apps/users/core/_requests'
import './styles.scss'
import moment from 'moment'
import { Users } from "app/modules/apps/users/core/_models"
import { getStaff } from 'app/modules/apps/staff/core/_requests'
const initialState = {
  empty: false,
  profile: false,
  forms: false,
  translations: false,
  services: false,
  email: false,
  menu: false,
  documents: false,
  timeline: false,
  register: false,
  library: false,
  edit: false,
  edit2: false,
  edit3: false
}

interface Props {
  ProfileImmigration: ProfileImmigration
  user: any
  setUs?: Dispatch<SetStateAction<string>>
}
interface AssociatedAccount {
  id: string
  email: string
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  userone: Users
  usertwo: Users
  __entity: string
}

const UserHeader: React.FC<Props> = (props, user) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const { selectUser, currentUser } = rootStore.usersStore
  const { getUser, getpProfile, profileImmigration } = rootStore.userStore
  const [columns, setColumns] = useState(2)
  const [switchMenus, setSwitchMenus] = useState(false)
  const [option, setOption] = React.useState(initialState)
  const [focusbtn, setFocusbtn] = useState('personales')
  const [personalData, setPersonalData] = useState(true)
  const [labourData, setLabourData] = useState(false)
  const [familyData, setFamilyData] = useState(false)
  const [prof, setProf] = useState<any>(null)
  const [update, setUpdate] = useState(0)
  const [tabActive, setTabActive] = useState<string>('profile')
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [refetchActivator, setRefetchActivator] = useState(false)
  const [refetchActivator2, setRefetchActivator2] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [wordSearched, setWordSearched] = useState('')
  const [users, setUsers] = useState<any>()
  const [isFetching, setIsFetching] = useState<any>()
  const [selectedUser, setSelectedUser] = useState<Users | null>(null)
  const [associatedAccounts, setAssociatedAccounts] = useState<AssociatedAccount[]>([])
  const {data: StaffData, status: StaffStatus} = useQuery(
    'getStff',
    async () => await getStaff(user ? user.id : ''),
    {enabled: user !== undefined, cacheTime: 0}
  )
  const [staffId, setStaffId] = useState('')

  useEffect(() => {
    if (staffId === '' && StaffData !== undefined && StaffData[0] && StaffData[0].id) {
      setStaffId(StaffData[0].id)
    }
  }, [StaffData])

  const handleSelectUser = (user: Users) => {
    setSelectedUser(user)
    setWordSearched('') // Limpia la búsqueda después de la selección
  }

  useEffect(() => {
    const fetchAssociatedAccounts = async () => {
      if (currentUser?.id) {
        try {
          const data = await getAssociatedAccounts(currentUser.id)
          if (Array.isArray(data)) {
            setAssociatedAccounts(data)
          } else {
            throw new Error('Failed to fetch associated accounts')
          }
        } catch (error) {
          console.error('Error fetching associated accounts:', error)
        }
      }
    }

    fetchAssociatedAccounts()
  }, [currentUser?.id])

  const handleRemoveAccount = async (userOneId: any, userTwoId: any) => {
    try {
      await removeAssociatedAccount(userOneId, userTwoId)
      setAssociatedAccounts(prev => prev.filter(account => account.usertwo.id !== userTwoId))
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleAddAssociatedUser = async () => {
    Swal.fire({
      title: '¿Está seguro que desea hacer este cambio?',
      text: `Se enviará una confirmación a ${currentUser?.email}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '<div class="btn-with-border">CONFIRMAR <img src="/media/icons/app/menu/icons8-check-50white.png" alt="User Icon" style="width: 15px " /></div>',
      cancelButtonText: 'CANCELAR',
      customClass: {
        confirmButton: 'order-2 btn-with-border-no-shadow',
        cancelButton: 'btn-with-icon',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (selectedUser && currentUser) {
          if (selectedUser.id === currentUser.id) {
            Swal.fire({
              title: 'Error',
              text: 'No puede asociar la misma cuenta del cliente actual',
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            })
            return // Prevent further execution if the same user is selected
          }

          try {
            const data = await addAssociatedAccount(currentUser.id, selectedUser.id)
            setAssociatedAccounts(prev => [...prev, { ...data, usertwo: selectedUser }])
            setSelectedUser(null)  // Limpiar el usuario seleccionado
            setWordSearched('')    // Limpiar el campo de búsqueda

            // Enviar correo
            await sendEmailSwitchAccount(currentUser.email, selectedUser.email)

            Swal.fire({
              title: '¡Fue modificado con éxito!',
              text: `Hemos notificado a ${currentUser?.email}`,
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            })
          } catch (error) {
            console.error('Error:', error)
          }
        }
      }
    })
  }




  const { data, status, refetch } = useQuery('getUser', async () => await getUserUsersById(currentUser?.id as string)
  )

  if (status === 'success') {
    /* console.log('valor de data', data)
    console.log('valor de current user', currentUser) */
  }
  const changeInputUser = async (search: string) => {
    setIsFetching(true)
    if (search.trim()) {
      try {
        const response = await getUserNewQueryDashboard({ email: search })
        if (response && response.data) {
          setUsers(response.data) // Asegurándote de que estás accediendo correctamente a la propiedad data.
        } else {
          setUsers([])
        }
        setIsFetching(false)
      } catch (error) {
        console.error("Error fetching users", error)
        setUsers([])
        setIsFetching(false)
      }
    } else {
      setUsers([]) // Limpia los resultados si la búsqueda está vacía
      setIsFetching(false)
    }
  }


  const handleOption = (key: string) => {
    setOption({
      ...initialState,
      [key]: true,
    })
  }

  useEffect(() => {
    if (refetchActivator && status === 'success') {
      selectUser(data !== undefined ? data : currentUser)
      getpProfile(currentUser?.id ?? '')
      getUser(currentUser?.id ?? '')
      setProf(profileImmigration)
      setTimeout(() => {
        setRefetchActivator2(true)
      }, 300)
      setUpdate(update + 1)
    }
    setRefetchActivator(false)
  }, [refetchActivator, currentUser])

  useEffect(() => {
    if (refetchActivator2 && status === 'success') {
      selectUser(data !== undefined ? data : currentUser)
      setUpdate(update + 1)
    }
    setRefetchActivator2(false)
  }, [refetchActivator2, currentUser])

  useEffect(() => {
    selectUser(currentUser)
    getpProfile(currentUser?.id ?? '')
    getUser(currentUser?.id ?? '')
    setProf(profileImmigration)
    setUpdate(update + 1)
  }, [currentUser])


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setColumns(1)
      } else {
        setColumns(2)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getProfile = () => {
    getpProfile(currentUser?.id ?? '')
    setProf(profileImmigration)
    setUpdate(update + 1)
  }

  const handlePpdateProfileBasic = async (profileBasic: object) => {
    const result = await updateProfileBasicByUser(profileBasic)
    if (result != null) {
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
    } else {
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }

  const handleGoToResumen = () => {
    setSwitchMenus(false);  // Switch back to Resumen view
    setFocusbtn('personales');  // Default tab should be 'personales'
    setPersonalData(true);  // Show personal data by default
    setLabourData(false);   // Hide labour data initially
    setFamilyData(false);   // Hide family data initially
    setUpdate(update + 1);  // Trigger a re-render
  };
  

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCreate = () => {
    setIsModalVisible(false)
  }

  const ageCalculator = (formikBirthDate: Date) => {
    const currentDate = moment()
    const birthDate = moment(formikBirthDate)
    if (!birthDate.isValid()) {
      return 'No se puede calcular'
    }
    const age = currentDate.diff(birthDate, 'years')
    return age.toString() + ' años de edad'
  }

  return (
    <>
      {/* menu */}
      {switchMenus === false &&
        <div className='flex flex-row items-center justify-between pl-8 pr-8 flex-wrap gap-4'
          style={{
            borderBottom: '1px solid #DFE4EA',
            borderTop: '1px solid #DFE4EA',
            paddingTop: '2rem'
          }}>
          <button
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            className='button-form4-navbar'
            onClick={() => {
              setSwitchMenus(false)
              handleOption('profile')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={UserIcon} alt="UserIcon" />
            <p style={{ margin: '0' }}>Resumen</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('documents')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Icard} alt="Icard" />
            <p style={{ margin: '0' }}>Visa</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('timeline')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Migration} alt="Migration" />
            <p style={{ margin: '0' }}>Plan migratorio</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('translations')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={World} alt="World" />
            <p style={{ margin: '0' }}>Traducciones</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('library')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={File} alt="File" />
            <p style={{ margin: '0' }}>Biblioteca</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('register')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={Graduation} alt="Graduation" />
            <p style={{ margin: '0' }}>Registros</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('services')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Puzzle} alt="Puzzle" />
            <p style={{ margin: '0' }}>Servicios</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('email')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Talk} alt="Talk" />
            <p style={{ margin: '0' }}>Comunicación</p>
          </button>
        </div>}
      {/* Client data */}
      {update >= 0 && currentUser &&
        <div className='aplicant-continer' style={{ marginTop: '2rem', alignItems: 'center', justifyContent: 'center', padding: '1rem', display: 'flex', flexDirection: 'column', width: '100%', minHeight: '4rem', paddingBottom: '1rem', paddingTop: '2rem' }}>
          <div className='w-full'>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center justify-start" style={{ paddingRight: '0.5rem' }}>
                <div >
                  {currentUser.photoId !== null ?
                    <img style={{ borderRadius: '999px', width: '60px', height: '60px' }}
                      src={`//immilandpublic.nyc3.digitaloceanspaces.com/${currentUser?.photoId}-avatar-large.jpg`} /> : <img style={{ width: '60px', height: '60px' }} src={UserIcon} alt="UserIcon" />}

                </div>
                <div>
                  <p style={{ margin: '0' }}><b>{currentUser?.firstName} {currentUser?.lastName}</b></p>
                  <p>Activo {currentUser?.createdAt.substring(0, 10)}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Email</p>
                  <p>{currentUser?.email}</p>
                </div>
              </div>
              {/* <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingRight: '1rem', paddingLeft: '1rem' }}>
                <div className="flex flex-col">
                <p style={{ margin: '0' }}>Asignado a</p>
                <p>{lastVisaContract && lastVisaContract.staffId && lastVisaContract.staffId} </p>
              </div>
            </div> */}
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Nacionalidad</p>
                  <p>{currentUser?.nationalitie}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-start" style={{ borderLeft: '1px solid black', paddingLeft: '0.5rem' }}>
                <div className="flex flex-col">
                  <p style={{ margin: '0' }}>Reside</p>
                  <p>{currentUser?.countryOfOrigin}</p>
                </div>
              </div>
              <div style={{ paddingRight: '0.5rem' }} className="flex gap-2">
                {/* <button
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  onClick={() => {
                    setSwitchMenus(true)
                    handleOption('email')
                  }}
                  className="button-form">
                  <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}><img
                    alt="Calendar"
                    className='meet-image2'
                    src={isHovered2 ? '/media/icons/app/menu/speech-bubble-19.svg' :
                      '/media/icons/app/menu/speech-bubble-19.svg'} />
                    ENVIAR MENSAJE</div>
                </button> */}


                {/*  <button
                  className='timeline-btn-one'
                  style={{ fontSize: '10px', width: '5rem', height: '2rem', marginBottom: '1.5rem', color: 'black', backgroundColor: 'white' }}
                >
                  <img style={{ width: '15px', height: '15px' }} src={Talk} alt="Talk" />
                  CITAR
                </button> */}
                <button
                  style={{ width: '6rem' }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => { setIsModalVisible(true) }}

                  className="button-ui button-secondary button-s"

                >
                  <img
                    alt="Calendar"
                    className='meet-image2'
                    src={isHovered ? '/media/icons/app/menu/speech-bubble-19.svg' : '/media/icons/app/menu/speech-bubble-19.svg'}
                  />
                    NOTAS

                </button>

              </div>
            </div>

            {switchMenus === false &&
              <div className='mt-8'>
                <div className='flex w-full justify-center'>
                  <button
                    className={`btn-user-profile ${focusbtn === 'personales' ? 'user-profile-active' : ''}`}
                    onClick={() => {
                      setFocusbtn('personales')
                      setPersonalData(true)
                      setLabourData(false)
                      setFamilyData(false)
                      setUpdate(update + 1)
                    }
                    }>
                    <img className='mr-1' src={UserBlue} alt="UserBlue" />
                    <p style={{ color: '#0B76B7' }}>DATOS PERSONALES</p>
                  </button>
                  <button
                    className={`btn-user-profile ${focusbtn === 'laborales' ? 'user-profile-active' : ''}`}
                    onClick={() => {
                      setFocusbtn('laborales')
                      setPersonalData(false)
                      setLabourData(true)
                      setFamilyData(false)
                      setUpdate(update + 1)
                      getProfile()
                    }}>
                    <img className='mr-1' src={BriefCase} alt="BriefCase" />
                    <p style={{ color: '#0B76B7' }}>DATOS LABORALES</p>
                  </button>
                  <button
                    className={`btn-user-profile ${focusbtn === 'familiares' ? 'user-profile-active' : ''}`}
                    onClick={() => {
                      setFocusbtn('familiares')
                      setPersonalData(false)
                      setLabourData(false)
                      setFamilyData(true)
                      setUpdate(update + 1)
                      getProfile()
                    }
                    }>
                    <img className='mr-1' src={UsersBlue} alt="UserBlue" />
                    <p style={{ color: '#0B76B7' }}>APLICACIONES Y FAMILIARES</p>
                  </button>
                </div>
                {personalData === true ?
                  <div style={{ width: '100%', border: '1px solid #0B76B7', padding: '1rem', borderRadius: '10px' }}>
                    <div className='flex justify-between w-full'>
                      <h1 style={{ color: '#0B76B7', fontSize: '1.3rem', fontWeight: 'bold' }}>Datos personales</h1>
                      <button
                        className='button-ui button-tertiary'
                        style={{ color: '#01A9DB', backgroundColor: 'white', padding: '5px 10px', borderRadius: '30px', display: 'flex', gap: '5px' }}
                        onClick={() => {
                          handleOption('edit')
                          setSwitchMenus(true)
                        }}>
                        <img src={EditBlue} alt="EditBlue" />
                        <p style={{ margin: '0' }}>Editar</p>
                      </button>
                    </div>
                    <div className='flex mt-6 flex-wrap gap-4'>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Nombre</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.firstName === '' ? 'Sin definir' : `${currentUser?.firstName} ${currentUser?.lastName}`}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Fecha de nacimiento</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.birth === null ? 'Sin definir' : currentUser?.birth?.toString().substring(0, 10)}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Edad</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.age === undefined ? currentUser.birth === undefined ? 'por definir' : ageCalculator(currentUser.birth!) : currentUser?.age}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Género</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.gender === '' ? 'Sin definir' : currentUser?.gender}</b></p>
                      </div>
                    </div>

                    <div className='flex mt-6 flex-wrap gap-4'>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>País de residencia</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.countryOfOrigin === '' ? 'Sin definir' : currentUser?.countryOfOrigin}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Nacionalidad</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.nationalitie === '' ? 'Sin definir' : currentUser?.nationalitie}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Idioma</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.language === '' ? 'Sin definir' : currentUser?.language}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Zona horaria</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.timeZone === '' ? 'Sin definir' : currentUser?.timeZone}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Email</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.email}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Teléfono</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.phone === '' ? 'Sin definir' : currentUser?.phone}</b></p>
                      </div>
                    </div>
                    <div className='flex mt-6 flex-wrap gap-4'>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Dirección</p>
                        <p style={{ margin: '0' }}><b>{currentUser?.address === '' ? 'Sin definir' : currentUser?.address}</b></p>
                      </div>
                    </div>
                  </div> : null}

                {update > 0 && labourData === true ?
                  <div style={{ width: '100%', border: '1px solid #0B76B7', padding: '1rem', borderRadius: '10px' }}>
                    <div className='flex justify-between w-full'>
                      <h1 style={{ color: '#0B76B7', fontSize: '1.3rem', fontWeight: 'bold' }}>Datos laborales</h1>
                      <button
                        style={{ color: '#01A9DB', backgroundColor: 'white', padding: '5px 10px', borderRadius: '30px', display: 'flex', gap: '5px' }}
                        onClick={() => {
                          handleOption('edit2')
                          setSwitchMenus(true)
                        }}>
                        <img src={EditBlue} alt="EditBlue" />
                        <p style={{ margin: '0' }}>Editar</p>
                      </button>
                    </div>
                    <div className='flex mt-6 flex-wrap gap-4'>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Ocupación</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.occupacion === undefined ? 'Sin definir' : prof.profile?.occupacion}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Área de estudio</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.area_de_estudios === undefined ? 'Sin definir' : prof.profile?.area_de_estudios}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Nivel de inglés</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.proeficiencia_en_ingles === undefined ? 'Sin definir' : prof.profile?.proeficiencia_en_ingles}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Nivel de francés</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.proeficiencia_en_frances === undefined ? 'Sin definir' : prof.profile?.proeficiencia_en_frances}</b></p>
                      </div>
                    </div>
                    <div className='flex mt-6 flex-wrap gap-4'>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Historial laboral más reciente</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.historial_laboral === undefined ? 'Sin definir' : prof.profile?.historial_laboral}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>¿Aplicaciones previas a Canadá?</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.plicaciones_previas === undefined ? 'Sin definir' : prof.profile?.plicaciones_previas}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>¿Qué tipo de aplicación?</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.tipo_aplicacion === undefined ? 'Sin definir' : prof.profile?.tipo_aplicacion}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Rechazos</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.rechazos_visa === undefined ? 'Sin definir' : prof.profile?.rechazos_visa}</b></p>
                      </div>
                    </div>

                  </div> : null}

                {update > 0 && familyData === true ?
                  <div style={{ width: '100%', border: '1px solid #0B76B7', padding: '1rem', borderRadius: '10px' }}>
                    <div className='flex justify-between w-full'>
                      <h1 style={{ color: '#0B76B7', fontSize: '1.3rem', fontWeight: 'bold' }}>Información de aplicaciones</h1>
                      <button
                        style={{ color: '#01A9DB', backgroundColor: 'white', padding: '5px 10px', borderRadius: '30px', display: 'flex', gap: '5px' }}
                        onClick={() => {
                          handleOption('edit3')
                          setSwitchMenus(true)
                        }}>
                        <img src={EditBlue} alt="EditBlue" />
                        <p style={{ margin: '0' }}>Editar</p>
                      </button>
                    </div>

                    <div className='flex mt-6 flex-wrap gap-4'>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>N° de Hijos</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.numero_hijos === undefined ? 'Sin definir' : prof.profile?.numero_hijos}</b></p>
                      </div>
                      <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                        <p style={{ margin: '0' }}>Edad de hijos</p>
                        <p style={{ margin: '0' }}><b>{prof.profile?.edad_hijos === undefined ? 'Sin definir' : prof.profile?.edad_hijos}</b></p>
                      </div>
                    </div>

                    <div className='flex justify-between w-full mt-4'>
                      <h1 style={{ color: '#0B76B7', fontSize: '1rem', fontWeight: 'bold' }}>Datos del cónyuge/pareja de hecho</h1>
                    </div>


                    <div style={{ width: '100%', border: '1px solid #0B76B7', padding: '1rem', borderRadius: '10px' }}>
                      <div className='flex flex-wrap gap-4'>
                        {Array.isArray(prof.profile?.conyuge_pais_residencia) ? (
                          prof.profile?.conyuge_pais_residencia.map((item: any, index: any) => (
                            <div key={index}>
                              <div className='flex flex-wrap gap-4'>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Nombre del Cónyuge</p>
                                  <p style={{ margin: '0' }}><b>{item.conyuge === undefined ? 'Sin definir' : item.conyuge}</b></p>
                                </div>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Edad</p>
                                  <p style={{ margin: '0' }}><b>{item.edadconyuge === undefined ? 'Sin definir' : item.edadconyuge}</b></p>
                                </div>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>País de residencia de cónyuge</p>
                                  <p style={{ margin: '0' }}><b>{item.question3 === undefined ? 'Sin definir' : item.question3}</b></p>
                                </div></div>
                              <div className='flex flex-wrap gap-4'>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Grado académico</p>
                                  <p style={{ margin: '0' }}><b>{item.conyuge_grado_academico === undefined ? 'Sin definir' : item.conyuge_grado_academico}</b></p>
                                </div>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Área de estudio</p>
                                  <p style={{ margin: '0' }}><b>{item.conyuge_area_estudios === undefined ? 'Sin definir' : item.conyuge_area_estudios}</b></p>
                                </div> </div>
                              <div className='flex mt-6 flex-wrap gap-4'>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Nivel de inglés</p>
                                  <p style={{ margin: '0' }}><b>{item.conyuge_proeficiencia_ingles === undefined ? 'Sin definir' : item.conyuge_proeficiencia_ingles}</b></p>
                                </div>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Nivel de francés</p>
                                  <p style={{ margin: '0' }}><b>{item.conyuge_proeficiencia_frances === undefined ? 'Sin definir' : item.conyuge_proeficiencia_frances}</b></p>
                                </div>
                                <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                  <p style={{ margin: '0' }}>Historial laboral más reciente</p>
                                  <p style={{ margin: '0' }}><b>{item.conyuge_historial_laboral === undefined ? 'Sin definir' : item.conyuge_historial_laboral}</b></p>
                                </div></div>

                            </div>
                          ))
                        ) : (
                          // Si no es un array
                          <div>
                            <div className='flex mt-6 flex-wrap gap-4'>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Nombre del Cónyuge</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge === undefined ? 'Sin definir' : prof.profile?.conyuge}</b></p>
                              </div>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Edad</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.edadconyuge === undefined ? 'Sin definir' : prof.profile?.edadconyuge}</b></p>
                              </div>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>País de residencia de cónyuge</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge_pais_residencia === undefined ? 'Sin definir' : prof.profile?.conyuge_pais_residencia}</b></p>
                              </div></div>
                            <div className='flex mt-6 flex-wrap gap-4'>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Grado académico</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge_grado_academico === undefined ? 'Sin definir' : prof.profile?.conyuge_grado_academico}</b></p>
                              </div>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Área de estudio</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge_area_estudios === undefined ? 'Sin definir' : prof.profile?.conyuge_area_estudios}</b></p>
                              </div></div>
                            <div className='flex mt-6 flex-wrap gap-4'>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Nivel de inglés</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge_proeficiencia_ingles === undefined ? 'Sin definir' : prof.profile?.conyuge_proeficiencia_ingles}</b></p>
                              </div>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Nivel de francés</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge_proeficiencia_frances === undefined ? 'Sin definir' : prof.profile?.conyuge_proeficiencia_frances}</b></p>
                              </div>
                              <div className="flex flex-col items-start justify-start" style={{ borderLeft: '1px solid black', paddingRight: '2rem', paddingLeft: '1rem' }}>
                                <p style={{ margin: '0' }}>Historial laboral más reciente</p>
                                <p style={{ margin: '0' }}><b>{prof.profile?.conyuge_historial_laboral === undefined ? 'Sin definir' : prof.profile?.conyuge_historial_laboral}</b></p>
                              </div>  </div>

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  : null}





              </div>}
          </div>
        </div>}
      {/* menu 2*/}
      {switchMenus === true &&
        <div className='flex flex-row items-center justify-between pl-8 pr-8 flex-wrap gap-4' style={{ borderBottom: '1px solid #DFE4EA', borderTop: '1px solid #DFE4EA', paddingTop: '2rem' }}>
          <button
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            className='button-form4-navbar'
            onClick={() => {
              setSwitchMenus(false)
              handleOption('profile')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={UserIcon} alt="UserIcon" />
            <p style={{ margin: '0' }}>Resumen</p>
          </button>

          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('documents')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Icard} alt="Icard" />
            <p style={{ margin: '0' }}>Visa</p>
          </button>

          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('timeline')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Migration} alt="Migration" />
            <p style={{ margin: '0' }}>Plan migratorio</p>
          </button>

          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('translations')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={World} alt="World" />
            <p style={{ margin: '0' }}>Traducciones</p>
          </button>

          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('library')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={File} alt="File" />
            <p style={{ margin: '0' }}>Biblioteca</p>
          </button>

          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('register')
            }}
          >
            <img style={{ width: '15px', height: '15px' }} src={Graduation} alt="Graduation" />
            <p style={{ margin: '0' }}>Registros</p>
          </button>

          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('services')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Puzzle} alt="Puzzle" />
            <p style={{ margin: '0' }}>Servicios</p>
          </button>
          <button
            className='button-form4-navbar'
            style={{ height: '2rem', display: 'flex', gap: '5px', alignItems: 'center' }}
            onClick={() => {
              setSwitchMenus(true)
              handleOption('email')
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={Talk} alt="Talk" />
            <p style={{ margin: '0' }}>Comunicación</p>
          </button>
        </div>
      }

      <Box>
        {option.profile && (
          <>
            {/* aqui de desplegará todos los datos del dasboard del cliente para ver una vista general de productos ETC */}
          </>
        )}
   {option.edit && (
  <div className='user-profile-popup'>
    <div className='user-profile-popup-continer'>
      <div style={{ borderBottom: '1px solid #DFE4EA', paddingBottom: '1rem' }}>
        <button
          style={{ marginTop: '1rem' }}
          className='button-ui button-secondary product-name btn-section help-step-btn'
          onClick={() => {
            setSwitchMenus(false)
            handleOption('empty')
            setRefetchActivator(true)
            refetch()
          }}>
          <img src={ArrowLeft} alt="ArrowLeft" />
          Volver
        </button>
      </div>



      {currentUser != null ? (
        <ProfileDetails2
          refetchActivator={refetchActivator}
          setRefetchActivator={setRefetchActivator}
          currentUser={currentUser}
          profile={currentUser}
          isUserLoading={false}
          updateProfileBasic={handlePpdateProfileBasic}
          showAvatar={false}
          edit={false}
        />
      ) : null}
      <a style={{fontSize:'16px', fontWeight:'700'}}>Asociar cuentas</a>

      <div style={{border:'1px solid black', borderRadius:'10px', padding:'1rem'}}> <div className="serach-bar-container" style={{width:'100%'}}>
      <a style={{fontSize:'16px', fontWeight:'400', marginBottom:'20px'}}>Correo electrónico de cuenta</a>  <div style={{position:'relative', display:'flex', gap:'10px'}}>

      <input
      style={{ width: '100%', border: '1px solid #637381', color: '#637381', marginTop:'10px' }}
      type="text"
      placeholder="Ingresa correo electrónico para asociar al cliente"
      className='input-styles'
      value={wordSearched}
      onChange={(e) => {
        setWordSearched(e.target.value)
        changeInputUser(e.target.value)
      }}
    />
  {/* {StaffData && StaffData[0] && StaffData[0].type !== 'consultant' && ( */}
            <button
              // style={{ border: '1px solid #01A9DB', bottom: '0.8rem', position: 'relative', height: '2.5rem' }}
              className="button-ui button-tertiary button-s"
              onClick={handleAddAssociatedUser}
            >
              Añadir
            </button>
       {/*    )} */}
        </div>





    {isFetching ? (
      <div className='results-list-emailadd'>Cargando..</div>
    ) : (
      wordSearched && (
        <div className='results-list-emailadd'>
          {users && users.length > 0 ? (
            users.map((user: Users) => (
              <div
                key={user.id}
                onClick={() => handleSelectUser(user)}
                className='search-result'>
                {user.email} -- {user.firstName} {user.lastName}
              </div>
            ))
          ) : (
            <div>No se encontraron resultados.</div>
          )}
        </div>
      )
    )}

    {selectedUser && (
      <div>
        <div>Seleccionado: {selectedUser.email}</div>

      </div>
    )}

<div style={{border:'1px solid black', borderRadius:'10px', padding:'1rem'}}>
  <h4>Cuentas asociadas al perfil</h4>
  {associatedAccounts.length > 0 ? (
    associatedAccounts.map(account => (
      <div key={account.id} className="associated-account" style={{borderBottom: '1px solid rgb(223, 228, 234'}}>
        {account.usertwo.email}
        <button
          style={{display:'flex', gap: '10px', alignItems: 'center'}}
          className="remove-button"
          onClick={() => handleRemoveAccount(currentUser?.id, account.usertwo.id)}
        >
          <img src="https://immiland.app/static/media/circle-minus.0cb1f22e0debddeab079.svg" alt="DeleteFrom" className="icon" />
          Quitar
        </button>
      </div>
    ))
  ) : (
    <p>No hay cuentas asociadas</p>
  )}
</div>


  </div></div>


    </div>



  </div>
)}

        {option.edit2 && (
          <div className='user-profile-popup'>
            <div className='user-profile-popup-continer'>
              <div style={{ borderBottom: '1px solid #DFE4EA', paddingBottom: '1rem' }}>
                <button
                  style={{ marginTop: '1rem' }}
                  className='button-ui button-secondary product-name btn-section help-step-btn'
                  onClick={() => {
                    setSwitchMenus(false)
                    handleOption('empty')
                    setRefetchActivator(true)
                    refetch()
                  }}>
                  <img src={ArrowLeft} alt="ArrowLeft" />
                  Volver
                </button>
              </div>
              {currentUser != null ? (
                <InmiDetailsNew
                  currentUser={currentUser}
                  setRefetchActivator={setRefetchActivator}
                  userId={currentUser?.id?.toString()}
                  data={prof}
                  setTabActive={setTabActive}
                  ProfileImmigration={prof}
                  isUserLoading={isUserLoading}
                  updateProfile={updateProfileNew}
                  showAvatar={true}
                />
              ) : null}
            </div>
          </div>
        )}
        {option.edit3 && (
          <div className='user-profile-popup'>
            <div className='user-profile-popup-continer'>
              <div style={{ borderBottom: '1px solid #DFE4EA', paddingBottom: '1rem' }}>
                <button
                  style={{ marginTop: '1rem' }}
                  className='button-ui button-secondary product-name btn-section help-step-btn'
                  onClick={() => {
                    setSwitchMenus(false)
                    handleOption('empty')
                    setRefetchActivator(true)
                    refetch()
                  }}>
                  <img src={ArrowLeft} alt="ArrowLeft" />
                  Volver
                </button>
              </div>
              {currentUser != null ? (
                <InmiDetailsNew2
                  currentUser={currentUser}
                  setRefetchActivator={setRefetchActivator}
                  userId={currentUser?.id?.toString()}
                  data={prof}
                  setTabActive={setTabActive}
                  ProfileImmigration={prof}
                  isUserLoading={isUserLoading}
                  updateProfile={updateProfileNew}
                  showAvatar={true}
                />
              ) : null}
            </div>
          </div>
        )}
        {option.email && (
          <>
            <ShowEmailUser data={data} handleClose={handleOption} />
          </>
        )}
        {option.services && (
          <>
            <ShowServices handleClose={handleOption} />
          </>
        )}
        {option.translations && (
          <>
            <ShowTranslations handleClose={handleOption} />
          </>
        )}{' '}
        {option.forms && (
          <>
            <ShowForms handleClose={handleOption} />
          </>
        )}
        {option.documents && (
          <>
            <ShowClientDocuments
              handleClose={handleOption}
              currentUserEmail={currentUser?.email ? currentUser.email : ''}
              goToResumen={handleGoToResumen}
            />
          </>
        )}
        {option.register && (
          <>
            <AplicationTableByUser user={currentUser ? currentUser : undefined} />
          </>
        )}
        {option.timeline && (
          <>
            <TimelineComponent
              user={currentUser !== null ? currentUser : user}
              email={''}
              timelineUserId={
                currentUser?.id !== null || currentUser?.id !== undefined
                  ? currentUser?.id?.toString()
                  : ''
              }
            />
          </>
        )}
        
        {option.library && (
          <>
            <ListDocuments user={currentUser} />
          </>
        )}
        {isModalVisible === true ? <NotesModal
          userInfo={currentUser}
          visible={isModalVisible}
          onCancel={handleCancel}
          onCreate={handleCreate}
          products={[]}
        /> : null}
      </Box>
    </>
  )
}

export default observer(UserHeader)
