/* eslint-disable @typescript-eslint/no-unused-vars */

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useState, useEffect } from 'react'
import { CountrySelect } from '../../../../../../app/modules/utils/components/CountrySelect'
import { type ProfileImmigration } from '../../../../../../app/modules/apps/profile/core/_models'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import 'antd/dist/antd.css'
import './style.css'

import { updateProfileNew } from 'app/modules/apps/profile/core/_requests'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const profileDetailsSchema = Yup.object().shape({
  emigrar_con_familia: Yup.string().required('Campo requerido'),
  otroCampo: Yup.string().test('conditionalValidation', 'Este campo es requerido cuando emigrar_con_familia es Sí', function (value) {
    const { emigrar_con_familia } = this.parent
    if (emigrar_con_familia === 'Sí') {
      return !!value
    }
    return true
  }),
  conyuge: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true
      }
      return !!value
    },
    message: 'Campo requerido',
  }),
  edadconyuge: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true
      }
      return !!value
    },
    message: 'Campo requerido',
  }),
  estado_civil: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      if (emigrar_con_familia === 'Si') {
        return !!value
      }
      return true
    },
    message: 'Campo requerido',
  }),
  conyuge_pais_residencia: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true // No se requiere la validación del cónyuge si emigrar_con_familia es "No"
      }
      return !!value
    },
    message: 'Campo requerido',
  }),

  conyuge_grado_academico: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true // No se requiere la validación del cónyuge si emigrar_con_familia es "No"
      }
      return !!value
    },
    message: 'Campo requerido',
  }),

  conyuge_area_estudios: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true // No se requiere la validación del cónyuge si emigrar_con_familia es "No"
      }
      return !!value
    },
    message: 'Campo requerido',
  }),

  conyuge_proeficiencia_ingles: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true // No se requiere la validación del cónyuge si emigrar_con_familia es "No"
      }
      return !!value
    },
    message: 'Campo requerido',
  }),

  conyuge_proeficiencia_frances: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true // No se requiere la validación del cónyuge si emigrar_con_familia es "No"
      }
      return !!value
    },
    message: 'Campo requerido',
  }),

  conyuge_historial_laboral: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const estadoCivil = this.parent.estado_civil
      if (emigrar_con_familia === 'No' || ['Soltero', 'Divorciado'].includes(estadoCivil)) {
        return true // No se requiere la validación del cónyuge si emigrar_con_familia es "No"
      }
      return !!value
    },
    message: 'Campo requerido',
  }),

  numero_hijos: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      if (emigrar_con_familia === 'Si') {
        return !!value
      }
      return true
    },
    message: 'Campo requerido',
  }),

  edad_hijos: Yup.string().test({
    test: function (value) {
      const emigrar_con_familia = this.parent.emigrar_con_familia
      const numero_hijos = this.parent.numero_hijos
      if (emigrar_con_familia === 'Si' && numero_hijos > 0) {
        return !!value
      }
      return true
    },
    message: 'Campo requerido',
  }),
})

interface Props {
  updateProfile: (ProfileImmigration: ProfileImmigration) => void
  ProfileImmigration: ProfileImmigration
  isUserLoading?: boolean
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
  /*  onComplete: (survey: any) => void */
  data: any
  userId: string | undefined
  setRefetchActivator: Dispatch<SetStateAction<boolean>>
  setFirstInfoObject: Dispatch<SetStateAction<any>>
  firstInfoObject: any
}

const InmiDetailsChop2Modal: React.FC<Props> = ({
  edit = true,
  setTabActive,
  data,
  setRefetchActivator,
  userId,
  setFirstInfoObject,
  firstInfoObject
}) => {
  // Actualizar los valores iniciales del formulario cuando data.profile cambie
  useEffect(() => {
    if (!isEmpty(data.profile)) {
      setFormValues(data.profile)
    }
  }, [data.profile])
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [allFieldsFilled, setAllFieldsFilled] = useState(false)
  const initialFormValues = {
    estado_civil: '',
    conyuge: '',
    edadconyuge: '',
    conyuge_pais_residencia: '',
    conyuge_grado_academico: '',
    conyuge_area_estudios: '',
    conyuge_proeficiencia_ingles: '',
    conyuge_proeficiencia_frances: '',
    conyuge_historial_laboral: '',
    emigrar_con_familia: '',
    numero_hijos: '',
  }
  const [formValues, setFormValues] = useState<ProfileImmigration | null>(
    data.profile || initialFormValues
  )
  const formikSecond = useFormik<ProfileImmigration>({
    initialValues: isEmpty(formValues) ? initialFormValues : formValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      /* await updateProfile(values) */
      setLoading(false)
    },
  })
  const shouldEnableButton =
    formikSecond.values.emigrar_con_familia === 'No' || // Si la respuesta es "No"
    (isEmpty(formikSecond.errors) && // Y no hay errores de validación
      (formikSecond.values.emigrar_con_familia !== '' || // Y se ha seleccionado una respuesta
        formikSecond.touched.emigrar_con_familia)) // O se ha tocado el campo

  const formData = {
    estado_civil: formikSecond.values.estadoCivil,
    conyuge: formikSecond.values.conyuge,
    edadconyuge: formikSecond.values.edadconyuge,
    conyuge_pais_residencia: formikSecond.values.conyuge_pais_residencia,
    conyuge_grado_academico: formikSecond.values.conyuge_grado_academico,
    conyuge_area_estudios: formikSecond.values.conyuge_area_estudios,
    conyuge_proeficiencia_ingles: formikSecond.values.conyuge_proeficiencia_ingles,
    conyuge_proeficiencia_frances: formikSecond.values.conyuge_proeficiencia_frances,
    conyuge_historial_laboral: formikSecond.values.conyuge_historial_laboral,
    numero_hijos: formikSecond.values.numero_hijos,
    edad_hijos: formikSecond.values.edad_hijos,
    emigrar_con_familia: formikSecond.values.emigrar_con_familia,
  }
  const [progreso, setProgreso] = useState(0.9494949)
  const [redirect, setRedirect] = useState(false)
  const navigate = useNavigate()
  const [redirectAfterSave, setRedirectAfterSave] = useState(false)

  useEffect(() => {
    // Verifica si todos los campos del formulario están llenos
    const isFormFilled = Object.values(formikSecond.values).every((value) => value !== '')
    setAllFieldsFilled(isFormFilled)
  }, [formikSecond.values])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    formData.estado_civil = formikSecond.values.estadoCivil
    formData.conyuge = formikSecond.values.conyuge
    formData.edadconyuge = formikSecond.values.edadconyuge
    formData.conyuge_pais_residencia = formikSecond.values.conyuge_pais_residencia
    formData.conyuge_grado_academico = formikSecond.values.conyuge_grado_academico
    formData.conyuge_area_estudios = formikSecond.values.conyuge_area_estudios
    formData.conyuge_proeficiencia_ingles = formikSecond.values.conyuge_proeficiencia_ingles
    formData.conyuge_proeficiencia_frances = formikSecond.values.conyuge_proeficiencia_frances
    formData.conyuge_historial_laboral = formikSecond.values.conyuge_historial_laboral
    formData.numero_hijos = formikSecond.values.numero_hijos
    formData.edad_hijos = formikSecond.values.edad_hijos
    formData.emigrar_con_familia = formikSecond.values.emigrar_con_familia

    try {
      await formikSecond.validateForm()
      await formikSecond.handleSubmit()
      await profileDetailsSchema.validate(formikSecond.values, { abortEarly: false })
      if (firstInfoObject) {
        const objetoCombinado = { ...formikSecond.values, ...firstInfoObject }
        await updateProfileNew({
          profile: objetoCombinado,
          userId: userId,
        })
        setFirstInfoObject(null)
      } else {
        setFirstInfoObject(formikSecond.values)
        await updateProfileNew({
          profile: formikSecond.values,
          userId: userId,
        })
      }

      const nuevoProgreso = calcularProgreso(formikSecond.values)
      setProgreso(nuevoProgreso)
      // Guardar los datos del formulario en el estado local del componente
      setFormValues(formikSecond.values)
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
      setRefetchActivator(true)
      setRedirectAfterSave(true)
      // Redirecciona después de 1 segundo
      setTimeout(() => {
        setRedirect(true)
        navigate('/dashboard')
      }, 1000)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        console.error('Error de validación:', error.errors)
        Swal.fire({
          title: 'Error',
          text: 'No puede continuar porque hay campos vacíos en el formulario',
          icon: 'error',
          confirmButtonText: 'Continuar',
        })
      } else {
        console.error('Error al actualizar el perfil:', error)
      }
    }
  }

  const handleSaveButtonClick = async () => {
    setLoading(true)
    // Realiza aquí la lógica de guardado de datos
    // Simulación de espera de 1 segundo (puedes eliminar esto en tu código real)
    await new Promise(resolve => setTimeout(resolve, 1000))
    setLoading(false)
    // Redirecciona a la ruta "/dashboard" después de 1 segundo
    setTimeout(() => {
      navigate('/dashboard')
    }, 1000)
  }

  useEffect(() => {
    // Si el estado de redirección es verdadero
    if (redirectAfterSave) {
      // Espera 1.5 segundos antes de redirigir
      const timeout = setTimeout(() => {
        // Redirige a la ruta "/dashboard"
        navigate("/dashboard")
      }, 1500)
      // Limpia el timeout para evitar fugas de memoria
      return () => clearTimeout(timeout)
    }
  }, [redirectAfterSave, navigate])

  useEffect(() => {
    const nuevoProgreso = calcularProgreso(formikSecond.values)
    setProgreso(nuevoProgreso)
  }, [formikSecond.values]) // Listen for changes in form values

  const calcularProgreso = (values: any) => {
    // Definir las preguntas que contribuyen al progreso
    const preguntas = [
      'estado_civil',
      'conyuge',
      'edadconyuge',
      'conyuge_pais_residencia',
      'conyuge_grado_academico',
      'conyuge_area_estudios',
      'conyuge_proeficiencia_ingles',
      'conyuge_proeficiencia_frances',
      'conyuge_historial_laboral',
      'numero_hijos',
      // Agrega otras preguntas que contribuyan al progreso aquí
    ]

    // Calcular cuántas de estas preguntas están respondidas
    const preguntasRespondidas = preguntas.filter((pregunta) => values[pregunta] !== '').length

    // Calcular el progreso en base al número de preguntas respondidas
    const nuevoProgreso = (preguntasRespondidas / preguntas.length) * 100

    return nuevoProgreso
  }
  const progresoEntero = Math.floor(progreso)

  const handleInputChange = (event: any) => {
    const { value } = event.target
    const newValue = value.replace(/\D/g, '') // Remover todos los caracteres no numéricos

    // Actualizar el valor del input con solo números
    event.target.value = newValue
  }

  const handleCommaInputChange = (event: any) => {
    const { value } = event.target
    const newValue = value.replace(/[^\d,]/g, '') // Remover todos los caracteres que no sean números ni comas
    // Actualizar el valor del input con solo números y comas
    event.target.value = newValue
  }
  return (
    <div
      id='kt_account_profile_details'
      style={{
        backgroundColor: 'white',
        paddingTop: '1rem',
        width: '100%',
        borderRadius: '25px',
      }}>
      <p className='card-container-2form-text'
        style={{
          fontSize: '1rem',
        }}
      >
        Por ultimo llene los datos sobre Informacion Familiar
      </p>
      <form
        onSubmit={handleSubmit}
        noValidate
        className='form'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          paddingBottom:'2rem' }}>

        <div className='card-body2 bg-white  container-form-modal flex-column'>
          <H3 className='card-container-2form-text col-lg-8 col-form-label required fw-bold fs-6'>
            DATOS GENERALES
          </H3>
          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderRadius: '10px',
              paddingTop: '1rem',
              marginBottom: '1rem'
            }} 
            className='border-black'>
            <div className='container-question'>
              <H3 className='lg-12 pl-3 col-form-label title-forms'>
                Desea emigrar con su familia?
              </H3>
              <div className='col-lg-8 fv-row'>
                <Select
                  className='custom-input-select'
                  {...formikSecond.getFieldProps('emigrar_con_familia')}
                  value={formikSecond.values.emigrar_con_familia}
                >
                  <option value=''>Seleccione...</option>
                  <option value='Si'>Sí</option>
                  <option value='No'>No</option>
                </Select>
                {formikSecond.touched.emigrar_con_familia &&
                  formikSecond.errors.emigrar_con_familia && (
                    <Alert>{formikSecond.errors.emigrar_con_familia}</Alert>
                  )}
              </div>
            </div>

            {formikSecond.values.emigrar_con_familia === 'Si' && (
              <>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Estado civil
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='custom-input-select'
                      {...formikSecond.getFieldProps('estado_civil')}
                      value={formikSecond.values.estado_civil}>
                      <option value=''>Seleccione</option>
                      <option value='Soltero'>Soltero</option>
                      <option value='Casado'>Casado</option>
                      <option value='Union_libre'>Unión libre</option>
                      <option value='Divorciado'>Divorciado</option>
                    </Select>
                    {formikSecond.touched.estado_civil && formikSecond.errors.estado_civil && (
                      <Alert>{formikSecond.errors.estado_civil}</Alert>
                    )}
                  </div>
                </div>
              </>
            )}

          </div>
          {formikSecond.values.estado_civil === 'Casado' ||
            formikSecond.values.estado_civil === 'Union_libre' ? (
            <div>
              <H3 className='lg-12 pl-3 col-form-label title-forms'>
                DATOS DEL CONYUGE
              </H3>
              <div className='border-black'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderRadius: '10px',
                  paddingTop: '1rem',
                  marginBottom: '1rem'
                }}>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Nombre del cónyuge
                  </H3>

                  <div className='col-lg-8 fv-row'>
                    <Input
                      className='custom-input-select'
                      type='text'
                      placeholder='Nombre del cónyuge'
                      {...formikSecond.getFieldProps('conyuge')}
                      value={formikSecond.values.conyuge}
                    />
                    {formikSecond.touched.conyuge && formikSecond.errors.conyuge && (
                      <Alert variant='danger'>{formikSecond.errors.conyuge}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Edad del cónyuge
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Input
                      onInput={handleInputChange}
                      className='custom-input-select'
                      type='text'
                      placeholder='Edad del cónyuge'
                      {...formikSecond.getFieldProps('edadconyuge')}
                      value={formikSecond.values.edadconyuge}
                    />
                    {formikSecond.touched.edadconyuge && formikSecond.errors.edadconyuge && (
                      <Alert variant='danger'>{formikSecond.errors.edadconyuge}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    País de residencia actual
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='custom-input-select'
                      {...formikSecond.getFieldProps('conyuge_pais_residencia')}
                      value={formikSecond.values.conyuge_pais_residencia}
                    >
                      <CountrySelect />
                    </Select>
                    {formikSecond.touched.conyuge_pais_residencia &&
                      formikSecond.errors.conyuge_pais_residencia && (
                        <Alert>{formikSecond.errors.conyuge_pais_residencia}</Alert>
                      )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Grado académico más alto finalizado
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='custom-input-select'
                      {...formikSecond.getFieldProps('conyuge_grado_academico')}
                      value={formikSecond.values.conyuge_grado_academico}
                    >
                      <option value=''>Seleccione</option>
                      <option value='Elementaria'>Elementaria</option>
                      <option value='Secundaria'>Secundaria</option>
                      <option value='Post_secundaria'>Post secundaria (nivel técnico)</option>
                      <option value='Post_secundaria'>
                        Post secundaria (nivel universitario)
                      </option>
                      <option value='Postgrado'>
                        Postgrado (certificación o diploma postgrado)
                      </option>
                      <option value='Doctorado'>Maestría</option>
                      <option value='Doctorado'>Doctorado</option>
                    </Select>
                    {formikSecond.touched.conyuge_grado_academico &&
                      formikSecond.errors.conyuge_grado_academico && (
                        <Alert>{formikSecond.errors.conyuge_grado_academico}</Alert>
                      )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Área de estudios
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Input
                      className='custom-input'
                      type='text'
                      placeholder='Área de estudios'
                      {...formikSecond.getFieldProps('conyuge_area_estudios')}
                      value={formikSecond.values.conyuge_area_estudios}
                    />
                    {formikSecond.touched.conyuge_area_estudios &&
                      formikSecond.errors.conyuge_area_estudios && (
                        <Alert variant='danger'>
                          {formikSecond.errors.conyuge_area_estudios}
                        </Alert>
                      )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Proeficiencia en Inglés
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='custom-input'
                      {...formikSecond.getFieldProps('conyuge_proeficiencia_ingles')}
                      value={formikSecond.values.conyuge_proeficiencia_ingles}
                    >
                      <option value=''>
                        Seleccione
                      </option>
                      <option value='Nulo'>Nulo</option>
                      <option value='Basico'>Basico</option>
                      <option value='Intermedio'>Intermedio</option>
                      <option value='Avanzado'>Avanzado</option>
                    </Select>
                    {formikSecond.touched.conyuge_proeficiencia_ingles &&
                      formikSecond.errors.conyuge_proeficiencia_ingles && (
                        <Alert>{formikSecond.errors.conyuge_proeficiencia_ingles}</Alert>
                      )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Proeficiencia en Francés
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='custom-input'
                      {...formikSecond.getFieldProps('conyuge_proeficiencia_frances')}
                      value={formikSecond.values.conyuge_proeficiencia_frances}
                    >
                      <option value=''>
                        Seleccione
                      </option>
                      <option value='Nulo'>Nulo</option>
                      <option value='Basico'>Basico</option>
                      <option value='Intermedio'>Intermedio</option>
                      <option value='Avanzado'>Avanzado</option>
                    </Select>
                    {formikSecond.touched.conyuge_proeficiencia_frances &&
                      formikSecond.errors.conyuge_proeficiencia_frances && (
                        <Alert>{formikSecond.errors.conyuge_proeficiencia_frances}</Alert>
                      )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Historial laboral
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <textarea
                      className='custom-input-form'
                      placeholder='Historial laboral'
                      {...formikSecond.getFieldProps('conyuge_historial_laboral')}
                      value={formikSecond.values.conyuge_historial_laboral}
                    ></textarea>
                    {formikSecond.touched.conyuge_historial_laboral &&
                      formikSecond.errors.conyuge_historial_laboral && (
                        <Alert variant='danger'>
                          {formikSecond.errors.conyuge_historial_laboral}
                        </Alert>
                      )}
                  </div>
                </div>
              </div>
            </div>) : null}
          {formikSecond.values.emigrar_con_familia === 'Si' &&
            <div>
              <H3 className='lg-12 pl-3 col-form-label title-forms'>
                DATOS DE LOS HIJOS
              </H3>
              <div className='border-black'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderRadius: '10px',
                  paddingTop: '1rem',
                  marginBottom: '1rem'
                }}>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Nº de hijos (digíte 0 si no tiene)
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Input
                      onInput={handleInputChange}
                      className='custom-input'
                      type='text'
                      placeholder='Nº de hijos'
                      {...formikSecond.getFieldProps('numero_hijos')}
                      value={formikSecond.values.numero_hijos}
                    />
                    {formikSecond.touched.numero_hijos && formikSecond.errors.numero_hijos && (
                      <Alert variant='danger'>{formikSecond.errors.numero_hijos}</Alert>
                    )}
                  </div>
                </div>
                {Number(formikSecond.values.numero_hijos) > 0 && (
                  <div className='container-question'>
                    <H3 className='lg-12 pl-3 col-form-label title-forms'>
                      Edad de sus hijos (ej: 18,20)
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Input
                        className='custom-input'
                        type='text'
                        placeholder='Digite la edad de sus hijos'
                        {...formikSecond.getFieldProps('edad_hijos')}
                        value={formikSecond.values.edad_hijos}
                        onInput={handleCommaInputChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>}
          {edit && (
            <div className='container-buttons2 mb-8 mt-4'>
              <Button
                type="submit"
                className="button-ui button-primary"
                disabled={!shouldEnableButton || loading}
                style={{ height: '2rem', width: '8rem', alignSelf: 'end', marginRight: '2rem' }}              >
                {!loading && 'Guardar'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Por favor espere...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
                {!isEmpty(formikSecond.errors)}
              </Button>
            </div>
          )}
        </div>
      </form>

    </div>
  )
}

export default observer(InmiDetailsChop2Modal)
