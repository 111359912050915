/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Input, InputRef, Slider, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  type Product,

} from '../../../../../app/modules/apps/cart/core/products/_models';
import { useEffect, useRef, useState } from 'react';
import {
  createProductNewManager,
  getProductsTableNewQuery,
  updateProductNew,

} from '../../../../../app/modules/apps/cart/core/products/_requests';
import { useQuery } from 'react-query';
import { getCategoryTableFilter } from 'app/modules/apps/cart/core/category/_requests';
import { GrEdit } from 'react-icons/gr';
import { ColumnFilterItem, ColumnType } from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import ProductsForms from '../ProductsForms';
import ProductImage from './ProductImage';
import checkmark_white from '../../../../../../src/app/pages/contratationApp/icons/checkmark_white.svg';


const ProductManager = () => {
  const [currentName, setCurrentName] = useState('');
  const [currentCategory, setCurrentCategory] = useState([]);
  const [currentPrice, setCurrentPrice] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromPrice, setFromPrice] = useState(0);
  const [toPrice, setToPrice] = useState(0);
  const [categoryInfo, setCategoryInfo] = useState<ColumnFilterItem[]>();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [handleEditProduct, setHandleEditProduct] = useState<any>(null);
  const [productObject, setproductObject] = useState({
    name: handleEditProduct?.name,
    category: handleEditProduct?.categoryId,
    description: handleEditProduct?.description,
    comment: handleEditProduct?.comment,
    price: handleEditProduct?.price,
    file: handleEditProduct?.file,
  });
  const [showToast, setShowToast] = useState(false);

  // Handle input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setproductObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchProducts = async (
    page: number,
    currentName: string,
    currentCategory: [],
    currentPrice: number[]
  ) => {
    const response = await getProductsTableNewQuery({
      limit: 50,
      page: page,
      name: currentName,
      category: currentCategory,
      price: currentPrice,
    });
    return response;
  };

  const { data, refetch } = useQuery(
    ['ListProducts', currentPage, currentCategory, currentPrice],
    async () => {
      const initialData = await fetchProducts(
        currentPage,
        currentName,
        currentCategory as [],
        currentPrice as number[]
      );
      return initialData;
    }
  );

  const { data: CatData } = useQuery('getCat', async () => await getCategoryTableFilter());

  const handleTableChange = (pagination: any, filters: any) => {
    setCurrentPage(pagination.current);
    if (filters.categoryName && filters.categoryName.length > 0) {
      setCurrentCategory(filters.categoryName);
    }
  };

  const { Column } = Table;

  useEffect(() => {
    setCategoryInfo(
      CatData?.data?.data?.map((item: any) => {
        if (item.name !== undefined) {
          return {
            text: item.name,
            value: item.name,
          };
        } else {
          return {
            text: '',
            value: '',
          };
        }
      })
    );
  }, [CatData]);

  useEffect(() => {
    setCurrentPage(1);
    refetch();
  }, [currentName, currentCategory, currentPrice]);

  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof Product;

  const handleSearchCustom = (value: string) => {
    setCurrentName(value);
  };

  const getColumnSearchPropsName = (dataIndex: DataIndex): ColumnType<Product> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            handleSearchCustom(selectedKeys[0] as string);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearchCustom(selectedKeys[0] as string);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setCurrentName('');
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: Product) =>
      record.name
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const getFile = (file: File | null) => {
    setproductObject((prevState) => ({
      ...prevState, // Spread the previous state
      file: file, // Update the file property
    }));
  };

  useEffect(() => {
    setproductObject({
      name: handleEditProduct?.name,
      category: handleEditProduct?.categoryId,
      description: handleEditProduct?.description,
      comment: handleEditProduct?.comment,
      price: handleEditProduct?.price,
      file: handleEditProduct?.image,
    });
  }, [handleEditProduct]); // El array vacío significa que este efecto se ejecuta solo una vez, al montar y desmontar

  const handleSave = async () => {
    const result = await updateProductNew({ ...productObject as Product, id: handleEditProduct.id });
    if (result) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
      refetch();
      setHandleEditProduct(null);
    }
  };

  const handleSaveNew = async () => {
    const result = await createProductNewManager({ ...productObject as Product });
    if (result) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
      refetch();
      setHandleEditProduct(null);
    }
  };

  return (
    <>
      <div style={{ marginBottom: '2rem' }}>{!handleEditProduct && (
        <Button className="button-ui button-secondary button-s button-form" onClick={() => { setHandleEditProduct("new"); }}>Agregar Producto</Button>
      )}</div>


      {showToast === true ? (
        <div className="toast-style-container">
          <div className="toast-style">
            <div style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Guardado Exitoso
          </div>
        </div>
      ) : null}

      {!handleEditProduct ? (
        <Table
          style={{ overflow: 'auto', position: 'relative', width: 'fit-content' }}
          scroll={{ x: 'max-content', y: 500 }}
          pagination={{
            pageSize: 50,
            showLessItems: true,
            size: 'small',
            responsive: true,
            simple: true,
            showQuickJumper: false,
            showSizeChanger: false,
            current: currentPage,
            total: data?.data.count || 0,
          }}
          dataSource={data?.data.data}
          onChange={handleTableChange}
        >
          <Column
            width={4}
            render={(product: any) => (
              <Space>
                <Button
                  onClick={() => {
                    setHandleEditProduct(product);
                  }}
                >
                  <GrEdit className="iconFaFacebook" />
                </Button>
              </Space>
            )}
          />

          <Column
            width={200}
            title="Nombre"
            dataIndex={'name'}
            {...getColumnSearchPropsName('name')}
          />

          <Column
            title="Categoria"
            dataIndex={'categoryName'}
            key="categoryName"
            width={200}
            filters={categoryInfo?.filter(
              (thing, index, self) =>
                index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
            )}
            onFilter={(value, record: any) => {
              return record === value.toString();
            }}
            render={(record: string) => <>{record}</>}
          />

          <Column
            title="Precio"
            width={100}
            render={(record: any) => (
              <Space style={{ display: 'flex', gap: '0px' }}>
                CAD $
                {parseFloat(record.price.toFixed(2)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </Space>
            )}
            filterDropdown={({ confirm }) => (
              <div style={{ padding: 8 }}>
                <Slider
                  range
                  min={0}
                  max={5000}
                  defaultValue={[fromPrice, toPrice]}
                  onChange={(e) => {
                    setFromPrice(e[0]);
                    setToPrice(e[1]);
                  }}
                ></Slider>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      confirm();
                      setCurrentPrice([fromPrice, toPrice]);
                    }}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    onClick={() => {
                      setFromPrice(0);
                      setToPrice(0);
                      setCurrentPrice([0, 0]);
                    }}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Limpiar
                  </Button>{' '}
                  *
                </div>
              </div>
            )}
          />
        </Table>
      ) : (
        <>
          {/* <Button onClick={() => { setHandleEditProduct(null) }}>Atras</Button> */}
          <div className="form-container">
            <div className="form-group">
              <label>Nombre del producto</label>
              <input
                className="form-input"
                defaultValue={handleEditProduct.name}
                name="name"
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              {CatData && (
                <>
                  <label>Nombre de la Categoria</label>
                  <select
                    className="form-input"
                    name="category"
                    onChange={handleInputChange}
                    defaultValue={handleEditProduct.categoryId}
                  >
                    <option key={''} value={''}>Sin Categoria</option>
                    {CatData.data?.data?.map((cat: any) => (
                      <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))}
                  </select>
                </>
              )}
            </div>

            <div className="form-group">
              <label>Descripcion</label>
              <input
                className="form-input"
                name="description"
                onChange={handleInputChange}
                defaultValue={handleEditProduct.description}
              />
            </div>

            <div className="form-group">
              <label>Comentario</label>
              <input
                className="form-input"
                name="comment"
                onChange={handleInputChange}
                defaultValue={handleEditProduct.comment}
              />
            </div>

            <div className="form-group">
              <label>Precio</label>
              <input
                className="form-input"
                name="price"
                onChange={handleInputChange}
                defaultValue={handleEditProduct.price}
              />
            </div>

            <div className="form-group">
              <label>Imagen</label>
              <ProductImage product={handleEditProduct} getFile={getFile} />
            </div>

            {handleEditProduct == "new" ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <button style={{ display: 'flex', alignItems: 'center' }} className="button-enviar" onClick={() => { handleSaveNew(); }}>
                  <a style={{ color: 'black' }}>Agregar el producto</a>
                </button>
              </div>
            ) : (
              <Button onClick={() => { handleSave(); }}>Actualizar el producto</Button>
            )}
          </div>
          <ProductsForms product={handleEditProduct} />
        </>
      )}
    </>
  );
};

export default ProductManager;
