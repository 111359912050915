import axios, {type AxiosResponse} from 'axios'
import {ExcelLogger, type Program} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PROGRAM_URL = `${API_URL}/program`

const getProgram = (): Promise<Program[]> | undefined => {
  return axios.get(`${PROGRAM_URL}`).then((response: AxiosResponse) => {
    return response.data as Program[]
  })
}

const createProgram = (program: Program): Promise<Program[]> | undefined => {
  return axios.post(`${PROGRAM_URL}/newProgram`, {...program}).then((response: AxiosResponse) => {
    return response.data as Program[]
  })
}

const getLogs = (): any | undefined => {
  return axios.get(`${API_URL}/logger`).then((response: AxiosResponse) => {
    return response.data as ExcelLogger[]
  })
}

const updateProgram = (program: string, body: {}): Promise<Program> | undefined => {
  return axios
    .patch(`${PROGRAM_URL}/updateProgram/${program}`, {...body})
    .then((response: AxiosResponse) => {
      return response.data as Program
    })
}

const getProgramTableFilter = (program: Program): Promise<any> => {
  return axios.get(`${PROGRAM_URL}`, {params: program}).then((response: AxiosResponse) => {
    return response.data // Usar response.data en lugar de "response as any"
  })
}

const getPormgramsById = (institutionId: string): Promise<Program[]> | undefined => {
  return axios
    .get(`${PROGRAM_URL}/byinstitution/${institutionId}`)
    .then((response: AxiosResponse) => {
      return response.data as Program[]
    })
}

export {getProgram, createProgram, updateProgram, getProgramTableFilter, getPormgramsById, getLogs}
