/* eslint-disable  */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Tab, Tabs} from 'react-bootstrap'
import {
  getProfileImmigration,
  updateProfileBasicByUser,
  updateProfileImmigrationAdmin,
  updateProfileNew,
} from '../../../app/modules/apps/profile/core/_requests'
import {useContext, useEffect, useState} from 'react'
import {type IComposeData} from '../../../app/modules/apps/appointments/core/_models'
import ProfileDetails from '../../../app/modules/accounts/components/settings/cards/ProfileDetails'
import {RootStoreContext} from '../../../stores/rootStore'
import Swal from 'sweetalert2'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import InmiDetailsNew from '../../../app/modules/accounts/components/settings/cards/surveyAnt'
import {useQuery} from 'react-query'
import {type ProfileImmigration, type Profile} from 'app/modules/apps/profile/core/_models'

interface IProps {
  composepostdata: IComposeData
  edit: boolean
  isUserLoading?: boolean
  ProfileImmigration: ProfileImmigration
}

const UserProfile: React.FC<IProps> = ({composepostdata, edit = false, isUserLoading}) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {user, getUser, getpProfile, profileImmigration} = rootStore.userStore
  const [userId, setUserId] = useState(composepostdata.userId)
  const [tabActive, setTabActive] = useState<string>('profile')
  const {data, status} = useQuery('profileimmigration', getProfileImmigration)
  const [refetchActivator, setRefetchActivator] = useState(false)

  useEffect(() => {
    if (refetchActivator) {
      getUser
    }
    setRefetchActivator(false)
  }, [refetchActivator])

  useEffect(() => {
    getUser(composepostdata.userId)
    getpProfile(composepostdata.userId)
  }, [userId])

  useEffect(() => {}, [user])

  const handlePpdateProfileBasic = async (profileBasic: object) => {
    const result = await updateProfileBasicByUser(profileBasic)
    if (result != null) {
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
    } else {
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }

  return (
    <>
      {user != null ? (
        <div style={{marginTop: '1rem'}}>
          <Tabs defaultActiveKey='profile' id='uncontrolled-tab-example' className='mb-3'>
            <Tab eventKey='profile' title={intl.formatMessage({id: 'PROFILE.PROFILETITTLEBASIC'})}>
              <ProfileDetails
                profile={user}
                isUserLoading={false}
                updateProfileBasic={handlePpdateProfileBasic}
                showAvatar={false}
                edit={edit}
              />
            </Tab>

            <Tab
              eventKey='homenew'
              title={intl.formatMessage({id: 'PROFILE.PROFILETITTLEIMMIGRATIONNEW'})}
            >
             {/*  {status === 'success' && (
                <InmiDetailsNew
                  setRefetchActivator={setRefetchActivator}
                  userId={user.id?.toString()}
                  data={user ?? {}}
                  setTabActive={setTabActive}
                  ProfileImmigration={user}
                  isUserLoading={isUserLoading}
                  updateProfile={updateProfileNew}
                  showAvatar={true}
                />
              )} */}
            </Tab>
          </Tabs>
        </div>
      ) : null}
    </>
  )
}

export default observer(UserProfile)
