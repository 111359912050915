/* eslint-disable @typescript-eslint/no-unused-vars */
import {type FC, useContext} from 'react'
import {Container} from '../../../components/Container/index'
import {RootStoreContext} from '../../../stores/rootStore'
import {useIntl} from 'react-intl'

const PreEvaluation: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  return (
    <>
     <a>hello World</a>
    </>
  )
}

export {PreEvaluation}
