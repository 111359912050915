import {Link} from 'components/Link'
import {Container} from '../../../components/Container/index'
import {useContext, type FC} from 'react'
import {RootStoreContext} from 'stores/rootStore'
import {useQuery} from 'react-query'
import {getStaff} from 'app/modules/apps/staff/core/_requests'

const ClientsWrapper: FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {data: dataStaff} = useQuery(
    'getAllStaffss',
    async () => await getStaff(user?.id ? user.id : '')
  )

  return (
    <Container title={'Clientes'}>
      <div className='flex gap-4 justify-center flex-wrap'>
        <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to='/userslist'>
          <img className='w-32' src='/media/svg/menu-icons/Pickup_airport.svg' alt='clientes' />
          <h2>Clientes</h2>
        </Link>
        <Link
          className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
          to='/templatesblue'
        >
          <img className='w-13' src='/media/svg/menu-icons/templates.svg' alt='clientes' />
          <h2>Mensajes masivos</h2>
        </Link>

        {dataStaff &&
          user?.role?.id === 1 &&
          (dataStaff[0].type === 'intake' || dataStaff[0].type === 'intake_admin') && (
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/services/contracts/answers'
            >
              <img className='w-32' src='/media/svg/menu-icons/cv_services.svg' alt='' />
              <h2>Respuestas de Contratacion</h2>
            </Link>
          )}
      </div>
    </Container>
  )
}

export {ClientsWrapper}
