import {Container, Link} from '../../../../components'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

const ImmigrationServices = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.AVAILABLESERVICES'})}>
        <ArrowLeftOutlined
          className=' p-2 hover:bg-gray-200 hover:rounded-lg'
          onClick={() => navigate(-1)}
        />
        <div className='grid grid-cols-2 gap-6 p-3'>
          <div className=' shadow rounded-xl hover:scale-105 transition delay-150 duration-300 ease-in-out'>
            <img className='mx-auto my-auto px-5 py-5' src='/media/services/contrato.png' alt='' />
            <p className='text-center font-bold text-lg'>Contratación de Visa</p>
            <Link
              className='cursor-pointer rounded-b-xl flex justify-center  p-3 text-white bg-indigo focus:ring-4 focus:outline-none font-medium  text-sm px-5  text-center '
              to='/cart/immigrationservicescontract'
            >
              Adquirir
            </Link>
          </div>
          <div className=' shadow rounded-xl hover:scale-105 transition delay-150 duration-300 ease-in-out'>
            <img className='mx-auto my-auto px-5 py-5' src='/media/services/consultar.png' alt='' />
            <p className='text-center font-bold text-lg'>Cita Migratoria con mi consultor</p>
            <Link
              className='cursor-pointer rounded-b-xl flex justify-center  p-3 text-white bg-indigo focus:ring-4 focus:outline-none font-medium  text-sm px-5  text-center '
              to='/cart/consultamigratoria'
            >
              Adquirir
            </Link>
          </div>
        </div>
      </Container>
    </>
  )
}
export default ImmigrationServices
