 
import {useContext, useEffect} from 'react'
import {type Product} from '../../../../app/modules/apps/cart/core/products/_models'
import {RootStoreContext} from '../../../../stores/rootStore'
import ShoppingProductsDetail from './ShoppingProductsDetail'
import {observer} from 'mobx-react'
import {useNavigate} from 'react-router-dom'

const {REACT_APP_PUBLIC_URL} = process.env

const ShoppingProducts = () => {
  const rootStore = useContext(RootStoreContext)
  const {isEmbed, cart, addTocart, removeFromCart, productembed, cartoptions} = rootStore.cartStore

  const navigate = useNavigate()

  useEffect(() => {
    if (isEmbed && cart.length === 0) {
      const queryparams = `?background=${cartoptions.background}&color=${cartoptions.color}&embed=${cartoptions.embed}`
      navigate(`${REACT_APP_PUBLIC_URL}/embed/store/product/${productembed?.id}${queryparams}`)
    }
    // eslint-disable-next-line
  }, [cart])

  const handleRemoveFromCart = (product: Product) => {
    removeFromCart(product)
  }

  return (
    <>
      {cart.length > 0 && (
        <div className=''>
          {cart.map((product, index) => {
            return (
              <ShoppingProductsDetail
                key={index}
                product={product}
                addTocart={addTocart}
                removeFromCart={handleRemoveFromCart}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export default observer(ShoppingProducts)
