/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext} from 'react'
import {type Messages as IMessages} from '../../../../app/modules/apps/messages/core/_models'
import {type IReply} from '../../../../app/modules/apps/appointments/core/_models'
import MessageRows from './MessageRows'
import ModalLoader from '../../../../core/components/ModalLoader'
import {RootStoreContext} from '../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {Space, Table} from 'antd'
import Column from 'antd/lib/table/Column'

interface IProps {
  callRefetch: boolean
  setMessageId?: React.Dispatch<React.SetStateAction<string>>
  setComposepostdata?: React.Dispatch<React.SetStateAction<IReply>>
  setlist: React.Dispatch<React.SetStateAction<boolean>>
  setShowMessage?: React.Dispatch<React.SetStateAction<boolean>>
  setReplyMessage?: React.Dispatch<React.SetStateAction<boolean>>
  handleClose?: () => void
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
  folder: string
}

const ListMessages: React.FC<IProps> = ({
  setMessageId,
  setShowMessage,
  setReplyMessage,
  setComposepostdata,
  setlist,
  folder,
  handleClose,
  setShowMessageRows,
}) => {
  const rootStore = useContext(RootStoreContext)
  const {messagesGeneral, setMessagesRead, isLoadingMessages} = rootStore.messageStore

  const HandleReadmessage = (id: string) => {
    setMessagesRead(id)
    if (setMessageId != null) setMessageId(id)
    if (setShowMessage != null) setShowMessage(true)
    setlist(false)
  }

  return (
    <>
      {isLoadingMessages ? <ModalLoader /> : null}
      <>
        {' '}
        <Table
          pagination={{
            showLessItems: true,
            size: 'small',
            responsive: true,
            simple: true,
            showQuickJumper: false,
            showSizeChanger: false,
          }}
          dataSource={messagesGeneral}
          rowKey='id'
          style={{width: '100%'}}
        >
          <Column
            title=''
            render={(text, record: any, index: any) => (
              <Space style={{width: '100%'}}>
                <MessageRows
                  key={index}
                  message={record}
                  folder={folder}
                  HandleReadmessage={HandleReadmessage}
                  handleClose={handleClose}
                  setShowMessageRows={setShowMessageRows}
                />
              </Space>
            )}
          />
        </Table>
      </>
    </>
  )
}

export default observer(ListMessages)
