import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {type FC, useContext, useEffect} from 'react'
import {App} from '../../app/App'
import {AuthPage} from '../../auth/AuthPage'
import CartRoutes from '../../app/pages/Cart/Routes/CartRoutes'
import CartRoutesEmbed from '../../app/pages/Cart/Routes/CartRoutesEmbed'
import {Logout} from '../../auth/Logout'
import Main from '../../core/layout/main'
import MainRoutes from '../../core/layout/main/MainRoutes'
import PrivateRoutes from '../../app/routing/PrivateRoutes'
import {RootStoreContext} from '../../stores/rootStore'
import WebRoutes from '../../app/pages/Web/Routes/WebRoutes'
import {observer} from 'mobx-react'
import {IComposeData} from 'app/modules/apps/appointments/core/_models'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'

const {REACT_APP_PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {isAuthenticated, loading} = rootStore.authStore

  const profileImmigrationValue: ProfileImmigration = {}
  const composepostdataValue: IComposeData = {
    fromUser: '',
    orderDetailId: '',
    user: '',
    email: '',
    userId: '',
    fromId: '',
    messageId: null,
    folder: '',
    draft: false,
    favorite: false,
    files_info:[],
    unread: false,
  }

  useEffect(() => {
    if (!loading) {
      const splashScreen = document.getElementById('splash-screen')

      if (splashScreen != null) {
        splashScreen.style.setProperty('display', 'none')
      }
    }
  }, [loading])

  return (
    <BrowserRouter basename={REACT_APP_PUBLIC_URL}>
      <Routes>
        <Route path='logout' element={<Logout />} />
        <>
          <Route element={<App />}>
            {!isAuthenticated ? (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='store/*' element={<CartRoutes />} />
                <Route path='web/*' element={<WebRoutes />} />
                <Route path='new/*' element={<Main />} />
                <Route path='embed/store/*' element={<CartRoutesEmbed />} />
                <Route
                  path='new/*'
                  element={<MainRoutes ProfileImmigration={profileImmigrationValue} />}
                />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            ) : (
              <>
                <Route
                  path='/*'
                  element={
                    <PrivateRoutes
                      ProfileImmigration={profileImmigrationValue}
                      composepostdata={composepostdataValue}
                    />
                  }
                />
                <Route path='store/*' element={<CartRoutes />} />
                <Route path='web/*' element={<WebRoutes />} />
                <Route path='embed/store/*' element={<CartRoutesEmbed />} />
                <Route index element={<Navigate to='dashboard' />} />
              </>
            )}
          </Route>
        </>
      </Routes>
    </BrowserRouter>
  )
}

export default observer(AppRoutes)
