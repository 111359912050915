 
 
import {
  Attachment,
  CategoryItemNumber,
  Item,
  ItemInbox,
  MailAvatar,
} from '../../../../../core/components/email'
import {PaperClipOutlined} from '@ant-design/icons'
import React from 'react'
import AvatarProfileUsers from '../../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {type Messages} from '../../../../../app/modules/apps/messages/core/_models'
import TextMailSplit from '../../../../../core/components/email/TextMailSplit'
import {TimeZone} from '../../../../../app/modules/utils/components/TimeZone'

interface IProps {
  message: Messages
  folder: string
  HandleReadmessage: (id: string) => void
  handleClose?: () => void
  setShowMessageRows: React.Dispatch<React.SetStateAction<boolean>>
}

const MessageRows: React.FC<IProps> = ({
  message,
  folder,
  HandleReadmessage,
  handleClose,
  setShowMessageRows,
}) => {
  const name = message.user ? message.user : message.email

  if (message.folder === folder || folder === 'search') {
    return (
      <Item
        key={message.id}
        read={!!message.mr_id}
        onClick={() => {
          HandleReadmessage(message.id ? message.id?.toString() : '')
          if (handleClose != null) {
            handleClose()
          }
          if (screen.width < 1024) {
            setShowMessageRows(false)
          }
        }}
      >
        {/*
              <Star>
                <StarOutlined />
              </Star>

              */}
        <ItemInbox read={!!message.mr_id}>
          <MailAvatar className=' sm:w-2/12 md:w-2/12 lg:w-2/12'>
            <AvatarProfileUsers
              image={
                message.fromPathId
                  ? `${message.fromPath}/${message.fromPathId}-avatar-small.jpg`
                  : '/media/avatars/avatar.png'
              }
              size={30}
            />
          </MailAvatar>
          <div style={screen.width < 1024 ? {width: '100%'} : {}} className='block  lg:w-10/12   '>
            <div className='flex items-center'>
              {message.mr_id ? (
                <div className='font-bold  w-2/3 text-gray-700 text-[15px] '>
                  {message.subject.length > 20
                    ? message.subject.substring(0, 20) + '...'
                    : message.subject}
                </div>
              ) : (
                <div className='font-bold text-xs w-2/3 text-black text-[15px] '>
                  {message.subject.length > 20
                    ? message.subject.substring(0, 20) + '...'
                    : message.subject}
                </div>
              )}

              {/* <div className='font-bold text-xs w-2/3 text-black'>{name}</div> */}
              <div
                style={{float: 'right', color: '#bf3c3c'}}
                className=' w-1/3 text-right text-[8px] mr-1'
              >
                <TimeZone date={message.created_at ? message.created_at : null} />
                {/* {folder !== 'sent' ? (
                  <>
                    {message.mr_id ? (
                      <CategoryItemNumber badge_success>Leido</CategoryItemNumber>
                    ) : (
                      <CategoryItemNumber badge_warning>No leido</CategoryItemNumber>
                    )}
                  </>
                ) : null} */}
                {folder === 'search' && (
                  <CategoryItemNumber badge_light>{message.folder}</CategoryItemNumber>
                )}
              </div>
            </div>
            <div
              style={screen.width < 1024 ? {display: 'flex'} : {}}
              className='md:flex items-center'
            >
              {message.mr_id ? (
                <p className='text-gray-700 font-semibold  text-[12px]  mb-0 w-9/12'>
                  {name.length > 10 ? name.substring(0, 10) + '...' : name}
                </p>
              ) : (
                <p className='text-black font-semibold  text-[12px] mb-0 w-9/12'>
                  {name.length > 10 ? name.substring(0, 10) + '...' : name}
                </p>
              )}

              {message.mr_id ? (
                <p className='text-gray-700 text-[12px]  mb-0 lg:ml-4  w-full '>
                  <TextMailSplit text={message.content} length={20} />
                </p>
              ) : (
                <p className='text-black text-[12px]  mb-0 lg:ml-4  w-full'>
                  <TextMailSplit text={message.content} length={20} />
                </p>
              )}
              {message.attachment ? (
                <Attachment style={{color: '#bf3c3c'}}>
                  <PaperClipOutlined />
                </Attachment>
              ) : null}
            </div>
          </div>
        </ItemInbox>
      </Item>
    )
  } else {
    return null
  }
}

export default MessageRows
