 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {type ReactNode, useEffect} from 'react'
import {DrawerComponent} from '../../assets/ts/components'
import {Navbar} from '../../../components/Navbar/index'
import {useLayout} from '../core'
import {useLocation} from 'react-router'

interface ContentProps {
  children: ReactNode
}

const Content: React.FC<ContentProps> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()

  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <>
 <div className='form-container-father bg-gray-100 h-screen overflow-y-auto overflow-x-hidden fixed w-full lg:w-5/6'>
        <picture>
          <source srcSet='/media/newdesign/barra-colores.png' media='(min-width: 1024px)' />
          <img
            src={'/media/newdesign/barra-colores-sin-texto.png'}
            className='lg:w-full lg:h-auto'
            alt=''
          />
        </picture>
        <Navbar />
        {children}
      </div>
    </>
  )
}

export {Content}
