/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import {Timeline, Switch} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown, faChevronUp, faTrash} from '@fortawesome/free-solid-svg-icons'

import {useQuery} from 'react-query'
import {getContractByUser} from 'app/modules/apps/contract/_requests'
import {RootStoreContext} from 'stores/rootStore'
import {Users} from 'app/modules/apps/users/core/_models'
import {IComposeData} from 'app/modules/apps/appointments/core/_models'
import Swal from 'sweetalert2'
import {getTimeLineByUserId, postTimeLineRight} from 'app/modules/apps/timeline/_requests'
import './newtimeline.scss'
import { format } from 'date-fns';

import TimelineSlide from './Timelineslide'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {P} from 'components'
interface IProps {
  email: string
  user: Users | undefined
  timelineUserId: any
}

interface ITimelineEvent {
  id: any
  events: {
    date: Date
    date2: Date
    tag: string
    status: string
    id: string
    title: string
    description: string
    additionalData: string
  }[]
}

// Define un objeto con la información correspondiente a cada opción del dropdown

const TimelineComponent: React.FC<IProps> = ({user, timelineUserId}) => {
  const rootStore = useContext(RootStoreContext)
  const {user: userrole} = rootStore.authStore
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedDate2, setSelectedDate2] = useState<Date | null>(null)
  const [timelineData, setTimelineData] = useState<any[]>([])
  const [timelineDataRecieved, setTimelineDataRecieved] = useState<any[]>([])
  const [eventTitle, setEventTitle] = useState('')
  const [eventStatus, setEventStatus] = useState('')
  const [tagger, setTagger] = useState('')
  const [idgger, setIdgger] = useState('')
  const [triggerSave, setTriggerSave] = useState(0)
  const [activedata, setActiveData] = useState(false)
  const [eventDescription, setEventDescription] = useState('')
  const [savedTimelineData, setSavedTimelineData] = useState<ITimelineEvent[]>([])
  const [userTimelineData, setUserTimelineData] = useState<ITimelineEvent[]>([]) // Cambia el tipo de esta variable de estado
  const [dataFromserver, serdataFromserver] = useState(false)
  const [openMonths, setOpenMonths] = useState<{[key: string]: boolean}>({})
  const [switchesState, setSwitchesState] = useState<{[key: string]: boolean}>({})
  const [switchTexts, setSwitchTexts] = useState<{[key: string]: string}>({})
  const [paginaAbierta, setPaginaAbierta] = useState(false)
  const [selectedDropdownItem, setSelectedDropdown] = useState('')
  const [additionalData, setAdditionalData] = useState('')
  const [editingEvent, setEditingEvent] = useState<ITimelineEvent | null>(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [labelCounts, setLabelCounts] = useState<{[key: string]: number}>({})
  const completedCountInmigracion = timelineData.filter(
    (event) => event.tag === 'Completado' && event.status === 'INMIGRACIÓN'
  ).length
  const completedCountTrabajo = timelineData.filter(
    (event) => event.tag === 'Completado' && event.status === 'TRABAJO'
  ).length
  const completedCountEducacion = timelineData.filter(
    (event) => event.tag === 'Completado' && event.status === 'ESTUDIOS'
  ).length
  const [progressValue, setProgressValue] = useState(83)
  const [lastEventDate, setLastEventDate] = useState<Date | null>(null)
  const [timeRemaining, setTimeRemaining] = useState<string>('')
  const [progressReached100, setProgressReached100] = useState(false)
  const [showSavedPopup, setShowSavedPopup] = useState(false);
  const currentMonth = format(new Date(), 'M');

  useEffect(() => {
    if (timelineData) {
      const completedCountInmigracion = timelineData.filter(
        (event) => event.tag === 'Completado' && event.status === 'INMIGRACIÓN'
      ).length
      const completedCountTrabajo = timelineData.filter(
        (event) => event.tag === 'Completado' && event.status === 'TRABAJO'
      ).length
      const completedCountEducacion = timelineData.filter(
        (event) => event.tag === 'Completado' && event.status === 'ESTUDIOS'
      ).length
      // Update state or perform other actions based on these counts
    }
  }, [timelineData])

  function calculateProgress(events: any[]) {
    const totalEvents = events.length
    const completedEvents = events.filter(
      (event: {tag: string}) => event.tag === 'Completado'
    ).length

    if (totalEvents === 0) {
      return 0 // Handle the case when there are no events to avoid division by zero.
    }

    return (completedEvents / totalEvents) * 100
  }
  useEffect(() => {
    const progress = calculateProgress(timelineData)
    setProgressValue(progress)

    if (progress === 100) {
      setProgressReached100(true)
    } else {
      setProgressReached100(false)
    }
  }, [timelineData])
  const {
    data: datareturn,
    status: statusreturn,
    refetch,
  } = useQuery('getTimeLine', async () => await getTimeLineByUserId(timelineUserId), {
    enabled: user !== undefined,
    cacheTime: 0,
  })
  const [isSlideOverVisible, setIsSlideOverVisible] = useState(false)
  if (
    statusreturn === 'success' &&
    timelineData &&
    timelineData.length === 0 &&
    dataFromserver === false
  ) {
    if (datareturn && datareturn[0] && datareturn[0].rightdata) {
      const loadedTimelineData = datareturn[0].rightdata.timelineData
      setTimelineData(loadedTimelineData)
      const initialSwitchesState: {[key: string]: boolean} = {}
      loadedTimelineData &&
        loadedTimelineData.forEach((event: any) => {
          initialSwitchesState[event.id] = event.tag === 'Completado'
        })
      setSwitchesState(initialSwitchesState)
    }
  }
  useEffect(() => {
    if (
      statusreturn === 'success' &&
      timelineData &&
      timelineData.length === 0 &&
      dataFromserver === false
    ) {
      if (datareturn && datareturn[0] && datareturn[0].rightdata) {
        const loadedTimelineData = datareturn[0].rightdata.timelineData
        setTimelineData(loadedTimelineData)

        const initialSwitchesState: {[key: string]: boolean} = {}
        const labelCounts: {[key: string]: number} = {}

        loadedTimelineData &&
          loadedTimelineData.forEach((event: any) => {
            initialSwitchesState[event.id] = event.tag === 'Completado'

            // Actualiza el contador de etiquetas utilizando event.status
            labelCounts[event.status] = (labelCounts[event.status] || 0) + 1
          })

        setSwitchesState(initialSwitchesState)
        setLabelCounts(labelCounts)
      }
    }
  }, [statusreturn, dataFromserver])

  function organizeTimelineData(
    timelineData: any[]
  ): {year: number; months: {month: number; events: any[]}[]}[] {
    const organizedData: {year: number; months: {month: number; events: any[]}[]}[] = []

    if (timelineData.length === 0) {
      return organizedData
    }

    // Obtener la fecha mínima y máxima de los eventos
    let minYear = Infinity
    let maxYear = -Infinity
    let minMonth = Infinity
    let maxMonth = -Infinity

    timelineData.forEach((event) => {
      const eventDate = new Date(event.date)
      if (!isNaN(eventDate.getTime())) {
        const year = eventDate.getFullYear()
        const month = eventDate.getMonth() + 1

        if (year < minYear) {
          minYear = year
        }
        if (year > maxYear) {
          maxYear = year
        }
        if (year === minYear && month < minMonth) {
          minMonth = month
        }
        if (year === maxYear && month > maxMonth) {
          maxMonth = month
        }
      }
    })

    for (let year = minYear; year <= maxYear; year++) {
      const yearData = {
        year: year,
        months: [] as {month: number; events: any[]}[],
      }

      for (let month = 1; month <= 12; month++) {
        if ((year === minYear && month < minMonth) || (year === maxYear && month > maxMonth)) {
          continue
        }

        yearData.months.push({
          month: month,
          events: [],
        })
      }

      organizedData.push(yearData)
    }

    timelineData.forEach((event) => {
      const eventDate = new Date(event.date)
      if (!isNaN(eventDate.getTime())) {
        const year = eventDate.getFullYear()
        const month = eventDate.getMonth() + 1

        const yearData = organizedData.find((item) => item.year === year)

        if (yearData) {
          const monthData = yearData.months.find((item) => item.month === month)
          if (monthData) {
            monthData.events.push({
              date: event.date,
              description: event.description,
              additionalData: event.additionalData,
              status: event.status,
              title: event.title,
              tag: event.tag,
              id: event.id,
            })
          }
        }
      }
    })

    return organizedData
  }

  const organizedTimelineData = organizeTimelineData(timelineData)
  useEffect(() => {
    if (showSavedPopup) {
      Swal.fire({
        title: 'Guardado',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });

      // Reinicia el estado después de mostrar el popup
      setShowSavedPopup(false);
    }
  }, [showSavedPopup]);


  useEffect(() => {
    if (activedata === false) {
      setTimelineDataRecieved(organizedTimelineData)
      setTimeout(() => {
        setActiveData(true)
      }, 1000)
    }
  }, [activedata, organizedTimelineData])

  useEffect(() => {
    if (progressReached100) {
      // Muestra el popup con el GIF
      Swal.fire({
        html: '<img src="/media/svg/menu-icons/congrats.gif" alt="Congratulations GIF">',
        showConfirmButton: false,
      })
    } else {
      // Muestra el popup estándar de "Guardado"

    }
  }, [progressReached100])
   
  const handleSubmit = async () => {
    if (user && user.id) {
      try {
        const centerdata = {
          user_id: user.id,
          timelineData: timelineData,
        }

        // Realizar la solicitud al servidor utilizando postTimeLineCenter
        const response = await postTimeLineRight(user.id, centerdata)
        if (response.status === 'success') {
          setUserTimelineData(response.data.rightdata)
        }
        if (progressReached100) {
          // Muestra el popup con el video corto
          Swal.fire({
            html: '<img src="/media/svg/menu-icons/congrats.gif" alt="Congratulations GIF">',
            showConfirmButton: false,
          })
        } else {
          // Muestra el popup estándar de "Guardado"
          Swal.fire({
            title: 'Guardado',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: `${error}`,
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: 'El usuario no está definido o no tiene un ID válido',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      })
    }
    setSavedTimelineData([...timelineData])
    setShowSavedPopup(true);
  }

  const handleEdit = () => {
    if (!eventTitle || !eventDescription || !selectedDate  || !eventStatus) {
      return
    }

    if (editingEvent) {
      const updatedTimelineData = timelineData.map((event: ITimelineEvent) => {
        if (editingEvent && event.id === editingEvent.id) {
          return {
            ...event,
            title: eventTitle,
            description: eventDescription,
            additionalData: additionalData,
            date: selectedDate.toISOString(),
           
            tag: tagger,
            status: eventStatus,
          }
        }
        return event
      })

      setTimelineData(updatedTimelineData)
      setEditingEvent(null) // Limpiar el evento en edición
    }
    setIsSlideOverVisible(false)
    setTriggerSave(triggerSave + 1)
    setIsEditMode(false)
    setShowSavedPopup(true);
  }
  const handleCreate = () => {
    setIsEditMode(false)
    if (!eventTitle || !eventDescription || !selectedDate  || !eventStatus) {
      return
    }
    const newEvent = {
      title: eventTitle,
      description: eventDescription,
      additionalData: additionalData,
      date: selectedDate.toISOString(),
     
      tag: tagger,
      status: eventStatus,
      id: idgger,
    }

    const newTimelineData = Array.isArray(timelineData) ? [...timelineData, newEvent] : [newEvent]
    setTimelineData(newTimelineData)
    setTriggerSave(triggerSave + 1)

    setLabelCounts((prevCounts) => ({
      ...prevCounts,
      [eventStatus]: (prevCounts[eventStatus] || 0) + 1,
      [`Pendiente${eventStatus}`]: (prevCounts[`Pendiente${eventStatus}`] || 0) + 1,
    }))

    setEventTitle('')
    setEventDescription('')
    setSelectedDate(null)
    setSelectedDate2(null)
    setIsSlideOverVisible(false)
    setShowSavedPopup(true);
  }

  const handleCreateEvent = () => {
    setIsEditMode(true)
    if (!eventTitle || !eventDescription || !selectedDate  || !eventStatus) {
      return
    }

    if (isEditMode === true && editingEvent) {
      handleEdit()
    } else {
      handleCreate()
    }
  }

  useEffect(() => {
    if (paginaAbierta) {
      handleSubmit()
      refetch()
      setActiveData(false)
    } else {
      setPaginaAbierta(true)
    }
  }, [triggerSave])

  const getMonthName = (monthNumber: any) => {
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]
    return months[monthNumber]
  }

  const toggleMonth = (year: number, month: number) => {
    const monthKey = `${year}-${month}`
    setOpenMonths((prevState: any) => ({
      ...prevState,
      [monthKey]: !prevState[monthKey],
    }))
  }

  const handleSwitchChange = (id: string | number, checked: boolean) => {
    // Find the event with the given id in the timelineData
    const updatedTimelineData = timelineData.map((event) => {
      if (event.id === id) {
        // Toggle the tag between "Completado" y "Pendiente"
        event.tag = checked ? 'Completado' : 'Pendiente'
      }
      return event
    })
    const currentProgress = calculateProgress(timelineData)
    setProgressReached100(currentProgress === 100)
    // Update the timelineData state with the modified data
    setTimelineData(updatedTimelineData)

    // Update the switchesState to reflect the new switch state
    const updatedSwitchesState = {...switchesState}
    updatedSwitchesState[id] = checked
    setSwitchesState(updatedSwitchesState)

    // Update the 'tag' state here

    // Update the labelCounts for events
    setLabelCounts((prevCounts) => {
      const event = timelineData.find((event) => event.id === id)
      if (event) {
        const eventStatus = event.status
        const isCompletado = event.tag === 'Completado'

        return {
          ...prevCounts,
          [eventStatus]: (prevCounts[eventStatus] || 0) + (isCompletado ? 1 : -1),
          [`Pendiente${eventStatus}`]:
            (prevCounts[`Pendiente${eventStatus}`] || 0) + (isCompletado ? -1 : 1),
        }
      }
      return prevCounts
    })

    // Other actions you may want to perform
    // ...
    setTriggerSave(triggerSave + 1)
  }

  const handleEditEvent = (eventId: any) => {
    setIsEditMode(true)
    const eventToEdit = timelineData.find((event) => event.id === eventId)
    if (eventToEdit) {
      setEditingEvent(eventToEdit)
      setEventTitle(eventToEdit.title)
      setEventDescription(eventToEdit.description)
      // Otras propiedades del evento (additionalData, selectedDate, selectedDate2, tag, status)
      setSelectedDate(new Date(eventToEdit.date))
      setSelectedDate2(new Date(eventToEdit.date2))
      setTagger(eventToEdit.tag)
      setEventStatus(eventToEdit.status)
      setIsSlideOverVisible(true) // Abre el modal de edición
    }
  }

  const statusOptions = [
    {
      value: 'INMIGRACIÓN',
      label: 'Inmigración',
      icon: '/media/svg/menu-icons/Group.svg', // Ruta de la imagen del icono de avión
      background: 'var(--cyan-cyan, #01A9DB)',
      color: 'white',
    },
    {
      value: 'ESTUDIOS',
      label: 'Educación',
      icon: '/media/svg/menu-icons/books.svg', // Ruta de la imagen del icono de libros
      background: 'var(--fuschia-100, #EF5DA8)',
      color: 'white',
    },
    {
      value: 'TRABAJO',
      label: 'Trabajo',
      icon: '/media/svg/menu-icons/briefcase-alt.svg', // Ruta de la imagen del icono de maletín
      background: 'var(--green-green, #22AD5C)',
      color: 'white',
    },
  ]

  const handleDeleteEvent = (idToDelete: string) => {
    // Filter the events to keep only those with a different ID
    const updatedTimelineData = timelineData.filter((event) => event.id !== idToDelete)

    setLabelCounts((prevCounts) => ({
      ...prevCounts,
      [eventStatus]: (prevCounts[eventStatus] || 0) - 1,
    }))
    // Update the state of timelineData
    setTimelineData(updatedTimelineData)

    setTriggerSave(triggerSave + 1)
  }

  const handleShowMore = (eventId: string, additionalData: string) => {
    const selectedEvent = timelineData.find((item) => item.id === eventId)

    if (selectedEvent) {
      Swal.fire({
        title: 'Informacion adicional',
        html: additionalData,
        icon: 'info',
        confirmButtonText: 'Cerrar',
        customClass: {
          icon: 'my-red-icon',
        },
      })
    }
  }

  useEffect(() => {
    if (timelineData.length > 0) {
      const lastEvent = timelineData[timelineData.length - 1] // Assuming timelineData is sorted by date
      const lastEventDate = new Date(lastEvent.date)
      setLastEventDate(lastEventDate)

      // Calculate time remaining
      const currentDate = new Date()
      const timeDifference = lastEventDate.getTime() - currentDate.getTime()
      if (timeDifference > 0) {
        const daysRemaining = Math.floor(timeDifference / (1000 * 3600 * 24))
        const monthsRemaining = Math.floor(daysRemaining / 30)
        const remainingDays = daysRemaining % 30
        setTimeRemaining(`${monthsRemaining} meses y ${remainingDays} días`)
      } else {
        setTimeRemaining('0 meses y 0 días')
      }
    }
  }, [timelineData])

  function getCategoryColor(category: string) {
    switch (category) {
      case 'ESTUDIOS':
        return '#EF5DA8'
      case 'TRABAJO':
        return '#22AD5C'
      case 'INMIGRACIÓN':
        return '#01A9DB'
      default:
        return '#ffffff'
    }
  }

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  // Agrega un efecto para registrar el evento de desplazamiento
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div>
      <div
        className='categories-container'
        style={{
          position: 'sticky',
          top: `${scrollPosition}px`,
          zIndex: 9,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className='progress-container'>
          {' '}
          <div className='progress-text'>
            {lastEventDate && (
              <>
                Tu plan termina el {lastEventDate.toLocaleDateString('es-ES')}
                <br />
                ¡Te quedan <strong>{timeRemaining}</strong> para lograr tu meta!
              </>
            )}
          </div>
          <div className='custom-progress-container2  '>
            <div
              className={`custom-progress-bar ${
                progressValue === 100 ? 'custom-progress-100' : ''
              }`}
            >
              <div className='custom-progress-fill' style={{width: `${progressValue}%`}}>
                <div className='custom-progress-text'>{`${Math.floor(progressValue)}%`}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row gap-2'>
          <div className='category-container inmigracion'>
            <div className='logo-container'>
              <img src='/media/svg/menu-icons/Group.svg' alt='Logo INMIGRACIÓN' />
            </div>
            <div className='text-container'>
              <p className='category-title1'>INMIGRACIÓN</p>
              <p className='category-info1' style={{textAlign: 'center'}}>
                {completedCountInmigracion} de{' '}
                {timelineData.filter((event) => event.status === 'INMIGRACIÓN').length}
              </p>
              <p className='category-complet1' style={{textAlign: 'center'}}>
                Completados
              </p>
            </div>
          </div>

          <div className='category-container trabajo'>
            <div className='logo-container'>
              <img src='/media/svg/menu-icons/briefcase-alt.svg' alt='Logo TRABAJO' />
            </div>
            <div className='text-container'>
              <p className='category-title2'>TRABAJO</p>
              <p className='category-info2' style={{textAlign: 'center'}}>
                {completedCountTrabajo} de{' '}
                {timelineData.filter((event) => event.status === 'TRABAJO').length}
              </p>
              <p className='category-complet2' style={{textAlign: 'center'}}>
                Completados
              </p>
            </div>
          </div>

          <div className='category-container educacion'>
            <div className='logo-container'>
              <img src='/media/svg/menu-icons/books.svg' alt='Logo EDUCACIÓN' />
            </div>
            <div className='text-container'>
              <p className='category-title3'>EDUCACIÓN</p>
              <p className='category-info3' style={{textAlign: 'center'}}>
                {completedCountEducacion} de{' '}
                {timelineData.filter((event) => event.status === 'ESTUDIOS').length}
              </p>
              <p className='category-complet3' style={{textAlign: 'center'}}>
                Completados
              </p>
            </div>
          </div>
        </div>
        <div className='button-containercreator'>
          {userrole?.role?.name !== 'User' && (
            <button
              className='buttoncreator'
              onClick={() => setIsSlideOverVisible(true)}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '23px', // Ajusta el tamaño de fuente aquí
                color: 'white',
              }}
            >
              <img
                src='/media/svg/menu-icons/plus.svg'
                alt='Plus Icon'
                style={{marginRight: '8px'}}
              />
              Crear actividad
            </button>
          )}
        </div>
      </div>

      <div className='timeline' style={{display: 'flex', flexDirection: 'row'}}>
        {/* <div style={{ borderLeft: '5px dashed black', width: '40px', margin: '16px 0px' , display: 'flex', flexDirection: 'row', gap:'2px'  }}>

        </div> */}
        <Timeline mode='left' style={{padding: '16px', background: 'white', width: '100%'}}>
          {activedata === true &&
            Array.isArray(timelineDataRecieved) &&
            timelineDataRecieved.map((yearData: any) => (
              <div
                key={yearData.year}
                style={{marginBottom: '16px', padding: '8px'}}
                className='timeline-container'
              >
                <p style={{fontWeight: 'bold'}}> {yearData.year}</p>
                {yearData.months
                  .sort((a: {month: number}, b: {month: number}) => a.month - b.month)
                  .map((month: any) => (
                    <div className='data-full'
                      key={month.month}
                      style={{
                        marginLeft: '16px',
                        border: '2px solid #9CA3AF',
                        borderRadius: '20px',
                        marginBottom: '16px',
                      }}
                    >
                      <div
                        className={`month-container ${
                          month.events.length === 0 ? 'no-events' : ''
                        }`}
                        onClick={() => toggleMonth(yearData.year, month.month)}
                      >
                        <div className='container-month'>
                          <p
                            style={{width: '6rem'}}
                            className={`month-name ${
                              month.events.length === 0 ? 'no-events-month' : ''
                            }`}
                          >
                            {getMonthName(month.month - 1)}
                          </p>
                          <p className='activity-name'>
  {month.events.length > 0
    ? <>
        <span className="large-screen-text">Existen {month.events.length} actividades</span>
        <span className="small-screen-text"> {month.events.length} actividades</span>
      </>
    : 'No existen actividades para este mes'}
</p>
                          <div className='month-pendientes'>
                            {month.events.length > 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: '2px solid #D97706',
                                  borderRadius: '20px',
                                  padding: '2px 6px',
                                }}
                              >
                                <p className='text-icon'
                                  style={{
                                    color: '#D97706',
                                    marginRight: '10px',
                                    marginBottom: '0rem',
                                    fontSize: '1rem',
                                  }}
                                >
                                  {`${
                                    month.events.filter(
                                      (event: {tag: string}) => event.tag === 'Pendiente'
                                    ).length
                                  } pendientes`}
                                </p>
                                {month.events
                                  .filter((event: {tag: string}) => event.tag === 'Pendiente')
                                  .map(
                                    (event: {id: React.Key | null | undefined; status: string}) => (
                                      <div
                                        key={event.id}
                                        className='category-logo-circle'
                                        style={{backgroundColor: getCategoryColor(event.status)}}
                                      >
                                        <img
                                          src={
                                            event.status === 'ESTUDIOS'
                                              ? '/media/svg/menu-icons/books.svg'
                                              : event.status === 'TRABAJO'
                                              ? '/media/svg/menu-icons/briefcase-alt.svg'
                                              : event.status === 'INMIGRACIÓN'
                                              ? '/media/svg/menu-icons/Group.svg'
                                              : ''
                                          }
                                          alt={`Logo ${event.status}`}
                                          className='category-logo-img'
                                        />
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                          </div>
                        </div>

                        {month.events.length > 0 && (
                          <button
                            onClick={() => toggleMonth(yearData.year, month.month)}
                            style={{background: 'none', border: 'none', cursor: 'pointer'}}
                          >
                            {openMonths[`${yearData.year}-${month.month}`] ? (
                              <FontAwesomeIcon icon={faChevronUp} className='black-icon2' />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} className='black-icon2' />
                            )}
                          </button>
                        )}
                      </div>
                      {/* Mostrar eventos solo si el mes está abierto */}
                      {openMonths.hasOwnProperty(`${yearData.year}-${month.month}`) &&
                        openMonths[`${yearData.year}-${month.month}`] &&
                        month.events.map((event: any) => (
                          <div key={event.date} style={{marginLeft: '16px', marginBottom: '16px'}}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                background: '#F9F9F9',
                                padding: '24px',
                                textAlign: 'center',
                                gap: '36px',
                                borderRadius: '12px',
                              }}
                            >
                              <div>
                                {statusOptions.map((option) => {
                                  if (event.status === option.value) {
                                    return (
                                      <div
                                        key={option.value}
                                        style={{
                                          background: option.background,
                                          color: option.color,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          borderRadius: '3px',
                                          width: '120px',
                                          marginBottom: '24px',
                                        }}
                                      >
                                        <img
                                          src={option.icon}
                                          alt={`Icono de ${option.value}`}
                                          style={{
                                            borderRadius: '3px',
                                            padding: '2px 6px',
                                            marginRight: '8px',
                                            width: '24px', // Ajusta el tamaño de la imagen según sea necesario
                                            height: '24px',
                                          }}
                                        />
                                        {option.label}
                                      </div>
                                    )
                                  }
                                  return null
                                })}
                                <p className='event-date'>
                                  {' '}
                                  Desde: {new Date(event.date).toLocaleDateString('es-ES')}
                                </p>
                               {/*  <p className='event-date'>
                                  Hasta: {new Date(event.date2).toLocaleDateString('es-ES')}
                                </p> */}
                                <div className='text-switch-container'>
                                  <Switch
                                    className='custom-switch'
                                    checked={switchesState[event.id]} // Utiliza event.id en lugar de event.date
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(checked) => {
                                      handleSwitchChange(event.id, checked) // Pasa event.id y checked
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      padding: '3px 10px',
                                      background:
                                        event.tag === 'Pendiente'
                                          ? '#FFFBEB'
                                          : event.tag === 'Completado'
                                          ? '#DAF8E6'
                                          : '#DAF8E6',
                                      color:
                                        event.tag === 'Pendiente'
                                          ? '#D97706'
                                          : event.tag === 'Completado'
                                          ? '#1A8245'
                                          : '#1A8245',
                                      borderRadius: '20px',
                                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {event.tag}
                                  </div>
                                  {userrole?.role?.name !== 'User' && (
                                    <div className='edit-remove-container'>
                                      <button
                                        className='editButton'
                                        onClick={() => handleEditEvent(event.id)}
                                      >
                                        Editar
                                        <img
                                          src='/media/svg/menu-icons/pencil-alt.svg'
                                          alt='Icono de lápiz'
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          handleDeleteEvent(event.id)
                                        }}
                                        style={{
                                          background: 'none',
                                          border: 'none',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <div className='papelera'>
                                          <FontAwesomeIcon icon={faTrash} className='black-icon' />{' '}
                                          Eliminar
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <p className='event-title'>{event.title}</p>
                                <div>
                                  <p className='event-description'>{event.description}</p>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      paddingRight: '3rem',
                                    }}
                                  >
                                    <button
                                      className='buttpn-show'
                                      onClick={() => {
                                        handleShowMore(event.id, event.additionalData)
                                      }}
                                    >
                                      <span className='button-text'>Ver detalles⇢</span>
                                    </button>{' '}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className='text-switch-container2'>
                                  <Switch
                                    className='custom-switch'
                                    checked={switchesState[event.id]} // Utiliza event.id en lugar de event.date
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(checked) => {
                                      handleSwitchChange(event.id, checked) // Pasa event.id y checked
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      padding: '3px 10px',
                                      background:
                                        event.tag === 'Pendiente'
                                          ? '#FFFBEB'
                                          : event.tag === 'Completado'
                                          ? '#DAF8E6'
                                          : '#DAF8E6',
                                      color:
                                        event.tag === 'Pendiente'
                                          ? '#D97706'
                                          : event.tag === 'Completado'
                                          ? '#1A8245'
                                          : '#1A8245',
                                      borderRadius: '20px',
                                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {event.tag}
                                  </div>
                                  {userrole?.role?.name !== 'User' && (
                                    <div className='edit-remove-container'>
                                      <button
                                        className='editButton'
                                        onClick={() => handleEditEvent(event.id)}
                                      >
                                        Editar
                                        <img
                                          src='/media/svg/menu-icons/pencil-alt.svg'
                                          alt='Icono de lápiz'
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          handleDeleteEvent(event.id)
                                        }}
                                        style={{
                                          background: 'none',
                                          border: 'none',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <div className='papelera'>
                                          <FontAwesomeIcon icon={faTrash} className='black-icon' />{' '}
                                          Eliminar
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            ))}
        </Timeline>
      </div>

      <div>
        <TimelineSlide
          isSlideOverVisible={isSlideOverVisible}
          setIsSlideOverVisible={setIsSlideOverVisible}
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          eventStatus={eventStatus}
          setEventStatus={setEventStatus}
          eventDescription={eventDescription}
          setEventDescription={setEventDescription}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
         

          handleCreateEvent={handleCreateEvent}
          setTagger={setTagger}
          idgger={setIdgger}
          selectedDropdownItem={selectedDropdownItem}
          setSelectedDropdown={setSelectedDropdown}
          additionalData={additionalData}
          setAdditionalData={setAdditionalData}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      </div>
    </div>
  )
}

export default TimelineComponent
