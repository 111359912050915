import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ContractsTemps } from 'app/modules/apps/contract/_models';
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models';
import CompletedContractsModalshow from './CompletedContractsModalshow';
interface CompletedContractsModalProps {
  visible: boolean;
  onClose: () => void;
  /*  email: string;  */
  setContractsFound: (contracts: any) => void;
  contractsFound: RootObjectCompletedContracts;
  userEmail: string;
  data: ContractsTemps | undefined
}

interface ApplicantsVisibility {
  [key: number]: boolean;
}

const CompletedContractsModal: React.FC<CompletedContractsModalProps> = ({ visible, onClose, setContractsFound/*,  email */, data, contractsFound }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('Respuestas de contratación')
  const [showMyContract, setShowMyContract] = useState(true)

  const [applicantsVisibility, setApplicantsVisibility] = useState<ApplicantsVisibility>({})
  const toggleVisibility = (index: number) => {
    setApplicantsVisibility((prevVisibility) => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }));
  };
  useEffect(() => {
    //
  }, [data, contractsFound]);


  useEffect(() => {
    if (data) {
      // Si `applicants` es un string JSON, necesitarás parsearlo
      const parsedApplicants = data.applicants ? JSON.parse(data.applicants) : [];
      
      // Aquí puedes manejar los datos como lo necesites en el modal
    }
  }, [data]);
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered={true}
      width={879}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      className="slide-over-modal"
      style={{ position: 'absolute', right: 0, top: 0, bottom: 0, zIndex: 9999 }}
    >
      <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
      <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
        <div className="relative w-screen main-holder" style={{ maxWidth: '879px' }}>
          <div style={{ border: '1px solid white', borderRadius: '6px' }} className="flex flex-col bg-white shadow-xl overflow-y-scroll h-100">
            <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
              <button
                style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', padding: '10px 24px 10px 24px', height: 'auto', width: 'auto' }}
                onClick={onClose}
                className='button2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  alt="Calendar"
                  className='meet-image2'
                  src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-black.svg'}
                />
                <b className='meet-text2'>VOLVER</b>
              </button>
              {/* <CompletedContracts email={data && data.email? data.email:""} setContractsFound={setContractsFound} />  */}

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'flex-start',
              }}>
                <h2 className='step-header-title' style={{marginTop:'24px'}}>Contrato y respuestas</h2>
                <div 
                className="side-buttons"
                style={{
                  marginLeft: '0'
                }}
                >
                  {/*  <button
                className={`side-button ${selectedMessage === 'Productos asociados' ? 'active' : ''}`}
                onClick={() => {
                  setSelectedMessage('Productos asociados');
                  setShowMyContract(true);
                }}>
                Servicios asociados
              </button> */}
                  
                    <button
                      className={`side-button ${selectedMessage === 'Contrato' ? 'active' : ''}`}
                      onClick={() => {
                        setSelectedMessage('Contrato');
                        setShowMyContract(true);
                      }}>
                      Contrato
                    </button>
                    <button
                      className={`side-button ${selectedMessage === 'Respuestas de contratación' ? 'active' : ''}`}
                      onClick={() => {
                        setSelectedMessage('Respuestas de contratación');
                        setShowMyContract(true);
                      }}>
                      Respuestas de contratación
                    </button>
                  

                </div>
                {/* Contenedor de mensaje */}
                {selectedMessage && (
                  <div className="message-container"
                    style={{ width: '100%' }}>


                    {selectedMessage === 'Productos asociados' && showMyContract && (
                      <>

                      </>
                    )}

                  </div>)}
                {selectedMessage && (
                  <div className="message-container"
                    style={{ width: '100%' }}>


                    {selectedMessage === 'Contrato' && showMyContract && (
                      <>
                        <CompletedContractsModalshow email={data && data.email ? data.email : ""} contractData={data} setContractsFound={setContractsFound} />
                      </>
                    )}

                  </div>
                )}

                {selectedMessage === 'Respuestas de contratación' && showMyContract && (
                  <>
                    {data != null ? (
                      <div style={{ width: '100%' }} className='main-data-container'>
                        {data.props.map((memberData: any, index: number) => (
                          <div className="color-box"
                            style={{
                              backgroundColor: '#D0F0FD66',
                              width: '100%',
                              padding: '20px',
                              borderRadius: '10px',
                              minWidth: '40rem',
                              marginBottom: '20px'

                            }}>
                            <div className='applicant-container-phase2' key={index}>
                              <div
                                className='applicant-header'
                                onClick={() => toggleVisibility(index)}
                              >
                                <div style={{ gap: '10px', marginBottom: '10px', flex: '1 auto', justifyContent: 'space-between' }} className='applicant-type'>
                                  <strong style={{ fontWeight: '700', fontSize: '18px' }}>{JSON.parse(memberData)['Tipo de familiar']}</strong>
                                  <img
                                    className='applicant-toggle-icon'
                                    src={
                                      applicantsVisibility[index]
                                        ? '/media/svg/menu-icons/chevron-up-circle.svg'
                                        : '/media/svg/menu-icons/chevron-down-circle.svg'
                                    }
                                    alt="icon"
                                  />
                                </div>
                              </div>
                              {applicantsVisibility[index] && (
                                <div className='applicant-data-container'>
                                  <div className='applicant-details'>
                                    {Object.keys(JSON.parse(memberData)).map((key) => (
                                      <div style={{ gap: '10px' }} className='applicant-detail' key={key}>
                                        <p style={{ marginLeft: '1rem' }} className='applicant-detail-key'><strong>{key}:</strong></p>
                                        <p className='applicant-detail-value'>
                                          {typeof JSON.parse(memberData)[key] === 'boolean'
                                            ? JSON.parse(memberData)[key]
                                              ? 'si'
                                              : 'no'
                                            : JSON.parse(memberData)[key]}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div> </div>
                        ))}
                      </div>
                    ) : (
                      <div>no data</div>
                    )}
                  </>
                )}





              </div>
            </div>

          </div>

        </div>
      </div>

    </Modal>
  );
};

export default CompletedContractsModal;
