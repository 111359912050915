/* eslint-disable @typescript-eslint/no-unused-vars */


import React, { useContext, useEffect, useRef, useState } from 'react'
import { type ContractsTemps } from '../../../../app/modules/apps/contract/_models'
import {
  initialIQueryUsers,
  type IQueryUsers,
  type Users,
} from '../../../../app/modules/apps/users/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import { observer } from 'mobx-react'
import { SearchOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import Filter from '../../../../app/pages/admin/users/Filter'
import { Button, Input, type InputRef, Space, Table, Spin, Select } from 'antd'
import Column from 'antd/lib/table/Column'
import { type ColumnType, type FilterConfirmProps } from 'antd/lib/table/interface'
import Highlighter from 'react-highlight-words'
import '../../../../app/pages/messages/UserMessages/styles/TableStyle.css'
import { getUserUsersByEmail } from '../../../../app/modules/apps/users/core/_requests'
import './style.css'
import CompletedContracts from './CompletedContracts'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import ShowAnswersConsultant2 from 'app/pages/services/services/supportDocumentsClient/ShowAnswersConsultant2'
import notes from './image/notes.svg'
import moment from 'moment'
const { Option } = Select;
import CompletedContractsModal from './CompletedContractsModal'
import saveAs from 'file-saver'
import { getContractTempsNew, saveFormat } from 'app/modules/apps/contract/_requests'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import funnel from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/funnel.svg'
import { fi } from 'date-fns/locale'
import { getAllProduct, getProducts, getProductsTableFilter } from 'app/modules/apps/cart/core/products/_requests'
import DatePicker from 'react-datepicker'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps { }
interface Applicant {
  memberName: string;
  memberType: string;
  productName: string;
  productId: string;
}

const AnswersList: React.FC<IProps> =  () => {
  const [search, setSearch] = useState<IQueryUsers>(initialIQueryUsers)
  const rootStore = useContext(RootStoreContext)
  const intl = useIntl()
  const { getContractTemps, contractTemps, isLoadingContractTemp } = rootStore.contractTempsStore
  const [cleanContractTemps, setCleanContractTemps] = useState<ContractsTemps[]>()
  const [showTable, setShowTable] = useState(false)
  const [dataSelected, setDataSelected] = useState<ContractsTemps>()
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [userData, setUserData] = useState<Users>()


  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setlimit] = useState(10)

  const [filterEmail, setFilterEmail] = useState<string>('')
  const [filterCode, setFilterCode] = useState<string>('')
  const [showEmailFilter, setShowEmailFilter] = useState<boolean>(false)
  const [filterFullName, setFilterFullName] = useState<string>('')

  const [showNameFilter, setShowNameFilter] = useState<boolean>(false)
  const [filterProduct, setFilterProduct] = useState<any>([])
  const [showproductFilter, setShowproductFilter] = useState<boolean>(false)
  const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null)
  const [filterDateTo, setFilterDateTo] = useState<Date | null>(null)
  const [showDateFilter, setshowDateFilter] = useState<boolean>(false)
  const [filterPassportCountry, setPassportCountry] = useState<any>([])
  const [filterCivil, setCivil] = useState<any>([])
  const [dataPassportCountry, setdataPassportCountry] = useState<any>([])
  const [showPassportCountryFilter, setshowPassportCountryFilter] = useState<boolean>(false)
  const [showCivilFilter, setshowCivilFilter] = useState<boolean>(false)
  const [showCodeFilter, setshowCodeFilter] = useState<boolean>(false)
  const { data: tempsData, status, refetch } = useQuery(
    'listcontacts',
    async () => await getContractTempsNew({
      "page": currentPage,
      "limit": limit,
      "email": filterEmail,
      "fullName": filterFullName,
      "productName": filterProduct,
      "fromDate": filterDateFrom,
      "toDate": filterDateTo,
      "passportCountry":filterPassportCountry,
      "civilStatus":filterCivil,
      "discountCode":filterCode

    })
  )


  const { data: products } = useQuery(
    'ptb',
    async () => await getAllProduct('limit=1000&page=1&name=')
  )


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://surveyjs.io/api/CountriesExample');
        const data = await response.json();
        setdataPassportCountry(data);
        console.log("goli, ",data)
      } catch (error) {
        console.error('Error fetching passport countries:', error);
      }
    };

    fetchData();
  }, []);
 
  const sleep = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));


  const exportToCSV = async () => {
    try {
      // Asegúrate de que este request traiga todos los datos, sin paginación ni filtros en el frontend
      const response = await getContractTempsNew({
        page: 1, // Aquí puedes ajustar según sea necesario para obtener todos los resultados
        limit: 1000, // Un número alto para traer todos los registros
        email: filterEmail,
        fullName: filterFullName,
        productName: filterProduct,
        fromDate: filterDateFrom,
        toDate: filterDateTo,
        passportCountry: filterPassportCountry,
        civilStatus: filterCivil,
        discountCode: filterCode
      });
  
      const cleanContractTemps = response.data; // Datos traídos del backend
  
      if (!cleanContractTemps || cleanContractTemps.length === 0) {
        console.error('No data available to export');
        return;
      }




      const csvContent = [
        'Nombre completo,Email,Código de descuento,Productos,Pais Emisor,Estado Civil',
        ...cleanContractTemps.map((record: any) => {
          const applicants = record.applicants ? JSON.parse(record.applicants) : [];
          const memberName = applicants.find((applicant: { memberType: string }) => applicant.memberType === 'Solicitante principal')?.memberName || '';
  
          let discountCode = '';
          if (record.props && Array.isArray(record.props)) {
            for (const propString of record.props) {
              try {
                const propsObject = JSON.parse(propString);
                if (propsObject['Codigo de descuento']) {
                  discountCode = propsObject['Codigo de descuento'];
                  break;
                }
              } catch (e) {
                console.error("Error parsing JSON:", e);
              }
            }
          }
  
          const products = applicants.map((applicant: any) => applicant.productName).join('; ');
          const passportCountry = record.passport_country || '';
          const civilStatus = record.civilstatus || '';
  
          return [
            memberName,
            record.email,
            discountCode,
            products,
            passportCountry,
            civilStatus,
          ].join(',');
        }),
      ].join('\n');
  
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'contracts.csv');
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };




  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pageRange = 11;

  // Calcula el rango de páginas a mostrar
  let startPage = currentPage;
  let endPage = Math.min(tempsData?.totalPages, currentPage + pageRange - 1);

  // Ajusta el rango si el final está cerca del límite de las páginas
  if (endPage - startPage + 1 < pageRange) {
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  // Asegúrate de que el rango no sobrepase el número total de páginas
  startPage = Math.max(1, startPage);
  endPage = Math.min(tempsData?.totalPages, endPage);

// Evita que se genere un array con longitud inválida
const totalPagesToShow = endPage >= startPage ? endPage - startPage + 1 : 0;















  const [contractsFound, setContractsFound] = useState<RootObjectCompletedContracts>({
    resultSetSize: '',
    startPosition: '',
    endPosition: '',
    totalSetSize: '',
    nextUri: '',
    previousUri: '',
    envelopes: [],
    folderItems: [],
  })

  useEffect(() => {
    getContractTemps(search)
  }, [search])

  useEffect(() => {
    refetch()
  }, [filterEmail, filterFullName, filterProduct, filterDateFrom, filterDateTo,filterPassportCountry,currentPage,filterCivil,filterCode])

  useEffect(() => {
    console.log(dataSelected, 'pepe elegante')
  }, [dataSelected])
  // const { getUsers, isLoadingUsers, users, selectUser } = rootStore.usersStore
  type DataIndex = keyof ContractsTemps
  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as string);
  };


  if (isLoadingContractTemp) {
    return (
      <>
        {' '}
        <Spin tip={intl.formatMessage({ id: 'LOADING.BUTTON' })} size='large'>
          <div className='content' />
        </Spin>
      </>
    )
  }
  const toggleModal = async (email: string) => {
    const result = await getUserUsersByEmail(email)

    result != null ? setUserData(result) : console.log('')
    setIsModalVisible((wasModasVisible) => !wasModasVisible)
  }










  const toggleEmailFilter = () => setShowEmailFilter(!showEmailFilter)
  const toggleNameFilter = () => setShowNameFilter(!showNameFilter)
  const toggleProductFilter = () => setShowproductFilter(!showproductFilter)
  const toggleDateFilter = () => setshowDateFilter(!showDateFilter)
  const togglePassportFilter = () => setshowPassportCountryFilter(!showPassportCountryFilter)
  const toggleCivilFilter = () => setshowCivilFilter(!showCivilFilter)
  const toggleCode = () => setshowCodeFilter(!showCodeFilter)
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= tempsData?.totalPages) {
      setCurrentPage(newPage)
    }
  }

  const handleEmailFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setFilterEmail(value);
  };

  const handleCodeFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setFilterCode(value);
  };



  const handleFullNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFullName(e.target.value);
  };

  const handleProductFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setFilterProduct(selectedOptions); 
  };
  
  

  const handlePassport = (event: React.ChangeEvent<HTMLSelectElement>) => {

    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setPassportCountry(selectedOptions); // Actualiza el estado con los valores seleccionados
  };

 
  const handleCivilFilterChnge = (event: React.ChangeEvent<HTMLSelectElement>) => {

    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setCivil(selectedOptions); // Actualiza el estado con los valores seleccionados
  };


  return (
  <>
    <div className='CSV-Container' style={{ position:'absolute', right:'30px', top:'127px'}}>
      <button className='button-ui button-primary' onClick={exportToCSV}>
        Exportar a CSV
      </button>
    </div>

    {showTable ? (
      <>
        <Button
          style={{ width: '11rem', fontSize: '1.5rem', height: '2rem' }}
          onClick={() => {
            setShowTable(false);
            setDataSelected(undefined);
          }}
        >
          Regresar
        </Button>
        <CompletedContracts
          email={dataSelected && dataSelected.email ? dataSelected?.email : ''}
          setContractsFound={setContractsFound}
        />
        <ShowAnswersConsultant2
          userEmail={dataSelected && dataSelected.email ? dataSelected?.email : ''}
          contractsFound={contractsFound}
        />
      </>
    ) : (
      <>
        <Filter searchQuery={search} setSearch={setSearch} />
        <div style={{ overflow: 'auto', maxWidth: '100%', height: '70%' }}>
         {/*  <>{JSON.stringify(tempsData?.count)}</>
          <>{JSON.stringify(filterProduct)}</>
          <>{currentPage}</> */}

          <table className="answers-dashboard-table" style={{ width: '82rem', borderCollapse: 'separate', borderSpacing: '0 10px', marginLeft: '0.5rem' }}>
            <thead>
              <tr style={{ fontSize: '16px', fontWeight: '700' }}>
                <th style={{ position: 'relative' }}>
                  <div style={{ display: 'flex' }}>
                    Email
                    <img src={funnel} alt="Filter" onClick={toggleEmailFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showEmailFilter && (
                  <div className="filter-container">
                      <input
                        type="text"
                        placeholder="Filtrar por email"
                        value={filterEmail}
                        onChange={handleEmailFilterChange}
                      />
                    </div>
                  )}
                </th>
                <th style={{ position: 'relative', paddingRight:'10rem' }}>
                  <div style={{ display: 'flex' }}>
                    Nombre
                    <img src={funnel} alt="Filter" onClick={toggleNameFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showNameFilter && (
                  <div className="filter-container">
                      <input
                        type="text"
                        placeholder="Filtrar por nombre completo"
                        value={filterFullName}
                        onChange={handleFullNameFilterChange}
                      />
                    </div>
                  )}
                </th>
                <th style={{ position: 'relative', paddingRight:'15rem' }}>
                  <div style={{ display: 'flex' }}>
                    Productos
                    <img src={funnel} alt="Filter" onClick={toggleProductFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showproductFilter && (
                    <div className="filter-container">
                      <Select
                        mode="multiple"
                        value={filterProduct}
                        onChange={setFilterProduct}
                        style={{ width: '100%' }}
                        placeholder="Seleccione productos"
                      >
                        {products && products.map((product: any) => (
                          <Option key={product.id} value={product.name}>
                            {product.name}
                          </Option>
                        ))}
                      </Select>
                      <Button onClick={() => setFilterProduct([])} style={{ marginTop: '10px', display:'flex' }}>Reset</Button>
                    </div>
                  )}
                </th>
                <th style={{ position: 'relative', paddingRight:'6rem' }}>
                  <div style={{ display: 'flex', width:'10rem' }}>
                    Fecha
                    <img src={funnel} alt="Filter" onClick={toggleDateFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showDateFilter && (
                  <div className="filter-container">
                      <div>
                        <label>Desde:</label>
                        <DatePicker selected={filterDateFrom} onChange={(date) => setFilterDateFrom(date)} />
                      </div>
                      <div>
                        <label>Hasta:</label>
                        <DatePicker selected={filterDateTo} onChange={(date) => setFilterDateTo(date)} />
                      </div>
                    </div>
                  )}
                </th>
                <th style={{ position: 'relative', paddingRight:'6rem' }}>
                  <div style={{ display: 'flex', width:'7rem' }}>
                    Pais Emisor
                    <img src={funnel} alt="Filter" onClick={togglePassportFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showPassportCountryFilter && (
                    <div className="filter-container">
                      <Select
                        mode="multiple"
                        value={filterPassportCountry}
                        onChange={setPassportCountry}
                        style={{ width: '100%' }}
                        placeholder="Seleccione País"
                      >
                        {dataPassportCountry && dataPassportCountry.map((pass: any) => (
                          <Option key={pass.name} value={pass.name}>
                            {pass.name}
                          </Option>
                        ))}
                      </Select>
                      <Button onClick={() => setPassportCountry([])} style={{ marginTop: '10px', display:'flex' }}>Reset</Button>
                    </div>
                  )}
                </th>
                <th style={{ position: 'relative', paddingRight:'6rem' }}>
                  <div style={{ display: 'flex', width:'7rem' }}>
                    Estado Civil
                    <img src={funnel} alt="Filter" onClick={toggleCivilFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showCivilFilter && (
                   <div className="filter-container">
                      <Select
                        mode="multiple"
                        value={filterCivil}
                        onChange={setCivil}
                        style={{ width: '100%' }}
                        placeholder="Seleccione Estado Civil"
                      >
                        <Option key="Casado" value="Casado">Casado</Option>
                        <Option key="Soltero" value="Soltero">Soltero</Option>
                        <Option key="Legalmente separado" value="Legalmente separado">Legalmente separado</Option>
                        <Option key="Divorciado" value="Divorciado">Divorciado</Option>
                        <Option key="Matrimonio anulado" value="Matrimonio anulado">Matrimonio anulado</Option>
                        <Option key="Viudo" value="Viudo">Viudo</Option>
                        <Option key="Unión marital de hecho" value="Unión marital de hecho">Unión marital de hecho</Option>
                        
                      </Select>
                      <Button onClick={() => setCivil([])} style={{ marginTop: '10px', display:'flex' }}>Reset</Button>
                    </div>
                  )}
                </th>
                <th style={{ position: 'relative', paddingRight:'6rem' }}>
                  <div style={{ display: 'flex', width:'11rem' }}>
                    Codigo de descuento
                    <img src={funnel} alt="Filter" onClick={toggleCode} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                  </div>
                  {showCodeFilter && (
                 <div className="filter-container">
                      <input
                        type="text"
                        placeholder="Filtrar por nombre o email"
                        value={filterCode}
                        onChange={handleCodeFilterChange}
                      />
                    </div>
                  )}
                </th>
                <th>
                <div style={{ display: 'flex', width:'11rem' }}>
                  Contrato Previo </div>
                </th>
                <th>
                  Respuestas
                </th>
              </tr>
            </thead>
            <tbody>
              {tempsData && Array.isArray(tempsData.data) && tempsData.data.map((elem: any, index: any) => (
                <tr key={index}>
                  <td style={{ width: '17%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.email}</div>
                  </td>
                  <td style={{ width: '14%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.completedname}</div>
                  </td>
                  <td style={{ width: '24%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    {elem && elem.applicants && JSON.parse(elem.applicants).map((applicant: any, index: any) => (
                      <div className="client-name" key={index}>
                        {applicant.productName}
                      </div>
                    ))}
                  </td>
                  <td style={{ width: '10%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.created_at.substring(0, 10)}</div>
                  </td>
                  <td style={{ width: '10%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.passport_country}</div>
                  </td>
                  <td style={{ width: '10%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.civilstatus}</div>
                  </td>
                  <td style={{ width: '10%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.discount_code}</div>
                  </td>
                  <td style={{ width: '10%', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">{elem.previous_contract}</div>
                  </td>
                  <td style={{ width: '1rem', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                    <div className="client-name">
                      <button onClick={() => {
                        setDataSelected(elem);
                        setIsModalVisible(true);
                      }}>
                        <img src={notes} alt="Notas" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}>
              ←
            </button>
            {totalPagesToShow > 0 && (
              <>
                {[...Array(endPage - startPage + 1)].map((_, index) => {
                  const pageNumber = startPage + index;
                  return (
                    <button
                      key={pageNumber}
                      className={`pagination-button ${currentPage === pageNumber ? 'active' : ''}`}
                      onClick={() => handlePageChange(pageNumber)}
                      style={{
                        backgroundColor: currentPage === pageNumber ? '#007bff' : '#f9f9f9',
                        color: currentPage === pageNumber ? 'white' : 'black',
                        border: `1px solid ${currentPage === pageNumber ? '#007bff' : '#ccc'}`,
                        cursor: 'pointer',
                        padding: '10px',
                        margin: '5px',
                      }}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
              </>
            )}
            <button
              className={`pagination-button ${currentPage === tempsData?.totalPages ? 'disabled' : ''}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === tempsData?.totalPages}>
              →
            </button>
          </div>
          <CompletedContractsModal
            visible={isModalVisible}
            onClose={handleCancel}
            setContractsFound={setContractsFound}
            contractsFound={contractsFound}
            data={dataSelected} userEmail={''}
          />
        </div>
      </>
    )}
  </>
)
}

export default observer(AnswersList)
