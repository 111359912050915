/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useContext, useEffect} from 'react'
import {type CheckBoxOptions} from '../types'
import ImmigrationDetails from '../ImmigrationDetails'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {observer} from 'mobx-react'

const ChooseImmigrationLink = () => {
  const rootStore = useContext(RootStoreContext)
  const {immigrationServices, immigrationLink, getImmigrationLinks, setImmigrationLinksCheked} =
    rootStore.composeTemplateStore

  useEffect(() => {
    getImmigrationLinks()
  }, [immigrationServices])

  const handleChange = (checkBoxOptions: CheckBoxOptions, cheked: boolean, type: string) => {
    setImmigrationLinksCheked(checkBoxOptions, cheked)
  }

  useEffect(() => {
    // comment to deactivate eslint
  }, [immigrationLink])

  return (
    <>
      {immigrationLink &&
        immigrationServices.map((item) => {
          return (
            <div key={item.id}>
              <ImmigrationDetails
                immigrationService={item}
                options={immigrationLink}
                type='immigrationLink'
                handleChange={handleChange}
              />
            </div>
          )
        })}
    </>
  )
}

export default observer(ChooseImmigrationLink)
