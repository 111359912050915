export const core = {
  'UPDATE.SUCCESS': 'Update Success',
  'UPDATE.ERROR': 'Error to update',

  'TRANSLATOR.SELECT': 'Select your language',
  'CORE.BTNSAVE': 'Save',
  'CORE.WELCOME': 'Welcome',
  'CORE.DATE': 'Date',
  'CORE.TYPE': 'Type',
  'CORE.STATE': 'Estado',
  'CORE.PENDING': 'Pending',
  'CORE.DONE': 'Done',
  'CORE.REQUIRED': 'Required field',

  'ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT': 'Selected records count: ',
  'ECOMMERCE.COMMON.ALL': 'All',
  'ECOMMERCE.COMMON.SUSPENDED': 'Suspended',
  'ECOMMERCE.COMMON.ACTIVE': 'Active',
  'ECOMMERCE.COMMON.FILTER': 'Filter',
  'ECOMMERCE.COMMON.BY_STATUS': 'by Status',
  'ECOMMERCE.COMMON.BY_TYPE': 'by Type',
  'ECOMMERCE.COMMON.BUSINESS': 'Business',
  'ECOMMERCE.COMMON.INDIVIDUAL': 'Individual',
  'ECOMMERCE.COMMON.SEARCH': 'Search',
  'ECOMMERCE.COMMON.IN_ALL_FIELDS': 'in all fields',
  'ECOMMERCE.ECOMMERCE': 'eCommerce',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS': 'Customers',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST': 'Customers list',
  'ECOMMERCE.CUSTOMERS.NEW_CUSTOMER': 'New Customer',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE': 'Customer Delete',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION':
    'Are you sure to permanently delete this customer?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION': 'Customer is deleting...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE': 'Customer has been deleted',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE': 'Customers Delete',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION':
    'Are you sure to permanently delete selected customers?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION': 'Customers are deleting...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE': 'Selected customers have been deleted',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE': 'Status has been updated for selected customers',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE':
    'Selected customers status have successfully been updated',
  'ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE': 'Customer has been updated',
  'ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE': 'Customer has been created',
  'PROFILE.PROFILETITTLE': 'My profile',
  'PROFILE.PROFILETITTLEBASIC': 'Basic data',
  'PROFILE.PROFILETITTLEIMMIGRATION': 'Immigration data',
  'PROFILE.PROFILETITTLEIMMIGRATIONNEW': 'Labour Profile',
  'PROFILE.SIGNOUT': 'Sign Out',
  'PROFILE.PLEASEWAIT': 'Please Wait',
  'PROFILE.FIRSTNAME': 'First name',
  'PROFILE.LASTNAME': 'Last name',
  'PROFILE.EMAIL': 'Email',
  'PROFILE.OCCUPATION': 'Occupation',
  'PROFILE.CONYUGE': 'Spouse`s name',
  'PROFILE.CONYUGEAGE': 'Spouse`s Age',
  'PROFILE.CONYUGECOUNTRY': 'Country of citizenship',
  'PROFILE.CONYUGESTUDY': 'Study Field',
  'PROFILE.CIVILSTATUS': 'Civil status',
  'PROFILE.REJECTED': 'have you had rejections?',
  'PROFILE.CHILDREN': 'Number of children',
  'PROFILE.ACADEMIC': 'Highest academic degree completed',
  'PROFILE.ACADEMICDEGREE': 'Choose highest academic degree completed',
  'PROFILE.ENGLISHPROF': 'English proficiency',
  'PROFILE.FRENCHPROF': 'French proficiency',
  'PROFILE.LABOURHISTORY': 'Most recent employment history',
  'PROFILE.CANADAAPLICATION': 'Have you made previous applications to Canada in the past?',
  'PROFILE.TYPEOFAPLICATION': 'what kind of application did you do?',
  'PROFILE.STUDY': 'Study field',
  'PROFILE.BIRTH': 'Birt',
  'PROFILE.ADDRESS': 'Address',
  'PROFILE.PHONE': 'Phone',
  'PROFILE.MARITALSTATUS': 'Marital status',
  'PROFILE.COUNTRYOFORIGIN': 'Country of current residence',
  'PROFILE.NATIONALITIE': 'Nationalitie',
  'PROFILE.NATIONALITIESELECT': 'Select Nationalitie...',
  'PROFILE.LANGUAGE': 'Language',
  'PROFILE.LANGUAGESELECT': 'Select Language...',
  'PROFILE.TIMEZONE': 'Time zone',
  'PROFILE.TIMEZONESELECT': 'Select time zone...',
  'PROFILE.GENDER': 'Gender',
  'PROFILE.GENDERSELECT': 'Select gender',
  'PROFILE.GENDERM': 'Male',
  'PROFILE.GENDERF': 'Female',
  'PROFILE.GENDERO': 'Other',
  'PROFILE.CONTINUE': 'Continue',
}
