import React, { useState, useEffect } from 'react';
import { getPostalCodes } from 'app/modules/apps/appointments/core/_requests';

interface PostalCodeResult {
  POSTAL_CODE?: string;
  CITY?: string;
  PROVINCE_ABBR?: string;
  ZipCode?: string;
  City?: string;
  County?: string;
  State?: string;
}

interface PostalCodeSearchProps {
  onPostalCodeSelect: (postalCode: string) => void;
  initialPostalCode?: string; // Add an optional prop for the initial postal code
}

const PostalCodeSearch: React.FC<PostalCodeSearchProps> = ({ onPostalCodeSelect, initialPostalCode }) => {
  const [query, setQuery] = useState(initialPostalCode || ''); // Initialize with initialPostalCode if provided
  const [results, setResults] = useState<PostalCodeResult[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState(true);

  useEffect(() => {
    if (initialPostalCode) {
      setQuery(initialPostalCode);
      onPostalCodeSelect(initialPostalCode);
    }
  }, [initialPostalCode, onPostalCodeSelect]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setError(null);
    if (value.length > 1) {
      const response = await getPostalCodes(value);
      setResults(response);
      setShowDropdown(true);

      if (response.length === 0) {
        setError('El código postal no existe');
      }
    } else {
      setResults([]);
      setShowDropdown(false);
    }
  };

  const handleSelect = (selected: PostalCodeResult) => {
    const formattedQuery = selected.ZipCode || selected.POSTAL_CODE || '';
    setQuery(formattedQuery);
    setShowDropdown(false);
    setError(null);
    onPostalCodeSelect(formattedQuery);
  };

  return (
    <div className="serach-bar-container">
      <input
        style={{ border: '1px solid #637381', color: '#637381' }}
        type="text"
        placeholder="Ingresa el código postal o la ciudad"
        className='option-styles'
        value={query}
        onChange={handleInputChange}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {showDropdown && results.length > 0 && (
        <div style={{ top:'28px', left:'4px', width:'595px', cursor:'pointer'}} className='results-list2'>
          {results.map((result, index) => (
            <div
              key={index}
              onClick={() => handleSelect(result)}
              className='search-result'>
              {result.ZipCode || result.POSTAL_CODE} - {result.City || result.CITY}, {result.County || result.PROVINCE_ABBR}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostalCodeSearch;
