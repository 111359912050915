/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {type Dispatch, type SetStateAction, useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {RootStoreContext} from '../../../../../stores/rootStore'
import './zoomOutButton.css'
import {type Contracts} from '../../../../../app/modules/apps/contract/_models'
import {ShowAnswers} from './showAnswers'

interface IProps {
  setTabActive: Dispatch<SetStateAction<string>>
  setVisaType: Dispatch<SetStateAction<string>>
  visaType: string
  handleClose: (key: string) => void
  dataResult?: Contracts[]
}
const ContractView: React.FC<IProps> = ({
  setTabActive,
  setVisaType,
  visaType,
  handleClose,
  dataResult,
}) => {
  const [showSurvey, setShowSurvey] = useState(false)
  const [jsonDataSelected, setJsonDataSelected] = useState({})
  const [formNameSelected, setformNameSelected] = useState('')
  const [formsDataIdSelected, setFormsDataIdSelected] = useState('')
  const [envelopesId, setEnvelopesId] = useState<Contracts[]>([])
  const [showListFormsClientTable, setShowListFormsClientTable] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  // const [visaTypeSelected, setvisaTypeSelected] = useState(visaType)
  const intl = useIntl()
  const {user} = rootStore.authStore
  const [numPage, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfDoc, setPdfDoc] = useState()
  const [contractsNumber, setContractsNumber] = useState<number>()
  const [showContract, setShowContract] = useState<boolean>(false)
  const [answers, setAnswers] = useState('')

  const answersVari = ''

  if (dataResult != null) {
    return (
      <div style={{width: '100%'}}>
        <div style={{width: '100%'}}></div>
        <div style={{width: '100%'}}>
          <ShowAnswers contracts={dataResult} setVisaType={setVisaType} />
        </div>

        <div className='m-3 p-3 block md:flex md:justify-between'>
          <button
            onClick={() => {
              handleClose('menu')
            }}
            style={{
              color: '#bf3c3c',
              textAlign: 'center',
              border: '1px solid #BF3C3C',
              backgroundColor: 'white',
              borderRadius: '7px',

              fontSize: '16px',
              fontWeight: '550',
              textDecoration: 'none',
              display: 'inline-block',
              width: '260px',
              justifyContent: 'center',
              height: '40px',
              alignItems: 'center',
              justifyItems: 'center',
              marginTop: '20px',
              padding: '8px',
            }}
          >
            Atras
          </button>
          {(visaType === 'visa de turismo' ||
            visaType === 'visa de estudios' ||
            visaType === 'visa de trabajo') &&
          dataResult[0] !== undefined ? (
            <button
              onClick={() => {
                setTabActive('importantInfo')
              }}
              style={{
                color: 'white',
                textAlign: 'center',
                border: '1px solid #BF3C3C',
                backgroundColor: '#BF3C3C',
                borderRadius: '7px',

                fontSize: '16px',
                fontWeight: '550',
                textDecoration: 'none',
                display: 'inline-block',
                width: '260px',
                justifyContent: 'center',
                height: '40px',
                alignItems: 'center',
                justifyItems: 'center',
                marginTop: '20px',
                padding: '8px',
              }}
            >
              Comenzar
            </button>
          ) : null}
        </div>
      </div>
    )
  } else {
    return (
      <>
        no data <> </>{' '}
      </>
    )
  }
}
export {ContractView}
