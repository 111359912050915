export const clients = {
  'CLIENTS.CLIENTSLIST': 'Client List',
  'CLIENTS.SEARCH': 'Search',
  'CLIENTS.NAME': 'Name',
  'CLIENTS.EMAIL': 'Email',
  'CLIENTS.PROFILEINFORMATION': 'Profile Information',
  'CLIENTS.PURCHASEDSERVICES': 'Purchased Services',
  'CLIENTS.COMMUNICATION': 'Communication',
  'CLIENTS.TRANSLATIONS': 'Translations',
  'CLIENTS.PROFILE': 'Profile',
  'CLIENTS.BACK': 'Back',
  'CLIENTS.FORMS': 'Forms',
  'CLIENTS.DOCUMENTS': 'Documents',
  'CLIENTS.MYPROCESSVISA': 'My visa process',
  'CLIENTS.PROCESSVISA': 'Visa process',
}
