/* eslint-disable @typescript-eslint/no-unused-vars */

import axios, {type AxiosResponse} from 'axios'
import {type PaymentsPayu, type ApiCalls} from './_models'
import {Orders} from '../preorders/_models'

const API_URL = process.env.REACT_APP_API_URL
const PAYMENTSPAYU_URL = `${API_URL}/paymentspayu`
const PAYMENTS_URL = `${API_URL}/payments`
const PRODUCTS = `${API_URL}/products`
const FILES = `${API_URL}/files`

const savePaymentsPayu = (paymentspayu: PaymentsPayu): Promise<PaymentsPayu[]> | undefined | any  => {
  return axios.post(`${PAYMENTSPAYU_URL}`, {...paymentspayu}).then((response: AxiosResponse) => {
    return response.data as PaymentsPayu[]
  })
}

const savePaymentsPaypal = (payments: any): Promise<any> | undefined | any  => {
  return axios.post(`${PRODUCTS}/createpayment`, {...payments}).then((response: AxiosResponse) => {
    return response.data as any
  })
}

const updatePaymentsPayu = (id: string, paymentspayu: ApiCalls): Promise<any> | undefined => {
  return axios
    .post(`${PAYMENTSPAYU_URL}/updated`, {
      id: id,
      ...{statusName: paymentspayu.statusName, status: paymentspayu.status},
    })
    .then((response: AxiosResponse) => {
      return response.data
    })
}

// ver donde se crea la tarea postorder

// const updatePaymentsPayu =  async (id:String, paymentspayu: ApiCalls): Promise<any> => {
//   console.log("paymentspayu: ", paymentspayu)
//   return axios.patch(`${PAYMENTSPAYU_URL}`, { statusName: paymentspayu.statusName }).then((response: AxiosResponse) => {
//     console.log("Set Paymente response.data: ", response.data)
//     return response.data as PaymentsPayu[]
//   })
// }

// getPaymentsPayu
const getPaymentsPayu = (code: string): Promise<PaymentsPayu> | undefined => {
  return axios.get(`${PAYMENTSPAYU_URL}/source/${code}`).then((response: AxiosResponse) => {
    return response.data as PaymentsPayu
  })
}

// getPayments
const getPayments = (code: string): Promise<any> | undefined => {
  return axios.get(`${PAYMENTSPAYU_URL}/sourcePayment/${code}`).then((response: AxiosResponse) => {
    return response.data as any
  })
}

const saveOrder = (orders: any): Promise<any> | undefined => {
  return axios.post(`${PAYMENTSPAYU_URL}/updateOrder2`, orders).then((response: AxiosResponse) => {
    return response.data
  })
}

const getFileIdByCode = (code: any): Promise<any> | undefined => {
  return axios.get(`${PAYMENTSPAYU_URL}/getByCode/${code}`).then((response: AxiosResponse) => {
    return response.data as any
  })
}

const saveTranslation = (translation: any): Promise<any> | undefined => {
  // translation.is_finished, translation.task_id, translation.user_id, translation.staff_id, translation.pages, translation.pages_translated, translation.tasks_users_id
  return axios
    .post(`${PAYMENTSPAYU_URL}/saveTranslation`, translation)
    .then((response: AxiosResponse) => {
      return response.data
    })
}

export {
  savePaymentsPayu,
  getPaymentsPayu,
  updatePaymentsPayu,
  getPayments,
  saveOrder,
  getFileIdByCode,
  saveTranslation,
  savePaymentsPaypal
}
