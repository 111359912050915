/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  type IQueryParams,
  type Translations,
} from '../../../../app/modules/apps/translations/core/_models'
import React, {type Dispatch, type SetStateAction} from 'react'
import {IReply, type IComposeData} from '../../../../app/modules/apps/appointments/core/_models'
import ListTranslations from './ListTranslations'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {Button, Link} from '../../../../components'

const querysParam: IQueryParams = {
  page: 1,
  limit: 50,
}

interface IProps {
  setAssign: Dispatch<SetStateAction<boolean>>
  setTranslation: Dispatch<SetStateAction<Translations>>
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>
  setComposePost: React.Dispatch<React.SetStateAction<boolean>>
  setTabNumber: Dispatch<SetStateAction<string>>
}

const TranslationsComponent: React.FC<IProps> = ({
  setAssign,
  setTranslation,
  setComposePost,
  setComposePostData,
  setTabNumber,
}) => {
  const intl = useIntl()

  return (
    <>
      {' '}
      <Link to='/admin/translate'>
        <Button
          className='button-ui button-secondary button-s'
          // style={{
          //   width: '8rem',
          //   alignSelf: 'end',
          //   marginRight: '2rem',
          //   border: 'none',
          //   marginBottom: '1rem',
          //   gap: '0.5rem',
          // }}
        >
          Regresar
        </Button>
      </Link>
      {/* <TranslationFilters /> */}
      {/*  <TranslationTabs
        children={
          <ListTranslations
            setAssign={setAssign}
            setComposePost={setComposePost}
            setComposePostData={setComposePostData}
            setTranslation={setTranslation}
          />
        }
        children2={<ListTranslationsCompleted setTranslation={setTranslation} />}
        setTranslation={setTranslation}
      /> */}
      <ListTranslations
        setTabNumber={setTabNumber}
        setAssign={setAssign}
        setComposePost={setComposePost}
        setComposePostData={setComposePostData}
        setTranslation={setTranslation}
      />
    </>
  )
}

export default observer(TranslationsComponent)
