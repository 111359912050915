/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState, useEffect } from 'react'
import { RootStoreContext } from '../../../../stores/rootStore'
import { getAllCompletedContractsByEmail } from '../../../modules/apps/docusign/_requests'
import { useQuery } from 'react-query'
import '../contratacionAnswers/style.css'
import OnboardingUser from '../userContratationmenu/OnboardingUser'
import AdditionalUserForms from '../userContratationmenu/AdditionalUserForms'
import 'antd/dist/antd.min.css'
import {
  getContractByUserId,
  saveContract,
} from '../../../modules/apps/contract/_requests'
import { type ContractFromDataBase } from '../../../modules/apps/contract/_models'
import './style.css'
import UploaderDocuments from './uploaderDocuments/UploaderDocuments'
import FinalStep from './FinalStep/FinalStep'
import UploaderLetters from './uploaderDocuments/UploaderLetters'

interface IProps {
  folderItems: ContractFromDataBase[] | undefined
  email: string
  createAt: string
  hoy: string
  name: string
  id: string
}

const NewUserContratactView: React.FC<IProps> = ({ email, createAt, id }) => {
  const fromDate = createAt
  const userEmail = email
  /* get current user */
  const rootStore = useContext(RootStoreContext)
  const { user: userAuthStore } = rootStore.authStore
  const [start, setStart] = useState(false)
  const [start2, setStart2] = useState(false)
  const [step, setStep] = useState(0)
  const [contractId, setContractId] = useState('')
  const [stepUpdate, setStepUpdate] = useState(0)
  const [maxStep, setMaxStep] = useState(0)
  const [stepBarProgress, setStepBarProgress] = useState(0)
  const [listProducts, setlistProducts] = useState<any>([])
  const { contracts: dataFromDatabase, loadingContracts } = rootStore.authStore
  const [selected, setSelected] = useState(false)
  const [fileContentState, setFileContentState] = useState<Blob | undefined>(undefined)

  const {
    data,
    isSuccess,
    status: statusDocusign,
  } = useQuery(
    'getAllContractCompletedByEmailActiveqq',
    async () => await getAllCompletedContractsByEmail(userEmail, fromDate, '0000-00-00'),
    { retry: false, cacheTime: 0 }
  )
  const {
    data: dataFromContractTable,
    isSuccess: isSuccessDataFromContractTable,
    status: statusDatabase,
  } = useQuery('contractFromUserIdActiveqq', async () => await getContractByUserId(id), {
    retry: false,
    cacheTime: 0,
  })

  if (
    data &&
    data.folderItems.length > 0 &&
    dataFromContractTable &&
    dataFromContractTable.data.length > 0 &&
    statusDatabase === 'success' &&
    isSuccess &&
    isSuccessDataFromContractTable &&
    statusDatabase === 'success'
  ) {
    for (const folderItem of data.folderItems) {
      let found = false
      for (const contractFromTable of dataFromContractTable.data) {
        if (contractFromTable.envelope_id === folderItem.envelopeId) {
          found = true
        }
      }
      if (!found) {
        const contract = {
          user: userAuthStore,
          envelope_id: folderItem.envelopeId,
          data: folderItem,
          email: userAuthStore?.email,
          offboarding: false,
          completedDate: folderItem.completedDateTime,
          matter: folderItem.matter ? folderItem.matter : '',
          match_id: folderItem.matchId ? folderItem.matchId : '',
        }
        // saveContract(contract)
      }
    }
  }

  const readLocalStorageData = () => {
    dataFromDatabase.map((cont: any) => {
      if (cont.status === 1) {
        setStep(cont.step === null ? 0 : cont.step)
        setMaxStep(cont.step !== null ? cont.step : '')
        setContractId(
          cont.envelope_id !== null ? cont.envelope_id : ''
        )
        setlistProducts(cont.list_products)
        setStepUpdate(stepUpdate + 1)
        setStart(true)
        setStart2(true)
        setStepUpdate(stepUpdate + 1)
        if (cont.step === 1) {
          setStepBarProgress(20)
        }
      } else {
        setStepUpdate(stepUpdate + 1)
        setStart2(true)
      }
    })
  }

  useEffect(() => {
    if (loadingContracts === false && start2 === false) {
      readLocalStorageData()
    }
  }, [dataFromDatabase, readLocalStorageData, start2, loadingContracts])

  useEffect(() => { }, [step])

  const starting = () => {
    setStart(true)
    setStep(1)
  }
  useEffect(() => { }, [fileContentState, selected])

  return (
    <>
      {start === false ? (
        <div className='popup-video-continer'>
          <div className='popup-video-continer-main'>
            <div className='flex flex-col items-center justify-center'>
              <h4 className='step-header' style={{ margin: '0' }}>¿Qué pasa ahora? - Próximos pasos</h4>
              <iframe
                className='video-continer'
                src='https://player.vimeo.com/video/903427440?h=61bebefe5f'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                id='youtube_player'
              ></iframe>
            </div>
            <div className='flex w-full items-center justify-center mb-4'>
              <button
                className='button2 close-video-btn'
                onClick={() => {
                  starting()
                  if (maxStep === 0 || maxStep === null) {
                    setStepBarProgress(20)
                    setMaxStep(1)
                  }
                }}>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ width: '100%' }}>
            {stepUpdate > 0 && step === 1 && (
              <OnboardingUser
                stepBar={stepBarProgress}
                setStepBar={setStepBarProgress}
                setMaxStep={setMaxStep}
                maxStep={maxStep}
                start={setStart}
                name={userAuthStore?.firstName ? userAuthStore.firstName : ''}
                data={data}
                status={statusDocusign}
                step={step}
                setStep={setStep}
                contractId={contractId}
                setContractId={setContractId} />)}
            {stepUpdate > 0 && step === 2 && (
              <AdditionalUserForms
                listProducts={listProducts}
                setListProducts={setlistProducts}
                stepBar={stepBarProgress}
                setStepBar={setStepBarProgress}
                setMaxStep={setMaxStep}
                maxStep={maxStep}
                start={setStart}
                name={userAuthStore?.firstName ? userAuthStore.firstName : ''}
                contractData={data}
                contractStatus={statusDatabase}
                step={step}
                setStep={setStep}
                contractId={contractId}
                setContractId={setContractId}
                contract={dataFromDatabase} />)}
            {stepUpdate > 0 && step === 3 && (
              <UploaderDocuments
                listProducts={listProducts}
                setListProducts={setlistProducts}
                stepBar={stepBarProgress}
                setStepBar={setStepBarProgress}
                setMaxStep={setMaxStep}
                maxStep={maxStep}
                start={setStart}
                name={userAuthStore?.firstName ? userAuthStore.firstName : ''}
                contractData={data}
                contractStatus={statusDatabase}
                step={step}
                setStep={setStep}
                contractId={contractId}
                setContractId={setContractId}
                contract={dataFromDatabase} />)}
            {stepUpdate > 0 && step === 4 && (
              <FinalStep
                setMaxStep={setMaxStep}
                maxStep={maxStep}
                start={setStart}
                name={userAuthStore?.firstName ? userAuthStore.firstName : ''}
                contractData={data}
                contractStatus={statusDatabase}
                step={step}
                setStep={setStep}
                contractId={contractId}
                setContractId={setContractId}
                contract={dataFromDatabase} />)}
            {stepUpdate > 0 && step === 5 && (
              <UploaderLetters
                listProducts={listProducts}
                setListProducts={setlistProducts}
                stepBar={stepBarProgress}
                setStepBar={setStepBarProgress}
                setMaxStep={setMaxStep}
                maxStep={maxStep}
                start={setStart}
                name={userAuthStore?.firstName ? userAuthStore.firstName : ''}
                contractData={data}
                contractStatus={statusDatabase}
                step={step}
                setStep={setStep}
                contractId={contractId}
                setContractId={setContractId}
                contract={dataFromDatabase} />)}
            <div className='w-full flex items-center mt-14'></div>
          </div>
        </>)}
    </>
  )
}
export { NewUserContratactView }
