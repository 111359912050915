import {type Response} from '../../../../../../core/helpers'
import {type status} from '../../../../../../core/interfaces/status'

export interface Category {
  id?: string
  name: string | ''
  icon: string | ''
  link: string | ''
  description: string | ''
  tittle: string | ''
  order: number
  status: status
}

export type CategoryQueryResponse = Response<Category[]>

type toFormValuesType = (apiData: Category) => Category

export const categoryToFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  name: apiData.name || '',
  icon: apiData.icon || '',
  link: apiData.link || '',
  description: apiData.description || '',
  tittle: apiData.tittle || '',
  order: apiData.order || 100,
  status: {
    id: apiData.status.id,
  },
})

export const categoryDetailsInitValues: Category = {
  name: '',
  icon: '',
  link: '',
  description: '',
  tittle: '',
  order: 100,
  status: {id: 1},
}
