import React, {useState, useEffect} from 'react'
import {Aplication} from 'app/modules/apps/cart/core/aplication/_models'
import {getStatusEducation, updateAplication} from 'app/modules/apps/cart/core/aplication/_request'
import {Institution} from 'app/modules/apps/cart/core/education/_models'
import {useQuery} from 'react-query'
import {getInstitution} from 'app/modules/apps/cart/core/education/_request'
import {Program} from 'app/modules/apps/cart/core/program/_models'
import {getPormgramsById} from 'app/modules/apps/cart/core/program/_request'

interface EditAplicationFormProps {
  editAplication: Aplication
  onSave: () => void
}

const EditAplicationForm: React.FC<EditAplicationFormProps> = ({editAplication, onSave}) => {
  const {data: statused} = useQuery('getStatusEd', async () => await getStatusEducation())
  const {data: institutions} = useQuery('GetI', async () => await getInstitution())
  const [insitutionSelected, setInstitutionSelected] = useState('')
  const [programselected, setProgramsSelected] = useState<Program[]>()

  if (insitutionSelected) {
    const fetchPrograms = async () => {
      const response = await getPormgramsById(insitutionSelected)
      if (response) {
        console.log('entre aqui', response)
        setProgramsSelected(response as any)
      }
    }

    fetchPrograms().then(() => {
      setInstitutionSelected('')
    })
  }

  console.log('ediatpalication', editAplication)
  const [formData, setFormData] = useState({
    id: '',
    user: '',
    statuseducation: '',
    program: '',
  })

  useEffect(() => {
    if (editAplication) {
      setFormData({
        id: editAplication.id,
        user: editAplication.user.id ? editAplication.user.id : '',
        statuseducation: editAplication.statuseducation.id ? editAplication.statuseducation.id : '',
        program: editAplication.program.programId ? editAplication.program.programId : '',
      })
    }
  }, [editAplication])

  const handleChange = (e: {target: {name: any; value: any}}) => {
    console.log('hola', e.target)
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()

    try {
      /* const response = await axios.patch(
        `${API_URL}/institution/updateInstitution/${editInstitution.institutionId}`,
        formData
      ); */
      const response = await updateAplication(editAplication.id, formData)

      console.log('Institution updated:', response)
      onSave()
    } catch (error) {
      console.error('Error updating institution:', error)
    }
  }
  const changeInstitutionSelected = (id: string) => {
    setInstitutionSelected(id)
  }

  return (
    <div className='form-container1'>
      <form onSubmit={handleSubmit} className='custom-form1'>
        <div className='form-group1'>
          <label className='label'>Client:</label>
          <input className='input' type='text' name='name' value={editAplication.user.email} />
        </div>
        <div className='form-group1'>
          <label className='label'>Status:</label>
          {/*  <input className="input" type="text" name="status" value={editAplication.statuseducation.name} /> */}
          {statused && editAplication.statuseducation.id ? (
            <select
              className='input'
              name='statuseducation'
              defaultValue={editAplication.statuseducation.id}
              value={formData.statuseducation}
              onChange={(event) => {
                handleChange(event)
              }}
            >
              <option value=''>Select an Status educations</option>
              {statused.map((status: any) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          ) : (
            <p>Cargando status...</p>
          )}
        </div>
        {/* <div className="form-group1">
          <label className="label">Institution Previa:</label>
          <input className="input" type="text" value={formData.program.institution.name} />

        </div> */}

        <div className='form-group1'>
          <p>Universidad Previa: {editAplication.program.institution.name}</p>
          <p>Programa Previo: {editAplication.program.name}</p>
        </div>
        <label className='label'>Institution Nueva:</label>
        <select
          className='input'
          onChange={(event) => {
            console.log('target', event.target)
            /*  handleChange(event); */
            changeInstitutionSelected(event.target.value)
          }}
        >
          <option value=''>Select an Institution</option>
          {institutions?.map((institution: Institution) => (
            <option key={institution.institutionId} value={institution.institutionId}>
              {institution.name}
            </option>
          ))}
        </select>

        <div className='form-group1'>
          <label className='label'>Programa Nuevo:</label>
          {programselected && programselected.length > 0 ? (
            <select
              className='input'
              name='program'
              value={formData.program}
              onChange={handleChange}
            >
              <option value=''>Select an program</option>
              {programselected.map((program: Program) => (
                <option key={program.programId} value={program.programId}>
                  {program.name}
                </option>
              ))}
            </select>
          ) : (
            <>
              {programselected && programselected.length === 0 ? (
                <p>La institucion seleccionada no tiene programas</p>
              ) : (
                <p>Cargando programas...</p>
              )}
            </>
          )}
        </div>
        <button type='submit' className='submit-button1'>
          Guardar cambios
        </button>
      </form>
    </div>
  )
}

export default EditAplicationForm
