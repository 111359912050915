/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */


import {InputEditable, Link} from '../../../../components'
import {Button, DatePicker, Input, InputRef, Space, Switch, Table, Tag} from 'antd'
import {
  type IQueryParams,
  type Translations,
  type TranslationsPartial,
} from '../../../../app/modules/apps/translations/core/_models'
import {LinkOutlined, SearchOutlined, UploadOutlined} from '@ant-design/icons'
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from 'react'
import {AiOutlineMail} from 'react-icons/ai'
import {IReply, type IComposeData} from '../../../../app/modules/apps/appointments/core/_models'
import ModalLoader from '../../../../core/components/ModalLoader/index'
import {type Priority} from '../../../../app/modules/apps/priority/core/_models'
import {RootStoreContext} from '../../../../stores/rootStore'
import {type StatusTag} from '../../../../app/modules/apps/statusTag/core/_models'
import StatusTagModule from '../../../../app/pages/statusTag/StatusTagModule'
import Swal from 'sweetalert2'
import {TimeZone} from '../../../../app/modules/utils/components/TimeZone'
import moment, {Moment} from 'moment'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import Column from 'antd/lib/table/Column'
import Highlighter from 'react-highlight-words'
import {ColumnType} from 'antd/lib/table'
import {ColumnFilterItem} from 'antd/lib/table/interface'
import {useQuery} from 'react-query'
import {getStatusTag} from 'app/modules/apps/statusTag/core/_requests'
import {getProductsTableFilter} from 'app/modules/apps/cart/core/products/_requests'
import {RangeValue} from 'rc-picker/lib/interface'
import image from '../admin/image/avatar.svg'
import '../admin/style.css'
import notes from '../admin/image/notes.svg'
import NotesModalTranslation from 'app/pages/admin/users/List/SlideTranslations'
import NotesModal from 'app/pages/admin/users/List/Slide'
import { sendEmailWithLink } from 'app/modules/apps/users/core/_requests'
const querysParam: IQueryParams = {
  page: 1,
  limit: 50,
  staffId: 'staff',
}

interface IProps {
  setTranslation: (b: boolean) => void
  setUploadFiles: (b: boolean) => void
  setList: (b: boolean) => void
  setTranslate: (b: string) => void
  setComposePostData: React.Dispatch<React.SetStateAction<IReply>>
  setComposePost: (b: boolean) => void
  setTabNumber: Dispatch<SetStateAction<string>>
}

const ListTranslationsStaff: React.FC<IProps> = ({
  setTranslation,
  setTranslate,
  setList,
  setComposePost,
  setComposePostData,
  setTabNumber,
}) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {
    translations,
    translationsTotal,
    isLoadingTranslation,
    loadTranslations,
    updateFieldTranslation,
  } = rootStore.translationStore
  const searchInput = useRef<InputRef>(null)

  type DataIndex = keyof Translations

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [currentTranlation, setCurrentTranlation] = React.useState<Translations>()
  const [priority, setPriority] = React.useState<Priority>()
  const [statusTag, setStatusTag] = React.useState<StatusTag>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentEmail, setCurrentEmail] = useState('')
  const [statusInfo, setStatusInfo] = useState<ColumnFilterItem[]>()
  const [productNameInfo, setProductNameInfo] = useState<ColumnFilterItem[]>()
  const [currentStatus, setCurrentStatus] = useState<number[]>()
  const [currentProductName, setCurrentProductName] = useState<string[]>()
  const [currentendDueDate, setCurrentEndDueDate] = useState<Moment[] | null[]>()
  const [fromDateEndDue, setFromDateEndDue] = useState<moment.Moment | null>(null)
  const [toDateEndDue, setToDateEndDue] = useState<moment.Moment | null>(null)
  const {data: dataStatus} = useQuery('gettingStatus', async () => await getStatusTag())
  const {data: dataProducts} = useQuery('getProducts', async () => await getProductsTableFilter())
  const [note, setNote] = useState<string>('')
  const [edit, setEdit] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState<any>()
  const [idSelected, setIdSelected] = useState<string>('')
  const [translationId, setTranslationId] = useState<string>('');
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const USERS_URL = `immiland.app`
  const handleSendFeedbackEmail = async (record: any) => {
    console.log('Entrando en handleSendFeedbackEmail - record:', record);

    if (record && record.id) {
      try {
        const link = `${USERS_URL}/services/satisfaction/${record.id}`;
        await sendEmailWithLink(record.email, link);
        console.log('Correo enviado con éxito');
      } catch (error) {
        console.error('Error al enviar el correo:', error);
      }
    } else {
      console.error('Error: Record or record.id is undefined');
    }
  };

  const handleCreate = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    loadTranslations({
      page: currentPage,
      limit: 50,
      email: currentEmail,
      status: currentStatus,
      productName: currentProductName,
      endDate: currentendDueDate,
      staffId: user?.email!,
    })
  }, [
    currentPage,
    currentEmail,
    currentStatus,
    currentProductName,
    currentendDueDate,
    loadTranslations,
    user?.email,
  ])

  useEffect(() => {
    setStatusInfo(
      dataStatus?.map((item: any) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
    )

    setProductNameInfo(
      dataProducts?.data.map((item: any) => {
        if (item.name !== undefined && item.name.toLowerCase().includes('traduccion')) {
          return {
            text: item.name,
            value: item.name,
          }
        } else {
          return {
            text: '',
            value: '',
          }
        }
      })
    )
  }, [translations, dataStatus, dataProducts])
  const handleChangeStatusTag = async (statusTag: StatusTag, id: string) => {
    const translation: TranslationsPartial = {
      statusTag: {id: statusTag.id},
      isFinished: statusTag.id === '5',
    }

    const updated = updateFieldTranslation(translation, id)
    if (!updated) {
      Swal.fire('Error', 'Error updating translation', 'error')
    }
  }
  useEffect(() => {
    setStatusInfo(
      dataStatus?.map((item: any) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
    )
  }, [translations])

  const handleNote = async (value: string, translationId: string) => {
    const translation: TranslationsPartial = {
      note: value,
    }
    await updateFieldTranslation(translation, translationId)
    setEdit(false)
  }

  const handleEndDue = async (value: any, translationId: string) => {
    try {
      if (value) {
        const endDue = value.format('DD-MM-YYYY')

        const translation: TranslationsPartial = {
          endDue: `${endDue} 23:59:59`,
        }

        const updated = await updateFieldTranslation(translation, translationId)
        if (updated != null) {
          loadTranslations(querysParam)
        }
      }
    } catch (error) {}
  }

  const handleRow = (value: any) => {
    setCurrentTranlation(value)
  }

  const handleShowFiles = (translationId: string, filesResultUploaded: string) => {
    setTranslation(true)
    setTranslate(translationId)
    setList(false)
  }

  const handleOpenPost = (data: any) => {
    setComposePost(true)
    setComposePostData(data)
  }

  const handlePagesTranslated = async (value: string, translationId: string) => {
    if (parseInt(value) > 0) {
      const translation: TranslationsPartial = {
        pagesTranslated: parseInt(value),
      }
      await updateFieldTranslation(translation, translationId)
    }
  }

  const handleSearchCustom = (type: string, value: string) => {
    setCurrentPage(1)
    if (type === 'email') {
      setCurrentEmail(value)
    }
  }
  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<Translations> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setCurrentEmail('')
            }}
            size='small'
            style={{width: 90}}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              /*  setSearchedColumn(dataIndex) */
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: Translations) =>
      record
        .email!.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const onChange = async (pagination: any, filters: any) => {
    setCurrentPage(pagination.current)
    if (currentStatus?.length === undefined || currentStatus.length === 0) {
      setCurrentStatus(filters.statusTag)
    } else if (currentStatus?.length > 0) {
      setCurrentStatus(currentStatus)
    }
    if (currentProductName?.length === undefined || currentProductName.length === 0) {
      setCurrentProductName(filters.productName)
    } else if (currentProductName?.length > 0) {
      setCurrentProductName(currentProductName)
    }
  }

  const handleDateChangeEndDue = (dates: RangeValue<moment.Moment>, type: string) => {
    console.log(dates, type)
    if (dates && type === 'endDue') {
      setFromDateEndDue(dates[0])
      setToDateEndDue(dates[1])
    }
  }
  const handleSearchDatePickerEndDue = (
    fromDate: Moment | null,
    toDate: Moment | null,
    type: string
  ) => {
    if (type === 'endDue') {
      setCurrentPage(1)
      setCurrentEndDueDate([fromDateEndDue!, toDateEndDue!])
    }
  }
  return (
    <>
      {' '}
      <Link to='/admin/translate'>
        <button
          className='button2'

        >
          Regresar
        </button>
      </Link>
      {isLoadingTranslation ? (
        <div>
          <ModalLoader />
        </div>
      ) : null}
      {(currentStatus && currentStatus.length > 0) ||
      (currentProductName && currentProductName.length > 0) ? (
        <>
          {' '}
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentStatus && currentStatus.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentStatus([])
                  }}
                >
                  <p>Status</p> <p>X</p>
                </Button>

                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentStatus.map((item) => {
                    if (item === 1) {
                      return 'Pending assignation'
                    }
                    if (item === 2) {
                      return 'Assigned'
                    }
                    if (item === 3) {
                      return 'In Progress'
                    }
                    if (item === 4) {
                      return 'Stuck'
                    }
                    if (item === 5) {
                      return 'Completed'
                    }
                    if (item === 6) {
                      return 'Waiting for the client'
                    }
                    if (item === 7) {
                      return 'Pending Payment'
                    }
                    if (item === 8) {
                      return 'Pending documents'
                    }
                    if (item === 9) {
                      return 'REFUNDED'
                    }
                    return ''
                  })}
                  readOnly
                ></input>
              </div>
            )}

            {currentProductName && currentProductName.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentProductName([])
                  }}
                >
                  <p>Product Name</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentProductName?.toString()}
                  readOnly
                ></input>{' '}
                {/* {currentCountryOfOrigin.map((item) => item + " ")} */}
              </div>
            )}
          </div>
        </>
      ) : null}
      <Table
        className='table-master my-custom-table2'
        style={{overflow: 'auto', position: 'relative'}}
        scroll={{x: 'max-content', y: 500}}
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: translationsTotal || 0,
        }}
        dataSource={translations}
        onChange={onChange}
      >

      {/*   <Column
          title={intl.formatMessage({id: 'TRANSLATION.CLIENT'})}
          render={(record: any) => (
            <Space
              style={{
                width: '150px',
              }}
            >
              {record.userName}
            </Space>
          )}
        /> */}
        <Column
          width={40}
       className='image-avatar'
          key='Avatars'
          render={(record: any) => (
            <>

         {  record.photoId !== null  ? <img style={{borderRadius:'999px',width:'35px', height:'35px', marginLeft:'10px'}} src={`//immilandpublic.nyc3.digitaloceanspaces.com/${record?.photoId}-avatar-large.jpg`}/> : <img style={{borderRadius:'999px',width:'35px', height:'35px'}} src={image}/>  }


            </>
          )}
        />
       <Column
          width={200}
          title='CLIENTE'
          dataIndex={'email'}
          key='email'
          {...getColumnSearchPropsEmail('email')}
          render={(email: string, record: any) =>      <div style={{display:'flex', flexDirection:'column', marginBottom:'15px'}}>   <b style={{margin:'0', height:'1.5rem'}}>{record.userName ?.length > 32 ? <>{record.userName.substring(0, 32)}...</> : <>{record.userName}</>}</b>      <p style={{ margin: '0' }}>{email ?.length > 32 ? <>{email.substring(0, 32)}...</> : <>{email}</>}</p></div>} />



         <Column
          width={120}
          title='TRADUCCIÓN'
          key='productName'
          render={(record: any) => (
            
            <Space>
              {record.productName}
            </Space>
          )}
          filters={productNameInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record.productName === value.toString()
          }}
        />
        <Column
         width={120}
          render={(record: any) => (
            <Space>
              <Button
                style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                /* outline */
                onClick={() => {
                  handleShowFiles(
                    record.id ? record.id : '',
                    record.filesUploaded ? record.filesUploaded : ''
                  )
                }}
                className='text-primary'
              >
                {record.filesUploaded ? (
                  <>
                    <LinkOutlined  style={{marginTop: '1px' , marginRight:'-30px'}} />
                   Archivos
                  </>
                ) : null}
              </Button>
            </Space>
          )}
        />
        <Column
          width={100}
          title='VENCE EL'
          filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: '30px'}}>
              <DatePicker.RangePicker
                value={[fromDateEndDue, toDateEndDue]}
                onChange={(value) => {
                  handleDateChangeEndDue(value, 'endDue')
                }}
              />
              <div>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleSearchDatePickerEndDue(fromDateEndDue!, toDateEndDue!, 'endDue')
                  }}
                  size='small'
                  style={{width: 90, marginRight: 8}}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDateEndDue(null)
                    setToDateEndDue(null)
                    setCurrentEndDueDate([null, null])
                  }}
                  size='small'
                  style={{width: 90}}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
          render={(record: any) => (
            <Space
              style={{
                width: '150px',
              }}
            >
              <DatePicker
                style={{width: '6.2rem'}}
                onChange={async (date: any) => {
                  await handleEndDue(date, record.id ? record.id : '')
                }}
                value={record.endDue ? moment(record.endDue).utc() : null}
                suffixIcon={null}
              />
            </Space>
          )}
        />



        <Column
          title='CREADO EL'
          render={(record: any) => (
            <Space
              style={{
                width: '150px',
              }}
            >
              <TimeZone date={record.created_at ? record.created_at : null} />
            </Space>
          )}
        />
             <Column
        width={80}
        title='EXPRESS'
          render={(record: any) => (
            <Space>
              <Switch defaultChecked={record.express ? record.express : false} disabled />
            </Space>
          )}
        />

            <Column
          width={100}
          title='N° PAGINAS'
          render={(record: any) => <p style={{paddingLeft:'30px'}}>{record.pages}</p>}
        />
            <Column
  width={140}
  title='P. TRADUCIDAS'
  render={(record: any) => (
    <Space>
      <input
        className="form-control"
        type='number'
        value={record.pagesTranslated ? record.pagesTranslated.toString() : '0'}
        onChange={async (event) => {
          const value = event.target.value;
          await handlePagesTranslated(value, record.id ? record.id : '');
        }}
      />
    </Space>
  )}
/>



    <Column
          title='ESTADO'
          key='statusTag'
          filters={statusInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          render={(record: any) => (
            <Space
              style={{
                width: '150px',
              }}
            >
              <StatusTagModule
                setStatusTag={(statusTag: StatusTag) => {
                  handleChangeStatusTag(statusTag, record.id || '')
                } }
                /*  setStatusTag={setStatusTag} */
                defaultValue={record.status ? record.status : ''}
                defaultColor={record.statusColor ? record.statusColor : ''}
                eneable={true}handleSendFeedbackEmail={handleSendFeedbackEmail} record={record}           />
            </Space>
          )}
        />

       {/*  <Column
          width={80}
          title={'NOTA'}
          render={(record: any) =>
            edit === false ? (
              <>
                <Space >
                  {record.note && <textarea value={record.note} className='ant-input' readOnly />}
                  <Button
                    onClick={async (e) => {

                      setEdit(true)
                      setIdSelected(record.id)
                    }}
                  >
                    {record.note ? 'Editar' : 'Crear'}
                  </Button>
                </Space>
              </>
            ) : (
              <>
                {record.id === idSelected && (
                  <Space >
                    <div style={{width: '150px'}}>
                      <textarea
                        style={{color: 'black'}}
                        defaultValue={record.note ? record.note : ''}
                        className='ant-input'
                        onChange={async (e) => {
                          setNote(e.target.value)
                        }}
                      />
                    </div>
                    <Button
                      onClick={async (e) => {
                        await handleNote(note, record.id ? record.id : '')
                      }}
                    >
                      Guardar
                    </Button>
                  </Space>
                )}
              </>
            )
          }
        /> */}
      <Column
          width={50}
          title='NOTAS'
          render={(record: any) => (
            <button
              onClick={(event) => {
                // Este es el clic del botón interno
                event.stopPropagation(); // Evita la propagación del evento al botón externo
                setIsModalVisible(true);
                setUserInfo(JSON.parse(JSON.stringify(record.userId)));
                setTranslationId(record.id); 
               /*  console.log('este es user info' + JSON.stringify(record));
                console.log(record); */
              }}
            >
              <img src={notes} alt="Notas" />
            </button>
          )}
        />
        <Column
        width={80}
          render={(record: any) => (
            <Space size='middle'>
              <Button
                style={{background: 'white', border: '1px solid #FEA102'}}
                onClick={() => {
                  setTabNumber('1')
                  handleOpenPost({
                    orderDetailId: record.orderDetailId ? record.orderDetailId : '',
                    user: `${record.userName} `,
                    email: record.email ? record.email : '',
                    userId: record.userId ? record.userId : '',
                    fromId: record.userTranslationId ? record.userTranslationId : '',
                    messageId: null,
                    fromUser: record.userTranslationId ? record.userTranslationId : '',
                    folder: 'inbox',
                    draft: false,
                    favorite:false,
                    files_info:[],
                    unread: false,
                  })
                }}
              >
                <AiOutlineMail />
              </Button>
            </Space>
          )}
        />
      </Table>
      {isModalVisible === true ? <NotesModalTranslation
        userInfo={userInfo}
        visible={isModalVisible}
        onCancel={handleCancel}
        onCreate={handleCreate}
        products={[]}
        translationId={translationId}
      /> : null}
    </>
  )
}

export default observer(ListTranslationsStaff)
