/* eslint-disable @typescript-eslint/no-unused-vars */
import './styles.css'
import {Document, Page, pdfjs} from 'react-pdf'
import {type FC, useEffect, useState} from 'react'
import {AiOutlineCloudDownload} from 'react-icons/ai'
import {Button} from '../../../components'
import fileDownload from 'js-file-download'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface PdfViewerProps {
  url: any
  name: string
  getNumPages?: (numPages: number) => void
}

const PdfViewer: FC<PdfViewerProps> = ({url, name, getNumPages}) => {
  const [numPages, setNumPages] = useState(1)
  const downloadPdfFile = (url: any) => {
    console.log('🚀 ~ file: PdfViewer.tsx ~ line 17 ~ downloadPdfFile ~ url', url)
    fileDownload(url, name)
  }

  useEffect(() => {
    console.log('🚀 ~ file: PdfViewer.tsx:28 ~ numPages', numPages)
    // getNumPages && getNumPages(numPages)
  }, [numPages])

  return (
    <>
      <div>
        <Button
          onClick={() => {
            downloadPdfFile(url)
          }}
          width={'w-8'}
          info={true}
          style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
        >
          <AiOutlineCloudDownload />
        </Button>
      </div>
      <Document
        file={url}
        onLoadError={(e: any) => {
          console.log(`Error ${e}`)
        }}
        onLoadSuccess={({numPages}: any) => {
          setNumPages(numPages)
        }}
        /*  options={{
          standardFontDataUrl: '/standard_fonts/',
        }} */
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={800} />
        ))}
      </Document>
    </>
  )
}

export default PdfViewer
