/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react"

import { RootStoreContext } from "stores/rootStore"
import { useNavigate } from 'react-router-dom'
import bell from '../userDasboardComponents/userDashboardIcons/bell-alt.svg'
import greenStart from '../userDasboardComponents/userDashboardIcons/GreenStart.svg'
import arrowWhite from '../userDasboardComponents/userDashboardIcons/arrow-right-white.svg'
import arrowBlack from '../userDasboardComponents/userDashboardIcons/arrow-right-black.svg'
import Eclipse from '../userDasboardComponents/userDashboardIcons/Ellipse 2797.png'
import User from '../userDasboardComponents/userDashboardIcons/users-2.svg'
import Plane from '../userDasboardComponents/userDashboardIcons/Plane-Blue.svg'
import Graduate from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg'
import Birrete from '../userDasboardComponents/userDashboardIcons/BirreteRosa.svg'
import Houseonly from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Houseonly.svg'
import UsersIcon2 from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg'
import { getNotificationsByUserId, getUserById } from "app/modules/apps/appointments/core/_requests"

/* otros */

const othersProducts = [
  '8dcb5115-bdce-45e3-8b50-d2d592020713', 'f9fe4a37-7284-4bf7-a22e-f0f12678bb99', '662a5e18-340f-41e6-830a-10576ead3115'
]

const othersProductsMap: { [key: string]: string } = {
  '8dcb5115-bdce-45e3-8b50-d2d592020713': 'Bio',
  'f9fe4a37-7284-4bf7-a22e-f0f12678bb99': 'F-Bio',
  '662a5e18-340f-41e6-830a-10576ead3115': 'SL',
}

/* VISITA */

const visitProducts = [
  'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
  '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
  'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
]

const visitProductsMap: { [key: string]: string } = {
  'a10980f2-57f3-43c7-b863-a995915389de': 'VVD',
  '3f056a93-1f4b-4598-9aec-f929ecd00e97': 'eTA',
  '7664135d-5b88-4640-8bf1-6f56d7f2a78a': 'Super visa',
  'daa261eb-6263-4b6e-acbb-4343bd4ce35a': 'transit',
  'cbdf9710-7c51-49b9-9e17-ced12c7c85c9': 'VR',
  'b0e0313b-6344-4733-90a0-6d6031f8be1e': 'VV',
  '41669177-9782-4052-bb8d-08b6c29c928f': 'VV(Study less 6)',
  'b9ada976-a4fe-444d-9f6a-dd58c0928120': 'VR(Dependent)',
}

/* ESTUDIO */

const studyProducts = [
  '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427',
  '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
  '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
]

const studyProductsMap: { [key: string]: string } = {
  '94726637-76e7-438b-9494-99b136bbc7ea': 'SPd',
  'bb4056fb-2440-4a06-aec1-8d13349e7427': 'SPd(IC)',
  '8327593a-cc82-4703-afaa-72030f67c001': 'SP',
  'f9b3dcd2-b420-4cc0-b5df-575f9a5be911': 'SDS',
  '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800': 'SP(IC)',
  '32549570-adf0-4add-ad43-5c49fed6566d': 'SP(language)',
  '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c': 'SPd(IC language)'
}

/* Residence */

const residenceProducts = [
  '6f317d28-cab2-4316-9a25-3f62e4e047e6',
  '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
  '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
  'd1195212-1cdc-4792-b88b-824922f63c36', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
  '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', '1ec2dc58-5776-49d3-b487-f373ffd1e0a3',
]

const residenceProductsMap: { [key: string]: string } = {
  '6f317d28-cab2-4316-9a25-3f62e4e047e6': 'Citizenship',
  '8b5628bb-15a8-44a9-a557-00d4f600cd1d': 'CSQ',
  '3320f244-f338-43d0-8006-503003d16501': 'CSQ(dependent)',
  'b58daf73-88be-41ee-8a0d-9e485748ced8': 'CSQ(spouse/partner)',
  '7ac7912e-9514-4281-924b-1a36a4dd3065': 'Express Entry(dependent)',
  '9c1f03d1-5100-4a50-8030-cfb8ebad4480': 'Express Entry(AM)',
  '48b466b6-d8e1-48f8-ae32-2877760f92b7': 'Express Entry(EI phase 1)',
  'd1195212-1cdc-4792-b88b-824922f63c36': 'Express Entry(IA phase 2)',
  '7d8df96c-dd71-48aa-b0f7-301e59706212': 'PNE(main applicant phase 1)',
  '03030060-aa44-452a-ad54-80fedf29a547': 'PNP',
  '52e5a255-5894-4f81-9e4b-7a59451ed83d': 'Express Entry(EI Spouse/Partner phase 1)',
  '5848c9c4-bfc9-49b0-881d-9cab020dd1e5': 'Express Entry(IA Spouse/Partner phase 2)',
  '1ec2dc58-5776-49d3-b487-f373ffd1e0a3': 'TRV',
}

/* SECCION DE TRABAJO */

const workProducts = [
  'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
  '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
  'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
  'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
  '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
  'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', '6420335e-30aa-46c5-892b-e1fa23914733',
  '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac', '229d7b91-7a9b-407a-988b-9e45096bff5b',
  'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994', 'c6df5f52-670d-4d7f-809e-72cb59fa684a',
  '8a29f12d-a898-4d52-8fd1-f184094cd8c3', 'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
  'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
]

const workProductsMap: { [key: string]: string } = {
  'd60d6643-8cee-4df2-bb67-ce10b40f6f61': 'LMIA Exempt',
  '41b1a433-5900-4b96-85f3-7e2c8fce0a41': 'LMIA Exempt(PE)',
  '50eb5404-9ae2-4655-91ae-89d2e8a6fa73': 'LMIA Exempt(Flagpole)',
  '19ca7bf2-6514-478b-bb78-c7861619fe39': 'LMIA Exempt(IC)',
  'e377ed33-a53a-4312-8842-1a689237b07c': 'LMIA Exempt',
  '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b': 'LMIA exempt(PE)',
  'e8e477d1-f126-4a64-93da-539b3715730d': 'LMIA positive',
  '30a323ab-236c-461c-93ea-28308c8fafa6': 'LMIA positive(PE)',
  '08e1143b-4f7f-48db-a289-9a3fc40f8037': 'LMIA positive(flagpole)',
  'aa7e79b8-2219-4b68-aa98-5a212c5311fa': 'LMIA positive(IC)',
  'a645705f-f9f7-47c6-9319-5a1026da84d2': 'LMIA positive',
  '6e420420-d96c-4cb8-a145-95a7964ed618': 'LMIA positive(PE)',
  '9d3ab240-2e68-42b8-a7d6-88e22e9fe095': 'CO-OP',
  '9015fb11-d513-4f6e-9de9-aab92d5d2b42': 'IEC',
  '4f050e6f-e9eb-4ee0-b12c-465d2d715c16': 'IEC',
  'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c': 'LMIA exemption',
  '0dac7746-02c0-47db-be28-14755d62a64d': 'LMIA Federal',
  '6420335e-30aa-46c5-892b-e1fa23914733': 'LMIA Quebec',
  '52096218-da31-4e01-a406-0e06a9ca4d07': 'OWP Vulnerable Worker',
  'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac': 'OWP(MA)',
  '229d7b91-7a9b-407a-988b-9e45096bff5b': 'OWP(Special Mesure Opt-in)',
  'c4bb7c15-0e15-487f-b161-21d0426d82f9': 'PGWP',
  'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994': 'PGWP(PE)',
  'c6df5f52-670d-4d7f-809e-72cb59fa684a': 'PGWP',
  '8a29f12d-a898-4d52-8fd1-f184094cd8c3': 'Sponsorship',
  'c8d8dce1-7959-4271-92c9-ddd6bbda35d4': 'Sponsorship',
  '1d2b991b-7d75-4127-99d8-19f884efcfd0': 'OWP Spouse/Partner',
  'd65141bb-58c1-46f1-a1c7-d1132f64796f': 'OWP Spouse/Partner',
  'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f': 'OWP Spouse/Partner',
  'b6f813c5-0911-4b86-be17-0e74bc332316': 'Spouse/Partner RSWP(Phase 1)'
}

const IntakeNotifications: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [notifications, setNotifications] = useState<any[]>([])
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const navigate = useNavigate()
  const { selectUser } = rootStore.usersStore
  const [selected, setSelected] = useState<any>()

  useEffect(() => {
    const fetchNotifications = async () => {
      if (user?.id) {
        const data = await getNotificationsByUserId(user.id)
        setNotifications(data)
      }
    }
    fetchNotifications()
  }, [user?.id])

  const hasAny = (productIds: string[], products: string[]): boolean => {
    return products.some(product => productIds.includes(product))
  }

  const countUniqueMemberTypes = (applicants: any[]): number => {
    const memberTypes = new Set(applicants.map(applicant => applicant.memberType))
    return memberTypes.size
  }

  const handleViewProcess = async (notification: any) => {
    const user = await getUserById(notification.user_id)
    if (user) {
      setSelected(notification)
      selectUser(user)
      navigate('/userslist')
    }
  }

  return (
    <>
      <div className='w-full flex flex-col gap-4'>
        <div className="card-title flex items-center gap-2">
          <img src={bell} alt="bell" />
          <h1>Notificaciones de expedientes asignados</h1>
        </div>
        <p style={{ fontSize: '16px', margin: '0' }}>
          <b>Tienes {notifications.length} notificaciones</b> sin atender
        </p>
        {notifications.map((notification, index) => {
          const applicants = JSON.parse(notification.applicants)
          const productIds = applicants.map((applicant: { productId: any }) => applicant.productId)
          const hasVisitProduct = hasAny(productIds, visitProducts)
          const hasStudyProduct = hasAny(productIds, studyProducts)
          const hasWorkProduct = hasAny(productIds, workProducts)
          const hasResidenceProduct = hasAny(productIds, residenceProducts)
          const hasSpecificProduct = hasAny(productIds, othersProducts)
          const uniqueMemberTypeCount = countUniqueMemberTypes(applicants)
          return (
            <div  key={index} className="notification-tag-inside">
              <div>
                <div className="flex justify-between">
                  <div className="flex gap-4 items-center">
                    <img src={greenStart} alt="greenStart" />
                    <div className="flex gap-2">
                      <p style={{ fontSize: '13px', margin: '0' }}>{notification.department}</p>
                      <p style={{ fontSize: '13px', margin: '0' }}>asignó un nuevo expediente</p>
                    </div>
                  </div>
                  <p style={{ fontSize: '11px', margin: '0', color: '#637381' }}>
                    {new Date(notification.notification_created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className=" w-full flex justify-between items-center mt-2">
                <div className="notification-data-tag">
                  <p style={{ fontSize: '11px', margin: '0', textTransform: 'uppercase', letterSpacing: '.02rem' }}>Ctto n° {notification.contract_id}</p>
                  <div className="w-full flex" style={{alignItems:'stretch', justifyContent:'flex-start'}}> 
                    <div className="flex gap-2 items-center"
                      style={{ borderRight: '1px solid #D1D5DB', paddingRight: '1rem', flex: '1' }}>
                      {notification.photoId ? (
                        <img style={{ borderRadius: '999px', width: '35px', height: '35px' }} src={`//immilandpublic.nyc3.digitaloceanspaces.com/${notification.photoId}-avatar-large.jpg`} alt="Avatar" />
                      ) : (
                        <img style={{ borderRadius: '999px', width: '35px', height: '35px' }} src={Eclipse} alt="Eclipse" />
                      )}
                      <p style={{ fontSize: '12px', margin: '0' }}>{notification.first_name}</p>
                    </div>
                    <div className="flex gap-2 items-center flex-wrap" style={{ paddingLeft: '5px', marginTop:'0.5rem', flex: '2' }}>
                      <div className="product-badge personas" >
                        <img src={User} alt="user" />
                        <span>{uniqueMemberTypeCount}</span>
                      </div>
                      {hasVisitProduct && (
                        (() => {
                          const displayedIds = new Set<string>(); // Conjunto para rastrear IDs mostrados
                          return Array.from(productIds)
                            .filter((id: any) => {
                              // Verifica que el id no haya sido mostrado antes
                              const isDisplayed = displayedIds.has(id);
                              if (!isDisplayed && visitProductsMap[id]) {
                                displayedIds.add(id);
                              }
                              return !isDisplayed && visitProductsMap[id];
                            })
                            .map((id: any) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#01A9DB',
                                //   borderRadius: '3px',
                                //   gap: '2px'
                                // }}
                                className="product-badge tourism"
                                >
                                <img style={{height:'27px', width:'27px'}} src={Plane} alt="Plane" />
                                <span >
                                  {visitProductsMap[id]}
                                </span>
                              </div>
                            ));
                        })()
                      )}
                      {hasStudyProduct && (
                        (() => {
                          const displayedIds = new Set<string>();
                          return Array.from(productIds)
                            .filter((id: any) => {
                              // Verifica que el id no haya sido mostrado antes
                              const isDisplayed = displayedIds.has(id);
                              if (!isDisplayed && studyProducts[id]) {
                                displayedIds.add(id);
                              }
                              return !isDisplayed && studyProducts[id];
                            })
                            .map((id: any) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#EF5DA8',
                                //   borderRadius: '3px',
                                //   gap: '2px'
                                // }}
                                className="product-badge study"
                                >
                                <img src={Graduate} alt="Graduate" />
                                <span >
                                  {studyProductsMap[id]}
                                </span>
                              </div>
                            ));
                        })()
                      )}
                      {hasWorkProduct && (
                        (() => {
                          const displayedIds = new Set<string>();
                          return Array.from(productIds)
                            .filter((id: any) => {
                              // Verifica que el id no haya sido mostrado antes
                              const isDisplayed = displayedIds.has(id);
                              if (!isDisplayed && workProductsMap[id]) {
                                displayedIds.add(id);
                              }
                              return !isDisplayed && workProductsMap[id];
                            })
                            .map((id: any) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#22AD5C',
                                //   borderRadius: '3px',
                                //   gap: '2px',
                                 
                                // }}
                                className="product-badge work"
                              >
                                <img src={Birrete} alt="Briefcase" />
                                <span >
                                  {workProductsMap[id]}
                                </span>
                              </div>
                            ));
                        })()
                      )}
                      {hasResidenceProduct && (
                        (() => {
                          const displayedIds = new Set<string>();
                          return Array.from(productIds)
                            .filter((id: any) => {
                              // Verifica que el id no haya sido mostrado antes
                              const isDisplayed = displayedIds.has(id);
                              if (!isDisplayed && residenceProductsMap[id]) {
                                displayedIds.add(id);
                              }
                              return !isDisplayed && residenceProductsMap[id];
                            })
                            .map((id: any) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   borderRadius: '3px',
                                //   gap: '2px',
                                //   background: '#F27430',
                                // }}
                                className="product-badge residencia"
                                >
                                <img src={Houseonly} alt="Houseonly" />
                                <span>
                                  {residenceProductsMap[id]}
                                </span>
                              </div>
                            ));
                        })()
                      )}
                      {hasSpecificProduct && (
                        (() => {
                          const displayedIds = new Set<string>();
                          return Array.from(productIds)
                            .filter((id: any) => {
                              // Verifica que el id no haya sido mostrado antes
                              const isDisplayed = displayedIds.has(id);
                              if (!isDisplayed && othersProductsMap[id]) {
                                displayedIds.add(id);
                              }
                              return !isDisplayed && othersProductsMap[id];
                            })
                            .map((id: any) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#DFE4EA',
                                //   borderRadius: '3px',
                                //   gap: '2px'
                                // }}
                                className="product-badge biometrics"
                                >
                                <img src={UsersIcon2} alt="UsersIcon2" />
                                <span>
                                  {othersProductsMap[id]}
                                </span>
                              </div>
                            ));
                        })()
                      )}
                    </div>
                  </div>
                </div>
                <button  className="button-ui button-primary button-xs "
                  onClick={() => handleViewProcess(notification)}
                >
                  <p style={{ fontSize: '13px', margin: '0' }}>IR</p>
                  <img style={{ width: '20px' }} src={arrowWhite} alt="arrowWhite" />
                </button>
              </div>
            </div>
          )
        })}
        <div className="flex w-full justify-center items-center mt-8">
          <button
            className='button2'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate('/user/notify')}>
            <div className="flex gap-2">
              <b className='meet-text2'>VER PENDIENTES</b>
              <img
                alt="Calendar"
                className='meet-image2'
                src={isHovered ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'}
              />
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export { IntakeNotifications }
