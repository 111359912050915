import { getTempsByMatchId } from "app/modules/apps/contract/_requests";
import React, { useState } from "react";
import { useQuery } from "react-query";

interface Props {
  contractInfo: {
    contracts_users_data: {
      matchId: string;
    };
  };
  setShowAnswers: (show: boolean) => void;
}

const MyAnswers: React.FC<Props> = (props) => {
  const { data: temps, status } = useQuery('TEMPMATCH', async () => await getTempsByMatchId(props.contractInfo.contracts_users_data.matchId));

  if (status === 'success') {
   
  }

  const renderTableRows = (data: any) => {
    const parsedData = JSON.parse(data);
    const entries = Object.entries(parsedData);
    const filteredEntries = entries.filter(([ value]) => value !== 'si' && value !== 'no');
    const pairs = [];
    for (let i = 0; i < filteredEntries.length; i += 2) {
      pairs.push(filteredEntries.slice(i, i + 2));
    }

    return pairs.map((pair, index) => (
      <tr key={index}>
        {pair.map(([key, value]) => (
          <React.Fragment key={key}>
            <td style={{ color: '#0B76B7', fontWeight: 'bold', padding: '0.25rem' }}>{key}</td>
            <td style={{ color: '#000', fontWeight: '700', padding: '0.25rem' }}>{value as string}</td>
          </React.Fragment>
        ))}
      </tr>
    ));
  };

  const renderYesNoRows = (data: any) => {
    const parsedData = JSON.parse(data);
    const yesNoEntries = Object.entries(parsedData).filter(([ value]) => value === 'si' || value === 'no');

    return yesNoEntries.map(([key, value], index) => (
      <tr style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%' }} key={index}>
        <td style={{ color: '#0B76B7', fontWeight: 'bold', width: '70%', padding: '0.25rem', marginLeft: '30rem' }}>{key}</td>
        <td style={{ color: '#000', fontWeight: '700', width: '30%', padding: '0.25rem' }}>{value as string}</td>
      </tr>
    ));
  };

  const [openSections, setOpenSections] = useState<boolean[]>(Array(temps?.props?.length).fill(false));

  const toggleSection = (index: number) => {
    const updatedOpenSections = [...openSections];
    updatedOpenSections[index] = !updatedOpenSections[index];
    setOpenSections(updatedOpenSections);
  };

  return (
    <>
      <div className='header-container'>
        <button
          className='close-video-btn'
          style={{ width: '10rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}
          onClick={() => props.setShowAnswers(false)}
        >
          Volver
        </button>
      </div>
      <div className='main-data-container-applicant'>
        {temps?.props && temps.props.map((data: any, index: number) => (
          <div key={index} className='applicant-container'>
            <div
              className='applicant-header'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                border: openSections[index] ? 'none' : '1px solid #0B76B7',
                borderRadius: '10px',
                padding: '0.5rem',
                backgroundColor: '#D0F0FD66',
              }}
              onClick={() => toggleSection(index)}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0.5rem' }}>
                <p style={{ color: '#0B76B7', fontWeight: 'bold' }}>
                  {JSON.parse(data)['Tipo de familiar']}
                </p>
                <img
                  alt="Arrow"
                  src="/media/svg/menu-icons/chevron-down-circle.svg"
                  style={{
                    transform: openSections[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.1s ease',
                  }}
                />
              </div>
            </div>
            {openSections[index] && (
              <div className='applicant-table' style={{ padding: '1rem', border: 'none', marginTop: '0.5rem' }}>
                <table>
                  <tbody>
                    {renderTableRows(data)}
                  </tbody>
                </table>
                <hr style={{ border: '1px solid #0B76B7', margin: '1rem 0' }} />
                <table>
                  <tbody>
                    {renderYesNoRows(data)}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default MyAnswers;
