const ContainerLeft = () => {
  return (
    <div className=' h-full py-28 align-middle block'>
      <section className='mb-2 justify-center items-center m-auto '>
        <img
          src={'/media/newdesign/logo_simbolo.svg'}
          className='w-36 justify-center items-center m-auto'
          alt=''
        />
      </section>
      <section className='mb-5 justify-center items-center '>
        <img
          src={'/media/newdesign/slogan.svg'}
          className='w-60 justify-center items-center m-auto'
          alt=''
        />
      </section>
      <section className='justify-center items-center '>
        <img
          src={'/media/newdesign/all-logos.svg'}
          className='justify-center items-center m-auto'
          alt=''
        />
      </section>
    </div>
  )
}

export default ContainerLeft
