 
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useState } from 'react'
import { Button } from '../../../../../components'
import ListMessagesUser from '../../../../../app/pages/messages/UserMessages/ListMessagesUser'
import { RootStoreContext } from '../../../../../stores/rootStore'
import { observer } from 'mobx-react'
import { Tabs } from 'antd'
import { TabPane } from 'reactstrap'
import MessageContent from 'app/pages/messages/MessageContent'
import { IToUsers } from 'app/pages/messages/interfaces/ITOUsers'
import { useQuery } from 'react-query'
import { getUserUsersById } from 'app/modules/apps/users/core/_requests'
import { Users } from 'app/modules/apps/users/core/_models'

interface IProps {
  handleClose: (key: string) => void
  data: Users | undefined
}

const ShowEmailUser: React.FC<IProps> = ({ data }) => {


  const [cc] = useState<IToUsers[]>([])
  const [showmessage, setShowMessage] = useState(false)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [replyMessage, setReplyMessage] = useState(false)
  const { data: userData } = useQuery('getUserInfo', async () => await getUserUsersById(data && data.id ? data.id?.toString() : ''), {
    enabled: data !== undefined,
    cacheTime: 0,
  })

  return (
    <div>
      <ListMessagesUser userId={data?.id || ''} callRefetch={true} />
      {/*  <MessageContent
              setShowMessage={setShowMessage}
              setShowMessageRows={setShowMessageRows}
              setReplyMessage={setReplyMessage}
              composepostdata={{
                fromUser: '',
                orderDetailId: '',
                user: '',
                email: currentUser?.email || '',
                userId: currentUser?.id || '',
                fromId: '',
                messageId: '',
                folder: 'inbox',
                draft: false,
                favorite: false,
                files_info: [],
                unread: false,
              }}
              showSelectFiles={false}
              showShareFiles={false}
              initialContent={''}
              showTemplateSearch={false}
              initialSubject={''}
              type={'new_message'}
              category={''}
              cc={cc}
              handleClose={() => { }}
              showComposeTemplate={false}
            /> */}
    </div>
  )
}

export default observer(ShowEmailUser)
