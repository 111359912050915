/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { getFilesNew } from '../../../../app/modules/apps/file/core/_requests'
import { useQuery } from 'react-query'
import Filters from '../UserMessagesGeneral/Filters'
import '../../../pages/contratationApp/newUserViewContratationApp/style.css'
import './style.css'
import { Button } from 'components/Button'
import { RootStoreContext } from 'stores/rootStore'
import { RoleEnum } from 'auth/models/User/UserModel'
import { getImmigrationServices } from 'app/modules/apps/immigration/services/core/_requests'
import ReactPaginate from 'react-paginate'
import { downloadFileLibrary } from 'app/modules/apps/library/core/_requests'
import PdfViewerAndres from 'core/components/PdfViewer/pdfViewerAndres'

interface Props {
  setTargetFilesParent?: React.Dispatch<React.SetStateAction<string[]>> | undefined
  target: {}[]
  setTarget: React.Dispatch<React.SetStateAction<{}[]>>
}
interface IItems {
  key: string
  title: string
  description: string
}

const FileSelector: React.FC<Props> = (props: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [roadSelected, setRoadSelected] = useState<string>('')
  const [provinceSelected, setProvinceSelected] = useState<string>('')
  const { setTargetFilesParent, target, setTarget } = props
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [datasoruce, setDataSource] = useState([] as IItems[])
  const [targetkeys, setTargetKeys] = useState<string[]>([])
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [filter, setFilter] = useState('')
  const [hoveredItemId, setHoveredItemId] = useState(null)
  const rootStore = useContext(RootStoreContext)
  const { user, staff } = rootStore.authStore
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 5
  const [selectedFiles, setSelectedFiles] = useState<Record<string, boolean>>({})
  const [isHovered, setIsHovered] = useState(false)
  const pageCount = Math.ceil(datasoruce.length / itemsPerPage)
  const [isHovered2, setIsHovered2] = useState(false)
  const indexOfLastItem = (currentPage + 1) * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = datasoruce.slice(indexOfFirstItem, indexOfLastItem)
  const [pdfViewerModalOpen, setPdfViewerModalOpen] = useState(false)

  const downloadPDF = () => {
    try {
      const url = URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error al descargar el PDF', error)
      // Puedes manejar el error según tus necesidades
    }
  }
  const resetState = () => {
    setModalIsOpen(false)
    setRoadSelected('')
    setProvinceSelected('')
    setSelectedFiles({})
  }

  const handleViewer = async (item: any) => {
    try {
      const downloadedFile = await downloadFileLibrary(item.key, () => {
        // Comentario para desactivar eslint
      })

      setFile(downloadedFile)
      setFileName(item.title)  // Cambia 'documento.pdf' por el nombre que desees
      setPdfViewerModalOpen(true)
    } catch (error) {
      console.error('Error al cargar el PDF', error)
      // Puedes manejar el error según tus necesidades
    }
  }
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected)
  }

  const handleMouseEnter = (itemId: any) => {
    setHoveredItemId(itemId)
  }

  const handleMouseLeave = () => {
    setHoveredItemId(null)
  }

  const { data, refetch } = useQuery(
    'getfiles',
    async () =>
      await getFilesNew(
        `type=file-manager&page=1&limit=200&filter=${filter}&province=${provinceSelected}&road=${roadSelected}`
      )
  )

  const { data: ImmigrationServices, status } = useQuery(
    'ListAllImmigrationServicesSelect',
    async () => await getImmigrationServices({})
  )

  const provinces = [
    {
      name: 'Alberta',
    },
    {
      name: 'Atlantic',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'Federal',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
  ]

  useEffect(() => {
    if (data?.data != null) {
      const newOptions = data.data.map((item: any) => {
        const roadName = ImmigrationServices?.find((road: any) => road.name === item.road)?.name
        const provinceName = provinces.find((province: any) => province.name === item.province)?.name
        return {
          key: item.id,
          title: item.name,
          description: item.name,
          roadName: roadName || '', // Add roadName property
          provinceName: provinceName || '', // Add provinceName property
          originalname: item.originalname || '', // Add provinceName property
        }
      })
      setDataSource(newOptions)
      console.log('Objeto de datos:', data)
    }
  }, [data, ImmigrationServices,])

  useEffect(() => { }, [targetkeys])

  useEffect(() => {
    if (setTargetFilesParent != null) {
      const ids = target.map((item: any) => item.key)
      setTargetFilesParent(ids)
    }
  }, [target, setTargetFilesParent])

  useEffect(() => {
    refetch()
  }, [provinceSelected, roadSelected])

  const handleChange = (file: any) => {
    const idFound = target.find((item: any) => item.key === file.key)
    if (!idFound) {
      setTarget((prevTargetKeys) => [...prevTargetKeys, { key: file.key, title: file.title, id: file.key }])
    }
  }

  const handleDelete = (file: any) => {
    const newArray = target.filter((item: any) => item.key !== file.key)
    setTarget(newArray)
  }

  const handleCheckboxChange = (file: any, isChecked: boolean) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [file.key]: isChecked,
    }))
    if (isChecked) {
      handleChange(file)
    } else {
      handleDelete(file)
    }
  }

  return (
    <>
      {file ?
        <div
          style={{
            position: 'relative',
            width: '200px'
          }}
        >
          <Button
            style={{ width: '5rem', height: '2rem', display: 'flex' }}
            className='button3'
            onClick={() => {
              setFile(null)
            }}
          >
            <a>Cerrar</a>
          </Button>
          <br />
          {pdfViewerModalOpen && (
            <Modal
              isOpen={pdfViewerModalOpen}
              onRequestClose={() => setFile(null)}
              overlayClassName="modal-overlay"
              contentLabel="Visor de PDF"
             className={'pdf-viewer-modal-responsive'}
              style={{
                content: {
                  width: '70%',
                  maxWidth: '850px',
                  margin: 'auto',
                  maxHeight: 'auto',
                  left:'70rem',
                  height: '100%',
                  overflow: 'auto',
                  borderRadius: '20px',
                  border: '1px solid #DFE4EA',
                  position:'fixed',
                },
              }}>
              <div>
                <Button onClick={() => setFile(null)}
                  className='button-pdf'
                  style={{ width: '5rem',
                  height: '2rem',
                  display: 'flex',
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '80px',
                  marginBottom: '1rem', 
                  marginTop: '1rem',
                  marginLeft: '1rem' }}>
                  <img
                    alt="Calendar"
                    className='meet-image2'
                    src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-black.svg'}
                  />
                  <p style={{ color: 'black', margin:'0' }}>Volver</p>
                </Button>

                <div style={{ borderBottom: '1px solid #DFE4EA', marginBottom: '1rem' }}></div>

                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '1rem', marginLeft: '1rem' }}><img
                  src='/media/svg/menu-icons/pdf.svg'
                  alt='Via express entry' />  <b className='text-download-responsive' style={{ fontSize: '12px', width: '10rem' }}>{fileName.slice(0, 30)}</b>
                
                  <Button
                    onClick={() => {
                      downloadPDF()
                    }}
                    className='button-pdf'
                    style={{ gap: '5px', width: '9rem', height: '2rem', display: 'flex', backgroundColor: 'white', border: '1px solid #18BFFF', borderRadius: '80px' }}
                  >
                    <img
                      src='/media/svg/menu-icons/download2.svg'
                      alt='Via express entry' />  <b className='text-download-responsive' style={{ color: '#18BFFF' }}>DESCARGAR</b>
                  </Button></div>


                <PdfViewerAndres
                  name={fileName}
                  url={URL.createObjectURL(file)}
                  children={undefined}
                />

              </div>
            </Modal>
          )}
        </div> :
        <div style={{overflowX:'scroll'}}>
          {staff &&
            <div>
              <button
                style={{ height: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #01A9DB', borderRadius: '7px', width: '14rem', gap: '10px' }}
                onClick={() => setModalIsOpen(true)}>
                <img
                  src='/media/svg/menu-icons/file-template.svg'
                  alt='Via express entry' />
                <p style={{ color: '#01A9DB', fontSize: '16px', margin: '0' }}>Subir desde <b>biblioteca</b></p>
              </button>
            </div>
          }
  <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  overlayClassName="modal-overlay"
  onAfterOpen={() => {
    const modalOverlay = document.querySelector('.ReactModal__Overlay') as HTMLElement;
    if (modalOverlay) {
      modalOverlay.style.overflowX = 'scroll';
    }
  }}
  contentLabel="Seleccionador de archivos"
  className='pdf-viewer-modal-responsive'
  style={{
    content: {
      width: '100%',
      maxWidth: '700px',
      margin: 'auto',
      maxHeight: 'auto',
      borderRadius: '30px',
      border: '1px solid #DFE4EA',
    },
  }}
>
            <div
              className="col-md-12 px-8 mt-4 selector-main-div-responsive"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '50px',
                paddingBottom: '2rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
              <div style={{width:'100%'}}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  paddingBottom: '2rem'
                }}>
                  {user?.role?.id === RoleEnum?.admin && (
                    <div
                      onClick={() => {
                        setShowFilters(!showFilters)
                      }}>
                    </div>
                  )}
                  <img
                    src='/media/svg/menu-icons/template-add.svg'
                    alt='Via express entry' />
                  <b className='title-responsive-doc' style={{ fontSize: '24px' }}>
                    Seleccionar archivo de biblioteca
                  </b>
                </div>
                <div className='filter-container-responsive' style={{
                  border: '1px solid #DFE4EA',
                  borderRadius: '25px',
                  padding: '10px 10px ',
                  width: '40rem'
                }}>
                  <b style={{
                    fontSize: '16px',
                    marginBottom: '1rem'
                  }}>Filtrar por</b>
                  <div style={{
                    borderBottom: '1px solid #DFE4EA',
                    marginBottom: '10px',
                    marginTop: '10px'
                  }} />
                  <Filters
                    roadSelected={roadSelected}
                    setRoadSelected={setRoadSelected}
                    provinceSelected={provinceSelected}
                    setProvinceSelected={setProvinceSelected} />
                </div>
                <div className="table-container">
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    {/* Encabezado de la tabla */}
                    <thead className='header-table'>
                      <tr>
                        <th> </th>
                        <th>Documento</th>
                        <th>Via</th>
                        <th>Provincia</th>
                        <th>Ver</th>
                      </tr>
                    </thead>
                    {/* Cuerpo de la tabla */}
                    <tbody>
                      {currentItems.map((item: any, index) => (
                        <>
                          {item.originalname.includes('.pdf') &&
                            <tr key={item.key}>
                              <td style={{ paddingRight: '1rem' }}>
                                <input
                                  type="checkbox"
                                  checked={selectedFiles[item.key] || false}
                                  onChange={(e) => handleCheckboxChange(item, e.target.checked)} />
                              </td>
                              <td style={{ paddingRight: '1rem', display: 'flex', gap: '5px', width: '100%' }}
                                className={`item ${hoveredItemId === item.key ? 'item-hover' : ''}`}
                                onMouseEnter={() => handleMouseEnter(item.key)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <img style={{ width: '15px', height: '15px', marginTop: '5px' }}
                                  src='/media/svg/menu-icons/pdf.svg'
                                  alt='Via express entry' />
                                {item.title.length > 25 ? `${item.title.substring(0, 25)}...` : item.title}
                              </td>
                              <td style={{ paddingRight: '2rem' }}>
                                {item.roadName === 'Vía laboral' && (
                                  <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'var(--green-green, #22AD5C)',
                                    padding: '4px',
                                    marginRight: '8px',
                                    borderRadius: '2px',
                                  }}>
                                    <img
                                    className='image-responsive-doc-asign'
                                      src='/media/svg/menu-icons/briefcase-alt.svg'
                                      alt='Via laboral'
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                      }} />
                                    <span style={{ marginLeft: '4px', color: 'white' }}>Trabajo</span>
                                  </div>
                                )}
                                {item.roadName === 'Vía estudios' && (
                                  <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'var(--fuschia-100, #EF5DA8)',
                                    padding: '4px',
                                    marginRight: '8px',
                                    borderRadius: '2px',
                                  }}>
                                    <img
                                     className='image-responsive-doc-asign'
                                      src='/media/svg/menu-icons/books.svg'
                                      alt='Via estudios'
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                      }} />
                                    <span style={{ marginLeft: '4px', color: 'white' }}>Estudio</span>
                                  </div>
                                )}
                                {item.roadName === 'Vía express entry' && (
                                  <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'var(--cyan-cyan, #01A9DB)',
                                    padding: '4px',
                                    marginRight: '8px',
                                    borderRadius: '2px',
                                  }}>
                                    <img
                                     className='image-responsive-doc-asign'
                                      src='/media/svg/menu-icons/Group.svg'
                                      alt='Via express entry'
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                      }} />
                                    <span style={{ marginLeft: '4px', color: 'white' }}>Immigracion</span>
                                  </div>
                                )}
                              </td>
                              <td style={{ paddingRight: '2rem' }}>{item.provinceName}</td>
                              <td>  <div style={{
                                border: '1px solid #01A9DB',
                                borderRadius: '50px', cursor: 'pointer', width: '36px', height: '26px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                              }}><img
                                  src='/media/svg/menu-icons/eye.svg'
                                  alt='Via estudios'
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                  }} onClick={async () => {
                                    handleViewer(item)
                                  }} />
                              </div>
                              </td>
                            </tr>
                          }
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              
                <ReactPaginate
                  previousLabel={<span>&#9664;</span>}
                  nextLabel={<span>&#9654;</span>}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'} />
              </div>
            </div>
            <div style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              height: '3rem',
              marginBottom: '2rem'
        
            }}>
              <button style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 24px 10px 24px',
                height: 'auto',
                width: 'auto'
              }}
                onClick={resetState}
                className='button-volver'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <img
                  alt="Calendar"
                  className='meet-image2'
                  src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-black.svg'} />
                <b className='meet-text2'>VOLVER</b>
              </button>
              <button
      style={{
        display: 'flex',
        width: 'auto',
        height: 'auto',
        gap: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 24px 10px 24px'
      }}
      onClick={() => setModalIsOpen(false)}
      className='button3'
      onMouseEnter={() => setIsHovered2(true)}
      onMouseLeave={() => setIsHovered2(false)}
    >
      <b className='meet-text3'>ADJUNTAR{window.innerWidth > 500 ? ' ARCHIVOS' : ''}</b>
      <img
        alt="Check Icon"
        className='meet-image3'
        src={isHovered2 ? '/media/icons/app/menu/icons8-check-50white.png' : '/media/icons/app/menu/icons8-check-50white.png'}
      />
    </button>
            </div>
          </Modal>
        </div >}
    </>)
}

export { FileSelector }
