/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
 
 
import { Table } from 'antd'
import {
  getMessagesIdUsers
} from '../../../../app/modules/apps/messages/core/_requests'
import { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'react-query'
import Envelop from '../../contratationApp/icons/Envelop.svg'
import Send from '../../contratationApp/icons/Send.svg'
import Start from '../../contratationApp/icons/Start.svg'
import MessageRows from './MessageRows'
import { IReply, initialReplyData } from 'app/modules/apps/appointments/core/_models'
import NewMessageImbox from '../NewMessageContent'
import { RootStoreContext } from 'stores/rootStore'
import MessageContent from '../MessageContent'
import ShowMessages from './ShowMessages'
import NewMessage from '../NewMessage'
import { Success } from 'components'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  setMessageId?: React.Dispatch<React.SetStateAction<string>>
  setShowMessage?: React.Dispatch<React.SetStateAction<boolean>>
  userId: string
}

const ListMessagesUser = (props: IProps) => {
  const { userId } = props
  const rootStore = useContext(RootStoreContext)
  const { loadMessages, loadMessagesUnread, loadMessagesCurrentMemberMessages,loadMessagesCurrentMemberMessagesAlternative, currentMemberMessages } = rootStore.messageStore
  const { messages, setMessagesRead,countMessagesClient } = rootStore.messageStore
  const [unreadMessage, setUnreadMessage] = useState(0)
  const [folder, setFolder] = useState('inbox')
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [composepostdata, setComposepostdata] = useState<IReply>(initialReplyData)
  const [isUnread, setIsUnread] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const [createMessage, setCreateMessage] = useState(false)
  const [messageId, setMessageId] = useState('')
  const [showmessage, setShowMessage] = useState(false)
  const [list, setlist] = useState(true)
  const [replyMessage, setReplyMessage] = useState(false)
  const { currentUser } = rootStore.usersStore

  /*  const { data, status, refetch } = useQuery(
     'listnotesrrrbyuser',
     async () => await getMessagesIdUsers(currentUser?.id?.toString()!!), {
       enabled:Boolean(currentUser),
       cacheTime: 0
     }) */

  const { callRefetch } = props
  const { Column } = Table

  useEffect(() => {
    if (callRefetch) {
      loadMessagesCurrentMemberMessages(currentUser?.id)
      console.log("callrefetch", JSON.stringify(currentMemberMessages[0]))
    }
  }, [callRefetch])

  useEffect(() => {
    console.log("messagenuevo", JSON.stringify(currentMemberMessages[0]))
  }, [currentMemberMessages,countMessagesClient]);
  useEffect(() => {
    /*  console.log("nuevo usuario",currentUser?.email) */
    loadMessagesCurrentMemberMessages(currentUser?.id)
    console.log("current", JSON.stringify(currentMemberMessages[0]))
  }, [currentUser])

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const HandleReadmessage = (id: string) => {
    console.log('por aqui pase', id)
    setMessagesRead(id)
    if (setMessageId != null) setMessageId(id)
    if (setShowMessage != null) setShowMessage(true)
    setlist(false)
  }

  const handleCloseShowMessage = () => {
    setReplyMessage(false)
    setShowMessage(false)
    setlist(true)
  }

  const handleClose = () => {
    setReplyMessage(false)
    setlist(true)
  }

  const handleSearchMessages = (value: string) => {
    loadMessagesCurrentMemberMessagesAlternative(currentUser?.id,value)
    setFolder(value)
    loadMessages(value)
    loadMessagesUnread()
  }

  if (currentMemberMessages) {
    return (
      <div className='mt-4'>
        <div>
          <NewMessageImbox
            handleSearchMessages={handleSearchMessages}
            setIsUnread={setIsUnread}
            setIsFavorite={setIsFavorite}
            setCreateMessage={setCreateMessage}
            hide={true}
          />
        </div>
        <div className='flex gap-8'>
          <div style={{ width: '10rem' }}>
            <div style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '12px', paddingTop: '12px' }}>
              <div
                style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                className={folder === 'inbox' ? 'bg-gray-900  hover:bg-gray-300' : ''}
                onClick={() => {
                  handleSearchMessages('inbox')
                  setIsUnread(false)
                  setIsFavorite(false)
                }}>
                <img src={Envelop} alt="Envelop" />
                <p style={{ margin: '0', fontSize: '12px' }}>RECIBIDOS</p>
                {Number(countMessagesClient)>0 && <p style={{ margin: '0', color: 'white', padding: '0 3px', backgroundColor: '#13C296', borderRadius: '5px', fontSize: '12px',width:'30px' }}>{/* {countMessagesClient} */}</p>}
              </div>
            </div>
            <div style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '1rem', paddingTop: '1rem' }}>
              <div
                style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                className={folder === 'sent' ? 'bg-gray-900  hover:bg-gray-300' : ''}
                onClick={() => {
                  handleSearchMessages('sent')
                  setIsUnread(false)
                  setIsFavorite(false)
                }}>
                <img src={Send} alt="Envelop" />
                <p style={{ margin: '0', fontSize: '12px' }}>ENVIADOS</p>
              </div>
            </div>
          </div>
          {!currentMemberMessages ? <><img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /></>  :<>
          {currentMemberMessages && currentMemberMessages.length===0  ?  <> No tiene mensajes </>: <>  <Table
            pagination={{
              size: 'small',
              responsive: true,
              simple: true,
              showQuickJumper: false,
              showSizeChanger: false,
            }}
            dataSource={currentMemberMessages}
            rowKey='id'
            style={{ width: '100%', backgroundColor: '#F9FAFB' }}
          >
            {isUnread === true &&
              <Column
                title=''
                render={(text, record: any, index: any) => (
                  <>
                    {record.unread === false &&
                      <div onClick={() => { loadMessagesUnread() }} style={{ width: '100%', marginBottom: '8px', backgroundColor: '#F9FAFB' }}>
                        <MessageRows
                          key={index}
                          message={record}
                          folder={folder}
                          HandleReadmessage={HandleReadmessage}
                          handleClose={handleClose}
                          setShowMessageRows={setShowMessageRows}
                          setComposePostData={setComposepostdata as any}
                        />
                      </div>}
                  </>
                )}
              />}

            {isFavorite === true &&
              <Column
                title=''
                render={(text, record: any, index: any) => (
                  <>
                    {record.favorite === true &&
                      <div style={{ width: '100%', marginBottom: '8px', backgroundColor: '#F9FAFB' }}>
                        <MessageRows
                          key={index}
                          message={record}
                          folder={folder}
                          HandleReadmessage={HandleReadmessage}
                          handleClose={handleClose}
                          setShowMessageRows={setShowMessageRows}
                          setComposePostData={setComposepostdata as any}
                        />
                      </div>}
                  </>
                )}
              />}
            {isFavorite === false && isUnread === false &&
              <Column
                title=''
                render={(text, record: any, index: any) => (
                  <div style={{ width: '100%', marginBottom: '8px', backgroundColor: '#F9FAFB' }}>
                    {/* <>{console.log('el record', record)}</> */}
                    <MessageRows
                      key={index}
                      message={record}
                      folder={folder}
                      HandleReadmessage={HandleReadmessage}
                      handleClose={handleClose}
                      setShowMessageRows={setShowMessageRows}
                      setComposePostData={setComposepostdata as any}
                    />
                  </div>
                )}
              />}
          </Table></>}
          </>}


        </div>
        <div style={{ width: '100%' }}>
          {showmessage && folder === 'draft' ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer'>
                <MessageContent
                  setShowMessage={setShowMessage}
                  setShowMessageRows={setShowMessageRows}
                  setReplyMessage={setReplyMessage}
                  composepostdata={composepostdata}
                  showSelectFiles={false}
                  showShareFiles={false}
                  initialContent={composepostdata.content}
                  showTemplateSearch={false}
                  initialSubject={composepostdata.subject}
                  type={composepostdata.type}
                  cc={[]}
                  handleClose={handleClose}
                  showButtonClose={true}
                  category='fromDraft'
                  setComposeData={setComposepostdata}
                />
              </div>
            </div>
          ) : null}
          {showmessage && folder !== 'draft' ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer'>
                <ShowMessages
                folder = {folder}
                  callRefetch={false}
                  messageId={messageId}
                  setShowMessage={setShowMessage}
                  handleClose={handleCloseShowMessage}
                  setComposepostdata={setComposepostdata}
                  setlist={setlist}
                  setReplyMessage={setReplyMessage}
                  setShowMessageRows={setShowMessageRows}
                />
              </div>
            </div>
          ) : null}
          {replyMessage ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer'>
                <MessageContent
                  setShowMessage={setShowMessage}
                  setShowMessageRows={setShowMessageRows}
                  setReplyMessage={setReplyMessage}
                  composepostdata={composepostdata}
                  showSelectFiles={false}
                  showShareFiles={false}
                  initialContent={composepostdata.content}
                  showTemplateSearch={false}
                  initialSubject={composepostdata.subject}
                  type={composepostdata.type}
                  cc={[]}
                  handleClose={handleClose}
                  showButtonClose={true}
                  category=''
                  setComposeData={setComposepostdata}
                />
              </div>
            </div>
          ) : null}
          {createMessage ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer'>
                <NewMessage
                  contractId={''}
                  emailStaff={''}
                  setShowMessage={setShowMessage}
                  setShowMessageRows={setShowMessageRows}
                  setReplyMessage={setReplyMessage}
                  composepostdata={composepostdata}
                  showSelectFiles={false}
                  showShareFiles={false}
                  initialContent={composepostdata.content}
                  showTemplateSearch={false}
                  initialSubject={composepostdata.subject}
                  type={'new_message'}
                  cc={[]}
                  handleClose={handleClose}
                  showButtonClose={true}
                  category=''
                  setCreateMessage={setCreateMessage}
                  setComposeData={setComposepostdata}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  } else {
    return <>No data</>
  }

}

export default observer(ListMessagesUser)
