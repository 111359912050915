import {Button, Container, Link} from '../../../../components'
import ProductManager from './ProductManager'
import React from 'react'
import {useIntl} from 'react-intl'

const ProductWrapper = () => {
  const intl = useIntl()
  return (
    <Container title={intl.formatMessage({id: 'MENU.STORE.PRODUCT'})}>
      {' '}
      
      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
      <Link to='/admin/intake'>
        <Button
         className="button-ui button-secondary button-s" 
          
        >
          VOLVER
        </Button>
      </Link></div>
      <ProductManager />
    </Container>
  )
}

export default ProductWrapper
