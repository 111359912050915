 
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {useContext} from 'react'
import {RootStoreContext} from 'stores/rootStore'
import {observer} from 'mobx-react'
import { convertCurrency } from 'app/components/payments/payu/convertCurrency'
import { Product } from 'app/modules/apps/cart/core/products/_models'

interface Props {
  method: string
  onPaymentMethodChange: (value: string) => void;
}

const Summary: React.FC<Props> =  observer(({method, onPaymentMethodChange }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  const rootStore = useContext(RootStoreContext)
  const {cart, cuponPrice} = rootStore.cartStore

  const getPesos = ()=>{
    return getTotal() * 2920 - (cuponPrice * 2920) //conseguir api para obtener tasa
  }

  const getSubTotalPesos = ()=>{
    return getTotal() * 2920 //conseguir api para obtener tasa
  }

  const getroduct = () => {
    return cart.reduce((acc: number, product: {product: any}) => {
      return product.product.name
    }, 0)
  }

  const getQuantity = () => {
    return cart.reduce((acc: number, product: {product: {price: number}; quantity: number}) => {
      return product.quantity
    }, 0)
  }

  const getSubtotal = () => {
    return cart.reduce((acc: number, product: {product: {price: number}; quantity: number}) => {
      return acc + product.product.price * product.quantity
    }, 0)
  }
  const getTax = () => {
    return getSubtotal() * 0.0
  }
  const getTotal = () => {
    // setCopTotal((getSubtotal() + getTax() - cuponPrice) * 2900 )
    return getSubtotal() + getTax() /* - cuponPrice */ 
  }
  const handlePaymentMethod = () => {
    let paymentMethodValue = '';
    if (method === 'payu') {
      paymentMethodValue = `COP ${formatter.format(getSubTotalPesos())}`;
    } else {
      paymentMethodValue = `CAD ${formatter.format(getTotal())}`;
    }
    return paymentMethodValue; // Return the calculated payment method value as string
  };
  
  const handleFinalPrice = () => { 
    if (method === 'payu') {
      return `COP ${formatter.format(getPesos())}`;
    }
    onPaymentMethodChange(`CAD ${formatter.format(finalPrice)}`);
    return `CAD ${formatter.format(finalPrice)}`;
  };
  
  const handleCupon = ()=>{
    if (method === 'payu'){
      return <span className='font-semibold'>COP {formatter.format(cuponPrice * 2920)}</span>
    }
    return <span className='font-semibold'>CAD {formatter.format(cuponPrice)}</span>
  }

  useEffect(() => {
    // Restablecer la posición de desplazamiento en la primera vista
    const containerLineText = document.querySelector('.container-line-text');
    if (containerLineText) {
      containerLineText.scrollTop = 0;
    }
  }, []);
  const finalPrice = getSubtotal() + getTax() - cuponPrice;


  // const changePrice = async (price: number) => {
  //     let total = await convertCurrency(preOrder.total, 'CAD', 'COP')
  //     total = total * 1.1
  //     return total
  // }
  // const totalcop = async()=>{
  //   const total = await changePrice(getTotal())
  //   setCopTotal(total)
  //   return 
  // } 
  // totalcop()

  return (
    <>

        <div   className='container-line-text'>
      <p className='style-text-confirm' style={{fontWeight:'700', fontSize:'25px', marginBottom:'0rem', marginRight:'17rem'}}>Confirmar solicitud</p>
<p className='style-text-check' style={{fontWeight:'400', fontSize:'18px'}}>Revise sus <b>productos</b> y complete su compra iniciando su sesión</p></div> 
      <div className='bg-white-100 m-5 rounded-md style-table'>
  <h2 className='step-header-title'>Resumen de la Compra</h2>
  <table className='w-full table-all-data'>
    <tbody>
      <tr style={{backgroundColor:'#F9FAFB', border:'1px solid #DFE4EA', borderRadius:'6px'}}>
        <td className='text-gray-700'>Producto</td>
        <td className='text-gray-700'>Cantidad</td>
        <td className='text-gray-700'>Cupón</td>
        <td className='text-gray-700'>Valor</td>
      </tr>
      <tr>
        <td style={{display:'flex', flexDirection:'column', marginTop:'1rem', alignItems:'flex-start'}} className='font-semibold'>{getroduct()}
         {method === 'payu' ? (
            <span className='font-light text-xs text-gray-600'>Valor por página - Impuestos incluidos</span>
          ) : (
            <span className='font-light text-xs text-gray-600'>Valor por página - Impuestos incluidos</span>
          )}</td>
        <td className='font-semibold'>{getQuantity()}</td>
        <td className='font-semibold'>{handleCupon()}</td>
        <td  className='font-semibold'>{handlePaymentMethod()}
       </td>
      </tr>
     
     
    </tbody>
    

  </table>
 
  <div className='line-2'/>
  <div className='container-table-payment'>
  <div className='table-total'>
     
    
     <div className='sub-total'>
             <span className='text-black'>Sub-Total:</span>
             <span className='font-semibold'> {handlePaymentMethod()}</span>
           </div>
               
     <div className='sub-total'>
             <span className='text-black'>Descuentos:</span>
             <span className='font-semibold'> {handleCupon()}</span>
           </div>
           <div className='sub-total'>
          <span className='text-black'>Valor final:</span>
          <span className='font-semibold'>{handleFinalPrice()}</span>
        </div>
           </div></div>
</div>

    </>
  )
})

export default Summary
