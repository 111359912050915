 
import {Button} from '../../../../../../../components'
import {RootStoreContext} from '../../../../../../../stores/rootStore'
import {observer} from 'mobx-react'
import {useContext} from 'react'
import {useIntl} from 'react-intl'
import '../style.css'
interface Props {
  editable: boolean
}

const AvatarProfileAction: React.FC<Props> = ({editable}) => {
  const intl = useIntl()

  const rootStore = useContext(RootStoreContext)
  const {setIsEditing, isEditing} = rootStore.profileStore

  return (
    <>
      {editable && !isEditing ? (
        <>
          <Button
            className='button-ui button-tertiary'
            width={'w-52'}
            type='link'
            onClick={async () => {
              await setIsEditing(true)
            }}
          >
            <img src='/media/svg/menu-icons/camera.svg' alt='Camera Icon' className='icon' />{' '}
            Cambiar foto de Perfil
          </Button>
          <Button
            className='buttonavatar hide-component-fullscreen ml-2'
            width={'w-50'}
            type='link'
            onClick={async () => {
              await setIsEditing(true)
            }}
          >
            <img src='/media/svg/menu-icons/camera.svg' alt='Camera Icon' className='icon' />{' '}
           Foto
          </Button>
        </>
      ) : null}
    </>
  )
}

export default observer(AvatarProfileAction)
