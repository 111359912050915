export const myinformation = {
  'MYINFO.CHANGEPROFILEPICTURE': 'Cambiar Foto de Perfil',
  'MYINFO.FORMSDATANAME': 'Forms Data Name',
  'MYINFO.ID': 'Forms Data id',
  'MYINFO.NAME': 'Nombre',
  'MYINFO.LASTNAME': 'Apellido',
  'MYINFO.FIRSTNAME': 'Primer Nombre',
  'MYINFO.CREATEDAT': 'Fecha de creación',
  'MYINFO.FORMSID': 'Form ID',
  'MYINFO.FORMSNAME': 'Nombre del formulario',
  'MYINFO.PREVIEW': 'Preview',
  'FORMSMANAGER.PLEASECLICKTHEFORM': 'Seleccione el formulario que desee completar',
  'MYINFO.EMAIL': 'Correo',
  'MYINFO.NORESULT': 'Sin Resultados',
  'MYINFO.LASTSERVICE': 'Ultimo Servicio',
  'MYINFO.ASSIGN': 'Asignar',
}
