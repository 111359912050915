/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react'
import xmlbuilder from 'xmlbuilder'
import axios from 'axios'
import { RootStoreContext } from 'stores/rootStore'
import thunderblue from './Icons/thunderbolt-blue.svg'
import thunderbolt from './Icons/thunderbolt.svg'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
interface IProps {
  product: any
  contractsData: any
  contractsFound: any
  lang: string
}

const IMM5257E: React.FC<IProps> = ({ product, contractsData, contractsFound, lang }) => {
  const rootStore = useContext(RootStoreContext)
  const { connectSocket } = rootStore.notificationStore
  const [age, setAge] = useState('')
  const [isAdult, setIsAdult] = useState(false)
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [visaType, setVisaType] = useState('')
  const [UCI, setUCI] = useState('')
  const [aliasNameIndicator, setAliasNameIndicator] = useState('')
  const [aliasFamilyName, setAliasFamilyName] = useState('')
  const [aliasGivenName, setAliasGivenName] = useState('')
  const [sex, setSex] = useState('')
  const [DOBYear, setDOBYear] = useState('')
  const [DOBMonth, setDOBMonth] = useState('')
  const [DOBDay, setDOBDay] = useState('')
  const [placeBirthCity, setPlaceBirthCity] = useState('')
  const [placeBirthCountry, setPlaceBirthCountry] = useState('')
  const [citizenship, setCitizenship] = useState('')
  const [serviceIn, setServiceIn] = useState('')
  const [CORCountry, setCORCountry] = useState('')
  const [CORStatus, setCORStatus] = useState('')
  const [COROther, setCOROther] = useState('')
  const [CORDates, setCORDates] = useState([])
  const [CORYearFrom, setCORYearFrom] = useState('')
  const [CORMonthFrom, setCORMonthFrom] = useState('')
  const [CORDayFrom, setCORDayFrom] = useState('')
  const [CORYearTo, setCORYearTo] = useState('')
  const [CORMonthTo, setCORMonthTo] = useState('')
  const [CORDayTo, setCORDayTo] = useState('')
  const [SameAsPCRIndicator, setSameAsPCRIndicator] = useState('')
  const [SameAsCORCountry, setSameAsCORCountry] = useState('')
  const [SameAsCORCountryStatus, setSameAsCORCountryStatus] = useState('')
  const [SameAsCORCountryOther, setSameAsCORCountryOther] = useState('')
  const [SameAsCORDates, setSameAsCORDates] = useState([])
  const [SameAsCORYearFrom, setSameAsCORYearFrom] = useState('')
  const [SameAsCORMonthFrom, setSameAsCORMonthFrom] = useState('')
  const [SameAsCORDayFrom, setSameAsCORDayFrom] = useState('')
  const [SameAsCORYearTo, setSameAsCORYearTo] = useState('')
  const [SameAsCORMonthTo, setSameAsCORMonthTo] = useState('')
  const [SameAsCORDayTo, setSameAsCORDayTo] = useState('')
  const [PCRIndicator, setPCRIndicator] = useState('')
  const [PCRCountry, setPCRCountry] = useState('')
  const [PCRStatus, setPCRStatus] = useState('')
  const [PCROther, setPCROther] = useState('')
  const [PCRDates, setPCRDates] = useState([])
  const [PCRYearFrom, setPCRYearFrom] = useState('')
  const [PCRMonthFrom, setPCRMonthFrom] = useState('')
  const [PCRDayFrom, setPCRDayFrom] = useState('')
  const [PCRYearTo, setPCRYearTo] = useState('')
  const [PCRMonthTo, setPCRMonthTo] = useState('')
  const [PCRDayTo, setPCRDayTo] = useState('')
  const [PCRCountry2, setPCRCountry2] = useState('')
  const [PCRStatus2, setPCRStatus2] = useState('')
  const [PCROther2, setPCROther2] = useState('')
  const [PCRDates2, setPCRDates2] = useState([])
  const [PCRYearFrom2, setPCRYearFrom2] = useState('')
  const [PCRMonthFrom2, setPCRMonthFrom2] = useState('')
  const [PCRDayFrom2, setPCRDayFrom2] = useState('')
  const [PCRYearTo2, setPCRYearTo2] = useState('')
  const [PCRMonthTo2, setPCRMonthTo2] = useState('')
  const [PCRDayTo2, setPCRDayTo2] = useState('')
  const [maritalStatus, setmaritalStatus] = useState('')
  const [maritalStatusDate, setmaritalStatusDate] = useState('')
  const [maritalStatusYear, setMaritalStatusYear] = useState('')
  const [maritalStatusMonth, setMaritalStatusMonth] = useState('')
  const [maritalStatusDay, setMaritalStatusDay] = useState('')
  const [maritalStatusGivenName, setMaritalStatusGivenName] = useState('')
  const [maritalStatusFamilyName, setMaritalStatusFamilyName] = useState('')
  //Page 2
  const [prevMarriedIndicator, setPrevMarriedIndicator] = useState('')
  const [PMFamilyNameGivenName, setPMFamilyNameGivenName] = useState('')
  const [PMFamilyName, setPMFamilyName] = useState('')
  const [PMDateOfBirthY, setPMDateOfBirthY] = useState('')
  const [PMDateOfBirthM, setPMDateOfBirthM] = useState('')
  const [PMDateOfBirthD, setPMDateOfBirthD] = useState('')
  const [PMTypeOfRelationship, setPMTypeOfRelationship] = useState('')
  const [PMDateFromDate, setPMDateFromDate] = useState('')
  const [PMDateToDate, setPMDateToDate] = useState('')
  const [PreviouslyMarriedDatesFromY, setPreviouslyMarriedDatesFromY] = useState('')
  const [PreviouslyMarriedDatesFromM, setPreviouslyMarriedDatesFromM] = useState('')
  const [PreviouslyMarriedDatesFromD, setPreviouslyMarriedDatesFromD] = useState('')
  const [PreviouslyMarriedDatesToY, setPreviouslyMarriedDatesToY] = useState('')
  const [PreviouslyMarriedDatesToM, setPreviouslyMarriedDatesToM] = useState('')
  const [PreviouslyMarriedDatesToD, setPreviouslyMarriedDatesToD] = useState('')
  const [passportNum, setPassportNum] = useState('')
  const [passportCountryOfIssue, setPassportCountryOfIssue] = useState('')
  const [passportDateOfIssue, setPassportDateOfIssue] = useState('')
  const [passportExpireDate, setPassportExpireDate] = useState('')
  const [IssueY, setIssueY] = useState('')
  const [IssueM, setIssueM] = useState('')
  const [IssueD, setIssueD] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const [expiryM, setExpiryM] = useState('')
  const [expiryD, setExpiryD] = useState('')
  const [nativeLang, setNativeLang] = useState('')
  const [ableToCommunicate, setAbleToCommunicate] = useState('')
  const [LOV, setLOV] = useState('')
  const [LanguageTest, setLanguageTest] = useState('')
  const [natIDIndicator, setNatIDIndicator] = useState('')
  const [DocNum, setDocNum] = useState('')
  const [IDCountryofIssue, setIDCountryofIssue] = useState('')
  const [IDIssueDate, setIDIssueDate] = useState('')
  const [IDExpiryDate, setIDExpiryDate] = useState('')
  const [usCardIndicator, setusCardIndicator] = useState('')
  const [usCardDocNum, setusCardDocNum] = useState('')
  const [usCardExpiryDate, setusCardExpiryDate] = useState('')
  const [POBox, setPOBox] = useState('')
  const [AptUnit, setAptUnit] = useState('')
  const [StreetNum, setStreetNum] = useState('')
  const [Streetname, setStreetname] = useState('')
  const [CityTown, setCityTown] = useState('')
  const [Country, setCountry] = useState('')
  const [ProvinceState, setProvinceState] = useState('')
  const [PostalCode, setPostalCode] = useState('')
  const [District, setDistrict] = useState('')
  const [SameAsMailingIndicator, setSameAsMailingIndicator] = useState('')
  const [SameAsMailingIndicatorAptUnit, setSameAsMailingIndicatorAptUnit] = useState('')
  const [SameAsMailingIndicatorStreetNum, setSameAsMailingIndicatorStreetNum] = useState('')
  const [SameAsMailingIndicatorStreetname, setSameAsMailingIndicatorStreetname] = useState('')
  const [SameAsMailingIndicatorCityTown, setSameAsMailingIndicatorCityTown] = useState('')
  const [SameAsMailingIndicatorCountry, setSameAsMailingIndicatorCountry] = useState('')
  const [SameAsMailingIndicatorProvince, setSameAsMailingIndicatorProvince] = useState('')
  const [SameAsMailingIndicatorPostalCode, setSameAsMailingIndicatorPostalCode] = useState('')
  const [SameAsMailingIndicatorDistrict, setSameAsMailingIndicatorDistrict] = useState('')
  //Page 3
  const [PurposeOfVisit, setPurposeOfVisit] = useState('')
  const [PurposeOfVisitOther, setPurposeOfVisitOther] = useState('')
  const [HowLongStay, setHowLongStay] = useState([])
  const [HowLongStayFromY, setHowLongStayFromY] = useState('')
  const [HowLongStayFromM, setHowLongStayFromM] = useState('')
  const [HowLongStayFromD, setHowLongStayFromD] = useState('')
  const [HowLongStayToY, setHowLongStayToY] = useState('')
  const [HowLongStayToM, setHowLongStayToM] = useState('')
  const [HowLongStayToD, setHowLongStayToD] = useState('')
  const [HowFounds, setHowFounds] = useState('')
  const [Contacts1Name, setContacts1Name] = useState('')
  const [Contacts1RelationshipToMe, setContacts1RelationshipToMe] = useState('')
  const [Contacts1AddressInCanada, setContacts1AddressInCanada] = useState('')
  const [Contacts2Name, setContacts2Name] = useState('')
  const [Contacts2RelationshipToMe, setContacts2RelationshipToMe] = useState('')
  const [Contacts2AddressInCanada, setContacts2AddressInCanada] = useState('')
  const [EducationIndicator, setEducationIndicator] = useState('')
  const [EducationDatesFromY, setEducationDatesFromY] = useState('')
  const [EducationDatesFromM, setEducationDatesFromM] = useState('')
  const [EducationDatesToY, setEducationDatesToY] = useState('')
  const [EducationDatesToM, setEducationDatesToM] = useState('')
  const [FieldOfStudy, setFieldOfStudy] = useState('')
  const [School, setSchool] = useState('')
  const [SchoolProvince, setSchoolProvince] = useState('')
  const [SchoolCityTown, setSchoolCityTown] = useState('')
  const [SchoolCountry, setSchoolCountry] = useState('')
  const [Ocopation1DatesFrom, setOcopation1DatesFrom] = useState('')
  const [Ocopation1DatesFromY, setOcopation1DatesFromY] = useState('')
  const [Ocopation1DatesFromM, setOcopation1DatesFromM] = useState('')
  const [Ocopation1DatesTo, setOcopation1DatesTo] = useState('')
  const [Ocopation1DatesToY, setOcopation1DatesToY] = useState('')
  const [Ocopation1DatesToM, setOcopation1DatesToM] = useState('')
  const [Employer1, setEmployer1] = useState('')
  const [Ocupation1, setOcupation1] = useState('')
  const [Ocupation1Province, setOcupation1Province] = useState('')
  const [Ocupation1CityTown, setOcupation1CityTown] = useState('')
  const [Ocupation1Country, setOcupation1Country] = useState('')
  const [Ocupation2Country, setOcupation2Country] = useState('')
  const [Ocopation2DatesFromY, setOcopation2DatesFromY] = useState('')
  const [Ocopation2DatesFromM, setOcopation2DatesFromM] = useState('')
  const [Ocopation2DatesToY, setOcopation2DatesToY] = useState('')
  const [Ocopation2DatesToM, setOcopation2DatesToM] = useState('')
  const [Employer2, setEmployer2] = useState('')
  const [Ocupation2, setOcupation2] = useState('')
  const [Ocupation2Province, setOcupation2Province] = useState('')
  const [Ocupation2CityTown, setOcupation2CityTown] = useState('')
  const [Ocopation3DatesFromY, setOcopation3DatesFromY] = useState('')
  const [Ocopation3DatesFromM, setOcopation3DatesFromM] = useState('')
  const [Ocopation3DatesToY, setOcopation3DatesToY] = useState('')
  const [Ocopation3DatesToM, setOcopation3DatesToM] = useState('')
  const [Employer3, setEmployer3] = useState('')
  const [Ocupation3, setOcupation3] = useState('')
  const [Ocupation3Province, setOcupation3Province] = useState('')
  const [Ocupation3CityTown, setOcupation3CityTown] = useState('')
  const [Ocupation3Country, setOcupation3Country] = useState('')
  const [BackgroundInfoMedicalChoice1, setBackgroundInfoMedicalChoice1] = useState('')
  const [BackgroundInfoMedicalChoice2, setBackgroundInfoMedicalChoice2] = useState('')
  const [BackgroundInfoMedicalDetails, setBackgroundInfoMedicalDetails] = useState('')
  const [BackgroundInfoMedicalDetails2, setBackgroundInfoMedicalDetails2] = useState('')
  const [BackgroundInfoVisaChioce1, setBackgroundInfoVisaChioce1] = useState('')
  const [BackgroundInfoVisaChioce2, setBackgroundInfoVisaChioce2] = useState('')
  const [BackgroundInfoVisaChioce3, setBackgroundInfoVisaChioce3] = useState('')
  const [BackgroundInfoVisaChioceDetails, setBackgroundInfoVisaChioceDetails] = useState('')
  const [BackgroundInfoVisaChioceDetails2, setBackgroundInfoVisaChioceDetails2] = useState('')
  const [BackgroundInfoVisaChioceDetails3, setBackgroundInfoVisaChioceDetails3] = useState('')
  const [BackgroundInfo3Choice, setBackgroundInfo3Choice] = useState('')
  const [BackgroundInfo3Details, setBackgroundInfo3Details] = useState('')
  const [MilitarChoice, setMilitarChoice] = useState('')
  const [PoliticalAssociation, setPoliticalAssociation] = useState('')
  const [PoliticalLoot, setPoliticalLoot] = useState('')
  const [MilitarDetails, setMilitarDetails] = useState('')
  const [MilitarDetails4, setMilitarDetails4] = useState('')
  const [MilitarDetails5, setMilitarDetails5] = useState('')
  const [MilitarDetails8, setMilitarDetails8] = useState('')
  const [MilitarDetails9, setMilitarDetails9] = useState('')
  const [MilitarDetails12, setMilitarDetails12] = useState('')
  const [MilitarDetails13, setMilitarDetails13] = useState('')
  const [MilitarDetails16, setMilitarDetails16] = useState('')
  const [MilitarDetails17, setMilitarDetails17] = useState('')
  const [MilitarDetails20, setMilitarDetails20] = useState('')

  //array validators
  const [arrayValidator, setArrayValidator] = useState<string[]>([])
  const fileName = `IMM5257E ${product.memberName} ${product.memberType} ${product.name || 'formulario'}.xml`
  const { user: userAUTH } = rootStore.authStore


  const getDateObjectFromString = (dateString: any) => {
    const [year, month, day] = dateString.split('-').map(Number)
    return new Date(year, month - 1, day)
  }

  const compareDates = (date1: any, date2: any) => {
    const d1 = getDateObjectFromString(date1)
    const d2 = getDateObjectFromString(date2)

    if (d1 > d2) {
      return 'Mayor'
    } else if (d1 < d2) {
      return 'Menor'
    } else {
      return 'Igual'
    }
  }

  const isDateGreaterThanToday = (dateString: any) => {
    const today = new Date()
    const dateToCompare = getDateObjectFromString(dateString)

    return dateToCompare > today
  }

  useEffect(() => {
    console.log('lang', lang)
  }, [])

  useEffect(() => {
    if (contractsData) {
      // Extraemos el arreglo 'data' del objeto contractsData
      const dataArray = contractsData.data

      // Encontramos el objeto dentro de dataArray que tenga el mismo envelope_id que contractsFound
      const foundContract = dataArray.find((contract:
        { contracts_users_envelope_id: any }) =>
        contract.contracts_users_envelope_id === contractsFound)

      if (foundContract) {
        console.log('contractsData:', foundContract)
      } else {
        console.log('No contract found with envelope id:', contractsFound)
      }
    } else {
      console.log('contractsData is undefined')
    }
  }, [contractsData, contractsFound])

  const countries = (country: any) => {
    let Tcountry = ''
    switch (country) {
      case 'Afganistan':
        Tcountry = 'AFG (Afganistan)'
        break
      case 'Angola':
        Tcountry = 'AGO (Angola)'
        break
      case 'Albania':
        Tcountry = 'ALB (Albania)'
        break
      case 'Andorra':
        Tcountry = 'AND (Andorra)'
        break
      case 'Anguilla':
        Tcountry = 'ANG (Anguilla)'
        break
      case 'United Arab Emirates':
        Tcountry = 'ARE (United Arab Emirates)'
        break
      case 'Argentina':
        Tcountry = 'ARG (Argentina)'
        break
      case 'Armenia':
        Tcountry = 'ARM (Armenia)'
        break
      case 'Antigua and Barbuda':
        Tcountry = 'ATG (Antigua and Barbuda)'
        break
      case 'Australia':
        Tcountry = 'AUS (Australia)'
        break
      case 'Austria':
        Tcountry = 'AUT (Austria)'
        break
      case 'Azerbaijan':
        Tcountry = 'AZE (Azerbaijan)'
        break
      case 'Burundi':
        Tcountry = 'BDI (Burundi)'
        break
      case 'Belgium':
        Tcountry = 'BEL (Belgium)'
        break
      case 'Benin':
        Tcountry = 'BEN (Benin)'
        break
      case 'Burkina Faso':
        Tcountry = 'BFA (Burkina Faso)'
        break
      case 'Bangladesh':
        Tcountry = 'BGD (Bangladesh)'
        break
      case 'Bulgaria':
        Tcountry = 'BGR (Bulgaria)'
        break
      case 'Bahrain':
        Tcountry = 'BHR (Bahrain)'
        break
      case 'Bahamas':
        Tcountry = 'BHS (Bahamas)'
        break
      case 'Bosnia and Herzegovina':
        Tcountry = 'BIH (Bosnia and Herzegovina)'
        break
      case 'Belarus':
        Tcountry = 'BLR (Belarus)'
        break
      case 'Belize':
        Tcountry = 'BLZ (Belize)'
        break
      case 'Bermuda':
        Tcountry = 'BMU (Bermuda)'
        break
      case 'Bolivia':
        Tcountry = 'BOL (Bolivia)'
        break
      case 'Brazil':
        Tcountry = 'BRA (Brazil)'
        break
      case 'Barbados':
        Tcountry = 'BRB (Barbados)'
        break
      case 'Brunei Darussalam':
        Tcountry = 'BRN (Brunei Darussalam)'
        break
      case 'Bhutan':
        Tcountry = 'BTN (Bhutan)'
        break
      case 'Botswana':
        Tcountry = 'BWA (Botswana)'
        break
      case 'Central African Republic':
        Tcountry = 'CAF (Central African Republic)'
        break
      case 'Canada':
        Tcountry = 'CAN (Canada)'
        break
      case 'Switzerland':
        Tcountry = 'CHE (Switzerland)'
        break
      case 'Chile':
        Tcountry = 'CHL (Chile)'
        break
      case 'China (Hong Kong SAR)':
        Tcountry = 'CHN (China (Hong Kong SAR))'
        break
      case 'China (Macao SAR)':
        Tcountry = 'CHN (China (Macao SAR))'
        break
      case 'China':
        Tcountry = 'CHN (China)'
        break
      case 'Ivory Coast':
        Tcountry = 'CIV (Ivory Coast)'
        break
      case 'Cameroon':
        Tcountry = 'CMR (Cameroon)'
        break
      case 'Democratic Rep. of Congo':
        Tcountry = 'COD (Democratic Rep. of Congo)'
        break
      case 'Republic of Congo':
        Tcountry = 'COG (Republic of Congo)'
        break
      case 'Colombia':
        Tcountry = 'COL (Colombia)'
        break
      case 'Comoros':
        Tcountry = 'COM (Comoros)'
        break
      case 'Cabo Verde':
        Tcountry = 'CPV (Cabo Verde)'
        break
      case 'Costa Rica':
        Tcountry = 'CRI (Costa Rica)'
        break
      case 'Cuba':
        Tcountry = 'CUB (Cuba)'
        break
      case 'Cayman Islands':
        Tcountry = 'CYM (Cayman Islands)'
        break
      case 'Cyprus':
        Tcountry = 'CYP (Cyprus)'
        break
      case 'Czech Republic':
        Tcountry = 'CZE (Czech Republic)'
        break
      case 'Germany, Federal Republic Of':
        Tcountry = 'D (Germany, Federal Republic Of)'
        break
      case 'Djibouti':
        Tcountry = 'DJI (Djibouti)'
        break
      case 'Dominica':
        Tcountry = 'DMA (Dominica)'
        break
      case 'Denmark':
        Tcountry = 'DNK (Denmark)'
        break
      case 'Dominican Republic':
        Tcountry = 'DOM (Dominican Republic)'
        break
      case 'Algeria':
        Tcountry = 'DZA (Algeria)'
        break
      case 'Ecuador':
        Tcountry = 'ECU (Ecuador)'
        break
      case 'Egypt':
        Tcountry = 'EGY (Egypt)'
        break
      case 'Eritrea':
        Tcountry = 'ERI (Eritrea)'
        break
      case 'Spain':
        Tcountry = 'ESP (Spain)'
        break
      case 'Estonia':
        Tcountry = 'EST (Estonia)'
        break
      case 'Ethiopia':
        Tcountry = 'ETH (Ethiopia)'
        break
      case 'Finland':
        Tcountry = 'FIN (Finland)'
        break
      case 'Fiji':
        Tcountry = 'FJI (Fiji)'
        break
      case 'France':
        Tcountry = 'FRA (France)'
        break
      case 'Faroe Islands':
        Tcountry = 'FRO (Faroe Islands)'
        break
      case 'Federated States of Micronesia':
        Tcountry = 'FSM (Federated States of Micronesia)'
        break
      case 'Gabon':
        Tcountry = 'GAB (Gabon)'
        break
      case 'UK - Brit. overseas terr.':
        Tcountry = 'GBD (UK - Brit. overseas terr.)'
        break
      case 'UK - Brit. Ntl. Overseas':
        Tcountry = 'GBN (UK - Brit. Ntl. Overseas)'
        break
      case 'UK - Brit. overseas citizen':
        Tcountry = 'GBO (UK - Brit. overseas citizen)'
        break
      case 'UK - Brit. protected person':
        Tcountry = 'GBP (UK - Brit. protected person)'
        break
      case 'UK - British citizen':
        Tcountry = 'GBR (UK - British citizen)'
        break
      case 'UK - British subject':
        Tcountry = 'GBS (UK - British subject)'
        break
      case 'Georgia':
        Tcountry = 'GEO (Georgia)'
        break
      case 'Ghana':
        Tcountry = 'GHA (Ghana)'
        break
      case 'Guinea':
        Tcountry = 'GIN (Guinea)'
        break
      case 'Gambia':
        Tcountry = 'GMB (Gambia)'
        break
      case 'Guinea-Bissau':
        Tcountry = 'GNB (Guinea-Bissau)'
        break
      case 'Equatorial Guinea':
        Tcountry = 'GNQ (Equatorial Guinea)'
        break
      case 'Greece':
        Tcountry = 'GRC (Greece)'
        break
      case 'Grenada':
        Tcountry = 'GRD (Grenada)'
        break
      case 'Guatemala':
        Tcountry = 'GTM (Guatemala)'
        break
      case 'Guyana':
        Tcountry = 'GUY (Guyana)'
        break
      case 'Honduras':
        Tcountry = 'HND (Honduras)'
        break
      case 'Croatia':
        Tcountry = 'HRV (Croatia)'
        break
      case 'Haiti':
        Tcountry = 'HTI (Haiti)'
        break
      case 'Hungary':
        Tcountry = 'HUN (Hungary)'
        break
      case 'Indonesia':
        Tcountry = 'IDN (Indonesia)'
        break
      case 'India':
        Tcountry = 'IND (India)'
        break
      case 'Ireland':
        Tcountry = 'IRL (Ireland)'
        break
      case 'Iran':
        Tcountry = 'IRN (Iran)'
        break
      case 'Iraq':
        Tcountry = 'IRQ (Iraq)'
        break
      case 'Iceland':
        Tcountry = 'ISL (Iceland)'
        break
      case 'Israel':
        Tcountry = 'ISR (Israel)'
        break
      case 'Italy':
        Tcountry = 'ITA (Italy)'
        break
      case 'Jamaica':
        Tcountry = 'JAM (Jamaica)'
        break
      case 'Jordan':
        Tcountry = 'JOR (Jordan)'
        break
      case 'Japan':
        Tcountry = 'JPN (Japan)'
        break
      case 'Kazakhstan':
        Tcountry = 'KAZ (Kazakhstan)'
        break
      case 'Kenya':
        Tcountry = 'KEN (Kenya)'
        break
      case 'Kyrgyzstan':
        Tcountry = 'KGZ (Kyrgyzstan)'
        break
      case 'Cambodia':
        Tcountry = 'KHM (Cambodia)'
        break
      case 'Kiribati':
        Tcountry = 'KIR (Kiribati)'
        break
      case 'Saint Kitts and Nevis':
        Tcountry = 'KNA (Saint Kitts and Nevis)'
        break
      case 'Korea, South':
        Tcountry = 'KOR (Korea, South)'
        break
      case 'Kuwait':
        Tcountry = 'KWT (Kuwait)'
        break
      case 'Laos':
        Tcountry = 'LAO (Laos)'
        break
      case 'Lebanon':
        Tcountry = 'LBN (Lebanon)'
        break
      case 'Liberia':
        Tcountry = 'LBR (Liberia)'
        break
      case 'Libya':
        Tcountry = 'LBY (Libya)'
        break
      case 'Saint Lucia':
        Tcountry = 'LCA (Saint Lucia)'
        break
      case 'Liechtenstein':
        Tcountry = 'LIE (Liechtenstein)'
        break
      case 'Sri Lanka':
        Tcountry = 'LKA (Sri Lanka)'
        break
      case 'Lesotho':
        Tcountry = 'LSO (Lesotho)'
        break
      case 'Lithuania':
        Tcountry = 'LTU (Lithuania)'
        break
      case 'Luxembourg':
        Tcountry = 'LUX (Luxembourg)'
        break
      case 'Latvia':
        Tcountry = 'LVA (Latvia)'
        break
      case 'Morocco':
        Tcountry = 'MAR (Morocco)'
        break
      case 'Monaco':
        Tcountry = 'MCO (Monaco)'
        break
      case 'Moldova':
        Tcountry = 'MDA (Moldova)'
        break
      case 'Madagascar':
        Tcountry = 'MDG (Madagascar)'
        break
      case 'Maldives':
        Tcountry = 'MDV (Maldives)'
        break
      case 'Mexico':
        Tcountry = 'MEX (Mexico)'
        break
      case 'Marshall Islands':
        Tcountry = 'MHL (Marshall Islands)'
        break
      case 'Macedonia':
        Tcountry = 'MKD (Macedonia)'
        break
      case 'Mali':
        Tcountry = 'MLI (Mali)'
        break
      case 'Malta':
        Tcountry = 'MLT (Malta)'
        break
      case 'Burma (Myanmar)':
        Tcountry = 'MMR (Burma (Myanmar))'
        break
      case 'Montenegro':
        Tcountry = 'MNE (Montenegro)'
        break
      case 'Mongolia':
        Tcountry = 'MNG (Mongolia)'
        break
      case 'Mozambique':
        Tcountry = 'MOZ (Mozambique)'
        break
      case 'Mauritania':
        Tcountry = 'MRT (Mauritania)'
        break
      case 'Montserrat':
        Tcountry = 'MSR (Montserrat)'
        break
      case 'Mauritius':
        Tcountry = 'MUS (Mauritius)'
        break
      case 'Malawi':
        Tcountry = 'MWI (Malawi)'
        break
      case 'Malaysia':
        Tcountry = 'MYS (Malaysia)'
        break
      case 'Namibia':
        Tcountry = 'NAM (Namibia)'
        break
      case 'New Caledonia':
        Tcountry = 'NCL (New Caledonia)'
        break
      case 'Niger':
        Tcountry = 'NER (Niger)'
        break
      case 'Nigeria':
        Tcountry = 'NGA (Nigeria)'
        break
      case 'Nicaragua':
        Tcountry = 'NIC (Nicaragua)'
        break
      case 'Niue':
        Tcountry = 'NIU (Niue)'
        break
      case 'Netherlands':
        Tcountry = 'NLD (Netherlands)'
        break
      case 'Norway':
        Tcountry = 'NOR (Norway)'
        break
      case 'Nepal':
        Tcountry = 'NPL (Nepal)'
        break
      case 'Nauru':
        Tcountry = 'NRU (Nauru)'
        break
      case 'New Zealand':
        Tcountry = 'NZL (New Zealand)'
        break
      case 'Oman':
        Tcountry = 'OMN (Oman)'
        break
      case 'Pakistan':
        Tcountry = 'PAK (Pakistan)'
        break
      case 'Panama':
        Tcountry = 'PAN (Panama)'
        break
      case 'Pitcairn Islands':
        Tcountry = 'PCN (Pitcairn Islands)'
        break
      case 'Peru':
        Tcountry = 'PER (Peru)'
        break
      case 'Philippines':
        Tcountry = 'PHL (Philippines)'
        break
      case 'Republic of Palau':
        Tcountry = 'PLW (Republic of Palau)'
        break
      case 'Papua New Guinea':
        Tcountry = 'PNG (Papua New Guinea)'
        break
      case 'Poland':
        Tcountry = 'POL (Poland)'
        break
      case 'Puerto Rico':
        Tcountry = 'PRI (Puerto Rico)'
        break
      case 'Korea, North':
        Tcountry = 'PRK (Korea, North (DPRK))'
        break
      case 'Portugal':
        Tcountry = 'PRT (Portugal)'
        break
      case 'Paraguay':
        Tcountry = 'PRY (Paraguay)'
        break
      case 'Palestinian Authority':
        Tcountry = 'PSE (Palestinian Authority)'
        break
      case 'French Polynesia':
        Tcountry = 'PYF (French Polynesia)'
        break
      case 'Qatar':
        Tcountry = 'QAT (Qatar)'
        break
      case 'Reunion':
        Tcountry = 'REU (Reunion)'
        break
      case 'Romania':
        Tcountry = 'ROU (Romania)'
        break
      case 'Russia':
        Tcountry = 'RUS (Russia)'
        break
      case 'Rwanda':
        Tcountry = 'RWA (Rwanda)'
        break
      case 'Saudi Arabia':
        Tcountry = 'SAU (Saudi Arabia)'
        break
      case 'Sudan':
        Tcountry = 'SDN (Sudan)'
        break
      case 'Senegal':
        Tcountry = 'SEN (Senegal)'
        break
      case 'Singapore':
        Tcountry = 'SGP (Singapore)'
        break
      case 'South Georgia and South Sandwich Islands':
        Tcountry = 'SGS (South Georgia and South Sandwich Islands)'
        break
      case 'Saint Helena':
        Tcountry = 'SHN (Saint Helena)'
        break
      case 'Svalbard and Jan Mayen Islands':
        Tcountry = 'SJM (Svalbard and Jan Mayen Islands)'
        break
      case 'Solomon Islands':
        Tcountry = 'SLB (Solomon Islands)'
        break
      case 'Sierra Leone':
        Tcountry = 'SLE (Sierra Leone)'
        break
      case 'El Salvador':
        Tcountry = 'SLV (El Salvador)'
        break
      case 'San Marino':
        Tcountry = 'SMR (San Marino)'
        break
      case 'Somalia':
        Tcountry = 'SOM (Somalia)'
        break
      case 'Saint Pierre and Miquelon':
        Tcountry = 'SPM (Saint Pierre and Miquelon)'
        break
      case 'Serbia, Republic of':
        Tcountry = 'SRB (Serbia, Republic of)'
        break
      case 'South Sudan':
        Tcountry = 'SSD (South Sudan)'
        break
      case 'Sao Tome and Principe':
        Tcountry = 'STP (Sao Tome and Principe)'
        break
      case 'Suriname':
        Tcountry = 'SUR (Suriname)'
        break
      case 'Slovakia':
        Tcountry = 'SVK (Slovakia)'
        break
      case 'Slovenia':
        Tcountry = 'SVN (Slovenia)'
        break
      case 'Sweden':
        Tcountry = 'SWE (Sweden)'
        break
      case 'Swaziland':
        Tcountry = 'SWZ (Swaziland)'
        break
      case 'Seychelles':
        Tcountry = 'SYC (Seychelles)'
        break
      case 'Syria':
        Tcountry = 'SYR (Syria)'
        break
      case 'Turks and Caicos Islands':
        Tcountry = 'TCA (Turks and Caicos Islands)'
        break
      case 'Chad':
        Tcountry = 'TCD (Chad)'
        break
      case 'Togo':
        Tcountry = 'TGO (Togo)'
        break
      case 'Thailand':
        Tcountry = 'THA (Thailand)'
        break
      case 'Tajikistan':
        Tcountry = 'TJK (Tajikistan)'
        break
      case 'Tokelau':
        Tcountry = 'TKL (Tokelau)'
        break
      case 'Turkmenistan':
        Tcountry = 'TKM (Turkmenistan)'
        break
      case 'East Timor':
        Tcountry = 'TLS (East Timor)'
        break
      case 'Tonga':
        Tcountry = 'TON (Tonga)'
        break
      case 'Trinidad and Tobago':
        Tcountry = 'TTO (Trinidad and Tobago)'
        break
      case 'Tunisia':
        Tcountry = 'TUN (Tunisia)'
        break
      case 'Turkey':
        Tcountry = 'TUR (Turkey)'
        break
      case 'Tuvalu':
        Tcountry = 'TUV (Tuvalu)'
        break
      case 'Taiwan':
        Tcountry = 'TWN (Taiwan)'
        break
      case 'Tanzania':
        Tcountry = 'TZA (Tanzania)'
        break
      case 'Uganda':
        Tcountry = 'UGA (Uganda)'
        break
      case 'Ukraine':
        Tcountry = 'UKR (Ukraine)'
        break
      case 'UN specialized agency':
        Tcountry = 'UNA (UN specialized agency)'
        break
      case 'UN or official':
        Tcountry = 'UNO (UN or official)'
        break
      case 'Uruguay':
        Tcountry = 'URY (Uruguay)'
        break
      case 'United States':
        Tcountry = 'USA (United States of America)'
        break
      case 'Uzbekistan':
        Tcountry = 'UZB (Uzbekistan)'
        break
      case 'Vatican City':
        Tcountry = 'VAT (Vatican City)'
        break
      case 'St. Vincent and the Grenadines':
        Tcountry = 'VCT (St. Vincent and the Grenadines)'
        break
      case 'Venezuela':
        Tcountry = 'VEN (Venezuela)'
        break
      case 'British Virgin Islands':
        Tcountry = 'VGB (British Virgin Islands)'
        break
      case 'Vietnam':
        Tcountry = 'VNM (Vietnam)'
        break
      case 'Vanuatu':
        Tcountry = 'VUT (Vanuatu)'
        break
      case 'Samoa':
        Tcountry = 'WSM (Samoa)'
        break
      case 'Yemen':
        Tcountry = 'YEM (Yemen)'
        break
      case 'South Africa, Republic of':
        Tcountry = 'ZAF (South Africa, Republic of)'
        break
      case 'Zambia':
        Tcountry = 'ZMB (Zambia)'
        break
      case 'Zimbabwe':
        Tcountry = 'ZWE (Zimbabwe)'
        break
      default:
        Tcountry = 'Código de país no válido'
        break
    }

    return Tcountry
  }

  const setAnswers = () => {
    if (product.name === 'Visitor visa') {
      setVisaType('Visitor Visa')
    } else if (product.name === 'Transit visa') {
      setVisaType('Transit')
    } else {
      setVisaType('')
    }

    product.forms.map((form: any) => {
      let newcountry = ''
      let newcountry2 = ''
      let newcountry3 = ''
      let newcountry4 = ''
      let newcountry5 = ''
      let newcountry6 = ''

      // detalles personales
      if (form && form.formData && form.formData.guide === '001') {
        form.formData.questions.map((q: any) => {
          if (q.index === '003') {
            setGivenName(q.answer)
          }
          if (q.index === '002') {
            setFamilyName(q.answer)
          }
          if (q.index === '008') {
            setUCI(q.answer)
          }
          if (q.index === '004') {
            q.answer === 'Si' ?
              setAliasNameIndicator('Y') :
              q.answer === 'No' ?
                setAliasNameIndicator('N') :
                setAliasNameIndicator('')
          }
          if (q.index === '006') {
            setAliasFamilyName(q.answer)
          }
          if (q.index === '007') {
            setAliasGivenName(q.answer)
          }
          if (q.index === '009') {
            q.answer === 'Femenino' ?
              setSex('F Female') :
              q.answer === 'Masculino' ?
                setSex('M Male') :
                q.answer === 'Desconocido' ?
                  setSex('U Unknown') :
                  q.answer === 'Otro genero' ?
                    setSex('X Another gender') :
                    setSex('')
          }
          if (q.index === '011' && q.answer.trim() !== '') {
            if (q.answer !== '') {
              setDOBYear(q.answer.substring(0, 4))
              setDOBMonth(q.answer.substring(5, 7))
              setDOBDay(q.answer.substring(8))
            }
          }
          if (q.index === '011') {
            const birthDate = new Date(q.answer)
            const today = new Date()
            let ageDifference = today.getFullYear() - birthDate.getFullYear()
            const monthsDifference = today.getMonth() - birthDate.getMonth()
            if (monthsDifference < 0 ||
              (monthsDifference === 0 &&
                today.getDate() < birthDate.getDate())) {
              ageDifference--
            }
            const isAdult = ageDifference >= 18
            const currentAge = ageDifference
            setAge(currentAge.toString())
            setIsAdult(isAdult)
          }
          if (q.index === '012') {
            setPlaceBirthCity(q.answer)
          }
          if (q.index === '013') {
            setPlaceBirthCountry(q.answer)
          }
          if (q.index === '015') {
            setCitizenship(q.answer)
          }
          if (q.index === '016') {
            if (q.answer === 'Inglés') {
              setServiceIn('English')
            }
            if (q.answer === 'Francés') {
              setServiceIn('French')
            }
          }
        })
      }

      //current country of res
      if (form && form.formData && form.formData.guide === '002') {
        form.formData.questions.map((q: any) => {
          if (q.index === '002') {
            setCORCountry(q.answer)
          }
          if (q.index === '003') {
            if (q.answer === 'Ciudadano') {
              setCORStatus('01')
            }
            if (q.answer === 'Residente permanente') {
              setCORStatus('02')
            }
            if (q.answer === 'Visitante') {
              setCORStatus('03')
            }
            if (q.answer === 'Trabajador') {
              setCORStatus('04')
            }
            if (q.answer === 'Estudiante') {
              setCORStatus('05')
            }
            if (q.answer === 'Persona protegida') {
              setCORStatus('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setCORStatus('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setCORStatus('09')
            }
            if (q.answer === 'Otro') {
              setCORStatus('06')
            }
          }
          if (q.index === '004') {
            if (q.translate === true) {
              if (lang === 'original') {
                setCOROther(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setCOROther(q.answer)
                } else {
                  setCOROther(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setCOROther(q.answer)
                } else {
                  setCOROther(q.frenchAnswer)
                }
              }
            } else {
              setCOROther(q.answer)
            }
          }
          if ((q.index === '005' &&
            q.multipleAnswer.length > 0) ||
            (q.index === '006' &&
              q.multipleAnswer.length > 0) ||
            (q.index === '007' &&
              q.multipleAnswer.length > 0) ||
            (q.index === '008' &&
              q.multipleAnswer.length > 0)) {
            setCORDates(q.multipleAnswer)

            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[0]
                ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `])
            }

          }
          if ((q.index === '005' &&
            q.multipleAnswer.length > 0) ||
            (q.index === '006' &&
              q.multipleAnswer.length > 0) ||
            (q.index === '007' &&
              q.multipleAnswer.length > 0) ||
            (q.index === '008' &&
              q.multipleAnswer.length > 0 && q.multipleAnswer[0] !== '')) {

            setCORYearFrom(q.multipleAnswer[0].substring(0, 4))
            setCORMonthFrom(q.multipleAnswer[0].substring(5, 7))
            setCORDayFrom(q.multipleAnswer[0].substring(8))
          }
          if ((q.index === '005' &&
            q.multipleAnswer.length > 0) ||
            (q.index === '006' &&
              q.multipleAnswer.length > 0) ||
            (q.index === '007' &&
              q.multipleAnswer.length > 0) ||
            (q.index === '008' &&
              q.multipleAnswer.length > 0 && q.multipleAnswer[1] !== '')) {
            setCORYearTo(q.multipleAnswer[1].substring(0, 4))
            setCORMonthTo(q.multipleAnswer[1].substring(5, 7))
            setCORDayTo(q.multipleAnswer[1].substring(8))
          }
          if (q.index === '009') {
            q.answer === 'Si' ?
              setSameAsPCRIndicator('Y') :
              q.answer === 'No' ?
                setSameAsPCRIndicator('N') :
                setSameAsPCRIndicator('')
          }
          if (q.index === '011') {
            setSameAsCORCountry(q.answer)
          }
          if (q.index === '012') {
            if (q.answer === 'Ciudadano') {
              setSameAsCORCountryStatus('01')
            }
            if (q.answer === 'Residente permanente') {
              setSameAsCORCountryStatus('02')
            }
            if (q.answer === 'Visitante') {
              setSameAsCORCountryStatus('03')
            }
            if (q.answer === 'Trabajador') {
              setSameAsCORCountryStatus('04')
            }
            if (q.answer === 'Estudiante') {
              setSameAsCORCountryStatus('05')
            }
            if (q.answer === 'Persona protegida') {
              setSameAsCORCountryStatus('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setSameAsCORCountryStatus('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setSameAsCORCountryStatus('09')
            }
            if (q.answer === 'Otro') {
              setSameAsCORCountryStatus('06')
            }
          }
          if (q.index === '013') {
            if (q.translate === true) {
              if (lang === 'original') {
                setSameAsCORCountryOther(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setSameAsCORCountryOther(q.answer)
                } else {
                  setSameAsCORCountryOther(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setSameAsCORCountryOther(q.answer)
                } else {
                  setSameAsCORCountryOther(q.frenchAnswer)
                }
              }
            } else {
              setSameAsCORCountryOther(q.answer)
            }
          }
          if ((q.index === '014' &&
            q.multipleAnswer.length > 0) ||
            (q.index === '015' && q.multipleAnswer.length > 0) ||
            (q.index === '016' && q.multipleAnswer.length > 0) ||
            (q.index === '017' && q.multipleAnswer.length > 0)) {
            setSameAsCORDates(q.multipleAnswer)
            if (
              (q.multipleAnswer[0] &&
                q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[0]
                ) === true)) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `])
            }
          }
          if ((q.index === '014' &&
            q.multipleAnswer.length > 0) ||
            (q.index === '015' && q.multipleAnswer.length > 0) ||
            (q.index === '016' && q.multipleAnswer.length > 0) ||
            (q.index === '017' && q.multipleAnswer.length > 0 && q.multipleAnswer[0] !== '')) {

            setSameAsCORYearFrom(q.multipleAnswer[0].substring(0, 4))
            setSameAsCORMonthFrom(q.multipleAnswer[0].substring(5, 7))
            setSameAsCORDayFrom(q.multipleAnswer[0].substring(8))
          }
          if ((q.index === '014' && q.multipleAnswer.length > 0) ||
            (q.index === '015' && q.multipleAnswer.length > 0) ||
            (q.index === '016' && q.multipleAnswer.length > 0) ||
            (q.index === '017' && q.multipleAnswer.length > 0 && q.multipleAnswer[1] !== '')) {
            setSameAsCORYearTo(q.multipleAnswer[1].substring(0, 4))
            setSameAsCORMonthTo(q.multipleAnswer[1].substring(5, 7))
            setSameAsCORDayTo(q.multipleAnswer[1].substring(8))
          }
        })
      }

      //previous country of res
      if (form && form.formData && form.formData.guide === '003') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setPCRIndicator('Y') :
              q.answer === 'No' ?
                setPCRIndicator('N') :
                setPCRIndicator('')
          }
          if (q.index === '003') {
            setPCRCountry(q.answer)
          }
          if (q.index === '004') {
            if (q.answer === 'Ciudadano') {
              setPCRStatus('01')
            }
            if (q.answer === 'Residente permanente') {
              setPCRStatus('02')
            }
            if (q.answer === 'Visitante') {
              setPCRStatus('03')
            }
            if (q.answer === 'Trabajador') {
              setPCRStatus('04')
            }
            if (q.answer === 'Estudiante') {
              setPCRStatus('05')
            }
            if (q.answer === 'Persona protegida') {
              setPCRStatus('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setPCRStatus('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setPCRStatus('09')
            }
            if (q.answer === 'Otro') {
              setPCRStatus('06')
            }
          }
          if (q.index === '006') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPCROther(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPCROther(q.answer)
                } else {
                  setPCROther(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPCROther(q.answer)
                } else {
                  setPCROther(q.frenchAnswer)
                }
              }
            } else {
              setPCROther(q.answer)
            }
          }
          if (q.index === '005') {
            setPCRDates(q.multipleAnswer)
            if (
              (q.multipleAnswer[0] &&
                q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[0]
                ) === true
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[1]
                ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Indique las fechas de su estatus legal `])
            }
          }
          if (q.index === '005') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer.length > 0) {
                if (q.multipleAnswer[0] !== '') {
                  setPCRYearFrom(q.multipleAnswer[0].substring(0, 4))
                  setPCRMonthFrom(q.multipleAnswer[0].substring(5, 7))
                  setPCRDayFrom(q.multipleAnswer[0].substring(8))
                }
                if (q.multipleAnswer[1] !== '') {
                  setPCRYearTo(q.multipleAnswer[1].substring(0, 4))
                  setPCRMonthTo(q.multipleAnswer[1].substring(5, 7))
                  setPCRDayTo(q.multipleAnswer[1].substring(8))
                }
              }
            }
          }
          if (q.index === '008') {
            setPCRCountry2(q.answer)
          }
          if (q.index === '009') {
            if (q.answer === 'Ciudadano') {
              setPCRStatus2('01')
            }
            if (q.answer === 'Residente permanente') {
              setPCRStatus2('02')
            }
            if (q.answer === 'Visitante') {
              setPCRStatus2('03r')
            }
            if (q.answer === 'Trabajador') {
              setPCRStatus2('04')
            }
            if (q.answer === 'Estudiante') {
              setPCRStatus2('05')
            }
            if (q.answer === 'Persona protegida') {
              setPCRStatus2('07')
            }
            if (q.answer === 'Solicitante de refugio') {
              setPCRStatus2('08')
            }
            if (q.answer === 'Nacional extranjero') {
              setPCRStatus2('09')
            }
            if (q.answer === 'Otro') {
              setPCRStatus2('06')
            }
          }
          if (q.index === '011') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPCROther2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPCROther2(q.answer)
                }
                else {
                  setPCROther2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPCROther2(q.answer)
                } else {
                  setPCROther2(q.frenchAnswer)
                }
              }
            } else {
              setPCROther2(q.answer)
            }
          }
          if (q.index === '010') {
            setPCRDates2(q.multipleAnswer)
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[0]
                ) === true
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[1]
                ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Indique las fechas de su estatus legal `])
            }
          }
          if (q.index === '010') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length === 2) {
              if (q.multipleAnswer.length > 0) {
                if (q.multipleAnswer[0] !== '') {
                  setPCRYearFrom2(q.multipleAnswer[0].substring(0, 4))
                  setPCRMonthFrom2(q.multipleAnswer[0].substring(5, 7))
                  setPCRDayFrom2(q.multipleAnswer[0].substring(8))
                }
                if (q.multipleAnswer[1] !== '') {
                  setPCRYearTo2(q.multipleAnswer[1].substring(0, 4))
                  setPCRMonthTo2(q.multipleAnswer[1].substring(5, 7))
                  setPCRDayTo2(q.multipleAnswer[1].substring(8))
                }
              }
            }
          }
        })
      }

      //Estado civil actual
      if (form && form.formData && form.formData.guide === '004') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            if (q.answer === 'Soltero') {
              setmaritalStatus('02')
            }
            if (q.answer === 'Casado') {
              setmaritalStatus('01')
            }
            if (q.answer === 'Legalmente separado') {
              setmaritalStatus('05')
            }
            if (q.answer === 'Unión de hecho (12 meses de convivencia)') {
              setmaritalStatus('03')
            }
            if (q.answer === 'Matrimonio anulado') {
              setmaritalStatus('09')
            }
            if (q.answer === 'Divorciado') {
              setmaritalStatus('04')
            }
            if (q.answer === 'Viudo') {
              setmaritalStatus('06')
            }
            if (q.answer === 'Desconocido') {
              setmaritalStatus('00')
            }
          }
          if ((q.index === '002' && q.answer !== '') ||
            (q.index === '006' && q.answer !== '')) {
            setmaritalStatusDate(q.answer)
            if ((q.answer) &&
              (isDateGreaterThanToday(
                q.answer
              ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en estado civil actual `])
            }
          }
          if ((q.index === '002' && q.answer !== '') ||
            (q.index === '006' && q.answer !== '')) {
            setMaritalStatusYear(q.answer.substring(0, 4))
            setMaritalStatusMonth(q.answer.substring(5, 7))
            setMaritalStatusDay(q.answer.substring(8))
          }
          if ((q.index === '005' && q.answer !== '') ||
            (q.index === '009' && q.answer !== '')) {
            setMaritalStatusGivenName(q.answer)
          }
          if ((q.index === '004' && q.answer !== '') ||
            (q.index === '008' && q.answer !== '')) {
            setMaritalStatusFamilyName(q.answer)
          }
          if (q.index === '012') {
            q.answer === 'Si' ?
              setPrevMarriedIndicator('Y') :
              q.answer === 'No' ?
                setPrevMarriedIndicator('N') :
                setPrevMarriedIndicator('')
          }
          if (q.index === '016' && q.answer !== '') {
            if (
              (q.answer) &&
              (isDateGreaterThanToday(
                q.answer
              ) === true)) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Fecha de nacimiento `])
            }
            if (q.answer !== '') {
              setPMDateOfBirthY(q.answer.substring(0, 4))
              setPMDateOfBirthM(q.answer.substring(5, 7))
              setPMDateOfBirthD(q.answer.substring(8))
            }
          }
          if (q.index === '014') {
            setPMFamilyName(q.answer)
          }
          if (q.index === '015') {
            setPMFamilyNameGivenName(q.answer)
          }
          if (q.index === '017') {
            setPMTypeOfRelationship(q.answer)
          }
          if (q.index === '018') {
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[0]
                ) === true
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[1]
                ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Fecha de inicio y finalización de la relación `])
            }
            setPMDateFromDate(q.multipleAnswer[0])
            setPMDateToDate(q.multipleAnswer[1])
            if (q.multipleAnswer.length > 0) {
              if (q.multipleAnswer[0] !== '') {
                setPreviouslyMarriedDatesFromY(q.multipleAnswer[0].substring(0, 4))
                setPreviouslyMarriedDatesFromM(q.multipleAnswer[0].substring(5, 7))
                setPreviouslyMarriedDatesFromD(q.multipleAnswer[0].substring(8))
              }
              if (q.multipleAnswer[1] !== '') {
                setPreviouslyMarriedDatesToY(q.multipleAnswer[1].substring(0, 4))
                setPreviouslyMarriedDatesToM(q.multipleAnswer[1].substring(5, 7))
                setPreviouslyMarriedDatesToD(q.multipleAnswer[1].substring(8))
              }
            }
          }
        })
      }

      //Pasaporte
      if (form && form.formData && form.formData.guide === '006') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            setPassportNum(q.answer)
          }
          if (q.index === '003') {
            const countryOfIssue = countries(q.answer)
            setPassportCountryOfIssue(countryOfIssue)
          }
          if (q.index === '004') {
            if (q.multipleAnswer && q.multipleAnswer[0] !== '') {
              setPassportDateOfIssue(q.multipleAnswer[0])
              setIssueY(q.multipleAnswer[0].substring(0, 4))
              setIssueM(q.multipleAnswer[0].substring(5, 7))
              setIssueD(q.multipleAnswer[0].substring(8))
            }
          }
          if (q.index === '004') {
            if (q.multipleAnswer && q.multipleAnswer[1] !== '') {
              setPassportExpireDate(q.multipleAnswer[1])
              setExpiryY(q.multipleAnswer[1].substring(0, 4))
              setExpiryM(q.multipleAnswer[1].substring(5, 7))
              setExpiryD(q.multipleAnswer[1].substring(8))
            }
          }
          if (passportDateOfIssue && passportExpireDate) {
            if ((passportDateOfIssue && passportExpireDate) &&
              (compareDates(
                passportDateOfIssue,
                passportExpireDate
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  passportDateOfIssue
                ) === true
                ||
                isDateGreaterThanToday(
                  passportExpireDate
                ) === false
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Fecha de emision Fecha de caducidad `])
            }
          }
        })
      }

      //Idiomas
      if (form && form.formData && form.formData.guide === '005') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            setNativeLang(q.answer)
          }
          if (q.index === '002') {
            if (q.answer === 'English') {
              setAbleToCommunicate('English')
            }
            if (q.answer === 'French') {
              setAbleToCommunicate('French')
            }
            if (q.answer === 'Ambos') {
              setAbleToCommunicate('Both')
            }
            if (q.answer === 'Ninguno') {
              setAbleToCommunicate('Neither')
            }
          }
          if (q.index === '003') {
            if (q.answer === 'English') {
              setLOV(q.answer)
            }
            if (q.answer === 'French') {
              setLOV(q.answer)
            }
          }
          if (q.index === '004') {
            q.answer === 'Si' ?
              setLanguageTest('Y') :
              q.answer === 'No' ?
                setLanguageTest('N') :
                setLanguageTest('')
          }
        })
      }

      //Documento nacional de identidad
      if (form && form.formData && form.formData.guide === '007') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setNatIDIndicator('Y') :
              q.answer === 'No' ?
                setNatIDIndicator('N') :
                setNatIDIndicator('')
          }
          if (q.index === '002') {
            setDocNum(q.answer)
          }
          if (q.index === '003') {
            const countryOfIssue = countries(q.answer)
            setIDCountryofIssue(countryOfIssue)
          }
          if (q.index === '004') {
            if (q.multipleAnswer && q.multipleAnswer[0] !== '') {
              setIDIssueDate(q.multipleAnswer[0])
            }
          }
          if (q.index === '004') {
            if (q.multipleAnswer && q.multipleAnswer[1] !== '') {
              setIDExpiryDate(q.multipleAnswer[1])
            }
          }
          if (IDIssueDate && IDExpiryDate) {
            if ((IDIssueDate && IDExpiryDate) &&
              (compareDates(
                IDIssueDate,
                IDExpiryDate
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  IDIssueDate
                ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Fecha de emisión Fecha de caducidad`])
            }
          }
        })
      }

      //Tarjeta de residente permanente (PR) de EE. UU.
      if (form && form.formData && form.formData.guide === '008') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setusCardIndicator('Y') :
              q.answer === 'No' ?
                setusCardIndicator('N') :
                setusCardIndicator('')
          }
          if (q.index === '002') {
            setusCardDocNum(q.answer)
          }
          if (q.index === '003') {
            setusCardExpiryDate(q.answer)
          }
          if (usCardDocNum) {
            if (
              (
                isDateGreaterThanToday(
                  usCardDocNum
                ) === true
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Fecha de caducidad`])
            }
          }
        })
      }

      //Dirección residencial principal
      if (form && form.formData && form.formData.guide === '009') {
        form.formData.questions.map((q: any) => {
          if (q.index === '002') {
            setPOBox(q.answer)
          }
          if (q.index === '003') {
            setAptUnit(q.answer)
          }
          if (q.index === '004') {
            setStreetNum(q.answer)
          }
          if (q.index === '005') {
            setStreetname(q.answer)
          }
          if (q.index === '006') {
            setCityTown(q.answer)
          }
          if (q.index === '007') {
            setCountry(q.answer)
            newcountry = q.answer
          }
          if (q.index === '008' && (newcountry === 'United States')) {
            setProvinceState(q.answer)
          }
          if (q.index === '043' && (newcountry === 'Canada')) {
            setProvinceState(q.answer)
          }
          if (q.index === '009') {
            setPostalCode(q.answer)
          }
          if (q.index === '010') {
            setDistrict(q.answer)
          }
          if (q.index === '011') {
            q.answer === 'Si' ?
              setSameAsMailingIndicator('Y') :
              q.answer === 'No' ?
                setSameAsMailingIndicator('N') :
                setSameAsMailingIndicator('')
          }
          if (q.index === '012') {
            setSameAsMailingIndicatorAptUnit(q.answer)
          }
          if (q.index === '013') {
            setSameAsMailingIndicatorStreetNum(q.answer)
          }
          if (q.index === '014') {
            setSameAsMailingIndicatorStreetname(q.answer)
          }
          if (q.index === '015') {
            setSameAsMailingIndicatorCityTown(q.answer)
          }
          if (q.index === '016') {
            setSameAsMailingIndicatorCountry(q.answer)
            newcountry2 = q.answer
          }
          if (q.index === '017' && (newcountry2 === 'United States')) {
            setSameAsMailingIndicatorProvince(q.answer)
          }
          if (q.index === '044' && (newcountry2 === 'Canada')) {
            setSameAsMailingIndicatorProvince(q.answer)
          }
          if (q.index === '018') {
            setSameAsMailingIndicatorPostalCode(q.answer)
          }
          if (q.index === '019') {
            setSameAsMailingIndicatorDistrict(q.answer)
          }
        })
      }

      //Propósito de la solicitud (visita)
      if (form && form.formData && form.formData.guide === '025') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            if (q.answer === 'Negocios') {
              setPurposeOfVisit('Business')
            }
            if (q.answer === 'Turismo') {
              setPurposeOfVisit('Tourism')
            }
            if (q.answer === 'Estudios de corta duración (curso o programa de seis meses o menos)') {
              setPurposeOfVisit('Short-Term Studies')
            }
            if (q.answer === 'Estudiante que regresa') {
              setPurposeOfVisit('Returning Student')
            }
            if (q.answer === 'Trabajador que regresa') {
              setPurposeOfVisit('Returning Worker')
            }
            if (q.answer === 'Súper visa - Padres y abuelos') {
              setPurposeOfVisit('Super Visa: For Parents or Grandparents')
            }
            if (q.answer === 'Visita familiar') {
              setPurposeOfVisit('Family Visit')
            }
            if (q.answer === 'Visita') {
              setPurposeOfVisit('Visit')
            }
            if (q.answer === 'Otro') {
              setPurposeOfVisit('Other')
            }
          }
          if (q.index === '002') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPurposeOfVisitOther(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPurposeOfVisitOther(q.answer)
                } else {
                  setPurposeOfVisitOther(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPurposeOfVisitOther(q.answer)
                } else {
                  setPurposeOfVisitOther(q.frenchAnswer)
                }
              }
            } else {
              setPurposeOfVisitOther(q.answer)
            }
          }
          if (q.index === '003' &&
            Array.isArray(q.multipleAnswer) &&
            q.multipleAnswer.length === 2) {
            if (
              (q.multipleAnswer[0] &&
                q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1]
              ) === "Mayor"
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[0]
                ) === false
                ||
                isDateGreaterThanToday(
                  q.multipleAnswer[1]
                ) === false
              )) {
              setArrayValidator((errors: any) =>
                [...errors, `Error en ${form.formData.formName} en Fecha `])
            }
            if (q.multipleAnswer.length > 0) {
              setHowLongStay(q.multipleAnswer)
              if (q.multipleAnswer[0] !== '') {
                setHowLongStayFromY(q.multipleAnswer[0].substring(0, 4))
                setHowLongStayFromM(q.multipleAnswer[0].substring(5, 7))
                setHowLongStayFromD(q.multipleAnswer[0].substring(8))
              }
              if (q.multipleAnswer[1] !== '') {
                setHowLongStayToY(q.multipleAnswer[1].substring(0, 4))
                setHowLongStayToM(q.multipleAnswer[1].substring(5, 7))
                setHowLongStayToD(q.multipleAnswer[1].substring(8))
              }
            }
          }
          if (q.index === '007') {
            setContacts1Name(q.answer)
          }
          if (q.index === '008') {
            if (q.translate === true) {
              if (lang === 'original') {
                setContacts1RelationshipToMe(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setContacts1RelationshipToMe(q.answer)
                } else {
                  setContacts1RelationshipToMe(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setContacts1RelationshipToMe(q.answer)
                } else {
                  setContacts1RelationshipToMe(q.frenchAnswer)
                }
              }
            } else {
              setContacts1RelationshipToMe(q.answer)
            }
          }
          if (q.index === '009') {
            setContacts1AddressInCanada(q.answer)
          }
          if (q.index === '011') {
            setContacts2Name(q.answer)
          }
          if (q.index === '012') {
            if (q.translate === true) {
              if (lang === 'original') {
                setContacts2RelationshipToMe(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setContacts2RelationshipToMe(q.answer)
                } else {
                  setContacts2RelationshipToMe(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setContacts2RelationshipToMe(q.answer)
                } else {
                  setContacts2RelationshipToMe(q.frenchAnswer)
                }
              }
            } else {
              setContacts2RelationshipToMe(q.answer)
            }
          }
          if (q.index === '013') {
            setContacts2AddressInCanada(q.answer)
          }
        })
      }

      //Educación
      if (form && form.formData && form.formData.guide === '010') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setEducationIndicator('Y') :
              q.answer === 'No' ?
                setEducationIndicator('N') :
                setEducationIndicator('')
          }
          if (q.index === '003') {
            if (Array.isArray(q.multipleAnswer)) {
              if (q.multipleAnswer.length > 0) {
                if (
                  (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
                  (compareDates(
                    q.multipleAnswer[0],
                    q.multipleAnswer[1]
                  ) === "Mayor"
                  )) {
                  setArrayValidator((errors: any) =>
                    [...errors, `Error en ${form.formData.formName} en Fecha `])
                }
                if (q.multipleAnswer[0] !== '') {
                  setEducationDatesFromY(q.multipleAnswer[0].substring(0, 4))
                  setEducationDatesFromM(q.multipleAnswer[0].substring(5, 7))
                }
                if (q.multipleAnswer[1] !== '') {
                  setEducationDatesToY(q.multipleAnswer[1].substring(0, 4))
                  setEducationDatesToM(q.multipleAnswer[1].substring(5, 7))
                }
              }
            }
          }
          if (q.index === '004') {
            if (q.translate === true) {
              if (lang === 'original') {
                setFieldOfStudy(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setFieldOfStudy(q.answer)
                } else {
                  setFieldOfStudy(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setFieldOfStudy(q.answer)
                } else {
                  setFieldOfStudy(q.frenchAnswer)
                }
              }
            } else {
              setFieldOfStudy(q.answer)
            }
          }
          if (q.index === '005') {
            setSchool(q.answer)
          }
          if (q.index === '006') {
            setSchoolCityTown(q.answer)
            newcountry3 = q.answer
          }
          if (q.index === '007' && newcountry3 === 'United States') {
            setSchoolProvince(q.answer)
          }
          if (q.index === '058' && newcountry3 === 'Canada') {
            setSchoolProvince(q.answer)
          }
          if (q.index === '008') {
            setSchoolCountry(q.answer)
          }
        })
      }

      //Ocupación o empleo (actual y anteriores)
      if (form && form.formData && form.formData.guide === '011') {
        form.formData.questions.map((q: any) => {
          if (q.index === '003' && q.answer !== '') {
            setOcopation1DatesFrom(q.answer)
            if (q.answer !== '') {
              setOcopation1DatesFromY(q.answer.substring(0, 4))
              setOcopation1DatesFromM(q.answer.substring(5, 7))
            }
          }
          if (q.index === '004' && q.answer !== '') {
            setOcopation1DatesTo(q.answer)
            if (q.answer !== '') {
              setOcopation1DatesToY(q.answer.substring(0, 4))
              setOcopation1DatesToM(q.answer.substring(5, 7))
            }
          }
          if (
            (Ocopation1DatesFrom && Ocopation1DatesTo) &&
            (compareDates(
              Ocopation1DatesFrom,
              Ocopation1DatesTo
            ) === "Mayor"
            )) {
            setArrayValidator((errors: any) =>
              [...errors, `Error en ${form.formData.formName} en Fecha `])
          }
          if (q.index === '005') {
            if (q.translate === true) {
              if (lang === 'original') {
                setOcupation1(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setOcupation1(q.answer)
                } else {
                  setOcupation1(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setOcupation1(q.answer)
                } else {
                  setOcupation1(q.frenchAnswer)
                }
              }
            } else {
              setOcupation1(q.answer)
            }
          }
          if (q.index === '006') {
            setEmployer1(q.answer)
          }
          if (q.index === '007') {
            setOcupation1CityTown(q.answer)
          }
          if (q.index === '008') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setOcupation1Country('Bolivia')
            }
            else {
              setOcupation1Country(q.answer)
            }
            newcountry4 = q.answer
          }
          if (q.index === '009' && newcountry4 === 'United States') {
            setOcupation1Province(q.answer)
          }
          if (q.index === '027' && newcountry4 === 'Canada') {
            setOcupation1Province(q.answer)
          }
          if (q.index === '012') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length > 0) {
              if (
                (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
                (compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1]
                ) === "Mayor"
                )) {
                setArrayValidator((errors: any) =>
                  [...errors, `Error en ${form.formData.formName} en Fecha `])
              }
              if (q.multipleAnswer[0] !== '') {
                setOcopation2DatesFromY(q.multipleAnswer[0].substring(0, 4))
                setOcopation2DatesFromM(q.multipleAnswer[0].substring(5, 7))
              }
              if (q.multipleAnswer[1] !== '') {
                setOcopation2DatesToY(q.multipleAnswer[1].substring(0, 4))
                setOcopation2DatesToM(q.multipleAnswer[1].substring(5, 7))
              }
            }
          }
          if (q.index === '013') {
            if (q.translate === true) {
              if (lang === 'original') {
                setOcupation2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setOcupation2(q.answer)
                } else {
                  setOcupation2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setOcupation2(q.answer)
                } else {
                  setOcupation2(q.frenchAnswer)
                }
              }
            } else {
              setOcupation2(q.answer)
            }
          }
          if (q.index === '014') {
            setEmployer2(q.answer)
          }
          if (q.index === '015') {
            setOcupation2CityTown(q.answer)
          }
          if (q.index === '016') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setOcupation2Country('Bolivia')
            }
            else {
              setOcupation2Country(q.answer)
            }
            newcountry5 = q.answer
          }
          if (q.index === '017' && newcountry5 === 'United States') {
            setOcupation2Province(q.answer)
          }
          if (q.index === '028' && newcountry5 === 'Canada') {
            setOcupation2Province(q.answer)
          }
          if (q.index === '020') {
            if (Array.isArray(q.multipleAnswer) && q.multipleAnswer.length > 0) {
              if (
                (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
                (compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1]
                ) === "Mayor"
                )) {
                setArrayValidator((errors: any) =>
                  [...errors, `Error en ${form.formData.formName} en Fecha `])
              }
              if (q.multipleAnswer[0] !== '') {
                setOcopation3DatesFromY(q.multipleAnswer[0].substring(0, 4))
                setOcopation3DatesFromM(q.multipleAnswer[0].substring(5, 7))
              }
              if (q.multipleAnswer[1] !== '') {
                setOcopation3DatesToY(q.multipleAnswer[1].substring(0, 4))
                setOcopation3DatesToM(q.multipleAnswer[1].substring(5, 7))
              }
            }
          }
          if (q.index === '021') {
            if (q.translate === true) {
              if (lang === 'original') {
                setOcupation3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setOcupation3(q.answer)
                } else {
                  setOcupation3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setOcupation3(q.answer)
                } else {
                  setOcupation3(q.frenchAnswer)
                }
              }
            } else {
              setOcupation3(q.answer)
            }
          }
          if (q.index === '022') {
            setEmployer3(q.answer)
          }
          if (q.index === '023') {
            setOcupation3CityTown(q.answer)
          }
          if (q.index === '024') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setOcupation3Country('Bolivia')
            }
            else {
              setOcupation3Country(q.answer)
            }
            newcountry6 = q.answer
          }
          if (q.index === '029' && newcountry6 === 'Canada') {
            setOcupation3Province(q.answer)
          }
          if (q.index === '025' && newcountry6 === 'United States') {
            setOcupation3Province(q.answer)
          }
        })
      }

      //Declaración de antecedentes
      if (form && form.formData && form.formData.guide === '012') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setBackgroundInfoMedicalChoice1('Y') :
              q.answer === 'No' ?
                setBackgroundInfoMedicalChoice1('N') :
                setBackgroundInfoMedicalChoice1('')
          }
          if (q.index === '002') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBackgroundInfoMedicalDetails(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBackgroundInfoMedicalDetails(q.answer)
                } else {
                  setBackgroundInfoMedicalDetails(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBackgroundInfoMedicalDetails(q.answer)
                } else {
                  setBackgroundInfoMedicalDetails(q.frenchAnswer)
                }
              }
            } else {
              setBackgroundInfoMedicalDetails(q.answer)
            }
          }
          if (q.index === '003') {
            q.answer === 'Si' ?
              setBackgroundInfoMedicalChoice2('Y') :
              q.answer === 'No' ?
                setBackgroundInfoMedicalChoice2('N') :
                setBackgroundInfoMedicalChoice2('')
          }
          if (q.index === '004') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBackgroundInfoMedicalDetails2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBackgroundInfoMedicalDetails2(q.answer)
                } else {
                  setBackgroundInfoMedicalDetails2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBackgroundInfoMedicalDetails2(q.answer)
                } else {
                  setBackgroundInfoMedicalDetails2(q.frenchAnswer)
                }
              }
            } else {
              setBackgroundInfoMedicalDetails2(q.answer)
            }
          }
          if (q.index === '005') {
            q.answer === 'Si' ?
              setBackgroundInfoVisaChioce1('Y') :
              q.answer === 'No' ?
                setBackgroundInfoVisaChioce1('N') :
                setBackgroundInfoVisaChioce1('')
          }
          if (q.index === '006') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBackgroundInfoVisaChioceDetails(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBackgroundInfoVisaChioceDetails(q.answer)
                } else {
                  setBackgroundInfoVisaChioceDetails(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBackgroundInfoVisaChioceDetails(q.answer)
                } else {
                  setBackgroundInfoVisaChioceDetails(q.frenchAnswer)
                }
              }
            } else {
              setBackgroundInfoVisaChioceDetails(q.answer)
            }
          }
          if (q.index === '007') {
            q.answer === 'Si' ?
              setBackgroundInfoVisaChioce2('Y') :
              q.answer === 'No' ?
                setBackgroundInfoVisaChioce2('N') :
                setBackgroundInfoVisaChioce2('')
          }
          if (q.index === '008') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBackgroundInfoVisaChioceDetails2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBackgroundInfoVisaChioceDetails2(q.answer)
                } else {
                  setBackgroundInfoVisaChioceDetails2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBackgroundInfoVisaChioceDetails2(q.answer)
                } else {
                  setBackgroundInfoVisaChioceDetails2(q.frenchAnswer)
                }
              }
            } else {
              setBackgroundInfoVisaChioceDetails2(q.answer)
            }
          }
          if (q.index === '009') {
            q.answer === 'Si' ?
              setBackgroundInfoVisaChioce3('Y') :
              q.answer === 'No' ?
                setBackgroundInfoVisaChioce3('N') :
                setBackgroundInfoVisaChioce3('')
          }
          if (q.index === '010') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBackgroundInfoVisaChioceDetails3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBackgroundInfoVisaChioceDetails3(q.answer)
                } else {
                  setBackgroundInfoVisaChioceDetails3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBackgroundInfoVisaChioceDetails3(q.answer)
                } else {
                  setBackgroundInfoVisaChioceDetails3(q.frenchAnswer)
                }
              }
            } else {
              setBackgroundInfoVisaChioceDetails3(q.answer)
            }
          }
          if (q.index === '011') {
            q.answer === 'Si' ?
              setBackgroundInfo3Choice('Y') :
              q.answer === 'No' ?
                setBackgroundInfo3Choice('N') :
                setBackgroundInfo3Choice('')
          }
          if (q.index === '012') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBackgroundInfo3Details(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBackgroundInfo3Details(q.answer)
                } else {
                  setBackgroundInfo3Details(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBackgroundInfo3Details(q.answer)
                } else {
                  setBackgroundInfo3Details(q.frenchAnswer)
                }
              }
            } else {
              setBackgroundInfo3Details(q.answer)
            }
          }
        })
      }

      //Servicio militar y paramilitar
      if (form && form.formData && form.formData.guide === '016') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setPoliticalLoot('Y') :
              q.answer === 'No' ?
                setPoliticalLoot('N') :
                setPoliticalLoot('')
          }
        })
      }

      //Servicio militar y paramilitar
      if (form && form.formData && form.formData.guide === '013') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setMilitarChoice('Y') :
              q.answer === 'No' ?
                setMilitarChoice('N') :
                setMilitarChoice('')
          }
          /* first service */
          if (q.index === '003' && q.multipleAnswer.length > 0) {
            setMilitarDetails(q.multipleAnswer[0] + ' ' + q.multipleAnswer[1])
          }
          if (q.index === '006') {
            if (q.translate === true) {
              if (lang === 'original') {
                setMilitarDetails4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setMilitarDetails4(q.answer)
                } else {
                  setMilitarDetails4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setMilitarDetails4(q.answer)
                } else {
                  setMilitarDetails4(q.frenchAnswer)
                }
              }
            } else {
              setMilitarDetails4(q.answer)
            }
          }
          /* second service */
          if (q.index === '009' && q.multipleAnswer.length > 0) {
            setMilitarDetails5(q.multipleAnswer[0] + ' ' + q.multipleAnswer[1])
          }
          if (q.index === '012') {
            if (q.translate === true) {
              if (lang === 'original') {
                setMilitarDetails8(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setMilitarDetails8(q.answer)
                } else {
                  setMilitarDetails8(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setMilitarDetails8(q.answer)
                } else {
                  setMilitarDetails8(q.frenchAnswer)
                }
              }
            } else {
              setMilitarDetails8(q.answer)
            }
          }
          /* third service */
          if (q.index === '015' && q.multipleAnswer.length > 0) {
            setMilitarDetails9(q.multipleAnswer[0] + ' ' + q.multipleAnswer[1])
          }
          if (q.index === '018') {
            if (q.translate === true) {
              if (lang === 'original') {
                setMilitarDetails12(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setMilitarDetails12(q.answer)
                } else {
                  setMilitarDetails12(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setMilitarDetails12(q.answer)
                } else {
                  setMilitarDetails12(q.frenchAnswer)
                }
              }
            } else {
              setMilitarDetails12(q.answer)
            }
          }
          /* fourth service */
          if (q.index === '021' && q.multipleAnswer.length > 0) {
            setMilitarDetails13(q.multipleAnswer[0] + ' ' + q.multipleAnswer[1])
          }
          if (q.index === '024') {
            if (q.translate === true) {
              if (lang === 'original') {
                setMilitarDetails16(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setMilitarDetails16(q.answer)
                } else {
                  setMilitarDetails16(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setMilitarDetails16(q.answer)
                } else {
                  setMilitarDetails16(q.frenchAnswer)
                }
              }
            } else {
              setMilitarDetails16(q.answer)
            }
          }
          /* fifth service */
          if (q.index === '027' && q.multipleAnswer.length > 0) {
            setMilitarDetails17(q.multipleAnswer[0] + ' ' + q.multipleAnswer[1])
          }
          if (q.index === '030') {
            if (q.translate === true) {
              if (lang === 'original') {
                setMilitarDetails20(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setMilitarDetails20(q.answer)
                } else {
                  setMilitarDetails20(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setMilitarDetails20(q.answer)
                } else {
                  setMilitarDetails20(q.frenchAnswer)
                }
              }
            } else {
              setMilitarDetails20(q.answer)
            }
          }
        })
      }

      // Afiliación o asociación con organizaciones
      if (form && form.formData && form.formData.guide === '014') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si' ?
              setPoliticalAssociation('Y') :
              q.answer === 'No' ?
                setPoliticalAssociation('N') :
                setPoliticalAssociation('')
          }
        })
      }

      //	Fondos
      if (form && form.formData && form.formData.guide === '025') {
        form.formData.questions.map((q: any) => {
          if (q.index === '015') {
            setHowFounds(q.answer)
          }
        })
      }
    })
  }

  useEffect(() => {
    setAnswers()
  }, [])

  const handleUploadToServer = async () => {
    toast.info('Estamos procesando tu solicitud. Te notificaremos cuando esté listo. Tiempo de espera aproximado: 3 minutos.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: '#C3CEF6',
        height: '5rem',
        width: '27rem',
        marginLeft: '-7rem',
      },
    })
    connectSocket()
    setAnswers() // Asumo que esta función establece algunas respuestas

    // Aquí accedes a foundContract si está disponible
    if (contractsData) {
      // Extraemos el arreglo 'data' del objeto contractsData
      const dataArray = contractsData.data
      // Encontramos el objeto dentro de dataArray que tenga el mismo envelope_id que contractsFound
      const foundContract = dataArray.find(
        (contract: { contracts_users_envelope_id: any }) => contract.contracts_users_envelope_id === contractsFound
      )
      if (foundContract) {
        console.log('contractsData:', foundContract)
        // Creación del FormData y XML
        const updatedFormData = {
          form1: {
            Page1: {
              /* Age: "",
              AdultFlag: false, */
              PersonalDetails: {
                UCIClientID: UCI !== '' ? UCI : '',
                ServiceIn: {
                  ServiceIn: serviceIn !== '' ? serviceIn : '',
                },
                VisaType: {
                  VisaType: visaType !== '' ? visaType : '',
                },
                Name: {
                  FamilyName: familyName !== '' ? familyName : '',
                  GivenName: givenName !== '' ? givenName : '',
                },
                AliasName: {
                  AliasFamilyName: aliasFamilyName !== '' ? aliasFamilyName : '',
                  AliasGivenName: aliasGivenName !== '' ? aliasGivenName : '',
                  AliasNameIndicator: {
                    AliasNameIndicator: aliasNameIndicator,
                  },
                },
                Sex: {
                  Sex: sex !== '' ? sex : '',
                },
                DOBYear: DOBYear !== '' ? DOBYear : '',
                DOBMonth: DOBMonth !== '' ? DOBMonth : '',
                DOBDay: DOBDay !== '' ? DOBDay : '',
                PlaceBirthCity: placeBirthCity !== '' ? placeBirthCity : '',
                PlaceBirthCountry: placeBirthCountry !== '' ? placeBirthCountry : '',
                Citizenship: {
                  Citizenship: citizenship !== '' ? citizenship : '',
                },
                CurrentCOR: {
                  Row2: {
                    Country: CORCountry !== '' ? CORCountry : '',
                    Status: CORStatus !== '' ? CORStatus : '',
                    Other: COROther !== '' ? COROther : '',
                    FromDate: CORDates[0] !== '' ? CORDates[0] : '',
                    ToDate: CORDates[1] !== '' ? CORDates[1] : '',
                  },
                },
                CORDates: {
                  FromYr: CORYearFrom !== '' ? CORYearFrom : '',
                  FromMM: CORMonthFrom !== '' ? CORMonthFrom : '',
                  FromDD: CORDayFrom !== '' ? CORDayFrom : '',
                  ToYr: CORYearTo !== '' ? CORYearTo : '',
                  ToMM: CORMonthTo !== '' ? CORMonthTo : '',
                  ToDD: CORDayTo !== '' ? CORDayTo : '',
                },
                PCRIndicator: PCRIndicator,
                PreviousCOR: {
                  Row2: {
                    Country: PCRCountry !== '' ? PCRCountry : '',
                    Status: PCRStatus !== '' ? PCRStatus : '',
                    Other: PCROther !== '' ? PCROther : '',
                    FromDate: PCRDates[0] !== '' ? PCRDates[0] : '',
                    ToDate: PCRDates[1] !== '' ? PCRDates[1] : '',
                  },
                  Row3: {
                    Country: PCRCountry2 !== '' ? PCRCountry2 : '',
                    Status: PCRStatus2 !== '' ? PCRStatus2 : '',
                    Other: PCROther2 !== '' ? PCROther2 : '',
                    FromDate: PCRDates2[0] !== '' ? PCRDates2[0] : '',
                    ToDate: PCRDates2[1] !== '' ? PCRDates2[1] : '',
                  },
                },
                PCRDatesR1: {
                  FromYr: PCRYearFrom !== '' ? PCRYearFrom : '',
                  FromMM: PCRMonthFrom !== '' ? PCRMonthFrom : '',
                  FromDD: PCRDayFrom !== '' ? PCRDayFrom : '',
                  ToYr: PCRYearTo !== '' ? PCRYearTo : '',
                  ToMM: PCRMonthTo !== '' ? PCRMonthTo : '',
                  ToDD: PCRDayTo !== '' ? PCRDayTo : '',
                },
                PCRDatesR2: {
                  FromYr: PCRYearFrom2 !== '' ? PCRYearFrom2 : '',
                  FromMM: PCRMonthFrom2 !== '' ? PCRMonthFrom2 : '',
                  FromDD: PCRDayFrom2 !== '' ? PCRDayFrom2 : '',
                  ToYr: PCRYearTo2 !== '' ? PCRYearTo2 : '',
                  ToMM: PCRMonthTo2 !== '' ? PCRMonthTo2 : '',
                  ToDD: PCRDayTo2 !== '' ? PCRDayTo2 : '',
                },
                SameAsCORIndicator: SameAsPCRIndicator,
                CountryWhereApplying: {
                  Row2: {
                    Country: SameAsCORCountry !== '' ? SameAsCORCountry : '',
                    Status: SameAsCORCountryStatus !== '' ? SameAsCORCountryStatus : '',
                    Other: SameAsCORCountryOther !== '' ? SameAsCORCountryOther : '',
                    FromDate: SameAsCORDates[0] !== '' ? SameAsCORDates[0] : '',
                    ToDate: SameAsCORDates[1] !== '' ? SameAsCORDates[1] : '',
                  },
                },
                CWADates: {
                  FromYr: SameAsCORYearFrom !== '' ? SameAsCORYearFrom : '',
                  FromMM: SameAsCORMonthFrom !== '' ? SameAsCORMonthFrom : '',
                  FromDD: SameAsCORDayFrom !== '' ? SameAsCORDayFrom : '',
                  ToYr: SameAsCORYearTo !== '' ? SameAsCORYearTo : '',
                  ToMM: SameAsCORMonthTo !== '' ? SameAsCORMonthTo : '',
                  ToDD: SameAsCORDayTo !== '' ? SameAsCORDayTo : '',
                },
              },
              MaritalStatus: {
                SectionA: {
                  MaritalStatus: maritalStatus !== '' ? maritalStatus : '',
                  DateOfMarriage: maritalStatusDate !== '' ? maritalStatusDate : '',
                  MarriageDate: {
                    FromYr: maritalStatusYear !== '' ? maritalStatusYear : '',
                    FromMM: maritalStatusMonth !== '' ? maritalStatusMonth : '',
                    FromDD: maritalStatusDay !== '' ? maritalStatusDay : '',
                  },
                  FamilyName: maritalStatusFamilyName !== '' ? maritalStatusFamilyName : '',
                  GivenName: maritalStatusGivenName !== '' ? maritalStatusGivenName : '',
                },
              },
            },
            Page2: {
              MaritalStatus: {
                SectionA: {
                  PrevMarriedIndicator: prevMarriedIndicator,
                  PMFamilyName: PMFamilyName !== '' ? PMFamilyName : '',
                  GivenName: {
                    PMGivenName: PMFamilyNameGivenName !== '' ? PMFamilyNameGivenName : '',
                  },
                  PrevSpouseDOB: {
                    DOBYear: PMDateOfBirthY !== '' ? PMDateOfBirthY : '',
                    DOBMonth: PMDateOfBirthM !== '' ? PMDateOfBirthM : '',
                    DOBDay: PMDateOfBirthD !== '' ? PMDateOfBirthD : '',
                  },
                  TypeOfRelationship: PMTypeOfRelationship !== '' ? PMTypeOfRelationship : '',
                  FromDate: PMDateFromDate !== '' ? PMDateFromDate : '',
                  ToDate: {
                    ToDate: PMDateToDate !== '' ? PMDateToDate : '',
                  },
                  PreviouslyMarriedDates: {
                    FromYr: PreviouslyMarriedDatesFromY !== '' ? PreviouslyMarriedDatesFromY : '',
                    FromMM: PreviouslyMarriedDatesFromM !== '' ? PreviouslyMarriedDatesFromM : '',
                    FromDD: PreviouslyMarriedDatesFromD !== '' ? PreviouslyMarriedDatesFromD : '',
                    ToYr: PreviouslyMarriedDatesToY !== '' ? PreviouslyMarriedDatesToY : '',
                    ToMM: PreviouslyMarriedDatesToM !== '' ? PreviouslyMarriedDatesToM : '',
                    ToDD: PreviouslyMarriedDatesToD !== '' ? PreviouslyMarriedDatesToD : '',
                  },
                  Passport: {
                    PassportNum: {
                      PassportNum: passportNum !== '' ? passportNum : '',
                    },
                    CountryofIssue: {
                      CountryofIssue: passportCountryOfIssue !== '' ? passportCountryOfIssue : '',
                    },
                    IssueDate: {
                      IssueDate: passportDateOfIssue !== '' ? passportDateOfIssue : '',
                    },
                    ExpiryDate: passportExpireDate !== '' ? passportExpireDate : '',
                    IssueYYYY: IssueY !== '' ? IssueY : '',
                    IssueMM: IssueM !== '' ? IssueM : '',
                    IssueDD: IssueD !== '' ? IssueD : '',
                    expiryYYYY: expiryY !== '' ? expiryY : '',
                    expiryMM: expiryM !== '' ? expiryM : '',
                    expiryDD: expiryD !== '' ? expiryD : '',
                  },
                  Languages: {
                    languages: {
                      nativeLang: {
                        nativeLang: nativeLang !== '' ? nativeLang : '',
                      },
                      ableToCommunicate: {
                        ableToCommunicate: ableToCommunicate !== '' ? ableToCommunicate : '',
                      },
                      lov: LOV !== '' ? LOV : '',
                    },
                    LanguageTest: LanguageTest !== '' ? LanguageTest : '',
                  },
                },
              },
              natID: {
                q1: {
                  natIDIndicator: natIDIndicator !== '' ? natIDIndicator : '',
                },
                natIDdocs: {
                  DocNum: {
                    DocNum: DocNum !== '' ? DocNum : '',
                  },
                  CountryofIssue: {
                    CountryofIssue: IDCountryofIssue !== '' ? IDCountryofIssue : '',
                  },
                  IssueDate: {
                    IssueDate: IDIssueDate !== '' ? IDIssueDate : '',
                  },
                  ExpiryDate: IDExpiryDate !== '' ? IDExpiryDate : '',
                },
              },
              USCard: {
                q1: {
                  usCardIndicator: usCardIndicator,
                },
                usCarddocs: {
                  DocNum: {
                    DocNum: usCardDocNum !== '' ? usCardDocNum : '',
                  },
                  ExpiryDate: usCardExpiryDate !== '' ? usCardExpiryDate : '',
                },
              },
              ContactInformation: {
                contact: {
                  AddressRow1: {
                    POBox: {
                      POBox: POBox !== '' ? POBox : '',
                    },
                    Apt: {
                      AptUnit: AptUnit !== '' ? AptUnit : '',
                    },
                    StreetNum: {
                      StreetNum: StreetNum !== '' ? StreetNum : '',
                    },
                    Streetname: {
                      Streetname: Streetname !== '' ? Streetname : '',
                    },
                  },
                  AddressRow2: {
                    CityTow: {
                      CityTown: CityTown !== '' ? CityTown : '',
                    },
                    Country: {
                      Country: Country !== '' ? Country : '',
                    },
                    ProvinceState: {
                      ProvinceState: ProvinceState !== '' ? ProvinceState : '',
                    },
                    PostalCode: {
                      PostalCode: PostalCode !== '' ? PostalCode : '',
                    },
                    District: District !== '' ? District : '',
                  },
                  SameAsMailingIndicator: SameAsMailingIndicator,
                  ResidentialAddressRow1: {
                    AptUnit: {
                      AptUnit: SameAsMailingIndicatorAptUnit !== '' ? SameAsMailingIndicatorAptUnit : '',
                    },
                    StreetNum: {
                      StreetNum: SameAsMailingIndicatorStreetNum !== '' ? SameAsMailingIndicatorStreetNum : '',
                    },
                    StreetName: {
                      Streetname: SameAsMailingIndicatorStreetname !== '' ? SameAsMailingIndicatorStreetname : '',
                    },
                    CityTown: {
                      CityTown: SameAsMailingIndicatorCityTown !== '' ? SameAsMailingIndicatorCityTown : '',
                    },
                  },
                  ResidentialAddressRow2: {
                    Country: {
                      Country: SameAsMailingIndicatorCountry !== '' ? SameAsMailingIndicatorCountry : '',
                    },
                    ProvinceState: {
                      ProvinceState: SameAsMailingIndicatorProvince !== '' ? SameAsMailingIndicatorProvince : '',
                    },
                    PostalCode: {
                      PostalCode: SameAsMailingIndicatorPostalCode !== '' ? SameAsMailingIndicatorPostalCode : '',
                    },
                    District: SameAsMailingIndicatorDistrict !== '' ? SameAsMailingIndicatorDistrict : '',
                  },
                  PhoneNumbers: {
                    Phone: {
                      Type: 'Cellular',
                      CanadaUS: 1,
                      Other: 0,
                      NumberCountry: 1,
                      ActualNumber: 5146238146,
                      NANumber: {
                        AreaCode: 514,
                        FirstThree: 623,
                        LastFive: 8146,
                      },
                      IntlNumber: {
                        IntlNumber: 5146238146,
                      },
                    },
                  },
                  FaxEmail: {
                    Email: 'visas@immilandlaw.com',
                  },
                },
              },
            },
            Page3: {
              DetailsOfVisit: {
                PurposeRow1: {
                  PurposeOfVisit: {
                    PurposeOfVisit: PurposeOfVisit !== '' ? PurposeOfVisit : '',
                  },
                  Other: {
                    Other: PurposeOfVisitOther !== '' ? PurposeOfVisitOther : '',
                  },
                  HowLongStay: {
                    FromDate: HowLongStay[0] !== '' ? HowLongStay[0] : '',
                    ToDate: HowLongStay[1] !== '' ? HowLongStay[1] : '',
                    StayDates: {
                      FromYr: HowLongStayFromY !== '' ? HowLongStayFromY : '',
                      FromMM: HowLongStayFromM !== '' ? HowLongStayFromM : '',
                      FromDD: HowLongStayFromD !== '' ? HowLongStayFromD : '',
                      ToYr: HowLongStayToY !== '' ? HowLongStayToY : '',
                      ToMM: HowLongStayToM !== '' ? HowLongStayToM : '',
                      ToDD: HowLongStayToD !== '' ? HowLongStayToD : '',
                    },
                  },
                  Funds: {
                    Funds: HowFounds !== '' ? HowFounds : '',
                  },
                },
                Contacts_Row1: {
                  Name: {
                    Name: Contacts1Name !== '' ? Contacts1Name : '',
                  },
                  RelationshipToMe: {
                    RelationshipToMe: Contacts1RelationshipToMe !== '' ? Contacts1RelationshipToMe : '',
                  },
                  AddressInCanada: {
                    AddressInCanada: Contacts1AddressInCanada !== '' ? Contacts1AddressInCanada : '',
                  },
                },
              },
              Contacts_Row2: {
                Name: {
                  Name: Contacts2Name !== '' ? Contacts2Name : '',
                },
                Relationship: {
                  RelationshipToMe: Contacts2RelationshipToMe !== '' ? Contacts2RelationshipToMe : '',
                },
                AddressInCanada: {
                  AddressInCanada: Contacts2AddressInCanada !== '' ? Contacts2AddressInCanada : '',
                },
              },
              Education: {
                EducationIndicator: EducationIndicator !== '' ? EducationIndicator : '',
                Edu_Row1: {
                  FromYear: EducationDatesFromY !== '' ? EducationDatesFromY : '',
                  FromMonth: EducationDatesFromM !== '' ? EducationDatesFromM : '',
                  ToYear: EducationDatesToY !== '' ? EducationDatesToY : '',
                  ToMonth: EducationDatesToM !== '' ? EducationDatesToM : '',
                  FieldOfStudy: FieldOfStudy !== '' ? FieldOfStudy : '',
                  School: School !== '' ? School : '',
                  CityTown: SchoolCityTown !== '' ? SchoolCityTown : '',
                  Country: {
                    Country: SchoolCountry !== '' ? SchoolCountry : '',
                  },
                  ProvState: SchoolProvince !== '' ? SchoolProvince : '',
                },
              },
              Occupation: {
                OccupationRow1: {
                  FromYear: Ocopation1DatesFromY !== '' ? Ocopation1DatesFromY : '',
                  FromMonth: Ocopation1DatesFromM !== '' ? Ocopation1DatesFromM : '',
                  ToYear: Ocopation1DatesToY !== '' ? Ocopation1DatesToY : '',
                  ToMonth: Ocopation1DatesToM !== '' ? Ocopation1DatesToM : '',
                  Occupation: {
                    Occupation: Ocupation1 !== '' ? Ocupation1 : '',
                  },
                  Employer: Employer1 !== '' ? Employer1 : '',
                  CityTown: {
                    CityTown: Ocupation1CityTown !== '' ? Ocupation1CityTown : '',
                  },
                  Country: {
                    Country: Ocupation1Country !== '' ? Ocupation1Country : '',
                  },
                  ProvState: Ocupation1Province !== '' ? Ocupation1Province : '',
                },
                OccupationRow2: {
                  FromYear: Ocopation2DatesFromY !== '' ? Ocopation2DatesFromY : '',
                  FromMonth: Ocopation2DatesFromM !== '' ? Ocopation2DatesFromM : '',
                  ToYear: Ocopation2DatesToY !== '' ? Ocopation2DatesToY : '',
                  ToMonth: Ocopation2DatesToM !== '' ? Ocopation2DatesToM : '',
                  Occupation: {
                    Occupation: Ocupation2 !== '' ? Ocupation2 : '',
                  },
                  Employer: Employer2 !== '' ? Employer2 : '',
                  CityTown: {
                    CityTown: Ocupation2CityTown !== '' ? Ocupation2CityTown : '',
                  },
                  Country: {
                    Country: Ocupation2Country !== '' ? Ocupation2Country : '',
                  },
                  ProvState: Ocupation2Province !== '' ? Ocupation2Province : '',
                },
                OccupationRow3: {
                  FromYear: Ocopation3DatesFromY !== '' ? Ocopation3DatesFromY : '',
                  FromMonth: Ocopation3DatesFromM !== '' ? Ocopation3DatesFromM : '',
                  ToYear: Ocopation3DatesToY !== '' ? Ocopation3DatesToY : '',
                  ToMonth: Ocopation3DatesToM !== '' ? Ocopation3DatesToM : '',
                  Occupation: {
                    Occupation: Ocupation3 !== '' ? Ocupation3 : '',
                  },
                  Employer: Employer3 !== '' ? Employer3 : '',
                  CityTown: {
                    CityTown: Ocupation3CityTown !== '' ? Ocupation3CityTown : '',
                  },
                  Country: {
                    Country: Ocupation3Country !== '' ? Ocupation3Country : '',
                  },
                  ProvState: Ocupation3Province !== '' ? Ocupation3Province : '',
                },
              },
              BackgroundInfo: {
                Choice: BackgroundInfoMedicalChoice1,
                Choice2: BackgroundInfoMedicalChoice2,
                Details: {
                  MedicalDetails:
                    BackgroundInfoMedicalDetails !== ''
                      ? BackgroundInfoMedicalDetails + '. ' + BackgroundInfoMedicalDetails2
                      : BackgroundInfoMedicalDetails2,
                },
              },
              BackgroundInfo2: {
                VisaChoice1: BackgroundInfoVisaChioce1,
                VisaChoice2: BackgroundInfoVisaChioce2,
                Details: {
                  refusedDetails:
                    BackgroundInfoVisaChioceDetails +
                    ' ' +
                    BackgroundInfoVisaChioceDetails2 +
                    ' ' +
                    BackgroundInfoVisaChioceDetails3,
                  VisaChoice3: BackgroundInfoVisaChioce3,
                },
              },
              PageWrapper: {
                BackgroundInfo3: {
                  Choice: BackgroundInfo3Choice,
                  details: BackgroundInfo3Details,
                },
                Military: {
                  Choice: MilitarChoice,
                  militaryServiceDetails:
                    MilitarDetails +
                    ' ' +
                    MilitarDetails4 +
                    ' ' +
                    MilitarDetails5 +
                    ' ' +
                    MilitarDetails8 +
                    ' ' +
                    MilitarDetails9 +
                    ' ' +
                    MilitarDetails12 +
                    ' ' +
                    MilitarDetails13 +
                    ' ' +
                    MilitarDetails16 +
                    ' ' +
                    MilitarDetails17 +
                    ' ' +
                    MilitarDetails20,
                },
                Occupation: {
                  Choice: PoliticalAssociation,
                },
                GovPosition: {
                  Choice: PoliticalLoot,
                },
              },
              Signature: {
                Consent0: {
                  Choice: 'Y',
                },
                hand: '',
                TextField2: givenName + ' ' + familyName,
              },
              Disclosure: '',
              ReaderInfo: '',
            },
          },
        }
        console.log(updatedFormData, 'datos del formulario')
        const xml = xmlbuilder.create(updatedFormData, { encoding: 'utf-8' }).end({ pretty: true })
        const file = new Blob([xml], { type: 'application/xml' })
        const formData = new FormData()
        formData.append('file', file, fileName)

        // Añadir datos adicionales al FormData como un objeto JSON
        const additionalData = {
          memberName: product.memberName,
          memberType: product.memberType,
          contracts_users_envelope_id: foundContract.contracts_users_envelope_id,
          contracts_users_user_id: foundContract.contracts_users_user_id,
          userId: userAUTH?.id
        }
        formData.append('additionalData', JSON.stringify(additionalData))

        try {
          const response = await axios.post('https://botimm.immiland.app/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          console.log('File uploaded successfully:')
        } catch (error) {
          console.error('Error uploading file:')
        }
      } else {
        console.log('No contract found with envelope id:', contractsFound)
      }
    } else {
      console.log('contractsData is undefined')
    }
  }

  return (
    <>
      <div>
        {(arrayValidator && arrayValidator?.length > 0) ?
          <div
            style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <button
              style={{
                height: '2.4rem',
                width: '2.9rem',
                padding: '0.3rem 0.5rem',
                borderRadius: '30px',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
              className='button-hover-disable'>
              <img style={{ width: '24px' }} src={thunderbolt} alt="thunderblue" />
            </button>
            <div className='toast-fixed-style'>
              <div
                style={{ backgroundColor: '#FBBF24', padding: '3px', borderRadius: '50%' }}>
                <img src={thunderbolt} alt="checkmark_white" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: '0', fontWeight: 'normal' }}>Se encontraron errores</p>
                <p
                  style={{ margin: '0', color: '#0B76B7', fontWeight: 'bold' }}
                  onClick={() => { toast(JSON.stringify(arrayValidator)) }}>Revisar lista de errores</p>
              </div>
            </div>
          </div> :
          <button
            className='button-ui button-tertiary'
            // style={{
            //   border: '2px solid #18BFFF',
            //   padding: '0.3rem 0.5rem',
            //   borderRadius: '30px',
            //   fontWeight: 'bold',
            //   marginLeft: '1rem',
            // }}
            onClick={handleUploadToServer}>
            <img style={{ width: '24px' }} src={thunderblue} alt="thunderblue" />
          </button>}

        <ToastContainer />
      </div>
    </>
  )
}

export default IMM5257E
