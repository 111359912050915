/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useContext, useEffect} from 'react'
import {type CheckBoxOptions} from '../types'
import ImmigrationDetails from '../ImmigrationDetails'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {observer} from 'mobx-react'

const ChooseImmigrationFund = () => {
  const rootStore = useContext(RootStoreContext)
  const {immigrationServices, immigrationFund, getImmigrationFunds, setImmigrationFundsCheked} =
    rootStore.composeTemplateStore

  useEffect(() => {
    getImmigrationFunds()
  }, [immigrationServices])

  const handleChange = (checkBoxOptions: CheckBoxOptions, cheked: boolean, type: string) => {
    setImmigrationFundsCheked(checkBoxOptions, cheked)
  }

  useEffect(() => {
    // comment to deactivate eslint
  }, [immigrationFund])

  return (
    <>
      {immigrationFund &&
        immigrationServices.map((item) => {
          return (
            <div key={item.id}>
              <ImmigrationDetails
                immigrationService={item}
                options={immigrationFund}
                type='immigrationFund'
                handleChange={handleChange}
              />
            </div>
          )
        })}
    </>
  )
}

export default observer(ChooseImmigrationFund)
