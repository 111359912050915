/* eslint-disable @typescript-eslint/no-empty-function */

import {Button} from '../../../../components'
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {Input, InputRef, Space, Spin, Table, Select} from 'antd'
import {
  deleteFile,
  getFiles,
  updateFile,
  downloadFile,
} from '../../../../app/modules/apps/file/core/_requests'
import {useEffect, useRef, useState} from 'react'
import LoadingBar from 'react-top-loading-bar'
import ProgressWraper from '../../../../app/modules/progress'
import {useQuery} from 'react-query'
import Column from 'antd/lib/table/Column'
import {Files} from 'app/modules/apps/file/core/_models'
import {ColumnType} from 'antd/lib/table/interface'
import Highlighter from 'react-highlight-words'
import {getImmigrationServices} from 'app/modules/apps/immigration/services/core/_requests'

interface IProps {
  callRefetch: boolean
}

const ListFiles = (props: IProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(50)
  const [filter, setFilter] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentName, setCurrentName] = useState('')
  const {data, status, refetch} = useQuery(
    'filemanager',
    async () =>
      await getFiles(`type=file-manager&page=${currentPage}&limit=${perPage}&filter=${filter}`)
  )
  const {data: ImmigrationServices} = useQuery(
    'ListAllImmigraaationServicesSelect',
    async () => await getImmigrationServices({})
  )

  const {callRefetch} = props
  const [percent, setPercent] = useState(-1)
  const [editID, setEditId] = useState('')
  const [messages, setMessages] = useState('Preparando descarga...')
  const [downloading, setDownloading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchedColumn, setSearchedColumn] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef<InputRef>(null)
  const handleSearchCustom = (value: string) => {
    console.log(value)
    setCurrentName(value)
  }
  type DataIndex = keyof Files
  /*  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  } */
  useEffect(() => {
    // debouence delay
    const timer = setTimeout(() => {
      refetch()
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [filter, refetch])

  useEffect(() => { }, [editID])

  useEffect(() => {
    if (percent === 100) {
      setDownloading(false)
    } else if (percent > 1) {
      setMessages('Descargando...')
    }

  }, [percent])
  useEffect(() => {
    refetch()

    // eslint-disable-next-line
  }, [currentPage, filter])

  useEffect(() => {
    if (callRefetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [callRefetch])

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const handleDeleteFile = async (fileID: string) => {
    if (await deleteFile(fileID)) {
      refetch()
    }
  }

  const handleDownloadFile = (fileID: string) => {
    setDownloading(true)
    setPercent(0)
    downloadFile(fileID, getProgress)
  }

  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  const handleEdit = (id: string) => {
    setEditId(id)
    /* data?.data?.map((item: any) => {
      if (item.id === id) {
        item.edit = true
      }
      return item
    }) */
  }
  const handleCancelEdit = (id: string) => {
    setEditId('')
    data?.data?.map((item: any) => {
      if (item.id === id) {
        item.edit = false
      }
      return item
    })
  }

  const handleUpdate = (id: string) => {
    setEditId('')
    data?.data?.map((item: any) => {
      if (item.id === id) {
        item.edit = false
        updateFile(item)
      }
      return item
    })
  }

  const getColumnSearchPropsName = (dataIndex: DataIndex): ColumnType<Files> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom(selectedKeys[0] as string)
          }}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              setCurrentPage(1)
              setFilter(selectedKeys[0] as string)
            }}
            /* size='small' */
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setCurrentPage(1)
              setFilter('')
            }}
            /*  size='small' */
            style={{width: 90}}
          >
            Reset
          </Button>

          <Button
            type='link'
            /*  size='small' */
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: Files) =>
      record.name
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleTableChange = (pagination: any, filters: any) => {
    console.log('params', pagination, filters)
    setCurrentPage(pagination.current)
  }

  const provinces = [
    {
      name: 'Alberta',
    },
    {
      name: 'Atlantic',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Federal',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
  ]
  if (status === 'success' && data) {
    return (
      <>
        {downloading ? (
          <>
            <Spin size='large' />

            <ProgressWraper percent={percent} />
            <LoadingBar color='#BE8575' progress={percent} height={5} />
            {messages}
          </>
        ) : null}

        <>
          <Table
            style={{overflow: 'auto', position: 'relative'}}
            scroll={{x: 'max-content', y: 500}}
            pagination={{
              pageSize: 50,
              showLessItems: true,
              size: 'small',
              responsive: true,
              simple: true,
              showQuickJumper: false,
              showSizeChanger: false,
              current: currentPage,
              total: data.count || 0,
            }}
            dataSource={data.data}
            onChange={handleTableChange}
          >
            <Column
              width={'35%'}
              title='Nombre'
              {...getColumnSearchPropsName('name')}
              render={(item: any) => (
                <Space>
                  {item.edit || item.id === editID ? (
                    <>
                      {item.edit}
                      <Input
                        defaultValue={item.name}
                        onChange={(e) => {
                          item.name = e.target.value
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <span>{item.name}</span>
                    </>
                  )}
                </Space>
              )}
            />

            <Column
              title='Acciones'
              width={'35%'}
              render={(item: any) => (
                <Space align='center'>
                  {item.edit || item.id === editID ? (
                    <>
                      <Button
                        type='link'
                        onClick={() => {
                          handleUpdate(item.id)
                        }}
                        className='text-white'
                      >
                        Guardar
                      </Button>
                      <Button
                        type='link'
                        onClick={() => {
                          handleCancelEdit(item.id)
                        }}
                        className='text-white'
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type='link'
                        onClick={() => {
                          handleDownloadFile(item.id)
                        }}
                        style={{background: 'white', border: '1px solid #bf3c3c'}}
                      >
                        <CloudDownloadOutlined />
                      </Button>
                      <Button
                        type='link'
                        onClick={() => {
                          handleEdit(item.id)
                        }}
                        style={{background: 'white', border: '1px solid #bf3c3c'}}
                      >
                        <EditOutlined />
                      </Button>
                      <Button
                        type='link'
                        onClick={async () => {
                          await handleDeleteFile(item.id)
                        }}
                        style={{background: 'white', border: '1px solid #bf3c3c'}}
                      >
                        <DeleteOutlined />
                      </Button>
                    </>
                  )}
                </Space>
              )}
            />
            <Column
              title='Via'
              width={'15%'}
              /*       dataIndex={'road'} */
              render={(item: any) => (
                <Space>
                  {item.edit || item.id === editID ? (
                    <>
                      <Select
                        defaultValue={item.road}
                        style={{width: 120}}
                        onChange={(e) => {
                          item.road = e
                        }}
                        options={
                          ImmigrationServices
                            ? ImmigrationServices.map((road: any) => ({
                                value: road.name,
                                label: road.name,
                              }))
                            : []
                        }
                      />
                    </>
                  ) : (
                    <>
                      <span>{item.road}</span>
                    </>
                  )}
                </Space>
              )}
            />
            <Column
              title='Provincia'
              width={'15%'}
              render={(item: any) => (
                <Space>
                  {item.edit || item.id === editID ? (
                    <>
                      <Select
                        defaultValue={item.province}
                        style={{width: 170}}
                        onChange={(e) => {
                          item.province = e
                        }}
                        options={
                          provinces
                            ? provinces.map((province: any) => ({
                                value: province.name,
                                label: province.name,
                              }))
                            : []
                        }
                      />
                    </>
                  ) : (
                    <>
                      <span>{item.province}</span>
                    </>
                  )}
                </Space>
              )}
            />
          </Table>
        </>
      </>
    )
  } else {
    return <>No data</>
  }
}

export {ListFiles}
