/* eslint-disable @typescript-eslint/no-unused-vars */
import {SetStateAction, useEffect, useRef, useState} from 'react'
import './consultorselector.css'
import MarthaInside from './MarthaInside'
import { useMediaQuery } from 'react-responsive';
const ConsultorSelector = () => {
  const consultores = [
    {
      id: 'eddy',
      nombre: 'Eddy Ramírez',
      cargo: 'CEO & Founder',
      licencia: 'Lic. #R-515840 CCG #1883',
      imagen:
        'https://assets-global.website-files.com/62aa6a0ad689e34226f97321/6387b6ce35d50e1b7269eefb_Eddy-p-500.png',
    },
    {
      id: 'carolina',
      nombre: 'Carolina Feican',
      cargo: 'Consultora Regulada CICC',
      licencia: 'Licencia #R-707026',
      imagen:
        'https://assets-global.website-files.com/62aa6a0ad689e34226f97321/6387b6fbba570e52f4d1502a_carolina_feican.png',
    },

    {
      id: 'andy',
      nombre: 'Andy Rodriguez',
      cargo: 'Consultor Regulado CICC',
      licencia: 'Licencia #R-417895',
      imagen:
        'https://assets-global.website-files.com/62aa6a0ad689e34226f97321/63a09441de93944b174aeaf4_Andy.png',
    },

    {
      id: 'edna',
      nombre: 'Edna Guerrero',
      cargo: 'Consultora Regulada CICC',
      licencia: 'Licencia #R705896',
      imagen:
        'https://assets-global.website-files.com/62aa6a0ad689e34226f97321/6387b6f1b30a2e649b2f1f5b_edna_guerrero.png',
    },

    {
      id: 'rebeca',
      nombre: 'Rebeca Laso',
      cargo: 'Consultora Regulada CICC',
      licencia: 'Licencia # R-708726',
      imagen:
        'https://i.ibb.co/ZJFynFT/rebeca.webp',
    },

    {
      id: 'Martha',
      nombre: 'Martha Acosta',
      cargo: 'Consultora de Immigración',
      licencia: 'Licencia # CICC R71316',
      imagen:
        'https://assets-global.website-files.com/62aa6a0ad689e34226f97321/647e15a2b3c3a2af38d3d0ba_Martha%20Acosta.jpg',
    },

    {
      id: 'lisandra',
      nombre: 'Lisandra Llamos',
      cargo: 'Consultora de Immigración',
      licencia: 'Licencia # CICC R711912',
      imagen:
        'https://assets-global.website-files.com/62aa6a0ad689e34226f97321/63eea7dd74f13bb5a669965f_134-0-4.jpeg',
    },
  ];

  const [selectedConsultor, setSelectedConsultor] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const consultorContainerRef = useRef<HTMLDivElement | null>(null);
  const confirmarCitaButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleConsultorChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSelectedConsultor(event.target.value);
    setShowInfo(true);
  };
  const isSmallScreen = useMediaQuery({ maxWidth: 700 });
  useEffect(() => {

    if (isSmallScreen && confirmarCitaButtonRef.current) {
      confirmarCitaButtonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isSmallScreen, showInfo]); 
  const handleConfirmarCita = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const selectedConsultorData = consultores.find(
    (consultor) => consultor.id === selectedConsultor
  );



    
  return (
    <>
    <div className="container-all">
      <div className="container-consultors" >
        <div className="w-dyn-list">
          {consultores.map((consultor) => (
            <div key={consultor.id} role="listitem" className="w-dyn-item">
              <label className="consultor-label">
                <input
                  type="radio"
                  value={consultor.id}
                  checked={selectedConsultor === consultor.id}
                  onChange={handleConsultorChange}
                />
                <div className="consultor-div">
                  <div className="foto">
                    <img
                      src={consultor.imagen}
                      alt=""
                      loading="lazy"
                      className="image"
                    />
                  </div>
                  <div className="consultor-specs">
                    <h3 className="titulo-consultor">{consultor.nombre}</h3>
                    <div className="cargo-consultor">{consultor.cargo}</div>
                    <div className="licencia">{consultor.licencia}</div>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      {showInfo && selectedConsultorData && (
  <div className="selected-consultor-info">
    <div className='constultant-photo'>
      <div className="foto-inside">
        <img 
          src={selectedConsultorData.imagen}
          alt=""
          loading="lazy"
          className="image-inside"
        />
      </div>
      <div className='container-title-desctript'>
        <div className="info-text">
          30 minutos consulta migratoria con <b>{selectedConsultorData.nombre}</b>
        </div>
        <div className="additional-text">
          Esta consulta está diseñada estrictamente <b>para clientes que ya han tenido una consulta inicial de 1 hora</b> o para aquellos clientes que <b>han sido instruidos a tomar media hora para evaluar posibilidades de una reconsideración, luego de un rechazo.</b>
        </div>
        <div className="additional-info">
        <div className="tag-div">
  <div className='time-price'>
    <div className='time'>
      <img
        src="/media/svg/menu-icons/clock.svg"
        alt="Logo de Immigracion"
        style={{ marginRight: '5px', width: '24px', height: '24px' }}
      />
      <b className='text-title-price'>30 minutos</b>  
    </div>
    <div className="vertical-line" style={{ backgroundColor: '#005C82', width: '2px', height: '30px', margin: ' 10px'}}></div>
    <div className='time'>
      <img
        src="/media/svg/menu-icons/credit-card.svg"
        alt="Logo de Immigracion"
        style={{  marginRight: '5px', width: '24px', height: '24px' }}
      />
      <b className='text-title-price'>$172.46 CAD</b>  
    </div>
  </div>
</div>
<div className="button-container-date">
<button className="confirmar-cita-btn" ref={confirmarCitaButtonRef} onClick={handleConfirmarCita}>
      Confirmar Cita
    </button>
    </div>
        </div>
      </div>
    </div>
  </div>
)}
</div>
  {showModal && selectedConsultor === 'eddy' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
            <span className='close' onClick={closeModal}>
              &times;
            </span>
            <p className='titlemodal' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '2rem', flexWrap: 'wrap' }}>Seleccione la Fecha para su cita</p>
            <iframe
              title='title'
              className='frames'
              src='https://calendly.com/30-minutos/30-min-consulta-migratoria-con-eddy-ramirez-tax?embed_domain=www.immilandcanada.com&embed_type=Inline&am...'
   
            ></iframe>
          </div>
        </div>
      )}

      {showModal && selectedConsultor === 'carolina' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
            <span className='close' onClick={closeModal}>
              &times;
            </span>
            <p className='titlemodal' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '2rem', flexWrap: 'wrap' }}>Seleccione la Fecha para su cita</p>
            <iframe
              title='title'
              className='frames'
              src='https://calendly.com/30-minutos/30-min-consulta-carolina-feican-taxes?embed_domain=www.immilandcanada.com&embed_type=Inline&a...'
      
            ></iframe>
          </div>
        </div>
      )}

{showModal && selectedConsultor === 'andy' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
        
            <span className='close' onClick={closeModal}>

              &times;
            </span>
            <p className='titlemodal' style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'2rem', flexWrap:'wrap'}}>Seleccione la Fecha para su cita</p>
            <iframe
              title='title'
              className='frames'
              src='https://calendly.com/30-minutos/30-min-consulta-andy-rodrigue-taxes?embed_domain=www.immilandcanada.com&embed_type=I...'
            ></iframe>
          </div>
        </div>
      )}

{showModal && selectedConsultor === 'rebeca' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
        
            <span className='close' onClick={closeModal}>

              &times;
            </span>
            <p className='titlemodal' style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'2rem', flexWrap:'wrap'}}>Seleccione la Fecha para su cita</p>
            <iframe
        title='title'
        className='frames'
        src='https://calendly.com/30-minutos/30-min-consulta-migratoria-con-rebeca-laso-taxes?embed_domain=www.immilandcanada.com&embed_type=Inline&...'

      ></iframe>
          </div>
        </div>
      )}

{showModal && selectedConsultor === 'edna' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
        
            <span className='close' onClick={closeModal}>

              &times;
            </span>
            <p className='titlemodal' style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'2rem', flexWrap:'wrap'}}>Seleccione la Fecha para su cita</p>
            <iframe
        title='title'
        className='frames'
        src='https://calendly.com/30-minutos/30-min-consulta-edna-guerrero-taxes?month=2023-07'
    
   
      ></iframe>
          </div>
        </div>
      )}

{showModal && selectedConsultor === 'lisandra' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
        
            <span className='close' onClick={closeModal}>

              &times;
            </span>
            <p className='titlemodal' style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'2rem', flexWrap:'wrap'}}>Seleccione la Fecha para su cita</p>
            <iframe
        title='title'
        className='frames'
        src='https://calendly.com/30-minutos/30-min-consulta-con-lisa-taxes?month=2023-09'
      ></iframe>
          </div>
        </div>
      )}

{showModal && selectedConsultor === 'Martha' && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content'>
        
            <span className='close' onClick={closeModal}>

              &times;
            </span>
            <p className='titlemodal' style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'2rem', flexWrap:'wrap'}}>Seleccione la Fecha para su cita</p>
            <iframe
        title='title'
        className='frames'
        src='https://calendly.com/30-minutos/30-min-consulta-con-martha-taxes'
      ></iframe>
          </div>
        </div>
      )}

    </>
  );
};
export default ConsultorSelector
