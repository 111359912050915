import tw from 'tailwind-styled-components'

const Box = tw.div`
    flex
    justify-center
    flex-col
    w-full
`

export {Box}
