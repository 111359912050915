import { AddressElement, PaymentElement } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Input } from "antd";
import { useIntl } from "react-intl";
import { RootStoreContext } from "stores/rootStore";
import PaymentStatus from "./PaymentStatus";
import { observer } from "mobx-react";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { AddressMode, StripeAddressElementOptions, StripePaymentElementOptions } from "@stripe/stripe-js";

const CheckoutForm: React.FC<{ paymentMethodValue: string }> = observer(({ paymentMethodValue }) => {
  const rootStore = useContext(RootStoreContext)
  const {savePreOrder, preOrder, setCuponPrice, cart,paymentItem,saveSecondCall} = rootStore.cartStore
  console.log(paymentMethodValue, 'valor de payment');
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl()
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [payment, setPayment] = useState({status: 'initial'})
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false);
/*   const [input, setInput] = useState({
    cardholderName: '',
  })
 */

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
   
      setIsProcessing(true);
      setIsLoading(true);
      console.log("CLIENTESEC", JSON.stringify(paymentItem))
      const cardElement = elements!.getElement(PaymentElement)
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setPayment({ status: 'Something went wrong ELEMENT.' })
        setErrorMessage("Something went wrong. ELEMENT")
        setIsLoading(false);
        return;
      }
      // Use the clientSecret and Elements instance to confirm the setup
      const clientSecret = paymentItem.client_secret
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams:{
          return_url:'https://immiland.app/embed/store/payment/sucesswo',
          /* payment_method_data:{
            billing_details:{
              name:"test"
            }
          } */
        }
      });
      if (error /* && (error.type === "card_error" || error.type === "validation_error") */) {
        if (error.message) {
          setIsLoading(false);
          console.log("ERROR",error.message)
          setMessage(error.message)
        }
      } else {
        console.log("S")
        setPayment({ status: 'succeeded' })
      }
    
    
   
    setIsProcessing(false);
  }; 
/*   const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  } */

  const addressOptions: StripeAddressElementOptions = {
    mode: 'shipping', // or 'billing'
/*     defaultValues: {
      name: null, // This hides the name field
      firstName: null,
      lastName: null,
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: 'US',
      },
    },
     allowedCountries: ['US', 'CA'],  // Example of allowed countries
     autocomplete: { mode: 'automatic' },
     blockPoBox: true,  */
  };

  const paymentsOptions: StripePaymentElementOptions = {
   /*  fields: {
      billingDetails: {
        name: 'never',
      }
    } */
  };

 useEffect(() => {
   console.log("payy",JSON.stringify(paymentItem))
    stripe?.retrievePaymentIntent(paymentItem.client_secret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          console.log("S")
          setMessage("Payment succeeded!");
          setPayment({status: 'succeeded'})
          break;
        case "processing":
          console.log("P")
          console.log("ERRORx")
          setMessage("Your payment is processing.");
          setPayment({status: 'processing'})
          setIsLoading(false);
          break;
        case "requires_payment_method":
          console.log("RQP")
          console.log("ERRORxx")
          setMessage("Your payment was not successful, please try again.");
          setPayment({status: 'requires_payment_method'})
          setIsLoading(false);
          break;
        default:
          console.log("X")
          console.log("ERRORxxx")
          setMessage("Something went wrong.");
          setPayment({status: 'Something went wrong.'})
          setErrorMessage("Something went wrong.")
          setIsLoading(false);
          break;
      }
    });
  }, [paymentItem]); 
  return (

    <form id="payment-form" onSubmit={handleSubmit}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}> <img
      src= '/media/svg/menu-icons/credit-cards-black.svg'
      alt='Logo de Visa'
      style={{ width: '26px', height: '25px', marginBottom: '5px' }}
    /> <legend style={{ display: 'flex', fontWeight: '700', fontSize: '14px' }}>Pague con tarjetas de Débito o Crédito</legend></div>
    {/*   <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '20px', alignItems: 'flex-start' }}><a>Nombre del titular de la tarjeta</a>
        <Input
          className='custom-input-payment'
          placeholder={intl.formatMessage({ id: 'TRANSLATION.NAMECARD' })}
          type='Text'
          name='cardholderName'
          onChange={handleInputChange}
          required
          style={{ backgroundColor: 'white' }}
        /></div> */}
        <AddressElement id="address-element" options={addressOptions} />
        <PaymentElement id="payment-element"/*  options={paymentsOptions} *//>
      
        <PaymentStatus
            status={payment.status}
            errorMessage={errorMessage}
            paymentCode={preOrder.code}
          />   

          
<div className="button-form-payment-stripe" style={{ gap: '10px', fontSize: '20px', fontWeight: '700', color: 'white', display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
  <button disabled={isLoading || !stripe || !elements} id="submit" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <span id="button-text">
      {isLoading ? <div className="spiner" id="spiner"></div> : "Pay now"}
    </span>
    {!isLoading }
  
  <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>{paymentMethodValue}  <img src="/media/icons/app/menu/arrow-right-white.svg" alt="Arrow Right" style={{ width: '30px', height: '30px' }} /></div>
  </button>
</div>


      {/* Show any error or success messages */}
      {isLoading && <div id="payment-message">Procesando su solicitud, No actualice ni cierre la ventana.</div>}
      {payment.status==="succeeded" && <div id="payment-message">No actualice ni cierre la ventana que le aparezca el mensaje de traducion creada.</div>}     
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
})

export default CheckoutForm;