/* eslint-disable @typescript-eslint/no-unused-vars */

import {Table} from 'antd'
import {Button} from '../../../../../components'
import {getMessagesIdUsers} from '../../../../../app/modules/apps/messages/core/_requests'
import {observer} from 'mobx-react'
import {useEffect} from 'react'
import {useQuery} from 'react-query'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  setMessageId?: React.Dispatch<React.SetStateAction<string>>
  setShowMessage?: React.Dispatch<React.SetStateAction<boolean>>
  userId: string
  handleClose: (key: string) => void
}

const RandomDocumentsClients = (props: IProps) => {
  const {userId, handleClose} = props
  const {data, status, refetch} = useQuery(
    'listnotesbyuser',
    async () => await getMessagesIdUsers(userId)
  )
  const {callRefetch} = props
  const {Column} = Table

  useEffect(() => {
    if (callRefetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [callRefetch])

  return (
    <>
      No data{' '}
      <Button
        className='mb-3 '
        onClick={() => {
          handleClose('menu')
        }}
      >
        Regresar
      </Button>
    </>
  )
}

export default observer(RandomDocumentsClients)
