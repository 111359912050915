/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../../components'
import {Space} from 'antd'
import {type Category} from '../../../../modules/apps/cart/core/category/_models'
import {GrEdit} from 'react-icons/gr'
import {getCategories} from '../../../../modules/apps/cart/core/category/_requests'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
  onComplete: (data: Category) => Promise<void>
}

// const ListCupons: React.FC<IProps> = ({
const ListCupons: React.FunctionComponent<IProps> = ({callRefetch, handleEdit, onComplete}) => {
  const intl = useIntl()
  const {data, status, refetch} = useQuery(
    'ListCategories',
    async () => await getCategories('limit=50&page=1&name=')
  )

  useEffect(() => {
    if (callRefetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [callRefetch])

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const handleEditTemplate = (templateId: string) => {
    if (data != null) {
      const templateEdit = data?.filter((temp) => temp.id === templateId)[0]
      handleEdit(templateEdit)
    }
  }

  if (status === 'success' && data != null) {
    return (
      <>
        <TableContainer style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
          <Table
          // dataSource={data} rowKey='id'
          >
            <TableHead>
              <TableRow>
                {/* <TableCell>{intl.formatMessage({ id: 'CATEGORY.NAME' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'CATEGORY.STATUS' })}</TableCell> */}
                <TableCell>Nombre</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Descripcion</TableCell>
                <TableCell>Servicio</TableCell>
                <TableCell>Valor Del %</TableCell>
                <TableCell>Valor Numerico </TableCell>
                <TableCell>Fecha Inicio </TableCell>
                <TableCell>Fecha Final </TableCell>
                <TableCell>Estado </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Dia del Idioma</TableCell>
                <TableCell>Porcentaje</TableCell>
                <TableCell>Descuento por el dia del idioma</TableCell>
                <TableCell>Traduccion</TableCell>
                <TableCell>15%</TableCell>
                <TableCell>-</TableCell>
                <TableCell>02/06/2023</TableCell>
                <TableCell>21/06/2023</TableCell>
                <TableCell>Inactivo</TableCell>
                <TableCell>
                  <Space>
                    <Button style={{background: 'white', border: '1px solid #bf3c3c'}}>
                      <GrEdit />
                    </Button>
                  </Space>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Aniversario Inmiland</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Descuento por el aniversario de Inmiland</TableCell>
                <TableCell>Traduccion</TableCell>
                <TableCell>-</TableCell>
                <TableCell>20 CAD</TableCell>
                <TableCell>02/07/2023</TableCell>
                <TableCell>21/07/2023</TableCell>
                <TableCell>Activo</TableCell>
                <TableCell>
                  <Space>
                    <Button style={{background: 'white', border: '1px solid #bf3c3c'}}>
                      <GrEdit />
                    </Button>
                  </Space>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  } else {
    return <>No data</>
  }
}

export {ListCupons}
