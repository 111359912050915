 

import {Button, Loader, TableCell, TableRow} from '../../../../../components'
import {Switch} from 'antd'
import React from 'react'
import {
  generatePayment,
  updateApicallIgnore,
  verifyPayment,
} from '../../../../../app/modules/apps/apicalls/core/_requests'
import {type ApiCalls} from '../../../../../app/modules/apps/apicalls/core/_models'
import StripeFormatData from '../StripeFormatData'
import TextArea from 'antd/lib/input/TextArea'

interface Props {
  data: ApiCalls[] | undefined
  refetch: () => void
}

const DetailApiCall: React.FC<Props> = ({data, refetch}) => {
  const [verification, setVerification] = React.useState(false)
  const [verificationrow, setVerificationRow] = React.useState(false)

  const handleVerification = () => {
    setVerification(true)
    setVerificationRow(true)
    callApiPerRow()
  }

  const callApiPerRow = async () => {
    if (data != null) {
      for (let i = 0; i < data.length; i++) {
        data[i].is_verifying = true
        await verifyRow(data[i])

        // sleep 1 second
      }
      setVerification(false)
    }
  }

  const verifyRow = async (row: ApiCalls) => {
    const result = await verifyPayment(row)
    if (result) {
      if (result.status === 'error') {
        // chande status to error in data array
        data?.map((item) => {
          if (item.id === row.id) {
            item.error = `Error: ${result.message}`
            item.is_verifying = false
          }
        })
      } else {
        data?.map((item) => {
          if (item.id === row.id) {
            item.error = 'Verified'
            item.is_verifying = false
          }
        })
      }
    }
    setVerificationRow(false)
  }

  const handeGenerateAll = () => {
    setVerification(true)
    setVerificationRow(true)
    callGenerateAll()
  }

  const callGenerateAll = async () => {
    if (data != null) {
      for (let i = 0; i < data.length; i++) {
        data[i].is_verifying = true
        await generateRow(data[i])

        // sleep 1 second
      }
      setVerification(false)
    }
  }

  const generateRow = async (row: ApiCalls) => {
    setVerification(true)
    await generatePayment(row)
    handleVerification()
  }

  const handleIgnore = async (row: ApiCalls) => {
    row.ignored = !row.ignored
    const result = await updateApicallIgnore(row)
    if (result) {
      refetch()
    }
  }

  return (
    <>
      <TableRow key={0}>
        <TableCell colSpan={2}>
          <Button
            onClick={() => {
              handleVerification()
            }}
          >
            Verificar Todos
          </Button>
        </TableCell>
        <TableCell colSpan={2}>
          <Button
            onClick={() => {
              handeGenerateAll()
            }}
          >
            Generar Todos
          </Button>
        </TableCell>
        <TableCell colSpan={10}></TableCell>
      </TableRow>

      {data?.map((item, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{item.id}</TableCell>
            <TableCell>
              {item.data ? <StripeFormatData data={item.data} /> : <>No data stripe</>}
            </TableCell>
            <TableCell>
              {item.error}
              {verificationrow ? <Loader /> : <></>}
            </TableCell>

            {!verification ? (
              <>
                <TableCell>
                  {!verification && (
                    <Button
                      onClick={() => {
                        verifyRow(item)
                        item.is_verifying = true
                        setVerificationRow(true)
                      }}
                    >
                      Verificar
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {!verification && (
                    <Button
                      onClick={() => {
                        handleIgnore(item)
                      }}
                    >
                      {item.ignored ? 'No  Ignorar' : 'Ignorar'}
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {!verification && (
                    <Button
                      onClick={() => {
                        generateRow(item)
                      }}
                    >
                      Generar
                    </Button>
                  )}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell colSpan={3}>Verificando</TableCell>
              </>
            )}
            <TableCell>
              <Switch checked={item.completed || false} />{' '}
            </TableCell>
            <TableCell>
              <Switch checked={item.verified || false} /> {item.verified}
            </TableCell>
            <TableCell>
              <Switch checked={item.ignored || false} /> {item.ignored}
            </TableCell>
            <TableCell>
              {item.created_at instanceof Date ? item.created_at.toLocaleDateString() : '-'}
            </TableCell>
            <TableCell>{item.idcall}</TableCell>

            <TableCell>
              <TextArea cols={10} rows={4} value={JSON.stringify(item.data)} />
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default DetailApiCall
