/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { RootStoreContext } from 'stores/rootStore'
import { Users } from 'app/modules/apps/users/core/_models'
import axios, { AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import notes from '../../admin/users/List/image/notes.svg'
import linki from '../../admin/users/List/image/linki.svg'
import UserBtn from '../../dashboard/userDasboardComponents/userDashboardIcons/user-alt-4.svg'
import Graduate from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg'
import UsersIcon from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Users.svg'
import Plane from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/air-mail.svg'
import Briefcase from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-2.svg'
import Houseonly from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Houseonly.svg'
import UsersIcon2 from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg'
import funnel from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/funnel.svg'
import ShowAnswersConsultant from 'app/pages/services/services/supportDocumentsClient/ShowAnswersConsultant'
import SlideIntakeProcess from 'app/pages/admin/users/List/SlideIntakeProcess'
import NotesModalIntake from 'app/pages/admin/users/List/SlideIntake'

interface ContractUser {
  user_id: string
  user_email: string
  user_first_name: string
  user_last_name: string
}

interface Client {
  formattedDate: ReactNode
  user: {
    firstName: string
    lastName: string
    email: string
    id: string
  }
  envelope_id: string
  uniqueMemberTypeCount: number
  hasWorkProduct: boolean
  hasStudyProduct: boolean
  hasVisitProduct: boolean
  hasresidenceProducts: boolean
  hasSpecificProduct: boolean
  productIds: []
  staffId: {
    jotformName: string
  } | null
  step: number | null
  department: string | null
  staffName?: string
}


const getContractsWithPagination = async (userId: string, page: number, limit: number): Promise<{ data: Client[], totalPages: number }> => {
  const API_URL = process.env.REACT_APP_API_URL
  return await axios
    .get(`${API_URL}/contracts/getContractsWithPagination?page=${page}&limit=${limit}&userId=${userId}`)
    .then((d: AxiosResponse<{ data: Client[], totalPages: number }>) => {
      return d.data
    })
    .catch((error) => {
      console.error("Error fetching contracts:", error)
      return { data: [], totalPages: 1 }
    })
}

const NotifyDashboard: React.FC<any> = ({ }) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const { selectUser } = rootStore.usersStore
  const { loadCounterMessagesClient } = rootStore.messageStore
  const [wordSearched, setWordSearched] = useState('')
  const [showList, setShowList] = useState(true)
  const [usersList, setUsersList] = useState<ContractUser[]>([])
  const [clientsData, setClientsData] = useState<Client[]>([])
  const [filteredClientsData, setFilteredClientsData] = useState<Client[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null)
  const [filterDateTo, setFilterDateTo] = useState<Date | null>(null)
  const [filterStage, setFilterStage] = useState<string>('')
  const [filterStatus, setFilterStatus] = useState<string>('')
  const [filterStaff, setFilterStaff] = useState<string>('')
  const [filterEnvelope, setFilterEnvelope] = useState<string>('')
  const [showDateFilter, setShowDateFilter] = useState(false)
  const [showStageFilter, setShowStageFilter] = useState(false)
  const [showStatusFilter, setShowStatusFilter] = useState(false)
  const [showStaffFilter, setShowStaffFilter] = useState(false)
  const [showEnvelopeFilter, setShowEnvelopeFilter] = useState(false)
  const [selected, setSelected] = useState<any>()
  const [activeKey, setActiveKey] = useState(false)
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisible2, setIsModalVisible2] = useState(false)
  const [userInfo, setUserInfo] = useState<any>()
  const [selectedEnvelopeId, setSelectedEnvelopeId] = useState<string | null>(null)
  const [filterNameEmail, setFilterNameEmail] = useState<string>('')
  const [showNameEmailFilter, setShowNameEmailFilter] = useState<boolean>(false)

  const [staffList, setStaffList] = useState<{ id: string, jotformName: string }[]>([]);
  const openNotesModal = (client: Client) => {
    setSelectedEnvelopeId(client.envelope_id) // Assigns the envelope_id of the selected client
    setIsModalVisible2(true)
    setUserInfo({ firstName: client.user.firstName, lastName: client.user.lastName }) // Save user info for modal
  }


  const fetchAllStaffIds = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.get(`${API_URL}/staffs/all`);

      console.log('Fetched staff IDs:', response.data);

      return response.data || [];

    } catch (error) {
      console.error('Error fetching staff IDs:', error);
      return [];
    }
  };



  const handleCancel2 = () => {
    setIsModalVisible2(false)
  }


  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCreate = () => {
    setIsModalVisible(false)
  }

  /* otros */

  const othersProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713', 'f9fe4a37-7284-4bf7-a22e-f0f12678bb99', '662a5e18-340f-41e6-830a-10576ead3115'
  ]

  const othersProductsMap: { [key: string]: string } = {
    '8dcb5115-bdce-45e3-8b50-d2d592020713': 'Bio',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99': 'F-Bio',
    '662a5e18-340f-41e6-830a-10576ead3115': 'SL',
  }

  /* VISITA */

  const visitProducts = [
    'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const visitProductsMap: { [key: string]: string } = {
    'a10980f2-57f3-43c7-b863-a995915389de': 'VVD',
    '3f056a93-1f4b-4598-9aec-f929ecd00e97': 'eTA',
    '7664135d-5b88-4640-8bf1-6f56d7f2a78a': 'Super visa',
    'daa261eb-6263-4b6e-acbb-4343bd4ce35a': 'transit',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9': 'VR',
    'b0e0313b-6344-4733-90a0-6d6031f8be1e': 'VV',
    '41669177-9782-4052-bb8d-08b6c29c928f': 'VV(Study less 6)',
    'b9ada976-a4fe-444d-9f6a-dd58c0928120': 'VR(Dependent)',
  }

  /* ESTUDIO */

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const studyProductsMap: { [key: string]: string } = {
    '94726637-76e7-438b-9494-99b136bbc7ea': 'SPd',
    'bb4056fb-2440-4a06-aec1-8d13349e7427': 'SPd(IC)',
    '8327593a-cc82-4703-afaa-72030f67c001': 'SP',
    'f9b3dcd2-b420-4cc0-b5df-575f9a5be911': 'SDS',
    '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800': 'SP(IC)',
    '32549570-adf0-4add-ad43-5c49fed6566d': 'SP(language)',
    '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c': 'SPd(IC language)'
  }

  /* Residence */

  const residenceProducts = [
    '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', '1ec2dc58-5776-49d3-b487-f373ffd1e0a3',
  ]

  const residenceProductsMap: { [key: string]: string } = {
    '6f317d28-cab2-4316-9a25-3f62e4e047e6': 'Citizenship',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d': 'CSQ',
    '3320f244-f338-43d0-8006-503003d16501': 'CSQ(dependent)',
    'b58daf73-88be-41ee-8a0d-9e485748ced8': 'CSQ(spouse/partner)',
    '7ac7912e-9514-4281-924b-1a36a4dd3065': 'Express Entry(dependent)',
    '9c1f03d1-5100-4a50-8030-cfb8ebad4480': 'Express Entry(AM)',
    '48b466b6-d8e1-48f8-ae32-2877760f92b7': 'Express Entry(EI phase 1)',
    'd1195212-1cdc-4792-b88b-824922f63c36': 'Express Entry(IA phase 2)',
    '7d8df96c-dd71-48aa-b0f7-301e59706212': 'PNE(main applicant phase 1)',
    '03030060-aa44-452a-ad54-80fedf29a547': 'PNP',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d': 'Express Entry(EI Spouse/Partner phase 1)',
    '5848c9c4-bfc9-49b0-881d-9cab020dd1e5': 'Express Entry(IA Spouse/Partner phase 2)',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3': 'TRV',
  }

  /* SECCION DE TRABAJO */

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', '6420335e-30aa-46c5-892b-e1fa23914733',
    '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac', '229d7b91-7a9b-407a-988b-9e45096bff5b',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994', 'c6df5f52-670d-4d7f-809e-72cb59fa684a',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3', 'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]

  const workProductsMap: { [key: string]: string } = {
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61': 'LMIA Exempt',
    '41b1a433-5900-4b96-85f3-7e2c8fce0a41': 'LMIA Exempt(PE)',
    '50eb5404-9ae2-4655-91ae-89d2e8a6fa73': 'LMIA Exempt(Flagpole)',
    '19ca7bf2-6514-478b-bb78-c7861619fe39': 'LMIA Exempt(IC)',
    'e377ed33-a53a-4312-8842-1a689237b07c': 'LMIA Exempt',
    '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b': 'LMIA exempt(PE)',
    'e8e477d1-f126-4a64-93da-539b3715730d': 'LMIA positive',
    '30a323ab-236c-461c-93ea-28308c8fafa6': 'LMIA positive(PE)',
    '08e1143b-4f7f-48db-a289-9a3fc40f8037': 'LMIA positive(flagpole)',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa': 'LMIA positive(IC)',
    'a645705f-f9f7-47c6-9319-5a1026da84d2': 'LMIA positive',
    '6e420420-d96c-4cb8-a145-95a7964ed618': 'LMIA positive(PE)',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095': 'CO-OP',
    '9015fb11-d513-4f6e-9de9-aab92d5d2b42': 'IEC',
    '4f050e6f-e9eb-4ee0-b12c-465d2d715c16': 'IEC',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c': 'LMIA exemption',
    '0dac7746-02c0-47db-be28-14755d62a64d': 'LMIA Federal',
    '6420335e-30aa-46c5-892b-e1fa23914733': 'LMIA Quebec',
    '52096218-da31-4e01-a406-0e06a9ca4d07': 'OWP Vulnerable Worker',
    'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac': 'OWP(MA)',
    '229d7b91-7a9b-407a-988b-9e45096bff5b': 'OWP(Special Mesure Opt-in)',
    'c4bb7c15-0e15-487f-b161-21d0426d82f9': 'PGWP',
    'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994': 'PGWP(PE)',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a': 'PGWP',
    '8a29f12d-a898-4d52-8fd1-f184094cd8c3': 'Sponsorship',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4': 'Sponsorship',
    '1d2b991b-7d75-4127-99d8-19f884efcfd0': 'OWP Spouse/Partner',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f': 'OWP Spouse/Partner',
    'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f': 'OWP Spouse/Partner',
    'b6f813c5-0911-4b86-be17-0e74bc332316': 'Spouse/Partner RSWP(Phase 1)'
  }

  // Definición de la función hasAny
  function hasAny(productIds: Set<string>, products: string[]): boolean {
    return products.some(product => productIds.has(product))
  }
  const fetchContracts = async (
    page: number,
    limit: number = 10,
    firstName?: string,
    lastName?: string,
    email?: string,
    envelopeId?: string,
    fromDate?: string,
    toDate?: string,
    staffId?: string,
    filterStatus?: string,
    step?: number,
    department?: string,
  ) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.get(`${API_URL}/contracts/getContractsWithPagination`, {
        params: {
          page,
          limit,
          firstName: firstName || undefined,
          lastName: lastName || undefined,
          email: email || undefined,
          envelopeId,
          fromDate,
          toDate,
          staffId: staffId || undefined,
          filterStatus: filterStatus,
          step: step || undefined,
          department: department || undefined,
        },
      });

      console.log('API response:', response.data);

      // Obtener todos los staff desde la función fetchAllStaffIds
      const allStaff = await fetchAllStaffIds();

      // Crear un mapa para acceder rápidamente a los nombres por ID
      const staffMap = new Map(allStaff.map((staff: { id: string, jotformName: string }) => [staff.id, staff.jotformName]));

      // Filtrar staffIds de acuerdo a los presentes en allStaffIds
      const filteredStaffList = response.data.allStaffIds.map((staffObj: { staffId: string }) => {
        const staffName = staffMap.get(staffObj.staffId) || '';
        return { id: staffObj.staffId, jotformName: staffName };
      });

      setStaffList(filteredStaffList);

      const sortedData = response.data.data.sort((a: { completedDate: string | number | Date }, b: { completedDate: string | number | Date }) => new Date(b.completedDate).getTime() - new Date(a.completedDate).getTime());

      const enrichedData = sortedData.map((contract: any) => {
        const applicants = JSON.parse(contract.applicants || '[]');
        const uniqueMemberTypes = new Set(applicants.map((app: { memberType: string }) => app.memberType));
        const productIds = new Set<string>(applicants.map((app: { productId: string }) => app.productId));
        const hasWorkProduct = hasAny(productIds, workProducts);
        const hasresidenceProducts = hasAny(productIds, residenceProducts);
        const hasStudyProduct = hasAny(productIds, studyProducts);
        const hasVisitProduct = hasAny(productIds, visitProducts);
        const hasSpecificProduct = hasAny(productIds, othersProducts);
        const formattedDate = new Date(contract.completedDate).toLocaleDateString('en-US');

        // Directly use the `jotformName` from the `staffId` object
        const staffName = contract.staffId?.jotformName || '';

        return {
          ...contract,
          uniqueMemberTypeCount: uniqueMemberTypes.size,
          hasWorkProduct,
          hasStudyProduct,
          hasVisitProduct,
          hasresidenceProducts,
          hasSpecificProduct,
          productIds,
          formattedDate,
          staffName,
        };
      });

      // Apply frontend filtering based on filterStatus
      // if (filterStatus) {
      //   enrichedData = enrichedData.filter((client: Client) => getStatusText(client) === filterStatus);
      // }

      console.log('Filtered Client Data:', enrichedData);

      setClientsData(enrichedData);
      setFilteredClientsData(enrichedData);
      setTotalPages(Math.ceil(response.data.totalPages));
    } catch (error) {
      console.error('Error fetching contracts:', error);
    }
  };


  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setFilterStatus(value);
    fetchContracts(currentPage, 10, filterNameEmail, '', '', filterEnvelope, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff, value);
  };


  // Fetch de todos los staffs cuando se monta el componente
  useEffect(() => {
    fetchAllStaffIds().then(allStaff => {
      const staffMap = new Map(allStaff.map((staff: { id: string, jotformName: string }) => [staff.id, staff.jotformName]));

      // Filtrar staffList para que solo incluya los que están en allStaffIds
      const filteredStaffList = staffList.filter((staff) => staffMap.has(staff.id));

      setStaffList(filteredStaffList);
    });
  }, []);

  const getStatusText = (client: any) => {
    console.log("client", client)
    if (client.staffId) {
      return 'Asignado';
    } else if (client && client.step && client.step <= 5) {
      return 'En proceso';
    } else if (client && !client.step) {
      return 'No ha ingresado';
    } else if (client && client.offboarding && client.offboarding === true ) {
      return 'Completado';
    } else {
      return `Desconocido`;
    }
  };

  const getStatusClass = (client: any) => {
    if (client.staffId) {
      return 'asignado'; // Clase para Asignado
    } else if (client && client.step && client.step <= 5) {
      return 'en-proceso'; // Clase para En proceso
    } else if (client && !client.step) {
      return 'en-proceso'; // Clase para No ha ingresado
    } else if (client && client.offboarding && client.offboarding === true) {
      return 'asignado'; // Clase para Completado
    } else {
      return 'desconocido'; // Clase para estado desconocido (si necesitas)
    }
  };
  
  // Fetch de todos los staffs cuando se monta el componente
  useEffect(() => {
    fetchAllStaffIds().then(setStaffList);
  }, []);





  useEffect(() => {
    fetchContracts(currentPage, 10, filterNameEmail, '', filterEnvelope, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff);
  }, [currentPage, filterNameEmail, filterEnvelope, filterDateFrom, filterDateTo, filterStaff]);






  useEffect(() => {
    if (filterNameEmail.includes('@')) {
      fetchContracts(currentPage, 10, '', '', filterNameEmail, filterEnvelope, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff);
    } else {
      const [firstName, ...lastNameParts] = filterNameEmail.trim().split(' ');
      const lastName = lastNameParts.join(' ');
      fetchContracts(currentPage, 10, firstName || '', lastName || '', '', filterEnvelope, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff);
    }
  }, [currentPage, filterNameEmail, filterEnvelope, filterDateFrom, filterDateTo, filterStaff]);

  const handleNameEmailFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setFilterNameEmail(value);

    if (value.includes('@')) {
      // Filtrado por email
      fetchContracts(currentPage, 10, '', '', value, filterEnvelope, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff);
    } else {
      // Separar el nombre completo en firstName y lastName
      const names = value.split(' ');
      const firstName = names.slice(0, -1).join(' '); // Todas las palabras menos la última son firstName
      const lastName = names.slice(-1).join(' '); // La última palabra es lastName

      // Filtrado por nombre
      fetchContracts(currentPage, 10, firstName, lastName, '', filterEnvelope, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff);
    }
  };









  const handleEnvelopeFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterEnvelope(value);
    fetchContracts(currentPage, 10, filterNameEmail, '', value, filterDateFrom?.toISOString(), filterDateTo?.toISOString(), filterStaff);
  };


  const onClickUser = async (user: ContractUser) => {
    setWordSearched(user.user_email)
    setShowList(false)
    try {
      const { data, totalPages } = await getContractsWithPagination(user.user_id, currentPage, 1000)
      setFilteredClientsData(data)
      setTotalPages(totalPages)
    } catch (error) {
      console.error("Error fetching filtered contracts:", error)
    }
  }


  const handleSelectUser2 = (client: Client) => {
    const userId = client.user.id
    if (userId) {
      setUserInfo({
        firstName: client.user.firstName,
        lastName: client.user.lastName,
        userId: userId, // Guardando el userId en el estado
      })
      setIsModalVisible(true)
    } else {
      console.error("User ID is null or undefined!")
    }
  }




  const handleSelectUser = (user: Client['user']) => {
    const userToSelect: Users = {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: '',
      address: '',
      countryOfOrigin: '',
      gender: '',
      phone: '',
      whatsapp: '',
      nationalitie: '',
      language: '',
      timeZone: '',
      createdAt: '',
      first_service: '',
      last_service: '',
      photoId: '',
      photoPath: '',
      age: '',
      last_service_date_jotform_name: ''
    }
    selectUser(userToSelect)
    loadCounterMessagesClient(user.id)
    navigate('/userslist')
  }

  const getStageClass = (hasStaffId: boolean) => {
    return hasStaffId ? 'intake' : 'cliente'
  }

  const getStepText = (step: number | null) => {
    switch (step) {
      case null:
        return "SERVICIO CONTRATADO"
      case 0:
        return "SERVICIO CONTRATADO"
      case 1:
        return "SERVICIO CONTRATADO"
      case 2:
        return "FORMULARIOS"
      case 3:
        return "DOCUMENTOS"
      case 4:
        return "ETAPAS COMPLETADAS"
      default:
        return ""
    }
  }

  const getStepClass = (step: number | null) => {
    switch (step) {
      case null:
      case 0:
      case 1:
      case 2:
      case 3:
        return "step-black"
      case 4:
        return "step-green"
      default:
        return ""
    }
  }

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
      fetchContracts(newPage)
    }
  }




  const toggleNameEmailFilter = () => setShowNameEmailFilter(!showNameEmailFilter)

  const toggleDateFilter = () => setShowDateFilter(!showDateFilter)
  const toggleStageFilter = () => setShowStageFilter(!showStageFilter)
  const toggleStatusFilter = () => setShowStatusFilter(!showStatusFilter)
  const toggleStaffFilter = () => setShowStaffFilter(!showStaffFilter)
  const toggleEnvelopeFilter = () => setShowEnvelopeFilter(!showEnvelopeFilter)


  const getButtonClass = (hasStaffId: boolean) => {
    return hasStaffId ? 'buton-stage-intake' : 'buton-stage-cliente'
  }

  return (
    <>
      {!(selected) ? (
        <>
          <div style={{ backgroundColor: '#DFE4EA', height: '1px', width: '100%' }}></div>
          <div style={{ display: 'flex', justifyContent: `${staff ? 'space-between' : 'flex-end'}` }} className='p-4 hide-component'>
            {wordSearched && showList && (
              <div className='results-list3' style={{ top: '124px', left: '19px' }}>
                {usersList.map((user: ContractUser) => (
                  <div
                    onClick={() => onClickUser(user)}
                    className='search-result'
                    key={user.user_id}
                  >
                    {user.user_first_name} {user.user_last_name} -- {user.user_email}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div style={{ overflowX: 'auto' }}>
            <table
              className="notify-dashboard-table"
              style={{
                // width: '82rem',
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 10px',
                // marginLeft: '0.5rem'
                padding: '0 24px'
              }}
            >
              <thead>
                <tr style={{ fontSize: '16px', fontWeight: '700' }}>
                  <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                      Nombre
                      <img src={funnel} alt="Filter" onClick={toggleNameEmailFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    {showNameEmailFilter && (
                      <div style={{ position: 'absolute', top: '100%', left: 0, background: 'white', border: '1px solid #ddd', padding: '10px', zIndex: 1 }}>
                        <input
                          type="text"
                          placeholder="Filtrar por nombre o email"
                          value={filterNameEmail}
                          onChange={handleNameEmailFilterChange}
                        />
                      </div>
                    )}

                  </th>
                  <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                      Proceso de preasignación
                      <img src={funnel} alt="Filter" onClick={toggleEnvelopeFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    {showEnvelopeFilter && (
                      <div style={{ position: 'absolute', top: '100%', left: 0, background: 'white', border: '1px solid #ddd', padding: '10px', zIndex: 1 }}>
                        <input
                          type="text"
                          value={filterEnvelope}
                          onChange={handleEnvelopeFilterChange} // Conecta el manejador aquí
                        />
                      </div>
                    )}
                  </th>
                  <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                      Fecha
                      <img src={funnel} alt="Filter" onClick={toggleDateFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    {showDateFilter && (
                      <div style={{ position: 'absolute', top: '100%', left: 0, background: 'white', border: '1px solid #ddd', padding: '10px', zIndex: 1 }}>
                        <div>
                          <label>Desde:</label>
                          <DatePicker selected={filterDateFrom} onChange={(date) => setFilterDateFrom(date)} />
                        </div>
                        <div>
                          <label>Hasta:</label>
                          <DatePicker selected={filterDateTo} onChange={(date) => setFilterDateTo(date)} />
                        </div>
                      </div>
                    )}
                  </th>
                  {/*   <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                      Responsable
                      <img src={funnel} alt="Filter" onClick={toggleStageFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    {showStageFilter && (
                      <div style={{ position: 'absolute', top: '100%', left: 0, background: 'white', border: '1px solid #ddd', padding: '10px', zIndex: 1 }}>
                        <select value={filterStage} onChange={(e) => setFilterStage(e.target.value)}>
                          <option value="">Todos</option>
                          <option value="Cliente">Cliente</option>
                          <option value="Case Worker">Case Worker</option>
                        </select>
                      </div>
                    )}
                  </th> */}
                  <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                      Estado
                      <img src={funnel} alt="Filter" onClick={toggleStatusFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    {showStatusFilter && (
                      <div style={{ position: 'absolute', top: '100%', left: 0, background: 'white', border: '1px solid #ddd', padding: '10px', zIndex: 1 }}>
                        <select value={filterStatus} onChange={handleStatusFilterChange}>
                          <option value="">Todos</option>
                          <option value="No ha ingresado">No ha ingresado</option>
                          <option value="En proceso">En proceso</option>
                          <option value="Asignado">Asignado</option>
                          <option value="Completado">Completado</option>
                        </select>
                      </div>
                    )}
                  </th>
                  <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                      Staff
                      <img src={funnel} alt="Filter" onClick={toggleStaffFilter} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    {showStaffFilter && (
                      <div style={{ position: 'absolute', top: '100%', left: 0, background: 'white', border: '1px solid #ddd', padding: '10px', zIndex: 1 }}>
                        <select value={filterStaff} onChange={(e) => setFilterStaff(e.target.value)}>
                          <option value="">Todos</option>
                          {staffList.map(staff => (
                            <option key={staff.id} value={staff.id}>{staff.jotformName}</option>
                          ))}
                        </select>

                      </div>
                    )}
                  </th>
                  <th>Acciones</th>
                  <th>Notas</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredClientsData) && filteredClientsData.map((client, index) => (
                  <tr key={index}>
                    <td style={{ width: '1rem', paddingLeft: '0.5rem' }} className='hover-table-notify'>
                      <div className="client-name">{client.user.firstName} {client.user.lastName}</div>
                      <div className="client-email">{client.user.email}</div>
                    </td>
                    <td style={{ fontSize: '14px', fontWeight: '700', width: '23rem' }}>{client.envelope_id}
                      <div className='flex flex-wrap gap-2 mt-2 mb-2'>
                        <button
                          // style={{
                          //   display: 'flex',
                          //   alignItems: 'center',
                          //   justifyContent: 'center',
                          //   padding: '3px 5px',
                          //   border: '1px solid #DFE4EA',
                          //   borderRadius: '3px', gap: '5px'
                          //   }}
                          className='product-badge personas'
                        >
                          <img src={UsersIcon} alt="Users" />
                          <p style={{ fontSize: '10px', fontWeight: 'bold', margin: '0' }}>{client.uniqueMemberTypeCount}</p>
                        </button>
                        {client.hasSpecificProduct && (
                          Array.from(client.productIds)
                            .filter((id: string) => othersProductsMap[id])
                            .map((id: string) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#DFE4EA',
                                //   borderRadius: '3px',
                                //   gap: '2px'
                                // }}
                                className='product-badge biometrics'
                              >
                                <img src={UsersIcon2} alt="UsersIcon2" />
                                <span >
                                  {othersProductsMap[id]}
                                </span>
                              </div>
                            ))
                        )}
                        {client.hasWorkProduct && (
                          Array.from(client.productIds)
                            .filter((id: string) => workProductsMap[id])
                            .map((id: string) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#22AD5C',
                                //   borderRadius: '3px',
                                //   gap: '2px',
                                //   marginBottom: '5px',
                                // }}
                                className='product-badge work'
                              >
                                <img src={Briefcase} alt="Briefcase" />
                                <span >
                                  {workProductsMap[id]}
                                </span>
                              </div>
                            ))
                        )}
                        {client.hasStudyProduct && (
                          Array.from(client.productIds)
                            .filter((id: string) => studyProductsMap[id])
                            .map((id: string) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#EF5DA8',
                                //   borderRadius: '3px',
                                //   gap: '2px'
                                // }}
                                className='product-badge study'
                              >
                                <img src={Graduate} alt="Graduate" />
                                <span >
                                  {studyProductsMap[id]}
                                </span>
                              </div>
                            ))
                        )}
                        {client.hasVisitProduct && (
                          Array.from(client.productIds)
                            .filter((id: string) => visitProductsMap[id])
                            .map((id: string) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   backgroundColor: '#01A9DB',
                                //   borderRadius: '3px',
                                //   gap: '2px'
                                // }}
                                className='product-badge tourism'
                              >
                                <img src={Plane} alt="Plane" />
                                <span >
                                  {visitProductsMap[id]}
                                </span>
                              </div>
                            ))
                        )}
                        {client.hasresidenceProducts && (
                          Array.from(client.productIds)
                            .filter((id: string) => residenceProductsMap[id])
                            .map((id: string) => (
                              <div
                                key={id}
                                // style={{
                                //   display: 'flex',
                                //   alignItems: 'center',
                                //   justifyContent: 'center',
                                //   padding: '3px 5px',
                                //   borderRadius: '3px',
                                //   gap: '2px',
                                //   background: '#F27430',
                                // }}
                                className='product-badge residencia'
                              >
                                <img style={{ width: '25px' }} src={Houseonly} alt="Houseonly" />
                                <span>
                                  {residenceProductsMap[id]}
                                </span>
                              </div>
                            ))
                        )}
                        <span className={`step-tag ${getStepClass(client.step)} en-proceso`}>
                          {getStepText(client.step)}
                        </span>
                      </div>

                    </td>
                    <td>{client.formattedDate}</td>
                    {/*    <td>
                      <span className={`stage-tag ${getStageClass(!!client.staffId)}`}>
                        {client.staffId ? 'Case Worker' : 'Intake'}
                      </span>
                    </td> */}
                  <td
  style={{ marginTop: '2rem' }}
  className={`status-label notify-dashboard-table ${getStatusClass(client)}`}
>
  {getStatusText(client)}
</td>

                    <td style={{ width: '12rem', paddingLeft: '1rem' }}>{client.staffName}</td>

                    <td style={{ display: 'flex', gap: '10px', marginLeft: '-3rem' }}>
                      <button className={`button-ui button-secondary button-xs ${getButtonClass(!!client.staffId)}`}
                        onClick={() => {
                          setSelected(client)
                          selectUser(client.user as any)
                        }}>
                        Ver proceso
                      </button>
                      <button
                        style={{ width: '4rem', padding: '5px', height: '2rem' }}
                        className='button-ui button-secondary button-xs'
                        onClick={() => handleSelectUser(client.user)}>
                        <div>
                          <img src={UserBtn} alt="UserBtn" className="button-image" />
                          {/* <span className="button-text">Ir</span> */}
                        </div>
                      </button>
                    </td>
                    <td>
                      <div style={{ marginTop: '23px', display: 'flex', flexDirection: 'row' }}>
                        <button
                          style={{ width: '2rem', height: '2rem', borderRadius: '10px', boxShadow: 'none' }}
                          onClick={() => handleSelectUser2(client)}>
                          <img src={notes} alt="Notas" />
                        </button>
                        <button
                          style={{ width: '2.5rem', height: '2rem', borderRadius: '10px', boxShadow: 'none' }}
                          onClick={() => openNotesModal(client)}>
                          <img src={linki} alt="Notas" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}>
                ←
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                  onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}
              <button
                className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}>
                →
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {selected && <ShowAnswersConsultant setActiveKey={setActiveKey} contractsFound={selected.envelope_id} userEmail={selected.user.email} goToResumen={function (): void {
              throw new Error('Function not implemented.')
            } } />}
        </>
      )}
      {isModalVisible2 === true ? (
        <SlideIntakeProcess
          visible={isModalVisible2}
          onCancel={handleCancel2}
          envelopeId={selectedEnvelopeId}
          firstName={userInfo?.firstName}
          lastName={userInfo?.lastName}
        />
      ) : null}
      {isModalVisible && userInfo?.userId && (
        <NotesModalIntake
          userInfo={userInfo}
          visible={isModalVisible}
          onCancel={handleCancel}
          onCreate={handleCreate}
          products={[]}
          userId={userInfo.userId} // Pasando el userId al modal
        />
      )}
    </>
  )
}

export default NotifyDashboard
