/* eslint-disable @typescript-eslint/no-unused-vars */
import { Space, Table, Spin, Tooltip } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import Column from 'antd/lib/table/Column';
import { getAllCompletedContractsByEmail } from '../../../modules/apps/docusign/_requests';
import { RootObjectCompletedContracts, type FolderItem } from '../../../modules/apps/docusign/_models';
import './style.css';
import { PdfView } from 'app/pages/services/services/formsManagerClient.tsx/pdfView';
import { ContractsTemps } from 'app/modules/apps/contract/_models';

interface IProps {
  email: string;
  setContractsFound: Dispatch<SetStateAction<RootObjectCompletedContracts>>;
  contractData: ContractsTemps | undefined
}

const CompletedContractsAutoShow = (props: IProps) => {
  const intl = useIntl();
  const { email, setContractsFound,contractData } = props;
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [envelopeIdSelected, setEnvelopeIdSelected] = useState<string | undefined>(undefined);

  const { data, isError, isLoading, isRefetching, status } = useQuery(
    'getAllContractCompletedByEmail',
    async () =>
      await getAllCompletedContractsByEmail(
        email,
        fromDate === '' ? '2023-01-01' : fromDate,
        toDate === '' ? '0000-00-00' : toDate
      ),
    { retry: false }
  );

  useEffect(() => {
    if (status === 'success') {
      setContractsFound(data);
      if (data && data.folderItems && data.folderItems.length > 0) {
        setEnvelopeIdSelected(data.folderItems[0].envelopeId); // Selecciona el primer contrato automáticamente
      }
    }
  }, [status, data, setContractsFound]);

/*   useEffect(() => {
    if (isError) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Regresar',
      });
    }
  }, [isError]); */

  if (isLoading || isRefetching) {
    return (
      <>
   {/*      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', fontSize: '1.5rem', marginTop: '1rem' }}>
          Contratos completados
        </div> */}
        <Spin tip={intl.formatMessage({ id: 'LOADING.BUTTON' })} size='large'>
          <div className='content' />
        </Spin>
      </>
    );
  }

  const filteredData = data?.folderItems.filter((item) => item.matchId === contractData?.match_id && item.matchId && contractData?.match_id );

  return (
    <div style={{ marginLeft: '3rem' }} className='contract-viewer'>
  <h1 style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', fontSize: '1.5rem', marginTop: '1rem' }}>Contratos completados</h1>
  {filteredData && filteredData[0] &&<>
  <Table
    className='tableContracts'
    pagination={false}
    dataSource={filteredData}
    rowKey='id'
  >

<Column
     title="Envelope Id"
      key='envelopeId'
      render={(text, record: FolderItem) => (
        <Space>
          <p>{record.envelopeId}</p>
        </Space>
      )}
    />
 <Column
          title="Nombre contrato"
          dataIndex='emailSubject'
          key='subject'
          render={(text: string) => (
            <Tooltip title={text}>
              {text.length > 50 ? `${text.substring(0, 30)}...` : text}
            </Tooltip>
          )}
        />

<Column
     title="Status contrato"
      key='Date'
      render={(text, record: FolderItem) => (
        <Space>
          <p>{record.status}</p>
        </Space>
      )}
    />
 
 

    <Column
     title="Fecha completado"
      key='Date'
      render={(text, record: FolderItem) => (
        <Space>
          <p>{record.completedDateTime.toString().substring(0, 10)}</p>
        </Space>
      )}
    />
  </Table>

  {filteredData[0].envelopeId && (
    <div style={{ marginTop: '2rem' }}>
      <PdfView envelopeId={filteredData[0].envelopeId} />
    </div>
  )}
  </>
}
</div>

  );
};

export default observer(CompletedContractsAutoShow);
