import {useContext, useEffect, useState} from 'react'
import {RoleEnum} from '../../../auth/models/User/UserModel'
import {Link} from 'react-router-dom'
import {Container} from '../../../components/Container/index'
import {RootStoreContext} from '../../../stores/rootStore'
import {useIntl} from 'react-intl'

const ContratationMenu = () => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const intl = useIntl()
  const [consultan, setConsultant] = useState(false)
  useEffect(() => {
    if (user !== null) {
      if (user?.role?.id === RoleEnum.admin) {
        setConsultant(true)
      }
    }
  }, [user])

  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.CONTRACTATION'})}>
        {consultan ? (
          <div className='flex gap-4 justify-center flex-wrap'>
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/services/contracts/answers'
            >
              <img className='w-32' src='/media/svg/menu-icons/cv_services.svg' alt='' />
              <h2>Respuestas de Contratacion</h2>
            </Link>
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/services/contracts/completed'
            >
              <img className='w-32' src='/media/svg/menu-icons/rrecruitment_services.svg' alt='' />
              <h2>Contratos Completados</h2>
            </Link>
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/user/support'
            >
              <img className='w-32' src='/media/svg/menu-icons/rrecruitment_services.svg' alt='' />
              <h2>Chat en vivo</h2>
            </Link>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export {ContratationMenu}
