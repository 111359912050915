/* eslint-disable  */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Select } from 'antd'
import { useQuery } from 'react-query'
import { getImmigrationOptions } from 'app/modules/apps/immigration/options/core/_requests'
import { getImmigrationServices } from 'app/modules/apps/immigration/services/core/_requests'
import './filters.css'
interface IProps {
  roadSelected: string
  setRoadSelected: Dispatch<SetStateAction<string>>
  provinceSelected: string
  setProvinceSelected: Dispatch<SetStateAction<string>>

}

const Filters: React.FC<IProps> = ({
  roadSelected,
  setRoadSelected,
  provinceSelected,
  setProvinceSelected,
}) => {
  const { data: ImmigrationServices, status } = useQuery(
    'ListAllImmigrationServicesSelect',
    async () => await getImmigrationServices({})
  )

  const { data, refetch } = useQuery(
    'getAllImmaai',
    async () =>
      await getImmigrationOptions(
        {
          page: 1,
          limit: 100,
        },
        {
          name: roadSelected,
        }
      )
  )

  let provinces = [
    {
      name: 'Alberta',
    },
    {
      name: 'Atlantic',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'Federal',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
  ]

  const getProvinceFlagIcon = (provinceName: string) => {

    const flagIcons: Record<string, string> = {
      Alberta: '/media/svg/menu-icons/Alberta.png',
      Atlantic: '/media/svg/menu-icons/atlantic.png',
      'British Columbia': '/media/svg/menu-icons/British columbia.png',
      Manitoba: '/media/svg/menu-icons/Manitoba.png',
      Federal: '/media/svg/menu-icons/Canada.png',
      'New Brunswick': '/media/svg/menu-icons/New Brunswick.png',
      'Newfoundland and Labrador': '/media/svg/menu-icons/Newfoundland and Labrador.png',
      'Nova Scotia': '/media/svg/menu-icons/Nova Scotia.png',
      Ontario: '/media/svg/menu-icons/Ontario.png',
      'Prince Edward Island': '/media/svg/menu-icons/Prince Edward Island.jpg',
      Quebec: '/media/svg/menu-icons/Quebec.png',
      Saskatchewan: '/media/svg/menu-icons/Saskatchewan.png',
    };

    return flagIcons[provinceName] || '';
  };


  useEffect(() => {
    console.log('road', roadSelected);
    refetch();
  }, [roadSelected]);
  const handleRoadChange = (name: string) => {
    setRoadSelected(name)
  }

  const handleProvinceChange = (name: string) => {
    setProvinceSelected(name)
  }

  useEffect(() => {
    console.log('road' + JSON.stringify(roadSelected))
    refetch()
  }, [roadSelected])
  useEffect(() => {
    console.log('province' + provinceSelected)
  }, [provinceSelected])
  return (
    <div className='filters-direction'>
      
     <div  className='div-selector-responsive'>   <b>Via</b>
        <Select
          className='slide-time-filter'
          defaultValue={roadSelected}
          value={roadSelected}
          style={{ minWidth: '12rem', width: '100%', backgroundColor: 'white' }}
          onChange={handleRoadChange}
          options={[
            { value: '', label: 'Ver Todo' },
            ...(ImmigrationServices
              ? ImmigrationServices.map((road: any) => ({
                value: road.name,
                label: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {road.name === 'Vía laboral' && (
                      <>
                        <img
                          src='/media/svg/menu-icons/Group.svg'
                          alt='Via laboral'
                          style={{
                            backgroundColor: 'var(--green-green, #22AD5C)',
                            borderRadius: '50%',
                            marginRight: '8px',
                            padding: '4px',
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        <span>{road.name}</span>
                      </>
                    )}
                    {road.name === 'Departamento de educacion' && (
                      <>
                        <img
                          src='/media/svg/menu-icons/books.svg'
                          alt='Via laboral'
                          style={{
                            backgroundColor: 'var(--green-green, #22AD5C)',
                            borderRadius: '50%',
                            marginRight: '8px',
                            padding: '4px',
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        <span>{road.name}</span>
                      </>
                    )}
                    {road.name === 'Vía estudios' && (
                      <>
                        <img
                          src='/media/svg/menu-icons/books.svg'
                          alt='Via estudios'
                          style={{
                            backgroundColor: 'var(--fuschia-100, #EF5DA8)',
                            borderRadius: '50%',
                            marginRight: '8px',
                            padding: '4px',
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        <span>{road.name}</span>
                      </>
                    )}
                    {road.name === 'Vía express entry' && (
                      <>
                        <img
                          src='/media/svg/menu-icons/briefcase-alt.svg'
                          alt='Via express entry'
                          style={{
                            backgroundColor: 'var(--cyan-cyan, #01A9DB)',
                            borderRadius: '50%',
                            marginRight: '8px',
                            padding: '4px',
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        <span>{road.name}</span>
                      </>
                    )}
                  </div>
                ),
              }))
              : [])
          ]}
        />
      </div>

      <div className='div-selector-responsive'>
        <b>  Provincia
        </b>
        <Select
          className='slide-time-filter'
          defaultValue={provinceSelected}
          value={provinceSelected}
          placeholder='Selecciona una provincia'
          style={{ minWidth: '12rem', width: '100%' }}
          onChange={handleProvinceChange}
          options={[
            { value: '', label: 'Ver Todo' },
            ...(provinces
              ? provinces.map((province: any) => ({
                value: province.name,
                label: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={getProvinceFlagIcon(province.name)}
                      alt={`${province.name} flag`}
                      style={{
                        borderRadius: '50%',
                        marginRight: '8px',
                        padding: '4px',
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <span>{province.name}</span>
                  </div>
                ),
              }))
              : [])
          ]}
        /></div>

    </div>
  )
}

export default Filters
