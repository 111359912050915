/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'

import Rocket from './userDashboardIcons/rocket.svg'
import Grid2 from './userDashboardIcons/grid-2-plus2.svg'
import World from './userDashboardIcons/world.svg'
import { RootStoreContext } from 'stores/rootStore'
import { Link } from 'react-router-dom'
import './style.css'

function PowerUps() {
  const [activePowerUps, setActivePowerUps] = useState(true)
  const rootStore = useContext(RootStoreContext)
  const { translationsUser, loadTranslationsUser } = rootStore.translationStore
  const [completedTranslationCounter, setCompletedTranslationCounter] = useState(0)
  const [isHovered2, setIsHovered2] = useState(false)
  useEffect(() => {
    loadTranslationsUser()
  }, [])

  useEffect(() => {
    const completedTranslations = translationsUser.filter(
      (translation) => translation.status === 'Completed'
    )
    setCompletedTranslationCounter(completedTranslations.length)
    if (translationsUser.length < 1) {
      setActivePowerUps(false)
    } else {
      setActivePowerUps(true)
    }
  }, [translationsUser])

  return (
    <div className='add-more-powerups-container'>
      <div className='flex flex-row w-full gap-2 items-center'>
        <img src={Rocket} alt="Rocket" />
        <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom:'0' }}>Sus servicios adicionales</h2>
      </div>
      {activePowerUps === false ?
        <div >
          <p >¡Haga más rápido su proceso con servicios pensados en agilizar su plan!</p>
          <div className='add-more-powerups-box'>
            <img style={{ height: '22px', width: '22px' }} src={Grid2} alt="Grid" />
            <p style={{ color: '#6B7280', textAlign: 'center' }}>Agrega aquí <br />su primer Power-up</p>
          </div>
        </div>
        :
        <div className='flex flex-col w-full'>
          {translationsUser.length > 0 &&
            <Link to='/translation'>
              <div  className='powerup-card'>
                <div className='powerup-card-left-style' style={{ backgroundColor: '#FBBF24', width: '40px' }}>
                  <img style={{ height: '20px', width: '20px' }} src={World} alt="World" />
                </div>
                <div className='powerup-card-right-style'>
                  <h2>Traducciones</h2>
                  <p> <b>{completedTranslationCounter > 0 ? completedTranslationCounter : 0}</b>  de <b>{translationsUser.length > 0 ? translationsUser.length : 0}</b> listas</p>
                </div>
              </div>
            </Link>
          }
          {/*  <div style={{ borderRadius: '8px', marginBottom: '1rem', height: '4rem', display: 'flex', flexDirection: 'row', boxShadow: '0px 4px 12px 0px rgba(13 10 44 /6%)', gap: '1rem' }}>
            <div style={{ backgroundColor: '#EF5DA8', height: '4rem', width: '40px', display: 'flex', justifyContent: 'center', paddingTop: '5px', borderEndStartRadius: '8px', borderStartStartRadius: '8px' }}>
              <img style={{ height: '20px', width: '20px' }} src={Graduation} alt="Graduation" />
            </div>
            <div>
              <h1>Asesoría educativa</h1>
              <p>1 de 2 listas</p>
            </div>
          </div> */}
        </div>
      }
      {/* <button className='timeline-btn-one'>
        <p className='btn-timeline-one' style={{ marginBottom: '0' }}>Ver más</p>
        <p className='btn-timeline-one-text' style={{ marginBottom: '0' }}>Ver más</p>
        <img style={{ height: '16px', width: '16px', marginTop: '3px' }} src={Arrow} alt="arrow" />
      </button> */}
      <button
          className='button2'
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <Link to={'/cart'} className='div-meet2'>
            <b className='meet-text2'>VER MÁS</b>
            <img
              alt="Calendar"
              className='meet-image2'
              src={isHovered2 ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'} />

          </Link>
        </button>
    </div>
  )
}

export default PowerUps
