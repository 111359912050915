/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  type IQueryUsers,
  initialIQueryUsers,
  type IQueryUsersFilter,
  initialIQueryUsersFilter,
} from '../../../../app/modules/apps/users/core/_models'
import React, {useContext} from 'react'
import {Button, Container, Link} from '../../../../components'
import {RootStoreContext} from '../../../../stores/rootStore'
import SendinblueFilter from './List/sendinBlueTemp'
import UserMenusoptions from './UserMenusoptions'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {type ProfileImmigration} from 'app/modules/apps/profile/core/_models'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const SendinblueTemp: React.FC<Props> = (props) => {
  const {ProfileImmigration} = props
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const [search, setSearch] = React.useState<IQueryUsers>(initialIQueryUsers)
  const [searchFilter, setSearchFilter] =
    React.useState<IQueryUsersFilter>(initialIQueryUsersFilter)
  return (
    <Container title={intl.formatMessage({id: 'CLIENTS.CLIENTSLIST'})}>
      <Link to='/admin/tools'>
        <Button
          className='btn  btn-primary'
          style={{
            width: '8rem',
            alignSelf: 'end',
            marginRight: '2rem',
            border: 'none',
            marginBottom: '1rem',
            gap: '0.5rem',
          }}
        >
          Volver al Menu
        </Button>
      </Link>
      {currentUser != null ? (
        <UserMenusoptions ProfileImmigration={ProfileImmigration} />
      ) : (
        <>
          <SendinblueFilter
            search={search}
            searchFilter={searchFilter}
            setSearch={setSearch}
            searchQuery={search}
          />{' '}
        </>
      )}
    </Container>
  )
}

export default observer(SendinblueTemp)
