/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Loader } from '../../components/Loader'

export interface IOption {
  id: string
  value: string
}

interface IProps {
  options: IOption[]
  onChange: (value: string) => void
  isLoading?: boolean
  onSelect: (option: IOption) => void
  selected?: IOption
}

const Autocomplete: React.FC<IProps> = ({
  options,
  onChange,
  isLoading = false,
  onSelect,
  selected,
}) => {
  const [query, setQuery] = useState('')

  useEffect(() => {
    onChange(query)
  }, [query])

  return (
    <div>
      <Combobox value={selected} onChange={onSelect}>
        <div className=' mt-1'>
          <div className='relative  cursor-defaul '>
            <Combobox.Input
              className='custom-input'
              displayValue={(option: any) => option.value}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <div className='h-5 w-5 text-gray-400' aria-hidden='true'>
                {isLoading && (
                  <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                    <Loader />
                  </div>
                )}
              </div>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => {
              setQuery('')
            }}
          >
            <Combobox.Options
              style={{ zIndex: '999999' }}
              className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50'>
              {options.length === 0 && query !== '' ? (
                <div className='relative cursor-pointer select-none py-2 px-4 text-gray-700'>
                  Nothing found.
                </div>
              ) : (
                options.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      `relative flex cursor-pointer select-none py-2 pl-4 pr-4 ${active ? 'bg-teal-600 text-tomato-100' : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal text-black'}`}
                        >
                          {option.value}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-black' : 'text-teal-600'
                              }`}
                          >
                            <div className='h-5 w-5 text-gray-400' aria-hidden='true'>
                              {' '}
                            </div>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export { Autocomplete }
