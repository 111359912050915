import {Container, Link} from '../../../../components'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

const EducationServices = () => {
  const intl = useIntl()

  const navigate = useNavigate()
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.AVAILABLESERVICES'})}>
        <ArrowLeftOutlined
          className=' p-2 hover:bg-gray-200 hover:rounded-lg'
          onClick={() => navigate(-1)}
        />

        <div className='grid grid-cols-3 gap-6 p-3'>
          <div className=' shadow rounded-xl hover:scale-105 transition delay-150 duration-300 ease-in-out'>
            <img className='mx-auto my-auto px-9 py-9' src='/media/services/opciones.png' alt='' />
            <p className='text-center font-bold text-lg'>OPCIONES EDUCATIVAS </p>
            <Link
              className=' mt-7  cursor-pointer rounded-b-xl flex justify-center  p-3 text-white bg-tomato-500 focus:ring-4 focus:outline-none font-medium  text-sm px-5  text-center '
              to='/cart/eduoption'
            >
              Adquirir
            </Link>
          </div>
          <div className=' shadow rounded-xl hover:scale-105 transition delay-150 duration-300 ease-in-out'>
            <img className='mx-auto my-auto px-9 py-9' src='/media/services/asesoria.png' alt='' />
            <p className='text-center font-bold text-lg'>ASESORIA EDUCATIVA</p>
            <Link
              className='cursor-pointer rounded-b-xl flex justify-center  p-3 text-white bg-tomato-500 focus:ring-4 focus:outline-none font-medium  text-sm px-5  text-center '
              to='/cart/OpcionesEducativas'
            >
              Adquirir
            </Link>
          </div>

          <div className=' shadow rounded-xl hover:scale-105 transition delay-150 duration-300 ease-in-out'>
            <img
              className='mx-auto my-auto px-10 py-10'
              src='/media/services/educacion.png'
              alt=''
            />
            <p className='text-center font-bold text-lg'>REGISTRO ACADEMICO</p>
            <a
              className='cursor-pointer rounded-b-xl flex justify-center  p-3 text-white bg-tomato-500 focus:ring-4 focus:outline-none font-medium  text-sm px-5  text-center'
              href='https://www.immilandcanada.com/educacion/registro-institucion-master'
              target='_blank'
              rel='noreferrer'
            >
              Adquirir
            </a>
          </div>
          <div className=' shadow rounded-xl hover:scale-105 transition delay-150 duration-300 ease-in-out'>
            <img className='mx-auto my-auto px-9 py-9' src='/media/services/idioma.png' alt='' />
            <p className='text-center font-bold text-lg'>REGISTRO IDIOMAS</p>
            <a
              className='cursor-pointer rounded-b-xl flex justify-center  p-3 text-white bg-tomato-500 focus:ring-4 focus:outline-none font-medium  text-sm px-5  text-center'
              href='https://www.immilandcanada.com/educacion/registro-idiomas-online'
              target='_blank'
              rel='noreferrer'
            >
              Adquirir
            </a>
          </div>
        </div>
      </Container>
    </>
  )
}

export default EducationServices
