/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Button, Popover, Select, Spin } from 'antd'
import { RootStoreContext } from '../../../../stores/rootStore'
import { observer } from 'mobx-react'
import { type IComposeData } from '../../../../app/modules/apps/appointments/core/_models'
import '../ComposeTemplate/style.css'
import { IToUsers } from '../interfaces/ITOUsers'
import { AvatarSection } from 'core/components/CardWindow'
import AvatarProfileUsers from 'app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import BaseModalWrapper from '../../../../components/profilePopup/BaseModalWarpper'
import { getUserUsersById } from 'app/modules/apps/users/core/_requests'
import { Users } from 'app/modules/apps/users/core/_models'
import { Link } from 'react-router-dom'
const { Option } = Select
interface IProps {
  setCC: React.Dispatch<React.SetStateAction<IToUsers[]>>
  dataTo?: Array<[string, string]>
  data: IComposeData
  isNew: boolean
}

const ToComponent: React.FC<IProps> = ({ setCC, data, isNew }) => {
  const rootStore = useContext(RootStoreContext)
  const { currentUser } = rootStore.usersStore
  const { loadStaffsTo, staffsTo, isLoadingStaff } = rootStore.staffStore
  const { user } = rootStore.userStore
  const [value, setValue] = React.useState([{ key: data.userId }])
  const [defvalue, setdefValue] = React.useState([{ label: data.email, key: data.userId }])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { getUsers, isLoadingUsers, users, selectUser } = rootStore.usersStore

  const findUser = async (fromId: string) => {
    const result: Users | undefined | void = await getUserUsersById(fromId)
    if (result !== undefined) {
      selectUser(result)
    }
  }


  useEffect(() => {
    loadStaffsTo('')
    if (currentUser) {
      setValue([{ key: currentUser.id as string }])
      setdefValue([{ label: currentUser.email, key: currentUser.id as string }])
    }
  }, [])

  useEffect(() => {
    console.log(value, 'valor')
    if (value.length === 0) {
      setCC([{ "id": "" }])
    }
    if (value.length > 0) {
      const newCC: IToUsers[] = []
      value.forEach((item: any) => {
        newCC.push({ id: item.key })
      })

      setCC(newCC)
    }
  }, [value])

  const handleChange = (value: any) => { }

  const fetchUser = (value: string) => {
    loadStaffsTo(value)
  }

  return (
    <div className='flex gap-4'>
      <span style={{ width: '3rem' }}>Para:</span>
      <Select
        className='option-styles option-force to-width'
        defaultValue={isNew === false ? currentUser?.email !== undefined ? [{ label: currentUser.email, key: data.userId }] : defvalue : undefined}
        mode='multiple'
        labelInValue
        placeholder={'Agregar destinatario'}
        filterOption={false}
        onSearch={fetchUser}
        onSelect={(value: any) => {
          handleChange(value)
        }}
        onChange={(newValue) => {
          setValue(newValue)
        }}>
        {isLoadingStaff ? (
          <Spin size='small' />
        ) : (
          <>
            {staffsTo.map((d) => (
              <Option key={d.id} value={d.id}>
                <Popover
                  content={
                    <>
                      <div style={{ display: 'flex', gap: '10px' }}> <AvatarProfileUsers
                        image={d && d && d.photoPath
                          ? `${d.photoPath}/${d.photoId}-avatar-small.jpg`
                          : ''}
                        size={50}
                      /> <b>{d.email}</b></div>
                      <Link to='/usersList'>
                        <button
                          style={{ marginLeft: 20 }}
                          className='button2'
                          onClick={() => {
                            if (d.id) {
                              findUser(d.id)
                            }
                          }}>
                          <p className='meet-text2'> Ver perfil</p>
                        </button>
                      </Link>
                    </>
                  }
                  placement="bottom"
                  style={{
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    border: '1px solid white',
                    borderRadius: '23px'
                  }}>
                  {d.email}
                </Popover>
              </Option>
            ))}
          </>)}
      </Select>
    </div>
  )
}

export default observer(ToComponent)
