/* eslint-disable @typescript-eslint/no-unused-vars */

import {useState} from 'react'
import {Container} from '../../../../components'
import StaffTranslation from './StaffTranslation'
import {type Translations} from '../../../../app/modules/apps/translations/core/_models'
import {useIntl} from 'react-intl'
import {type ProfileImmigration} from '../../../../app/modules/apps/profile/core/_models'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const TranslationsWrapperStaff: React.FC<Props> = (props) => {
  const intl = useIntl()
  const [translation, setTranslation] = useState<Translations>({})
  const [assign, setAssign] = useState(false)
  const {ProfileImmigration} = props
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.TRANSLATION'})}>
        <StaffTranslation ProfileImmigration={ProfileImmigration} />
      </Container>
    </>
  )
}

export {TranslationsWrapperStaff}
