import PdfViewerAndres from 'core/components/PdfViewer/pdfViewerAndres'

interface IProps {
  document: any
  setLookDocument: React.Dispatch<any>
}

const DocumentView: React.FC<IProps> = ({ document, setLookDocument }) => {
  return (
    <>
      <div style={{paddingBottom:'1rem', borderBottom:'1px solid #DFE4EA'}}>
        <button
          style={{
            marginBottom: '10px',
          }}
          className='button-ui button-secondary button2'
          onClick={() => {
            setLookDocument(undefined)
          }}
        >
          <img
            alt="Calendar"
            className='meet-image2'
            src={'/media/icons/app/menu/arrow-left-white.svg' }
          />
          <p className='meet-text2' style={{ fontWeight: 'bold', margin: '0' }}>Volver</p>
        </button>
      </div>
      {document ? (
        <div style={{ marginBottom: '1rem', marginTop:'1rem' }}>
        <PdfViewerAndres name={''} url={URL.createObjectURL(document)} children={undefined} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default DocumentView
