/* eslint-disable @typescript-eslint/no-unused-vars */

import {useContext, useEffect} from 'react'
import EmbedContent from './Embed/EmbedContent/EmbedContent'
import {Outlet} from 'react-router-dom'
import {RootStoreContext} from '../../../stores/rootStore'
import {getEmbedVars} from './Product/EmbedVars'

const LayoutEmbed: React.FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {cart} = rootStore.cartStore
  useEffect(() => {
    getEmbedVars()
  }, [])

  return (
    
    <>
      <EmbedContent>
        <Outlet />
      </EmbedContent>
    </>
  )
}

export default LayoutEmbed
