import React, {type ReactNode} from 'react'

interface ContentProps {
  children: ReactNode
}

const WebContecnt: React.FC<ContentProps> = ({children}) => {
  return <>{children}</>
}

export default WebContecnt
