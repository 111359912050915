import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const USERNOTES = `${API_URL}/usernotes`
const TRANSLATIONNOTES = `${API_URL}/translationnotes`;


const getNotesByTranslationId = async (translationId: string): Promise<any> => {
  if (!translationId) {
    console.error("Translation ID is null or undefined!");
    throw new Error("Translation ID is required to fetch notes.");
  }
  try {
    const response = await axios.get(`${TRANSLATIONNOTES}/bytranslation/${translationId}`);
    return response.data as any;
  } catch (error) {
    console.error("Error fetching notes by translation ID:", error);
    throw error;
  }
};




const getNotesByUser = async (id: string): Promise<any> => {
  if (!id) {
      console.error("User ID is null or undefined!");
      throw new Error("User ID is required to fetch notes.");
  }
  const response = await axios.get(`${USERNOTES}/byuserid/${id}`);
  return response.data as any;
}

const getNotesByUser2 = async (userId: string): Promise<any> => {
  if (!userId) {
    console.error("User ID is null or undefined in getNotesByUser2!");
    throw new Error("User ID is required to fetch notes.");
  }
  try {
    const response = await axios.get(`${USERNOTES}/byuserid/${userId}`);
    return response.data as any;
  } catch (error) {
    console.error("Error fetching notes in getNotesByUser2:", error);
    throw error;
  }
};

// Nueva función postNote2
const postNote4 = async (userId: string, staff: any, note: string, module: string): Promise<any> => {
  if (!userId) {
    console.error("User ID is null or undefined in postNote2!");
    throw new Error("User ID is required to post a note.");
  }
  try {
    const response = await axios.post(`${USERNOTES}`, {
      userId: userId,
      staffId: staff,
      note: note,
      module: module,
    });
    return response.data as any;
  } catch (error) {
    console.error("Error posting note in postNote2:", error);
    throw error;
  }
};
const postNote = async (user: any, staff: any, note: any, module:string): Promise<any> => {
    const response = await axios.post(`${USERNOTES}`, {
        userId: user,
        staffId: staff,
        note: note,
        module: module
    })
    return response.data as any
}

const getNotesByTranslation = async (translationId: string): Promise<any> => {
  if (!translationId) {
    console.error("Translation ID is null or undefined!");
    throw new Error("Translation ID is required to fetch notes.");
  }
  try {
    const response = await axios.get(`${USERNOTES}/bytranslation/${translationId}`);
    return response.data as any;
  } catch (error) {
    console.error("Error fetching notes in getNotesByTranslation:", error);
    throw error;
  }
};
const postNoteTranslation = async (translationId: string, staff: any, note: string): Promise<any> => {
  try {
    const response = await axios.post(`${TRANSLATIONNOTES}`, {
      translation_id: translationId,  // Correcto
      staff_id: staff.id,
      note: note,
    });
    return response.data as any;
  } catch (error) {
    console.error("Error posting note in postNoteTranslation:", error);
    throw error;
  }
};






const postNote2 = async (user: any, staff: any, note: any, module: string): Promise<any> => {
    try {
      const response = await axios.post(`${USERNOTES}`, {
        userId: user.user_id,  // Assuming 'id' is the correct property for user ID
        staffId: staff,  // Assuming 'id' is the correct property for staff ID
        note: note,
        module: module
      });
      return response.data as any;
    } catch (error) {
      console.error('Error in postNote:', error);
      throw error; // Propagate the error to the calling code
    }
  };
  const editNote = async (id: string, note: string): Promise<any> => {
    const response = await axios.patch(`${API_URL}/translationnotes/edit/${id}`, {
      note: note
    });
    return response.data as any;
  }
  
  const deleteNote = async (id: string): Promise<any> => {
    const response = await axios.delete(`${API_URL}/translationnotes/delete/${id}`);
    return response.data as any;
  }
  



export {
    getNotesByUser,
    postNote,
    editNote,
    deleteNote,
    postNote2,
    getNotesByUser2,
    postNote4,
    getNotesByTranslation,
    postNoteTranslation,
    getNotesByTranslationId
}
