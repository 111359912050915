/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { type Dispatch, type SetStateAction, useContext, useState, useEffect } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { useQuery } from 'react-query'
import { Checkbox, DatePicker, Space } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import {
  getFormsCompletedDataById,
  updateFormWithData,
} from 'app/modules/apps/formsdata/core/_requests'
import { CountrySelect } from 'app/modules/utils/components/CountrySelect'
import moment from 'moment'
import Lifering from '../../icons/question-circle.svg'
import PostalCodeSearch from './postaCodeFilter'
import { CitySelect } from 'app/modules/utils/components/CanadaCitiesSelect/Index'

interface IProps {
  name: string
  formIndex: number
  openForm: boolean
  setOpenForm: Dispatch<SetStateAction<boolean>>
  setFormIndex: Dispatch<SetStateAction<string>>
  openFormCompleted: boolean
  setOpenFormCompleted: Dispatch<SetStateAction<boolean>>
  formCompletedId: string
  productId: string
  setMemberNameSelected: Dispatch<SetStateAction<string>>
  memberNameSelected: string
  setMemberTypeSelected: Dispatch<SetStateAction<string>>
  memberTypeSelected: string
  refetch: () => void
  refetchForms: () => void
  refetchFromDataBase: () => void
  setIndex: Dispatch<SetStateAction<number>>
}

const DisplayConsultantFormDarft: React.FC<IProps> = ({
  name,
  setOpenForm,
  formCompletedId,
  productId,
  setFormIndex,
  setMemberNameSelected,
  setMemberTypeSelected,
  memberNameSelected,
  memberTypeSelected,
  setIndex,
  refetch,
  refetchForms,
  refetchFromDataBase,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [shouldShowConditional, setShouldShowConditional] = useState(false)
  const [currentAnswers, setCurrentAnswers] = useState<any>(null)
  const [currentUser, setCurrentUser] = useState('')
  const [readpopup, setReadpopup] = useState(0)
  const [readValidator, setReadValidator] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [spawnQuestion, setSpawnQuestion] = useState(0)
  const [alert, setAlert] = useState(false)
  const [alertIndex, setAlertIndex] = useState(0)
  const {
    data: dataById,
    status,
    isLoading,
    isFetching,
  } = useQuery('listformsclientsbyid',
    async () => await getFormsCompletedDataById(
      formCompletedId),
    { enabled: (formCompletedId !== undefined), cacheTime: 0 })
  const [startDate, setStartDate] = useState<Record<number, string>>({})
  const [endDate, setEndDate] = useState<Record<number, string>>({})
  const [errors, setErrors] = useState<Record<number, string>>({})
  const [noErrors, setNoErrors] = useState(true)
  const [selectedLanguage, setSelectedLanguage] = React.useState<Record<number, string>>({})
  const [noErrors2, setNoErrors2] = useState(true)
  const [errors2, setErrors2] = useState<Record<number, string>>({})

  const handleTranslate = (index: number, languaje: string) => {
    setSelectedLanguage(prev => ({ ...prev, [index]: languaje }))
  }

  const handleStartDateChange = (index: number, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : ''
    setStartDate(prev => ({ ...prev, [index]: dateString }))

    if (dateString && endDate[index] && dateString > endDate[index]) {
      setErrors(prev => ({ ...prev, [index]: 'La fecha "Desde" no puede ser mayor que la fecha "Hasta".' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }

  const handleEndDateChange = (index: number, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : ''
    setEndDate(prev => ({ ...prev, [index]: dateString }))
    if (startDate[index] && dateString && startDate[index] > dateString) {
      setErrors(prev => ({ ...prev, [index]: 'La fecha "Hasta" no puede ser menor que la fecha "Desde".' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }

  const handleDateMustBeCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors(prev => {
          const newErrors = { ...prev, [index]: 'La fecha debe ser mayor a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  const handleDateMustBeNotCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha debe ser previa a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  const handleDateFromMustBeCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha desde debe ser mayor a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  useEffect(() => {
    setNoErrors2(Object.keys(errors2).length === 0)
  }, [errors2])

  useEffect(() => {
    if (noErrors2 !== null) {
      if (currentAnswers) {
        validateDataForSave(currentAnswers)
      }
    }
  }, [noErrors2]);

  useEffect(() => {
    setNoErrors(Object.keys(errors).length === 0)
  }, [errors])

  useEffect(() => {
    if (noErrors !== null) {
      if (currentAnswers) {
        validateDataForSave(currentAnswers)
      }
    }
  }, [noErrors]);

  useEffect(() => {
    user?.id !== undefined ? setCurrentUser(user?.id) : setCurrentUser('')
  }, [user])
  useEffect(() => {
    if (isValid === true) {
      const status = 1
      saveForms(currentAnswers, status)
      setReadValidator(readValidator + 1)
      setShowError(false)
    }
  }, [isValid])

  useEffect(() => {
    if (currentAnswers !== undefined) {
      setSpawnQuestion(1)
    }
  }, [currentAnswers])

  if (status === 'success' && isLoading === false && currentAnswers === null) {
    setCurrentAnswers(dataById)
  }

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const hideConditionalQuestions = (cond: any, index: number) => {
    cond.hide = 1
  }

  const conditionalQuestions = (cond: any, index: number) => {
    cond.hide = 0
    return (
      <>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    value={cond.answer}
                    className='option-styles '
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
                {cond.translate === true ? <div className='flex gap-1'>
                  <div
                    style={{
                      border: '2px solid #01A9DB',
                      borderRadius: '30px',
                      padding: '5px',
                      color: '#01A9DB'
                    }}
                    onClick={() => {
                      handleTranslate(index, 'original')
                    }}
                  >
                    Original
                  </div>
                  <div
                    style={{
                      border: '2px solid #01A9DB',
                      borderRadius: '30px',
                      padding: '5px',
                      color: '#01A9DB'
                    }}
                    onClick={() => {
                      handleTranslate(index, 'english')
                    }}
                  >
                    Inglés
                  </div>
                  <div
                    style={{
                      border: '2px solid #01A9DB',
                      borderRadius: '30px',
                      padding: '5px',
                      color: '#01A9DB'
                    }}
                    onClick={() => {
                      handleTranslate(index, 'french')
                    }}
                  >
                    Francés
                  </div>
                </div> : null}
              </div>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    maxLength={40}
                    defaultValue={cond.answer}
                    placeholder='Escriba aquí'
                    className='input-styles-quetion'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                      // Update the value of the input
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)

                      if (/[^A-Z0-9\s/]/.test(inputValue)) {
                        // Show the alert
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Hide the alert
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }}
                  />
                  {selectedLanguage && selectedLanguage[index] === 'english' &&
                    <input
                      type='text'
                      title='name'
                      maxLength={28}
                      defaultValue={cond.englishAnswer}
                      placeholder='Escriba aquí'
                      className='input-styles-quetion'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                        // Update the value of the input
                        e.target.value = cleanInput
                        cond.englishAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s/]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  {selectedLanguage && selectedLanguage[index] === 'french' &&
                    <input
                      type='text'
                      title='name'
                      maxLength={28}
                      defaultValue={cond.frenchAnswer}
                      placeholder='Escriba aquí'
                      className='input-styles-quetion'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                        // Update the value of the input
                        e.target.value = cleanInput
                        cond.frenchAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s/]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {readValidator > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Numeric Input */}
        {cond.type === 'numericInput' ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      *Solo se admiten caracteres numericos
                    </p>
                  )}
                  <input
                    type="text"
                    title="name"
                    defaultValue={cond.answer}
                    maxLength={28}
                    placeholder="Escriba aquí"
                    className="input-styles-quetion"
                    value={cond.answer} // Usar value en lugar de manipular directamente el valor
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue.replace(/[^0-9]/g, '') // Elimina cualquier carácter que no sea un número

                      // Actualiza cond.answer con el valor limpio
                      cond.answer = cleanInput

                      if (/[^0-9]/.test(inputValue)) {
                        // Mostrar la alerta
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Ocultar la alerta
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      setReadValidator(readValidator + 1)
                    }}
                  />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-2'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
                {cond.translate === true ? <div className='flex gap-1'>
                  <div
                    style={{
                      border: '2px solid #01A9DB',
                      borderRadius: '30px',
                      padding: '5px',
                      color: '#01A9DB'
                    }}
                    onClick={() => {
                      handleTranslate(index, 'original')
                    }}
                  >
                    Original
                  </div>
                  <div
                    style={{
                      border: '2px solid #01A9DB',
                      borderRadius: '30px',
                      padding: '5px',
                      color: '#01A9DB'
                    }}
                    onClick={() => {
                      handleTranslate(index, 'english')
                    }}
                  >
                    Inglés
                  </div>
                  <div
                    style={{
                      border: '2px solid #01A9DB',
                      borderRadius: '30px',
                      padding: '5px',
                      color: '#01A9DB'
                    }}
                    onClick={() => {
                      handleTranslate(index, 'french')
                    }}
                  >
                    Francés
                  </div>
                </div> : null}
              </div>
              <div className='text-format'>
                <div className='flex flex-col gap-1'>
                  <textarea
                    title='name'
                    maxLength={200}
                    defaultValue={cond.answer}
                    className='text-styles'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s]/g, '') // Remove any character that is not a letter, number, or space

                      // Update the value of the textarea
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)

                      if (/[^A-Z0-9\s]/.test(inputValue)) {
                        // Show the alert
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Hide the alert
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }}
                  />
                  {selectedLanguage && selectedLanguage[index] === 'english' &&
                    <textarea
                      title='name'
                      maxLength={200}
                      defaultValue={cond.englishAnswer}
                      className='text-styles'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s]/g, '') // Remove any character that is not a letter, number, or space

                        // Update the value of the textarea
                        e.target.value = cleanInput
                        cond.englishAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  {selectedLanguage && selectedLanguage[index] === 'french' &&
                    <textarea
                      title='name'
                      maxLength={200}
                      defaultValue={cond.frenchAnswer}
                      className='text-styles'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s]/g, '') // Remove any character that is not a letter, number, or space

                        // Update the value of the textarea
                        e.target.value = cleanInput
                        cond.frenchAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  <div className='extra-info'>
                    {cond.info !== '' ? (
                      <>
                        <button
                          type='button'
                          className='info-styles'
                          onClick={() => {
                            cond.activeInfo === true
                              ? (cond.activeInfo = false)
                              : (cond.activeInfo = true)
                            setReadpopup(readpopup + 1)
                          }}
                        >
                          ?
                        </button>
                        {readpopup > 0 && cond.activeInfo ? (
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: cond.info }}
                          />
                        ) : null}
                      </>
                    ) : (
                      <div className='info-styles' />
                    )}
                  </div>
                </div>
                <span>Max 100 carácteres</span>
                {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                  <span
                    style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginBottom: '3rem',
                      marginLeft: '3rem',
                    }}
                  >
                    {cond.validateAnswer}
                  </span>
                ) : null}
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex justify-between'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked === true) {
                          cond.multipleAnswer.push(e.target.value)
                          setReadValidator(readValidator + 1)
                        } else {
                          const indexToRemove = cond.multipleAnswer.indexOf(e.target.value)
                          if (indexToRemove !== -1) {
                            cond.multipleAnswer.splice(indexToRemove, 1)
                          }
                        }
                      }}
                    >
                      {choice}
                    </Checkbox>
                  ))}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      format='YYYY-MM-DD'
                      defaultValue={cond.answer ? moment(cond.answer, 'YYYY-MM-DD') : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.answer = date.format('YYYY-MM-DD').toString()
                          setReadValidator(readValidator + 1)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className="flex flex-col gap-12">
            <div className="flex justify-between">
              <p className="question-styles">{cond.question}</p>
              <div className="flex">
                <div className="flex flex-col gap-4 ml-16">
                  <Space direction="vertical">
                    <p className="m-0">Desde:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[0]
                        ? moment(cond.multipleAnswer[0], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                          handleStartDateChange(index, date)
                          handleDateFromMustBeCurrent(date, cond.fromMustBeCurrent, index)
                        } else {
                          handleStartDateChange(index, null)
                          handleDateFromMustBeCurrent(null, cond.fromMustBeCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <Space direction="vertical">
                    <p className="m-0">Hasta:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[1] ?
                        moment(cond.multipleAnswer[1], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          handleEndDateChange(index, date)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleEndDateChange(index, null)
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  {errors && errors[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors[index]}</p>}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className="extra-info">
                  {cond.info !== '' ? (
                    <>
                      <button
                        type="button"
                        className="info-styles"
                        onClick={() => {
                          cond.activeInfo = !cond.activeInfo
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className="popup-from-style"
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className="info-styles" />
                  )}
                  {cond.validate === true ? (
                    <p className="info-styles" style={{ color: '#bf3c3c', fontSize: '1.5rem', marginRight: '-5px', marginLeft: '3px' }}>
                      *
                    </p>
                  ) : (
                    <div className="info-styles" />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex flex-col justify-between'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex flex-col mt-8'>
            <p className='paragraph-styles' style={{ color: '#ababab' }}>
              {cond.question}
            </p>
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex flex-col mt-8'>
            <p className='description-styles' style={{ color: '#3a3a3a' }}>
              {cond.question}
            </p>
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <a className='paragra' href={cond.link}>
              {cond.question}
            </a>
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Selecione</option>
                    <CountrySelect />
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type city */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Selecione</option>
                    <CitySelect province={cond.conditional} />
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(cond, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                    initialPostalCode={cond.answer}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {readValidator > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}
      </>
    )
  }

  const conditionalQuestions2 = (cond: any, index: number): React.JSX.Element => {
    cond.hide = 0
    return (
      <>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
                {cond.translate === true ?
                  <div className='flex gap-1'>
                    <div
                      style={{
                        border: '2px solid #01A9DB',
                        borderRadius: '30px',
                        padding: '5px',
                        color: '#01A9DB'
                      }}
                      onClick={() => {
                        handleTranslate(index, 'original')
                      }}
                    >
                      Original
                    </div>
                    <div
                      style={{
                        border: '2px solid #01A9DB',
                        borderRadius: '30px',
                        padding: '5px',
                        color: '#01A9DB'
                      }}
                      onClick={() => {
                        handleTranslate(index, 'english')
                      }}
                    >
                      Inglés
                    </div>
                    <div
                      style={{
                        border: '2px solid #01A9DB',
                        borderRadius: '30px',
                        padding: '5px',
                        color: '#01A9DB'
                      }}
                      onClick={() => {
                        handleTranslate(index, 'french')
                      }}
                    >
                      Francés
                    </div>
                  </div> : null}
              </div>
              <div className='flex'>
                <div>
                  <input
                    type='text'
                    title='name'
                    maxLength={40}
                    defaultValue={cond.answer}
                    placeholder='Escriba aquí'
                    className='input-styles-quetion'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                      // Update the value of the input
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)

                      if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                        // Show the alert
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Hide the alert
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }}
                  />
                  {selectedLanguage && selectedLanguage[index] === 'english' &&
                    <input
                      type='text'
                      title='name'
                      maxLength={28}
                      defaultValue={cond.englishAnswer}
                      placeholder='Escriba aquí'
                      className='input-styles-quetion'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                        // Update the value of the input
                        e.target.value = cleanInput
                        cond.englishAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  {selectedLanguage && selectedLanguage[index] === 'french' &&
                    <input
                      type='text'
                      title='name'
                      maxLength={28}
                      defaultValue={cond.frenchAnswer}
                      placeholder='Escriba aquí'
                      className='input-styles-quetion'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                        // Update the value of the input
                        e.target.value = cleanInput
                        cond.frenchAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s/]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {readValidator > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Numeric Input */}
        {cond.type === 'numericInput' ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      *Solo se admiten caracteres numericos
                    </p>
                  )}
                  <input
                    type="text"
                    title="name"
                    defaultValue={cond.answer}
                    maxLength={28}
                    placeholder="Escriba aquí"
                    className="input-styles-quetion"
                    value={cond.answer} // Usar value en lugar de manipular directamente el valor
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue.replace(/[^0-9]/g, '') // Elimina cualquier carácter que no sea un número

                      // Actualiza cond.answer con el valor limpio
                      cond.answer = cleanInput

                      if (/[^0-9]/.test(inputValue)) {
                        // Mostrar la alerta
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Ocultar la alerta
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      setReadValidator(readValidator + 1)
                    }}
                  />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-2'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
                {cond.translate === true ?
                  <div className='flex gap-1'>
                    <div
                      style={{
                        border: '2px solid #01A9DB',
                        borderRadius: '30px',
                        padding: '5px',
                        color: '#01A9DB'
                      }}
                      onClick={() => {
                        handleTranslate(index, 'original')
                      }}
                    >
                      Original
                    </div>
                    <div
                      style={{
                        border: '2px solid #01A9DB',
                        borderRadius: '30px',
                        padding: '5px',
                        color: '#01A9DB'
                      }}
                      onClick={() => {
                        handleTranslate(index, 'english')
                      }}
                    >
                      Inglés
                    </div>
                    <div
                      style={{
                        border: '2px solid #01A9DB',
                        borderRadius: '30px',
                        padding: '5px',
                        color: '#01A9DB'
                      }}
                      onClick={() => {
                        handleTranslate(index, 'french')
                      }}
                    >
                      Francés
                    </div>
                  </div> : null}
              </div>
              <div className='text-format'>
                <div className='flex flex-col gap-1'>
                  <textarea
                    title='name'
                    maxLength={200}
                    defaultValue={cond.answer}
                    className='text-styles'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s]/g, '') // Remove any character that is not a letter, number, or space

                      // Update the value of the textarea
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)

                      if (/[^A-Z0-9\s]/.test(inputValue)) {
                        // Show the alert
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        // Hide the alert
                        setAlert(false)
                        setAlertIndex(0)
                      }
                    }}
                  />
                  {selectedLanguage && selectedLanguage[index] === 'english' &&
                    <textarea
                      title='name'
                      maxLength={200}
                      defaultValue={cond.englishAnswer}
                      className='text-styles'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s]/g, '') // Remove any character that is not a letter, number, or space

                        // Update the value of the textarea
                        e.target.value = cleanInput
                        cond.englishAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  {selectedLanguage && selectedLanguage[index] === 'french' &&
                    <textarea
                      title='name'
                      maxLength={200}
                      defaultValue={cond.frenchAnswer}
                      className='text-styles'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s]/g, '') // Remove any character that is not a letter, number, or space

                        // Update the value of the textarea
                        e.target.value = cleanInput
                        cond.frenchAnswer = cleanInput
                        setReadValidator(readValidator + 1)

                        if (/[^A-Z0-9\s]/.test(inputValue)) {
                          // Show the alert
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          // Hide the alert
                          setAlert(false)
                          setAlertIndex(0)
                        }
                      }}
                    />}
                  <div className='extra-info'>
                    {cond.info !== '' ? (
                      <>
                        <button
                          type='button'
                          className='info-styles'
                          onClick={() => {
                            cond.activeInfo === true
                              ? (cond.activeInfo = false)
                              : (cond.activeInfo = true)
                            setReadpopup(readpopup + 1)
                          }}
                        >
                          ?
                        </button>
                        {readpopup > 0 && cond.activeInfo ? (
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: cond.info }}
                          />
                        ) : null}
                      </>
                    ) : (
                      <div className='info-styles' />
                    )}
                  </div>
                </div>
                <span>Max 100 carácteres</span>
                {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                  <span
                    style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginBottom: '3rem',
                      marginLeft: '3rem',
                    }}
                  >
                    {cond.validateAnswer}
                  </span>
                ) : null}
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex justify-between'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked === true) {
                          cond.multipleAnswer.push(e.target.value)
                          setReadValidator(readValidator + 1)
                        } else {
                          const indexToRemove = cond.multipleAnswer.indexOf(e.target.value)
                          if (indexToRemove !== -1) {
                            cond.multipleAnswer.splice(indexToRemove, 1)
                          }
                        }
                      }}
                    >
                      {choice}
                    </Checkbox>
                  ))}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      format='YYYY-MM-DD'
                      defaultValue={cond.answer ? moment(cond.answer, 'YYYY-MM-DD') : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.answer = date.format('YYYY-MM-DD').toString()
                          setReadValidator(readValidator + 1)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className="flex flex-col gap-12">
            <div className="flex justify-between">
              <p className="question-styles">{cond.question}</p>
              <div className="flex">
                <div className="flex flex-col gap-4 ml-16">
                  <Space direction="vertical">
                    <p className="m-0">Desde:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[0]
                        ? moment(cond.multipleAnswer[0], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                          handleStartDateChange(index, date)
                          handleDateFromMustBeCurrent(date, cond.fromMustBeCurrent, index)
                        } else {
                          handleStartDateChange(index, null)
                          handleDateFromMustBeCurrent(null, cond.fromMustBeCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  <Space direction="vertical">
                    <p className="m-0">Hasta:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[1]
                        ? moment(cond.multipleAnswer[1], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          handleEndDateChange(index, date)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleEndDateChange(index, null)
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                      }}
                    />
                  </Space>
                  {errors && errors[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors[index]}</p>}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className="extra-info">
                  {cond.info !== '' ? (
                    <>
                      <button
                        type="button"
                        className="info-styles"
                        onClick={() => {
                          cond.activeInfo = !cond.activeInfo
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className="popup-from-style"
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className="info-styles" />
                  )}
                  {cond.validate === true ? (
                    <p className="info-styles" style={{ color: '#bf3c3c', fontSize: '1.5rem', marginRight: '-5px', marginLeft: '3px' }}>
                      *
                    </p>
                  ) : (
                    <div className="info-styles" />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              {readpopup > 0 && cond.activeInfo ? (
                <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
              ) : null}
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex'>
            <p className='paragraph-styles' style={{ color: '#ababab' }}>
              {cond.question}
            </p>
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex'>
            <p className='description-styles' style={{ color: '#3a3a3a' }}>
              {cond.question}
            </p>
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <a className='paragra' href={cond.link}>
              {cond.question}
            </a>
            {readpopup > 0 && cond.activeInfo ? (
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            ) : null}
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Selecione</option>
                    <CountrySelect />
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type city */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Selecione</option>
                    <CitySelect province={cond.conditional} />
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(cond, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                    initialPostalCode={cond.answer}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div className='extra-info'>
                  {cond.info !== '' ? (
                    <>
                      <button
                        type='button'
                        className='info-styles'
                        onClick={() => {
                          cond.activeInfo === true
                            ? (cond.activeInfo = false)
                            : (cond.activeInfo = true)
                          setReadpopup(readpopup + 1)
                        }}
                      >
                        ?
                      </button>
                      {readpopup > 0 && cond.activeInfo ? (
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: cond.info }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className='info-styles' />
                  )}
                </div>
              </div>
            </div>
            {readValidator > 0 &&
              cond.answer !== '' &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}
      </>
    )
  }

  const saveForms = async (answers: any, status: number) => {
    let dataFormData: object
    if (answers.id != null) {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.datafill,
        status: status,
        product: productId,
        memberType: memberTypeSelected,
        memberName: memberNameSelected,
      }
    } else {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.datafill,
        status: status,
        product: productId,
        memberType: memberTypeSelected,
        memberName: memberNameSelected,
      }
    }
    await updateFormWithData(currentAnswers.id, dataFormData)
    console.log('saved')
    setOpenForm(false)
  }

  const validateDataForSave = (answers: any) => {
    let allQuestionsValid = true
    answers.datafill.questions.forEach((answer: any) => {
      if (noErrors === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (noErrors2 === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'input' ||
          answer.type === 'date' ||
          answer.type === 'text' ||
          answer.type === 'option' ||
          answer.type === 'country') &&
        answer.answer === ''
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'choice' || answer.type === 'interval') &&
        answer.multipleAnswer.length === 0
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
    })
    if (allQuestionsValid) {
      setIsValid(true)
    }
  }

  return (
    <>
      {isFetching === false && currentAnswers && currentAnswers !== undefined ? (
        <form className='form-style' style={{ marginTop: '4rem', backgroundColor: 'white', borderRadius: '10px', padding: '1rem 2rem' }}>
          {currentAnswers.datafill &&
            currentAnswers.datafill.questions.map((question: any, index: number) => (
              <div style={{ position: 'absolute', top: '0px', right: '24px' }} className='w-full flex justify-end help-button'>
                {question.type === 'help' ? (
                  <a
                    className='product-name btn-section mt-4'
                    style={{ width: 'max-content' }}
                    href={question.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={Lifering} alt="Lifering" />
                    <p style={{ margin: '0', fontSize: '14px', textDecoration: 'none', color: 'black' }}>{question.question}</p>
                  </a>
                ) : null}
              </div>
            ))}
          <h1 className='form-title'>{currentAnswers.datafill?.formName}</h1>
          {currentAnswers.datafill.questions.map((question: any, index: number) => (
            <div className='flex flex-col gap-6'>
              {/* Type Input */}
              {question.type === 'input' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                      {question.translate === true ? <div className='flex gap-1'>
                        <div
                          style={{
                            border: '2px solid #01A9DB',
                            borderRadius: '30px',
                            padding: '5px',
                            color: '#01A9DB'
                          }}
                          onClick={() => {
                            handleTranslate(index, 'original')
                          }}
                        >
                          Original
                        </div>
                        <div
                          style={{
                            border: '2px solid #01A9DB',
                            borderRadius: '30px',
                            padding: '5px',
                            color: '#01A9DB'
                          }}
                          onClick={() => {
                            handleTranslate(index, 'english')
                          }}
                        >
                          Inglés
                        </div>
                        <div
                          style={{
                            border: '2px solid #01A9DB',
                            borderRadius: '30px',
                            padding: '5px',
                            color: '#01A9DB'
                          }}
                          onClick={() => {
                            handleTranslate(index, 'french')
                          }}
                        >
                          Francés
                        </div>
                      </div> : null}
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <input
                          type='text'
                          title='name'
                          maxLength={28}
                          defaultValue={question.answer}
                          placeholder='Escriba aquí'
                          className='input-styles-quetion'
                          value={question.answer} // Usar value en lugar de manipular directamente el valor
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value
                            const cleanInput = inputValue
                              .normalize('NFD') // Normalize the string
                              .replace(/[\u0300-\u036f]/g, '') // Remove accents
                              .toUpperCase() // Convert to uppercase
                              .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                            // Actualiza question.answer con el valor limpio
                            question.answer = cleanInput

                            if (/[^A-Z0-9\s/]/.test(inputValue)) {
                              // Mostrar la alerta
                              setAlert(true)
                              setAlertIndex(index)
                            } else {
                              // Ocultar la alerta
                              setAlert(false)
                              setAlertIndex(0)
                            }
                            setReadValidator(readValidator + 1)
                          }}
                        />
                        {selectedLanguage && selectedLanguage[index] === 'english' &&
                          <input
                            type='text'
                            title='name'
                            maxLength={28}
                            defaultValue={question.englishAnswer}
                            placeholder='Write here'
                            className='input-styles-quetion'
                            value={question.englishAnswer} // Usar value en lugar de manipular directamente el valor
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const inputValue = e.target.value
                              const cleanInput = inputValue
                                .normalize('NFD') // Normalize the string
                                .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .toUpperCase() // Convert to uppercase
                                .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                              // Actualiza question.answer con el valor limpio
                              question.englishAnswer = cleanInput

                              if (/[^A-Z0-9\s/]/.test(inputValue)) {
                                // Mostrar la alerta
                                setAlert(true)
                                setAlertIndex(index)
                              } else {
                                // Ocultar la alerta
                                setAlert(false)
                                setAlertIndex(0)
                              }
                              setReadValidator(readValidator + 1)
                            }}
                          />}
                        {selectedLanguage && selectedLanguage[index] === 'french' &&
                          <input
                            type='text'
                            title='name'
                            maxLength={40}
                            defaultValue={question.frenchAnswer}
                            placeholder='écrivez ici'
                            className='input-styles-quetion'
                            value={question.frenchAnswer} // Usar value en lugar de manipular directamente el valor
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const inputValue = e.target.value
                              const cleanInput = inputValue
                                .normalize('NFD') // Normalize the string
                                .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .toUpperCase() // Convert to uppercase
                                .replace(/[^A-Z0-9\s/]/g, '') // Remove any character that is not a letter, number, space, or /

                              // Actualiza question.answer con el valor limpio
                              question.frenchAnswer = cleanInput

                              if (/[^A-Z0-9\s/]/.test(inputValue)) {
                                // Mostrar la alerta
                                setAlert(true)
                                setAlertIndex(index)
                              } else {
                                // Ocultar la alerta
                                setAlert(false)
                                setAlertIndex(0)
                              }
                              setReadValidator(readValidator + 1)
                            }}
                          />}
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, i)
                          : null
                      )}
                  </div>
                </div>
              ) : null}

              {/* Type Numeric Input */}
              {question.type === 'numericInput' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        {alert === true && alertIndex === index && (
                          <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                            *Solo se admiten caracteres numericos
                          </p>
                        )}
                        <input
                          type="text"
                          title="name"
                          defaultValue={question.answer}
                          maxLength={28}
                          placeholder="Escriba aquí"
                          className="input-styles-quetion"
                          value={question.answer} // Usar value en lugar de manipular directamente el valor
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value
                            const cleanInput = inputValue.replace(/[^0-9]/g, '') // Elimina cualquier carácter que no sea un número

                            // Actualiza question.answer con el valor limpio
                            question.answer = cleanInput

                            if (/[^0-9]/.test(inputValue)) {
                              // Mostrar la alerta
                              setAlert(true)
                              setAlertIndex(index)
                            } else {
                              // Ocultar la alerta
                              setAlert(false)
                              setAlertIndex(0)
                            }
                            setReadValidator(readValidator + 1)
                          }}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                              fontSize: '10px'
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Type Text */}
              {question.type === 'text' && question.dependent === false ? (
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col'>
                    <div className='flex gap-2'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                      {question.translate === true ? <div className='flex gap-1'>
                        <div
                          style={{
                            border: '2px solid #01A9DB',
                            borderRadius: '30px',
                            padding: '5px',
                            color: '#01A9DB'
                          }}
                          onClick={() => {
                            handleTranslate(index, 'original')
                          }}
                        >
                          Original
                        </div>
                        <div
                          style={{
                            border: '2px solid #01A9DB',
                            borderRadius: '30px',
                            padding: '5px',
                            color: '#01A9DB'
                          }}
                          onClick={() => {
                            handleTranslate(index, 'english')
                          }}
                        >
                          Inglés
                        </div>
                        <div
                          style={{
                            border: '2px solid #01A9DB',
                            borderRadius: '30px',
                            padding: '5px',
                            color: '#01A9DB'
                          }}
                          onClick={() => {
                            handleTranslate(index, 'french')
                          }}
                        >
                          Francés
                        </div>
                      </div> : null}
                    </div>
                    <div className='flex'>
                      <div className='text-format'>
                        <div className='flex flex-col gap-1'>
                          <textarea
                            title='name'
                            maxLength={200}
                            defaultValue={question.answer}
                            className='text-styles'
                            onChange={(e) => {
                              const inputValue = e.target.value
                              const cleanInput = inputValue
                                .normalize('NFD') // Normalize the string
                                .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .toUpperCase() // Convert to uppercase
                                .replace(/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/g, '') // Remove any character that is not a letter, number, space, or /

                              // Update the value of the textarea
                              e.target.value = cleanInput
                              question.answer = cleanInput
                              setReadValidator(readValidator + 1)

                              if (/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/.test(inputValue)) {
                                // Show the alert
                                setAlert(true)
                                setAlertIndex(index)
                              } else {
                                // Hide the alert
                                setAlert(false)
                                setAlertIndex(0)
                              }
                            }}
                          />
                          {selectedLanguage && selectedLanguage[index] === 'english' &&
                            <textarea
                              title='name'
                              maxLength={200}
                              defaultValue={question.englishAnswer}
                              className='text-styles'
                              onChange={(e) => {
                                const inputValue = e.target.value
                                const cleanInput = inputValue
                                  .normalize('NFD') // Normalize the string
                                  .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                  .toUpperCase() // Convert to uppercase
                                  .replace(/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/g, '') // Remove any character that is not a letter, number, space, or /

                                // Update the value of the textarea
                                e.target.value = cleanInput
                                question.englishAnswer = cleanInput
                                setReadValidator(readValidator + 1)

                                if (/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/.test(inputValue)) {
                                  // Show the alert
                                  setAlert(true)
                                  setAlertIndex(index)
                                } else {
                                  // Hide the alert
                                  setAlert(false)
                                  setAlertIndex(0)
                                }
                              }}
                            />
                          }
                          {selectedLanguage && selectedLanguage[index] === 'french' &&
                            <textarea
                              title='name'
                              maxLength={200}
                              defaultValue={question.frenchAnswer}
                              className='text-styles'
                              onChange={(e) => {
                                const inputValue = e.target.value
                                const cleanInput = inputValue
                                  .normalize('NFD') // Normalize the string
                                  .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                  .toUpperCase() // Convert to uppercase
                                  .replace(/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/g, '') // Remove any character that is not a letter, number, space, or /

                                // Update the value of the textarea
                                e.target.value = cleanInput
                                question.frenchAnswer = cleanInput
                                setReadValidator(readValidator + 1)

                                if (/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/.test(inputValue)) {
                                  // Show the alert
                                  setAlert(true)
                                  setAlertIndex(index)
                                } else {
                                  // Hide the alert
                                  setAlert(false)
                                  setAlertIndex(0)
                                }
                              }}
                            />
                          }
                        </div>
                        <span>Max 100 carácteres</span>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, i)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type Option */}
              {question.type === 'option' && question.dependent === false ? (
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex '>
                      <div className='flex flex-col'>
                        <select
                          title='option'
                          className='option-styles'
                          value={question.answer}
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                          }}
                        >
                          <option value=''>Seleccione</option>
                          {question.option.map((option: string) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, i)
                        : null
                    )}
                  {
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer !== cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? hideConditionalQuestions(cond, i)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type choice */}
              {question.type === 'choice' && question.dependent === false ? (
                <div>
                  <div className='flex justify-between'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p style={{ fontSize: '1rem' }}>*</p>
                      ) : (
                        <div />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='choice-option'>
                        {question.choices.map((choice: string) => (
                          <Checkbox
                            value={choice}
                            onChange={(e: CheckboxChangeEvent) => {
                              if (e.target.checked === true) {
                                currentAnswers.datafill.questions[index].multipleAnswer.push(
                                  e.target.value
                                )
                              } else {
                                const indexToRemove = currentAnswers.datafill.questions[
                                  index
                                ].multipleAnswer.indexOf(e.target.value)
                                if (indexToRemove !== -1) {
                                  currentAnswers.datafill.questions[index].multipleAnswer.splice(
                                    indexToRemove,
                                    1
                                  )
                                }
                              }
                            }}
                          >
                            {choice}
                          </Checkbox>
                        ))}
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, i)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type Date */}
              {question.type === 'date' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <Space direction='vertical'>
                          <DatePicker
                            format='YYYY-MM-DD'
                            defaultValue={
                              question.answer ? moment(question.answer, 'YYYY-MM-DD') : undefined
                            }
                            onChange={(date) => {
                              if (date && date !== null) {
                                question.answer = date.format('YYYY-MM-DD').toString()
                                handleDateMustBeCurrent(date, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                              } else {
                                handleDateMustBeCurrent(null, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                              }
                            }}
                          />
                        </Space>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, i)
                          : null
                      )}
                  </div>
                </div>
              ) : null}

              {/* Type Interval */}
              {question.type === 'interval' && !question.dependent ? (
                <div>
                  <div className="flex flex-col">
                    <div className="flex gap-1">
                      {question.validate ? (
                        <p className="info-styles" style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                          *
                        </p>
                      ) : (
                        <div className="info-styles" />
                      )}
                      <p className="question-styles">{question.question}</p>
                    </div>
                    <div className="flex gap-2">
                      <div className="flex flex-col gap-2">
                        <Space direction="vertical">
                          <p className="m-0">Desde:</p>
                          <DatePicker
                            format="YYYY-MM-DD"
                            defaultValue={question.multipleAnswer[0]
                              ? moment(question.multipleAnswer[0], 'YYYY-MM-DD')
                              : undefined}
                            onChange={(date) => {
                              if (date && date !== null) {
                                question.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                                handleStartDateChange(index, date)
                                handleDateFromMustBeCurrent(date, question.fromMustBeCurrent, index)
                              } else {
                                handleStartDateChange(index, null)
                                handleDateFromMustBeCurrent(null, question.fromMustBeCurrent, index)
                              }
                            }}
                          />
                        </Space>
                        <Space direction="vertical">
                          <p className="m-0">Hasta:</p>
                          <DatePicker
                            format="YYYY-MM-DD"
                            defaultValue={question.multipleAnswer[1]
                              ? moment(question.multipleAnswer[1], 'YYYY-MM-DD')
                              : undefined}
                            onChange={(date) => {
                              if (date && date !== null) {
                                question.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                                handleEndDateChange(index, date)
                                handleDateMustBeCurrent(date, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                              } else {
                                handleEndDateChange(index, null)
                                handleDateMustBeCurrent(null, question.mustBeCurrent, index)
                                handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                              }
                            }}
                          />
                        </Space>
                        {errors && errors[index] &&
                          <p style={{
                            color: '#bf3c3c',
                            fontWeight: 'bolder',
                            marginLeft: '0.3rem',
                            marginBottom: '0'
                          }}>{errors[index]}</p>}
                        {question.validate && !question.answer && readValidator > 0 && (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                              fontSize: '10px'
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        )}
                      </div>
                      <div className="extra-info" style={{ marginTop: '2rem' }}>
                        {question.info && (
                          <>
                            <button
                              type="button"
                              className="info-styles"
                              onClick={() => {
                                question.activeInfo = !question.activeInfo
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo && (
                              <div
                                className="popup-form-style"
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Type Video */}
              {question.type === 'video' && question.dependent === false ? (
                <div className='flex flex-col'>
                  <p className='question-styles'>{question.question}</p>
                  <iframe className='iframe-style' src={question.video} title={question.question} />

                  {readpopup > 0 && question.activeInfo ? (
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  ) : null}
                </div>
              ) : null}

              {/* Type Image */}
              {question.type === 'image' && question.dependent === false ? (
                <div className='flex flex-col'>
                  <p className='question-styles'>{question.question}</p>
                  <img className='iframe-style' src={question.image} alt={question.question} />

                  {readpopup > 0 && question.activeInfo ? (
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  ) : null}
                </div>
              ) : null}

              {/* Type description */}
              {question.type === 'description' && question.dependent === false ? (
                <div className='flex justify-between'>
                  <p className='description-styles' style={{ color: '#3a3a3a' }}>
                    {question.question}
                  </p>
                  {readpopup > 0 && question.activeInfo ? (
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  ) : null}
                </div>
              ) : null}

              {/* Type Paragraph */}
              {question.type === 'paragraph' && question.dependent === false ? (
                <div className='flex justify-between'>
                  <p className='paragraph-styles' style={{ color: '#ababab' }}>
                    {question.question}
                  </p>
                  {readpopup > 0 && question.activeInfo ? (
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  ) : null}
                </div>
              ) : null}

              {/* Type Link */}
              {question.type === 'link' && question.dependent === false ? (
                <div className='flex justify-between'>
                  <a
                    className='paragraph-styles'
                    style={{ fontSize: '14px' }}
                    href={question.link}>
                    {question.question}
                  </a>
                  {readpopup > 0 && question.activeInfo ? (
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  ) : null}
                </div>
              ) : null}

              {/* Type country */}
              {question.type === 'country' && question.dependent === false ? (
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex '>
                      <div className='flex flex-col'>
                        <select
                          title='option'
                          className='option-styles '
                          value={question.answer}
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}
                        >
                          <option value=''>Selecione</option>
                          <CountrySelect />
                        </select>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, i)
                        : null
                    )}
                  {
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer !== cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? hideConditionalQuestions(cond, i)
                        : null
                    )}
                </div>
              ) : null}

              {/*Type province */}
              {question.type === 'province' && question.dependent === false ? (
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <select
                          title='option'
                          className='option-styles '
                          value={question.answer}
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}                        >
                          <option value=''>Seleccione</option>
                          <option value='AB'>Alberta</option>
                          <option value='BC'>British Columbia</option>
                          <option value='MB'>Manitoba</option>
                          <option value='NB'>New Brunswick</option>
                          <option value='NL'>Newfoundland and Labrador</option>
                          <option value='NS'>Nova Scotia</option>
                          <option value='NU'>Nunavut</option>
                          <option value='ON'>Ontario</option>
                          <option value='QC'>Quebec</option>
                          <option value='SK'>Saskatchewan</option>
                          <option value='YT'>Yukon</option>
                          <option value='PE'>Prince Edward Island</option>
                          <option value='NT'>Northwest Territories</option>
                        </select>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, i)
                        : null
                    )}
                  {
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer !== cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? hideConditionalQuestions(cond, i)
                        : null
                    )}
                </div>
              ) : null}

              {/* Type city */}
              {question.type === 'city' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex '>
                      <div className='flex flex-col'>
                        <select
                          title='option'
                          className='option-styles '
                          value={question.answer}
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}
                        >
                          <CitySelect province={question.conditional} />
                        </select>
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                  </div>
                  {question.answer !== '' &&
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer === cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? conditionalQuestions(cond, i)
                        : null
                    )}
                  {
                    currentAnswers.datafill.questions.map((cond: any, i: number) =>
                      question.answer !== cond.conditional &&
                        question.question === cond.conditionalQuestion &&
                        question.index === cond.conditionId
                        ? hideConditionalQuestions(cond, i)
                        : null
                    )}
                </div>
              ) : null}

              {/*Type language */}
              {question.type === 'language' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>                    <div className='flex'>
                      <div className='flex flex-col'>
                        <select
                          title='option'
                          className='option-styles '
                          value={question.answer}
                          onChange={(e: any) => {
                            question.answer = e.target.value
                            shouldShowConditional === true
                              ? setShouldShowConditional(false)
                              : setShouldShowConditional(true)
                            setSpawnQuestion(spawnQuestion + 1)
                          }}
                        >
                          <option value=''>Seleccione</option>
                          <option value='English'>English</option>
                          <option value='Mandarin'>Mandarin Chinese</option>
                          <option value='Hindi'>Hindi</option>
                          <option value='Spanish'>Spanish</option>
                          <option value='French'>French</option>
                          <option value='Arabic'>Standard Arabic</option>
                          <option value='Bengali'>Bengali</option>
                          <option value='Russian'>Russian</option>
                          <option value='Portuguese'>Portuguese</option>
                          <option value='Urdu'>Urdu</option>
                          <option value='Indonesian'>Indonesian</option>
                          <option value='German'>German</option>
                          <option value='Japanese'>Japanese</option>
                          <option value='Nigerian'>Nigerian Pidgin</option>
                          <option value='Marathi'>Marathi</option>
                          <option value='Telugu'>Telugu</option>
                          <option value='Turkish'>Turkish</option>
                          <option value='Tamil'>Tamil</option>
                          <option value='Yue'>Yue Chinese</option>
                          <option value='Vietnamese'>Vietnamese</option>
                          <option value='Tagalog'>Tagalog</option>
                          <option value='Wu'>Wu Chinese</option>
                          <option value='Korean'>Korean</option>
                          <option value='Iranian'>Iranian Persian (Farsi)</option>
                          <option value='Hausa'>Hausa</option>
                          <option value='Egyptian'>Egyptian Spoken Arabic</option>
                          <option value='Swahili'>Swahili</option>
                          <option value='Javanese'>Javanese</option>
                          <option value='Italian'>Italian</option>
                          <option value='Punjabi'>Western Punjabi</option>
                          <option value='Kannada'>Kannada</option>
                          <option value='Gujarati'>Gujarati</option>
                          <option value='Thai'>Thai</option>
                        </select>
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.datafill.quesquestions &&
                      currentAnswers.datafill.quesquestions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, i)
                          : null
                      )}
                  </div>
                </div>
              ) : null}

              {/* Type postalcode */}
              {question.type === 'postalcode' && question.dependent === false ? (
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-1'>
                      {question.validate === true ? (
                        <p className='info-styles' style={{ fontSize: '1rem' }}>
                          *
                        </p>
                      ) : (
                        <div className='info-styles' />
                      )}
                      <p className='question-styles'>{question.question}</p>
                    </div>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <PostalCodeSearch
                          onPostalCodeSelect={(selectedPostalCode: string) => {
                            question.answer = selectedPostalCode;
                          }}
                          initialPostalCode={question.answer}
                        />
                        {question.validate === true &&
                          question.answer === '' &&
                          readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '3rem',
                            }}
                          >
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                      <div className='extra-info'>
                        {question.info !== '' ? (
                          <>
                            <button
                              type='button'
                              className='info-styles'
                              onClick={() => {
                                question.activeInfo === true
                                  ? (question.activeInfo = false)
                                  : (question.activeInfo = true)
                                setReadpopup(readpopup + 1)
                              }}
                            >
                              ?
                            </button>
                            {readpopup > 0 && question.activeInfo ? (
                              <div
                                className='popup-form-style'
                                dangerouslySetInnerHTML={{ __html: question.info }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className='info-styles' />
                        )}
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, i)
                          : null
                      )}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
          <div className='flex gap-2 justify-between'>
            <button
              style={{ marginTop: '4rem', width: '8rem' }}
              type='button'
              className='powerup-card-btn'
              onClick={() => {
                setFormIndex('')
                setIndex(0)
                setOpenForm(false)
                setOpenForm(false)

              }}
            >
              <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>Volver</p>
            </button>
            <button
              style={{ marginTop: '4rem', width: '8rem' }}
              type='button'
              className='powerup-card-btn'
              onClick={() => {
                validateDataForSave(currentAnswers)
                refetch()
                refetchForms()
                refetchFromDataBase()
                setFormIndex('')
                setIndex(0)
              }}
            >
              <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>Guardar</p>
            </button>
          </div>
          {readValidator > 0 && showError === true ? <p>No data</p> : null}
        </form>
      ) : (
        <p>no data</p>
      )}
    </>
  )
}
export { DisplayConsultantFormDarft }
