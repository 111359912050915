/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Button, Link, Loader} from '../../../../components'
import {
  type IApointments,
  type IComposeData,
} from '../../../../app/modules/apps/appointments/core/_models'
import React, {useContext, useEffect, useState} from 'react'
import {AppointmentDetails} from './AppointmentDetails'
import {RootStoreContext} from '../../../../stores/rootStore'
import {getAdminAppointmentsNew} from '../../../../app/modules/apps/appointments/core/_requests'
import {useQuery} from 'react-query'

// import {data as initialContent} from 'app/pages/messages/InternalNotes/data'

interface Props {
  query: IApointments
  setQuery: (query: IApointments) => void
}
const AppointmentByConsultor: React.FC<Props> = ({query, setQuery}) => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {staff} = rootStore.authStore

  const [zone, setZone] = useState<string>('Canada/Saskatchewan')

  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    } else {
      setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }, [user])

  /* useEffect(() => {
    if(staff){
    const params: any = {
      ...query,
      consultantId: staff.id,
    }
    setQuery(params)
  }
  }, [staff]) */

  const {data, status, refetch} = useQuery(
    'adminappointmentsbyconsultant',
    async () => await getAdminAppointmentsNew(query)
  )

  useEffect(() => {
    refetch()
  }, [query])

  if (status === 'loading') return <Loader />

  return (
    <>
      <div className='card card-custom gutter-b'>
        <Link to='/appointmentsbyconsultant'>
          <Button
            className='btn  btn-primary'
            style={{
              width: '8rem',
              alignSelf: 'end',
              marginRight: '2rem',
              border: 'none',
              marginBottom: '1rem',
              gap: '0.5rem',
            }}
          >
            Regresar
          </Button>
        </Link>
        {data != null && (
          <AppointmentDetails
            appointments={data}
            setComposePost={(b: boolean) => {
              // comentario para desactivar el linter
            }}
            setComposePostData={(icomposeData: IComposeData) => {
              // comentario para desactivar el linter
            }}
            setComposeNote={(b: boolean) => {
              // comentario para desactivar el linter
            }}
            zone={zone}
            setQuery={setQuery}
            query={query}
          />
        )}
        <div className='card-footer d-flex justify-content-end'></div>
      </div>
    </>
  )
}

export default AppointmentByConsultor
