 
 
/* eslint-disable  */

import {Container, H2, P} from '../../../../components'
import {PageHeader, Tabs} from 'antd'
import React, {type Dispatch, type SetStateAction, useContext, useEffect, useState} from 'react'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import ListStaff from '../../../../app/pages/staffs/ListStaff'
import {RootStoreContext} from '../../../../stores/rootStore'
import {type Staff} from '../../../../app/modules/apps/staff/core/_models'
import {type Translations} from '../../../../app/modules/apps/translations/core/_models'
import parse from 'html-react-parser'
import {useIntl} from 'react-intl'

const {TabPane} = Tabs

interface IProps {
  setAssign: Dispatch<SetStateAction<boolean>>
  setTranslation: Dispatch<SetStateAction<Translations>>
  translation: Translations
}

const AssignTranslation: React.FC<IProps> = ({setAssign, setTranslation, translation}) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {setStaffToTranslation, isLoadingTranslation} = rootStore.translationStore
  const {staffsTo} = rootStore.staffStore
  const [staff, setStaff] = useState<Staff | null>(null)

  if (isLoadingTranslation) {
    return <div>Loading...</div>
  }

  useEffect(() => {
    try {
      if (staff != null && staff.id && translation.id) {
        setStaffToTranslation(staff.id, translation.id).then(() => {
          setAssign(false)
        })
      }
    } catch (e) {}
  }, [staff])

  return (
    <Container title={intl.formatMessage({id: 'TRANSLATION.ASSIGN'})}>
      <PageHeader
        onBack={() => {
          setAssign(false)
        }}
        subTitle='Atras'
        footer={<Tabs defaultActiveKey='1' animated={true}></Tabs>}
      >
        <div className='flex flex-col  mb-3'>
          <div className='grid grid-cols-1 mt-4 gap-4'>
            <div className='flex items-start p-4 rounded-xl shadow-sm bg-white'>
              <div className='flex items-center justify-center h-12 w-12 rounded-full'>
                <AvatarProfileUsers
                  image={
                    translation.photoId
                      ? `${translation.photoPath}/${translation.photoId}-avatar-small.jpg`
                      : ''
                  }
                  size={30}
                />
              </div>

              <div className=' w-1/2 '>
                <div className='flex justify-center items-center m-auto '>
                  <div className='w-1/5 text-right mr-3'>
                    <H2>Cliente</H2>
                  </div>
                  <div className='w-4/5'>
                    <P className='text-gray-500 text-xs text-left'>{translation.userName}</P>
                  </div>
                </div>
                <div className='flex justify-center items-center m-auto'>
                  <div className='w-1/5 text-right mr-3'>
                    <H2>Email</H2>
                  </div>
                  <div className='w-4/5'>
                    <P className='text-gray-500 text-xs  text-left'>{translation.email}</P>
                  </div>
                </div>
              </div>
              <div className=' w-1/2'>
                <div className='flex justify-center items-center m-auto '>
                  <div className='w-1/5 text-right mr-3'>
                    <H2>Descripcion</H2>
                  </div>
                  <div className='w-4/5'>
                    <P className='text-gray-500 text-xs  text-left'>
                      {parse(translation.description ? translation.description : '')}
                    </P>
                  </div>
                </div>
                <div className='flex justify-center items-center m-auto'>
                  <div className='w-1/5 text-right mr-3 '>
                    <H2>Express</H2>
                  </div>
                  <div className='w-4/5'>
                    <P className='text-gray-500 text-xs  text-left'>{translation.express}</P>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListStaff setStaff={setStaff} />
      </PageHeader>
    </Container>
  )
}

export default AssignTranslation
