/* eslint-disable @typescript-eslint/no-unused-vars */
import { type Files, type FilesQueryResponse, type QueryfilesData, toFormValues } from './_models'
import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import Buffer from 'buffer'
import { axiosErrorHandler } from '../../../../../app/modules/errors/ApiError'
import { dataURLtoFile } from '../../../../../core/utils/dataURLtoFile'
import fileDownload from 'js-file-download'
import { type filesData } from '../../../../../core/common/interfaces/files'
const API_URL = process.env.REACT_APP_API_URL
const FILE_URL = `${API_URL}/files`
const TRANSLATION_URL = `${API_URL}/translations`
const uploadFiles = async (
  fileListObject: any,
  getProgress: Function,
  type: string,
  taskId: string
) => {
  const formData = new FormData()
  fileListObject.forEach((fileObject: any) => {
    formData.append('files[]', fileObject.originFileObj)
  })
  formData.append('type', type)
  formData.append('taskId', taskId)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadtranslate`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const uploadFile = async (fileObject: any, getProgress: Function, type: string) => {
  const formData = new FormData()
  formData.append('file', fileObject.dragger[0].originFileObj)
  formData.append('fileName', fileObject.fileName)
  formData.append('province', fileObject.province)
  formData.append('road', fileObject.road)
  formData.append('type', type)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadprivate`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}

const uploadFileIntake = async (
  fileObject: any,
  getProgress: Function,
  type: string,
  documentId: string,
  userId: string,
  contractId: string,
  productId: string,
  memberName: string,
  memberType: string
) => {
  const formData = new FormData()
  formData.append('file', fileObject)
  formData.append('fileName', fileObject.name)
  formData.append('type', type)
  formData.append('documentId', documentId)
  formData.append('userId', userId)
  formData.append('contractId', contractId)
  formData.append('productId', productId)
  formData.append('memberName', memberName)
  formData.append('memberType', memberType)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadintake`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}

const uploadFileIntakeLetters = async (
  fileObject: any,
  getProgress: Function,
  type: string,
  documentId: string,
  userId: string,
  contractId: string,
  productId: string,
  memberName: string,
  memberType: string,
  authorId: string
) => {
  const formData = new FormData()
  formData.append('file', fileObject)
  formData.append('fileName', fileObject.name)
  formData.append('type', type)
  formData.append('documentId', documentId)
  formData.append('userId', userId)
  formData.append('contractId', contractId)
  formData.append('productId', productId)
  formData.append('memberName', memberName)
  formData.append('memberType', memberType)
  formData.append('authorId', authorId)
  const config = {
    headers: {
      'content-type': 'multipart/Intake-Letter',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      getProgress(percentCompleted)
    },
  }
  console.log(formData, 'el forms data')
  return await axios
    .post(`${FILE_URL}/uploadintakeletter`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}

const uploadFileManualTranslation = async (fileObject: any, getProgress: Function) => {
  const formData = new FormData()
  formData.append('file', fileObject)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${TRANSLATION_URL}/uploadfilemanualtranslation`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}

const uploadfilesmessagenewversion = async (fileObject: any, getProgress: Function) => {
  const formData = new FormData()
  formData.append('file', fileObject)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadfilesmessagenewversion`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const uploadNoFileIntake = async (
  /*  getProgress: Function, */

  documentId: string,
  userId: string,
  contractId: string,
  productId: string,
  memberName?: string,
  memberType?: string,
  saveAuthor?:string
) => {
  const formData = new FormData()
  formData.append('documentId', documentId)
  formData.append('userId', userId)
  formData.append('contractId', contractId)
  formData.append('productId', productId)
  if(saveAuthor){
  formData.append('saveAuthor', saveAuthor)
 }
  if (memberName && memberType) {
    formData.append('memberName', memberName)
    formData.append('memberType', memberType)
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      /*    getProgress(percentCompleted) */
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadnofileintake`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
}

const uploadNoFileIntakeProduct = async (
  /*  getProgress: Function, */

  documentId: string,
  userId: string,
  contractId: string,
  productId: string,
  memberName?: string,
  memberType?: string
) => {
  const formData = new FormData()
  formData.append('documentId', documentId)
  formData.append('userId', userId)
  formData.append('contractId', contractId)
  formData.append('productId', productId)
  if (memberName && memberType) {
    formData.append('memberName', memberName)
    formData.append('memberType', memberType)
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      /*    getProgress(percentCompleted) */
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadnofileintakeproduct`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
}
const uploadLogger = async (
  fileObject: any,
  getProgress: Function,
  type: string,
  fileName: string
) => {
  const formData = new FormData()
  formData.append('file', fileObject)
  formData.append('fileName', fileName)
  formData.append('type', type)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadloggger`, formData, config)
    .then((response: AxiosResponse<unknown>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const uploadFileAvatar = async (
  imageLarge: any,
  imageMedium: any,
  imageSmall: any,
  getProgress: Function
) => {
  // imageToBase64(imageLarge)
  const formData = new FormData()
  formData.append('files[]', dataURLtoFile(imageLarge, 'avatar-large.jpg'))
  formData.append('files[]', dataURLtoFile(imageMedium, 'avatar-medium.jpg'))
  formData.append('files[]', dataURLtoFile(imageSmall, 'avatar-small.jpg'))
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadavatar`, formData, config)
    .then((response: AxiosResponse<any>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const getFiles = async (query?: string): Promise<FilesQueryResponse> => {
  return await axios.get(`${FILE_URL}?${query}`).then((d: AxiosResponse<FilesQueryResponse>) => {
    return d.data
  })
}

const getFilesNew = async (query?: string): Promise<FilesQueryResponse> => {
  return await axios
    .get(`${FILE_URL}/new?${query}`)
    .then((d: AxiosResponse<FilesQueryResponse>) => {
      return d.data
    })
}
const downloadFile = async (id: string, getProgress: Function) => {
  const config: AxiosRequestConfig = {
    responseType: 'stream',
    onDownloadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios.get(`${FILE_URL}/download/${id}`, config).then((response) => {
    const buff = Buffer.Buffer.from(response.data.file)
    fileDownload(buff, response.data.fileName)
    return null
  })
}

const downloadFileNew = async (id: string, getProgress: Function) => {
  const config: any = {
    responseType: 'Arraybuffer',
    onDownloadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios.get(`${FILE_URL}/download/${id}`, config).then((response) => {
    const buff = Buffer.Buffer.from(response.data.file)
    fileDownload(buff, response.data.fileName)
    return 'downloaded'
  })
}
const updateFile = async (file: Files) => {
  return await axios.patch(`${FILE_URL}/${file.id}`, file).then((response) => {
    return toFormValues(response.data as Files)
  })
}
const deleteFile = async (id: string) => {
  return await axios.delete(`${FILE_URL}/${id}`).then((response) => {
    return toFormValues(response.data as Files)
  })
}
const uploadPublic = async (files: filesData[], getProgress: Function) => {
  // imageToBase64(imageLarge)
  const formData = new FormData()
  files.forEach((file: filesData) => {
    formData.append('files[]', dataURLtoFile(file.file, file.name))
  })
  formData.append('length', files.length.toString())
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      getProgress(percentCompleted)
    },
  }
  return await axios
    .post(`${FILE_URL}/uploadpublic`, formData, config)
    .then((response: AxiosResponse<any>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
}
const uploadTemp = () => {
  return `${FILE_URL}/uploadtemp`
}
const getFilesIds = async (query: QueryfilesData): Promise<FilesQueryResponse> => {
  return await axios
    .post(`${FILE_URL}/filesids`, { ...query })
    .then((d: AxiosResponse<FilesQueryResponse>) => {
      return d.data
    })
}
export {
  getFilesNew,
  uploadFile,
  getFiles,
  downloadFile,
  updateFile,
  deleteFile,
  uploadFileAvatar,
  uploadFiles,
  uploadPublic,
  uploadTemp,
  getFilesIds,
  uploadFileIntake,
  uploadLogger,
  uploadNoFileIntake,
  uploadFileManualTranslation,
  uploadfilesmessagenewversion,
  uploadNoFileIntakeProduct,
  downloadFileNew,
  uploadFileIntakeLetters
}
