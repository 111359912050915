/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type Appointments,
  type AppointmentsAdmin,
  type AppointmentsAdminQueryResponse,
  type AppointmentsQueryResponse,
  type IApointments,
  type ICreacteAppointment,
  ResponseApointment,
} from './_models'
import axios, {type AxiosResponse} from 'axios'

import {type Response} from '../../../../../core/helpers'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'
import { Users } from '../../users/core/_models';
const API_KEY = 'MiClaveSecreta1235trgh5';
const API_URL = process.env.REACT_APP_API_URL
const APPOINTMENTS_URL = `${API_URL}/calendly`
const ADMIN_APPOINTMENTS_URL = `${API_URL}/staffs/consultation`
interface PostalCodeResult {
  POSTAL_CODE?: string;
  CITY?: string;
  PROVINCE_ABBR?: string;
  ZipCode?: string;
  City?: string;
  County?: string;
  State?: string;
}



const createGoogleDocAPI = async (title: string): Promise<string> => {
  try {
    const response = await axios.post(`${API_URL}/googledocs/create`, { title });
    return response.data.documentId; // Devuelve el documentId del backend
  } catch (error) {
    console.error('Error al crear el documento en Google Docs', error);
    throw error;
  }
}
const getPostalCodes = async (query: string): Promise<PostalCodeResult[]> => {
  try {
    const response = await axios.get<PostalCodeResult[]>(`${API_URL}/postalCode/search`, {
      params: { query },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching postal codes:', error);
    return [];
  }
};
const getAppointments = async (query: string): Promise<Appointments[]> => {
  return await axios
    .get(`${APPOINTMENTS_URL}?${query}`)
    .then((d: AxiosResponse<AppointmentsQueryResponse>) => {
      return d.data as Appointments[]
    })
}

const getNotificationsByUserId = async (userId: string) => {
  try {
    const response = await axios.get(`${API_URL}/notifications/${userId}`);
    return response.data;
  } catch (error) {
   
    return [];
  }
};

const getUserById = async (userId: string) => {
  try {
    const response = await axios.get(`${API_URL}/users/${userId}`);
    return response.data;
  } catch (error) {
   
    return null;
  }
};
const translateText = async (text: string, language: string) => {
  try {
    const response = await axios.get(`${API_URL}/translationForms`, {
      params: { text, lang: language }
    });
    return response.data.translated_text;
  } catch (error) {
    return '';
  }
};


const getAdminAppointments = (params: IApointments | null): Promise<AppointmentsAdmin[]> | null => {
  if (params != null) {
    return axios
      .post(`${ADMIN_APPOINTMENTS_URL}`, params)
      .then((d: AxiosResponse<AppointmentsAdminQueryResponse>) => {
        return d.data as AppointmentsAdmin[]
      })
  }
  return null
}

const updateAppointments = async (appointments: Appointments, id: string) => {
  return await axios
    .patch(`${APPOINTMENTS_URL}`, appointments)
    .then((response: AxiosResponse<Response<Appointments>>) => response.data)
    .then((response: Response<Appointments>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar perfil')
    })
}

const getAdminAppointmentsByConsultant = (
  params: IApointments | null
): Promise<AppointmentsAdmin[]> | null => {
  if (params != null) {
    return axios
      .post(`${API_URL}/staffs/consultationbyconsultant`, params)
      .then((d: AxiosResponse<AppointmentsAdminQueryResponse>) => {
        return d.data as AppointmentsAdmin[]
      })
  }
  return null
}

const getContractsByUserIds = async (input: string): Promise<Users[]> => {
  const API_URL = process.env.REACT_APP_API_URL;
  return await axios
    .get(`${API_URL}/contracts/userIds?input=${input}`)
    .then((d: AxiosResponse<Users[]>) => {
      return d.data;
    })
    .catch((error) => {
      console.error("Error fetching contracts by user IDs:", error);
      return [];
    });
};



const findTempsByContractId = async (contractId: string) => {
  try {
    const response = await axios.post(`${API_URL}/contractstemps/findtemp`, {
      envelopeId: contractId // O el campo correcto según el backend
    });
    console.log('Response from findTempsByContractId:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching temps by contractId:', error);
    return null;
  }
};

const getAdminAppointmentsNew = async (params: any) => {
   if (params != null) {
    try {
      // Solicitar el token al servidor
      const tokenResponse = await axios.get(`${API_URL}/staffs/generate-token`, {
        params: { startDate: params.startDate, endDate: params.endDate },
        headers: {
          'X-My-Custom-Header': 'MiClaveSecreta1235trgh5'  // Envía la clave como una cabecera personalizada
        }
      });
      let token = tokenResponse.data.token;

      // Ofuscar el token
      token = btoa(token);  // Encode the token in base64

      // Asignar el token ofuscado a los parámetros
      params.token = token;


      const response = await axios.post(`${API_URL}/staffs/consultationNew`, params, {
        headers: {
          'X-My-Custom-Header': 'MiClaveSecreta1235trgh5'  // Incluir la misma cabecera en esta solicitud
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return { error: "Failed to fetch appointments" };
    }
  } 
  return null;
};

const getAdminAppointmentsUser= async (params: any) => {
  if (params != null) {
    try {
      // Solicitar el token al servidor
      const tokenResponse = await axios.get(`${API_URL}/staffs/generate-token`, {
        params: { startDate: params.startDate, endDate: params.endDate },
        headers: {
          'X-My-Custom-Header': 'MiClaveSecreta1235trgh5'  // Envía la clave como una cabecera personalizada
        }
      });
      let token = tokenResponse.data.token;

      // Ofuscar el token
      token = btoa(token);  // Encode the token in base64

      // Asignar el token ofuscado a los parámetros
      params.token = token;

      // Realizar la solicitud POST incluyendo la cabecera personalizada
      const response = await axios.post(`${API_URL}/staffs/consultationNewUser`, params, {
        headers: {
          'X-My-Custom-Header': 'MiClaveSecreta1235trgh5'  // Incluir la misma cabecera en esta solicitud
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return { error: "Failed to fetch appointments" };
    }
  }
  return null;
};



const sendNotification = async (userId: string, contractId: string) => {
  try {
    await axios.post(`${API_URL}/notifications`, {
      user: userId,
      contract: contractId
    });
  } catch (error) {
    console.error('Error sending notification:', error);
  }
};

const createAppointment = async (data: ICreacteAppointment) => {
  return await axios
    .post(`${API_URL}/orders/appointments`, {...data})
    .then((d: AxiosResponse<any>) => {
      return d.data
    })
}

export {
  getContractsByUserIds,
  getAppointments,
  updateAppointments,
  getAdminAppointments,
  getAdminAppointmentsByConsultant,
  createAppointment,
  getAdminAppointmentsNew,
  getNotificationsByUserId,
  getUserById,
  translateText,
  sendNotification,
  getPostalCodes,
  findTempsByContractId,
  getAdminAppointmentsUser
}
