 
/* eslint-disable  */
import React, {useContext, useEffect} from 'react'
import {type CheckBoxOptions} from '../types'
import {Checkbox} from 'antd'
import {RootStoreContext} from '../../../../../stores/rootStore'
import parse from 'html-react-parser'

interface IProps {
  options: CheckBoxOptions[]
  handleChange?: (checkBoxOptions: CheckBoxOptions, cheked: boolean) => void
}

const ImmigrationDetailsList: React.FC<IProps> = ({options, handleChange}) => {
  const rootStore = useContext(RootStoreContext)
  const {immigrationRequirement} = rootStore.composeTemplateStore

  const onChange = (e: any, optionChoose: CheckBoxOptions) => {
    handleChange != null ? handleChange(optionChoose, e.target.checked) : null
  }

  useEffect(() => {
    if (options.length === 1) {
      options[0].checked = true
      handleChange != null ? handleChange(options[0], true) : null
    }
  }, [])
  const verifyChecked = (option: CheckBoxOptions[]) => {
    if (options.length === 1) {
      handleChange != null ? handleChange(options[0], true) : null
      return true
    }

    return false
  }

  return (
    <ul role='list' className='my-0 '>
      {options.map((option) => {
        return (
          <li key={option.id} className='flex items-center justify-between py-2'>
            <Checkbox
              onChange={(e) => {
                onChange(e, option)
              }}
              name={option.name || ''}
              // defaultChecked={options.length === 1 ? true : false}
              defaultChecked={verifyChecked(options)}
            >
              <span className='ml-2 text-gray-800 '>{option.name}</span>
              <br />
              <span className='ml-2 text-gray-600 dark:text-gray-400'>
                {parse(option.description || '')}
              </span>
            </Checkbox>
          </li>
        )
      })}
    </ul>
  )
}

export default ImmigrationDetailsList
