import PayWrapper from './PayWrapper'

const CheckOut = () => {
  return (
    <div className='w-full p-4  mx-auto my-4 '>
      {/** choose paymentMethod   */}

      <PayWrapper />
    </div>
  )
}

export default CheckOut
