/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from '../../../../stores/rootStore'
import {getAppointments} from '../../../../app/modules/apps/appointments/core/_requests'
import {observer} from 'mobx-react'
import {stringifyRequestQuery} from '../../../../core/helpers'
import {useQuery} from 'react-query'
import {useQueryRequest} from 'app/modules/apps/profile/core/QueryRequestProvider'
import {AdminAppointments} from '../adminappointments'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import {getStaff} from 'app/modules/apps/staff/core/_requests'
import {RoleEnum} from 'auth/models/User/UserModel'
import { AppointmentsUser } from '../adminappointments/AppointmentsUser'

interface Props {
  data?: string
  ProfileImmigration: ProfileImmigration
}

const Appointments: React.FC<Props> = (props) => {
  const {ProfileImmigration} = props
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const [zone, setZone] = useState<string>('Canada/Saskatchewan')
  const [staffId, setStaffId] = useState('')
  const {data: StaffData, status: StaffStatus} = useQuery(
    'getStassff',
    async () => await getStaff(user ? user.id : ''),
    {enabled: user !== undefined, cacheTime: 0}
  )
  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    }
  }, [user])
  useEffect(() => {
    setQuery('')
  }, [query])

  useEffect(() => {
    if (staffId === '' && StaffData !== undefined && StaffData[0] && StaffData[0].id) {
      setStaffId(StaffData[0].id)
    }
  }, [StaffData])

  const {status} = useQuery('appointments', async () => await getAppointments(query))

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  if ((staffId === '' || StaffStatus === 'loading') && user?.role?.id === RoleEnum.admin) {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  return (
    <>
    
    {user?.role?.id===1 ? 
    <><></><AdminAppointments ProfileImmigration={ProfileImmigration} staffId={staffId} /></>
    :
    <><></><AppointmentsUser ProfileImmigration={ProfileImmigration} staffId={staffId} /></>}
      
    </>
  )
}

export default observer(Appointments)
