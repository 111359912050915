import React, {useState, useEffect} from 'react'
import {Institution} from 'app/modules/apps/cart/core/education/_models'
import {CountrySelect} from 'app/modules/utils/components/CountrySelect'
import {updateInstitution} from 'app/modules/apps/cart/core/education/_request'

interface EditInstitutionFormProps {
  editInstitution: Institution // Replace "Institution" with the actual type of editInstitution
  onSave: () => void
}

const EditInstitutionForm: React.FC<EditInstitutionFormProps> = ({editInstitution, onSave}) => {
  const [formData, setFormData] = useState({
    name: '',
    province: '',
    country: '',
    address: '',
    type: '',
    partnership: '',
  })

  useEffect(() => {
    if (editInstitution) {
      setFormData({
        name: editInstitution.name,
        province: editInstitution.province,
        country: editInstitution.country,
        address: editInstitution.address,
        type: editInstitution.type,
        partnership: editInstitution.partnership,
      })
    }
  }, [editInstitution])

  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()

    try {
      /* const response = await axios.patch(
        `${API_URL}/institution/updateInstitution/${editInstitution.institutionId}`,
        formData
      ); */
      const response = await updateInstitution(editInstitution, formData)

      console.log('Institution updated:', response)
      onSave()
    } catch (error) {
      console.error('Error updating institution:', error)
    }
  }
  const provinces = [
    {
      name: 'Alberta',
    },

    {
      name: 'British Columbia',
    },

    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
  ]
  return (
    <div className='form-container1'>
      <form onSubmit={handleSubmit} className='custom-form1'>
        <div className='form-group1'>
          <label className='label'>Name:</label>
          <input
            className='input'
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className='form-group1'>
          <label className='label'>Province</label>
          <select
            className='input'
            name='province'
            value={formData.province}
            onChange={handleChange}
          >
            <option value=''>Select a province</option>
            {provinces.map((province) => (
              <option key={province.name} value={province.name}>
                {province.name}
              </option>
            ))}
          </select>
        </div>
        <div className='form-group1'>
          <label className='label'>Country:</label>
          <select className='input' name='country' value={formData.country} onChange={handleChange}>
            <CountrySelect />
          </select>
        </div>
        <div className='form-group1'>
          <label className='label'>Address:</label>
          <input
            className='input'
            type='text'
            name='address'
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className='form-group1'>
          <label className='label'>Type:</label>
          <input
            className='input'
            type='text'
            name='type'
            value={formData.type}
            onChange={handleChange}
          />
        </div>
        <div className='form-group1'>
          <label className='label'>Partnership:</label>
          <input
            className='input'
            type='text'
            name='partnership'
            value={formData.partnership}
            onChange={handleChange}
          />
        </div>
        {/* <div className="form-group">
          <label className="label">Owner:</label>
          <input className="input" type="text" name="owner" value={formData.owner} onChange={handleChange} />
        </div> */}
        <button type='submit' className='submit-button1'>
          Save Changes
        </button>
      </form>
    </div>
  )
}

export default EditInstitutionForm
