/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { type IconType } from 'react-icons/lib'
import { Link, useLocation } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import { checkIsActive } from '../../core/helpers'
import { useLayout } from '../../core/layout/core'
import { RootStoreContext } from 'stores/rootStore'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'

interface Props {
  to: string
  title: string
  icon?: string | IconType
  fontIcon?: string
  hasBullet?: boolean
  IconName?: JSX.Element
  newMessages?: number
  newMessagesAbsentConsultant?: number
  children: React.ReactNode
}

const AsideMenuItem: React.FC<Props> = observer(({ // Notice the change here: opening parenthesis instead of curly braces
  to,
  title,
  icon,
  IconName,
  newMessagesAbsentConsultant,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { pathname } = useLocation()
  const { config } = useLayout()
  const { aside } = config
  const setPrimary = checkIsActive(pathname, to) ? 'menu-color-text ' : 'menu-color-text2 '
  const setPrimary2 = checkIsActive(pathname, to) ? 'svg-fill-color' : 'svg-fill-color2'
  const { countMessagesNotRead: newMessages, countMessagesAuth } = rootStore.messageStore
  const intl = useIntl()
  useEffect(() => {
  }, [countMessagesAuth])


  return (
    <li style={{ listStyle: 'none' }} className={`${setPrimary2} p-2 pl-4 roun`}> {/* hover:bg-gray-300  hover:rounded-md */}
      <Link to={to} className='flex text-gray-500  hover:text-gray-500'>
        <span className='text-black w-6 inline-block  justify-center items-center '>
          <i>
            {icon && aside.menuIcon === 'svg' &&
              <span>
                {IconName}
              </span>}
          </i>
        </span>
        <span className={`${setPrimary} text-centar `}
        style={{fontSize:'14px'}}>{title}</span>
        {/* span counter unread  */}
        {countMessagesAuth && countMessagesAuth > 0 && title === 'Mensajes' ? (
          <div
            className='animate-bounce'
          >
            <div>
              <span>
                !
              </span>
            </div>
          </div>
        ) : null}

        {/* span counter absent unread  */}
        {newMessagesAbsentConsultant &&
          newMessagesAbsentConsultant > 0 &&
          (title === 'Mensajes consultor ausente' || title === 'Messages consultant absent') ? (
          <div className='animate-bounce w-4 h-4 '>
            <div >
              <img src='https://img.icons8.com/office/16/null/secured-letter--v1.png' alt='' />
              <span>
                {Number(newMessagesAbsentConsultant)}
              </span>
            </div>
          </div>
        ) : null}
      </Link>
    </li>
  )
})

export { AsideMenuItem }
