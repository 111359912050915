import tw from 'tailwind-styled-components'

const TableBody = tw.tbody`
text-xs
text-gray-500
text-black
normal-case

 
`
export {TableBody}
