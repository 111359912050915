

import { FaGraduationCap } from 'react-icons/fa'
import { MdCategory } from 'react-icons/md'
import {  RiProductHuntLine } from 'react-icons/ri'
import HomeIcon from '../icons/HomeIcon.svg'
import UsersIcon from '../icons/usersMenu.svg'

const getIcon = (name: string) => {
  switch (name) {
    case 'AiFillCreditCard':
      return <img width={15} src={HomeIcon} alt='Menu' />//Dashboard
    case 'AiOutlineUser':
      return <img width={15} src='/media/icons/app/menu/UserProfile.svg' alt='Menu' /> //perfil
    case 'MdOutlineContactPage':
      return <img width={15} src={UsersIcon} alt='Menu' /> // lista de clientes
    case 'RiDashboardFill':
      return <img width={15} src={UsersIcon} alt='Menu' /> //Clientes
    case 'MdCategory':
      return <MdCategory /> //Varios
    case 'BiStore':
      return <img width={15} src='/media/icons/app/menu/PowerUp.svg' alt='Menu' /> // admin tienda
    case 'RiProductHuntLine':
      return <RiProductHuntLine />
    case 'AiFillFolderOpen':
      return <img width={15} src='/media/icons/app/menu/Library.svg' alt='Menu' /> // files icon
    case 'BsFileText':
      return <img width={15} src='/media/icons/app/menu/Library.svg' alt='Menu' /> // editor plantillas
    case 'BsCalendar3':
      return <img width={15} src='/media/icons/app/menu/calender-alt-1.svg' alt='Menu' /> // Mis citas o cosas con calendario
    case 'IntakeName':
      return <img width={15} src='/media/icons/app/menu/bar-chart.svg' alt='Menu' /> // Mis citas o cosas con calendario
    case 'BiMessageDetail':
      return <img width={15} src='/media/icons/app/menu/speech-bubble-19.svg' alt='Menu' /> //mensajes
    case 'AiOutlineFileText':
      return <img width={15} src='/media/icons/app/menu/Library.svg' alt='Menu' /> //editor formuarios
    case 'BsTranslate':
      return <img width={15} src='/media/icons/app/menu/world.svg' alt='Menu' /> // traducciones
    case 'FaGraduationCap':
      return <FaGraduationCap />
    default:
      return <img width={15} src='/media/icons/app/menu/Library.svg' alt='Menu' />
  }
}

export default getIcon
