/* eslint-disable @typescript-eslint/no-unused-vars */
import { type UserModel, initialUser } from '../models/User/UserModel'
import { action, makeObservable, observable, configure } from 'mobx'
import {
  getUserByToken,
  login,
  register,
  reminder,
  requestPassword,
  reset,
  changeFirstAccess,
  changeEmail
} from '../models/AuthRequest'
import { type ErrorResponse } from '../../core/error/types'
import { type RootStore } from '../../stores/rootStore'
import axios from 'axios'
import setupAxios from '../../setup/axios/SetupAxios'
import { getStaff } from 'app/modules/apps/staff/core/_requests'
import { getContractByUserId, getContractOnlyWithMatchId, getTempsByMatchId, saveContract } from 'app/modules/apps/contract/_requests'
import { getAllCompletedContractsByEmail } from 'app/modules/apps/docusign/_requests'
import { toast } from 'react-toastify'
configure({
  enforceActions: 'never',
})

interface IStorageUser {
  'persist-data-auth': {
    accessToken: string
    user: string
    timezone: string
    language: string
  }
}

const initialStateData: IStorageUser = {
  'persist-data-auth': {
    accessToken: '',
    user: '',
    timezone: '',
    language: 'es',
  },
}

export default class AuthStore {
  rootStore: RootStore
  foundContract: any = false
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
    this.isAuthenticated = !!this.getAccessToken()
    this.contracts = this.getContract()
  }

  @observable accessToken: string | null = null
  @observable onErrorLogin: ErrorResponse | null = null
  @observable onErrorRegister: any = null
  @observable user?: UserModel
  @observable staff?: any
  @observable contracts?: any
  @observable loading = true
  @observable loadingContracts = true
  @observable loadingDocusignFetch = false
  @observable isAuthenticated = false
  @observable authData: IStorageUser = initialStateData

  getAccessToken() {
    try {
      const documentData = JSON.parse(localStorage.getItem('document')?.toString() ?? '{}')
      if (documentData['persist-data-auth'].accessToken !== '') {
        this.accessToken = documentData['persist-data-auth'].accessToken
        setupAxios(axios, this.accessToken)
        if (this.accessToken) {
          getUserByToken(this.accessToken).then((user) => {

            this.user = user.data as unknown as UserModel
            this.loading = false
            this.isAuthenticated = true
            // Now that you have the user data, fetch staff data.
            if (user.data.id) {
              getStaff(user.data.id).then((staff: any) => {
                if (staff) {
                  this.staff = staff[0]
                }
              })
            }
          })
        }

        return true
      }
    } catch (e) { }
    this.loading = false
    this.isAuthenticated = false
    this.accessToken = null
    this.user = initialUser
    return false
  }

  getContract() {
    const contracts = localStorage.getItem('contracts');
    if (contracts !== null) {
      this.contracts = JSON.parse(contracts)
      this.loadingContracts = false
      return JSON.parse(contracts);
    }
    return []
  }

  @action fulfillUser = (user: UserModel) => {
    this.user = user
  }

  @action fillStaff = (staff: any) => {
    this.staff = staff
  }

  @action logout = () => {
    localStorage.removeItem('contracts');
    this.contracts = []
    if (this.user) {
      changeFirstAccess(this.user.id)
    }
    this.isAuthenticated = false
    this.accessToken = ''
    this.authData = {
      'persist-data-auth': {
        accessToken: '',
        user: '',
        timezone: '',
        language: 'es',
      },
    }
    try {
      setupAxios(axios, '')
      localStorage.removeItem('document')
    } catch (e) { }
    window.location.href = '/'
  }


  @action changeAssociateEmail = async (email: string) => {

    localStorage.removeItem('contracts');
    this.contracts = [];
    if (this.user) {
      changeFirstAccess(this.user.id);
    }
    this.isAuthenticated = false;
    this.accessToken = '';
    this.authData = {
      'persist-data-auth': {
        accessToken: '',
        user: '',
        timezone: '',
        language: 'es',
      },
    };
    try {
      setupAxios(axios, '');
      localStorage.removeItem('document');
    } catch (e) { }
    await this.loginChangeUser(email);

    if (this.accessToken !== '') {
      window.location.reload();

    }
  }


  @action loginUser = async (user: string, password: string) => {
    this.onErrorLogin = null

    await login(user, password)
      .then(async (response) => {
        this.accessToken = response.data.token
        setupAxios(axios, response.data.token)
        this.fulfillUser(response.data.user)

        this.authData = {
          'persist-data-auth': {
            accessToken: response.data.token,
            user: JSON.stringify(response.data.user),
            timezone: '',
            language: 'es',
          },
        }

        this.isAuthenticated = true

        localStorage.setItem('document', JSON.stringify(this.authData))
        // Now that you have the user data, fetch staff data.
        if (response.data.user.id) {
          getStaff(response.data.user.id).then((staff: any) => {
            if (staff) {
              this.staff = staff[0]
            }
          })
          if (response.data.user.role.id === 2) {
            const setMonth = (month: string) => {
              if (month === '01') {
                return '12'
              } else if (month === '02') {
                return '01'
              } else if (month === '03') {
                return '02'
              } else if (month === '04') {
                return '03'
              } else if (month === '05') {
                return '04'
              } else if (month === '06') {
                return '05'
              } else if (month === '07') {
                return '06'
              } else if (month === '08') {
                return '07'
              } else if (month === '09') {
                return '08'
              } else if (month === '10') {
                return '09'
              } else if (month === '11') {
                return '10'
              } else if (month === '12') {
                return '11'
              } else {
                return '01'
              }
            }
            const mm = setMonth(response.data.user.createdAt != null ? response.data.user.createdAt.toString().substring(5, 7) : '02')
            const dd = response.data.user.createdAt != null ? response.data.user.createdAt.toString().substring(8, 10) : '01'
            const yyyy = response.data.user.createdAt != null ? response.data.user.createdAt.toString().substring(0, 4) : '2021'
            const createdAt = yyyy + '-' + mm + '-' + dd
            const automaticSet = await  getContractOnlyWithMatchId(response.data.user.id)
            if (automaticSet && automaticSet.data.length > 0) {
              localStorage.setItem('contracts', JSON.stringify(automaticSet.data))
              this.contracts = automaticSet.data
              this.loadingContracts = false
            }
            const databaseCon = await  getContractByUserId(response.data.user.id)
            try{
            this.loadingDocusignFetch=true
            const docusignCon = await getAllCompletedContractsByEmail(response.data.user.email, createdAt, '0000-00-00')
            if (docusignCon.folderItems.length > 0 && databaseCon) {


              for (const folderItem of docusignCon.folderItems) {
                let found = false
                const matchIdFound = folderItem.matchId
                for (const contractFromTable of databaseCon.data) {
                  if (contractFromTable.envelope_id === folderItem.envelopeId) {
                    found = true
                  }
                }
                if (!found) {

                  if (matchIdFound) {

                    const tempWithMatchId = await getTempsByMatchId(matchIdFound)

                    const contract = {
                      user: response.data.user.id,
                      envelope_id: folderItem.envelopeId,
                      data: folderItem,
                      offboarding: false,
                      completedDate: folderItem.completedDateTime,
                      matter: folderItem.matter ? folderItem.matter : '',
                      match_id: folderItem.matchId ? folderItem.matchId : '',
                      applicants: tempWithMatchId.applicants,
                      type: folderItem.type ? folderItem.type : '',
                    }


                    // Verificar si applicants, matter o match_id están vacíos o no existen
                    if (!contract.applicants || contract.applicants.length === 0) {
                     // toast.error('Error: El contrato no tiene applicants. No se puede guardar.');
                    } else if (!contract.matter) {
                    //  toast.error('Error: El contrato no tiene matter. No se puede guardar.');
                    } else if (!contract.match_id) {
                   //   toast.error('Error: El contrato no tiene match_id. No se puede guardar.');
                    } else {

                    }
                  }else{
                    if((folderItem.type==='useofrepresentative' || folderItem.type==='amendment')){
                    const contract = {
                      user: response.data.user.id,
                      envelope_id: folderItem.envelopeId,
                      data: folderItem,
                      offboarding: false,
                      completedDate: folderItem.completedDateTime,
                      matter: folderItem.matter ? folderItem.matter : '',
                      match_id: folderItem.matchId ? folderItem.matchId : '',
                      applicants: '',
                      type: folderItem.type ? folderItem.type : '',
                    }


                    await saveContract(contract);
                  }
                  }

                /*  if (newContract) {
                   const databaseConNew = await getContractByUserEmail(response.data.user.email)
                   if (databaseConNew.data.length > 0) {
                     localStorage.setItem('contracts', JSON.stringify(databaseConNew.data))
                     this.contracts = databaseConNew.data
                     this.getContract()
                     this.loadingContracts = false
                   }
                 } */
                  }
                }
                const databaseConNew = await getContractOnlyWithMatchId(response.data.user.id)
                if (databaseConNew.data.length > 0) {
                  localStorage.setItem('contracts', JSON.stringify(databaseConNew.data))
                  this.contracts = databaseConNew.data
                  this.getContract()
                  this.loadingContracts = false
                }
              }
            this.loadingDocusignFetch=false

            } catch (error) {
              this.loadingDocusignFetch=false
              this.loadingContracts = false
              console.error('Error in Docusign', error);

            }
          }
        }
      })
      .catch((error) => {
        this.onErrorLogin = error.response.data || { error: 'Error' }
        this.loading = false
      })
  }

  @action loginChangeUser = async (user: string) => {
    this.onErrorLogin = null

    await changeEmail(user)
      .then(async (response) => {
        this.accessToken = response.data.token
        setupAxios(axios, response.data.token)
        this.fulfillUser(response.data.user)

        this.authData = {
          'persist-data-auth': {
            accessToken: response.data.token,
            user: JSON.stringify(response.data.user),
            timezone: '',
            language: 'es',
          },
        }

        this.isAuthenticated = true

        localStorage.setItem('document', JSON.stringify(this.authData))
        // Now that you have the user data, fetch staff data.
        if (response.data.user.id) {
          getStaff(response.data.user.id).then((staff: any) => {
            if (staff) {
              this.staff = staff[0]
            }
          })
          if (response.data.user.role.id === 2) {
            const setMonth = (month: string) => {
              if (month === '01') {
                return '12'
              } else if (month === '02') {
                return '01'
              } else if (month === '03') {
                return '02'
              } else if (month === '04') {
                return '03'
              } else if (month === '05') {
                return '04'
              } else if (month === '06') {
                return '05'
              } else if (month === '07') {
                return '06'
              } else if (month === '08') {
                return '07'
              } else if (month === '09') {
                return '08'
              } else if (month === '10') {
                return '09'
              } else if (month === '11') {
                return '10'
              } else if (month === '12') {
                return '11'
              } else {
                return '01'
              }
            }
            const mm = setMonth(response.data.user.createdAt != null ? response.data.user.createdAt.toString().substring(5, 7) : '02')
            const dd = response.data.user.createdAt != null ? response.data.user.createdAt.toString().substring(8, 10) : '01'
            const yyyy = response.data.user.createdAt != null ? response.data.user.createdAt.toString().substring(0, 4) : '2021'
            const createdAt = yyyy + '-' + mm + '-' + dd
            const automaticSet = await  getContractOnlyWithMatchId(response.data.user.id)
            if (automaticSet && automaticSet.data.length > 0) {
              localStorage.setItem('contracts', JSON.stringify(automaticSet.data))
              this.contracts = automaticSet.data
              this.loadingContracts = false
            }
            const databaseCon = await  getContractByUserId(response.data.user.id)
            try{
            this.loadingDocusignFetch=true
            const docusignCon = await getAllCompletedContractsByEmail(response.data.user.email, createdAt, '0000-00-00')
            if (docusignCon.folderItems.length > 0 && databaseCon) {
              for (const folderItem of docusignCon.folderItems) {
                let found = false
                const matchIdFound = folderItem.matchId
                for (const contractFromTable of databaseCon.data) {
                  if (contractFromTable.envelope_id === folderItem.envelopeId) {
                    found = true
                  }
                }
                if (!found) {
                  let tempWithMatchId
                  if (matchIdFound) {
                    const tempWithMatchId = await getTempsByMatchId(matchIdFound)
                    const contract = {
                      user: response.data.user.id,
                      envelope_id: folderItem.envelopeId,
                      data: folderItem,
                      offboarding: false,
                      completedDate: folderItem.completedDateTime,
                      matter: folderItem.matter ? folderItem.matter : '',
                      match_id: folderItem.matchId ? folderItem.matchId : '',
                      applicants: tempWithMatchId.applicants,
                      type: folderItem.type ? folderItem.type : '',
                    }
                    // Verificar si applicants, matter o match_id están vacíos o no existen
                    if (!contract.applicants || contract.applicants.length === 0) {
                  //    toast.error('Error: El contrato no tiene applicants. No se puede guardar.');
                    } else if (!contract.matter) {
                   //   toast.error('Error: El contrato no tiene matter. No se puede guardar.');
                    } else if (!contract.match_id) {
                  //    toast.error('Error: El contrato no tiene match_id. No se puede guardar.');
                    } else {
                      await saveContract(contract);
                    }
                  }else{
                    if((folderItem.type==='useofrepresentative' || folderItem.type==='amendment')){
                    const contract = {
                      user: response.data.user.id,
                      envelope_id: folderItem.envelopeId,
                      data: folderItem,
                      offboarding: false,
                      completedDate: folderItem.completedDateTime,
                      matter: folderItem.matter ? folderItem.matter : '',
                      match_id: folderItem.matchId ? folderItem.matchId : '',
                      applicants: '',
                      type: folderItem.type ? folderItem.type : '',
                    }
                    await saveContract(contract);
                  }
                  }

                   /*  if (newContract) {
                      const databaseConNew = await getContractByUserEmail(response.data.user.email)
                      if (databaseConNew.data.length > 0) {
                        localStorage.setItem('contracts', JSON.stringify(databaseConNew.data))
                        this.contracts = databaseConNew.data
                        this.getContract()
                        this.loadingContracts = false
                      }
                    } */
                  }
                }
                const databaseConNew = await getContractOnlyWithMatchId(response.data.user.id)
                if (databaseConNew.data.length > 0) {
                  localStorage.setItem('contracts', JSON.stringify(databaseConNew.data))
                  this.contracts = databaseConNew.data
                  this.getContract()
                  this.loadingContracts = false
                }
              }
            this.loadingDocusignFetch=false

            } catch (error) {
              this.loadingDocusignFetch=false
              this.loadingContracts = false
              console.error('Error in Docusign', error);

            }
          }
        }
      })
      .catch((error) => {
        this.onErrorLogin = error.response.data || { error: 'Error' }
        this.loading = false
      })
  }




  @action requestPasswordReset = async (email: string) => {
    return await requestPassword(email)
  }

  @action sendReminder = async (email: string) => {
    return await reminder(email)
  }

  @action requestReset = async (hash: string, password: string, password_confirmation: string) => {
    return await reset(hash, password, password_confirmation)
  }

  @action registerUser = async (user: UserModel, password: string) => {
    this.onErrorRegister = ''

    await register(user, password)
      .then(async (response) => {
        this.loginUser(user.email, password)
      })
      .catch((error) => {
        this.onErrorRegister = error.response
        this.loading = false
      })
    return true
  }
  @action updatingContracts = async () => {
    this.loadingContracts = true
    if (this.user) {
      try {
        const databaseCon = await getContractOnlyWithMatchId(this.user.id)
        if (databaseCon) {
          localStorage.setItem('contracts', JSON.stringify(databaseCon.data))
          this.contracts = databaseCon.data
          this.loadingContracts = false
        }
      } catch (error) { this.loadingContracts = false }
    }
    this.loadingContracts = false
  }
}





