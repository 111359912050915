 
 
/* eslint-disable  */
import './style.css'
import {
  type IQueryUsers,
  type Users,
  type IQueryUsersFilter,
  initialIQueryUsers,
} from '../../../../modules/apps/users/core/_models'
import React, {type Key, useContext, useEffect, useRef, useState} from 'react'
import AvatarProfileUsers from '../../../../modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {
  Button,
  Checkbox,
  Input,
  type InputRef,
  Select,
  Space,
  Table,
  type TableProps,
  Spin,
} from 'antd'
import {
  TablePaginationConfig,
  type ColumnFilterItem,
  type ColumnType,
  type FilterConfirmProps,
  type FilterValue,
  type SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'
import {SearchOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import type {TableRowSelection} from 'antd/es/table/interface'
import {
  getNewUsers,
  getTemplateSendingBluee,
  getUserUsers,
  sendEmailBlueTemplate,
} from '../../../../modules/apps/users/core/_requests'

import Swal from 'sweetalert2'
import {useQuery} from 'react-query'
import {relative} from 'path'
import moment, {Moment} from 'moment'
import {RangeValue} from 'rc-picker/lib/interface'
import {DatePicker} from 'antd'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {getUserProfile} from '../../../../modules/apps/user/core/_requests'
import {getAllStaff} from 'app/modules/apps/staff/core/_requests'
import {getProductsTableFilter} from 'app/modules/apps/cart/core/products/_requests'
import Column from 'antd/lib/table/Column'

const {RangePicker} = DatePicker

interface IProps {
  search: IQueryUsers
  searchFilter: IQueryUsersFilter
  setSearch: (search: IQueryUsers) => void
  searchQuery: IQueryUsers
}

const UserList: React.FC<IProps> = ({search, searchFilter, setSearch, searchQuery}) => {
  const {Column} = Table
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {getUsersNewQuery, users, selectUser, isLoadingUsers} = rootStore.usersStore
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRowEmail, setSelectedRowEmail] = useState<string[]>([])
  const [templateId, setTemplateId] = useState('')
  const [loading, setLoading] = useState(false)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Users>>({})
  const [lastServiceInfo, setLastServiceInfo] = useState<ColumnFilterItem[]>()
  const [filteredInfo, setFilteredInfo] = useState({
    nationalitie: [],
    countryOfOrigin: [],
    last_service: [],
    last_service_date_jotform_name: [],
  })
  const [countryOfOriginInfo, setCountryOfOriginInfo] = useState<ColumnFilterItem[]>()
  const [nationalityInfo, setNationalityInfo] = useState<ColumnFilterItem[]>()
  const [staffInfo, setStaffInfo] = useState<ColumnFilterItem[]>()
  const [productInfo, setProductInfo] = useState<ColumnFilterItem[]>()
  const [sbData, setSbData] = useState<Array<{value: string; label: string}>>([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [ifDataFiltered, setIfDataFiltered] = useState<Users[]>([])

  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentCountryOfOrigin, setCurrentCountryOfOrigin] = useState([])
  const [currentCountryOfNationality, setCurrentCountryOfNationality] = useState([])
  const [currentStaffName, setCurrentStaffName] = useState([])
  const [currentLastService, setCurrentLastService] = useState([])

  const {data: sendingBlueData, refetch} = useQuery(
    'getAllSendingBlueTemplates',
    async () => await getTemplateSendingBluee()
  )
  const {data: StaffData} = useQuery('getStf', async () => await getAllStaff())

  const {data: productData} = useQuery('getPro', async () => await getProductsTableFilter())

  const [searchDate, setSearchDate] = React.useState<IQueryUsers>(initialIQueryUsers)

  const handleSearchDatePicker = (fromDate: Moment, toDate: Moment) => {
    setCurrentPage(1)
    setSearch({
      ...searchQuery,
      startDate: moment.utc(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      endDate: moment.utc(toDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      page: currentPage,
    })
  }

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }
  // menu dropdown sending blue

  const handleSendTamplate = async (templateId: string, selectedEmails: React.Key[]) => {
    if (templateId === '' || selectedEmails.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'Por favor seleccione un usuario y un template',
        icon: 'error',
        confirmButtonText: 'Regresar',
      })
    } else {
      await sendEmailBlueTemplate(selectedEmails, templateId).then(async (response) => {
        Swal.fire({
          title: 'Acción exitosa',
          text: '',
          icon: 'success',
          confirmButtonText: 'Regresar',
        })
      })
    }
  }

  const handleSelectTemplate = (value: string) => {
    setTemplateId(value)
  }
  // end dropdown sending blue

  const searchInput = useRef<InputRef>(null)

  type DataIndex = keyof Users

  /* const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  } */

  const handleSearchCustom = (type: string, value: string) => {
    setCurrentPage(1)
    if (type === 'email') {
      setCurrentEmail(value)
    }
  }

  useEffect(() => {
    if (sendingBlueData != null) {
      setSbData(
        sendingBlueData.map((template) => {
          return {
            value: template.id.toString(),
            label: '#' + `${template.id.toString()} ` + template.name,
          }
        })
      )
    }
  }, [sendingBlueData])

  useEffect(() => {
    if (users != null && users?.data != null) {
      setLastServiceInfo(
        users.data?.map((item) => {
          if (item.last_service !== undefined) {
            return {
              text: item.last_service,
              value: item.last_service,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )

      const fetchData = async () => {
        try {
          const response = await fetch('https://surveyjs.io/api/CountriesExample')
          const data = await response.json()
          // Update the state with the fetched data
          setCountryOfOriginInfo(
            data?.map((item: any) => {
              if (item.name !== undefined) {
                return {
                  text: item.name,
                  value: item.name,
                }
              } else {
                return {
                  text: '',
                  value: '',
                }
              }
            })
          )

          setNationalityInfo(
            data?.map((item: any) => {
              if (item.name !== undefined) {
                return {
                  text: item.name,
                  value: item.name,
                }
              } else {
                return {
                  text: '',
                  value: '',
                }
              }
            })
          )
        } catch (error) {
          // Handle error
          console.error('Error fetching country of origin info:', error)
        }
      }
      fetchData()
      /* setCountryOfOriginInfo(
        users.data?.map((item) => {
          if (item.countryOfOrigin !== undefined) {
            return {
              text: item.countryOfOrigin,
              value: item.countryOfOrigin
            }
          } else {
            return {
              text: '',
              value: ''
            }
          }
        })
        ) */

      setStaffInfo(
        StaffData?.map((item: any) => {
          if (item.jotformName !== undefined) {
            return {
              text: item.jotformName,
              value: item.jotformName,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )

      setLastServiceInfo(
        productData?.data?.map((item: any) => {
          if (item.name !== undefined) {
            return {
              text: item.name,
              value: item.name,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }

    if (users !== undefined && users?.data !== undefined) {
      setIfDataFiltered(users.data)
    }
  }, [users])

  useEffect(() => {
    getUsersNewQuery({
      limit: 50,
      page: currentPage,
      email: currentEmail,
      startDate: moment.utc(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      endDate: moment.utc(toDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
      countryOfOrigin: currentCountryOfOrigin,
      nationalitie: currentCountryOfNationality,
      staffName: currentStaffName,
      lastService: currentLastService,
    })
  }, [
    search,
    currentPage,
    currentEmail,
    currentCountryOfOrigin,
    currentCountryOfNationality,
    currentStaffName,
    currentLastService,
  ])

  const handleSelectUser = (user: Users) => {
    selectUser(user)
  }

  const onChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<Users> | SorterResult<Users>[],
    extra: TableCurrentDataSource<Users>
  ) => {
    setCurrentPage(pagination.current)
    console.log('params', pagination, filters, sorter, extra)
    if (filters.countryOfOrigin && filters.countryOfOrigin.length > 0) {
      setCurrentCountryOfOrigin(filters.countryOfOrigin as [])
    }

    if (filters.nationalitie && filters.nationalitie.length > 0) {
      setCurrentCountryOfNationality(filters.nationalitie as [])
    }

    if (
      filters.last_service_date_jotform_name &&
      filters.last_service_date_jotform_name.length > 0
    ) {
      setCurrentStaffName(filters.last_service_date_jotform_name as [])
    }

    if (filters.last_service && filters.last_service.length > 0) {
      setCurrentLastService(filters.last_service as [])
    }

    setIfDataFiltered(extra.currentDataSource)
    setFilteredInfo(filters)
    setSortedInfo(sorter as SorterResult<Users>)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  // start search by first name
  const getColumnSearchPropsName = (dataIndex: DataIndex): ColumnType<Users> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          /*     onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }} */
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            /*    onClick={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }} */
            size='small'
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters != null && handleReset(clearFilters)
            }}
            size='small'
            style={{width: 90}}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record: Users) =>
      record.firstName
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // end search by first name

  // start search by CountryOfNaciolality

  const getColumnSearchPropsCountryOfNaciolality = (dataIndex: DataIndex): ColumnType<Users> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          /* onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }} */
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              /* handleSearch(selectedKeys as string[], confirm, dataIndexxx)  */
            }}
            size='small'
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters != null && handleReset(clearFilters)
            }}
            size='small'
            style={{width: 90}}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record: Users) =>
      record.countryOfOrigin
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  // end search by CountryOfNaciolality

  // start search by Naciolality
  const getColumnSearchPropsNaciolality = (dataIndex: DataIndex): ColumnType<Users> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            /* handleSearch(selectedKeys as string[], confirm, dataIndex)  */
          }}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              /* handleSearch(selectedKeys as string[], confirm, dataIndex) */
            }}
            size='small'
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters != null && handleReset(clearFilters)
            }}
            size='small'
            style={{width: 90}}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record: Users) =>
      record.nationalitie
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // end search by Naciolality

  // start search by email
  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<Users> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setCurrentEmail('')
            }}
            size='small'
            style={{width: 90}}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record: Users) =>
      record.email
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // end search by email

  // start when a checkbox is selected to get the user id
  const onSelectChange = async (newSelectedRowKeys: React.Key[]) => {
    selectedRowEmail.length = 0
    setSelectedRowKeys(newSelectedRowKeys)
    newSelectedRowKeys.map(async (id) => {
      const userEmailSelected = await getUserProfile(id as unknown as string)
      selectedRowEmail.push(userEmailSelected.email)
    })
  }
  // end when a checkbox is selected to get the user id

  // start checkbox select all
  const handleSelect = (record: any, selected: any) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key])
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key)
        return [...keys.slice(0, index), ...keys.slice(index + 1)]
      })
    }
  }

  const toggleSelectAll = () => {
    if (users !== null && users.data !== null && users.data !== undefined) {
      setSelectedRowKeys((keys) =>
        keys.length === ifDataFiltered.length
          ? []
          : users !== null && users.data !== null && users.data !== undefined
          ? ifDataFiltered.map((r) => r.id as Key)
          : []
      )
    }
  }

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length > 0}
      indeterminate={
        users !== null &&
        users.data !== null &&
        users.data !== undefined &&
        selectedRowKeys.length > 0 &&
        selectedRowKeys.length < users.data.length
      }
      onChange={toggleSelectAll}
    />
  )
  // end  checkbox select all
  const rowSelection: TableRowSelection<Users> = {
    selectedRowKeys,
    type: 'checkbox',
    onChange: onSelectChange,
    fixed: false,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  }
  const hasSelected = selectedRowKeys.length > 0

  if (isLoadingUsers) {
    return (
      <>
        {' '}
        <div>
          <img src='/media/logos/logovid.gif' className='h-10px w-10px' alt='' />
        </div>
      </>
    )
  }

  const filterDropdown = (
    <div style={{padding: 8}}>
      <Checkbox.Group
        options={nationalityInfo?.map((option) => ({label: option.text, value: option.value}))}
        value={filteredInfo.nationalitie}
        style={{display: 'block'}}
      />
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 8}}>
        <Button type='primary' size='small'>
          Filtrar
        </Button>
        <Button size='small'>Limpiar</Button>
      </div>
    </div>
  )

  return (
    <>
      <div className='sendinblue'>
        <div className='flex flex-col float-right mb-2 '>
          <h1 className='self-center font-bold' style={{fontSize: '2rem'}}>
            Mensajes masivos
          </h1>
          <div className='flex flex-row  gap-1'>
            <p style={{fontSize: '1rem'}}>Templates:</p>
            <Select
              defaultValue=''
              style={{width: 320, display: 'flex'}}
              onChange={handleSelectTemplate}
              options={sbData}
            />
            <Button
              onClick={async () => {
                await handleSendTamplate(templateId, selectedRowEmail)
              }}
            >
              Enviar
            </Button>
          </div>
        </div>
      </div>
      {currentCountryOfOrigin.length > 0 ||
      currentCountryOfNationality.length > 0 ||
      currentStaffName.length > 0 ||
      currentLastService.length > 0 ? (
        <>
          {' '}
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentCountryOfOrigin.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentCountryOfOrigin([])
                    filteredInfo.countryOfOrigin = []
                  }}
                >
                  <p>Pais de Residencia</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentCountryOfOrigin.toString()}
                  readOnly
                ></input>{' '}
                {/* {currentCountryOfOrigin.map((item) => item + " ")} */}
              </div>
            )}

            {currentCountryOfNationality.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentCountryOfNationality([])
                    filteredInfo.nationalitie = []
                  }}
                >
                  <p>Pais de Nacionalidad</p> <p>X</p>
                </Button>
                <input
                  title='nationality'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentCountryOfNationality.toString()}
                  readOnly
                ></input>
              </div>
            )}

            {currentLastService.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentLastService([])
                    filteredInfo.last_service = []
                  }}
                >
                  <p>Ultimo servicio</p> <p>X</p>
                </Button>
                <input
                  title='last services'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentLastService.toString()}
                  readOnly
                ></input>
              </div>
            )}
            {currentStaffName.length > 0 && (
              <div style={{display: 'flex'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentStaffName([])
                    filteredInfo.last_service_date_jotform_name = []
                  }}
                >
                  <p>Nombre del staff</p> <p>X</p>
                </Button>
                <input
                  title='staff'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentStaffName.toString()}
                  readOnly
                ></input>
              </div>
            )}
          </div>
        </>
      ) : null}

      <Table
        style={{overflow: 'auto', position: 'relative'}}
        scroll={{x: 'max-content', y: 500}}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: users?.count || 0,
        }}
        dataSource={users?.data}
        rowKey='id'
        onChange={onChange}
      >
        <Column
          key='data'
          render={(index: Users) => (
            <>
              <Button
                onClick={() => {
                  handleSelectUser(index)
                }}
              >
                {intl.formatMessage({id: 'CLIENTS.PROFILE'})}
              </Button>
            </>
          )}
        />

        <Column
          key='Avatars'
          render={(record: Users) => (
            <>
              <AvatarProfileUsers
                image={
                  record.photoId
                    ? `${record.photoPath}/${record.photoId}-avatar-small.jpg`
                    : '/media/avatars/avatar.png'
                }
                size={25}
              />
            </>
          )}
        />

        <Column
          width={250}
          title='Name'
          key='name'
          /*  {...getColumnSearchPropsName('email')} */
          render={(record: Users) => <Space>{record.firstName + ' ' + record.lastName}</Space>}
        />

        <Column
          width={200}
          title='Email'
          dataIndex={'email'}
          key='email'
          {...getColumnSearchPropsEmail('email')}
          render={(email: string) => <>{email}</>}
        />
        <Column
          width={100}
          title='Edad'
          dataIndex={'age'}
          key='age'
          /*   sorter={(a: Users, b: Users) => Number(a.age) - Number(b.age)} */
          render={(age: string) => <>{age}</>}
        />

        <Column
          width={200}
          title='Pais de residencia'
          dataIndex={'countryOfOrigin'}
          key='countryOfOrigin'
          filters={countryOfOriginInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) => <>{record}</>}
        />

        <Column
          width={200}
          title='Nacionalidad'
          dataIndex={'nationalitie'}
          key='nationalitie'
          filters={nationalityInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            ;<Button>Filtrar</Button>
            return record === value.toString()
          }}
          /*   onFilterDropdownVisibleChange={() => {}}
          filterDropdown={filterDropdown} */
        />

        <Column
          width={200}
          title='Fecha de Creación'
          dataIndex='createdAt'
          key='creationDate'
          render={(date) => <span>{moment(date).format('YYYY-MM-DD')}</span>}
          filterIcon={(filtered) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
          )}
          filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
              <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleSearchDatePicker(fromDate!, toDate!)
                  }}
                  size='small'
                  style={{width: 90, marginRight: 8}}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDate(null)
                    setToDate(null)
                    handleSearchDatePicker(fromDate!, toDate!)
                  }}
                  size='small'
                  style={{width: 90}}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
        />

        <Column
          width={200}
          title='Ultimo servicio'
          dataIndex={'last_service'}
          key='last_service'
          filters={lastServiceInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) => <>{record}</>}
        />

        <Column
          width={200}
          title='Staff'
          dataIndex={'last_service_date_jotform_name'}
          key='last_service_date_jotform_name'
          filters={staffInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record === value.toString()
          }}
          render={(record: string) => <>{record}</>}
        />
      </Table>
    </>
  )
}

export default observer(UserList)
