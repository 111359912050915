export const contratation = {
  'MENU.ANSWERS': 'Contratation answers',
  'CONTRATATION.ANSWERSCONTAINER': 'Answers',
  'CONTRATATION.EMAIL': 'Email',
  'CONTRATATION.DATEANDHOUR': 'Date And Hour',
  'CONTRATATION.ANSWERS': 'Answers',
  'CONTRATATION.BACK': 'Back',
  'CONTRATATION.ANSWERSOF': 'Answers of ',
  'CONTRATATION.QUESTION': 'Question ',
}
