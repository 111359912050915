import {LoadingOutlined} from '@ant-design/icons'
import {Modal} from './style'
import React from 'react'
import {Spin} from 'antd'

const ModalLoader: React.FC = () => {
  return (
    <Modal>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin />} />
    </Modal>
  )
}

export default ModalLoader
