/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { format, differenceInHours } from 'date-fns';
import './style.css';
import Swal from 'sweetalert2';
import { deleteNote, editNote, getNotesByTranslationId, postNoteTranslation } from 'app/modules/apps/UserNotes/_requests';
import { RootStoreContext } from 'stores/rootStore';
import { useQuery } from 'react-query';

interface ManualTranslationModalProps {
  userInfo: any;
  visible: boolean;
  onCancel: () => void;
  onCreate: (values: any) => void;
  products: any;
  translationId: string;
}

const NotesModalTranslation: React.FC<ManualTranslationModalProps> = ({
  visible,
  onCancel,
  onCreate,
  products,
  userInfo,
  translationId,
}: ManualTranslationModalProps) => {
  const rootStore = useContext(RootStoreContext);
  const { user: userAUTH, staff: staffAUTH } = rootStore.authStore;
  const [note, setNote] = useState('');
  const [editedNote, setEditedNote] = useState<any>();
  const [editedNoteText, setEditedNoteText] = useState<any>('');
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [form] = Form.useForm();
  const [noteAdded, setNoteAdded] = useState(false);
  const module = 'translation';
  const { data: notes, refetch: refetchNotes } = useQuery(
    'nbt',
    async () => await getNotesByTranslationId(translationId)
  );

  useEffect(() => {
    console.log('Translation ID:', translationId);
    refetchNotes();
  }, [translationId]);

  const handleSubmit = async () => {
    try {
      await postNoteTranslation(translationId, staffAUTH, note);
      form.resetFields(['note']);
      setNoteAdded(true);
      setNote('');
      refetchNotes();
    } catch (error) {
      console.error("Error al enviar la nota:", error);
    }
  };

  useEffect(() => {
    refetchNotes();
    setNoteAdded(false);
  }, [translationId, noteAdded]);

  const handleEdit = async (id: string, editedText: string) => {
    try {
      await editNote(id, editedText);
      setEditedNote(undefined);
      setEditedNoteText('');
      refetchNotes();
      Swal.fire('Éxito', 'Nota editada correctamente', 'success');
    } catch (error) {
      console.error('Error editando la nota:', error);
      Swal.fire('Error', 'Hubo un problema al editar la nota', 'error');
    }
  };
  
  const handleDelete = async (id: any) => {
    try {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: 'No podrás revertir esta acción!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'No, cancelar!',
      });
  
      if (result.isConfirmed) {
        await deleteNote(id);
        refetchNotes();
        Swal.fire('Eliminada!', 'La nota ha sido eliminada.', 'success');
      }
    } catch (error) {
      console.error('Error eliminando la nota:', error);
      Swal.fire('Error', 'Hubo un problema al eliminar la nota', 'error');
    }
  };
  

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value.length <= 255) {
      setNoteAdded(false);
      setNote(value);
    }
  };

  const formatDate = (createdAt: string, updatedAt: string) => {
    const createdDate = new Date(createdAt);
    const updatedDate = new Date(updatedAt);
    const mostRecentDate = updatedDate > createdDate ? updatedDate : createdDate;
    const now = new Date();
    const hoursDifference = differenceInHours(now, mostRecentDate);
  
    if (hoursDifference > 24) {
      return format(mostRecentDate, "E. dd/MM/yyyy 'a las' HH:mm 'hrs'");
    } else {
      return `hace ${hoursDifference} hora${hoursDifference !== 1 ? 's' : ''}`;
    }
  };
  

  return (
    <div
      className={`fixed inset-0 overflow-hidden transform transition-transform ${visible ? 'translate-x-0' : 'translate-x-full'}`}
      style={{ zIndex: '9999' }}
    >
      <div className='absolute inset-0 bg-gray-500 opacity-75' onClick={onCancel}></div>
      <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
        <div className='relative w-screen main-holder ' style={{ maxWidth: '879px' }}>
          <div style={{ border: '1px solid white', borderRadius: '6px' }} className='flex flex-col bg-white shadow-xl overflow-y-scroll h-100'>
            <div className='flex-1 py-6 overflow-y-auto px-4 sm:px-6'>
              <button
                style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', padding: '10px 24px 10px 24px', height: 'auto', width: 'auto' }}
                onClick={onCancel}
                className='button2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  alt="Calendar"
                  className='meet-image2'
                  src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-black.svg'}
                />
                <b className='meet-text2'>VOLVER</b>
              </button>
              <div className='line-container-consult' style={{ width: '25rem' }}></div>
              <div style={{ display: 'flex', width: '100%', height: 'auto', gap: '5px', marginTop: '20px' }}>
                <img alt="Calendar" style={{ width: '30px', height: '30px' }} src='/media/icons/app/menu/booking.svg' />
                <h2 style={{ fontFamily: 'Lato', fontSize: '24px', fontWeight: '600' }} className='text-lg font-medium text-black'>
                  Notas del cliente {userInfo && userInfo.email}
                </h2>
              </div>
              <div className='sm:px-6 relative flex-1 px-4 mt-6'>
                {(!editedNote) && (
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                    <label style={{ fontWeight: '500', fontSize: '16px', fontFamily: 'Lato' }} className='label'>
                      Añade una nota de cliente:
                    </label>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        <textarea
                          placeholder='Escriba aquí...'
                          style={{ border: "1px solid", width: '543px', height: '100px', borderRadius: '6px', padding: '10px 16px 10px 20px' }}
                          value={noteAdded ? '' : note}
                          onChange={handleChange}
                        />
                        <div>{note.length}/255</div>
                      </div>
                      <button
                        className='button-ui button-tertiary next-button-input-notes'
                        onClick={handleSubmit}
                      >
                        Añadir nota
                      </button>
                    </div>
                  </div>
                )}
                {userInfo !== undefined && notes && notes.map((note: any) => (
                  <div key={note.id}>
                    {(editedNote && editedNote.id) ? (
                      <>
                        {note.id === editedNote.id && (
                          <div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                              <p style={{ fontFamily: 'Lato', fontSize: '12px', fontWeight: '700' }}>{note.staff.jotformName}</p>
                              <div style={{ fontSize: '12px', fontWeight: '400' }}>
  {formatDate(note.created_at, note.updated_at)}
</div>

                            </div>
                            <input
                              defaultValue={note.note}
                              style={{ border: "1px solid", width: '543px', height: '34px', borderRadius: '6px', padding: '10px 16px 10px 20px' }}
                              onChange={(e) => setEditedNoteText(e.target.value)}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <button
                                style={{ display: 'flex', width: '7rem', height: '2rem', gap: '5px', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => handleEdit(note.id, editedNoteText)}
                                className='button2'
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                              >
                                <img
                                  alt="Calendar"
                                  className='meet-image2'
                                  src={isHovered2 ? '/media/icons/app/menu/icons8-check-50white.png' : '/media/icons/app/menu/icons8-check-50.png'}
                                />
                                <b className='meet-text2'>Editar</b>
                              </button>
                              <button
                                style={{ display: 'flex', width: '7rem', height: '2rem', gap: '5px', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => {
                                  setEditedNote(undefined);
                                  setEditedNoteText('');
                                }}
                                className='button2'
                                onMouseEnter={() => setIsHovered3(true)}
                                onMouseLeave={() => setIsHovered3(false)}
                              >
                                <img
                                  style={{ width: '15px', height: '15px' }}
                                  alt="Calendar"
                                  className='meet-image2'
                                  src={isHovered3 ? '/media/icons/app/menu/icons8-x-50 (1).png' : '/media/icons/app/menu/icons8-x-50.png'}
                                />
                                <b className='meet-text2'>Cancelar</b>
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ display: 'flex' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <p style={{ fontFamily: 'Lato', fontSize: '12px', fontWeight: '700' }}>{note.staff.jotformName}</p>
                            <div style={{ fontSize: '12px', fontWeight: '400' }}>
  {formatDate(note.created_at, note.updated_at)}
</div>

                          </div>
                          <div style={{ marginLeft: '10px' }}>
                            {staffAUTH && staffAUTH.jotform_name === note.staff.jotformName && (
                              <>
                                <button onClick={() => setEditedNote(note)}>
                                  <img alt="Editar" style={{ width: '20px', height: '20px' }} src='/media/icons/app/menu/icons8-edit-50.png' />
                                </button>
                                <button onClick={() => handleDelete(note.id)}>
                                  <img alt="Eliminar" style={{ width: '20px', height: '20px' }} src='/media/icons/app/menu/trashcan.svg' />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        <div style={{ fontFamily: 'Lato', fontSize: '14px', fontWeight: '400', marginBottom: '1rem' }}>{note.note}</div>
                        <div className='line-container-consult' style={{ width: '27.5rem', marginTop: '20px', marginBottom: '20px' }}></div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '30px', marginRight: '30px' }}>
              {(editedNote && editedNote.id) ? null : (
                <button
                  onClick={onCancel}
                  className='button-ui button-primary'
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                >
                  <b className='meet-text3'>GUARDAR NOTA</b>
                  <img
                    alt="Calendar"
                    className='meet-image3'
                    src={isHovered2 ? '/media/icons/app/menu/icons8-check-50white.png' : '/media/icons/app/menu/icons8-check-50white.png'}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesModalTranslation;
