/* eslint-disable react-hooks/exhaustive-deps */
import { getAllStaff } from "app/modules/apps/staff/core/_requests"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { RootStoreContext } from "stores/rootStore"
import checkmark_white from '../../../pages/contratationApp/icons/checkmark_white.svg'

const SubstituteNotification: React.FC = () => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [showToast, setShowToast] = useState(false)
  const [staffOffline, setStaffOffline] = useState('')
  const { data: StaffData, status: StaffStatus } = useQuery('getStf', async () => await getAllStaff())

  useEffect(() => {
    if (StaffStatus === 'success') {
      StaffData.map((staffName: any) => {
        if (staffName.substitute !== null && staffName.substitute.id === user?.id) {
          setStaffOffline(staffName.user.firstName + ' ' + staffName.user.lastName)
          setShowToast(true)
        }
      })
    }

  }, [StaffData])

  return (
    <>
      {showToast === true ?
        <div className='substitute-toast-container'>
          <div className='substitute-toast-style'>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            <div>
              <p style={{margin:'0'}}>Se te asignó la suplencia de</p>
              <p style={{margin:'0'}}> <b>{staffOffline}</b> </p>
            </div>
          </div>
        </div> : null}
    </>
  )
}

export { SubstituteNotification }
