import { useEffect, useState } from "react";
import PostCard from '../icons/postcard (1).svg';

interface IProps {
  prod: any
}

const MyContractConsultor = (props: IProps) => {
  const [finalProducts, setFinalProducts] = useState<any[]>([]);
  const { prod } = props;

  useEffect(() => {
    if (finalProducts.length === 0) {
      const matterProducts = JSON.parse(prod.contracts_users_applicants);
      const groupedProducts: any = {};
      matterProducts.forEach((applicant: any) => {
        const key = `${applicant.memberName}-${applicant.memberType}`;
        if (!(key in groupedProducts)) {
          groupedProducts[key] = [];
        }
        groupedProducts[key].push(applicant.productName);
      });
      const finalProductsArray = Object.entries<string[]>(groupedProducts).map(([key, products]) => {
        const [memberName, memberType] = key.split('-');
        return {
          memberName,
          memberType,
          products
        };
      });
      setFinalProducts(finalProductsArray);
    }
  }, [finalProducts.length, prod.contracts_users_applicants]);


  return (
    <div className='my-contract-wraper'>
      <div className='my-contract-container-wraper'>
        <div>
          <div className='my-contract-products-list' style={{ width: '100%' }} >
            {finalProducts && finalProducts.length > 0 && finalProducts.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <ul style={{ width: '100%' }}>
                    <li style={{
                      borderBottom: '1px solid #DFE4EA',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      listStyle: 'none'
                    }}>
                      <p style={{
                        color: '#111928',
                        margin: '0',
                        marginBottom: '20px'
                      }}>
                        <b>{item.memberName}</b> &nbsp; ({item.memberType})</p>
                      <ul style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                      }}>
                        {item.products && item.products.length > 0 &&
                          item.products.map((product: string, index: number) => (
                            <li key={index} style={{ listStyle: 'none' }}>
                              <div style={{ gap: '20px' }}
                                className='flex flex-row gap-2 items-start'>
                                <img src={PostCard}
                                  alt='PostCard' />
                                <p style={{ color: '#4B5563', margin: '0' }}>
                                  {product}
                                </p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyContractConsultor;
