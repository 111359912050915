/* eslint-disable @typescript-eslint/no-unused-vars */
import {type Services, type ServicesQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Response} from '../../../../../core/helpers'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'

const API_URL = process.env.REACT_APP_API_URL
const SERVICES_URL = `${API_URL}/orderdetails`

const getServices = async (query: string): Promise<ServicesQueryResponse> => {
  return await axios
    .get(`${SERVICES_URL}?${query}`)
    .then((d: AxiosResponse<ServicesQueryResponse>) => {
      return d.data
    })
}

const updateServices = async (services: Services, id: string) => {
  return await axios
    .patch(`${SERVICES_URL}`, services)
    .then((response: AxiosResponse<Response<Services>>) => response.data)
    .then((response: Response<Services>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const getServicesAdmin = async (userId: string): Promise<ServicesQueryResponse> => {
  return await axios
    .get(`${SERVICES_URL}/user/${userId}`)
    .then((d: AxiosResponse<ServicesQueryResponse>) => {
      return d.data
    })
}

export {getServices, updateServices, getServicesAdmin}
