import {Container} from '../../../../components/Container/index'
import {useIntl} from 'react-intl'
import {Link} from 'components'
import {useQuery} from 'react-query'
import {getStaff} from 'app/modules/apps/staff/core/_requests'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useContext} from 'react'

const MessagesWrapper = () => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {data: dataStaff} = useQuery(
    'getAllStaffss',
    async () => await getStaff(user?.id ? user.id : '')
  )
  const intl = useIntl()
  const {countMessagesNotRead: newMessages} = rootStore.messageStore
  return (
    <Container title={intl.formatMessage({id: 'MENU.MESSAGES'})}>
      <div className='flex gap-4 justify-center flex-wrap'>
        <Link
          className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
          to='/messages/imbox'
        >
          <img className='w-32' src='/media/svg/menu-icons/mensaje.svg' alt='clientes' />
          <h2 style={{margin: '0', padding: '0'}}>Buzon</h2>
          {newMessages && newMessages > 0 ? (
            <div
              className='animate-bounce w-4 h-4 ... flex justify-end ml-10'
              style={{left: '140px'}}
            >
              <div style={{top: '-5px', position: 'fixed', width: '4rem'}}>
                {/*  <img src='https://img.icons8.com/office/16/null/secured-letter--v1.png' style={{width:'12rem',height:'4rem'}} /> */}
                <span
                  className='text-right text-xs flex bg-tomato-500 rounded-full  text-white px-0.5 justify-center items-center m-auto z-9999 h-12 w-12 '
                  style={{left: '17px', top: '-170px', position: 'fixed', fontSize: '14px'}}
                >
                  {Number(newMessages)}
                </span>
              </div>
            </div>
          ) : null}
        </Link>

        {dataStaff &&
        user?.role?.id === 1 &&
        (dataStaff[0].type === 'intake' ||
          dataStaff[0].type === 'intake_admin' ||
          dataStaff[0].type === 'translate' ||
          dataStaff[0].type === 'translate_admin') ? (
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/user/support'
          >
            <img className='w-32' src='/media/svg/menu-icons/rrecruitment_services.svg' alt='' />
            <h2>Chat en vivo</h2>
          </Link>
        ) : (
          <></>
        )}
      </div>
    </Container>
  )
}

export default MessagesWrapper
