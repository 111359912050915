 

import {AiFillEye, AiOutlineCloudDownload, AiTwotoneDelete} from 'react-icons/ai'
import {Button, Space, Alert, Modal} from 'antd'
import {Table, TableBody, TableCell, TableHead, TableRow} from '../../../../components'
import React, {useState} from 'react'
import {type Translations} from '../../../../app/modules/apps/translations/core/_models'
import './style.css'

interface IProps {
  data: Translations[]

  allowDelete?: boolean
  downloadFile: (id: string) => void
  previewFile: (id: string) => void
  deleteFile?: (id: string) => void
}

const ListFilesExtra: React.FC<IProps> = ({
  data,
  allowDelete,
  downloadFile,
  previewFile,
  deleteFile,
}) => {
  const handleDownload = (id: string) => {
    downloadFile(id)
  }

  const handleDeleteFile = (id: string) => {
    deleteFile != null && deleteFile(id)
  }

  const [popupVisible, setPopupVisible] = useState(false)
  const [popupContent, setPopupContent] = useState('')

  const renderStatusMessage = (status: string | null) => {
    if (status === 'Assigned') {
      return <Alert message='Tu traducción ya ha sido asignada a un asesor.' type='info' showIcon />
    } else if (status === 'In Progress') {
      return <Alert message='Tu traducción está parcialmente terminada.' type='info' showIcon />
    } else if (status === 'Completed') {
      return (
        <Alert
          message='Tu traducción está lista! Puedes descargar los archivos en el icono de abajo'
          type='success'
          showIcon
          className='latido'
        />
      )
    } else if (status === 'Pending assignation') {
      return (
        <Alert
          message='Traducción pendiente! Click para mas info'
          type='info'
          showIcon
          onClick={() => {
            setPopupVisible(true)
            setPopupContent(
              'Tenga en consideración que los tiempos de entrega de las traducciones Express es de 1 a 5 días hábiles. Su traductor asignado se contactará con usted a través de la misma plataforma en caso de necesitar información adicional para completar el servicio. ¡Muchas gracias por preferirnos!'
            )
          }}
        />
      )
    } else {
      return null
    }
  }

  return (
    <>
      {renderStatusMessage(data[0]?.status ?? '')}{' '}
      {/* Mostrar el mensaje de acuerdo al primer registro de data */}
      <Modal
        visible={popupVisible}
        onCancel={() => setPopupVisible(false)}
        footer={null}
        centered
        style={{fontSize: '180px'}}
      >
        {popupContent}
      </Modal>
      <Table className='responsive-table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Space>Descargar</Space>
            </TableCell>
            <TableCell>
              <Space>Archivos</Space>
            </TableCell>
            <TableCell>
              <Space>Status</Space>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((record, index) =>
              record?.originalname?.includes('ImmilandExtraDocument') ? (
                <TableRow key={index}>
                  <TableCell>
                    <Space>
                      <Button
                        onClick={() => {
                          handleDownload(record.fileId || '')
                        }}
                        style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                      >
                        <AiOutlineCloudDownload />
                      </Button>
                    </Space>
                  </TableCell>
                  <TableCell> {record.originalname}</TableCell>
                  <TableCell>{record.status}</TableCell> {/* Mostrar el status */}
                  <TableCell>
                    <Space>
                      <Button
                        onClick={() => {
                          previewFile(record.fileId || '')
                        }}
                        style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                      >
                        <AiFillEye />
                      </Button>
                    </Space>
                  </TableCell>
                  <TableCell>
                    {allowDelete && (
                      <Space>
                        <Button
                          onClick={() => {
                            handleDeleteFile(record.fileId || '')
                          }}
                          style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                        >
                          <AiTwotoneDelete />
                        </Button>
                      </Space>
                    )}
                  </TableCell>
                </TableRow>
              ) : null
            )}
        </TableBody>
      </Table>
    </>
  )
}

export default ListFilesExtra
