import {type ID, type Response} from '../../../../../core/helpers'

export interface ApiCalls {
  id?: ID
  data?: any | null
  idcall?: string | null
  completed?: boolean | null
  verified?: boolean | null
  ignored?: boolean | null
  updated_at?: Date | null
  created_at?: Date | null
  is_verifying?: boolean | null
  error?: string | null
}

export type ApiCallsQueryResponse = Response<ApiCalls[]>

type toFormValuesType = (apiData: ApiCalls) => ApiCalls

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id || '',
  data: apiData.data || null,
  idcall: apiData.idcall || null,
  completed: apiData.completed || false,
  verified: apiData.verified || false,
  ignored: apiData.ignored || false,
  updated_at: apiData.updated_at != null ? new Date(apiData.updated_at) : new Date(),
  created_at: apiData.created_at != null ? new Date(apiData.created_at) : new Date(),
  is_verifying: false,
  error: '..',
})

export const apiCallsInitValues: ApiCalls = {
  data: {},
  completed: false,
  idcall: '',
  verified: false,
  ignored: false,
  updated_at: new Date(),
  created_at: new Date(),
}

export interface IQueryApiCalls {
  startDate?: Date | null
  endDate?: Date | null
  page?: number | null
  limit?: number | null
  data?: string
  completed?: boolean
  verified?: boolean
  ignored?: boolean
}

export const apiCallsQueryInitValues: IQueryApiCalls = {
  data: '',
  completed: false,
  verified: false,
  ignored: false,
}
