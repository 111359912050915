 

import {Route, Routes} from 'react-router-dom'
import CategoryWrapper from '../Category'
import CheckOut from '../CheckOut'
import LayoutEmbed from '../LayoutEmbed'
import PaymentSucess from '../CheckOut/Steps/Payment/PaymentSucess'
import ProductCard from '../Product/ProductCard'
import CheckOutResumeWO from '../CheckOut/Steps/Payment/CheckOutResumeWO'

const CartRoutesEmbed = () => {
  return (
    <Routes>
      <Route element={<LayoutEmbed />}>
        <Route path='checkout' element={<CheckOut />} />
        <Route path='category/:categorySlug' element={<CategoryWrapper />} />
        <Route path='payment/sucess/:code' element={<PaymentSucess />} />
        <Route path='payment/sucesswo' element={<CheckOutResumeWO />} />
        <Route path='product/:id' element={<ProductCard />} />
      </Route>
    </Routes>
  )
}

export default CartRoutesEmbed
