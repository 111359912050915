export const common = {
  'COMMON.NAME': 'Nombre',
  'COMMON.ACTION': 'Acciones',
  'COMMON.ADD': 'Añadir',
  'COMMON.BACK': 'Regresar',
  'COMMON.SEARCH': 'Buscar',
  'COMMON.REMOVE': 'Eliminar',
  'COMMON.SAVE': 'Guardar',
  'COMMON.CANCEL': 'Cancelar',
  'COMMON.EDIT': 'Editar',
  'COMMON.DELETE': 'Eliminar',
  'COMMON.CLOSE': 'Cerrar',
  'COMMON.SELECT': 'Seleccionar',
  'COMMON.DATETIME': 'Fecha y hora',
  'COMMON.URLMEETING': 'Url de la reunión',
  'COMMON.BTNSAVEUSER': 'Guardar usuario',
}
