/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AdminAppointmentsWrapper } from '../../app/pages/appointments/AdminAppointmentsWrapper';
import ApiCalls from '../../app/pages/admin/apicalls';
import { AppointmentsWrapper } from '../../app/pages/appointments/AppointmentsWrapper';
import CategoryWrapper from '../../app/pages/admin/categories/CategoryWrapper';
import CreateAppointment from '../../app/pages/appointments/Create';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import EmbedImmigration from '../../app/pages/Cart/ImmigrationServices/EmbedImmigration';
import { FileManagerWrapper } from '../../app/pages/fileManager/FileManagerWrapper';
import { FormsManagerWrapper } from '../../app/pages/formsManager/FormsManagerWrapper';
import GeneralMessagesWrapper from '../../app/pages/messages/GeneralMessages/GeneralMessagesWrapper';
import ImmigrationRoutes from './ImmigrationRoutes';
import ImmigrationServices from '../../app/pages/Cart/ImmigrationServices';
import LibraryWrapper from '../../app/pages/library/LibraryWrapper';
import MasterLayout from '../../core/layout/MasterLayout';
import MessagesWrapper from '../../app/pages/messages/UserMessages/MessagesWrapper';
import ProductWrapper from '../../app/pages/admin/products/ProductWrapper';
import { ServicesWrapper } from '../pages/services/ServicesWrapper';
import UserServicesWrapper from '../pages/services/UserServicesWrapper';
import Settings from '../../app/modules/accounts/components/settings/Settings';
import { SuperAdminAppointments } from '../../app/pages/appointments/SuperAdminAppointments';
import { TemplateManagerWrapper } from '../../app/pages/templateManager/TemplateManagerWrapper';
import { TranslationsWrapper } from '../../app/pages/translations/TranslationsWrapper';
import { TranslationsWrapperAdmin } from '../../app/pages/translations/admin/TranslationsWrapperAdmin';
import { TranslationsWrapperStaff } from '../../app/pages/translations/staff/TranslationsWrapperStaff';
import UserCart from '../../app/pages/Cart/UserCart';
import UserSupport from '../../app/pages/UserSupport';
import UserWrapper from '../../app/pages/admin/users/UserWrapper';
import ChangeUserPassword from '../../app/pages/user/changePassword/ChangeUserPassword';
import { AdminToolsWrapper } from '../../app/pages/admin/adminNavTools/adminToolsWrapper';
import { AdminStoreWrapper } from '../../app/pages/admin/adminNavTools/adminStoreWrapper';
import { ContratationAppAnswers } from '../../app/pages/contratationApp/contratacionAnswers/ContratationAppAnswers';
import { AdminTranslateWrapper } from '../../app/pages/admin/adminNavTools/adminTranslateWrapper';
import { ShowContracts } from '../../app/pages/contratationApp/showcompletedcontracts/showContracts';
import { ContratationMenu } from '../../app/pages/contratationApp/contratationmenu';
import WrapperUserContratation from '../../app/pages/contratationApp/wrapperUserContratations';
import { RootStoreContext } from '../../stores/rootStore';
import EmbedCalendly from '../pages/Cart/ImmigrationServices/calendar';
import UsersListPlanetaJuan from '../../core/layout/components/aside/MenuUser/planetaJuanUserList';
import SendinblueTemp from 'app/pages/admin/users/SendinblueTemp';
import { ProfileImmigration } from 'app/modules/apps/profile/core/_models';
import { IApointments, IComposeData } from 'app/modules/apps/appointments/core/_models';
import CuponsWrapper from 'app/pages/admin/cupons/CuponsWrapper';
import EducationServices from '../../app/pages/Cart/ImmigrationServices/EducationServices';
import EmbedCal from 'app/pages/Cart/ImmigrationServices/embedCal';
import EduOptions from 'app/pages/Cart/ImmigrationServices/eduoptions';
import { getUserUsersById, sendEmailImm } from 'app/modules/apps/users/core/_requests';
import { useQuery } from 'react-query';
import ConsultorSelectorFree from 'app/pages/Cart/ImmigrationServices/consultorSelectorfree';
import DocumentEditor from 'app/pages/contratationApp/newUserViewContratationApp/uploaderDocuments/EditorDocuments';
import { ClientsWrapper } from 'app/pages/clients/ClientsWrapper';
import AppointmentByConsultor from 'app/pages/appointments/SuperAdminAppointments/AppointmentByConsultor';
import UserMessages from 'app/pages/messages/UserMessages';
import { IntakeAdmin } from 'app/pages/intakeadmin/IntakeAdmin';
import { AppointmentDetailsTimeline } from 'app/pages/appointments/adminappointments/Appointmenttimeline';
import { EducationWrapper } from 'app/pages/admin/adminNavTools/EducationWrapper';
import InstitutionTable from 'app/pages/admin/products/ProductManager/educationinstWrapper';
import ProgramTable from 'app/pages/admin/products/ProductManager/educationProgramWrapper';
import AplicationTable from 'app/pages/admin/products/ProductManager/educationAplicationWrapper';
import AplicationTableByUser from 'app/pages/admin/products/ProductManager/educationAplicationByUser';
import { DashboardLogger } from 'app/pages/admin/adminNavTools/adminLoggerWrapper';
import { ConsultantClientList } from 'app/pages/intakeadmin/ConsultantClienteList';
import TimelineComponent from 'app/pages/user/newtimeline';
import CustomerFeedbackForm from 'app/pages/services/satisfaction';
import { PreEvaluation } from 'app/pages/pre-evaluation/preEvaluation';
import { TranslationsWrapperSatisfaction } from 'app/pages/translations/admin/TranslationSatisfaction';
import { observer } from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css'; // Aquí se define el estilo personalizado
import CustomToastContainer from './customToastContainer';
import NotifyDashboard from '../pages/contratationApp/newUserViewContratationApp/notificationDashboard';
import ShowAnswersConsultant from 'app/pages/services/services/supportDocumentsClient/ShowAnswersConsultant';
interface Props {
  ProfileImmigration: ProfileImmigration;
  composepostdata: IComposeData;
}

interface Toast {
  message: string;
}

const PrivateRoutes: React.FC<Props> = ({ ProfileImmigration, composepostdata }) => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;
  const { currentUser } = rootStore.usersStore;
  const { socket, disconnectSocket } = rootStore.notificationStore;
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [activeKey, setActiveKey] = useState(false)
  const [contractSelected, setContractSelected] = useState('')

  const { data } = useQuery('getUserInfo', async () => await getUserUsersById(user ? user.id : ''), {
    enabled: user !== undefined,
    cacheTime: 0,
  });
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1; // Months are zero-based
  const day = today.getDate();
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  const initialDate = {
    startDate: formattedDate,
    endDate: '',
    page: 1,
    limit: 100,
    search: '',
    consultantId: '',
    email: '',
  };

  const [query, setQuery] = useState<IApointments>(initialDate);
  
  const notify = (message: string) => {
    setToasts((prevToasts) => [...prevToasts, { message }]);
  };

  const removeToast = (index: number) => {
    setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
  };

  const handleNewNotification = useCallback(async (notificationData: { data: { author_id: string | undefined; message: string; }; }) => {
    if (user?.id === notificationData.data.author_id) {
        notify(notificationData.data.message);
        // Enviar correo electrónico al consultor
        if (data?.email) {
            try {
                const response = await sendEmailImm(data.email, notificationData.data.message);
                console.log('Correo enviado con éxito:', response);
            } catch (error) {
                console.error('Error al enviar el correo:', error);
            }
        }
    }
}, [user?.id, data?.email, notify]);

useEffect(() => {
    if (socket) {
        socket.on('connect', () => {
            console.log('connected to server');
            socket.on('server:newnotification', handleNewNotification);
        });

        return () => {
            socket.off('server:newnotification', handleNewNotification);
        };
    }
}, [socket, user?.id, handleNewNotification]);

  return (
    <>
 <CustomToastContainer toasts={toasts} removeToast={removeToast} />
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route
            path='dashboard'
            element={<DashboardWrapper ProfileImmigration={ProfileImmigration} />}
          />
          <Route path='admin/logger' element={<DashboardLogger />} />
          <Route path='user/notify' element={<NotifyDashboard user={user}  />} />
          <Route path="/show-answers-consultant/:envelopeId" element={<ShowAnswersConsultant setActiveKey={setActiveKey} contractsFound={contractSelected} userEmail={currentUser?.email} goToResumen={function (): void {
            throw new Error('Function not implemented.');
          } }/>  } />
          <Route path='services' element={<ServicesWrapper />} />
          <Route path='pre-evaluation' element={<PreEvaluation />} />
          <Route path='user/services' element={<UserServicesWrapper user={user} />} />
          <Route
            path='timeline'
            element={<TimelineComponent user={data} email={''} timelineUserId={''} />}
          />
          <Route
            path='timelineadmins'
            element={
              <AppointmentDetailsTimeline ProfileImmigration={ProfileImmigration} staffId={''} />
            }
          />
          <Route
            path='services/satisfaction/:translationId'
            element={<CustomerFeedbackForm user={user} translationId={undefined} />}
          />
          <Route
            path='timelineadminsnew'
            element={<TimelineComponent email={''} user={undefined} timelineUserId={''} />}
          />
          <Route path='appointmentsbyconsultant' element={<SuperAdminAppointments />} />
          <Route
            path='appointments'
            element={<AppointmentsWrapper ProfileImmigration={ProfileImmigration} />}
          />
          <Route path='consultantappointments' element={<AdminAppointmentsWrapper />} />
          <Route path='files' element={<FileManagerWrapper />} />
          <Route path='templates' element={<TemplateManagerWrapper />} />
          <Route path='messages' element={<MessagesWrapper />} />
          <Route path='generalmessages' element={<GeneralMessagesWrapper />} />
          <Route path='translation' element={<TranslationsWrapper />} />
          <Route
            path='translationadmin'
            element={<TranslationsWrapperAdmin ProfileImmigration={ProfileImmigration} />}
          />
          <Route
            path='translationstaff'
            element={<TranslationsWrapperStaff ProfileImmigration={ProfileImmigration} />}
          />
          <Route
            path='translationstaffsatisfaction'
            element={<TranslationsWrapperSatisfaction ProfileImmigration={ProfileImmigration} />}
          />
          <Route path='userslist' element={<UserWrapper ProfileImmigration={ProfileImmigration} />} />
          <Route
            path='myclients/list'
            element={<ConsultantClientList currentUser={currentUser ? currentUser : null} />}
          />
          <Route
            path='templatesblue'
            element={<SendinblueTemp ProfileImmigration={ProfileImmigration} />}
          />
          <Route path='formsmanager' element={<FormsManagerWrapper />} />
          <Route path='services/contracts/answers' element={<ContratationAppAnswers />} />
          <Route path='services/contracts/completed' element={<ShowContracts />} />
          <Route path='admin/store/categories' element={<CategoryWrapper />} />
          <Route path='admin/store/cupons' element={<CuponsWrapper />} />
          <Route path='admin/apicalls' element={<ApiCalls />} />
          <Route path='admin/createappointment' element={<CreateAppointment />} />
          <Route path='admin/store/products' element={<ProductWrapper />} />
          <Route
            path='myvisa/proccess'
            element={<WrapperUserContratation email={user?.email ? user.email : ''} />}
          />
          <Route path='education' element={<EducationWrapper />} />
          <Route path='admin/store/Eduproducts' element={<InstitutionTable />} />
          <Route path='admin/store/Eduprogram' element={<ProgramTable />} />
          <Route path='admin/store/Apliprogram' element={<AplicationTable />} />
          <Route
            path='admin/store/Apliprogrambysers'
            element={<AplicationTableByUser user={currentUser ? currentUser : undefined} />}
          />
          <Route path='library' element={<LibraryWrapper />} />
          <Route path='/*' element={<ImmigrationRoutes />} />
          {/* <Route path='/user/support' element={<NewChat />} /> */}
          <Route path='admin/tools' element={<AdminToolsWrapper />} />
          <Route path='admin/store' element={<AdminStoreWrapper />} />
          <Route path='admin/translate' element={<AdminTranslateWrapper />} />
          <Route path='services/contracts/menu' element={<ContratationMenu />} />
          <Route path='education' element={<></>} />
          <Route path='userlist/planetajuan' element={<UsersListPlanetaJuan />} />
          <Route path='doc-editor' element={<DocumentEditor />} />
          <Route path='clients' element={<ClientsWrapper />} />
          <Route
            path='appointmentsbyconsultant/list'
            element={<AppointmentByConsultor query={query} setQuery={setQuery} />}
          />
          <Route path='messages/imbox' element={<UserMessages />} />
          <Route path='admin/intake' element={<IntakeAdmin />} />
          {/* Lazy Modules */}
          <Route
            path='/crafted/account/settings'
            element={
              <>
                <Settings composepostdata={composepostdata} />
              </>
            }
          />
          <Route path='cart' element={<UserCart />} />
          <Route path='cart/immigrationservices' element={<ImmigrationServices />} />
          <Route path='cart/educationservices' element={<EducationServices />} />
          <Route
            path='cart/immigrationservicescontract'
            element={
              <EmbedImmigration
                link={'https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402'}
              />
            }
          />
          <Route path='cart/consultamigratoria' element={<EmbedCalendly link={''} />} />
          <Route path='cart/OpcionesEducativas' element={<ConsultorSelectorFree />} />
          <Route path='cart/embedcalendar' element={<EmbedCal />} />
          <Route path='cart/eduoption' element={<EduOptions />} />
          <Route path='support' element={<UserSupport />} />
          <Route path='changePassword' element={<ChangeUserPassword />} />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  );
};

export default observer(PrivateRoutes);
