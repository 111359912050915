import DialogComponent from '../../../../../components/Dialog'
import ListTranslationsService from './ListTranslationsService'
import React from 'react'
import ShowTranslationService from './ShowTranslationService'
import {type Translations} from '../../../../../app/modules/apps/translations/core/_models'

interface IProps {
  translationsUser: Translations[]
}
const TranslationService: React.FC<IProps> = ({translationsUser}) => {
  const [translation, setTranslation] = React.useState<Translations | null>(null)

  return (
    <>
      {translation != null ? (
        <DialogComponent
          title='Translation'
          isOpen={!!translation}
          closeModal={() => {
            setTranslation(null)
          }}
        >
          <ShowTranslationService translation={translation} setTranslation={setTranslation} />
        </DialogComponent>
      ) : (
        <>
          <ListTranslationsService
            translationsUser={translationsUser}
            setTranslation={setTranslation}
          />
        </>
      )}
    </>
  )
}

export default TranslationService
