
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { type Dispatch, type SetStateAction, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getFormsCompletedDataById } from 'app/modules/apps/formsdata/core/_requests'

interface IProps {
  name: string
  formIndex: number
  openForm: boolean
  setOpenForm: Dispatch<SetStateAction<boolean>>
  openFormCompleted: boolean
  setOpenFormCompleted: Dispatch<SetStateAction<boolean>>
  setFormIndex: Dispatch<SetStateAction<number>>
  setBarUpdate: Dispatch<SetStateAction<number>>
  barUpdate: number
  formCompletedId: string
  setFormCompletedID: Dispatch<SetStateAction<string>>
  setActiveRefech: Dispatch<SetStateAction<boolean>>
  setActivePorcentageBar: Dispatch<SetStateAction<boolean>>
  setIndex: Dispatch<SetStateAction<number>>
  userAllForms: any
  setMemberNameSelected: Dispatch<SetStateAction<string>>
  setMemberTypeSelected: Dispatch<SetStateAction<string>>
}

const DisplayFormCompleted: React.FC<IProps> = ({
  setOpenForm,
  setOpenFormCompleted,
  barUpdate,
  setBarUpdate,
  formCompletedId,
  setFormIndex,
  setFormCompletedID,
  setActiveRefech,
  setActivePorcentageBar,
  setIndex,
  userAllForms,
  setMemberNameSelected,
  setMemberTypeSelected
}) => {
  const [currentAnswers, setCurrentAnswers] = useState<any>(null)
  const {
    data: dataById,
    status: statusById,
    refetch,
  } = useQuery('listformsclientsbyid', async () => await getFormsCompletedDataById(formCompletedId))

  useEffect(() => { }, [currentAnswers])
  useEffect(() => {
    refetch()
  }, [formCompletedId, refetch])

  if (statusById === 'success' /* && currentAnswers === null */) {
    setTimeout(() => {
      setCurrentAnswers(dataById !== null ? dataById : null)
    }, 500)
  }

  if (statusById === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  if (statusById === 'error') {
    refetch()
  }

  return (
    <>
      {currentAnswers && currentAnswers !== undefined ? (
        <form className='form-style' style={{ marginTop: '3.5rem', padding: '1rem' }}>
          <h1 className='form-title' style={{ marginBottom: '2rem' }}>
            {currentAnswers.datafill.formName}
          </h1>
          {currentAnswers.datafill &&
            currentAnswers.datafill.questions.map((question: any) => (
              <>
                {question.answer !== '' ?
                  <div className='flex flex-col gap-4 w-full mb-4 justify-between'>
                    <>
                      {question.answer !== '' || question.multipleAnswer.length > 0 ? (
                        <b>{question.question}</b>
                      ) : null}
                    </>
                    {question.answer === '' ? null : <p className='input-styles-quetion'>{question.answer}</p>}
                    {question.multipleAnswer.length === 0 ? null : (
                      <div className='flex flex-col'>
                        {question.multipleAnswer.map((ans: any) => (
                          <p className='text-styles'>{ans}</p>
                        ))}
                      </div>
                    )}
                  </div>
                  : null}
              </>
            ))}
        </form>
      ) : (
        <> <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /></>
      )}
    </>
  )
}
export { DisplayFormCompleted }
