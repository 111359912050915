/* eslint-disable @typescript-eslint/no-unused-vars */
 

import React, {useEffect, useState} from 'react'
import {getTemplates} from '../../../../app/modules/apps/templates/core/_requests'
import {useQuery} from 'react-query'

interface Props {
  data?: string
  setContent?: React.Dispatch<React.SetStateAction<string>> | undefined
  setSubject?: React.Dispatch<React.SetStateAction<string>> | undefined
  setNewContent?: React.Dispatch<React.SetStateAction<string>> | undefined
}

interface dataOption {
  value: string
  text: string
  id: string
  dataTemplate: string
  category: string
}

const SearchTemplate: React.FC<Props> = ({setContent, setSubject, setNewContent}) => {
  const [query, setQuery] = useState('')
  const {data, refetch} = useQuery('gettemplates', async () => await getTemplates(query))
  const [options, setOptions] = useState([] as dataOption[])
  const [filteredOptions, setFilteredOptions] = useState([] as dataOption[])
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [category, setCategory] = useState('')

  useEffect(() => {
    if (data?.data != null) {
      const newOptions = data.data.map((item: any) => {
        return {
          text: item.id,
          id: item.id,
          value: item.name,
          dataTemplate: item.text,
          category: getCategory(item.name) // Assign category based on name
        }
      })
      setOptions(newOptions)
    }
  }, [data])

  useEffect(() => {
    // Filter options based on selected category
    if (category) {
      setFilteredOptions(options.filter(option => option.category === category))
    } else {
      setFilteredOptions(options)
    }
  }, [category, options])

  const getCategory = (name: string): string => {
    const immigrationIntakeCaseworking = [
  ""
    ]
    const immigrationConsultations = [
      "Encuesta de satisfacción", "Student Direct Stream", "Servicios Settlement ",
      "Visa emprendedor - siguientes pasos", "Express Entry Language Exam Preparation",
      "Cursos Idiomas ", "Notas adicionales cita, links y descuentos", "Contratación - Honorarios Profesionales",
      "Informacion para Medicos/ doctores", "Servicios de Oferta de Empleo",
      "LMIA pre assessment ", "Servicio de reconsideración", "Provincia de Newfoundland & Labrador",
      "Opciones Educativas - Conectando con Asesor Académico de Immiland ",
      "Provincia de Nueva Escocia ", "Provincia de British Columbia", "Provincia de  PEI",
      "Provincia de Ontario ", "Provincia de Nuevo Brunswick", "Provincia de Quebec",
      "Provincia de Manitoba", "Provincia de Saskatchewan", "Provincia de Alberta", "ATIP servicio ",
      "¿Que pasa después de la consulta? IMPORTANTE LEER", "Herramientas y tips para la via laboral - TLC  Colombia ",
      "Herramientas y tips para la via laboral - TLC  Peru", "Herramientas y tips para la via laboral - TLC  Chile",
      "Herramientas y tips para la via laboral - TLC Mexico", "Herramientas y tips para la via laboral - TLC  Panama",
      "Herramientas y tips para la via laboral - Programa Atlantico", "Herramientas y tips para la via laboral tracicional con LMIA "
    ]
    const education = [
     "Educacion - opciones educativas citas migratorias",
      "Educacion - opciones educativas consultas vocacionales"
    ]

    if (immigrationIntakeCaseworking.includes(name)) return 'Immigration - intake/caseworking'
    if (immigrationConsultations.includes(name)) return 'Immigration - Consultations'
    if (education.includes(name)) return 'Education'
    return ''
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    if (selectedValue === 'Sin plantilla' && setContent) {
      setContent('')
    }
    const selectedTemplate = filteredOptions.find((option) => option.value === selectedValue) || null
    setSelectedOption(selectedTemplate)

    if (setContent && selectedTemplate) {
      setContent(selectedTemplate.dataTemplate)
    }

    if (setNewContent && selectedTemplate) {
      setNewContent(selectedTemplate.dataTemplate)
    }
  }

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(event.target.value)
  }

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <select style={{ width: '100%', border: '1px solid rgb(230, 230, 230)', height: '32px' }} onChange={handleCategoryChange}>
        <option value="">Todas las categorías</option>
        <option value="Immigration - Consultations">Immigration - Consultations</option>
        <option value="Education">Education</option>
        <option value="Immigration - intake/caseworking">Immigration - intake/caseworking</option>

      </select>
      <select style={{ width: '100%', border: '1px solid rgb(230, 230, 230)', height: '32px' }} onChange={handleSelectChange} value={selectedOption?.value || ''}>
        <option value="Sin plantilla">- Sin template -</option>
        {filteredOptions.map((template) => (
          <option key={template.id} value={template.value}>
            {template.value}
          </option>
        ))}
      </select>
    </div>
  )
}

export {SearchTemplate}
