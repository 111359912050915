 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {GrEdit} from 'react-icons/gr'
import {Input, Space, Table, Button, InputRef, DatePicker} from 'antd'
import {type Templates} from '../../../../app/modules/apps/templates/core/_models'
import {getTemplatesManager} from '../../../../app/modules/apps/templates/core/_requests'
import {useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import Column from 'antd/lib/table/Column'
import {ColumnType} from 'antd/lib/table'
import {SearchOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import moment, {Moment} from 'moment'
import {RangeValue} from 'rc-picker/lib/interface'

interface IProps {
  callRefetch: boolean
  handleEdit?: any
}

const ListTemplates = (props: IProps) => {
  const [currentName, setCurrentName] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  const {data, status, refetch} = useQuery(
    'listtemplates',
    async () =>
      await getTemplatesManager(
        `limit=50&page=${currentPage}&name=${currentName}&startUpdatedDate=${moment
          .utc(fromDate)
          .format('YYYY-MM-DD HH:mm:ss.SSS')}&endUpdatedDate=${moment
          .utc(toDate)
          .format('YYYY-MM-DD HH:mm:ss.SSS')}`
      )
  )
  const {callRefetch, handleEdit} = props
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof Templates
  useEffect(() => {
    if (callRefetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [callRefetch])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [currentPage, currentName, fromDate, toDate])

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const handleSearchDatePicker = (fromDate: Moment, toDate: Moment) => {
    setCurrentPage(1)
    refetch()
  }
  const handleEditTemplate = (templateId: string) => {
    if (data != null) {
      const templateEdit = data.data?.filter((temp) => temp.id === templateId)[0] as Templates

      handleEdit(templateEdit)
    }
  }

  const handleTableChange = (pagination: any, filters: any) => {
    console.log('params', pagination, filters)
    setCurrentPage(pagination.current)
  }

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }

  const getColumnSearchPropsName = (dataIndex: DataIndex): ColumnType<Templates> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          /*   onPressEnter={() => { handleSearchCustom(selectedKeys[0] as string) }} */
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              setCurrentPage(1)
              setCurrentName(selectedKeys[0] as string)
            }}
            size='small'
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setCurrentPage(1)
              setCurrentName('')
            }}
            size='small'
            style={{width: 90}}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: Templates) =>
      record?.name
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  if (status === 'success' && data != null) {
    return (
      <>
        <Table
          style={{overflow: 'auto', position: 'relative'}}
          scroll={{x: 'max-content', y: 500}}
          pagination={{
            pageSize: 50,
            showLessItems: true,
            size: 'small',
            responsive: true,
            simple: true,
            showQuickJumper: false,
            showSizeChanger: false,
            current: currentPage,
            total: data.count || 0,
          }}
          dataSource={data.data}
          onChange={handleTableChange}
        >
          {/* <Column
            width={600}
            title="Nombre"
            dataIndex={"name"}
            {...getColumnSearchPropsName('name')}
          /> */}

          <Column
            title='Nombre'
            {...getColumnSearchPropsName('name')}
            render={(item: any) => <Space>{item.name}</Space>}
          />

          {/* <Column

            title='Ultima edicion'

            render={(item: any) => (
              <Space>
                {item.updatedAt.toString().substring(0, 10)}
              </Space>
            )

            }

          /> */}
          <Column
            width={200}
            title='Ultima Edicion'
            dataIndex='updatedAt'
            key='creationDate'
            render={(date) => <span>{moment(date).format('YYYY-MM-DD')}</span>}
            filterIcon={(filtered) => (
              <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
            )}
            filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
              <div style={{padding: 8}}>
                <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
                  <Button
                    type='primary'
                    onClick={() => {
                      confirm()
                      handleSearchDatePicker(fromDate!, toDate!)
                    }}
                    size='small'
                    style={{width: 90, marginRight: 8}}
                  >
                    Filtrar
                  </Button>
                  <Button
                    onClick={() => {
                      setFromDate(null)
                      setToDate(null)
                      setCurrentPage(1)
                    }}
                    size='small'
                    style={{width: 90}}
                  >
                    Limpiar
                  </Button>
                </div>
              </div>
            )}
          />

          <Column
            title='Acciones'
            render={(item: any) => (
              <Space>
                <Button
                  style={{background: 'white', border: '1px solid #bf3c3c'}}
                  onClick={() => {
                    handleEditTemplate(`${item.id}`)
                  }}
                >
                  <GrEdit />
                </Button>
              </Space>
            )}
          />
        </Table>
        {/* <TableContainer style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
          <Table
          // dataSource={data} rowKey='id'
          >
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({ id: 'TEMPLATES.FILE' })} </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data?.map((template: Templates) => (
                <TableRow key={template.id}>
                  <TableCell>{template.name}</TableCell>
                  <TableCell>
                    <Space>
                      <Button
                        style={{ background: 'white', border:'1px solid #bf3c3c' }}
                        onClick={() => { handleEditTemplate(`${template.id}`) }}
                      >
                        <GrEdit />
                      </Button>
                    </Space>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </>
    )
  } else {
    return <>No data</>
  }
}

export {ListTemplates}
