import CategoryManager from './CategoryManager'
import {Button, Container, Link} from '../../../../components'
import {useIntl} from 'react-intl'

const CategoryWrapper = () => {
  const intl = useIntl()
  return (
    <Container title={intl.formatMessage({id: 'MENU.STORE.CATEGORY'})}>
      <Link to='/admin/store'>
        <Button
          className='btn  btn-primary'
          style={{
            width: '8rem',
            alignSelf: 'end',
            marginRight: '2rem',
            border: 'none',
            marginBottom: '1rem',
            gap: '0.5rem',
          }}
        >
          Regresar
        </Button>
      </Link>
      <CategoryManager />
    </Container>
  )
}

export default CategoryWrapper
