// import 'core/layout/main/styles/components/mainlogin.css'

import ContainerLeft from './ContainerLeft'
import Login from './Login'

const LoginWrapper = () => {
  return (
    <div className=' flex-wrap overflow-hidden table my-0'>
      <div className='w-full h-screen overflow-hidden lg:w-1/2 xl:w-1/2 px-4 mx-auto my-auto table-cell align-middle'>
        <div className='-mt-32'>
          <header className='h-32 text-center w-48 justify-center items-center lg:left-0 m-auto lg:w-32 xl:w-32'>
            <img
              src={'/media/newdesign/07-logo-horizontal-color.svg'}
              alt='immiland'
              className=''
            />
          </header>
        </div>
        <div className='align-content-center lg:px-20 xl:px-32 '>
          <Login />
        </div>
      </div>

      <div className='w-full h-0 lg:h-screen xl:h-screen overflow-hidden bg-gray-100 lg:w-1/2 xl:w-1/2 p-0 lg:p-10 xl:p-10 table-cell align-middle'>
        <ContainerLeft />
      </div>
    </div>
  )
}

export default LoginWrapper
