import {Progress} from 'antd'

interface IProgressProps {
  percent: number
}

const ProgressWraper = (props: IProgressProps) => {
  const {percent} = props

  return (
    <div>
      <Progress percent={percent} />
    </div>
  )
}

export default ProgressWraper
