/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../../components'
import { LinkOutlined, FilterOutlined } from '@ant-design/icons'
import { Space, Tag, Input, Select } from 'antd'
import { BsTranslate } from 'react-icons/bs'
import { TimeZone } from '../../../../../app/modules/utils/components/TimeZone'
import { type Translations } from '../../../../../app/modules/apps/translations/core/_models'
import { observer } from 'mobx-react'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'
import { RootStoreContext } from "stores/rootStore"
import './style.css'
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment'
const { RangePicker } = DatePicker;

const { Option } = Select;

interface IProps {
  translationsUser: Translations[]
  setTranslation: (translations: Translations) => void
}

const ListTranslationsService: React.FC<IProps> = ({ translationsUser, setTranslation }) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  
  // Estado para el filtro y su visibilidad
  const [filter, setFilter] = useState<string[]>([])
  const [filterVisible, setFilterVisible] = useState(false)
  const [assignedFilter, setAssignedFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [assignedFilterVisible, setAssignedFilterVisible] = useState(false);
const [statusFilterVisible, setStatusFilterVisible] = useState(false);
const [expiryDateFilter, setExpiryDateFilter] = useState<string | null>(null);
const [creationDateFilter, setCreationDateFilter] = useState<string | null>(null);

const [expiryDateFilterVisible, setExpiryDateFilterVisible] = useState(false);
const [creationDateFilterVisible, setCreationDateFilterVisible] = useState(false);
const [expiryDateRange, setExpiryDateRange] = useState<[Moment | null, Moment | null] | null>(null);
const [creationDateRange, setCreationDateRange] = useState<[Moment | null, Moment | null] | null>(null);

const handleExpiryDateRangeChange = (dates: [Moment | null, Moment | null] | null, dateStrings: [string, string]) => {
  setExpiryDateRange(dates);
};

const handleCreationDateRangeChange = (dates: [Moment | null, Moment | null] | null, dateStrings: [string, string]) => {
  setCreationDateRange(dates);
};
const formatDate = (dateTimeString: string | number | Date) => {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}





const toggleExpiryDateFilterVisibility = () => {
  setExpiryDateFilterVisible(!expiryDateFilterVisible);
}

const toggleCreationDateFilterVisibility = () => {
  setCreationDateFilterVisible(!creationDateFilterVisible);
}


  const handleFilterChange = (value: string[]) => {
    setFilter(value)
  }

  const handleExpiryDateFilterChange = (date: any, dateString: any) => {
    setExpiryDateFilter(dateString);
  }
  
  const handleCreationDateFilterChange = (date: any, dateString: string) => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : null;
    setCreationDateFilter(formattedDate);
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible)
  }
  const handleAssignedFilterChange = (value: string[]) => {
    setAssignedFilter(value);
  }
  
  const handleStatusFilterChange = (value: string[]) => {
    setStatusFilter(value);
  }
  const toggleAssignedFilterVisibility = () => {
    setAssignedFilterVisible(!assignedFilterVisible);
  }
  
  const toggleStatusFilterVisibility = () => {
    setStatusFilterVisible(!statusFilterVisible);
  }

  const uniqueTranslations = Array.from(new Set(translationsUser.map(record => record.productName)));
  const uniqueAssignees = Array.from(new Set(translationsUser.map(record => record.staffName)));
  const uniqueStatuses = Array.from(new Set(translationsUser.map(record => record.status)));
  

  const filteredTranslations = translationsUser.filter(record => {
    const recordEndDue = record.endDue ? moment(record.endDue) : null;
    const recordCreatedAt = record.created_at ? moment(record.created_at) : null;
  
    return (
      ((!filter || filter.length === 0) || (record.productName ?? '').includes(filter.join(''))) &&
      ((!assignedFilter || assignedFilter.length === 0) || assignedFilter.includes(record.staffName ?? '')) &&
      ((!statusFilter || statusFilter.length === 0) || statusFilter.includes(record.status ?? '')) &&
      (!expiryDateRange || (recordEndDue && recordEndDue.isBetween(expiryDateRange[0], expiryDateRange[1], 'days', '[]'))) &&
      (!creationDateRange || (recordCreatedAt && recordCreatedAt.isBetween(creationDateRange[0], creationDateRange[1], 'days', '[]')))
    );
  });
  
  
  
  
  
  
  
  

  return (
    <div style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto',}}>
      <table className="table-master my-custom-table" style={{marginTop:'2rem'}}>
        <thead style={{ width: '1035px', height: '24px', padding: '0px 16px', gap: '16px', textTransform: 'uppercase' }}>
          <tr>
            <th style={{ width: '15%',  top: 0, backgroundColor: '#fff', zIndex: 1, position: 'sticky' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                Traducción 
                <FilterOutlined 
                  className="icon-filter"
                  style={{ marginLeft: '0.5rem', cursor: 'pointer'}} 
                  onClick={toggleFilterVisibility} 
                />
              </div>
              {filterVisible && (
                  <div style={{ position: 'absolute', top: '2rem', left: 0, right: 0, zIndex: 2, backgroundColor: '#fff', padding: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'}}>
                  <Select
                    mode="multiple"
                    placeholder="Filtrar por traducción"
                    value={filter}
                    onChange={handleFilterChange}
                    style={{ width: '100%' }}
                  >
                    {uniqueTranslations.map((translation) => (
                      <Option key={translation} value={translation}>
                        {translation}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </th>
            <th style={{ width: '10%', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1, }}>
  <div style={{ marginBottom: '1rem', gap:'0.5rem', display:'flex' }}>
    Vence el
    <div onClick={toggleExpiryDateFilterVisibility} style={{cursor:'pointer'}}> <button onClick={toggleExpiryDateFilterVisibility} style={{cursor:'pointer'}}>
      <FilterOutlined
      className='icon-filter' />
    </button></div>
   
    <div style={{ position: 'absolute', top: '1rem', left: '-4rem', right: 0, zIndex: 2, padding: '8px' }}>
      {expiryDateFilterVisible && (
        <RangePicker 
          onChange={handleExpiryDateRangeChange} 
          style={{ width: '12rem' }} 
          placeholder={["Desde", "Hasta"]} 
        />
      )}
    </div>
  </div>
</th>
<th style={{ width: '15%', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
  <div style={{ marginBottom: '1rem', gap:'0.5rem', display:'flex' }}>
    Creado el
   <div onClick={toggleCreationDateFilterVisibility} style={{cursor:'pointer'}}> <button onClick={toggleCreationDateFilterVisibility} style={{cursor:'pointer'}}>
      <FilterOutlined
      className='icon-filter' />
    </button></div>
   
    <div style={{ position: 'absolute', top: '1rem', left: '-1rem', right: 0, zIndex: 2, padding: '8px'}}>
      {creationDateFilterVisible && (
        <RangePicker 
          onChange={handleCreationDateRangeChange} 
          style={{ width: '100%' }} 
          placeholder={["Desde", "Hasta"]} 
        />
      )}
    </div>
  </div>
</th>



            <th style={{ width: '10%', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
              <div style={{ marginBottom: '1rem' }}>N° PÁGS traducidas</div>
            </th>
            <th style={{ width: '10%', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
              <div style={{ marginBottom: '1rem', marginLeft: '2rem' }}>Tipo</div>
            </th>
            <th style={{ width: '15%',  top: 0, backgroundColor: '#fff', zIndex: 1, position: 'sticky' }}>
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', gap:'0.5rem'}}>
    Asignado a
    <FilterOutlined 
      className="icon-filter"
      style={{ marginLeft: '0.5rem', cursor: 'pointer' }} 
      onClick={toggleAssignedFilterVisibility} 
    />
  </div>
  {assignedFilterVisible && (
   <div style={{ position: 'absolute', top: '2rem', width:'10rem', left: '-2rem', right: '0', zIndex: 10, backgroundColor: '#fff', padding: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'}}>
      <Select
        mode="multiple"
        placeholder="Filtrar por asignado a"
        value={assignedFilter}
        onChange={handleAssignedFilterChange}
        style={{ width: '100%' }}
      >
        {uniqueAssignees.map((assignee) => (
          <Option key={assignee} value={assignee}>
            {assignee}
          </Option>
        ))}
      </Select>
    </div>
  )}
</th>
<th style={{ width: '10%',  top: 0, backgroundColor: '#fff', zIndex: 1, position: 'sticky' }}>
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', gap:'0.5rem' }}>
    Estado
    <FilterOutlined 
      className="icon-filter"
      style={{ marginLeft: '0.5rem', cursor: 'pointer' }} 
      onClick={toggleStatusFilterVisibility} 
    />
  </div>
  
  {statusFilterVisible && (
     <div style={{ position: 'absolute', top: '2rem', width:'10rem', left: '-2rem', right: '0', zIndex: 2, backgroundColor: '#fff', padding: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>
      <Select
        mode="multiple"
        placeholder="Filtrar por estado"
        value={statusFilter}
        onChange={handleStatusFilterChange} 
        style={{ width: '100%' }}
      >
        {uniqueStatuses.map((status) => (
          <Option key={status} value={status}>
            {status}
          </Option>
        ))}
      </Select>
    </div>
  )}
</th>
          </tr>
        </thead>
        <tbody>
          {filteredTranslations.map((record, index) => (
            <React.Fragment key={index}>
              <tr className="custom-row" onClick={() => {
                setTranslation(record);
              }} style={{ cursor: 'pointer', padding: '10px 10px' }}>
                <td style={{ marginLeft: '1rem' }}>{record.productName}</td>
                <td>{record.endDue?.slice(0, 10)}</td>

                <td>{record.created_at && formatDate(record.created_at)}</td>


                <td>
                  <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'center' }}>
                    {record.pages}
                  </div>
                </td>
                <td>{record.express ? 'express' : ''}</td>
                <td>{record.staffName}</td>
                <td>
                  <Tag style={{ borderRadius: '25px' }} color={record.statusColor ? record.statusColor : ''.toLowerCase()}>
                    {record.status === 'Completed' ? intl.formatMessage({ id: 'CORE.DONE' }) : intl.formatMessage({ id: 'CORE.PENDING' })}
                  </Tag>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <button
                    onClick={() => {
                      setTranslation(record);
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: '0',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      src="/media/avatars/Group.svg"
                      alt="Icon"
                      style={{ width: '24px', height: '24px', marginTop: '2px', marginRight: '10px' }}
                    />
                  </button>
                </td>
              </tr>
              <tr style={{ height: '1rem' }}></tr> {/* Espacio entre filas */}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(ListTranslationsService)  
