import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAllStaff } from 'app/modules/apps/staff/core/_requests';


interface SlideIntakeProcessProps {
  envelopeId: string | null;
  visible: boolean;
  onCancel: () => void;
  firstName?: string;
  lastName?: string;
}

const SlideIntakeProcess: React.FC<SlideIntakeProcessProps> = ({
  envelopeId,
  visible,
  onCancel,
  firstName,
  lastName,
}) => {
  const [trackingData, setTrackingData] = useState<any[]>([]);
  const [staffData, setStaffData] = useState<any[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchTrackingData = async () => {
      if (!envelopeId) return;
      try {
        const response = await axios.get(`http://localhost:4000/api/v1/logger/track/${envelopeId}`);
        const data = response.data;
        setTrackingData(data);
      } catch (error) {
        console.error("Error fetching tracking data:", error);
      }
    };

    const fetchStaffData = async () => {
      try {
        const staff = await getAllStaff();
        setStaffData(staff);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    if (visible) {
      fetchTrackingData();
      fetchStaffData();
    }
  }, [envelopeId, visible]);

  const getJotformName = (staffId: string | null) => {
    if (!staffId) return 'Sin asignar';
    const staff = staffData.find(staff => staff.id === staffId);
    return staff ? staff.jotformName : 'Desconocido';
  };

  const getStepLabel = (step: number | null) => {
    if (step === null) return "Sin Etapa";
    return `Etapa ${step}`;
  };

  const generateTrackingMessages = () => {
    return trackingData
        .map((entry, index) => {
            const oldValue = entry.old_value;
            const newValue = entry.new_value;

            const messages = [];

            // Check for changes in step
            if (oldValue.step !== newValue.step) {
                messages.push(
                    <div key={index} className="tracking-row">
                        <div className="tracking-date">
                            {new Date(newValue.updated_at).toLocaleString()}
                        </div>
                        <div className={`tracking-step ${newValue.step === 4 ? 'completed' : 'in-progress'}`}>
                            {getStepLabel(newValue.step)}
                        </div>
                        <div className={`tracking-assigned ${!newValue.staffId ? 'no-assignment' : ''}`}>
                            Asignado a {getJotformName(newValue.staffId)}
                        </div>
                    </div>
                );
            }

            // Check for changes in staffId
            if (oldValue.staffId !== newValue.staffId) {
                messages.push(
                    <div key={index} className="tracking-row">
                        <div className="tracking-date">
                            {new Date(newValue.updated_at).toLocaleString()}
                        </div>
                        <div className={`tracking-step ${newValue.step === 4 ? 'completed' : 'in-progress'}`}>
                            {getStepLabel(newValue.step)}
                        </div>
                        <div className={`tracking-assigned ${!newValue.staffId ? 'no-assignment' : ''}`}>
                            Asignado a {getJotformName(newValue.staffId)}
                        </div>
                    </div>
                );
            }

            // Only return the entry if there is at least one change
            return messages.length > 0 ? messages : null;
        })
        .filter(Boolean); // Filter out null or empty values
  };

  const trackingMessages = generateTrackingMessages();

  return (
    <div
      className={`fixed inset-0 overflow-hidden transform transition-transform ${visible ? 'translate-x-0' : 'translate-x-full'}`}
      style={{ zIndex: '9999' }}
    >
      <div className='absolute inset-0 bg-gray-500 opacity-75' onClick={onCancel}></div>
      <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
        <div className='relative w-screen slide-intake-process-container'>
          <div className='flex-1 py-6 overflow-y-auto px-4 sm:px-6'>
          <button
                style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', padding: '10px 24px', height: 'auto', width: 'auto' }}
                onClick={onCancel}
                className='button2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  alt="Calendar"
                  className='meet-image2'
                  src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-black.svg'}
                />
                <b className='meet-text2'>VOLVER</b>
              </button>
            <div className='line-container-consult'></div>

            {/* Display client name at the top */}
            <h2 className='process-tracking-title'>
              Seguimiento del proceso - {firstName} {lastName}
            </h2>

            {/* Display the tracking messages */}
            <div className='tracking-messages'>
              {trackingMessages.length > 0 ? trackingMessages : "No hay cambios relevantes para mostrar."}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlideIntakeProcess;
