 

import {Route, Routes} from 'react-router-dom'
import CategoryWrapper from '../Category'
import CheckOut from '../CheckOut'
import Embed from '../Components/Embed'
import Layout from '../Layout'
import PaymentSucess from '../CheckOut/Steps/Payment/PaymentSucess'
import ProductCard from '../Product/ProductCard'
import CheckOutResumeWO from '../CheckOut/Steps/Payment/CheckOutResumeWO'

const CartRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<CategoryWrapper />} />
        <Route
          path='category/consulta-migratoria'
          element={<Embed link={'https://calendly.com/immiland-canada?utm_source=web'} />}
        />
        <Route
          path='category/immigration'
          element={
            <Embed link={'https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402'} />
          }
        />

        <Route path='checkout' element={<CheckOut />} />
        <Route path='category/:categorySlug' element={<CategoryWrapper />} />
        <Route path='payment/sucess/:code' element={<PaymentSucess />} />
        <Route path='payment/sucesswo' element={<CheckOutResumeWO />} />
        <Route path='product/:id' element={<ProductCard />} />
      </Route>
    </Routes>
  )
}

export default CartRoutes
