/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import {Alert, Button, H1, H3, H4, Input, Link} from '../../components'
import {useContext, useEffect, useState} from 'react'
import {ErrorParser} from '../../core/error/utils/ErrorParser'
import {RootStoreContext} from '../../stores/rootStore'
import clsx from 'clsx'
import {observer} from 'mobx-react'
import {useFormik} from 'formik'
import './styles/mainlogin1.css'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const user_dev_test = process.env.REACT_APP_TEMP_DEV_USER_MAIL || ''
const user_dev_test_pass = process.env.REACT_APP_TEMP_DEV_MAIL_PASS || ''

const initialValues = {
  email: user_dev_test,
  password: user_dev_test_pass,
}

const Login = () => {
  const [loading, setLoading] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {loginUser, onErrorLogin, isAuthenticated} = rootStore.authStore

  useEffect(() => {
    if (onErrorLogin != null) {
      setLoading(false)
      formik.setStatus(ErrorParser(onErrorLogin.errors))
      formik.setSubmitting(false)
    }
    // eslint-disable-next-line
  }, [onErrorLogin])

  // const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      setStatus('')

      loginUser(values.email, values.password)
    },
  })

  return (
    <form className='login-responsive mx-5' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
      <section className=''>
        <H1>Bienvenido de nuevo</H1>
        <H4>¡Bienvenido de nuevo! Por favor ingrese sus datos</H4>
      </section>
      <section className=''>
        <H3>Correo Electronico</H3>
        <Input
          className=''
          placeholder='Correo Electronico'
          {...formik.getFieldProps('email')}
          type='email'
          name='email'
        />
        {formik.touched.email && formik.errors.email && (
          <div className=''>
            <span role='alert' className=''>
              <Alert>{formik.errors.email}</Alert>
            </span>
          </div>
        )}
      </section>
      <section>
        <H3>Contraseña</H3>
        <Input
          type='password'
          autoComplete='off'
          placeholder='Contraseña'
          {...formik.getFieldProps('password')}
          className={clsx(
            '',
            {
              'is-invalid': <Alert>formik.touched.password && formik.errors.password,</Alert>,
            },
            {
              'is-valid': <Alert>formik.touched.password && !formik.errors.password,</Alert>,
            }
          )}
        />

        <Alert>
          {formik.touched.password && formik.errors.password && (
            <div className=''>
              <div className=''>
                <span role='alert' className=''>
                  <Alert>{formik.errors.password}</Alert>
                </span>
              </div>
            </div>
          )}
        </Alert>
      </section>
      <section>
        <Alert>
          {formik.status && (
            <div className=''>
              <div className=''>
                <span role='alert' className=''>
                  <Alert>{formik.status}</Alert>
                </span>
              </div>
            </div>
          )}
        </Alert>
        <Button type='submit' disabled={loading}>
          {loading ? (
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
          ) : (
            'Iniciar sesión'
          )}
        </Button>
        <div className='my-2'>
          <Link to='/auth/forgot-password'>
            <p>¿Has olvidado tu contraseña?</p>
          </Link>
        </div>
      </section>
    </form>
  )
}

export default observer(Login)
