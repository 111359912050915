import tw from 'tailwind-styled-components'

const Content = tw.div`
  w-full
  position-relative
  overflow-hidden
  mr-2
  border-t
  border-gray-200
  pb-3
  mb-3
  md:m-auto
  justify-center
  items-center



`
export {Content}
