/* eslint-disable @typescript-eslint/no-empty-function */
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {uploadLogger} from 'app/modules/apps/file/core/_requests'

export const exportToExcel = async (data: any, fileName: string, titles: string[]) => {
  const ws = XLSX.utils.book_new()
  XLSX.utils.sheet_add_aoa(ws, [titles])
  XLSX.utils.sheet_add_json(ws, data, {origin: 'A2', skipHeader: true})
  const wb = {Sheets: {Data: ws}, SheetNames: ['Data']}
  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array', cellStyles: true})
  const finalData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  })
  saveAs(finalData, fileName + new Date().getTime() + 'xlsx')
  await uploadLogger(finalData, () => {}, 'loggertable', fileName + new Date().getTime() + '.xlsx')
}
