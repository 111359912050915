 
 
/* eslint-disable  */
import './style.css'
import {
  type IQueryUsers,
  type Users,
  type IQueryUsersFilter,
} from '../../../../modules/apps/users/core/_models'
import React, {
  type Key,
  useContext,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react'
import AvatarProfileUsers from '../../../../modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {Button, Checkbox, Input, type InputRef, Space, Switch, Table} from 'antd'
import {
  type ColumnFilterItem,
  type ColumnType,
  type SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'
import {SearchOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import type {TableRowSelection} from 'antd/es/table/interface'
import {
  getPartnetship,
  getTemplateSendingBluee,
} from '../../../../modules/apps/users/core/_requests'
import {useQuery} from 'react-query'
import moment, {Moment} from 'moment'
import {RangeValue} from 'rc-picker/lib/interface'
import {DatePicker} from 'antd'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {getUserProfile} from '../../../../modules/apps/user/core/_requests'
import {getAllStaff} from 'app/modules/apps/staff/core/_requests'
import {getProductsTableFilter} from 'app/modules/apps/cart/core/products/_requests'
import {getContractByStaffId} from 'app/modules/apps/contract/_requests'

import type {ColumnsType} from 'antd/es/table'
import type {FilterConfirmProps} from 'antd/es/table/interface'
import {current} from '@reduxjs/toolkit'

interface IProps {
  search: IQueryUsers
  searchFilter: IQueryUsersFilter
  setSearch: (search: IQueryUsers) => void
  searchQuery: IQueryUsers
  setUs: Dispatch<SetStateAction<string>>
}

interface DataType {
  key: string
  name: string
  email: string
  contract: number
  matter: string
  createdAt: string
  user: Users
  status: string
}

type DataIndex = keyof DataType

const rowSelection: TableRowSelection<DataType> = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}

const MyClientList: React.FC<IProps> = ({search, searchFilter, setSearch, searchQuery, setUs}) => {
  const [checkStrictly, setCheckStrictly] = useState(false)
  const {Column} = Table
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {users, selectUser} = rootStore.usersStore
  const {user: userAUTH, staff: staffAuth} = rootStore.authStore
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRowEmail, setSelectedRowEmail] = useState<string[]>([])
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Users>>({})
  const [lastServiceInfo, setLastServiceInfo] = useState<ColumnFilterItem[]>()
  const [partnershipInfo, setPartnershipInfo] = useState<ColumnFilterItem[]>()
  const [data, setData] = useState<DataType[]>([])
  const [active, setActive] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)

  const {data: sendingBlueData} = useQuery(
    'getAllSendingBlueTemplates',
    async () => await getTemplateSendingBluee()
  )
  const {data: StaffData} = useQuery('getStf', async () => await getAllStaff())
  const {data: productData} = useQuery('getPro', async () => await getProductsTableFilter())
  const {data: parnershipData} = useQuery('getPar', async () => await getPartnetship())
  const {data: dataFromDatabase, status: statusFromDatabase} = useQuery(
    'allcontrByStaff',
    async () => await getContractByStaffId(staffAuth.id)
  )

  useEffect(() => {
    if (statusFromDatabase === 'success') {
      console.log('la data', dataFromDatabase)
      const newDataArray = dataFromDatabase.data.map((item: any, index: number) => ({
        key: String(index + 1),
        name: '',
        email: item.contracts_users_emailId,
        contract: item.contracts_users_envelope_id,
        matter: item.contracts_users_matter,
        createdAt: item.contracts_users_created_at,
        user: users?.data,
        
      }))
      setData(newDataArray)
      setActive(active + 1)
    }
  }, [dataFromDatabase])

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            size='small'
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{width: 90}}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSelectUser = (user: Users) => {
    console.log('el boton', user)
    selectUser(user)
    setUs(user.id as string)
  }

  const columns: ColumnsType<DataType> = [
    {
      key: 'email',
      width: '10%',
      align: 'center',
      render: (index: Users) => (
        <>
          <Button
            onClick={() => {
              handleSelectUser(index)
            }}
          >
            {intl.formatMessage({id: 'CLIENTS.PROFILE'})}
          </Button>
        </>
      ),
    },
    {
      key: 'email',
      width: '5%',
      align: 'center',
      render: (record: Users) => (
        <>
          <AvatarProfileUsers
            image={
              record.photoId
                ? `${record.photoPath}/${record.photoId}-avatar-small.jpg`
                : '/media/avatars/avatar.png'
            }
            size={25}
          />
        </>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Matter',
      dataIndex: 'matter',
      key: 'matter',
      width: '30%',
      ...getColumnSearchProps('matter'),
      render: (text) => (
        <div
          style={{
            maxWidth: '240px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      ...getColumnSearchProps('createdAt'),
      render: (text, record) => <span>{moment(text).format('YYYY-MM-DD')}</span>,
    },
  ]

  return (
    <>
      <Table
        style={{overflow: 'auto', position: 'relative', marginBottom: '10px'}}
        scroll={{x: 'max-content', y: 500}}
        columns={columns}
        dataSource={data}
        rowSelection={{...rowSelection, checkStrictly}}
      />
    </>
  )
}

export default observer(MyClientList)
