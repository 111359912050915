/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react'
import {type Category} from '../../../../app/modules/apps/cart/core/category/_models'
import ProductsList from '../ProductsList'
import {RootStoreContext} from '../../../../stores/rootStore'
import {observer} from 'mobx-react'

interface IProps {
  category: Category
}

const ListWrapper: React.FC<IProps> = ({category}) => {
  const rootStore = useContext(RootStoreContext)
  const {getProductsByCategory, isLoadingProducts, products} = rootStore.cartStore

  useEffect(() => {
    if (category.id) {
      getProductsByCategory(category.id)
    }
  }, [category])

  return (
    <div>
      {isLoadingProducts ? <>Loading</> : <>{products && <ProductsList products={products} />}</>}
    </div>
  )
}

export default observer(ListWrapper)
