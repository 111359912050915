import React from 'react'
import {SearchOutlined} from '@ant-design/icons'

interface IProps {
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

const Search: React.FC<IProps> = ({value, onChange}) => {
  return (
    <form className='flex items-center p-2 w-full '>
      <div className='relative w-full rounded-md'>
        <div className='flex absolute inset-y-0 items-center pl-3 '>
          <SearchOutlined className='' />
        </div>
        <input
          type='text'
          className=' bg-gray-100 text-sm rounded-md block w-full pl-5 p-1 focus:outline-none'
          placeholder='Search'
          value={value}
          onChange={onChange}
        />
      </div>
    </form>
  )
}

export default Search
