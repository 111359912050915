 
import {type FC} from 'react'
import {Container} from 'reactstrap'
import AnswersList from './AnswersList'

const Answers: FC = () => {
  return (
    <>
      <Container>
        <AnswersList />
      </Container>
    </>
  )
}

export {Answers}
