 
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Link} from '../../../../../../components'
import React, {useContext, useEffect, useState} from 'react'
import {type Orders} from '../../../../../../app/modules/apps/cart/core/preorders/_models'
import {RootStoreContext} from '../../../../../../stores/rootStore'


const CheckOutResumeWO: React.FC = ({}) => {


  
  return (
    <div className='w-full block lg:w-1/2 xl:w-1/2 justify-center m-auto text-center'>
      <div className=''>
        
              <div >
                <div className='m-4'>
                  <div>
                    <img src={'/media/newdesign/ready.png'} className=' w-80 block m-auto' alt='' />
                  </div>
                  <div className='my-2'>
                    <div className='font-bold mb-2'>Transaction sucessfully purchased!</div>
                    <p className='text-xs '>
                      Congratulations ! Your purchase is complete. You will receive a
                    </p>
                    <p className='text-xs -mt-3'>confirmation email shortly.</p>
                  </div>
                  <div className='my-2'>
                    <Link to='/web/thanks'>
                      <p className='text-white rounded-md bg-tomato-500 hover:bg-tomato-400 py-2 px-3 '>
                        Mis Traducciones
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            
      </div>
    </div>
  )
}

export default CheckOutResumeWO
