/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
  import {PageHeader, Space} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import React, {useEffect, useState} from 'react'
import {saveTemplates, updateTemplates} from '../../../../app/modules/apps/templates/core/_requests'
import {AddTemplate} from './AddTemplate'
import {Button} from '../../../../components'
import {ListTemplates} from './ListTemplates'
import {MdOutlineArrowBackIosNew} from 'react-icons/md'
import Swal from 'sweetalert2'
import {type Templates} from '../../../../app/modules/apps/templates/core/_models'
import {useIntl} from 'react-intl'

interface Props {
  data?: string
}

const initialData: Templates = {
  id: null,
  name: '',
  text: '',
}

const TemplateManager: React.FC<Props> = ({data: Props}) => {
  const [addfile, setAddFile] = useState(false)
  const [icon, setIcon] = useState(<PlusOutlined />)
  const [callRefetch, setCallRefetch] = useState(false)
  const [datatemplate, setInitialData] = useState<Templates>(initialData)

  const intl = useIntl()

  useEffect(() => {
    if (addfile) {
      setCallRefetch(false)
      /*  setIcon(<MinusCircleOutlined style={{color: 'white'}} />) */
      setIcon(
        <Button
          onClick={() => {
            setInitialData(initialData)
            setAddFile(!addfile)
          }}
          style={{
            border: '1px solid #BF3C3C',
            backgroundColor: '#BF3C3C',
            borderRadius: '15px',
            color: 'white',
            width: '250px',
            height: '44px',
            fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '16px',
            fontWeight: '550',
          }}
        >
          <MdOutlineArrowBackIosNew
            style={{
              color: 'white',
              marginRight: '15px',
            }}
          />
          {intl.formatMessage({id: 'TEMPLATES.BACK'})}
        </Button>
      )
    } else {
      setCallRefetch(true)
      setIcon(
        <Button
          onClick={() => {
            setAddFile(!addfile)
          }}
          style={{
            border: '1px solid #BF3C3C',
            backgroundColor: '#BF3C3C',
            borderRadius: '15px',
            color: 'white',
            width: '250px',
            height: '44px',
            fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '16px',
            fontWeight: '550',
          }}
        >
          {intl.formatMessage({id: 'TEMPLATES.ADDNEWTEMPLATE'})}
        </Button>
      )
    }
  }, [addfile])

  const handleSaveTemplate = async (data: Templates) => {
    setCallRefetch(true)
    if (!data.id) {
      const result = await saveTemplates(data)
      if (result != null) {
        setInitialData(result)
        Swal.fire({
          title: 'Success!',
          text: 'Guardado correctamente ',
          icon: 'success',
          confirmButtonText: 'Regresar',
        })
      }
    } else {
      const result = await updateTemplates(data)

      if (result) {
        setInitialData(result)
        Swal.fire({
          title: 'Success!',
          text: 'Guardado correctamente ',
          icon: 'success',
          confirmButtonText: 'Regresar',
        })
      }
    }
    setCallRefetch(false)
  }

  const handleEdit = (data: Templates) => {
    setInitialData(data)

    setAddFile(true)
  }

  return (
    <>
      <PageHeader
        className='site-page-header-responsive'
        title=''
        subTitle=' '
        extra={[<h1 key='template-manager-btn'>{icon}</h1>]}
      >
        {addfile ? (
          <>
            <AddTemplate data={datatemplate} onComplete={handleSaveTemplate} />
          </>
        ) : (
          <Space direction='vertical' style={{display: 'flex'}}>
            <ListTemplates handleEdit={handleEdit} callRefetch={callRefetch} />
          </Space>
        )}
      </PageHeader>
    </>
  )
}

export {TemplateManager}
