export const myinformation = {
  'MYINFO.CHANGEPROFILEPICTURE': 'Change profile picture',
  'MYINFO.FORMSDATANAME': 'Forms Data Name',
  'MYINFO.ID': 'Forms Data id',
  'MYINFO.NAME': 'Name',
  'MYINFO.LASTNAME': 'Last Name',
  'MYINFO.FIRSTNAME': 'First Name',
  'MYINFO.FORMSID': 'Form ID',
  'MYINFO.FORMSNAME': 'Form Name',
  'MYINFO.PREVIEW': 'Preview',
  'FORMSMANAGER.PLEASECLICKTHEFORM': 'Please select the form you want to complete',
  'MYINFO.EMAIL': 'Email',
  'MYINFO.NORESULT': ' No Results',
  'MYINFO.LASTSERVICE': 'Last Service',
}
