/* eslint-disable @typescript-eslint/no-unused-vars */

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Briefcase from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-4.svg'
import DeleteFrom from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/circle-minus.svg'
import ChevronRight from '../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/chevron-right.svg'
import { observer } from 'mobx-react'
import {useQuery } from 'react-query'
import { getForms } from 'app/modules/apps/forms/core/_requests'
import { deleteFormsData, getFormsCompletedData, saveFormsData, saveFormsFromProductData } from 'app/modules/apps/formsdata/core/_requests'
import { Users } from 'app/modules/apps/users/core/_models'
import { getContractByUser } from 'app/modules/apps/contract/_requests'
import Swal from 'sweetalert2'

interface IProps {
  user: Users | null
  setOpenManual: Dispatch<SetStateAction<boolean>>
  contractsFound: string
  principalService: string
  allFroms: any
  aplicants: string[]
  refetchFromDataBaseTwo: () => void
  refetchTwo:() => void
}

const FormManagerClientNew: React.FC<IProps> = ({ setOpenManual, user, contractsFound, principalService, allFroms,aplicants,refetchTwo,refetchFromDataBaseTwo }) => {
  const [actualContract, setActualcontract] = useState<any>(null)
  const [collapsed, setCollapsed] = useState<{ [key: number]: boolean }>({});
  const [aplicantName, setAplicantName] = useState('')
  const [isHovered, setIsHovered] = useState(false)
  const [aplicantType, setAplicantType] = useState('')
  const [serviceId, setServiceId] = useState('')
  const [formName, setFormName] = useState('')
  const [answer, setAnswer] = useState <any>(null)
  const { data, status, refetch: refetchForms,isFetched:isFForms,isRefetching} = useQuery('listforsms', async () =>
    await getForms('limit=50&page=1&name=')) /*formularios*/
  const { data: dataFormsCompleted, status: statusFromCompleted, refetch, isRefetching:irformcompleted} = useQuery(
    'alistformsclientsq',
    async () => await getFormsCompletedData(user !== null ? user.id! : '')
  ) /*formularios COmpletados*/

  const { data: dataFromDatabase, status: statusFromDatabase, refetch: refetchFromDataBase, isRefetching:irdatabase } = useQuery(
    'anallcontracsDbq',
    async () => await getContractByUser(user !== null ? user.id! : '')
  ) /* contratos del cliente */

  useEffect(() => {
    console.log("status",status,"statusdatabase",statusFromDatabase,"statusFromCompleted",statusFromCompleted)
    if (status === 'success') {
      console.log(data, 'todos la data')
    }
    if (statusFromCompleted === 'success') {
      console.log(dataFormsCompleted, 'los formularios completados')
    }
    if (statusFromDatabase === 'success') {
      console.log(dataFromDatabase, 'los contratos', contractsFound, dataFromDatabase)
      dataFromDatabase.data.map((contract: any) => {
        if (contract.contracts_users_envelope_id === contractsFound) {
          console.log("el mero mero", contract)
          setActualcontract(contract)
        }
      })
    }
  }, [])

  useEffect(() => {
  if(isRefetching===false && irdatabase===false && irformcompleted===false){
    if (status === 'success') {
      console.log(data, 'todos la data')
    }
    if (statusFromCompleted === 'success') {
      console.log(dataFormsCompleted, 'los formularios completados')
    }
    if (statusFromDatabase === 'success') {
      console.log(dataFromDatabase, 'los contratos')
      dataFromDatabase.data.map((contract: any) => {
        if (contract.contracts_users_envelope_id === contractsFound) {
           setActualcontract(contract)
        }
      })
    }
  }
  }, [isRefetching,irdatabase,irformcompleted])

  const toggleCollapse = (index: number) => {
    setCollapsed((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const saveForms = async (memberName:string , memberType: string , productId:string, answers: any,) => {
    let dataFormData: any;
    if (answers.id != null) {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.data,
        product: productId,
        contract: contractsFound,
        status: 0,
        memberName: memberName,
        memberType: memberType
      };
    } else {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.data,
        product: productId,
        contract: contractsFound,
        status: 0,
        memberName: memberName,
        memberType: memberType
      };
    }
    dataFormData.saveAuthor=true
   const result = await saveFormsData(dataFormData);

    refetch();
    refetchForms();
    refetchFromDataBase();
    refetchTwo();
    refetchFromDataBaseTwo();
    if (result) {
      Swal.fire({
        icon: 'success',
        html: `<p style="color:black; font-size: 25px;">Formulario asignado con éxito!</b>`,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'CERRAR',
        customClass: {
          confirmButton: 'btn-no-shadow',
        },
        buttonsStyling: false,
        allowOutsideClick: false
      });
    }
};
const handleDelete = async (id: string) => {
  const result = await deleteFormsData(id);
  if(result) {
    Swal.fire({
      icon: 'success',
      html: `<p style="color: black; font-size: 25px;">Formulario eliminado con éxito!</b>`,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'CERRAR',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      allowOutsideClick: false
    });
    refetch();
    refetchFromDataBase();
    refetchTwo();
    refetchFromDataBaseTwo();
    refetchForms();
  }
};
    

  const handleDeleteFormFromProduct = async (form: any,userForms:any) => {
   const  dataFormData = {
      user: actualContract.contracts_users_user_id,
      form: form.id,
      datafill: form.data,
      product: userForms.prodIds,
      contract: contractsFound,
      status: 0,
      memberName: userForms.memberName,
      memberType: userForms.memberType,
      saveAuthor:true
    }

    const result= await saveFormsFromProductData(dataFormData)

    refetch()
    refetchForms()
    refetchFromDataBase()
    refetchTwo()
    refetchFromDataBaseTwo()
    if (result) {console.log('saved and deleted')}
  }

  return (
    <div>
  
      <button style={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 24px 10px 24px',
        height: 'auto',
        width: 'auto',
        marginTop: '30px',
        marginBottom: '20px',
       
      }}
        onClick={() => setOpenManual(false)}
        className='button-form-volver'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <img
          alt="Calendar"
          className='meet-image2'
          src={isHovered ? '/media/icons/app/menu/arrow-left-white.svg' : '/media/icons/app/menu/arrow-left-white.svg'}
        />
        <b style={{color:'white'}}>VOLVER</b>
      </button>
      <div
        className='flex gap-2 items-center mb-4'>
        <img src={Briefcase} alt="Briefcase" />
        <h1 style={{ fontSize: '18px', fontWeight: 'bold', margin: '0' }}>Asignar formularios manualmente</h1>
      </div>
      <div
        className='flex gap-2'
        style={{ height: '5rem', backgroundColor: '#D0F0FD', borderRadius: '10px', padding: '1rem' }}>
        <div style={{ width: '1px', height: '100%', border: '1px solid #8899A8' }} />
        {statusFromDatabase === 'success' &&
          <div>
            <p style={{ fontSize: '12px', margin: '0' }}>Servicio</p>
            <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0' }}>{principalService}</p>
          </div>}
      </div>
      <h2
        style={{ fontSize: '16px', fontWeight: 'bold', margin: '0' }}
        className='mt-4 mb-2'>Asignar formularios</h2>
      <div
        style={{
          width: '100%',
          height: '100%',
          border: '2px solid #374151',
          padding: '1rem',
          borderRadius: '10px'
        }}>
        <div>
          <div className='flex gap-4 mb-4 items-center'>
            <div>
              <h2>Asignar al Usuario</h2>
              <select
                className='option-styles '
                style={{width: '100%'}}
                onChange={(e: any) => {
                  setAplicantName(e.target.value);
                  allFroms.map((aplicant:any)=>{
                    if(aplicant.memberName === e.target.value) {
                       setAplicantType(aplicant.memberType)
                       setServiceId(aplicant.prodIds)
                    }
                  })
                }}
                id="GetAplicantName">
                <option value="">Seleccione</option>
                {aplicants.map((aplicant) =>
                  <option value={aplicant}>{aplicant}</option>)}
              </select>
            </div>
            <div>
              <h2>Formulario a Asignar</h2>
              <select
                className='option-styles'
                style={{width: '100%'}}
                id=""
                onChange={(e: any) => {
                  setFormName(e.target.value);
                  status === 'success' && data.map((form) =>{
                    if (form.id=== e.target.value) {
                       setAnswer(form)
                    }
                  })
                }}>
                <option value="">Seleccione</option>
                {status === 'success' && data.map((form) =>
                  <option value={form.id?.toString()}>{form.name}</option>)}
              </select>
            </div>
            <button
            className='button-ui button-tertiary'
            onClick={()=>{saveForms(aplicantName, aplicantType, serviceId, answer)}}
              // style={{
              //   borderRadius: '50px',
              //   border: '2px solid #01A9DB',
              //   gap: '10px',
              //   color: '#01A9DB'
              // }}
              >
              Añadir
            </button>
          </div>
          <h2
            style={{ fontSize: '16px', fontWeight: 'bold', margin: '0' }}
            className='mt-4 mb-2'>Formularios Asignados</h2>
          {allFroms !== null && allFroms.map((userforms: any, index: number) =>
            <div
              key={index}
              style={{
                width: '100%',
                height: '100%',
                border: '2px solid #374151',
                padding: '0 1rem',
                borderRadius: '10px',
                marginBottom: '1rem',
           
              }}>
              <div  className='flex justify-between items-center mb-2'>
                <div className='flex gap-2 items-center'>
                  <h2
                    style={{ fontSize: '16px', fontWeight: 'bold', margin: '0' }}
                    className='mt-4 mb-2'>
                    {userforms.memberName}
                  </h2>
                  <h2
                    style={{ fontSize: '12px', margin: '0' }}
                    className='mt-4 mb-2'>
                    ({userforms.memberType})
                  </h2>
                </div>
                <button
                  className={collapsed[index] ? 'show-btn-presed2' : 'show-btn-presed'}
                  onClick={() => toggleCollapse(index)}>
                  <img
                    className='show-btn'
                    src={ChevronRight}
                    alt='ChevronRight' />
                </button>
              </div>

              {!collapsed[index] &&
        <div className='flex flex-col' style={{gap:'20px'}}>
        {userforms.forms
            .slice() // Hacer una copia para no modificar el arreglo original
            .sort((a: any, b: any) => {
                // Comprobamos si ambos tienen la propiedad updatedAt
                if (a.updatedAt && b.updatedAt) {
                    // Ordenamos por updatedAt de manera descendente
                    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
                } else if (a.updatedAt) {
                    // Si solo 'a' tiene updatedAt, colocarlo antes
                    return -1;
                } else if (b.updatedAt) {
                    // Si solo 'b' tiene updatedAt, colocarlo después
                    return 1;
                } else {
                    // Si ninguno tiene updatedAt, mantener el orden original
                    return 0;
                }
            })
            .map((form: any) => (
                <div
                    className='form-container-options flex justify-between pb-2'
                    style={{ borderBottom: '1px solid #DFE4EA'}}
                    key={form.id} // Aseguramos una clave única para cada elemento
                >
                    {form.name && <p style={{ margin: '0' }}>{form.name}</p>}
                    {form.formData && <p style={{ margin: '0' }}>{form.formData.formName}</p>}
                    <div
                        onClick={() => {
                            form.formData ? handleDelete(form.formDataId) : handleDeleteFormFromProduct(form, userforms);
                        }}
                        className='flex gap-2 items-center delete-button'
                        style={{ position: 'relative' }}
                    >
                        <img src={DeleteFrom} alt="DeleteFrom" />
                        <p className='delete-button-text delete-button-hover' style={{ fontWeight: 'bold', color: '#E10E0E', margin: '0', cursor: 'pointer' }}>
                            Quitar Formulario
                        </p>
                    </div>
                </div>
            ))}
    </div>
}

            </div>)}
        </div>
      </div>
    </div >
  )
}

export default observer(FormManagerClientNew)
