/* eslint-disable @typescript-eslint/no-unused-vars */

import {type LibraryList, type LibraryListQueryResponse} from './_models'
import {type QueryState} from '../../../../../core/helpers'
import axios, {type AxiosRequestConfig, type AxiosResponse} from 'axios'
import Buffer from 'buffer'
import {type Files} from '../../../../../app/modules/apps/file/core/_models'

const API_URL = process.env.REACT_APP_API_URL

const LibraryList_URL = `${API_URL}/librarylists`
const UploaderFiles = `${API_URL}/uploaderfiles`

const getLibraryList = async (query?: QueryState): Promise<LibraryList[]> => {
  return await axios
    .get(`${LibraryList_URL}/all`)
    .then((response: AxiosResponse<LibraryListQueryResponse>) => {
      return response.data as LibraryList[]
    })
}

const getLibraryListFiles = async (id: string): Promise<Files[]> => {
  return await axios
    .get(`${LibraryList_URL}/files/${id}`)
    .then((response: AxiosResponse<LibraryListQueryResponse>) => {
      return response.data as Files[]
    })
}

const postLibraryFiles = async (userId: string, files: string[]): Promise<any[]> => {
  const response = await axios.post(`${UploaderFiles}/libraryfiles`, {
    userId: userId,
    files: files,
  })

  return response.data as any[]
}

const getLibraryFiles = async (userId: string): Promise<any[]> => {
  const response = await axios.get(`${UploaderFiles}/libraryfiles`, {
    params: {
      userId: userId,
    },
  })

  return response.data as any[]
}
const downloadFileLibrary = async (id: string, getProgress: Function) => {
  const config: AxiosRequestConfig = {
    responseType: 'stream',
    onDownloadProgress: (progressEvent: {loaded: number; total?: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))

      getProgress(percentCompleted)
    },
  }
  return await axios.get(`${LibraryList_URL}/download/${id}`, config).then((response) => {
    const buff = Buffer.Buffer.from(response.data.file)
    // conver file to blob
    const blob = new Blob([buff], {type: 'application/pdf'})
    // create url from blob
    const url = window.URL.createObjectURL(blob)

    // fileDownload(buff, response.data.fileName)
    return blob
  })
}

export {getLibraryList, getLibraryListFiles, downloadFileLibrary, postLibraryFiles, getLibraryFiles}
