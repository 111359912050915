/* eslint-disable react-hooks/exhaustive-deps */
 
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {
  getOneFormsData,
  saveFormsData,
} from '../../../../../app/modules/apps/formsdata/core/_requests'
import {useQuery} from 'react-query'
import {postFormWithUserIdAndFormId} from '../../../../../app/modules/apps/formsUsers/core/_request'
import {useIntl} from 'react-intl'

interface IProps {
  surveyJson: object
  userId: string
  formId: string
  formsDataIdSelected: string
}
const SurveyClient: React.FC<IProps> = ({surveyJson, userId, formId, formsDataIdSelected}) => {
  const [formsDataId, setFormDataId] = useState(formsDataIdSelected)
  const surveyJSON = surveyJson
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const {user} = rootStore.authStore
  let currentPage = 0

  const intl = useIntl()

  const {data: dataResult} = useQuery(
    'formdatalist',
    async () => await getOneFormsData(formId, userId)
  )
  let answersSoFar
  if (dataResult !== undefined && dataResult !== null) {
    const result = dataResult
    Object?.entries(result).find(([key, value]) => {
      if (key === 'datafill') {
        answersSoFar = value
      }
    })
  }

  const sendData = async (questions: any) => {
    let dataFormData: object
    if (formsDataId != null) {
      dataFormData = {
        user: userId,
        form: formId,
        datafill: questions.data,
        id: formsDataId,
      }
    } else {
      dataFormData = {
        user: userId,
        form: formId,
        datafill: questions.data,
      }
    }
    currentPage = questions.currentPageNo + 1
    const requestIncomplete = await saveFormsData(dataFormData)
    if (requestIncomplete) {
      setFormDataId(requestIncomplete.id as string)
    }
  }
  const onCurrentPageChanging = async (survey: any, options: any) => {
    if (options.isPrevPage) {
      // Don't allow user to go to previous page is there are errors on page
      if (currentLang === 'en') {
        alert('you clicked the previous button, you can change the fields that you already filled ')
      } else if (currentLang === 'es') {
        alert('Presiono  el boton de previous es posible que cambie los campos que ya lleno')
      }
    }
  }

  const sendDataIsFinished = async (questions: any) => {
    let payloadForFormData
    if (formsDataId != null) {
      payloadForFormData = {
        user: userId,
        form: formId,
        datafill: questions.data,
        id: formsDataId,
      }
    } else {
      payloadForFormData = {
        user: userId,
        form: formId,
        datafill: questions.data,
      }
    }

    const payloadForFormUserIsFinished = {
      form: formId,
      user: userId,
    }

    await saveFormsData(payloadForFormData)
    await postFormWithUserIdAndFormId(payloadForFormUserIsFinished)
  }

  let currentObject: object
  let currentLang: string
  currentLang = ''
  const la = localStorage.getItem('i18nConfig') || null

  if (la) {
    currentObject = JSON.parse(la)
    Object.entries(currentObject).find(([key, value]) => {
      if (key === 'selectedLang') {
        currentLang = value
      }
    })
  }

  useEffect(() => {
    Survey.StylesManager.applyTheme('defaultV2')
  }, [])

  useEffect(() => {
    if (currentLang !== 'es' && currentLang !== 'en') {
      currentLang = 'es'
    }
  })

  return (
    <>
      <Survey.Survey
        currentPageNo={currentPage}
        sendResultOnPageNext={true}
        onPartialSend={sendData}
        data={answersSoFar}
        json={surveyJSON}
        onComplete={sendDataIsFinished}
        showProgressBar={'top'}
        locale={currentLang}
        pageNextText={intl.formatMessage({id: 'PAGE.NEXT'})}
        pagePrevText={intl.formatMessage({id: 'PAGE.PREVIOUS'})}
        completeText={intl.formatMessage({id: 'PAGE.COMPLETEPAGE'})}
        onCurrentPageChanging={onCurrentPageChanging}
        completedHtml={intl.formatMessage({id: 'PAGE.COMPLETEPAGEMESSAGE'})}
      />
    </>
  )
}

export {SurveyClient}
