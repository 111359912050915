 
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../components'
import {DeleteOutlined, EditOutlined, FunnelPlotOutlined} from '@ant-design/icons'
import React, {type Dispatch, type SetStateAction, useState} from 'react'
import {Space, Tooltip} from 'antd'
import {deleteForms, getForms} from '../../../../app/modules/apps/forms/core/_requests'
import {type Forms} from '../../../../app/modules/apps/forms/core/_models'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {ID} from 'core/helpers'
import {DisplayFormsTests} from 'app/pages/contratationApp/newUserViewContratationApp/Forms/formTest'

interface IProps {
  callRefetch?: boolean
  handleEdit?: any
  setFormSelected: Dispatch<SetStateAction<boolean | null>>
  setFormIdSelected: Dispatch<SetStateAction<string | null>>
  setFormDataSelected: Dispatch<SetStateAction<Forms | null>>
  setIsEditing: Dispatch<SetStateAction<boolean | null>>
}

const ListForms: React.FC<IProps> = ({setFormIdSelected, setIsEditing, handleEdit}) => {
  const intl = useIntl()
  const [getIndex, setGetIndex] = useState('')
  const [displayEditor, setDisplayEditor] = useState(false)
  const specificOrder: string[] = [
    '001', '002', '003', '004', '005', '006', '007', '008', '009', '010',
    '011', '012', '013', '014', '015', '016', '017', '018', '019', '020',
    '021', '022', '023', '024', '025', '026', '027', '028', '029', '030',
  ]

  const {data, status, refetch} = useQuery(
    'listforms',
    async () => await getForms('limit=50&page=1&name=')
  )

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }



  const handleEditForm = async (formId: string) => {
    const formEdit = data?.filter((temp) => temp.id === formId)[0] as Forms
    handleEdit(formEdit)
    setFormIdSelected(formId)
    setIsEditing(true)
  }

  const handleDelete = async (formId: string) => {
    await deleteForms(formId)
    refetch()
  }

  // Function to sort data based on specificOrder
  const sortForms = (forms: Forms[], order: string[]) => {
    return forms.sort((a, b) => {
      const aIndex = order.indexOf(a.data.guide)
      const bIndex = order.indexOf(b.data.guide)
      return aIndex - bIndex
    })
  }

  if (status === 'success' && data) {
    const sortedData = sortForms(data, specificOrder)

    const GetIndex = (id: ID) => {
      console.log(id, 'este es el id despues')
      sortedData?.map((form, index) => {
        if (id === form.id) {
          console.log(index, 'este es el index despues')
          console.log(form.id, 'este es el id formid')
          setGetIndex(form.id as string)
          setDisplayEditor(true)
        }
      })
    }

    return (
      <>
        {displayEditor === false ? (
          <TableContainer style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nro Guia</TableCell>
                  <TableCell>{intl.formatMessage({id: 'FORMSMANAGER.FORMSLIST'})}</TableCell>
                  <TableCell>{intl.formatMessage({id: 'FORMSMANAGER.FORMSDESCRIPTION'})}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((form: Forms) => (
                  <TableRow key={form.id}>
                    <TableCell>{form.data.guide}</TableCell>
                    <TableCell>{form.name}</TableCell>
                    <TableCell>{form.description}</TableCell>
                    <TableCell>
                      <Space>
                        <Tooltip placement='top' title={'Testear'}>
                          <Button
                            type='link'
                            onClick={() => {
                              GetIndex(form.id)
                              console.log(form.id, 'el form id')
                            }}
                            style={{backgroundColor: 'white', border: '1px solid orange'}}
                          >
                            <FunnelPlotOutlined className='rotate-180' />
                          </Button>
                        </Tooltip>
                        <Tooltip placement='top' title={'Editar'}>
                          <Button
                            type='link'
                            onClick={() => {
                              handleEditForm(`${form.id}`)
                            }}
                            style={{backgroundColor: 'white', border: '1px solid green'}}
                          >
                            <EditOutlined />
                          </Button>
                        </Tooltip>
                        <Tooltip placement='top' title={'Borrar'}>
                          <Button
                            type='link'
                            onClick={() => {
                              handleDelete(`${form.id}`)
                            }}
                            style={{backgroundColor: 'white', border: '1px solid #bf3c3c'}}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Tooltip>
                      </Space>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <DisplayFormsTests formIndex={getIndex} setOpenForm={setDisplayEditor} />
        )}
      </>
    )
  } else {
    return <>{intl.formatMessage({id: 'FORMSMANAGER.NODATA'})}</>
  }
}

export {ListForms}
