 
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Program} from 'app/modules/apps/cart/core/program/_models'
import {updateProgram} from 'app/modules/apps/cart/core/program/_request'
import {getInstitution} from 'app/modules/apps/cart/core/education/_request'
import {useQuery} from 'react-query'
import {Institution} from 'app/modules/apps/cart/core/education/_models'
import Select from 'react-select'
const API_URL = process.env.REACT_APP_API_URL
const INSTITUTION_URL = `${API_URL}`

interface EditProgramFormProps {
  editProgram: Program // Replace "Institution" with the actual type of editInstitution
  onSave: () => void
}

const EditProgramForm: React.FC<EditProgramFormProps> = ({editProgram, onSave}) => {
  console.log('Edit Program Data:', editProgram)

  const selectedIntakesOptions = editProgram.intakes.map((intake: any) => ({
    value: intake.intakeId,
    label: intake.name,
  }))
  const [selectedOptions, setSelectedOptions] = useState<any[]>(selectedIntakesOptions)

  const [tags, setTags] = useState<string[]>([])
  const [intakesLoaded, setIntakesLoaded] = useState(false)

  const {data: institutions} = useQuery('GetInsitutionss', async () => await getInstitution())

  const {data: intakes} = useQuery('GetIntakes', async () => {
    try {
      const response = await axios.get(`${INSTITUTION_URL}/intake`)
      return response.data
    } catch (error) {
      console.error('Error fetching intakes:', error)
      throw error
    }
  })

  const intakesOptions =
    intakes?.map((intake: {intakeId: any; name: any}) => ({
      value: intake.intakeId,
      label: intake.name,
    })) || []
  const {data: levels} = useQuery('GetLevels', async () => {
    const response = await axios.get(`${INSTITUTION_URL}/level`)
    return response.data
  })
  const {data: discipline} = useQuery('GetDiscipline', async () => {
    const response = await axios.get(`${INSTITUTION_URL}/discipline`)
    return response.data
  })
  const {data: type} = useQuery('Gettype', async () => {
    const response = await axios.get(`${INSTITUTION_URL}/institution-type`)
    return response.data
  })
  const [formData, setFormData] = useState({
    name: '',
    institution: {
      institutionId: '',
      name: '',
      province: '',
      country: '',
      address: '',
      type: '',
      partnership: '',
      /* owner: '' */
    },
    location: '',
    type: {
      name: '',
      id: '',
    },
    level: {
      name: '',
      id: '',
    },
    discipline: {
      name: '',
      id: '',
    },
    intakes: [],
    duration: '',
  })

  useEffect(() => {
    if (editProgram) {
      /* const selectedIntakesOptions = editProgram.intakes.map((intake: any) => ({
        value: intake.intakeId,
        label: intake.name,
      })); */
      setFormData({
        name: editProgram.name,
        institution: {
          institutionId: editProgram.institution.institutionId || '', // Handle possible undefined value
          name: editProgram.institution.name,
          province: editProgram.institution.province,
          country: editProgram.institution.country,
          address: editProgram.institution.address,
          type: editProgram.institution.type,
          partnership: editProgram.institution.partnership,
        },
        location: editProgram.location,
        type: editProgram.type,
        level: editProgram.level,
        discipline: editProgram.discipline,
        intakes: editProgram.intakes as any,

        duration: editProgram.duration,
      })

      setSelectedOptions(editProgram.intakes)
    }
  }, [editProgram])

  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()

    try {
      /* const response = await axios.patch(
        `${API_URL}/institution/updateInstitution/${editInstitution.institutionId}`,
        formData
      ); */
      const response = await updateProgram(editProgram.programId!, formData)

      console.log('Program updated:', response)
      onSave()
    } catch (error) {
      console.error('Error updating Program:', error)
    }
  }
  const duration = [
    {
      name: '1',
    },

    {
      name: '2',
    },

    {
      name: '3',
    },
    {
      name: '4',
    },
    {
      name: '5',
    },
    {
      name: '6',
    },
    {
      name: '7',
    },
    {
      name: '8',
    },
    {
      name: '9',
    },
    {
      name: '10',
    },
    {
      name: '11',
    },
    {
      name: '12',
    },
  ]

  useEffect(() => {
    if (tags.length === 0) {
      setSelectedOptions([])
      console.log('Edit selected options Data number 2:', selectedOptions)
    }
  }, [tags])

  useEffect(() => {
    if (intakes) {
      setIntakesLoaded(true)
    }
  }, [intakes])

  const handleIntakeChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions)

    const updatedIntakes = selectedOptions.map((option: any) => ({
      intakeId: option.value,
      name: option.label,
    }))

    console.log('Updated intakes:', updatedIntakes)

    setFormData((prevData) => ({
      ...prevData,
      intakes: updatedIntakes,
    }))
  }

  return (
    <div className='form-container1'>
      <form onSubmit={handleSubmit} className='custom-form1'>
        <div className='form-group1'>
          <label className='label'>Name:</label>
          <input
            className='input'
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div className='form-group1'>
          <label className='label'>Institution:</label>
          {institutions ? (
            <select
              className='input'
              name='institution'
              value={formData.institution.institutionId}
              onChange={handleChange}
            >
              <option value=''>Select an Institution</option>
              {institutions.map((institution: Institution) => (
                <option key={institution.institutionId} value={institution.institutionId}>
                  {institution.name}
                </option>
              ))}
            </select>
          ) : (
            <p>Loading institutions...</p>
          )}
        </div>
        <div className='form-group1'>
          <label className='label'>Location:</label>
          <input
            className='input'
            type='text'
            name='location'
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <label className='label'>Type:</label>
        <select className='input' name='type' value={formData.type.id} onChange={handleChange}>
          <option value=''>Select a Type</option>
          {type ? (
            type.map((type: any) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))
          ) : (
            <option disabled>Loading type...</option>
          )}
        </select>
        <label className='label'>Level:</label>
        <select className='input' name='level' value={formData.level.id} onChange={handleChange}>
          <option value=''>Select an level</option>
          {levels ? (
            levels.map((level: any) => (
              <option key={level.levelId} value={level.levelId}>
                {level.name}
              </option>
            ))
          ) : (
            <option disabled>Loading levels...</option>
          )}
        </select>

        <div className='form-group1'>
          <label className='label'>Intake:</label>
          <Select
            name='intakes'
            options={intakesOptions}
            value={selectedOptions.length > 0 ? selectedOptions : selectedIntakesOptions}
            onChange={handleIntakeChange}
            isMulti
            placeholder='Select intakes...'
          />
        </div>

        <label className='label'>Discipline:</label>
        <select
          className='input'
          name='discipline'
          value={formData.discipline.id}
          onChange={handleChange}
        >
          <option value=''>Select an Discipline</option>
          {discipline ? (
            discipline.map((discipline: any) => (
              <option key={discipline.disciplineId} value={discipline.disciplineId}>
                {discipline.name}
              </option>
            ))
          ) : (
            <option disabled>Loading Discipline...</option>
          )}
        </select>
        <div className='form-group1'>
          <label className='label'>Duration</label>
          <select
            className='input'
            name='duration'
            value={formData.duration}
            onChange={handleChange}
          >
            <option value=''>Select a Duration</option>
            {duration.map((duration) => (
              <option key={duration.name} value={duration.name}>
                {duration.name} Semesters
              </option>
            ))}
          </select>
        </div>
        {/* <div className="form-group">
          <label className="label">Owner:</label>
          <input className="input" type="text" name="owner" value={formData.owner} onChange={handleChange} />
        </div> */}
        <button type='submit' className='submit-button1'>
          Save Changes
        </button>
      </form>
    </div>
  )
}

export default EditProgramForm
