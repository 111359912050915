import {Button} from '../../../../../../components'
import IframeCalendly from '../IframeCalendly'
import React from 'react'

interface IProps {
  iframe: string
  isOpen: boolean
  closeModal: () => void
}

const ModalIframe: React.FC<IProps> = ({iframe, isOpen, closeModal}) => {
  return (
    <>
      {isOpen && (
        <div className='flex  justify-center items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none bg-gray-600 bg-opacity-90 '>
          <div className={'relative  my-6 mx-auto   w-full h-full '}>
            <div className='border-0 rounded-lg shadow-xl relative   bg-white outline-none focus:outline-none '>
              <div className='flex  items-start justify-between     '>
                <Button
                  type='button'
                  onClick={() => {
                    closeModal()
                  }}
                  width='w-full'
                >
                  Cerrar
                </Button>
              </div>
              <div className='relative p-6 flex-auto   overflow-y-auto h-full '>
                <IframeCalendly iframe={iframe} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalIframe
