import Appointments from './appointments'
import {Container} from '../../../components/Container/index'
import {useIntl} from 'react-intl'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const AppointmentsWrapper: React.FC<Props> = (props) => {
  const {ProfileImmigration} = props
  const intl = useIntl()
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.MYAPPOINTMENTS'})}>
        <Appointments ProfileImmigration={ProfileImmigration} />
      </Container>
    </>
  )
}

export {AppointmentsWrapper}
