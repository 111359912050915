/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
// import questions from './questions'
import {RootStoreContext} from '../../../stores/rootStore'
// import {styles, stylesUseEffect} from './styles/styles'
import './styles/surveyStyle.css'
import {type Forms} from '../../../app/modules/apps/forms/core/_models'
interface IProps {
  formIdSelected: string | null
  formDataSelected: Forms | null
}

const CheckListWrapper: React.FC<IProps> = ({formIdSelected, formDataSelected}) => {
  const [isClicked, setIsClicked] = useState(false)
  const storageName = 'AndresTest'
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const surveyJSON = formDataSelected?.data
  const sendData = async (questions: any) => {
    /*
    //enviar llenado a forms data
    const data = {
      form: formIdSelected,
      datafill: questions.data,
    }
    const request = await saveFormsData(data)
    */
  }

  useEffect(() => {
    Survey.StylesManager.applyTheme('defaultV2')
  }, [])

  const currentJson: object = {}
  const currentPage = 0

  const handlingPartialSend = async (questions: any) => {
    /*

    setIsClicked(true)
    var data = questions.data
    data.pageNo = questions.currentPageNo

    window.localStorage.setItem(storageName, JSON.stringify(data))

    */
  }

  /*
  var jsonString = localStorage.getItem(storageName) || null

  if (jsonString) {
    currentJson = JSON.parse(jsonString)
    Object.entries(currentJson).find(([key, value]) => {
      if (key === 'pageNo') {
        currentPage = value + 1
      }
    })
  }

  */
  return (
    <>
      <Survey.Survey
        currentPageNo={currentPage}
        sendResultOnPageNext={true}
        onPartialSend={handlingPartialSend}
        data={currentJson}
        json={surveyJSON}
        onComplete={sendData}
        showProgressBar={'top'}
        locale={user?.language}
      />
    </>
  )
}

export {CheckListWrapper}
