/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  type IComposeData,
  initialComposeData,
  IReply,
  initialReplyData,
} from '../../../../app/modules/apps/appointments/core/_models'
import {type IToUsers} from '../../../../app/pages/messages/interfaces/ITOUsers'
import ListTranslationsStaff from './ListTranslationsStaff'
import {PostConsult} from '../../../../app/pages/messages'
import ShowTranslation from '../common/ShowTranslation'
import UploadHandlerStaff from './UploadHandlerStaff'
import {useState} from 'react'
import {type ProfileImmigration} from '../../../../app/modules/apps/profile/core/_models'
import {Box} from 'core/layout/components/Box/Box'

interface Props {
  ProfileImmigration: ProfileImmigration
}

const StaffTranslation: React.FC<Props> = (props) => {
  const [uploadFiles, setUploadFiles] = useState(false)
  const {ProfileImmigration} = props
  const [list, setList] = useState(true)
  const [translation, setTranslation] = useState(false)

  const [composePost, setComposePost] = useState<boolean>(false)
  const [composepostdata, setComposePostData] = useState<IReply>(initialReplyData)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const [to, setTO] = useState<IToUsers[]>([])

  // If the user is uploading files, the translation is not shown
  const [filesUploaded, setFilesUploaded] = useState(false)
  const [translate, setTranslate] = useState('')
  const [counterToRefreshTranslatedDocument, setCounterToRefreshTranslatedDocument] =
    useState<number>(0)
  const [tabNumber, setTabNumber] = useState('')
  return (
    <div 
    // style={{width: '100%', position:'absolute'}}
    >
      {composePost ? (
        <>
          <PostConsult
            setComposePost={setComposePost}
            setCC={setTO}
            cc={[]}
            composepostdata={composepostdata}
            showShareFiles={false}
            type='translation'
            showSelectFiles={false}
            initialContent=''
            showTemplateSearch={false}
            title='Traducción'
            initialSubject={'Traducción'}
            ProfileImmigration={ProfileImmigration}
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
            setComposePostData={setComposePostData}
          />
        </>
      ) : (
        <>
          {list ? (
            <ListTranslationsStaff
              setUploadFiles={setUploadFiles}
              setTranslation={setTranslation}
              setList={setList}
              setTranslate={setTranslate}
              setComposePost={setComposePost}
              setComposePostData={setComposePostData}
              setTabNumber={setTabNumber}
            />
          ) : null}
        </>
      )}

      {/*   {uploadFiles && (
        <UploadHandlerStaff
          setUploadFiles={setUploadFiles}
          setTranslate={setTranslate}
          setFilesUploaded={setFilesUploaded}
          setList={setList}
          translate={translate}
        />
      )}  */}

      {translation ? (
        <div className='translation-wrapper-responsive' style={{display: 'flex', flexDirection: 'row'}}>
          <ShowTranslation
            uploaded={uploaded}
            setTranslation={setTranslation}
            counterToRefreshTranslatedDocument={counterToRefreshTranslatedDocument}
            setCounterToRefreshTranslatedDocument={setCounterToRefreshTranslatedDocument}
            setList={setList}
            translate={translate}
          />
          <div className='translation-wrapper' style={{marginTop: '4rem'}}>
            <h2>Adjuntador</h2>
            <UploadHandlerStaff
              setUploadFiles={setUploadFiles}
              setTranslate={setTranslate}
              setFilesUploaded={setFilesUploaded}
              setList={setList}
              translate={translate}
              setCounterToRefreshTranslatedDocument={setCounterToRefreshTranslatedDocument}
              counterToRefreshTranslatedDocument={counterToRefreshTranslatedDocument}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default StaffTranslation
