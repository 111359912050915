/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useContext} from 'react'
import {Button} from '../../../../../components'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {ServiceDetail} from '../../../../../app/pages/services/services/ServiceDetails'
import {getServicesAdmin} from '../../../../../app/modules/apps/services/core/_requests'
import {observer} from 'mobx-react'
import {useQuery} from 'react-query'

interface IProps {
  handleClose: (key: string) => void
}

const ShowServices: React.FC<IProps> = ({handleClose}) => {
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore

  const {data, status, refetch} = useQuery(
    'lisservicesuser',
    async () => await getServicesAdmin(currentUser?.id || '')
  )

  if (status === 'loading') {
    return <div>Cargando...</div>
  }
  if (status === 'error') {
    return <div>Error</div>
  }
  if (status === 'success' && data) {
    return (
      <div>
        <ServiceDetail services={data} />
      </div>
    )
  } else {
    return <div>No data</div>
  }
}

export default ShowServices
