import axios, {type AxiosResponse} from 'axios'
import {type Institution} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const INSTITUTION_URL = `${API_URL}/institution`

const getInstitution = (): Promise<Institution[]> | undefined => {
  return axios.get(`${INSTITUTION_URL}`).then((response: AxiosResponse) => {
    return response.data as Institution[]
  })
}

const createInstitution = (institution: Institution): Promise<Institution[]> | undefined => {
  return axios
    .post(`${INSTITUTION_URL}/newInstitution`, {...institution})
    .then((response: AxiosResponse) => {
      return response.data as Institution[]
    })
}

const updateInstitution = (
  institution: Institution,
  body: {}
): Promise<Institution> | undefined => {
  return axios
    .patch(`${INSTITUTION_URL}/updateInstitution/${institution.institutionId}`, {...body})
    .then((response: AxiosResponse) => {
      return response.data as Institution
    })
}

const getInstitutionTableFilter = (institution: Institution): Promise<any> => {
  return axios.get(`${INSTITUTION_URL}`, {params: institution}).then((response: AxiosResponse) => {
    return response.data // Usar response.data en lugar de "response as any"
  })
}

export {getInstitution, createInstitution, updateInstitution, getInstitutionTableFilter}
