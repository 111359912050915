/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Link} from 'components'
import {Container} from '../../../components/Container/index'
import {FC, useContext, useEffect} from 'react'
import UserMenusoptions from '../admin/users/UserMenusoptions'
import {RootStoreContext} from 'stores/rootStore'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import React from 'react'
import {
  IQueryUsers,
  IQueryUsersFilter,
  Users,
  initialIQueryUsers,
  initialIQueryUsersFilter,
} from 'app/modules/apps/users/core/_models'
import MyCLientList from '../admin/users/List/MyCLientList'
import {getContractByStaffId} from 'app/modules/apps/contract/_requests'
import {useQuery} from 'react-query'

interface Props {
  currentUser: Users | null
}
const ConsultantClientList: React.FC<Props> = (props) => {
  const {currentUser} = props
  const [ProfileImmigration, setProfileImmigration] = React.useState<ProfileImmigration>({})
  const rootStore = useContext(RootStoreContext)
  /*  const {currentUser,users,getUserClientTable} = rootStore.usersStore */
  const [search, setSearch] = React.useState<IQueryUsers>(initialIQueryUsers)
  const [us, setUs] = React.useState<any>('')
  const [searchFilter, setSearchFilter] =
    React.useState<IQueryUsersFilter>(initialIQueryUsersFilter)
  const {user: userAUTH, staff: staffAuth} = rootStore.authStore
  const {data: dataFromDatabase, status: statusFromDatabase} = useQuery(
    'allcontrByStaffN',
    async () => await getContractByStaffId(staffAuth.id)
  )

  if (searchFilter !== null) {
    console.log('el filtro', search)
    console.log('el userrrr', currentUser)
  }

  useEffect(() => {
    setTimeout(() => {
      setUs(currentUser ? currentUser.id : '')
    }, 500)
  }, [])

  return (
    <>
      <Container title='Administrador de intake rsfs'>
        <Link to='/admin/tools' className='btn  btn-primary ant-btn ant-btn-default'>
          Volver al Menu
        </Link>
        <h1 className='text-2xl mb-4'>Mis clientes</h1>
        {us !== '' ? (
          <>
            <UserMenusoptions setUs={setUs} ProfileImmigration={ProfileImmigration} />
          </>
        ) : (
          <>
            <MyCLientList
              setUs={setUs}
              search={search}
              searchFilter={searchFilter}
              setSearch={setSearch}
              searchQuery={search}
            />
            {''}
          </>
        )}
      </Container>
    </>
  )
}

export {ConsultantClientList}
