/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Container, Link} from '../../../../components'
import {
  type ImmigrationCost,
  ImmigrationCostInitValues,
} from '../../../../app/modules/apps/immigration/costs/core/_models'
import React, {useEffect, useState} from 'react'
import {
  getImmigrationCostFiles,
  saveImmigrationCost,
  updateImmigrationCost,
} from '../../../../app/modules/apps/immigration/costs/core/_requests'
import AddEditData from '../FormBuilder'
import {type Files} from '../../../../app/modules/apps/file/core/_models'
import ListCosts from './ListCosts'
import {useIntl} from 'react-intl'

interface IProps {
  title: string
}
const ImmigrationCosts: React.FC<IProps> = ({title}) => {
  const intl = useIntl()

  const [list, setList] = useState(true)
  const [data, setData] = useState<ImmigrationCost>(ImmigrationCostInitValues)
  const [files, setFiles] = useState<Files[]>([])

  useEffect(() => {
    if (files) {
      data.files = files
    }
  }, [files])

  const handleSave = (data: ImmigrationCost) => {
    if (data.id) {
      updateForms(data)
    } else {
      saveForms(data)
    }
  }

  const saveForms = async (data: ImmigrationCost) => {
    const result = await saveImmigrationCost(data)
    if (result) {
      setList(true)
    }
  }

  const updateForms = async (data: ImmigrationCost) => {
    const result = await updateImmigrationCost(data)
    if (result) {
      setList(true)
    }
  }

  const handleEdit = async (data: ImmigrationCost) => {
    const filesOptions = await getImmigrationCostFiles(data.id || '')
    console.log('🚀 ~ file: index.tsx ~ line 59 ~ handleEdit ~ filesOptions', filesOptions)
    if (filesOptions) {
      data.files = filesOptions
      setFiles(filesOptions)
    }

    setData(data)
    setList(false)
  }

  return (
    <div>
      <Container title={intl.formatMessage({id: title})}>
        <Link to='/admin/tools'>
          <Button
            className='btn  btn-primary'
            style={{
              width: '8rem',
              alignSelf: 'end',
              marginRight: '2rem',
              border: 'none',
              marginBottom: '1rem',
              gap: '0.5rem',
            }}
          >
            Regresar
          </Button>
        </Link>
        <Button
          onClick={() => {
            setData(ImmigrationCostInitValues)
            setList(!list)
          }}
          style={{width: '8rem', alignSelf: 'end', marginRight: '2rem'}}
        >
          {intl.formatMessage({id: list ? 'COMMON.ADD' : 'COMMON.BACK'})}
        </Button>
        {list && (
          <>
            <ListCosts handleEdit={handleEdit} />
          </>
        )}

        {!list && data && (
          <>
            <AddEditData
              onComplete={handleSave}
              data={data}
              showwFileSelect={true}
              files={files}
              setFiles={setFiles}
            />
          </>
        )}
      </Container>
    </div>
  )
}

export default ImmigrationCosts
