 

import React from 'react'
import {type StripeApiCalls} from '../../../../../app/modules/apps/apicalls/core/_models_stripe'

interface Props {
  data: StripeApiCalls
}

const StripeFormatData: React.FC<Props> = ({data}) => {
  return (
    <>
      <ul>
        <li>
          {data.charges.data[0].description ? data.charges.data[0].description : 'No desctiprion'}
        </li>
        <li>{data.charges.data[0].receipt_email}</li>
      </ul>
    </>
  )
}

export default StripeFormatData
