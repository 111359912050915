import {axiosErrorHandler} from 'app/modules/errors/ApiError'
import axios, {type AxiosResponse} from 'axios'
import {DocumentData} from './_models'
const API_URL = process.env.REACT_APP_API_URL
const DOCUMENTSLIST = `${API_URL}/documentlist`

const getDocuments = async (): Promise<any> => {
  const response = await axios.get(`${DOCUMENTSLIST}/all`)
  return response.data as any
}

const updateDocuments = async (id: string, docdata: object): Promise<any> => {
  return await axios
    .patch(`${DOCUMENTSLIST}/${id}`, docdata)
    .then((response: AxiosResponse<DocumentData>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const createNewDocuments = async (docdata: object): Promise<any> => {
  return await axios.post(`${DOCUMENTSLIST}`, docdata).then((d) => {
    return d.data
  })
}

const deleteDocuments = async (id: string) => {
  return await axios.delete(`${DOCUMENTSLIST}/${id}`).then((response) => {
    return response.status === 200
  })
}

export {getDocuments, updateDocuments, createNewDocuments, deleteDocuments}
