/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Space, Table, Tooltip} from 'antd'
import {useEffect, useState} from 'react'
import {type Messages} from '../../../../app/modules/apps/messages/core/_models'
import {downloadFile} from '../../../../app/modules/apps/file/core/_requests'

const {Column} = Table

interface IProps {
  files: Messages[]
}

const FilesShowTable: React.FC<IProps> = ({files}) => {
  const [percent, setPercent] = useState(-1)
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (percent === 100) {
      setDownloading(false)
    }
     
  }, [percent])

  const handleDownloadFile = (id: string) => {
    setDownloading(true)
    downloadFile(id, getProgress)
  }

  const getProgress = (progress: any) => {
    setPercent(progress)
  }

  return (
    <div>
      {/* {downloading ? (
        <>
          <Spin size='large' />

          <ProgressWraper percent={percent} />
          <LoadingBar color='#BE8575' progress={percent} height={5} />
        </>
      ) : null}   */}
      <Table
        pagination={{
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
        }}
        style={{padding: '20px', backgroundColor: 'white'}}
        className='w-full'
        dataSource={files}
        rowKey='fileId'
      >
        {files.some((record: any) => record.fileId !== null) && (
          <Column width={250} title={`${files.length} Adjuntos`} dataIndex='fileName' />
        )}

        <Column
          title=''
          render={(record: any) =>
            record.fileId && (
              <Space style={{marginBottom: 10}}>
                <Tooltip title='Descargar'>
                  <Button
                    type='link'
                    onClick={() => {
                      handleDownloadFile(record.fileId)
                    }}
                    style={{color: 'white'}}
                  >
                    Descargar
                  </Button>
                </Tooltip>
              </Space>
            )
          }
        />
      </Table>
    </div>
  )
}

export default FilesShowTable
