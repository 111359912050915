 
import {useContext} from 'react'
import {Checkbox} from 'antd'
import {type CheckboxChangeEvent} from 'antd/lib/checkbox'
import {type ImmigrationService} from '../../../../../app/modules/apps/immigration/services/core/_models'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {getImmigrationServices} from '../../../../../app/modules/apps/immigration/services/core/_requests'
import {useQuery} from 'react-query'

const ChooseImmigrationService = () => {
  const rootStore = useContext(RootStoreContext)
  const {immigrationServices, setImmigrationServices} = rootStore.composeTemplateStore
  const {data} = useQuery('ChooseImmigrationService', async () => await getImmigrationServices({}))

  const onChange = (e: CheckboxChangeEvent, immigrationservice: ImmigrationService) => {
    if (e.target.checked) {
      // add to selected array

      setImmigrationServices([
        ...immigrationServices,
        {id: immigrationservice.id, name: immigrationservice.name},
      ])
    } else {
      setImmigrationServices(
        immigrationServices.filter((item) => item.id !== immigrationservice.id)
      )
    }
  }
  return (
    <div>
      {data?.map((immigrationservice) => (
        <div key={immigrationservice.id}>
          <Checkbox
            onChange={(e) => {
              onChange(e, immigrationservice)
            }}
            name={immigrationservice.name || ''}
          >
            {immigrationservice.name}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}

export default ChooseImmigrationService
