import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import MainLayout from './MainLayout'
import MainLogin from './MainLogin'
import {type ProfileImmigration} from 'app/modules/apps/profile/core/_models'

interface Props {
  ProfileImmigration: ProfileImmigration
}
const MainRoutes: React.FC<Props> = (props) => {
  const {ProfileImmigration} = props
  return (
    <>
      <Routes>
        <Route path='main' element={<MainLayout />} />
        <Route path='login' element={<MainLogin />} />
        <Route path='dashboard' element={<Dashboard ProfileImmigration={ProfileImmigration} />} />
      </Routes>
    </>
  )
}

export default MainRoutes
