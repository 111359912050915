import {type FC, createContext, useContext, useState, type ReactNode} from 'react'
import {
  type QueryRequestContextProps,
  type QueryState,
  initialQueryRequest,
} from '../../../../../core/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
interface ContentProps {
  children: ReactNode
}
const QueryRequestProvider: FC<ContentProps> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
