import tw from 'tailwind-styled-components'

const TableHead = tw.thead`
  border-collapse: separate !important;
  border-radius: 40px !important;
  border-spacing: 12px !important;
  text-align: left !important;
  background-color: white !important;
color: black !important;

`

export {TableHead}
