import React, {useEffect, useState} from 'react'

interface IProps {
  link: string
}

const Embed: React.FC<IProps> = ({link}) => {
  const [width, setWidth] = useState(window.innerWidth >= 1200 ? 1200 : window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight - 20)
  const updateDimensions = () => {
    let width = window.innerWidth
    const height = window.innerHeight - 200
    if (width >= 960) {
      width = 960
    }

    setWidth(width)
    setHeight(height)
  }

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head?.appendChild(script)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])
  return (
    <div className='calendly-inline-widget' data-url={link} style={{minWidth: width, height}} />
  )
}

export default Embed
