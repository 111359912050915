 
import axios, { type AxiosResponse } from 'axios'
import { type IQueryUsers } from '../users/core/_models'
import {
  type Contracts,
  type ContactsQueryResponse,
  type ContractsTemps,
  type ContactsTempsQueryResponse,
  type ContractFromDataBaseQueryResponse,
} from './_models'
const API_URL = process.env.REACT_APP_API_URL
const FORMSDATAS_URL = `${API_URL}/contracts`
const TEMPS = `${API_URL}/contractstemps`
const GOOGLE_DOCS_URL = `${API_URL}/googledocs`;



const getGoogleAuthUrl = async (): Promise<string> => {
  try {
    const response = await axios.get(`${GOOGLE_DOCS_URL}/auth-url`);
    return response.data as string;
  } catch (error) {
    console.error('Error fetching Google Auth URL:', error);
    throw error;
  }
};

// Crear un nuevo documento basado en una plantilla
const createGoogleDocument = async (
  templateId: string,
  clientData: object
): Promise<string> => {
  try {
    const response = await axios.post(`${GOOGLE_DOCS_URL}/create`, {
      templateId,
      clientData,
    });
    return response.data.documentId as string;
  } catch (error) {
    console.error('Error creating Google document:', error);
    throw error;
  }
};

// Publicar el documento y obtener el embed link
const publishGoogleDocument = async (documentId: string): Promise<string> => {
  try {
    const response = await axios.post(`${GOOGLE_DOCS_URL}/publish/${documentId}`);
    return response.data.embedLink as string;
  } catch (error) {
    console.error('Error publishing Google document:', error);
    throw error;
  }
};

const saveContract = async (contract: object) => {
  return await axios.post(`${FORMSDATAS_URL}`, contract).then((d) => {
    return d.data
  })
}

const saveFormat = async (id:string,formatted: object) => {
  return await axios.post(`${TEMPS}/format`, {
    id:id,
    formatted:formatted
  }).then((d) => {
    return d.data
  })
}

const saveContractTemps = async (contractTemps: object) => {
  return await axios.post(`${TEMPS}`, contractTemps).then((d) => {
    return d.data
  })
}
const getFromBackEndContracts = async (params: string): Promise<Contracts[]> => {
  return await axios
    .get(`${FORMSDATAS_URL}/mycontracts?${params}`)
    .then((d: AxiosResponse<AxiosResponse>) => {
      return d.data.data as Contracts[]
    })
}
const getTemps = async (email: string): Promise<ContractsTemps[]> => {
  return await axios.post(`${TEMPS}/mycontracts`, email).then((d: AxiosResponse<AxiosResponse>) => {
    return d.data.data as ContractsTemps[]
  })
}

const getTempsByMatchId = async (matchid: string): Promise<ContractsTemps> => {
  return await axios.get(`${TEMPS}/getonewithmatch/${matchid}`).then((d: AxiosResponse<AxiosResponse>) => {
    return d.data as any
  })
}
const getTempsAnwersById = async (tempsId: string): Promise<ContractsTemps[]> => {
  return await axios
    .get(`${TEMPS}/getonewithid/${tempsId}`)
    .then((d: AxiosResponse<ContactsTempsQueryResponse>) => {
      return d.data as ContractsTemps[]
    })
}
const getAllContractTemps = async (params: string): Promise<ContractsTemps[]> => {
  return await axios
    .get(`${TEMPS}?${params}`)
    .then((d: AxiosResponse<ContactsTempsQueryResponse>) => {
      return d.data.data as ContractsTemps[]
    })
}

const getContractTempsNew = async (params: any): Promise<any> => {
  console.log("params",params)
  try {
    const response: AxiosResponse<any> = await axios.get(`${TEMPS}/temps`, {
      params: params 
    });
    return response.data as any;
  } catch (error) {
    console.error('Error fetching contract temps:', error);
    throw error;
  }
};
const getAllContractsTempsTemps = (
  search: IQueryUsers
): Promise<ContactsTempsQueryResponse> | undefined => {
  return axios
    .post(`${TEMPS}/contractstempslist`, { ...search })
    .then((response: AxiosResponse<ContactsTempsQueryResponse>) => response.data)
}

const getContractByUserId = async (userId: string): Promise<any> => {
  return await axios
    .get(`${FORMSDATAS_URL}/my/${userId}`)
    .then((d: AxiosResponse<ContractFromDataBaseQueryResponse>) => {
      return d as any
    })
}

const getContractByUserEmailAllTable = async (userId: string): Promise<any> => {
  return await axios
    .get(`${FORMSDATAS_URL}/myAllTable/${userId}`)
    .then((d: AxiosResponse<ContractFromDataBaseQueryResponse>) => {
      return d as any
    })
}

const getContractOnlyWithMatchId = async (userId: string): Promise<any> => {
  return await axios
    .get(`${FORMSDATAS_URL}/mycontractsmatchid/${userId}`)
    .then((d: AxiosResponse<ContractFromDataBaseQueryResponse>) => {
      return d as any
    })
}

const getContractByUser = async (userId: string): Promise<any> => {
  return await axios
    .get(`${FORMSDATAS_URL}/allData/${userId}`)
    .then((d: AxiosResponse<ContractFromDataBaseQueryResponse>) => {
      return d as any
    })
}

const getContractByUserForStepOne = async (userId: string): Promise<any> => {
  return await axios
    .get(`${FORMSDATAS_URL}/allDatastepone/${userId}`)
    .then((d: AxiosResponse<ContractFromDataBaseQueryResponse>) => {
      return d as any
    })
}

const getContractByStaffId = async (userId: string): Promise<any> => {
  return await axios
    .get(`${FORMSDATAS_URL}/contracts/${userId}`)
    .then((d: AxiosResponse<ContractFromDataBaseQueryResponse>) => {
      return d as any
    })
}

const getAnswerByEmail = async (userEmail: string): Promise<ContractsTemps[]> => {
  return await axios
    .get(`${TEMPS}/mycontracts/${userEmail}`)
    .then((d: AxiosResponse<ContactsTempsQueryResponse>) => {
      return d.data as ContractsTemps[]
    })
}

const getAnswersByUserId = async (userId: string): Promise<Contracts[]> => {
  return await axios
    .get(`${TEMPS}/mycontracts/${userId}`)
    .then((d: AxiosResponse<ContactsQueryResponse>) => {
      return d.data.data as Contracts[]
    })
}

export {
  getFromBackEndContracts,
  getContractByUserId,
  getAnswersByUserId,
  getTemps,
  getAnswerByEmail,
  saveContract,
  saveContractTemps,
  getAllContractsTempsTemps,
  getContractByUser,
  getTempsAnwersById,
  getAllContractTemps,
  getContractByStaffId,
  getTempsByMatchId,
  getContractOnlyWithMatchId,
  getContractByUserEmailAllTable,
  getContractByUserForStepOne,
  saveFormat,
  getContractTempsNew,
  getGoogleAuthUrl,
  createGoogleDocument,
  publishGoogleDocument,
}
