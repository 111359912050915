/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react'
import { AsideMenuItem, Link } from '../../../../components'
import { RootStoreContext } from '../../../../stores/rootStore'
import { observer } from 'mobx-react'
import HomeIcon from './icons/HomeIcon.svg'
import AvatarProfile from 'app/modules/accounts/components/settings/cards/Avatar/AvatarProfile'
import { useQuery } from 'react-query'
import { getTimeLineByUserId } from 'app/modules/apps/timeline/_requests'
import { getAllTranslationUser } from 'app/modules/apps/translations/core/_requests'
import { getLibraryFiles } from 'app/modules/apps/library/core/_requests'
import { IApointments } from 'app/modules/apps/appointments/core/_models'
import { getAdminAppointmentsNew, getAdminAppointmentsUser } from 'app/modules/apps/appointments/core/_requests'
import { RoleEnum } from 'auth/models/User/UserModel'
import { getAssociatedAccounts } from 'app/modules/apps/users/core/_requests'

interface Props {
  initial?: string
  isOpenProfile?: boolean
  setIsOpenProfile?: (isOpenProfile: boolean) => void
}

const AsideMenuMainUser: React.FC<Props> = () => {
  const rootStore = useContext(RootStoreContext)
  const { user, logout, changeAssociateEmail } = rootStore.authStore
  const { contracts, loadingContracts } = rootStore.authStore
  const { countMessagesNotRead } = rootStore.messageStore
  const { loadMenus } = rootStore.menustore
  const [isClient, setIsClient] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [associatedEmails, setAssociatedEmails] = useState<string[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [hasFetched, setHasFetched] = useState(false)
  useEffect(() => {
    const fetchAssociatedEmails = async () => {
      if (user?.id) {
        try {
          const data = await getAssociatedAccounts(user.id)
          if (Array.isArray(data)) {
            setAssociatedEmails(data.map(account => account.usertwo.email))
          } else {
            throw new Error('Failed to fetch associated emails')
          }
        } catch (error) {
          console.error('Error fetching associated emails:', error)
        }
      }
    }

    fetchAssociatedEmails()
  }, [user?.id])

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState)
  }

  const handleEmailClick = (email: string) => {
    changeAssociateEmail(email)
    setIsDropdownOpen(false) // Cerrar el dropdown después de seleccionar un email
  }

  const truncateEmail = (email: string, maxLength: number) => {
    return email.length > maxLength ? `${email.substring(0, maxLength)}...` : email
  }

  const [query, setQuery] = useState<IApointments | null>(null)


  useEffect(() => {
    if (user?.id) {
      const initialDate: IApointments = {
        startDate: '',
        endDate: '',
        page: 1,
        limit: 50,
        search: '',
        email: '',
        userId: user?.role?.id === RoleEnum.user && user.id ? user.id : '',
      }
      setQuery(initialDate)
      setHasFetched(true) 
    }
  },  [user?.id, hasFetched])

  const { data: translationData } = useQuery(
    'getTransList',
    async () => await getAllTranslationUser()
  )

  const { data: libraryData } = useQuery(
    'getLibraryList',
    async () => {
      if (user?.id) {
        return await getLibraryFiles(user.id)
      } else {
        return []
      }
    }
  )

  const { data } = useQuery(
    'getTimeLine',
    async () => {
      const timelineData = await getTimeLineByUserId(user && user?.id ? user.id.toString() : '')
      return timelineData
    },
    { enabled: user !== undefined, cacheTime: 0 }
  )

  const { data: appointments } = useQuery(
    'adminappointments',
    async () => await getAdminAppointmentsUser(query),
    {
      enabled: query !== null && !!user?.id && hasFetched, 
      retry: false,
      cacheTime: 0,
    }
  )
  

  useEffect(() => {
    loadMenus()
  }, [])

  useEffect(() => {
    if (user?.role?.name === 'User') {
      setIsClient(true)
    }
  }, [user?.role?.name])

  const handleItemClick = (option: string) => {
    setSelectedOption(option)
  }

  return (
    <>
      <div className='flex items-start ml-4 mt-4 mb-8 gap-2' style={{marginLeft: '24px'}}>
        <div className=''>
          <AvatarProfile initialImage='' size={40} />
        </div>
        <div className='flex flex-col items-start'>
          <h3 className='font-bold' style={{ fontSize: '14px', margin: '0' }}>
            {user?.firstName}
          </h3>
          <h3 className='font-bold' style={{ fontSize: '14px', margin: '0' }}>
            {user?.lastName}
          </h3>
          <p className=' text-gray-500' style={{ fontSize: '12px', margin: '0' }}>
            {user?.email.substring(0, 20)}...
          </p>
          {isClient ? (
            <div className='step-badge-active-success2 mt-2'>
              <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0', color: '#1A8245' }}>Cliente</p>
            </div>
          ) : (
            <div className='step-badge-active-success2'>
              <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0', color: '#1A8245' }}>Admin</p>
            </div>
          )}

          <div style={{ marginLeft: '0 ', marginTop:'24px', fontSize:'12px' }}>
            <div className='dropdown-container'>
              <label className='dropdown-label'>Viendo cuenta</label>
              <div className='dropdown' style={{ cursor: 'pointer' }}>
                <div className='dropdown-selected' onClick={toggleDropdown}>
                  {truncateEmail(user?.email ?? '', 30)}
                  <span className='dropdown-arrow'>
                    <img
                      src={isDropdownOpen ? '/media/svg/menu-icons/chevron-up.svg' : '/media/svg/menu-icons/chevron-down.svg'}
                      alt='Toggle Dropdown'
                    />
                  </span>
                </div>
                {isDropdownOpen && (
                  <div className='dropdown-menu'>
                    {associatedEmails.map(email => (
                      <div
                        key={email}
                        className='dropdown-item'
                        onClick={() => handleEmailClick(email)}
                      >
                        {truncateEmail(email, 20)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/app/menu/HomeIcon.svg'
          title='Tablero'
          fontIcon='bi-app-indicator'
          IconName={<img width={15} src={HomeIcon} alt='Menu' />}
          children={undefined}
        />
      </div>

      {loadingContracts === false && contracts && contracts.length > 0 &&
        <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
          <AsideMenuItem
            to='/myvisa/proccess'
            icon='/media/icons/app/menu/VisaProccess.svg'
            title='Proceso de visa'
            fontIcon='bi-app-indicator'
            IconName={<img width={15} src='/media/icons/app/menu/VisaProccess.svg' alt='Menu' />}
            children={undefined}
          />
        </div>}
      {appointments && appointments.data && appointments.data.length > 0 && (
        <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
          <AsideMenuItem
            to='appointments'
            icon='/media/icons/app/menu/calender-alt-1.svg'
            title='Citas'
            fontIcon='bi-app-indicator'
            IconName={<img width={15} src='/media/icons/app/menu/calender-alt-1.svg' alt='Menu' />}
            children={undefined}
          />
        </div>
      )}
      {libraryData && libraryData.length > 0 && (
        <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
          <AsideMenuItem
            to='library'
            icon='/media/icons/app/menu/Library.svg'
            title='Biblioteca'
            fontIcon='bi-app-indicator'
            IconName={<img width={15} src='/media/icons/app/menu/Library.svg' alt='Menu' />}
            children={undefined}
          />
        </div>
      )}
      {translationData && translationData.length > 0 && (
        <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
          <AsideMenuItem
            to='translation'
            icon='/media/icons/app/menu/world.svg'
            title='Traducciones'
            fontIcon='bi-app-indicator'
            IconName={<img width={15} src='/media/icons/app/menu/world.svg' alt='Menu' />}
            children={undefined}
          />
        </div>
      )}
      {data && data.length > 0 && (
        <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
          <AsideMenuItem
            to='timeline'
            icon='/media/icons/app/menu/flag-alt.svg'
            title='Linea de tiempo'
            fontIcon='bi-app-indicator'
            IconName={<img width={15} src='/media/icons/app/menu/flag-alt.svg' alt='Menu' />}
            children={undefined}
          />
        </div>
      )}
      <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
        <AsideMenuItem
          to='/messages'
          icon='/media/icons/app/menu/Library.svg'
          title='Mensajes'
          fontIcon='bi-app-indicator'
          IconName={<img width={15} src='/media/icons/app/menu/Envelope.svg' alt='Menu' />}
          newMessages={countMessagesNotRead}
          children={undefined}
        />
      </div>
      <div className='sidebar-menu-item' onClick={() => { handleItemClick('') }}>
        <AsideMenuItem
          to='/cart'
          icon='/media/icons/app/menu/Library.svg'
          title='Tienda'
          fontIcon='bi-app-indicator'
          IconName={<img width={15} src='/media/icons/app/menu/Tienda.svg' alt='Menu' />}
          newMessages={countMessagesNotRead}
          children={undefined}
        />
      </div>
      <div className='flex flex-col items-start justify-start pt-2 mt-2 ml-2 mb-4'
        style={{ borderTop: '1px solid #DFE4EA' }}>
        <div
          className={selectedOption === 'perfil' ? 'svg-fill-color w-full h-9 flex items-center pl-4' : 'svg-fill-color2 w-full h-9 flex items-center pl-4'}
          onClick={() => {
            handleItemClick('perfil')
          }}>
          <Link
            className='w-full'
            to={'/crafted/account/settings'}>
            <p
              style={{ fontSize: '14px', fontWeight: 'normal', width: '100%', margin: '0' }}
              className={selectedOption === 'perfil' ? 'menu-color-text2 menu-color-text3 ' : 'menu-color-text2 '}
            >Mi perfil</p>
          </Link>
        </div>
        <div
          className={selectedOption === 'tecnico' ? 'svg-fill-color w-full h-9 flex items-center pl-4' : 'svg-fill-color2 h-9 w-full flex items-center pl-4'}
          onClick={() => handleItemClick('tecnico')}>
          <Link
            className='w-full'
            to={'/support'}>
            <p
              style={{ fontSize: '14px', fontWeight: 'normal', width: '100%', margin: '0' }}
              className={selectedOption === 'tecnico' ? 'menu-color-text2 menu-color-text3' : 'menu-color-text2'}
            >Soporte Técnico</p>
          </Link>
        </div>
        <div
          className={selectedOption === 'password' ? 'svg-fill-color w-full h-9 flex items-center pl-4' : 'svg-fill-color2 h-9 w-full flex items-center pl-4'}
          onClick={() => handleItemClick('password')}>
          <Link
            className='w-full'
            to={'/changePassword'}>
            <p
              style={{ fontSize: '14px', fontWeight: 'normal', width: '100%', margin: '0' }}
              className={selectedOption === 'password' ? 'menu-color-text2 menu-color-text3' : 'menu-color-text2'}
            >Cambiar contraseña</p>
          </Link>
        </div>
        <p className='menu-color-text2 svg-fill-color2 pt-2 h-9 flex items-center flex items-center pl-4'
          style={{ cursor: 'pointer', fontSize: '14px', width: '100%', borderTop: '1px solid #DFE4EA', fontWeight: 'normal', margin: '0' }}
          onClick={logout}>Cerrar sesión</p>
      </div>
    </>
  )
}

export default observer(AsideMenuMainUser)
