// CustomerFeedbackForm.tsx
import React, { useEffect, useState } from 'react';


import { getSurvey, saveSurvey } from 'app/modules/apps/translations/core/_requests';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';



interface IProps {

  user: any 
  translationId: any
}
const CustomerFeedbackForm: React.FC<IProps> = (user) => {

  const { translationId} = useParams<{translationId : string }>();
  console.log( "translationId", translationId );
  const [rating1, setRating1] = useState<number | null>(null);
  const [rating2, setRating2] = useState<number | null>(null);
  const [rating3, setRating3] = useState<number | null>(null);
  const [rating4, setRating4] = useState<number | null>(null);
  const [rating5, setRating5] = useState<number | null>(null);


  const [comments, setComments] = useState<string>('');
  const [feedbackData, setFeedbackData] = useState<Array<{ stars: number; comment: string }>>([]);



  const handleStarClick = (value: number) => {
    setRating1(value);
  };
  const handleStarClick2 = (value: number) => {
    setRating2(value);
  };
  const handleStarClick3 = (value: number) => {
    setRating3(value);
  };

  const handleStarClick4 = (value: number) => {
    setRating4(value);
  };

  const handleStarClick5 = (value: number) => {
    setRating5(value);
  };
  const navigate = useNavigate()
  const handleSubmit = async () => {
    if (rating1 !== null) {
      console.log(user)
      const feedbackItem = {
        stars: rating1,
        stars2: rating2,
        stars3: rating3,
        stars4: rating4,
        stars5: rating5,
        comment: comments,
        user: user,
        translation: translationId
      };

      const updatedFeedbackData = [...feedbackData, feedbackItem];
      setFeedbackData(updatedFeedbackData);
  
     { user &&  console.log(updatedFeedbackData, 'feedbackData' , user);}
  
      // Ahora utiliza el nuevo valor en la llamada a la función asincrónica
      const surveyResponse = await saveSurvey(updatedFeedbackData);
      console.log(surveyResponse);

      /*   setRating1(null);
        setRating2(null);
        setComments(''); */
    } else {
      alert('Por favor, selecciona una calificación antes de enviar.');
    }
  };
  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await getSurvey(translationId);
  
        if (response && response.length > 0) {
          // Tomamos el primer elemento del array (si hay más de uno)
          const existingSurveyData = response[0].survey;
  
          if (existingSurveyData && existingSurveyData.length > 0) {
            // Tomamos el primer elemento del array (si hay más de uno)
            const surveyItem = existingSurveyData[0];
  
            setRating1(surveyItem.stars || null);
            setRating2(surveyItem.stars2 || null);
            setRating3(surveyItem.stars3 || null);
            setRating4(surveyItem.stars4 || null);
            setRating5(surveyItem.stars5 || null);
            setComments(surveyItem.comment || '');
          }
        }
      } catch (error) {
        console.error('Error fetching survey data:', error);
      }
    };
  
    fetchSurveyData();
  }, [translationId]);
  
   

  return (
    <div className="customer-satisfaction-form">
       <ArrowLeftOutlined
            className=' p-2 hover:bg-gray-200 hover:rounded-lg'
            onClick={() => navigate(-1)}
          />
      <img src="/media/newdesign/07-logo-horizontal-color.svg" alt="Logo" className="logo" />
      <div style={{ marginBottom: '30px', fontSize: '30px' }}>
        <b>Por favor cuéntenos como evalúa los siguientes aspectos del servicio traducción</b></div>
      <p>Comunicación clara con traductor (de haber sido necesario)</p>
      <div className="rating-container">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`star ${value <= (rating1 || 0) ? 'selected' : ''}`}
            onClick={() => handleStarClick(value)}
          >
            ★
          </span>
        ))}
      </div>
      <p>Traducción precisa:</p>
      <div className="rating-container">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`star ${value <= (rating2 || 0) ? 'selected' : ''}`}
            onClick={() => handleStarClick2(value)}
          >
            ★
          </span>
        ))}
      </div>
      <p>Formatos:</p>
      <div className="rating-container">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`star ${value <= (rating3 || 0) ? 'selected' : ''}`}
            onClick={() => handleStarClick3(value)}
          >
            ★
          </span>
        ))}
      </div>

      {/* Pregunta 4 */}
      <p>Procesos dentro de los tiempos predeterminados por servicio:
      </p>
      <div className="rating-container">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`star ${value <= (rating4 || 0) ? 'selected' : ''}`}
            onClick={() => handleStarClick4(value)}
          >
            ★
          </span>
        ))}
      </div>

      {/* Pregunta 5 */}
      <p>Preguntas resueltas:</p>
      <div className="rating-container">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`star ${value <= (rating5 || 0) ? 'selected' : ''}`}
            onClick={() => handleStarClick5(value)}
          >
            ★
          </span>
        ))}
      </div>
      <div className="form-container">
        <textarea
          className="comments-input"
          placeholder="Comentarios adicionales"
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <button className="submit-button" onClick={handleSubmit}>
          Enviar
        </button>
      </div>

   
    </div>
  );
};

export default CustomerFeedbackForm;
