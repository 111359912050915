import {type FormsData, type FormsDataQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'
import {type ID} from '../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL
const FORMSDATAS_URL = `${API_URL}/formsdatas`

const saveFormsData = async (formsdata: object) => {
  return await axios.post(`${FORMSDATAS_URL}`, formsdata).then((d) => {
    return d.data
  })
}

const saveFormsFromProductData = async (formsdata: object) => {
  return await axios.post(`${FORMSDATAS_URL}/saveanddelete`, formsdata).then((d) => {
    return d.data
  })
}

const getFormsData = (params: string | null): Promise<FormsDataQueryResponse> | null => {
  return axios
    .get(`${FORMSDATAS_URL}?${params}`)
    .then((d: AxiosResponse<FormsDataQueryResponse>) => {
      return d.data
    })
}

const getOneFormsData = (formid: string | null, userId: string): Promise<FormsData[]> | null => {
  return axios
    .get(`${FORMSDATAS_URL}/getOne/${formid}/${userId}`)
    .then((d: AxiosResponse<FormsDataQueryResponse>) => {
      return d.data as FormsData[]
    })
}

const getFormsCompletedData = (userId: string): Promise<any> | null => {
  return axios
    .get(`${FORMSDATAS_URL}/getFormData/${userId}`)
    .then((d: AxiosResponse<FormsDataQueryResponse>) => {
      return d.data as FormsData[]
    })
}

const getFormsCompletedDataById = (id: string): Promise<any[]> | null => {
  return axios
    .get(`${FORMSDATAS_URL}/getFormDataId/${id}`)
    .then((d: AxiosResponse<FormsDataQueryResponse>) => {
      return d.data as any[]
    })
}

const getFormsDataWithExtraInfo = (): Promise<FormsData[]> | null => {
  return axios
    .get(`${FORMSDATAS_URL}/findFormDataWithExtraInfo`)
    .then((d: AxiosResponse<FormsDataQueryResponse>) => {
      return d.data as FormsData[]
    })
}

const updateFormWithData = async (id: string, formsdata: object) => {
  return await axios
    .patch(`${FORMSDATAS_URL}/${id}`, formsdata)
    .then((response: AxiosResponse<FormsDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const deleteFormsData = async (formsdataId: ID) => {
  return await axios.delete(`${FORMSDATAS_URL}/${formsdataId}`).then((response) => {
    return response.data as FormsData
  })
}
export {
  saveFormsData,
  getFormsData,
  getOneFormsData,
  updateFormWithData,
  getFormsDataWithExtraInfo,
  deleteFormsData,
  getFormsCompletedData,
  getFormsCompletedDataById,
  saveFormsFromProductData
}
