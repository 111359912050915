/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useEffect} from 'react'
import CalendlyCards from './CalendlyCards'
import {LoaderModal} from '../../../../../../components'
import {getCalendly} from '../../../../../../app/modules/apps/calendly/core/_requests'
import {useQuery} from 'react-query'

interface IProps {
  date: Date
  setUrl: (url: string) => void
}

const ListStaffsCalendly: React.FC<IProps> = ({date, setUrl}) => {
  const [dateString, setDateString] = React.useState('')
  const [timezone, setTimezone] = React.useState('')

  const {data, status, isFetching, refetch} = useQuery(
    'getAllStaffs',
    async () => await getCalendly(dateString, timezone),
    {
      enabled: false,
    }
  )

  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [])

  useEffect(() => {
    if (timezone !== '' && dateString !== '') {
      refetch()
    }
  }, [dateString])

  useEffect(() => {
    setDateString(date.toISOString())
  }, [date])

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2   overflow-y-auto overflow-x-hidden max-h-screen '>
      {isFetching && <LoaderModal />}

      {data != null && data.length > 0 ? (
        <>
          {data.map((item, index) => (
            <div key={index}>
              <CalendlyCards calendly={item} timezone={timezone} setUrl={setUrl} />
            </div>
          ))}
        </>
      ) : (
        <>No hay citas para este día {dateString.split('T')[0]} intente con otra fecha</>
      )}
    </div>
  )
}

export default ListStaffsCalendly
