/* eslint-disable react-hooks/exhaustive-deps */
import { PdfView } from "app/pages/services/services/formsManagerClient.tsx/pdfView"
import { useEffect, useState } from "react"
import MyAnswers from "./MyAnswers"
import Arrow from '../icons/arrow-left.svg'
import PostCard from '../icons/postcard (1).svg'
import Box from '../icons/box-4.svg'

const MyContractStep = (props: any) => {
    const [finalProducts, setFinalProducts] = useState<any[]>([])
    const [showAnswers, setShowAnswers] = useState<boolean>(false)

    useEffect(() => {
        if (finalProducts.length === 0) {
            const newFinalProducts: any[] = []
            const matter = props.showContractInfo.contracts_users_matter
            const matterProducts = matter.split('|||')
            const filteredMatterProducts = matterProducts.filter((item: string) => item.trim() !== '')
            const finalProductObj = { products: filteredMatterProducts } // Paso 2
            newFinalProducts.push(finalProductObj) // Almacena el objeto en el array
            setFinalProducts(newFinalProducts)
        }
    }, [props.showContractInfo.contracts_users_matter])

    return (
        <>
            {showAnswers === true ?
                <>
                    <MyAnswers contractInfo={props.showContractInfo} setShowAnswers={setShowAnswers} />
                </> :
                <>
                    <div className='w-full' style={{ paddingTop: '1rem', paddingBottom: '1rem', borderBottom: '1px solid black', marginBottom: '2rem' }}>
                        <button
                            className='close-video-btn'
                            style={{ width: '15rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}
                            onClick={() => props.setShowMainContainer(true)}>
                            <img src={Arrow} alt="Arrow" />
                            <p style={{ margin: '0' }}>VOLVER A MI PROCESO</p>
                        </button>
                        <div style={{ width: '100%', height: '1px', backgroundColor: '#DFE4EA', marginTop: '1rem', marginBottom: '1rem' }}></div>
                    </div>
                    
                    <div className='my-contract-wraper'>
                        <div className="flex flex-col w-full mb-4">
                            <h1 className='step-header-title'>Contrato nº {props.showContractInfo.contracts_users_envelope_id}</h1>
                            <p>Completado {props.showContractInfo.contracts_users_completedDate.substring(0, 10)}</p>
                        </div>
                        <div className='my-contract-container-wraper'>
                            <div>
                                <h1 className='step-header-title'>Servicio contratado</h1>
                            </div>
                            <div className='my-contract-container-main'>
                                <div className='my-contract-products-list' >
                                    {finalProducts && finalProducts.length > 0 && finalProducts.map((item: any) => (
                                        <div className='matter'>
                                            <ul key={item.id} style={{ marginTop: '2rem' }}>
                                                {item.products && item.products.length > 0 &&
                                                    item.products.map((prod: string, index: number) => {
                                                        const parts = prod.split(' - ');
                                                        return (
                                                            <li key={index} style={{ borderBottom: '1px solid #DFE4EA', paddingTop: '0.5rem', paddingBottom: '0.5rem', listStyle: 'none' }} >
                                                                <div className='flex flex-row gap-2 items-start'>
                                                                    <img src={PostCard} alt='PostCard' />
                                                                    <p style={{ color: '#111928', margin: '0' }} >{parts[0]}</p>
                                                                </div>
                                                                <p style={{ color: '#4B5563', margin: '0' }}>{parts[1]}</p>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                            <button
                                                style={{ color: '#01A9DB', display: 'flex', flexDirection: 'row', gap: '0.3rem', alignItems: 'center', justifyContent: 'center' }}
                                                onClick={() => { { setShowAnswers(true) } }}>
                                                <img src={Box} alt="Box" />
                                                <p style={{ margin: '0' }}>ver respuesta de contratación</p>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ width: '100%', minWidth: '50%' }} >
                                    <PdfView envelopeId={props.showContractInfo.contracts_users_envelope_id} />
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full items-center justify-center">
                        <button
                            className='close-video-btn'
                            style={{ width: '15rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '1rem', backgroundColor:'#111928' }}
                            onClick={() => props.setShowMainContainer(true)}>
                          
                            <p style={{ margin: '0', color:'white' }}>CONTINUAR AL PROCESO</p> <img
          alt="Arrow"
          src="/media/svg/menu-icons/arrow-right.svg" />
                        </button>
                        </div>

                
                    </div>
                </>}
        </>
    )
}

export default MyContractStep
