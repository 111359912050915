import {type Dispatch, type SetStateAction} from 'react'

export type ID = undefined | null | string

export interface PaginationState {
  page: number
  limit: 30 | 10 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}> | string
}

export interface SortState {
  sort?: string
  order?: 'asc' | 'desc'
}

export interface FilterState {
  filter?: unknown
}

export interface SearchState {
  search?: string
}

export interface Response<T> {
  data?: T
  count?: number
  hasNextPage?: boolean
  payload?: {
    message?: string
    errors?: Record<string, string[]>
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export interface QueryRequestContextProps {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 100,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {
    // comment to deactivate eslint
  },
}

export interface QueryResponseContextProps<T> {
  response?: Response<T[]> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {
  refetch: () => {
    // comment to deactivate eslint
  },
  isLoading: false,
  query: '',
}

export interface ListViewContextProps {
  selected: ID[]
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {
    // comment to deactivate eslint
  },
  onSelectAll: () => {
    // comment to deactivate eslint
  },
  clearSelected: () => {
    // comment to deactivate eslint
  },
  setItemIdForUpdate: () => {
    // comment to deactivate eslint
  },
  isAllSelected: false,
  disabled: false,
}
