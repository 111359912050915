/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {type Dispatch, type SetStateAction, useContext, useState} from 'react'
import {useQuery} from 'react-query'
import {useIntl} from 'react-intl'
import {RootStoreContext} from '../../../../../stores/rootStore'
import './zoomOutButton.css'
import {getTemps} from '../../../../../app/modules/apps/contract/_requests'
import {type Contracts} from '../../../../../app/modules/apps/contract/_models'
import {ShowTable} from './showTable'

interface IProps {
  setVisaType: Dispatch<SetStateAction<string>>
  contracts: Contracts[]
}
const ShowAnswers: React.FC<IProps> = ({setVisaType, contracts}) => {
  const [showSurvey, setShowSurvey] = useState(false)
  const [jsonDataSelected, setJsonDataSelected] = useState({})
  const [formNameSelected, setformNameSelected] = useState('')
  const [formsDataIdSelected, setFormsDataIdSelected] = useState('')
  // const [envelopeId, setEnvelopeId] = useState('')
  const [envelopesId, setEnvelopesId] = useState<Contracts[]>([])
  const [showListFormsClientTable, setShowListFormsClientTable] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const intl = useIntl()
  const {user} = rootStore.authStore
  const [numPage, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfDoc, setPdfDoc] = useState()
  const [contractsNumber, setContractsNumber] = useState<number>()
  const [contain, setContain] = useState<string>()
  const [answers, setAnswers] = useState<{}>({})
  const stringToFind = 'visa de turismo'
  // useEffect(() => {}, [answers])

  const answersVari = {}
  const {
    data: dataResult,
    status,
    isSuccess,
  } = useQuery('listformsdata', async () => await getTemps(`limit=50&page=1&email=${user?.email}`))

  // if (isSuccess && answers) {
  // console.log('primera respuesta' + dataResult[0].props[0])
  //  answersVari = dataResult[0].props[0]
  /* Object.keys(JSON.parse(dataResult[0].props[0])).forEach((key) =>
       console.log(
        'value',
        JSON.parse(dataResult[0].props[0])[key as keyof typeof answersVari] + '--KEY' + key
      )
    ) */

  /*  if (dataResult && dataResult[0] != undefined) {
      for (let i = 0; i < dataResult[0].props.length; i++) {
      }
    } */
  // }

  if (isSuccess) {
    return (
      <>
        {dataResult?.find((contract) => contract.visible) != null ? (
          /* {dataResult[0].visible === true ? ( */
          <>
            {dataResult.map((data) =>
              data.visible ? (
                data.props.map((props) => (
                  <>
                    <ShowTable data={props} contracts={contracts} setVisaType={setVisaType} />
                  </>
                ))
              ) : (
                <></>
              )
            )}
          </>
        ) : (
          <></>
        )}
      </>
    )
  } else {
    return (
      <>
        no Answers <></>{' '}
      </>
    )
  }
}
export {ShowAnswers}
