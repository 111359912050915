 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, TableCell, TableRow} from '../../../../components'
import React, {useEffect} from 'react'
import {AiFillEye, AiOutlineCloudDownload} from 'react-icons/ai'
import {type LibraryList} from '../../../../app/modules/apps/library/core/_models'
import {downloadFileLibrary} from '../../../../app/modules/apps/library/core/_requests'
import {downloadFile} from 'app/modules/apps/file/core/_requests'
import {Tooltip} from 'antd'

interface IProps {
  libraryList: LibraryList[]
  setFile: (file: any) => void
  setFileName: (fileName: string) => void
}
const API_URL = process.env.REACT_APP_API_URL
const LibraryList_URL = `${API_URL}/librarylists`

const ListDocumentsDetailsServices: React.FC<IProps> = ({libraryList, setFile, setFileName}) => {
  const [services, setServices] = React.useState<LibraryList[]>([])

  useEffect(() => {
    distinctServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryList])

  // distinct services
  const distinctServices = () => {
    // delete duplicates
    const distinctServices = libraryList.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.immigrationservice_name === thing.immigrationservice_name)
    )

    setServices(distinctServices)
  }

  const handleLookPdf = async (id: string) => {
    const file = await downloadFileLibrary(id, () => {
      // comment to deactivate eslint
    })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      file
    )
    setFile(file)
    setFileName('documento.pdf')
    // setFile(`${LibraryList_URL}/download/${id}`)
  }

  const handleDownload = async (id: string) => {
    await downloadFile(id, () => {
      // comment to deactivate eslint
    })
  }

  return (
    <>
      {libraryList.map((service: any, index) => (
        <TableRow key={index}>
          <TableCell>
            <ul className='mb-8 space-y-4 text-left '>
              <li className='flex items-center space-x-3'>
                <span>{service.name}</span>
              </li>
            </ul>
          </TableCell>
          <TableCell>{service.updated_at?.substring(0, 10)}</TableCell>
          <TableCell>
            <ul className='mb-8 space-y-4 text-left '>
              <>
                <li className='flex items-center space-x-3'>
                  <span>
                    <Tooltip placement='top' title={'Descargar'}>
                      <Button
                        outline={true}
                        onClick={async () => {
                          await handleDownload(service.file_id as string)
                        }}
                      >
                        <AiOutlineCloudDownload />
                      </Button>
                    </Tooltip>
                  </span>
                  <span>
                    <Tooltip placement='top' title={'Ver Documento'}>
                      <Button
                        outline={true}
                        onClick={async () => {
                          await handleLookPdf(service.file_id as string)
                        }}
                      >
                        <AiFillEye />
                      </Button>
                    </Tooltip>
                  </span>
                </li>
              </>
            </ul>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export default ListDocumentsDetailsServices
