/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useContext, useEffect, useState} from 'react'
import {Select, Space, Spin} from 'antd'
import {Form} from 'react-bootstrap'
import {RootStoreContext} from '../../../stores/rootStore'
import {type StatusTag} from '../../../app/modules/apps/statusTag/core/_models'
import {observer} from 'mobx-react'

const {Option} = Select
interface IProps {
  setStatusTag: (statusTag: StatusTag) => void
  defaultValue?: string
  defaultColor?: string | '#fff'
  eneable: boolean
  handleSendFeedbackEmail: any
  record: any
}

const StatusTagModule: React.FC<IProps> = ({setStatusTag, defaultValue, defaultColor, eneable, handleSendFeedbackEmail, record}) => {
  const rootStore = useContext(RootStoreContext)
  const {statustags, loadStatusTag, isLoadingStatusTag} = rootStore.statusTagStore
  const [color, setColor] = useState(defaultColor)
  const [prevStatus, setPrevStatus] = useState<string | undefined>(defaultValue);
  const [completedSwitchToggled, setCompletedSwitchToggled] = useState(false);
  useEffect(() => {
    loadStatusTag()

    defaultColor && setColor(defaultColor)
  }, [])

  useEffect(() => {
    defaultColor && setColor(defaultColor)
  }, [defaultColor])
  const handleChange = async (value: any) => {
    if (value.target.value === prevStatus) {
      return; // No change in status, do nothing
    }
  
    const statustag = statustags.find((statustag) => Number(statustag.id) === Number(value.target.value))
  
    if (statustag?.name === 'Completed' && !completedSwitchToggled) {
      await handleSendFeedbackEmail(record); // Pasa el registro a la función
      setCompletedSwitchToggled(true);
    }
  
    if (value.target.value === 'All') {
      setStatusTag({id: '0', name: 'All', color: 'default'})
      return
    }
  
    if (statustag != null) setStatusTag(statustag)
  
    setColor(statustag?.color ? statustag.color : '#fff')
  }
  


  return (
    <Space>
      {isLoadingStatusTag ? (
        <div>
          <Spin />
        </div>
      ) : (
        <Form.Select
          aria-label='Default select example'
          onChange={(value) => {
            handleChange(value)
          }}
          style={{
            width: 115,
            background: color,
            borderRadius: '20px',
            color: 'white',
            border: '1px solid #c7c7c7',
            paddingTop: '6px',
            paddingBottom: '6px',
          }}
        >
          <option style={{color: 'white', display:'flex', justifyContent:'center'}}>{defaultValue}</option>
          {eneable ? (
            <>
              {statustags.map((statustag) => (
                <option
                  key={statustag.id}
                  style={{background: statustag.color ? statustag.color : '#fff', color: 'white'}}
                  data-color={statustag.color}
                  value={statustag.id}
                >
                  {statustag.name}
                </option>
              ))}
            </>
          ) : null}
        </Form.Select>
      )}
    </Space>
  )
}

export default observer(StatusTagModule)
