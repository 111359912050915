/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons'
import React, {useContext, useEffect, useRef} from 'react'
import {Button} from '../../../../components'
import ChooseImmigrationCategory from './ChooseImmigrationService'
import ChooseImmigrationCost from './ChooseImmigrationCost'
import ChooseImmigrationElement from './ChooseImmigrationElement'
import ChooseImmigrationFund from './ChooseImmigrationFund'
import ChooseImmigrationLink from './ChooseImmigrationLink'
import ChooseImmigrationOptions from './ChooseImmigrationOptions'
import ChooseImmigrationRequirement from './ChooseImmigrationRequirement'
import {RootStoreContext} from '../../../../stores/rootStore'
import TabsTemplate from './TabsTemplate'
import {observer} from 'mobx-react'
import './style.css'
interface IProps {
  setContent: (content: string) => void
  content?: string
}

const ComposeTemplate: React.FC<IProps> = ({setContent}) => {
  const rootStore = useContext(RootStoreContext)
  const {
    immigrationServices,

    getContent,
    content,
    setImmigrationServices,
  } = rootStore.composeTemplateStore

  const [visible, setVisible] = React.useState(false)

  useEffect(() => {
    getContent()
  }, [immigrationServices])

  useEffect(() => {
    if (visible) {
      setImmigrationServices([])
    }
  }, [visible])

  const editor = useRef(null)
  // const [content, setContent] = useState('')

  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ol",
    "ul",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "|",
    "image",
    "link",
    "table",
    "|",
    "hr",
    "|",
    "fullsize",
    "selectall",
    "print",
    "|"
  ];

  const config = {
    minHeight: 300,
    size: 'large',
    readonly: false,
    spellcheck: true,
    buttons: buttons,
    indentMargin: 30,
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    tabIndex: -1,
    language: 'es',
    debugLanguage: false,
  }


  const getAllOptions = () => {
    getContent()
    setContent(content)
  }

  return (
    <>
      <Button
        className='buttonconsult'
        onClick={() => {
          setVisible(!visible)
        }}
        width={'20px'}
      >
        {visible ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </Button>

      {visible && (
        <>
          {/* <JoditEditor ref={editor} value={content} config={config} /> */}
          <div className='shadow-md m-2 p-4 border border-gray-200'>
            <div className=' flex flex-wrap    relative   '>
              <div className='w-full  xl:w-1/6   	 '>
                <label className='block text-sm font-medium text-gray-700 '>
                  <ChooseImmigrationCategory />
                </label>
              </div>

              <div className=' '>
                <TabsTemplate
                  options={<ChooseImmigrationOptions />}
                  requirements={
                    <>
                      <ChooseImmigrationRequirement />
                    </>
                  }
                  funds={
                    <>
                      <ChooseImmigrationFund />
                    </>
                  }
                  elements={
                    <>
                      <ChooseImmigrationElement />
                    </>
                  }
                  costs={
                    <>
                      <ChooseImmigrationCost />
                    </>
                  }
                  links={
                    <>
                      <ChooseImmigrationLink />
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <Button
            onClick={() => {
              getAllOptions()
            }}
          >
            Generar contenido
          </Button>
        </>
      )}
    </>
  )
}

export default observer(ComposeTemplate)
