/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {createProgram} from 'app/modules/apps/cart/core/program/_request'
import {useQuery} from 'react-query'
import {getInstitution} from 'app/modules/apps/cart/core/education/_request'
import {Institution} from 'app/modules/apps/cart/core/education/_models'
import Swal from 'sweetalert2'
import Select from 'react-select'

interface CreateProgramFormProps {
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
  onProgramCreated: () => void
}
const API_URL = process.env.REACT_APP_API_URL
const INSTITUTION_URL = `${API_URL}`
const isDropdownSelected = (value: string) => {
  return value !== ''
}
const CreateProgramForm: React.FC<CreateProgramFormProps> = ({
  showTable,
  setShowTable,
  onProgramCreated,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    institution: {
      institutionId: '',
      name: '',
      province: '',
      country: '',
      address: '',
      type: '',
      partnership: '',
      /* owner: '' */
    },
    location: '',
    type: {
      name: '',
      id: '',
    },
    level: {
      name: '',
      id: '',
    },
    discipline: {
      name: '',
      id: '',
    },
    intake: {
      name: '',
      id: '',
    },
    duration: '',
    intakes: [],

    /*    owner: '', */
  })
  const [fieldErrors, setFieldErrors] = useState({
    name: false,
    institution: false,
    location: false,
    type: false,
    level: false,
    /*     intake: false, */
    discipline: false,
    duration: false,
  })
  const [formVisible, setFormVisible] = useState(true)
  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const {data: institutions} = useQuery('GetInsitutions', async () => await getInstitution())
  const {data: intakes} = useQuery('GetIntakes', async () => {
    try {
      const response = await axios.get(`${INSTITUTION_URL}/intake`)
      return response.data
    } catch (error) {
      console.error('Error fetching intakes:', error)
      throw error
    }
  })
  const {data: levels} = useQuery('GetLevels', async () => {
    const response = await axios.get(`${INSTITUTION_URL}/level`)
    return response.data
  })
  const {data: discipline} = useQuery('GetDiscipline', async () => {
    const response = await axios.get(`${INSTITUTION_URL}/discipline`)
    return response.data
  })
  const {data: type} = useQuery('Gettype', async () => {
    const response = await axios.get(`${INSTITUTION_URL}/institution-type`)
    return response.data
  })

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    const errors: {[key: string]: boolean} = {}
    if (!formData.name) errors.name = true
    if (!isDropdownSelected(formData.institution.institutionId)) errors.institution = true
    if (!formData.location) errors.location = true
    if (!isDropdownSelected(formData.type.id)) errors.type = true
    if (!isDropdownSelected(formData.level.id)) errors.level = true
    /*     if (!isDropdownSelected(formData.intake.id)) errors.intake = true; */
    if (!isDropdownSelected(formData.discipline.id)) errors.discipline = true
    if (!formData.duration) errors.duration = true
    // ... (perform similar checks for other fields)

    if (Object.keys(errors).length > 0) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }))

      Swal.fire({
        icon: 'error',
        title: 'Form Submission Error',
        text: 'Cannot submit the form with empty fields. Please fill out all fields.',
      })
      return
    }
    /*  const dataToSend = {
      name: formData.name,
      institution: formData.institution,
      location: formData.location,
      type: formData.type,
      level: formData.level,
      intake: formData.intake,
      discipline: formData.discipline,
      duration: formData.duration,
      intakes: formData.intakes
    }; */
    try {
      /*
      const response = await axios.post(`${INSTITUTION_URL}/institution/newInstitution`, formData); */
      console.log(selectedOptions, 'selectedOptionsbefore')
      /*       formData.intakes = selectedOptions.map(option => option.intakeId) as any; */
      const response = await createProgram(formData)

      console.log('Program created:', response)

      setFormData({
        name: '',
        institution: {
          institutionId: '',
          name: '',
          province: '',
          country: '',
          address: '',
          type: '',
          partnership: '',
          /* owner: '' */
        },
        location: '',
        type: {
          name: '',
          id: '',
        },
        level: {
          name: '',
          id: '',
        },
        discipline: {
          name: '',
          id: '',
        },
        intake: {
          name: '',
          id: '',
        },
        duration: '',
        intakes: [],
      })

      setShowTable(true)
      setFormVisible(false)
      onProgramCreated()
    } catch (error) {
      console.error('Error creating program:', error)
    }
  }

  const duration = [
    {
      name: '1',
    },

    {
      name: '2',
    },

    {
      name: '3',
    },
    {
      name: '4',
    },
    {
      name: '5',
    },
    {
      name: '6',
    },
    {
      name: '7',
    },
    {
      name: '8',
    },
    {
      name: '9',
    },
    {
      name: '10',
    },
    {
      name: '11',
    },
    {
      name: '12',
    },
  ]
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [intakesLoaded, setIntakesLoaded] = useState(false)

  useEffect(() => {
    if (tags.length === 0) {
      setSelectedOptions([])
    }
  }, [tags])

  useEffect(() => {
    if (intakes) {
      setIntakesLoaded(true)
    }
  }, [intakes])

  const intakesOptions =
    intakes?.map((intake: {intakeId: any; name: any}) => ({
      value: intake.intakeId,
      label: intake.name,
    })) || []

  const handleIntakeChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions)
    setFormData((prevData) => ({
      ...prevData,
      intakes: selectedOptions.map((option: any) => ({
        intakeId: option.value,
      })),
    }))
    console.log(selectedOptions, 'selectedOptionsafter')
  }
  return (
    <div className='form-container1'>
      {formVisible && (
        <form onSubmit={handleSubmit} className='custom-form1'>
          <div className='form-group1'>
            <label className='label'>Name:</label>
            <input
              className='input'
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
            />
            {fieldErrors.name && <p className='error-message'>Name is required.</p>}
          </div>

          <div className='form-group1'>
            <label className='label'>Institution:</label>
            {institutions ? (
              <select
                className='input'
                name='institution'
                value={formData.institution.institutionId}
                onChange={handleChange}
              >
                <option value=''>Select Institution</option>
                {institutions.map((institution: Institution) => (
                  <option key={institution.institutionId} value={institution.institutionId}>
                    {institution.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>Loading institutions...</p>
            )}
            {fieldErrors.institution && <p className='error-message'>Institution is required.</p>}
          </div>
          <div className='form-group1'>
            <label className='label'>Location:</label>
            <input
              className='input'
              type='text'
              name='location'
              value={formData.location}
              onChange={handleChange}
            />
          </div>
          {fieldErrors.location && <p className='error-message'>Location is required.</p>}
          <label className='label'>Type:</label>
          <select className='input' name='type' value={formData.type.name} onChange={handleChange}>
            <option value=''>Select Type</option>
            {type ? (
              type.map((type: any) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))
            ) : (
              <option disabled>Loading type...</option>
            )}
          </select>
          {fieldErrors.type && <p className='error-message'>Type is required.</p>}
          <label className='label'>Level:</label>
          <select
            className='input'
            name='level'
            value={formData.level.name}
            onChange={handleChange}
          >
            <option value=''>Select level</option>
            {levels ? (
              levels.map((level: any) => (
                <option key={level.levelId} value={level.levelId}>
                  {level.name}
                </option>
              ))
            ) : (
              <option disabled>Loading levels...</option>
            )}
          </select>
          {fieldErrors.level && <p className='error-message'>Level is required.</p>}
          <div className='form-group1'>
            <label className='label'>Intake:</label>
            <Select
              className='SelectIns'
              name='intake'
              options={intakesOptions}
              value={selectedOptions}
              onChange={handleIntakeChange}
              isMulti
              placeholder='Select intakes'
            />
            {/*   {fieldErrors.intake && <p className="error-message">Intake is required.</p>} */}
          </div>
          <label className='label'>Discipline:</label>
          <select
            className='input'
            name='discipline'
            value={formData.discipline.name}
            onChange={handleChange}
          >
            <option value=''>Select an Discipline</option>
            {discipline ? (
              discipline.map((discipline: any) => (
                <option key={discipline.disciplineId} value={discipline.disciplineId}>
                  {discipline.name}
                </option>
              ))
            ) : (
              <option disabled>Loading Discipline...</option>
            )}
          </select>
          {fieldErrors.discipline && <p className='error-message'>Discipline is required.</p>}
          <div className='form-group1'>
            <label className='label'>Duration</label>
            <select
              className='input'
              name='duration'
              value={formData.duration}
              onChange={handleChange}
            >
              <option value=''>Select a Duration</option>
              {duration.map((duration) => (
                <option key={duration.name} value={duration.name}>
                  {duration.name} Semesters
                </option>
              ))}
            </select>
            {fieldErrors.duration && <p className='error-message'>Duration is required.</p>}
          </div>
          {/*  <div className="form-group">
          <label className="label">Owner:</label>
          <input className="input" type="text" name="owner" value={formData.owner} onChange={handleChange} />
        </div> */}
          <button type='submit' className='submit-button1'>
            Create Program
          </button>
        </form>
      )}
    </div>
  )
}

export default CreateProgramForm
