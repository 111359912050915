/* eslint-disable @typescript-eslint/no-unused-vars */

import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../core/helpers'
import {
  getPayments,
  getPaymentsPayu,
  updatePaymentsPayu,
  saveOrder,
  getFileIdByCode,
} from 'app/modules/apps/cart/core/paymentpayu/_requests'
import '../../styles/components/_thanks.scss'
const Thanks = () => {
  const getOrderModel = (
    merchant_url: any,
    user_id: any,
    payment_id: any,
    date: any,
    total: any
  ) => {
    return {
      receiptLink: merchant_url,
      date: new Date(date),
      total: total || '',
      payment_id: payment_id,
      user_id: user_id,
      status: '1',
    }
  }

  const getTranslationModel = (
    task_id: any,
    user_id: any,
    staff_id: any,
    pages: any,
    tasks_users_id: any
  ) => {
    return {
      is_finished: false,
      task_id: task_id,
      user_id: user_id,
      staff_id: staff_id,
      pages: pages,
      pages_translated: 0,
      tasks_users_id: tasks_users_id,
    }
  }

  useEffect(() => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)

    const params = new URLSearchParams(url.search)
    // console.log("params: ", params)
    const merchantId = params.get('merchantId')
    const merchantName = params.get('merchant_name')
    const merchantAddress = params.get('merchant_address')

    const telephone = params.get('telephone')
    // transactionState=4
    // lapTransactionState=APPROVED
    // message=APPROVED
    const transactionState = params.get('transactionState')
    const lapTransactionState = params.get('lapTransactionState')
    const message = params.get('message')
    const referenceCode = params.get('referenceCode')
    const reference_pol = params.get('reference_pol')

    const transactionId = params.get('transactionId')
    const description = params.get('description')
    const processingDate = params.get('processingDate')
    const buyerEmail = params.get('buyerEmail')
    const signature = params.get('signature')

    const polTransactionState = params.get('polTransactionState')
    const polResponseCode = params.get('polResponseCode')
    const lapResponseCode = params.get('lapResponseCode')
    const authorizationCode = params.get('authorizationCode')

    const total = params.get('TX_VALUE')
    const merchant_url = params.get('merchant_url')

    const jsonObject: {[key: string]: string} = {}
    params.forEach((value, key) => {
      jsonObject[key] = value
    })

    // console.log(jsonObject);

    // const fetchData = async () => {
    //   try {
    //     /* const result = await getPaymentsPayu(referenceCode || '') */
    //      const payments = await getPayments(referenceCode || '') 
    //     /* const paymentsPayu = JSON.parse(JSON.stringify(result)) */

    //     // console.log("payments:" , payments)
    //     // console.log("result 1: ", result)
    //     // console.log("json: ", paymentsPayu)

    //     /* if (result?.status == null) {
    //       const pages = 0
    //       const task_id = 'ac54f089-479e-4508-a6eb-0d7b1dc995a2'
    //       const file = await getFileIdByCode(referenceCode || '')
    //       // console.log("file: ", file)
    //       // console.log("paymentsPayu.user_id: ", paymentsPayu.user_id)
    //       const result2 = await updatePaymentsPayu(result?.id || '', {
    //         statusName: message || '',
    //         status: transactionState || '0',
    //       })

    //       const order = getOrderModel(
    //         merchant_url,
    //         paymentsPayu.user_id,
    //         payments.id,
    //         payments.created_at,
    //         paymentsPayu.amount
    //       )
    //       const orderSaved = await saveOrder(order)

    //       // (task_id:any, user_id:any, staff_id:any, pages:any, tasks_users_id:any)
    //       const translation = getTranslationModel(task_id, paymentsPayu.user_id, 0, pages, 0)
    //     } */
    //   } catch (error) {
    //     // Handle any errors that occurred during the fetch
    //   }
    // }

    // fetchData()
  }, [])

  return (
    <div>
      <div className='thankyou-page'>
        <div className='_header'>
          <div className='logo'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} />
          </div>
          <h2>Gracias!</h2>
        </div>
        <div className='_body'>
          <div className='_box'>
            <h3>
              <strong>Por favor revisa tu Email,</strong> Allí encontrarás las instrucciones para
              completar tu perfil.
            </h3>
            <p>
              Para ingresar a la plataforma de immiland haz clic en el siguiente botón
              <br />
              <br />
              <a
                className='btn btn-block'
                href='https://app.tango.us/app/workflow/Activacion-de-cuenta--9be46d2767d044228ebe7385d0945ee5'
              >
                <strong>
                  <u>Activa tu cuenta </u>
                </strong>
              </a>
              <br />
              <a className='btn btn-block' href='/'>
                <strong>
                  <u>Inicia sesión</u>
                </strong>
              </a>
            </p>
          </div>
        </div>
        <div className='_footer'>
          <p>Tienes problemas? contactanos a info@immilandcanada.com</p>
          <a className='btn' href='https://immilandcanada.com'>
            Regresar a la página principal
          </a>
        </div>
      </div>
    </div>
  )
}

export default Thanks
