/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {type Dispatch, type SetStateAction, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {RootStoreContext} from '../../../../../stores/rootStore'
import './zoomOutButton.css'
import {type Contracts} from '../../../../../app/modules/apps/contract/_models'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeaders,
  TableRow,
  Table,
} from '../../../../../components'

interface IProps {
  data: string
  setVisaType: Dispatch<SetStateAction<string>>
  contracts: Contracts[]
}
const ShowTable: React.FC<IProps> = ({data, setVisaType, contracts}) => {
  const [showSurvey, setShowSurvey] = useState(false)
  const [jsonDataSelected, setJsonDataSelected] = useState({})
  const [formNameSelected, setformNameSelected] = useState('')
  const [formsDataIdSelected, setFormsDataIdSelected] = useState('')
  // const [envelopeId, setEnvelopeId] = useState('')
  const [envelopesId, setEnvelopesId] = useState<Contracts[]>([])
  const [showListFormsClientTable, setShowListFormsClientTable] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const intl = useIntl()
  const {user} = rootStore.authStore
  const [numPage, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfDoc, setPdfDoc] = useState()
  const [contractsNumber, setContractsNumber] = useState<number>()
  const [contain, setContain] = useState<string>()
  const [answers, setAnswers] = useState<{}>({})
  const stringToFind = 'visa de turismo'

  if (JSON.parse(data)['Tipo de visa'] === 'visa de turismo') {
    console.log('aqui vtu')
    setVisaType('visa de turismo')
  } else if (JSON.parse(data)['Tipo de visa'] === 'visa de estudios') {
    setVisaType('visa de estudios')
    console.log('aqui ve')
  } else if (JSON.parse(data)['Tipo de visa'] === 'visa de trabajo') {
    setVisaType('visa de trabajo')
    console.log('aqui vt')
  }

  return (
    <div style={{marginTop: '50px', marginBottom: '50px'}}>
      <h1>Respuestas del {JSON.parse(data)['Tipo de familiar']}</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaders>Pregunta</TableHeaders>
            <TableHeaders>Respuesta</TableHeaders>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(JSON.parse(data)).map((key) => (
            <TableRow>
              {' '}
              <TableCell style={{width: '50%'}}> {key}</TableCell>{' '}
              <TableCell> {JSON.parse(data)[key as keyof typeof data]}</TableCell>{' '}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
export {ShowTable}
