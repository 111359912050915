import {type FormsQueryResponse, type FormsUsers} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const FORMSUSERS_URL = `${API_URL}/formsusers`

const postFormWithUserIdAndFormId = async (formsusersdata: object): Promise<FormsUsers[]> => {
  return await axios.post(`${FORMSUSERS_URL}`, formsusersdata)
}

const getFormsUsers = (params: string | null): Promise<FormsQueryResponse> | null => {
  return axios.get(`${FORMSUSERS_URL}?${params}`).then((d: AxiosResponse<FormsQueryResponse>) => {
    return d.data
  })
}

const getFormsWithUserId = (userId: string | null): Promise<FormsUsers[]> | null => {
  return axios.get(`${FORMSUSERS_URL}/${userId}`).then((d: AxiosResponse<FormsQueryResponse>) => {
    return d.data as FormsUsers[]
  })
}

const getFormsUsersRow = (
  formId: string | null,
  currentUserId: string
): Promise<FormsUsers[]> | null => {
  return axios
    .get(`${FORMSUSERS_URL}/getOne/${formId}/${currentUserId}`)
    .then((d: AxiosResponse<FormsQueryResponse>) => {
      return d.data as FormsUsers[]
    })
}

const deleteFormsUsers = async (formsUsersId: string) => {
  return await axios.delete(`${FORMSUSERS_URL}/${formsUsersId}`).then((response) => {
    return response.status === 200
  })
}
export {
  postFormWithUserIdAndFormId,
  getFormsWithUserId,
  getFormsUsers,
  deleteFormsUsers,
  getFormsUsersRow,
}
