import tw from 'tailwind-styled-components'

const Select = tw.select`
w-full
text-xs
bg-rosa-100
text-black
p-2
shadow-sm
no-underline
mb-3
focus:outline-none
rounded-full
border-[1px]
border-slate-400
focus:border-[1px]
focus:border-tomato-500

`
export {Select}
