export const contracts = {
  'CONTRACTS.MYCONTRACTS': 'Mis Contratos',
  'CONTRACTS.CONTRACTS': 'Contratos',
  'CONTRACTS.FORMS': 'Formularios',
  'CONTRACTS.DOCS': 'Documentos',
  'CONTRACTS.DOCSCIC': 'Documentos CIC',
  'CONTRACTS.CONTRACTSANSWERS': 'Respuestas de contratación',
  'CONTRACTS.STATUS': 'Estado',
  'CONTRACTS.MYVISA': 'Mi Proceso de visa',
  'CONTRACTS.CONTRACTLIST': 'Lista de Contratos',
  'CONTRACTS.VIEWCONTRACT': 'Ver el contrato',
  'CONTRACTS.CONTAINERCOMPLETED': 'Contratos completados',
  'CONTRACTS.CLIENTS': 'Clientes',
  'CONTRACTS.SUBJECTNAME': 'Nombre del asunto',
  'CONTRACTS.CONTRACTDESCRIPTION': 'Descripcion del contrato',
  'CONTRACTS.COMPLETED': 'Completado',
  'CONTRACTS.PERFIL': 'Profile',
  'CONTRACTS.SEARCH': 'Buscar',
  'CONTRACTS.DELETE': 'Borrar',
  'CONTRACTS.SEECONTRACT': 'Contrato',
}
