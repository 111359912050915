// funtion to convert number to currency format
export const numberCurrencyConverter = (number: number, currency: string) => {
  if (currency === 'COP') {
    return new Intl.NumberFormat('es-CO', {style: 'currency', currency: 'COP'}).format(number)
  }

  const numberString = number.toString()
  const numberStringArray = numberString.split('')
  const lastTwoDigits = numberStringArray.slice(
    numberStringArray.length - 2,
    numberStringArray.length
  )
  const firstDigits = numberStringArray.slice(0, numberStringArray.length - 2).join('')
  return new Intl.NumberFormat('en-US', {style: 'currency', currency}).format(
    Number(firstDigits + '.' + lastTwoDigits.join(''))
  )
}
