 
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import LoginWrapper from './components/LoginWrapper'
import Registration from './components/Registration'
import {Restore} from './components/Restore'
import {useEffect} from 'react'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
      <div>
        {/* begin::Content */}
        <div>
          {/* begin::Logo */}

          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}

        {/* end::Footer */}
      </div>
    </>
  )
}

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<LoginWrapper />} />

        <Route path='registration' element={<Registration />} />

        <Route path='restore/:id' element={<Restore />} />
        <Route path='confirm/:id' element={<Restore />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route index element={<LoginWrapper />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
