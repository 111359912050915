 
/* eslint-disable @typescript-eslint/no-unused-vars */

import {Button, Space, Table} from 'antd'
import React, {useState} from 'react'
import {
  getFormsNotSelected,
  saveFormsProduct,
} from '../../../../../../app/modules/apps/forms/formproducts/core/_requests'
import {type Forms} from '../../../../../../app/modules/apps/forms/core/_models'
import {type FormsProduct} from '../../../../../../app/modules/apps/forms/formproducts/core/_models'
import {type Product} from '../../../../../../app/modules/apps/cart/core/products/_models'
import {useQuery} from 'react-query'
import checkmark_white from '../../../../contratationApp/icons/checkmark_white.svg'

const {Column} = Table

interface IProps {
  product: Product
  formsproducts: FormsProduct[]
}

const AddForms: React.FC<IProps> = ({product, formsproducts}) => {
  const [showToast, setShowToast] = useState(false)
  const {data, status, refetch} = useQuery(
    'ListProductsNotSelected',
    async () => await getFormsNotSelected(product.id as string)
  )
  const [forms, setForms] = useState<Forms[]>([])

  const handleClick = async (id: string) => {
    const result = await saveFormsProduct(id, product.id as string)
    if (result) refetch()
      if (result) {
        setShowToast(true)
        setTimeout(() => {
          setShowToast(false)
        }, 3000)
      }
  }

  return (
    <div>
         {showToast === true ?
        <div className='toast-style-container'>
          <div className='toast-style'>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Guardado exitoso
          </div>
        </div> : null}
      {status === 'loading' && <div>Loading...</div>}
      {status === 'success' && data && (
        <div>
          <Table dataSource={data} rowKey='id' size='small'>
            <Column title='Form' dataIndex='name' />
            <Column title='Descripcion' dataIndex='description' />
            <Column
              title='Orden'
              key='estado'
              render={(text, record: any) => (
                <Space>
                  <input
                    style={{width: '80%'}}
                    type='number'
                    onChange={() => {
                      console.log('asignar orden')
                    }}
                  />
                </Space>
              )}
            />
            <Column
              title='asignar'
              key='estado'
              render={(text, record: any) => (
                <Space>
                  <Button
                    type='link'
                    onClick={async () => {
                      await handleClick(record.id)
                    }}
                  >
                    Asignar
                  </Button>
                </Space>
              )}
            />
          </Table>
        </div>
      )}
    </div>
  )
}
export default AddForms
