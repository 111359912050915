import { AdminAppointments } from '../appointments/adminappointments'
import { type ProfileImmigration } from 'app/modules/apps/profile/core/_models'
import MessagesDashboard from './userDasboardComponents/DashboardMessages'

import { StaffManager } from './consultantDashboardComponents/staffManager'
import { useQuery } from 'react-query'
import { getAllStaff, getStaff } from 'app/modules/apps/staff/core/_requests'
import { useContext, useEffect, useState } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { SubstituteNotification } from './consultantDashboardComponents/SubstituteNotification'
import userAlt from '../../pages/contratationApp/icons/user-alt.svg'
import checkMark from '../../pages/contratationApp/icons/checkmark-circle-green.svg'
import beach from '../../pages/contratationApp/icons/beach.svg'
import minus from '../../pages/contratationApp/icons/circle-minus.svg'
import { IntakeNotifications } from './consultantDashboardComponents/intakeNotifications'
import { DashboardConsultantSearchBar } from './consultantDashboardComponents/consultantSearchBar'

interface Props {
  ProfileImmigration: ProfileImmigration
  staffId: string
}

const DashboardConsultant: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const { ProfileImmigration, staffId } = props
  const [newActive, setNewActive] = useState(1)
  const [roll, setRoll] = useState<any>(null)
  const { data: dataStaff } = useQuery(
    'getAllStaffsss',
    async () => await getStaff(user?.id ? user.id : '')
  )
  const { data: StaffData, status: StaffStatus } = useQuery('getStf', async () => await getAllStaff())

  useEffect(() => {
    if (StaffStatus === 'success' && StaffData) {
      const types = StaffData
        .map((option: any) => option.type.trim()) // Elimina los espacios en blanco de los types
        .filter((type: string) => type !== '') // Filtra los types vacíos
        .filter((type: string, index: number, self: string[]) => self.indexOf(type) === index) // Elimina duplicados
      setRoll(types)
    }
  }, [StaffStatus, StaffData])

  return <>
    <div className="w-full">
      <SubstituteNotification />
    </div>
    <div style={{ justifyContent: 'flex-start', width: '100%' }}>
      <DashboardConsultantSearchBar />
    </div>
    <div className='secondary-cards-container'
      style={{
        justifyContent: 'flex-start',
        width: '100%'
      }}>
      { dataStaff && dataStaff[0].type !== 'intake' &&
       <div className='first-logging-timeline-card'>
        <AdminAppointments ProfileImmigration={ProfileImmigration} staffId={staffId} />
      </div>}
      <div className='first-logging-timeline-card'>
        <MessagesDashboard />
      </div>
      {dataStaff && (dataStaff[0].type === 'intake' || dataStaff[0].type === 'coo'  || dataStaff[0].type === 'caseworker') && (
  <div className='first-logging-timeline-card' style={{ overflow: 'auto', }}>
    <IntakeNotifications />
  </div>
)}

    </div>

    {dataStaff &&
      user?.role?.id === 1 &&
      dataStaff[0].type === 'coo'
      && <div className='first-logging-timeline-card' style={{ justifyContent: 'flex-start', width: '100%' }}>
        <div className='flex flex-wrap w-full justify-between mb-4'>
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingBottom: '1rem',
              borderBottom: `${newActive === 1 ? '3px solid #374151' : 'none'}`
            }}
            onClick={() => setNewActive(1)}>
            <img src={userAlt} alt="userAlt" />
            <p style={{ margin: '0' }}>Gestionar consultores</p>
          </button>
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingBottom: '1rem',
              borderBottom: `${newActive === 2 ? '3px solid #374151' : 'none'}`
            }}
            onClick={() => setNewActive(2)}>
            <img src={checkMark} alt="checkMark" />
            <p style={{ margin: '0' }}>Disponibles</p>
          </button>
          {/* <button
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingBottom:'1rem',
              borderBottom:`${newActive === 3 ? '3px solid #374151': 'none'}`
            }}
            onClick={() => setNewActive(3)}>
            <img src={checkMarkOrange} alt="" />
            <p style={{ margin: '0' }}>Ausentes</p>
          </button> */}
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingBottom: '1rem',
              borderBottom: `${newActive === 4 ? '3px solid #374151' : 'none'}`
            }}
            onClick={() => setNewActive(4)}>
            <img src={beach} alt="beach" />
            <p style={{ margin: '0' }}>Vacaciones</p>
          </button>
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingBottom: '1rem',
              borderBottom: `${newActive === 5 ? '3px solid #374151' : 'none'}`
            }}
            onClick={() => setNewActive(5)}>
            <img src={minus} alt="minus" />
            <p style={{ margin: '0' }}>Offline</p>
          </button>
        </div>
        {newActive === 1 &&
          <StaffManager />}

        {newActive === 2 &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              gap: '0.5rem'
            }}>
            {StaffData &&
              StaffData.map((option: any) => (
                <>
                  {option.offline === false ?
                    <div
                      style={{
                        width: '15rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                      }}>
                      <img src={userAlt} alt="userAlt" />
                      <p style={{ margin: '0' }}> {option.jotformName} </p>
                    </div> : null}
                </>
              ))}
          </div>}

        {/*  {newActive === 3 &&
          <div>
            lola
          </div>} */}

        {newActive === 4 &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              gap: '0.5rem'
            }}>
            {StaffData &&
              StaffData.map((option: any) => (
                <>
                  {option.offline === true ?
                    <div
                      style={{
                        width: '15rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                      }}>
                      <img src={userAlt} alt="userAlt" />
                      <p style={{ margin: '0' }}> {option.jotformName} </p>
                    </div>
                    : null}
                </>
              ))}
          </div>}

        {newActive === 5 &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              gap: '0.5rem'
            }}>
            {StaffData &&
              StaffData.map((option: any) => (
                <>
                  {option.status.name === 'Inactive' ?
                    <div
                      style={{
                        width: '15rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                      }}>
                      <img src={userAlt} alt="userAlt" />
                      <p style={{ margin: '0' }}> {option.jotformName} </p>
                    </div> : null}
                </>
              ))}
          </div>}
      </div>}
  </>
}

export { DashboardConsultant }
