import { axiosErrorHandler } from 'app/modules/errors/ApiError'
import axios, { type AxiosResponse } from 'axios'
import { UserDocumentrDataQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const DOCUMENTSLIST = `${API_URL}/immigrationuserdocuments`
const INTAKEDOCUMENTSLIST = `${API_URL}/intakeletters`
const ALLDOCS = `${API_URL}/documentlist`

const getUserDocumentsbyDocumentsId = async (id: string): Promise<any> => {
  const response = await axios.get(`${DOCUMENTSLIST}/all/${id}`)
  return response.data as any
}

const getUserDocumentsbyUserId = async (id: string): Promise<any> => {
  const response = await axios.get(`${DOCUMENTSLIST}/all/${id}`)
  return response.data as any
}


const getAllDocuments = async (): Promise<any> => {
  const response = await axios.get(`${ALLDOCS}/all`)
  return response.data as any
}

const getDocumentsbyContractId = async (id: string): Promise<any> => {
  const response = await axios.get(`${DOCUMENTSLIST}/contract/${id}`)
  return response.data as any
}

const updateDocStatus = async (id: string, status: string): Promise<any> => {
  return await axios
    .patch(`${DOCUMENTSLIST}/update/${id}`, { status: status })
    .then((response: AxiosResponse<UserDocumentrDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const updateDocComments = async (id: string, comments: string): Promise<any> => {
  return await axios
    .patch(`${DOCUMENTSLIST}/updatecomments/${id}`, { comments: comments })
    .then((response: AxiosResponse<UserDocumentrDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const postFormWithUserIdAndDocumentId = async (formsusersdata: object): Promise<any> => {
  return await axios.post(`${DOCUMENTSLIST}`, formsusersdata)
}

const deleteUserDocumentsUsers = async (formsUsersId: string) => {
  return await axios.delete(`${DOCUMENTSLIST}/${formsUsersId}`).then((response) => {
    return response.status === 200
  })
}

const updateUserDocumentWithData = async (id: string, formsdata: object) => {
  return await axios
    .patch(`${DOCUMENTSLIST}/${id}`, formsdata)
    .then((response: AxiosResponse<UserDocumentrDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

/* paquete de inttake */

const getIntakeDocumentsbyDocumentsId = async (id: string): Promise<any> => {
  const response = await axios.get(`${INTAKEDOCUMENTSLIST}/all/${id}`)
  return response.data as any
}

const getIntakeDocumentsbyContractId = async (id: string): Promise<any> => {
  const response = await axios.get(`${INTAKEDOCUMENTSLIST}/contract/${id}`)
  return response.data as any
}

const getIntakeDocumentsbyUserId = async (id: string): Promise<any> => {
  const response = await axios.get(`${INTAKEDOCUMENTSLIST}/all/${id}`)
  return response.data as any
}

const updateIntakeDocComments = async (id: string, comments: string): Promise<any> => {
  return await axios
    .patch(`${INTAKEDOCUMENTSLIST}/updatecomments/${id}`, { comments: comments })
    .then((response: AxiosResponse<UserDocumentrDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const updateIntakeDocStatus = async (id: string, status: string): Promise<any> => {
  return await axios
    .patch(`${INTAKEDOCUMENTSLIST}/update/${id}`, { status: status })
    .then((response: AxiosResponse<UserDocumentrDataQueryResponse>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const deleteIntakeDocumentsUsers = async (formsUsersId: string) => {
  return await axios.delete(`${INTAKEDOCUMENTSLIST}/${formsUsersId}`).then((response) => {
    return response.status === 200
  })
}

export {
  getUserDocumentsbyDocumentsId,
  getUserDocumentsbyUserId,
  postFormWithUserIdAndDocumentId,
  deleteUserDocumentsUsers,
  updateUserDocumentWithData,
  updateDocStatus,
  updateDocComments,
  getDocumentsbyContractId,
  getAllDocuments,

  getIntakeDocumentsbyDocumentsId,
  getIntakeDocumentsbyContractId,
  getIntakeDocumentsbyUserId,
  updateIntakeDocComments,
  updateIntakeDocStatus,
  deleteIntakeDocumentsUsers
}
