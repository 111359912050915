/* eslint-disable @typescript-eslint/no-unused-vars */
import {useContext, useEffect, useState} from 'react'
import {Content} from './components/Content'
import {Menu} from '../../components'
import {MenuComponent} from '../assets/ts/components'
import {Outlet, useLocation} from 'react-router-dom'
import {RoleEnum} from '../../auth/models/User/UserModel'
import {RootStoreContext} from '../../stores/rootStore'
import {useIntl} from 'react-intl'

const MasterLayout = () => {
  const rootStore = useContext(RootStoreContext)
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [menu, setMenu] = useState(true)
  const intl = useIntl()

  const {user, logout} = rootStore.authStore

  useEffect(() => {
    if (user != null) {
      if (user.role?.id === RoleEnum.admin) {
        setMenu(false)
      }
    }
  }, [user])

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <>
      <div className='flex flex-wrap overflow-hidden'>
        <Menu />

        {/* <div className='lg:w-5/6 bg-white w-full relative shadow-sm'> */}
        <div className='w-5/6 bg-white  relative shadow-sm'>
          <Content>
            <Outlet />
          </Content>
        </div>
      </div>
    </>
  )
}

export default MasterLayout
