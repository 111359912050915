/* eslint-disable @typescript-eslint/no-unused-vars */
import {DatePicker} from 'antd'
import {
  type IApointments,
  type IComposeData,
  initialComposeData,
  IReply,
  initialReplyData,
} from '../../../../app/modules/apps/appointments/core/_models'
import React, {useContext, useEffect, useState} from 'react'
import {AppointmentDetails} from './AppointmentDetails'

import {PostConsult} from '../../../../app/pages/messages'
import {RootStoreContext} from '../../../../stores/rootStore'
import {getAdminAppointmentsNew} from '../../../../app/modules/apps/appointments/core/_requests'
import {getTemplateById} from '../../../../app/modules/apps/templates/core/_requests'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {type ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import {RoleEnum} from 'auth/models/User/UserModel'
import {UsersAppointments} from '../UsersAppointments'
import { IToUsers } from 'app/pages/messages/interfaces/ITOUsers'

const {RangePicker} = DatePicker

interface Props {
  data?: string
  ProfileImmigration: ProfileImmigration
  staffId: string
}
const dateFormat = 'DD-MM-YYYY'

const today = new Date()
const new_date = moment(today, dateFormat).add(30, 'days')

const AdminAppointments: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {ProfileImmigration, staffId} = props

  /*   const { data: StaffData,status:StaffStatus } = useQuery('getStff', async () =>
    await getStaff(user? user.id: ''),{enabled:user!==undefined ,cacheTime:0}
  ) */

  const today: Date = new Date()

  const year: number = today.getFullYear()
  const month: number = today.getMonth() + 1 // Months are zero-based
  const day: number = today.getDate()

  // Pad single-digit months and days with a leading zero if needed
  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`
  const formattedDay: string = day < 10 ? `0${day}` : `${day}`

  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`

  const initialDate: IApointments = {
    startDate: '',
    endDate: '',
    page: 1,
    limit: 50,
    search: '',
    email: '',
    consultantId: user?.role?.id === RoleEnum.admin ? staffId : '',
    userId: user?.role?.id === RoleEnum.user && user.id ? user.id : '',
  }

  const [query, setQuery] = useState<IApointments | null>(initialDate)

  const {data, status, refetch} = useQuery(
    'adminappointmenasdts',
    async () => await getAdminAppointmentsNew(query),
    {
      retry: false,
      cacheTime: 0,
    }
  )
  const {data: dataInitial} = useQuery(
    'getTemplateByconsultaionNotes',
    async () => await getTemplateById('37e9b8ea-950a-43fa-9d97-54f86c32bde9')
  )
  const [composepost, setComposePost] = useState<boolean>(false)
  const [zone, setZone] = useState<string>('Canada/Saskatchewan')
  const [cc, setCC] = useState<IToUsers[]>([])
  const [composenote, setComposeNote] = useState<boolean>(false)
  const [composepostdata, setComposePostData] = useState<IReply>(initialReplyData)
  const [initialData, setInitialData] = useState<string>('')

  const intl = useIntl()

  useEffect(() => {
    if (dataInitial != null) {
      setInitialData(dataInitial.text || '')
    }
  }, [dataInitial])

  useEffect(() => {
    if (query != null) {
      const delayDebounceFn = setTimeout(() => {
        refetch()
      }, 500)

      return () => {
        clearTimeout(delayDebounceFn)
      }
    }
    // eslint-disable-next-line
  }, [query, data])

  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    } else {
      setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }, [user])

  if (status === 'loading' && query != null) {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const handleSearch = (e: any) => {
    setQuery({
      ...query,
      search: e.target.value,
    } as IApointments)
  }

  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: IApointments = {
          startDate: values[0].format('DD-MM-YYYY'),
          endDate: values[1].format('DD-MM-YYYY'),
          page: 1,
          limit: 100,
          search: '',
          email: '',
          consultantId: user?.id,
        }

        setQuery(params)
      }
    } catch (error) {}
  }

  if (composepost) {
    return (
      <>
        <PostConsult
          setComposePost={setComposePost}
          setCC={setCC}
          cc={cc}
          composepostdata={composepostdata}
          showShareFiles={false}
          type='post_consult'
          showSelectFiles={true}
          initialContent=''
          showTemplateSearch={true}
          title='Mensaje post consulta'
          initialSubject=''
          ProfileImmigration={ProfileImmigration}
          setComposePostData={setComposePostData}
        />
      </>
    )
  }

  if (composenote) {
    return (
      <>
        <PostConsult
          setComposeNote={setComposeNote}
          setCC={setCC}
          cc={cc}
          composepostdata={composepostdata}
          showShareFiles={true}
          type='in_consult'
          showSelectFiles={false}
          initialContent={initialData}
          showTemplateSearch={false}
          title='Detalles de la consulta'
          initialSubject='Detalles de la consulta'
          showComposeTemplate={true}
          ProfileImmigration={ProfileImmigration}
          setComposePostData={setComposePostData}
        />
      </>
    )
  }
  return (
    <>
      {/*   <>
        <div className='flex flex-wrap overflow-hidden lg:-mx-2'>
          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3'>
            <RangePicker
              defaultValue={[moment(new Date(), dateFormat), moment(new_date, dateFormat)]}
              onChange={handleDate}
              style={{
                border: '1px solid #be8575',
                width: '100%'
              }}
              onCalendarChange={handleDate}
            />
          </div>

          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3'>
            <Input className='flex' placeholder='Buscar' onChange={handleSearch} />
          </div>

          <div className='w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3'>
            <Button
              onClick={() => {
                refetch()
              }}
              style={{ width: '8rem', alignSelf: 'end', marginRight: '2rem' }}
            >
              Actualizar
            </Button>
          </div>
        </div>
      </> */}

      {data != null ? (
        <>
          {user?.role?.id === RoleEnum.admin ? (
            <AppointmentDetails
              setComposePostData={setComposePostData}
              setComposePost={setComposePost}
              setComposeNote={setComposeNote}
              appointments={data}
              zone={zone}
              ProfileImmigration={ProfileImmigration}
              setQuery={setQuery}
              query={query}
            />
          ) : (
            <UsersAppointments
              setComposePostData={setComposePostData}
              setComposePost={setComposePost}
              setComposeNote={setComposeNote}
              appointments={data}
              zone={zone}
              ProfileImmigration={ProfileImmigration}
              setQuery={setQuery}
              query={query}
              userId={user?.id ? user.id : ''}
            />
          )}
        </>
      ) : null}
    </>
  )
}

export {AdminAppointments}
