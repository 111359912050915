/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect} from 'react'
import {type CheckBoxOptions} from '../types'
import ImmigrationDetails from '../ImmigrationDetails'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {observer} from 'mobx-react'

const ChooseImmigrationRequirement = () => {
  const rootStore = useContext(RootStoreContext)
  const {
    immigrationServices,
    immigrationRequirement,
    getImmigrationRequirements,
    setImmigrationRequirementsCheked,
  } = rootStore.composeTemplateStore

  useEffect(() => {
    getImmigrationRequirements()
  }, [immigrationServices])

  const handleChange = (checkBoxOptions: CheckBoxOptions, cheked: boolean) => {
    setImmigrationRequirementsCheked(checkBoxOptions, cheked)
  }

  useEffect(() => {
    // comment to deactivate eslint
  }, [immigrationRequirement])

  return (
    <>
      {immigrationRequirement &&
        immigrationServices.map((item) => {
          return (
            <div key={item.id}>
              <ImmigrationDetails
                immigrationService={item}
                options={immigrationRequirement}
                type='immigrationRequirement'
                handleChange={handleChange}
              />
            </div>
          )
        })}
    </>
  )
}

export default observer(ChooseImmigrationRequirement)
