export const translation = {
  'TRANSLATION.TRANSLATION': 'Traduccion de documento',
  'TRANSLATION.PRIORITY': 'Prioridad',
  'TRANSLATION.CLIENT': 'Cliente',
  'TRANSLATION.CLIENTS': 'Clientes',
  'TRANSLATION.STATE': 'Estado',
  'TRANSLATION.STAFF': 'Equipo',
  'TRANSLATION.EMAIL': 'Correo electronico',
  'TRANSLATION.EXPIRATION': 'Vencimiento',
  'TRANSLATION.DELIBER': 'Fecha de entrega',
  'TRANSLATION.EXPRESS': 'via Express',
  'TRANSLATION.CREATED': 'Creado',
  'TRANSLATION.PAGES': 'Paginas',
  'TRANSLATION.TRANSLATEDPAGES': 'Paginas traducidas',
  'TRANSLATION.PENDINGPAGES': 'PENDIENTES',
  'TRANSLATION.UPLOADEXTRAS': 'Adjuntar extras',
  'TRANSLATION.UPLOAD': 'Click en el recuadro o arrastre los documentos que desea traducir',
  'TRANSLATION.TOTAL': 'Total',
  'TRANSLATION.NEXTT': 'Siguiente',
  'TRANSLATION.CLICK': 'Haga click o arrastre el archivo a esta área para cargarlo',
  'TRANSLATION.IN': 'En',
  'TRANSLATION.PAYMENTS': 'Detalles del pago',
  'TRANSLATION.NAMECARD': 'Nombre en la tarjeta de credito',
  'TRANSLATION.PAY': 'Pagar',
  'TRANSLATION.ASSIGN': 'Asignar traducción',
  'TRANSLATION.TRANSLATOR': 'Traductor',
  'TRANSLATION.UPLOADED': 'Archivos cargados',
  'TRANSLATION.DATE': 'Fecha',
  'TRANSLATION.DATECREATION': 'Fecha de creación',
  'TRANSLATION.DATEEXPIRATION': 'Fecha de creacion',
  'TRANSLATION.DESCRIPTION': 'Descripción',
  'TRANSLATION.UPLOADFILES': 'Cargar Archivos',
  'TRANSLATION.LOAD': 'Cargar Archivos Extras',
  'TRANSLATION.FILES': 'Archivos',
  'TRANSLATION.PROFILE': 'Perfil',
  'TRANSLATION.NAME': 'Nombre',
  'TRANSLATION.SELECT': 'Seleccionar',
  'TRANSLATION.RESEND': 'Reenviar email',
  'TRANSLATION.CHANGE': 'Cambiar consultor',
  'TRANSLATION.PRODUCTNAME': 'Nombre del servicio',
  'TRANSLATION.FINISHED': 'Traducción finalizadas',
  'TRANSLATION.DELIVERED': 'Entregado',
  'TRANSLATION.NETSUITESTATUS': 'Estado de NetSuite',
  'TRANSLATION.ERRORUPLOADCOUNT':
    'Error, las paginas cargadas no coinciden con las paginas a traducir, por favor verifique',
  'TRANSLATION.ASKDELETEFILE': '¿Esta seguro que desea eliminar este archivo?',
  'TRANSLATION.ASKDELETEFILEBTNCONFIRM': 'Eliminar',
  'TRANSLATION.ASKDELETEFILEBTNCANCEL': 'Cancelar',
}
