/* eslint-disable react-hooks/exhaustive-deps */
 
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Container, Link} from '../../../../components'
import {
  type ImmigrationRequirement,
  ImmigrationRequirementInitValues,
} from '../../../../app/modules/apps/immigration/requirements/core/_models'
import React, {useEffect, useState} from 'react'
import {
  getImmigrationRequirementFiles,
  saveImmigrationRequirement,
  updateImmigrationRequirement,
} from '../../../../app/modules/apps/immigration/requirements/core/_requests'
import AddEditData from '../FormBuilder'
import {type Files} from '../../../../app/modules/apps/file/core/_models'
import ListRequirements from './ListRequirements'
import {saveForms} from '../../../../app/modules/apps/forms/core/_requests'
import {useIntl} from 'react-intl'

interface IProps {
  title: string
}
const ImmigrationRequirements: React.FC<IProps> = ({title}) => {
  const intl = useIntl()

  const [list, setList] = useState(true)
  const [data, setData] = useState<ImmigrationRequirement>(ImmigrationRequirementInitValues)
  const [files, setFiles] = useState<Files[]>([])

  useEffect(() => {
    if (files) {
      data.files = files
    }
  }, [files])

  const handleSave = (data: ImmigrationRequirement) => {
    if (data.id) {
      updateForms(data)
    } else {
      saveForms(data)
    }
  }

  const saveForms = async (data: ImmigrationRequirement) => {
    const result = await saveImmigrationRequirement(data)
    if (result) {
      setList(true)
    }
  }

  const updateForms = async (data: ImmigrationRequirement) => {
    const result = await updateImmigrationRequirement(data)
    if (result) {
      setList(true)
    }
  }

  const handleEdit = async (data: ImmigrationRequirement) => {
    const filesOptions = await getImmigrationRequirementFiles(data.id || '')

    if (filesOptions) {
      data.files = filesOptions
      setFiles(filesOptions)
    }

    setData(data)
    setList(false)
  }

  return (
    <div>
      <Container title={intl.formatMessage({id: title})}>
        {' '}
        <Link to='/admin/tools'>
          <Button
            className='btn  btn-primary'
            style={{
              width: '8rem',
              alignSelf: 'end',
              marginRight: '2rem',
              border: 'none',
              marginBottom: '1rem',
              gap: '0.5rem',
            }}
          >
            Regresar
          </Button>
        </Link>
        <Button
          onClick={() => {
            setData(ImmigrationRequirementInitValues)
            setList(!list)
          }}
          style={{width: '8rem', alignSelf: 'end', marginRight: '2rem'}}
        >
          {intl.formatMessage({id: list ? 'COMMON.ADD' : 'COMMON.BACK'})}
        </Button>
        {list && (
          <>
            <ListRequirements handleEdit={handleEdit} />
          </>
        )}
        {!list && data && (
          <>
            <AddEditData
              onComplete={handleSave}
              data={data}
              showwFileSelect={true}
              files={files}
              setFiles={setFiles}
            />
          </>
        )}
      </Container>
    </div>
  )
}

export default ImmigrationRequirements
