import {type Forms, type FormsQueryResponse} from '../../core/_models'
import {type FormsProduct, type FormsProductQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const FORMS_URL = `${API_URL}/formsproduct`

const saveFormsProduct = async (formId: string, protuctId: string): Promise<FormsProduct[]> => {
  return await axios
    .post(`${FORMS_URL}`, {form: formId, product: protuctId})
    .then((response: AxiosResponse) => {
      return response.data as FormsProduct[]
    })
}

const getFormsProducts = (id: string): Promise<FormsProduct[]> | null => {
  return axios.get(`${FORMS_URL}/all/${id}`).then((d: AxiosResponse<FormsProductQueryResponse>) => {
    return d.data as FormsProduct[]
  })
}

const getFormsNotSelected = async (id: string | null): Promise<Forms[]> => {
  return await axios
    .get(`${FORMS_URL}/notselected/${id}`)
    .then((d: AxiosResponse<FormsQueryResponse>) => {
      return d.data as Forms[]
    })
}

const softDeleteFormsProduct = async (id: string): Promise<any> => {
  return await axios.delete(`${FORMS_URL}/${id}`).then((response) => {
    return response
  })
}

export {saveFormsProduct, getFormsProducts, getFormsNotSelected, softDeleteFormsProduct}
