import React, { useState, useContext, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { loadStripe } from '@stripe/stripe-js';
import { RootStoreContext } from '../../../../../../stores/rootStore';
import CheckoutForm from './CheckoutForm';
import Payu from '../../../../../../app/components/payments/payu';
import CheckoutPaypal from '../../../../../../app/components/payments/paypal';
import { GrTextAlignLeft } from 'react-icons/gr';

const REACT_APP_STRIPE = process.env.REACT_APP_STRIPE || '';
const stripePromise = loadStripe(REACT_APP_STRIPE);
const loader = 'auto';

interface Props {
  setMethod: Dispatch<SetStateAction<string>>;
  paymentMethodValue: string;
}

const PaymentForm: React.FC<Props> = ({ setMethod, paymentMethodValue }) => {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;
  const {setCuponPrice,savePaymentItem,setPaymentItem} = rootStore.cartStore
  const [saving, setSaving] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPaymentComponent, setShowPaymentComponent] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<string>('');
  const [paymentSelected, setPaymentSelected] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { cart } = rootStore.cartStore;
  const [couponCode, setCouponCode] = useState('')
  const formControlStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    border: '1px solid #c7cfd8',
    background: '#fff',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '1.428571429',
    color: '#555',
    display: 'block',
    width: '100%',
    height: '100%',
    margin: '0.5em 0 2em',  
    position: 'relative' as const,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch' as const,
  }
  useEffect(() => {
    if (user?.timeZone) {
      // Se establece la zona horaria del usuario
    } else {
      // Si no hay zona horaria del usuario, se establece la zona horaria predeterminada
    }
  }, [user]);

  const handleOptionChange = (paymentMethod: string) => {
    setSelectedMethod(paymentMethod);
    setPaymentMethod(paymentMethod);
    setMethod(paymentMethod);
    setPaymentSelected(true); // Seleccionar el método de pago
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const handleContinueButtonClick = async () => {
    if (termsAccepted && paymentSelected) { // Verificar si se han aceptado los términos y se ha seleccionado un método de pago
      setShowPaymentComponent(true);
      if(selectedMethod==="card"){
      const result = await savePaymentItem()
      console.log("MEET",selectedMethod)
      console.log("resultttANDRES",JSON.stringify(result),"selectec",selectedMethod)
      setClientSecret(result.client_secret as any);
      setPaymentItem(result)
    }
    }
  };

  const handleBackButtonClick = () => {
    setShowPaymentComponent(false);
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_URL}/products/stripeconfig`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey) as any);
    });
  }, []);


  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleInputChangeCupon = (e: any) => {
    setCouponCode(e.target.value)
    if (e.target.value === 'PREPARACIONVISAEDDY') {
      setCuponPrice(getSubtotal() * 0.3)
    } else if (e.target.value === 'EDUCATION') {
      setCuponPrice(getSubtotal() * 0.3)
    } else if (e.target.value === 'IMMILAND-VISA4CLIENT') {
      setCuponPrice(getSubtotal() * 0.1)
    } else if (e.target.value === 'EBSC') {
      setCuponPrice(getSubtotal() * 0.1)
    } else {
      setCuponPrice(0)
    }
  }
  const getSubtotal = () => {
    return cart.reduce((acc: number, product: {product: {price: number}; quantity: number}) => {
      return acc + product.product.price * product.quantity
    }, 0)
  }

  return (
    <div className='mx-5 table-method'>
      {!showPaymentComponent ? (
      <div className='mx-5 table-method' style={{display:'flex', flexDirection:'column', backgroundColor:'#EEF3F5', borderRadius:'10px', padding:'0px 30px', alignItems:'center', marginTop:'5rem'}}>
         
        <div style={{fontSize:'16px', fontWeight:'700', width:'100%', display:'flex', marginTop:'20px'}} >Seleccione el metodo de pago </div>
        <div style={{ marginBottom: '1rem', position: 'relative' }} onClick={() => handleOptionChange('card')}>
  <div className={`payment-option ${selectedMethod === 'card' ? 'selected' : ''}`} style={{ backgroundColor: 'white', padding: '16px', marginTop: '10px', display:'flex', alignItems: 'flex-start', cursor: 'pointer', borderRadius: '10px', width:'290px' }}>
    <img
      src={selectedMethod === 'card' ? '/media/svg/menu-icons/credit-cards.svg' : '/media/svg/menu-icons/credit-cards-black.svg'}
      alt='Logo de Visa'
      style={{ width: '60px', height: '25px', marginBottom: '5px' }}
    />
    <button
      onClick={() => handleOptionChange('card')}
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
    >
      <strong style={{textAlign:'left', marginBottom:'0rem', color: selectedMethod === 'card' ? '#2D9CDB' : 'black'}}>Pague con <br/> tarjetas de Débito o Crédito</strong>
    </button>
            </div>
          </div>


          {/* <div style={{ marginBottom: '1rem', position: 'relative' }} onClick={() => handleOptionChange('apple')}>
            <div className={`payment-option ${selectedMethod === 'apple' ? 'selected' : ''}`} style={{ backgroundColor: 'white', padding: '16px', marginTop: '10px', display:'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer', borderRadius: '10px', width:'290px' }}>
              <button
                onClick={() => handleOptionChange('apple')}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              >
                <p>(BETA)</p>
              </button>
            </div>
          </div>  */}<div style={{ marginBottom: '1rem', position: 'relative', width: '290px' }} onClick={() => handleOptionChange('paypal')}>
  <div className={`payment-option ${selectedMethod === 'paypal' ? 'selected' : ''}`} style={{ backgroundColor: 'white', padding: '16px', marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', cursor: 'pointer', borderRadius: '10px' }}>
    <img
      src={selectedMethod === 'paypal' ? '/media/svg/menu-icons/paypal-blue.svg' : '/media/svg/menu-icons/paypal.svg'}
      alt='Logo de PayPal'
      style={{ width: '60px', height: '29px', marginBottom: '5px' }}
    />
    <button
      onClick={() => handleOptionChange('paypal')}
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <strong style={{ textAlign: 'left', marginBottom: '0rem', color: selectedMethod === 'paypal' ? '#2D9CDB' : 'black' }}>
        Pague con métodos de pago <br /> asociados a su cuenta Paypal
        </strong>
      </div>
    </button>
  </div>
  <div>
    {/* <h1 style={{color:'red'}}>BAJO CONSTRUCCION, POR FAVOR SELECCIONE STRIPE</h1> */}
    {/* <CheckoutPaypal /> */}
  </div>
</div>
  
          <div>
            <legend style={{textAlign:'left', fontSize:'14px'}} className='text-md'>¿Tiene un <strong>código de descuento?</strong></legend>
            <input
              type='text'
              value={couponCode}
              onChange={handleInputChangeCupon}
              placeholder='Ingresa Cupón'
             className='custom-input-payment'
              style={{borderRadius: '4px', width: '100px', backgroundColor:'white'}}
            />
           </div>
       
          <div>
            <label style={{ display: 'flex', alignItems: 'flex-start' }}>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={handleCheckboxChange}
                style={{ width: '20px', height: '20px',  backgroundColor: 'green', marginTop:'5px'}}
              />
              <p style={{ fontSize: '16px', fontWeight: '400', marginLeft: '5px' }}> Acepto los  </p> <a style={{ fontSize: '16px', fontWeight: '400', marginLeft: '5px', color:'blue', borderBottom:'1px solid blue'}} href="#" onClick={toggleModal} >
        Términos y condiciones
      </a>
            </label>
          </div>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <button 
              disabled={!termsAccepted || !paymentSelected} 
              onClick={handleContinueButtonClick} 
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '201px',
                height: '56px',
                borderRadius: '50px',
                border: '2px solid #E0E0E0',
                padding: '16px 36px',
                gap: '10px',
                backgroundColor: termsAccepted && paymentSelected ? 'black' : '#E0E0E0',
                color: termsAccepted && paymentSelected ? 'white' : 'black',
                cursor: 'pointer',
                position: 'relative', 
                marginBottom:'20px'
              }}
            >
              <div></div>
              <strong>CONTINUAR</strong>
              <img
                alt="Flecha"
                className='meet-image2' 
                src={termsAccepted && paymentSelected ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'}
                style={{ width: '24px', height: '24px' }}
              />
            </button>
          </div>
        </div>
      ) : (
        <>
         
          <div className='mx-5 table-method' style={{display:'flex', flexDirection:'column', backgroundColor:'#EEF3F5', borderRadius:'10px', padding:'30px 30px', alignItems:'center', marginTop:'5rem'}}>
          <div onClick={()=>{location.reload();}} style={{ display: 'flex', alignItems: 'center' , width:'100%', paddingBottom:'3px', justifyContent:'flex-start' }}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderBottom: '1px solid black', paddingBottom: '5px' }}><img src="/media/icons/app/menu/arrow-left-black.svg" alt="Back Icon" style={{ width: '24px', height: '24px', marginRight: '5px' }} />
            <button >VOLVER</button></div>
          </div>

          {paymentMethod === 'card' && stripePromise && clientSecret && (
  <Elements stripe={stripePromise} options={{ clientSecret }}>
    <CheckoutForm paymentMethodValue={paymentMethodValue} />
  </Elements>
)}
{paymentMethod === 'card' && !clientSecret && <>Cargando stripe..</> }
          {paymentMethod === 'payu' && (
            <Payu saving={saving} setSaving={setSaving} />
          )}
          {paymentMethod === 'paypal' && (
            <CheckoutPaypal />
          )} 
           {/*  {paymentMethod === 'apple' && (
              <>
              <Elements stripe={stripePromise}>
            <CheckoutApple paymentMethodValue={paymentMethodValue}/>
            </Elements>
            </>
          )} */}
           </div>
          
        </>
      )}

{showModal && (
  <div id="modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div className='responsive-contract' style={{ backgroundColor: 'white', padding: '20px', maxWidth: '50%', maxHeight: '80%', overflowY: 'auto', borderRadius: '10px', position: 'relative', border:'1px solid black' }}>

      <div style={{ marginBottom: '50px' }}>
    
      <div>
              <p style={{textAlign: 'center'}}>
                <strong>INMILAND CANADA INC, Terms and Conditions Translations</strong>
              </p>
              <ol>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Modalidad de servicio:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que nuestros servicios de traducción son realizados por traductores
                humanos, cada uno con su estilo y enfoque personal en la traducción. Nos esforzamos
                por proporcionar traducciones precisas y fieles al original, pero ten en cuenta que
                el estilo de traducción puede variar ligeramente entre diferentes traductores.
              </p>
              <ol start={2}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Alcance de servicio </strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que este servicio es de traducción regular. Si requieres servicio especial
                para traducir mi licencia de conducir, NO debo comprar este servicio y debe
                devolverse al menú principal de productos y seleccionar el adecuado.&nbsp;
              </p>
              <ol start={3}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Política de cancelación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que al momento de la compra, si existe algún documento que compré pero que
                ya no necesito traducir, Immiland no realiza reembolsos, pero si podrá dar uso de
                esa página para reemplazarla por otra que sí requiera traducción. Immiland respeta
                su crédito para intercambiarlo por otra página por un lapso de tiempo de 2 meses
                posteriores a la compra.&nbsp;
              </p>
              <ol start={4}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Revisión y retroalimentación:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que una vez entregados los documentos traducidos, debo revisarlos
                cuidadosamente para asegurarse de que cumplan con mis expectativas. Si encuentro
                algún error o necesito realizar cambios, debo informar al traductor. Estaremos
                encantados de corregir cualquier error y adaptar la traducción a tus requerimientos
                específicos.
              </p>
              <ol start={5}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Tiempo de entrega:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nuestros tiempos de entrega estimados son de 1 a 5 días hábiles para servicios
                express y de 5 a 7 días hábiles para servicios normales. Sin embargo, ten en cuenta
                que el tiempo de entrega puede variar según la disponibilidad de los traductores y
                la complejidad de los documentos. Luego de la compra, podrás acceder a la plataforma
                de Immiland para chequear los tiempos. Te recomendamos contactarnos para obtener una
                estimación precisa en base a tus necesidades.
              </p>

              <ol start={6}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Entrega de documentación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Los documentos se entregan en copias digitales por la plataforma de Immiland al
                cliente. El servicio no incluye envíos de traducciones físicas.&nbsp;
              </p>
              <ol start={7}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Calidad del documento:</strong>
                  </p>
                </li>
              </ol>
              <p>
                La calidad del documento original enviado puede afectar la precisión y el estilo de
                nuestras traducciones. Te recomendamos proporcionar documentos claros, legibles y
                con una buena calidad de resolución para obtener mejores resultados. Además, si
                tienes preferencias específicas de estilo o terminología, por favor indícanos al
                realizar tu solicitud.
              </p>
              <ol start={8}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Confidencialidad:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Valoramos la confidencialidad y nos comprometemos a proteger la información que nos
                proporcionas durante el proceso de traducción. A continuación, se detallan nuestras
                políticas y prácticas relacionadas con la confidencialidad y la protección de la
                información:&nbsp;
              </p>
              <p>a. Privacidad y Seguridad:</p>
              <p>
                Tomamos medidas para garantizar la privacidad y seguridad de tus documentos y datos
                personales. Implementamos procedimientos físicos, electrónicos y administrativos
                para proteger la información contra accesos no autorizados, divulgación o
                alteración.
              </p>
              <p>b. Uso Limitado de la Información:</p>
              <p>
                Utilizamos la información proporcionada exclusivamente con el propósito de llevar a
                cabo los servicios de traducción solicitados. No compartiremos, venderemos ni
                alquilaremos tu información personal a terceros sin tu consentimiento explícito, a
                menos que estemos legalmente obligados a hacerlo.
              </p>
              <p>c. Acuerdos de Confidencialidad:</p>
              <p>
                Nuestros traductores y miembros del equipo están sujetos a acuerdos de
                confidencialidad que salvaguardan la información que manejan durante el proceso de
                traducción. Estos acuerdos garantizan que los traductores mantengan la
                confidencialidad de los documentos y la información proporcionada por los clientes.
              </p>
              <p>d. Eliminación Segura de Datos:</p>
              <p>
                Una vez que se ha completado el proceso de traducción y entregado el trabajo final
                al cliente, eliminaremos de manera segura todos los documentos y datos relacionados
                con la traducción, a menos que se haya acordado lo contrario con el cliente.
              </p>
              <p>e. Comunicación Segura:</p>
              <p>
                Utilizamos métodos seguros de comunicación para el intercambio de documentos y
                cualquier otra información relacionada con la traducción. Estos métodos pueden
                incluir el cifrado de archivos y el uso de sistemas seguros de gestión de
                documentos.
              </p>
              <p>f. Responsabilidad Compartida:</p>
              <p>
                Si bien nos esforzamos por proteger la información confidencial, es importante
                destacar que la seguridad en línea y la confidencialidad de los datos son
                responsabilidades compartidas. Te recomendamos tomar precauciones adicionales al
                compartir información confidencial a través de Internet y mantener actualizados tus
                sistemas de seguridad informática.
              </p>
              <p>g. Cumplimiento Legal:</p>
              <p>
                Cumplimos con todas las leyes y regulaciones aplicables en relación con la
                confidencialidad y la protección de datos. En el caso de cualquier cambio en las
                leyes o regulaciones pertinentes, ajustaremos nuestras prácticas para garantizar el
                cumplimiento continuo.
              </p>
              <p>
                Ten en cuenta que este descargo de responsabilidad proporciona una visión general de
                nuestras políticas y prácticas de confidencialidad. Para obtener información más
                detallada sobre cómo manejamos y protegemos la información confidencial, te
                recomendamos revisar nuestra política de privacidad y solicitar cualquier aclaración
                adicional que puedas necesitar.
              </p>
              <ol start={9}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Derechos de autor:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Al utilizar nuestros servicios de traducción, confirmas que tienes los derechos
                necesarios sobre los documentos a traducir y que no infringes los derechos de autor
                de terceros. No nos responsabilizamos por reclamaciones legales relacionadas con los
                derechos de autor de los documentos proporcionados.
              </p>
              <ol start={10}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Modificaciones en los términos:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nos reservamos el derecho de realizar modificaciones en estos términos y condiciones
                en cualquier momento sin previo aviso. Los cambios entrarán en vigor a partir de su
                publicación en nuestro sitio web.
              </p>
              <p>
                <strong>
                  Descargo de responsabilidad para servicios de traducción licencia conducir
                  (Disclaimer)
                </strong>
              </p>
              <ol start={11}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Modalidad de servicio:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que nuestros servicios de traducción son realizados por traductores
                humanos, cada uno con su estilo y enfoque personal en la traducción. Nos esforzamos
                por proporcionar traducciones precisas y fieles al original, pero ten en cuenta que
                el estilo de traducción puede variar ligeramente entre diferentes traductores.
              </p>
              <ol start={12}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Alcance de servicio </strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que este servicio NO es de traducción regular y solo aplica para licencias
                de conducir.&nbsp;
              </p>

              <ol start={13}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Política de cancelación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que al momento de la compra, si existe algún documento que compré pero que
                ya no necesito traducir, Immiland no realiza reembolsos, pero si podrá dar uso de
                esa página para reemplazarla por otra que si requiera traducción. Immiland respeta
                su crédito para intercambiarlo por otra página por un lapso de tiempo de 2 meses
                posteriores a la compra.
              </p>
              <br />
              <ol start={14}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Revisión y retroalimentación:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Entiendo que una vez entregados los documentos traducidos, debo revisarlos
                cuidadosamente para asegurarse de que cumplan con mis expectativas. Si encuentro
                algún error o necesito realizar cambios, debo informar al traductor. Estaremos
                encantados de corregir cualquier error y adaptar la traducción a tus requerimientos
                específicos.
              </p>
              <br />
              <ol start={15}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Tiempo de entrega:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nuestros tiempos de entrega estimados son de 1 a 5 días hábiles para servicios
                express y de 5 a 7 días hábiles para servicios normales. Sin embargo, ten en cuenta
                que el tiempo de entrega puede variar según la disponibilidad de los traductores y
                la complejidad de los documentos. Luego de la compra, podrás acceder a la plataforma
                de Immiland para chequear los tiempos. Te recomendamos contactarnos para obtener una
                estimación precisa en base a tus necesidades.
              </p>
              <br />
              <ol start={16}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Entrega de documentación</strong>
                  </p>
                </li>
              </ol>
              <p>
                Los documentos se entregan en copias digitales por la plataforma de Immiland al
                cliente. El servicio no incluye envíos de traducciones físicas.
              </p>
              <br />
              <ol start={17}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Calidad del documento:</strong>
                  </p>
                </li>
              </ol>
              <p>
                La calidad del documento original enviado puede afectar la precisión y el estilo de
                nuestras traducciones. Te recomendamos proporcionar documentos claros, legibles y
                con una buena calidad de resolución para obtener mejores resultados. Además, si
                tienes preferencias específicas de estilo o terminología, por favor indícanos al
                realizar tu solicitud.
              </p>
              <br />
              <ol start={18}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Confidencialidad:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Valoramos la confidencialidad y nos comprometemos a proteger la información que nos
                proporcionas durante el proceso de traducción. A continuación, se detallan nuestras
                políticas y prácticas relacionadas con la confidencialidad y la protección de la
                información.
              </p>
              <br />
              <p>a. Privacidad y Seguridad:</p>
              <p>
                Tomamos medidas para garantizar la privacidad y seguridad de tus documentos y datos
                personales. Implementamos procedimientos físicos, electrónicos y administrativos
                para proteger la información contra accesos no autorizados, divulgación o
                alteración.
              </p>
              <br />
              <p>b. Uso Limitado de la Información:</p>
              <p>
                Utilizamos la información proporcionada exclusivamente con el propósito de llevar a
                cabo los servicios de traducción solicitados. No compartiremos, venderemos ni
                alquilaremos tu información personal a terceros sin tu consentimiento explícito, a
                menos que estemos legalmente obligados a hacerlo.
              </p>
              <br />
              <p>c. Acuerdos de Confidencialidad:</p>
              <p>
                Nuestros traductores y miembros del equipo están sujetos a acuerdos de
                confidencialidad que salvaguardan la información que manejan durante el proceso de
                traducción. Estos acuerdos garantizan que los traductores mantengan la
                confidencialidad de los documentos y la información proporcionada por los clientes.
              </p>
              <br />
              <p>d. Eliminación Segura de Datos:</p>
              <p>
                Una vez que se ha completado el proceso de traducción y entregado el trabajo final
                al cliente, eliminaremos de manera segura todos los documentos y datos relacionados
                con la traducción, a menos que se haya acordado lo contrario con el cliente.
              </p>
              <br />
              <p>e. Comunicación Segura:</p>
              <p>
                Utilizamos métodos seguros de comunicación para el intercambio de documentos y
                cualquier otra información relacionada con la traducción. Estos métodos pueden
                incluir el cifrado de archivos y el uso de sistemas seguros de gestión de
                documentos.
              </p>
              <br />
              <p>f. Responsabilidad Compartida:</p>
              <p>
                Si bien nos esforzamos por proteger la información confidencial, es importante
                destacar que la seguridad en línea y la confidencialidad de los datos son
                responsabilidades compartidas. Te recomendamos tomar precauciones adicionales al
                compartir información confidencial a través de Internet y mantener actualizados tus
                sistemas de seguridad informática.
              </p>
              <br />
              <p>g. Cumplimiento Legal:</p>
              <p>
                Cumplimos con todas las leyes y regulaciones aplicables en relación con la
                confidencialidad y la protección de datos. En el caso de cualquier cambio en las
                leyes o regulaciones pertinentes, ajustaremos nuestras prácticas para garantizar el
                cumplimiento continuo.
              </p>
              <br />
              <p>
                Ten en cuenta que este descargo de responsabilidad proporciona una visión general de
                nuestras políticas y prácticas de confidencialidad. Para obtener información más
                detallada sobre cómo manejamos y protegemos la información confidencial, te
                recomendamos revisar nuestra política de privacidad y solicitar cualquier aclaración
                adicional que puedas necesitar.
              </p>
              <br />
              <ol start={19}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Derechos de autor:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Al utilizar nuestros servicios de traducción, confirmas que tienes los derechos
                necesarios sobre los documentos a traducir y que no infringes los derechos de autor
                de terceros. No nos responsabilizamos por reclamaciones legales relacionadas con los
                derechos de autor de los documentos proporcionados.
              </p>
              <br />
              <ol start={20}>
                <li style={{fontWeight: 'bold'}}>
                  <p>
                    <strong>Modificaciones en los términos:</strong>
                  </p>
                </li>
              </ol>
              <p>
                Nos reservamos el derecho de realizar modificaciones en estos términos y condiciones
                en cualquier momento sin previo aviso. Los cambios entrarán en vigor a partir de su
                publicación en nuestro sitio web.
              </p>
              <br />
              <br />
              <div className='button-volver-contract' style={{ position: 'fixed', bottom: '100px', left: 0, width: '100%'}}>
        {/* Botón fijo dentro del modal */}
        <button
          className='button-volver'
          style={{
            height: '2rem',
            width: '5rem',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            margin: '0 auto', // Centra horizontalmente el botón dentro del contenedor
            backgroundColor: 'black',
          }}
          onClick={toggleModal}
        >
          <p style={{color:'white', marginBottom:'0rem'}}>Cerrar</p>
        </button>
      </div>
            </div>
      
      </div>
    
    
    </div>
  </div>
)}



    </div>
   
    
  );
};

export default observer(PaymentForm);

   {/*  <div style={{ backgroundColor: 'white', padding: '10px', marginTop: '10px', display:'flex' }}>
          <button
            onClick={() => handleOptionChange('payu')}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <img
              src='/media/svg/menu-icons/payu.png'
              alt='Logo de Payu'
              style={{ marginRight: '5px', width: '24px', height: '24px' }}
            />
            Pague con tarjetas de Débito o Crédito
          </button>
        </div> */}


    