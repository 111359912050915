 
 
import {type CheckBoxOptions} from '../types'
import ImmigrationDetailsList from './ImmigrationDetailsList'
import {type ImmigrationService} from '../../../../../app/modules/apps/immigration/services/core/_models'
import React from 'react'

interface IProps {
  immigrationService: ImmigrationService
  options: CheckBoxOptions[]
  type: string
  handleChange?: (checkBoxOptions: CheckBoxOptions, cheked: boolean, type: string) => void
}

const ImmigrationDetails: React.FC<IProps> = ({
  immigrationService,
  options,
  type,
  handleChange,
}) => {
  return (
    <div>
      <div className='p-4 my-2 w-full   bg-white rounded-lg border shadow-md  dark:bg-gray-800 dark:border-gray-700'>
        <h5 className='mb-4 text-xl font-medium text-tomato-500 dark:text-tomato-400'>
          {immigrationService.name}
        </h5>
        <ImmigrationDetailsList
          options={options.filter(
            (option) => option.immigrationService.id === immigrationService.id
          )}
          handleChange={(option, checked) => {
            handleChange != null ? handleChange(option, checked, type) : null
          }}
        />
      </div>
    </div>
  )
}

export default ImmigrationDetails
