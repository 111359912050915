 
import {Button, Space, Table} from 'antd'
import React, { useState } from 'react'
import Column from 'antd/lib/table/Column'
import {type DocumentsProduct} from '../../../../../../app/modules/apps/forms/formproducts/core/_models'
import {type Product} from '../../../../../../app/modules/apps/cart/core/products/_models'
import {useQuery} from 'react-query'
import {
  getImmigrationRequirementFiles,
  softImmigrationRequirementFiles,
} from 'app/modules/apps/immigration/requirements/core/_requests'
import checkmark_white from '../../../../../pages/contratationApp/icons/checkmark_white.svg'

interface IProps {
  product: Product
  setDocumentsProducts: (formsProducts: DocumentsProduct[]) => void
}

const DocumentsList: React.FC<IProps> = ({product, setDocumentsProducts}) => {
  const [showToast, setShowToast] = useState(false)
  const {data, status, refetch} = useQuery(
    'ListDocumentsProducts',
    async () => await getImmigrationRequirementFiles(product.id || '')
  )

  if (data != null) {
    setDocumentsProducts(data)
  }

  const handleDelete = async (id: string) => {
    const result=await softImmigrationRequirementFiles(id)
    refetch()
    if (result) {
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
    }
  }

  return (
    <div>
       {showToast === true ?
        <div className='toast-style-container'>
          <div className='toast-style'>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%',top:'0px' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Eliminado
          </div>
        </div> : null}
      {status === 'loading' && <div>Loading...</div>}
      {status === 'success' && data != null && (
        <div>
          <Table dataSource={data.filter((item:any) => item.document  !== null)} rowKey='id' size='small'>
            <Column
              title='Form'
              render={(text, record: any) => <Space>{record.document.name}</Space>}
            />
            <Column
              title='Descripcion'
              render={(text, record: any) => <Space>{record.document.description}</Space>}
            />
            <Column
              title='Eliminar'
              key='estado'
              render={(text, record: any) => (
                <Space>
                  <Button
                    type='link'
                    onClick={async () => {
                      await handleDelete(record.id)
                    }}
                  >
                    Eliminar
                  </Button>
                </Space>
              )}
            />
          </Table>
        </div>
      )}
    </div>
  )
}

export default DocumentsList
