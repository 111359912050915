/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {type FC, useContext, useEffect, useRef, useState, type ReactNode} from 'react'
import {type Profile} from '../app/modules/apps/profile/core/_models'
import {RootStoreContext} from '../stores/rootStore'
import SplashScreen from '../core/components/SplashScreen'
import {observer} from 'mobx-react'

interface AuthInitProps {
  children: ReactNode
}
const AuthInit: FC<AuthInitProps> = ({children}) => {
  const rootStore = useContext(RootStoreContext)
  const {setProfile, profile} = rootStore.profileStore
  const {loadMessages, loadMessagesUnread} = rootStore.messageStore
  const {accessToken, user, logout, isAuthenticated} = rootStore.authStore

  const didRequest = useRef(false)

  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          setProfile(user as Profile)
        } else {
          setShowSplashScreen(false)
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (isAuthenticated && accessToken) {
      requestUser(accessToken)
      loadMessages('all')
      loadMessagesUnread()
    } else {
      setShowSplashScreen(false)
    }
  }, [isAuthenticated])

  return showSplashScreen ? <SplashScreen /> : <>{children}</>
}

export default observer(AuthInit)
