/* eslint-disable @typescript-eslint/no-unused-vars */

import './style.css'
import React from 'react'

interface IProps {
  showPanel: boolean
  children: React.ReactNode
}

const RigthPanel: React.FC<IProps> = ({showPanel, children}) => {
  const [show, setShow] = React.useState(showPanel)

  return <>{show && <div className='translation-panel-rigth-wrapper'>{children}</div>}</>
}

export default RigthPanel
