/* eslint-disable @typescript-eslint/no-unused-vars */
import './pdfAndres.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { type FC } from 'react'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import './styles.css'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface PdfViewerProps {
  url: any
  name: string
  children: React.ReactNode
}

const PdfViewerAndres: FC<PdfViewerProps> = ({ url, name, children }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  return (
    <>
      <div className="pdf-viewer-container">
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js'>
          <div className='rezize-pdf-viewer' style={{ height: '720px', width: '90%' }}>
            <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
          </div>
        </Worker>
      </div>
    </>
  )
}

export default PdfViewerAndres
