 

import {type FC, useContext} from 'react'
import {Container} from '../../../../components/Container/index'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getStaff} from 'app/modules/apps/staff/core/_requests'

const AdminToolsWrapper: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore

  const {data: dataStaff} = useQuery(
    'getAllStaffsss',
    async () => await getStaff(user?.id ? user.id : '')
  )
  return (
    <>
      <Container title={intl.formatMessage({id: 'FORMSMANAGER.ADMINTOOLS'})}>
        <div className='flex gap-4 justify-center flex-wrap'>
          {/*  <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/services">
            <img className='w-32' src="/media/svg/menu-icons/Visa_express_entry.svg" alt='Servicios de immigracion'/>
            <h2>Servicios de Immigratión</h2>
          </Link> */}
          {/* <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/admin/createappointment">
            <img className='w-32' src="/media/svg/menu-icons/consulta_regular_migratorio.svg" alt='Crear cita'/>
            <h2>Crear Cita</h2>
          </Link>
          <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/options">
            <img className='w-32' src="/media/svg/menu-icons/rrecruitment_services.svg" alt='Opciones Migratorias'/>
            <h2>Opciones Migratorias</h2>
          </Link> */}
          {/* <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/funds">
            <img className='w-32' src="/media/svg/menu-icons/Bank_account_opening.svg" alt='Fondos de immigracion'/>
            <h2>Fondos de Immigración</h2>
          </Link>
          <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/elements">
            <img className='w-32' src="/media/svg/menu-icons/Traduccion_espanol.svg" alt='Elementos de immigracion'/>
            <h2>Elementos de Immigración</h2>
          </Link> */}
          {/*  <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/links">
            <img className='w-32' src="/media/svg/menu-icons/Visa_turismo.svg" alt='Links de immigracion'/>
            <h2>Links de Immigración</h2>
          </Link>
          {/*  <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/requirements">
            <img className='w-32' src="/media/svg/menu-icons/affidavit.svg" alt='Requerimientos de immigracion'/>
            <h2>Requerimientos de Immigración</h2>
          </Link>
          <Link className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl' to="/immigration/costs">
            <img className='w-32' src="/media/svg/menu-icons/Visa_inversor.svg" alt='Costos de Immigracion'/>
            <h2>Costos de Immigración</h2>
          </Link> */}
          {/* <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/myclients/list'
          >
            <img className='w-32' src='/media/svg/menu-icons/Pickup_airport.svg' alt='clientes' />
            <h2>Mis clientes</h2>
          </Link>
 */}
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/userslist'
          >
            <img className='w-32' src='/media/svg/menu-icons/Pickup_airport.svg' alt='clientes' />
            <h2>Clientes</h2>
          </Link>
          {dataStaff && user?.role?.id === 1 && dataStaff[0].type !== 'partner' && (
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/templatesblue'
            >
              <img className='w-32' src='/media/svg/menu-icons/templates.svg' alt='clientes' />
              <h2>Mensajes masivos</h2>
            </Link>
          )}
          {dataStaff &&
            user?.role?.id === 1 &&
            /* (dataStaff[0].type === 'intake' ||
              dataStaff[0].type === 'intake_admin' ||
              dataStaff[0].type === 'coo') && */ (
              <Link
                className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
                to='/services/contracts/answers'
              >
                <img className='w-32' src='/media/svg/menu-icons/templates.svg' alt='clientes' />
                <h2>Respuestas de contratacion</h2>
              </Link>
            )}

          {dataStaff && user?.role?.id === 1 && dataStaff[0].type !== 'partner' && (
            <Link
              className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
              to='/timelineadmins'
            >
              <img className='w-32' src='/media/svg/menu-icons/timeline.svg' alt='' />
              <h2>{intl.formatMessage({id: 'MENU.TIMELINE'})}</h2>
            </Link>
          )}


        </div>
      </Container>
    </>
  )
}

export {AdminToolsWrapper}
