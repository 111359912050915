export const translation = {
  'TRANSLATION.TRANSLATION': 'Document Translation',
  'TRANSLATION.PRIORITY': 'Priority',
  'TRANSLATION.CLIENT': 'Client',
  'TRANSLATION.CLIENTS': 'Clients',
  'TRANSLATION.STATE': 'State',
  'TRANSLATION.STAFF': 'Staff',
  'TRANSLATION.EMAIL': 'Email',
  'TRANSLATION.EXPIRATION': 'Expiration',
  'TRANSLATION.DELIBER': 'Delivery date',
  'TRANSLATION.EXPRESS': 'Express',
  'TRANSLATION.CREATED': 'Created',
  'TRANSLATION.PAGES': 'Pages',
  'TRANSLATION.TRANSLATEDPAGES': 'Translated pages',
  'TRANSLATION.UPLOADEXTRAS': 'Upload extras',
  'TRANSLATION.UPLOAD': 'Please upload the documents you would like to translate',
  'TRANSLATION.TOTAL': 'Total',
  'TRANSLATION.NEXTT': 'Next',
  'TRANSLATION.CLICK': 'Click or drag file to this area to upload',
  'TRANSLATION.IN': 'In',
  'TRANSLATION.PAYMENTS': 'Payment details',
  'TRANSLATION.NAMECARD': 'Name on the credit card',
  'TRANSLATION.PAY': 'Pay',
  'TRANSLATION.ASSIGN': 'Assign translation',
  'TRANSLATION.TRANSLATOR': 'Translator',
  'TRANSLATION.UPLOADED': 'Uploaded files ',
  'TRANSLATION.DATE': 'Date',
  'TRANSLATION.DATECREATION': 'Creation Date',

  'TRANSLATION.DESCRIPTION': 'Description',
  'TRANSLATION.UPLOADFILES': 'Upload Files',
  'TRANSLATION.LOAD': 'Load Extra Files',
  'TRANSLATION.FILES': 'files',
  'TRANSLATION.PROFILE': 'Profile',
  'TRANSLATION.NAME': 'Name',
  'TRANSLATION.SELECT': 'Select',
  'TRANSLATION.RESEND': 'Resend email',
  'TRANSLATION.CHANGE': 'Change consultant',
  'TRANSLATION.PRODUCTNAME': 'Service name',
  'TRANSLATION.FINISHED': 'Translation finished',
  'TRANSLATION.DELIVERED': 'Delivered',
  'TRANSLATION.NETSUITESTATUS': 'NetSuite status',
  'TRANSLATION.ERRORUPLOADCOUNT':
    'Error, the uploaded pages do not match the pages to be translated, please verify',
  'TRANSLATION.ASKDELETEFILE': 'Are you sure you want to delete this file?',
  'TRANSLATION.ASKDELETEFILEBTNCONFIRM': 'Confirm',
  'TRANSLATION.ASKDELETEFILEBTNCANCEL': 'Cancel',
}
