/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import xmlbuilder from 'xmlbuilder'
import axios from 'axios'
import thunderblue from './Icons/thunderbolt-blue.svg'
import { RootStoreContext } from 'stores/rootStore'
import { toast } from 'react-toastify'
interface IProps {
  product: any
  principalProduct: any
  contractsData: any
  contractsFound: any
  lang: string
}

const IMM5645EDEPENDANT: React.FC<IProps> = ({ product, principalProduct, contractsData, contractsFound, lang  }) => {
  console.log(product, 'Respuestas del Hijo')
  console.log(principalProduct, 'Respuestas del principal')

  // trigers
  const [addressTriggers, setAddressTriggers] = useState(false)
  const [parentsTriggers, setParentsTriggers] = useState(false)

  useEffect(() => { setAnswers() }, [addressTriggers])
  useEffect(() => { setAnswers() }, [parentsTriggers])
  //Page 1
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [visaVisitor, setVisaVisitor] = useState('')
  const [DOBYear, setDOBYear] = useState('')
  const [AptUnit, setAptUnit] = useState('')
  const [StreetNum, setStreetNum] = useState('')
  const [Streetname, setStreetname] = useState('')
  const [CityTown, setCityTown] = useState('')
  const [Country, setCountry] = useState('')
  const [ProvinceState, setProvinceState] = useState('')
  const [PostalCode, setPostalCode] = useState('')
  const [placeBirthCountry, setPlaceBirthCountry] = useState('')
  const [maritalStatus, setmaritalStatus] = useState('')
  const [Ocupation1, setOcupation1] = useState('')
  const [maritalStatusGivenName, setMaritalStatusGivenName] = useState('')
  const [maritalStatusCountryBirth, setMaritalStatusCountryBirth] = useState('')
  const [prevMarriedIndicator, setPrevMarriedIndicator] = useState('')
  const [prevMarriedIndicator2, setPrevMarriedIndicator2] = useState('')
  const [PMSpouseOcupation, setPMSpouseOcupation] = useState('')
  const [PMSpouseAddress, setPMSpouseAddress] = useState('')
  const [PMDateOfBirthY, setPMDateOfBirthY] = useState('')
  const [motherGivenName, setMotherGivenName] = useState('')
  const [motherFamilyName, setMotherFamilyName] = useState('')
  const [motherBirthY, setMotherBirthY] = useState('')
  const [motherCountryBirth, setMotherCountryBirth] = useState('')
  const [motherMaritalStatus, setMotherMaritalStatus] = useState('')
  const [motherAddress, setMotherAddress] = useState('')
  const [motherOcupation, setMotherOcupation] = useState('')
  const [motherIndicator, setMotherIndicator] = useState('')
  const [motherIndicator2, setMotherIndicator2] = useState('')
  const [fatherGivenName, setFatherGivenName] = useState('')
  const [fatherFamilyName, setFatherFamilyName] = useState('')
  const [fatherBirthY, setFatherBirthY] = useState('')
  const [fatherCountryBirth, setFatherCountryBirth] = useState('')
  const [fatherMaritalStatus, setFatherMaritalStatus] = useState('')
  const [fatherAddress, setFatherAddress] = useState('')
  const [fatherOcupation, setFatherOcupation] = useState('')
  const [fatherIndicator, setFatherIndicator] = useState('')
  const [fatherIndicator2, setFatherIndicator2] = useState('')
  const [childrenGivenName, setChildrenGivenName] = useState('')
  const [childrenBirthY, setChildrenBirthY] = useState('')
  const [childrenCountryBirth, setChildrenCountryBirth] = useState('')
  const [childrenMaritalStatus, setChildrenMaritalStatus] = useState('')
  const [childrenAddress, setChildrenAddress] = useState('')
  const [childrenRelationship, setChildrenRelationship] = useState('')
  const [childrenOcupation, setChildrenOcupation] = useState('')
  const [childrenIndicator, setChildrenIndicator] = useState('')
  const [childrenIndicator2, setChildrenIndicator2] = useState('')
  const [childrenGivenName2, setChildrenGivenName2] = useState('')
  const [childrenBirthY2, setChildrenBirthY2] = useState('')
  const [childrenCountryBirth2, setChildrenCountryBirth2] = useState('')
  const [childrenMaritalStatus2, setChildrenMaritalStatus2] = useState('')
  const [childrenAddress2, setChildrenAddress2] = useState('')
  const [childrenRelationship2, setChildrenRelationship2] = useState('')
  const [childrenOcupation2, setChildrenOcupation2] = useState('')
  const [children2Indicator, setChildren2Indicator] = useState('')
  const [children2Indicator2, setChildren2Indicator2] = useState('')
  const [childrenGivenName3, setChildrenGivenName3] = useState('')
  const [childrenBirthY3, setChildrenBirthY3] = useState('')
  const [childrenCountryBirth3, setChildrenCountryBirth3] = useState('')
  const [childrenMaritalStatus3, setChildrenMaritalStatus3] = useState('')
  const [childrenAddress3, setChildrenAddress3] = useState('')
  const [childrenRelationship3, setChildrenRelationship3] = useState('')
  const [childrenOcupation3, setChildrenOcupation3] = useState('')
  const [children3Indicator, setChildren3Indicator] = useState('')
  const [children3Indicator2, setChildren3Indicator2] = useState('')
  const [childrenGivenName4, setChildrenGivenName4] = useState('')
  const [childrenBirthY4, setChildrenBirthY4] = useState('')
  const [childrenCountryBirth4, setChildrenCountryBirth4] = useState('')
  const [childrenMaritalStatus4, setChildrenMaritalStatus4] = useState('')
  const [childrenAddress4, setChildrenAddress4] = useState('')
  const [childrenRelationship4, setChildrenRelationship4] = useState('')
  const [childrenOcupation4, setChildrenOcupation4] = useState('')
  const [children4Indicator, setChildren4Indicator] = useState('')
  const [children4Indicator2, setChildren4Indicator2] = useState('')
  const [BrotherGivenName, setBrotherGivenName] = useState('')
  const [BrotherBirthY, setBrotherBirthY] = useState('')
  const [BrotherCountryBirth, setBrotherCountryBirth] = useState('')
  const [BrotherMaritalStatus, setBrotherMaritalStatus] = useState('')
  const [BrotherAddress, setBrotherAddress] = useState('')
  const [BrotherRelationship, setBrotherRelationship] = useState('')
  const [BrotherOcupation, setBrotherOcupation] = useState('')
  const [BrotherIndicator, setBrotherIndicator] = useState('')
  const [BrotherIndicator2, setBrotherIndicator2] = useState('')
  const [BrotherGivenName2, setBrotherGivenName2] = useState('')
  const [BrotherBirthY2, setBrotherBirthY2] = useState('')
  const [BrotherCountryBirth2, setBrotherCountryBirth2] = useState('')
  const [BrotherMaritalStatus2, setBrotherMaritalStatus2] = useState('')
  const [BrotherAddress2, setBrotherAddress2] = useState('')
  const [BrotherRelationship2, setBrotherRelationship2] = useState('')
  const [BrotherOcupation2, setBrotherOcupation2] = useState('')
  const [Brother2Indicator, setBrother2Indicator] = useState('')
  const [Brother2Indicator2, setBrother2Indicator2] = useState('')
  const [BrotherGivenName3, setBrotherGivenName3] = useState('')
  const [BrotherBirthY3, setBrotherBirthY3] = useState('')
  const [BrotherCountryBirth3, setBrotherCountryBirth3] = useState('')
  const [BrotherMaritalStatus3, setBrotherMaritalStatus3] = useState('')
  const [BrotherAddress3, setBrotherAddress3] = useState('')
  const [BrotherRelationship3, setBrotherRelationship3] = useState('')
  const [BrotherOcupation3, setBrotherOcupation3] = useState('')
  const [Brother3Indicator, setBrother3Indicator] = useState('')
  const [Brother3Indicator2, setBrother3Indicator2] = useState('')
  const [BrotherGivenName4, setBrotherGivenName4] = useState('')
  const [BrotherBirthY4, setBrotherBirthY4] = useState('')
  const [BrotherCountryBirth4, setBrotherCountryBirth4] = useState('')
  const [BrotherMaritalStatus4, setBrotherMaritalStatus4] = useState('')
  const [BrotherAddress4, setBrotherAddress4] = useState('')
  const [BrotherRelationship4, setBrotherRelationship4] = useState('')
  const [BrotherOcupation4, setBrotherOcupation4] = useState('')
  const [Brother4Indicator, setBrother4Indicator] = useState('')
  const [Brother4Indicator2, setBrother4Indicator2] = useState('')
  const [BrotherGivenName5, setBrotherGivenName5] = useState('')
  const [BrotherBirthY5, setBrotherBirthY5] = useState('')
  const [BrotherCountryBirth5, setBrotherCountryBirth5] = useState('')
  const [BrotherMaritalStatus5, setBrotherMaritalStatus5] = useState('')
  const [BrotherAddress5, setBrotherAddress5] = useState('')
  const [BrotherRelationship5, setBrotherRelationship5] = useState('')
  const [BrotherOcupation5, setBrotherOcupation5] = useState('')
  const [Brother5Indicator, setBrother5Indicator] = useState('')
  const [Brother5Indicator2, setBrother5Indicator2] = useState('')
  const [BrotherGivenName6, setBrotherGivenName6] = useState('')
  const [BrotherBirthY6, setBrotherBirthY6] = useState('')
  const [BrotherCountryBirth6, setBrotherCountryBirth6] = useState('')
  const [BrotherMaritalStatus6, setBrotherMaritalStatus6] = useState('')
  const [BrotherAddress6, setBrotherAddress6] = useState('')
  const [BrotherRelationship6, setBrotherRelationship6] = useState('')
  const [BrotherOcupation6, setBrotherOcupation6] = useState('')
  const [Brother6Indicator, setBrother6Indicator] = useState('')
  const [Brother6Indicator2, setBrother6Indicator2] = useState('')
  const [BrotherGivenName7, setBrotherGivenName7] = useState('')
  const [BrotherBirthY7, setBrotherBirthY7] = useState('')
  const [BrotherCountryBirth7, setBrotherCountryBirth7] = useState('')
  const [BrotherMaritalStatus7, setBrotherMaritalStatus7] = useState('')
  const [BrotherAddress7, setBrotherAddress7] = useState('')
  const [BrotherRelationship7, setBrotherRelationship7] = useState('')
  const [BrotherOcupation7, setBrotherOcupation7] = useState('')
  const [Brother7Indicator, setBrother7Indicator] = useState('')
  const [Brother7Indicator2, setBrother7Indicator2] = useState('')
  const fileName = `IMM5645E ${product.memberName} ${product.name || 'formulario'}.xml`;
  const rootStore = useContext(RootStoreContext)
  const { connectSocket } = rootStore.notificationStore

  const { user: userAUTH} = rootStore.authStore
  const setAnswers = () => {

    if (product.name === 'Visitor visa') {
      setVisaVisitor('01')
    }
    if (product.name === 'Work') {
      setVisaVisitor('02')
    }
    if (product.name === 'Study') {
      setVisaVisitor('03')
    }
    if (product.name === 'Other') {
      setVisaVisitor('04')
    }
    product.forms.map((form: any) => {
      // Formulario de Detalles personales
      if (form && form.formData && form.formData.guide === '001') {
        form.formData.questions.map((q: any) => {
          if (q.index === '003') {
            setGivenName(q.answer)
          }
          if (q.index === '002') {
            setFamilyName(q.answer)
          }
          if (q.index === '011' && q.answer.trim() !== '') {
            setDOBYear(q.answer)
          }
          if (q.index === '013') {

            setPlaceBirthCountry(q.answer)
          }
        })
      }

      // Formulario de Estado civil Actual
      if (form && form.formData && form.formData.guide === '004') {
        form.formData.questions.map((q: any) => {
          if (q.index === '021') {
            if (q.answer === 'Soltero') {
              setmaritalStatus('07')
            }
            if (q.answer === 'Casado') {
              setmaritalStatus('05')
            }
            if (q.answer === 'Legalmente separado') {
              setmaritalStatus('04')
            }
            if (q.answer === 'Unión de hecho (12 meses de convivencia)') {
              setmaritalStatus('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setmaritalStatus('01')
            }
            if (q.answer === 'Divorciado') {
              setmaritalStatus('03')
            }
            if (q.answer === 'Viudo') {
              setmaritalStatus('08')
            }
            if (q.answer === 'Desconocido') {
              setmaritalStatus('00')
            }
          }
          if (q.index === '010') {
            if (q.answer === 'Si') {
              setmaritalStatus('6')
            }
          }
        })
      }

      // Formulario de Ocupación o empleo (actual y anteriores)
      if (form && form.formData && form.formData.guide === '011') {
        form.formData.questions.map((q: any) => {
          if (q.index === '005') {
            if (q.translate === true) {
              if (lang === 'original') {
                setOcupation1(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setOcupation1(q.answer)
                } else {
                  setOcupation1(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setOcupation1(q.answer)
                } else {
                  setOcupation1(q.frenchAnswer)
                }
              }
            } else {
              setOcupation1(q.answer)
            }
          }
        })
      }

      // Formulario de Direccion principal
      if (form && form.formData && form.formData.guide === '009') {
        form.formData.questions.map((q: any) => {
          if (q.index === '021') {
            if (q.answer === 'No') {
              setAddressTriggers(false)
            } else if (q.answer === 'Si') {
              setAddressTriggers(true)
            }
          }
          if (addressTriggers === true) {

            if (q.index === '003') {
              setAptUnit(q.answer)
            }
            if (q.index === '004') {
              setStreetNum(q.answer)
            }
            if (q.index === '005') {
              setStreetname(q.answer)
            }
            if (q.index === '006') {
              setCityTown(q.answer)
            }
            if (q.index === '007') {
              setCountry(q.answer)
            }
            if (q.index === '008') {
              setProvinceState(q.answer)
            }
            if (q.index === '009') {
              setPostalCode(q.answer)
            }


          }
        })
      }

      // Formulario Información sobre su cónyuge o pareja de hecho
      if (form && form.formData && form.formData.guide === '018') {
        form.formData.questions.map((q: any) => {

          if (q.index === '020' && q.answer !== '') {
            setMaritalStatusGivenName(q.answer)
          }

          if (q.index === '021' && q.answer !== '') {
            setPMDateOfBirthY(q.answer)
          }

          if (q.index === '022' && q.answer !== '') {

            setMaritalStatusCountryBirth(q.answer)
          }

          if (q.index === '023' && q.answer !== '') {
            setPMSpouseAddress(q.answer)
          }

          if (q.index === '024' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPMSpouseOcupation(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPMSpouseOcupation(q.answer)
                } else {
                  setPMSpouseOcupation(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPMSpouseOcupation(q.answer)
                } else {
                  setPMSpouseOcupation(q.frenchAnswer)
                }
              }
            } else {
              setPMSpouseOcupation(q.answer)
            }
          }

          if (q.index === '025' && q.answer !== '') {
            if (q.answer === 'Si') {
              setPrevMarriedIndicator('1')
              setPrevMarriedIndicator2('0')
            }
            if (q.answer === 'No') {
              setPrevMarriedIndicator2('1')
              setPrevMarriedIndicator('0')
            }

          }
        })
      }

      // Formulario Información sobre sus padres caso donde los padres no aplican con el carajito
      if (form && form.formData && form.formData.guide === '019') {
        form.formData.questions.map((q: any) => {
          if (q.index === '019') {
            if (q.answer === 'No') {
              setParentsTriggers(false)
            } else if (q.answer === 'Si') {
              setParentsTriggers(true)
            }
          }

          if (parentsTriggers === false) {
            if (q.index === '037' && q.answer !== '') {
              setMotherGivenName(q.answer)
            }

            if (q.index === '038' && q.answer !== '') {
              setMotherBirthY(q.answer)
            }

            if (q.index === '039' && q.answer !== '') {
              setMotherCountryBirth(q.answer)
            }

            if (q.index === '040') {
              if (q.answer === 'Soltero') {
                setMotherMaritalStatus('07')
              }
              if (q.answer === 'Casado') {
                setMotherMaritalStatus('05')
              }
              if (q.answer === 'Legalmente separado') {
                setMotherMaritalStatus('04')
              }
              if (q.answer === 'Unión de hecho') {
                setMotherMaritalStatus('02')
              }
              if (q.answer === 'Matrimonio anulado') {
                setMotherMaritalStatus('01')
              }
              if (q.answer === 'Divorciado') {
                setMotherMaritalStatus('03')
              }
              if (q.answer === 'Viudo') {
                setMotherMaritalStatus('08')
              }
              if (q.answer === 'Desconocido') {
                setMotherMaritalStatus('00')
              }
            }

            if (q.index === '041' && q.answer !== '') {
              setMotherAddress(q.answer)
            }

            if (q.index === '042') {
              if (q.translate === true) {
                if (lang === 'original') {
                  setMotherOcupation(q.answer)
                }
                if (lang === 'ingles') {
                  if (q.englishAnswer === '') {
                    setMotherOcupation(q.answer)
                  } else {
                    setMotherOcupation(q.englishAnswer)
                  }
                }
                if (lang === 'frances') {
                  if (q.frenchAnswer === '') {
                    setMotherOcupation(q.answer)
                  } else {
                    setMotherOcupation(q.frenchAnswer)
                  }
                }
              } else {
                setMotherOcupation(q.answer)
              }
            }

            if (q.index === '043' && q.answer !== '') {
              if (q.answer === 'Si') {
                setMotherIndicator('1')
                setMotherIndicator2('0')
              }
              if (q.answer === 'No') {
                setMotherIndicator2('1')
                setMotherIndicator('0')
              }
            }

            if (q.index === '045' && q.answer !== '') {
              setFatherGivenName(q.answer)
            }

            if (q.index === '046' && q.answer !== '') {
              setFatherBirthY(q.answer)
            }

            if (q.index === '047' && q.answer !== '') {
              setFatherCountryBirth(q.answer)
            }

            if (q.index === '048') {
              if (q.answer === 'Soltero') {
                setFatherMaritalStatus('07')
              }
              if (q.answer === 'Casado') {
                setFatherMaritalStatus('05')
              }
              if (q.answer === 'Legalmente separado') {
                setFatherMaritalStatus('04')
              }
              if (q.answer === 'Unión de hecho') {
                setFatherMaritalStatus('02')
              }
              if (q.answer === 'Matrimonio anulado') {
                setFatherMaritalStatus('01')
              }
              if (q.answer === 'Divorciado') {
                setFatherMaritalStatus('03')
              }
              if (q.answer === 'Viudo') {
                setFatherMaritalStatus('08')
              }
              if (q.answer === 'Desconocido') {
                setFatherMaritalStatus('00')
              }
            }

            if (q.index === '049' && q.answer !== '') {
              setFatherAddress(q.answer)
            }

            if (q.index === '050') {
              if (q.translate === true) {
                if (lang === 'original') {
                  setFatherOcupation(q.answer)
                }
                if (lang === 'ingles') {
                  if (q.englishAnswer === '') {
                    setFatherOcupation(q.answer)
                  } else {
                    setFatherOcupation(q.englishAnswer)
                  }
                }
                if (lang === 'frances') {
                  if (q.frenchAnswer === '') {
                    setFatherOcupation(q.answer)
                  } else {
                    setFatherOcupation(q.frenchAnswer)
                  }
                }
              } else {
                setFatherOcupation(q.answer)
              }
            }

            if (q.index === '051' && q.answer !== '') {
              if (q.answer === 'Si') {
                setFatherIndicator('1')
                setFatherIndicator2('0')
              }
              if (q.answer === 'No') {
                setFatherIndicator2('1')
                setFatherIndicator('0')
              }
            }
          }
        })
      }

      // Formulario Información sobre su(s) hijo(s)
      if (form && form.formData && form.formData.guide === '020') {
        form.formData.questions.map((q: any) => {

          if (q.index === '003' && q.answer !== '') {
            setChildrenGivenName(q.answer)
          }

          if (q.index === '004' && q.answer !== '') {
            setChildrenBirthY(q.answer)
          }

          if (q.index === '005' && q.answer !== '') {

            setChildrenCountryBirth(q.answer)
          }


          if (q.index === '006' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenRelationship(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenRelationship(q.answer)
                } else {
                  setChildrenRelationship(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenRelationship(q.answer)
                } else {
                  setChildrenRelationship(q.frenchAnswer)
                }
              }
            } else {
              setChildrenRelationship(q.answer)
            }
          }

          if (q.index === '007') {
            if (q.answer === 'Soltero') {
              setChildrenMaritalStatus('07')
            }
            if (q.answer === 'Casado') {
              setChildrenMaritalStatus('05')
            }
            if (q.answer === 'Legalmente separado') {
              setChildrenMaritalStatus('04')
            }
            if (q.answer === 'Unión de hecho') {
              setChildrenMaritalStatus('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setChildrenMaritalStatus('01')
            }
            if (q.answer === 'Divorciado') {
              setChildrenMaritalStatus('03')
            }
            if (q.answer === 'Viudo') {
              setChildrenMaritalStatus('08')
            }
            if (q.answer === 'Desconocido') {
              setChildrenMaritalStatus('00')
            }
          }

          if (q.index === '008' && q.answer !== '') {
            setChildrenAddress(q.answer)
          }

          if (q.index === '009') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenOcupation(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenOcupation(q.answer)
                } else {
                  setChildrenOcupation(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenOcupation(q.answer)
                } else {
                  setChildrenOcupation(q.frenchAnswer)
                }
              }
            } else {
              setChildrenOcupation(q.answer)
            }
          }

          if (q.index === '010' && q.answer !== '') {
            if (q.answer === 'Si') {
              setChildrenIndicator('1')
              setChildrenIndicator2('0')
            }
            if (q.answer === 'No') {
              setChildrenIndicator2('1')
              setChildrenIndicator('0')
            }
          }
          //Childre 2
          if (q.index === '012' && q.answer !== '') {
            setChildrenGivenName2(q.answer)
          }

          if (q.index === '013' && q.answer !== '') {
            setChildrenBirthY2(q.answer)
          }

          if (q.index === '014' && q.answer !== '') {

            setChildrenCountryBirth2(q.answer)
          }

          if (q.index === '015' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenRelationship2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenRelationship2(q.answer)
                } else {
                  setChildrenRelationship2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenRelationship2(q.answer)
                } else {
                  setChildrenRelationship2(q.frenchAnswer)
                }
              }
            } else {
              setChildrenRelationship2(q.answer)
            }
          }

          if (q.index === '016') {
            if (q.answer === 'Soltero') {
              setChildrenMaritalStatus2('07')
            }
            if (q.answer === 'Casado') {
              setChildrenMaritalStatus2('05')
            }
            if (q.answer === 'Legalmente separado') {
              setChildrenMaritalStatus2('04')
            }
            if (q.answer === 'Unión de hecho') {
              setChildrenMaritalStatus2('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setChildrenMaritalStatus2('01')
            }
            if (q.answer === 'Divorciado') {
              setChildrenMaritalStatus2('03')
            }
            if (q.answer === 'Viudo') {
              setChildrenMaritalStatus2('08')
            }
            if (q.answer === 'Desconocido') {
              setChildrenMaritalStatus2('00')
            }
          }

          if (q.index === '017' && q.answer !== '') {
            setChildrenAddress2(q.answer)
          }

          if (q.index === '018') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenOcupation2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenOcupation2(q.answer)
                } else {
                  setChildrenOcupation2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenOcupation2(q.answer)
                } else {
                  setChildrenOcupation2(q.frenchAnswer)
                }
              }
            } else {
              setChildrenOcupation2(q.answer)
            }
          }

          if (q.index === '019' && q.answer !== '') {
            if (q.answer === 'Si') {
              setChildren2Indicator('1')
              setChildren2Indicator2('0')
            }
            if (q.answer === 'No') {
              setChildren2Indicator2('1')
              setChildren2Indicator('0')
            }
          }

          //Childre 3
          if (q.index === '021' && q.answer !== '') {
            setChildrenGivenName3(q.answer)
          }

          if (q.index === '022' && q.answer !== '') {
            setChildrenBirthY3(q.answer)
          }

          if (q.index === '023' && q.answer !== '') {

            setChildrenCountryBirth3(q.answer)
          }


          if (q.index === '024' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenRelationship3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenRelationship3(q.answer)
                } else {
                  setChildrenRelationship3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenRelationship3(q.answer)
                } else {
                  setChildrenRelationship3(q.frenchAnswer)
                }
              }
            } else {
              setChildrenRelationship3(q.answer)
            }
          }

          if (q.index === '025') {
            if (q.answer === 'Soltero') {
              setChildrenMaritalStatus3('07')
            }
            if (q.answer === 'Casado') {
              setChildrenMaritalStatus3('05')
            }
            if (q.answer === 'Legalmente separado') {
              setChildrenMaritalStatus3('04')
            }
            if (q.answer === 'Unión de hecho') {
              setChildrenMaritalStatus3('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setChildrenMaritalStatus3('01')
            }
            if (q.answer === 'Divorciado') {
              setChildrenMaritalStatus3('03')
            }
            if (q.answer === 'Viudo') {
              setChildrenMaritalStatus3('08')
            }
            if (q.answer === 'Desconocido') {
              setChildrenMaritalStatus3('00')
            }
          }

          if (q.index === '117' && q.answer !== '') {
            setChildrenAddress3(q.answer)
          }

          if (q.index === '118') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenOcupation3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenOcupation3(q.answer)
                } else {
                  setChildrenOcupation3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenOcupation3(q.answer)
                } else {
                  setChildrenOcupation3(q.frenchAnswer)
                }
              }
            } else {
              setChildrenOcupation3(q.answer)
            }
          }

          if (q.index === '026' && q.answer !== '') {
            if (q.answer === 'Si') {
              setChildren3Indicator('1')
              setChildren3Indicator2('0')
            }
            if (q.answer === 'No') {
              setChildren3Indicator2('1')
              setChildren3Indicator('0')
            }
          }

          //Childre 4
          if (q.index === '028' && q.answer !== '') {
            setChildrenGivenName4(q.answer)
          }

          if (q.index === '029' && q.answer !== '') {
            setChildrenBirthY4(q.answer)
          }

          if (q.index === '030' && q.answer !== '') {

            setChildrenCountryBirth4(q.answer)
          }


          if (q.index === '031' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenRelationship4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenRelationship4(q.answer)
                } else {
                  setChildrenRelationship4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenRelationship4(q.answer)
                } else {
                  setChildrenRelationship4(q.frenchAnswer)
                }
              }
            } else {
              setChildrenRelationship4(q.answer)
            }
          }

          if (q.index === '032') {
            if (q.answer === 'Soltero') {
              setChildrenMaritalStatus4('07')
            }
            if (q.answer === 'Casado') {
              setChildrenMaritalStatus4('05')
            }
            if (q.answer === 'Legalmente separado') {
              setChildrenMaritalStatus4('04')
            }
            if (q.answer === 'Unión de hecho') {
              setChildrenMaritalStatus4('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setChildrenMaritalStatus4('01')
            }
            if (q.answer === 'Divorciado') {
              setChildrenMaritalStatus4('03')
            }
            if (q.answer === 'Viudo') {
              setChildrenMaritalStatus4('08')
            }
            if (q.answer === 'Desconocido') {
              setChildrenMaritalStatus4('00')
            }
          }

          if (q.index === '033' && q.answer !== '') {
            setChildrenAddress4(q.answer)
          }

          if (q.index === '034') {
            if (q.translate === true) {
              if (lang === 'original') {
                setChildrenOcupation4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setChildrenOcupation4(q.answer)
                } else {
                  setChildrenOcupation4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setChildrenOcupation4(q.answer)
                } else {
                  setChildrenOcupation4(q.frenchAnswer)
                }
              }
            } else {
              setChildrenOcupation4(q.answer)
            }
          }

          if (q.index === '035' && q.answer !== '') {
            if (q.answer === 'Si') {
              setChildren4Indicator('1')
              setChildren4Indicator2('0')
            }
            if (q.answer === 'No') {
              setChildren4Indicator2('1')
              setChildren4Indicator('0')
            }
          }
        })
      }

      // Formulario Información sobre su(s) hermano(s)
      if (form && form.formData && form.formData.guide === '021') {
        form.formData.questions.map((q: any) => {

          if (q.index === '003' && q.answer !== '') {
            setBrotherGivenName(q.answer)
          }

          if (q.index === '004' && q.answer !== '') {
            setBrotherBirthY(q.answer)
          }

          if (q.index === '005' && q.answer !== '') {
            setBrotherCountryBirth(q.answer)
          }

          if (q.index === '006' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship(q.answer)
                } else {
                  setBrotherRelationship(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship(q.answer)
                } else {
                  setBrotherRelationship(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship(q.answer)
            }
          }

          if (q.index === '007') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus('00')
            }
          }

          if (q.index === '008' && q.answer !== '') {
            setBrotherAddress(q.answer)
          }

          if (q.index === '009') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation(q.answer)
                } else {
                  setBrotherOcupation(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation(q.answer)
                } else {
                  setBrotherOcupation(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation(q.answer)
            }
          }

          if (q.index === '010' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrotherIndicator('1')
              setBrotherIndicator2('0')
            }
            if (q.answer === 'No') {
              setBrotherIndicator2('1')
              setBrotherIndicator('0')
            }
          }
          //Brother 2
          if (q.index === '012' && q.answer !== '') {
            setBrotherGivenName2(q.answer)
          }

          if (q.index === '013' && q.answer !== '') {
            setBrotherBirthY2(q.answer)
          }

          if (q.index === '014' && q.answer !== '') {
            setBrotherCountryBirth2(q.answer)
          }


          if (q.index === '015' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship2(q.answer)
                } else {
                  setBrotherRelationship2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship2(q.answer)
                } else {
                  setBrotherRelationship2(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship2(q.answer)
            }
          }

          if (q.index === '016') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus2('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus2('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus2('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus2('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus2('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus2('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus2('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus2('00')
            }
          }

          if (q.index === '017' && q.answer !== '') {
            setBrotherAddress2(q.answer)
          }

          if (q.index === '117') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation2(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation2(q.answer)
                } else {
                  setBrotherOcupation2(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation2(q.answer)
                } else {
                  setBrotherOcupation2(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation2(q.answer)
            }
          }

          if (q.index === '018' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrother2Indicator('1')
              setBrother2Indicator2('0')
            }
            if (q.answer === 'No') {
              setBrother2Indicator2('1')
              setBrother2Indicator('0')
            }
          }

          //Brother 3
          if (q.index === '020' && q.answer !== '') {
            setBrotherGivenName3(q.answer)
          }

          if (q.index === '021' && q.answer !== '') {
            setBrotherBirthY3(q.answer)
          }

          if (q.index === '022' && q.answer !== '') {

            setBrotherCountryBirth3(q.answer)
          }


          if (q.index === '023' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship3(q.answer)
                } else {
                  setBrotherRelationship3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship3(q.answer)
                } else {
                  setBrotherRelationship3(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship3(q.answer)
            }
          }

          if (q.index === '024') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus3('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus3('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus3('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus3('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus3('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus3('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus3('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus3('00')
            }
          }

          if (q.index === '025' && q.answer !== '') {
            setBrotherAddress3(q.answer)
          }

          if (q.index === '026') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation3(q.answer)
                } else {
                  setBrotherOcupation3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation3(q.answer)
                } else {
                  setBrotherOcupation3(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation3(q.answer)
            }
          }

          if (q.index === '027' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrother3Indicator('1')
              setBrother3Indicator2('0')
            }
            if (q.answer === 'No') {
              setBrother3Indicator2('1')
              setBrother3Indicator('0')
            }
          }

          //Brother 4
          if (q.index === '029' && q.answer !== '') {
            setBrotherGivenName4(q.answer)
          }

          if (q.index === '030' && q.answer !== '') {
            setBrotherBirthY4(q.answer)
          }

          if (q.index === '031' && q.answer !== '') {

            setBrotherCountryBirth4(q.answer)
          }

          if (q.index === '032' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship4(q.answer)
                } else {
                  setBrotherRelationship4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship4(q.answer)
                } else {
                  setBrotherRelationship4(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship4(q.answer)
            }
          }

          if (q.index === '033') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus4('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus4('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus4('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus4('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus4('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus4('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus4('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus4('00')
            }
          }

          if (q.index === '034' && q.answer !== '') {
            setBrotherAddress4(q.answer)
          }

          if (q.index === '035') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation4(q.answer)
                } else {
                  setBrotherOcupation4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation4(q.answer)
                } else {
                  setBrotherOcupation4(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation4(q.answer)
            }
          }

          if (q.index === '036' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrother4Indicator('1')
              setBrother4Indicator2('0')
            }
            if (q.answer === 'No') {
              setBrother4Indicator2('1')
              setBrother4Indicator('0')
            }
          }

          //Brother 5
          if (q.index === '038' && q.answer !== '') {
            setBrotherGivenName5(q.answer)
          }

          if (q.index === '039' && q.answer !== '') {
            setBrotherBirthY5(q.answer)
          }

          if (q.index === '040' && q.answer !== '') {

            setBrotherCountryBirth5(q.answer)
          }

          if (q.index === '041' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship5(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship5(q.answer)
                } else {
                  setBrotherRelationship5(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship5(q.answer)
                } else {
                  setBrotherRelationship5(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship5(q.answer)
            }
          }

          if (q.index === '042') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus5('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus5('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus5('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus5('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus5('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus5('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus5('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus5('00')
            }
          }

          if (q.index === '043' && q.answer !== '') {
            setBrotherAddress5(q.answer)
          }

          if (q.index === '044') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation5(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation5(q.answer)
                } else {
                  setBrotherOcupation5(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation5(q.answer)
                } else {
                  setBrotherOcupation5(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation5(q.answer)
            }
          }

          if (q.index === '045' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrother5Indicator('1')
              setBrother5Indicator2('0')
            }
            if (q.answer === 'No') {
              setBrother5Indicator2('1')
              setBrother5Indicator('0')
            }
          }

          //Brothre 6
          if (q.index === '047' && q.answer !== '') {
            setBrotherGivenName6(q.answer)
          }

          if (q.index === '048' && q.answer !== '') {
            setBrotherBirthY6(q.answer)
          }

          if (q.index === '049' && q.answer !== '') {

            setBrotherCountryBirth6(q.answer)
          }

          if (q.index === '050' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship6(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship6(q.answer)
                } else {
                  setBrotherRelationship6(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship6(q.answer)
                } else {
                  setBrotherRelationship6(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship6(q.answer)
            }
          }

          if (q.index === '051') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus6('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus6('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus6('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus6('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus6('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus6('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus6('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus6('00')
            }
          }

          if (q.index === '052' && q.answer !== '') {
            setBrotherAddress6(q.answer)
          }

          if (q.index === '053') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation6(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation6(q.answer)
                } else {
                  setBrotherOcupation6(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation6(q.answer)
                } else {
                  setBrotherOcupation6(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation6(q.answer)
            }
          }

          if (q.index === '054' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrother6Indicator('1')
              setBrother6Indicator2('0')
            }
            if (q.answer === 'No') {
              setBrother6Indicator2('1')
              setBrother6Indicator('0')
            }
          }

          //brother 7
          if (q.index === '056' && q.answer !== '') {
            setBrotherGivenName7(q.answer)
          }

          if (q.index === '057' && q.answer !== '') {
            setBrotherBirthY7(q.answer)
          }

          if (q.index === '058' && q.answer !== '') {
            setBrotherCountryBirth7(q.answer)
          }

          if (q.index === '059' && q.answer !== '') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherRelationship7(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherRelationship7(q.answer)
                } else {
                  setBrotherRelationship7(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherRelationship7(q.answer)
                } else {
                  setBrotherRelationship7(q.frenchAnswer)
                }
              }
            } else {
              setBrotherRelationship7(q.answer)
            }
          }

          if (q.index === '060') {
            if (q.answer === 'Soltero') {
              setBrotherMaritalStatus7('07')
            }
            if (q.answer === 'Casado') {
              setBrotherMaritalStatus7('05')
            }
            if (q.answer === 'Legalmente separado') {
              setBrotherMaritalStatus7('04')
            }
            if (q.answer === 'Unión de hecho') {
              setBrotherMaritalStatus7('02')
            }
            if (q.answer === 'Matrimonio anulado') {
              setBrotherMaritalStatus7('01')
            }
            if (q.answer === 'Divorciado') {
              setBrotherMaritalStatus7('03')
            }
            if (q.answer === 'Viudo') {
              setBrotherMaritalStatus7('08')
            }
            if (q.answer === 'Desconocido') {
              setBrotherMaritalStatus7('00')
            }
          }

          if (q.index === '061' && q.answer !== '') {
            setBrotherAddress7(q.answer)
          }

          if (q.index === '062') {
            if (q.translate === true) {
              if (lang === 'original') {
                setBrotherOcupation7(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setBrotherOcupation7(q.answer)
                } else {
                  setBrotherOcupation7(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setBrotherOcupation7(q.answer)
                } else {
                  setBrotherOcupation7(q.frenchAnswer)
                }
              }
            } else {
              setBrotherOcupation7(q.answer)
            }
          }

          if (q.index === '063' && q.answer !== '') {
            if (q.answer === 'Si') {
              setBrother7Indicator('1')
              setBrother7Indicator2('0')
            }
            if (q.answer === 'No') {
              setBrother7Indicator2('1')
              setBrother7Indicator('0')
            }
          }
        })
      }
    })

    principalProduct.forms.map((form: any) => {
      // Direccion Principal
      // Formulario de Dirección residencial principal del papa
      if (form && form.formData && form.formData.guide === '009') {
        let address = ''
        form.formData.questions.map((q: any) => {
          if (addressTriggers === true) {
            if (q.index === '003') {
              setAptUnit(q.answer)
            }
            if (q.index === '004') {
              setStreetNum(q.answer)
            }
            if (q.index === '005') {
              setStreetname(q.answer)
            }
            if (q.index === '006') {
              setCityTown(q.answer)
            }
            if (q.index === '007') {
              setCountry(q.answer)
            }
            if (q.index === '008') {
              setProvinceState(q.answer)
            }
            if (q.index === '009') {
              setPostalCode(q.answer)
            }
          }
          if (parentsTriggers === true) {
            if (q.index === '003') {
              address = address + ' ' + q.answer
            }
            if (q.index === '004') {
              address = address + ' ' + q.answer
            }
            if (q.index === '005') {
              address = address + ' ' + q.answer
            }
            if (q.index === '006') {
              address = address + ' ' + q.answer
            }
            if (q.index === '007') {
              address = address + ' ' + q.answer
            }
            if (q.index === '008') {
              address = address + ' ' + q.answer
            }
            if (q.index === '009') {
              address = address + ' ' + q.answer
            }
            setFatherAddress(address)
            setMotherAddress(address)
            if (q.index === '016' && q.answer !== '') {
              if (q.answer === 'Si') {
                setFatherIndicator('1')
                setFatherIndicator2('0')
              }
              if (q.answer === 'No') {
                setFatherIndicator2('1')
                setFatherIndicator('0')
              }
            }
          }
        })
      }

      if (form && form.formData && form.formData.guide === '019') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001' && q.answer !== '') {
            setMotherGivenName(q.answer)
          }

          if (q.index === '002' && q.answer !== '') {
            setMotherBirthY(q.answer)
          }

          if (q.index === '003' && q.answer !== '') {
            setMotherCountryBirth(q.answer)
          }
          if (q.index === '005') {
            setMotherOcupation(q.answer)
          }

          if (q.index === '043' && q.answer !== '') {
            if (q.answer === 'Si') {
              setMotherIndicator('1')
              setMotherIndicator2('0')
            }
            if (q.answer === 'No') {
              setMotherIndicator2('1')
              setMotherIndicator('0')
            }
          }
        })
      }

      // Formulario de Detalles personales
      if (form && form.formData && form.formData.guide === '001') {
        form.formData.questions.map((q: any) => {
          if (parentsTriggers === true) {

            if (q.index === '003' && q.answer !== '') {
              setFatherGivenName(q.answer)
            }
            if (q.index === '002' && q.answer !== '') {
              setFatherFamilyName(q.answer)
            }
            if (q.index === '011' && q.answer !== '') {
              setFatherBirthY(q.answer)
            }
            if (q.index === '013' && q.answer !== '') {
              setFatherCountryBirth(q.answer)
            }
          }
        })
      }

      // Formulario de Estado civil Actual
      if (form && form.formData && form.formData.guide === '004') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            if (q.answer === 'Soltero') {
              setFatherMaritalStatus('7')
              setMotherMaritalStatus('7')
            }
            if (q.answer === 'Casado') {
              setFatherMaritalStatus('5')
              setMotherMaritalStatus('5')
            }
            if (q.answer === 'Legalmente separado') {
              setFatherMaritalStatus('4')
              setMotherMaritalStatus('4')
            }
            if (q.answer === 'Unión de hecho (12 meses de convivencia)') {
              setFatherMaritalStatus('2')
              setMotherMaritalStatus('2')
            }
            if (q.answer === 'Matrimonio anulado') {
              setFatherMaritalStatus('1')
              setMotherMaritalStatus('1')
            }
            if (q.answer === 'Divorciado') {
              setFatherMaritalStatus('3')
              setMotherMaritalStatus('3')
            }
            if (q.answer === 'Viudo') {
              setFatherMaritalStatus('8')
              setMotherMaritalStatus('8')
            }
            if (q.answer === 'Desconocido') {
              setFatherMaritalStatus('0')
              setMotherMaritalStatus('0')
            }
          }
          if (q.index === '010') {
            if (q.answer === 'Si') {
              setFatherMaritalStatus('6')
              setMotherMaritalStatus('6')
            }
          }
        })
      }


      // Formulario de Ocupación o empleo (actual y anteriores)
      if (form && form.formData && form.formData.guide === '011') {
        form.formData.questions.map((q: any) => {
          if (q.index === '005') {
            setFatherOcupation(q.answer)
          }
        })
      }
    })
  }


  const handleUploadToServer = async () => {
    toast.info('Estamos procesando tu solicitud. Te notificaremos cuando esté listo. Tiempo de espera aproximado: 3 minutos.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: '#C3CEF6',
        height: '5rem',
        width: '27rem',
        marginLeft:'-7rem',
      },
    })
    connectSocket()
    setAnswers() // Asumo que esta función establece algunas respuestas

    // Aquí accedes a foundContract si está disponible
    if (contractsData) {
      // Extraemos el arreglo 'data' del objeto contractsData
      const dataArray = contractsData.data

      // Encontramos el objeto dentro de dataArray que tenga el mismo envelope_id que contractsFound
      const foundContract = dataArray.find(
        (contract: { contracts_users_envelope_id: any }) => contract.contracts_users_envelope_id === contractsFound
      )

      if (foundContract) {
        console.log('contractsData:', foundContract)

        // Creación del FormData y XML
        const updatedFormData = {
          IMM_5645: {
            page1: {
              Subform1: {
                Visitor: visaVisitor,
                Worker: 0,
                Student: 0,
                Other: 0
              },
              SectionA: {
                Applicant: {
                  AppName: givenName + ' ' + familyName,
                  AppDOB: DOBYear,
                  AppCOB: placeBirthCountry,
                  AppAddress: AptUnit + ' ' + StreetNum + ' ' + Streetname + ' ' + CityTown + ' ' + ProvinceState + ' ' + Country + ' ' + PostalCode,
                  AppOccupation: Ocupation1,
                  ChildMStatus: maritalStatus
                },
                Spouse: {
                  SpouseName: maritalStatusGivenName !== '' ? maritalStatusGivenName : 'N/A',
                  SpouseDOB: PMDateOfBirthY !== '' ? PMDateOfBirthY : 'N/A',
                  SpouseCOB: maritalStatusCountryBirth !== '' ? maritalStatusCountryBirth : 'N/A',
                  SpouseAddress: PMSpouseAddress !== '' ? PMSpouseAddress : 'N/A',
                  SpouseOccupation: PMSpouseOcupation !== '' ? PMSpouseOcupation : 'N/A',
                  SpouseYes: prevMarriedIndicator,
                  SpouseNo: prevMarriedIndicator2,
                  ChildMStatus: maritalStatus
                },
                Mother: {
                  MotherName: motherGivenName !== '' ? `${motherGivenName} ${motherFamilyName}` : 'N/A',
                  MotherDOB: motherBirthY !== '' ? motherBirthY : 'N/A',
                  MotherCOB: motherCountryBirth !== '' ? motherCountryBirth : 'N/A',
                  MotherAddress: motherAddress !== '' ? motherAddress : 'N/A',
                  MotherOccupation: motherOcupation !== '' ? motherOcupation : 'N/A',
                  MotherYes: motherIndicator,
                  MotherNo: motherIndicator2,
                  ChildMStatus: motherMaritalStatus
                },
                Father: {
                  FatherName: fatherGivenName !== '' ? `${fatherGivenName} ${fatherFamilyName}` : 'N/A',
                  FatherDOB: fatherBirthY !== '' ? fatherBirthY : 'N/A',
                  FatherCOB: fatherCountryBirth !== '' ? fatherCountryBirth : 'N/A',
                  FatherAddress: fatherAddress !== '' ? fatherAddress : 'N/A',
                  FatherOccupation: fatherOcupation !== '' ? fatherOcupation : 'N/A',
                  FatherYes: fatherIndicator,
                  FatherNo: fatherIndicator2,
                  ChildMStatus: fatherMaritalStatus
                },
              },
              SectionB: {
                Child: [
                  {
                    ChildName: childrenGivenName !== '' ? childrenGivenName : 'N/A',
                    ChildMStatus: childrenMaritalStatus,
                    ChildRelationship: childrenRelationship,
                    ChildDOB: childrenBirthY !== '' ? childrenBirthY : 'N/A',
                    ChildCOB: childrenCountryBirth !== '' ? childrenCountryBirth : 'N/A',
                    ChildAddress: childrenAddress !== '' ? childrenAddress : 'N/A',
                    ChildOccupation: childrenOcupation !== '' ? childrenOcupation : 'N/A',
                    ChildYes: childrenIndicator,
                    ChildNo: childrenIndicator2
                  },
                  {
                    ChildName: childrenGivenName2 !== '' ? childrenGivenName2 : 'N/A',
                    ChildMStatus: childrenMaritalStatus2,
                    ChildRelationship: childrenRelationship2,
                    ChildDOB: childrenBirthY2 !== '' ? childrenBirthY2 : 'N/A',
                    ChildCOB: childrenCountryBirth2 !== '' ? childrenCountryBirth2 : 'N/A',
                    ChildAddress: childrenAddress2 !== '' ? childrenAddress2 : 'N/A',
                    ChildOccupation: childrenOcupation2 !== '' ? childrenOcupation2 : 'N/A',
                    ChildYes: children2Indicator,
                    ChildNo: children2Indicator2
                  },
                  {
                    ChildName: childrenGivenName3 !== '' ? childrenGivenName3 : 'N/A',
                    ChildMStatus: childrenMaritalStatus3,
                    ChildRelationship: childrenRelationship3,
                    ChildDOB: childrenBirthY3 !== '' ? childrenBirthY3 : 'N/A',
                    ChildCOB: childrenCountryBirth3 !== '' ? childrenCountryBirth3 : 'N/A',
                    ChildAddress: childrenAddress3 !== '' ? childrenAddress3 : 'N/A',
                    ChildOccupation: childrenOcupation3 !== '' ? childrenOcupation3 : 'N/A',
                    ChildYes: children3Indicator,
                    ChildNo: children3Indicator2
                  },
                  {
                    ChildName: childrenGivenName4 !== '' ? childrenGivenName4 : 'N/A',
                    ChildMStatus: childrenMaritalStatus4,
                    ChildRelationship: childrenRelationship4,
                    ChildDOB: childrenBirthY4 !== '' ? childrenBirthY4 : 'N/A',
                    ChildCOB: childrenCountryBirth4 !== '' ? childrenCountryBirth4 : 'N/A',
                    ChildAddress: childrenAddress4 !== '' ? childrenAddress4 : 'N/A',
                    ChildOccupation: childrenOcupation4 !== '' ? childrenOcupation4 : 'N/A',
                    ChildYes: children4Indicator,
                    ChildNo: children4Indicator2
                  }
                ],
              },
              SectionC: {
                Child: [
                  {
                    ChildName: BrotherGivenName !== '' ? BrotherGivenName : 'N/A',
                    ChildMStatus: BrotherMaritalStatus,
                    ChildRelationship: BrotherRelationship,
                    ChildDOB: BrotherBirthY !== '' ? BrotherBirthY : 'N/A',
                    ChildCOB: BrotherCountryBirth !== '' ? BrotherCountryBirth : 'N/A',
                    ChildAddress: BrotherAddress !== '' ? BrotherAddress : 'N/A',
                    ChildOccupation: BrotherOcupation !== '' ? BrotherOcupation : 'N/A',
                    ChildYes: BrotherIndicator,
                    ChildNo: BrotherIndicator2
                  },
                  {
                    ChildName: BrotherGivenName2 !== '' ? BrotherGivenName2 : 'N/A',
                    ChildMStatus: BrotherMaritalStatus2,
                    ChildRelationship: BrotherRelationship2,
                    ChildDOB: BrotherBirthY2 !== '' ? BrotherBirthY2 : 'N/A',
                    ChildCOB: BrotherCountryBirth2 !== '' ? BrotherCountryBirth2 : 'N/A',
                    ChildAddress: BrotherAddress2 !== '' ? BrotherAddress2 : 'N/A',
                    ChildOccupation: BrotherOcupation2 !== '' ? BrotherOcupation2 : 'N/A',
                    ChildYes: Brother2Indicator,
                    ChildNo: Brother2Indicator2
                  },
                  {
                    ChildName: BrotherGivenName3 !== '' ? BrotherGivenName3 : 'N/A',
                    ChildMStatus: BrotherMaritalStatus3,
                    ChildRelationship: BrotherRelationship3,
                    ChildDOB: BrotherBirthY3 !== '' ? BrotherBirthY3 : 'N/A',
                    ChildCOB: BrotherCountryBirth3 !== '' ? BrotherCountryBirth3 : 'N/A',
                    ChildAddress: BrotherAddress3 !== '' ? BrotherAddress3 : 'N/A',
                    ChildOccupation: BrotherOcupation3 !== '' ? BrotherOcupation3 : 'N/A',
                    ChildYes: Brother3Indicator,
                    ChildNo: Brother3Indicator2
                  },
                  {
                    ChildName: BrotherGivenName4 !== '' ? BrotherGivenName4 : 'N/A',
                    ChildMStatus: BrotherMaritalStatus4,
                    ChildRelationship: BrotherRelationship4,
                    ChildDOB: BrotherBirthY4 !== '' ? BrotherBirthY4 : 'N/A',
                    ChildCOB: BrotherCountryBirth4 !== '' ? BrotherCountryBirth4 : 'N/A',
                    ChildAddress: BrotherAddress4 !== '' ? BrotherAddress4 : 'N/A',
                    ChildOccupation: BrotherOcupation4 !== '' ? BrotherOcupation4 : 'N/A',
                    ChildYes: Brother4Indicator,
                    ChildNo: Brother4Indicator2
                  },
                  {
                    ChildName: BrotherGivenName5 !== '' ? BrotherGivenName5 : 'N/A',
                    ChildMStatus: BrotherMaritalStatus5,
                    ChildRelationship: BrotherRelationship5,
                    ChildDOB: BrotherBirthY5 !== '' ? BrotherBirthY5 : 'N/A',
                    ChildCOB: BrotherCountryBirth5 !== '' ? BrotherCountryBirth5 : 'N/A',
                    ChildAddress: BrotherAddress5 !== '' ? BrotherAddress5 : 'N/A',
                    ChildOccupation: BrotherOcupation5 !== '' ? BrotherOcupation5 : 'N/A',
                    ChildYes: Brother5Indicator,
                    ChildNo: Brother5Indicator2
                  },
                  {
                    ChildName: BrotherGivenName6 !== '' ? BrotherGivenName6 : 'N/A',
                    ChildMStatus: BrotherMaritalStatus6,
                    ChildRelationship: BrotherRelationship6,
                    ChildDOB: BrotherBirthY6 !== '' ? BrotherBirthY6 : 'N/A',
                    ChildCOB: BrotherCountryBirth6 !== '' ? BrotherCountryBirth6 : 'N/A',
                    ChildAddress: BrotherAddress6 !== '' ? BrotherAddress6 : 'N/A',
                    ChildOccupation: BrotherOcupation6 !== '' ? BrotherOcupation6 : 'N/A',
                    ChildYes: Brother6Indicator,
                    ChildNo: Brother6Indicator2
                  },
                  {
                    ChildName: BrotherGivenName7 !== '' ? BrotherGivenName7 : 'N/A',
                    ChildMStatus: BrotherMaritalStatus7,
                    ChildRelationship: BrotherRelationship7,
                    ChildDOB: BrotherBirthY7 !== '' ? BrotherBirthY7 : 'N/A',
                    ChildCOB: BrotherCountryBirth7 !== '' ? BrotherCountryBirth7 : 'N/A',
                    ChildAddress: BrotherAddress7 !== '' ? BrotherAddress7 : 'N/A',
                    ChildOccupation: BrotherOcupation7 !== '' ? BrotherOcupation7 : 'N/A',
                    ChildYes: Brother7Indicator,
                    ChildNo: Brother7Indicator2
                  }
                ],
              }
            }
          },
        }
        console.log(updatedFormData, 'datos del formulario')
        const xml = xmlbuilder.create(updatedFormData, { encoding: 'utf-8' }).end({ pretty: true })
        const file = new Blob([xml], { type: 'application/xml' })
        const formData = new FormData()
        formData.append('file', file, fileName)

        // Añadir datos adicionales al FormData como un objeto JSON
        const additionalData = {
          memberName: product.memberName,
          memberType: product.memberType,
          contracts_users_envelope_id: foundContract.contracts_users_envelope_id,
          contracts_users_user_id: foundContract.contracts_users_user_id,
          userId: userAUTH?.id
        }
        formData.append('additionalData', JSON.stringify(additionalData))

        try {
          const response = await axios.post('https://botimm.immiland.app/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          console.log('File uploaded successfully:', response.data)
        } catch (error) {
          console.error('Error uploading file:', error)
        }
      } else {
        console.log('No contract found with envelope id:', contractsFound)
      }
    } else {
      console.log('contractsData is undefined')
    }
  }

  useEffect(() => {
    setAnswers()
  }, [])

  return (
    <div>

      <button
        className='button-ui button-tertiary'
        // style={{
        //   border: '2px solid #18BFFF',
        //   padding: '0.3rem 0.5rem',
        //   borderRadius: '30px',
        //   fontWeight: 'bold',
        //   marginLeft: '1rem',
        // }}
        onClick={handleUploadToServer}
      >
      <img style={{width: '24px'}} src={thunderblue} alt="thunderblue" />
      </button>
    </div>
  )
}

export default IMM5645EDEPENDANT
