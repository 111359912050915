/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect} from 'react'
import {countPagePdf} from './CountPDFPages'

interface IProps {
  files: File[] | null
  getPageCounter?: (totalPages: number) => void
}

const PageCounter: React.FC<IProps> = ({files, getPageCounter}) => {
  // if file[i] is pdf count pages and add to total pages counter else add 1 to total pages counter for each file uploaded (jpg, png, zip) and return total pages

  const [totalPages, setTotalPages] = React.useState(0)

  useEffect(() => {
    countPages()
  }, [files])

  const countPages = async () => {
    let total = 0
  
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'application/pdf') {
          total += await countPagePdf(files[i])
        } else {
          total += 1
        }
      }
    }
  
    setTotalPages(total)
  }

  useEffect(() => {
    getPageCounter != null && getPageCounter(totalPages)
  }, [totalPages])

  return <>{totalPages}</>
}

export default PageCounter
