/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container } from '../../../../components/Container/index'
import { useIntl } from 'react-intl'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import './style.css'
import ConsultorSelector from './consultorSelector'
import ConsultorSelectorOutside from './consultorSelectorOutside'

interface IProps {
  link: string
}

const EmbedCalendly: React.FC<IProps> = ({ link }) => {
  const [width, setWidth] = useState(window.innerWidth >= 1200 ? 1200 : window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight - 20)
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)

  const updateDimensions = () => {
    let width = window.innerWidth
    const height = window.innerHeight - 400
    if (width >= 960) {
      width = 960
    }
    setWidth(width)
    setHeight(height)
  }

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', link)
    head?.appendChild(script)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const handleLocationSelect = (location: string) => {
    setSelectedLocation(location === selectedLocation ? null : location);
  }

  return (
    <>
     <ArrowLeftOutlined
          className=' p-2 hover:bg-gray-200 hover:rounded-lg'
          onClick={() => {
            history.back()
          }}
        />
       <Container title={''}>
       <div style={{ display: 'flex', alignContent:'center', marginBottom:'60px', gap:'10px'}}>
       <div className='image-div' style={{ display: 'flex', alignItems: 'center', backgroundColor: '#01A9DB', padding: '3px',  width: '7rem', height: '2rem', borderRadius:'5px'  }}>
          <img
            src="/media/svg/menu-icons/Group.svg"
            alt="Logo de Immigracion"
            style={{ marginRight: '5px', width: '15px', height: '15px' }}
          />
          <span style={{ color: 'white' }}>Immigracion</span>
        </div> <b className='title-migratory' style={{ color: 'black', fontSize:'1.2rem' }}>Cita migratoria con consultor</b>
       
</div>
        
    
  
      

        <div className='container-primary'>
        <b style={{ color: 'black', fontSize:'1rem', marginBottom:'7px' }}>Selecciona tu Ubicacion</b>
        <label className='label-style' style={{ display: 'flex',    border: '1px solid black',
      borderRadius: '10px', padding:'10px 10px 10px 10px', width:'18rem', height:'3rem', flexWrap:'wrap', justifyContent:'center' }}>
  <input
    type="radio"
    name="location"
    checked={selectedLocation === 'adentro'}
    onChange={() => handleLocationSelect('adentro')}
    style={{
      width: '20px',
      height: '20px',
      marginRight: '5px',
      marginTop: '2px',
 
    }}
  />
  <b className='label-text' style={{ color: 'black' }}>Me encuentro Dentro de Canadá</b>
</label>

<label className='label-style' style={{ display: 'flex',   border: '1px solid black',
      borderRadius: '10px', padding:'10px 10px 10px 10px', width:'18rem', height:'3rem',  flexWrap:'wrap', justifyContent:'center' }}>
  <input
    type="radio"
    name="location"
    checked={selectedLocation === 'afuera'}
    onChange={() => handleLocationSelect('afuera')}
    style={{
      width: '20px',
      height: '20px',
      marginRight: '5px',
      marginTop: '2px',
   
    }}
  />
  <b className='label-text' style={{ color: 'black' }}>Me encuentro Fuera de Canadá</b>
</label>

</div>
        {selectedLocation && (
          <div className='consultors'>
            {selectedLocation === 'afuera' && <ConsultorSelector />}
            {selectedLocation === 'adentro' && <ConsultorSelectorOutside />}
          </div>
        )}
      </Container>
    </>
  )
}

export default EmbedCalendly;