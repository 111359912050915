export const menu = {
  'MENU.NEW': 'new',
  'MENU.ACTIONS': 'Actions',
  'MENU.CREATE_POST': 'Create New Post',
  'MENU.PAGES': 'Pages',
  'MENU.FEATURES': 'Features',
  'MENU.APPS': 'Apps',
  'MENU.DASHBOARD': 'Dashboard',
  'MENU.MYINFO': 'My Info',
  'MENU.MYSERVICES': 'My services',
  'MENU.MYVISA': 'My visa Process',
  'MENU.MYAPPOINTMENTS': 'My appointments',
  'MENU.MYACCESOR': 'My accesor',
  'MENU.MYDOCUMENTS': 'My documents',
  'MENU.ACTIVITIES': 'Activities',
  'MENU.ACTIVITIES_LIST': 'Activitie list',
  'MENU.FILEMANAGER': 'File manager',
  'MENU.TEMPLATES': 'Templates',
  'MENU.MESSAGES': 'Messages',
  'MENU.CLIENTS': 'Clients',
  'MENU.APPOINMENTS': 'Citas por consultor',
  'MENU.TRANSLATION': 'Translations',
  'MENU.AVAILABLESERVICES': 'Available Services',
  'MENU.TIMELINE': 'Timeline',
  'MENU.MYTRANSLATIONS': 'My Translations',
  'MENU.MANAGE': 'Manage',
  'MENU.ADMIN': 'Clients',
  'MENU.STORE': 'Ecomerce',
  'MENU.CHANGEPASSWORD': 'Change Password',
  'MENU.STORE.CATEGORY': 'Categories',
  'MENU.STORE.CUPON': 'Cupons',
  'MENU.STORE.PRODUCT': 'Services',
  'MAIN.NAVIGATION': 'Main Navigation',
  'MENU.APPOINTMENTBYCONSULTANT': 'Appointments by consultant',
  'MENU.CUSTOMERS': 'Customers',
  'MENU.GENERALMESSAGES': 'Messages consultant absent',
  'MENU.INTAKEADMIN': 'Administrador de intake',
  'MENU.PURCHASESERVICE': 'Purchase Service',
  'MENU.MENU.APICALLS': 'Api integrations',
  'MENU.MYAPPOINTMENTSADMIN': 'Appointments Admin',
  'MENU.CREATEAPPOINMENT': 'Create Appointment',
  'MENU.SUPPORT': 'Support Technical',
  'MENU.LIBRARY': 'Library',
  'MENU.MYSHOP': 'My Shop',
}
