import {Avatar} from 'antd'
import React, {useState, useEffect} from 'react'

interface Props {
  image: string
  size: number
  style?: React.CSSProperties
}

const AvatarProfileUsers: React.FC<Props> = ({image, size}) => {
  const getImage = (image: string) => {
    if (image === '') return '/media/avatars/avatar.svg'
    return image
  }

  const [isScreenLarge, setIsScreenLarge] = useState<boolean>(true)

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLarge(window.innerWidth >= 1024)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
     {/*  {isScreenLarge && ( */}
        <Avatar
          src={getImage(image)}
          style={{
            borderRadius: '50%',
          }}
          size={size}
          className='md:w-10 md:h-10 w-8 h-8'
        />
   {/*    )} */}
    </>
  )
}

export default AvatarProfileUsers
