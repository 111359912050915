import styled from 'styled-components'

const Header = styled.div`
  padding: 4px;
  width: 100%;
  height: 100%;
  -webkit-app-region: drag;
  margin-bottom: 45px;
`
export {Header}
