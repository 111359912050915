import tw from 'tailwind-styled-components'

const Success = tw.p`
  italic
  -mt-3
  p-0
  text-xs
  text-verde-700

`
export {Success}
