 
/* eslint-disable  */
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../components'
import {type Files} from '../../../../app/modules/apps/file/core/_models'
import {Input, Space} from 'antd'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'

interface IProps {
  files: Files[]
  inIds: boolean
  showSearch?: boolean
  showAdd?: boolean
  showRemove?: boolean
  handleAddFile?: (file: Files) => void
  handleRemove?: (file: Files) => void
  filter?: string
  setFilter?: (filter: string) => void
}

const ListFiles = ({
  files,
  inIds,
  showSearch,
  showAdd,
  showRemove,
  handleAddFile,
  handleRemove,
  setFilter,
  filter = '',
}: IProps) => {
  const intl = useIntl()

  useEffect(() => {
    // comment to deactivate eslint
  }, [files])

  // uiseEfect  with debounce 500ms
  useEffect(() => {
    const timer = setTimeout(() => {
      // comment to deactivate eslint
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [filter])

  const refetch = () => {
    // comment to deactivate eslint
  }

  return (
    <>
      <Box>
        <Input
          placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
          value={filter}
          onChange={(e) => {
            setFilter != null ? setFilter(e.target.value) : null
          }}
          onPressEnter={() => {
            refetch()
          }}
        />

        <TableContainer style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({id: 'FILEMANAGER.FILE'})}</TableCell>
                <TableCell>{intl.formatMessage({id: 'COMMON.ACTION'})}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((item: any) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Space>
                      {item.edit ? (
                        <>
                          <Input
                            defaultValue={item.name}
                            onChange={(e) => {
                              item.name = e.target.value
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <span>{item.name}</span>
                        </>
                      )}
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      {showAdd && (
                        <Button
                          color='primary'
                          onClick={() => {
                            handleAddFile != null ? handleAddFile(item) : null
                            refetch()
                          }}
                        >
                          {intl.formatMessage({id: 'COMMON.ADD'})}
                        </Button>
                      )}
                      {showRemove && (
                        <Button
                          color='primary'
                          onClick={() => {
                            files.splice(files.indexOf(item), 1)
                            handleRemove != null ? handleRemove(item) : null
                            refetch()
                          }}
                        >
                          {intl.formatMessage({id: 'COMMON.REMOVE'})}
                        </Button>
                      )}
                    </Space>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export {ListFiles}
