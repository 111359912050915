import React from 'react'

const Main = () => {
  return (
    <>
      <h1>desde el Index de layout</h1>
    </>
  )
}

export default Main
