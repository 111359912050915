 
/* eslint-disable */
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useContext, useState, useEffect } from 'react'
import AvatarEdit from './Avatar/AvatarEdit'
import AvatarProfile from './Avatar/AvatarProfile'
import AvatarProfileAction from './Avatar/AvatarProfileAction'
import { CountrySelect } from '../../../../../../app/modules/utils/components/CountrySelect'
import { type Profile } from '../../../../../../app/modules/apps/profile/core/_models'
import { RootStoreContext } from '../../../../../../stores/rootStore'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import type { MenuProps } from 'antd'
import { DatePicker } from 'antd'
import './style.css'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  birth: Yup.date().required('birth name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'El teléfono solo puede contener números')
    .required('El teléfono es obligatorio')
    .min(10, 'El teléfono debe tener al menos 10 dígitos'),
  countryOfOrigin: Yup.string().required('Country of origin is required'),
  gender: Yup.string().required('gender is required'),
  nationalitie: Yup.string().required('Nationalities is required'),
  address: Yup.string().required('Address is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
})

interface Props {
  profile: Profile
  isUserLoading?: boolean
  updateProfileBasic: (profile: Profile) => void
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
}

type MenuItem = Required<MenuProps>['items'][number]

const ProfileDetails: React.FC<Props> = ({
  profile,
  isUserLoading,
  updateProfileBasic,
  showAvatar,
  edit = true,
  setTabActive,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { staff, user } = rootStore.authStore
  const { isEditing } = rootStore.profileStore
  const intl = useIntl()
  const timeZones = moment.tz.names()
  const [loading, setLoading] = useState(false)
  const [activeView, setActiveView] = useState(1)
  const [inputValue, setInputValue] = useState<string>('');
  const [showList, setShowList] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(timeZones);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const formik = useFormik<Profile>({
    initialValues: profile,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await updateProfileBasic(values)
      setLoading(false)
    },
  })

  useEffect(() => {
    const birthDate = formik.values.birth
    if (birthDate instanceof Date) {
      const age = ageCalculator(birthDate)
      formik.setFieldValue('age', age)
    }
  }, [formik.values.birth])

  const ageCalculator = (formikBirthDate: Date) => {
    const currentDate = moment()
    const birthDate = moment(formikBirthDate)
    if (!birthDate.isValid()) {
      return 'No se puede calcular'
    }
    const age = currentDate.diff(birthDate, 'years')
    return age.toString() + ' años de edad'
  }

  useEffect(() => {
    setInputValue(formik.values.timeZone || '');
  }, [formik.values.timeZone]);

  const handleInputChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setShowList(true);

    const filtered = timeZones.filter(option =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setErrorMessage('');
    formik.setFieldValue('timeZone', value); // Actualizar el valor en el objeto formik
  };

  const handleOptionSelect = (value: string) => {
    setInputValue(value);
    setShowList(false);
    setErrorMessage(''); // Limpiar el mensaje de error al seleccionar una opción válida
    formik.setFieldValue('timeZone', value); // Actualizar el valor en el objeto formik
  };

  const handleBlur = () => {
    if (!inputValue && errorMessage) {
      setShowList(false);
    }
    if (!filteredOptions.includes(inputValue) && inputValue !== '') {

      setErrorMessage('Zona horaria incorrecta');
    }
  };

  const [isViewFieldsFilled, setIsViewFieldsFilled] = useState(false)
  const checkIfActiveViewFieldsFilled = () => {
    let isFilled = false;

    const fieldsToCheck =
      activeView === 1
        ? ['firstName', 'lastName', 'birth', 'gender']
        : activeView === 2
          ? ['address', 'phone']
          : activeView === 3
            ? ['countryOfOrigin', 'nationalitie', 'language', 'timeZone']
            : [];
    if (activeView === 2) {
      isFilled = fieldsToCheck.every(fieldName => {
        if (fieldName === 'phone') {
          const phoneValue = formik.values[fieldName];
          return typeof phoneValue === 'string' && phoneValue.length >= 10;
        }
        return !!formik.values[fieldName as keyof Profile];
      });
    } else {
      isFilled = fieldsToCheck.every(fieldName => !!formik.values[fieldName as keyof Profile]);
    }
    setIsViewFieldsFilled(isFilled);
  };

  useEffect(() => {
    checkIfActiveViewFieldsFilled()
  }, [activeView])

  useEffect(() => {
    checkIfActiveViewFieldsFilled()
  }, [formik.values])

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    const newValue = value.replace(/\D/g, ''); // Remover todos los caracteres no numéricos
    event.target.value = newValue;
  };

  return (
    <>
      <div>
        <div id='kt_account_profile_details'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className='w-full card-body2 p-9 bg-white' style={{ borderRadius: '10px' }}>
              {showAvatar ? (
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    {intl.formatMessage({ id: 'PROFILE.AVATAR' })}
                  </H3>
                  <div className='avatar-section'>
                    <div>
                      {isEditing ? (
                        <>
                          <AvatarEdit initialImage={''} />
                        </>
                      ) : (
                        <>
                          <div className='flex justify-start items-center flex-wrap gap-3'>
                            <AvatarProfile initialImage='' size={100} />
                            <AvatarProfileAction editable={true} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className='container-form-modal'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderRadius: '10px',
                  paddingTop: '1rem',
                  marginBottom: '1rem'
                }}>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Nombre
                  </H3>
                  <div className='col-lg-8'>
                    <Input
                      type='text'
                      placeholder='Nombre'
                      {...formik.getFieldProps('firstName')}
                      className='custom-input'
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <Alert variant='danger'>{formik.errors.firstName}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Apellido
                  </H3>
                  <div className='col-lg-8'>
                    <Input
                      type='text'
                      placeholder='Apellido'
                      {...formik.getFieldProps('lastName')}
                      className='custom-input'
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <Alert variant='danger'>{formik.errors.lastName}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question' >
                  <H3 className='lg-12 pl-3 col-form-label title-forms' >
                    Fecha de nacimiento
                  </H3>
                  <div className='col-lg-8'>
                    <DatePicker
                      className='custom-datepicker'
                      style={{ border: '1px solid gray' }}
                      value={
                        formik.getFieldProps('birth').value !== ''
                          ? moment.utc(formik.getFieldProps('birth').value)
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate = moment.utc(date).format('YYYY-MM-DD')
                        formik.setFieldValue('birth', formattedDate)
                      }}
                      data-date-format='yyyy-MM-dd'
                    />
                  </div>
                  {formik.touched.birth && formik.errors.birth && (
                    <Alert variant='danger'>{formik.errors.birth}</Alert>
                  )}
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Edad
                  </H3>
                  <div className='col-lg-8'>
                    <Input
                      className='custom-input '
                      type='text'
                      placeholder={ageCalculator(formik.getFieldProps('birth').value)}
                      value={ageCalculator(formik.getFieldProps('birth').value)}
                      readOnly
                    />
                    {formik.touched.birth && formik.errors.birth && (
                      <Alert variant='danger'>{formik.errors.birth}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Dirección
                  </H3>
                  <div className='col-lg-8'>
                    <Input
                      className='custom-input'
                      type='text'
                      placeholder='Dirección'
                      {...formik.getFieldProps('address')}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <Alert variant='danger'>{formik.errors.address}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Teléfono                    </H3>
                  <div className='col-lg-8'>
                    <Input
                      type='tel'
                      placeholder='Teléfono'
                      {...formik.getFieldProps('phone')}
                      className='custom-input'
                      onInput={handleInputChange}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <Alert variant='danger'>{formik.errors.phone}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    <span className='required'>
                      País en el que reside
                    </span>
                  </H3>
                  <div className='col-lg-8'>
                    <Select {...formik.getFieldProps('countryOfOrigin')} className='custom-input'>
                      <CountrySelect />
                    </Select>
                    {formik.touched.countryOfOrigin && formik.errors.countryOfOrigin && (
                      <Alert>{formik.errors.countryOfOrigin}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Nacionalidad
                  </H3>
                  <div className='col-lg-8'>
                    <Select {...formik.getFieldProps('nationalitie')}
                      className='custom-input'>
                      <CountrySelect />
                    </Select>
                    {formik.touched.nationalitie && formik.errors.nationalitie && (
                      <Alert>{formik.errors.nationalitie}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Idioma
                  </H3>
                  <div className='col-lg-8'>
                    <Select {...formik.getFieldProps('language')} className='custom-input'>
                      <option value=''>Seleccione</option>
                      <option value='en'>English</option>
                      <option value='es'>Español - Spanish</option>
                      <option value='fr'>Français - French</option>
                    </Select>
                    {formik.touched.language && formik.errors.language && (
                      <Alert>{formik.errors.language}</Alert>
                    )}
                  </div>
                </div>
                <div className='container-question'>
                  <div style={{ display: 'flex', alignItems: 'center' }}> <H3 className='lg-12 pl-3 col-form-label title-forms'>Zona Horaria (Ciudad)</H3>
                    {errorMessage && (
                      <div className='fv-plugins-message-container'>
                        <div style={{ marginLeft: '18px', fontSize: '12px', marginBottom: '4px' }} className='custom-error-message'>{errorMessage}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-8'>
                    <input
                      type="text"
                      placeholder='Zona Horaria'
                      value={inputValue}
                      onChange={handleInputChange2}
                      onBlur={handleBlur}
                      className='custom-input' />
                    {showList && (
                      <div className='results-list-form'>
                        {filteredOptions.map((timeZone: string) => (
                          <div
                            key={timeZone}
                            className='search-result'
                            onClick={() => handleOptionSelect(timeZone)}
                          >
                            {timeZone}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className='container-question '>
                  <H3 className='lg-12 pl-3 col-form-label title-forms'>
                    Género
                  </H3>
                  <div className='col-lg-8'>
                    <Select {...formik.getFieldProps('gender')}
                      className='custom-input'>
                      <option value=''>Seleccione</option>
                      <option value='M'>Masculino</option>
                      <option value='F'>Femenino</option>
                      <option value='O'>Otro</option>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && (
                      <Alert>{formik.errors.gender}</Alert>
                    )}
                  </div>
                </div>
                {staff && staff.type === 'consultant' && (
                  <div className='container-question'>
                    <H3 className='lg-12 pl-3 col-form-label title-forms'>
                      Descripción
                    </H3>
                    <div className='col-lg-8'>
                      <textarea
                        className='custom-input'
                        placeholder={'Datos del consultor'}
                        maxLength={200} />
                    </div>
                    {formik.touched.phone && formik.errors.phone && (
                      <Alert variant='danger'>{formik.errors.phone}</Alert>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className='flex w-full mt-4 items-center justify-center'
              style={{ marginBottom: '8rem' }}>
              <Button
                className={`button-ui button-primary ${!formik.values.timeZone ? 'disabled-button' : ''}`}
                type='submit'
                disabled={!isViewFieldsFilled || !formik.values.timeZone || !!errorMessage}
                onClick={() => {
                  // Verifica si no hay errores y setTabActive no es nulo, y luego establece la pestaña activa
                  if (!isEmpty(formik.errors) || setTabActive == null) {
                    return;
                  }
                  setTabActive('profile');
                }}>
                {!loading && 'Guardar'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Por favor espere...{' '}
                  </span>
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default observer(ProfileDetails)
