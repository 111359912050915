/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import {Space, Table} from 'antd'
import {Button} from '../../../../../components'
import {useQuery} from 'react-query'
import Column from 'antd/lib/table/Column'
import {useIntl} from 'react-intl'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {getFormsWithUserId} from '../../../../../app/modules/apps/formsUsers/core/_request'
import {SurveyClient} from './surveyClient'
import * as Survey from 'survey-react'

interface IProps {
  formsusersData?: string
  handleClose: (key: string) => void
}
const ListFormsClient: React.FC<IProps> = ({formsusersData, handleClose}) => {
  const [showSurvey, setShowSurvey] = useState(false)
  const [jsonDataSelected, setJsonDataSelected] = useState({})
  const [formNameSelected, setformNameSelected] = useState('')
  const [formsDataIdSelected, setFormsDataIdSelected] = useState('')
  const [showListFormsClientTable, setShowListFormsClientTable] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {currentUser} = rootStore.usersStore
  const intl = useIntl()
  const {user} = rootStore.authStore

  useEffect(() => {
    if (user?.role?.name === 'User') {
      setShowListFormsClientTable(true)
    }
  }, [user])

  const handleStartForm = (recordData: object, formName: string, formsDataId: string) => {
    setShowSurvey(true)
    setJsonDataSelected(recordData)
    setformNameSelected(formName)
    setFormsDataIdSelected(formsDataId)
  }

  const {
    data: dataResult,
    status,
    refetch,
  } = useQuery('listformsclients', async () => await getFormsWithUserId(`${user?.id}`))

  useEffect(() => {
    Survey.StylesManager.applyTheme('defaultV2')
  }, [])

  if (showSurvey) {
    return (
      <>
        <SurveyClient
          userId={`${user?.id}`}
          formId={formNameSelected}
          surveyJson={jsonDataSelected}
          formsDataIdSelected={formsDataIdSelected}
        />
        <Button
          className='mb-3 '
          onClick={() => {
            handleClose('menu')
          }}
        >
          Regresar
        </Button>
      </>
    )
  } else {
    if (user !== undefined) {
      if (showListFormsClientTable) {
        return (
          <>
            {dataResult !== null ? (
              <Table dataSource={dataResult} rowKey='id'>
                <Column
                  title={intl.formatMessage({id: 'MYINFO.FORMSNAME'})}
                  key='action'
                  render={(text, record: any) => (
                    <Space>
                      <Space>{record.formsname}</Space>
                    </Space>
                  )}
                />
                <Column
                  title={intl.formatMessage({id: 'MENU.ACTIONS'})}
                  key='action'
                  render={(text, record: any) => (
                    <Space>
                      <Space>
                        <Button
                          type='link'
                          onClick={() => {
                            handleStartForm(record.data, record.id, record.formsdataid)
                          }}
                          style={{color: 'green', fontSize: '14px', border: '1px solid'}}
                        >
                          {intl.formatMessage({id: 'LISTFORMS.FILLTHEFORM'})}
                        </Button>
                      </Space>
                    </Space>
                  )}
                />
              </Table>
            ) : null}
            <Button
              className='mb-3 '
              onClick={() => {
                handleClose('menu')
              }}
            >
              Regresar
            </Button>
          </>
        )
      } else {
        return <h1> {intl.formatMessage({id: 'LISTFORMS.YOUARENOTCLIENT'})}</h1>
      }
    } else {
      return <h1> {intl.formatMessage({id: 'LISTFORMS.USERISUNDEFINED'})}</h1>
    }
  }
}
export {ListFormsClient}
