import React, {useEffect} from 'react'
import {type Messages} from '../../../../../app/modules/apps/messages/core/_models'
import Search from '../../../../../components/Search'
import {getMessagesFilter} from '../../../../../app/modules/apps/messages/core/_requests'
import {useQuery} from 'react-query'

interface IProps {
  setMessagesSeacrh: (messages: Messages[] | null) => void
  clearSearch: boolean
}

const SearchMessages: React.FC<IProps> = ({setMessagesSeacrh, clearSearch}) => {
  const [search, setSearch] = React.useState('')

  const {data, refetch} = useQuery('getAllStaffs', async () => await getMessagesFilter(search), {
    enabled: false,
  })

  const handleSearch = (value: string) => {
    setSearch(value)
  }
  useEffect(() => {
    if (data != null) setMessagesSeacrh(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (clearSearch) {
      setMessagesSeacrh(null)
      setSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch])

  useEffect(() => {
    if (search === '') {
      setMessagesSeacrh(null)
    } else {
      const delayDebounceFn = setTimeout(() => {
        refetch()
      }, 500)

      return () => {
        clearTimeout(delayDebounceFn)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <>
      <Search
        value={search}
        onChange={(e: any) => {
          handleSearch(e.target.value)
        }}
      />
    </>
  )
}

export default SearchMessages
