/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type Dispatch, type SetStateAction, useContext, useState, useEffect, useRef } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { useQuery } from 'react-query'
import { Checkbox, DatePicker, Space } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import {
  getFormsCompletedDataById,
  updateFormWithData,
} from 'app/modules/apps/formsdata/core/_requests'
import { CountrySelect } from 'app/modules/utils/components/CountrySelect'
import moment from 'moment'
import Lifering from '../../icons/question-circle.svg'
import { uploadNoFileIntake } from 'app/modules/apps/file/core/_requests'
import { toast } from 'react-toastify'
import PostalCodeSearch from './postaCodeFilter'
import { CitySelect } from 'app/modules/utils/components/CanadaCitiesSelect/Index'

interface IProps {
  name: string
  formIndex: number
  openForm: boolean
  setOpenForm: Dispatch<SetStateAction<boolean>>
  setBarUpdate: Dispatch<SetStateAction<number>>
  barUpdate: number
  setActivePorcentageBar: Dispatch<SetStateAction<boolean>>
  setActiveRefech: Dispatch<SetStateAction<boolean>>
  productId: string
  contractId: string
  formCompletedId: string
  setDraft: Dispatch<SetStateAction<boolean>>
  setIndex: Dispatch<SetStateAction<string>>
  setMemberNameSelected: Dispatch<SetStateAction<string>>
  memberNameSelected: string
  setMemberTypeSelected: Dispatch<SetStateAction<string>>
  memberTypeSelected: string
  refetch: () => void
  refetchForms: () => void
  refetchFromDataBase: () => void
  setUploadThing: Dispatch<SetStateAction<boolean>>
  setPositionIndex: Dispatch<SetStateAction<any>>
  positionIndex: any
  guides: any
  userAllForms: any
}

const DisplayFormDarft: React.FC<IProps> = ({
  name,
  setOpenForm,
  barUpdate,
  setBarUpdate,
  setActivePorcentageBar,
  setActiveRefech,
  productId,
  contractId,
  formCompletedId,
  setDraft,
  setIndex,
  setMemberNameSelected,
  memberNameSelected: memberName,
  setMemberTypeSelected,
  memberTypeSelected: memberType,
  refetch,
  refetchForms,
  refetchFromDataBase,
  setUploadThing,
  setPositionIndex,
  positionIndex,
  guides,
  userAllForms

}) => {
  const debounceTimer = useRef<NodeJS.Timeout | null>(null)
  const rootStore = useContext(RootStoreContext)
  const { user, updatingContracts } = rootStore.authStore
  const [shouldShowConditional, setShouldShowConditional] = useState(false)
  const [currentAnswers, setCurrentAnswers] = useState<any>(null)
  const [currentUser, setCurrentUser] = useState('')
  const [readValidator, setReadValidator] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [spawnQuestion, setSpawnQuestion] = useState(0)
  const [alert, setAlert] = useState(false)
  const [alertIndex, setAlertIndex] = useState(0)
  const [startDate, setStartDate] = useState<Record<number, string>>({})
  const [endDate, setEndDate] = useState<Record<number, string>>({})
  const [errors, setErrors] = useState<Record<number, string>>({})
  const [errors2, setErrors2] = useState<Record<number, string>>({})
  const [noErrors, setNoErrors] = useState(true)
  const [noErrors2, setNoErrors2] = useState(true)
  const [isValid2, setIsValid2] = useState(false)

  const {
    data: dataById,
    status,
    isLoading,
    refetch: refetchForContinuous
  } = useQuery(
    'listformsclientsbydraftid',
    async () => await getFormsCompletedDataById(formCompletedId)
  )

  const handleStartDateChange = (index: number, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : ''
    setStartDate(prev => ({ ...prev, [index]: dateString }))
    if (dateString && endDate[index] && dateString > endDate[index]) {
      setErrors(prev => ({ ...prev, [index]: 'La fecha "Desde" no puede ser mayor que la fecha "Hasta".' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }

  const handleEndDateChange = (index: number, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : ''
    setEndDate(prev => ({ ...prev, [index]: dateString }))
    if (startDate[index] && dateString && startDate[index] > dateString) {
      setErrors(prev => ({ ...prev, [index]: 'La fecha "Hasta" no puede ser menor que la fecha "Desde".' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }

  const handleDateMustBeCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha debe ser mayor a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  const handleDateMustBeNotCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha debe ser previa a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  const handleDateFromMustBeCurrent = (date: any, current: any, index: number) => {
    if (date && date !== null) {
      if (current === true && date.isSameOrBefore(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev, [index]: 'La fecha desde debe ser mayor a hoy' } // Debugging log
          return newErrors
        })
      } else if (current === true && date.isSameOrAfter(moment(), 'day')) {
        setErrors2(prev => {
          const newErrors = { ...prev }
          delete newErrors[index] // Debugging log
          return newErrors
        })
      }
    }
  }

  useEffect(() => {
    setNoErrors2(Object.keys(errors2).length === 0)
  }, [errors2])

  useEffect(() => {
    if (noErrors2 !== null) {
      if (currentAnswers) {
        validateDataForSave2(currentAnswers)
      }
    }
  }, [noErrors2]);

  useEffect(() => {
    setNoErrors(Object.keys(errors).length === 0)
  }, [errors])

  useEffect(() => {
    if (noErrors !== null) {
      if (currentAnswers) {
        validateDataForSave2(currentAnswers)
      }
    }
  }, [noErrors]);

  useEffect(() => {
    user?.id !== undefined ? setCurrentUser(user?.id) : setCurrentUser('')
  }, [user])

  useEffect(() => {
    refetchForContinuous()
  }, [formCompletedId])

  useEffect(() => {
    if (isValid === true) {
      const status = 1
      saveForms(currentAnswers, status)
      setReadValidator(readValidator + 1)
      setShowError(false)
      setBarUpdate(barUpdate + 1)
      setActivePorcentageBar(false)
      setActiveRefech(true)
      setDraft(false)
    }
  }, [isValid])

  useEffect(() => {
    if (currentAnswers !== undefined) {
      setSpawnQuestion(1)
    }
  }, [currentAnswers])

  if (status === 'success' && isLoading === false/*  && currentAnswers === null */) {
    setTimeout(() => {
      setCurrentAnswers(dataById)
    }, 500)
  }

  if (status === 'loading') {
    return (
      <>
        <span className='spinner-border spinner-border-lx align-middle ms-2'></span>
      </>
    )
  }

  const hideConditionalQuestions = (cond: any, index: number) => {
    cond.hide = 1
  }

  const SaveQuestionDocs = (currentAnswers: any) => {
    currentAnswers.datafill.questions.map((q: any) => {
      q.docsAttached &&
        q.docsAttached.map((attached: any) => {
          if (attached.trigger === q.answer) {
            attached.docs.map(async (doc: any) => {
              await uploadNoFileIntake(doc, user?.id!, contractId, productId, memberName, memberType)
            })
          }
        })
    })
  }

  const conditionalQuestions = (cond: any, index: number) => {
    cond.hide = 0
    return (
      <>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                      debounceTimer.current = setTimeout(() => {
                        validateDataForSave2(currentAnswers)
                      }, 500)
                    }}>
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
                <div
                  className='popup-form-style'
                  dangerouslySetInnerHTML={{ __html: cond.info }}
                />              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div >
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      No se admiten caracteres especiales
                    </p>
                  )}
                  <input
                    type='text'
                    title='name'
                    defaultValue={cond.answer}
                    maxLength={40}
                    placeholder='Escriba aquí'
                    className='input-styles-quetion'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s/]/g, '')
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)
                      if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      validateDataForSave2(currentAnswers)
                    }} />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>) : null}

        {/* Type Numeric Input */}
        {cond.type === 'numericInput' ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      Solo se admiten caracteres numericos
                    </p>
                  )}
                  <input
                    type="text"
                    title="name"
                    defaultValue={cond.answer}
                    maxLength={28}
                    placeholder="Escriba aquí"
                    className="input-styles-quetion"
                    value={cond.answer} // Usar value en lugar de manipular directamente el valor
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue.replace(/[^0-9]/g, '')
                      cond.answer = cleanInput
                      if (/[^0-9]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      setReadValidator(readValidator + 1)
                      validateDataForSave2(currentAnswers)
                    }} />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='text-format w-full'>
                <div className='flex w-full' >
                  <div className='flex flex-col w-full' style={{ height: '6rem' }}>
                    {alert === true && alertIndex === index && (
                      <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                        * No se admiten caracteres especiales
                      </p>
                    )}
                    <textarea
                      title='name'
                      maxLength={200}
                      defaultValue={cond.answer}
                      className='text-styles'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s]/g, '')
                        e.target.value = cleanInput
                        cond.answer = cleanInput
                        setReadValidator(readValidator + 1)
                        if (/[^A-Z0-9\s]/.test(cleanInput)) {
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          setAlert(false)
                          setAlertIndex(0)
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    />
                  </div>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                </div>
                <span style={{ fontSize: '10px' }}>Max 100 carácteres</span>
                {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                  <span
                    style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginBottom: '3rem',
                      marginLeft: '0.3rem',
                      fontSize: '10px'
                    }}>
                    {cond.validateAnswer}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex justify-between'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked === true) {
                          cond.multipleAnswer.push(e.target.value)
                          setReadValidator(readValidator + 1)
                        } else {
                          const indexToRemove = cond.multipleAnswer.indexOf(e.target.value)
                          if (indexToRemove !== -1) {
                            cond.multipleAnswer.splice(indexToRemove, 1)
                          }
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    >
                      {choice}
                    </Checkbox>
                  ))}
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      className='new-date-style'
                      format='YYYY-MM-DD'
                      defaultValue={
                        cond.answer
                          ? moment(cond.answer, 'YYYY-MM-DD')
                          : undefined
                      }
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.answer = date.format('YYYY-MM-DD').toString()
                          setReadValidator(readValidator + 1)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                        validateDataForSave2(currentAnswers)
                      }} />
                  </Space>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(cond, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className="flex flex-col gap-12">
            <div className="flex justify-between">
              <p className="question-styles">{cond.question}</p>
              <div className="flex">
                <div className="flex flex-col gap-4 ml-16">
                  <Space direction="vertical">
                    <p className="m-0">Desde:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={
                        cond.multipleAnswer[0]
                          ? moment(cond.multipleAnswer[0], 'YYYY-MM-DD')
                          : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                          handleStartDateChange(index, date)
                          handleDateFromMustBeCurrent(date, cond.fromMustBeCurrent, index)
                        } else {
                          handleStartDateChange(index, null)
                          handleDateFromMustBeCurrent(null, cond.fromMustBeCurrent, index)
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    />
                  </Space>
                  <Space direction="vertical">
                    <p className="m-0">Hasta:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[1]
                        ? moment(cond.multipleAnswer[1], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          handleEndDateChange(index, date)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleEndDateChange(index, null)
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    />
                  </Space>
                  <div
                    className="popup-from-style"
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {errors && errors[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors[index]}</p>}
                  {cond.validate === true && !cond.multipleAnswer && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex'>
            <p className='paragraph-styles' style={{ color: '#ababab', fontSize: '14px' }}>
              {cond.question}
            </p>
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex'>
            <p className='description-styles' style={{ fontSize: '14px' }}>
              {cond.question}
            </p>
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <a
              style={{
                fontSize: '14px'
              }}
              className='paragraph-styles'
              href={cond.link}>
              {cond.question}
            </a>
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                      debounceTimer.current = setTimeout(() => {
                        validateDataForSave2(currentAnswers)
                      }, 500)
                    }}>
                    <CountrySelect />
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions2(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type city */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      setSpawnQuestion(spawnQuestion + 1)
                      debounceTimer.current = setTimeout(() => {
                        validateDataForSave2(currentAnswers)
                      }, 500)
                    }}>
                    <CitySelect province={cond.conditional} />
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                    initialPostalCode={cond.answer} />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>) : null}
      </>
    )
  }

  const conditionalQuestions2 = (cond: any, index: number): React.JSX.Element => {
    cond.hide = 0
    return (
      <>
        {/* Type option */}
        {cond.type === 'option' && (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      shouldShowConditional === true
                        ? setShouldShowConditional(false)
                        : setShouldShowConditional(true)
                      setSpawnQuestion(spawnQuestion + 1)
                      debounceTimer.current = setTimeout(() => {
                        validateDataForSave2(currentAnswers)
                      }, 500)
                    }}>
                    <option value=''>Seleccione</option>
                    {cond.option.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        )}

        {/* Type Input */}
        {cond.type === 'input' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      *No se admiten caracteres especiales
                    </p>
                  )}
                  <input
                    type='text'
                    title='name'
                    defaultValue={cond.answer}
                    maxLength={40}
                    placeholder='Escriba aquí'
                    className='input-styles-quetion'
                    onChange={(e) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue
                        .normalize('NFD') // Normalize the string
                        .replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .toUpperCase() // Convert to uppercase
                        .replace(/[^A-Z0-9\s/]/g, '')
                      e.target.value = cleanInput
                      cond.answer = cleanInput
                      setReadValidator(readValidator + 1)
                      if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      validateDataForSave2(currentAnswers)
                    }} />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>) : null}

        {/* Type Numeric Input */}
        {cond.type === 'numericInput' ? (
          <div>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='flex flex-col'>
                  {alert === true && alertIndex === index && (
                    <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                      *Solo se admiten caracteres numericos
                    </p>
                  )}
                  <input
                    type="text"
                    title="name"
                    defaultValue={cond.answer}
                    maxLength={28}
                    placeholder="Escriba aquí"
                    className="input-styles-quetion"
                    value={cond.answer} // Usar value en lugar de manipular directamente el valor
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value
                      const cleanInput = inputValue.replace(/[^0-9]/g, '')
                      cond.answer = cleanInput
                      if (/[^0-9]/.test(cleanInput)) {
                        setAlert(true)
                        setAlertIndex(index)
                      } else {
                        setAlert(false)
                        setAlertIndex(0)
                      }
                      setReadValidator(readValidator + 1)
                      validateDataForSave2(currentAnswers)
                    }} />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true &&
                    cond.answer === '' &&
                    readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Text */}
        {cond.type === 'text' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='text-format w-full'>
                <div className='flex w-full' >
                  <div className='flex flex-col w-full' style={{ height: '6rem' }}>
                    {alert === true && alertIndex === index && (
                      <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                        No se admiten caracteres especiales
                      </p>)}
                    <textarea
                      title='name'
                      maxLength={200}
                      defaultValue={cond.answer}
                      className='text-styles'
                      onChange={(e) => {
                        const inputValue = e.target.value
                        const cleanInput = inputValue
                          .normalize('NFD') // Normalize the string
                          .replace(/[\u0300-\u036f]/g, '') // Remove accents
                          .toUpperCase() // Convert to uppercase
                          .replace(/[^A-Z0-9\s]/g, '')
                        e.target.value = cleanInput
                        cond.answer = cleanInput
                        setReadValidator(readValidator + 1)
                        if (/[^A-Z0-9\s]/.test(cleanInput)) {
                          setAlert(true)
                          setAlertIndex(index)
                        } else {
                          setAlert(false)
                          setAlertIndex(0)
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    />
                  </div>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                </div>
                <span style={{ fontSize: '10px' }}>Max 100 carácteres</span>
                {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                  <span
                    style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginBottom: '3rem',
                      marginLeft: '0.3rem',
                      fontSize: '10px'
                    }}>
                    {cond.validateAnswer}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Type choice */}
        {cond.type === 'choice' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex justify-between'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div className='choice-option'>
                  {cond.choices.map((choice: string) => (
                    <Checkbox
                      value={choice}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked === true) {
                          cond.multipleAnswer.push(e.target.value)
                          setReadValidator(readValidator + 1)
                        } else {
                          const indexToRemove = cond.multipleAnswer.indexOf(e.target.value)
                          if (indexToRemove !== -1) {
                            cond.multipleAnswer.splice(indexToRemove, 1)
                          }
                        }
                        validateDataForSave2(currentAnswers)
                      }}>
                      {choice}
                    </Checkbox>
                  ))}
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type Date */}
        {cond.type === 'date' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <Space direction='vertical'>
                    <DatePicker
                      className='new-date-style'
                      format='YYYY-MM-DD'
                      defaultValue={
                        cond.answer
                          ? moment(cond.answer, 'YYYY-MM-DD')
                          : undefined
                      }
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.answer = date.format('YYYY-MM-DD').toString()
                          setReadValidator(readValidator + 1)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          console.log('')
                          handleDateMustBeCurrent(null, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(null, cond.mustBeNotCurrent, index)
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    />
                  </Space>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Interval */}
        {cond.type === 'interval' ? (
          <div className="flex flex-col gap-12">
            <div className="flex justify-between">
              <p className="question-styles">{cond.question}</p>
              <div className="flex">
                <div className="flex flex-col gap-4 ml-16">
                  <Space direction="vertical">
                    <p className="m-0">Desde:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[0]
                        ? moment(cond.multipleAnswer[0], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                          handleStartDateChange(index, date)
                          handleDateFromMustBeCurrent(date, cond.fromMustBeCurrent, index)
                        } else {
                          handleStartDateChange(index, null)
                          handleDateFromMustBeCurrent(null, cond.fromMustBeCurrent, index)
                        }
                        validateDataForSave2(currentAnswers)
                      }}
                    />
                  </Space>
                  <Space direction="vertical">
                    <p className="m-0">Hasta:</p>
                    <DatePicker
                      format="YYYY-MM-DD"
                      defaultValue={cond.multipleAnswer[1]
                        ? moment(cond.multipleAnswer[1], 'YYYY-MM-DD')
                        : undefined}
                      onChange={(date) => {
                        if (date && date !== null) {
                          cond.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                          handleEndDateChange(index, date)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        } else {
                          handleEndDateChange(index, null)
                          handleDateMustBeCurrent(date, cond.mustBeCurrent, index)
                          handleDateMustBeNotCurrent(date, cond.mustBeNotCurrent, index)
                        }
                        validateDataForSave2(currentAnswers)
                      }} />
                  </Space>
                  <div
                    className="popup-from-style"
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {errors2 && errors2[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors2[index]}</p>}
                  {errors && errors[index] &&
                    <p style={{
                      color: '#bf3c3c',
                      fontWeight: 'bolder',
                      marginLeft: '0.3rem',
                      marginBottom: '0'
                    }}>{errors[index]}</p>}
                  {cond.validate === true && !cond.multipleAnswer && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type Video */}
        {cond.type === 'video' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <iframe className='iframe-style' src={cond.video} title={cond.question} />
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Image */}
        {cond.type === 'image' ? (
          <div className='flex flex-col'>
            <p className='question-styles'>{cond.question}</p>
            <div className='flex'>
              <img className='iframe-style' src={cond.image} alt={cond.question} />
              <div className='popup-form-style' dangerouslySetInnerHTML={{ __html: cond.info }} />
            </div>
          </div>
        ) : null}

        {/* Type Paragraph */}
        {cond.type === 'paragraph' ? (
          <div className='flex'>
            <p className='paragraph-styles' style={{ color: '#ababab', fontSize: '14px' }}>
              {cond.question}
            </p>
          </div>
        ) : null}

        {/* Type description */}
        {cond.type === 'description' ? (
          <div className='flex'>
            <p
              className='description-styles'
              style={{ fontSize: '14px' }}>
              {cond.question}
            </p>
          </div>
        ) : null}

        {/* Type link */}
        {cond.type === 'link' ? (
          <div className='flex'>
            <a
              style={{ fontSize: '14px' }}
              className='paragraph-styles'
              href={cond.link}>
              {cond.question}
            </a>
          </div>
        ) : null}

        {/* Type country */}
        {cond.type === 'country' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      setSpawnQuestion(spawnQuestion + 1)
                      debounceTimer.current = setTimeout(() => {
                        validateDataForSave2(currentAnswers)
                      }, 500)
                    }}>
                    <CountrySelect />
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type province */}
        {cond.type === 'province' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='AB'>Alberta</option>
                    <option value='BC'>British Columbia</option>
                    <option value='MB'>Manitoba</option>
                    <option value='NB'>New Brunswick</option>
                    <option value='NL'>Newfoundland and Labrador</option>
                    <option value='NS'>Nova Scotia</option>
                    <option value='NU'>Nunavut</option>
                    <option value='ON'>Ontario</option>
                    <option value='QC'>Quebec</option>
                    <option value='SK'>Saskatchewan</option>
                    <option value='YT'>Yukon</option>
                    <option value='PE'>Prince Edward Island</option>
                    <option value='NT'>Northwest Territories</option>
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/* Type city */}
        {cond.type === 'city' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      setSpawnQuestion(spawnQuestion + 1)
                      debounceTimer.current = setTimeout(() => {
                        validateDataForSave2(currentAnswers)
                      }, 500)
                    }}>
                    <CitySelect province={cond.conditional} />
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {spawnQuestion > 0 &&
              cond.answer !== '' &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer === extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? conditionalQuestions(extra, i)
                  : null
              )}
            {spawnQuestion > 0 &&
              currentAnswers &&
              currentAnswers !== undefined &&
              currentAnswers.datafill.questions.map((extra: any, i: number) =>
                cond.answer !== extra.conditional &&
                  cond.question === extra.conditionalQuestion &&
                  cond.index === extra.conditionId
                  ? hideConditionalQuestions(extra, i)
                  : null
              )}
          </div>
        ) : null}

        {/*Type language */}
        {cond.type === 'language' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>

              </div>
              <div className='flex'>
                <div>
                  <select
                    title='option'
                    className='option-styles '
                    value={cond.answer}
                    onChange={(e: any) => {
                      cond.answer = e.target.value
                      setSpawnQuestion(spawnQuestion + 1)
                    }}>
                    <option value=''>Seleccione</option>
                    <option value='English'>English</option>
                    <option value='Mandarin'>Mandarin Chinese</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='French'>French</option>
                    <option value='Arabic'>Standard Arabic</option>
                    <option value='Bengali'>Bengali</option>
                    <option value='Russian'>Russian</option>
                    <option value='Portuguese'>Portuguese</option>
                    <option value='Urdu'>Urdu</option>
                    <option value='Indonesian'>Indonesian</option>
                    <option value='German'>German</option>
                    <option value='Japanese'>Japanese</option>
                    <option value='Nigerian'>Nigerian Pidgin</option>
                    <option value='Marathi'>Marathi</option>
                    <option value='Telugu'>Telugu</option>
                    <option value='Turkish'>Turkish</option>
                    <option value='Tamil'>Tamil</option>
                    <option value='Yue'>Yue Chinese</option>
                    <option value='Vietnamese'>Vietnamese</option>
                    <option value='Tagalog'>Tagalog</option>
                    <option value='Wu'>Wu Chinese</option>
                    <option value='Korean'>Korean</option>
                    <option value='Iranian'>Iranian Persian (Farsi)</option>
                    <option value='Hausa'>Hausa</option>
                    <option value='Egyptian'>Egyptian Spoken Arabic</option>
                    <option value='Swahili'>Swahili</option>
                    <option value='Javanese'>Javanese</option>
                    <option value='Italian'>Italian</option>
                    <option value='Punjabi'>Western Punjabi</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='Gujarati'>Gujarati</option>
                    <option value='Thai'>Thai</option>
                  </select>
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}>
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Type postalcode */}
        {cond.type === 'postalcode' ? (
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <div className='flex gap-1'>
                {cond.validate === true ? (
                  <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                    *
                  </p>
                ) : (
                  <div className='info-styles' />
                )}
                <p className='question-styles'>{cond.question}</p>
              </div>
              <div className='flex'>
                <div>
                  <PostalCodeSearch
                    onPostalCodeSelect={(selectedPostalCode: string) => {
                      cond.answer = selectedPostalCode;
                    }}
                    initialPostalCode={cond.answer}
                  />
                  <div
                    className='popup-form-style'
                    dangerouslySetInnerHTML={{ __html: cond.info }}
                  />
                  {cond.validate === true && cond.answer === '' && readValidator > 0 ? (
                    <span
                      style={{
                        color: '#bf3c3c',
                        fontWeight: 'bolder',
                        marginBottom: '3rem',
                        marginLeft: '0.3rem',
                        fontSize: '10px'
                      }}
                    >
                      {cond.validateAnswer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>) : null}
      </>
    )
  }

  const saveForms = async (answers: any, status: number) => {
    let dataFormData: object
    if (answers.id != null) {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.datafill,
        product: productId,
        contract: contractId,
        status: status,
        memberName: memberName,
        memberType: memberType
      }
    } else {
      dataFormData = {
        user: user?.id,
        form: answers.id,
        datafill: answers.datafill,
        product: productId,
        contract: contractId,
        status: status,
        memberName: memberName,
        memberType: memberType
      }
    }

    const result = await updateFormWithData(currentAnswers.id, dataFormData)
    console.log('saved')

    setTimeout(() => {
      refetch()
      refetchForms()
      refetchFromDataBase()
      /*  setOpenForm(false) */
      setUploadThing(true)
      setDraft(false)
      setIndex('')
      setActivePorcentageBar(false)
      setActiveRefech(false)
      if (result) {
        toast.success('Formulario enviado con éxito')
        updatingContracts()
        if (userAllForms.formsStatusCompleted[0].forms.length + 1 === guides.length) {
          setOpenForm(false)
        }
        const index = guides.indexOf(positionIndex)
        const foundValue = guides[index + 1]
        setPositionIndex(foundValue)
      }
      setTimeout(() => {
        setCurrentAnswers(null)
      }, 100)
    }, 500)
  }

  const validateDataForSave = (answers: any) => {
    let allQuestionsValid = true
    answers.datafill.questions.forEach((answer: any) => {
      if (noErrors === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (noErrors2 === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'input' ||
          answer.type === 'date' ||
          answer.type === 'text' ||
          answer.type === 'option' ||
          answer.type === 'country') &&
        answer.answer === ''
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'choice' || answer.type === 'interval') &&
        answer.multipleAnswer.length === 0
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
    })
    if (allQuestionsValid) {
      setIsValid(true)
    }
  }

  const validateDataForSave2 = (answers: any) => {
    let allQuestionsValid = true
    answers.datafill.questions.forEach((answer: any) => {
      if (noErrors === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (noErrors2 === false) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'input' ||
          answer.type === 'date' ||
          answer.type === 'text' ||
          answer.type === 'option' ||
          answer.type === 'country') &&
        answer.answer === ''
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
      if (
        answer.dependent === false &&
        answer.validate === true &&
        (answer.type === 'choice' || answer.type === 'interval') &&
        answer.multipleAnswer.length === 0
      ) {
        setReadValidator(readValidator + 1)
        console.log('no es valido')
        allQuestionsValid = false
      }
    })
    if (allQuestionsValid) {
      setIsValid2(true)
    } else {
      setIsValid2(false)
    }
  }

  return (
    <>
      {currentAnswers &&
        currentAnswers !== undefined ? (
        <form className='form-style'
          style={{
            marginTop: '4rem',
            maxWidth: '50rem',
            padding: '1rem'
          }}>
          {currentAnswers.datafill &&
            currentAnswers.datafill.questions.map((question: any) => (
              <div style={{ position: 'absolute', top: '0px', right: '24px' }} className='w-full flex justify-end help-button'>
                {question.type === 'help' ? (
                  <a
                    className='product-name btn-section mt-4'
                    style={{ width: 'max-content' }}
                    href={question.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={Lifering} alt="Lifering" />
                    <p style={{ margin: '0', fontSize: '14px', textDecoration: 'none', color: 'black' }}>{question.question}</p>
                  </a>
                ) : null}
              </div>
            ))}
          <h1 className='form-title'>{currentAnswers.datafill?.formName}</h1>
          <div className='flex flex-col gap-6' >
            {currentAnswers.datafill.questions.map((question: any, index: number) => (
              question.dependent === false &&
              <div className='flex flex-col gap-6'>
                {/* Type Input */}
                {question.type === 'input' && question.dependent === false ? (
                  <div>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>
                      </div>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          {alert === true && alertIndex === index && (
                            <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                              *No se admiten caracteres especiales
                            </p>
                          )}
                          <input
                            type='text'
                            title='name'
                            maxLength={40}
                            defaultValue={question.answer}
                            placeholder='Escriba aquí'
                            className='input-styles-quetion'
                            value={question.answer} // Usar value en lugar de manipular directamente el valor
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const inputValue = e.target.value
                              const cleanInput = inputValue
                                .normalize('NFD') // Normalize the string
                                .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .toUpperCase() // Convert to uppercase
                                .replace(/[^A-Z0-9\s/]/g, '')
                              question.answer = cleanInput
                              if (/[^A-Z0-9\s/]/.test(cleanInput)) {
                                setAlert(true)
                                setAlertIndex(index)
                              } else {
                                setAlert(false)
                                setAlertIndex(0)
                              }
                              setReadValidator(readValidator + 1)
                              validateDataForSave2(currentAnswers)
                            }} />
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}>
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Type Numeric Input */}
                {question.type === 'numericInput' && question.dependent === false ? (
                  <div>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>
                      </div>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          {alert === true && alertIndex === index && (
                            <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                              *Solo se admiten caracteres numericos
                            </p>
                          )}
                          <input
                            type="text"
                            title="name"
                            defaultValue={question.answer}
                            maxLength={28}
                            placeholder="Escriba aquí"
                            className="input-styles-quetion"
                            value={question.answer} // Usar value en lugar de manipular directamente el valor
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const inputValue = e.target.value
                              const cleanInput = inputValue.replace(/[^0-9]/g, '')
                              question.answer = cleanInput
                              if (/[^0-9]/.test(cleanInput)) {
                                setAlert(true)
                                setAlertIndex(index)
                              } else {
                                setAlert(false)
                                setAlertIndex(0)
                              }
                              setReadValidator(readValidator + 1)
                              validateDataForSave2(currentAnswers)
                            }}
                          />
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '3rem',
                                fontSize: '10px'
                              }}
                            >
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Type Text */}
                {question.type === 'text' && question.dependent === false ? (
                  <div className='flex flex-col gap-6'>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>
                      </div>
                      <div className='text-format w-full'>
                        <div className='flex w-full' style={{ height: '6rem' }}>
                          <div className='flex flex-col w-full'>
                            {alert === true && alertIndex === index && (
                              <p style={{ fontSize: '0.7rem', color: '#bf3c3c', marginLeft: '2.5rem' }}>
                                * No se admiten caracteres especiales
                              </p>)}
                            <textarea
                              title='name'
                              maxLength={200}
                              className='text-styles'
                              defaultValue={question.answer}
                              onChange={(e) => {
                                const inputValue = e.target.value
                                const cleanInput = inputValue
                                  .normalize('NFD') // Normalize the string
                                  .replace(/[\u0300-\u036f]/g, '') // Remove accents
                                  .toUpperCase() // Convert to uppercase
                                  .replace(/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/g, '')
                                e.target.value = cleanInput
                                question.answer = cleanInput
                                setReadValidator(readValidator + 1)
                                if (/[^A-Z0-9\sáéíóúÁÉÍÓÚ]/.test(cleanInput)) {
                                  setAlert(true)
                                  setAlertIndex(index)
                                } else {
                                  setAlert(false)
                                  setAlertIndex(0)
                                }
                                validateDataForSave2(currentAnswers)
                              }} />
                          </div>
                        </div>
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                        <span style={{ fontSize: '10px' }}>Max 100 carácteres</span>
                        {question.validate === true && question.answer === '' && readValidator > 0 ? (
                          <span
                            style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginBottom: '3rem',
                              marginLeft: '0.3rem',
                              fontSize: '10px'
                            }}>
                            {question.validateAnswer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>) : null}

                {/* Type Option */}
                {question.type === 'option' && question.dependent === false ? (
                  <div className='flex flex-col gap-6' >
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>
                      </div>
                      <div className='flex '>
                        <div className='flex flex-col'>
                          <select
                            value={question.answer}
                            title='option'
                            className='option-styles'
                            onChange={(e: any) => {
                              question.answer = e.target.value
                              shouldShowConditional === true
                                ? setShouldShowConditional(false)
                                : setShouldShowConditional(true)
                              debounceTimer.current = setTimeout(() => {
                                validateDataForSave2(currentAnswers)
                              }, 500)
                            }}>
                            <option value=''>Seleccione</option>
                            {question.option.map((option: string) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}
                            >
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return conditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                    {currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer !== cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return hideConditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                  </div>
                ) : null}

                {/* Type choice */}
                {question.type === 'choice' && question.dependent === false ? (
                  <div>
                    <div className='flex justify-between'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>*</p>
                        ) : (
                          <div />
                        )}
                        <p className='question-styles'>{question.question}</p>

                      </div>
                      <div className='flex'>
                        <div className='choice-option'>
                          {question.choices.map((choice: string) => (
                            <Checkbox
                              value={choice}
                              onChange={(e: CheckboxChangeEvent) => {
                                if (e.target.checked === true) {
                                  currentAnswers.datafill.questions[index].multipleAnswer.push(
                                    e.target.value
                                  )
                                } else {
                                  const indexToRemove = currentAnswers.datafill.questions[
                                    index
                                  ].multipleAnswer.indexOf(e.target.value)
                                  if (indexToRemove !== -1) {
                                    currentAnswers.datafill.questions[index].multipleAnswer.splice(
                                      indexToRemove,
                                      1
                                    )
                                  }
                                }
                                validateDataForSave2(currentAnswers)
                              }}>
                              {choice}
                            </Checkbox>
                          ))}
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}
                            >
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.data.questions.map((cond: any, i: number) =>
                        question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                          ? conditionalQuestions(cond, i)
                          : null
                      )}
                    {currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer !== cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return hideConditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                  </div>
                ) : null}

                {/* Type Date */}
                {question.type === 'date' && question.dependent === false ? (
                  <div>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>
                      </div>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <Space direction='vertical'>
                            <DatePicker
                              className='new-date-style'
                              format='YYYY-MM-DD'
                              defaultValue={
                                question.answer
                                  ? moment(question.answer, 'YYYY-MM-DD')
                                  : undefined
                              }
                              onChange={(date) => {
                                if (date && date !== null) {
                                  question.answer = date.format('YYYY-MM-DD').toString()
                                  handleDateMustBeCurrent(date, question.mustBeCurrent, index)
                                  handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                                } else {
                                  handleDateMustBeCurrent(null, question.mustBeCurrent, index)
                                  handleDateMustBeNotCurrent(null, question.mustBeNotCurrent, index)
                                }
                                validateDataForSave2(currentAnswers)

                              }}
                            />
                          </Space>
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {errors2 && errors2[index] &&
                            <p style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginLeft: '0.3rem',
                              marginBottom: '0'
                            }}>{errors2[index]}</p>}
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}
                            >
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Type Interval */}
                {question.type === 'interval' && !question.dependent ? (
                  <div>
                    <div className="flex flex-col">
                      <div className="flex gap-1">
                        {question.validate ? (
                          <p className="info-styles" style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className="info-styles" />
                        )}
                        <p className="question-styles">{question.question}</p>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex flex-col gap-2">
                          <Space direction="vertical">
                            <p className="m-0">Desde:</p>
                            <DatePicker
                              format="YYYY-MM-DD"
                              defaultValue={question.multipleAnswer[0]
                                ? moment(question.multipleAnswer[0], 'YYYY-MM-DD')
                                : undefined}
                              onChange={(date) => {
                                if (date && date !== null) {
                                  question.multipleAnswer[0] = date.format('YYYY-MM-DD').toString()
                                  handleStartDateChange(index, date)
                                  handleDateFromMustBeCurrent(date, question.fromMustBeCurrent, index)
                                } else {
                                  handleStartDateChange(index, null)
                                  handleDateFromMustBeCurrent(null, question.fromMustBeCurrent, index)
                                }

                                validateDataForSave2(currentAnswers)
                              }}
                            />
                          </Space>
                          <Space direction="vertical">
                            <p className="m-0">Hasta:</p>
                            <DatePicker
                              format="YYYY-MM-DD"
                              defaultValue={question.multipleAnswer[0]
                                ? moment(question.multipleAnswer[0], 'YYYY-MM-DD')
                                : undefined}
                              onChange={(date) => {
                                if (date && date !== null) {
                                  question.multipleAnswer[1] = date.format('YYYY-MM-DD').toString()
                                  handleEndDateChange(index, date)
                                  handleDateMustBeCurrent(date, question.mustBeCurrent, index)
                                  handleDateMustBeNotCurrent(date, question.mustBeNotCurrent, index)
                                } else {
                                  handleEndDateChange(index, null)
                                  handleDateMustBeCurrent(null, question.mustBeCurrent, index)
                                  handleDateMustBeNotCurrent(null, question.mustBeNotCurrent, index)
                                }
                                validateDataForSave2(currentAnswers)
                              }}
                            />
                          </Space>
                          <div
                            className="popup-form-style"
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {errors2 && errors2[index] &&
                            <p style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginLeft: '0.3rem',
                              marginBottom: '0'
                            }}>{errors2[index]}</p>}
                          {errors && errors[index] &&
                            <p style={{
                              color: '#bf3c3c',
                              fontWeight: 'bolder',
                              marginLeft: '0.3rem',
                              marginBottom: '0'
                            }}>{errors[index]}</p>}
                          {question.validate && !question.multipleAnswer && readValidator > 0 && (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}>
                              {question.validateAnswer}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Type Video */}
                {question.type === 'video' && question.dependent === false ? (
                  <div className='flex flex-col'>
                    <p className='question-styles'>{question.question}</p>
                    <iframe className='iframe-style' src={question.video} title={question.question} />
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  </div>
                ) : null}

                {/* Type Image */}
                {question.type === 'image' && question.dependent === false ? (
                  <div className='flex flex-col'>
                    <p className='question-styles'>{question.question}</p>
                    <img className='iframe-style' src={question.image} alt={question.question} />
                    <div
                      className='popup-form-style'
                      dangerouslySetInnerHTML={{ __html: question.info }}
                    />
                  </div>
                ) : null}

                {/* Type Paragraph */}
                {question.type === 'paragraph' && question.dependent === false ? (
                  <div className='flex justify-between'>
                    <p className='paragraph-styles' style={{ color: '#ababab', fontSize: '14px' }}>
                      {question.question}
                    </p>
                  </div>
                ) : null}

                {/* Type description */}
                {question.type === 'description' && question.dependent === false ? (
                  <div className='flex justify-between'>
                    <p className='description-styles' style={{ color: '#ababab', fontSize: '14px' }}>
                      {question.question}
                    </p>
                  </div>
                ) : null}

                {/* Type Link */}
                {question.type === 'link' && question.dependent === false ? (
                  <div className='flex justify-between'>
                    <a
                      style={{ fontSize: '14px' }}
                      className='paragraph-styles'
                      href={question.link}>
                      {question.question}
                    </a>
                  </div>
                ) : null}

                {/* Type country */}
                {question.type === 'country' && question.dependent === false ? (
                  <div className='flex flex-col gap-6'>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>

                      </div>
                      <div className='flex '>
                        <div className='flex flex-col'>
                          <select
                            title='option'
                            className='option-styles'
                            value={question.answer ?? ''}
                            onChange={(e: any) => {
                              question.answer = e.target.value
                              setSpawnQuestion(spawnQuestion + 1)
                              debounceTimer.current = setTimeout(() => {
                                validateDataForSave2(currentAnswers)
                              }, 500)
                            }}>
                            <CountrySelect />
                          </select>
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}
                            >
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return conditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                    {currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer !== cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return hideConditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                  </div>
                ) : null}

                {/*Type province */}
                {question.type === 'province' && question.dependent === false ? (
                  <div className='flex flex-col gap-6'>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>

                      </div>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <select
                            value={question.answer ?? ''}
                            title='option'
                            className='option-styles '
                            onChange={(e: any) => {
                              question.answer = e.target.value
                              setSpawnQuestion(spawnQuestion + 1)
                            }}>
                            <option value=''>Seleccione</option>
                            <option value='AB'>Alberta</option>
                            <option value='BC'>British Columbia</option>
                            <option value='MB'>Manitoba</option>
                            <option value='NB'>New Brunswick</option>
                            <option value='NL'>Newfoundland and Labrador</option>
                            <option value='NS'>Nova Scotia</option>
                            <option value='NU'>Nunavut</option>
                            <option value='ON'>Ontario</option>
                            <option value='QC'>Quebec</option>
                            <option value='SK'>Saskatchewan</option>
                            <option value='YT'>Yukon</option>
                            <option value='PE'>Prince Edward Island</option>
                            <option value='NT'>Northwest Territories</option>
                          </select>
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}>
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return conditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                    {currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer !== cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return hideConditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                  </div>
                ) : null}

                {/* Type city */}
                {question.type === 'city' && question.dependent === false ? (
                  <div>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>

                      </div>
                      <div className='flex '>
                        <div className='flex flex-col'>
                          <select
                            title='option'
                            className='option-styles'
                            value={question.answer ?? ''}
                            onChange={(e: any) => {
                              question.answer = e.target.value
                              setSpawnQuestion(spawnQuestion + 1)
                              debounceTimer.current = setTimeout(() => {
                                validateDataForSave2(currentAnswers)
                              }, 500)
                            }}>
                            <CitySelect province={question.conditional} />
                          </select>
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}
                            >
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {question.answer !== '' &&
                      currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer === cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return conditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                    {currentAnswers &&
                      currentAnswers !== undefined &&
                      currentAnswers.datafill.questions.map((cond: any, i: number) => {
                        if (
                          question.answer !== cond.conditional &&
                          question.question === cond.conditionalQuestion &&
                          question.index === cond.conditionId
                        ) {
                          return hideConditionalQuestions(cond, i)
                        } else {
                          return null
                        }
                      }
                      )}
                  </div>
                ) : null}

                {/*Type language */}
                {question.type === 'language' && question.dependent === false ? (
                  <div>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>

                      </div>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <select
                            title='option'
                            className='option-styles '
                            value={question.answer ?? ''}
                            onChange={(e: any) => {
                              question.answer = e.target.value
                              setSpawnQuestion(spawnQuestion + 1)
                            }}>
                            <option value=''>Seleccione</option>
                            <option value='English'>English</option>
                            <option value='Mandarin'>Mandarin Chinese</option>
                            <option value='Hindi'>Hindi</option>
                            <option value='Spanish'>Spanish</option>
                            <option value='French'>French</option>
                            <option value='Arabic'>Standard Arabic</option>
                            <option value='Bengali'>Bengali</option>
                            <option value='Russian'>Russian</option>
                            <option value='Portuguese'>Portuguese</option>
                            <option value='Urdu'>Urdu</option>
                            <option value='Indonesian'>Indonesian</option>
                            <option value='German'>German</option>
                            <option value='Japanese'>Japanese</option>
                            <option value='Nigerian'>Nigerian Pidgin</option>
                            <option value='Marathi'>Marathi</option>
                            <option value='Telugu'>Telugu</option>
                            <option value='Turkish'>Turkish</option>
                            <option value='Tamil'>Tamil</option>
                            <option value='Yue'>Yue Chinese</option>
                            <option value='Vietnamese'>Vietnamese</option>
                            <option value='Tagalog'>Tagalog</option>
                            <option value='Wu'>Wu Chinese</option>
                            <option value='Korean'>Korean</option>
                            <option value='Iranian'>Iranian Persian (Farsi)</option>
                            <option value='Hausa'>Hausa</option>
                            <option value='Egyptian'>Egyptian Spoken Arabic</option>
                            <option value='Swahili'>Swahili</option>
                            <option value='Javanese'>Javanese</option>
                            <option value='Italian'>Italian</option>
                            <option value='Punjabi'>Western Punjabi</option>
                            <option value='Kannada'>Kannada</option>
                            <option value='Gujarati'>Gujarati</option>
                            <option value='Thai'>Thai</option>
                          </select>
                        </div>
                        <div
                          className='popup-form-style'
                          dangerouslySetInnerHTML={{ __html: question.info }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Type postalcode */}
                {question.type === 'postalcode' && question.dependent === false ? (
                  <div>
                    <div className='flex flex-col'>
                      <div className='flex gap-1'>
                        {question.validate === true ? (
                          <p className='info-styles' style={{ fontSize: '1rem', marginRight: '-5px', marginLeft: '3px' }}>
                            *
                          </p>
                        ) : (
                          <div className='info-styles' />
                        )}
                        <p className='question-styles'>{question.question}</p>
                      </div>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <PostalCodeSearch
                            onPostalCodeSelect={(selectedPostalCode: string) => {
                              question.answer = selectedPostalCode;
                            }}
                            initialPostalCode={question.answer}
                          />
                          <div
                            className='popup-form-style'
                            dangerouslySetInnerHTML={{ __html: question.info }}
                          />
                          {question.validate === true &&
                            question.answer === '' &&
                            readValidator > 0 ? (
                            <span
                              style={{
                                color: '#bf3c3c',
                                fontWeight: 'bolder',
                                marginBottom: '3rem',
                                marginLeft: '0.3rem',
                                fontSize: '10px'
                              }}>
                              {question.validateAnswer}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

              </div>
            ))}
          </div>
          <div className='flex gap-1 justify-between'>
            <button
              style={{ marginTop: '4rem', width: '13rem', border: '2px solid black' }}
              type='button'
              className='product-name btn-section'
              onClick={() => {
                const status = 0
                setReadValidator(readValidator + 1)
                setShowError(false)
                saveForms(currentAnswers, status)
                setBarUpdate(barUpdate + 1)
                setActivePorcentageBar(false)
                setActiveRefech(true)
                /* setDraft(false) */
              }}>
              <p style={{ fontSize: '0.8rem', margin: '0' }}>GUARDAR BORRADOR</p>
            </button>
            <button
              type='button'
              style={{
                marginTop: '4rem',
                width: '13rem',
                border: '1px solid white',
                boxShadow: 'rgba(5, 13, 29, 0.18) 0px 10px 15px 0px',
                backgroundColor: isValid2 ? 'rgb(44, 214, 115)' : 'rgb(200, 200, 200)', // Cambia el color de fondo
                display: 'flex',
                gap: '5px',
                alignItems: 'center'
              }}
              className='button-volver'
              onClick={() => {
                if (isValid2) {
                  validateDataForSave(currentAnswers)
                  SaveQuestionDocs(currentAnswers)
                }
              }}>
              <p style={{ fontSize: '0.8rem', margin: '0', color: 'white' }}>ENVIAR Y SIGUIENTE</p>
              <img
                src="/media/svg/menu-icons/telegram-original.svg"
                alt='Enviar'
                style={{
                  width: '25px',
                  height: '25px',
                }} />
            </button>
          </div>
          {readValidator > 0 && showError === true ? <p></p> : null}
        </form>
      ) : (
        <> <img src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" /></>
      )}
    </>
  )
}
export { DisplayFormDarft }
