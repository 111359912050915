import './style.css'
import {useEffect, useState} from 'react'
import CalendarComponent from './CalendarComponent'
import ListStaffsCalendly from './CalendarDetails/ListStaffsCalendly'
import ModalIframe from './ModalIframe/ModalIframe'

const Calendly = () => {
  const [showIframe, setShowIframe] = useState(false)

  const [iframeString, setIframeString] = useState<string | null>(null)

  const [selectedDate, setSelectedDate] = useState<Date>(new Date())

  const handleChange = (date: Date | null) => {
    console.log('🚀 ~ file: index.tsx:16 ~ handleChange ~ date', date)
    if (date != null) setSelectedDate(date)
  }

  useEffect(() => {
    if (iframeString) {
      setShowIframe(true)
    } else {
      setShowIframe(false)
    }
  }, [iframeString])

  return (
    <div>
      <div
        className={
          'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 overflow-hidden lg  gap-2 grid-flow-row main-calendar-calendly  '
        }
      >
        <div className='box row-span-2  consultant-calendar'>
          <CalendarComponent setSelectedDate={handleChange} />
        </div>

        <div className='box row-span-2 consultant-calendar-cards'>
          <ListStaffsCalendly date={selectedDate} setUrl={setIframeString} />
          {/* <CalendlyUsers date={selectedDate} setUrl={setIframe} /> */}
        </div>
      </div>
      {showIframe && iframeString ? (
        <ModalIframe
          iframe={iframeString}
          closeModal={() => {
            setShowIframe(false)
          }}
          isOpen={showIframe}
        />
      ) : null}
    </div>
  )
}

export default Calendly
