/* eslint-disable @typescript-eslint/no-unused-vars */
import {type FC, useContext, useEffect, useState} from 'react'
import AsideMenuAdmin from '../../core/layout/components/aside/AsideMenuAdmin'
import AsideMenuMainUser from '../../core/layout/components/aside/AsideMenuMainUser'
import {RoleEnum} from '../../auth/models/User/UserModel'
import {RootStoreContext} from '../../stores/rootStore'
import {useIntl} from 'react-intl'

interface IProps {
  setIsOpenMenu?: (isOpenProfile: boolean) => void
  isOpenMenu?: boolean
}

const Menu: FC<IProps> = ({setIsOpenMenu, isOpenMenu}) => {
  const [menu, setMenu] = useState(true)

  const intl = useIntl()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const rootStore = useContext(RootStoreContext)
  const {user, logout} = rootStore.authStore

  useEffect(() => {
    if (user != null) {
      if (user.role?.id === RoleEnum.admin) {
        setMenu(false)
      }
    }
     
  }, [user])
  return (
    <>
      <div className='w-0 lg:w-1/6 overflow '>
        <div className=' bg-white  top-0 left-0 h-screen block mb-10'>
          {/* <div className=' block float-left mb-4 ml-2 mt-2'>
            <img
              src={'/media/newdesign/07-logo-horizontal-color.svg'}
              className='block lg:w-3/4 m-auto float-left w-0 '
              alt=''
            />
          </div> */}
          <div className=''>
            <ul className='mt-8'>
              {menu ? <AsideMenuMainUser /> : <AsideMenuAdmin />}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export {Menu}
