/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Alert, Button, H3, type IOption, Input } from '../../../../components'
import React, { useContext, useEffect } from 'react'
import { dateConverterTimeZone } from '../../../../core/utils/dateConverterTimeZone'
import CreateUser from '../../../../app/pages/user/create/CreateUser'
import { DatePicker } from 'antd'
import { type ICreacteAppointment } from '../../../../app/modules/apps/appointments/core/_models'
import ProductSelect from '../../../../app/pages/Cart/Product/ProductSelect'
import { RootStoreContext } from '../../../../stores/rootStore'
import StaffSelectOption from '../../../../app/components/common/StaffSelect/StaffSelectOption'
import Swal from 'sweetalert2'
import TimeZoneSelect from '../../../../core/components/TimeZone/TimeZoneSelect'
import { type UserModel } from '../../../../auth/models/User/UserModel'
import { createAppointment } from '../../../../app/modules/apps/appointments/core/_requests'
import { generateCalendlyProps } from '../../../../app/modules/apps/appointments/calendlymodel/generateCalendlyProps'
import moment from 'moment'
import { useIntl } from 'react-intl'

const FormCreate = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore

  const [selectedUser, setSelectedUser] = React.useState({ id: '', value: '' })
  const [selectedStaff, setSelectedStaff] = React.useState({ id: '', value: '' })
  const [selectedProduct, setSelectedProduct] = React.useState({ id: '', value: '' })
  const [dateTime, setDateTime] = React.useState('')
  const [dateChoose, setDateChoose] = React.useState<any>()
  const [url, setUrl] = React.useState('')
  const [zone, setZone] = React.useState('UTC')
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const handleDateChange = (date: any) => {
    setDateChoose(date)
    const dateObject = date.toDate()
    const convertedDate = dateConverterTimeZone(dateObject, zone, 'utc') || '' // Fix para deploy en vercel proyecto Fenix
    setDateTime(convertedDate)
  }

  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    }
  }, [user])

  useEffect(() => {
     
    dateChoose ? handleDateChange(dateChoose) : null
  }, [zone])

  const validateData = () => {
    if (!selectedUser.id) {
      setError('Please select or save a user')
      return false
    }

    if (selectedStaff.id === '') {
      alert('Please select a staff')
      return false
    }
    if (selectedProduct.id === '') {
      alert('Please select a product')
      return false
    }
    if (!dateTime) {
      alert('Please select a date')
      return false
    }
    if (url === '') {
      alert('Please enter a url')
      return false
    }
    save()
    return true
  }

  const save = async () => {
    const appointment: ICreacteAppointment = {
      staff: {
        id: selectedStaff.id,
      },
      product: {
        id: selectedProduct.id,
      },
      props: JSON.stringify(generateCalendlyProps(url, dateTime, selectedUser.value)),
      user: {
        id: selectedUser.id,
      },
    }

    const result = await createAppointment(appointment)

    if (result) {
      Swal.fire({
        title: 'Success',
        text: 'Appointment created successfully',
        icon: 'success',
        confirmButtonText: 'OK',
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Appointment created error',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const alert = (message: string) => {
    setError(message)
  }

  return (
    <>
      <div className='p-9 bg-white flex flex-col items-start ml-4 w-full'>
        <CreateUser
          setSelectedUser={(user: UserModel) => {
            setSelectedUser({ id: user.id, value: user.firstName + '  ' + user.lastName })
          }}/>
        <div className='row mb-6'>
          <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>Staff</H3>
          <div className='col-lg-8 fv-row'>
            <StaffSelectOption selected={selectedStaff} setSelected={setSelectedStaff} />
          </div>
        </div>
        <div className='row mb-6'>
          <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
            {intl.formatMessage({ id: 'COMMON.DATETIME' })}
          </H3>
          <div className='col-lg-8 fv-row gap-4 flex flex-col'>
            <DatePicker
              className='custom-input'
              format='YYYY-MM-DD HH:mm:ss'
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onChange={handleDateChange}
            />
            <TimeZoneSelect
              selected={{ id: zone, value: zone }}
              setSelected={(option: IOption) => {
                setZone(option.value)
              }}
            />
          </div>
        </div>
        <div className='row mb-6'>
          <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
            {intl.formatMessage({ id: 'APPOINTMENTS.SERVICE' })}
          </H3>

          <div className='col-lg-8 fv-row'>
            <ProductSelect selected={selectedProduct} setSelected={setSelectedProduct} />
          </div>
        </div>

        <div className='row mb-6'>
          <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
            {intl.formatMessage({ id: 'COMMON.URLMEETING' })}
          </H3>
          <div className='col-lg-8 fv-row'>
            <Input
              className='custom-input'
              type='text'
              placeholder={intl.formatMessage({ id: 'COMMON.URLMEETING' })}
              value={url}
              onChange={(e: any) => {
                setUrl(e.target.value)
              }}
            />

            <Alert variant='danger'></Alert>
          </div>
        </div>

        <Alert type='error'>{error}</Alert>
        <Button
          type='button'
          onClick={() => {
            if (validateData()) {
              // setLoading(true)
            }
          }}
          // disabled={loading}
          style={{ width: '8rem', alignSelf: 'end', marginRight: '2rem' }}
        >
          {!loading && intl.formatMessage({ id: 'CORE.BTNSAVE' })}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'PROFILE.PLEASEWAIT' })}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </div>
    </>
  )
}

export default FormCreate
