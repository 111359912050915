/* eslint-disable @typescript-eslint/no-unused-vars */
import {type FC, useContext, useState} from 'react'
import {Container} from '../../../../components/Container/index'
import {RootStoreContext} from '../../../../stores/rootStore'
import {useIntl} from 'react-intl'
import {exportToExcel} from 'utils/export'
import {getLogs} from 'app/modules/apps/cart/core/program/_request'
import {ExcelLogger} from 'app/modules/apps/cart/core/program/_models'
import {useQuery} from 'react-query'

const DashboardLogger: FC = () => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const [data, setData] = useState([])
  useQuery(['logs'], getLogs, {
    onSuccess: (response) => {
      response = JSON.parse(JSON.stringify(response))
      setData(response)
    },
  })

  const handleExportClick = async () => {
    const titles = ['Log id', 'Author', 'Date', 'Value', 'Action', 'Table name', 'Author email']
    exportToExcel(await getExcelData(data), 'Logger-', titles)
  }

  const getExcelData = (data: any) => {
    const excelData: ExcelLogger[] = []
    data?.forEach(function (data: any) {
      excelData.push({
        id: data.id,
        author: data.author,
        fecha: data.fecha,
        new_value: data.new_value,
        accion: data.accion,
        table_name: data.table_name,
        author_email: data.author_email,
      })
    })
    return excelData
  }
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.STORE'})}>
        <div className='flex gap-4 justify-center flex-wrap'>
          <button onClick={() => handleExportClick()}> Descargar Logs</button>
        </div>
      </Container>
    </>
  )
}

export {DashboardLogger}
