/* eslint-disable @typescript-eslint/no-unused-vars */

import {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from 'stores/rootStore'
import ShoppingProducts from './ShoppingProducts'
import ShoppingTotals from './ShoppingTotals'
import ValidationQuantity from './ValidationQuantity'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'

interface IProps {
  setCurrent: React.Dispatch<React.SetStateAction<number>>
}

const ShoppingCart: React.FC<IProps> = ({setCurrent}) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {error, files, formsProducst} = rootStore.cartStore
  const [allForms, setAllForms] = useState(false)
  const [allFiles, setFiles] = useState(false)
  const [next, setNext] = useState(false)

  useEffect(() => {
    let allFormFill = true
    formsProducst.forEach((item) => {
      if (item.data === '') {
        allFormFill = false
      }
    })
    setAllForms(allFormFill)
  }, [formsProducst])

  useEffect(() => {
    if (files == null || files.length === 0) {
      setFiles(false)
    } else {
      setFiles(true)
    }
  }, [files])

  useEffect(() => {
    if (allForms && allFiles) {
      setNext(true)
    }
  }, [allForms, allFiles])

  const validateData = () => {
    setCurrent(1)
  }

  return (
    <>
      <br />
      <div className='text-center mb-20 first-page-wrapper' >
        <ShoppingProducts />
       
        <ValidationQuantity next={next} setCurrent={setCurrent} />
      </div>
    </>
  )
}

export default observer(ShoppingCart)
