import styled from 'styled-components'

const CloseButton = styled.button`
  width: 2rem;
  height: 2rem;
  padding: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  border: none;

  background-color: transparent;

  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #333;
    display: block;
    transform: rotate(45deg) translateX(0px);
    position: absolute;
    left: 50%;
    top: 0;
  }
  &:after {
    transform: rotate(-45deg) translateX(0px);
  }
  &:hover {
    &:before,
    &:after {
      width: 2px;
    }
  }
`
export {CloseButton}
