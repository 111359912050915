import {Container} from '../../../../components/Container/index'
import ListApiCalls from './List/ListApiCalls'
import React from 'react'
import {useIntl} from 'react-intl'

const ApiCalls = () => {
  const intl = useIntl()
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.APICALLS'})}>
        <ListApiCalls />
      </Container>
    </>
  )
}

export default ApiCalls
