/* eslint-disable react-hooks/exhaustive-deps */

import {Calendar, type Day} from 'react-modern-calendar-datepicker'
import React, {useEffect, useState} from 'react'
import {getAllMontCalendly} from '../../../../../../app/modules/apps/calendly/core/_requests'
import moment from 'moment'
import {useQuery} from 'react-query'

const defaultValue = {
  year: 2022,
  month: 12,
  day: 18,
}

const renderDays = [
  // here we add some CSS classes
  {year: 2022, month: 12, day: 8, className: 'purpleDay'},
]

interface IRederDay {
  year: number
  month: number
  day: number
  className: string
}

interface IProps {
  setSelectedDate: (date: Date | null) => void
}

const CalendarComponent: React.FC<IProps> = ({setSelectedDate}) => {
  const [renderedDays, setRenderedDays] = useState<IRederDay[]>(renderDays)
  const [mont, setMont] = useState(defaultValue.month)
  const [selectedDay, setSelectedDay] = useState({
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date(),
  })

  const {data, refetch} = useQuery(
    'getAllMontCalendly',
    async () =>
      await getAllMontCalendly(
        moment(`${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`).format('YYYY-MM-DD'),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
  )

  useEffect(() => {
    if (data != null && data.length > 0) {
      const days = data.map((item: any) => {
        const days = moment(item.day)

        return {
          year: days.year(),
          month: days.month() + 1,
          day: days.date(),
          className: 'purpleDay',
        }
      })
      setRenderedDays(days)
    }
  }, [data])

  useEffect(() => {
    // if mont is different to selectedDay.month
    if (mont !== selectedDay.month) {
      setMont(selectedDay.month)
      refetch()
    }
  }, [selectedDay])

  const onSelect = (date: Day) => {
    setSelectedDay(date as any)
    const dateMoment = moment(`${date.year}-${date.month}-${date.day}`)
    // convert date to Date type
    const dateSelected: Date = new Date(dateMoment.format('YYYY-MM-DD'))

    setSelectedDate(dateSelected)
  }

  try {
    return (
      <Calendar
        value={selectedDay}
        onChange={onSelect}
        colorPrimary='#9c88ff' // added this
        calendarClassName='custom-calendar' // and this
        calendarTodayClassName='custom-today-day' // also this
        customDaysClassName={renderedDays}
      />
    )
  } catch (error) {
    console.log(error)

    return <></>
  }
}

export default CalendarComponent
