/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useContext, useEffect} from 'react'
import {type CheckBoxOptions} from '../types'
import ImmigrationDetails from '../ImmigrationDetails'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {observer} from 'mobx-react'

const ChooseImmigrationOption = () => {
  const rootStore = useContext(RootStoreContext)
  const {
    immigrationServices,
    immigrationOption,
    getImmigrationOptions,
    setImmigrationOptionsCheked,
  } = rootStore.composeTemplateStore

  useEffect(() => {
    getImmigrationOptions()
  }, [immigrationServices])

  const handleChange = (checkBoxOptions: CheckBoxOptions, cheked: boolean, type: string) => {
    setImmigrationOptionsCheked(checkBoxOptions, cheked)
  }

  useEffect(() => {
    // comment to deactivate eslint
  }, [immigrationOption])

  return (
    <>
      {immigrationOption &&
        immigrationServices.map((item) => {
          return (
            <div key={item.id}>
              <ImmigrationDetails
                immigrationService={item}
                options={immigrationOption}
                type='immigrationOption'
                handleChange={handleChange}
              />
            </div>
          )
        })}
    </>
  )
}

export default observer(ChooseImmigrationOption)
