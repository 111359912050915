/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react'
import {ListFormsClient} from '../formsManagerClient.tsx/listFormsClient'
import RandomDocumentsClients from '../supportDocumentsClient/RandomDocumentsClients'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {ListFormsClientAuto} from '../formsManagerClient.tsx/listFormClientAuto'
import {ContractView} from '../formsManagerClient.tsx/contractView'
import {useQuery} from 'react-query'
import {
  getFromBackEndContracts,
  saveContract,
} from '../../../../../app/modules/apps/contract/_requests'
import {SurveyImportantInfo} from '../formsManagerClient.tsx/surveyImportantInfo'
import {Tabs} from 'antd'

// import {SupportDocumentClient} from '../supportDocumentsClient/SupportDocumentClient'

interface IProps {
  handleClose: (key: string) => void
}
const {TabPane} = Tabs
/* */
const ShowForms: React.FC<IProps> = ({handleClose}) => {
  const intl = useIntl()
  const [tabActive, setTabActive] = useState<string>('form')
  const [activateRestOfTheTab, setactivateRestOfTheTabs] = useState<boolean>(false)
  const [visaType, setVisaType] = useState<string>('')
  const tabChangedHandler = (key: any) => {
    if (key === 'form') {
      setTabActive('form')
    } else if (key === 'importantInfo') {
      setTabActive('importantInfo')
    } else if (key === 'listFormAuto') {
      setTabActive('listFormAuto')
    } else if (key === 'listForm') {
      setTabActive('listForm')
    } else if (key === 'document') {
      setTabActive('document')
    } else if (key === 'randimdocument') {
      setTabActive('randimdocument')
    }
  }

  const {
    data: dataResult,
    isSuccess
  } = useQuery('contracts', async () => await getFromBackEndContracts('limit=50&page=1&&name='))

  useEffect(() => {
    // comment to deactivate eslint
  }, [activateRestOfTheTab])
  const processStarted = false
  if (isSuccess) {
    // processStarted = dataResult.find((contract)=>contract.processStarted===true && contract.visible===true)
  }
  if (isSuccess && activateRestOfTheTab) {
    const payload = dataResult.map(async function (data) {
      const object = {id: data.id, visible: data.visible, processStarted: true}
      if (data.visible) {
        /* await saveContract(object) */
      }
    })
  }
  return (
    <>
      <Tabs
        activeKey={tabActive}
        // id='controlled-tab-example'
        // className=''
        onTabClick={tabChangedHandler}
      >
        <TabPane key='form' tab={intl.formatMessage({id: 'CONTRACTS.MYCONTRACTS'})}>
          <ContractView
            dataResult={dataResult}
            setTabActive={setTabActive}
            setVisaType={setVisaType}
            visaType={visaType}
            handleClose={handleClose}
          />
        </TabPane>

        {dataResult?.find((contract) => contract.visible) != null ? (
          <TabPane key='importantInfo' tab={intl.formatMessage({id: 'SERVICES.IMPORTANTINFO'})}>
            <SurveyImportantInfo setactivateRestOfTheTabs={setactivateRestOfTheTabs} />
          </TabPane>
        ) : null}

        {dataResult?.find(
          (contract) => (contract.processStarted && contract.visible) || activateRestOfTheTab
        ) != null ? (
          <TabPane key='listFormAuto' tab={intl.formatMessage({id: 'SERVICES.FORMAUTOMATIC'})}>
            <ListFormsClientAuto
              contracts={dataResult}
              visaType={visaType}
              handleClose={handleClose}
            />
          </TabPane>
        ) : (
          <></>
        )}

        {dataResult?.find(
          (contract) => (contract.processStarted && contract.visible) || activateRestOfTheTab
        ) != null ? (
          <TabPane key='listForm' tab={intl.formatMessage({id: 'SERVICES.FORMMANUAL'})}>
            <ListFormsClient handleClose={handleClose} />
          </TabPane>
        ) : (
          <></>
        )}

        {dataResult?.find(
          (contract) => (contract.processStarted && contract.visible) || activateRestOfTheTab
        ) != null ? (
          <TabPane
            key='document'
            tab={intl.formatMessage({id: 'SERVICES.SUPPORTDOCUMENTS'})}
          ></TabPane>
        ) : (
          <></>
        )}

        {dataResult?.find(
          (contract) => (contract.processStarted && contract.visible) || activateRestOfTheTab
        ) != null ? (
          <TabPane key='randimdocument' tab={intl.formatMessage({id: 'SERVICES.RANDOMDOCUMENTS'})}>
            <RandomDocumentsClients callRefetch={false} userId={''} handleClose={handleClose} />
          </TabPane>
        ) : (
          <></>
        )}
      </Tabs>
    </>
  )
}

export default observer(ShowForms)
