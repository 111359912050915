import 'antd/dist/antd.min.css'
import AuthInit from '../auth/AuthInit'
import {ConfigProvider} from 'antd'
import {I18nProvider} from '../core/i18n/i18nProvider'
import {MasterInit} from '../core/layout/MasterInit'
import {Outlet} from 'react-router-dom'
import {Suspense} from 'react'

const App = () => {
  return (
    <ConfigProvider locale={{locale: 'es'}}>
      <Suspense>
        <I18nProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </I18nProvider>
      </Suspense>
    </ConfigProvider>
  )
}

export {App}
