import {DateTime, Settings} from 'luxon'

export const dateConverterTimeZoneString = (date: any, zone: string) => {
  const keepOffset = DateTime.fromISO(date, {zone}).toFormat('yyyy-MM-dd HH:mm:ss a')
  return keepOffset.toString()
}

export const dateConverterTimeZoneDate = (date: Date, zone: string) => {
  const keepOffset = DateTime.fromISO(date.toISOString(), {zone})
  return keepOffset
}

// convert date from zone to other zone
export const dateConverterTimeZone = (date: Date, zone: string, zone2: string) => {
  console.log(
    '🚀 ~ file: dateConverterTimeZone.ts ~ line 15 ~ dateConverterTimeZone ~ ',
    zone,
    zone2
  )
  Settings.defaultZone = zone

  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  // conver year month day  hour   minute  from zone  to zone2
  const keepOffset = DateTime.fromObject({
    year,
    month: month + 1,
    day,
    hour,
    minute,
    second,
  }).setZone(zone)

  const yearNew = keepOffset.year
  const monthNew = keepOffset.month - 1
  const dayNew = keepOffset.day
  const hourNew = keepOffset.hour
  const minuteNew = keepOffset.minute
  const secondNew = keepOffset.second

  // convert keepOffset to zone2
  const keepOffset2 = DateTime.fromObject({
    year: yearNew,
    month: monthNew + 1,
    day: dayNew,
    hour: hourNew,
    minute: minuteNew,
    second: secondNew,
  }).setZone(zone2)

  return keepOffset2.toISO()
}
