import {useContext} from 'react'
import {RootStoreContext} from '../../../stores/rootStore'
import {Container} from '../../../components'
import {NewUserContratactView} from './newUserViewContratationApp/newUserContractView'
import { observer } from 'mobx-react'
interface IProps {
  email: string
}
const WrapperUserContratation: React.FC<IProps> = ({}) => {


  const rootStore = useContext(RootStoreContext)
  const { contracts } = rootStore.authStore
  const {user} = rootStore.authStore
  /* get current date */
  const fecha = new Date()
  const hoy = fecha.getFullYear() + '-' + (fecha.getMonth() + 1) + '-' + fecha.getDate()
  const setMonth = (month: string) => {
    if (month === '01') {
      return '12'
    } else if (month === '02') {
      return '01'
    } else if (month === '03') {
      return '02'
    } else if (month === '04') {
      return '03'
    } else if (month === '05') {
      return '04'
    } else if (month === '06') {
      return '05'
    } else if (month === '07') {
      return '06'
    } else if (month === '08') {
      return '07'
    } else if (month === '09') {
      return '08'
    } else if (month === '10') {
      return '09'
    } else if (month === '11') {
      return '10'
    } else if (month === '12') {
      return '11'
    } else {
      return '01'
    }
  }
  const mm = setMonth(user?.createdAt != null ? user.createdAt.toString().substring(5, 7) : '02')
  const dd = user?.createdAt != null ? user.createdAt.toString().substring(8, 10) : '01'
  const yyyy = user?.createdAt != null ? user.createdAt.toString().substring(0, 4) : '2021'
  const createdAt = yyyy + '-' + mm + '-' + dd

  return (
    <>
      <Container title={''}>
        <NewUserContratactView
          folderItems={contracts}
          id={user?.id ? user.id : ''}
          email={user?.email ? user.email : ''}
          createAt={createdAt}
          hoy={hoy}
          name={user?.firstName ? user.firstName : ''}
        />
      </Container>
    </>
  )
}
export default observer (WrapperUserContratation)
