/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../components'
import React, {type Dispatch, type SetStateAction, useContext, useEffect, useState} from 'react'
import {Space} from 'antd'
import AvatarProfileUsers from '../../../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfileUsers'
import {BsPersonCheckFill} from 'react-icons/bs'
import {RootStoreContext} from '../../../../stores/rootStore'
import {type Staff} from '../../../../app/modules/apps/staff/core/_models'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {getAssigned} from 'app/modules/apps/staff/core/_requests'

interface IProps {
  setStaff: Dispatch<SetStateAction<Staff | null>>
}

const ListStaff: React.FC<IProps> = ({setStaff}) => {
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {staffs, loadStaffs, isLoadingStaff} = rootStore.staffStore
  const [staffTranslations, setStaffTranslations] = useState<any[]>([])

  const fetchData = async () => {
    if (staffs) {
      const assigned: any[] = []
      staffs.map((record, index) => assigned.push(record.id))
      const assignedObject: any[] = await getAssigned(assigned)
      const combinedArray = staffs.map((staffs, index) => {
        const matchingAssignedObject = assignedObject.find((obj) => obj.staff_id === staffs.id)
        return {
          ...staffs,
          translations: matchingAssignedObject ? matchingAssignedObject.total_pages : null,
        }
      })
      setStaffTranslations(combinedArray)
    }
  }

  useEffect(() => {
    const staffsVars: Staff = {
      type: 'translate,translate_admin,translation,translation_admin',
      name: '',
    }
    loadStaffs(staffsVars)
    fetchData()
  }, [])

  const handleAssign = (staff: Staff) => {
    setStaff(staff)
  }

  if (isLoadingStaff) {
    return <div>Loading...</div>
  }

  return (
    <>
      <TableContainer style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{intl.formatMessage({id: 'TRANSLATION.PROFILE'})}</TableCell>
              <TableCell>{intl.formatMessage({id: 'TRANSLATION.NAME'})}</TableCell>
              <TableCell>{intl.formatMessage({id: 'TRANSLATION.EMAIL'})}</TableCell>
              <TableCell>{intl.formatMessage({id: 'TRANSLATION.SELECT'})}</TableCell>
              <TableCell>Traducciones asignadas (páginas)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {staffTranslations &&
              staffTranslations.map((record, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Space>
                      <AvatarProfileUsers
                        image={
                          record.photoId
                            ? `${record.photoPath}/${record.photoId}-avatar-small.jpg`
                            : ''
                        }
                        size={25}
                      />
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space
                      style={{
                        width: '150px',
                      }}
                    >
                      {record.name}
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>{record.email}</Space>
                  </TableCell>
                  <TableCell>
                    <Space>
                      <Button
                        style={{background: 'white', border: '1px solid #4600b7'}}
                        onClick={() => {
                          handleAssign(record)
                        }}
                      >
                        <BsPersonCheckFill />
                      </Button>
                    </Space>
                  </TableCell>
                  <TableCell>
                    <Space>{record.translations}</Space>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default observer(ListStaff)
