import CheckOutResume from './CheckOutResume'
import {useParams} from 'react-router-dom'

const PaymentSucess = () => {
  const {code} = useParams()
console.log("hola")
  return (
    <div>
      {code && (
        <>
          <CheckOutResume code={code} />
        </>
      )}
    </div>
  )
}

export default PaymentSucess
