import {type Forms, type FormsQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const FORMS_URL = `${API_URL}/forms`

const saveForms = async (payload: object): Promise<Forms[]> => {
  return await axios.post(`${FORMS_URL}`,payload).then((response: AxiosResponse) => {
    return response as any
  })
}

const getForms = async (params: string): Promise<Forms[]> => {
  return await axios.get(`${FORMS_URL}?${params}`).then((d: AxiosResponse<FormsQueryResponse>) => {
    return d.data.data as Forms[]
  })
}

const getImms = async (contract:string): Promise<any> => {
  return await axios.get(`${API_URL}/contractsimm/envelope/${contract}`).then((d: AxiosResponse<FormsQueryResponse>) => {
    return d as any
  })
}

const getFormsNew = async (params: string): Promise<any> => {
  return await axios.get(`${FORMS_URL}?${params}`).then((response: AxiosResponse) => {
    return response as any
  })
}

const getFormsActivatedInClient = async (userId: string): Promise<Forms[]> => {
  return await axios
    .get(`${FORMS_URL}/getlist/${userId}`)
    .then((d: AxiosResponse<FormsQueryResponse>) => {
      return d.data as Forms[]
    })
}

const getFormsNotActivatedInClient = async (userId: string): Promise<Forms[]> => {
  return await axios
    .get(`${FORMS_URL}/getlistnot/${userId}`)
    .then((d: AxiosResponse<FormsQueryResponse>) => {
      return d.data as Forms[]
    })
}

const deleteForms = async (formsId: string) => {
  return await axios.delete(`${FORMS_URL}/${formsId}`).then((response) => {
    return response.data as Forms
  })
}
export {
  getFormsNew,
  saveForms,
  getForms,
  getFormsActivatedInClient,
  getFormsNotActivatedInClient,
  deleteForms,
  getImms
}
