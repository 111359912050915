import tw from 'tailwind-styled-components'

const TittleBar = tw.div`
  w-full
  flex
  p-2
  border-b
  border-gray-200
  justify-between
  items-center
`
export {TittleBar}
