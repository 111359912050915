import React from 'react'

interface IProps {
  text: string
  length: number
}

const TextMailSplit: React.FC<IProps> = ({text, length}) => {
  const htmlToTextConvertert = (text: string) => {
    const div = document.createElement('div')
    div.innerHTML = text
    return div.textContent || div.innerText || ''
  }

  return <> {htmlToTextConvertert(text).substring(0, length)}... </>
}

export default TextMailSplit
