/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useState, useEffect } from 'react'
import { type ProfileImmigration } from '../../../../../../app/modules/apps/profile/core/_models'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import 'antd/dist/antd.css'
import './style.css'

import { updateProfileNew } from 'app/modules/apps/profile/core/_requests'
import Swal from 'sweetalert2'
const profileDetailsSchema = Yup.object().shape({
  occupacion: Yup.string().required('Occupation is required'),
  /*   grado_academico: Yup.string().required('Degree is required'), */
  area_de_estudios: Yup.string().required('Study is required'),
  proeficiencia_en_frances: Yup.string().required('French proficiency is required'),
  proeficiencia_en_ingles: Yup.string().required('English proficiency is required'),
  historial_laboral: Yup.string().required('Labour history is required'),
  plicaciones_previas: Yup.string().required('Campo requerido'),

  tipo_aplicacion: Yup.string().test({
    test: function (value) {
      const plicaciones_previas = this.parent.plicaciones_previas;
      if (plicaciones_previas === 'Si') {
        return !!value;
      }
      return true;
    },
    message: 'Tipo de aplicación es requerido cuando hay aplicaciones previas',
  }),

  rechazos_visa: Yup.string().test({
    test: function (value) {
      const plicaciones_previas = this.parent.plicaciones_previas;
      if (plicaciones_previas === 'Si') {
        return !!value;
      }
      return true;
    },
    message: 'Campo requerido cuando hay aplicaciones previas',
  }),
});

interface Props {
  updateProfile: (ProfileImmigration: ProfileImmigration) => void
  ProfileImmigration: ProfileImmigration
  isUserLoading?: boolean
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
  data: any
  userId: string | undefined
  setRefetchActivator: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstInfoObject: Dispatch<SetStateAction<any>>
  firstInfoObject: any
  setCompletionStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const InmiDetailsChop1: React.FC<Props> = ({
  edit = true,
  setTabActive,
  data,
  setRefetchActivator,
  userId,
  setFirstInfoObject,
  firstInfoObject,
  setCompletionStatus,
}) => {
  // Actualizar los valores iniciales del formulario cuando data.profile cambie
  useEffect(() => {
    if (!isEmpty(data.profile)) {
      setFormValues(data.profile)
    }
  }, [data.profile])
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const initialFormValues = {
    occupacion: '',
    grado_academico: '',
    area_de_estudios: '',
    proeficiencia_en_ingles: '',
    proeficiencia_en_frances: '',
    historial_laboral: '',
    plicaciones_previas: '',
    tipo_aplicacion: '',
    rechazos_visa: '',
  }
  const [formValues, setFormValues] = useState<ProfileImmigration | null>(data.profile || initialFormValues)
  const [showConditionalQuestions, setShowConditionalQuestions] = useState(false);
  const [isContinueButtonDisabled, setContinueButtonDisabled] = useState(true)
  const [isFormCompleted, setFormCompleted] = useState(false);

  const checkAllFields = () => {
    const allFieldsFilled = areAllFieldsFilled();
    setContinueButtonDisabled(!allFieldsFilled);
    setFormCompleted(allFieldsFilled);
    setContinueButtonColor(allFieldsFilled ? 'blue' : 'gray');
  };

  const formikSecond = useFormik<ProfileImmigration>({
    initialValues: isEmpty(formValues) ? initialFormValues : formValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async () => {
      setLoading(true)
      setLoading(false)
    },
  })

  useEffect(() => {
    checkAllFields();
  }, [formikSecond.values]);

  const formData = {
    occupacion: formikSecond.values.occupacion,
    grado_academico: formikSecond.values.grado_academico,
    area_de_estudios: formikSecond.values.area_de_estudios,
    proeficiencia_en_ingles: formikSecond.values.proeficiencia_en_ingles,
    proeficiencia_en_frances: formikSecond.values.proeficiencia_en_frances,
    historial_laboral: formikSecond.values.historial_laboral,
    plicaciones_previas: formikSecond.values.plicaciones_previas,
    tipo_aplicacion: formikSecond.values.tipo_aplicacion,
    rechazos_visa: formikSecond.values.rechazos_visa,
  }
  const [progreso, setProgreso] = useState(0.9494949)
  useEffect(() => {
    checkAllFields();
    setCompletionStatus(formikSecond.isValid)
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    formData.occupacion = formikSecond.values.occupacion
    formData.grado_academico = formikSecond.values.grado_academico
    formData.area_de_estudios = formikSecond.values.area_de_estudios
    formData.proeficiencia_en_ingles = formikSecond.values.proeficiencia_en_ingles
    formData.proeficiencia_en_frances = formikSecond.values.proeficiencia_en_frances
    formData.historial_laboral = formikSecond.values.historial_laboral
    formData.plicaciones_previas = formikSecond.values.plicaciones_previas
    formData.tipo_aplicacion = formikSecond.values.tipo_aplicacion
    formData.rechazos_visa = formikSecond.values.rechazos_visa
    try {
      await formikSecond.validateForm()
      await formikSecond.handleSubmit()
      await profileDetailsSchema.validate(formikSecond.values)
      if (firstInfoObject) {
        const objetoCombinado = { ...formikSecond.values, ...firstInfoObject };
        await updateProfileNew({
          profile: objetoCombinado,
          userId: userId,
        })
        setFirstInfoObject(null)
      } else {
        setFirstInfoObject(formikSecond.values)
        await updateProfileNew({
          profile: formikSecond.values,
          userId: userId,
        })
      }
      await updateProfileNew({
        profile: formikSecond.values,
        userId: userId,
      })
      const nuevoProgreso = calcularProgreso(formikSecond.values)
      setProgreso(nuevoProgreso)
      setFormValues(formikSecond.values)
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
      setRefetchActivator(true)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        console.error('Error de validación:', error.errors)
        Swal.fire({
          title: 'Error',
          text: 'No puede continuar porque hay campos vacíos en el formulario',
          icon: 'error',
          confirmButtonText: 'Continuar',
        })
      } else {
        console.error('Error al actualizar el perfil:', error)
      }
    }
  }

  useEffect(() => {
    const nuevoProgreso = calcularProgreso(formikSecond.values)
    setProgreso(nuevoProgreso)
  }, [formikSecond.values])

  const calcularProgreso = (values: any) => {
    const preguntas = [
      'occupacion',
      'grado_academico',
      'area_de_estudios',
      'proeficiencia_en_ingles',
      'proeficiencia_en_frances',
      'plicaciones_previas',
      'historial_laboral',
    ]
    const preguntasRespondidas = preguntas.filter((pregunta) => values[pregunta] !== '').length
    const nuevoProgreso = (preguntasRespondidas / preguntas.length) * 100
    return nuevoProgreso
  }

  const progresoEntero = Math.floor(progreso)
  const [continueButtonColor, setContinueButtonColor] = useState('gray')

  const areAllFieldsFilled = () => {
    const areMainFieldsFilled =
      isEmpty(formikSecond.errors) && Object.values(formikSecond.values).every(Boolean);
    if (!areMainFieldsFilled) {
      return false;
    }

    if (formikSecond.values.plicaciones_previas === 'No') {
      return true;
    }

    const areConditionalFieldsFilled =
      formikSecond.values.tipo_aplicacion !== '' &&
      formikSecond.values.rechazos_visa !== '';
    return areConditionalFieldsFilled;
  };

  const handleConditionalQuestions = () => {
    const isPreviousApplicationYes = formikSecond.values.plicaciones_previas === 'Si';
    setShowConditionalQuestions(isPreviousApplicationYes);
  };

  useEffect(() => {
    const handleConditionalQuestionsAsync = () => {
      handleConditionalQuestions();
    };
    handleConditionalQuestionsAsync();
  }, [formikSecond.values.plicaciones_previas, isFormCompleted]);

  useEffect(() => {
    handleConditionalQuestions();
  }, [formikSecond.values.plicaciones_previas, isFormCompleted]);

  useEffect(() => {
    const nuevoProgreso = calcularProgreso(formikSecond.values);
    setProgreso(nuevoProgreso);
    const allFieldsFilled = areAllFieldsFilled();
    setContinueButtonDisabled(!allFieldsFilled);
    setContinueButtonColor(allFieldsFilled ? 'blue' : 'gray');
  }, [formikSecond.values, formikSecond.values.plicaciones_previas]);

  return (
    <div>
      <div id='kt_account_profile_details'>
        <p
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '1rem',
            textAlign: 'center',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          Complete los campos solicitados para poder comenzar su proceso migratorio
        </p>
        <form
          onSubmit={handleSubmit}
          noValidate
          className='form'
          style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}
        >
          <div className='card-body2 p-9 bg-white'>
            <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
              INFORMACIÓN LABORAL
            </H3>
            <div className='container-form-modal border-black'
            // style={{
            //   display: 'flex',
            //   flexDirection: 'row',
            //   flexWrap: 'wrap',
            //   border: '1px solid black',
            //   borderRadius: '10px',
            //   paddingTop: '1rem',
            //   marginBottom: '1rem'
            // }}
            >
              <div className='container-question'>
                <H3 className='lg-12 pl-3 col-form-label title-forms'>
                  Ocupación
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    className='custom-input'
                    type='text'
                    placeholder='Ocupación'
                    {...formikSecond.getFieldProps('occupacion')}
                    value={formikSecond.values.occupacion}
                  />
                  {formikSecond.touched.occupacion && formikSecond.errors.occupacion && (
                    <Alert variant='danger'>{formikSecond.errors.occupacion}</Alert>
                  )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='lg-12 pl-3 col-form-label title-forms'>
                  Área de estudio
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Input
                    className='custom-input'
                    type='text'
                    placeholder='Área de estudio'
                    {...formikSecond.getFieldProps('area_de_estudios')}
                    value={formikSecond.values.area_de_estudios || ''}
                  />
                  {formikSecond.touched.area_de_estudios &&
                    formikSecond.errors.area_de_estudios && (
                      <Alert variant='danger'>{formikSecond.errors.area_de_estudios}</Alert>
                    )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='lg-12 pl-3 col-form-label title-forms'>
                  Historial laboral más reciente (Max. 200 Caracteres)
                </H3>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    maxLength={200}
                    className='custom-input-form'
                    placeholder='Historial laboral'
                    {...formikSecond.getFieldProps('historial_laboral')}
                    defaultValue={formikSecond.values.historial_laboral}
                  ></textarea>
                  {formikSecond.touched.historial_laboral &&
                    formikSecond.errors.historial_laboral && (
                      <Alert variant='danger'>{formikSecond.errors.historial_laboral}</Alert>
                    )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='lg-12 pl-3 col-form-label title-forms'>
                  Proficiencia en inglés
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    className='custom-input'
                    {...formikSecond.getFieldProps('proeficiencia_en_ingles')}
                    value={formikSecond.values.proeficiencia_en_ingles}
                  >
                    <option value=''>{intl.formatMessage({ id: 'PROFILE.ENGLISHPROF' })}...</option>
                    <option value='Nulo'>Nulo</option>
                    <option value='Basico'>Básico</option>
                    <option value='Intermedio'>Intermedio</option>
                    <option value='Avanzado'>Avanzado</option>
                  </Select>
                  {formikSecond.touched.proeficiencia_en_ingles &&
                    formikSecond.errors.proeficiencia_en_ingles && (
                      <Alert>{formikSecond.errors.proeficiencia_en_ingles}</Alert>
                    )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='lg-12 pl-3 col-form-label title-forms'>
                  Proficiencia en frances
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    className='custom-input'
                    {...formikSecond.getFieldProps('proeficiencia_en_frances')}
                    value={formikSecond.values.proeficiencia_en_frances}
                  >
                    <option value=''>{intl.formatMessage({ id: 'PROFILE.FRENCHPROF' })}...</option>
                    <option value='Nulo'>Nulo</option>
                    <option value='Basico'>Básico</option>
                    <option value='Intermedio'>Intermedio</option>
                    <option value='Avanzado'>Avanzado</option>
                  </Select>
                  {formikSecond.touched.proeficiencia_en_frances &&
                    formikSecond.errors.proeficiencia_en_frances && (
                      <Alert>{formikSecond.errors.proeficiencia_en_frances}</Alert>
                    )}
                </div>
              </div>
              <div className='container-question'>
                <H3 className='lg-12 pl-3 col-form-label title-forms'>
                  ¿Ha realizado aplicaciones previas a Canadá en el pasado?
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    {...formikSecond.getFieldProps('plicaciones_previas')}
                    className='custom-input'
                  >
                    <option value=''>
                      {intl.formatMessage({ id: 'PROFILE.CANADAAPLICATION' })}...
                    </option>
                    <option value='Si'>Si</option>
                    <option value='No'>No</option>
                  </Select>
                  {formikSecond.touched.plicaciones_previas &&
                    formikSecond.errors.plicaciones_previas && (
                      <Alert>{formikSecond.errors.plicaciones_previas}</Alert>
                    )}
                </div>
              </div>
              {formikSecond.values.plicaciones_previas === 'Si' && (
                <>
                  <div className='container-question'>
                    <H3 className='lg-12 pl-3 col-form-label title-forms'>
                      ¿Qué tipo de aplicación realizó?
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Input
                        className='custom-input'
                        type='text'
                        placeholder='¿Qué tipo de aplicación realizó?'
                        {...formikSecond.getFieldProps('tipo_aplicacion')}
                        value={formikSecond.values.tipo_aplicacion}
                      />
                      {formikSecond.touched.tipo_aplicacion &&
                        formikSecond.errors.tipo_aplicacion && (
                          <Alert variant='danger'>{formikSecond.errors.tipo_aplicacion}</Alert>
                        )}
                    </div>
                  </div>
                  <div className='container-question'>
                    <H3 className='lg-12 pl-3 col-form-label title-forms'>
                      ¿Ha tenido rechazos?
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input'
                        {...formikSecond.getFieldProps('rechazos_visa')}
                        value={formikSecond.values.rechazos_visa || ''}
                      >
                        <option value=''>{intl.formatMessage({ id: 'PROFILE.REJECTED' })}...</option>
                        <option value='Si'>Si</option>
                        <option value='No'>No</option>
                      </Select>
                      {formikSecond.touched.rechazos_visa &&
                        formikSecond.errors.rechazos_visa && (
                          <Alert>{formikSecond.errors.rechazos_visa}</Alert>
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {edit && (
              <div className='flex w-full mt-4 items-center justify-center'
              style={{marginBottom:'9rem'}}>
                <Button
                  className='button-ui button-secondary button-s back-button-form'
                  onClick={() => setTabActive && setTabActive('profile')}
                  style={{
                    height: '2rem',
                    width: '8rem',
                    alignSelf: 'end',
                    marginRight: '2rem',
                    color: 'black'
                  }}>
                  VOLVER
                </Button>
                {/*    <>{setCompletionStatus(formikSecond.isValid)}</> */}
                <Button
                  className='button-ui button-secondary'
                  type='submit'
                  /*   disabled={!formikSecond.isValid} */
                  onClick={() => {
                    if (/* !isContinueButtonDisabled && */ setTabActive != null) {
                      setTabActive('homenew1')
                    }
                  }}
                  style={{
                    width: '8rem',
                    height: '2rem',
                    alignSelf: 'end',
                    marginRight: '2rem',
                    backgroundColor: continueButtonColor,
                  }}
                >
                  {!loading && intl.formatMessage({ id: 'CORE.BTNSAVE' })}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'PROFILE.PLEASEWAIT' })}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default observer(InmiDetailsChop1)
