/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'
import { RootStoreContext } from '../../../../../stores/rootStore'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import ShowAnswersConsultant from 'app/pages/services/services/supportDocumentsClient/ShowAnswersConsultant'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import IntakeList from './IntakeList'
import ContractsList from './NewIntakeView/ContractsList'

interface IProps {
  handleClose: (key: string) => void
  currentUserEmail: string
  goToResumen: () => void
}

const ShowClientDocuments: React.FC<IProps> = ({ goToResumen }) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const { selectUser, currentUser } = rootStore.usersStore
  const [activeKey, setActiveKey] = useState(false)
  const [contractSelected, setContractSelected] = useState('')

  const [contractsFound, setContractsFound] = useState<RootObjectCompletedContracts>({
    resultSetSize: '',
    startPosition: '',
    endPosition: '',
    totalSetSize: '',
    nextUri: '',
    previousUri: '',
    envelopes: [],
    folderItems: [],
  })

  return (
    <div>
      {staff.type === 'god_mode' || staff.type === 'intake_admin' ? (
        <IntakeList email={currentUser?.email || ''} id={currentUser?.id || ''} contractsFound={contractsFound}/>
      ) : null}
      <>
        {activeKey === false && <ContractsList
          userId={currentUser?.id || ''}
          userEmail={currentUser?.email || ''}
          callRefetch={true}
          setActiveKey = {setActiveKey}
          setContractSelected = {setContractSelected}
        />}

        {activeKey === true && <ShowAnswersConsultant
          userEmail={currentUser?.email || ''}
          contractsFound={contractSelected}
          setActiveKey = {setActiveKey}
          goToResumen={goToResumen} 
        />}
        
      </>
    </div>
  )
}

export default observer(ShowClientDocuments)
