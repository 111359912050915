 
/* eslint-disable  */
import './zoomOutButton.css'
import React, {useContext, useState} from 'react'
import PdfViewerAndres from '../../../../../core/components/PdfViewer/pdfViewerAndres'
import {RootStoreContext} from '../../../../../stores/rootStore'
import {getBase64pdf} from '../../../../../app/modules/apps/docusign/_requests'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Spin} from 'antd'

interface IProps {
  envelopeId: string
}
const PdfView: React.FC<IProps> = ({envelopeId}) => {
  const rootStore = useContext(RootStoreContext)
  const intl = useIntl()
  const {currentUser} = rootStore.usersStore
  const {user, fulfillUser} = rootStore.authStore
  const [validateShowContract, setValidateShowContract] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [result, setResult] = useState()

  // const [envelopeId, setEnvelopeId] = useState('')

  localStorage.removeItem(`${envelopeId}`)
  if (!window.localStorage.getItem(`${envelopeId}`)) {
    const {
      data: dataResult,
      status,
      refetch,
      isSuccess,
      isLoading,
      isFetching,
    } = useQuery('pdfbase64', async () => await getBase64pdf(envelopeId))
    if (isFetching) {
      return (
        <>
          {' '}
          <Spin tip={intl.formatMessage({id: 'LOADING.BUTTON'})} size='large'>
            <div className='content' />
          </Spin>
        </>
      )
    }
    if (isLoading) {
      return (
        <>
          {' '}
          <Spin tip={intl.formatMessage({id: 'LOADING.BUTTON'})} size='large'>
            <div className='content' />
          </Spin>
        </>
      )
    }
    if (isSuccess && result !== dataResult) {
      setResult(dataResult)
      window.localStorage.setItem(`${envelopeId}`, '')
    }
  }

  return (
    <div>
      <>
        <> {/* Visible {envelopeId} */} </>
        <PdfViewerAndres
          url={`data:application/pdf;base64,${result}`}
          name='='
          children={undefined}
        />
      </>
    </div>
  )
}

export {PdfView}
