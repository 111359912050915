import React, { useState } from 'react';
import CartRoutesEmbed from '../Routes/CartRoutesEmbed';
import IntakeTranslationRoute from '../Routes/IntakeTranslationRoute';

interface SlideOverProps {
  onClose: () => void;
}

const SlideOverComponent: React.FC<SlideOverProps> = ({ onClose }) => {
  const [selectedTranslation, setSelectedTranslation] = useState('');

  // Mapa de opciones con nombres y rutas
  const translationOptions = [
    { name: 'Seleccione', value: '' }, // Opción por defecto
    { name: 'Español a Inglés', value: '4762b784-650d-429b-b48d-02c351f34085' },
    { name: 'Inglés a Español', value: '84f2189d-5f26-4cc9-a323-a7862c4c89ab' },
    { name: 'Español a frances', value: '4762b784-650d-429b-b48d-02c351f34020' },
    { name: 'Frances a español  ', value: 'a93de436-69c4-41f0-8363-c89308b4136d' },
    { name: 'Español a Inglés (Express)', value: '246a7754-2472-4d28-9126-eaab9f60d3fa' },
    { name: 'Inglés a Español (Express)', value: '60d978e5-9e05-4171-8835-b6aca153f2e2' },
    { name: 'Español a frances express', value: '41bad2f7-37f9-49d6-92e9-fd78daa8776f' },
    { name: 'Frances a español  express', value: 'cbb623cb-9db0-4435-a149-e6447ce54cb4' },
    { name: 'Traduccion Licencia Quebec', value: 'c51024d8-9ac0-4e25-8330-55e50ee22fd6' },
    { name: 'Traduccion Licencia Ontario', value: 'ed872c2e-087c-4123-bf9e-bc0b8abd4627' },
    { name: 'Traduccion Licencia British Columbia', value: '7ea43705-fd66-486a-9cf3-bb7eb4c1046a' },
    { name: 'Traduccion resto de provincias (ingles)', value: '520e34cf-5ecd-41fa-aa46-13cefe37a289' },
    { name: 'Traduccion OTTIAQ Quebec', value: '40edcebc-556a-419d-a375-5ae7c3196cb8' },
    { name: 'Traduccion ATIO Ontario', value: 'b6103120-8886-492c-a25a-fe4b4d9ecce8' },
  ];
  const handleBackButtonClick = () => {
    onClose();
  };

  const handleTranslationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("entre aca")
   setSelectedTranslation("")
    setSelectedTranslation(e.target.value);
  };

  return (
    <div className="overlay">
      <div style={{paddingLeft:'30px'}} className="panel slide-over">
        <div className="header">
          <button onClick={handleBackButtonClick} className="back-button">
            Volver a consignar documentos
          </button>
          <span className="sr-only">Close</span>
        </div>

        <div className="title-photo">
        <img
                        src='/media/svg/menu-icons/world.svg'
                        alt='arrow Icon'
                        className='icon'
                      /> <h2 className="title-hover" id="slide-over-title">
       Comprar Traduccion
          </h2></div>

        <div className="content">
          <div className="form-element">
            <label className="block text-sm font-medium text-gray-700">
              Seleccionar Traducción
            </label>
            <select
              value={selectedTranslation}
              onChange={handleTranslationChange}
              className='custom-input'
            >
              {translationOptions.map((option, index) => (
                <option key={index} value={option.value}>{option.name}</option>
              ))}
            </select>
          </div>

          {/* Mostrar embed según la opción seleccionada */}
        {/*   {selectedTranslation && (
            <iframe
              src={selectedTranslation}
              width="100%"
              height="800"
              frameBorder="0"
              allowFullScreen
              title="Embedded Translation"
            />
          )} */}
          {selectedTranslation &&
            <IntakeTranslationRoute productId={selectedTranslation}/>}
         
        </div>
      </div>
    </div>
  );
};

export default SlideOverComponent;
