/* eslint-disable @typescript-eslint/no-unused-vars */

import {Container} from '../../../components/Container/index'
import EmbedSupport from './EmbedSupport'
import {useIntl} from 'react-intl'
import {useState} from 'react'

const UserSupport = () => {
  const intl = useIntl()

  const [immigration, setImmigration] = useState('')
  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.SUPPORT'})}>
        <EmbedSupport
          link={'https://forms.monday.com/forms/embed/0485af6f1738f6d4d09f06932ac96197?r=use1'}
        />
      </Container>
    </>
  )
}

export default UserSupport
