/* eslint-disable @typescript-eslint/no-unused-vars */
import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useContext, useEffect, useState} from 'react'
import AsideMenuAdmin from '../../core/layout/components/aside/AsideMenuAdmin'
import AsideMenuMainUser from '../../core/layout/components/aside/AsideMenuMainUser'
import AvatarProfile from '../../app/modules/accounts/components/settings/cards/Avatar/AvatarProfile'
import {BiXCircle} from 'react-icons/bi'
import {GiHamburgerMenu} from 'react-icons/gi'
import {HeaderUserMenu} from '../../core/layout/components'
import {RoleEnum} from '../../auth/models/User/UserModel'
import {RootStoreContext} from '../../stores/rootStore'
import {useIntl} from 'react-intl'
import '../../Style/navbar.css'

interface IProps {
  fixed?: string
}

const Navbar: React.FC<IProps> = ({fixed}) => {
  const [menu, setMenu] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const rootStore = useContext(RootStoreContext)
  const {user, logout} = rootStore.authStore
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    if (user != null) {
      if (user.role?.id === RoleEnum.admin) {
        setMenu(false)
      }
    }
  }, [user])

  return (
    <>
      <nav className='w-full'>
        <div className=' mx-auto px-4 flex justify-between'>
          <div className='flex items-center h-10 rounded-full lg:hidden'>
            <button
              className='h-8 w-8'
              onClick={() => {
                setSidebarOpen(true)
              }}
            >
              <GiHamburgerMenu className='h-8 w-8 ' />
            </button>
          </div>
          {/* <img
            src={'/media/newdesign/slogan.svg'}
            className='lg:h-5 lg:w-auto flex my-auto h-0 w-0'
            alt=''
          /> */}
        {/*   <img
            src={'/media/newdesign/07-logo-horizontal-color.svg'}
            className=' flex my-auto mx-auto h-10 lg:hidden logo-navbar'
            alt=''
          /> */}
          {/* <div className='flex items-center h-12 rounded-full pl-2 avatar-logo'>
            <div
              className='h-5 w-5 '
              onClick={() => {
                setIsOpen(true)
              }}
            >
              <AvatarProfile initialImage='' size={30} />
            </div>
            {isOpen && <HeaderUserMenu setIsOpen={setIsOpen} isOpen={isOpen} />}
          </div> */}
        </div>
      </nav>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-40 flex lg:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative max-w-xs w-full bg-white pb-8 flex-1 flex flex-col'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div
                className='absolute top-0 right-0 -mr-14 p-1'
                style={{backgroundColor:'white', borderRadius:'0 10px 10px 0'}}>
                  <button
                    type='button'
                    className='h-12 w-12 pt-3 rounded-full flex items-center justify-center focus:outline-none '
                    onClick={() => {
                      setSidebarOpen(false)
                    }}
                  >
                    <p style={{border:'3px solid #1F2A37' ,color:'#1F2A37', padding:'0 6px', borderRadius:'50%', fontWeight:'bold'}}>X</p>
                  </button>
                </div>
              </Transition.Child>
              <ul
              style={{overflow:'scroll'}}
                className='mt-0'
                onClick={() => {
                  setSidebarOpen(false)
                }}>
                {menu ? <AsideMenuMainUser /> : <AsideMenuAdmin />}
              </ul>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export {Navbar}
