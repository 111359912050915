/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type IApointments,
  type Orders,
  type OrdersAdmin,
  type OrdersAdminQueryResponse,
  type OrdersQueryResponse,
} from './_models'
import axios, {type AxiosResponse} from 'axios'

import {type Response} from '../../../../../core/helpers'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'

const API_URL = process.env.REACT_APP_API_URL
const ORDERS_URL = `${API_URL}/calendly`
const ADMIN_ORDERS_URL = `${API_URL}/staffs/consultation`

const getOrders = async (query: string): Promise<Orders[]> => {
  return await axios.get(`${ORDERS_URL}?${query}`).then((d: AxiosResponse<OrdersQueryResponse>) => {
    return d.data as Orders[]
  })
}

const getAdminOrders = (params: IApointments | null): Promise<OrdersAdmin[]> | null => {
  if (params != null) {
    return axios
      .post(`${ADMIN_ORDERS_URL}`, params)
      .then((d: AxiosResponse<OrdersAdminQueryResponse>) => {
        return d.data as OrdersAdmin[]
      })
  }
  return null
}

const updateOrders = async (orders: Orders, id: string) => {
  return await axios
    .patch(`${ORDERS_URL}`, orders)
    .then((response: AxiosResponse<Response<Orders>>) => response.data)
    .then((response: Response<Orders>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar perfil')
    })
}

const saveOrder = (orders: Orders): Promise<any> | undefined => {
  // total: number;
  // receiptLink: string;
  // date: Date | null;
  // user: User;
  // payment: Payment;

  console.log('orders 123: ', orders)

  return axios.post(`${API_URL}/orders`, {...orders}).then((response: AxiosResponse) => {
    return response.data
  })
}

export {getOrders, updateOrders, getAdminOrders, saveOrder}
