import React from 'react';
import './CustomToastStyles.css';

// Definir los tipos de las props
interface CustomToastProps {
  message: string;
  onClose: () => void;
}

interface Toast {
  message: string;
}

interface CustomToastContainerProps {
  toasts: Toast[];
  removeToast: (index: number) => void;
  toastStyle?: string; 
  closable?: boolean;
}

const CustomToast: React.FC<CustomToastProps> = ({ message, onClose }) => (
  <div className="custom-toast">
    <img alt="Calendar" className='meet-image2' src='/media/icons/app/menu/notify.svg' />
    <span className="toast-message">{message}</span>
    <button style={{color:'#004434', marginBottom:'2rem'}} onClick={onClose} className="toast-close-button">X</button>
  </div>
);

const CustomToastContainer: React.FC<CustomToastContainerProps> = ({ toasts, removeToast }) => (
  <div className="custom-toast-container">
    {toasts.map((toast, index) => (
      <CustomToast key={index} message={toast.message} onClose={() => removeToast(index)} />
    ))}
  </div>
);

export default CustomToastContainer;
