export const core = {
  'UPDATE.SUCCESS': 'Update Success',
  'UPDATE.ERROR': 'Error to update',
  'APPOINTMENTS.LINKMEET': 'Link Meet',
  'APPOINTMENTS.CONSULTANT': 'Consultant',
  'APPOINTMENTS.DATE': 'Appointment Date',
  'TRANSLATOR.SELECT': 'Select your language',
  'CORE.BTNSAVE': 'Save',
  'CORE.WELCOME': 'Welcome',
  'CORE.DATE': 'Date',
  'CORE.TYPE': 'Type',
  'CORE.REQUIRED': 'required field',
  'MENU.NEW': 'new',
  'MENU.ACTIONS': 'Actions',
  'MENU.CREATE_POST': 'Create New Post',
  'MENU.PAGES': 'Pages',
  'MENU.FEATURES': 'Features',
  'MENU.APPS': 'Apps',
  'MENU.DASHBOARD': 'Dashboard',
  'MENU.MYINFO': 'My Info',
  'MENU.MYVISA': 'My visa proccess',
  'MENU.MYSERVICES': 'My services',
  'MENU.MYAPPOINTMENTS': 'My appointments',
  'MENU.MYDOCUMENTS': 'My documents',
  'MENU.ACTIVITIES': 'Activities',
  'MENU.ACTIVITIES_LIST': 'Activitie list',
  'MENU.FILEMANAGER': 'File manager',
  'MENU.TEMPLATES': 'Templates',
  'MENU.MESSAGES': 'Messages',
  'MENU.LIBRARY': 'Library',
  'MENU.TRANSLATION': 'My translations',
  'MENU.TIMELINE': 'Timeline',
  'AUTH.GENERAL.OR': 'Or',
  'AUTH.GENERAL.SUBMIT_BUTTON': 'Submit',
  'AUTH.GENERAL.NO_ACCOUNT': "Don't have an account?",
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Sign Up',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Forgot Password',
  'AUTH.GENERAL.BACK_BUTTON': 'Back',
  'AUTH.GENERAL.PRIVACY': 'Privacy',
  'AUTH.GENERAL.LEGAL': 'Legal',
  'AUTH.GENERAL.CONTACT': 'Contact',
  'AUTH.LOGIN.TITLE': 'Login Account',
  'AUTH.LOGIN.BUTTON': 'Sign In',
  'AUTH.FORGOT.TITLE': 'Forgotten Password?',
  'AUTH.FORGOT.DESC': 'Enter your email to reset your password',
  'AUTH.FORGOT.SUCCESS': 'Your account has been successfully reset.',
  'AUTH.REGISTER.TITLE': 'Sign Up',
  'AUTH.REGISTER.DESC': 'Enter your details to create your account',
  'AUTH.REGISTER.SUCCESS': 'Your account has been successfuly registered.',
  'AUTH.INPUT.EMAIL': 'Email',
  'AUTH.INPUT.FULLNAME': 'Fullname',
  'AUTH.INPUT.PASSWORD': 'Password',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Confirm Password',
  'AUTH.INPUT.USERNAME': 'Username',
  'AUTH.VALIDATION.INVALID': '{name} is not valid',
  'AUTH.VALIDATION.REQUIRED': '{name} is required',
  'AUTH.VALIDATION.MIN_LENGTH': '{name}} minimum length is {{min}',
  'AUTH.VALIDATION.AGREEMENT_REQUIRED': 'Accepting terms & conditions are required',
  'AUTH.VALIDATION.NOT_FOUND': 'The requested {name} is not found',
  'AUTH.VALIDATION.INVALID_LOGIN': 'The login detail is incorrect',
  'AUTH.VALIDATION.REQUIRED_FIELD': 'Required field',
  'AUTH.VALIDATION.MIN_LENGTH_FIELD': 'Minimum field length:',
  'AUTH.VALIDATION.MAX_LENGTH_FIELD': 'Maximum field length:',
  'AUTH.VALIDATION.INVALID_FIELD': 'Field is not valid',
  'ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT': 'Selected records count: ',
  'ECOMMERCE.COMMON.ALL': 'All',
  'ECOMMERCE.COMMON.SUSPENDED': 'Suspended',
  'ECOMMERCE.COMMON.ACTIVE': 'Active',
  'ECOMMERCE.COMMON.FILTER': 'Filter',
  'ECOMMERCE.COMMON.BY_STATUS': 'by Status',
  'ECOMMERCE.COMMON.BY_TYPE': 'by Type',
  'ECOMMERCE.COMMON.BUSINESS': 'Business',
  'ECOMMERCE.COMMON.INDIVIDUAL': 'Individual',
  'ECOMMERCE.COMMON.SEARCH': 'Search',
  'ECOMMERCE.COMMON.IN_ALL_FIELDS': 'in all fields',
  'ECOMMERCE.ECOMMERCE': 'eCommerce',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS': 'Customers',
  'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST': 'Customers list',
  'ECOMMERCE.CUSTOMERS.NEW_CUSTOMER': 'New Customer',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE': 'Customer Delete',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION':
    'Are you sure to permanently delete this customer?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION': 'Customer is deleting...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE': 'Customer has been deleted',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE': 'Customers Delete',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION':
    'Are you sure to permanently delete selected customers?',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION': 'Customers are deleting...',
  'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE': 'Selected customers have been deleted',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE': 'Status has been updated for selected customers',
  'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE':
    'Selected customers status have successfully been updated',
  'ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE': 'Customer has been updated',
  'ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE': 'Customer has been created',
  'PROFILE.PROFILETITTLE': 'My profile',
  'PROFILE.PROFILETITTLEBASIC': 'Basic data',
  'PROFILE.PROFILETITTLEMIGRATORY': 'Migratory data',
  'PROFILE.SIGNOUT': 'Sign Out',
  'PROFILE.PLEASEWAIT': 'Please Wait',
  'PROFILE.FIRSTNAME': 'First name',
  'PROFILE.LASTNAME': 'Last name',
  'PROFILE.EMAIL': 'Email',
  'PROFILE.OCCUPATION': 'Occupation',
  'PROFILE.BIRTH': 'Birt',
  'PROFILE.ADDRESS': 'Address',
  'PROFILE.PHONE': 'Phone',
  'PROFILE.MARITALSTATUS': 'Marital status',
  'PROFILE.COUNTRYOFORIGIN': 'Country of current residence',
  'PROFILE.NATIONALITIE': 'Nationalitie',
  'PROFILE.NATIONALITIESELECT': 'Select Nationalitie...',
  'PROFILE.LANGUAGE': 'Language',
  'PROFILE.LANGUAGESELECT': 'Select Language...',
  'PROFILE.TIMEZONE': 'Time zone',
  'PROFILE.TIMEZONESELECT': 'Select time zone...',
  'PROFILE.GENDER': 'Gender',
  'PROFILE.GENDERSELECT': 'Select gender',
  'PROFILE.GENDERM': 'Male',
  'PROFILE.GENDERF': 'Female',
  'PROFILE.GENDERO': 'Other',
}
