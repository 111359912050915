/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import xmlbuilder from 'xmlbuilder'
import axios from 'axios'
import thunderblue from './Icons/thunderbolt-blue.svg'
import thunderbolt from './Icons/thunderbolt.svg'
import { RootStoreContext } from 'stores/rootStore'
import { toast } from 'react-toastify'
interface IProps {
  product: any
  principalProduct: any
  contractsData: any;
  contractsFound: any;
  lang: string
}

const IMM5257B_CONYUGUE: React.FC<IProps> = ({ product, principalProduct, contractsData, contractsFound, lang }) => {

  console.log(product, 'Respuestas del conyuge')
  console.log(principalProduct, 'Respuestas del principal')

  //Page 1
  const [age, setAge] = useState('')
  const [isAdult, setIsAdult] = useState(false)
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [visaType, setVisaType] = useState('')
  const [UCI, setUCI] = useState('')
  const [DOBYear, setDOBYear] = useState('')
  const [DOBMonth, setDOBMonth] = useState('')
  const [DOBDay, setDOBDay] = useState('')

  const [MilitarChoice, setMilitarChoice] = useState(false)
  const [MilitarDetails, setMilitarDetails] = useState('')
  const [Militar2Details, setMilitar2Details] = useState('')
  const [MilitarDetails2, setMilitarDetails2] = useState('')
  const [MilitarDetails3, setMilitarDetails3] = useState('')
  const [MilitarDetails4, setMilitarDetails4] = useState('')
  const [MilitarDetails5, setMilitarDetails5] = useState('')
  const [Militar2Details5, setMilitar2Details5] = useState('')
  const [MilitarDetails6, setMilitarDetails6] = useState('')
  const [MilitarDetails7, setMilitarDetails7] = useState('')
  const [MilitarDetails8, setMilitarDetails8] = useState('')
  const [MilitarDetails9, setMilitarDetails9] = useState('')
  const [Militar2Details9, setMilitar2Details9] = useState('')
  const [MilitarDetails10, setMilitarDetails10] = useState('')
  const [MilitarDetails11, setMilitarDetails11] = useState('')
  const [MilitarDetails12, setMilitarDetails12] = useState('')
  const [MilitarDetails13, setMilitarDetails13] = useState('')
  const [Militar2Details13, setMilitar2Details13] = useState('')
  const [MilitarDetails14, setMilitarDetails14] = useState('')
  const [MilitarDetails15, setMilitarDetails15] = useState('')
  const [MilitarDetails16, setMilitarDetails16] = useState('')
  const [MilitarDetailsAdd5Militar, setMilitarDetailsAdd5Militar] = useState('')
  const [MilitarDetails17, setMilitarDetails17] = useState('')
  const [Militar2Details17, setMilitar2Details17] = useState('')
  const [MilitarDetails18, setMilitarDetails18] = useState('')
  const [MilitarDetails19, setMilitarDetails19] = useState('')
  const [MilitarDetails20, setMilitarDetails20] = useState('')

  const [OrganizationsAssociation, setOrganizationsAssociation] = useState(false)
  const [OrganizationsDetails, setOrganizationsDetails] = useState('')
  const [Organizations2Details, setOrganizations2Details] = useState('')
  const [OrganizationsDetails2, setOrganizationsDetails2] = useState('')
  const [OrganizationsDetails3, setOrganizationsDetails3] = useState('')
  const [OrganizationsDetails4, setOrganizationsDetails4] = useState('')
  const [OrganizationsDetails5, setOrganizationsDetails5] = useState('')
  const [OrganizationsDetails6, setOrganizationsDetails6] = useState('')
  const [Organizations2Details6, setOrganizations2Details6] = useState('')
  const [OrganizationsDetails7, setOrganizationsDetails7] = useState('')
  const [OrganizationsDetails8, setOrganizationsDetails8] = useState('')
  const [OrganizationsDetails9, setOrganizationsDetails9] = useState('')
  const [OrganizationsDetails10, setOrganizationsDetails10] = useState('')
  const [OrganizationsDetails11, setOrganizationsDetails11] = useState('')
  const [Organizations2Details11, setOrganizations2Details11] = useState('')
  const [OrganizationsDetails12, setOrganizationsDetails12] = useState('')
  const [OrganizationsDetails13, setOrganizationsDetails13] = useState('')
  const [OrganizationsDetails14, setOrganizationsDetails14] = useState('')
  const [OrganizationsDetails15, setOrganizationsDetails15] = useState('')

  const [PoliticalAssociation, setPoliticalAssociation] = useState(false)
  const [AsociationDetails, setAsociationDetails] = useState('')
  const [Asociation2Details, setAsociation2Details] = useState('')
  const [AsociationDetails2, setAsociationDetails2] = useState('')
  const [AsociationDetails3, setAsociationDetails3] = useState('')
  const [AsociationDetails4, setAsociationDetails4] = useState('')
  const [AsociationDetails5, setAsociationDetails5] = useState('')
  const [AsociationDetails6, setAsociationDetails6] = useState('')
  const [Asociation2Details6, setAsociation2Details6] = useState('')
  const [AsociationDetails7, setAsociationDetails7] = useState('')
  const [AsociationDetails8, setAsociationDetails8] = useState('')
  const [AsociationDetails9, setAsociationDetails9] = useState('')
  const [AsociationDetails10, setAsociationDetails10] = useState('')
  const [AsociationDetails11, setAsociationDetails11] = useState('')
  const [Asociation2Details11, setAsociation2Details11] = useState('')
  const [AsociationDetails12, setAsociationDetails12] = useState('')
  const [AsociationDetails13, setAsociationDetails13] = useState('')
  const [AsociationDetails14, setAsociationDetails14] = useState('')
  const [AsociationDetails15, setAsociationDetails15] = useState('')
  const [AsociationDetails16, setAsociationDetails16] = useState('')
  const [Asociation2Details16, setAsociation2Details16] = useState('')
  const [AsociationDetails17, setAsociationDetails17] = useState('')
  const [AsociationDetails18, setAsociationDetails18] = useState('')
  const [AsociationDetails19, setAsociationDetails19] = useState('')
  const [AsociationDetails20, setAsociationDetails20] = useState('')

  const [GovermentPositions, setGovermentPositions] = useState(false)
  const [GovermentPositionsDetails, setGovermentPositionsDetails] = useState('')
  const [GovermentPositions2Details, setGovermentPositions2Details] = useState('')
  const [GovermentPositionsDetails2, setGovermentPositionsDetails2] = useState('')
  const [GovermentPositionsDetails3, setGovermentPositionsDetails3] = useState('')
  const [GovermentPositionsDetails4, setGovermentPositionsDetails4] = useState('')
  const [GovermentPositionsDetails5, setGovermentPositionsDetails5] = useState('')
  const [GovermentPositionsDetails6, setGovermentPositionsDetails6] = useState('')
  const [GovermentPositions2Details6, setGovermentPositions2Details6] = useState('')
  const [GovermentPositionsDetails7, setGovermentPositionsDetails7] = useState('')
  const [GovermentPositionsDetails8, setGovermentPositionsDetails8] = useState('')
  const [GovermentPositionsDetails9, setGovermentPositionsDetails9] = useState('')
  const [GovermentPositionsDetails10, setGovermentPositionsDetails10] = useState('')
  const [GovermentPositionsDetails11, setGovermentPositionsDetails11] = useState('')
  const [GovermentPositions2Details11, setGovermentPositions2Details11] = useState('')
  const [GovermentPositionsDetails12, setGovermentPositionsDetails12] = useState('')
  const [GovermentPositionsDetails13, setGovermentPositionsDetails13] = useState('')
  const [GovermentPositionsDetails14, setGovermentPositionsDetails14] = useState('')
  const [GovermentPositionsDetails15, setGovermentPositionsDetails15] = useState('')
  const [GovermentPositionsDetails16, setGovermentPositionsDetails16] = useState('')
  const [GovermentPositions2Details16, setGovermentPositions2Details16] = useState('')
  const [GovermentPositionsDetails17, setGovermentPositionsDetails17] = useState('')
  const [GovermentPositionsDetails18, setGovermentPositionsDetails18] = useState('')
  const [GovermentPositionsDetails19, setGovermentPositionsDetails19] = useState('')
  const [GovermentPositionsDetails20, setGovermentPositionsDetails20] = useState('')

  const [Travel, setTravel] = useState(false)
  const [PreviousTravel, setPreviousTravel] = useState('')
  const [Previous2Travel, setPrevious2Travel] = useState('')
  const [PreviousTravel2, setPreviousTravel2] = useState('')
  const [PreviousTravel3, setPreviousTravel3] = useState('')
  const [PreviousTravel4, setPreviousTravel4] = useState('')
  const [PreviousTravel5, setPreviousTravel5] = useState('')
  const [Previous2Travel5, setPrevious2Travel5] = useState('')
  const [PreviousTravel6, setPreviousTravel6] = useState('')
  const [PreviousTravel7, setPreviousTravel7] = useState('')
  const [PreviousTravel8, setPreviousTravel8] = useState('')
  const [PreviousTravel9, setPreviousTravel9] = useState('')
  const [Previous2Travel9, setPrevious2Travel9] = useState('')
  const [PreviousTravel10, setPreviousTravel10] = useState('')
  const [PreviousTravel11, setPreviousTravel11] = useState('')
  const [PreviousTravel12, setPreviousTravel12] = useState('')
  const [PreviousTravel13, setPreviousTravel13] = useState('')
  const [Previous2Travel13, setPrevious2Travel13] = useState('')
  const [PreviousTravel14, setPreviousTravel14] = useState('')
  const [PreviousTravel15, setPreviousTravel15] = useState('')
  const [PreviousTravel16, setPreviousTravel16] = useState('')

  const [PreviousTravelAdd5, setPreviousTravelAdd5] = useState('')
  const [PreviousTravel17, setPreviousTravel17] = useState('')
  const [Previous2Travel17, setPrevious2Travel17] = useState('')
  const [PreviousTravel18, setPreviousTravel18] = useState('')
  const [PreviousTravel19, setPreviousTravel19] = useState('')
  const [PreviousTravel20, setPreviousTravel20] = useState('')

  const [PreviousTravelAdd6, setPreviousTravelAdd6] = useState('')
  const [PreviousTravel21, setPreviousTravel21] = useState('')
  const [Previous2Travel21, setPrevious2Travel21] = useState('')
  const [PreviousTravel22, setPreviousTravel22] = useState('')
  const [PreviousTravel23, setPreviousTravel23] = useState('')
  const [PreviousTravel24, setPreviousTravel24] = useState('')

  const [PreviousTravelAdd7, setPreviousTravelAdd7] = useState('')
  const [PreviousTravel25, setPreviousTravel25] = useState('')
  const [Previous2Travel25, setPrevious2Travel25] = useState('')
  const [PreviousTravel26, setPreviousTravel26] = useState('')
  const [PreviousTravel27, setPreviousTravel27] = useState('')
  const [PreviousTravel28, setPreviousTravel28] = useState('')

  const [PreviousTravelAdd8, setPreviousTravelAdd8] = useState('')
  const [PreviousTravel29, setPreviousTravel29] = useState('')
  const [Previous2Travel29, setPrevious2Travel29] = useState('')
  const [PreviousTravel30, setPreviousTravel30] = useState('')
  const [PreviousTravel31, setPreviousTravel31] = useState('')
  const [PreviousTravel32, setPreviousTravel32] = useState('')

  const [PreviousTravelAdd9, setPreviousTravelAdd9] = useState('')
  const [PreviousTravel33, setPreviousTravel33] = useState('')
  const [Previous2Travel33, setPrevious2Travel33] = useState('')
  const [PreviousTravel34, setPreviousTravel34] = useState('')
  const [PreviousTravel35, setPreviousTravel35] = useState('')
  const [PreviousTravel36, setPreviousTravel36] = useState('')

  const [PreviousTravelAdd10, setPreviousTravelAdd10] = useState('')
  const [PreviousTravel37, setPreviousTravel37] = useState('')
  const [Previous2Travel37, setPrevious2Travel37] = useState('')
  const [PreviousTravel38, setPreviousTravel38] = useState('')
  const [PreviousTravel39, setPreviousTravel39] = useState('')
  const [PreviousTravel40, setPreviousTravel40] = useState('')

  const fileName = `IMM5257B ${product.memberName} ${product.name || 'formulario'}.xml`;
  const rootStore = useContext(RootStoreContext)
  const { user: userAUTH } = rootStore.authStore

  //array validators
  const [arrayValidator, setArrayValidator] = useState<string[]>([]);
  const getDateObjectFromString = (dateString: any) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const compareDates = (date1: any, date2: any) => {
    const d1 = getDateObjectFromString(date1);
    const d2 = getDateObjectFromString(date2);

    if (d1 > d2) {
      return 'Mayor';
    } else if (d1 < d2) {
      return 'Menor';
    } else {
      return 'Igual';
    }
  };


  const { connectSocket } = rootStore.notificationStore
  const setAnswers = () => {
    if (product.name === 'Visitor visa') {
      setVisaType('Visitor Visa')
    } else if (product.name === 'Transit visa') {
      setVisaType('Transit')
    } else {
      setVisaType('')
    }
    product.forms.map((form: any) => {
      // Formulario de Detalles personales
      if (form && form.formData && form.formData.guide === '001') {
        form.formData.questions.map((q: any) => {
          if (q.index === '003') {
            setGivenName(q.answer)
          }
          if (q.index === '002') {
            setFamilyName(q.answer)
          }
          if (q.index === '008') {
            setUCI(q.answer)
          }
          if (q.index === '011' && q.answer.trim() !== '') {
            setDOBYear(q.answer.substring(0, 4))
            setDOBMonth(q.answer.substring(5, 7))
            setDOBDay(q.answer.substring(8))
          }
          if (q.index === '011') {
            const birthDate = new Date(q.answer)
            const today = new Date()
            let ageDifference = today.getFullYear() - birthDate.getFullYear()
            const monthsDifference = today.getMonth() - birthDate.getMonth()
            if (
              monthsDifference < 0 ||
              (monthsDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
              ageDifference--
            }
            const isAdult = ageDifference >= 18
            const currentAge = ageDifference
            setAge(currentAge.toString())
            setIsAdult(isAdult)
          }
        })
      }

      // Formulario de Servicio militar y paramilitar
      if (form && form.formData && form.formData.guide === '013') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            if (q.answer === 'Si') {
              setMilitarChoice(true)
            }
            else if (q.answer === 'No') {
              setMilitarChoice(false)
            }
          }
          /* first service */
          if (q.index === '003' && q.multipleAnswer.length > 0) {
            setMilitarDetails(q.multipleAnswer[0])
            setMilitar2Details(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '004') {
            setMilitarDetails2(q.answer)
          }
          if (q.index === '005') {
            setMilitarDetails3(q.answer)
          }
          if (q.index === '006') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setMilitarDetails4('Bolivia')
            }
            else {
              setMilitarDetails4(q.answer)
            } // pais
          }

          /* second service */
          if (q.index === '009' && q.multipleAnswer.length > 0) {
            setMilitarDetails5(q.multipleAnswer[0])
            setMilitar2Details5(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '010') {
            setMilitarDetails6(q.answer)
          }
          if (q.index === '011') {
            setMilitarDetails7(q.answer)
          }
          if (q.index === '012') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setMilitarDetails8('Bolivia')
            }
            else {
              setMilitarDetails8(q.answer)
            } // pais
          }

          /* third service */
          if (q.index === '015' && q.multipleAnswer.length > 0) {
            setMilitarDetails9(q.multipleAnswer[0])
            setMilitar2Details9(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '016') {
            setMilitarDetails10(q.answer)
          }
          if (q.index === '017') {
            setMilitarDetails11(q.answer)
          }
          if (q.index === '018') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setMilitarDetails12('Bolivia')
            }
            else {
              setMilitarDetails12(q.answer)
            } // pais
          }

          /* fourth service */
          if (q.index === '021' && q.multipleAnswer.length > 0) {
            setMilitarDetails13(q.multipleAnswer[0])
            setMilitar2Details13(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '022') {
            setMilitarDetails14(q.answer)
          }
          if (q.index === '023') {
            setMilitarDetails15(q.answer)
          }
          if (q.index === '024') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setMilitarDetails16('Bolivia')
            }
            else {
              setMilitarDetails16(q.answer)
            } // pais
          }
          if (q.index === '025') {
            setMilitarDetailsAdd5Militar(q.answer)
          }
          /* fifth service */
          if (q.index === '027' && q.multipleAnswer.length > 0) {
            setMilitarDetails17(q.multipleAnswer[0])
            setMilitar2Details17(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '028') {
            setMilitarDetails18(q.answer)
          }
          if (q.index === '029') {
            setMilitarDetails19(q.answer)
          }
          if (q.index === '030') {
            setMilitarDetails20(q.answer)
          }
        })
      }

      // Formulario de Participación en malos tratos a prisioneros o civiles
      if (form && form.formData && form.formData.guide === '016') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setOrganizationsAssociation(true)
              : q.answer === 'No'
                ? setOrganizationsAssociation(false)
                : setOrganizationsAssociation(false)
          }

          /* first organization*/
          if (q.index === '002' && q.multipleAnswer.length > 0) {  //fechas
            setOrganizationsDetails(q.multipleAnswer[0])
            setOrganizations2Details(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '003') {
            setOrganizationsDetails2(q.answer)  // Ubicacion
          }
          if (q.index === '004') {
            setOrganizationsDetails3(q.answer) // Provincia
          }
          if (q.index === '005') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setOrganizationsDetails4('Bolivia')
            }
            else {
              setOrganizationsDetails4(q.answer)
            } // pais
          }

          if (q.index === '006') {
            setOrganizationsDetails5(q.answer) //Detalles
          }

          /* Second organization*/
          if (q.index === '008' && q.multipleAnswer.length > 0) {  //fechas
            setOrganizationsDetails6(q.multipleAnswer[0])
            setOrganizations2Details6(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '009') {
            setOrganizationsDetails7(q.answer)  // Ubicacion
          }
          if (q.index === '010') {
            setOrganizationsDetails8(q.answer) // Provincia
          }
          if (q.index === '011') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setOrganizationsDetails9('Bolivia')
            }
            else {
              setOrganizationsDetails9(q.answer)
            } // pais
          }
          if (q.index === '012') {
            setOrganizationsDetails10(q.answer) //Detalles
          }

          /* Thrid organization*/
          if (q.index === '014' && q.multipleAnswer.length > 0) {  //fechas
            setOrganizationsDetails11(q.multipleAnswer[0])
            setOrganizations2Details11(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '015') {
            setOrganizationsDetails12(q.answer)  // Ubicacion
          }
          if (q.index === '016') {
            setOrganizationsDetails13(q.answer) // Provincia
          }
          if (q.index === '017') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setOrganizationsDetails14('Bolivia')
            }
            else {
              setOrganizationsDetails14(q.answer)
            } // pais
          }
          if (q.index === '018') {
            setOrganizationsDetails15(q.answer) //Detalles
          }
        })
      }

      // Formulario de Participación y asociación con organizaciones
      if (form && form.formData && form.formData.guide === '014') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setPoliticalAssociation(true)
              : q.answer === 'No'
                ? setPoliticalAssociation(false)
                : setPoliticalAssociation(false)
          }

          /* first organization*/
          if (q.index === '002' && q.multipleAnswer.length > 0) {  //fechas
            setAsociationDetails(q.multipleAnswer[0])
            setAsociation2Details(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '003') {
            setAsociationDetails2(q.answer)  // nombre de la organizacion
          }
          if (q.index === '004') {
            if (q.translate === true) {
              if (lang === 'original') {
                setAsociationDetails3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setAsociationDetails3(q.answer)
                } else {
                  setAsociationDetails3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setAsociationDetails3(q.answer)
                } else {
                  setAsociationDetails3(q.frenchAnswer)
                }
              }
            } else {
              setAsociationDetails3(q.answer)
            } // cargo que desempeño
          }
          if (q.index === '005') {
            setAsociationDetails4(q.answer) //provincia
          }

          if (q.index === '006') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setAsociationDetails5('Bolivia')
            }
            else {
              setAsociationDetails5(q.answer)
            } // pais
          }

          /* Second organization*/
          if (q.index === '009' && q.multipleAnswer.length > 0) {  //fechas
            setAsociationDetails6(q.multipleAnswer[0])
            setAsociation2Details6(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (
                compareDates(
                  q.multipleAnswer[0],
                  q.multipleAnswer[1],
                ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '010') {
            setAsociationDetails7(q.answer)  // nombre de la organizacion
          }
          if (q.index === '011') {
            if (q.translate === true) {
              if (lang === 'original') {
                setAsociationDetails8(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setAsociationDetails8(q.answer)
                } else {
                  setAsociationDetails8(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setAsociationDetails8(q.answer)
                } else {
                  setAsociationDetails8(q.frenchAnswer)
                }
              }
            } else {
              setAsociationDetails8(q.answer)
            } // cargo que desempeño
          }
          if (q.index === '012') {
            setAsociationDetails9(q.answer) //provincia
          }

          if (q.index === '013') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setAsociationDetails10('Bolivia')
            }
            else {
              setAsociationDetails10(q.answer)
            } // pais
          }

          /* Thrid organization*/
          if (q.index === '015' && q.multipleAnswer.length > 0) {  //fechas
            setAsociationDetails11(q.multipleAnswer[0])
            setAsociation2Details11(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '016') {
            setAsociationDetails12(q.answer)  // nombre de la organizacion
          }
          if (q.index === '017') {
            if (q.translate === true) {
              if (lang === 'original') {
                setAsociationDetails13(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setAsociationDetails13(q.answer)
                } else {
                  setAsociationDetails13(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setAsociationDetails13(q.answer)
                } else {
                  setAsociationDetails13(q.frenchAnswer)
                }
              }
            } else {
              setAsociationDetails13(q.answer)
            } // cargo que desempeño
          }
          if (q.index === '018') {
            setAsociationDetails14(q.answer) //provincia
          }

          if (q.index === '019') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setAsociationDetails15('Bolivia')
            }
            else {
              setAsociationDetails15(q.answer)
            } // pais
          }

          /* fourth organization*/
          if (q.index === '022' && q.multipleAnswer.length > 0) {  //fechas
            setAsociationDetails16(q.multipleAnswer[0])
            setAsociation2Details16(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '023') {
            setAsociationDetails17(q.answer)  // nombre de la organizacion
          }
          if (q.index === '024') {
            if (q.translate === true) {
              if (lang === 'original') {
                setAsociationDetails18(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setAsociationDetails18(q.answer)
                } else {
                  setAsociationDetails18(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setAsociationDetails18(q.answer)
                } else {
                  setAsociationDetails18(q.frenchAnswer)
                }
              }
            } else {
              setAsociationDetails18(q.answer)
            } // cargo que desempeño
          }
          if (q.index === '025') {
            setAsociationDetails19(q.answer) //provincia
          }

          if (q.index === '026') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setAsociationDetails20('Bolivia')
            }
            else {
              setAsociationDetails20(q.answer)
            } // pais
          }
        })
      }

      // Formulario de Cargos gubernamentales
      if (form && form.formData && form.formData.guide === '015') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setGovermentPositions(true)
              : q.answer === 'No'
                ? setGovermentPositions(false)
                : setGovermentPositions(false)
          }

          /* first organization*/
          if (q.index === '002' && q.multipleAnswer.length > 0) {  //fechas
            setGovermentPositionsDetails(q.multipleAnswer[0])
            setGovermentPositions2Details(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '003') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setGovermentPositionsDetails2('Bolivia')
            }
            else {
              setGovermentPositionsDetails2(q.answer)
            } // pais
          }
          if (q.index === '004') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails3(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails3(q.answer)
                } else {
                  setGovermentPositionsDetails3(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails3(q.answer)
                } else {
                  setGovermentPositionsDetails3(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails3(q.answer)
            } // jurisdiccion
          }
          if (q.index === '005') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails4(q.answer)
                } else {
                  setGovermentPositionsDetails4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails4(q.answer)
                } else {
                  setGovermentPositionsDetails4(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails4(q.answer)
            } //departamento sucursal
          }

          if (q.index === '006') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails5(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails5(q.answer)
                } else {
                  setGovermentPositionsDetails5(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails5(q.answer)
                } else {
                  setGovermentPositionsDetails5(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails5(q.answer)
            } //cargos
          }

          /* second organization*/
          if (q.index === '009' && q.multipleAnswer.length > 0) {  //fechas
            setGovermentPositionsDetails6(q.multipleAnswer[0])
            setGovermentPositions2Details6(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '010') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setGovermentPositionsDetails7('Bolivia')
            }
            else {
              setGovermentPositionsDetails7(q.answer)
            } // pais
          }
          if (q.index === '011') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails8(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails8(q.answer)
                } else {
                  setGovermentPositionsDetails8(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails8(q.answer)
                } else {
                  setGovermentPositionsDetails8(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails8(q.answer)
            } // jurisdiccion
          }
          if (q.index === '012') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails9(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails9(q.answer)
                } else {
                  setGovermentPositionsDetails9(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails9(q.answer)
                } else {
                  setGovermentPositionsDetails9(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails9(q.answer)
            } //departamento sucursal
          }

          if (q.index === '013') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails10(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails10(q.answer)
                } else {
                  setGovermentPositionsDetails10(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails10(q.answer)
                } else {
                  setGovermentPositionsDetails10(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails10(q.answer)
            } //cargos
          }

          /* Thrid organization*/
          if (q.index === '016' && q.multipleAnswer.length > 0) {  //fechas
            setGovermentPositionsDetails11(q.multipleAnswer[0])
            setGovermentPositions2Details11(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '017') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setGovermentPositionsDetails12('Bolivia')
            }
            else {
              setGovermentPositionsDetails12(q.answer)
            } // pais
          }
          if (q.index === '018') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails13(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails13(q.answer)
                } else {
                  setGovermentPositionsDetails13(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails13(q.answer)
                } else {
                  setGovermentPositionsDetails13(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails13(q.answer)
            } // jurisdiccion
          }
          if (q.index === '019') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails14(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails14(q.answer)
                } else {
                  setGovermentPositionsDetails14(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails14(q.answer)
                } else {
                  setGovermentPositionsDetails14(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails14(q.answer)
            } //departamento sucursal
          }

          if (q.index === '020') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails15(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails15(q.answer)
                } else {
                  setGovermentPositionsDetails15(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails15(q.answer)
                } else {
                  setGovermentPositionsDetails15(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails15(q.answer)
            } //cargos
          }

          /* Fourth organization*/
          if (q.index === '023' && q.multipleAnswer.length > 0) {  //fechas
            setGovermentPositionsDetails16(q.multipleAnswer[0])
            setGovermentPositions2Details16(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '024') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setGovermentPositionsDetails17('Bolivia')
            }
            else {
              setGovermentPositionsDetails17(q.answer)
            } // pais
          }
          if (q.index === '025') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails18(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails18(q.answer)
                } else {
                  setGovermentPositionsDetails18(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails18(q.answer)
                } else {
                  setGovermentPositionsDetails18(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails18(q.answer)
            } // jurisdiccion
          }
          if (q.index === '026') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails19(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails19(q.answer)
                } else {
                  setGovermentPositionsDetails19(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails19(q.answer)
                } else {
                  setGovermentPositionsDetails19(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails19(q.answer)
            } //departamento sucursal
          }

          if (q.index === '027') {
            if (q.translate === true) {
              if (lang === 'original') {
                setGovermentPositionsDetails20(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setGovermentPositionsDetails20(q.answer)
                } else {
                  setGovermentPositionsDetails20(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setGovermentPositionsDetails20(q.answer)
                } else {
                  setGovermentPositionsDetails20(q.frenchAnswer)
                }
              }
            } else {
              setGovermentPositionsDetails20(q.answer)
            } //cargos
          }
        })
      }

      // Formulario de Historial de viajes
      if (form && form.formData && form.formData.guide === '017') {
        form.formData.questions.map((q: any) => {
          if (q.index === '001') {
            q.answer === 'Si'
              ? setTravel(true)
              : q.answer === 'No'
                ? setTravel(false)
                : setTravel(false)
          }
          //Travel 1
          if (q.index === '003' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel(q.multipleAnswer[0])
            setPrevious2Travel(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '004') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel2('Bolivia')
            }
            else {
              setPreviousTravel2(q.answer)
            } // pais
          }
          if (q.index === '005') {
            setPreviousTravel3(q.answer) // ubicacion
          }
          if (q.index === '006') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel4(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel4(q.answer)
                } else {
                  setPreviousTravel4(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel4(q.answer)
                } else {
                  setPreviousTravel4(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel4(q.answer)
            } //proposito
          }
          //Travel 2
          if (q.index === '008' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel5(q.multipleAnswer[0])
            setPrevious2Travel5(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '009') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel6('Bolivia')
            }
            else {
              setPreviousTravel6(q.answer)
            } // pais
          }
          if (q.index === '010') {
            setPreviousTravel7(q.answer) // ubicacion
          }
          if (q.index === '011') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel8(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel8(q.answer)
                } else {
                  setPreviousTravel8(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel8(q.answer)
                } else {
                  setPreviousTravel8(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel8(q.answer)
            } //proposito
          }
          //Travel 3
          if (q.index === '013' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel9(q.multipleAnswer[0])
            setPrevious2Travel9(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '014') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel10('Bolivia')
            }
            else {
              setPreviousTravel10(q.answer)
            } // pais
          }
          if (q.index === '015') {
            setPreviousTravel11(q.answer) // ubicacion
          }
          if (q.index === '016') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel12(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel12(q.answer)
                } else {
                  setPreviousTravel12(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel12(q.answer)
                } else {
                  setPreviousTravel12(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel12(q.answer)
            } //proposito
          }
          //Travel 4
          if (q.index === '019' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel13(q.multipleAnswer[0])
            setPrevious2Travel13(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '020') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel14('Bolivia')
            }
            else {
              setPreviousTravel14(q.answer)
            } // pais
          }
          if (q.index === '021') {
            setPreviousTravel15(q.answer) // ubicacion
          }
          if (q.index === '022') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel16(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel16(q.answer)
                } else {
                  setPreviousTravel16(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel16(q.answer)
                } else {
                  setPreviousTravel16(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel16(q.answer)
            } //proposito
          }
          //Travel 5
          if (q.index === '023') {
            setPreviousTravelAdd5(q.answer)
          }
          if (q.index === '024' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel17(q.multipleAnswer[0])
            setPrevious2Travel17(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '025') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel18('Bolivia')
            }
            else {
              setPreviousTravel18(q.answer)
            } // pais
          }
          if (q.index === '026') {
            setPreviousTravel19(q.answer) // ubicacion
          }
          if (q.index === '027') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel20(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel20(q.answer)
                } else {
                  setPreviousTravel20(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel20(q.answer)
                } else {
                  setPreviousTravel20(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel20(q.answer)
            } //proposito
          }
          //Travel 6
          if (q.index === '028') {
            setPreviousTravelAdd6(q.answer)
          }
          if (q.index === '029' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel21(q.multipleAnswer[0])
            setPrevious2Travel21(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '030') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel22('Bolivia')
            }
            else {
              setPreviousTravel22(q.answer)
            } // pais
          }
          if (q.index === '031') {
            setPreviousTravel23(q.answer) // ubicacion
          }
          if (q.index === '032') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel24(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel24(q.answer)
                } else {
                  setPreviousTravel24(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel24(q.answer)
                } else {
                  setPreviousTravel24(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel24(q.answer)
            } //proposito
          }
          //Travel 7
          if (q.index === '033') {
            setPreviousTravelAdd7(q.answer)
          }
          if (q.index === '034' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel25(q.multipleAnswer[0])
            setPrevious2Travel25(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '035') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel26('Bolivia')
            }
            else {
              setPreviousTravel26(q.answer)
            } // pais
          }
          if (q.index === '036') {
            setPreviousTravel27(q.answer) // ubicacion
          }
          if (q.index === '037') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel28(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel28(q.answer)
                } else {
                  setPreviousTravel28(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel28(q.answer)
                } else {
                  setPreviousTravel28(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel28(q.answer)
            } //proposito
          }
          //Travel 8
          if (q.index === '038') {
            setPreviousTravelAdd8(q.answer)
          }
          if (q.index === '039' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel29(q.multipleAnswer[0])
            setPrevious2Travel29(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '040') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel30('Bolivia')
            }
            else {
              setPreviousTravel30(q.answer)
            } // pais
          }
          if (q.index === '041') {
            setPreviousTravel31(q.answer) // ubicacion
          }
          if (q.index === '042') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel32(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel32(q.answer)
                } else {
                  setPreviousTravel32(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel32(q.answer)
                } else {
                  setPreviousTravel32(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel32(q.answer)
            } //proposito
          }
          //Travel 9
          if (q.index === '043') {
            setPreviousTravelAdd9(q.answer)
          }
          if (q.index === '044' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel33(q.multipleAnswer[0])
            setPrevious2Travel33(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '045') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel34('Bolivia')
            }
            else {
              setPreviousTravel34(q.answer)
            } // pais
          }
          if (q.index === '046') {
            setPreviousTravel35(q.answer) // ubicacion
          }
          if (q.index === '047') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel36(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel36(q.answer)
                } else {
                  setPreviousTravel36(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel36(q.answer)
                } else {
                  setPreviousTravel36(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel36(q.answer)
            } //proposito
          }
          //Travel 10
          if (q.index === '048') {
            setPreviousTravelAdd10(q.answer)
          }
          if (q.index === '049' && q.multipleAnswer.length > 0) {  //fechas
            setPreviousTravel37(q.multipleAnswer[0])
            setPrevious2Travel37(q.multipleAnswer[1])
            if (
              (q.multipleAnswer[0] && q.multipleAnswer[1]) &&
              (compareDates(
                q.multipleAnswer[0],
                q.multipleAnswer[1],
              ) === "Mayor"
              )) {
              setArrayValidator((errors: any) => [...errors, `Error en ${form.formData.formName} en  Indique las fechas de su estatus legal `]);
            }
          }
          if (q.index === '050') {
            if (q.answer === 'Bolivia, Plurinational State of') {
              setPreviousTravel38('Bolivia')
            }
            else {
              setPreviousTravel38(q.answer)
            } // pais
          }
          if (q.index === '051') {
            setPreviousTravel39(q.answer) // ubicacion
          }
          if (q.index === '052') {
            if (q.translate === true) {
              if (lang === 'original') {
                setPreviousTravel40(q.answer)
              }
              if (lang === 'ingles') {
                if (q.englishAnswer === '') {
                  setPreviousTravel40(q.answer)
                } else {
                  setPreviousTravel40(q.englishAnswer)
                }
              }
              if (lang === 'frances') {
                if (q.frenchAnswer === '') {
                  setPreviousTravel40(q.answer)
                } else {
                  setPreviousTravel40(q.frenchAnswer)
                }
              }
            } else {
              setPreviousTravel40(q.answer)
            } //proposito
          }
        })
      }
    })
  }

  const handleUploadToServer = async () => {
    toast.info('Estamos procesando tu solicitud. Te notificaremos cuando esté listo. Tiempo de espera aproximado: 3 minutos.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: '#C3CEF6',
        height: '5rem',
        width: '27rem',
        marginLeft: '-7rem',
      },
    });
    connectSocket()
    setAnswers(); // Asumo que esta función establece algunas respuestas

    // Aquí accedes a foundContract si está disponible
    if (contractsData) {
      // Extraemos el arreglo 'data' del objeto contractsData
      const dataArray = contractsData.data;

      // Encontramos el objeto dentro de dataArray que tenga el mismo envelope_id que contractsFound
      const foundContract = dataArray.find(
        (contract: { contracts_users_envelope_id: any }) => contract.contracts_users_envelope_id === contractsFound
      );

      if (foundContract) {
        console.log('contractsData:', foundContract);

        // Creación del FormData y XML
        const updatedFormData = {
          Schedule1: {
            PrincipalApplicant: true,
            FamilyName: familyName,
            GivenName: givenName,
            ApplicantBirthDate: {
              Year: DOBYear,
              Month: DOBMonth,
              Day: DOBDay
            },
            UCI: UCI,
            MilitaryServiceInfo: {
              ServedInMilitary: MilitarChoice,
              MilitaryServiceDetails: {
                MilitaryServiceDetail:
                {
                  From: {
                    Year: MilitarChoice === true && MilitarDetails && MilitarDetails !== '' ? MilitarDetails.substring(0, 4) : '',
                    Month: MilitarChoice === true && MilitarDetails && MilitarDetails !== '' ? MilitarDetails.substring(5, 7) : ''
                  },
                  To: {
                    Year: MilitarChoice === true && Militar2Details && Militar2Details !== '' ? Militar2Details.substring(0, 4) : '',
                    Month: MilitarChoice === true && Militar2Details && Militar2Details !== '' ? Militar2Details.substring(5, 7) : ''
                  },
                  Location: MilitarChoice === true ? MilitarDetails2 : '',
                  Province: MilitarChoice === true ? MilitarDetails3 : '',
                  CountryCode: MilitarChoice === true ? MilitarDetails4 : ''
                },
                MilitaryServiceDetail2:
                {
                  From: {
                    Year: MilitarDetails5 && MilitarDetails5 !== '' ? MilitarDetails5.substring(0, 4) : '',
                    Month: MilitarDetails5 && MilitarDetails5 !== '' ? MilitarDetails5.substring(5, 7) : ''
                  },
                  To: {
                    Year: Militar2Details5 && Militar2Details5 !== '' ? Militar2Details5.substring(0, 4) : '',
                    Month: Militar2Details5 && Militar2Details5 !== '' ? Militar2Details5.substring(5, 7) : ''
                  },
                  Location: MilitarDetails6 !== '' ? MilitarDetails6 : '',
                  Province: MilitarDetails7 !== '' ? MilitarDetails7 : '',
                  CountryCode: MilitarDetails8 !== '' ? MilitarDetails8 : ''
                },
                MilitaryServiceDetail3:
                {
                  From: {
                    Year: MilitarDetails9 && MilitarDetails9 !== '' ? MilitarDetails9.substring(0, 4) : '',
                    Month: MilitarDetails9 && MilitarDetails9 !== '' ? MilitarDetails9.substring(5, 7) : ''
                  },
                  To: {
                    Year: Militar2Details9 && Militar2Details9 !== '' ? Militar2Details9.substring(0, 4) : '',
                    Month: Militar2Details9 && Militar2Details9 !== '' ? Militar2Details9.substring(5, 7) : ''
                  },
                  Location: MilitarDetails10 !== '' ? MilitarDetails10 : '',
                  Province: MilitarDetails11 !== '' ? MilitarDetails11 : '',
                  CountryCode: MilitarDetails12 !== '' ? MilitarDetails12 : ''
                },
                MilitaryServiceDetail4:
                {
                  From: {
                    Year: MilitarDetails13 && MilitarDetails13 !== '' ? MilitarDetails13.substring(0, 4) : '',
                    Month: MilitarDetails13 && MilitarDetails13 !== '' ? MilitarDetails13.substring(5, 7) : ''
                  },
                  To: {
                    Year: Militar2Details13 && Militar2Details13 !== '' ? Militar2Details13.substring(0, 4) : '',
                    Month: Militar2Details13 && Militar2Details13 !== '' ? Militar2Details13.substring(5, 7) : ''
                  },
                  Location: MilitarDetails14 !== '' ? MilitarDetails14 : '',
                  Province: MilitarDetails15 !== '' ? MilitarDetails15 : '',
                  CountryCode: MilitarDetails16 !== '' ? MilitarDetails16 : ''
                },
                MilitaryServiceDetail5:
                {
                  Add5Service: MilitarDetailsAdd5Militar,
                  From: {
                    Year: MilitarDetails17 && MilitarDetails17 !== '' ? MilitarDetails17.substring(0, 4) : '',
                    Month: MilitarDetails17 && MilitarDetails17 !== '' ? MilitarDetails17.substring(5, 7) : ''
                  },
                  To: {
                    Year: Militar2Details17 !== '' ? Militar2Details17.substring(0, 4) : '',
                    Month: Militar2Details17 !== '' ? Militar2Details17.substring(5, 7) : ''
                  },
                  Location: MilitarDetails18 !== '' ? MilitarDetails18 : '',
                  Province: MilitarDetails19 !== '' ? MilitarDetails19 : '',
                  CountryCode: MilitarDetails20 !== '' ? MilitarDetails20 : ''
                }
              }
            },
            WarHumanityCrimesInfo: {
              HaveWitnessedParticipated: OrganizationsAssociation,
              WarHumanityCrimesDetails: {
                WarHumanityCrimesDetail:
                {
                  From: {
                    Year: OrganizationsDetails && OrganizationsDetails !== '' ? OrganizationsDetails.substring(0, 4) : '',
                    Month: OrganizationsDetails && OrganizationsDetails !== '' ? OrganizationsDetails.substring(5, 7) : ''
                  },
                  To: {
                    Year: Organizations2Details && Organizations2Details !== '' ? Organizations2Details.substring(0, 4) : '',
                    Month: Organizations2Details && Organizations2Details !== '' ? Organizations2Details.substring(5, 7) : ''
                  },
                  Location: OrganizationsDetails2 !== '' ? OrganizationsDetails2 : '',
                  Province: OrganizationsDetails3 !== '' ? OrganizationsDetails3 : '',
                  CountryCode: OrganizationsDetails4 !== '' ? OrganizationsDetails4 : '',
                  Details: OrganizationsDetails5 !== '' ? OrganizationsDetails5 : ''
                },
                WarHumanityCrimesDetail2:
                {
                  From: {
                    Year: OrganizationsDetails6 && OrganizationsDetails6 !== '' ? OrganizationsDetails6.substring(0, 4) : '',
                    Month: OrganizationsDetails6 && OrganizationsDetails6 !== '' ? OrganizationsDetails6.substring(5, 7) : ''
                  },
                  To: {
                    Year: Organizations2Details6 && Organizations2Details6 !== '' ? Organizations2Details6.substring(0, 4) : '',
                    Month: Organizations2Details6 && Organizations2Details6 !== '' ? Organizations2Details6.substring(5, 7) : ''
                  },
                  Location: OrganizationsDetails7 !== '' ? OrganizationsDetails7 : '',
                  Province: OrganizationsDetails8 !== '' ? OrganizationsDetails8 : '',
                  CountryCode: OrganizationsDetails9 !== '' ? OrganizationsDetails9 : '',
                  Details: OrganizationsDetails10 !== '' ? OrganizationsDetails10 : ''
                },
                WarHumanityCrimesDetail3:
                {
                  From: {
                    Year: OrganizationsDetails11 && OrganizationsDetails11 !== '' ? OrganizationsDetails11.substring(0, 4) : '',
                    Month: OrganizationsDetails11 && OrganizationsDetails11 !== '' ? OrganizationsDetails11.substring(5, 7) : ''
                  },
                  To: {
                    Year: Organizations2Details11 && Organizations2Details11 !== '' ? Organizations2Details11.substring(0, 4) : '',
                    Month: Organizations2Details11 && Organizations2Details11 !== '' ? Organizations2Details11.substring(5, 7) : ''
                  },
                  Location: OrganizationsDetails12 !== '' ? OrganizationsDetails12 : '',
                  Province: OrganizationsDetails13 !== '' ? OrganizationsDetails13 : '',
                  CountryCode: OrganizationsDetails14 !== '' ? OrganizationsDetails14 : '',
                  Details: OrganizationsDetails15 !== '' ? OrganizationsDetails15 : ''
                }
              }
            },
            MembershipAssociationInfo: {
              BeenMemberAssociated: PoliticalAssociation,
              MembershipAssociationDetails: {
                MembershipAssociationDetail:
                {
                  From: {
                    Year: AsociationDetails && AsociationDetails !== '' ? AsociationDetails.substring(0, 4) : '',
                    Month: AsociationDetails && AsociationDetails !== '' ? AsociationDetails.substring(5, 7) : ''
                  },
                  To: {
                    Year: Asociation2Details && Asociation2Details !== '' ? Asociation2Details.substring(0, 4) : '',
                    Month: Asociation2Details && Asociation2Details !== '' ? Asociation2Details.substring(5, 7) : ''
                  },
                  NameOfOrganization: AsociationDetails2 !== '' ? AsociationDetails2 : '',
                  ActivitiesPositionHeld: AsociationDetails3 !== '' ? AsociationDetails3 : '',
                  Province: AsociationDetails4 !== '' ? AsociationDetails4 : '',
                  CountryCode: AsociationDetails5 !== '' ? AsociationDetails5 : ''
                },
                MembershipAssociationDetail2:
                {
                  From: {
                    Year: AsociationDetails6 && AsociationDetails6 !== '' ? AsociationDetails6.substring(0, 4) : '',
                    Month: AsociationDetails6 && AsociationDetails6 !== '' ? AsociationDetails6.substring(5, 7) : ''
                  },
                  To: {
                    Year: Asociation2Details6 && Asociation2Details6 !== '' ? Asociation2Details6.substring(0, 4) : '',
                    Month: Asociation2Details6 && Asociation2Details6 !== '' ? Asociation2Details6.substring(5, 7) : ''
                  },
                  NameOfOrganization: AsociationDetails7 !== '' ? AsociationDetails7 : '',
                  ActivitiesPositionHeld: AsociationDetails8 !== '' ? AsociationDetails8 : '',
                  Province: AsociationDetails9 !== '' ? AsociationDetails9 : '',
                  CountryCode: AsociationDetails10 !== '' ? AsociationDetails10 : ''
                },
                MembershipAssociationDetail3:
                {
                  From: {
                    Year: AsociationDetails11 && AsociationDetails11 !== '' ? AsociationDetails11.substring(0, 4) : '',
                    Month: AsociationDetails11 && AsociationDetails11 !== '' ? AsociationDetails11.substring(5, 7) : ''
                  },
                  To: {
                    Year: Asociation2Details11 && Asociation2Details11 !== '' ? Asociation2Details11.substring(0, 4) : '',
                    Month: Asociation2Details11 && Asociation2Details11 !== '' ? Asociation2Details11.substring(5, 7) : ''
                  },
                  NameOfOrganization: AsociationDetails12 !== '' ? AsociationDetails12 : '',
                  ActivitiesPositionHeld: AsociationDetails13 !== '' ? AsociationDetails13 : '',
                  Province: AsociationDetails14 !== '' ? AsociationDetails14 : '',
                  CountryCode: AsociationDetails15 !== '' ? AsociationDetails15 : ''
                },
                MembershipAssociationDetail4:
                {
                  From: {
                    Year: AsociationDetails16 && AsociationDetails16 !== '' ? AsociationDetails16.substring(0, 4) : '',
                    Month: AsociationDetails16 && AsociationDetails16 !== '' ? AsociationDetails16.substring(5, 7) : ''
                  },
                  To: {
                    Year: Asociation2Details16 && Asociation2Details16 !== '' ? Asociation2Details16.substring(0, 4) : '',
                    Month: Asociation2Details16 && Asociation2Details16 !== '' ? Asociation2Details16.substring(5, 7) : ''
                  },
                  NameOfOrganization: AsociationDetails17 !== '' ? AsociationDetails17 : '',
                  ActivitiesPositionHeld: AsociationDetails18 !== '' ? AsociationDetails18 : '',
                  Province: AsociationDetails19 !== '' ? AsociationDetails19 : '',
                  CountryCode: AsociationDetails20 !== '' ? AsociationDetails20 : ''
                }

              }
            },
            GovernmentPositionsInfo: {
              HeldGovernmentPositions: GovermentPositions,
              GovernmentPositionsDetails: {
                GovernmentPositionsDetail:
                {
                  From: {
                    Year: GovermentPositionsDetails && GovermentPositionsDetails !== '' ? GovermentPositionsDetails.substring(0, 4) : '',
                    Month: GovermentPositionsDetails && GovermentPositionsDetails !== '' ? GovermentPositionsDetails.substring(5, 7) : ''
                  },
                  To: {
                    Year: GovermentPositions2Details && GovermentPositions2Details !== '' ? GovermentPositions2Details.substring(0, 4) : '',
                    Month: GovermentPositions2Details && GovermentPositions2Details !== '' ? GovermentPositions2Details.substring(5, 7) : ''
                  },
                  CountryCode: GovermentPositionsDetails2 !== '' ? GovermentPositionsDetails2 : '',
                  LevelOfJurisdiction: GovermentPositionsDetails3 !== '' ? GovermentPositionsDetails3 : '',
                  DepartmentBranch: GovermentPositionsDetails4 !== '' ? GovermentPositionsDetails4 : '',
                  ActivitiesPositionHeld: GovermentPositionsDetails5 !== '' ? GovermentPositionsDetails5 : ''
                },
                GovernmentPositionsDetail2:
                {
                  From: {
                    Year: GovermentPositionsDetails6 && GovermentPositionsDetails6 !== '' ? GovermentPositionsDetails6.substring(0, 4) : '',
                    Month: GovermentPositionsDetails6 && GovermentPositionsDetails6 !== '' ? GovermentPositionsDetails6.substring(5, 7) : ''
                  },
                  To: {
                    Year: GovermentPositions2Details6 && GovermentPositions2Details6 !== '' ? GovermentPositions2Details6.substring(0, 4) : '',
                    Month: GovermentPositions2Details6 && GovermentPositions2Details6 !== '' ? GovermentPositions2Details6.substring(5, 7) : ''
                  },
                  CountryCode: GovermentPositionsDetails7 !== '' ? GovermentPositionsDetails7 : '',
                  LevelOfJurisdiction: GovermentPositionsDetails8 !== '' ? GovermentPositionsDetails8 : '',
                  DepartmentBranch: GovermentPositionsDetails9 !== '' ? GovermentPositionsDetails9 : '',
                  ActivitiesPositionHeld: GovermentPositionsDetails10 !== '' ? GovermentPositionsDetails10 : ''
                },
                GovernmentPositionsDetail3:
                {
                  From: {
                    Year: GovermentPositionsDetails11 && GovermentPositionsDetails11 !== '' ? GovermentPositionsDetails11.substring(0, 4) : '',
                    Month: GovermentPositionsDetails11 && GovermentPositionsDetails11 !== '' ? GovermentPositionsDetails11.substring(5, 7) : ''
                  },
                  To: {
                    Year: GovermentPositions2Details11 && GovermentPositions2Details11 !== '' ? GovermentPositions2Details11.substring(0, 4) : '',
                    Month: GovermentPositions2Details11 && GovermentPositions2Details11 !== '' ? GovermentPositions2Details11.substring(5, 7) : ''
                  },
                  CountryCode: GovermentPositionsDetails12 !== '' ? GovermentPositionsDetails12 : '',
                  LevelOfJurisdiction: GovermentPositionsDetails13 !== '' ? GovermentPositionsDetails13 : '',
                  DepartmentBranch: GovermentPositionsDetails14 !== '' ? GovermentPositionsDetails14 : '',
                  ActivitiesPositionHeld: GovermentPositionsDetails15 !== '' ? GovermentPositionsDetails15 : ''
                },
                GovernmentPositionsDetail4:
                {
                  From: {
                    Year: GovermentPositionsDetails16 && GovermentPositionsDetails16 !== '' ? GovermentPositionsDetails16.substring(0, 4) : '',
                    Month: GovermentPositionsDetails16 && GovermentPositionsDetails16 !== '' ? GovermentPositionsDetails16.substring(5, 7) : ''
                  },
                  To: {
                    Year: GovermentPositions2Details16 && GovermentPositions2Details16 !== '' ? GovermentPositions2Details16.substring(0, 4) : '',
                    Month: GovermentPositions2Details16 && GovermentPositions2Details16 !== '' ? GovermentPositions2Details16.substring(5, 7) : ''
                  },
                  CountryCode: GovermentPositionsDetails17 !== '' ? GovermentPositionsDetails17 : '',
                  LevelOfJurisdiction: GovermentPositionsDetails18 !== '' ? GovermentPositionsDetails18 : '',
                  DepartmentBranch: GovermentPositionsDetails19 !== '' ? GovermentPositionsDetails19 : '',
                  ActivitiesPositionHeld: GovermentPositionsDetails20 !== '' ? GovermentPositionsDetails20 : ''
                }
              }
            },
            PreviousTravelInfo: {
              TraveledOtherCountry: Travel,
              PreviousTravelDetails: {
                PreviousTravelDetail:
                {
                  From: {
                    Year: PreviousTravel && PreviousTravel !== '' ? PreviousTravel.substring(0, 4) : '',
                    Month: PreviousTravel && PreviousTravel !== '' ? PreviousTravel.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel && Previous2Travel !== '' ? Previous2Travel.substring(0, 4) : '',
                    Month: Previous2Travel && Previous2Travel !== '' ? Previous2Travel.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel2 !== '' ? PreviousTravel2 : '',
                  Location: PreviousTravel3 !== '' ? PreviousTravel3 : '',
                  PurposeOfTravel: PreviousTravel4 !== '' ? PreviousTravel4 : ''
                },
                PreviousTravelDetail2:
                {
                  From: {
                    Year: PreviousTravel5 && PreviousTravel5 !== '' ? PreviousTravel5.substring(0, 4) : '',
                    Month: PreviousTravel5 && PreviousTravel5 !== '' ? PreviousTravel5.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel5 && Previous2Travel5 !== '' ? Previous2Travel5.substring(0, 4) : '',
                    Month: Previous2Travel5 && Previous2Travel5 !== '' ? Previous2Travel5.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel6 !== '' ? PreviousTravel6 : '',
                  Location: PreviousTravel7 !== '' ? PreviousTravel7 : '',
                  PurposeOfTravel: PreviousTravel8 !== '' ? PreviousTravel8 : ''
                },
                PreviousTravelDetail3:
                {
                  From: {
                    Year: PreviousTravel9 && PreviousTravel9 !== '' ? PreviousTravel9.substring(0, 4) : '',
                    Month: PreviousTravel9 && PreviousTravel9 !== '' ? PreviousTravel9.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel9 && Previous2Travel9 !== '' ? Previous2Travel9.substring(0, 4) : '',
                    Month: Previous2Travel9 && Previous2Travel9 !== '' ? Previous2Travel9.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel11 !== '' ? PreviousTravel10 : '',
                  Location: PreviousTravel12 !== '' ? PreviousTravel11 : '',
                  PurposeOfTravel: PreviousTravel13 !== '' ? PreviousTravel12 : ''
                },
                PreviousTravelDetail4:
                {
                  From: {
                    Year: PreviousTravel13 && PreviousTravel13 !== '' ? PreviousTravel13.substring(0, 4) : '',
                    Month: PreviousTravel13 && PreviousTravel13 !== '' ? PreviousTravel13.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel13 && Previous2Travel13 !== '' ? Previous2Travel13.substring(0, 4) : '',
                    Month: Previous2Travel13 && Previous2Travel13 !== '' ? Previous2Travel13.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel14 !== '' ? PreviousTravel14 : '',
                  Location: PreviousTravel15 !== '' ? PreviousTravel15 : '',
                  PurposeOfTravel: PreviousTravel16 !== '' ? PreviousTravel16 : ''
                },
                PreviousTravelDetail5:
                {
                  AddTravel5: PreviousTravelAdd5 !== '' ? PreviousTravelAdd5 : '',
                  From: {
                    Year: PreviousTravel17 && PreviousTravel17 !== '' ? PreviousTravel17.substring(0, 4) : '',
                    Month: PreviousTravel17 && PreviousTravel17 !== '' ? PreviousTravel17.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel17 && Previous2Travel17 !== '' ? Previous2Travel17.substring(0, 4) : '',
                    Month: Previous2Travel17 && Previous2Travel17 !== '' ? Previous2Travel17.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel18 !== '' ? PreviousTravel18 : '',
                  Location: PreviousTravel19 !== '' ? PreviousTravel19 : '',
                  PurposeOfTravel: PreviousTravel20 !== '' ? PreviousTravel20 : ''
                },
                PreviousTravelDetail6:
                {
                  AddTravel6: PreviousTravelAdd6 !== '' ? PreviousTravelAdd6 : '',
                  From: {
                    Year: PreviousTravel21 && PreviousTravel21 !== '' ? PreviousTravel21.substring(0, 4) : '',
                    Month: PreviousTravel21 && PreviousTravel21 !== '' ? PreviousTravel21.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel21 && Previous2Travel21 !== '' ? Previous2Travel21.substring(0, 4) : '',
                    Month: Previous2Travel21 && Previous2Travel21 !== '' ? Previous2Travel21.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel22 !== '' ? PreviousTravel22 : '',
                  Location: PreviousTravel23 !== '' ? PreviousTravel23 : '',
                  PurposeOfTravel: PreviousTravel24 !== '' ? PreviousTravel24 : ''
                },
                PreviousTravelDetail7:
                {
                  AddTravel7: PreviousTravelAdd7 !== '' ? PreviousTravelAdd7 : '',
                  From: {
                    Year: PreviousTravel25 && PreviousTravel25 !== '' ? PreviousTravel25.substring(0, 4) : '',
                    Month: PreviousTravel25 && PreviousTravel25 !== '' ? PreviousTravel25.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel25 && Previous2Travel25 !== '' ? Previous2Travel25.substring(0, 4) : '',
                    Month: Previous2Travel25 && Previous2Travel25 !== '' ? Previous2Travel25.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel26 !== '' ? PreviousTravel26 : '',
                  Location: PreviousTravel27 !== '' ? PreviousTravel27 : '',
                  PurposeOfTravel: PreviousTravel28 !== '' ? PreviousTravel28 : ''
                },
                PreviousTravelDetail8:
                {
                  AddTravel8: PreviousTravelAdd8 !== '' ? PreviousTravelAdd8 : '',
                  From: {
                    Year: PreviousTravel29 && PreviousTravel29 !== '' ? PreviousTravel29.substring(0, 4) : '',
                    Month: PreviousTravel29 && PreviousTravel29 !== '' ? PreviousTravel29.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel29 && Previous2Travel29 !== '' ? Previous2Travel29.substring(0, 4) : '',
                    Month: Previous2Travel29 && Previous2Travel29 !== '' ? Previous2Travel29.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel30 !== '' ? PreviousTravel30 : '',
                  Location: PreviousTravel31 !== '' ? PreviousTravel31 : '',
                  PurposeOfTravel: PreviousTravel32 !== '' ? PreviousTravel32 : ''
                },
                PreviousTravelDetail9:
                {
                  AddTravel9: PreviousTravelAdd9 !== '' ? PreviousTravelAdd9 : '',
                  From: {
                    Year: PreviousTravel33 && PreviousTravel33 !== '' ? PreviousTravel33.substring(0, 4) : '',
                    Month: PreviousTravel33 && PreviousTravel33 !== '' ? PreviousTravel33.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel33 && Previous2Travel33 !== '' ? Previous2Travel33.substring(0, 4) : '',
                    Month: Previous2Travel33 && Previous2Travel33 !== '' ? Previous2Travel33.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel34 !== '' ? PreviousTravel34 : '',
                  Location: PreviousTravel35 !== '' ? PreviousTravel35 : '',
                  PurposeOfTravel: PreviousTravel36 !== '' ? PreviousTravel36 : ''
                },
                PreviousTravelDetail10:
                {
                  AddTravel10: PreviousTravelAdd10 !== '' ? PreviousTravelAdd10 : '',
                  From: {
                    Year: PreviousTravel37 && PreviousTravel37 !== '' ? PreviousTravel37.substring(0, 4) : '',
                    Month: PreviousTravel37 && PreviousTravel37 !== '' ? PreviousTravel37.substring(5, 7) : ''
                  },
                  To: {
                    Year: Previous2Travel37 && Previous2Travel37 !== '' ? Previous2Travel37.substring(0, 4) : '',
                    Month: Previous2Travel37 && Previous2Travel37 !== '' ? Previous2Travel37.substring(5, 7) : ''
                  },
                  CountryCode: PreviousTravel38 !== '' ? PreviousTravel38 : '',
                  Location: PreviousTravel39 !== '' ? PreviousTravel39 : '',
                  PurposeOfTravel: PreviousTravel40 !== '' ? PreviousTravel40 : ''
                },
              }
            }
          }
        }
        console.log(updatedFormData, 'datos del formulario');
        const xml = xmlbuilder.create(updatedFormData, { encoding: 'utf-8' }).end({ pretty: true });
        const file = new Blob([xml], { type: 'application/xml' });
        const formData = new FormData();
        formData.append('file', file, fileName);

        // Añadir datos adicionales al FormData como un objeto JSON
        const additionalData = {
          memberName: product.memberName,
          memberType: product.memberType,
          contracts_users_envelope_id: foundContract.contracts_users_envelope_id,
          contracts_users_user_id: foundContract.contracts_users_user_id,
          userId: userAUTH?.id
        };
        formData.append('additionalData', JSON.stringify(additionalData));

        try {
          const response = await axios.post('https://botimm.immiland.app/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('File uploaded successfully:', response.data);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      } else {
        console.log('No contract found with envelope id:', contractsFound);
      }
    } else {
      console.log('contractsData is undefined');
    }
  };

  useEffect(() => {
    setAnswers()
  }, [])

  return (
    <div>
      {(arrayValidator && arrayValidator?.length > 0) ?
        <div
          style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <button
            style={{
              height: '2.4rem',
              width: '2.9rem',
              padding: '0.3rem 0.5rem',
              borderRadius: '30px',
              fontWeight: 'bold',
              marginLeft: '1rem',
            }}
            className='button-hover-disable'>
            <img style={{ width: '24px' }} src={thunderbolt} alt="thunderblue" />
          </button>
          <div className='toast-fixed-style'>
            <div
              style={{ backgroundColor: '#FBBF24', padding: '3px', borderRadius: '50%' }}>
              <img src={thunderbolt} alt="checkmark_white" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ margin: '0', fontWeight: 'normal' }}>Se encontraron errores</p>
              <p
                style={{ margin: '0', color: '#0B76B7', fontWeight: 'bold' }}
                onClick={() => { toast(JSON.stringify(arrayValidator)) }}>Revisar lista de errores</p>
            </div>
          </div>
        </div> :
        <button
          className='button-ui button-tertiary'
          // style={{
          //   border: '2px solid #18BFFF',
          //   padding: '0.3rem 0.5rem',
          //   borderRadius: '30px',
          //   fontWeight: 'bold',
          //   marginLeft: '1rem',
          // }}
          onClick={handleUploadToServer}>
          <img style={{ width: '24px' }} src={thunderblue} alt="thunderblue" />
        </button>}
    </div>
  )
}

export default IMM5257B_CONYUGUE
