 
import {type ReactNode, type FC} from 'react'
import {IntlProvider} from 'react-intl'
import enMessages from './messages/en'
import esMessages from './messages/es'
import frMessages from './messages/fr'

let currentObject: object
let currentLang: string

const geti18nConfig = () => {
  let ls
  try {
    ls = localStorage.getItem('i18nConfig')
    return ls
  } catch (e) {
    // generate script to allow localstorage for this site in browser settings and reload page to continue with the process of the app or show a message to the user to allow localstorage for this site in browser settings
    window.parent.postMessage('allow_third_party_cookie', '*')

    alert(
      'Su navegador esat bloquenado las pagina, para continuar por favor permitalas para el sitio web'
    )
  }

  return null
}
const la = geti18nConfig()
if (la) {
  currentObject = JSON.parse(la)
  Object.entries(currentObject).find(([key, value]) => {
    if (key === 'selectedLang') {
      currentLang = value
    }
  })
}

const allMessages = () => {
  switch (currentLang) {
    case 'en':
      return enMessages
    case 'es':
      return esMessages
    case 'fr':
      return frMessages
    default:
      return esMessages
  }
}
interface ContentProps {
  children: ReactNode
}
const I18nProvider: FC<ContentProps> = ({children}) => {
  return (
    <IntlProvider locale='es' messages={allMessages()}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
