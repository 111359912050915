import React from 'react'

interface IProps {
  children: React.ReactNode
  title: string
  className?: string
}

const Container: React.FC<IProps> = ({children, title}) => {
  return (
    <>
      
      <div className='principal-continer '>
      <p className='step-header-title'>{title}</p>
        {children}
      </div>
    </>
  )
}

export {Container}
