/* eslint-disable @typescript-eslint/no-unused-vars */
 

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import * as Yup from 'yup'
import { Alert, Button, Input, Select, H3 } from '../../../../../../components'
import React, { type Dispatch, type SetStateAction, useState, useEffect, useContext } from 'react'
import { CountrySelect } from '../../../../utils/components/CountrySelect'
import { type ProfileImmigration } from '../../../../apps/profile/core/_models'
import { isEmpty } from 'lodash'
import { Progress } from 'antd'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import 'antd/dist/antd.css'
import './style.css'

import { updateProfileNew } from 'app/modules/apps/profile/core/_requests'
import Swal from 'sweetalert2'
import { RootStoreContext } from 'stores/rootStore'
import { Users } from 'app/modules/apps/users/core/_models'
const profileDetailsSchema = Yup.object().shape({
  numero_hijos: Yup.string().required('Children number is required'),
  estado_civil: Yup.string().required('Civil status is required'),
})

interface Props {
  updateProfile: (ProfileImmigration: ProfileImmigration) => void
  ProfileImmigration: ProfileImmigration
  isUserLoading?: boolean
  showAvatar: boolean
  edit?: boolean
  setTabActive?: Dispatch<SetStateAction<string>>
  /*  onComplete: (survey: any) => void */
  data: any
  userId: string | undefined
  setRefetchActivator: Dispatch<SetStateAction<boolean>>
  currentUser: Users
}

const InmiDetailsNew2: React.FC<Props> = ({
  edit = true,
  setTabActive,
  data,
  setRefetchActivator,
  userId,
  currentUser,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { selectUser } = rootStore.usersStore
  // Actualizar los valores iniciales del formulario cuando data.profile cambie
  useEffect(() => {
    if (!isEmpty(data.profile)) {
      setFormValues(data.profile)
    }
  }, [data.profile])
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const initialFormValues = {
    estado_civil: '',
    conyuge: '',
    edadconyuge: '',
    conyuge_pais_residencia: '',
    conyuge_grado_academico: '',
    conyuge_area_estudios: '',
    conyuge_proeficiencia_ingles: '',
    conyuge_proeficiencia_frances: '',
    conyuge_historial_laboral: '',
    edad_hijos: '',
    numero_hijos: '',
  }
  const [formValues, setFormValues] = useState<ProfileImmigration | null>(
    data.profile || initialFormValues
  )
  const formikSecond = useFormik<ProfileImmigration>({
    initialValues: isEmpty(formValues) ? initialFormValues : formValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async () => {
      setLoading(true)
      setLoading(false)
    },
  })

  const formData = {
    estado_civil: formikSecond.values.estadoCivil,
    conyuge: formikSecond.values.conyuge,
    edadconyuge: formikSecond.values.edadconyuge,
    conyuge_pais_residencia: formikSecond.values.conyuge_pais_residencia,
    conyuge_grado_academico: formikSecond.values.conyuge_grado_academico,
    conyuge_area_estudios: formikSecond.values.conyuge_area_estudios,
    conyuge_proeficiencia_ingles: formikSecond.values.conyuge_proeficiencia_ingles,
    conyuge_proeficiencia_frances: formikSecond.values.conyuge_proeficiencia_frances,
    conyuge_historial_laboral: formikSecond.values.conyuge_historial_laboral,
    numero_hijos: formikSecond.values.numero_hijos,
    edad_hijos: formikSecond.values.edad_hijos,
  }
  const [progreso, setProgreso] = useState(0.9494949)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    /* Datos familiares */
    formData.estado_civil = formikSecond.values.estadoCivil
    formData.conyuge = formikSecond.values.conyuge
    formData.edadconyuge = formikSecond.values.edadconyuge
    formData.conyuge_pais_residencia = formikSecond.values.conyuge_pais_residencia
    formData.conyuge_grado_academico = formikSecond.values.conyuge_grado_academico
    formData.conyuge_area_estudios = formikSecond.values.conyuge_area_estudios
    formData.conyuge_proeficiencia_ingles = formikSecond.values.conyuge_proeficiencia_ingles
    formData.conyuge_proeficiencia_frances = formikSecond.values.conyuge_proeficiencia_frances
    formData.conyuge_historial_laboral = formikSecond.values.conyuge_historial_laboral
    formData.numero_hijos = formikSecond.values.numero_hijos
    formData.edad_hijos = formikSecond.values.edad_hijos

    try {
      await formikSecond.validateForm()
      await formikSecond.handleSubmit()
      await profileDetailsSchema.validate(formikSecond.values, { abortEarly: false })
      await updateProfileNew({
        profile: formikSecond.values,
        userId: userId,
      })

      const nuevoProgreso = calcularProgreso(formikSecond.values)
      setProgreso(nuevoProgreso)
      setFormValues(formikSecond.values)
      Swal.fire({
        title: intl.formatMessage({
          id: 'UPDATE.SUCCESS',
        }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
      setRefetchActivator(true)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        console.error('Error de validación:', error.errors)
        Swal.fire({
          title: 'Error',
          text: 'No puede continuar porque hay campos vacíos en el formulario',
          icon: 'error',
          confirmButtonText: 'Continuar',
        })
      } else {
        console.error('Error al actualizar el perfil:', error)
      }
    }
  }

  useEffect(() => {
    const nuevoProgreso = calcularProgreso(formikSecond.values)
    setProgreso(nuevoProgreso)
  }, [formikSecond.values])

  const handleSelectUser = (user: Users) => {
    selectUser(user)
  }

  const calcularProgreso = (values: any) => {
    const preguntas = [
      'numero_hijos',
      'estado_civil',
    ]

    const preguntasRespondidas = preguntas.filter((pregunta) => values[pregunta] !== '').length

    const nuevoProgreso = (preguntasRespondidas / preguntas.length) * 100

    return nuevoProgreso
  }
  const progresoEntero = Math.floor(progreso)
  return (
    <div>
      <div id='kt_account_profile_details'>
        {/* <p
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '1rem',
            textAlign: 'center',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          Por favor llene los campos correspondientes para poder comenzar su proceso migratorio
        </p>
        <div className='progress-bar-container' style={{ padding: '0rem 5rem 0rem 5rem' }}>
          <Progress
            percent={progresoEntero} // Usar el valor entero
            status={progresoEntero === 100 ? 'success' : 'active'}
            showInfo={true}
          />
        </div> */}
        <form
          onSubmit={handleSubmit}
          noValidate
          className='form'
          style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}
        >
          <div className='card-body p-9 bg-white container-form-modal flex-column'>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: '1px solid black', borderRadius: '10px', paddingTop: '1rem', marginBottom: '1rem' }}>
            <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
              DATOS GENERALES
            </H3>
              <div className='border-black column mb-6'>

                <H3 className='container-question col-lg-8 col-form-label required fw-bold fs-6'>
                  Desea emigrar con su familia?
                </H3>
                <div className='col-lg-8 fv-row'>
                  <Select
                    className='custom-input-select'
                    {...formikSecond.getFieldProps('emigrar_con_familia')}
                    value={formikSecond.values.emigrar_con_familia}
                  >
                    <option value=''>Seleccione...</option>
                    <option value='Si'>Sí</option>
                    <option value='No'>No</option>
                  </Select>
                  {formikSecond.touched.emigrar_con_familia &&
                    formikSecond.errors.emigrar_con_familia && (
                      <Alert>{formikSecond.errors.emigrar_con_familia}</Alert>
                    )}
                </div>
              </div>
              {formikSecond.values.emigrar_con_familia === 'Si' && (
                <div className='container-question column mb-6'>
                  <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({ id: 'PROFILE.CIVILSTATUS' })}
                  </H3>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='custom-input-select'
                      {...formikSecond.getFieldProps('estado_civil')}
                      value={formikSecond.values.estado_civil}
                    >
                      <option value=''>{intl.formatMessage({ id: 'PROFILE.CIVILSTATUS' })}...</option>
                      <option value='Soltero'>Soltero</option>
                      <option value='Casado'>Casado</option>
                      <option value='Union_libre'>Unión libre</option>
                      <option value='Divorciado'>Divorciado</option>
                    </Select>
                    {formikSecond.touched.estado_civil && formikSecond.errors.estado_civil && (
                      <Alert>{formikSecond.errors.estado_civil}</Alert>
                    )}
                  </div>
                </div>
              )}
            </div>
            {formikSecond.values.estado_civil === 'Casado' ||
              formikSecond.values.estado_civil === 'Union_libre' ? (
              <div>
                <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                  DATOS DEL CONYUGE
                </H3>
                <div className='border-black' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: '1px solid black', borderRadius: '10px', paddingTop: '1rem', marginBottom: '1rem' }}>
                  <div className='column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.CONYUGE' })}
                    </H3>

                    <div className='container-question col-lg-8 fv-row'>
                      <Input
                        className='custom-input-select'
                        type='text'
                        placeholder={intl.formatMessage({ id: 'PROFILE.CONYUGE' })}
                        {...formikSecond.getFieldProps('conyuge')}
                        value={formikSecond.values.conyuge}
                      />
                      {formikSecond.touched.conyuge && formikSecond.errors.conyuge && (
                        <Alert variant='danger'>{formikSecond.errors.conyuge}</Alert>
                      )}
                    </div>
                  </div>
                  <div className='column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.CONYUGEAGE' })}
                    </H3>

                    <div className='container-question col-lg-8 fv-row'>
                      <Input
                        className='custom-input-select'
                        type='text'
                        placeholder={intl.formatMessage({ id: 'PROFILE.CONYUGEAGE' })}
                        {...formikSecond.getFieldProps('edadconyuge')}
                        value={formikSecond.values.edadconyuge}
                      />
                      {formikSecond.touched.edadconyuge && formikSecond.errors.edadconyuge && (
                        <Alert variant='danger'>{formikSecond.errors.edadconyuge}</Alert>
                      )}
                    </div>
                  </div>
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-12 col-form-label fw-bold fs-6'>
                      <span className='required'>
                        {intl.formatMessage({ id: 'PROFILE.CONYUGECOUNTRY' })}
                      </span>
                    </H3>

                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input-select'
                        {...formikSecond.getFieldProps('conyuge_pais_residencia')}
                        value={formikSecond.values.conyuge_pais_residencia}
                      >

                        <CountrySelect />
                      </Select>
                      {formikSecond.touched.conyuge_pais_residencia &&
                        formikSecond.errors.conyuge_pais_residencia && (
                          <Alert>{formikSecond.errors.conyuge_pais_residencia}</Alert>
                        )}
                    </div>
                  </div>
                  {/* <div className='column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.ACADEMIC' })}
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input-select'
                        {...formikSecond.getFieldProps('conyuge_grado_academico')}
                        value={formikSecond.values.conyuge_grado_academico}
                      >
                        <option value=''>
                          {intl.formatMessage({ id: 'PROFILE.ACADEMICDEGREE' })}...
                        </option>
                        <option value='Elementaria'>Elementaria</option>
                        <option value='Secundaria'>Secundaria</option>
                        <option value='Post_secundaria'>Post secundaria (nivel técnico)</option>
                        <option value='Post_secundaria'>
                          Post secundaria (nivel universitario)
                        </option>
                        <option value='Postgrado'>
                          Postgrado (certificación o diploma postgrado)
                        </option>
                        <option value='Doctorado'>Maestría</option>
                        <option value='Doctorado'>Doctorado</option>
                      </Select>
                      {formikSecond.touched.conyuge_grado_academico &&
                        formikSecond.errors.conyuge_grado_academico && (
                          <Alert>{formikSecond.errors.conyuge_grado_academico}</Alert>
                        )}
                    </div>
                  </div> */}
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-4 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.CONYUGESTUDY' })}
                    </H3>

                    <div className='col-lg-8 fv-row'>
                      <Input
                        className='custom-input-form'
                        type='text'
                        placeholder={intl.formatMessage({ id: 'PROFILE.CONYUGESTUDY' })}
                        {...formikSecond.getFieldProps('conyuge_area_estudios')}
                        value={formikSecond.values.conyuge_area_estudios}
                      />
                      {formikSecond.touched.conyuge_area_estudios &&
                        formikSecond.errors.conyuge_area_estudios && (
                          <Alert variant='danger'>
                            {formikSecond.errors.conyuge_area_estudios}
                          </Alert>
                        )}
                    </div>
                  </div>
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.ACADEMIC' })}
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input-select'
                        {...formikSecond.getFieldProps('grado_academico')}
                        value={formikSecond.values.grado_academico || ''}
                      >
                        <option value=''>
                          {intl.formatMessage({ id: 'PROFILE.ACADEMICDEGREE' })}...
                        </option>
                        <option value='Elementaria'>Elementaria</option>
                        <option value='Secundaria'>Secundaria</option>
                        <option value='Post_secundaria'>Post secundaria (nivel técnico)</option>
                        <option value='Post_secundaria_univ'>
                          Post secundaria (nivel universitario)
                        </option>
                        <option value='Postgrado'>Postgrado (certificación o diploma postgrado)</option>
                        <option value='Maestría'>Maestría</option>
                        <option value='Doctorado'>Doctorado</option>
                      </Select>
                      {formikSecond.touched.grado_academico && formikSecond.errors.grado_academico && (
                        <Alert>{formikSecond.errors.grado_academico}</Alert>
                      )}
                    </div>
                  </div>
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.ENGLISHPROF' })}
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input-form'
                        {...formikSecond.getFieldProps('conyuge_proeficiencia_ingles')}
                        value={formikSecond.values.conyuge_proeficiencia_ingles}
                      >
                        <option value=''>
                          {intl.formatMessage({ id: 'PROFILE.ENGLISHPROF' })}...
                        </option>
                        <option value='Nulo'>Nulo</option>
                        <option value='Basico'>Basico</option>
                        <option value='Intermedio'>Intermedio</option>
                        <option value='Avanzado'>Avanzado</option>
                      </Select>
                      {formikSecond.touched.conyuge_proeficiencia_ingles &&
                        formikSecond.errors.conyuge_proeficiencia_ingles && (
                          <Alert>{formikSecond.errors.conyuge_proeficiencia_ingles}</Alert>
                        )}
                    </div>
                  </div>
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.FRENCHPROF' })}
                    </H3>
                    <div className='col-lg-8 fv-row'>
                      <Select
                        className='custom-input-form'
                        {...formikSecond.getFieldProps('conyuge_proeficiencia_frances')}
                        value={formikSecond.values.conyuge_proeficiencia_frances}
                      >
                        <option value=''>
                          {intl.formatMessage({ id: 'PROFILE.FRENCHPROF' })}...
                        </option>
                        <option value='Nulo'>Nulo</option>
                        <option value='Basico'>Basico</option>
                        <option value='Intermedio'>Intermedio</option>
                        <option value='Avanzado'>Avanzado</option>
                      </Select>
                      {formikSecond.touched.conyuge_proeficiencia_frances &&
                        formikSecond.errors.conyuge_proeficiencia_frances && (
                          <Alert>{formikSecond.errors.conyuge_proeficiencia_frances}</Alert>
                        )}
                    </div>
                  </div>
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-12 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.LABOURHISTORY' })}
                    </H3>

                    <div className='col-lg-8 fv-row'>
                      <textarea
                        className='custom-input-form'
                        style={{height:'8rem'}}
                        placeholder={intl.formatMessage({ id: 'PROFILE.LABOURHISTORY' })}
                        {...formikSecond.getFieldProps('conyuge_historial_laboral')}
                        value={formikSecond.values.conyuge_historial_laboral}
                      ></textarea>
                      {formikSecond.touched.conyuge_historial_laboral &&
                        formikSecond.errors.conyuge_historial_laboral && (
                          <Alert variant='danger'>
                            {formikSecond.errors.conyuge_historial_laboral}
                          </Alert>
                        )}
                    </div>

                  </div>
                </div>
              </div>) : null}

            {formikSecond.values.emigrar_con_familia === 'Si' &&
              <div>
                <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                  DATOS DE LOS HIJOS
                </H3>
                <div className='border-black' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: '1px solid black', borderRadius: '10px', paddingTop: '1rem', marginBottom: '1rem' }}>
                  <div className='container-question column mb-6'>
                    <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({ id: 'PROFILE.CHILDREN' })}
                    </H3>

                    <div className='col-lg-8 fv-row'>
                      <Input
                        className='custom-input-form'
                        type='text'
                        placeholder={intl.formatMessage({ id: 'PROFILE.CHILDREN' })}
                        {...formikSecond.getFieldProps('numero_hijos')}
                        value={formikSecond.values.numero_hijos}
                      />
                      {formikSecond.touched.numero_hijos && formikSecond.errors.numero_hijos && (
                        <Alert variant='danger'>{formikSecond.errors.numero_hijos}</Alert>
                      )}
                    </div>
                  </div>
                  {Number(formikSecond.values.numero_hijos) > 0 && (
                    <div className='container-question column mb-6'>
                      <H3 className='col-lg-8 col-form-label required fw-bold fs-6'>
                        Edad de sus hijos (ej: 18,20)
                      </H3>

                      <div className='col-lg-8 fv-row'>
                        <Input
                          className='custom-input-form'
                          type='text'
                          placeholder='Digite la edad de sus hijos'
                          {...formikSecond.getFieldProps('edad_hijos')}
                          value={formikSecond.values.edad_hijos}
                        />
                        {/* {formikSecond.touched.numero_hijos && formikSecond.errors.numero_hijos && (
                <Alert variant='danger'>{formikSecond.errors.numero_hijos}</Alert>
              )} / */}
                      </div>
                    </div>
                  )}
                </div>
              </div>}
            {edit && (
              <div className='container-buttons mb-8'>
                <Button
                  className='button-ui button-primary'
                  type='submit'
                  onClick={() => {
                    handleSelectUser(currentUser)
                    isEmpty(formikSecond.errors) && setTabActive != null
                      ? setTabActive('homenew')
                      : null
                  }}
                  
                >
                  Guardar datos
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default observer(InmiDetailsNew2)
