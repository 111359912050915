/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
 

import {Button, Form, Input, Select, Upload} from 'antd'
import {InboxOutlined} from '@ant-design/icons'
import React from 'react'
import {useIntl} from 'react-intl'
import {getImmigrationServices} from 'app/modules/apps/immigration/services/core/_requests'
import {useQuery} from 'react-query'
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }

  return e && e.fileList
}

export interface ISurveyProps {
  onComplete: (survey: any) => void
}

const UploadFile: React.FunctionComponent<ISurveyProps> = (props) => {
  const intl = useIntl()
  const {onComplete} = props
  const {data: ImmigrationServices} = useQuery(
    'ListAllImmigraaationServicesSelect',
    async () => await getImmigrationServices({})
  )
  const actionUpload = (values: any) => {
    // comment to deactivate eslint
  }
  const provinces = [
    {
      name: 'Alberta',
    },
    {
      name: 'Atlantic',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Federal',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
  ]
  return (
    <Form
      name='validate_other'
      {...formItemLayout}
      onFinish={onComplete}
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
    >
      <Form.Item
        name='fileName'
        label={intl.formatMessage({id: 'FILEMANAGER.FILENAME'})}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={intl.formatMessage({id: 'FILEMANAGER.DRAGGER'})}>
        <Form.Item name='dragger' valuePropName='fileList' getValueFromEvent={normFile} noStyle>
          <Upload.Dragger
            name='files'
            maxCount={1}
            customRequest={(f) => {
              actionUpload(f)
            }}
            beforeUpload={() => false}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>Support for a single or bulk upload.</p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <Form.Item
        name='road'
        label='Via'
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          style={{width: 120}}
          onChange={(e) => {}}
          options={
            ImmigrationServices
              ? ImmigrationServices.map((road: any) => ({
                  value: road.name,
                  label: road.name,
                }))
              : []
          }
        />
      </Form.Item>

      <Form.Item name='province' label='provincia' rules={[{}]}>
        <Select
          style={{width: 120}}
          onChange={(e) => {}}
          options={
            provinces
              ? provinces.map((province: any) => ({
                  value: province.name,
                  label: province.name,
                }))
              : []
          }
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          style={{background: '#80706b', borderColor: '#80706b'}}
        >
          {intl.formatMessage({id: 'FILEMANAGER.SUBMIT'})}
        </Button>
      </Form.Item>
    </Form>
  )
}

export {UploadFile}
