import tw from 'tailwind-styled-components'

const TableCell = tw.td`
px-3
py-3
border-gray-300
color: black !important;

`

export {TableCell}
