import {Container} from '../../../components/Container/index'
import {type FC} from 'react'
import UserTranslation from './user/UserTranslation'
import {useIntl} from 'react-intl'

const TranslationsWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <Container title={intl.formatMessage({id: 'MENU.TRANSLATION'})}>
        <UserTranslation />
      </Container>
    </>
  )
}

export {TranslationsWrapper}
