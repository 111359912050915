/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  IApointments,
  type AppointmentsAdmin,
  type IComposeData,
  ResponseApointment,
} from '../../../../app/modules/apps/appointments/core/_models'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import MenuOptions from '../MenuOptions'
import { RootStoreContext } from '../../../../stores/rootStore'
import { Button, DatePicker, Input, InputRef, Space, Table, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import Column from 'antd/lib/table/Column'
import { ColumnType } from 'antd/lib/table'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { ColumnFilterItem, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { getAllStaff } from 'app/modules/apps/staff/core/_requests'
import { useQuery } from 'react-query'
import '../../admin/users/List/style.css'
import { RangeValue } from 'rc-picker/lib/interface'
import image from './image/avatar.svg'
import { Link } from 'react-router-dom'
import { getUserNewQueryDashboard,  getUserUsersById} from 'app/modules/apps/users/core/_requests'
import notess from './image/notes.svg'
import NotesModalDate from 'app/pages/admin/users/List/SlideDate'
import { Users } from 'app/modules/apps/users/core/_models'
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
  appointments?: ResponseApointment
  setComposePost: (b: boolean) => void
  setComposePostData: (data: IComposeData) => void
  setComposeNote: (b: boolean) => void
  zone: string
  setQuery: (query: IApointments) => void
  query: IApointments
}
interface ButtonHoverStates {
  [buttonId: string]: boolean;
}
const AppointmentDetails: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const [currentEmail, setCurrentEmail] = useState('')
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [staffNameInfo, setStaffNameInfo] = useState<ColumnFilterItem[]>()
  const [productNameInfo, setProductNameInfo] = useState<ColumnFilterItem[]>()
  const { appointments, zone, setQuery, query } =
    props
  const [currentPage, setCurrentPage] = useState(1)
  const [currentStaffName, setCurrentStaffName] = useState([])
  const [currentDescription, setCurrentDescription] = useState([])
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  const [buttonHoverStates, setButtonHoverStates] = useState<ButtonHoverStates>({});
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState<any>()
  const { selectUser } = rootStore.usersStore
  const [todayAppointmentsCount, setTodayAppointmentsCount] = useState(0);

  useEffect(() => {
    const todayAppointments = appointments?.data?.filter((appointment) => {
      if (appointment.start_time && typeof appointment.start_time === 'string') {
        const isToday = DateTime.fromISO(appointment.start_time).hasSame(DateTime.local(), 'day');
        return isToday;
      }
      return false;
    });

    setTodayAppointmentsCount(todayAppointments?.length || 0);
  }, [appointments]);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCreate = () => {
    setIsModalVisible(false)
  }
  const selectedUser = async (userId: string) => {
    const user = await getUserUsersById(userId)
    if (user) selectUser(user)
  }


  const handleHoverChange = (buttonId: string, isHovered: boolean) => {
    setButtonHoverStates((prevStates: any) => ({
      ...prevStates,
      [buttonId]: isHovered,
    }));
  };
  
  const [users, setUsers] = useState<any>()
  const [isFetching, setIsFetching] = useState<any>()
  const [wordSearched, setWordSearched] = useState('')
  const [showList, setShowList] = useState(true)
  const {data: StaffData} = useQuery('getStf', async () => await getAllStaff())

  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, {zone}).toFormat('yyyy-MM-dd HH:mm a')
    return keepOffset.toString()
  }
  const handleClearSearch = () => {
    setWordSearched('');
    setShowList(false);

    setCurrentEmail('');
    setCurrentStaffName([]);
    setCurrentDescription([]);
    const params: IApointments = {
      ...query,
     email: '',
    }
    setQuery(params)
  };
  const handleOpenMeet = (value: any) => {
    window.open(value, '_blank')
  }

  const handleSearchCustom = (type: string, value: string) => {
    if (type === 'email') {
      setCurrentEmail(value)
      try {
        const params: IApointments = {
          ...query,
          email: value,
          startDate: '',
          endDate: '',
        }
        setFromDate(null)
        setToDate(null)
        setQuery(params)
      } catch (error) { }
    }
  }

  const onClickUser = (user: any) => {
    try {
      const params: IApointments = {
        ...query,
        email: user.email,
        startDate: '',
        endDate: '',
      }

      setQuery(params)
      setShowList(false)
    } catch (error) { }
  }

  const changeInputUser = async (search: string) => {
    const trimmedSearch = search.trim()
    setShowList(true)
    setIsFetching(true)
    setWordSearched(trimmedSearch)
    if (search) {
      try {
        const result = await getUserNewQueryDashboard({ email: trimmedSearch })
        setUsers(result)
        setIsFetching(false)
      } catch (e) {
        setIsFetching(false)
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {

  }, [wordSearched])


  useEffect(() => {
    if (staffNameInfo === undefined && StaffData && StaffData.length > 0) {
      setStaffNameInfo(
        StaffData?.map((item: any) => {
          if (
            item.jotformName !== undefined &&
            (item.type === 'immigration_admin' ||
              item.type === 'consultant' ||
              item.type === 'education')
          ) {
            return {
              text: item.jotformName,
              value: item.jotformName,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }

    if (productNameInfo === undefined && appointments && appointments.data && appointments.data.length > 0) {
      setProductNameInfo(
        appointments?.data?.map((item) => {
          if (item.name !== undefined && item.name !== null) {
            return {
              text: item.name,
              value: item.name,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }
  }, [appointments, StaffData])
  useEffect(() => {
    try {
      const params: IApointments = {
        ...query,
        page: currentPage,
        consultantName: currentStaffName,
        description: currentDescription,
      }

      setQuery(params)
    } catch (error) { }
  }, [currentDescription, currentStaffName, currentPage])

  const searchInput = useRef<InputRef>(null)

  type DataIndex = keyof AppointmentsAdmin
  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<AppointmentsAdmin> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              selectedKeys[0] = ''
              setCurrentEmail('')
              handleSearchCustom('email', '')
            }}
            size='small'
            style={{ width: 90 }}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record: any) =>
      record.email
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const onChange = (
    pagination: any,
    filters: any,
 
  ) => {
    setCurrentPage(pagination.current)
    if (filters.staffName !== null) {
      setCurrentStaffName(filters.staffName)
    }
    if (filters.staffName === null) {
      setCurrentStaffName([])
    }
    if (filters.description !== null) {
      setCurrentDescription(filters.description)
    }

    if (filters.description === null) {
      setCurrentDescription([])
    }
  }
  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: IApointments = {
          ...query,
          startDate: values[0].format('DD-MM-YYYY'),
          endDate: values[1].format('DD-MM-YYYY'),
          email: '',
        }

        setQuery(params)
      } else {
        const params: IApointments = {
          ...query,
          startDate: '',
          endDate: '',
        }
        setQuery(params)
      }
    } catch (error) { }
  }
  const handleDateChange = (dates: RangeValue<moment.Moment>, ) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }

  const handleSearchDateChange = (date: string) => {
    if (date === "all") {
      const params: IApointments = {
        ...query,
        startDate: getFormattedDate(),
        endDate: ""
      }
      setQuery(params)
    } else if (date === "today") {
      const params: IApointments = {
        ...query,
        startDate: getFormattedDate(),
        endDate: getFormattedDate(),
      }
      setQuery(params)
    }
  }
  const getFormattedDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return dd + '-' + mm + '-' + yyyy;
  };
  return (
    <>
      {currentDescription.length > 0 || currentStaffName.length > 0 ? (
        <>
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentDescription.length > 0 && (
              <div style={{ display: 'flex', marginBottom: '0.002rem' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentDescription([])
                  }}
                >
                  <p>Descripcion</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentDescription.toString()}
                  readOnly
                ></input>
              </div>
            )}

            {currentStaffName.length > 0 && (
              <div style={{ display: 'flex', marginBottom: '0.002rem' }}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentStaffName([])
                  }}
                >
                  <p>Nombre Staff</p> <p>X</p>
                </Button>
                <input
                  title='nationality'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentStaffName.toString()}
                  readOnly
                ></input>
              </div>
            )}
          </div>
        </>
      ) : null}
      <div style={{display:'flex', justifyContent:'space-between' ,gap:'10px', alignItems:'center', marginRight:'1rem'}}>
        <div style={{display:'flex', justifyContent:'flex-start', width:'100%', maxWidth:'31rem'}}><input
  style={{
    width: '380px',
    height: '48px',
    border: '1px solid #637381',
    color: '#637381',
    marginLeft: '1rem',
  }}
  type="text"
  placeholder="Ingresa el nombre de un cliente"
  className='input-styles'
  value={wordSearched}
  onChange={(e) => {
    changeInputUser(e.target.value);
  }}
/>
{wordSearched && (
  <button
    className='clear-search-button'
    onClick={handleClearSearch}
  >

   <div style={{marginLeft:'1rem'}}> <DeleteOutlined style={{ color: 'black', width:'20px', height:'20px' }} /></div>
  </button>
)}
            {isFetching === true && wordSearched ?
          <div className='results-list3'>
            Cargando..
          </div>
          : <>
            {wordSearched && showList && (
              <div className='results-list3'>
                {users?.data?.map((user: Users) => (
                  <div
                    onClick={() => {
                      onClickUser(user)
                    }}
                    className='search-result' /* key={user.id}  value={user.id}  */
                  >
                    {user.email + "--" + user.firstName + " " + user.lastName}
                  </div>
                ))}
              </div>
            )} </>}</div>
       <div className='button-container-dates' style={{display:'flex', gap:'20px'}}> 
      <button  onClick={()=>{handleSearchDateChange("all")}} style={{padding:'8px 20px', display:'flex', justifyContent:'center', alignItems:'center', border:'2px solid #1F2A37', borderRadius:'45px', color:'#111928'}}>VER TODOS</button>
      <button onClick={() => { handleSearchDateChange("today") }} style={{ padding: '8px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #8899A8', borderRadius: '45px', color: '#6B7280', gap:'10px' }}>
    Citas de hoy <div style={{backgroundColor:'#F59E0B', borderRadius:'50px', width:'24px', height:'24px', color:'white'}}>{todayAppointmentsCount}</div>
  </button></div></div> 
      <Table
        className='table-master my-custom-table2'
        style={{ position: 'relative', height: '100%', overflow: 'auto' }}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: Number(appointments?.total) || 0,
        }}
        dataSource={appointments?.data}
        rowKey='id'
        onChange={onChange}
      >
        <Column
          width={70}
          key='link'
          title={<div style={{ textAlign: 'center' }}>LINK</div>}
          render={(record: any) => (
            <Space size='middle' style={{ paddingLeft: 10 }}>
              <Tooltip placement='top' title={'Google meet'}>
                <button
                  className='buttonmeet'

                  onClick={() => {
                    handleOpenMeet(`${record.join_url}`)
                  }}
                  onMouseEnter={() => handleHoverChange(record.id, true)}
                  onMouseLeave={() => handleHoverChange(record.id, false)}
                >
                  <div className='div-meet-button'>
                    <img
                      alt="Calendar"
                      className='meet-image'
                      src={buttonHoverStates[record.id] ? '/media/icons/app/menu/google-meet.svg' : '/media/icons/app/menu/google-meet-disable.svg'}
                    />
                    <b className='meet-buttontext'>Unirme</b>
                  </div>
                </button>
              </Tooltip>
            </Space>
          )}
        />
        <Column
          width={60}
          className='image-avatar'
          key='Avatars'
          render={(record: AppointmentsAdmin) => (
            <>
              {record.avatarID !== null ? <img style={{ borderRadius: '999px', width: '45px', height: '45px', marginLeft: '10px' }} src={`//immilandpublic.nyc3.digitaloceanspaces.com/${record?.avatarID}-avatar-large.jpg`} /> : <img style={{ borderRadius: '999px', width: '45px', height: '45px', marginLeft: '10px' }} src={image} />}
            </>
          )}
        />

        <Column
          width={340}
          title='CLIENTE'
          dataIndex={'email'}
          key='email'
          {...getColumnSearchPropsEmail('email')}
          render={(email: string, record: any) =>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
              <b style={{ margin: '0', height: '1.5rem' }}>{record.first_name?.length > 32 ?
                <>{record.first_name.substring(0, 32)}...</> : <>{record.first_name}</>}
              </b>
              <p style={{ margin: '0' }}>{email?.length > 32 ?
                <>{email.substring(0, 32)}...</>
                : <>{email}</>}
              </p>
            </div>} />

        <Column
          width={250}
          key='start_time'
          // dataIndex={'start_time'}
          render={(record: AppointmentsAdmin) => (
            <Space>{record.start_time?.toString().substring(0, 10)}</Space>
          )}
          title={'FECHA CITA'}
          filterDropdown={({ confirm }) => (
            <div style={{ padding: 8 }}>
              <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleDate([fromDate, toDate])
                  }}
                  size='small'
                  style={{ width: 90, marginRight: 8 }}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDate(null)
                    setToDate(null)
                    handleDate(['', ''])
                  }}
                  size='small'
                  style={{ width: 90 }}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
        />
     <Column
      width={120}
      title='HORA CITA'
      render={(record) => (
        <Space>{getDate(record.start_time).substring(11, 16)}</Space>
      )}
    />
    <Column
  width={220}
  key='description'
  title={'DESCRIPCIÓN/SERVICIO'}
  render={(record: AppointmentsAdmin) => (
    <Space style={{marginLeft:'20px'}}>
      {record.description && (
        <b dangerouslySetInnerHTML={{ __html: record.description }} />
      )}
    </Space>
  )}
  filters={productNameInfo?.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
  )}
  onFilter={(value, record: any) => {
    return record.name === value.toString();
  }}
  filteredValue={currentDescription}
/>

      {/*   <Column
          width={150}
          key='staffName'
          title={'Nombre del staff'}
          render={(record: any) => <>{record.consultant}</>}
          filters={staffNameInfo?.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
          )}
          onFilter={(value, record: any) => {
            return record.consultant === value.toString()
          }}
          filteredValue={currentStaffName}
        /> */}
  <Column
   width={110}
          render={(record: AppointmentsAdmin) => (
            <div style={{ display: 'flex', alignItems: 'center', width:'8rem' }}>
            <Link style={{marginBottom:'30px'}} className='link2' onClick={() => { selectedUser(record.user_id) }} to='/userslist'>
              <b style={{color:'#01A9DB'}} >Ver perfil</b>
              {/* Add the image with the specified src attribute */}
              <img src="/media/icons/app/menu/user-icon.svg" alt="User Icon" style={{ marginLeft: '5px' }} />
            </Link>
            </div>
          )}
        />

        <Column
           width={80}
           title={
            <span style={{ marginLeft: '50px', display: 'inline-block' }}>
              {'ACCIONES'}
            </span>
          }
          render={(record: AppointmentsAdmin) => (
            <Space
              size='middle'
              style={{
                height: 'fit-content',
                padding: '17px',
              }}
            >
              <MenuOptions appointment={record} />
            </Space>
          )}
        />
        <Column
          width={50}
          title=''
          render={(record: any) => (
            <button
            style={{marginBottom:'20px'}}
              onClick={(event) => {
                event.stopPropagation(); 
                setIsModalVisible(true);
                setUserInfo(JSON.parse(JSON.stringify(record)));
                console.log('este es user info' + JSON.stringify(record));
                console.log(record);
              }}
            >
              <img src={notess} alt="Notas" />
            </button>
          )}
        />
      </Table>
      {isModalVisible === true ? <NotesModalDate
        userInfo={userInfo}
        visible={isModalVisible}
        onCancel={handleCancel}
        onCreate={handleCreate}
        products={[]}
      /> : null}
    </>
  )
}

export { AppointmentDetails }
