/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Link } from 'react-router-dom'
import { getMyMessages, updateMessagesState } from 'app/modules/apps/messages/core/_requests'
import { useQuery } from 'react-query'
import MessageContent from 'app/pages/messages/MessageContent'
import ShowMessages from 'app/pages/messages/UserMessages/ShowMessages'
import { IReply, initialReplyData } from 'app/modules/apps/appointments/core/_models'
import { observer } from 'mobx-react'
import Speech from '../userDasboardComponents/userDashboardIcons/speech-bubble-19.svg'




const MessagesDashboard: React.FC = observer(({ }) => {
  const [countNotRead, setCountNotRead] = useState(0);
  const rootStore = useContext(RootStoreContext)
  const { loadMessages, loadMessagesUnread, messagesUnread, setMessagesRead, loadCounterMessagesAuth, countMessagesAuth } = rootStore.messageStore
  const { user } = rootStore.authStore
  const { data: messages, refetch } = useQuery('getAllStaffs', async () => await getMyMessages("inbox"))
  const [isHovered, setIsHovered] = useState(false);
  const [replyMessage, setReplyMessage] = useState(false)
  const [showmessage, setShowMessage] = useState(false)
  const [list, setlist] = useState(true)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [composepostdata, setComposepostdata] = useState<IReply>(initialReplyData)
  const [messageId, setMessageId] = useState('')
  const [folder, setFolder] = useState('inbox')
  const [active, setActive] = useState(false)
  const getImage = (image: string) => {
    if (image === '') return '/media/avatars/avatar.png'
    return image
  }

  useEffect(() => {
    if (active === true) {
      setTimeout(() => {
        loadMessages(folder)
        loadMessagesUnread()
        messagesUnread
      }, 200)
      setActive(false)
    }
  }, [active])

  useEffect(() => {
    if (messages) {
      loadCounterMessagesAuth(user?.id)
      const unreadMessages = messages.filter((message) => message.mr_read === null);
      const totalUnreadMessages = unreadMessages.length;
      setCountNotRead(totalUnreadMessages)
    }
  }, [messages])

  const handleCloseShowMessage = () => {
    setReplyMessage(false)
    setShowMessage(false)
    setlist(true)
  }

  const MessagesUpdateState = async (message: any) => {
    const unread = true
    if (message.id && message.unread === false) {
      await updateMessagesState(unread, message.id?.toString())
      loadMessages(folder)
      loadCounterMessagesAuth(user?.id)
      loadMessagesUnread()
      refetch()
    }
  }

  const HandleReadmessage = (id: string) => {
    setMessagesRead(id)
    if (setMessageId != null) setMessageId(id)
    if (setShowMessage != null) setShowMessage(true)
    setlist(false)
  }

  const handleClose = () => {
    setReplyMessage(false)
    setlist(true)
  }

  return (
    <div className="w-full sm:w-60 mt-2">
      <div className='card-title'>
        <img style={{width:'30px'}} src={Speech} alt="Mensajes" />
        {/* <img alt="Mensajes" src='/media/icons/app/menu/Group.svg' /> */}
        <h1 >Mensajes</h1>
      </div>
      {Number(countMessagesAuth) > 0 ?
        <p>Tienes {/* {Number(countMessagesAuth) > 0 ? Number(countMessagesAuth) : 0} */} mensajes sin leer</p>
        : <p>No tiene mensajes pendientes</p>}
      <ul className='messages-card-container'>
        {messages && countMessagesAuth > 0 && messages.map((message, index) => (
          (message.unread === false) && (
            <li
              key={index}
              className='messages-card'
              onClick={() => {
                setShowMessage(true)
                MessagesUpdateState(message)
                setActive(true)
                HandleReadmessage(message.id ? message.id?.toString() : '');
                if (handleClose != null) {
                  handleClose();
                }
              }}
            >
              <img src={message.fromPathId ? getImage(`${message.fromPath}/${message.fromPathId}-avatar-small.jpg`) : getImage('/media/avatars/avatar.png')} alt="Grid2" />
              <div>
                <p className='from' style={{ margin: '0' }}>{message && message.fromName && message.fromName?.length > 40 ? <>{message.fromName.substring(0, 40)}...</> : <>{message.fromName}</>}</p>
                <p className='subject' style={{ margin: '0' }}>{message && message.subject && message.subject?.length > 40 ? <>{message.subject.substring(0, 40)}...</> : <>{message.subject}</>}</p>
              </div>
            </li>
          )
        ))}
      </ul>
      <div className="button-container">
        <button
          className='button-ui button-secondary button2'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Link to='/messages/imbox' className='div-meet2'>
            <p className='meet-text2'>VER MIS MENSAJES</p>
            <img
              alt="Calendar"
              className='meet-image2'
              src={isHovered ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'}
            />

          </Link>
        </button>
      </div>

      {showmessage ? (
        <div className='user-profile-popup'>
          <div className='user-profile-popup-continer'>
            <ShowMessages
              folder={folder}
              callRefetch={false}
              messageId={messageId}
              setShowMessage={setShowMessage}
              handleClose={handleCloseShowMessage}
              setComposepostdata={setComposepostdata}
              setlist={setlist}
              setReplyMessage={setReplyMessage}
              setShowMessageRows={setShowMessageRows}
            />
          </div>
        </div>
      ) : null}

      {replyMessage ? (
        <div className='user-profile-popup'>
          <div className='user-profile-popup-continer'>
            <MessageContent
              setShowMessage={setShowMessage}
              setShowMessageRows={setShowMessageRows}
              setReplyMessage={setReplyMessage}
              composepostdata={composepostdata}
              showSelectFiles={false}
              showShareFiles={false}
              initialContent={composepostdata.content}
              showTemplateSearch={false}
              initialSubject={composepostdata.subject}
              type={composepostdata.type}
              cc={[]}
              handleClose={handleClose}
              showButtonClose={true}
              category=''
              setComposeData={setComposepostdata}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
})

export default MessagesDashboard;
