/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { type IReply, initialReplyData } from '../../../../app/modules/apps/appointments/core/_models'
import React, { useContext, useEffect, useState } from 'react'
import ListMessages from './ListMessages'
import ListMessagesSearch from './ListMessagesSearch'
import MessageContent from '../MessageContent'
import { type Messages } from '../../../../app/modules/apps/messages/core/_models'
import { RootStoreContext } from '../../../../stores/rootStore'
import ShowMessages from './ShowMessages'
import { Button, Link } from 'components'
import NewMessageImbox from '../NewMessageContent'
import Envelop from '../../contratationApp/icons/Envelop.svg'
import Send from '../../contratationApp/icons/Send.svg'
import Start from '../../contratationApp/icons/Start.svg'
import Draft from '../../contratationApp/icons/Draft.svg'
import Adsent from '../../contratationApp/icons/Absent.svg'
import NewMessage from '../NewMessage'
import './style.css'
import { user } from 'auth/models/User'
import { observer } from 'mobx-react'

interface Props {
  data?: string
}

const UserMessages: React.FC<Props> = observer(({ }) => {
  const rootStore = useContext(RootStoreContext)
  const { staff } = rootStore.authStore
  const { selectUser } = rootStore.usersStore
  const { loadMessages, loadMessagesUnread, messagesUnread, loadMessagesNew, countMessagesAuth, loadCounterMessagesAuth } = rootStore.messageStore
  const { user } = rootStore.authStore
  const [showmessage, setShowMessage] = useState(false)
  const [replyMessage, setReplyMessage] = useState(false)
  const [list, setlist] = useState(true)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [folder, setFolder] = useState('inbox')
  const [messagesSeacrh, setMessagesSeacrh] = useState<Messages[] | null>(null)
  const [clearSearch, setClearSearch] = useState(false)
  const [createMessage, setCreateMessage] = useState(false)
  const [composepostdata, setComposepostdata] = useState<IReply>(initialReplyData)
  const [messageId, setMessageId] = useState('')
  const [unreadMessage, setUnreadMessage] = useState(0)
  const [isUnread, setIsUnread] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const [isInbox, setIsInbox] = useState(true)
  useEffect(() => {
    handleSearchMessages('inbox')
    if (list) loadMessagesUnread()
  }, [list])

  useEffect(() => {
    selectUser(null)
  }, [])


  useEffect(() => {
    console.log(isInbox, 'este es inbox')
  }, [isInbox])

  const handleClose = () => {
    setReplyMessage(false)
    setlist(true)
  }

  const handleCloseShowMessage = () => {
    setReplyMessage(false)
    setShowMessage(false)
    setlist(true)
  }

  const handleSearchMessages = (value: string) => {
    console.log('por aqui pase')
    setFolder(value)
    loadMessages(value)
    loadMessagesUnread()
    loadMessagesNew(value, 1)
    loadCounterMessagesAuth(user?.id)
  }

  return (
    <>
      <div>
        <NewMessageImbox
          handleSearchMessages={handleSearchMessages}
          setIsUnread={setIsUnread}
          setIsFavorite={setIsFavorite}
          setCreateMessage={setCreateMessage}
          hide={false}
        />
      </div>
      <div className='flex-responsive inbox-continer-component-menu' style={{}}>
        <>
          <div className='message-container'
            style={{ paddingLeft: '1rem', paddingRight: '1rem', marginRight: '1rem' }}
          >
            {showMessageRows ? (
              <>
                <div className='messages-responsive' style={{ width: '10rem' }}>
                  <div className='message-div' style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '12px', paddingTop: '12px' }}>
                    <div
                      style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                      className={folder === 'inbox' && isInbox === true ? 'bg-gray-900  hover:bg-gray-300' : ''}
                      onClick={() => {
                        handleSearchMessages('inbox')
                        setIsUnread(false)
                        setIsFavorite(false)
                        setIsInbox(true)
                      }}>
                      <img className='icon-message' src={Envelop} alt="Envelop" />
                      <p className='message-title' style={{ margin: '0', fontSize: '12px' }}>Recibidos</p>
                      {Number(countMessagesAuth) > 0 && <p style={{ margin: '0', color: 'white', padding: '0 3px', backgroundColor: '#13C296', borderRadius: '5px', fontSize: '12px', width: '15px' }}>{/* {countMessagesAuth} */}</p>}
                    </div>
                  </div>
                  <div className='message-div' style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '1rem', paddingTop: '1rem' }}>
                    <div
                      style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                      className={folder === 'sent' ? 'bg-gray-900  hover:bg-gray-300' : ''}
                      onClick={() => {
                        handleSearchMessages('sent')
                        setIsUnread(false)
                        setIsFavorite(false)

                      }}>
                      <img className='icon-message' src={Send} alt="Envelop" />
                      <p className='message-title' style={{ margin: '0', fontSize: '12px' }}>Enviados</p>
                    </div>
                  </div>
                  <div className='message-div' style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '1rem', paddingTop: '1rem' }}>
                    <div
                      style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                      className={folder === 'inbox' && isInbox === false ? 'bg-gray-900  hover:bg-gray-300' : ''}
                      onClick={() => {
                        handleSearchMessages('inbox')
                        setIsUnread(false)
                        setIsFavorite(true)
                        setIsInbox(false)
                      }}>
                      <img className='icon-message' src={Start} alt="Start" />
                      <p className='message-title' style={{ margin: '0', fontSize: '12px' }}>Destacados</p>
                    </div>
                  </div>
                  <div className='message-div' style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '1rem', paddingTop: '1rem' }}>
                    <div
                      style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                      className={folder === 'draft' ? 'bg-gray-900  hover:bg-gray-300' : ''}
                      onClick={() => {
                        handleSearchMessages('draft')
                        setIsUnread(false)
                        setIsFavorite(false)

                      }}>
                      <img className='icon-message' src={Draft} alt="Draft" />
                      <p className='message-title' style={{ margin: '0', fontSize: '12px' }}>Borradores</p>
                    </div>
                  </div>
                  {staff && <div className='message-div' style={{ borderBottom: '2px solid #DFE4EA', paddingBottom: '1rem', paddingTop: '1rem' }}>
                    <div
                      style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '10px', padding: '12px', borderRadius: '10px' }}
                      className={folder === 'absent' ? 'bg-gray-900  hover:bg-gray-300' : '' }
                      onClick={() => {
                        handleSearchMessages('absent')
                        setIsUnread(false)
                        setIsFavorite(false)
                      }}>
                      <img className='icon-message' src={Adsent} alt="Adsent" />
                      <p className='message-title' style={{ margin: '0', fontSize: '12px' }}>Ausentes</p>
                    </div>
                  </div>}
                </div>
              </>
            ) : null}
          </div>
        </>
        <div style={{ width: '100%' }}>
          <div className='col-lg-12'>
            {messagesSeacrh != null ? (
              <ListMessagesSearch
                setMessageId={setMessageId}
                setShowMessage={setShowMessage}
                setReplyMessage={setReplyMessage}
                setComposepostdata={setComposepostdata}
                setlist={setlist}
                folder={folder}
                messages={messagesSeacrh}
                setMessagesSeacrh={setMessagesSeacrh}
                setClearSearch={setClearSearch}
                handleClose={handleClose}
                setShowMessageRows={setShowMessageRows}
              />
            ) : (
              <ListMessages
                setMessageId={setMessageId}
                callRefetch={false}
                setShowMessage={setShowMessage}
                setReplyMessage={setReplyMessage}
                setComposepostdata={setComposepostdata}
                setlist={setlist}
                folder={folder}
                handleClose={handleClose}
                setShowMessageRows={setShowMessageRows}
                unread={setUnreadMessage}
                isUnread={isUnread}
                isFavorite={isFavorite}
              />
            )}
          </div>
          {showmessage && folder === 'draft' ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer'>
                <MessageContent
                  setShowMessage={setShowMessage}
                  setShowMessageRows={setShowMessageRows}
                  setReplyMessage={setReplyMessage}
                  composepostdata={composepostdata}
                  showSelectFiles={false}
                  showShareFiles={false}
                  initialContent={composepostdata.content}
                  showTemplateSearch={false}
                  initialSubject={composepostdata.subject}
                  type={composepostdata.type}
                  cc={[]}
                  handleClose={handleClose}
                  showButtonClose={true}
                  category='fromDraft'
                  setComposeData={setComposepostdata}
                />
              </div>
            </div>
          ) : null}
          {showmessage && folder !== 'draft' ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer '>
                <ShowMessages
                  folder = {folder}
                  callRefetch={false}
                  messageId={messageId}
                  setShowMessage={setShowMessage}
                  handleClose={handleCloseShowMessage}
                  setComposepostdata={setComposepostdata}
                  setlist={setlist}
                  setReplyMessage={setReplyMessage}
                  setShowMessageRows={setShowMessageRows}
                />
                {replyMessage ? (
                  <div style={{
                    padding:'20px',
                    border:'1px solid black',
                    borderRadius:'10px',
                    boxShadow: '0 10px 15px 0 rgba(5, 13, 29, 0.18)'
                  }}>
                    <MessageContent
                      setShowMessage={setShowMessage}
                      setShowMessageRows={setShowMessageRows}
                      setReplyMessage={setReplyMessage}
                      composepostdata={composepostdata}
                      showSelectFiles={false}
                      showShareFiles={false}
                      initialContent={composepostdata.content}
                      showTemplateSearch={false}
                      initialSubject={composepostdata.subject}
                      type={composepostdata.type}
                      cc={[]}
                      handleClose={handleClose}
                      showButtonClose={true}
                      category=''
                      setComposeData={setComposepostdata}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {createMessage ? (
            <div className='user-profile-popup'>
              <div className='user-profile-popup-continer'>
                <NewMessage
                  contractId={''}
                  emailStaff={''}
                  setShowMessage={setShowMessage}
                  setShowMessageRows={setShowMessageRows}
                  setReplyMessage={setReplyMessage}
                  composepostdata={composepostdata}
                  showSelectFiles={false}
                  showShareFiles={false}
                  initialContent={composepostdata.content}
                  showTemplateSearch={false}
                  initialSubject={composepostdata.subject}
                  type={'new_message'}
                  cc={[]}
                  handleClose={handleClose}
                  showButtonClose={true}
                  category=''
                  setCreateMessage={setCreateMessage}
                  setComposeData={setComposepostdata}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
})

export default UserMessages
