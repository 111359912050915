import {Link} from 'components'
import {Container} from '../../../components/Container/index'
import {useContext, type FC} from 'react'
import { useQuery } from 'react-query'
import { getStaff } from 'app/modules/apps/staff/core/_requests'
import { RootStoreContext } from 'stores/rootStore'

const IntakeAdmin: FC = () => {
  const rootStore = useContext(RootStoreContext)
  const {user} = rootStore.authStore
  const {data: dataStaff} = useQuery(
    'getAllStaffsss',
    async () => await getStaff(user?.id ? user.id : '')
  )
  return (
    <>
      <Container title='Administrador de intake'>
        <div className='flex gap-4 justify-center flex-wrap'>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/formsmanager'
          >
            <img className='w-32' src='/media/svg/menu-icons/Pickup_airport.svg' alt='clientes' />
            <h2>Formularios</h2>
          </Link>
          <Link
            className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
            to='/admin/store/products'
          >
            <img className='w-32' src='/media/svg/menu-icons/cv_services.svg' alt='' />
            <h2>Servicios</h2>
          </Link>
          {dataStaff &&
            user?.role?.id === 1 &&
            dataStaff[0].type !== 'caseworker' &&
            dataStaff[0].type !== 'partner' && (
              <Link
                className='flex flex-col gap-4 items-center p-7 border-1 rounded-xl'
                to='/doc-editor'
              >
                <img
                  className='w-32'
                  src='https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/256/bf3c3c/external-documents-business-vitaliy-gorbachev-lineal-vitaly-gorbachev.png'
                  alt='external-documents-business-vitaliy-gorbachev-lineal-vitaly-gorbachev'
                />
                <h2>Administrador de documentos</h2>
              </Link>
            )}
        </div>
      </Container>
    </>
  )
}

export {IntakeAdmin}
