import tw from 'tailwind-styled-components'

const MailAvatar = tw.span` 
  

  p-0 
  mr-2 
  justify-center
  items-center
  
`
// avatar
export {MailAvatar}
