/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  initialReplyData,
  IReply,
} from '../../../../app/modules/apps/appointments/core/_models'
import { useContext, useEffect, useState } from 'react'
import ListTranslations from './ListTranslations'
import MessageContent from '../../../../app/pages/messages/MessageContent'
import ShowTranslation from '../common/ShowTranslation'
import UploadHandler from './UploadHandler'
import { RootStoreContext } from 'stores/rootStore'

const UserTranslation = () => {
  const [uploadFiles, setUploadFiles] = useState(false)
  const [list, setList] = useState(true)
  const [translation, setTranslation] = useState(false)
  const [composemessage, setComposeMessage] = useState(false)
  // If the user is uploading files, the translation is not shown
  const [filesUploaded, setFilesUploaded] = useState(false)
  const [translate, setTranslate] = useState('')
  const [composepostdata, setComposepostdata] = useState<IReply>(initialReplyData)
  const [subject, setSubject] = useState('')
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const [showmessage, setShowMessage] = useState(false)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [replyMessage, setReplyMessage] = useState(false)

  const handleClose = () => {
    setList(true)
    setComposeMessage(false)
  }

  useEffect(() => {
    // comment to deactivate eslint
  }, [subject])


  return (
    <div>
      {list ? (
        <ListTranslations
          setUploadFiles={setUploadFiles}
          setTranslation={setTranslation}
          setList={setList}
          setTranslate={setTranslate}
          setFilesUploaded={setFilesUploaded}
          setComposepostdata={setComposepostdata}
          setComposeMessage={setComposeMessage}
          setSubject={setSubject}
          user={user}
        />
      ) : null}
      {uploadFiles ? (
        <UploadHandler
          setUploadFiles={setUploadFiles}
          setTranslate={setTranslate}
          setFilesUploaded={setFilesUploaded}
          setList={setList}
          translate={translate}
        />
      ) : null}
      {translation ? (
        <>
          <ShowTranslation
            setTranslation={setTranslation}
            setList={setList}
            translate={translate}
          />
        </>
      ) : null}

      {composemessage ? (
        <MessageContent
          setShowMessage={setShowMessage}
          setShowMessageRows={setShowMessageRows}
          setReplyMessage={setReplyMessage}
          composepostdata={composepostdata}
          showSelectFiles={false}
          showShareFiles={false}
          initialContent={''}
          showTemplateSearch={false}
          initialSubject={subject}
          type={'translation'}
          cc={[]}
          handleClose={handleClose}
          showButtonClose={true}
          showComposeTemplate={false}
          category=''
          setComposeData ={setComposepostdata}
        />
      ) : null}
    </div>
  )
}

export default UserTranslation
