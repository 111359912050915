/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react'
import FirstLogging from './userDasboardComponents/firstLogging'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import PowerUps from './userDasboardComponents/powerUps'
import CardManager from './userDasboardComponents/HideDisplayCards'
import MessagesDashboard from './userDasboardComponents/DashboardMessages'
import BoostPowerUps from './userDasboardComponents/BoostPowerUps'
import { useQuery } from 'react-query'
import { UsersAppointments2 } from '../appointments/UsersAppointments/indexDashboard'
import { IApointments, IComposeData, initialComposeData } from 'app/modules/apps/appointments/core/_models'
import { getAdminAppointmentsNew, getAdminAppointmentsUser } from 'app/modules/apps/appointments/core/_requests'
import { UserModel } from 'auth/models/User/UserModel'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { updateShowIntroVideo } from 'app/modules/apps/profile/core/_requests'
import UserDashboardVisaProcces from 'app/pages/dashboard/userDasboardComponents/visaProccess'
import { getOrders } from 'app/modules/apps/orders/core/_requests'

interface IProps {
  userDetected: UserModel
}
const DashboardUser: React.FC<IProps> = ({ userDetected }) => {
  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore
  const { getpProfile, profileImmigration } = rootStore.userStore
  const [closeVideoPopUp, setCloseVideoPopUp] = useState(false)
  const [openKeysPopUp, setOpenKeysPopUp] = useState(false)
  const [composepostdata, setComposePostData] = useState<IComposeData>(initialComposeData)
  const [composepost, setComposePost] = useState<boolean>(false)
  const [composenote, setComposeNote] = useState<boolean>(false)
  const [zone, setZone] = useState<string>('Canada/Saskatchewan')

  const [thisBannerShown, setThisBannerShown] = useState(false)
  const [otherBannerShown, setOtherBannerShown] = useState(false)
  const { contracts, loadingContracts, updatingContracts, loadingDocusignFetch } = rootStore.authStore
  const [showProfilePopup, setShowProfilePopup] = useState(false)
  const navigate = useNavigate()


  const initialDate: IApointments = {
    startDate: '',
    endDate: '',
    page: 1,
    limit: 50,
    search: '',
    email: '',
    userId: userDetected.id,
  }

  useEffect(() => {
    if (user?.timeZone) {
      setZone(user.timeZone)
    } else {
      setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }, [user])

  const [query, setQuery] = useState<IApointments | null>(initialDate)
  /*  const {data: services, status: servicesStatus} = useQuery('services', async () => await getServices(query)) */
  const { data } = useQuery(
    'adminappointmenasdsssswwts',
    async () => await getAdminAppointmentsUser(query),
    {
      retry: false,
      cacheTime: 0,
      enabled: Boolean(initialDate.userId),
    }
  )

  useEffect(() => {
    if (showProfilePopup) {
      const { firstName, lastName, birth, gender } = user || {}

      // Check if the required fields are not empty
      if (!firstName || !lastName || !birth || !gender) {
        // Check if 'birth' is a valid Date
        Swal.fire({
          icon: 'info',
          title: '<i class="fas fa-exclamation-circle"></i> Complete sus datos',
          text: 'Para continuar, debe completar algunos datos de su perfil',
          showCloseButton: false,
          confirmButtonText: `<span style="color: white; font-size: 14px; display: flex; gap: 5px;">IR A COMPLETAR MIS DATOS <img src="/media/icons/app/menu/arrow-right-white.svg" alt="Arrow" style="vertical-align: middle; width: 24px"; height: 24px;></span>`,
          background: 'white',
          grow: 'row',
          width: '500px',
          padding: '50px',
          buttonsStyling: false,
          customClass: {
            title: 'popup-title',
            confirmButton: 'popup-button-black',
            popup: 'popup-with-border-radius',
          },
          confirmButtonColor: '#000',
        }).then(() => {
          // Navega a la ruta deseada cuando se hace clic en el botón
          navigate('/crafted/account/settings')

        })
      }
    }
  }, [showProfilePopup, navigate, user])

/*   useEffect(() => {
    getpProfile(user?.id ?? '')
  }, [user])
 */



  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders('some-query')


        // Mostrar el primer banner si response está vacío y el segundo banner no se está mostrando
        if (response.length === 0 && !otherBannerShown) {
          setThisBannerShown(true)
        }

        // Mostrar el segundo banner si hay citas y response no está vacío
        if (contracts && contracts.length === 0 && response.length    > 0 && loadingContracts === false && !thisBannerShown) {
          setOtherBannerShown(true)
        }
      } catch (error) {
        console.error('Error fetching orders:', error)
      }
    }

    fetchOrders()
  }, [contracts, otherBannerShown, thisBannerShown])


  const popupVideo = () => {
    if (user?.show_intro_video) {
      return (
        <>
          <div className='popup-user-video-welcome-continer'>
            <div className='popup-user-video-welcome'>
              <h1 className='user-video-title'>¡Bienvenido a la plataforma!</h1>
              <iframe
                className='dashboard-video'
                src='https://player.vimeo.com/video/842910868?h=58eeee7d4d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                allow='autoplay; fullscreen; picture-in-picture allowfullscreen'
                title='Bienvenidos a consulta'
              />
              {/*  <div className='no-show-video-btn'>
                <CustomCheckbox />
                <p style={{ margin: '0' }}>
                  No volver a mostrar este video
                </p>
              </div> */}
              <div className='no-show-video-btn'>
                <input style={{ marginBottom: '6px' }}
                  type='checkbox'
                  id='noShowVideo'
                  onChange={async () => {
                    const test = await updateShowIntroVideo(user?.id)


                    setShowProfilePopup(true)
                  }}
                />
                <label htmlFor='noShowVideo'>No volver a mostrar este video</label>
              </div>
              <button
                className='button-ui button-secondary button-s '
                onClick={() => {
                  setCloseVideoPopUp(true)
                  setShowProfilePopup(true)
                }}>
                Cerrar
              </button>
            </div>
            <script src='https://player.vimeo.com/api/player.js' />
          </div>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        {openKeysPopUp === true ? <CardManager hide={setOpenKeysPopUp} /> : null}
        <div className='header-name-container'>
          <h1 className='header-name'>Bienvenid@, {user?.firstName} {user?.lastName}</h1>

          {/* <button
            className='dashboard-manager-btn'
            onClick={() => {
              setOpenKeysPopUp(true)
            }}
          >
            <img style={{ width: '15px', height: '15px', marginTop: '3px' }} src={Grid2} alt="logout icon" />
            <p style={{ color: '#01A9DB', padding: '0', margin: '0' }}>Personaliza tu dashboard</p>
          </button> */}
        </div>


        {/* cerrar proceso de visa */}
        <div className='first-logging-timeline-main' >

          <div className='banner-dashboard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt="Calendar"
                className='dashboard-image'
                src='/media/svg/menu-icons/image-138.png'
                style={{ marginRight: '20px' }}
              />
              <div>
                <p className='dashboard-title-banner'>¿Quiere viajar a Canadá?</p>
                <p className='dashboard-pretitle-banner'>Converse con nosotros para poder hacerlo realidad.</p>
              </div>
            </div>
            <Link to='/cart/consultamigratoria'>
              <button
              style={{marginRight:'1rem'}}
                className='button-ui button-CTA '
              >
                <img src='/media/svg/menu-icons/chat-alt-11.svg' alt='Cart' />
                <p >SOLICITAR ASESORÍA</p>
              </button>
            </Link>
          </div>



        {otherBannerShown && (
          <div className='banner-dashboard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt="Calendar"
                className='dashboard-image'
                src='/media/svg/menu-icons/image-138.png'
                style={{ marginRight: '20px' }}
              />
              <div>
                <p className='dashboard-title-banner'>¿Todo listo para viajar?</p>
                <p className='dashboard-pretitle-banner'>¡No pierda el tiempo, cotize y contrate ahora!</p>
              </div>
            </div>
            <Link to='/cart/immigrationservicescontract'>
              <button
                className='button-form-notify'
                style={{ gap: '10px', display: 'flex', alignItems: 'center', marginRight: '3rem' }}
              >
                <img src='/media/svg/menu-icons/chat-alt-11.svg' alt='Cart' />
                <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>COTIZA TU VISA </p>
              </button>
            </Link>
          </div>
        )}
          <div className='dashboard-main-card '>
            {user?.firstAccess === false && <FirstLogging />}
            <UserDashboardVisaProcces />
          </div>
        </div>
        <div className='secondary-cards-container'>
          {/* {timeline && timeline[0] && timeline[0].rightdata && timeline[0].rightdata.timelineData && timeline[0].rightdata.timelineData.length > 0 &&
            <div className='first-logging-timeline-card'>
              <DashboardTimeline />
            </div>
          } */}
          <div className='first-logging-timeline-card'>
            <MessagesDashboard />
          </div>
          <div className='first-logging-timeline-card'>
            {data != null ? (
              <>
                <UsersAppointments2
                  setComposePostData={setComposePostData}
                  setComposePost={setComposePost}
                  setComposeNote={setComposeNote}
                  appointments={data}
                  zone={zone}
                  ProfileImmigration={profileImmigration}
                  setQuery={setQuery}
                  query={query}
                  userId={user?.id ? user.id : ''}
                />
              </>
            ) : null}
          </div>
          <PowerUps />
        </div>
        {closeVideoPopUp === false ? popupVideo() : null}
      </div>
      <div>
        <BoostPowerUps />
      </div>
    </>
  )
}

export default observer(DashboardUser)
