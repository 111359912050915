import Shopping from './userDashboardIcons/shopping-bag-14.svg'
import World from './userDashboardIcons/world.svg'
import Cart from './userDashboardIcons/cart-alt.svg'
import Group from './userDashboardIcons/Group.svg'
import { Link } from 'react-router-dom'
import { useState } from 'react'


const BoostPowerUps: React.FC = () => {
  const [isHovered2, setIsHovered2] = useState(false)
  return (
    <><div className='bottom-services w-full'>

      <div className='title-services flex '>
       
        <h2> <img src={Shopping} alt="Shopping" />Tienda</h2> 
        <h3 >Potencie su plan y agilice su proceso con estos servicios </h3>
      </div>
     
      <ul className='powerup-card-container'>
        <li className='powerup-card'>
          <div className='powerup-card-left-style' style={{ backgroundColor: 'red' }}>
            <img src={World} alt="World" />
          </div>
          <div className='powerup-card-right-style'>
            <div className='description'>
              <h2>Consulta</h2>
              <p style={{ margin: '0' }}>Consulta de seguimiento - 30 min</p>
            </div>
            <Link
              className='button-ui button-primary button-xs'
              to='/cart/consultamigratoria'>
              <img src={Cart} alt='Cart' />
              <p>Desde<br></br>  CAD$ 150</p>
            </Link>
          </div>
        </li>
        <li className='powerup-card'>
          <div className='powerup-card-left-style' style={{ backgroundColor: '#FBBF24' }}>
            <img src={World} alt="World" />
          </div>
          <div className='powerup-card-right-style'>
            <div className='description'>
              <h2>Traducciones</h2>
               <p style={{ margin: '0' }}>Acelere sus procesos con una traducción de calidad para sus documentos.</p>
            </div>
            <a
              className='button-ui button-primary button-xs'
              rel='noreferrer'
              target='_blank'
              href='https://www.immilandcanada.com/traduccion/inicio'
            >
              <img src={Cart} alt='Cart' />
              <p>Desde<br></br>  CAD$ 37</p>
            </a>
          </div>
        </li>
        <li className='powerup-card'>
          <div className='powerup-card-left-style' style={{ backgroundColor: '#F59460' }}>
            <img src={Group} alt="Group" />
          </div>
          <div className='powerup-card-right-style'>
            <div className='description'>
              <h2 >Establecimiento</h2>
              <p style={{ margin: '0' }}>Le apoyamos encontrando un buen lugar para vivir.</p>
            </div>
            <a
              className='button-ui button-primary button-xs'
              rel='noreferrer'
              target='_blank'
              href='https://www.immilandcanada.com/establecimiento/inicio'>
              <img src={Cart} alt='Cart' />
              <p >Desde<br></br>  CAD$ 100</p>
            </a>
          </div>
        </li>
        <li className='powerup-card'>
          <div className='powerup-card-left-style' style={{ backgroundColor: '#EF5DA8' }}>
            <img src={World} alt="World" />
          </div>
          <div className='powerup-card-right-style'>
            <div className='description'>
               <h2>Educación</h2>
               <p style={{ margin: '0' }}>Le guiamos para encontrar las mejores alternativas de educación para usted y su familia..</p>
            </div>
            <Link
              className='button-ui button-primary button-xs'
              to='/cart/educationservices'>
              <img src={Cart} alt='Cart' />
              <p>Desde<br></br>  CAD$ 20</p>
            </Link>
          </div>
        </li>
        <li className='powerup-card'>
          <div className='powerup-card-left-style' style={{ backgroundColor: '#22AD5C' }}>
            <img src={World} alt="World" />
          </div>
          <div className='powerup-card-right-style'>
             <div className='description'>
                <h2>Trabajo</h2>
                <p style={{ margin: '0' }}>Aceleramos su inscripción en la institución educativa que más se ajuste a sus necesidades.</p>
            </div>
            <a
              className='button-ui button-primary button-xs'
              rel='noreferrer'
              target='_blank'
              href='https://www.immilandcanada.com/reclutamiento/inicio'>
              <img src={Cart} alt='Cart' />
              <p>Desde<br></br> CAD$ 150</p>
            </a>
          </div>
        </li>
        <li className='powerup-card'>
          <div className='powerup-card-left-style' style={{ backgroundColor: '#2D9CDB' }}>
            <img src={World} alt="World" />
          </div>
          <div className='powerup-card-right-style'>
            <div className='description'>
              <h2>Inmigración</h2>
              <p style={{ margin: '0' }}>Realice su sueño de trabajar, estudiar y vivir en Canadá.</p>
            </div>
           
            <Link
              className='button-ui button-primary button-xs'
              to='/cart/immigrationservices'>
              <img src={Cart} alt='Cart' />
              <p >Desde <br></br> CAD$ 80</p>
            </Link>
          </div>
        </li>
      </ul>
    </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <button
          className='button-ui button-secondary'

          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <Link to={'/cart'} className='div-meet2'>
            <p className='meet-text2'>VER TIENDA</p>
            <img
              alt="Calendar"
              className='meet-image2'
              src={isHovered2 ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-black.svg'} />

          </Link>
        </button></div></>
  )
}

export default BoostPowerUps
