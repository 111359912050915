/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect} from 'react'
import {countPagePdf} from '../../../../app/pages/Cart/CheckOut/UploaderTemp/CountPDFPages'

interface IProps {
  file: File
  getPageCounter?: (totalPages: number) => void
}

const PageCounter: React.FC<IProps> = ({file}) => {
  // if file[i] is pdf count pages and add to total pages counter else add 1 to total pages counter for each file uploaded (jpg, png, zip) and return total pages

  const [totalPages, setTotalPages] = React.useState(0)

  useEffect(() => {
    countPages()
  }, [file])

  const countPages = async () => {
    let total = 0

    if (file.type === 'application/pdf') {
      total += await countPagePdf(file)
    } else {
      total += 1
    }

    setTotalPages(total)
  }

  useEffect(() => {
    // getPageCounter && getPageCounter(totalPages)
  }, [totalPages])

  return <>{totalPages}</>
}

export default PageCounter
