/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  ResponseApointment,
  type AppointmentsAdmin,
  type IComposeData,
  IApointments,
  IReply,
} from '../../../../app/modules/apps/appointments/core/_models'
import {Link} from '../../../../components'
import {Button, DatePicker, Input, InputRef, Space, Table, Tooltip} from 'antd'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {DateTime} from 'luxon'
import DialogComponent from '../../../../components/Dialog'
import {RootStoreContext} from '../../../../stores/rootStore'
import UserProfile from '../../../../app/pages/user/UserProfile'
import {useIntl} from 'react-intl'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import Column from 'antd/lib/table/Column'
import {ColumnFilterItem, ColumnType} from 'antd/lib/table/interface'
import {SearchOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import {RangeValue} from 'rc-picker/lib/interface'
import {getUserUsersById} from 'app/modules/apps/users/core/_requests'
import {Users} from 'app/modules/apps/users/core/_models'
import '../../admin/users/List/style.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHistory} from '@fortawesome/free-solid-svg-icons'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import Swal from 'sweetalert2'
interface Props {
  appointments?: ResponseApointment
  setComposePostTimeline: React.Dispatch<React.SetStateAction<boolean>>
  setComposePostDataTimeline: React.Dispatch<React.SetStateAction<IReply>>
  setComposeNote: React.Dispatch<React.SetStateAction<boolean>>
  zone: string
  ProfileImmigration: ProfileImmigration
  setQuery: (query: IApointments) => void
  query: IApointments | null
  setTimelineUserId: React.Dispatch<React.SetStateAction<string>>
}

const TimelineDetails: React.FC<Props> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const {selectUser} = rootStore.usersStore
  const {ProfileImmigration} = props
  const [open, setOpen] = React.useState(false)
  const [opendataUser, setDataUser] = React.useState<IComposeData | null>(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [nameInfo, setNameInfo] = useState<ColumnFilterItem[]>()
  const intl = useIntl()
  const {
    setTimelineUserId,
    appointments,
    setComposePostTimeline,
    setComposePostDataTimeline,
    zone,
    setQuery,
    query,
  } = props
  const [currentEmail, setCurrentEmail] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const searchInput = useRef<InputRef>(null)
  const [currentProductName, setCurrentProductName] = useState<string[]>()
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null)
  const [toDate, setToDate] = useState<moment.Moment | null>(null)
  type DataIndex = keyof AppointmentsAdmin
  const getDate = (date: any) => {
    const keepOffset = DateTime.fromISO(date, {zone}).toFormat('yyyy-MM-dd HH:mm a')

    return keepOffset.toString()
  }

  const handleOpenPost = (data: any) => {
    console.log(data)
    setComposePostDataTimeline(data)
    setComposePostTimeline(true)
    setTimelineUserId(data.userId)
  }

  useEffect(() => {
    if (nameInfo === undefined && appointments && appointments.data.length > 0) {
      setNameInfo(
        appointments?.data.map((item: any) => {
          if (item.name !== undefined && item.name !== null) {
            return {
              text: item.name,
              value: item.name,
            }
          } else {
            return {
              text: '',
              value: '',
            }
          }
        })
      )
    }
  }, [appointments])

  const handleSearchCustom = (type: string, value: string) => {
    if (type === 'email') {
      setCurrentEmail(value)
      try {
        const params: any = {
          ...query,
          email: value,
          startDate: '',
          endDate: '',
        }
        setFromDate(null)
        setToDate(null)
        setQuery(params)
      } catch (error) {}
    }
  }
  const getColumnSearchPropsEmail = (dataIndex: DataIndex): ColumnType<AppointmentsAdmin> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{padding: 8}}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string | number | undefined}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearchCustom('email', selectedKeys[0] as string)
          }}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => {
              handleSearchCustom('email', selectedKeys[0] as string)
            }}
            size='small'
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              selectedKeys[0] = ''
              setCurrentEmail('')
              handleSearchCustom('email', '')
            }}
            size='small'
            style={{width: 90}}
          >
            Resetear
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({closeDropdown: false})
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record: any) =>
      record.email
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const onChange = (pagination: any, filters: any) => {
    setCurrentPage(pagination.current)
    console.log('proudctName' + filters.productName)
    if (filters.productName !== null) {
      setCurrentProductName(filters.productName)
    }

    if (filters.productName === null) {
      setCurrentProductName([])
    }
  }

  useEffect(() => {
    try {
      if (query !== null) {
        console.log('product' + currentProductName)
        const params: IApointments = {
          ...query,
          page: currentPage,
          description: currentProductName,
        }

        setQuery(params)
      }
    } catch (error) {}
  }, [currentPage, currentProductName])

  const handleDate = (values: any) => {
    try {
      if (values) {
        const params: any = {
          ...query,
          startDate: values[0].format('DD-MM-YYYY'),
          endDate: values[1].format('DD-MM-YYYY'),
          email: '',
        }

        setQuery(params)
      } else {
        const params: any = {
          ...query,
          startDate: '',
          endDate: '',
        }
        setQuery(params)
      }
    } catch (error) {}
  }

  const handleDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates) {
      setFromDate(dates[0])
      setToDate(dates[1])
    }
  }
  const findUser = async (fromId: string) => {
    const result: Users | undefined | void = await getUserUsersById(fromId)
    if (result !== undefined) {
      selectUser(result)
    }
  }

  const handlePopup = () => {
    Swal.fire({
      title: 'Filtre por fechas o por email para ubicar el timeline de su cliente',
      icon: 'info',
      confirmButtonText: 'Entendido',
    })
  }
  return (
    <>
      <DialogComponent
        children={
          <>
            {opendataUser != null && (
              <UserProfile
                composepostdata={opendataUser}
                edit={true}
                ProfileImmigration={ProfileImmigration}
              />
            )}
          </>
        }
        title='Perfil del usuario'
        isOpen={open}
        closeModal={() => {
          setOpen(false)
        }}
        width='w-4/5'
        height='h-4/5'
      />
      {currentProductName && currentProductName.length > 0 ? (
        <>
          {' '}
          <h1>Filtros: </h1>
          <div
            style={{
              border: '2px solid #bf3c3c',
              display: 'block',
              padding: '0.5rem',
              paddingTop: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
            }}
          >
            {currentProductName.length > 0 && (
              <div style={{display: 'flex', marginBottom: '0.002rem'}}>
                <Button
                  style={{
                    width: '13rem',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    setCurrentProductName([])
                  }}
                >
                  <p>Descripcion</p> <p>X</p>
                </Button>
                <input
                  title='recidence'
                  className='w-full text-xs bg-rosa-100 text-black p-2 shadow-sm no-underline mb-3 focus:outline-none rounded-full border-[1px] border-slate-400 focus:border-[1px] focus:border-tomato-500'
                  value={currentProductName.toString()}
                  readOnly
                ></input>{' '}
                {/* {currentCountryOfOrigin.map((item) => item + " ")} */}
              </div>
            )}
          </div>
        </>
      ) : null}
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h2 className='timelineText' style={{marginRight: '3px'}}>
          Selector Timeline
        </h2>
        <ExclamationCircleOutlined
          onClick={handlePopup}
          style={{cursor: 'pointer', fontSize: '1rem'}}
        />
      </div>

      <Table
        style={{overflow: 'auto', position: 'relative'}}
        className={appointments?.data && appointments.data.length === 0 ? 'tableantdauto' : ''}
        scroll={{x: 'max-content', y: 500}}
        pagination={{
          pageSize: 50,
          showLessItems: true,
          size: 'small',
          responsive: true,
          simple: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: currentPage,
          total: Number(appointments?.total) || 0,
        }}
        dataSource={appointments?.data}
        onChange={onChange}
      >
        <Column
          className='timeline-column'
          title={intl.formatMessage({id: 'MENU.TIMELINE'})}
          render={(record: AppointmentsAdmin) => (
            <Space size='middle' style={{width: '2rem', display: 'flex', justifyContent: 'center'}}>
              <Tooltip placement='top' title={'Timeline'}>
                <Button
                  style={{background: 'white', border: '1px solid #FEA102'}}
                  onClick={() => {
                    handleOpenPost({
                      userId: record.user_id,
                    })
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} />
                </Button>
              </Tooltip>
            </Space>
          )}
        />

        <Column
          width={250}
          title={intl.formatMessage({id: 'PROFILE.FIRSTNAME'})}
          render={(record: AppointmentsAdmin) => (
            <Space>
              <Link to='/usersList'>
                <div
                  style={{color: 'gray', fontSize: '10px'}}
                  onClick={() => {
                    findUser(record.user_id)
                  }}
                >
                  {record.first_name + ' ' + record.last_name}
                </div>
              </Link>
            </Space>
          )}
        />

        <Column
          width={300}
          key='email'
          dataIndex={'email'}
          {...getColumnSearchPropsEmail('email')}
          title={intl.formatMessage({id: 'PROFILE.EMAIL'})}
        />

        <Column
          width={180}
          title={intl.formatMessage({id: 'CORE.DATE'}) + ` (${zone})`}
          render={(record: AppointmentsAdmin) => (
            <Space size='middle'>
              <p>{getDate(record.start_time)}</p>
            </Space>
          )}
          filterDropdown={({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
              <DatePicker.RangePicker value={[fromDate, toDate]} onChange={handleDateChange} />
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
                <Button
                  type='primary'
                  onClick={() => {
                    confirm()
                    handleDate([fromDate, toDate])
                    setCurrentEmail('')
                  }}
                  size='small'
                  style={{width: 90, marginRight: 8}}
                >
                  Filtrar
                </Button>
                <Button
                  onClick={() => {
                    setFromDate(null)
                    setToDate(null)
                    handleDate(['', ''])
                  }}
                  size='small'
                  style={{width: 90}}
                >
                  Limpiar
                </Button>
              </div>
            </div>
          )}
        />
      </Table>
    </>
  )
}

export {TimelineDetails}
