/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
 

import {Button, Col, Form, Input, Row, Switch, Typography} from 'antd'
import {
  type Category,
  categoryDetailsInitValues,
} from '../../../../../app/modules/apps/cart/core/category/_models'
import React, {useEffect, useState} from 'react'
import {stringToSlug} from '../../../../../core/utils/stringToSlug'

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }

  return e && e.fileList
}

export interface IProps {
  onComplete: (data: Category) => Promise<void>
  category: Category
}

const AddCategory: React.FunctionComponent<IProps> = ({
  onComplete,
  category = categoryDetailsInitValues,
}) => {
  const [link, setLink] = useState('')
  const [newCategory, setNewCategory] = useState(category)
  const [status, setStatus] = useState(category?.status.id === 1)

  useEffect(() => {
    handleChangeStatus(status)
    if (category?.link) {
      setLink(category.link)
    }
  }, [category])

  const handleSubmit = () => {
    onComplete(newCategory)
  }

  const handleChangeName = (text: string) => {
    const slug = stringToSlug(text)
    setLink(slug)
    category.link = slug

    handleChange('name', text)
  }

  const handleChange = (key: string, value: string | boolean) => {
    category = {...newCategory, [key]: value}
    setNewCategory(category)
  }

  const handleChangeStatus = (checked: boolean) => {
    setStatus(checked)
    category.status = checked ? {id: 1} : {id: 0}
  }

  return (
    <>
      <Row>
        {category?.id}
        <Col span={24}>
          <Form name='validate_other' layout='vertical' {...formItemLayout} onFinish={handleSubmit}>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              initialValue={category?.name}
              name='name'
              label='Nombre del la plantilla'
              style={{width: '100%'}}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{width: '100%'}}
                onChange={(e) => {
                  handleChangeName(e.target.value)
                }}
              />
            </Form.Item>
            <Typography>
              <pre>Slug: {link}</pre>
            </Typography>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              initialValue={category?.description}
              name='description'
              label='Descripcion'
              style={{width: '100%'}}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{width: '100%'}}
                onChange={(e) => {
                  handleChange('description', e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              initialValue={category?.tittle}
              name='tittle'
              label='Titulo'
              style={{width: '100%'}}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{width: '100%'}}
                onChange={(e) => {
                  handleChange('tittle', e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              initialValue={category?.order}
              name='order'
              label='Orden'
              style={{width: '100%'}}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type={'number'}
                style={{width: '100%'}}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value)
                }}
              />
            </Form.Item>

            <Form.Item label='Estado' valuePropName='checked'>
              <Switch
                defaultChecked={status}
                onChange={(e) => {
                  handleChangeStatus(e)
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                danger
                type='primary'
                htmlType='submit'
                style={{background: '#80706b', borderColor: '#80706b'}}
              >
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export {AddCategory}
