   
import {type FC} from 'react'
import {useIntl} from 'react-intl'
import {Container} from '../../../../components/Container/index'
import '../../messages/UserMessages/styles/TableStyle.css'

const ShowContracts: FC = () => {
  const intl = useIntl()

  return (
    <>
      <Container title={intl.formatMessage({id: 'CONTRACTS.CONTAINERCOMPLETED'})}>
        {' '}
        <div>
          <img src='/media/logos/logovid.gif' className='h-10px w-10px' alt='' />
        </div>
      </Container>
    </>
  )
}

export {ShowContracts}
