import {type Response} from '../../../../../../core/helpers'
import {type Files} from '../../../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../services/core/_models'

export interface ImmigrationRequirement {
  id?: string
  name?: string | null
  description?: string | null
  order?: number | null
  immigrationService: ImmigrationService
  files?: Files[]
}

export type ImmigrationRequirementQueryResponse = Response<ImmigrationRequirement[]>

type toFormValuesType = (apiData: ImmigrationRequirement) => ImmigrationRequirement

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  description: apiData.description || '',
  name: apiData.name || '',
  order: apiData.order || 100,
  immigrationService: apiData.immigrationService,
})

export const ImmigrationRequirementInitValues: ImmigrationRequirement = {
  description: '',
  name: '',
  order: 100,
  immigrationService: {},
}
